import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { GalleryPopupComponent } from '../../modules/admin/pages/campaign-gallery/gallery-popup.component';
import { BaseService } from '../../core/services/base.service';
import { AuthGuard } from '../../core/guards/auth.guard';
import { BsModalRef, TooltipModule } from 'ngx-bootstrap';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { MyFilterPipe } from '../../../app/my-filter.pipe';
import { CommonModule } from '@angular/common';
import { InputTrimModule } from 'ng2-trim-directive';
import { CkEditorModule } from './ckeditor.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { PageDisableComponent } from '../../modules/admin/pages/page-disable/page-disable.component';
import { SpinnerComponent } from '../../modules/admin/pages/spinner/spinner.component';
import { SpinnerService } from '../../core/services/spinner.service';
import { SafeHtmlPipe } from '../../../app/safe-html.pipe';
import { UserFromIdPipe } from '../pipes/user-extraction-from-id.pipe';
import { MappingFilterPipe } from '../../../app/mapping-filter.pipe';
import { ArraySortPipe } from '../../../app/sort-filter.pipe';
import { ChannelTypeComponent } from '../../modules/admin/pages/channel-type/channel-type.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { ColorPickerModule } from 'ngx-color-picker';
import { AudienceDetailComponent } from '../../modules/admin/pages/audience-detail/audience-detail.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';

@NgModule({
    imports: [
        BrowserModule.withServerTransition({ appId: 'myAppId' }),
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TabsModule.forRoot(),
        RouterModule,
        TooltipModule,
        InputTrimModule,
        CkEditorModule,
        NgxPaginationModule,
        NgSelectModule,
        PickerModule,
        ColorPickerModule,
        NgxDaterangepickerMd.forRoot(),
        BsDatepickerModule.forRoot(),
        TimepickerModule.forRoot(),
    ],
    declarations: [
        GalleryPopupComponent,
        MyFilterPipe,
        MappingFilterPipe,
        ArraySortPipe,
        SafeHtmlPipe,
        PageDisableComponent,
        SpinnerComponent,
        UserFromIdPipe,
        ChannelTypeComponent,
        AudienceDetailComponent,
    ],
    exports:[
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        GalleryPopupComponent,
        MyFilterPipe,
        MappingFilterPipe,
        ArraySortPipe,
        SafeHtmlPipe,
        TooltipModule,
        InputTrimModule,
        CkEditorModule,
        PageDisableComponent,
        NgxPaginationModule,
        NgxDaterangepickerMd,
        SpinnerComponent,
        UserFromIdPipe,
        ChannelTypeComponent,
        AudienceDetailComponent
    ],
    providers: [
        BaseService,
        AuthGuard,
        BsModalRef,
        SpinnerService,
    ],
    entryComponents:[
        GalleryPopupComponent
    ]
})
export class ShareModule {

}