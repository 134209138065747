import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { delay } from 'rxjs/operators';
import { AppConstants } from '../../constants/app.constants';
import { ApiConstants } from '../../constants/api.constants';
import { AttributeObj, CategoryObj, DateRange, ExcelPageObj, FacebookPageObj, GoogleFormObj, LeadStatusObj, PlguinDataObj, ProjectDetailObj, ShopifyPageObj, SourceObj, UserProjectDetail } from '../modal/project';
import { BaseService } from './base.service';
import { DatePipe } from '@angular/common';
import { Observable, Subject, Subscription, timer } from 'rxjs';
import { Router } from '@angular/router';
import { UserObj } from '../modal/user';
import * as moment from 'moment';
import { Global } from '../../shared/models/global.model';

@Injectable({
  providedIn: 'root'
})

export class ProjectService {
    attributeList: Map<string, AttributeObj>;
    userList: Map<string, UserObj>;
    leadStatusData: LeadStatusObj[];
    sourceList: Map<string, SourceObj>;
    pluginDataSubject = new Subject<boolean>();
    pluginData : Map<string, PlguinDataObj[]>;
    projectDetails:ProjectDetailObj;
    googleFormList: GoogleFormObj[];
    facebookPageList: FacebookPageObj[];
    excelPageList: ExcelPageObj[];
    shopifyPageList:ShopifyPageObj[];
    followUpTimer: Observable<number>;
    followUpSubscribe: Subscription
    maxRange:DateRange;
    userProjectDetail:UserProjectDetail = {};
    categoryObj: CategoryObj[];
    categoryListData:any;
    allUserFromMaster:any;
    constructor(
        private baseService: BaseService,
        private toastr: ToastrService,
        public router: Router,
        private datePipe: DatePipe,
        private global:Global
    ) { 
        
    }
    followUpUnsubscribe(){
        if(this.followUpSubscribe){
            this.followUpSubscribe.unsubscribe();
        }
    }

    getSyncConfiguration(body){
        return this.baseService.postRequest(ApiConstants.GET_CONFIG_SYNC,body);
    }

    getMagicLink(body){
        return this.baseService.postRequest(ApiConstants.GET_MAGIC_LINK,body);
    }

    getFixedParams() {
        return this.baseService.getRequest(ApiConstants.FETCH_FIXED_PARAM);
    }

    upsertInputParams(body: any) {
        return this.baseService.postRequestMultipart(ApiConstants.UPSERT_INPUT_PARAMS, body);
    }

    updateInputParams(body: any) {
        console.log(body);
        
        return this.baseService.postRequestMultipart(ApiConstants.UPDATE_INPUT_PARAMS, body);
    }

    deleteInputParams(body: any) {
        return this.baseService.postRequest(ApiConstants.DELETE_INPUT_PARAMS, body);
    }

    getInputParams(body: any) {
        return this.baseService.postRequest(ApiConstants.FETCH_INPUT_PARAMS, body);
    }

    fetchAttributeSource(body: any) {
        return this.baseService.postRequest(ApiConstants.FETCH_ATTRIBUTE_SOURCE_MAPPING, body, false);
    }

    getProjectDetails(body: any) {
        return this.baseService.postRequest(ApiConstants.FETCH_PROJECT_DETAIL, body, false);
    }

    getIvrStatus(body: any) {
        return this.baseService.postRequest(ApiConstants.GET_IVR_STATUS, body, false);
    }

    updateIvrStatus(body: any) {
        return this.baseService.postRequest(ApiConstants.UPDATE_IVR_STATUS, body);
    }

    upsertProject(body: any) {
        return this.baseService.postRequest(ApiConstants.UPSERT_PROJECT, body);
    }

    downloadKit(body: any) {
        return this.baseService.postRequest(ApiConstants.DOWNLOAD_INTIGRATION_KIT, body);
    }

    downloadSampleFile(body: any) {
        return this.baseService.postRequest(ApiConstants.DOWNLOAD_SAMPLE_FILE, body);
    } 
    
    uploadAudienceDataExcel(body: any) {
        return this.baseService.postRequestMultipart(ApiConstants.UPLOAD_AUDIENCE_DATA_EXCEL, body);
    }

    uploadAttributeDataCsv(body: any) {
        return this.baseService.postRequestMultipart(ApiConstants.UPLOAD_ATTRIBUTE_DATA_CSV, body);
    }

    getChildUserData(body){
        return this.baseService.postRequest(ApiConstants.GET_CHILD_USERS, body);
    }

    downloadCsvData(body: any) {
        
        return this.baseService.getRequestDownloadRpot(ApiConstants.DOWNLOAD_ATTRIBUTE_DATA_CSV, body);
    }

    downloadFailReport(body: any) {
        return this.baseService.postRequest(ApiConstants.DOWNLOAD_FAILD_REPORT, body);
    }
    confirmUploadedExcel(body: any) {
        return this.baseService.postRequest(ApiConstants.CONFIRM_UPLOADED_EXCEL, body);
    }

    fetchUserSource(body: any) {
        return this.baseService.postRequest(ApiConstants.FETCH_USER_SOURCE, body);
    }
    
    fetchUserPlugins(body: any) {
        return this.baseService.postRequest(ApiConstants.FETCH_USER_PLUGINS, body);
    }

    fetchPluginsFrom(body: any,header:any,url?) {
        //return this.baseService.postRequest(url+ApiConstants.FETCH_PLUGINS_FROM, body);
        return this.baseService.postRequestWithHeaders(url+ApiConstants.FETCH_PLUGINS_FROM, body,header);
    }

    fetchXLPluginsFrom(body: any,header:any) {        
        //return this.baseService.postRequest(url+ApiConstants.FETCH_PLUGINS_FROM, body);
        return this.baseService.postRequestWithHeaders(ApiConstants.FETCH_XL_FROM, body,header);
    }
    

    deletepluginsFormData(body: any,header:any,url?) {
        return this.baseService.postRequestWithHeaders(url+ApiConstants.DELETE_PLUGINS_FORM_DATA, body,header);
    }

    deleteExcelFormData(body: any) {
        return this.baseService.postRequestXlFromData(ApiConstants.DELETE_XL_FORM_DATA, body);
    }

    savePluginsFormData(body: any,header:any,url?) {
        return this.baseService.postRequestWithHeaders(url+ApiConstants.SAVE_PLUGINS_FORM_DATA, body,header);
    }

    fetchFormDetail(body:any)
    {
        return this.baseService.postRequest(ApiConstants.FETCH_FORM_DETAIL, body);
    }

    updateFormDetail(body:any)
    {
        return this.baseService.postRequest(ApiConstants.UPDATE_FORM_DETAIL, body);
    }


    fetchAllInputParams(body: any) {
        return this.baseService.postRequest(ApiConstants.FETCH_ALL_INPUT_PARAMS, body);
    }

    fetchFormsMapping(body: any) {
        return this.baseService.postRequest(ApiConstants.FETCH_FORMS_MAPPING, body);
    }

    saveFormsMapping(body: any) {
        return this.baseService.postRequest(ApiConstants.SAVE_FORMS_MAPPING, body);
    }

    updatePluginStatus(url:string,body: any, type) {
        return this.baseService.postRequest(url+ApiConstants.UPDATE_PLUGIN_STATUS.replace(AppConstants.REPLACE_TYPE,type), body);
    }

    updatePluginStatusByMS(url:string,body: any, header:any) {        
        return this.baseService.postRequestWithHeaders(url+ApiConstants.UPDATE_PLUGIN_STATUS_MS, body,header);
    }

    updatePluginStatusByEcomm(url:string,body: any,header) {        
        return this.baseService.postRequestWithHeaders(url+ApiConstants.UPDATE_PLUGIN_STATUS_MS, body,header);
    }

    updateEcommFormStatus(body: any, header) {
        return this.baseService.putRequest(ApiConstants.UPDATE_ECOMM_FORM_STATUS, body , header);
    }

    updateFormStatusByMS(url:string,body: any, header:any) {
    return this.baseService.postRequestWithHeaders(url+ApiConstants.UPDATE_FORM_STATUS_MS, body,header);
    }

    cancelUplodedExcel(body: any) {
        return this.baseService.postRequest(ApiConstants.CANCEL_UPLODED_EXCEL, body);
    }

    savefbFormMapping(body: any) {
        return this.baseService.postRequest(ApiConstants.SAVE_FB_FORM_MAPPING, body);
    }
    
    getFacebookPages(body: any) {
        return this.baseService.postRequest(ApiConstants.FETCH_FACEBOOK_PAGES, body);
    }

    getExcelPages(body: any) {
        return this.baseService.postRequest(ApiConstants.FETCH_EXCEL_PAGES, body);
    }

    getShopifyPages() {
        return this.baseService.getRequest(ApiConstants.FETCH_SHOPIFY_PAGES);
    }

    fetchEcommerceForms(body: any,header:any) {        
        return this.baseService.postRequestWithHeaders(ApiConstants.FETCH_ECOMMERCE_FORMS,body,header);
    }

    saveEcommerceForms(body: any,header:any) {        
        return this.baseService.postRequestWithHeaders(ApiConstants.SAVE_ECOMMERCE_FORMS,body,header);
    }

    updateEcommerceForms(body: any,header:any) {        
        return this.baseService.putRequest(ApiConstants.UPDATE_ECOMMERCE_FORMS,body,header);
    }

    delEcommerceForms(body: any,header:any) {        
        return this.baseService.deleteRequestWithHeaders(ApiConstants.DEL_ECOMMERCE_FORMS,body,header);
    }

    getFacebookFormMapping(body: any) {
        return this.baseService.postRequest(ApiConstants.FETCH_FACEBOOK_FORM_MAPPING, body);
    }

    getGoogleFormList(body: any) {
        return this.baseService.postRequest(ApiConstants.FETCH_GOOGLEFORM_LIST, body);
    }

    createGoogleForm(body: any) {
        return this.baseService.postRequest(ApiConstants.CREATE_GOOGLE_FORM, body);
    }

    getGoogleFormData(body: any) {
        return this.baseService.postRequest(ApiConstants.FETCH_GOOGLEFORMS_MAPPING, body);
    }

    saveGoogleFormsMapping(body: any) {
        return this.baseService.postRequest(ApiConstants.SAVE_GOOGLEFORMS_MAPPING, body);
    }

    deleteGoogleForm(body: any) {
        return this.baseService.postRequest(ApiConstants.DELETE_GOOGLE_FORM, body);
    }

    deleteFacebookForm(body: any) {
        return this.baseService.postRequest(ApiConstants.DELETE_FB_PAGES, body);
    }

    updateFacebookFormStatus(body: any) {
        return this.baseService.postRequest(ApiConstants.UPDATE_FB_FORM_STATUS, body);
    }

    updateExcelFormStatus(body: any) {
        return this.baseService.postRequest(ApiConstants.UPDATE_XL_FORM_STATUS, body);
    }

    updateCustomFormStatus(body: any) {
        return this.baseService.postRequest(ApiConstants.UPDATE_CUSTOM_FORM_STATUS, body);
    }

    updateGoogleFormStatus(body: any) {
        return this.baseService.postRequest(ApiConstants.UPDATE_GOOGLE_FORM_STATUS, body);
    }
    

    getMapformList(body: any) {
        return this.baseService.postRequest(ApiConstants.FETCH_FB_FORMS, body);
    }
    
    isPluginSubscribed(body: any) {
        return this.baseService.postRequest(ApiConstants.IS_PLUGIN_SUBSCRIBED, body);
    }

    saveFBAuthToken(body: any) {
        return this.baseService.postRequest(ApiConstants.SAVE_FB_AUTH_TOKEN, body);
    }

    saveFBAudeinceSegmentAuthToken(body: any) {
        return this.baseService.postRequest(ApiConstants.SAVE_FB_AUDIENCE_AUTH_TOKEN, body);
    }


    

   
    getFBAuthToken(body: any) {
        return this.baseService.postRequest(ApiConstants.FETCH_FB_AUTH_DETAILS, body);
    }

    updateLeadActiveStatus(body: any) {
        return this.baseService.postRequest(ApiConstants.UPDATE_LEAD_ACTIVE_STATUS, body);
    }
    updateLeadRepeatStatus(body: any) {
        return this.baseService.postRequest(ApiConstants.UPDATE_LEAD_REPEAT_STATUS, body);
    }

    deleteAudienceData(body: any) {
        return this.baseService.postRequest(ApiConstants.DELETE_AUDIENCE_DATA, body);
    }
    deleteBulkAudienceData(body: any,category) {
        return this.baseService.postRequest(ApiConstants.DELETE_BULK_AUDIENCE_DATA+'/'+category, body);
    }
    
    getFilters(body:any) {
        return this.baseService.postRequest(ApiConstants.FETCH_CAMPAIGN_PARAMS, body)
    }
    fetchLeadStatus(menuParams:string,body:any) {    
        return this.baseService.postRequest(ApiConstants.FETCH_USER_LEAD_ASSIGN_STATUS +'/'+menuParams, body)
    }
    upsetLeadStatus(menuParams:string,body:any) {
        return this.baseService.postRequest(ApiConstants.UPSET_USER_LEAD_ASSIGN_STATUS +'/'+menuParams, body)
    }
    deleteLeadStatus(menuParams:string,body:any) {
        return this.baseService.postRequest(ApiConstants.DELETE_USER_LEAD_ASSIGN_STATUS +'/'+menuParams, body)
    }

    registerAudienceData(menuParams:string,body: any) {
        return this.baseService.postRequest(ApiConstants.REGISTER_AUDIENCE_DATA +'/'+menuParams, body);
    }

    upsetUserFilter(body: any) {
        return this.baseService.postRequest(ApiConstants.UPSET_USER_FILTER, body);
    }
    
    listUserFilter(body: any) {
        return this.baseService.postRequest(ApiConstants.LIST_USER_FILTER, body);
    }

    deleteUserFilter(body: any) {
        return this.baseService.postRequest(ApiConstants.DELETE_USER_FILTER, body);
    }

    setAttributeData(data:Map<string, AttributeObj>){
        this.attributeList = data;
    }

    getTaxData() {
        return this.baseService.getRequest(ApiConstants.FETCH_TAX_DATA);
    }

    addInvoiceProd(body: any) {
        return this.baseService.postRequest(ApiConstants.ADD_INVOICE_PRODUCT, body);
    }

    getInvoiceProd(body: any) {
        return this.baseService.postRequest(ApiConstants.GET_INVOICE_PRODUCT, body);
    }

    insertEstimate(body: any) {
        return this.baseService.postRequest(ApiConstants.INSERT_INVOICE, body);
    }

    insertQuotation(body: any) {
        return this.baseService.postRequest(ApiConstants.INSERT_QUOTATION, body);
    }

    invoiceList(page,size,body) {
        return this.baseService.postRequest(ApiConstants.GET_INVOICE_LIST.replace(AppConstants.REPLACE_SIZE, size).replace(AppConstants.REPLACE_PAGE, page), body);
    }

    quotationList(page,size,body) {
        return this.baseService.postRequest(ApiConstants.GET_QUOTATION_LIST.replace(AppConstants.REPLACE_SIZE, size).replace(AppConstants.REPLACE_PAGE, page), body);
    }

    invoiceListView(body:any) {
        return this.baseService.postRequest(ApiConstants.VIEW_INVOICE_LIST, body);
    }

    quotationListView(body:any) {
        return this.baseService.postRequest(ApiConstants.VIEW_QUOTATION_LIST, body);
    }

    deleteInvoice(body:any) {
        return this.baseService.postRequest(ApiConstants.DELETE_INVOICE, body);
    }

    deleteQuotation(body:any) {
        return this.baseService.postRequest(ApiConstants.DELETE_QUOTATION, body);
    }

    createSellerConf(body:any) {
        return this.baseService.postRequest(ApiConstants.CREATE_SELLER_CONFIG, body);
    }

    getSellerConf(body:any) {
        return this.baseService.postRequest(ApiConstants.GET_SELLER_CONFIG, body);
    }

    updateInvoiceStatus(body:any) {
        return this.baseService.postRequest(ApiConstants.UPDATE_INVOICE_STATUS, body);
    }

    updateQuotationStatus(body:any) {
        return this.baseService.postRequest(ApiConstants.UPDATE_QUOTATION_STATUS, body);
    }

    removeInvoiceItem(body:any) {
        return this.baseService.postRequest(ApiConstants.REMOVE_INVOICE_ITEM, body);
    }

    invoiceProdData(page,size,body:any) {
        return this.baseService.postRequest(ApiConstants.GET_PROD_LIST_INVOICE.replace(AppConstants.REPLACE_SIZE, size).replace(AppConstants.REPLACE_PAGE, page), body);
    }
    
    removeInvoiceProd(body:any) {
        return this.baseService.postRequest(ApiConstants.REMOVE_INVOICE_PROD, body);
    }

    fetchRepeatLeads(body:any,category) {
        return this.baseService.postRequest(ApiConstants.FETCH_REPEAT_LEADS+'/'+category, body);
    }


    updateRepeatLeadPreferences(body:any) {
        return this.baseService.postRequest(ApiConstants.UPDATE_REPEAT_LEAD_PREFERENCE, body);
    }

    uploadProductExcel(body:any){
        return this.baseService.postRequestProductUpload(ApiConstants.ADD_INVOICE_PROD_XLS, body);
    }

    getGraphDetailInvoice(body:any){
        return this.baseService.postRequest(ApiConstants.LOAD_GRAPH_DATA_INVOICE, body);
    }

    getNotification(page,size){
        return this.baseService.getRequest(ApiConstants.GET_NOTIFICATION.replace(AppConstants.REPLACE_SIZE, size).replace(AppConstants.REPLACE_PAGE, page));
    }

    getUnreadNotification(){
        return this.baseService.getRequest(ApiConstants.GET_UNREAD_NOTIFICATION);
    }

    viewNotification(body:any){
        return this.baseService.postRequest(ApiConstants.VIEW_NOTIFICATION,body);
    }

    createCategory(body:any) {
        return this.baseService.postRequest(ApiConstants.CREATE_CATEGORY, body)
    }

    categoryList(body){
        return this.baseService.postRequest(ApiConstants.FETCH_CATEGORY, {})
    }

    audienceCall(body){
        return this.baseService.postRequest(ApiConstants.AUDIENCE_CALL, body)
    }

    tatSave(body){
        return this.baseService.postRequest(ApiConstants.TAT_SAVE, body)
    }

    verifyDataPushUrl(body){
        return this.baseService.postRequest(ApiConstants.VERIFY_URL,body)
    }

    pushDataSubmit(body){
        return this.baseService.postRequest(ApiConstants.PUST_DATA_STORE,body)
    }

    tatList(body){
        return this.baseService.getRequest(ApiConstants.TAT_LIST+'/'+body)
    }

    pushDataList(body){
        return this.baseService.getRequest(ApiConstants.PUST_DATA_LIST+'/'+body)
    }

    removePushdata(body){
        return this.baseService.postRequest(ApiConstants.REMOVE_DATA_LIST+'/'+body,{})
    }

    deleteTat(body){
        return this.baseService.postRequest(ApiConstants.REMOVE_TAT+'/'+body,{});
    }

    editTat(body){
        return this.baseService.getRequest(ApiConstants.EDIT_TAT+'/'+body);
    }


    call_to_ivr(body){
        return this.baseService.postRequest(ApiConstants.CALL_TO_IVR,body);
    }

    getDetailsByPinCode(body){
        return this.baseService.getRequest("https://apiv2.shiprocket.in/v1/external/open/postcode/details?postcode="+body);
    }

   async fetchCategory(body?,reload?) {
    if(reload == 'reload'){
        this.categoryListData = undefined
    }
        if(this.categoryListData == undefined){
            let catData = await this.categoryList({}).pipe().toPromise();
            if(catData.status){
                this.categoryListData = catData.data
                return this.categoryListData;
            }
        } else{
            return this.categoryListData;
        }
    }

    deleteCategory(body:any) {
        return this.baseService.postRequest(ApiConstants.DELETE_CATEGORY, body)
    }

    async fetchSourceList() {      
        var requestData = await this.fetchAttributeSource({}).pipe(delay(200)).toPromise();
        this.sourceList = requestData.data.source;
        return this.sourceList;
    }

    async fetchChannelList() {      
        var requestData = await this.fetchAttributeSource({}).pipe(delay(200)).toPromise();
        let subsChannel = requestData.data.subcribeChannel;
        return subsChannel;
    }
    
    async fetchAttributeList() {        
        if(this.attributeList == undefined){
            var requestData = await this.fetchAttributeSource({}).pipe(delay(200)).toPromise();
            this.attributeList = requestData.data.filter;    
            this.sourceList = requestData.data.source;
            this.leadStatusData = requestData.data.leadStatus;
            this.userList = requestData.data.users
            this.setLeadStatusData(this.leadStatusData)
            return requestData.status;
        } else {
            return true;
        };
    }

    filterAttributeList(plugin? : string[],getDynamicAttr?){
        if(plugin == undefined){
            plugin = []; 
        };
        var keyList : string[] = [];
        var attributes : AttributeObj[] = [];
        keyList = Object.keys(this.attributeList);
        var map = new Map<number, AttributeObj>();
        keyList.forEach( list => {
            if(this.attributeList[list] != undefined){
                    if(this.attributeList[list]){
                        if(this.attributeList[list].hideUserRole){
                            let userRoleToArray = this.attributeList[list].hideUserRole.split(',');
                            userRoleToArray = userRoleToArray.filter(p=>{
                                return p != null && p != ""
                            });
                         
                            if(this.projectDetails.userRoleId != 1 && userRoleToArray.includes(this.projectDetails.userRoleId.toString())){
                                delete this.attributeList[list];
                            }
                        }
                    }
                    if(this.attributeList[list]){
                        map.set(this.attributeList[list].id,this.attributeList[list]);
                    }
            }
        });
        
        attributes = Array.from(map.values());        
        return attributes;
    }

    getSourceList(){
          var keyList : string[] = [];
        var attributes : SourceObj[] = [];
        keyList = Object.keys(this.sourceList);
        var map = new Map<string, SourceObj>();
        keyList.forEach( list => {
            if(this.sourceList[list] != undefined){
                    map.set(list,this.sourceList[list]);
            }
        });
        
        attributes = Array.from(map.values());
        
        return attributes;
       
    }
    
    setLeadStatusData(data:LeadStatusObj[]){
        this.leadStatusData = data;
    }
     
    setCategoryData(data:CategoryObj[]){
        this.categoryObj = data;
    }

    async getLeadStatusData(parentMenu,reload?){
         if(reload == 'reload'){
            this.leadStatusData = undefined;
         }
        if(this.leadStatusData == undefined){
            var requestData = await this.fetchLeadStatus(parentMenu,{}).pipe(delay(200)).toPromise();
            this.leadStatusData = requestData.data;
        };
        return this.leadStatusData;
    }

    async getUserSourceData(reload?){
        if(reload == 'reload')
        {
            this.sourceList = undefined; 
            this.allUserFromMaster = undefined;
        }
        if(this.sourceList == undefined){
            var requestData = await this.fetchAttributeSource({}).pipe(delay(200)).toPromise();
            this.sourceList = requestData.data.source;
            this.attributeList = requestData.data.filter;
            this.leadStatusData = requestData.data.leadStatus;
            this.userList = requestData.data.users;
            this.maxRange = requestData.data.userProject.maxRange
        };
        return true;
    }

    async getUserFromMaster(){
        if(this.allUserFromMaster){
            return this.allUserFromMaster
        }else{
        var requestData = await this.fetchAttributeSource({}).pipe(delay(200)).toPromise();
        this.allUserFromMaster = requestData.data.users
        return requestData.data.users;
        }
    }


    async getMaxRange(){                
        if(this.maxRange != undefined){            
            return this.maxRange
        } else if(this.maxRange == undefined) {
        var requestData = await this.fetchAttributeSource({}).pipe(delay(200)).toPromise();
        if(requestData.data.userProject.maxRange){
            this.maxRange = requestData.data.userProject.maxRange
            return this.maxRange
        }
    }
    }

    async userProjecDetails(){
        var requestData = await this.fetchAttributeSource({}).pipe(delay(200)).toPromise();
        this.userProjectDetail.mauLimit = requestData.data.userProject.mauLimit;
        this.userProjectDetail.endDate = requestData.data.userPlan.endDate;
        this.userProjectDetail.maxUserLimit = requestData.data.userProject.maxUserLimit
        return this.userProjectDetail;
    }

    async getPluginData(reload?){
        if(reload == 'reload')
        {
            this.pluginData = undefined;
        }
        if(this.pluginData == undefined){
            var requestData = await this.fetchUserPlugins({}).pipe(delay(200)).toPromise();
            if(requestData.status){
                this.pluginData = requestData.data;
            }
        };
        return this.pluginData;
    }

    async getUserProjectDetails(){
        if(this.projectDetails == undefined){
            var requestData = await this.getProjectDetails({}).pipe(delay(200)).toPromise();
            if(requestData.status){
                this.projectDetails = requestData.data;
            }
        };
        return this.projectDetails;
    }

    followUpNotifications(body:any) {
        return this.baseService.getRequest(ApiConstants.FOLLOW_UP_REPORT);
    }

   newLeadNotificationsReport(body:any) {
        return this.baseService.getRequest(ApiConstants.NEW_LEAD_REPORT);
    }




    getNewLeadReport(){
        this.newLeadNotificationsReport({}).subscribe( data => {
            if(data.status)
            {
            var content = ''
            
            let reminderPopup = data.data;
            if(reminderPopup != undefined && reminderPopup.length>0){
                for (let notification in reminderPopup) {
                   this.sendPushMsg(notification["message"], notification["json"]);
                  }
             }
            }
           
        });
    }

  
    getLeadFollowReport(){
        this.followUpNotifications({}).subscribe( data => {
            if(data.status){
            var content = ''
            
            let reminderPopup = data.data;
            if(reminderPopup != undefined && reminderPopup.length>0){
                for (let notification in reminderPopup ) {
                   this.sendPushMsg(notification["message"], notification["json"]);
                  }
             }
            }
           
        });
    }


    showToaster(stageName,time,id) {
        this.toastr.success('Your '+stageName+' is schedule at '+time+' <u>click here</u><i class="d-none">'+id+'</i>', null,{enableHtml:true,timeOut:600000,closeButton:true,extendedTimeOut:600000}).onTap.pipe()
          .subscribe(() => {
                this.router.navigate(['/admin/audience/view-audience/audience/'+id],{queryParams:{notification_view:true}});
          });
    }

    // setFollowUpTimer(){
    //     if(this.followUpTimer == undefined && this.projectDetails != undefined && this.projectDetails.userRoleId != 2 && this.router.routerState.snapshot.url.indexOf('/admin/audience/view-audience/audience') < 0){
    //         if(this.router.routerState.snapshot.url.indexOf('/admin/audience/view-audience/audience') == 0){
    //             this.followUpTimer = timer(AppConstants.FOLLOWUP_CHECK_TIMING * 60000, AppConstants.FOLLOWUP_CHECK_TIMING * 60000);
    //         } else {
    //             this.followUpTimer = timer(5000, AppConstants.FOLLOWUP_CHECK_TIMING * 60000);
    //         }
    //         this.followUpSubscribe = this.followUpTimer.subscribe(currTime => {
    //             this.getLeadFollowReport();
    //         });
    //     }
    // }


     setNotificationTimer(){
        if(this.followUpTimer == undefined && this.projectDetails != undefined){
            this.followUpTimer = timer(AppConstants.FOLLOWUP_CHECK_TIMING * 1000, AppConstants.FOLLOWUP_CHECK_TIMING * 12000);
            this.followUpSubscribe = this.followUpTimer.subscribe(currTime => {
                this.getNotificationlistSum();
            });            
        }
    }



    getNotificationlistSum(){
            this.getUnreadNotification().subscribe( data => {
                if(data.status){
                this.global.notificationSum = data.data;
                }

            });
    }
    
    checkTime(time){
        //var diff = Math.abs(new Date(time).getTime() - new Date().getTime());
        let systemDate:any = moment(time+".000+05:30",'H:mm:ss');
        let currentDate:any = moment(new Date(),'H:mm:ss');
        var diff = systemDate.diff(currentDate,'minutes');
        var minutes = Math.floor((diff/1000)/60);
        return minutes <= AppConstants.FOLLOWUP_TIMING;
    }

    sendPushMsg(msg,link){
        var options : NotificationOptions = {
            body: msg,
            data:link
        };
        if(window.navigator && navigator.serviceWorker) {
            navigator.serviceWorker.getRegistrations().then(function(registrations) {
                for(let registration of registrations) {
                    registration.showNotification('New follow up coming', options);
                };
            });
        }
    }

    async getGoogleFormsData(reload?){
        if(reload == 'reload')
        {
            this.googleFormList = undefined
        }
        if(this.googleFormList == undefined){
            var requestData = await this.getGoogleFormList({}).pipe(delay(200)).toPromise();
            this.googleFormList = requestData.data;
        };
        return this.googleFormList;
    }

    async getFacebookPagesData(reload?){            
        if(reload == 'reload')
        {
            this.facebookPageList = undefined;            
        }
        if(this.facebookPageList == undefined){
            var requestData = await this.getFacebookPages({}).pipe(delay(200)).toPromise();
            this.facebookPageList = requestData.data;            
        };
        return this.facebookPageList;
    }

    async getExcelPagesData(reload?){            
        if(reload == 'reload')
        {
            this.excelPageList = undefined;            
        }
        if(this.excelPageList == undefined){
            var requestData = await this.getExcelPages({}).pipe(delay(200)).toPromise();
            this.excelPageList = requestData.data;            
        };
        return this.excelPageList;
    }

    async getShopifyPagesData(reload?){            
        if(reload == 'reload')
        {
            this.shopifyPageList = undefined;            
        }
        if(this.shopifyPageList == undefined){
            var requestData = await this.getShopifyPages().pipe(delay(200)).toPromise();
            this.shopifyPageList = requestData.data;            
        };
        return this.shopifyPageList;
    }

}
