import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { ApiConstants } from '../../../app/constants/api.constants';
import { environment } from '../../../environments/environment';
import { AndroidCampaignObj, AndroidPushTemplateObj, EmailTemplateObj, InappTemplateObj, IosCampaignObj, IosPushTemplateObj, JourneyTemplateObj, SmsTemplateObj, WebPushTemplateObj, WhatsappTemplateObj } from '../modal/campaign';
import { LeadStatusObj } from '../modal/project';
import { BaseService } from './base.service';
import { LeadStatus, PushTemplate } from './cache.modal';
import { CampaignService } from './campaign.service';
import { ProjectService } from './project.service';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})



export class CacheService {
  pushServiceUrl:any = environment.pushServiceUrl;
  // iosPushUrl:any = environment.iosPushUrl;
  // webPushUrl:any = environment.webPushUrl;
  whatsappApiUrl:any = environment.whatsappApiUrl;
  stageTypeList: LeadStatusObj[];
  leadStatusSubject: any = new Subject<LeadStatus[]>();
  androidListSubject : any = new Subject<any[]>();
  wabaListSubject : any = new Subject<any[]>();
  iosListSubject : any = new Subject<any[]>();
  webListSubject : any = new Subject<any[]>();
  smsListSubject : any = new Subject<any[]>();
  emailListSubject : any = new Subject<any[]>();
  whatsappListSubject : any = new Subject<any[]>();
  androidPushTemplateList: AndroidPushTemplateObj[] = [];
  iosPushTemplateList: IosPushTemplateObj[] = [];
  webPushTemplateList: WebPushTemplateObj[] = [];
  smsTemplateList: SmsTemplateObj[] = []; 
  emailTemplateList: EmailTemplateObj[] = []; 
  whatsappTemplateList: WhatsappTemplateObj[] = []; 
  androidCampaignList:AndroidCampaignObj[] = [];
  androidCampListSubject:any = new Subject<any[]>();
  iosCampaignList:IosCampaignObj[] = [];
  iosCampSubject:any = new Subject<any[]>();
  webCampaignList:WebPushTemplateObj[] = [];
  webCampSubject:any = new Subject<any[]>();
  smsCampaignList:SmsTemplateObj[] = [];
  smsCampSubject:any = new Subject<any[]>();
  emailCampaignList:EmailTemplateObj[] = [];
  emailCampSubject:any = new Subject<any[]>();
  whatsappCampaignList:WhatsappTemplateObj[] = [];
  whatsappCampSubject:any = new Subject<any[]>();
  inAppCampaignList:InappTemplateObj[] = [];
  inAppCampSubject:any = new Subject<any[]>();
  journeyCampaignList:JourneyTemplateObj[] = [];
  journeyCampSubject:any = new Subject<any[]>();
  queryParamsFromMenu:any;
  wabaTemplateList:any;
  constructor(
    private projectService: ProjectService,
    private campaignService:CampaignService,
    private toastr: ToastrService,
    private baseService: BaseService,
    private toaster:ToastrService,
    private route:ActivatedRoute,
    private router:Router
  ) {
    // this.syncLeadStatus();
  }
  

  syncTemplates(url,type,reload?){
    if(type == 'push')
    {
      if(reload == 'reload'){        
        this.androidPushTemplateList = []
      }
        if(this.androidPushTemplateList != undefined && this.androidPushTemplateList.length > 0){
          this.androidListSubject.next(this.androidPushTemplateList);
        }else{
          this.campaignService.templateList({},url).subscribe(data=>{
            if (data.status) {
              this.androidPushTemplateList = data.data;
              this.androidListSubject.next(this.androidPushTemplateList)
            }
          })
        }
      } else if(type == 'ios'){
        if(reload == 'reload'){        
          this.iosPushTemplateList = []
        }
        if(this.iosPushTemplateList != undefined && this.iosPushTemplateList.length > 0){
          this.iosListSubject.next(this.iosPushTemplateList);
        }else{
          this.campaignService.templateList({},url).subscribe(data=>{
            if (data.status) {
              this.iosPushTemplateList = data.data;
              this.iosListSubject.next(this.iosPushTemplateList)
            }
          })
        }
      } else if(type == 'web'){
        if(reload == 'reload'){        
          this.webPushTemplateList = []
        }
        if(this.webPushTemplateList != undefined && this.webPushTemplateList.length > 0){
          this.webListSubject.next(this.webPushTemplateList);
        }else{
          this.campaignService.templateList({},url).subscribe(data=>{
            if (data.status) {
              this.webPushTemplateList = data.data;
              this.webListSubject.next(this.webPushTemplateList)
            }
          })
        }
      } else if(type == 'sms'){
        if(reload == 'reload'){        
          this.smsTemplateList = []
        }
        if(this.smsTemplateList != undefined && this.smsTemplateList.length > 0){
          this.smsListSubject.next(this.smsTemplateList);
        }else{
          this.campaignService.templateList({},url).subscribe(data=>{
            if (data.status) {
              this.smsTemplateList = data.data;
              this.smsListSubject.next(this.smsTemplateList)
            }
          })
        }
      } else if(type == 'email'){
        if(reload == 'reload'){        
          this.emailTemplateList = []
        }
        if(this.emailTemplateList != undefined && this.emailTemplateList.length > 0){
          this.emailListSubject.next(this.emailTemplateList);
        }else{
          this.campaignService.templateList({},url).subscribe(data=>{
            if (data.status) {
              this.emailTemplateList = data.data;
              this.emailListSubject.next(this.emailTemplateList)
            }
          })
        }
      } else if(type == 'whatsapp'){
        if(reload == 'reload'){        
          this.whatsappTemplateList = []
        }
        if(this.whatsappTemplateList != undefined && this.whatsappTemplateList.length > 0){
          this.whatsappListSubject.next(this.whatsappTemplateList);
        }else{
          this.campaignService.templateList({},url).subscribe(data=>{
            if (data.status) {
              this.whatsappTemplateList = data.data;
              this.whatsappListSubject.next(this.whatsappTemplateList)
            }
          })
        }
      }
  }


  getWabaSyncTmpltList(url,key,ismigrate){
    let postData = {key:key,isMigrated:''}
    if(ismigrate){
      postData.isMigrated = ismigrate
    } else{
      delete postData.isMigrated
    }
    this.baseService.postRequest(url+'/fetch-templates',postData).subscribe(data=>{
      this.wabaTemplateList = data;
      if(this.wabaTemplateList){
        this.wabaListSubject.next(this.wabaTemplateList);
      }
    })
  }


  getCampaignsList(body:any,type?,url?,reload?) {
    
    if(type == 'android'){
      if(reload == 'reload'){
        this.androidCampaignList = [];
      }
      if(this.androidCampaignList != undefined && this.androidCampaignList.length >0){
        this.androidCampListSubject.next(this.androidCampaignList);
      }
      else
      {
      this.baseService.postRequest(url+ApiConstants.CAMPAIGN_LIST, body).subscribe(data=>{
        if(data.status){
        this.androidCampaignList = data.data;
        this.androidCampListSubject.next(this.androidCampaignList);
        } else{
          this.toaster.error(data.error_msgs);
        }
      })
    }
    }

    if(type == 'ios'){
      if(reload == 'reload'){
        this.iosCampaignList = [];
      }
      if(this.iosCampaignList != undefined && this.iosCampaignList.length >0){
        this.iosCampSubject.next(this.iosCampaignList);
      } else{
      this.baseService.postRequest(url+ApiConstants.CAMPAIGN_LIST, body).subscribe(data=>{
        if(data.status){
        this.iosCampaignList = data.data;
        this.iosCampSubject.next(this.iosCampaignList);
        } else{
          this.toaster.error(data.error_msgs);
        }
      })
      }
    }

    if(type == 'web'){
      if(reload == 'reload'){
        this.webCampaignList = [];
      }
      if(this.webCampaignList != undefined && this.webCampaignList.length >0){
        this.webCampSubject.next(this.webCampaignList);
      } else{
      this.baseService.postRequest(url+ApiConstants.CAMPAIGN_LIST, body).subscribe(data=>{
        if(data.status){
        this.webCampaignList = data.data;
        this.webCampSubject.next(this.webCampaignList);
        } else{
          this.toaster.error(data.error_msgs);
        }
      })
      }
    }

    if(type == 'sms'){
      if(reload == 'reload'){
        this.smsCampaignList = [];
      }
      if(this.smsCampaignList != undefined && this.smsCampaignList.length >0){
        this.smsCampSubject.next(this.smsCampaignList);
      } else{
      this.baseService.postRequest(url+ApiConstants.CAMPAIGN_LIST, body).subscribe(data=>{
        if(data.status){
        this.smsCampaignList = data.data;
        this.smsCampSubject.next(this.smsCampaignList);
        }else{
          this.toaster.error(data.error_msgs);
        }
      })
      }
    }

    if(type == 'email'){

      if(reload == 'reload'){
        this.emailCampaignList = [];
      }
      if(this.emailCampaignList != undefined && this.emailCampaignList.length >0){
        this.emailCampSubject.next(this.emailCampaignList);
      } else{
      this.baseService.postRequest(url+ApiConstants.CAMPAIGN_LIST, body).subscribe(data=>{
        if(data.status){
        this.emailCampaignList = data.data;
        this.emailCampSubject.next(this.emailCampaignList);
        } else{
          this.toaster.error(data.error_msgs)
        }
      })
      }
    }

    if(type == 'whatsapp'){
      if(reload == 'reload'){
        this.whatsappCampaignList = [];
      }
      if(this.whatsappCampaignList != undefined && this.whatsappCampaignList.length >0){
        this.whatsappCampSubject.next(this.whatsappCampaignList);
      } else{
      this.baseService.postRequest(url+ApiConstants.CAMPAIGN_LIST, body).subscribe(data=>{
        if(data.status){
        this.whatsappCampaignList = data.data;
        this.whatsappCampSubject.next(this.whatsappCampaignList);
        }else{
          this.toaster.error(data.error_msgs);
        }
      })
      }
    }

    if(type == 'inapp'){
      if(reload == 'reload'){
        this.inAppCampaignList = [];
      }
      if(this.inAppCampaignList != undefined && this.inAppCampaignList.length >0){
        this.inAppCampSubject.next(this.inAppCampaignList);
      } else{
      this.baseService.postRequest(url+ApiConstants.CAMPAIGN_LIST, body).subscribe(data=>{
        if(data.status){
        this.inAppCampaignList = data.data;
        this.inAppCampSubject.next(this.inAppCampaignList);
        }else{
          this.toaster.error(data.error_msgs);
        }
      })
      }
    }

    if(type == 'journey'){
      if(reload == 'reload'){
        this.journeyCampaignList = [];
      }
      if(this.journeyCampaignList != undefined && this.journeyCampaignList.length >0){
        this.journeyCampSubject.next(this.journeyCampaignList);
      } else{
      this.baseService.postRequest(url+ApiConstants.CAMPAIGN_LIST, body).subscribe(data=>{
        if(data.status){
        this.journeyCampaignList = data.data;
        this.journeyCampSubject.next(this.journeyCampaignList);
        }else{
          this.toaster.error(data.error_msgs);
        }
      })
      }
    }

  }


  sendWabaTemplateData():Observable<any>{
    return this.wabaListSubject.asObservable();
  }

  getAndroidTemplateData(): Observable<any> {
    return this.androidListSubject.asObservable();
  }

  getIosTemplateData(): Observable<any> {
    return this.iosListSubject.asObservable();
  }

  getWebTemplateData(): Observable<any> {
    return this.webListSubject.asObservable();
  }

  getSmsTemplateData(): Observable<any> {
    return this.smsListSubject.asObservable();
  }

  getEmailTemplateData(): Observable<any> {
    return this.emailListSubject.asObservable();
  }

  getWhatsappTemplateData(): Observable<any> {
    return this.whatsappListSubject.asObservable();
  }

  getAndroidCampList(): Observable<any> {
    return this.androidCampListSubject.asObservable();
  }

  getIosCampList(): Observable<any> {
    return this.iosCampSubject.asObservable();
  }

  getWebCampList(): Observable<any> {
    return this.webCampSubject.asObservable();
  }

  getSmsCampList(): Observable<any> {
    return this.smsCampSubject.asObservable();
  }

  getEmailCampList(): Observable<any> {
    return this.emailCampSubject.asObservable();
  }

  getWhatsappCampList(): Observable<any> {
    return this.whatsappCampSubject.asObservable();
  }

  getInappCampList(): Observable<any> {
    return this.inAppCampSubject.asObservable();
  }

  getJourneyCampList(): Observable<any> {
    return this.journeyCampSubject.asObservable();
  }


  //Need to help sunil
  syncLeadStatus() {    
    if (this.stageTypeList != undefined && this.stageTypeList.length > 0) {
      this.leadStatusSubject.next(this.stageTypeList);
    } else {
      this.projectService.fetchLeadStatus('',{}).subscribe(data => {
        if (data.status) {
          this.stageTypeList = data.data;
          this.leadStatusSubject.next(this.stageTypeList);    
        } else {
          this.toastr.error(data.error_msgs, 'Error');
        }
      })    
    }
  }

  getLeadData(): Observable<any> {
    return this.leadStatusSubject.asObservable();
  }

  updateStatusList()
  {
    if (this.stageTypeList != undefined)
    {
      this.stageTypeList = undefined;
    }
  }




}
