import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../../../app/core/guards/auth.guard";
import { AuthLayoutComponent } from "../admin/components/auth-layout/auth-layout.component";
import { CallAssistLocationComponent } from "./call-assist-location/call-assist-location.component";
import { CallassistMissRecordingDetailsComponent } from "./callassist-miss-recording-details/callassist-miss-recording-details.component";
import { CallassistDashboardComponent } from "./callassist.dashboard.component";
import { CallassistDetailComponent } from "./callassist.detail.component";

const routes: Routes = [
    {path:'admin',component:AuthLayoutComponent, canActivate:[AuthGuard],
     children:[
        {path:'call-assist', component: CallassistDashboardComponent},
        { path: 'call-assist-detail', component: CallassistDetailComponent},
        {path:'call-assist-location',component:CallAssistLocationComponent},
        {path:'missed-recordings',component:CallassistMissRecordingDetailsComponent},
        

    ]}
]



@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})

export class CallassistRouting {
    
}