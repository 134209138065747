import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Global } from '../../../../shared/models/global.model';
import { noWhitespaceValidator } from '../../../../shared/models/no-whitespace-validator';
import { UserService } from '../../../../core/services/user.service';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { AppConstants } from '../../../../constants/app.constants';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  form: FormGroup;
  addForm: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    public global: Global,
    private toastr: ToastrService,
    private router: Router,
    private userservice: UserService,
    private authenticationService: AuthenticationService
  ) {

  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      oldPassword: ['', Validators.compose([Validators.required, noWhitespaceValidator, Validators.minLength(3), Validators.maxLength(32)])],
      newPassword: ['', Validators.compose([Validators.required, noWhitespaceValidator, Validators.minLength(3), Validators.maxLength(32)])],
      confirmPassword: ['', Validators.compose([Validators.required, noWhitespaceValidator, Validators.minLength(3), Validators.maxLength(32)])],
    });
  }
  get af() { return this.form.controls; }

  changePassword() {
    if (this.form.invalid) {
      this.toastr.error(this.global.MessageRequired); this.global.validateAllFormFields(this.form);
      return;
    }

    let message = this.checkPasswordValidity(this.af.newPassword.value);
        if(message){
            this.toastr.error(message);
            return;
        }

    this.userservice.changePassword({ oldPassword: this.af.oldPassword.value, password: this.af.newPassword.value, confirmPassword: this.af.confirmPassword.value }).subscribe(
      data => {
        if (data.status) {
          this.toastr.success(data.data);
          this.form.reset();

          if (this.authenticationService.getUserData().accountCreated) {
            this.router.navigate(['/admin/'+this.authenticationService.getLandingPage()]);
          } else {
            this.router.navigate(['/admin/edit-account']);
          }
        }
        else {
          this.toastr.error(data.error_msgs, 'Error');
          
        }
      });
  }

  checkPasswordValidity = (value) => {
        
    const combinedRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}[\]|\\:;"'<>,.?/_₹]).{8,20}$/;
  
    if (!combinedRegex.test(value)) {
        return "Passwords must have 1 digit, 1 uppercase, 1 lowercase, and a special character. The length should be between 8-20 characters.";
    }
    return null;
  }

}
