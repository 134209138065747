<main>
  <section class="inner-page">
    <div class="inner-page-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <h4>
              <a class="back-link" (click)="back()"><img src="assets/images/angle-left.svg"></a>
              Channel Configuration
            </h4>
          </div>
        </div>
      </div>
    </div>

    <div class="inner-page-body configuration">
      <div class="container-fluid">

        <tabset #pageTabs>

          <tab heading="Push Notifications" id="push" (selectTab)="changeTab($event)">
            <div class="error_msg_" *ngIf="emailErrorMsg != false">{{emailErrorMsg}}</div>
            <div class="row">
              <div class="col-md-12">
                <div class="push-notifications-tab">

                  <tabset>

                    <tab heading="Android App">
                      <div class="card">
                        <div class="card-body">
                          <form [formGroup]="androidForm" (ngSubmit)="saveAndroidApp()">
                            <div class="row">
                              <div class="col-md-12 mb-3">
                                <div class="text-right">
                                  <a class="integration-link" href="{{global.StaticUrl}}integration_guide/android_integration_guide.pdf" target="_blank">Firebase Android integration guide</a>
                                </div>
                              </div>
                              <div class="col-md-12">
                                <div class="form-group row">
                                  <label class="col-md-3 control-label" for="serverKey">Server Key
                                  </label>
                                  <div class="col-md-9">
                                    <input class="form-control" accept=".json" type="file" (change)="uploadDefaultValues($event)" name="json_file_name" formControlName="json_file_name">
                                    <input type="hidden" name="file" formControlName="file" /> 
                                </div>

                                </div>
                              </div>
                                <div class="col-md-12 text-right d-flex justify-content-between">
                                    <div>
                                        <button *ngIf="userRole != 1 && !isAndroidAppReset" type="button" class="btn btn-border mr-3" (click)="resetForm(androidForm, 'android')">Create Own Configuration</button>
                                        <button *ngIf="userRole != 1 && isAndroidAppReset" type="button" class="btn btn-border mr-3" (click)="resetAndroidPush();" >Reset Configuration</button>
                                    </div>
                                    <div><div *ngIf="userRole == 1 || (userRole != 1 && isAndroidAppReset)">
                                        <button type="button" class="btn btn-border mr-3" (click)="openModalValidation(androidForm, android)"> Test push notification</button>
                                        <button *ngIf="androidApp.id==undefined" type="submit" class="btn btn-dark mr-3">Save</button>
                                        <button *ngIf="androidApp.id!=undefined" type="submit" class="btn btn-border">Update</button>
                                    </div></div>
                                </div>
                            </div>
                          </form>
                        </div>
                      </div>

                      <div class="row mt-3" *ngIf="false">
                        <div class="col-md-12">
                          <div class="android-ip">
                            <p class="mb-3">
                              <strong>Optional step:</strong> You can add the following IP addresses to your GCM whitelist for additional
                              security.
                            </p>
                            <ul>
                              <li>34.345.265.98</li>
                              <li>34.345.265.98</li>
                              <li>34.345.265.98</li>
                              <li>34.345.265.98</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </tab>

                    <tab heading="IOS App">
                      <div class="card">
                        <div class="card-body">
                          <form [formGroup]="iosForm" (ngSubmit)="saveIosApp()">
                            <div class="row">
                              <div class="col-md-12 mb-3">
                                <div class="text-right">
                                  <a class="integration-link" href="{{global.StaticUrl}}integration_guide/ios_integration_guide.pdf" target="_blank">Firebase iOS integration guide</a>
                                </div>
                              </div>
                              <div class="col-md-12">
                                <div class="form-group row">
                                  <label class="col-md-3 control-label" for="serverKeyIOS">Server Key
                                  </label>
                                  <div class="col-md-9">
                                    <input class="form-control" accept=".json" type="file" (change)="uploadIosValues($event)" name="json_file_name" formControlName="json_file_name">
                                    <input type="hidden" name="file" formControlName="file" /> 
                                </div>
                                </div>
                              </div>
                                <div class="col-md-12 text-right d-flex justify-content-between">
                                    <div>
                                        <button *ngIf="userRole != 1 && !isIOSAppReset" type="button" class="btn btn-border mr-3" (click)="resetForm(iosForm, 'ios')">Create Own Configuration</button>
                                        <button *ngIf="userRole != 1 && isIOSAppReset" type="button" class="btn btn-border mr-3" (click)="resetIosApp();" >Reset Configuration</button>
                                    </div>
                                    <div><div *ngIf="userRole == 1 || (userRole != 1 && isIOSAppReset)">
                                        <button type="button" class="btn btn-border mr-3" (click)="openModalValidation(iosForm, ios)"> Test push notification</button>
                                        <button *ngIf="iosApp.id==undefined" type="submit" class="btn btn-dark mr-3">Save</button>
                                        <button *ngIf="iosApp.id!=undefined" type="submit" class="btn btn-border">Update</button>
                                    </div></div>
                                </div>
                            </div>
                          </form>
                        </div>
                      </div>

                      <div class="row mt-3" *ngIf="false">
                        <div class="col-md-12">
                          <div class="android-ip">
                            <p class="mb-3">
                              <strong>Optional step:</strong> You can add the following IP addresses to your GCM whitelist for additional
                              security.
                            </p>
                            <ul>
                              <li>34.345.265.98</li>
                              <li>34.345.265.98</li>
                              <li>34.345.265.98</li>
                              <li>34.345.265.98</li>
                            </ul>
                          </div>
                        </div>
                      </div>

                    </tab>
               
                    <tab heading="Web">
                      <div class="card mb-3">
                        <div class="card-body">
                          <form [formGroup]="webForm" (ngSubmit)="saveWebApp()">

                            <div class="row" *ngIf="false">
                              <div class="col-md-12">
                                <div class="row text-right">
                                  <div class="col-md-12">
                                  
                                    <div class="custom-control custom-switch swich-button">
                                      <span class="bm" [ngClass]="{pink: !yearly}">Disabled</span>
                                      <input type="checkbox" [(ngModel)]="yearly" [ngModelOptions]="{standalone: true}" class="custom-control-input" id="customSwitches">
                                      <label class="custom-control-label" for="customSwitches"></label>
                                      <span class="by" [ngClass]="{pink: yearly}">Enable</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="row" *ngIf="false">
                              <div class="col-md-6">
                                <h4 class="configuration-heading">STEP 1</h4>
                              </div>

                              <div class="col-md-6">

                              </div>
                            </div>

                            <div class="row">
                              <div class="col-md-12 mb-3">
                                <div class="text-right">
                                  <a class="integration-link" href="{{global.StaticUrl}}integration_guide/web_integration_guide.pdf" target="_blank">Firebase Web integration guide</a>
                                </div>
                              </div>

                              <div class="col-md-12">
                                <div class="form-group row">
                                  <label class="col-md-3 control-label" for="serverKeyWeb">Server Key</label>
                                  <div class="col-md-9">
                                    <input class="form-control" accept=".json" type="file" (change)="uploadDeWebValues($event)" name="json_file_name" formControlName="json_file_name">
                                    <input type="hidden" name="file" formControlName="file" /> 
                                </div>

                                </div>
                              </div>
                                <div class="col-md-12 text-right d-flex justify-content-between">
                                    <div>
                                        <button *ngIf="userRole != 1 && !isWebAppReset" type="button" class="btn btn-border mr-3" (click)="resetForm(webForm, 'web')">Create Own Configuration</button>
                                        <button *ngIf="userRole != 1 && isWebAppReset" type="button" class="btn btn-border mr-3" (click)="resetWebPush()" >Reset Configuration</button>
                                    </div>
                                    <div><div *ngIf="userRole == 1 || (userRole != 1 && isWebAppReset)">
                                        <button type="button" class="btn btn-border mr-3" (click)="openModalValidation(webForm, web)"> Test push notification</button>
                                        <button *ngIf="webApp.id==undefined" type="submit" class="btn btn-dark mr-3">Save</button>
                                        <button *ngIf="webApp.id!=undefined" type="submit" class="btn btn-border">Update</button>
                                    </div></div>
                                </div>
                            </div>

                            <div class="row mt-2" *ngIf="false">
                              <div class="col-md-12">
                                <div class="android-ip">
                                  <p class="mb-3">
                                    <strong>Optional step:</strong> You can add the following IP addresses to your GCM whitelist
                                    for additional security.</p>
                                  <ul>
                                    <li>34.345.265.98</li>
                                    <li>34.345.265.98</li>
                                    <li>34.345.265.98</li>
                                    <li>34.345.265.98</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>

                      <div class="card mb-3" *ngIf="false">
                        <div class="card-body">
                          <form [formGroup]="webForm" (ngSubmit)="saveWebApp()">
                            <div class="row">
                              <div class="col-md-6">
                                <h4 class="configuration-heading mb-0">STEP 2</h4>
                              </div>

                              <div class="col-md-6">

                              </div>
                            </div>

                            <div class="row mt-2">
                              <div class="col-md-12">
                                <div class="android-ip">
                                  <p class="mb-3">To enable chrome notifications on your website, follow the steps mentioned in the
                                    <span>support documentation.</span>
                                  </p>

                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>

                    </tab>

                  </tabset>
                </div>
              </div>
            </div>
          </tab>

          <tab heading="SMS" id="sms" (selectTab)="changeTab($event)">
            <div class="error_msg_" *ngIf="emailErrorMsg != false">{{emailErrorMsg}}</div>
            <form [formGroup]="smsForm" (ngSubmit)="saveSMSDetails()">
              <div class="sms">
                <div class="card">
                  <div class="card-header">
                    <div class="row">
                      <div class="col-lg-8 col-md-12 col-12 col-sm-12">
                        <div class="form-group row">
                          <label class="col-md-4 control-label blue-label" for="SMSService">SMS Service Provider</label>
                          <div class="col-md-4">
                            <select class="form-control" formControlName="providerId" id="SMSService" (change)="updateSMSProvide($event.target.value);" >
                              <option value="">Select Provider</option>
                              <option *ngFor="let item of smsProviders" [value]="item.id">{{item.providerName}}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 col-12 col-sm-12">
                        <div class="integration-guide-link">
                          <!-- <a *ngIf="smsProvider!=undefined" (click)="openProviderLink('Sms')">{{smsProvider.providerName}}
                            integration guide</a> -->
                          <a *ngIf="smsProvider!=undefined" href="{{global.StaticUrl}}integration_guide/ration_guide.pdf" target="_blank">{{smsProvider.providerName}} integration guide</a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card-body">
                    <div class="sms-servic-2">
                      <div class="row">
                        <div class="col-lg-8">
                          <div class="row">
                            <div class="col-md-12 mb-3">
                              <div class="form-group row">
                                <label class="col-md-4 control-label" for="serviceProviderName">Service Provider Name</label>
                                <div class="col-md-8">
                                  <input trim="blur" type="text" class="form-control" id="serviceProviderName" formControlName="nickName">
                                </div>
                              </div>
                              <label class="check" *ngIf="false"> Mark as default
                                <input type="checkbox" checked="checked" formControlName="isDefault">
                                <span class="checkmark"></span>
                              </label>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-md-12">
                              <div class="form-group row">

                                <div class="offset-md-4 col-md-3 mb-3 pr-0">
                                  <select class="form-control" formControlName="apiType"> 
                                    <option value="Select Type">Select Type</option>
                                    <option value="Get">GET</option>
                                    <option value="Post">POST</option>
                                  </select>
                                </div>
                                <div class="col-md-5 mb-3">
                                  <input trim="blur" type="text" class="form-control" formControlName="apiEndpoint" placeholder="Enter your provider’s HTTP end point">
                                </div>
                              </div>
                            </div>
                          </div>


                          <div class="row">
                            <div class="col-md-12">
                              <div class="sms-second-tab">
                                <tabset>
                                  <tab heading="Authentication">

                                    <div class="row ">
                                      <div class="col-md-12">
                                        <div class="form-group row">
                                          <label class="col-md-4 control-label" for="authType">Auth Type</label>
                                          <div class="col-md-8">
                                            <select class="form-control" (change)="onChangeAuthType($event.target.value)" formControlName="authType" id="authType">
                                              <option value="Select Authentication">Select Authentication</option>
                                              <option value="NA">No Authentication</option>
                                              <option value="Basic">Basic Authentication</option>
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="row " *ngIf="this.smsDetails.authType=='Basic'">
                                      <div class="col-md-12">
                                        <div class="form-group row">
                                          <label class="col-md-4 control-label" for="username">Username</label>
                                          <div class="col-md-8">
                                            <input trim="blur" type="text" class="form-control" formControlName="apiUser" id="username">
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="row " *ngIf="this.smsDetails.authType=='Basic'">
                                      <div class="col-md-12">
                                        <div class="form-group row">
                                          <label class="col-md-4 control-label" for="password">Password</label>
                                          <div class="col-md-8">
                                            <input trim="blur" type="password" class="form-control" formControlName="apiPassword" id="password">
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                  </tab>
                                  <tab heading="Headers">
                                    <div formArrayName="smsPushConfigHeadersDTO" *ngFor="let item of smsPushConfigHeadersDTO().controls; let i = index;" class="row">
                                      <div class="col-md-12" [formGroupName]="i">
                                        <div class="form-group row">
                                          <label class="col-md-4 control-label">Add Key/Value pair</label>
                                          <div class="col-md-4">
                                            <input trim="blur" type="text" class="form-control" placeholder="Key" formControlName="keyName">
                                          </div>
                                          <div class="col-md-4">
                                            <input trim="blur" type="text" class="form-control" placeholder="Value" formControlName="keyValue">
                                            <a (click)="removeSMSHeader(i)" class="verify add-dlt" *ngIf="disableSms == false">
                                              <span class="icon-trash"></span>
                                            </a>
                                            <a *ngIf="smsForm.get('smsPushConfigHeadersDTO').controls.length==(i+1)" (click)="addSMSHeader()" class="verify">
                                              <span class="icon-plus"></span>
                                              Add another pair</a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div *ngIf="smsPushConfigHeadersDTO().controls.length==0" class="row">
                                      <div class="col-md-12">
                                        <a (click)="addSMSHeader()" class="verify">
                                          <span class="icon-plus"></span> Add another pair</a>
                                      </div>
                                    </div>

                                  </tab>
                                  <tab heading="Parameters">
                                    <div class="row mb-3">
                                      <div class="col-md-12">
                                        <div class="form-group row">
                                          <label class="col-md-4 control-label">Select Type</label>
                                          <div class="col-md-8">
                                            <select class="form-control" (change)="onChangeselectType($event.target.value)" formControlName="contentType">
                                              <option value="x-www-form-urlencoded">x-www-form-urlencoded</option>
                                              <option value="application/JSON">Application/JSON</option>
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="row mb-3" *ngIf="smsDetails.selectType=='Raw'">
                                      <div class="col-md-12">
                                        <div class="form-group row">
                                          <label class="col-md-3 control-label w-22">Raw</label>
                                          <div class="col-md-9">
                                            <textarea class="form-control" rows="5" formControlName="raw"></textarea>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div formArrayName="smsPushConfigBodyDTO" *ngFor="let item of smsPushConfigBodyDTO().controls; let i = index;" class="row">
                                      <div *ngIf="smsDetails.selectType!='Raw'" class="col-md-12" [formGroupName]="i">
                                        <div class="form-group row">
                                          <label class="col-md-4 control-label">Add Key/Value pair</label>
                                          <div class="col-md-4">
                                            <input trim="blur" type="text" class="form-control" placeholder="Key" formControlName="keyName">
                                          </div>
                                          <div class="col-md-4">
                                            <input trim="blur" type="text" class="form-control" placeholder="Value" formControlName="keyValue">
                                            <a (click)="removeSMSBody(i)" class="verify add-dlt" *ngIf="disableSms == false">
                                              <span class="icon-trash"></span>
                                            </a>
                                            <a *ngIf="smsForm.get('smsPushConfigBodyDTO').controls.length == (i+1)" (click)="addSMSBody()" class="verify">
                                              <span class="icon-plus"></span> Add another pair</a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div *ngIf="smsPushConfigBodyDTO().controls.length==0 && smsDetails.selectType!='Raw'" class="row">
                                      <div class="col-md-12">
                                        <a (click)="addSMSBody()" class="verify">
                                          <span class="icon-plus"></span> Add another pair
                                        </a>
                                      </div>
                                    </div>

                                  </tab>
                                </tabset>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-4">
                          <div class="note-box">
                            <h5>Note:</h5>
                            <p>Use the "$$To" and "$$Message" parameters either in the API Endpoint, Headers or the Parameter
                              values to donote the user's mobile number and SMS message respectively. They'll be replaced
                              with actual values when the message is delivered to the endpoint.</p>
                          </div>
                        </div>

                      </div>



                        <div class="row mt-5 justify-content-between">
                            <div class="col-md-6">
                                <button *ngIf="userRole != 1 && !isSMSReset" type="button" class="btn btn-border mr-3" (click)="resetForm(smsForm, 'sms')">Create Own Configuration</button>
                                <button *ngIf="userRole != 1 && isSMSReset" type="button" class="btn btn-border mr-3" (click)="resetSMS()" >Reset Configuration</button>
                            </div>
                            <div class="col-md-6 text-right">
                                <div *ngIf="userRole == 1 || (userRole != 1 && isSMSReset)">
                                    <button type="button" class="btn btn-border mr-3" (click)="openModalValidation(smsForm, sms)">Send test SMS</button>
                                    <button type="submit" class="btn btn-dark">Add Provider</button>
                                </div>
                            </div>
                        </div>

                    </div>

                  </div>

                </div>
              </div>
            </form>
          </tab>
          
          <tab heading="Email" id="email" (selectTab)="changeTab($event)">
            <div class="error_msg_" *ngIf="emailErrorMsg != false">{{emailErrorMsg}}</div>

            <form [formGroup]="emailForm" (ngSubmit)="saveEmailDetails()">
              <div class="sms">
                <div class="card">
                  <div class="card-header">
                    <div class="row">

                      <div class="col-md-6">
                        <div class="form-group row">
                          <label class="col-md-4 control-label blue-label">Email Service Provider
                          </label>
                          <div class="col-md-8">
                            <select class="form-control" formControlName="providerId" (change)="updateEmailProvide($event.target.value);">
                              <option value="">Select Provider</option>
                              <option *ngFor="let item of emailProviders" [value]="item.id">{{item.providerName}}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      
                      <div class="col-md-6">
                        <div class="integration-guide-link">
                          <a *ngIf="emailProvider!=undefined" href="{{global.StaticUrl}}integration_guide/email_integration_guide.pdf" target="_blank">{{emailProvider.providerName}} integration guide</a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card-body">
                    <div class="sms-servic-1 mb-2">
                      <div class="row">
                        <div class="col-md-6 mb-3">
                          <div class="form-group row">
                            <label class="col-md-4 control-label" for="providerName">Provider Name</label>
                            <div class="col-md-8">
                              <input trim="blur" type="text" class="form-control" id="providerName" formControlName="nickName" tabindex="1">
                            </div>
                          </div>
                          <label class="check" *ngIf="false"> Mark as default
                            <input type="checkbox" checked="checked" formControlName="isDefault" tabindex="">
                            <span class="checkmark"></span>
                          </label>
                        </div>

                        <div class="col-md-6 mb-3">
                          <div class="form-group row">
                            <label class="col-md-4 control-label" for="senderEmail">Sender Email/Alias</label>
                            <div class="col-md-8">
                              <input trim="blur" type="text" class="form-control" id="senderEmail" formControlName="emailFromAddress" tabindex="6">
                            </div>
                          </div>
                        </div>

                        <div class="col-md-6 mb-3">
                          <div class="form-group row">
                            <label class="col-md-4 control-label" for="emailUser">Username</label>
                            <div class="col-md-8">
                              <input trim="blur" type="text" class="form-control" id="emailUser" formControlName="emailUser" tabindex="2">
                            </div>
                          </div>
                        </div>

                        <div class="col-md-6 mb-3" *ngIf="false">
                          <div class="form-group row">
                            <label class="col-md-4 control-label" for="callBackUl">Callback URL</label>
                            <div class="col-md-8">
                              <input trim="blur" type="text" class="form-control" id="callBackUl" formControlName="callBackUl" tabindex="3">
                            </div>
                          </div>
                        </div>

                        <div class="col-md-6 mb-3">
                          <div class="form-group row">
                            <label class="col-md-4 control-label" for="emailPassword">Password</label>
                            <div class="col-md-8">
                              <div class="input-group password-text">
                                <input trim="blur" type="text" *ngIf="!isEmailTypePassword" class="form-control" id="emailPassword" formControlName="emailPassword" tabindex="4">
                                <input trim="blur" type="password" *ngIf="isEmailTypePassword" class="form-control" id="emailPassword1" formControlName="emailPassword" tabindex="4">
                                <span class="input-group-append" (click)="isEmailTypePassword = !isEmailTypePassword" *ngIf="userRole == 1">
                                  <img src="assets/images/eye-dark.svg" *ngIf="isEmailTypePassword">
                                  <img src="assets/images/eye-slash.svg" *ngIf="!isEmailTypePassword">
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-6 mb-3">
                          <div class="form-group row">
                            <label class="col-md-4 control-label" for="emailServerIp">Server Name/IP</label>
                            <div class="col-md-8">
                              <input trim="blur" type="text" class="form-control" id="emailServerIp" formControlName="emailServerIp" tabindex="5">
                            </div>
                          </div>
                        </div>


                        <div class="col-md-6 mb-3">
                          <div class="form-group row">
                            <label class="col-md-4 control-label" for="emailServerPort">Port</label>
                            <div class="col-md-8">
                              <input trim="blur" type="text" class="form-control" id="emailServerPort" formControlName="emailServerPort" tabindex="7">
                            </div>
                          </div>
                        </div>

                        <div class="col-md-6 mb-3" *ngIf="false">
                          <div class="form-group row">
                            <label class="col-md-4 control-label lable-span" for="unsubscribePage">Unsubscribe page
                              <span>(optional)</span>
                            </label>
                            <div class="col-md-8">
                              <input trim="blur" type="text" class="form-control" id="unsubscribePage" formControlName="unsubscribePage" tabindex="8">
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 text-right d-flex justify-content-between">
                            <div>
                                <button *ngIf="userRole != 1 && !isEmailReset" type="button" class="btn btn-border mr-3" (click)="resetForm(emailForm, 'email')">Create Own Configuration</button>
                                <button *ngIf="userRole != 1 && isEmailReset" type="button" class="btn btn-border mr-3" (click)="resetEmail()" >Reset Configuration</button>
                            </div>
                            <div><div *ngIf="userRole == 1 || (userRole != 1 && isEmailReset)">
                                <button type="button" class="btn btn-border mr-3" (click)="openModalValidation(emailForm, email)">Send test Email</button>
                                <button type="submit" class="btn btn-dark" tabindex="9">Add Provider</button>
                            </div></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </tab>

          <tab heading="In App" id="inapp" (selectTab)="changeTab($event)">
            <div class="row">
              <div class="col-md-12">
                <div class="inapp-tab">
                  <tabset>
                    <tab heading="Mobile">
                      <div class="card">
                        <div class="card-body">
                          <form>
                            <div class="row">
                              <div class="col-md-12 mb-3">
                                <div class="text-right">
                                  <a class="integration-link" href="{{global.StaticUrl}}integration_guide/inapp_integration_guide.pdf" target="_blank">In App integration guide</a>
                                </div>
                              </div>
                              <div class="col-md-12">
                                <div class="form-group row">
                                  <label class="col-md-3 control-label">Iframe URL</label>
                                  <div class="col-md-9">
                                    <div class="input-group">
                                      <input trim="blur" type="text" class="form-control" #iframeMobile value="{{global.webInapp}}mobile-view?audienceId=$$Audience&api-key=$$apiKey"
                                        readonly>
                                      <div class="input-group-append">
                                        <span class="input-group-text btn btn-dark mb-0" (click)="copyInputMessage(iframeMobile)" id="basic-addon2">Copy</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="form-group row">
                                  <label class="col-md-3 control-label">JSON URL
                                  </label>
                                  <div class="col-md-9">

                                    <div class="input-group">
                                      <input trim="blur" type="text" class="form-control" #iframeMobileJSON value="{{global.WebUrl}}campaign/fetch-inapp-notifications-list"
                                        readonly>
                                      <div class="input-group-append">
                                        <span class="input-group-text btn btn-dark mb-0" (click)="copyInputMessage(iframeMobileJSON)" id="basic-addon2">Copy</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-12 mb-3 text-details">
                                <span>In app notification is a way to show user specific notifications inside any website or mobile
                                  app. To integrate inapp notification on your website or mobile app, either call the above
                                  iframe URL inside any iframe of your website or any web-view section of your mobile app.</span>
                                <span>Optionally, if you want to get the notification data in raw format. Please call the above
                                  mentioned JSON URL using POST and show the notifications in your customised format.
                                </span>
                              </div>

                              <div class="col-md-12 text-right">
                                <button type="button" class="btn btn-border mr-3" (click)="openModal(inAppMobileTest)">
                                  Test In App notification</button>

                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </tab>

                    <tab heading="Web">
                      <div class="card">
                        <div class="card-body">
                          <form>
                            <div class="row">
                              <div class="col-md-12 mb-3">
                                <div class="text-right">
                                  <a class="integration-link" href="{{global.StaticUrl}}integration_guide/inapp_integration_guide.pdf" target="_blank">In App integration guide</a>
                                </div>
                              </div>
                              <div class="col-md-12">
                                <div class="form-group row">
                                  <label class="col-md-3 control-label">Iframe URL</label>
                                  <div class="col-md-9">
                                    <div class="input-group">
                                      <input trim="blur" type="text" class="form-control" #iframeWeb value="{{global.webInapp}}web-view?audienceId=$$Audience&api-key=$$apiKey"
                                        readonly>
                                      <div class="input-group-append">
                                        <span class="input-group-text btn btn-dark mb-0" (click)="copyInputMessage(iframeWeb)" id="basic-addon2">Copy</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="form-group row">
                                  <label class="col-md-3 control-label">JSON URL
                                  </label>
                                  <div class="col-md-9">

                                    <div class="input-group">
                                      <input trim="blur" type="text" class="form-control" #iframeWebJSON value="{{global.WebUrl}}campaign/fetch-inapp-notifications-list"
                                        readonly>
                                      <div class="input-group-append">
                                        <span class="input-group-text btn btn-dark mb-0" (click)="copyInputMessage(iframeWebJSON)" id="basic-addon2">Copy</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-12 mb-3 text-details">
                                <span>In app notification is a way to show user specific notifications inside any website or mobile
                                  app. To integrate inapp notification on your website or mobile app, either call the above
                                  iframe URL inside any iframe of your website or any web-view section of your mobile app.</span>
                                <span>Optionally, if you want to get the notification data in raw format. Please call the above
                                  mentioned JSON URL using POST and show the notifications in your customised format.
                                </span>
                              </div>

                              <div class="col-md-12 text-right">
                                <button type="button" class="btn btn-border mr-3" (click)="openModal(inAppWebTest)">
                                  Test In App notification</button>

                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </tab>
                  </tabset>
                </div>
              </div>
            </div>
          </tab>

          <tab heading="Announcement" id="announcement" (selectTab)="changeTab($event)">
            <div class="error_msg_" *ngIf="announcementErrorMsg != false">{{announcementErrorMsg}}</div>
            <div class="row">
              <div class="col-md-12">
                <div class="inapp-tab">
                    <div class="card">
                      <div class="card-body">
                        <form>
                          <div class="row">
                            <div class="col-md-12">
                              <h4>Install Announcement Bar/Popup Script</h4>
                              <div class="mb-3 text-details">
                                <p>Once the script is installed, your dashboard will begin populating data all about your bars and visitors. This only needs to be done once per site.</p>
                                <p><strong>To install Announcement Bar/Popup, copy and paste the following code right before your closing &lt;/body&gt; tag. </strong></p>
                              </div>
                              <div class="form-group row">
                                <label class="col-md-3 control-label">Embed code</label>
                                <div class="col-md-9">
                                  <div class="input-group">
                                    <input trim="blur" type="text" class="form-control" #EmbedCode value='&lt;script src="{{announcementJsURL}}" type="text/javascript"&gt; &lt;/script&gt;'
                                      readonly>
                                    <div class="input-group-append">
                                      <span class="input-group-text btn btn-dark mb-0" (click)="copyInputMessage(EmbedCode)" id="basic-addon2">Copy</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="mb-3 text-details">
                                <span>That's it! You'll never need to update the code on your site again.</span>
                              </div>
                            </div>
                            

                            <br>
                          </div>
                        </form>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </tab>

         

          <tab heading="Whatsapp Business" id="Whatsapp-Api" (selectTab)="showSyncButton = false; changeTab($event)">
            <div class="error_msg_" *ngIf="whatsappErrorMsg != false">{{whatsappErrorMsg}}</div>
            <form [formGroup]="wpBForm" (ngSubmit)="savewpBDetails()">
              <div class="sms">
                <div class="card">
                  <div class="card-header">
                    <div class="row">
                      <div class="col-lg-8 col-md-12 col-12 col-sm-12">
                        <div class="form-group row">
                          <label class="col-md-4 control-label blue-label" for="SMSService">Whatsapp Service Provider</label>
                          <div class="col-md-4">
                            <select class="form-control" formControlName="providerId" id="WPBService" (change)="updatewpBProvide($event.target.value);" >
                              <option value="">Select Provider</option>
                              <option *ngFor="let item of wpBProviders" [value]="item.id">{{item.providerName}}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 col-12 col-sm-12">
                        <div class="integration-guide-link">
                          <!-- <a *ngIf="smsProvider!=undefined" (click)="openProviderLink('Sms')">{{smsProvider.providerName}}
                            integration guide</a> -->
                         </div>
                      </div>
                    </div>
                  </div>

                  <div class="card-body">
                    <div class="sms-servic-2">
                      <div class="row">
                        <div class="col-lg-8">
                          <ng-container *ngIf="wpBDetails">
                           <div class="row" *ngFor="let field of wpBDetails.uiFields; let i=index">
                            <div class="col-md-12 mb-3">
                              <div class="form-group row">
                                <label class="col-md-4 control-label" for="serviceProviderName">{{field}}</label>
                                <div class="col-md-8 outerRelated">
                                  <input trim="blur" type="text" class="form-control" formControlName="authorization" [(ngModel)]="wpBDetails.uiAuthorization[i]">
                                  <button type="button" class="btn btn-dark sync_button" (click)="syncConf()" *ngIf="showSyncButton">
                                    Sync Configuration
                                  </button>
                                </div>
                              </div>
                             </div>
                            </div>
                          </ng-container>
                        </div>

                        <div class="col-lg-4">
                          <!-- <div class="note-box">
                            <h5>Note:</h5>
                            <p>Use the "$$To" and "$$Message" parameters either in the API Endpoint, Headers or the Parameter
                              values to donote the user's mobile number and SMS message respectively. They'll be replaced
                              with actual values when the message is delivered to the endpoint.</p>
                          </div> -->
                        </div>

                      </div>



                        <div class="row mt-5 justify-content-between">
                            <div class="col-md-6">
                                <button *ngIf="userRole != 1 && !isSMSReset" type="button" class="btn btn-border mr-3" (click)="resetForm(wpBForm, 'whatsAppB')">Create Own Configuration</button>
                                <button *ngIf="userRole != 1 && isSMSReset" type="button" class="btn btn-border mr-3" (click)="resetwpB()" >Reset Configuration</button>
                            </div>
                            <div class="col-md-6 text-right">
                                <div *ngIf="userRole == 1 || (userRole != 1 && iswpBReset)">
                                    <button type="button" class="btn btn-border mr-3" (click)="openModalValidation(wpBForm, wpBNotif)">Send test whatsapp</button>
                                    <button type="submit" class="btn btn-dark">Add Provider</button>
                                </div>
                            </div>
                        </div>

                    </div>

                  </div>

                </div>
              </div>
            </form>
          </tab>


        </tabset>
      </div>
    </div>

  </section>
</main>

<!-- modal android -->
<ng-template #android>

  <form [formGroup]="androidNotiForm">
    <div class="forgot-password-modal">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Test Push Notification</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">

          <div class="col-md-12 form-group">
            <label class="control-label pt-0">Fcm Token</label>
            <input trim="blur" class="form-control" type="text" formControlName="deviceId">
          </div>

          <div class="col-md-12 form-group">
            <label class="control-label pt-0">Title</label>
            <input class="form-control" formControlName="title" type="text">
          </div>

          <div class="col-md-12 form-group">
            <label class="control-label pt-0">Content</label>
            <textarea class="form-control" formControlName="message" rows="4"></textarea>
          </div>
          <div class="col-md-12 form-group">
              <div class="row">
                <label class="col-lg-4 col-md-12 control-label">Upload Image</label>
                <div class="col-lg-8 col-md-12">
                  <!-- <input trim="blur" class="form-control modal-space" accept="image/*" type="file" (change)="AndroidchangeListeners($event)" required> -->
                  <button (click)="openGalleryModal('image','ndroidNotification')" class="btn btn-border" type="button">
                    <span>Select Image</span>
                  </button>
                  <span class="icontooltip" tooltip="Android : 500 KB  png/jpg
                      Ios		: 500 KB  png/jpg
                      Web		: 192 * 192 px" [tooltipAnimation]="true" tooltipPlacement="right">
                    <img src="assets/images/info-circle.svg "/>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-md-12 form-group">
              <div class="row">
                <label class="col-lg-4 col-md-12 control-label">Notification Icon</label>
                <div class="col-lg-8 col-md-12">
                  <!-- <input trim="blur" class="form-control modal-space" accept="image/*" type="file" (change)="AndroidNotification($event)" required> -->
                  <button (click)="openGalleryModal('image','ndroidNotificationIcon')" class="btn btn-border" type="button">
                    <span>Select Image</span>
                  </button>
                  <span class="icontooltip" tooltip="Android : 500 KB to 3 MB png/jpg
                    Ios	: 500 KB to 3 MB png/jpg
                    Web	: 360 x 240 px" [tooltipAnimation]="true" tooltipPlacement="right">
                    <img src="assets/images/info-circle.svg " />
                  </span>
                </div>
              </div>
            </div>
          <div class="col-md-12 text-right">
            <button class="btn btn btn-dark" type="button" (click)="saveAndroidNotiForm()">Send</button>
          </div>
        </div>
      </div>
    </div>
  </form>

</ng-template>

<!-- modal ios -->
<ng-template #ios>

  <form [formGroup]="iosNotiForm" (ngSubmit)="saveIosNotiForm()">
    <div class="forgot-password-modal">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Test Push Notification</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12 form-group">
            <label class="control-label pt-0">Fcm Token</label>
            <input trim="blur" class="form-control" type="text" formControlName="deviceId">
          </div>

          <div class="col-md-12 form-group">
            <label class="control-label pt-0">Title</label>
            <input class="form-control" formControlName="title" type="text">
          </div>

          <div class="col-md-12 form-group">
            <label class="control-label pt-0">Content</label>
            <textarea class="form-control" formControlName="message" rows="4"></textarea>
          </div>
          <div class="col-md-12 form-group">
              <div class="row">
                <label class="col-lg-4 col-md-12 control-label">Upload Image</label>
                <div class="col-lg-8 col-md-12">
                  <!-- <input trim="blur" class="form-control modal-space" accept="image/*" type="file" (change)="IoschangeListeners($event)" required> -->
                  <button (click)="openGalleryModal('image','iosNotificationImage')" class="btn btn-border" type="button">
                    <span>Select Image</span>
                  </button>
                  <span class="icontooltip" tooltip="Android : 500 KB  png/jpg
                      Ios		: 500 KB  png/jpg
                      Web		: 192 * 192 px" [tooltipAnimation]="true" tooltipPlacement="right">
                    <img src="assets/images/info-circle.svg "/>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-md-12 form-group">
              <div class="row">
                <label class="col-lg-4 col-md-12 control-label">Notification Icon</label>
                <div class="col-lg-8 col-md-12">
                  <!-- <input trim="blur" class="form-control modal-space" accept="image/*" type="file" (change)="IosNotification($event)" required> -->
                  <button (click)="openGalleryModal('image','iosNotificationIcon')" class="btn btn-border" type="button">
                    <span>Select Image</span>
                  </button>
                  <span class="icontooltip" tooltip="Android : 500 KB to 3 MB png/jpg
                    Ios	: 500 KB to 3 MB png/jpg
                    Web	: 360 x 240 px" [tooltipAnimation]="true" tooltipPlacement="right">
                    <img src="assets/images/info-circle.svg "/>
                  </span>
                </div>
              </div>
            </div>
          <div class="col-md-12 text-right">
            <button class="btn btn btn-dark" type="submit">Send</button>
          </div>
        </div>
      </div>
    </div>
  </form>

</ng-template>

<!-- <ng-template #windows>

  <form [formGroup]="windowsNotiForm" (ngSubmit)="saveWindowsNotiForm()">
    <div class="forgot-password-modal">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Test Push Notification</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">

          <div class="col-md-12 form-group">
            <label class="control-label pt-0">Device ID</label>
            <input trim="blur" class="form-control" type="text" formControlName="deviceId">
          </div>

          <div class="col-md-12 form-group">
            <label class="control-label pt-0">Title</label>
            <input class="form-control" formControlName="title" type="text">
          </div>

          <div class="col-md-12 form-group">
            <label class="control-label pt-0">Content</label>
            <textarea class="form-control" formControlName="message" rows="4"></textarea>
          </div>
         
          
          <div class="col-md-12 text-right">
            <button class="btn btn btn-dark" type="submit">Send</button>
          </div>
        </div>
      </div>
    </div>
  </form>

</ng-template> -->


<ng-template #web>

  <form [formGroup]="webNotiForm" (ngSubmit)="saveWebNotiForm()">
    <div class="forgot-password-modal">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Test Push Notification</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">

          <div class="col-md-12 form-group">
            <label class="control-label pt-0">Browser ID (fcm token)</label>
            <input trim="blur" class="form-control" type="text" formControlName="deviceId">
          </div>

          <div class="col-md-12 form-group">
            <label class="control-label pt-0">Title</label>
            <input class="form-control" formControlName="title" type="text">
          </div>

          <div class="col-md-12 form-group">
            <label class="control-label pt-0">Content</label>
            <textarea class="form-control" formControlName="message" rows="4"></textarea>
          </div>
          <div class="col-md-12 form-group">
            <div class="row">
              <label class="col-lg-4 col-md-12 control-label">Upload Image</label>
              <div class="col-lg-8 col-md-12">
                <!-- <input trim="blur" class="form-control modal-space" accept="image/*" type="file" (change)="WebchangeListeners($event)" required> -->
                <button (click)="openGalleryModal('image','webNotificationImage')" class="btn btn-border" type="button">
                  <span>Select Image</span>
                </button>
                <span class="icontooltip" tooltip="Android : 500 KB  png/jpg
                    Ios		: 500 KB  png/jpg
                    Web		: 192 * 192 px" [tooltipAnimation]="true" tooltipPlacement="right">
                  <img src="assets/images/info-circle.svg "/>
                </span>
              </div>
            </div>
          </div>
          <div class="col-md-12 form-group">
            <div class="row">
              <label class="col-lg-4 col-md-12 control-label">Notification Icon</label>
              <div class="col-lg-8 col-md-12">
                <!-- <input trim="blur" class="form-control modal-space" accept="image/*" type="file" (change)="WebNotification($event)" required> -->
                <button (click)="openGalleryModal('image','webNotificationIcon')" class="btn btn-border" type="button">
                  <span>Select Image</span>
                </button>
                <span class="icontooltip" tooltip="Android : 500 KB to 3 MB png/jpg
                  Ios	: 500 KB to 3 MB png/jpg
                  Web	: 360 x 240 px" [tooltipAnimation]="true" tooltipPlacement="right">
                  <img src="assets/images/info-circle.svg " />
                </span>
              </div>
            </div>
          </div>
          <div class="col-md-12 text-right">
            <button class="btn btn btn-dark" type="submit">Send</button>
          </div>
        </div>
      </div>
    </div>
  </form>

</ng-template>


<!-- modal sms -->
<ng-template #sms>

  <form [formGroup]="smsNotiForm" (ngSubmit)="saveSmsNotiForm()">
    <div class="forgot-password-modal">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Test SMS</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12 form-group">
            <label class="control-label pt-0">Phone Number</label>
            <input trim="blur" class="form-control" type="text" formControlName="phone" maxlength="15">
          </div>

          <div class="col-md-12 form-group">
            <label class="control-label pt-0">Message</label>
            <textarea class="form-control" rows="4" formControlName="message" maxlength="500"></textarea>
          </div>

          <div class="col-md-12 text-right">
            <button class="btn btn btn-dark" type="submit">Send</button>
          </div>
        </div>
      </div>
    </div>
  </form>

</ng-template>

<!-- modal whatsappb -->
<ng-template #wpBNotif>

  <form [formGroup]="wpBNotiForm" (ngSubmit)="savewpBNotiForm()">
    <div class="forgot-password-modal">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Test WhatsApp Message</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">

          <div class="col-md-12 form-group">
            <label class="control-label pt-0">Template Name</label>
            <input trim="blur" class="form-control" type="text" formControlName="templateName">
          </div>

          <div class="col-md-12 form-group">
            <label class="control-label pt-0">Language Code</label>
            <select class="form-control" formControlName="languageCode">
              <option value="en"> English </option>
              <option value="hi"> Hindi </option>
            </select>
          </div>

          <div class="col-md-12 form-group">
            <label class="control-label pt-0">Media Url</label>
            <input trim="blur" class="form-control" type="text" formControlName="mediaUrl">
          </div>

          <div class="col-md-12 form-group">
            <label class="control-label pt-0">Media Type</label>
            <input trim="blur" class="form-control" type="text" formControlName="mediaType">
          </div>

          <div class="col-md-12 form-group">
            <label class="control-label pt-0">Country Code</label>
              <select class="form-control" formControlName="countryCode">
                <option value="+91" selected>
                  +91
                </option>
              </select>
          </div>

          <div class="col-md-12 form-group">
            <label class="control-label pt-0">Phone Number</label>
            <input trim="blur" class="form-control" type="text" formControlName="phone" maxlength="15">
          </div>

          <div class="col-md-12 form-group">
            <label class="control-label pt-0">Body Text <span class="quotMsgConf">( Please enclosed message into double quotes (""). )</span></label>
            <textarea class="form-control" rows="4" formControlName="message" maxlength="500"></textarea>
          </div>

          <div class="col-md-12 form-group" *ngIf="wpBDetails && wpBDetails.providerName == 'Cronberry WABA'">
            <label class="control-label pt-0">Button Text <span class="quotMsgConf"></span></label>
            <textarea class="form-control" rows="4" formControlName="buttonText" maxlength="500"></textarea>
          </div>

          <div class="col-md-12 text-right">
            <button class="btn btn btn-dark" type="submit">Send</button>
          </div>
        </div>
      </div>
    </div>
  </form>

</ng-template>



<!-- modal email -->
<ng-template #email>

  <form [formGroup]="emailNotiForm" (ngSubmit)="saveEmailNotiForm()">
    <div class="forgot-password-modal">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Test Email</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12 form-group">
            <label class="control-label pt-0">Email Address</label>
            <input trim="blur" class="form-control" type="email" formControlName="email">
          </div>

          <div class="col-md-12 form-group">
            <label class="control-label pt-0">Subject</label>
            <input trim="blur" class="form-control" type="text" formControlName="subject" maxlength="100">
          </div>

          <div class="col-md-12 form-group">
            <label class="control-label pt-0">Message</label>
            <textarea class="form-control" rows="5" formControlName="message" maxlength="10000"></textarea>
          </div>

          <div class="col-md-12 text-right">
            <button class="btn btn btn-dark" type="submit">Send</button>
          </div>
        </div>
      </div>
    </div>
  </form>

</ng-template>

<!-- modal inapp mobile test -->
<ng-template #inAppMobileTest>

  <form [formGroup]="inAppMobileForm" (ngSubmit)="previewInAppMobile()">
    <div class="forgot-password-modal">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Test In App</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12 form-group">
            <label class="control-label pt-0">Audience ID</label>
            <input trim="blur" class="form-control" type="text" formControlName="audienceID">
          </div>

          <div class="col-md-12 form-group">
            <label class="control-label pt-0">API Key</label>
            <input trim="blur" class="form-control" type="text" formControlName="apiKey">
          </div>

          <div class="col-md-12 text-right">
            <button class="btn btn btn-dark" type="submit">View</button>
          </div>
        </div>
      </div>
    </div>
  </form>

</ng-template>


<!-- modal inapp web test -->
<ng-template #inAppWebTest>

  <form [formGroup]="inAppWebForm" (ngSubmit)="previewInAppWeb()">
    <div class="forgot-password-modal">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Test In App</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12 form-group">
            <label class="control-label pt-0">Audience ID</label>
            <input trim="blur" class="form-control" type="text" formControlName="audienceID">
          </div>

          <div class="col-md-12 form-group">
            <label class="control-label pt-0">API Key</label>
            <input trim="blur" class="form-control" type="text" formControlName="apiKey">
          </div>

          <div class="col-md-12 text-right">
            <button class="btn btn btn-dark" type="submit">View</button>
          </div>
        </div>
      </div>
    </div>
  </form>

</ng-template>


<!-- modal inapp mobile test -->
<ng-template #inAppMobileIframe>

  <div class="forgot-password-modal">
    <div class="modal-header">
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-bodyx">
      <div class="configuration-inapp-mobile-popup">
        <div class="mobile-contant">
          <iframe frameBorder="0" [src]="InAppMobileUrl"></iframe>
        </div>
      </div>

    </div>
  </div>

</ng-template>

<!-- modal inapp web test -->
<ng-template #inAppWebIframe>

  <div class="forgot-password-modal">
    <div class="modal-header">
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-bodyx">
      <iframe frameBorder="0" [src]="InAppWebUrl"></iframe>
    </div>
  </div>

</ng-template>

<!-- modal whatsapp -->
<ng-template #whatsappModal>
    <div class="forgot-password-modal">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Test Message</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12 form-group">
            <label class="control-label pt-0">Phone Number</label>
            <input trim="blur" class="form-control" type="text" [(ngModel)]="whatsAppMsg.phone" maxlength="11">
          </div>
          <div class="col-md-12 text-right">
            <button class="btn btn btn-dark" type="button" (click)="sendWhatappMsg()">Send</button>
          </div>
        </div>
      </div>
    </div>

</ng-template>