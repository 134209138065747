<static-header></static-header>

<section class="inner-page">
    <section class="page-heading loans">
        <div class="container">
            <h1>FEATURES</h1>
        </div>
    </section>


    <section class="feature-box">
        <div class="container">
            <ul class="row">
                <li class="col-sm-6">  
                    <h3>Easy Integration</h3>
                    Integrate your existing website forms or mobile app using simple readymade scripts.
                </li>
                <li class="col-sm-6">
                    <h3>Automatic Lead Capturing</h3>
                    Automatically fetch leads from Google Forms, Facebook Lead Campaigns, Indiamart, and JustDial without a single line of code.
                </li>
                <li class="col-sm-6">
                    <h3>Readymade Plugins</h3>
                    Ready to use plugins for data capturing on different platforms like Shopify, WordPress (woo-commerce), etc.
                </li>
                <li class="col-sm-6">
                    <h3>Bulk Audience Upload via Excel</h3>
                    Easily upload or edit bulk leads or audience data using excel sheets.
                </li>
                <li class="col-sm-6">
                    <h3>360-degree data filtration</h3>
                    Target campaigns to specific customers using a 360-degree data filtration interface on all captured fields.
                </li>
                <li class="col-sm-6">
                    <h3>Multi-Channel Engagement with Sms/Email/Push/In-app</h3>
                    Engage your customers with different channels like Sms, Email, Android, and Ios Push Notifications and Web Notifications or show InApp notifications on your website or mobile app.
                </li>
                <li class="col-sm-6">
                    <h3>Auto-Repeat Campaigns</h3>
                    Create campaigns that are auto executed repeatedly at any given time, like every day or every month or every nth day of the month.
                </li>
                <li class="col-sm-6">
                    <h3>Event-Based Campaigns</h3>
                    Create campaigns based on events, like send a welcome email on lead generation or send greetings on customer birthday or a follow-up email after 2 days of lead generation.
                </li>
                <li class="col-sm-6">
                    <h3>Sms/Email/Push Templates</h3>
                    Create Custom templates using a drag and drop GUI editor and use these templates as many times in creating SMS/email/push campaigns.
                </li>
                <li class="col-sm-6">
                    <h3>PreConfigured Templates</h3>
                    A set of pre-configured templates for common engagement campaigns like Welcome email, Greetings, Birthday Wishes, etc.
                </li>
                <li class="col-sm-6">
                    <h3>Dynamic Content</h3>
                    Now send personalized emails/SMS/notification to each customer with its individual name, city, etc.
                </li>
                <li class="col-sm-6">
                    <h3>Asset Library</h3>
                    Easily upload your product catalog, images, documents, and use them in email and notification campaigns. You can also send a link of such documents with SMS with automatic short URL integration of bitly.
                </li>
                <li class="col-sm-6">
                    <h3>Announcement via Bar and Popup</h3>
                    Announce special promotions via a bar or a popup on your website or show an Exit Intent to retain your customer.
                </li>
                <li class="col-sm-6">
                    <h3>Market Segments</h3>
                    Segment your customers using specific data filtration logic and target them anytime using a campaign builder.
                </li>
                <li class="col-sm-6 last">
                    <h3>Campaign Reporting</h3>
                    Get advanced reporting on campaign execution, targets, open and click statistics, etc.
                </li>
                <li class="col-sm-6">
                    <h3> Support</h3>
                    Technical and Integration Support by cronberry expert team.
                </li>
            </ul>
            <div class="text-center mt-5"><a [routerLink]="['/contact-us']" class="btn btn-default">Contact For Pricing</a></div>
        </div>
    </section>

            <!-- <div class="row">
                <div class="col-md-4 plan-option">
                    <ng-container *ngFor="let item of pricingPlans">
                        <div *ngIf="yearly ? item.planType=='YEARLY':item.planType!='YEARLY'" class="box">
                            <label>
                                <div>
                                    <div class="box-inner d-flex justify-content-between">
                                        <input name="radio" type="radio"
                                            [disabled]="this.currentPlan.planName!='FREE' && this.currentPlan.activeStatus"
                                            [checked]="item.id == this.currentPlan.id" value="item.id"
                                            (change)="onSelectionChange(item)">
                                        <span class="checkmark"></span>
                                        <span class="text">{{item.planName}}</span>
                                        <div class="right">
                                            <p *ngIf="item.planDesc.startsWith('Starting')">Starting at</p>
                                            <span
                                                *ngIf="item.planType=='MONTHLY'"><strong>₹{{item.planCost | number: '1.0-0'}}/</strong>month</span>
                                            <span
                                                *ngIf="item.planType=='YEARLY'"><strong>₹{{item.planCost | number: '1.0-0'}}/</strong>yearly</span>
                                        </div>
                                    </div>
                                </div>
                            </label>
                        </div>
                    </ng-container>
                </div>
                <div class="col-md-8 plan-desc">
                    <h4>Make Your Plan</h4>
                    <div class="row checklist">
                        <div class="col-md-6">
                            <h6>Data Integration</h6>
                            <ul *ngFor="let item of activePlugins; let i = index">
                                <li >
                                    <label tooltip="{{item.priceDesc}}">{{item.pluginName}}
                                        <input type="checkbox" [disabled]="activePlugins[i].activeFlag=='1'"
                                            name="item.id"
                                            (change)="activePlugins[i].checked = !activePlugins[i].checked"
                                            [checked]="activePlugins[i].checked">
                                        <span class="checkmark"></span>
                                    </label>
                                </li>

                            </ul>
                        </div>
                        <div class="col-md-6">
                            <h6>Marketing Channels</h6>
                            <ul>
                                <li *ngFor="let item of activeChannels; let i = index">
                                    <label tooltip="₹{{item.price}}">{{item.channelName}}
                                        <input type="checkbox" [disabled]="activeChannels[i].activeFlag=='1'"
                                            name="item.id"
                                            (change)="activeChannels[i].checked = !activeChannels[i].checked"
                                            [checked]="activeChannels[i].checked">
                                        <span class="checkmark"></span>
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-11 p-0">
                            <div class="subs-box">
                                <h4 class="mb-3">SUBSCRIBERS</h4>
                                <p *ngIf="this.currentPlan.planName!='FREE'">Select subscriber limit for your account and see how it affects pricing</p>
                                <div class="custom-slider">
                                    <div>
                                        <ng5-slider *ngIf="this.currentPlan.planName!='FREE'" (valueChange)="updatePrice()" [(value)]="this.planCreditRangeValue" [options]="options"></ng5-slider>
                                    </div>
                                </div>
                                <div class="subs-count">
                                    <strong *ngIf="this.currentPlan.planName!='FREE'" class="red">{{this.planCreditRangeValue}}</strong>
                                    <span *ngIf="this.currentPlan.planName=='FREE'" > Free subscriber limit for your account - <strong class="red">250</strong></span>
                                </div>
                            </div>
                            <div class="subs-box d-flex justify-content-between pr-3">
                                <h4 *ngIf="(this.currentPlan.planName=='FREE' && yearly) || (this.currentPlan.planName!='FREE' && this.currentPlan.planType != 'MONTHLY')"> Yearly Cost</h4>
                                <h4 *ngIf="(this.currentPlan.planName=='FREE' && !yearly) || (this.currentPlan.planName!='FREE' && this.currentPlan.planType == 'MONTHLY')"> Monthly Cost</h4>
                                <h4 *ngIf="this.currentPlan.planName=='FREE'">₹0</h4>
                                <h4 *ngIf="this.currentPlan.planName!='FREE'">₹{{this.finalPrice()}}</h4>
                            </div>
                            <div class="subs-box  pr-3 text-right">
                                <a class="btn btn-default" (click)="upgrade()">Upgrade</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
       <!-- </div>
    </div> -->
</section>
<static-footer></static-footer>