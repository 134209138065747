<section class="wrapper-body {{ parentClass }}">
    <a id="show-sidebar" class="btn btn-sm btn-dark sidebar-open" (click)="showMenu()">
        <img alt="" src="assets/images/open-menu.svg" />
    </a>
    <div class="inner-wrapper">
        <!-- start: sidebar -->
        <aside class="sidebar-left">
            <div class="version">Version : {{VERSION}}</div>
            <div class="sidebar-content">
                <div class="top-logo">
                <!-- <a [routerLink]="['/admin/dashboard']" class="logo">
                    <img src="assets/images/logo.png" alt="" />
                </a> -->

                <a href="javascript:void(0)" class="logo">
                    <img src="assets/images/logo.png" alt="" />
                </a>
                <span *ngIf="innerWidth <= 1150" class="close close-menu" (click)="hideMenu()">
                    <img alt="" src="assets/images/cancel-menu.svg" />
                </span>
                </div>
                <nav class="nav-main">
                    <ul>
                        <li routerLinkActive="active-x" class="side-menu-active">
                            <a routerLinkActive="active-x" [routerLink]="['/admin/edit-account']">
                                <span class="font-icon icon-cogs"></span>
                                <span>Create Account</span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </aside>

        <!-- end: sidebar -->
        <section class="content-body">
            <section class="page-body">
                <router-outlet></router-outlet>
            </section>
        </section>
    </div>
</section>

