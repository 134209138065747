import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { version } from '../../../../../../package.json';

@Component({
  selector: 'app-layout',
  templateUrl: './account-layout.component.html',
  styleUrls: ['../auth-layout/auth-layout.component.scss']
})
export class AccountLayoutComponent implements OnInit {

    readonly VERSION = version;
    parentClass: any = 'hide-menu';
    innerWidth: number = 1200;
    isVisible:boolean=true;

    constructor(
        public router: Router,
    ){}

    ngOnInit() {
        this.innerWidth = window.innerWidth;
            if(this.innerWidth>1150){
                this.parentClass = 'show-menu';
            }
            if(this.innerWidth<575){
                this.router.events.subscribe((val) => {
                    this.hideMenu();
                });
        }
    }

    showMenu() {
        this.parentClass = 'show-menu';
    }

    hideMenu() {
        this.parentClass = 'hide-menu';
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if(this.innerWidth<=1150 && window.innerWidth>1150){
            this.showMenu();
        }
        if(this.innerWidth>1150 && window.innerWidth<=1150){
            this.hideMenu();
        }
        this.innerWidth = window.innerWidth;
    }

    EnableInput(disable: boolean) {
        if (disable==true) {
            this.isVisible = disable;
        } else {
            this.isVisible = disable;
        }
    }

}
