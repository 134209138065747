<main>
    <section class="inner-page">
        <div class="inner-page-header">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-6">
                        <h4>
                            <a class="back-link" (click)="back()"><img src="assets/images/angle-left.svg"></a>
                            Campaign Manager
                        </h4>
                    </div>
                </div>
            </div>
        </div>

        <div class="inner-page-body">
            <div class="row box-list">
                <div *ngFor="let item of campaignList | paginate: { itemsPerPage:dataSize, currentPage:pageNo, totalItems:totalDataSize };" class="col-lg-2 col-md-4 col-6">
                    <div class="box">
                        <div class="icon"><img src="assets/images/campaign-edit.svg" alt=""></div>
                        <h3>{{item.showName}} <br> <span>({{item.userName}})</span></h3>
                        <div class="action">
                            <a class="btn btn-outline-dark select" [routerLink]="['/admin/campaign/create-campaign/'+item.campName+'/'+item.journeyUserIdEncode]">Select</a>
                            <button class="btn btn-outline-danger btn-icon" (click)="deleteConfirmation(item)"><img src="assets/images/trash-alt-red.svg" alt=""></button>
                            <a class="btn btn-outline-dark btn-icon" [routerLink]="['/admin/report/'+item.campName+'/'+item.journeyUserIdEncode]"> <img src="assets/images/file-alt.svg" width="14" alt="Report"></a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 col-md-4 col-6">
                    <div class="box">
                        <div class="icon"><img src="assets/images/campaign-create.svg" alt=""></div>
                        <h3>Create Campaign</h3>
                        <div class="action">
                            <a class="btn btn-outline-dark" [routerLink]="['/admin/campaign/create-campaign']">Select</a>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="totalDataSize > 0" class="table-footer footer-gray">
                <pagination-controls
                      (pageChange)="dataForPaging($event)"
                      maxSize="9"
                      directionLinks="true"
                      autoHide="true"
                      class="pull-right"
                      responsive="true"
                      previousLabel="Previous"
                      nextLabel="Next"
                      screenReaderPaginationLabel="Pagination"
                      screenReaderPageLabel="page"
                      screenReaderCurrentLabel="You're on page">
                </pagination-controls>
                <div class="textblue text-left">
                  <div class="data-length"> Show
                    <select [(ngModel)]="dataSize" (change)="getList()">
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                  <span>Total Count: {{totalDataSize}}</span>
                </div>
            </div>
        </div>
    </section>
</main>

<ng-template #deleteConfirm>
    <div class="forgot-password-modal">
        <div class="modal-header">
            <h4 class="modal-title pull-left">Delete Campaign</h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body modal-user-management">
            <div class="form-group mb-0">
                <p class="pt-2">
                    Please check campaign run against this journey. If you remove it you won't able to stop campaign execute from this journey 
                    <a [routerLink]="goToReports()" (click)="modalRef.hide()">View Reports</a>
                </p>
                <div class="d-flex justify-content-end mt-5">
                    <div><button class="btn remove-user-btn mr-4" (click)="modalRef.hide()" type="button">Dismiss</button></div>
                    <div><button class="btn save-btn" type="button" (click)="deleteCampaign()">Yes</button></div>
                </div>
            </div>
        </div>
    </div>
</ng-template>