  <div *ngIf="!pageUnderProcess" class="content-body {{ parentClass }}" (contextmenu)="onRightClick()">
      <header class="page-header">
          <a id="show-sidebar" class="btn btn-sm btn-dark sidebar-open" (click)="showMenu()">
              <img _ngcontent-serverapp-c2="" alt="" src="assets/images/open-menu.svg" />
          </a>
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-5 col-9">
                  <div class="form-group row edit-project-block">
                    <div class="col-md-6 ng-star-inserted">
                        <label class="control-label pl-0" for="inputDefault">{{projectDetails.projectName}}</label>
                    </div>
                    <div class="copybtn">
                    <p>{{projectDetails.projectKey}}</p>
                    <span class="cursor-pointer">
                      <img src="assets/images/copy.svg" (click)="copyText(projectDetails.projectKey)"></span>
                  </div>
                  </div>
              </div>
              <div class="col-sm-7 col-3">
                <div class="profile-menu">
                  <nav class="navbar navbar-default text-right pr-0">
                    <div class="collapse navbar-collapse in show">
                      <ul class="nav navbar-nav navbar-right">
                        <li *ngIf="ls.getItem('userRoleId') != '3' && projectDetails.mauLimit != undefined && projectDetails.mauLimit != 0" class="item d-none d-sm-block"><span>Assigned MAU</span> {{projectDetails.mauLimit}}</li>
                        <li *ngIf="ls.getItem('userRoleId') != '3' && projectDetails.mauUsed != undefined && projectDetails.mauUsed != 0" class="item d-none d-sm-block"><span>Remaining MAU</span> {{projectDetails.mauLimit - projectDetails.mauUsed}}</li>
                        <li class="dropdown" dropdown>
                          <a href="javascript:;" dropdownToggle class="dropdown-toggle">
                            <span class="user">{{ email | shortName }}</span>
                            <span class="email"> {{ email }}</span>
                            <span class="icon-angle-down-1"></span>
                          </a>
                          <ul *dropdownMenu class="dropdown-menu" role="menu">
                            <li class="d-block d-sm-none">
                              <span class="dropdown-item"> {{ email }}</span>
                            </li>
                            <li role="menuitem" *ngIf="ls.getItem('AccountCreated') == 'Yes'">
                              <a class="dropdown-item" [routerLink]="['/admin/edit-account']">Edit Account</a>
                            </li>
                            <li role="menuitem" *ngIf="ls.getItem('AccountCreated') != 'Yes'">
                              <a class="dropdown-item" [routerLink]="['/admin/edit-account']">Create Account</a>
                            </li>
                            <li role="menuitem">
                              <a class="dropdown-item" [routerLink]="['/admin/change-password']">Change Password</a>
                            </li>
                            <li role="menuitem">
                              <a class="dropdown-item" (click)="signout()">Sign Out</a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
            <ul class="mau-item d-sm-none d-flex" *ngIf="ls.getItem('userRoleId') != '3' && projectDetails.mauLimit != 0 && projectDetails.mauLimit != undefined">
              <li class="item"><span>Assigned MAU</span> {{projectDetails.mauLimit}}</li>
              <li class="item"><span>Remaining MAU</span> {{projectDetails.mauLimit - projectDetails.mauUsed}}</li>
            </ul>
          </div>
        </header>
  </div>
  <iframe *ngIf="domain != undefined" [src]="domain"  #iframeTagName (load)="loadIframe(iframeTagName)" frameborder="0"></iframe>
  <div *ngIf="pageUnderProcess" class="page-loader">
    <div class="loader">
        <div class="la-ball-clip-rotate la-2x"><div></div></div>
        <h3>Store Loading...</h3>
    </div>
  </div>