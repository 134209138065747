import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../../core/guards/auth.guard';
import { AuthLayoutComponent } from '../admin/components/auth-layout/auth-layout.component';
import { TemplateBuilderComponent } from './templateBuilder.component';

const routes: Routes = [
    {path:'admin', component: AuthLayoutComponent, canActivate: [AuthGuard], children : [
        { path: 'template-builder', component: TemplateBuilderComponent},
        { path: 'template-builder/:id', component: TemplateBuilderComponent,  },
    ]}
]

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})

export class TemplateBuilderRouting { };

