<main>
  <section class="inner-page invoiceEstimatePage">
      <div class="inner-page-header">
          <div class="container-fluid">
              <div class="form-row d-flex headMainOuterBox">
                  <div class="headHalfBox">
                      <h4>
                          <a class="back-link" (click)="back()"><img src="assets/images/angle-left.svg"></a>
                         Invoice & Quotation Settings
                      </h4>
                  </div>
                 
              </div>
          </div>
      </div>
      <div class="inner-page-body">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-4">
                <div class="invoiceSettingLeft">
                  <div class="">
                  <form #settingForm="ngForm" (ngSubmit)="settingFormSubmitted()">
                    <tabset>
                      <tab heading="Design">
                        <div class="accordion" id="accordionExample">
                          <div class="card">
                            <div class="card-header" id="headingOne">
                              <h2 class="mb-0">
                                <span class="" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                 <img src="assets/images/template.svg">  Templates
                                </span>
                              </h2>
                            </div>
                        
                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                              <div class="card-body">
                                <ul class="templateList">
                                  <li> <label for="templateOne">Template 1</label> <input (change)="tempSelect('PACIFIC')" id="templateOne" type="radio" [(ngModel)]="settingFormFields.templateType" name="templateType" value="PACIFIC"> </li>
                                  <li> <label for="templateTwo">Template 2</label> <input (change)="tempSelect('ATLANTIC')" id="templateTwo" type="radio" [(ngModel)]="settingFormFields.templateType" name="templateType" value="ATLANTIC"> </li>
                                  <li> <label for="templateThree">Template 3</label> <input (change)="tempSelect('INDIAN')" id="templateThree" type="radio" [(ngModel)]="settingFormFields.templateType" name="templateType" value="INDIAN"> </li>
                                  <li> <label for="templateFour">Template 4</label> <input (change)="tempSelect('ARCTIC')" id="templateFour" type="radio" [(ngModel)]="settingFormFields.templateType" name="templateType" value="ARCTIC"> </li>
                                  <li> <label for="templateFive">Template 5</label> <input (change)="tempSelect('SOUTHERN')" id="templateFive" type="radio" [(ngModel)]="settingFormFields.templateType" name="templateType" value="SOUTHERN"> </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div class="card">
                            <div class="card-header" id="headingTwo">
                              <h2 class="mb-0">
                                <span class="collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                 <img src="assets/images/logo.svg">  Logo
                                </span>
                                </h2>
                            </div>

                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                              <div class="card-body">
                                <div class="upload-button">
                                  <button (click)="openGalleryModal('logo')" type="button">
                                      <span
                                      *ngIf="settingFormFields.logo != undefined; else elsebgImage">Change
                                      Image</span>
                                      <ng-template #elsebgImage>Select Image</ng-template>
                                  </button>
                                  <a href="javascript:void(0)" class="m_l_10" *ngIf="settingFormFields.logo != undefined" (click)="settingFormFields.logo = undefined"><img src="assets/images/form-trash-alt.svg" class="width_12" alt=""></a>
                                </div>
                              </div>
                            </div>
                            </div>

                            <div class="card">
                              <div class="card-header" id="headingFive">
                                <h2 class="mb-0">
                                  <span class="collapsed" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                   <img src="assets/images/signature.png">  Signature  
                                  </span>
                                  </h2>
                              </div>
  
                              <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                                <div class="card-body">
                                  <div class="upload-button">
                                    <button (click)="openGalleryModal('sign')" type="button">
                                        <span
                                        *ngIf="settingFormFields.signature != undefined; else elseSignImage">Change
                                        Image</span>
                                        <ng-template #elseSignImage>Select Image</ng-template>
                                    </button>
                                    <a href="javascript:void(0)" class="m_l_10" *ngIf="settingFormFields.signature != undefined" (click)="settingFormFields.signature = undefined"><img src="assets/images/form-trash-alt.svg" class="width_12" alt=""></a>
                                  </div>
                                </div>
                              </div>
            
                          </div>
                          <div class="card overFlowVisible">
                            <div class="card-header" id="headingThree">
                              <h2 class="mb-0">
                                <span class="collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                 <img src="assets/images/color.svg">  Colors
                                </span>
                              </h2>
                            </div>
                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                              <div class="card-body">
                                <ul class="templateList color_picker_list">
                                    <li> 
                                      <label for="">Background Color</label> 
                                      <div class="color_picker_" [(colorPicker)]="bGcolor" [style.background]="bGcolor" ></div>                      
                                    </li>
                                  <li> <label for="">Font Color</label> 
                                    <div class="color_picker_" [(colorPicker)]="color" [style.background]="color" ></div>                      
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <!-- <div class="card">
                            <div class="card-header" id="headingfive">
                              <h2 class="font_selector">
                                <span class="collapsed" data-toggle="collapse" data-target="#collapsefive" aria-expanded="false" aria-controls="collapsefive">
                                 <img src="assets/images/font.svg">  Fonts
                                </span>
                                <select class="form-control" [(ngModel)]="settingFormFields.font" name="font">
                                  <option value="select">Select</option>
                                  <option value="Roboto">Roboto</option>
                                  <option value="Open Sans">Open Sans</option>
                                  <option value="Lato">Lato</option>
                                  <option value="Roboto Condensed">Roboto Condensed</option>
                                  <option value="Source Sans Pro">Source Sans Pro</option>
                                  <option value="Oswald">Oswald</option>
                                  <option value="Raleway">Raleway</option>
                                  <option value="PT Sans">PT Sans</option>
                                  <option value="PT Serif">PT Serif</option>
                              </select>
                              </h2>
                            </div>
                          </div> -->

                          <div class="card">
                            <div class="card-header" id="headingsix">
                              <h2 class="font_selector">
                                <span class="collapsed" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                  Prefix / Suffix
                                </span>
                                
                              </h2>
                            </div>
                            <div id="collapseSix" *ngIf="userProfileData" class="collapse" aria-labelledby="headingsix" data-parent="#accordionExample">
                              <div class="card-body">
                                <ul class="templateList">
                                  <li>
                                <div class="form-group">
                                  <label>Invoice Prefix</label>
                                  <input type="text" [disabled]="userProfileData.users.roleTypeId != 1" class="form-control" [(ngModel)]="settingFormFields.invoicePrefix" name="invoicePrefix" maxlength="10">
                                </div>
                                </li>
                                <li>
                                <div class="form-group">
                                  <label>Invoice Suffix</label>
                                  <input type="text" [disabled]="userProfileData.users.roleTypeId != 1" class="form-control" [(ngModel)]="settingFormFields.invoiceSuffix" name="invoiceSuffix" maxlength="10">
                                </div>
                                </li>
                                <li>
                                  <div class="form-group d-inline-block width_100">
                                    <div class="d-flex">
                                    <label>Invoice Counter</label>
                                    <input type="text" [disabled]="userProfileData.users.roleTypeId != 1" class="form-control" onpaste="return false" onkeypress='return ( event.charCode >= 48 && event.charCode <= 57 )' [(ngModel)]="settingFormFields.invoiceCounter" name="invoiceCounter" maxlength="10">
                                    </div>
                                    <div class="checkInfoCount">
                                      <input type="checkbox" [(ngModel)]="settingFormFields.updateInvoiceCounter" name="updateInvoiceCounter" class="m_r_5">                                    
                                      Check to reset counter
                                    </div>
                                  </div>
                                  </li>
                                <li>
                                <div class="form-group">
                                  <label>Quotation Prefix</label>
                                  <input type="text" [disabled]="userProfileData.users.roleTypeId != 1" class="form-control" [(ngModel)]="settingFormFields.quotationPrefix" name="quotationPrefix" maxlength="10">
                                </div>
                                </li>
                                <li>
                                <div class="form-group">
                                  <label>Quotation Suffix</label>
                                  <input type="text" [disabled]="userProfileData.users.roleTypeId != 1" class="form-control" [(ngModel)]="settingFormFields.quotationSuffix" name="quotationSuffix" maxlength="10">
                                </div>
                                </li>
                                <li>
                                  <div class="form-group d-inline-block width_100">
                                    <div class="d-flex">
                                    <label>Quotation Counter</label>
                                    <input type="text" [disabled]="userProfileData.users.roleTypeId != 1" onpaste="return false" onkeypress='return ( event.charCode >= 48 && event.charCode <= 57 )' class="form-control" [(ngModel)]="settingFormFields.quotationCounter" name="quotationCounter" maxlength="10">
                                  </div>
                                    <div class="checkInfoCount">
                                      <input type="checkbox" [(ngModel)]="settingFormFields.updateQuotationCounter" name="updateQuotationCounter" class="m_r_5"> Check to reset counter
                                    </div>
                                  </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>


                        </div>
                      </tab>

                      <tab heading="Content">
                        <ng-container *ngIf="userProfileData">
                        <div class="form-group">
                          <label>Business Name</label>
                          <input type="text" class="form-control" [disabled]="userProfileData.users.roleTypeId != 1" [(ngModel)]="settingFormFields.businessName" name="businessName" maxlength="50">
                        </div>
                        <div class="form-group">
                          <label>Phone No.</label>
                          <div class="d-flex">
                            <select class="form-control country_code_dropdown" [(ngModel)]="settingFormFields.countryCode" name="countryCode">
                              <option value="+1">+1</option>
                              <option value="+27">+27</option>
                              <option value="+44">+44</option>
                              <option value="+91">+91</option>
                              <option value="+971">+971</option>
                            </select>
                            <input type="text" onpaste="return false" [disabled]="userProfileData.users.roleTypeId != 1" class="form-control" onkeypress='return ( event.charCode >= 48 && event.charCode <= 57 ) || event.charCode == 43 || event.charCode == 45' [(ngModel)]="settingFormFields.phoneNumber" name="phoneNumber" maxlength="16">
                          </div>
                        </div>
                        <div class="form-group">
                          <label>Email</label>
                          <input type="text" class="form-control" [disabled]="userProfileData.users.roleTypeId != 1" [(ngModel)]="settingFormFields.email" name="email">
                        </div>
                        <div class="form-group">
                          <label>Address</label>
                          <input type="text" class="form-control" [disabled]="userProfileData.users.roleTypeId != 1" [(ngModel)]="settingFormFields.address" name="address" maxlength="255">
                        </div>
                        <div class="form-group">
                          <label>GST No.</label>
                          <input type="text" maxlength="15" [disabled]="userProfileData.users.roleTypeId != 1" onpaste="return false" class="form-control uppercaseChar" [(ngModel)]="settingFormFields.gstNumber" name="gstNumber" (keypress)="restrictSpecialChar($event);" onkeyup="this.value=this.value.toUpperCase()" (input)="gstPressing($event)">
                        </div>
                        <div class="row">
                          <div class="col-md-4">
                            <div class="form-group">
                              <label>City</label>
                              <input type="text" class="form-control" [disabled]="userProfileData.users.roleTypeId != 1" [(ngModel)]="settingFormFields.city" name="city">
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <label>State</label>
                              <input type="text" class="form-control" [disabled]="userProfileData.users.roleTypeId != 1" [(ngModel)]="settingFormFields.state" name="state">
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <label>Postal Code</label>
                              <input type="text" [disabled]="userProfileData.users.roleTypeId != 1" onkeypress='return event.charCode >= 48 && event.charCode <= 57' maxlength="6" class="form-control" [(ngModel)]="settingFormFields.postalCode" name="postalCode">
                            </div>
                          </div>
                        </div>

                        <div class="form-group font_600 m_t_20">
                          <label>Lead Mapping</label>
                        </div>

                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>GST</label>
                            </div>
                          </div>
                          <div class="col-md-6" *ngIf="showMapParams">
                            <div class="form-group">
                              <select class="form-control" [(ngModel)]="settingFormFields.gstFieldId" name="gstFieldId">
                                <option value="select">Select</option>
                                <ng-container *ngFor="let list of showMapParams">
                                <option *ngIf="list.paramVisible == 1" [value]="list.paramKey">
                                  {{list.paramName}}
                                </option>
                                </ng-container>
                              </select>
                            </div>
                          </div>
                        </div>


                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Company Name</label>
                            </div>
                          </div>
                          <div class="col-md-6" *ngIf="showMapParams">
                            <div class="form-group">
                              <select class="form-control" [(ngModel)]="settingFormFields.companyNameFieldId" name="companyNameFieldId">
                                <option value="select">Select</option>
                                <ng-container *ngFor="let list of showMapParams">
                                <option *ngIf="list.paramVisible == 1" [value]="list.paramKey">
                                  {{list.paramName}}
                                </option>
                                </ng-container>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Address</label>
                            </div>
                          </div>
                          <div class="col-md-6" *ngIf="showMapParams">
                            <div class="form-group">
                              <select class="form-control" [(ngModel)]="settingFormFields.addressFieldId" name="addressFieldId">
                                <option value="select">Select</option>
                                <ng-container *ngFor="let list of showMapParams">
                                <option *ngIf="list.paramVisible == 1" [value]="list.paramKey">
                                  {{list.paramName}}
                                </option>
                                </ng-container>
                              </select>
                            </div>
                          </div>
                        </div>
                        </ng-container>
                      </tab>

                      <tab heading="Bank Details">
                        <ng-container *ngIf="userProfileData">
                        <div class="form-group">
                          <label>Bank Name</label>
                          <input type="text" [disabled]="userProfileData.users.roleTypeId != 1" class="form-control" [(ngModel)]="settingFormFields.bankName" name="bankName" maxlength="45">
                        </div>

                        <div class="form-group">
                          <label>Account Holder Name</label>
                          <input type="text" [disabled]="userProfileData.users.roleTypeId != 1" class="form-control" [(ngModel)]="settingFormFields.accountHolderName" name="accountHolderName" maxlength="45">
                        </div>

                        <div class="form-group">
                          <label>Company PAN (Capital Letters)</label>
                          <input type="text" (input)="gstPressingPan($event)" [disabled]="userProfileData.users.roleTypeId != 1" class="form-control" [(ngModel)]="settingFormFields.companyPan" name="companyPan" maxlength="10">
                        </div>

                        <div class="form-group">
                          <label>Account Number</label>
                          <input type="text" [disabled]="userProfileData.users.roleTypeId != 1" class="form-control" onkeypress='return event.charCode >= 48 && event.charCode <= 57' [(ngModel)]="settingFormFields.accountNumber" name="accountNumber" maxlength="18">
                        </div>

                        <div class="form-group">
                          <label>Branch Name</label>
                          <input type="text" [disabled]="userProfileData.users.roleTypeId != 1" class="form-control" [(ngModel)]="settingFormFields.branch" name="branch" maxlength="255">
                        </div>

                        <div class="form-group">
                          <label>IFSC Code</label>
                          <input type="text" [disabled]="userProfileData.users.roleTypeId != 1" maxlength="11" class="form-control" [(ngModel)]="settingFormFields.ifscCode" name="ifscCode">
                        </div>

                        <div class="form-group showBankDtlsOption">
                          <input type="checkbox" [(ngModel)]="settingFormFields.showBankDetails" name="showBankDetails">
                          <label>Show Bank Details</label>
                        </div>
                        </ng-container>
                      </tab>
                    </tabset>
                    <ng-container *ngIf="userProfileData">
                    <div class="formSubmitBtn" *ngIf="userProfileData.users.roleTypeId == 1">
                      <button class="btn btn-dark" type="submit">Submit</button>
                    </div>
                  </ng-container>
                  </form>
                  </div>
                </div>
              </div>
              <div class="col-md-8">

                <div class="templateInnerBox templateOne" [style.color]="color" *ngIf="templateSelected == 'PACIFIC'">
                  <div class="templateTitle">
                    Template 1
                  </div>
                  <div class="template_container">
                    <div class="row c-align">
                      <div class="col-md-6">
                        <div class="companyTitle" *ngIf="settingFormFields.businessName == undefined">Cronberry Pvt. Ltd.</div>
                        <div class="companyTitle" *ngIf="settingFormFields.businessName != undefined">{{settingFormFields.businessName}}</div>
                        <div class="smallFont_additionalDetail">
                          <div class="CompanyAddress" *ngIf="settingFormFields.address == undefined">
                            T2 Tirthraj Apartment, Jacob Road, Civil Lines, Jaipur, Rajasthan 302017
                          </div>
                          <div class="CompanyAddress" *ngIf="settingFormFields.address != undefined">
                            {{settingFormFields.address}}
                          </div>
                          <span *ngIf="settingFormFields.city">{{settingFormFields.city}},</span>
                          <span *ngIf="settingFormFields.state">{{settingFormFields.state}},</span>
                          <span *ngIf="settingFormFields.postalCode">{{settingFormFields.postalCode}}</span>
                          <div class="mobileNo" *ngIf="settingFormFields.phoneNumber == undefined">Mobile no.: +91 9878712123</div>
                          <div class="mobileNo" *ngIf="settingFormFields.phoneNumber != undefined">Mobile no.:{{settingFormFields.phoneNumber}}</div>

                          <div class="emailId" *ngIf="settingFormFields.email == undefined">Email : info@cronberry.com</div>
                          <div class="emailId" *ngIf="settingFormFields.email != undefined">Email : {{settingFormFields.email}}</div>

                          <div class="gstNumber">GST NO.:  {{settingFormFields.gstNumber}}</div>
                        </div>
                      </div>
                      <div class="col-md-6 text-right d-flex j-center">
                        <div class="bannerResizer min_width_20 min_height_20" #logoImage [style.height]="settingFormFields.logoHeight" [style.width]="settingFormFields.logoWidth">
                          <!-- <img class="img-responsive mainBannerImage" [src]="dataModel.allInOneBannerImage" alt="" /> -->
                          <img [src]="settingFormFields.logo?settingFormFields.logo:''" class="max_width_100">
                        </div>                      
                      </div>
                    </div>
                  </div>

                <div class="taxInvoiceDetail">
                      <div class="taxInvoiceTitle">
                        Proforma Invoice <span *ngIf="settingFormFields.invoicePrefix">{{settingFormFields.invoicePrefix}}</span>01<span *ngIf="settingFormFields.invoiceSuffix">{{settingFormFields.invoiceSuffix}}</span>
                      </div>

                    <div class="row">
                      <div class="col-md-4">
                        <div class="box_title_invoice">Bill To</div>
                        <div class="smallFont_additionalDetail">
                          <div class="firmName">Worknrby</div>
                          <div class="firmAddress">T4, Mansarovar, Jaipur, Rajasthan India</div>
                          <div class="firmGstNum">GST NO.:</div>
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="box_title_invoice">Ship To</div>
                        <div class="smallFont_additionalDetail">
                          <div class="firmName">Worknrby</div>
                          <div class="firmAddress">T4, Mansarovar, Jaipur, Rajasthan India</div>
                          <div class="firmGstNum">GST NO.:</div>
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="box_title_invoice">&nbsp;</div>
                        <div class="smallFont_additionalDetail">
                          <div class="">Due Date :<span class="semiBoldFont"> 7th Sep 2019</span></div>
                          <div class="">Date :<span class="semiBoldFont"> 12th Sep 2019</span></div>
                          <div class="">Terms :<span class="semiBoldFont"> Net 30</span></div>
                        </div>
                      </div>

                    </div>

                    <div class="table_format">
                      <div class="table-responsive">
                        <table class="table">
                          <tr>
                            <th [style.color]="color" [style.background]="bGcolor">Sr No.</th>
                            <th [style.color]="color" [style.background]="bGcolor">HSN/SAC</th>
                            <th [style.color]="color" [style.background]="bGcolor">Product</th>
                            <th [style.color]="color" [style.background]="bGcolor">Description</th>
                            <th [style.color]="color" [style.background]="bGcolor">Tax</th>
                            <th [style.color]="color" [style.background]="bGcolor">Qty</th>
                            <th [style.color]="color" [style.background]="bGcolor">Rate</th>
                            <th [style.color]="color" [style.background]="bGcolor">Discount</th>
                            <th [style.color]="color" [style.background]="bGcolor">Amount</th>
                          </tr>
                          <tr>
                            <td [style.color]="color">1</td>
                            <td [style.color]="color">123456</td>
                            <td [style.color]="color">Item Name</td>
                            <td [style.color]="color">Description Of the Item</td>
                            <td [style.color]="color">20.0%</td>
                            <td [style.color]="color">5</td>
                            <td [style.color]="color">225.00</td>
                            <td [style.color]="color">20%</td>
                            <td [style.color]="color">450.00</td>
                          </tr>
                          <tr>
                            <td [style.color]="color">2</td>
                            <td [style.color]="color">123456</td>
                            <td [style.color]="color">Item Name</td>
                            <td [style.color]="color">Description Of the Item</td>
                            <td [style.color]="color">20.0%</td>
                            <td [style.color]="color">5</td>
                            <td [style.color]="color">225.00</td>
                            <td [style.color]="color">20%</td>
                            <td [style.color]="color">450.00</td>
                          </tr>
                          
                          <tr class="countFormatRow">
                            <td colspan="9">
                              <div class="left_bank_dtl_col semiBoldFont" [style.color]="color" *ngIf="settingFormFields.showBankDetails == true">
                                <div class="dtlGroup">
                                  <div class="colHeading">Account Holder's Name</div>
                                  <div class="colDetail">{{settingFormFields.accountHolderName}}</div>
                                </div>
                                <div class="dtlGroup">
                                  <div class="colHeading">Account Number</div>
                                  <div class="colDetail">{{settingFormFields.accountNumber}}</div>
                                </div>
                                <div class="dtlGroup">
                                  <div class="colHeading">Bank Name</div>
                                  <div class="colDetail">{{settingFormFields.bankName}}</div>
                                </div>
                                <div class="dtlGroup">
                                  <div class="colHeading">Branch Name</div>
                                  <div class="colDetail">{{settingFormFields.branch}}</div>
                                </div>
                                <div class="dtlGroup">
                                  <div class="colHeading">IFSC Code</div>
                                  <div class="colDetail">{{settingFormFields.ifscCode}}</div>
                                </div>
                                <div class="dtlGroup">
                                  <div class="colHeading">Company PAN</div>
                                  <div class="colDetail">{{settingFormFields.companyPan}}</div>
                                </div>
                              </div>

                              <div class="right_count_details semiBoldFont" [style.color]="color">
                                <div class="dtlGroup">
                                  <div class="colHeading">Subtotal</div>
                                  <div class="colDetail">&#8377; 675.00</div>
                                </div>

                                <div class="dtlGroup">
                                  <div class="colHeading">Total tax</div>
                                  <div class="colDetail">&#8377; 101.25</div>
                                </div>

                                <div class="dtlGroup">
                                  <div class="colHeading">Total</div>
                                  <div class="colDetail">&#8377;  776.25</div>
                                </div>

                                <div class="dtlGroup">
                                  <div class="colHeading">Round off</div>
                                  <div class="colDetail">&#8377;  -0.25</div>
                                </div>

                                <div class="dtlGroup">
                                  <div class="colHeading">Balance Due</div>
                                  <div class="colDetail">&#8377; 776</div>
                                </div>
                              </div>

                            </td>
                          </tr>
                          
                        </table>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12">
                        <div class="signatureLogoBox">
                        <div class="bannerResizer min_height_20 min_width_20 d-inline-block" #signImage [style.height]="settingFormFields.signatureHeight" [style.width]="settingFormFields.signatureWidth">
                          <!-- <img class="img-responsive mainBannerImage" [src]="dataModel.allInOneBannerImage" alt="" /> -->
                          <img [src]="settingFormFields.signature?settingFormFields.signature:''" class="max_width_100">
                        </div> 
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div class="templateInnerBox templateTwo" [style.color]="color"  *ngIf="templateSelected == 'ATLANTIC'">
                  <div class="templateTitle">
                    Template 2
                  </div>
                  <div class="taxInvoiceTitle">
                    Proforma Invoice <span *ngIf="settingFormFields.invoicePrefix">{{settingFormFields.invoicePrefix}}</span>01<span *ngIf="settingFormFields.invoiceSuffix">{{settingFormFields.invoiceSuffix}}</span>
                  </div>
                  <div class="template_container">
                    <div class="row c-align">
                      <div class="col-md-6">
                        <div class="companyTitle" *ngIf="settingFormFields.businessName == undefined">Cronberry Pvt. Ltd.</div>
                        <div class="companyTitle" *ngIf="settingFormFields.businessName != undefined">{{settingFormFields.businessName}}</div>
                        <div class="smallFont_additionalDetail">
                          <div class="CompanyAddress" *ngIf="settingFormFields.address == undefined">
                            T2 Tirthraj Apartment, Jacob Road, Civil Lines, Jaipur, Rajasthan 302017
                          </div>
                          <div class="CompanyAddress" *ngIf="settingFormFields.address != undefined">
                            {{settingFormFields.address}}
                          </div>
                          <span *ngIf="settingFormFields.city">{{settingFormFields.city}},</span>
                          <span *ngIf="settingFormFields.state">{{settingFormFields.state}},</span>
                          <span *ngIf="settingFormFields.postalCode">{{settingFormFields.postalCode}}</span>
                          <div class="mobileNo" *ngIf="settingFormFields.phoneNumber == undefined">Mobile no.: +91 9878712123</div>
                          <div class="mobileNo" *ngIf="settingFormFields.phoneNumber != undefined">Mobile no.:{{settingFormFields.phoneNumber}}</div>

                          <div class="emailId" *ngIf="settingFormFields.email == undefined">Email : info@cronberry.com</div>
                          <div class="emailId" *ngIf="settingFormFields.email != undefined">Email : {{settingFormFields.email}}</div>

                          <div class="gstNumber">GST NO.: {{settingFormFields.gstNumber}}</div>
                        </div>
                      </div>
                      <div class="col-md-6 text-right d-flex j-center">
                        <div class="bannerResizer min_height_20 min_width_20" #logoImage [style.height]="settingFormFields.logoHeight" [style.width]="settingFormFields.logoWidth">
                          <!-- <img class="img-responsive mainBannerImage" [src]="dataModel.allInOneBannerImage" alt="" /> -->
                          <img [src]="settingFormFields.logo?settingFormFields.logo:''" class="max_width_100">
                        </div>
                      </div>
                    </div>
                  </div>

                <div class="taxInvoiceDetail">
                      
                  <div class="billToShipTo">
                    <div class="one_half_detail">
                      <div class="box_title_cstmr_detail" [style.background]="bGcolor">
                        Bill To
                      </div>
                      <div class="cstmrDetailInner">
                        <div class="compny_name_">
                          Worknrby
                        </div>
                        <div class="company_address_">
                          T4, mansarovar, jaipur
                        </div>
                        <div class="compny_gst_num">
                          GST NO. :
                        </div>
                      </div>
                      </div>
                    
                      <div class="one_half_detail">
                        <div class="box_title_cstmr_detail" [style.background]="bGcolor">
                          Ship To
                        </div>
                        <div class="cstmrDetailInner">
                          <div class="compny_name_">
                            Abhishek Joshi
                          </div>
                          <div class="company_address_">
                            T4, mansarovar, jaipur
                          </div>
                          <div class="compny_gst_num">
                            Mobile no. : +91 9878712183
                          </div>
                        </div>
                      </div>
                    </div>

                  <div class="invoiceDetailsDue">
                    <div class="table-responsive">
                      <table class="table">
                        <tr>
                          <th [style.color]="color" [style.background]="bGcolor">Invoice no.</th>
                          <th [style.color]="color" [style.background]="bGcolor">Date</th>
                          <th [style.color]="color" [style.background]="bGcolor">Total Due</th>
                          <th [style.color]="color" [style.background]="bGcolor">Due Date</th>
                          <th [style.color]="color" [style.background]="bGcolor">Terms</th>
                        </tr>
                        <tr>
                          <td [style.color]="color">123456</td>
                          <td [style.color]="color">07/01/2018</td>
                          <td [style.color]="color">&#8377; 665.00</td>
                          <td [style.color]="color">24/07/2018</td>
                          <td [style.color]="color">Net 30</td>
                        </tr>
                      </table>
                    </div>
                  </div>

                    <div class="table_format">
                      <div class="table-responsive">
                        <table class="table">
                          <tr>
                            <th [style.color]="color" [style.background]="bGcolor">Sr No.</th>
                            <th [style.color]="color" [style.background]="bGcolor">HSN/SAC</th>
                            <th [style.color]="color" [style.background]="bGcolor">Product</th>
                            <th [style.color]="color" [style.background]="bGcolor">Description</th>
                            <th [style.color]="color" [style.background]="bGcolor">Tax</th>
                            <th [style.color]="color" [style.background]="bGcolor">Qty</th>
                            <th [style.color]="color" [style.background]="bGcolor">Rate</th>
                            <th [style.color]="color" [style.background]="bGcolor">Discount</th>
                            <th [style.color]="color" [style.background]="bGcolor">Amount</th>
                          </tr>
                          <tr>
                            <td [style.color]="color">1</td>
                            <td [style.color]="color">123456</td>
                            <td [style.color]="color">Item Name</td>
                            <td [style.color]="color">Description Of the Item</td>
                            <td [style.color]="color">20.0%</td>
                            <td [style.color]="color">5</td>
                            <td [style.color]="color">225.00</td>
                            <td [style.color]="color">20%</td>
                            <td [style.color]="color">450.00</td>
                          </tr>
                          <tr>
                            <td [style.color]="color">2</td>
                            <td [style.color]="color">123456</td>
                            <td [style.color]="color">Item Name</td>
                            <td [style.color]="color">Description Of the Item</td>
                            <td [style.color]="color">20.0%</td>
                            <td [style.color]="color">5</td>
                            <td [style.color]="color">225.00</td>
                            <td [style.color]="color">20%</td>
                            <td [style.color]="color">450.00</td>
                          </tr>
                          <tr class="countFormatRow">
                            <td colspan="9">
                              <div class="left_bank_dtl_col semiBoldFont" [style.color]="color" *ngIf="settingFormFields.showBankDetails == true">
                                <div class="dtlGroup">
                                  <div class="colHeading">Account Holder's Name</div>
                                  <div class="colDetail">{{settingFormFields.accountHolderName}}</div>
                                </div>
                                <div class="dtlGroup">
                                  <div class="colHeading">Account Number</div>
                                  <div class="colDetail">{{settingFormFields.accountNumber}}</div>
                                </div>
                                <div class="dtlGroup">
                                  <div class="colHeading">Bank Name</div>
                                  <div class="colDetail">{{settingFormFields.bankName}}</div>
                                </div>
                                <div class="dtlGroup">
                                  <div class="colHeading">Branch Name</div>
                                  <div class="colDetail">{{settingFormFields.branch}}</div>
                                </div>
                                <div class="dtlGroup">
                                  <div class="colHeading">IFSC Code</div>
                                  <div class="colDetail">{{settingFormFields.ifscCode}}</div>
                                </div>
                                <div class="dtlGroup">
                                  <div class="colHeading">Company PAN</div>
                                  <div class="colDetail">{{settingFormFields.companyPan}}</div>
                                </div>
                              </div>

                              <div class="right_count_details semiBoldFont" [style.color]="color">
                                <div class="dtlGroup">
                                  <div class="colHeading">Subtotal</div>
                                  <div class="colDetail">&#8377; 675.00</div>
                                </div>

                                <div class="dtlGroup">
                                  <div class="colHeading">Total tax</div>
                                  <div class="colDetail">&#8377; 101.25</div>
                                </div>

                                <div class="dtlGroup">
                                  <div class="colHeading">Total</div>
                                  <div class="colDetail">&#8377;  776.25</div>
                                </div>

                                <div class="dtlGroup">
                                  <div class="colHeading">Round off</div>
                                  <div class="colDetail">&#8377;  -0.25</div>
                                </div>

                                <div class="dtlGroup">
                                  <div class="colHeading">Balance Due</div>
                                  <div class="colDetail">&#8377; 776</div>
                                </div>
                              </div>

                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12">
                        <div class="signatureLogoBox">
                        <div class="bannerResizer min_height_20 min_width_20 d-inline-block" #signImage [style.height]="settingFormFields.signatureHeight" [style.width]="settingFormFields.signatureWidth">
                          <!-- <img class="img-responsive mainBannerImage" [src]="dataModel.allInOneBannerImage" alt="" /> -->
                          <img [src]="settingFormFields.signature?settingFormFields.signature:''" class="max_width_100">
                        </div> 
                      </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div class="templateInnerBox templateThree" *ngIf="templateSelected == 'INDIAN'" [style.color]="color">
                  <div class="templateTitle">
                    Template 3
                  </div>
                  <div class="template_container">
                    <div class="row c-align">
                      <div class="col-md-6">
                        <div class="companyTitle" *ngIf="settingFormFields.businessName == undefined">Cronberry Pvt. Ltd.</div>
                        <div class="companyTitle" *ngIf="settingFormFields.businessName != undefined">{{settingFormFields.businessName}}</div>
                        <div class="smallFont_additionalDetail">
                          <div class="CompanyAddress" *ngIf="settingFormFields.address == undefined">
                            T2 Tirthraj Apartment, Jacob Road, Civil Lines, Jaipur, Rajasthan 302017
                          </div>
                          <div class="CompanyAddress" *ngIf="settingFormFields.address != undefined">
                            {{settingFormFields.address}}
                          </div>
                          <span *ngIf="settingFormFields.city">{{settingFormFields.city}},</span>
                          <span *ngIf="settingFormFields.state">{{settingFormFields.state}},</span>
                          <span *ngIf="settingFormFields.postalCode">{{settingFormFields.postalCode}}</span>
                          <div class="mobileNo" *ngIf="settingFormFields.phoneNumber == undefined">Mobile no.: +91 9878712123</div>
                          <div class="mobileNo" *ngIf="settingFormFields.phoneNumber != undefined">Mobile no.:{{settingFormFields.phoneNumber}}</div>

                          <div class="emailId" *ngIf="settingFormFields.email == undefined">Email : info@cronberry.com</div>
                          <div class="emailId" *ngIf="settingFormFields.email != undefined">Email : {{settingFormFields.email}}</div>

                          <div class="gstNumber">GST NO.: {{settingFormFields.gstNumber}}</div>
                        </div>
                      </div>
                      <div class="col-md-6 text-right d-flex j-center">
                        <div class="bannerResizer min_height_20 min_width_20" #logoImage [style.height]="settingFormFields.logoHeight" [style.width]="settingFormFields.logoWidth">
                          <!-- <img class="img-responsive mainBannerImage" [src]="dataModel.allInOneBannerImage" alt="" /> -->
                          <img [src]="settingFormFields.logo?settingFormFields.logo:''" class="max_width_100">
                        </div>                      </div>
                    </div>
                  </div>

                <div class="taxInvoiceDetail">
                      <div class="taxInvoiceTitle">
                        Proforma Invoice <span *ngIf="settingFormFields.invoicePrefix">{{settingFormFields.invoicePrefix}}</span>01<span *ngIf="settingFormFields.invoiceSuffix">{{settingFormFields.invoiceSuffix}}</span>
                      </div>

                    <div class="row">
                      <div class="col-md-4">
                        <div class="box_title_invoice">Bill To</div>
                        <div class="smallFont_additionalDetail">
                          <div class="firmName">Worknrby</div>
                          <div class="firmAddress">T4, Mansarovar, Jaipur, Rajasthan India</div>
                          <div class="firmGstNum">GST NO.:</div>
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="box_title_invoice">Ship To</div>
                        <div class="smallFont_additionalDetail">
                          <div class="firmName">Worknrby</div>
                          <div class="firmAddress">T4, Mansarovar, Jaipur, Rajasthan India</div>
                          <div class="firmGstNum">GST NO.:</div>
                        </div>
                      </div>

                      <div class="col-md-4 invoiceDateDetail">
                        <div class="box_title_invoice">&nbsp;</div>
                        <div class="smallFont_additionalDetail" [style.background]="bGcolor">
                          <table>
                            

                            <tr>
                              <td class="text-right">
                                Due Date :
                              </td>
                              <td>
                                <span class="semiBoldFont"> 7th Sep 2019</span>
                              </td>
                            </tr>

                            <tr>
                              <td class="text-right">
                                Date :
                              </td>
                              <td>
                                <span class="semiBoldFont"> 12th Sep 2019</span>
                              </td>
                            </tr>

                          </table>
                        </div>
                      </div>

                    </div>

                    <div class="table_format">
                      <div class="table-responsive">
                        <table class="table">
                          <tr>
                            <th [style.color]="color" [style.background]="bGcolor">Sr No.</th>
                            <th [style.color]="color" [style.background]="bGcolor">HSN/SAC</th>
                            <th [style.color]="color" [style.background]="bGcolor">Product</th>
                            <th [style.color]="color" [style.background]="bGcolor">Description</th>
                            <th [style.color]="color" [style.background]="bGcolor">Tax</th>
                            <th [style.color]="color" [style.background]="bGcolor">Qty</th>
                            <th [style.color]="color" [style.background]="bGcolor">Rate</th>
                            <th [style.color]="color" [style.background]="bGcolor">Discount</th>
                            <th [style.color]="color" [style.background]="bGcolor">Amount</th>
                          </tr>
                          <tr>
                            <td [style.color]="color">1</td>
                            <td [style.color]="color">123456</td>
                            <td [style.color]="color">Item Name</td>
                            <td [style.color]="color">Description Of the Item</td>
                            <td [style.color]="color">20.0%</td>
                            <td [style.color]="color">5</td>
                            <td [style.color]="color">225.00</td>
                            <td [style.color]="color">20%</td>
                            <td [style.color]="color">450.00</td>
                          </tr>
                          <tr>
                            <td [style.color]="color">2</td>
                            <td [style.color]="color">123456</td>
                            <td [style.color]="color">Item Name</td>
                            <td [style.color]="color">Description Of the Item</td>
                            <td [style.color]="color">20.0%</td>
                            <td [style.color]="color">5</td>
                            <td [style.color]="color">225.00</td>
                            <td [style.color]="color">20%</td>
                            <td [style.color]="color">450.00</td>
                          </tr>
                          
                          <tr class="countFormatRow">
                            <td colspan="9">
                              <div class="left_bank_dtl_col semiBoldFont" [style.color]="color" *ngIf="settingFormFields.showBankDetails == true">
                                <div class="dtlGroup">
                                  <div class="colHeading">Account Holder's Name</div>
                                  <div class="colDetail">{{settingFormFields.accountHolderName}}</div>
                                </div>
                                <div class="dtlGroup">
                                  <div class="colHeading">Account Number</div>
                                  <div class="colDetail">{{settingFormFields.accountNumber}}</div>
                                </div>
                                <div class="dtlGroup">
                                  <div class="colHeading">Bank Name</div>
                                  <div class="colDetail">{{settingFormFields.bankName}}</div>
                                </div>
                                <div class="dtlGroup">
                                  <div class="colHeading">Branch Name</div>
                                  <div class="colDetail">{{settingFormFields.branch}}</div>
                                </div>
                                <div class="dtlGroup">
                                  <div class="colHeading">IFSC Code</div>
                                  <div class="colDetail">{{settingFormFields.ifscCode}}</div>
                                </div>
                                <div class="dtlGroup">
                                  <div class="colHeading">Company PAN</div>
                                  <div class="colDetail">{{settingFormFields.companyPan}}</div>
                                </div>
                              </div>

                              <div class="right_count_details semiBoldFont" [style.color]="color">
                                <div class="dtlGroup">
                                  <div class="colHeading">Subtotal</div>
                                  <div class="colDetail">&#8377; 675.00</div>
                                </div>

                                <div class="dtlGroup">
                                  <div class="colHeading">Total tax</div>
                                  <div class="colDetail">&#8377; 101.25</div>
                                </div>

                                <div class="dtlGroup">
                                  <div class="colHeading">Total</div>
                                  <div class="colDetail">&#8377;  776.25</div>
                                </div>

                                <div class="dtlGroup">
                                  <div class="colHeading">Round off</div>
                                  <div class="colDetail">&#8377;  -0.25</div>
                                </div>

                                <div class="dtlGroup">
                                  <div class="colHeading">Balance Due</div>
                                  <div class="colDetail">&#8377; 776</div>
                                </div>
                              </div>

                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12">
                        <div class="signatureLogoBox">
                        <div class="bannerResizer min_height_20 min_width_20 d-inline-block" #signImage [style.height]="settingFormFields.signatureHeight" [style.width]="settingFormFields.signatureWidth">
                          <!-- <img class="img-responsive mainBannerImage" [src]="dataModel.allInOneBannerImage" alt="" /> -->
                          <img [src]="settingFormFields.signature?settingFormFields.signature:''" class="max_width_100">
                        </div> 
                      </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div class="templateInnerBox templateFour" *ngIf="templateSelected == 'ARCTIC'" [style.color]="color">
                  <div class="templateTitle">
                    Template 4
                  </div>
                  <div class="template_container">
                    <div class="taxInvoiceTitle">
                      Proforma Invoice <span *ngIf="settingFormFields.invoicePrefix">{{settingFormFields.invoicePrefix}}</span>01<span *ngIf="settingFormFields.invoiceSuffix">{{settingFormFields.invoiceSuffix}}</span>
                    </div>
                    <div class="row c-align">
                      <div class="col-md-6">
                        <div class="companyTitle" *ngIf="settingFormFields.businessName == undefined">Cronberry Pvt. Ltd.</div>
                        <div class="companyTitle" *ngIf="settingFormFields.businessName != undefined">{{settingFormFields.businessName}}</div>
                        <div class="smallFont_additionalDetail">
                          <div class="CompanyAddress" *ngIf="settingFormFields.address == undefined">
                            T2 Tirthraj Apartment, Jacob Road, Civil Lines, Jaipur, Rajasthan 302017
                          </div>
                          <div class="CompanyAddress" *ngIf="settingFormFields.address != undefined">
                            {{settingFormFields.address}}
                          </div>
                          <span *ngIf="settingFormFields.city">{{settingFormFields.city}},</span>
                          <span *ngIf="settingFormFields.state">{{settingFormFields.state}},</span>
                          <span *ngIf="settingFormFields.postalCode">{{settingFormFields.postalCode}}</span>
                          <div class="mobileNo" *ngIf="settingFormFields.phoneNumber == undefined">Mobile no.: +91 9878712123</div>
                          <div class="mobileNo" *ngIf="settingFormFields.phoneNumber != undefined">Mobile no.:{{settingFormFields.phoneNumber}}</div>

                          <div class="emailId" *ngIf="settingFormFields.email == undefined">Email : info@cronberry.com</div>
                          <div class="emailId" *ngIf="settingFormFields.email != undefined">Email : {{settingFormFields.email}}</div>

                          <div class="gstNumber">GST NO.: {{settingFormFields.gstNumber}}</div>
                        </div>
                      </div>
                      <div class="col-md-6 text-right d-flex j-center">
                        <div class="bannerResizer min_height_20 min_width_20" #logoImage [style.height]="settingFormFields.logoHeight" [style.width]="settingFormFields.logoWidth">
                          <!-- <img class="img-responsive mainBannerImage" [src]="dataModel.allInOneBannerImage" alt="" /> -->
                          <img [src]="settingFormFields.logo?settingFormFields.logo:''" class="max_width_100">
                        </div>                      </div>
                    </div>
                  </div>

                  <div class="taxInvoiceDetail">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="box_title_invoice">Bill To</div>
                            <div class="smallFont_additionalDetail">
                              <div class="firmName">Worknrby</div>
                              <div class="firmAddress">T4, Mansarovar, Jaipur, Rajasthan India</div>
                              <div class="firmGstNum">GST NO.:</div>
                            </div>
                          </div>
  
                          <div class="col-md-6">
                            <div class="box_title_invoice">Ship To</div>
                            <div class="smallFont_additionalDetail">
                              <div class="firmName">Worknrby</div>
                              <div class="firmAddress">T4, Mansarovar, Jaipur, Rajasthan India</div>
                              <div class="firmGstNum">GST NO.:</div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6">
                          <div class="invoiceDueDate">
                            <div class="invoiceDateDevider light_blue" [style.background]="bGcolor">
                              <div class="dateTitle">DATE :</div>
                              <div class="date_invoice_semiBold">7th Sep 2019</div>
                            </div>
                            <div class="invoiceDateDevider dark_blue" [style.background]="bGcolor">
                              <div class="dateTitle">PLEASE PAY :</div>
                              <div class="date_invoice_semiBold">&#8377; 776</div>
                            </div>
                            <div class="invoiceDateDevider light_blue" [style.background]="bGcolor">
                              <div class="dateTitle">DUE DATE : </div>
                              <div class="date_invoice_semiBold">12th Sep 2019</div>
                            </div>
                          </div>
                      </div>
                    </div>
                      

                    <div class="table_format">
                      <div class="table-responsive">
                        <table class="table">
                          <tr>
                            <th [style.color]="color" [style.background]="bGcolor">Sr No.</th>
                            <th [style.color]="color" [style.background]="bGcolor">HSN/SAC</th>
                            <th [style.color]="color" [style.background]="bGcolor">Product</th>
                            <th [style.color]="color" [style.background]="bGcolor">Description</th>
                            <th [style.color]="color" [style.background]="bGcolor">Tax</th>
                            <th [style.color]="color" [style.background]="bGcolor">Qty</th>
                            <th [style.color]="color" [style.background]="bGcolor">Rate</th>
                            <th [style.color]="color" [style.background]="bGcolor">Discount</th>
                            <th [style.color]="color" [style.background]="bGcolor">Amount</th>
                          </tr>
                          <tr>
                            <td [style.color]="color">1</td>
                            <td [style.color]="color">123456</td>
                            <td [style.color]="color">Item Name</td>
                            <td [style.color]="color">Description Of the Item</td>
                            <td [style.color]="color">20.0%</td>
                            <td [style.color]="color">5</td>
                            <td [style.color]="color">225.00</td>
                            <td [style.color]="color">20%</td>
                            <td [style.color]="color">450.00</td>
                          </tr>
                          <tr>
                            <td [style.color]="color">2</td>
                            <td [style.color]="color">123456</td>
                            <td [style.color]="color">Item Name</td>
                            <td [style.color]="color">Description Of the Item</td>
                            <td [style.color]="color">20.0%</td>
                            <td [style.color]="color">5</td>
                            <td [style.color]="color">225.00</td>
                            <td [style.color]="color">20%</td>
                            <td [style.color]="color">450.00</td>
                          </tr>
                          
                          <tr class="countFormatRow">
                            <td colspan="9">
                              <div class="left_bank_dtl_col semiBoldFont" [style.color]="color" *ngIf="settingFormFields.showBankDetails == true">
                                <div class="dtlGroup">
                                  <div class="colHeading">Account Holder's Name</div>
                                  <div class="colDetail">{{settingFormFields.accountHolderName}}</div>
                                </div>
                                <div class="dtlGroup">
                                  <div class="colHeading">Account Number</div>
                                  <div class="colDetail">{{settingFormFields.accountNumber}}</div>
                                </div>
                                <div class="dtlGroup">
                                  <div class="colHeading">Bank Name</div>
                                  <div class="colDetail">{{settingFormFields.bankName}}</div>
                                </div>
                                <div class="dtlGroup">
                                  <div class="colHeading">Branch Name</div>
                                  <div class="colDetail">{{settingFormFields.branch}}</div>
                                </div>
                                <div class="dtlGroup">
                                  <div class="colHeading">IFSC Code</div>
                                  <div class="colDetail">{{settingFormFields.ifscCode}}</div>
                                </div>
                                <div class="dtlGroup">
                                  <div class="colHeading">Company PAN</div>
                                  <div class="colDetail">{{settingFormFields.companyPan}}</div>
                                </div>
                              </div>

                              <div class="right_count_details semiBoldFont" [style.color]="color">
                                <div class="dtlGroup">
                                  <div class="colHeading">Subtotal</div>
                                  <div class="colDetail">&#8377; 675.00</div>
                                </div>

                                <div class="dtlGroup">
                                  <div class="colHeading">Total tax</div>
                                  <div class="colDetail">&#8377; 101.25</div>
                                </div>

                                <div class="dtlGroup">
                                  <div class="colHeading">Total</div>
                                  <div class="colDetail">&#8377;  776.25</div>
                                </div>

                                <div class="dtlGroup">
                                  <div class="colHeading">Round off</div>
                                  <div class="colDetail">&#8377;  -0.25</div>
                                </div>

                                <div class="dtlGroup">
                                  <div class="colHeading">Balance Due</div>
                                  <div class="colDetail">&#8377; 776</div>
                                </div>
                              </div>

                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12">
                        <div class="signatureLogoBox">
                        <div class="bannerResizer min_height_20 min_width_20 d-inline-block" #signImage [style.height]="settingFormFields.signatureHeight" [style.width]="settingFormFields.signatureWidth">
                          <!-- <img class="img-responsive mainBannerImage" [src]="dataModel.allInOneBannerImage" alt="" /> -->
                          <img [src]="settingFormFields.signature?settingFormFields.signature:''" class="max_width_100">
                        </div> 
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                

                <div class="templateInnerBox templateFive" *ngIf="templateSelected == 'SOUTHERN'" [style.color]="color">
                  <div class="templateTitle">
                    Template 5
                  </div>
                  <div class="template_container">
                    <div class="taxInvoiceTitle text-center">
                      Proforma Invoice <span *ngIf="settingFormFields.invoicePrefix">{{settingFormFields.invoicePrefix}}</span>01<span *ngIf="settingFormFields.invoiceSuffix">{{settingFormFields.invoiceSuffix}}</span>
                    </div>
                  </div>

                  <div class="taxInvoiceDetail">
                    <div class="table_format">
                      <div class="table-responsive">
                        <table class="table">
                          <tr>
                            <th [style.color]="color" [style.background]="bGcolor">Sr No.</th>
                            <th [style.color]="color" [style.background]="bGcolor">HSN/SAC</th>
                            <th [style.color]="color" [style.background]="bGcolor">Product</th>
                            <th [style.color]="color" [style.background]="bGcolor">Description</th>
                            <th [style.color]="color" [style.background]="bGcolor">Tax</th>
                            <th [style.color]="color" [style.background]="bGcolor">Qty</th>
                            <th [style.color]="color" [style.background]="bGcolor">Rate</th>
                            <th [style.color]="color" [style.background]="bGcolor">Discount</th>
                            <th [style.color]="color" [style.background]="bGcolor">Amount</th>
                          </tr>
                          <tr>
                            <td [style.color]="color">1</td>
                            <td [style.color]="color">123456</td>
                            <td [style.color]="color">Item Name</td>
                            <td [style.color]="color">Description Of the Item</td>
                            <td [style.color]="color">20.0%</td>
                            <td [style.color]="color">5</td>
                            <td [style.color]="color">225.00</td>
                            <td [style.color]="color">20%</td>
                            <td [style.color]="color">450.00</td>
                          </tr>
                          <tr>
                            <td [style.color]="color">2</td>
                            <td [style.color]="color">123456</td>
                            <td [style.color]="color">Item Name</td>
                            <td [style.color]="color">Description Of the Item</td>
                            <td [style.color]="color">20.0%</td>
                            <td [style.color]="color">5</td>
                            <td [style.color]="color">225.00</td>
                            <td [style.color]="color">20%</td>
                            <td [style.color]="color">450.00</td>
                          </tr>
                          
                          <tr class="countFormatRow">
                            <td colspan="9">
                              

                              <div class="right_count_details semiBoldFont" [style.color]="color">
                                <div class="dtlGroup">
                                  <div class="colHeading">Subtotal</div>
                                  <div class="colDetail">&#8377; 675.00</div>
                                </div>

                                <div class="dtlGroup">
                                  <div class="colHeading">Total tax</div>
                                  <div class="colDetail">&#8377; 101.25</div>
                                </div>

                                <div class="dtlGroup">
                                  <div class="colHeading">Total</div>
                                  <div class="colDetail">&#8377;  776.25</div>
                                </div>

                                <div class="dtlGroup">
                                  <div class="colHeading">Round off</div>
                                  <div class="colDetail">&#8377;  -0.25</div>
                                </div>

                                <div class="dtlGroup">
                                  <div class="colHeading">Balance Due</div>
                                  <div class="colDetail">&#8377; 776</div>
                                </div>
                              </div>

                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                

              </div>
            </div>
          </div>
       </div>   