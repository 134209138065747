<main>
    <section class="inner-page">
        <div class="inner-page-header">
            <div class="container-fluid">
                <h4>
                    <a class="back-link" [routerLink]="['/admin/announcement/bar']"><img src="assets/images/angle-left.svg"></a>
                    {{pageTitle}}
                </h4>
            </div>
        </div>

        <div class="inner-page-body">
            <div class="container-fluid">
                <form [formGroup]="siteForm" class="project-integration-tab configuration site-form">
                    <tabset #pageTabs>
                        <tab heading="Appearance">
                            <div class="row form-body">
                                <div class="col-md-7">
                                    <div class="form-group form-row">
                                        <label class="col-md-3 control-label required">Name</label>
                                        <div class="col-md-9">
                                            <input class="form-control" trim="blur" formControlName="name" type="text" placeholder="Name" maxlength="100">
                                        </div>
                                    </div>
                                    <div class="form-group form-row">
                                        <label class="col-md-3 control-label required">Background Color</label>
                                        <div class="col-md-3">
                                            <div class="color-box" [cpPosition]="'bottom'" [(colorPicker)]="siteForm.value.bgColor" [style.background]="siteForm.value.bgColor"
                                            (colorPickerChange)="siteForm.controls.bgColor.setValue($event)"></div>
                                        </div>
                                    </div>
                                    <div class="form-group form-row">
                                        <label class="col-md-3 control-label required">Close Button Color</label>
                                        <div class="col-md-3">
                                            <div class="color-box" [cpPosition]="'bottom'" [(colorPicker)]="siteForm.value.closeBtnColor" [style.background]="siteForm.value.closeBtnColor"
                                            (colorPickerChange)="siteForm.controls.closeBtnColor.setValue($event)"></div>
                                        </div>
                                    </div>
                                    <div class="form-group form-row">
                                        <label class="col-md-3 control-label required">Height</label>
                                        <div class="col-md-3">
                                            <div class="input-group">
                                                <input trim="blur" class="form-control" formControlName="height" type="number">
                                                <div class="input-group-append">
                                                    <span class="input-group-text">PX</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group form-row">
                                        <label class="col-md-3 control-label pt-1">Close Button</label>
                                        <div class="col-md-3">
                                            <div class="custom-control custom-switch swich-button">
                                                <input type="checkbox" name="CurrentPluginStatus" formControlName="closable" class="custom-control-input" id="formClosable" />
                                                <label class="custom-control-label" for="formClosable"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <button class="btn btn-sm btn-border mb-3" type="button" aria-label="Close" (click)="openGalleryModal()">Select Image</button>
                                        <app-ckeditor
                                            formControlName="bodyContent"
                                            [config]="config"
                                            [editorData]="ckeditorData"
                                        ></app-ckeditor>
                                    </div>
                                </div>
                                <div class="col-md-5">
                                    <div class="preview">
                                        <div class="bar-html">
                                            <div class="cb-topbar" [ngClass]="siteForm.value.barPosition" [ngStyle]="{'backgroundColor':siteForm.value.bgColor, 'height':siteForm.value.height*.75+'px'}">
                                                <div class="cb-block">
                                                    <a *ngIf="siteForm.value.closable" href="javascript:void(0)" class="cb-close">
                                                        <svg [attr.fill]="siteForm.value.closeBtnColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"/></svg>
                                                    </a>
                                                    <div class="cb-content"><div [innerHtml]="siteForm.value.bodyContent | safeHtml"></div></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </tab>
                        <tab heading="Trigger">
                            <div class="row form-body"><div class="col-md-7">
                                <div class="form-group form-row">
                                    <label class="col-md-3 control-label required">Start Time</label>
                                    <div class="col-md-6">
                                        <div class="input-group">
                                            <input trim="blur" class="form-control" formControlName="startTime" type="number" min="0" max="100">
                                            <div class="input-group-append">
                                                <span class="input-group-text">Second After Page Load</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group form-row">
                                    <label class="col-md-3 control-label required">Duration</label>
                                    <div class="col-md-4 d-flex duration">
                                        <select class="form-control" formControlName="durationType">
                                            <option value="always">Always</option>
                                            <option value="second">Second</option>
                                        </select>
                                        <input *ngIf="siteForm.controls.durationType.value != 'always'" trim="blur" class="form-control" formControlName="duration" type="number" min="1" max="100">
                                    </div>
                                </div>
                                <div class="form-group form-row">
                                    <label class="col-md-3 control-label required">Bar Position</label>
                                    <div class="col-md-4">
                                        <select class="form-control" formControlName="barPosition">
                                            <option value="top">Top</option>
                                            <option value="bottom">Bottom</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group form-row">
                                    <div class="col-md-3">
                                        <label class="control-label check">
                                            Clickable
                                            <input type="checkbox" formControlName="clickable" (change)="checkLinkClickable($event)">
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <div class="col-md-6">
                                        <input class="form-control" formControlName="link" type="text" maxlength="100" placeholder="https://example.com" [readonly]="!siteForm.controls.clickable.value">
                                    </div>
                                    <div class="col-md-3">
                                        <label class="control-label check font-13">
                                            Open in new tab?
                                            <input type="checkbox" formControlName="target">
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="form-group form-row">
                                    <label class="col-md-3 control-label required">Valid From/To</label>
                                    <div class="col-md-8">
                                        <div class="form-row">
                                        <div class="col-6 single-date">
                                            <input [type]="isUpdate?'hidden':'text'" placeholder="From Date" placement="top left" formControlName="fromDate" readonly (bsValueChange)="siteForm.controls['toDate'].setValue('')"
                                                class="form-control date-icon modal-space" [minDate]="isUpdate ? null : minDate"
                                                bsDatepicker autocomplete="off" [bsConfig]="global.datePickerConfig">
                                            <input *ngIf="isUpdate" type="text" placeholder="From Date" value="{{siteForm.controls.fromDate.value | date:'dd/MM/yyyy'}}" readonly class="form-control date-icon modal-space opacity-5 cursor-0">
                                        </div>
                                        <div class="col-6 single-date">
                                            <input type="text" placeholder="To Date" placement="top left" formControlName="toDate" readonly 
                                            class="form-control date-icon modal-space" [minDate]="isUpdate ? minDate : siteForm.controls.fromDate.value"
                                            bsDatepicker autocomplete="off" [bsConfig]="global.datePickerConfig">
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div></div>
                        </tab>
                        <tab heading="Target">
                            <div class="row form-body"><div class="col-md-7">
                                <div class="form-group form-row">
                                    <label class="col-md-3 control-label">Allowed URL</label>
                                    <div class="col-md-8">
                                        <textarea class="form-control" formControlName="allowPages" rows="6" maxlength="1000" placeholder="https://example1.com, https://example2.com" ></textarea>
                                        <div class="text-muted">Leave blank to allow for all pages.</div>
                                    </div>
                                </div>
                                <div class="form-group form-row">
                                    <label class="col-md-3 control-label required">Repeat Days</label>
                                    <div class="col-md-4">
                                        <div class="input-group">
                                            <input trim="blur" class="form-control" formControlName="repeatDays" type="number" min="0" max="20">
                                            <div class="input-group-append">
                                                <span class="input-group-text">Days</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group form-row">
                                    <label class="col-md-3 control-label">Open by Click</label>
                                    <div class="col-md-4">
                                        <input class="form-control" formControlName="openbyClick" placeholder="#id,.class" >
                                    </div>
                                </div>
                            </div></div>
                        </tab>
                    </tabset>

                    <div class="d-flex justify-content-between form-bottom">
                        <div class="custom-control custom-switch swich-button">
                            <span class="bm" [ngClass]="{ pink: siteForm.controls.deleteStatus.value}">Enable</span>
                            <input type="checkbox" name="CurrentPluginStatus" formControlName="deleteStatus" class="custom-control-input" id="formStatus" />
                            <label class="custom-control-label" for="formStatus"></label>
                            <span class="by" [ngClass]="{ pink: !siteForm.controls.deleteStatus.value }">Disable</span>
                        </div>
                        <div>
                            <button class="btn btn-dark" type="button" (click)="submitForm('PREVIEW'); isPreview = true;" >Preview</button> &nbsp; 
                            <button class="btn btn-dark" type="submit" [disabled]="!isPreview" (click)="submitForm('PUBLISH');">Publish</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </section>

</main>


<!-- <ng-template #galleryPopup>
    <gallery-popup [modalRefGallery]="modalRefGallery" (selectMediaEvent)="getMedia($event)" [galleryType]="'image'"></gallery-popup>
</ng-template> -->


<ng-template #confirm>
    <form>
      <div class="forgot-password-modal">
        <div class="modal-header">
          <h4 class="modal-title pull-left">Your bar design is saved</h4>
          <button type="button" class="close pull-right" aria-label="Close" routerLink="/admin/announcement/bar" (click)="modalRefConfirm.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body modal-user-management">
          <div class="form-group mb-0">
            <p class="pt-2">
              {{saveMsg}}
            </p>
            <div class="d-flex justify-content-end mt-5">
              <div>
                <button class="btn remove-user-btn mr-4" routerLink="/admin/announcement/bar" (click)="modalRefConfirm.hide()" type="button">
                    I have already added the script
                </button>
              </div>
              <div>
                <a class="btn save-btn" routerLink="/admin/configuration/announcement" (click)="modalRefConfirm.hide()">
                    Show me the script to add
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </ng-template>