import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "sort"
})
export class ArraySortPipe  implements PipeTransform {
  transform(array: any, field: string): any[] {
    if (!Array.isArray(array)) {
      return;
    }
    array.sort((a: any, b: any) => {      
      if (field == 'descending') {
        return -1;
      } else if (field == 'ascending') {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  }
}
