
import { Injectable } from '@angular/core';
import { ReportObj } from '../modal/report';
import { ApiConstants } from '../../constants/api.constants';
import { BaseService } from '../services/base.service';
import { DatePickerObj, DatePickerObjFollow } from '../modal/datePicker';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { delay } from 'rxjs/operators';
import { AppConstants } from '../../constants/app.constants';
import { AuthenticationService } from './authentication.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

    leadReportsData: ReportObj[];
    leadsDays:DatePickerObj;
    leadCountsReportsData:any;
    leadCountsReportsTime:any;
    leadCountsDays:DatePickerObj;
    leadCountsDaysFollow:DatePickerObjFollow
    sortByDate:any;
    constructor(
        private baseService: BaseService,
        private datePipe: DatePipe,
        private authenticService:AuthenticationService,
        private toaster:ToastrService
    ) { 
      this.fetchUserProfile()
    }

    fetchAudienceData(body:any, category) {
      return this.baseService.postRequest(ApiConstants.FETCH_AUDIENCE_DATA +'/'+category, body,false);
    }

    fetchAudienceDataForPaging(body:any) {
      return this.baseService.postRequest(ApiConstants.FETCH_AUDIENCE_DATA_FOR_PAGGING, body);
    }

    fetchIndividualAudienceData(body:any) {
      return this.baseService.postRequest(ApiConstants.FETCH_INDIVIDUAL_AUDIENCE_DATA, body);
    }

    fetchIndividualAudienceTimelineEntry(body:any) {
      return this.baseService.postRequest(ApiConstants.FETCH_INDIVIDUAL_AUDIENCE_TIMELINE_ENTRY, body);
    }

    downloadFileTypeData(body,category){
      return this.baseService.postRequest(ApiConstants.DOWNLOAD_AUDIENCE_DETAIL_FILE+category,body)
    }

    fetchIndividualCampaignAllData(body:any , url) {      
      return this.baseService.postRequest(url + ApiConstants.FETCH_INDIVIDUAL_CAMPAIGN_ALL_DATA, body);
    }

    fetchIndividualAudienceStats(body:any) {
      return this.baseService.postRequest(ApiConstants.FETCH_INDIVIDUAL_AUDIENCE_STATS, body);
    }

    upsertUserLeadAssign(body:any, menuParams) {
      return this.baseService.postRequest(ApiConstants.UPSERT_USER_LEAD_ASSIGN +'/'+menuParams, body);
    }

    shareLead(body:any,category) {
      return this.baseService.postRequest(ApiConstants.SHARE_LEAD+'/'+category, body);
    }

    leadCountsForAllUsers(body:any) {
      return this.baseService.postRequest(ApiConstants.LEAD_COUNTS_FOR_ALL_USERS, body);
    }

    leadCountsSourceAndUsers(body:any,category) {
      return this.baseService.postRequest(ApiConstants.LEAD_COUNTS_USERS+'/'+category, body);
    }

    leadCountsSource(body:any, category) {
      return this.baseService.postRequest(ApiConstants.LEAD_COUNTS_SOURCE +'/'+category, body);
    }

    createReport(body:any, category) {
      return this.baseService.postRequest(ApiConstants.CREATE_REPORT +'/'+category, body);
    }

    listReports(body:any, category) {
      return this.baseService.postRequest(ApiConstants.LIST_REPORTS +'/'+category, body, false);
    }

    reportDetail(body:any) {      
      return this.baseService.postRequest(ApiConstants.REPORT_DETAIL, body,false);
    }

    deleteReport(body:any,category) {
      if(category == undefined){
        return
      }
      return this.baseService.postRequest(ApiConstants.DELETE_REPORT+'/'+category, body);
    }

    fetchReport(body:any) {
      return this.baseService.postRequest(ApiConstants.FETCH_REPORT, body);
    }

    upsertUserLeadAssignBulk(body:any, menuParams) {
      return this.baseService.postRequest(ApiConstants.UPSERT_USER_LEAD_ASSIGN_BULK +'/'+menuParams, body);
    }

    fetchIndividualAudienceCallLog(body:any) {
      return this.baseService.postRequest(ApiConstants.FETCH_INDIVIDUAL_AUDIENCE_CALL_LOG, body);
    }

    fetchIvrCallLog(body:any) {
      console.log(body)
      return this.baseService.postRequest(ApiConstants.FETCH_IVR_CALL_LOG, body);
    }

    fetchCallLogs(body:any, size, page) {
      return this.baseService.postRequest(ApiConstants.FETCH_CALL_LOGS.replace(AppConstants.REPLACE_SIZE, size).replace(AppConstants.REPLACE_PAGE, page), body);
    }

    downloadAudienceReport(body:any) {
      return this.baseService.postRequest(ApiConstants.DOWNLOAD_AUDIENCE_REPORT, body);
    }

    upsertAudienceColumn(body:any,category) {
      if(category == undefined){
        return
      }
      return this.baseService.postRequest(ApiConstants.UPSERT_AUDIENCE_COLUMN+'/'+category, body);
    }
    
    fetchAudienceColumn(body:any) {
      return this.baseService.postRequest(ApiConstants.FETCH_AUDIENCE_COLUMN, body);
    }

    fetchLeadsCount(body:any, menuParams) {
      return this.baseService.postRequest(ApiConstants.FETCH_LEADS_COUNT +'/'+menuParams, body, false);
    }

    saveAudienceDataDownloadRequest(body:any,menuParams) {      
      return this.baseService.postRequest(ApiConstants.SAVE_AUDIENCE_DATA_DOWNLOAD_REQUEST +'/'+menuParams, body);
    }

    AudienceDataDownload(category:string,body:any) {
      return this.baseService.postRequest(ApiConstants.AUDIENCE_DATA_DOWNLOAD +'/'+category, body);
    }

    moveLeadByCat(body:any,category) {
      if(category == undefined){
        return
      }
      return this.baseService.postRequest(ApiConstants.MOVE_LEAD_BY_CATEGORY+'/'+category, body);
    }

    sendFollowUpEmail(body){
      return this.baseService.postRequest(ApiConstants.FOLLOW_UP_COUNTS_USERS_SEND_EMAIL,body)
    }

    sendLeadReportEmail(category,body){
      return this.baseService.postRequest(ApiConstants.LEAD_REPORT_SEND_EMAIL+'/'+category,body)
    }

    followUpData(date:DatePickerObj,sortDate, category) {
      var postData  = {
        startDate: this.datePipe.transform(date.startDate, "yyyy-MM-dd"),
        endDate: this.datePipe.transform(date.endDate, "yyyy-MM-dd"),
        "reportDateType":sortDate
      }
      this.leadCountsDaysFollow = date;
      return this.baseService.postRequest(ApiConstants.FOLLOW_UP_COUNTS_USERS +'/'+category, postData);
    }

    fetchUserProfile(){
      this.authenticService.getProfile().then(data=>{
          if(data.status){            
              this.sortByDate = data.sortBy;
          }
      })
  }

    async getReportListData(date:DatePickerObj,menuParams, showToDashboard?,reload?){
      this.fetchUserProfile();
      if(reload == 'reload'){
        this.leadReportsData = [];
      }
      if(this.leadsDays != undefined && 
          (!this.leadsDays.startDate.isSame(date.startDate) || 
          !this.leadsDays.endDate.isSame(date.endDate))
          ){
              this.leadReportsData = undefined;
          }
      if(this.leadReportsData == undefined || this.leadReportsData.length == 0){
          var postData  = {
              fromDate: this.datePipe.transform(date.startDate, "yyyy-MM-dd"),
              toDate: this.datePipe.transform(date.endDate, "yyyy-MM-dd"),
              showToDashboard: showToDashboard,
              reportDateType:this.sortByDate
          }
          
          var requestData = await this.listReports(postData, menuParams).pipe(delay(200)).toPromise();
          this.leadsDays = date;
          this.leadReportsData =  requestData.data;
          if(requestData.status == false && menuParams != 'dashboard'){
            this.toaster.error(requestData.error_msgs);
          }
      };
      return this.leadReportsData;
    }
    
    async getleadCountsData(date:DatePickerObj,sortByDate,parentMenu){
            var postData  = {
                startDate: this.datePipe.transform(date.startDate, "yyyy-MM-dd"),
                endDate: this.datePipe.transform(date.endDate, "yyyy-MM-dd"),
                reportDateType:sortByDate
            }
            postData.startDate = postData.startDate.split('T')[0];
            postData.endDate = postData.endDate.split('T')[0];
            
            var requestData = await this.leadCountsSourceAndUsers(postData,parentMenu).pipe(delay(200)).toPromise();
            this.leadCountsDays = date;
            this.leadCountsReportsData = requestData;
            this.leadCountsReportsTime = new Date().getTime();
        return this.leadCountsReportsData;
    }

    checkApiCallTime(lastTime){
        var time = lastTime + (10 * 60 * 1000);
        var nowTime = new Date().getTime();
        return time < nowTime;
    }
}
