<main>
    <section class="inner-page email-editor">
        <div class="inner-page-header">
            <div class="container-fluid">
                <h4>Email Editor</h4>
            </div>
        </div>
        <div class="inner-page-body">
            <email-editor></email-editor>
        </div>
    </section>
</main>

