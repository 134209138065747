import { Component, OnInit, Inject, HostListener, ViewChild, Input, TemplateRef } from '@angular/core';
import { Global } from '../../../../shared/models/global.model';
import { ProjectService } from '../../../../core/services/project.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { noWhitespaceValidator } from '../../../../shared/models/no-whitespace-validator';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { PLATFORM_ID } from '@angular/core';
import { DatePipe, isPlatformBrowser } from '@angular/common';
import { PlguinDataObj, ProjectDetailObj } from '../../../../core/modal/project';
import { Subscription } from 'rxjs';
import { version } from '../../../../../../package.json';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { MenuObj, UserObj } from '../../../../core/modal/user';
import { SpinnerService } from '../../../../core/services/spinner.service';
import{ViewAudienceComponent} from '../../pages/view-audience/view-audience.component'
import { environment } from '../../../../../environments/environment';
import { BsModalRef, BsModalService, BsDatepickerConfig } from 'ngx-bootstrap';
@Component({
    selector: 'app-auth-layout',
    templateUrl: './auth-layout.component.html',
    styleUrls: ['./auth-layout.component.scss']
})
export class AuthLayoutComponent implements OnInit {
    isCollapsed: boolean = true;
    innerWidth: number = 1200;
    projectForm: FormGroup;
    isVisible:boolean=true;
    parentClass: any = 'hide-menu';
    waitCount: any = 1;
    returnUrl: string;
    isCollapse : boolean = true;
    isAnCollapse : boolean = true;
    isPICollapse : boolean = true;
    isStCollapse : boolean = true;
    isBrowser: boolean = false;
    pluginList: any[] = [];
    pluginDetails: Map<string, PlguinDataObj[]>;
    projectDetails: ProjectDetailObj;
    subscribe: Subscription;
    readonly VERSION = version;
    userData:UserObj;
    notificationList:any;
    menuItems: MenuObj[];
    @ViewChild(ViewAudienceComponent) AudienceComponent;
    page:any=0;
    dataSize:any=20;
    totalSize:any;
    loadingNotificationData:boolean = false;
    showReadMore:boolean = false
    unreadNotification:any = [];
    dataList:any;
    projectIdEncrpted:any;
    @Input('refreshMenuList') refreshMenuList:any;
    ivrStatus:boolean = false;
    modalRef: BsModalRef;
    @ViewChild('popupConfirm') popupConfirm:TemplateRef<any>;
    setIvrStatus:boolean = false
    ivrStatusInput:any;
    constructor(
        private toastr: ToastrService,
        public route: ActivatedRoute,
        public global: Global,
        private formBuilder: FormBuilder,
        public router: Router,
        private projectService: ProjectService,
        private authenticationService: AuthenticationService,
        @Inject(PLATFORM_ID) platformId: Object,
        private spinner: SpinnerService,
        private datePipe:DatePipe,
        private modalService: BsModalService,
    ) {
        this.userData = this.authenticationService.getUserData();
        this.isCollapsed = false;
        if(this.router.routerState.snapshot.url=='/admin/audience/view-audience' || this.router.routerState.snapshot.url=='/admin/bulk-upload'){
            this.isCollapse = false;
        }
        this.isBrowser = isPlatformBrowser(platformId);
    }

    ngOnInit() {
        this.projectIdEncrpted = this.authenticationService.getProjectId();
        this.fetchCategory();
        if(this.authenticationService.getUserId() != undefined && this.authenticationService.getProjectId() != undefined){
            this.getProjectDetails();
            this.getMenuItems();
            this.projectService.getUnreadNotification().subscribe(data=>{
                if(data.status){
                    this.global.notificationSum = data.data;
                }
            })
            this.getNotificationList();
        }
        this.innerWidth = window.innerWidth;
        if(this.innerWidth>1150){
            this.parentClass = 'show-menu';
        }
        if(this.innerWidth<575){
            this.router.events.subscribe((val) => {
                this.hideMenu();
            });
        }
        this.projectForm = this.formBuilder.group({
            projectName: ['', Validators.compose([Validators.required, noWhitespaceValidator, Validators.minLength(3), Validators.maxLength(50)])],
        });   

        this.projectService.setNotificationTimer();
       
    }

    ngOnChanges(){
        if(this.refreshMenuList){
            this.getMenuItems('reload');
        }
    }

    checkIvrStatus(){
        this.projectService.getIvrStatus({}).subscribe(data=>{
            if(data.status){
                this.ivrStatus = data.data;
                this.ivrStatusInput = data.data
            }
        })
    }

    ivrStatusUpdate(event){
        this.setIvrStatus = event.target.checked
        this.modalRef = this.modalService.show(this.popupConfirm, {
            class: "", backdrop: 'static'
        });
    }

    confirmUpdate(){
        let statusStore = this.setIvrStatus == true ? 'Active' : 'Inactive';
        this.projectService.updateIvrStatus({status:statusStore}).subscribe(data=>{
            console.log(data)
            if(data.status){
                this.modalRef.hide();
                this.checkIvrStatus();
            } else{
                this.toastr.error(data.error_msgs);
                this.modalRef.hide();
            }
        })
    }

    goToViewAud(item){
        if(item.notStats == 'Permission Change'){
            return;
        }
        if(item.status == 0){
            this.global.notificationSum = this.global.notificationSum - 1;
        }
        let getViewAudUrl = this.router.url;
        let searchString = item.json.join();
        localStorage.setItem('searchString','objectid:'+searchString);
        this.projectService.viewNotification({'id':item.id}).subscribe(data=>{
        })
        this.router.navigate(['/admin/lead/'+item.category],{queryParams:{notification:new Date().getTime()}});
    }
    fetchCategory() {        
        this.dataList = [];
        this.projectService.fetchCategory({}).then(data => {
            if (data) {
                this.dataList = data;
            }
        })
    }

    getNotificationList(event?){
        if(event == 'nextPage'){
            this.loadingNotificationData = true
            let checkreadMore =  this.totalSize - this.dataSize;
            if(checkreadMore >= 1){
            this.dataSize = this.dataSize + (checkreadMore >= 20 ? 20 : checkreadMore);
            }
            this.global.notificationDataSize = this.dataSize
        }
        this.projectService.getNotification(this.page, this.global.notificationDataSize).subscribe(data=>{
            if(data.status){
                this.unreadNotification = []
                this.loadingNotificationData = false;
                this.totalSize = data.data.size;
                let checkreadMore =  this.totalSize - this.dataSize;
                this.showReadMore = checkreadMore >= 1 ? true : false;
                this.notificationList = data.data.notifications ? data.data.notifications : [];
                
                
                this.notificationList.forEach(element => {
                    let elemCat = this.dataList.find(x=>x.linkRewrite == element.category);
                    if(elemCat){
                        element.catName = elemCat.name;
                    }

                    element.day = new Date().getDate() - new Date(element.createdate).getDate();
                    element.hour = new Date().getHours() - new Date(element.createdate).getHours();
                    element.minute = new Date().getMinutes() - new Date(element.createdate).getMinutes();
                    element.second = new Date().getSeconds() - new Date(element.createdate).getSeconds();
                });
            } else {
                this.toastr.error(data.error_msgs);
            }
        })
    }

    collapseMenu(){
        this.isCollapse = !this.isCollapse;
    }
  
    showMenu() {
        this.parentClass = 'show-menu';
    }
    
    hideMenu() {
        this.parentClass = 'hide-menu';
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if(this.innerWidth<=1150 && window.innerWidth>1150){
            this.showMenu();
        }
        if(this.innerWidth>1150 && window.innerWidth<=1150){
            this.hideMenu();
        }
        this.innerWidth = window.innerWidth;
    }
    
    EnableInput(disable: boolean) {
        if (disable==true) {
            this.isVisible = disable;
        } else {
            this.isVisible = disable;
        }
    }

    getProjectDetails() {
        this.projectService.getUserProjectDetails().then( data => {
            if (data != undefined) {
                this.projectDetails = data;
                this.authenticationService.setUserdata(this.userData);
                this.projectForm.controls['projectName'].setValue(this.projectDetails.projectName);
                if(this.projectDetails.ivrActivation ==  true){
                    this.checkIvrStatus()
                }
            }
        });
    }

    getMenuItems(reload?) {
        this.authenticationService.getSiteMenuData(reload).then(data => {
            if(data != undefined){
            this.menuItems = this.setLandingPage(data); 
            }          
        })
    }

    categoryMenuStatusGet(submenu,mainmenu){
        if(mainmenu.menuKey == 'audience'){
            localStorage.removeItem('checkedLeadReasign');
            localStorage.removeItem('checkedLeadDelete');
            localStorage.removeItem('paramList');
        }
    }

    checkListDestroy(event)
    {
        if(event.menuKey == 'view-audience')
        {
            localStorage.removeItem('checkedLeadReasign');
            localStorage.removeItem('checkedLeadDelete');
            localStorage.removeItem('paramList');
        }
    }

    setLandingPage(data) : MenuObj[]{
        data.forEach( u => {
            if(u.menuList != undefined){
                u.isSubMenuAdded = true;
                u.menuList = this.setLandingPage(u.menuList);
            }
        });
        return data;
    }

    get pf() { return this.projectForm.controls; }
    saveProject() {
        if (this.projectForm.invalid) {
            this.toastr.error(this.global.MessageRequired); this.global.validateAllFormFields(this.projectForm);
            return;
        }
        let postForm: any = {
            projectName: this.pf.projectName.value,
        };

        this.projectService.upsertProject(postForm).subscribe(data => {
            if (data.status) {
                this.toastr.success('Success');
                this.isVisible=true;
                this.projectDetails.projectName = this.pf.projectName.value;
            } else {
                this.toastr.error(data.error_msgs, 'Error');
            }
        });
    }

    callWabaApi(){
        this.projectService.getMagicLink({}).subscribe(data=>{
            console.log(data);
            if(data.status){
                this.router.navigate([]).then(result => {  window.open(data.data, '_blank'); });
            } else{
                this.toastr.error(data.error_msgs);
            }
        })
    }
    
    copyText(val: string){
        this.global.copyText(val);
        this.toastr.success('Copied to clipboard');
    }

    signout(){
        this.authenticationService.signout({userId:this.userData.userId,webToken: localStorage.getItem("token")}).subscribe(data=>{
        });
        this.authenticationService.logout();
        this.spinner.show();
        setTimeout(() => {
            this.spinner.hide()
            this.router.navigate(['/admin/sign-in'],{ queryParams:{type:'signout'}});
        }, 500);
    }

    reload(){
        window.location.reload();
    }
}
