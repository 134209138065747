import { Injectable } from "@angular/core";
import { delay } from "rxjs/operators";
import { ApiConstants } from "../../../app/constants/api.constants";
import { BaseService } from "../../core/services/base.service";
import { CatalogueObj } from "./catalogue.modal";

@Injectable({
    providedIn: 'root'
})

export class CatalogueService {
    catalogueData: CatalogueObj[];
    constructor(
        private baseService: BaseService
    ){
        
    }

    createCatalogue(body: any) {
        return this.baseService.postRequest(ApiConstants.CREATE_CATALOGUE, body);
    }
    
    copyCatalogue(body: any) {
        return this.baseService.postRequest(ApiConstants.COPY_CATALOGUE, body);
    }
    

    updateCatalogue(body: any) {
        return this.baseService.postRequest(ApiConstants.UPDATE_CATALOGUE, body);
    }

    fetchCatalogue(body:any) {
        return this.baseService.postRequest(ApiConstants.FETCH_CATALOGUE, body);
    }

    deleteCatalogue(body:any) {
        return this.baseService.postRequest(ApiConstants.DELETE_CATALOGUE, body);
    }

    listCatalogue() {
        return this.baseService.getRequest(ApiConstants.LIST_OF_CATALOGUES);
    }

    udpateCatalogueProduct( body: any) {
        return this.baseService.postRequest(ApiConstants.UPDATE_CATALOGUE_PRODUCT,body);
    }
    
    deleteCatalogueProduct(body:any) {
        return this.baseService.postRequest(ApiConstants.DELETE_CATALOGUE_PRODUCT,body);
    }

    cataloguesData(body:any) {
        return this.baseService.postRequest(ApiConstants.CATALOGUES_DATA,body);
    }

    checkConfigurationForCampaign(body:any) {
        return this.baseService.postRequest(ApiConstants.CHECK_CONFIGURATION_FOR_CAMPAIGN, body)
    }

    getCatalogueHTML(body:any) {
        return this.baseService.getRequestHTML(body,)
    }

    async getCatalogueData(reload?){
        if(reload == 'reload')
        {
            this.catalogueData = undefined;
        }
        if(this.catalogueData == undefined){
            var requestData = await this.listCatalogue().pipe(delay(200)).toPromise();
            this.catalogueData = requestData.data ? requestData.data : requestData.error_msgs;
        };
        return this.catalogueData;
    }
    
}