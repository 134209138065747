<main>
  <section class="inner-page">
      <div class="inner-page-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-6">
                <h4>
                    <a class="back-link" (click)="back()"><img src="assets/images/angle-left.svg"></a>
                    <span *ngIf="selectedUserobject">{{selectedUserobject.userProfileName}} call location history</span>
                </h4>
              </div>
              <div class="col-md-6 text-right datePickerCol">
                <input type="text" class="form-control date-icon modal-space datePickInline" readonly autocomplete="off"
                            ngxDaterangepickerMd
                            [(ngModel)]="callAssistLocationRange"
                            [alwaysShowCalendars]="true"
                            [locale]="{format: 'DD/MM/YYYY'}"
                            [ranges]="ranges"
                            [autoApply]="false"
                            [opens]="'left'"
                            [maxDate]="maxDate"
                            placeholder="Select Date Range"
                            (datesUpdated)="callAssistLocationList()">
                            
              </div>
            </div>
          </div>
      </div>

      <div class="inner-page-body site-form">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="call_assist_location_tbl table-reports">
                  <div class="table-responsive">
                    <table class="table">
                      <tr>
                        <th>
                          Created
                        </th>
                        <th>
                          Location
                        </th>
                      </tr>
                      <ng-container *ngIf="locationList">
                      <tr *ngFor="let list of locationList | paginate: { id: 'foo',itemsPerPage:limit, currentPage:page, totalItems:totalRecords }; let i = index">
                        <td>{{list.created}}<span *ngIf="i == 0 && list.currentLocation == true" class="current_location_txt"> ( Current Location )</span><span *ngIf="i == 0 && list.currentLocation == false" class="current_location_txt"> ( Last Location )</span></td>
                        <td>
                          <a href="{{list.location}}" target="_blank">Click Here</a>
                        </td>
                      </tr>
                    </ng-container>
                    <tr *ngIf="locationList.length == 0">
                      <td class="no-records" colspan="2">No Records Found</td>
                    </tr>
                    </table>
                  </div>
                  <div *ngIf="locationList.length > 0" class="table-footer footer-gray">
                    <pagination-controls id="foo" (pageChange)="dataForPaging($event)" maxSize="9" directionLinks="true" autoHide="true" class="pull-right" responsive="true" previousLabel="Previous" nextLabel="Next" screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
                        screenReaderCurrentLabel="You're on page">
                    </pagination-controls>
                    <div class="textblue text-left">
                        <div class="data-length"> Show
                            <select [(ngModel)]="limit" (change)="callAssistLocationList()">
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            </select>
                        </div>
                        <span> Total Count: {{locationList.length}}</span>
                    </div>
                </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </section>
</main>