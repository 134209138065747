<main>
    <section class="inner-page">
        <div class="inner-page-header">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-6">
                        <h4>
                          <a class="back-link" (click)="back()"><img src="assets/images/angle-left.svg"></a>
                          Url Shortener
                      </h4>
                    </div>
                    <div *ngIf="isSubscribe" class="col-md-6 text-right d-flex j-end">
                        <button class="btn white_add_btn d-flex c-align" (click)="addUrlShortner()" tooltip="Create URL Shortner" [tooltipAnimation]="true" tooltipPlacement="top">
                          <img src="assets/images/plus-icon.png"> &nbsp; Add
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="inner-page-body site-form">
            <div *ngIf="isSubscribe" class="container-fluid">
                <div class="table-responsive table-height-scroll table-reports">
                    <table class="table">
                        <tbody class="norecord-handling">
                            <tr>
                                <th width="20">S.No.</th>
                                <th width="50%">Link</th>
                                <th>Shortened Link</th>
                                <th class="text-center">Action</th>
                            </tr>
                            <tr *ngFor="let item of dataList | paginate: { itemsPerPage:dataSize, currentPage:pageNo, totalItems:totalDataSize }; let i = index;">
                                <td>{{(dataSize*(pageNo-1))+ (i + 1)}}</td>
                                <td class="url">{{item.actualLink}}</td>
                                <td class="copyLink">
                                  <span (click)="copyText(item.shortnerLink)">
                                    <p>{{item.shortnerLink}}</p>
                                    <span>&nbsp;<img src="assets/images/copy.svg"></span>
                                  </span>
                                </td>
                                <td class="text-center">
                                    <a class="c-pointer" tooltip="Delete" tooltipPlacement="top" (click)="showDeleteModal(item.id)">
                                      <img class="img" src="assets/images/delete.png" />
                                    </a>
                                </td>
                            </tr>
                            <tr><td *ngIf="dataList.length == 0" class="no-records text-center" colspan="6">No Records Yet</td></tr>
                        </tbody>
                    </table>
                  </div>
                  <div *ngIf="totalDataSize > 0" class="table-footer footer-gray">
                    <pagination-controls
                          (pageChange)="dataForPaging($event)"
                          maxSize="9"
                          directionLinks="true"
                          autoHide="true"
                          class="pull-right"
                          responsive="true"
                          previousLabel="Previous"
                          nextLabel="Next"
                          screenReaderPaginationLabel="Pagination"
                          screenReaderPageLabel="page"
                          screenReaderCurrentLabel="You're on page">
                    </pagination-controls>
                    <div class="textblue text-left">
                      <div class="data-length"> Show
                        <select [(ngModel)]="dataSize" (change)="listShortens()">
                          <option value="20">20</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                      <span>Total Count: {{totalDataSize}}</span>
                    </div>
                  </div>
            </div>
            <app-page-disable *ngIf="!isSubscribe"></app-page-disable>
        </div>
    </section>

</main>



<ng-template #addUrlShortnerModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left"> Create Short URL</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="siteForm" (ngSubmit)="saveForm()">
            <div class="form-row">
                <div class="form-group col-12">
                    <label class="required">Link</label>
                    <input trim="blur" type="text" class="form-control" formControlName="actualLink" maxlength="150" [readonly]="isShrotUrlCreated">
                </div>
                <div *ngIf="isShrotUrlCreated" class="form-group col-12">
                    <label class="control-label">Short URL</label>
                    <div class="input-group">
                        <input type="text" class="form-control" value="{{sortUrl}}" disabled="disabled" />
                        <span (click)="copyText(sortUrl)" class="input-group-text pinkcolor mb-0">Copy</span>
                    </div>
                </div>
                <div class="col-12 text-right mt-1 mb-2">
                    <button *ngIf="!isShrotUrlCreated" class="btn btn-dark" type="submit">Get Short URL</button>
                    <button *ngIf="isShrotUrlCreated" class="btn btn-dark" type="button" (click)="modalRef.hide()">close</button>
                </div>
            </div>
        </form>
    </div>
</ng-template>

  <ng-template #confirmModal>
    <form>
      <div class="forgot-password-modal">
        <div class="modal-header">
          <h4 class="modal-title pull-left">Do you want to remove?</h4>
          <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body modal-user-management">
          <div class="form-group mb-0">
            <p class="pt-2">
                Delete operation can't be undone.
            </p>
            <div class="d-flex justify-content-end mt-5">
              <div>
                <button class="btn remove-user-btn mr-4" (click)="modalRef.hide()" type="button">
                  Dismiss
                </button>
              </div>
              <div>
                <button class="btn save-btn" type="button" (click)="deleteItem()">
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </ng-template>