import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ProjectService } from '../../../../core/services/project.service';
import { Global } from '../../../../shared/models/global.model';

@Component({
  selector: 'app-integration-kit',
  templateUrl: './integration-kit.component.html',
  styleUrls: ['./integration-kit.component.scss']
})
export class IntegrationKitComponent implements OnInit {

  json: any = {};
  userData: any = {};
  fixedParamList: any = {};
  JSON:any = {};
  constructor(
    private global: Global,
    private projectService: ProjectService,
    private location:Location
    ) {
      this.JSON = JSON;
    this.json = JSON.stringify({data:'Record inserted successfully',status:true}, null, 4);
  }

  ngOnInit() {
    this.downloadFile();
  }

  back()
  {
    this.location.back();
  }

  downloadFile() {
    this.projectService.downloadKit({}).subscribe(data => {
          if (data.status) {
              this.userData = data.data;
              this.projectService.fetchAttributeList().then(data => {
                if (data) {
                  let filters = this.projectService.filterAttributeList(); 
                  this.fixedParamList = {
                    projectKey: this.projectService.projectDetails.projectKey,
                    audienceId: "_id (number)"
                  };    

                  let dynamicParamList = [];
                  for (let index = 0; index < filters.length; index++) {
                    let element = filters[index];
                    if (element["paramType"] == "static") {
                       this.fixedParamList[element.paramKey] = '';
                    }else{
                      if(element["paramVisible"] == 1){
                            dynamicParamList.push({
                              paramKey: element["paramKey"],
                              paramValue: element["paramCategory"] == "0" ? true : ''
                            });
                          }
                    }
                  }

                  this.fixedParamList.paramList =[];
                  this.fixedParamList.paramList = dynamicParamList;

                 

                  // for (let index = 0; index < data.data.paramData.length; index++) {
                  //   const element = data.data.paramData[index];
                    
                  //   if (element.paramOwner == 0 && element.paramType != "static") {
                  //     this.fixedParamList.paramList.push({
                  //       paramKey: element.paramKey,
                  //       paramValue: element.paramCategory == "0" ? true : ''
                  //     });
                  //   }
                  // }

                }
              })
            

           
            
            
           
            
            
          }
        });
  }
}
