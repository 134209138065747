import { Injectable } from '@angular/core';
import { AppConstants } from '../../constants/app.constants';
import { ApiConstants } from '../../constants/api.constants';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class AnnouncementService {
    
    constructor (
        private baseService: BaseService
    ) { 

    }

    fetchBarNotificationsList(body:any) {
      return this.baseService.postRequest(ApiConstants.FETCH_BAR_NOTIFICATIONS_LIST, body);
    }
    upsertBarNotification(body:any) {
      return this.baseService.postRequest(ApiConstants.UPSERT_BAR_NOTIFICATION, body);
    }
    fetchBarNotificationDetail(body:any) {
      return this.baseService.postRequest(ApiConstants.FETCH_BAR_NOTIFICATION_DETAIL, body);
    }
    deleteBarNotification(body:any) {
      return this.baseService.postRequest(ApiConstants.DELETE_BAR_NOTIFICATION, body);
    }

    fetchPopupNotificationList(body:any) {
      return this.baseService.postRequest(ApiConstants.FETCH_POPUP_NOTIFICATIONS_LIST, body);
    }
    upsertPopupNotification(body:any) {
      return this.baseService.postRequest(ApiConstants.UPSERT_POPUP_NOTIFICATION, body);
    }
    fetchPopupNotificationDetail(body:any) {
      return this.baseService.postRequest(ApiConstants.FETCH_POPUP_NOTIFICATION_DETAIL, body);
    }
    deletePopupNotification(body:any) {
      return this.baseService.postRequest(ApiConstants.DELETE_POPUP_NOTIFICATION, body);
    }

    createAnnouncement(body:any, type) {
      return this.baseService.postRequest(ApiConstants.CREATE_POPUP_USER.replace(AppConstants.REPLACE_TYPE,type), body);
    }
    
    updateAnnouncement(body:any, type) {
      return this.baseService.postRequest(ApiConstants.UPDATE_POPUP_USER.replace(AppConstants.REPLACE_TYPE,type), body);
    }

    fetchAnnouncement(body:any, type) {
      return this.baseService.postRequest(ApiConstants.FETCH_POPUP_USER.replace(AppConstants.REPLACE_TYPE,type), body);
    }

    fetchAnnouncementsList(type:any,size,page) {
      return this.baseService.getRequest(ApiConstants.LIST_ANNOUNCEMENTS.replace(AppConstants.REPLACE_TYPE,type).replace(AppConstants.REPLACE_SIZE, size).replace(AppConstants.REPLACE_PAGE, page));
    }

    deleteAnnouncement(body:any, type) {
      return this.baseService.postRequest(ApiConstants.DELETE_POPUP_USER.replace(AppConstants.REPLACE_TYPE,type), body);
    }
  
  }