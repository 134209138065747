import { Component, OnInit, TemplateRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Global } from '../../../../shared/models/global.model';
import { BsModalService } from 'ngx-bootstrap';
import { SegmentService } from '../../../../core/services/segment.service';
import { CampaignService } from '../../../../core/services/campaign.service';
import { Location } from '@angular/common';
import { ProjectService } from '../../../../core/services/project.service';

@Component({
  selector: 'app-segments',
  templateUrl: './segments.component.html',
  styleUrls: ['./segments.component.scss']
})
export class SegmentsComponent implements OnInit {
  modalRef: any;
  filters: any = [];
  inputParams: any = [];
  waitCount: any = 2;
  inputParam: any = {};
  segmentText: any = "";
  segmentdata:any={};
  id:number;
  evntid:number;
  source: any=[];
  segmentsId: any=[];
  isSubscribe: boolean = true;
  dataList:any;
  constructor(  private toastr: ToastrService,
    public global: Global,
    private modalService: BsModalService,
    private Segmentservice: SegmentService,
    private segmentService: SegmentService,
    private campaignService: CampaignService,
    private location:Location,
    private projectService:ProjectService
  ) {
  
  }

  
  ngOnInit() {
    this.fetchCategory()
    this.getInputParams();
  }

  back()
  {
      this.location.back();
  }

  openModal(id: number,webPushSegment: TemplateRef<any>) {
    this.inputParam = id;
    this.modalRef = this.modalService.show(webPushSegment, {class: "modal-lg",backdrop: 'static'});
    this.getfetchSegment();
  }

  openModalDelete(item: any, template: TemplateRef<any>) {    
    this.inputParam = item;
    this.modalRef = this.modalService.show(template, { backdrop: 'static' });
  }

  fetchCategory() {        
    this.dataList = [];
    this.projectService.fetchCategory({}).then(data => {
        if (data) {
            this.dataList = data;
        }
    })
}

  getInputParams() {
    this.Segmentservice.getSegmentlist({}).subscribe( data => {
        if (data.status) {
          this.inputParams = data.data;
          if(this.inputParams){
          this.inputParams.forEach(element => {
            let checkCatName = this.dataList.find(x=>x.linkRewrite == element.category);
            if(checkCatName){
              element.catName = checkCatName.name
            }
          });
        }
        } else {
          this.inputParams = [];
          this.toastr.error(data.error_msgs)
        }
    });
  }


  deleteSegment() {
    this.Segmentservice.deleteSegment({id: this.inputParam.id }).subscribe( data => {
        if (data.status) {
          this.toastr.success('Segment Deleted Successfully');
          this.getInputParams();
          this.modalRef.hide();
        }
        else {
          this.toastr.error(data.error_msgs, 'Error');
        }
     });
  }

  getfetchSegment() {
    this.segmentService.fetchSegment({ id:this.inputParam}).subscribe(
      data => {
        if (data.status) {
          this.segmentdata = data.data;
          this.source  = data.data.segmentGroupDetail;
          this.evntid  = data.data.segmentGroupDetail[0].event;
       
        }
      });
  }

  getFilters() {
      this.campaignService.getFilters({}).subscribe( data => {
            if (data.status) {
              this.filters = data.data;
            }
      });
  }

  calculateAudience(item){
    this.inputParam = item;    
    let jsonParse = JSON.parse(this.inputParam.jsonData);
      var postData = {
        segment: [{'paramList':jsonParse.paramList,"source":jsonParse.source,"pluginFormIds":jsonParse.pluginFormIds}],
        searchstring:""
      }
      this.campaignService.calculateAudience(postData,this.inputParam.category).subscribe(data => {
       // this.modalRef.hide();
        if (data.status) {
          this.toastr.success(data.data.totalElements + " User(s) Found");
        } else {
          this.toastr.error(data.error_msgs, "Error");
        }
      })
  }

}
