import { Location } from "@angular/common";
import { Component, OnInit, TemplateRef, ViewChild} from "@angular/core";
import { Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { ToastrService } from "ngx-toastr";
import { AuthenticationService } from "../../core/services/authentication.service";
import { UrlShortnerService } from "../../core/services/urlShortner.service";
import { Global } from "../../shared/models/global.model";
import { CatalogueObj } from "./catalogue.modal";
import { CatalogueService } from "./catalogue.service";

@Component({
    selector: 'app-catalogue',
    templateUrl: './catalogue.component.html',
    styleUrls:['./catalogue.component.scss']
})

export class CatalogueComponent implements OnInit {

    catalogueList:CatalogueObj[];
    @ViewChild('deleteConfirm') deleteConfirm: TemplateRef<any>;
    @ViewChild('copyCatalogueProp') copyCatalogueProp: TemplateRef<any>;
    @ViewChild('showQrCode') showQRcodeTmp: TemplateRef<any>;
    modalRef: BsModalRef;
    catalogueId: number;
    catalogueCopyDtl:any;
    isSubscribe: boolean = true;
    isShortUrlSubscribe: boolean = true
    selectedCatalogue:CatalogueObj;
    constructor(
        private global: Global,
        private toastr: ToastrService,
        private router: Router,
        private catalogueService: CatalogueService,
        private modalService: BsModalService,
        private urlShortnerService: UrlShortnerService,
        private authenticationService: AuthenticationService,
        private location:Location
    ){
        this.isSubscribe = this.authenticationService.isPageSubscribe('Catalogues');
        this.isShortUrlSubscribe = this.authenticationService.isPageSubscribe('Url Shortner');
    }

    ngOnInit(){
        this.catalogueList = [];
        if(this.isSubscribe)
        this.getCatalogueList();
    }

    copyCatalogue(item)
    {
        this.catalogueCopyDtl = item        
        this.modalRef = this.modalService.show(this.copyCatalogueProp, {
            backdrop: 'static'
        });
    }

    submitCopyCatalogue(catalogname,cataloghandle){
        if(catalogname == '')
        {
            this.toastr.error("Catalogue name can't be empty");
            return;
        }
        if(cataloghandle == '')
        {
            this.toastr.error("Catalogue handle can't be empty");
            return;
        }

        let alphaNumWithChar  = /^[a-z A-Z0-9_/-]*$/
        if(!alphaNumWithChar.test(catalogname)){
            this.toastr.error('Catalogue Name Should be Alphanumeric', 'Error');
            return;
        }
        if(!alphaNumWithChar.test(cataloghandle)){
            this.toastr.error('Catalogue Handle Should be Alphanumeric', 'Error');
            return;
        }

        let copyCatalogueObj = {title:catalogname,handle:cataloghandle,catalogueId:this.catalogueCopyDtl.id};
        this.catalogueService.copyCatalogue(copyCatalogueObj).subscribe(data=>{
            if(data.status)
            {
                
                this.toastr.success('Catalogue copied successfully');
                this.getCatalogueList();
                this.modalRef.hide();
            }
            else
            {
                if(data.error_msgs.title){
                    this.toastr.error(data.error_msgs.title);
                } else{
                    this.toastr.error(data.error_msgs);
                }
                
            }
        })
    }

    downloadQr()
    {
        var downloadableImg = document.getElementById('qrScanner').firstElementChild;
        const imageUrl = downloadableImg.attributes[2].nodeValue;
        const a: any = document.createElement('a');
        a.href = imageUrl;
        a.download = this.selectedCatalogue.title+'-with-price.png';
        document.body.appendChild(a);
        a.style = 'display: none';
        a.click();
        a.remove();
    }

    downloadQrWithoutPrice()
    {
        var downloadableImg = document.getElementById('qrScannerwithoutPrice').firstElementChild;
        const imageUrl = downloadableImg.attributes[2].nodeValue;
        const a: any = document.createElement('a');
        a.href = imageUrl;
        a.download = this.selectedCatalogue.title+'-without-price.png';
        document.body.appendChild(a);
        a.style = 'display: none';
        a.click();
        a.remove();
    }

    back()
    {
        this.location.back();
    }


    copyText(text){
        this.global.copyText(text);
        this.toastr.success('Copied to clipboard');
    }

    editCatalogue(id){
        this.router.navigate(['/admin/edit-catalogue/'+id]);
    }

    getCatalogueList(){
        this.catalogueService.getCatalogueData('reload').then(data => {
            if(data != undefined){
                if(typeof(data) == 'string'){
                    this.toastr.error(data)
                } else{
                    this.catalogueList = data;
                }                
            }
        })
    }

    openModalDelete(id:number){
        this.catalogueId = id;
        this.modalRef = this.modalService.show(this.deleteConfirm, {
            backdrop: 'static'
        });
    }

    showQRcode(id:number){
        this.catalogueList.forEach(catalogue => {
            if(catalogue.id == id){
                this.selectedCatalogue = catalogue;
            }
        })
        this.modalRef = this.modalService.show(this.showQRcodeTmp, {
            backdrop: 'static'
        });
    }

    deleteCatalogue(){
        this.modalRef.hide();
        this.catalogueService.deleteCatalogue({id:this.catalogueId}).subscribe(data => {
            if(data.status){
                this.toastr.success(data.data, 'Success');
                this.catalogueService.catalogueData = undefined;
                this.getCatalogueList();
            } else {
                this.toastr.error(data.error_msgs);
            }
        })
    }

    downloadPdf(url){
        let popupWin;
        this.catalogueService.getCatalogueHTML(url).subscribe(data => {
            var style = data.substr(data.indexOf('<link rel'),(data.indexOf('</style>') - data.indexOf('<link rel') + 8));
            var body = data.substr((data.indexOf('<body>') + 6),(data.indexOf('</body>') - data.indexOf('<body>')));
            popupWin = window.open('', '_blank', 'height=800,width=auto,status=no, toolbar=no,menubar=no,location=no');
            if(popupWin == null || popupWin == undefined){
                window.alert('Please disable popup blocker.');
                return;
            }
            popupWin.document.open('','_self', 'noopener=true');
            popupWin.document.open();
            popupWin.document.write(`
            <html>
                <head>
                <title>Print tab</title>
                ${style}
                </head>
                <body onload="window.print();window.close()">${body}</body>
            </html>`
            );
            setTimeout(() => {
                popupWin.document.close();
            }, 400);
        })
        // window.print();
    }

    getShortUrl(url){
        this.urlShortnerService.getUrlShortner(url).then( data => {
            if(data != undefined){
                this.global.copyText(data);
                this.toastr.success('Copied to clipboard');
            }
        })
    }

}
