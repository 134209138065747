<main>
    <section class="inner-page">
        <div class="inner-page-header">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-6 align-self-center mb-2 mb-md-0">
                        <h4>
                            <a class="back-link" (click)="back()"><img src="assets/images/angle-left.svg"></a>
                            Campaign Template
                        </h4>
                    </div>
                    <div class="col-md-6 text-right d-flex j-end">
                        <button *ngIf="activeTab == 'push'" class="d-flex c-align btn white_add_btn" tooltip="Create Template" [tooltipAnimation]="true" tooltipPlacement="top" (click)="isUpdate = false; openModal(templatePushNotification); push_url = pushServiceUrl" type="button">
                            <img src="assets/images/plus-icon.png"> &nbsp; Add
                        </button>
                        <!-- <button *ngIf="activeTab == 'ios'" class="d-flex c-align btn white_add_btn" tooltip="Create Template" [tooltipAnimation]="true" tooltipPlacement="top" (click)="isUpdate = false; openModal(templatePushNotification); push_url = iosPushUrl" type="button">
                            <img src="assets/images/plus-icon.png"> &nbsp; Add
                        </button>
                        <button *ngIf="activeTab == 'web'" class="d-flex c-align btn white_add_btn" tooltip="Create Template" [tooltipAnimation]="true" tooltipPlacement="top" (click)="isUpdate = false; openModal(templatePushNotification); push_url = webPushUrl" type="button">
                            <img src="assets/images/plus-icon.png"> &nbsp; Add
                        </button> -->
                        <button *ngIf="activeTab == 'sms'" class="btn white_add_btn d-flex c-align" (click)="isUpdate = false; openModal(templateSMSCampaign); push_url = smsPushUrl" type="button" tooltip="Create Template" [tooltipAnimation]="true" tooltipPlacement="top">
                            <img src="assets/images/plus-icon.png"> &nbsp; Add
                        </button>
                        <button *ngIf="activeTab == 'whatsapp' && wpBDetails && wpBDetails.providerName != 'Cronberry WABA'" (click)="isUpdate = false; resetWhatsAppData(); openModal(templateWhatsappCampaign); push_url = whatsappPushUrl" class="btn white_add_btn d-flex c-align" type="button" tooltip="Create Template" [tooltipAnimation]="true" tooltipPlacement="top">
                            <img src="assets/images/plus-icon.png"> &nbsp; Add
                        </button>
                        <button *ngIf="activeTab == 'whatsapp' && wpBDetails && wpBDetails.providerName == 'Cronberry WABA'" (click)="isUpdate = false; resetWhatsAppData(); openModalSync(syncTemplateList); push_url = whatsappPushUrl" class="btn white_add_btn d-flex c-align" type="button" tooltip="Create Template" [tooltipAnimation]="true" tooltipPlacement="top">
                            Sync Template
                        </button>
                        <ng-container *ngIf="activeTab == 'email';">
                            <button (click)="push_url = emailPushUrl ; isUpdate = false; openModal(templateEmailCampaign);" class="btn white_add_btn d-flex c-align" type="button" tooltip="Create Template" [tooltipAnimation]="true" tooltipPlacement="top">
                                <img src="assets/images/plus-icon.png"> &nbsp; Add
                            </button> &nbsp;
                            <button (click)="openModal(importEmailCampaign); getDefaultEmailTemplates();" class="btn btn-dark" type="button">Import Template</button>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>

        <div class="configuration project-integration-tab">
            <tabset #siteTab>
                <tab heading="Push Templates" id="tab1" (selectTab)="activeTab = 'push'; push_url = pushServiceUrl; getPushTemplateList('push');">
                    <!-- <tabset #pushTemplate>
                        <tab heading="Android" (selectTab)="activeTab = 'android'; push_url = pushServiceUrl; getPushTemplateList(pushServiceUrl);"> -->
                            <div class="inner-page-body">
                                <div class="container-fluid">
                                    <div class="table-reports">
                                        <div class="table-body">
                                            <div class="table-responsive table-height-scroll table-reports">
                                                <table class="table">
                                                    <tbody class="norecord-handling">
                                                        <tr>
                                                            <th>S.No.</th>
                                                            <th>Template Name</th>
                                                            <th class="d-none d-md-table-cell">Last Modified Date</th>
                                                            <th class="text-center">Action</th>
                                                        </tr>
                                                        <tr *ngFor="let item of androidPushTemplateList; let i = index;">
                                                            <td>{{i+1}}</td>
                                                            <td>{{item.templateName}}</td>
                                                            <td class="d-none d-md-table-cell">{{item.updated | date}}</td>
                                                            <td class="text-center">
                                                                <a class="c-pointer" (click)="getPushTemplateData(item.id,pushServiceUrl)">
                                                                    <img class="img" src=".../../../../../assets/images/pinkedit.png" />
                                                                </a> &nbsp; &nbsp;
                                                                <a class="c-pointer" (click)="openModalDelete(confirm, item.id, i, pushServiceUrl)">
                                                                    <img class="img" src=".../../../../../assets/images/delete.png" />
                                                                </a>
                                                            </td>
                                                        </tr>
                                                        <tr *ngIf="androidPushTemplateList.length == 0">
                                                            <td class="no-records" colspan="4">No Records Found</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </tab>

                        <!-- <tab heading="IOS" (selectTab)="activeTab = 'ios'; push_url = iosPushUrl; getPushTemplateList('ios');">
                            <div class="inner-page-body">
                                <div class="container-fluid">
                                    <div class="table-reports">
                                        <div class="table-body">
                                            <div class="table-responsive table-height-scroll table-reports">
                                                <table class="table">
                                                    <tbody class="norecord-handling">
                                                        <tr>
                                                            <th>S.No.</th>
                                                            <th>Template Name</th>
                                                            <th class="d-none d-md-table-cell">Last Modified Date</th>
                                                            <th class="text-center">Action</th>
                                                        </tr>
                                                        <tr *ngFor="let item of iosPushTemplateList; let i = index;">
                                                            <td>{{i+1}}</td>
                                                            <td>{{item.templateName}}</td>
                                                            <td class="d-none d-md-table-cell">{{item.updated | date}}</td>
                                                            <td class="text-center">
                                                                <a class="c-pointer" (click)="getPushTemplateData(item.id,iosPushUrl)">
                                                                    <img class="img" src=".../../../../../assets/images/pinkedit.png" />
                                                                </a> &nbsp; &nbsp;
                                                                <a class="c-pointer" (click)="openModalDelete(confirm, item.id, i,iosPushUrl)">
                                                                    <img class="img" src=".../../../../../assets/images/delete.png" />
                                                                </a>
                                                            </td>
                                                        </tr>
                                                        <tr *ngIf="iosPushTemplateList.length == 0">
                                                            <td class="no-records" colspan="4">No Records Found</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </tab>

                        <tab heading="Web" (selectTab)="activeTab = 'web'; push_url = webPushUrl; getPushTemplateList('web');">
                            <div class="inner-page-body">
                                <div class="container-fluid">
                                    <div class="table-reports">
                                        <div class="table-body">
                                            <div class="table-responsive table-height-scroll table-reports">
                                                <table class="table">
                                                    <tbody class="norecord-handling">
                                                        <tr>
                                                            <th>S.No.</th>
                                                            <th>Template Name</th>
                                                            <th class="d-none d-md-table-cell">Last Modified Date</th>
                                                            <th class="text-center">Action</th>
                                                        </tr>
                                                        <tr *ngFor="let item of webPushTemplateList; let i = index;">
                                                            <td>{{i+1}}</td>
                                                            <td>{{item.templateName}}</td>
                                                            <td class="d-none d-md-table-cell">{{item.updated | date}}</td>
                                                            <td class="text-center">
                                                                <a class="c-pointer" (click)="getPushTemplateData(item.id,webPushUrl)">
                                                                    <img class="img" src=".../../../../../assets/images/pinkedit.png" />
                                                                </a> &nbsp; &nbsp;
                                                                <a class="c-pointer" (click)="openModalDelete(confirm, item.id, i,webPushUrl)">
                                                                    <img class="img" src=".../../../../../assets/images/delete.png" />
                                                                </a>
                                                            </td>
                                                        </tr>
                                                        <tr *ngIf="webPushTemplateList.length == 0">
                                                            <td class="no-records" colspan="4">No Records Found</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </tab> -->
                    <!-- </tabset>
                </tab> -->
                <tab heading="SMS Templates" (selectTab)="activeTab = 'sms'; push_url = smsPushUrl; getList();">
                    <div class="inner-page-body">
                        <div class="container-fluid">
                            <div class="table-reports">
                                <div class="table-body">
                                    <div class="table-responsive table-height-scroll table-reports">
                                        <table class="table">
                                            <tbody class="norecord-handling">
                                                <tr>
                                                    <th>S.No.</th>
                                                    <th>Template Name</th>
                                                    <th class="d-none d-md-table-cell">Last Modified Date</th>
                                                    <th class="text-center">Action</th>
                                                </tr>
                                                <ng-container *ngIf="smsTemplateList.length > 0">
                                                <tr *ngFor="let item of smsTemplateList; let i = index;">
                                                    <td>{{i+1}}</td>
                                                    <td>{{item.templateName}}</td>
                                                    <td class="d-none d-md-table-cell">{{item.updated | date}}</td>
                                                    <td class="text-center">
                                                        <a class="c-pointer" (click)="getSmsTemplateData(item.id,smsPushUrl)">
                                                            <img class="img" src=".../../../../../assets/images/pinkedit.png" />
                                                        </a> &nbsp; &nbsp;
                                                        <a class="c-pointer" (click)="openModalDelete(confirm, item.id, i,smsPushUrl)">
                                                            <img class="img" src=".../../../../../assets/images/delete.png" />
                                                        </a>
                                                    </td>
                                                </tr>
                                                </ng-container>
                                                <tr *ngIf="smsTemplateList.length == 0">
                                                    <td class="no-records" colspan="4">No Records Found</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </tab>
                <tab heading="Email Templates" (selectTab)="activeTab = 'email'; push_url = emailPushUrl; getList();">
                    <div class="inner-page-body">
                        <div class="container-fluid">
                            <div class="table-reports">
                                <div class="table-body">
                                    <div class="table-responsive table-height-scroll table-reports">
                                        <table class="table">
                                            <tbody class="norecord-handling">
                                                <tr>
                                                    <th>S.No.</th>
                                                    <th class="template-name">Template Name</th>
                                                    <th class="d-none d-md-table-cell">Last Modified Date</th>
                                                    <th class="text-center">Action</th>
                                                </tr>
                                                <tr *ngFor="let item of emailTemplateList; let i = index;">
                                                    <td>{{i+1}}</td>
                                                    <td>{{item.templateName}}</td>
                                                    <td class="d-none d-md-table-cell">{{item.updated | date}}</td>
                                                    <td class="text-center">
                                                        <a class="c-pointer" (click)="getEmailTemplateData(item.id)">
                                                            <img class="img" src=".../../../../../assets/images/pinkedit.png" />
                                                        </a> &nbsp; &nbsp;
                                                        <a class="c-pointer" (click)="openModalDelete(confirm, item.id, i,emailPushUrl)">
                                                            <img class="img" src=".../../../../../assets/images/delete.png" />
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr *ngIf="emailTemplateList.length==0">
                                                    <td class="no-records" colspan="4">No Records Found</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </tab>
                <tab heading="Whatsapp Templates" (selectTab)="activeTab = 'whatsapp'; push_url = whatsappPushUrl ; getList();">
                    <div class="inner-page-body">
                        <div class="container-fluid">
                            <div class="table-reports">
                                <div class="table-body">
                                    <div class="table-responsive table-height-scroll table-reports">
                                        <table class="table">
                                            <tbody class="norecord-handling">
                                                <tr>
                                                    <th>S.No.</th>
                                                    <th class="template-name">Template Name</th>
                                                    <th class="d-none d-md-table-cell">Last Modified Date</th>
                                                    <th class="text-center">Action</th>
                                                </tr>
                                                <tr *ngFor="let item of whatsappTemplateList; let i = index;">
                                                    <td>{{i+1}}</td>
                                                    <td>{{item.templateName}}</td>
                                                    <td class="d-none d-md-table-cell">{{item.updated | date}}</td>
                                                    <td class="text-center">
                                                        <a class="c-pointer" (click)="getWhatsappTemplateData(item.id)">
                                                            <img class="img" src=".../../../../../assets/images/pinkedit.png" />
                                                        </a> &nbsp; &nbsp;
                                                        <a class="c-pointer" (click)="openModalDelete(confirm, item.id, i,whatsappPushUrl)">
                                                            <img class="img" src=".../../../../../assets/images/delete.png" />
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr *ngIf="whatsappTemplateList.length==0">
                                                    <td class="no-records" colspan="4">No Records Found</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </tab>
            </tabset>
        </div>
    </section>

</main>

<ng-template #templatePushNotification>
    <form [formGroup]="formPushNotification" (ngSubmit)="savePushNotification(push_url)">
        <div class="campaign-modal">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Push Notification</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-lg-8 col-md-12 col-sm-12 col-12">
                        <div class="mb-3">
                            <div class="form-group row">
                                <label class="col-lg-3 col-md-12 control-label required">Template Name</label>
                                <div class="col-lg-7 col-md-12">
                                    <input trim="blur" class="form-control" type="text" formControlName="templateName" maxlength="50">
                                </div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <div class="form-group row">
                                <label class="col-lg-3 col-md-12 control-label required">Title</label>
                                <div class="col-lg-7 col-md-12">
                                    <input trim="blur" class="form-control" type="text" formControlName="title" maxlength="50">
                                </div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <div class="form-group row">
                                <label class="col-lg-3 col-md-12 control-label">Dynamic Values</label>
                                <div class="col-lg-7 col-md-12">
                                    <ng-select [items]="inputParams" formControlName="paramsSelected" class="params" bindLabel="paramName" [multiple]="true" [(ngModel)]="inputParamsSelected" (add)="getSeletedParem($event, formPushNotification, 'message')" (remove)="removeParem($event, formPushNotification, 'message')" (clear)="allDynamicValRemoved('push')">
                                    </ng-select>
                                    <span class="icontooltip" tooltip="Create customise message for each user by selecting dynamic fields from here. The selected field in the message gets replaced with individual user values during submission." [tooltipAnimation]="true" tooltipPlacement="right">
                      <img src="assets/images/info-circle.svg " />
                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <div class="form-group row">
                                <label class="col-lg-3 col-md-12 control-label">Upload Image</label>
                                <div class="col-lg-7 col-md-12">
                                    <button (click)="openGalleryModal('image')" class="btn btn-border" type="button">
                                        <span
                                            *ngIf="formPushNotification.controls.notificationImage.value != null && formPushNotification.controls.notificationImage.value != ''; else elseSelectText">Change
                                            Image</span>
                                        <ng-template #elseSelectText> Select Image</ng-template>
                                    </button>
                                    <!-- <input trim="blur" class="form-control modal-space" accept="image/*" type="file"
                                              (change)="changeListeners($event)" required>
                                            <span class="icontooltip" tooltip="1. Device must be on Android 4.1+ (API Level 16) 
                                            2. Android images require an aspect ratio of 2:1 (consider trying sizes 512x256, 960x480, or 1024x512). " [tooltipAnimation]="true" tooltipPlacement="right">
                                              <img src="assets/images/info-circle.svg " />
                                            </span> -->
                                </div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <div class="form-group row">
                                <label class="col-lg-3 col-md-12 control-label required">Message</label>
                                <div class="col-lg-7 col-md-12" #emojiElement>
                                    <textarea class="form-control" rows="9" formControlName="message" maxlength="1024"></textarea>
                                    <br/>
                                    <div class="emojiIcon" (click)="isShowEmoji = !isShowEmoji"><img src="assets/images/smile.svg" alt=""></div>
                                    <emoji-mart *ngIf="isShowEmoji" title="Pick your emoji…" (emojiClick)="addEmoji($event, formPushNotification, 'message' )"></emoji-mart>
                                </div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <div class="form-group row">
                                <label class="col-lg-3 col-md-12 control-label lable-span">Click Action
                    <span>(Optional)</span>
                  </label>
                                <div class="col-lg-7 col-md-12">
                                    <input trim="blur" class="form-control" type="text" formControlName="deepLink">
                                    <span class="icontooltip" tooltip="Write activityname to open android activity or write relative url (like /index.html) for opening web link" [tooltipAnimation]="true" tooltipPlacement="right">
                      <img src="assets/images/info-circle.svg " />
                    </span>
                                </div>
                            </div>
                        </div>
                       
                        <div class="mb-3 row">
                            <div class="text-right mt-1 mb-2 col-md-10 ">
                                <button class="btn btn-dark" type="submit">
                      <span *ngIf="isUpdate; else saveBtn1">Update</span>
                      <ng-template #saveBtn1>Save</ng-template>
                  </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12 col-sm-12 col-12">
                        <div class="screen-tab configuration pl-12">
                            <tabset>
                                <tab heading="Push Notification" *ngIf="activeTab == 'push'">
                                    <div class="pushimg">
                                        <div class="pushimg-detail-ios">

                                            <div class="push-width">
                                                <div class="push-contant">
                                                    <div class="push-icon">
                                                        <span>
                                  <img alt="" *ngIf="this.formPushNotification.controls.notificationIcon.value == null || this.formPushNotification.controls.notificationIcon.value == ''" src="{{'/assets/images/push_notification/blue-round.png'}}">
                                  <img alt="" *ngIf="this.formPushNotification.controls.notificationIcon.value != null && this.formPushNotification.controls.notificationIcon.value != ''" src="{{this.formPushNotification.controls.notificationIcon.value}}">
                                </span>
                                                    </div>
                                                    <div class="push-icon-detail">
                                                        <h5>{{formPushNotification.controls.title.value||'Title'}}</h5>
                                                        <div [innerHTML]="formPushNotification.controls.message.value ||'Lorem Ipsum is simply dummy text of the printing
                                and typesetting industry'"></div>
                                                    </div>
                                                    <img class="notification-banner-img" alt="" *ngIf="this.formPushNotification.controls.notificationImage.value != null && this.formPushNotification.controls.notificationImage.value != ''" src="{{this.formPushNotification.controls.notificationImage.value}}">
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </tab>

                                <!-- <tab heading="Android" *ngIf="activeTab == 'android'">
                                    <div class="pushimg android-push-img">
                                        <div class="pushimg-detail-android">
                                            <div class="push-width">
                                                <div class="push-contant">
                                                    <div class="push-icon">
                                                        <span>
                                                            <img alt=""
                                                                *ngIf="this.formPushNotification.controls.notificationIcon.value == null || this.formPushNotification.controls.notificationIcon.value == ''"
                                                                src="{{'/assets/images/push_notification/blue-round.png'}}">
                                                            <img alt=""
                                                                *ngIf="this.formPushNotification.controls.notificationIcon.value != null && this.formPushNotification.controls.notificationIcon.value != ''"
                                                                src="{{this.formPushNotification.controls.notificationIcon.value}}">
                                                        </span>
                                                    </div>
                                                    <div class="push-icon-detail">
                                                        <h5>{{formPushNotification.controls.title.value||'Title'}}</h5>
                                                        <div [innerHTML]="formPushNotification.controls.message.value ||'Lorem Ipsum is simply dummy text of the printing
                                                                and typesetting industry'"></div>
                                                    </div>
                                                    <img class="notification-banner-img" alt=""
                                                        *ngIf="this.formPushNotification.controls.notificationImage.value != null && this.formPushNotification.controls.notificationImage.value != ''"
                                                        src="{{this.formPushNotification.controls.notificationImage.value}}">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </tab> -->

                                <!-- <tab heading="Web" *ngIf="activeTab == 'web'">
                                    <div class="pushimg web-push-img">
                                        <div class="pushimg-detail-web">

                                            <div class="push-width-manage">

                                                <div class="push-width">

                                                    <div class="push-contant">
                                                        <div class="push-icon">
                                                            <span>
                                    <img alt="" *ngIf="this.formPushNotification.controls.notificationIcon.value == null || this.formPushNotification.controls.notificationIcon.value == ''" src="{{'/assets/images/push_notification/blue-round.png'}}">
                                    <img alt="" *ngIf="this.formPushNotification.controls.notificationIcon.value != null && this.formPushNotification.controls.notificationIcon.value != ''" src="{{this.formPushNotification.controls.notificationIcon.value}}">
                                  </span>
                                                        </div>
                                                        <div class="push-icon-detail">
                                                            <h5>{{formPushNotification.controls.title.value||'Title'}}</h5>
                                                            <div [innerHTML]="formPushNotification.controls.message.value ||'Lorem Ipsum is simply dummy text of the printing
                                and typesetting industry'"></div>
                                                        </div>
                                                        <img class="notification-banner-img" alt="" *ngIf="this.formPushNotification.controls.notificationImage.value != null && this.formPushNotification.controls.notificationImage.value != ''" src="{{this.formPushNotification.controls.notificationImage.value}}">
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </tab> -->
                            </tabset>

                        </div>

                    </div>

                </div>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #templateSMSCampaign>
    <form [formGroup]="formSMSCampaign" (ngSubmit)="saveSMSCampaign()">
        <div class="campaign-modal">
            <div class="modal-header">
                <h4 class="modal-title pull-left">SMS Campaign</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                        <div class="mb-3">
                            <div class="form-group row">
                                <label class="col-lg-4 col-md-12 control-label required">Template Name</label>
                                <div class="col-lg-8 col-md-12">
                                    <input trim="blur" class="form-control" type="text" formControlName="templateName" maxlength="50">
                                </div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <div class="form-group row">
                                <label class="col-lg-4 col-md-12 control-label">Dynamic Values</label>
                                <div class="col-lg-8 col-md-12">
                                    <ng-select [items]="inputParams" formControlName="paramsSelected" bindLabel="paramName" [multiple]="true" class="params" [(ngModel)]="inputParamsSelected" (add)="getSeletedParem($event, formSMSCampaign, 'smsText')" (remove)="removeParem($event, formSMSCampaign, 'smsText')" (clear)="allDynamicValRemoved('sms')">
                                    </ng-select>
                                    <span class="icontooltip" tooltip="Create customise message for each user by selecting dynamic fields from here. The selected field in the message gets replaced with individual user values during submission." [tooltipAnimation]="true" tooltipPlacement="right">
                      <img src="assets/images/info-circle.svg " />
                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <div class="form-group row">
                                <label class="col-lg-4 col-md-12 control-label required">Message</label>
                                <div class="col-lg-8 col-md-12">
                                    <textarea class="form-control" rows="5" formControlName="smsText" maxlength="700"></textarea>
                                    <span class="character-text">{{700 - smsTextLength}} left</span>
                                    <div class="mediaLink float-left" (click)="openGalleryModal('all,form')" tooltipPlacement="top" tooltip="Select Media / Custom Form">
                                        <img src="assets/images/link.svg" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="text-right mt-1 mb-2">
                            <button class="btn btn-dark" type="submit">
                    <span *ngIf="isUpdate; else saveBtn2">Update</span>
                    <ng-template #saveBtn2>Save</ng-template>
                </button>
                        </div>


                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 col-12 notification-hidden">

                        <div class="pushimg pt-50">
                            <div class="sms-campaign-detail">
                                <div class="campaign-content">
                                    <p>
                                        {{formSMSCampaign.controls.smsText.value||'Lorem ipsum dolor sit amet, tetur adipiscing elit, sed do ] labore aipsum dolor sit amet, tetur'}}
                                    </p>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #syncTemplateList>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Sync Whatsapp Template</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
    </div>
    <div class="modal-body p_0">
        <div class="table-reports">
            <div class="table-body">
        <div class="table-responsive table-height-scroll table-reports">
    <table class="table m_0">
        <thead>
            <tr>
                <th>Sr.</th>
                <th class="text-center">Name</th>
                <th class="text-right p_r_10">Action</th>
            </tr>
        </thead>
        <tbody *ngIf="wabaTemplateListData">
            <tr *ngFor="let list of wabaTemplateListData.waba_templates; let i=index;">
               <td>{{i+1}}</td>
               <td class="text-center">{{list.name}}</td>
               <td class="text-right">
                <button class="btn white_add_btn" (click)="importTmplt(list)">
                    Import
                </button>
                </td>
            </tr>
        </tbody>
    </table>
    </div>
    </div>
    </div>
    </div>
</ng-template>

<ng-template #syncTemplateWhatsappCampaign>
    <form #syncForm="ngForm">
        <div class="campaign-modal">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Whatsapp Campaign</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                        <!-- <div class="mb-3">
                            <div class="form-group row">
                                <label class="col-lg-4 col-md-12 control-label required">Template Name</label>
                                <div class="col-lg-8 col-md-12">
                                    <input trim="blur" class="form-control" type="text" name="templateName" [(ngModel)]="synctemplateName" maxlength="50">
                                </div>
                            </div>
                        </div> -->

                        <div class="mb-3">
                            <div class="form-group row">
                                <div class="floatingLabel">
                                   <div class="innerFloating">
                                    <label class="control-label">Template Name</label>
                                       <div class="textBox">
                                        <input trim="blur" class="form-control" type="text" name="templateName" [(ngModel)]="synctemplateName" maxlength="50">
                                           <!-- <input class="form-control" name="headerUrlText" [(ngModel)]="headerUrlText" (input)="headerImageUrl($event,'input')"> -->
                                           <!-- <label class="control-label">Language</label> -->
                                       </div>
                                   </div>
                               </div>
                           </div>
                        </div> 

                         <div class="mb-3" *ngIf="syncTemplateImport.components">
                            <ng-container>
                                    <div class="form-group row" *ngFor="let bodyParam of syncTemplateImport.bodyParameters; let bodyParamIndex = index;">
                                        <div class="floatingLabel">
                                            <div class="innerFloating">
                                                <div class="textBox">
                                                <input name="paramsText_{{bodyParamIndex}}" placeholder="Type here" [(ngModel)]="bodyParam.param_text" type="text" class="form-control" (input)="valueAddForTextParam($event.target.value,bodyParamIndex,'bodyParams')">
                                                <label class="control-label">Body Param {{bodyParamIndex+1}}</label>
                                                </div>
    
                                                <div class="or_middle"> OR </div>
    
                                                <div class="selectBox">
                                                    <select name="paramsSelected_{{bodyParamIndex}}" [(ngModel)]="bodyParam.param_select " class="params form-control text-right" (change)="bodyParamSelected($event.target.value,bodyParamIndex)">
                                                        <option value="">Select</option>
                                                        <option *ngFor="let paramsList of inputParams" value="{{paramsList.paramKey}}">{{paramsList.paramName}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                       
                                        <!-- <label class="col-lg-4 col-md-12 control-label">Body Param {{bodyParamIndex+1}}</label>
                                        <div class="col-lg-8 col-md-12">
                                            <select name="paramsSelected_{{bodyParamIndex}}" ngModel class="params form-control" (change)="bodyParamSelected($event.target.value,bodyParamIndex)">
                                                <option value="">Select</option>
                                                <option *ngFor="let paramsList of inputParams" value="{{paramsList.paramKey}}">{{paramsList.paramName}}</option>
                                            </select>
                                        </div> -->
                                    </div>
                            <ng-container *ngFor="let buttonParams of syncTemplateImport.buttonParameters; let buttonParamIndex = index;">
                                <div class="form-group row" *ngIf="buttonParams != 'nonParameter'">
                                    <div class="floatingLabel">
                                        <div class="innerFloating">
                                            <div class="textBox">
                                            <input name="paramsButtonText{{buttonParamIndex}}" placeholder="Type here" [(ngModel)]="buttonParams.param_text" (input)="valueAddForTextParam($event.target.value,buttonParamIndex,'buttonParams')" type="text" class="form-control">
                                            <label class="control-label">Button Param {{buttonParamIndex+1}}</label>
                                            </div>

                                            <div class="or_middle"> OR </div>

                                            <div class="selectBox">
                                                <select name="paramsButtonSelected_{{buttonParamIndex}}" [(ngModel)]="buttonParams.param_select" class="params form-control text-right" (change)="buttonParamSelected($event.target.value,buttonParamIndex)">
                                                    <option value="">Select</option>
                                                    <option *ngFor="let paramsList of inputParams" value="{{paramsList.paramKey}}">{{paramsList.paramName}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </ng-container>

                                <ng-container *ngIf="syncTemplateImport && syncTemplateImport.components && syncTemplateImport.components.headerFormat">
                                    <div class="form-group row">
                                        <div class="floatingLabel">
                                            <div class="innerFloating">
                                                <div class="textBox">
                                                <input name="headerFormat" [(ngModel)]="syncTemplateImport.components.headerFormat" disabled type="text" class="form-control">
                                                <label class="control-label">Header Format</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>

                            </ng-container>
                            
                        </div>
                        <div class="mb-3" *ngIf="syncTemplateImport.components.headerFormat">
                        <div class="form-group row">
                            <div class="floatingLabel">
                               <div class="innerFloating">
                                   <div class="textBox">
                                       <input class="form-control" name="headerUrlText" [(ngModel)]="headerUrlText" (input)="headerImageUrl($event,'input')">
                                       <label class="control-label">Header(Image/PDF)</label>
                                   </div>
                                   <div class="or_middle"> OR </div>
                                   <div class="selectBox">
                                       <select [(ngModel)]="syncheaderUrl" (change)="headerImageUrl($event,'select')" name="syncheaderUrl" class="form-control text-right">
                                           <option value="">Select</option>
                                           <option *ngFor="let paramsList of inputParamsFileType" value="{{paramsList.paramKey}}">{{paramsList.paramName}}</option>
                                       </select>
                                   </div>
                               </div>
                           </div>
                       </div>
                    </div>

                        
                        <!-- <div class="mb-3">
                            <div class="form-group row">
                                <label class="col-lg-4 col-md-12 control-label">Dynamic Values</label>
                                
                                <div class="col-lg-8 col-md-12">
                                    <ng-select [items]="inputParams" formControlName="paramsSelected" bindLabel="paramName" [multiple]="true" class="params" [(ngModel)]="inputParamsSelected" (add)="getSeletedParemWhatsapp($event, formWhatsappCampaign, 'smsText')" (remove)="removeParemWhatsapp($event, formWhatsappCampaign, 'smsText')" (clear)="allDynamicValRemoved('whatsapp')">
                                    </ng-select>
                                    <span class="icontooltip" tooltip="Create customise message for each user by selecting dynamic fields from here. The selected field in the message gets replaced with individual user values during submission." [tooltipAnimation]="true" tooltipPlacement="right">
                      <img src="assets/images/info-circle.svg " />
                    </span>
                                </div>
                                
                            </div>
                            
                        </div> -->
                        <!-- <div class="mb-3">
                            <div class="form-group row">
                                <label class="col-lg-4 col-md-12 control-label">Header (Image/PDF)</label>
                                <div class="col-lg-8 col-md-12">
                                    <input trim="blur" class="form-control" type="text" formControlName="headerUrl">
                                </div>
                            </div>
                        </div> -->

                            

                              <!-- <label class="col-lg-4 col-md-12 control-label">Header(Image/PDF)</label>
                              <div class="col-lg-8 col-md-12">
                                <ng-select [items]="inputParamsFileType" name="syncheaderUrl" bindLabel="paramName" [multiple]="false" class="params" [(ngModel)]="syncheaderUrl" (change)="headerImageUrl($event,'select')">
                                </ng-select>
                                <div class="orIcon">
                                  <span class="headerImageOr">OR</span>
                                </div>
                                <input class="form-control" name="headerUrlText" placeholder="Type header image/pdf url" [(ngModel)]="headerUrlText" (input)="headerImageUrl($event,'input')">
                              </div> -->

                              
                            
                              <div class="mb-3">
                                <div class="form-group row">
                                    <div class="floatingLabel">
                                       <div class="innerFloating">
                                        <label class="control-label">Language</label>
                                           <!-- <div class="textBox">
                                               <input class="form-control" name="headerUrlText" [(ngModel)]="headerUrlText" (input)="headerImageUrl($event,'input')">
                                               <label class="control-label">Language</label>
                                           </div> -->
                                           <!-- <div class="or_middle"> OR </div> -->
                                           <div class="selectBox">
                                            <select class="form-control line_height_18" name="synclanguageCode" [(ngModel)]="synclanguageCode">
                                                <option value="en">English</option>
                                                <option value="hi">Hindi</option>
                                            </select>
                                           </div>
                                       </div>
                                   </div>
                               </div>
                            </div>      


                        <!-- <div class="mb-3">
                            <div class="form-group row">
                                <label class="col-lg-4 col-md-12 control-label">Language</label>
                                <div class="col-lg-8 col-md-12">
                                    <select class="form-control line_height_18" name="synclanguageCode" [(ngModel)]="synclanguageCode">
                                        <option value="en">English</option>
                                        <option value="hi">Hindi</option>
                                    </select>
                                </div>
                            </div>
                        </div> -->


                        <!-- <div class="mb-3">
                            <div class="form-group row">
                                <label class="col-lg-4 col-md-12 control-label">Body Params</label>
                                <div class="col-lg-8 col-md-12">
                                    <textarea class="form-control" rows="5" name="bodyParams" [(ngModel)]="bodyParams" maxlength="500"></textarea>
                                    <span class="character-text">{{500 - smsTextLength}} left</span>
                                    <div class="mediaLink float-left" (click)="openGalleryModal('all,form')" tooltipPlacement="top" tooltip="Select Media / Custom Form">
                                        <img src="assets/images/link.svg" alt="">
                                    </div>
                                </div>
                            </div>
                        </div> -->

                        <div class="text-right mt-1 mb-2">
                            <button class="btn btn-dark" type="button" (click)="saveWabaForm(syncForm.value)">
                                Save
                            </button>
                        </div>


                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 col-12 notification-hidden">

                        <div class="pushimg pt-50">
                            <div class="sms-campaign-detail">
                                <div class="campaign-content">
                                    <p *ngIf="syncTemplateImport && syncTemplateImport.headerParams" class="headerBoxSync">
                                        <ng-container *ngIf="syncTemplateImport.components.headerText; else otherFormat">
                                        {{syncTemplateImport.components.headerText}}
                                        </ng-container>
                                        <ng-template #otherFormat>
                                            <ng-container *ngIf="syncTemplateImport.components.headerFormat;">
                                                {{syncTemplateImport.components.headerFormat}}
                                                </ng-container>
                                        </ng-template>
                                    </p>

                                    <p *ngIf="syncTemplateImport && syncTemplateImport.bodyText">
                                        {{syncTemplateImport.bodyText}}
                                    </p>
                                    <div class="m_t_10 text-center" *ngIf="syncTemplateImport && syncTemplateImport.buttonParams">
                                        <div *ngFor="let buttons of syncTemplateImport.components.buttons">
                                        <button class="linkBtnView">
                                            {{buttons.text}}
                                        </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </form>
</ng-template>


<ng-template #templateWhatsappCampaign>
    <form [formGroup]="formWhatsappCampaign" (ngSubmit)="saveWhatsappCampaign()">
        <div class="campaign-modal">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Whatsapp Campaign</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                        <div class="mb-3">
                            <div class="form-group row">
                                <label class="col-lg-4 col-md-12 control-label required">Template Name</label>
                                <div class="col-lg-8 col-md-12">
                                    <input trim="blur" class="form-control" type="text" formControlName="templateName" maxlength="50">
                                </div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <div class="form-group row">
                                <label class="col-lg-4 col-md-12 control-label">Dynamic Values</label>
                                
                                <div class="col-lg-8 col-md-12">
                                    <ng-select [items]="inputParams" formControlName="paramsSelected" bindLabel="paramName" [multiple]="true" class="params" [(ngModel)]="inputParamsSelected" (add)="getSeletedParemWhatsapp($event, formWhatsappCampaign, 'smsText')" (remove)="removeParemWhatsapp($event, formWhatsappCampaign, 'smsText')" (clear)="allDynamicValRemoved('whatsapp')">
                                    </ng-select>
                                    <span class="icontooltip" tooltip="Create customise message for each user by selecting dynamic fields from here. The selected field in the message gets replaced with individual user values during submission." [tooltipAnimation]="true" tooltipPlacement="right">
                      <img src="assets/images/info-circle.svg " />
                    </span>
                                </div>
                                
                            </div>
                            
                        </div>
                        <!-- <div class="mb-3">
                            <div class="form-group row">
                                <label class="col-lg-4 col-md-12 control-label">Header (Image/PDF)</label>
                                <div class="col-lg-8 col-md-12">
                                    <input trim="blur" class="form-control" type="text" formControlName="headerUrl">
                                </div>
                            </div>
                        </div> -->

                        <div class="mb-3">
                            <div class="form-group row">
                              <label class="col-lg-4 col-md-12 control-label">Header(Image/PDF)</label>
                              <div class="col-lg-8 col-md-12">
                                <!-- <input class="form-control" formControlName="headerUrl"> -->
                                <ng-select [items]="inputParamsFileType" formControlName="headerUrl" bindLabel="paramName" [multiple]="false" class="params" [(ngModel)]="headerUrl" (change)="headerImageUrl($event,'select')">
                                </ng-select>
                                <div class="orIcon">
                                  <span class="headerImageOr">OR</span>
                                </div>
                                <input class="form-control" formControlName="headerUrlText" placeholder="Type header image/pdf url" [(ngModel)]="headerUrlText" (input)="headerImageUrl($event,'input')">
                              </div>
                            </div>
                          </div>

                        <div class="mb-3">
                            <div class="form-group row">
                                <label class="col-lg-4 col-md-12 control-label">Language</label>
                                <div class="col-lg-8 col-md-12">
                                    <select class="form-control line_height_18" formControlName="languageCode">
                                        <option value="en">English</option>
                                        <option value="hi">Hindi</option>
                                    </select>
                                </div>
                            </div>
                        </div>


                        <div class="mb-3">
                            <div class="form-group row">
                                <label class="col-lg-4 col-md-12 control-label">Params</label>
                                <div class="col-lg-8 col-md-12">
                                    <textarea class="form-control" rows="5" formControlName="smsText" maxlength="500"></textarea>
                                    <span class="character-text">{{500 - smsTextLength}} left</span>
                                    <div class="mediaLink float-left" (click)="openGalleryModal('all,form')" tooltipPlacement="top" tooltip="Select Media / Custom Form">
                                        <img src="assets/images/link.svg" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="text-right mt-1 mb-2">
                            <button class="btn btn-dark" type="submit">
                    <span *ngIf="isUpdate; else saveBtn2">Update</span>
                    <ng-template #saveBtn2>Save</ng-template>
                </button>
                        </div>


                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 col-12 notification-hidden">

                        <div class="pushimg pt-50">
                            <div class="sms-campaign-detail">
                                <div class="campaign-content">
                                    <p>
                                        {{formSMSCampaign.controls.smsText.value||'Lorem ipsum dolor sit amet, tetur adipiscing elit, sed do ] labore aipsum dolor sit amet, tetur'}}
                                    </p>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </form>
</ng-template>


<ng-template #templateEmailCampaign>
    <form [formGroup]="formEmailCampaign" (ngSubmit)="saveEmailTemplate()">
        <div class="campaign-modal">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Email Campaign</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
            </div>
            <div class="modal-body pb-40 mbp-20">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-12">

                        <div class="mb-3">
                            <div class="form-group row">
                                <label class="col-lg-2 col-md-12 control-label required">Template Name</label>
                                <div class="col-lg-10 col-md-12">
                                    <input trim="blur" type="text" class="form-control" formControlName="templateName" maxlength="100">
                                </div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <div class="form-group row">
                                <label class="col-lg-2 col-md-12 control-label required">Subject</label>
                                <div class="col-lg-10 col-md-12">
                                    <input trim="blur" type="text" class="form-control" formControlName="emailSubject" maxlength="500">
                                </div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <div class="form-group row">
                                <label class="col-lg-2 col-md-12 control-label">Dynamic Values</label>
                                <div class="col-lg-10 col-md-12">
                                    <ng-select [items]="inputParams" formControlName="paramsSelected" class="params" bindLabel="paramName" [multiple]="true" [(ngModel)]="inputParamsSelected" (add)="getSeletedParem($event, formEmailCampaign, 'emailText')" (remove)="removeParem($event, formEmailCampaign, 'emailText')" (clear)="allDynamicValRemoved('email')">
                                    </ng-select>
                                    <span class="icontooltip" tooltip="Create customise message for each user by selecting dynamic fields from here. The selected field in the message gets replaced with individual user values during submission." [tooltipAnimation]="true" tooltipPlacement="right">
                      <img src="assets/images/info-circle.svg " />
                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <div class="form-group row">
                                <label class="col-lg-2 col-md-12 control-label required">Email</label>
                                <div class="col-lg-10 col-md-12">
                                    <div class="mb-3"><button (click)="openGalleryModal('all,form')" class="btn btn-dark" type="button">Select Media / Custom Form</button></div>
                                    <textarea *ngIf="!isEditorView" class="form-control" rows="16" formControlName="emailText"></textarea>
                                    <app-ckeditor *ngIf="isEditorView" formControlName="emailText" [config]="config" [editorData]="ckeditorData"></app-ckeditor>
                                    <div class="emojiIcon" (click)="isShowEmoji = !isShowEmoji"><img src="assets/images/smile.svg" alt=""></div>
                                    <emoji-mart *ngIf="isShowEmoji" title="Pick your emoji…" (emojiClick)="addEmoji($event, formEmailCampaign, 'emailText' )"></emoji-mart>
                                    <div class="text-right font-12 m_b_5" (click)="isEditorView = !isEditorView;">
                                        <a href="javascript:;" *ngIf="!isEditorView">HTML View</a>
                                        <a href="javascript:;" *ngIf="isEditorView">Editor View</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="text-right mt-1">
                            <button class="btn btn-dark" type="submit">
                    <span *ngIf="isUpdate; else saveBtn3">Update</span>
                    <ng-template #saveBtn3>Save</ng-template>
                </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #importEmailCampaign>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Import Template</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
    </div>
    <div class="modal-body template-list">
        <div class="row">
            <div class="col-md-2" *ngFor="let item of defaultEmailTemplateList">
                <div class="item" (click)="importTemplate(item.id)">
                    <div class="image"><img [src]="item.imagePath" alt="{{item.templateName}}"></div>
                    <h3>{{item.templateName}}</h3>
                    <span class="import">Import</span>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #confirm>
    <form>
        <div class="forgot-password-modal">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Delete Template</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
            </div>
            <div class="modal-body modal-user-management">
                <div class="form-group mb-0">
                    <p class="pt-2">
                        Are you sure, you want to delete?
                    </p>
                    <div class="d-flex justify-content-end mt-5">
                        <div>
                            <button class="btn remove-user-btn mr-4" (click)="modalRef.hide()" type="button">
                  Dismiss
                </button>
                        </div>
                        <div>
                            <button class="btn save-btn" type="button" (click)="deleteTemplate()">
                  Yes
                </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</ng-template>

<!-- <ng-template #galleryPopup>
      <gallery-popup [modalRefGallery]="modalRefGallery" (selectMediaEvent)="getMedia($event)" [galleryType]="galleryType"></gallery-popup>
  </ng-template> -->