import { Component, OnInit, TemplateRef, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { BsModalRef, BsModalService, TabsetComponent } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { ConfigurationService } from '../../../../core/services/configuration.service';
import { MasterService } from '../../../../core/services/master.service';
import { Global } from '../../../../shared/models/global.model';
import { noWhitespaceValidator } from '../../../../shared/models/no-whitespace-validator';
import { minLengthOrBlank } from '../../../../shared/models/min-length-validator.1';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { SpinnerService } from '../../../../core/services/spinner.service';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { GalleryPopupComponent } from '../campaign-gallery/gallery-popup.component';
import { AppConstants } from "../../../../../app/constants/app.constants";
import { Location } from '@angular/common';
import { ThrowStmt } from '@angular/compiler';
import { environment } from '../../../../../environments/environment';
import { ProjectService } from '../../../../core/services/project.service';
declare var io: any;

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss'],
})
export class ConfigurationComponent implements OnInit, AfterViewInit, OnDestroy {

  yearly: any = false;
  androidApp: any = {};
  iosApp: any = {};
  windowsApp: any = {};
  webApp: any = {};
  smsDetails: any = {};
  wpBDetails: any = {};
  smsDefaultDetails: any = {};
  wpBDefaultDetails: any = {};
  smsDetailsList: any = [];
  wpBDetailsList: any = [];
  emailDetails: any = {};
  emailDefaultDetails: any = {};
  emailDetailsList: any = [];
  email: any = {};
  smsProviders: any = [];
  smsProvider = undefined;

  wpBProviders: any = [];
  wpBProvider = undefined;

  emailProviders: any = [];
  emailProvider = undefined;
  apiType: any;
  authSelectBox: any;
  waitCount: any = 0;
  androidForm: FormGroup;
  iosForm: FormGroup;
  windowsForm: FormGroup;
  webForm: FormGroup;
  smsForm: FormGroup;
  emailForm: FormGroup;
  wpBForm: FormGroup;

  androidNotiForm: FormGroup;
  iosNotiForm: FormGroup;
  windowsNotiForm: FormGroup;
  webNotiForm: FormGroup;
  smsNotiForm: FormGroup;
  wpBNotiForm: FormGroup;
  emailNotiForm: FormGroup;
  inAppMobileForm: FormGroup;
  inAppWebForm: FormGroup;
  wpBDetailsId:any;
  modalRef: BsModalRef;
  whatsappErrorMsg:any=false;
  emailErrorMsg:any = false;
  smsErrorMsg:any = false;
  announcementErrorMsg:any = false;
  @ViewChild('android') androidTemplate: TemplateRef<any>;
  @ViewChild('ios') iosTemplate: TemplateRef<any>;
  @ViewChild('windows') windowsTemplate: TemplateRef<any>;
  @ViewChild('web') webTemplate: TemplateRef<any>;
  @ViewChild('sms') smsTemplate: TemplateRef<any>;
  @ViewChild('email') emailTemplate: TemplateRef<any>;
  @ViewChild('inAppMobileIframe') inAppMobileIframe: TemplateRef<any>;
  @ViewChild('inAppWebIframe') inAppWebIframe: TemplateRef<any>;
  @ViewChild('pageTabs') pageTabs: TabsetComponent;
  InAppMobileUrl: SafeUrl;
  InAppWebUrl: SafeUrl;
  isEmailTypePassword: boolean = true
  announcementJsURL: string = '';
  pageType: string = '';
  fcmURL: string = 'https://fcm.googleapis.com/fcm/send';
  userRole: number;
  isAndroidAppReset: boolean = false;
  isIOSAppReset: boolean = false;
  isWindowsAppReset: boolean = false;
  isWebAppReset: boolean = false;
  isEmailReset: boolean = false;
  isSMSReset: boolean = false;
  iswpBReset: boolean = false;
  isWhatsAppReset: boolean = false;
  selectfile:any;
  whatsAppPairingStatus: number;
  whatsAppMsg: { smsText?: string, deepLink?: string, imagePath?: string, phone?: string, confStatus?: string, tested?: boolean, };
  whatsAppQrCode: string = '';
  modalRefGallery: BsModalRef;
  whatsAppMsgSubmited: boolean = false;
  whatsAppUnderProcess: number = 0;
  webSocket: any;
  whatsappInstanceId: string = '';
  whatsAppMsgNumber: string = '';
  androidConfUrl:any = environment.pushServiceUrl;
  iosConfUrl:any = environment.pushServiceUrl;
  webConfUrl:any = environment.pushServiceUrl;
  smsConfUrl:any = environment.smsPushUrl;
  emailConfUrl:any = environment.emailPushUrl;
  whatsappConfUrl:any = environment.whatsappApiUrl;
  disablewps:boolean = false;
  disableSms:boolean = false;
  disableEmail:boolean = false;
  showSyncButton:boolean = false;
  constructor(private modalService: BsModalService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    public global: Global,
    private sanitizer: DomSanitizer,
    private configurationService: ConfigurationService,
    private masterService: MasterService,
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private authenticationService: AuthenticationService,
    private location: Location,
    private projectService:ProjectService

  ) {
    this.route.params.subscribe(params => {
      if (params['type'] !== undefined) {
        this.pageType = params['type'];
      }
    });
  }

  ngOnDestroy() {
    if (this.modalRef) {
      this.modalRef.hide();
    }
    if (this.webSocket) {
      this.webSocket.close();
    };
  }

  back() {
    this.location.back();
  }

  ngOnInit() {
    this.userRole = this.authenticationService.getUserRole();
    this.prepareAndroidForm();
    this.prepareIosForm();
    this.prepareWindowsForm();
    this.prepareWebForm();
    this.prepareSMSForm();
    this.prepareEmailForm();
    this.prepareNotiForms();
    this.preparewpBForm();

    if (this.pageType == 'push') {
      this.pageTabs.tabs[0].active = true;
    } else if (this.pageType == 'sms') {
      this.pageTabs.tabs[1].active = true;
    } else if (this.pageType == 'email') {
      this.pageTabs.tabs[2].active = true;
    } else if (this.pageType == 'inapp') {
      this.pageTabs.tabs[3].active = true;
    } else if (this.pageType == 'announcement') {
      this.pageTabs.tabs[4].active = true;
    }
    else if (this.pageType == 'Whatsapp-Api') {
      this.pageTabs.tabs[6].active = true;
    }

  }

  uploadDefaultValues(event){
    this.selectfile = event.target.files[0];
    this.androidForm.get('file').setValue(this.selectfile);
    console.log(this.selectfile);

    let readerResult ;
    let postData = {"file":""}
        const reader = new FileReader();
        reader.readAsDataURL(this.selectfile);
        reader.onload = () => {
            console.log(reader.result);
            readerResult = reader.result
            this.androidForm.get('file').setValue(readerResult);
        }
}


uploadIosValues(event){
  this.selectfile = event.target.files[0];
  this.iosForm.get('file').setValue(this.selectfile);
  console.log(this.selectfile);

  let readerResult ;
  let postData = {"file":""}
      const reader = new FileReader();
      reader.readAsDataURL(this.selectfile);
      reader.onload = () => {
          console.log(reader.result);
          readerResult = reader.result
          this.iosForm.get('file').setValue(readerResult);
      }
}


uploadDeWebValues(event){
  this.selectfile = event.target.files[0];
  this.webForm.get('file').setValue(this.selectfile);
  console.log(this.selectfile);

  let readerResult ;
  let postData = {"file":""}
      const reader = new FileReader();
      reader.readAsDataURL(this.selectfile);
      reader.onload = () => {
          console.log(reader.result);
          readerResult = reader.result
          this.webForm.get('file').setValue(readerResult);
      }
}


  ngAfterViewInit() {
    this.pageTabs.tabs.forEach(element => {
      if (element.active)
        this.changeTab(element);
    });
  }

  changeTab(e) {
    if (this.webSocket) {
      this.webSocket.close();
    };
    if (e.id == 'push' && this.androidApp.id == undefined) {
      this.getAndroidApp();
      this.getIosApp();
      this.getWebApp();
    } else if (e.id == 'sms' && this.smsDetailsList.length == 0) {      
      this.getServiceProviders('Sms');
      this.getSMSDetails();
    } else if (e.id == 'email' && this.emailDetailsList.length == 0) {
      this.getServiceProviders('Email');
      this.getEmailDetails();
    } else if (e.id == 'announcement' && this.announcementJsURL == '') {
      this.getAnnouncementCode();
    } else if (e.id == 'whatsApp') {
      this.getWhatsApp();
    }
    else if (e.id == 'Whatsapp-Api' ) {
      this.getServiceProviders('Whatsapp');
      this.getwpBDetails();
    }
  }

  syncConf(){
    this.projectService.getSyncConfiguration({}).subscribe(data=>{
      if(data.status){
        this.wpBForm.patchValue({authorization:data.data})
      } else{
        this.toastr.error(data.error_msgs);
      }      
    })
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { backdrop: 'static' });
  }

  openModalValidation(formGroup: FormGroup, template: TemplateRef<any>) { 
    console.log(formGroup)   
    if (formGroup.invalid) {
      this.toastr.error(this.global.MessageRequired); this.global.validateAllFormFields(formGroup);
      return;
    }
    this.modalRef = this.modalService.show(template, { backdrop: 'static', class: 'model-custom-sm' });
  }


  whatsappPushConfigHeadersDTO(param?): any {
    if (param) {
      this.wpBForm.reset();
      return;
    }
    return this.wpBForm.get('whatsappPushConfigHeadersDTO');
  }

  smsPushConfigHeadersDTO(param?): any {
    if (param) {
      this.smsForm.reset();
      return;
    }
    return this.smsForm.get('smsPushConfigHeadersDTO');
  }


  whatsappPushConfigBodyDTO(param?): any {
    if (param) {
      this.wpBForm.reset();
      return;
    }
    return this.wpBForm.get('whatsappPushConfigBodyDTO');
  }

  smsPushConfigBodyDTO(param?): any {
    if (param) {
      this.smsForm.reset();
      return;
    }
    return this.smsForm.get('smsPushConfigBodyDTO');
  }

  prepareNotiForms() {
    this.androidNotiForm = this.formBuilder.group({
      deviceId: ['', Validators.compose([Validators.required, noWhitespaceValidator, Validators.minLength(5)])],
      title: ['', Validators.compose([Validators.required, noWhitespaceValidator, Validators.minLength(5), Validators.maxLength(50)])],
      message: ['', Validators.compose([Validators.required, noWhitespaceValidator, Validators.minLength(5), Validators.maxLength(100)])],
      notificationIcon: [''],
      notificationImage: ['']
    });
    this.iosNotiForm = this.formBuilder.group({
      deviceId: ['', Validators.compose([Validators.required, noWhitespaceValidator, Validators.minLength(5)])],
      title: ['', Validators.compose([Validators.required, noWhitespaceValidator, Validators.minLength(5), Validators.maxLength(50)])],
      message: ['', Validators.compose([Validators.required, noWhitespaceValidator, Validators.minLength(5), Validators.maxLength(100)])],
      notificationIcon: [''],
      notificationImage: ['']
    });
    this.windowsNotiForm = this.formBuilder.group({
      deviceId: ['', Validators.compose([Validators.required, noWhitespaceValidator, Validators.minLength(5)])],
      title: ['', Validators.compose([Validators.required, noWhitespaceValidator, Validators.minLength(5), Validators.maxLength(50)])],
      message: ['', Validators.compose([Validators.required, noWhitespaceValidator, Validators.minLength(5), Validators.maxLength(100)])]
    });
    this.webNotiForm = this.formBuilder.group({
      deviceId: ['', Validators.compose([Validators.required, noWhitespaceValidator, Validators.minLength(5)])],
      title: ['', Validators.compose([Validators.required, noWhitespaceValidator, Validators.minLength(5), Validators.maxLength(50)])],
      message: ['', Validators.compose([Validators.required, noWhitespaceValidator, Validators.minLength(5), Validators.maxLength(100)])],
      notificationIcon: [''],
      notificationImage: ['']
    });
    this.smsNotiForm = this.formBuilder.group({
      phone: ['', Validators.compose([Validators.required, noWhitespaceValidator, Validators.minLength(7), Validators.maxLength(15), Validators.pattern(this.global.NumericPattern)])],
      message: ['', Validators.compose([Validators.required, noWhitespaceValidator, Validators.minLength(5), Validators.maxLength(500)])]

    });

    this.wpBNotiForm = this.formBuilder.group({
      phone: ['', Validators.compose([Validators.required, noWhitespaceValidator, Validators.minLength(7), Validators.maxLength(15), Validators.pattern(this.global.NumericPattern)])],
      message: ['', Validators.compose([Validators.maxLength(500)])],
      templateName:['',[Validators.required]],
      languageCode:['',[Validators.required]],
      mediaUrl:['',[]],
      countryCode:['',[Validators.required]],
      mediaType:["",[]],
      buttonText:["",[]]
    });
    this.emailNotiForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, noWhitespaceValidator, Validators.minLength(5), Validators.maxLength(50), Validators.pattern(this.global.EmailPattern)])],
      subject: ['', Validators.compose([Validators.required, noWhitespaceValidator, Validators.minLength(5), Validators.maxLength(100)])],
      message: ['', Validators.compose([Validators.required, noWhitespaceValidator, Validators.minLength(5), Validators.maxLength(10000)])]
    });
    this.inAppMobileForm = this.formBuilder.group({
      audienceID: ['', Validators.compose([Validators.required, noWhitespaceValidator, Validators.minLength(1), Validators.maxLength(500)])],
      apiKey: ['', Validators.compose([Validators.required, noWhitespaceValidator, Validators.minLength(5), Validators.maxLength(500)])]
    });
    this.inAppWebForm = this.formBuilder.group({
      audienceID: ['', Validators.compose([Validators.required, noWhitespaceValidator, Validators.minLength(1), Validators.maxLength(500)])],
      apiKey: ['', Validators.compose([Validators.required, noWhitespaceValidator, Validators.minLength(5), Validators.maxLength(500)])]
    });



  }

  prepareAndroidForm(type?) {
    this.androidForm = this.formBuilder.group({
      tested: false,
      userId: [this.androidApp.userId],
      pushType: "ANDROID",
      file:"",
      json_file_name:""
    });
    if ((this.userRole != 1 && this.androidApp.userId == null) && type == undefined) {
      setTimeout(() => this.androidForm.disable(), 500);
    } else {
      setTimeout(() => this.androidForm.enable(), 500);
    }

  }

  prepareIosForm(type?) {
    this.iosForm = this.formBuilder.group({
      tested: false,
      userId: [this.iosApp.userId],
      pushType: "IOS",
      file:"",
      json_file_name:""
    });
    if ((this.userRole != 1 && this.iosApp.userId == null) && type == undefined) {
      setTimeout(() => this.iosForm.disable(), 500);
    } else {
      setTimeout(() => this.iosForm.enable(), 500);
    }
  }

  prepareWindowsForm() {
    this.windowsForm = this.formBuilder.group({
      fcmSenderid: [this.windowsApp.fcmSenderid, Validators.compose([Validators.required, noWhitespaceValidator, Validators.minLength(5)])],
      fcmKey: [this.windowsApp.fcmKey, Validators.compose([Validators.required, noWhitespaceValidator, Validators.minLength(6)])],
      tested: false
    });
  }

  prepareWebForm(type?) {
    this.webForm = this.formBuilder.group({
      tested: false,
      pushType: "WEB",
      file:"",
      json_file_name:""
    });
    if ((this.userRole != 1 && this.webApp.userId == null) && type == undefined) {
      setTimeout(() => this.webForm.disable(), 500);
    } else {
      setTimeout(() => this.webForm.enable(), 500);
    }
  }


  preparewpBForm(type?) {
    var providerId = this.wpBDetails.providerId == undefined ? '' : this.wpBDetails.providerId;    
    this.wpBForm = this.formBuilder.group({
      providerId: [providerId, Validators.compose([Validators.required, Validators.pattern(this.global.NumericPattern)])],
      providerName: [this.wpBDetails.providerName, Validators.compose([Validators.required, noWhitespaceValidator, Validators.minLength(3), Validators.maxLength(50)])],
      authorization:[this.wpBDetails.uiAuthorization ? this.wpBDetails.uiAuthorization[0]:null,[]],
      tested: false
    });
    this.onChangeAuthTypeWpb(this.wpBDetails.authType);
    if (this.wpBDetails.providerId)
      this.selectwpBProvider(this.wpBDetails.providerId);

    this.wpBForm.get('providerId').valueChanges.subscribe(providerId => {
      this.selectwpBProvider(providerId);
    });
  
    if ((this.userRole != 1 && this.wpBDetails.userId == 0) && type == undefined) {
      this.disablewps = true;
      setTimeout(() => this.wpBForm.disable(), 500);
    } else {
      this.disablewps = false;
      setTimeout(() => this.wpBForm.enable(), 500);
    }

  }


  prepareSMSForm(type?) {
    
    var providerId = this.smsDetails.providerId == undefined ? '' : this.smsDetails.providerId;
    
    this.smsForm = this.formBuilder.group({
      id: [this.smsDetails.id],
      providerId: [providerId, Validators.compose([Validators.required, Validators.pattern(this.global.NumericPattern)])],
      apiType: [this.smsDetails.apiType || '', Validators.required],
      apiEndpoint: [this.smsDetails.apiEndpoint, Validators.compose([Validators.required, noWhitespaceValidator, Validators.minLength(3)])],
      nickName: [this.smsDetails.nickName, Validators.compose([Validators.required, noWhitespaceValidator, Validators.minLength(3), Validators.maxLength(50)])],
      authType: [this.smsDetails.authType, Validators.compose([Validators.required, Validators.minLength(2)])],
      isDefault: [this.smsDetails.isDefault],
      raw: [this.smsDetails.raw],
      contentType:this.smsDetails.contentType,
      apiUser: [this.smsDetails.apiUser, Validators.compose([Validators.required, noWhitespaceValidator])],
      apiPassword: [this.smsDetails.apiPassword, Validators.compose([Validators.required, noWhitespaceValidator])],
      smsPushConfigHeadersDTO: this.formBuilder.array([]),
      smsPushConfigBodyDTO: this.formBuilder.array([]),
      tested: false
    });
    this.onChangeAuthType(this.smsDetails.authType);
    const arraySmsPushConfigHeadersDTO = <FormArray>this.sf.smsPushConfigHeadersDTO;
    if (this.smsDetails.smsPushConfigHeadersDTO == undefined || this.smsDetails.smsPushConfigHeadersDTO.length == 0) {
      this.smsDetails.smsPushConfigHeadersDTO = [];
    }
    this.smsDetails.smsPushConfigHeadersDTO.forEach(item => {
      let newGroup = this.formBuilder.group({
        id: [item.id],
        smsPushConfigurationId: [this.smsDetails.id],
        keyName: [item.keyName, Validators.compose([Validators.required, noWhitespaceValidator])],
        keyValue: [item.keyValue, Validators.compose([Validators.required, noWhitespaceValidator])]
      });
      arraySmsPushConfigHeadersDTO.push(newGroup);
    });

    const arraySmsPushConfigBodyDTO = <FormArray>this.sf.smsPushConfigBodyDTO;
    if (this.smsDetails.smsPushConfigBodyDTO == undefined || this.smsDetails.smsPushConfigBodyDTO.length == 0) {
      this.smsDetails.smsPushConfigBodyDTO = [];
    }
    this.smsDetails.smsPushConfigBodyDTO.forEach(item => {
      let newGroup = this.formBuilder.group({
        id: [item.id],
        smsPushConfigurationId: [this.smsDetails.id],
        keyName: [item.keyName, Validators.compose([Validators.required, noWhitespaceValidator])],
        keyValue: [item.keyValue, Validators.compose([Validators.required, noWhitespaceValidator])],
      });
      arraySmsPushConfigBodyDTO.push(newGroup);
    });

    if (this.smsDetails.providerId)
      this.selectSmsProvider(this.smsDetails.providerId);

    this.smsForm.get('providerId').valueChanges.subscribe(providerId => {
      this.selectSmsProvider(providerId);
    });

    if ((this.userRole != 1 && this.smsDetails.userId == null) && type == undefined) {
      this.disableSms = true
      setTimeout(() => this.smsForm.disable(), 500);
    } else {
      this.disableSms = false;
      setTimeout(() => this.smsForm.enable(), 500);
    }

  }

  private selectwpBProvider(providerId: any) {

    this.wpBProvider = this.wpBProviders.find(function (x) { return x.id == providerId; });
  }

  private selectSmsProvider(providerId: any) {

    this.smsProvider = this.smsProviders.find(function (x) { return x.id == providerId; });
  }

  prepareEmailForm(type?) {
    var providerId = this.emailDetails.providerId == undefined ? '' : this.emailDetails.providerId;
    this.emailForm = this.formBuilder.group({
      id: [this.emailDetails.id],
      providerId: [providerId, Validators.compose([Validators.required, Validators.pattern(this.global.NumericPattern)])],
      emailServerIp: [this.emailDetails.emailServerIp, Validators.compose([Validators.required, noWhitespaceValidator, Validators.minLength(7), Validators.maxLength(50)])],
      emailServerPort: [this.emailDetails.emailServerPort, Validators.compose([minLengthOrBlank(2), Validators.maxLength(6), Validators.pattern(this.global.NumericPattern)])],
      emailUser: [this.emailDetails.emailUser, Validators.compose([Validators.required, noWhitespaceValidator, Validators.minLength(1), Validators.maxLength(50)])],
      nickName: [this.emailDetails.nickName, Validators.compose([Validators.required, noWhitespaceValidator, Validators.minLength(3), Validators.maxLength(50)])],
      isDefault: [this.emailDetails.isDefault],
      emailPassword: [this.emailDetails.emailPassword, Validators.compose([Validators.required, noWhitespaceValidator, Validators.minLength(3), Validators.maxLength(500)])],
      emailFromAddress: [this.emailDetails.emailFromAddress, Validators.compose([Validators.required, noWhitespaceValidator, Validators.minLength(3), Validators.maxLength(150)])],
      emailFromAlias: [this.emailDetails.emailFromAlias],
      callBackUl: [this.emailDetails.callBackUl, Validators.compose([Validators.pattern(this.global.Url)])],
      unsubscribePage: [this.emailDetails.unsubscribePage],
      tested: false
    });
    
    if (this.emailDetails.providerId)
      this.selectEmailProvider(this.emailDetails.providerId);
    this.emailForm.get('providerId').valueChanges.subscribe(providerId => {
      this.selectEmailProvider(providerId);
    });

    if ((this.userRole != 1 && this.emailDetails.userId == null) && type == undefined) {
      this.disableEmail = true;
      setTimeout(() => this.emailForm.disable(), 500);
    } else {
      this.disableEmail = false;
      setTimeout(() => this.emailForm.enable(), 500);
    }
  }


  private selectEmailProvider(providerId: any) {
    this.emailProvider = this.emailProviders.find(function (x) { return x.id == providerId; });
    
  }

  removeSMSHeader(i) {
    const arraySmsPushConfigHeadersDTO = <FormArray>this.sf.smsPushConfigHeadersDTO;
    arraySmsPushConfigHeadersDTO.removeAt(i);
  }


  removewpBHeader(i) {
    const arrayWpPushConfigHeadersDTO = <FormArray>this.wpf.whatsappPushConfigHeadersDTO;
    arrayWpPushConfigHeadersDTO.removeAt(i);
  }

  removeSMSBody(i) {
    const arraySmsPushConfigBodyDTO = <FormArray>this.sf.smsPushConfigBodyDTO;
    arraySmsPushConfigBodyDTO.removeAt(i);
  }

  removewpBBody(i) {
    const arrayWpPushConfigBodyDTO = <FormArray>this.wpf.whatsappPushConfigBodyDTO;
    arrayWpPushConfigBodyDTO.removeAt(i);
  }



  addwpBHeader() {

    const arrayWpPushConfigHeadersDTO = <FormArray>this.wpf.whatsappPushConfigHeadersDTO;
    let newGroup = this.formBuilder.group({
      id: [0],
      arrayWpPushConfigHeadersDTO: [this.wpBDetails.id],
      keyName: ['', Validators.compose([Validators.required, noWhitespaceValidator])],
      keyValue: ['', Validators.compose([Validators.required, noWhitespaceValidator])],
    });
    arrayWpPushConfigHeadersDTO.push(newGroup);
  }


  addSMSHeader() {

    const arraySmsPushConfigHeadersDTO = <FormArray>this.sf.smsPushConfigHeadersDTO;
    let newGroup = this.formBuilder.group({
      id: [0],
      smsPushConfigurationId: [this.smsDetails.id],
      keyName: ['', Validators.compose([Validators.required, noWhitespaceValidator])],
      keyValue: ['', Validators.compose([Validators.required, noWhitespaceValidator])],
    });    
    arraySmsPushConfigHeadersDTO.push(newGroup);
  }


  addwpBBody() {
    const arrayWpPushConfigBodyDTO = <FormArray>this.wpf.whatsappPushConfigBodyDTO;
    let newGroup = this.formBuilder.group({
      id: [0],
      whatsappPushConfigurationId: [this.wpBDetails.id],
      keyName: ['', Validators.compose([Validators.required, noWhitespaceValidator])],
      keyValue: ['', Validators.compose([Validators.required, noWhitespaceValidator])]
    });
    arrayWpPushConfigBodyDTO.push(newGroup);
  }

  addSMSBody() {
    const arraySmsPushConfigBodyDTO = <FormArray>this.sf.smsPushConfigBodyDTO;
    let newGroup = this.formBuilder.group({
      id: [0],
      smsPushConfigurationId: [this.smsDetails.id],
      keyName: ['', Validators.compose([Validators.required, noWhitespaceValidator])],
      keyValue: ['', Validators.compose([Validators.required, noWhitespaceValidator])]
    });
    arraySmsPushConfigBodyDTO.push(newGroup);
  }

  openProviderLink(type) {
    let link: any = '';
    let list: any = [];
    let id: any = 0;
    if (type == 'Email') {
      list = this.emailProviders;
      id = this.ef.providerId.value;
    } else if (type == 'whatsAppB') {
      list = this.wpBProviders;
      id = this.wpf.providerId.value;
    }
    else {
      list = this.smsProviders;
      id = this.sf.providerId.value;
    }
    for (let index = 0; index < list.length; index++) {
      if (list[index].id == id) {
        link = list[index].integrationGuide;
      }
    }

    window.open(link, "_blank");
  }


  onChangewpBAuthType(authType) {
    this.wpBDetails.authType = authType;
    const apiUser = this.wpBForm.get('apiUser');
    const apiPassword = this.wpBForm.get('apiPassword');
    if (authType != 'Select Authentication') {
      if (authType != 'Basic') {
        apiUser.setValidators(null);
        apiPassword.setValidators(null);
      }
      else {
        apiUser.setValidators([Validators.compose([Validators.required, noWhitespaceValidator])]);
        apiPassword.setValidators([Validators.compose([Validators.required, noWhitespaceValidator])]);
      }
      apiUser.updateValueAndValidity();
      apiPassword.updateValueAndValidity();
    }
  }





  onChangeAuthType(authType) {
    this.smsDetails.authType = authType;
    const apiUser = this.smsForm.get('apiUser');
    const apiPassword = this.smsForm.get('apiPassword');
    if (authType != 'Select Authentication') {
      if (authType != 'Basic') {
        apiUser.setValidators(null);
        apiPassword.setValidators(null);
      }
      else {
        apiUser.setValidators([Validators.compose([Validators.required, noWhitespaceValidator])]);
        apiPassword.setValidators([Validators.compose([Validators.required, noWhitespaceValidator])]);
      }
      apiUser.updateValueAndValidity();
      apiPassword.updateValueAndValidity();
    }
  }

  onChangeAuthTypeWpb(authType) {
    // this.wpBDetails.authType = authType;
    // const apiUser = this.wpBForm.get('apiUser');
    // const apiPassword = this.wpBForm.get('apiPassword');
    // if (authType != 'Select Authentication') {
    //   if (authType != 'Basic') {
    //     apiUser.setValidators(null);
    //     apiPassword.setValidators(null);
    //   }
    //   else {
    //     apiUser.setValidators([Validators.compose([Validators.required, noWhitespaceValidator])]);
    //     apiPassword.setValidators([Validators.compose([Validators.required, noWhitespaceValidator])]);
    //   }
    //   apiUser.updateValueAndValidity();
    //   apiPassword.updateValueAndValidity();
    // }
  }


  onChangeselectTypewpB(selectType) {
    this.wpBDetails.contentType = selectType
    this.wpBDetails.selectType = selectType;    
    const arrayWpPushConfigBodyDTO = <FormArray>this.wpf.whatsappPushConfigBodyDTO;
    
    if (selectType == 'Raw') {
      var result = '';
      for (let index = 0; index < arrayWpPushConfigBodyDTO.length; index++) {
        const element = <FormGroup>arrayWpPushConfigBodyDTO.controls[index];
        if (element.controls.keyName.value != null && element.controls.keyValue.value != null) {
          result += element.controls.keyName.value + '=' + element.controls.keyValue.value + '&';
        }
      }
      this.wpf.raw.setValue(result);
    }
    else {      
     
      result = this.wpf.raw.value;      
      if(result != null)
      {
      var lst = result.split('&');
      if (lst.length == 0) {
        lst.push('=');
      }
      for (let index = 0; index < lst.length; index++) {
        const element = lst[index];
        let newGroup = this.formBuilder.group({
          id: [0],
        whatsappPushConfigurationId: [this.wpBDetails.id],
          keyName: [element.split('=')[0], Validators.compose([Validators.required, noWhitespaceValidator])],
          keyValue: [element.split('=')[1], Validators.compose([Validators.required, noWhitespaceValidator])]
        });
        arrayWpPushConfigBodyDTO.push(newGroup);
      }
    }
    
    }

  }


  onChangeselectType(selectType) {    
    this.smsDetails.selectType = selectType;
    const arraySmsPushConfigBodyDTO = <FormArray>this.sf.smsPushConfigBodyDTO;
    if (selectType == 'Raw') {
      var result = '';
      for (let index = 0; index < arraySmsPushConfigBodyDTO.length; index++) {
        const element = <FormGroup>arraySmsPushConfigBodyDTO.controls[index];
        if (element.controls.keyName.value != null && element.controls.keyValue.value != null) {
          result += element.controls.keyName.value + '=' + element.controls.keyValue.value + '&';
        }
      }
      this.sf.raw.setValue(result);
    }
    else {
      
      result = this.sf.raw.value;      
      var lst = result != null ? result.split('&') : [];
      if (lst.length == 0) {
        lst.push('=');
      }      
      for (let index = 0; index < lst.length; index++) {
        const element = lst[index];
        let newGroup = this.formBuilder.group({
          id: [0],
          smsPushConfigurationId: [this.smsDetails.id],
          keyName: [element.split('=')[0], Validators.compose([Validators.required, noWhitespaceValidator])],
          keyValue: [element.split('=')[1], Validators.compose([Validators.required, noWhitespaceValidator])]
        });
        arraySmsPushConfigBodyDTO.push(newGroup);
      }
    }

  }

  getServiceProviders(type) {
    let typeUrl
    if(type == 'Sms'){
      typeUrl = this.smsConfUrl;
    } else if(type == 'Whatsapp'){
      typeUrl = this.whatsappConfUrl;
    }else if(type == "Email"){
      typeUrl = this.emailConfUrl;
    }
    
    this.masterService.getServiceProviders({ type: type },typeUrl).subscribe(data => {
      
      if (data.status) {
        
        if (type == 'Sms') {
          this.smsProviders = data.data;
        } else if (type == 'Whatsapp') {
          this.wpBProviders = data.data;
        }
        else if(type == "Email") {
          this.emailProviders = data.data;          
        }
      }
    });
  }

  getAndroidApp() {
    this.configurationService.getConfiguration({ pushType: "ANDROID" },this.androidConfUrl).subscribe(data => {
      if (data.status) {
        this.androidApp = data.data;
      } else {
        this.androidApp.fcmSenderid = this.fcmURL;
      }
      this.isAndroidAppReset = this.androidApp.userId != null;
      this.prepareAndroidForm();
    });
  }

  getIosApp() {
    this.configurationService.getConfiguration({pushType: "IOS"},this.iosConfUrl).subscribe(data => {
      if (data.status) {
        this.iosApp = data.data;
      } else {
        this.iosApp.fcmSenderid = this.fcmURL;
      }
      this.isIOSAppReset = this.iosApp.userId != null;
      this.prepareIosForm();
    });
  }

  getWebApp() {
    this.configurationService.getConfiguration({pushType: "WEB"},this.webConfUrl).subscribe(data => {
      if (data.status) {
        this.webApp = data.data;
        this.isWebAppReset = this.webApp.userId != null;
        this.prepareWebForm();
      }
    });
  }

  getWindowsApp() {
    this.configurationService.getWindowsApp({}).subscribe(data => {
      if (data.status) {
        this.windowsApp = data.data;
      } else {
        this.windowsApp.fcmSenderid = this.fcmURL;
      }
      this.isWebAppReset = this.windowsApp.userId != null;
      this.prepareWindowsForm();
    });
  }

  getAnnouncementCode() {
    this.configurationService.getUserJsConfiguration({}).subscribe(data => {
      if (data.status) {
        this.announcementJsURL = data.data.userJsUrl;
        this.announcementErrorMsg = false;
      }
      else
      {
        this.announcementErrorMsg = data.error_msgs;
      }
    });
  }

  

  getSMSDetails() {
    
    this.configurationService.getSMSDetails({},this.smsConfUrl).subscribe(data => {
      if (data.status) {
       this.smsDetailsList = data.data;

       for(let i=0; i<this.smsDetailsList.length; i++)
       {
        if(this.smsDetailsList[i].id > 0)
        {
            this.updateSMSProvide(this.smsDetailsList[i].providerId);
            this.isSMSReset = this.smsDetails.userId != null;
            this.prepareSMSForm();
            return;
        }
        else if(this.smsDetailsList[i].id == 0 && this.smsDetailsList[i].nickName == 'Alcodes')
          {
            this.smsDetails = this.smsDetailsList[i];
            this.updateSMSForm(this.smsDetailsList[i]);
            this.isSMSReset = this.smsDetails.userId != null;
            this.prepareSMSForm();            
          }

       }
        
        this.smsErrorMsg = false;
      }
      else
      {
        this.smsErrorMsg = data.error_msgs;
      }
    });
  }


  getwpBDetails() {
    this.configurationService.getConfiguration({},this.whatsappConfUrl).subscribe(data => {
      if (data.status) {
        this.wpBDetailsList = data.data;        
        for(let i=0; i<this.wpBDetailsList.length; i++)
        {
          if(this.wpBDetailsList[i].id > 0)
          {
            this.wpBDetails = this.wpBDetailsList[i];           
            this.iswpBReset = this.wpBDetails.userId != null;
            this.wpBDetails.uiFields = [];
            this.wpBDetails.uiAuthorization = [];
            this.wpBDetails.uiFields = this.wpBDetails.fields.split(',');
            this.wpBDetails.uiAuthorization = this.wpBDetails.authorization.split(',');

            this.wpBDetails.uiAuthorization.filter((p)=>{
              return p != null;
            })

            this.wpBDetails.uiFields.filter((p)=>{
              return p != null;
            })
            this.preparewpBForm();
            this.whatsappErrorMsg = false;
            return;
          }
          else
          {
            if(this.wpBDetailsList[i].providerId == 1 && this.wpBDetailsList[i].nickName=='Interakt')
            {
              this.wpBDetails = this.wpBDetailsList[i];           
              this.iswpBReset = this.wpBDetails.userId != null;
              this.preparewpBForm();
              this.whatsappErrorMsg = false;
            }
          }
        }
      }
      else
      {
        this.whatsappErrorMsg = data.error_msgs;
      }
    });
  }


  getEmailDetails() {
    this.configurationService.getEmailDetails({},this.emailConfUrl).subscribe(data => {
      if (data.status) {
        this.emailDetailsList = data.data;        
        for(let i=0; i<this.emailDetailsList.length; i++)
        {
          if(this.emailDetailsList[i].id > 0)
          {
            this.emailDetails = this.emailDetailsList[i];           
            this.isEmailReset = this.emailDetails.userId != null;
            this.prepareEmailForm();
            this.emailErrorMsg = false;
            return;
          }
          else
          {
            if(this.emailDetailsList[i].providerId == 2 && this.emailDetailsList[i].nickName=='SendGrid')
            {
              this.emailDetails = this.emailDetailsList[i];           
              this.isEmailReset = this.emailDetails.userId != null;
              this.prepareEmailForm();
              this.emailErrorMsg = false;
            }
          }
        }
      }
      else
      {
        this.emailErrorMsg = data.error_msgs;
      }
    });
  }

  IoschangeListeners($event): void {
    this.IosNotificationImage($event.target);
  }
  IosNotificationImage(inputValue: any): void {
    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      this.iosNotiForm.controls.notificationImage.setValue(myReader.result);
    }
    myReader.readAsDataURL(file);
  }
  IosNotification($event): void {
    this.IosNotificationIcon($event.target);
  }
  IosNotificationIcon(inputValue: any): void {
    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();
    myReader.onloadend = (e) => {
      this.iosNotiForm.controls.notificationIcon.setValue(myReader.result);
    }
    myReader.readAsDataURL(file);
  }

  get af() { return this.androidForm.controls; }
  get if() { return this.iosForm.controls; }
  get wf() { return this.windowsForm.controls; }
  get wef() { return this.webForm.controls; }
  get sf() { return this.smsForm.controls; }
  get wpf() { return this.wpBForm.controls; }
  get ef() { return this.emailForm.controls; }



  saveAndroidApp() {
    if (this.androidForm.invalid) {
      this.toastr.error(this.global.MessageRequired); this.global.validateAllFormFields(this.androidForm);
      return;
    }

    if (!this.androidForm.controls.tested.value) {
      this.toastr.error('Please send test push notification first to proceed further');
      return;
    }

    let postForm: any = this.androidForm.value;

    if (this.androidApp.id != undefined) {
      postForm.id = this.androidApp.id;
    }


    this.configurationService.upsertConfiguration(postForm,this.androidConfUrl).subscribe(
      data => {
        this.toastr.success('Android App Details Saved');
        this.getAndroidApp();
      });
  }

  saveIosApp() {

    if (this.iosForm.invalid) {
      this.toastr.error(this.global.MessageRequired); this.global.validateAllFormFields(this.iosForm);
      return;
    }

    if (!this.iosForm.controls.tested.value) {
      this.toastr.error('Please send test push notification first to proceed further');
      return;
    }

    let postForm: any = this.iosForm.value;

    if (this.iosApp.id != undefined) {
      postForm.id = this.iosApp.id;
    }

    this.configurationService.upsertConfiguration(postForm,this.iosConfUrl).subscribe(
      data => {
        this.toastr.success('Ios App Details Saved');
        this.getIosApp();
      });
  }
  WebchangeListeners($event): void {
    this.WebNotificationImage($event.target);
  }
  WebNotificationImage(inputValue: any): void {
    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      this.webNotiForm.controls.notificationImage.setValue(myReader.result);
    }
    myReader.readAsDataURL(file);
  }
  WebNotification($event): void {
    this.webNotificationIcon($event.target);
  }
  webNotificationIcon(inputValue: any): void {
    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();
    myReader.onloadend = (e) => {
      this.webNotiForm.controls.notificationIcon.setValue(myReader.result);
    }
    myReader.readAsDataURL(file);
  }
  saveWindowsApp() {

    if (this.windowsForm.invalid) {
      this.toastr.error(this.global.MessageRequired); this.global.validateAllFormFields(this.windowsForm);
      return;
    }

    let postForm: any = this.windowsForm.value;

    if (this.windowsApp.id != undefined) {
      postForm.id = this.windowsApp.id;
    }

    this.configurationService.upsertWindowsApp(postForm).subscribe(
      data => {
        this.toastr.success('Windows App Details Saved');
        this.getWindowsApp();
      });
  }
  saveWebApp() {

    if (this.webForm.invalid) {
      this.toastr.error(this.global.MessageRequired); this.global.validateAllFormFields(this.webForm);
      return;
    }

    if (!this.webForm.controls.tested.value) {
      this.toastr.error('Please send test push notification first to proceed further');
      return;
    }

    let postForm: any = this.webForm.value;

    if (this.webApp.id != undefined) {
      postForm.id = this.webApp.id;
    }

    this.configurationService.upsertConfiguration(postForm,this.webConfUrl).subscribe(
      data => {
        this.toastr.success('Web Details Saved');
        this.getWebApp();
      });
  }

  saveSMSDetails() {

    if (this.smsForm.controls.apiType.value == 'Select Type') {
      this.toastr.error('Please select api type');
      return;
    }

    if (this.smsForm.controls.authType.value == 'Select Authentication') {
      this.toastr.error('Please select authentication');
      return;
    }

    if (this.smsForm.invalid) {
      this.toastr.error(this.global.MessageRequired); this.global.validateAllFormFields(this.smsForm);
      return;
    }



    if (!this.smsForm.controls.tested.value) {
      this.toastr.error('Please send test sms first to proceed further');
      return;
    }

    const arraySmsPushConfigHeadersDTO = <FormArray>this.sf.smsPushConfigHeadersDTO;
    if(arraySmsPushConfigHeadersDTO.length == 0)
    {
      let newGroup = this.formBuilder.group({
        id: [0],
        smsPushConfigurationId: [this.smsDetails.id],
        keyName: ['Content-Type', Validators.compose([Validators.required, noWhitespaceValidator])],
        keyValue: ['application/json', Validators.compose([Validators.required, noWhitespaceValidator])],
      });
      
      arraySmsPushConfigHeadersDTO.push(newGroup);
    }

    

    let postForm: any = this.smsForm.value;

    if (this.smsDetails.id != undefined) {
      postForm.id = this.smsDetails.id;
    }

    this.configurationService.upsertConfiguration(postForm, this.smsConfUrl).subscribe(data => {
      this.toastr.success('SMS Details Saved');
      this.getSMSDetails();
    });
  }

  savewpBDetails() {    
    if (this.wpBForm.invalid) {
      this.toastr.error(this.global.MessageRequired); this.global.validateAllFormFields(this.wpBForm);
      return;
    }
    if (!this.wpBForm.controls.tested.value) {
    this.toastr.error('Please send test sms first to proceed further');
    return;
    }

    let postForm: any = this.wpBForm.value;
    delete postForm.tested;
    this.configurationService.upsertConfiguration(postForm,this.whatsappConfUrl).subscribe(data => {
      if(data.status){
      this.toastr.success('Whatsapp Business Details Saved');
      this.showSyncButton = false;
      this.getwpBDetails();
      }
      else{
        this.toastr.error(data.error_msgs);
      }
    });
  }

  saveEmailDetails() {

    if (this.emailForm.invalid) {
      this.toastr.error(this.global.MessageRequired); this.global.validateAllFormFields(this.emailForm);
      return;
    }

    if (!this.emailForm.controls.tested.value) {
      this.toastr.error('Please send test email first to proceed further');
      return;
    }

    let postForm: any = this.emailForm.value;

    if (this.emailDetails.id != undefined) {
      postForm.id = this.emailDetails.id;
    }

    this.configurationService.upsertConfiguration(postForm,this.emailConfUrl).subscribe(data => {
      this.toastr.success('Email Details Saved');
      this.getEmailDetails();
    });
  }

  previewInAppMobile() {
    if (this.inAppMobileForm.invalid) {
      this.toastr.error(this.global.MessageRequired); this.global.validateAllFormFields(this.inAppMobileForm);
      return;
    }

    this.InAppMobileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.global.WebUrl + 'mobile-view?audienceId=' + this.inAppMobileForm.controls.audienceID.value + '&api-key=' + this.inAppMobileForm.controls.apiKey.value);

    this.modalRef.hide();
    this.openModal(this.inAppMobileIframe);
  }
  previewInAppWeb() {
    if (this.inAppWebForm.invalid) {
      this.toastr.error(this.global.MessageRequired); this.global.validateAllFormFields(this.inAppWebForm);
      return;
    }

    this.InAppWebUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.global.WebUrl + 'web-view?audienceId=' + this.inAppWebForm.controls.audienceID.value + '&api-key=' + this.inAppWebForm.controls.apiKey.value);

    this.modalRef.hide();
    this.openModal(this.inAppWebIframe);
  }

  saveAndroidNotiForm() {
    this.testApiCall(this.androidForm, this.androidNotiForm, this.configurationService.sendTestMessage.bind(this.configurationService),this.androidConfUrl, 'Notification Send');
  }

  saveIosNotiForm() {
    this.testApiCall(this.iosForm, this.iosNotiForm, this.configurationService.sendTestMessage.bind(this.configurationService),this.androidConfUrl, 'Notification Send');
  }


  saveWebNotiForm() {

    this.testApiCall(this.webForm, this.webNotiForm, this.configurationService.sendTestMessage.bind(this.configurationService),this.androidConfUrl, 'Notification Send');
  }

  saveSmsNotiForm() {
    this.testApiCall(this.smsForm, this.smsNotiForm, this.configurationService.sendTestMessage.bind(this.configurationService),this.smsConfUrl, 'SMS Send');
  }



  savewpBNotiForm() {
    this.testApiCall(this.wpBForm, this.wpBNotiForm, this.configurationService.sendTestMessage.bind(this.configurationService),this.whatsappConfUrl, 'Whatsapp Send');
  }


  saveEmailNotiForm() {
    this.testApiCall(this.emailForm, this.emailNotiForm, this.configurationService.sendTestMessage.bind(this.configurationService),this.emailConfUrl, 'Email Send');
  }


  private testApiCall(baseForm: FormGroup, currentForm: FormGroup, apiMethod: any, url,successMessage: string) {
   
    


    if (currentForm.invalid) {
      this.toastr.error(this.global.MessageRequired); 
      this.global.validateAllFormFields(currentForm);
      return;
    }
    var baseFormValues: any = baseForm.value;
    var postForm: any = currentForm.value;
    Object.assign(postForm, baseFormValues);

    this.spinner.show();

    if(successMessage == 'Whatsapp Send'){
      let wpPostjson = {
        "providerId":baseForm.controls.providerId.value,
        "providerName":baseForm.controls.providerName.value,
        "authorization":baseForm.controls.authorization.value,
        "messageBody":{
          "templateName":currentForm.controls.templateName.value,
          "languageCode":currentForm.controls.languageCode.value,
          "mediaUrl":currentForm.controls.mediaUrl.value,
          "messageBody":currentForm.controls.message.value,
          "countryCode":currentForm.controls.countryCode.value,
          "phoneNumber":currentForm.controls.phone.value,
          "buttonText":"",
          "mediaType":currentForm.controls.mediaType.value,
        }
      }

      if(this.wpBDetails && this.wpBDetails.providerName == 'Cronberry WABA'){
        wpPostjson.messageBody.buttonText = currentForm.controls.buttonText.value
      } else{
        delete wpPostjson.messageBody.buttonText
      }

      apiMethod(wpPostjson,url).subscribe(data => {
        this.spinner.hide();
        if (data.data) {
          baseForm.controls.tested.setValue(true);
          this.toastr.success(successMessage);        
          this.modalRef.hide();
          this.prepareNotiForms();
        }
        else {
          this.toastr.error(data.error_msgs, 'Error');        
        }
      });

      return
    }
    
    apiMethod(postForm,url).subscribe(data => {
      this.spinner.hide();
      if (data.data) {
        baseForm.controls.tested.setValue(true);
        this.toastr.success(successMessage);        
        this.modalRef.hide();
        this.prepareNotiForms();
      }
      else {
        this.toastr.error(data.error_msgs, 'Error');        
      }
    });
  }

  copyInputMessage(inputElement) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    this.toastr.success('Copied to clipboard');
  }

  AndroidchangeListeners($event): void {
    this.AndroidNotificationImage($event.target);
  }
  AndroidNotificationImage(inputValue: any): void {
    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      this.androidNotiForm.controls.notificationImage.setValue(myReader.result);
    }
    myReader.readAsDataURL(file);
  }
  AndroidNotification($event): void {
    this.AndroidNotificationIcon($event.target);
  }
  AndroidNotificationIcon(inputValue: any): void {
    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();
    myReader.onloadend = (e) => {
      this.androidNotiForm.controls.notificationIcon.setValue(myReader.result);
    }
    myReader.readAsDataURL(file);
  }

  updateSMSProvide(id) {
    
    this.smsDetails = {};
    if (id == "") {
      this.smsDetails = { id: 0, providerId: id };
    }
    this.smsDetailsList.forEach(element => {
      if (id == element.providerId) {
        if (element.isDefault == 0)
          this.smsDefaultDetails = element;
          
        if (element.isDefault == 1)
          this.smsDetails = element;
          
      }
    });
    if (this.smsDetails.providerId == undefined) {
      this.smsDetails = this.smsDefaultDetails;
    };
    setTimeout(() => {
      this.updateSMSForm(this.smsDetails);
    }, 200);
  }


  updatewpBProvide(id) {
    
    

    this.wpBDetails = {};
    if (id == "") {
      this.wpBDetails = { id: 0, providerId: id };
    }
    
    this.wpBDetailsList.forEach(element => {
      if (id == element.providerId) {
        
        if (element.isDefault == 0)
          this.wpBDefaultDetails = element;
          
        if (element.isDefault == 1)
          this.wpBDetails = element;
          
      }
    });
    if (this.wpBDetails.providerId == undefined) {
      this.wpBDetails = this.wpBDefaultDetails;
      
    };
    setTimeout(() => {
      this.updatewpBForm(this.wpBDetails);
    }, 200);
  }

  updateEmailProvide(id) {
    this.emailDetails = {};
    if (id == "") {
      this.emailDetails = { id: 0, providerId: id };
    }
    this.emailDetailsList.forEach(element => {
      if (id == element.providerId) {
        if (element.isDefault == 0)
          this.emailDefaultDetails = element;
        if (element.isDefault == 1)
          this.emailDetails = element;
      }
    });
    if (this.emailDetails.providerId == undefined) {
      this.emailDetails = this.emailDefaultDetails;
    };
    setTimeout(() => {
      this.updateEmailForm(this.emailDetails);
    }, 200);
  }

  updateSMSForm(data) {    
    this.smsDetails = data;
    if (this.smsDetails.smsPushConfigHeadersDTO) {
      if (this.smsDetails.smsPushConfigHeadersDTO.length) {
        const headerFormLength = this.smsDetails.smsPushConfigHeadersDTO.length + 20;
        for (let i = 0; i <= headerFormLength; i++) {
          this.removeSMSHeader(0);
        }
      }
      else {
        this.smsDetails.apiType = 'Select Type';
        this.smsDetails.authType = 'Select Authentication'
      }
    }
    else {
      this.smsDetails.smsPushConfigHeadersDTO = undefined;
      this.smsDetails.apiType = 'Select Type';
      this.smsDetails.authType = 'Select Authentication'
    }
    if (this.smsDetails.smsPushConfigBodyDTO) {
      if (this.smsDetails.smsPushConfigBodyDTO.length) {
        const bodyFormLength = this.smsDetails.smsPushConfigBodyDTO.length + 1;
        for (let j = 0; j < bodyFormLength; j++) {
          this.removeSMSBody(0);
        }
      }
      else {
        this.smsDetails.apiType = 'Select Type';
        this.smsDetails.authType = 'Select Authentication'
      }
    }
    else {
      this.smsDetails.smsPushConfigBodyDTO = undefined;
      this.smsDetails.apiType = 'Select Type';
      this.smsDetails.authType = 'Select Authentication'
    }
    if (this.smsDetails.smsPushConfigHeadersDTO == undefined || this.smsDetails.smsPushConfigHeadersDTO.length == 0) {
      this.smsDetails.apiType = 'Select Type';
      this.smsDetails.authType = 'Select Authentication'
    }

    if (this.smsDetails.smsPushConfigBodyDTO == undefined ||
      this.smsDetails.smsPushConfigBodyDTO.length == 0) {
    }

      this.smsForm.patchValue({
        id: data.id,
        apiType: data.apiType,
        apiEndpoint: data.apiEndpoint,
        nickName: data.nickName,
        authType: data.authType,
        isDefault: data.isDefault,
        raw: data.raw,
        apiUser: data.apiUser,
        apiPassword: data.apiPassword,
        tested: false
      });

    this.onChangeAuthType(data.authType);



    const arraySmsPushConfigHeadersDTO = <FormArray>this.sf.smsPushConfigHeadersDTO;
    
    if (this.smsDetails.smsPushConfigHeadersDTO == undefined || this.smsDetails.smsPushConfigHeadersDTO.length == 0) {
      this.smsDetails.smsPushConfigHeadersDTO = [];
      arraySmsPushConfigHeadersDTO.controls = [];
    }

    const arraySmsPushConfigBodyDTO = <FormArray>this.sf.smsPushConfigBodyDTO;
    if (this.smsDetails.smsPushConfigBodyDTO == undefined || this.smsDetails.smsPushConfigBodyDTO.length == 0) {
      this.smsDetails.smsPushConfigBodyDTO = [];
      arraySmsPushConfigBodyDTO.controls = [];
    }


    this.smsDetails.smsPushConfigHeadersDTO.forEach(item => {
      let newGroup = this.formBuilder.group({
        id: [item.id],
        smsPushConfigurationId: [this.smsDetails.id],
        keyName: [item.keyName, Validators.compose([Validators.required, noWhitespaceValidator])],
        keyValue: [item.keyValue, Validators.compose([Validators.required, noWhitespaceValidator])]
      });
      arraySmsPushConfigHeadersDTO.push(newGroup);
    });




    this.smsDetails.smsPushConfigBodyDTO.forEach(item => {

      let newGroup = this.formBuilder.group({
        id: [item.id],
        smsPushConfigurationId: [this.smsDetails.id],
        keyName: [item.keyName, Validators.compose([Validators.required, noWhitespaceValidator])],
        keyValue: [item.keyValue, Validators.compose([Validators.required, noWhitespaceValidator])],
      });
      arraySmsPushConfigBodyDTO.push(newGroup);
    });


  }




  updatewpBForm(data) {    
    this.wpBDetails = data;
    this.wpBDetails.uiFields = [];
    this.wpBDetails.uiAuthorization = [];
    this.wpBDetails.uiFields = this.wpBDetails.fields ? this.wpBDetails.fields.split(','):[];
    this.wpBDetails.uiAuthorization = this.wpBDetails.authorization ? this.wpBDetails.authorization.split(','):[];

      this.wpBForm.patchValue({
        providerId: data.providerId,
        providerName:data.providerName,
        authorization:this.wpBDetails.uiAuthorization.length > 0 ? this.wpBDetails.uiAuthorization[0] : '',
        tested: false
      });

      
      
        if(this.wpBDetails.providerName == 'Cronberry WABA'){
          if(this.wpBForm.controls.authorization.value){
            this.showSyncButton = false;
          } else{
            this.showSyncButton = true;
          }
        } else{
          this.showSyncButton = false
        }
      
  

  }



  updateEmailForm(data) {
    this.emailForm.patchValue({
      id: data.id,
      providerId: data.providerId,
      emailServerIp: data.emailServerIp,
      emailServerPort: data.emailServerPort,
      emailUser: data.emailUser,
      nickName: data.nickName,
      isDefault: data.isDefault,
      emailPassword: data.emailPassword,
      emailFromAddress: data.emailFromAddress,
      emailFromAlias: data.emailFromAlias,
      callBackUl: data.callBackUl,
      unsubscribePage: data.unsubscribePage,
      tested: false
    });
  };


  getWhatsApp() {
    this.configurationService.getConfiguration({},this.whatsappConfUrl).subscribe(data => {
      if (data.status) {
        var res = data.data;
        this.whatsAppMsg = {
          smsText: res.smsText != undefined ? res.smsText : '',
          deepLink: res.deepLink != undefined ? res.deepLink : '',
          imagePath: res.imagePath != undefined ? res.imagePath : '',
          tested: false
        }
        this.whatsappInstanceId = res.instanceId;
        if (res.confStatus == 'inactive') {
          this.whatsAppPairing();
        } else {
          this.whatsAppPairingStatus = 2;
        }
        this.isWhatsAppReset = res.userId != null;
        this.whatsappErrorMsg = false;
      } else {
        this.whatsAppPairingStatus = 0;
        this.whatsappErrorMsg = data.error_msgs;
      }
    })
  }

  sendWhatsAppMsg(type?) {
    this.whatsAppMsgSubmited = true;
    this.whatsAppMsg.smsText = this.whatsAppMsg.smsText.trim();
    if (this.whatsAppMsg.smsText == '' && type == undefined) {
      this.toastr.error(this.global.MessageRequired);
      return
    };
    if (!this.whatsAppMsg.tested && type == undefined) {
      this.toastr.error('Please send test Whatsapp Message first to proceed further');
      return;
    }
    if (type != undefined) {
      this.whatsAppMsg.confStatus = 'inactive'
    }
    this.whatsAppMsg.smsText = this.whatsAppMsg.smsText.replace(/^\s+\n|\s+$/g, '');
    this.whatsAppMsg.smsText = this.whatsAppMsg.smsText == '' && type != undefined ? ' ' : this.whatsAppMsg.smsText;
    this.configurationService.upsertWpPushConfiguration(this.whatsAppMsg).subscribe(data => {
      this.whatsAppMsgSubmited = false;
      if (data.status) {
        this.whatsAppMsg.tested = false;
        if (type == undefined) {
          this.toastr.success('WhatsApp message saved')
        } else {
          this.toastr.success('Successfully unpair');
          if (this.webSocket) {
            this.webSocket.close();
          }
          this.whatsAppPairing();
        }
      } else {
        this.toastr.error(data.error_msgs)
      }
    })
  }

  whatsAppPairing() {
    this.whatsAppUnderProcess = 0;
    this.whatsAppPairingStatus = 1;
    this.webSocket = io('https://' + this.whatsappInstanceId + '.cronberry.com', { auth: { "p": this.authenticationService.getProjectId(), "u": this.authenticationService.getUserId() } });
    this.webSocket.emit('join');
    var qrCount = 0;
    this.webSocket.on('qr', (msg) => {
      qrCount++
      this.whatsAppQrCode = msg;
      if (qrCount == 6) {
        this.whatsAppQrCode = '1@oSdBGWlypzawqagEXIGj6ZuTad,wDTi5sSbBlQ9VcSJeGDCj,xOFrWKDlNs8246ZyCl6H3w'
        this.whatsAppUnderProcess = 2;
        this.webSocket.close();
      }
    });
    this.webSocket.on('success', () => {
      this.whatsAppPairingStatus = 2;
      this.webSocket.close();
      if (this.whatsAppMsg == undefined) {
        this.whatsAppMsg = {
          smsText: '',
          deepLink: '',
          imagePath: '',
          tested: false
        }
      }
    });
  }

  connectWhatsApp() {
    this.whatsAppUnderProcess = 1;
    this.configurationService.wpScanConfiguration({}).subscribe(data => {
      this.whatsAppUnderProcess = 0;
      if (data.status) {
        this.whatsappInstanceId = data.data.instanceId;
        this.whatsAppPairing();
      } else {
        this.toastr.error(data.error_msgs);
      }
    })
  }

  openGalleryModal(type,imagetype?) {
    const initialState = {
      galleryType: type,
      modalRefGallery: this.modalRefGallery
    }
    this.modalRefGallery = this.modalService.show(GalleryPopupComponent, {
      initialState,
      backdrop: "static",
      class: "gallery-popup"
    });
    this.modalRefGallery.content.modalRefGallery = this.modalRefGallery;
    this.modalRefGallery.content.selectMediaEvent.subscribe(item => {
      if (item.fileType == AppConstants.FILETYPE_IMAGE || item.fileType == AppConstants.FILETYPE_GALLERY) {
        if(imagetype == 'ndroidNotification'){
          this.androidNotiForm.controls.notificationImage.setValue(item.originalFile);
          return
        }
        if(imagetype == 'ndroidNotificationIcon'){
          this.androidNotiForm.controls.notificationIcon.setValue(item.originalFile);
          return
        }
        if(imagetype == 'iosNotificationImage'){
          this.iosNotiForm.controls.notificationImage.setValue(item.originalFile);
          return
        }
        if(imagetype == 'iosNotificationIcon'){
          this.iosNotiForm.controls.notificationIcon.setValue(item.originalFile);
          return
        }
        if(imagetype == 'webNotificationImage'){
          this.webNotiForm.controls.notificationImage.setValue(item.originalFile);
          return
        }
        if(imagetype == 'webNotificationIcon'){
          this.webNotiForm.controls.notificationIcon.setValue(item.originalFile);
          return
        }

        this.whatsAppMsg.imagePath = item.originalFile;
      }
    });
  }

  addTextWhatsAppMsg(text) {
    if (this.whatsAppMsg.smsText.length < 240 && !(this.userRole != 1 && !this.isWhatsAppReset))
      this.whatsAppMsg.smsText = this.whatsAppMsg.smsText + ' [' + text + ']';
  }

  resetForm(formName: FormGroup, type: string) {
    formName.reset();
    if (type == 'android') {
      this.isAndroidAppReset = true;
      this.androidApp = {};
      this.androidApp.fcmSenderid = this.fcmURL;
      this.prepareAndroidForm('reset');
    } else if (type == 'ios') {
      this.isIOSAppReset = true;
      this.iosApp = {};
      this.iosApp.fcmSenderid = this.fcmURL;
      this.prepareIosForm('reset');
    } else if (type == 'web') {
      this.isWebAppReset = true;
      this.webApp = {};
      this.webApp.fcmSenderid = this.fcmURL;
      this.prepareWebForm('reset');
    } else if (type == 'sms') {
      this.isSMSReset = true;
      this.smsDetails = {};
      this.prepareSMSForm('reset');
    } else if (type == 'email') {
      this.isEmailReset = true;
      this.emailDetails = this.emailDefaultDetails;
      this.prepareEmailForm('reset');
    } else if (type == 'whatsAppB') {
      this.iswpBReset = true;
      this.wpBDetails = {};
      this.preparewpBForm('reset');
    } 
  }

  resetWhatsapp() {
    this.whatsAppMsg = {
      smsText: '',
      deepLink: '',
      imagePath: ''
    }
    this.whatsAppPairingStatus = 0;
    this.isWhatsAppReset = true;
  }
  

  sendWhatappTestMsg(model) {
    this.whatsAppMsgSubmited = true;
    if (this.whatsAppMsg.smsText == '') {
      this.toastr.error(this.global.MessageRequired);
      return
    };
    this.openModal(model);
  }

  sendWhatappMsg() {
    if (this.whatsAppMsg.phone == '' || this.whatsAppMsg.phone.match(/^[+0]{0,2}(91){0,1}(-){0,1}(\s)?[56789]{1}\d{9}$/) == null) {
      this.toastr.error(this.global.MessageRequired);
      return
    };
    this.configurationService.sendTestWhatsApp(this.whatsAppMsg).subscribe(data => {
      this.modalRef.hide();
      this.whatsAppMsg.phone = '';
      if (data.status) {
        this.whatsAppMsg.tested = true;
        this.toastr.success(data.data);
      } else {
        this.toastr.error(data.error_msgs);
      }
    })
  }

  resetAndroidPush() {
    this.configurationService.resetPushConfiguration({},this.androidConfUrl).subscribe(data => {
      if (data.status) {
        this.androidApp = data.data;
      } else {
        this.androidApp.fcmSenderid = this.fcmURL;
      }
      this.prepareAndroidForm();
      this.isAndroidAppReset = false;
    });
  }
  resetIosApp() {
    this.configurationService.resetPushConfiguration({},this.iosConfUrl).subscribe(data => {
      if (data.status) {
        this.iosApp = data.data;
      } else {
        this.iosApp.fcmSenderid = this.fcmURL;
      }
      this.prepareIosForm();
      this.isIOSAppReset = false;
    });
  }
  resetWebPush() {
    this.configurationService.resetPushConfiguration({},this.webConfUrl).subscribe(data => {
      if (data.status) {
        this.webApp = data.data;
        this.prepareWebForm();
        this.isWebAppReset = false;
      }
    });
  }
  resetSMS() {
    this.configurationService.resetPushConfiguration({},this.smsConfUrl).subscribe(data => {
      if (data.status) {
        this.smsDetailsList = data.data;
        this.smsDetailsList.forEach(element => {
          if (element.id > 0) {
            this.updateSMSProvide(element.providerId);
            this.prepareSMSForm();
            this.isSMSReset = false;
          }
        });
      }
    });
  }

  resetwpB() {
    this.configurationService.resetPushConfiguration({},this.whatsappConfUrl).subscribe(data => {
      if (data.status) {
        this.wpBDetailsList = data.data;
        this.wpBDetailsList.forEach(element => {
          if (element.id > 0) {
            this.updatewpBProvide(element.providerId);
            this.preparewpBForm();
            this.iswpBReset = false;
          }
        });
      }
    });
  }

  resetEmail() {
    this.configurationService.resetPushConfiguration({},this.emailConfUrl).subscribe(data => {
      if (data.status) {
        this.emailDetailsList = data.data;
        this.emailDetailsList.forEach(element => {
          if (element.id > 0) {
            this.updateEmailProvide(element.providerId);
            this.prepareEmailForm();
            this.isEmailReset = false;
          }
        });
      }
    });
  }

}


