<main>
    <section class="inner-page">
        <div class="inner-page-body border-bottom-0">
            <div class="dashboard-top" *ngIf="projectDetails != undefined && projectDetails.pricingPlans != undefined">
                <div class="row">
                    <div class="col-md-9">
                        <h2>Hi <span class="text-capitalize">{{userName}},</span> <br>
                        What would you like to do today?</h2>
                        <div class="buttons">
                            <a [routerLink]="['/admin/create-campaign-segment/push']" class="btn btn-outline-gray">Push <span class="d-none d-md-inline">Notification</span></a>
                            <a [routerLink]="['/admin/create-campaign-segment/sms']" class="btn btn-outline-gray">SMS <span class="d-none d-md-inline">Campaign</span></a>
                            <a [routerLink]="['/admin/create-campaign-segment/email']" class="btn btn-outline-gray">Email <span class="d-none d-md-inline">Campaign</span></a>
                            <a [routerLink]="['/admin/create-campaign-segment/whatsapp']" class="btn btn-outline-gray">Whatsapp <span class="d-none d-md-inline">Campaign</span></a>
                            <a [routerLink]="['/admin/create-campaign-segment/inapp']" class="btn btn-outline-gray">In-App <span class="d-none d-md-inline">Notification</span></a>
                            <a [routerLink]="['/admin/create-campaign-segment/journey']" class="btn btn-outline-gray">Journey <span class="d-none d-md-inline">Campaign</span></a>
                        </div>
                        <!-- <div class="buttons" *ngIf=isExpired>
                            <button (click)="toastr.error('Your subscription has been expired. please email or contact us at sales@cronberry.com', 'Error');" class="btn btn-outline-gray disabled">Push <span class="d-none d-md-inline">Notification</span></button>
                            <button (click)="toastr.error('Your subscription has been expired. please email or contact us at sales@cronberry.com', 'Error');" class="btn btn-outline-gray disabled">SMS <span class="d-none d-md-inline">Campaign</span></button>
                            <button (click)="toastr.error('Your subscription has been expired. please email or contact us at sales@cronberry.com', 'Error');" class="btn btn-outline-gray disabled">Email <span class="d-none d-md-inline">Campaign</span></button>
                            <button (click)="toastr.error('Your subscription has been expired. please email or contact us at sales@cronberry.com', 'Error');" class="btn btn-outline-gray disabled">In-App <span class="d-none d-md-inline">Notification</span></button>
                        </div> -->
                    </div>
                    <div class="col-md-3 d-none d-md-block">
                        <div *ngIf="userRoleId != '3'" class="mau-info justify-content-end d-flex">
                            <div *ngIf="isShowMAUChart && projectDetails.mauLimit != undefined && projectDetails.mauLimit != 0" class="value align-self-center">
                                <span> Used MAU {{projectDetails.mauUsed}}</span>/Assigned MAU {{projectDetails.mauLimit}}
                            </div>
                            <div class="circle">
                                <div class="image">
                                    <apx-chart class="width_100" *ngIf="isShowMAUChart && projectDetails.mauLimit != undefined && projectDetails.mauLimit != 0"
                                        [series]="pieChartOptions.seriesNon"
                                        [chart]="pieChartOptions.chart"
                                        [labels]="pieChartOptions.labels"
                                        [dataLabels]="pieChartOptions.dataLabels"
                                        [legend]="pieChartOptions.legend"
                                        [colors]="pieChartOptions.colors"
                                        [stroke]="pieChartOptions.stroke"
                                    ></apx-chart>
                                </div>
                                <span>Expires on {{projectDetails.pricingPlans.renewDate | date}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid Dashboard-body">

                


                <div class="report_title_db" *ngIf="!isNoDataLeadChart">
                    <div class="row">
                        <div class="col-5 align-self-center">
                            <h4>
                                Analysis Graph
                            </h4>
                        </div>
                        <div class="col-7 text-right">
                            <input type="text" class="form-control date-icon modal-space" readonly autocomplete="off"
                            ngxDaterangepickerMd
                            [locale]="{format: 'DD/MM/YYYY'}"
                            [(ngModel)]="leadReportDaysInput"
                            [alwaysShowCalendars]="true"
                            [ranges]="ranges"
                            [autoApply]="false"
                            [opens]="'left'"
                            [maxDate]=""
                            placeholder="Select Date Range"
                            (datesUpdated)="fetchLeadReports()">
                        </div>
                    </div>
                </div>
                <div class="reportsOnDb" *ngIf="isNoDataLeadChart == false">
                    <div class="form-row">
                        <ng-container *ngFor="let item of leadReportChartOptions; let i = index">
                            <div *ngIf="item" [class]="item.data.dataCount > 15 ? 'col-md-12 position_relative' : 'col-md-6'">
                            <div [class]="item.data.part == undefined || item.data.part == 1 ? 'part chart-box graphOuterBox position_relative':'otherPart chart-box graphOuterBox position_relative'" [ngClass]="{'no-chart-icon' : item.data.reportType == 1 || item.data.dataCount == 0}"  >
                                <div class="spinner absolute_position" *ngIf="item.loader">
                                    <div class="loader">
                                        <div class="la-ball-clip-rotate la-2x"><div></div></div>
                                        <p *ngIf="timer > 20 && isLogin">&nbsp;<a [routerLink]="['/admin/dashboard']" (click)="httploading = false">Lost somewhere ?.. Go to Dashboard again !!!</a></p>
                                    </div>
                                </div>
                                <div class="chart-delete" (click)="showDeleteModal(item)"><img tooltip="Delete" tooltipPlacement="top" src="assets/images/delete.png" alt=""></div>
                                <div class="chart-edit" *ngIf="!item.data.missedAttribute" (click)="fetchReport(item.data)"><img tooltip="Edit" tooltipPlacement="top" src="assets/images/pencil.svg" alt=""></div>
                                <ng-container *ngIf="item.data.reportType == 0; else quantitativeReport">
                                    <div *ngIf="item.data.dataCount > 0">
                                        <div class="chart-edit chart-reset" (click)="refreshGraph(item)"><img src="assets/images/reset-icon.png" width="15" class="103" [class.reload]="item.loadingData"></div>
                                        <div *ngIf="item.data.dataCount <= 10 && item.data.ycolumnSum == 0" class="leadchart-type" (click)="changeChartType(i, item.data.id)">
                                            <img tooltip="Switch to Bar Chart" tooltipPlacement="top" *ngIf="item.chart.type == 'pie'" src="assets/images/chart-bar.svg" alt="">
                                            <img tooltip="Switch to Pie Chart" tooltipPlacement="top" *ngIf="item.chart.type == 'bar'" src="assets/images/chart-pie-alt.svg" alt="">
                                        </div>
                                        <apx-chart *ngIf="item.chart.type == 'bar'"
                                            [title]="item.title"
                                            [series]="item.series"
                                            [chart]="item.chart"
                                            [xaxis]='item.xaxis'
                                            [yaxis]='item.yaxis'
                                            [dataLabels]="item.dataLabels"
                                            [legend]="item.legend"
                                            [colors]="item.colors"
                                            [plotOptions]="item.plotOptions"
                                            [tooltip]="item.tooltip"
                                        ></apx-chart>
                                        <apx-chart *ngIf="item.chart.type == 'pie'"
                                            [title]="item.title"
                                            [series]="item.seriesNon"
                                            [chart]="item.chart"
                                            [labels]="item.labels"
                                            [legend]="item.legend"
                                            [colors]="item.colors"
                                            [stroke]="item.stroke"
                                            [dataLabels]="item.dataLabels"
                                        ></apx-chart>
                                    </div>
                                    <div class="no-data" *ngIf="!item.data.missedAttribute && item.data.dataCount == 0">
                                        <h3>{{item.data.reportName}}</h3>
                                        No Data Available.
                                    </div>
                                    <div class="no-data" *ngIf="item.data.missedAttribute && item.data.dataCount == 0">
                                        <h3>{{item.data.reportName}}</h3>
                                        Report is based on {{item.data.misedAttrList}}. Attribute is not having visibility for your role. Hence Graph will not render. 
                                    </div>
                                </ng-container>
                                <ng-template #quantitativeReport>
                                    <div class="quantative-box">
                                        <h3>{{item.data.reportName}}</h3>
                                        <div [class]="item.data.per < 0 ? 'value red' : 'value' ">{{item.data.xaxisAttributeName}} {{item.data.curCount}}
                                            <span><img [src]="item.data.per < 0 ? 'assets/images/caret-down.svg' : 'assets/images/caret-up.svg'" alt=""> {{item.data.per}}%</span>
                                        </div>
                                        <div class="info">Compared from: {{item.data.fromDate | date}} - {{item.data.toDate | date}}</div>
                                    </div>
                                </ng-template>
                            </div>
                            </div>
                        </ng-container>
                    </div>
                </div>


                <div class="leads-collected position_relative chart-box">
                    <div class="">
                    <div class="spinner absolute_position" *ngIf="loader == 'leadsCollected'">
                        <div class="loader">
                            <div class="la-ball-clip-rotate la-2x"><div></div></div>
                            <p *ngIf="timer > 20 && isLogin">&nbsp;<a [routerLink]="['/admin/dashboard']" (click)="httploading = false">Lost somewhere ?.. Go to Dashboard again !!!</a></p>
                        </div>
                    </div>
                    <div class="select-box d-flex d-md-block w-100">
                        <input type="text" class="form-control date-icon modal-space" readonly autocomplete="off"
                            ngxDaterangepickerMd
                            [(ngModel)]="leadsDays"
                            [alwaysShowCalendars]="true"
                            [locale]="{format: 'DD/MM/YYYY'}"
                            [ranges]="ranges"
                            [autoApply]="false"
                            [opens]="'right'"
                            [maxDate]=""
                            placeholder="Select Date Range"
                            (datesUpdated)="fetchLeadsStats()">

                        <select class="form-control" [(ngModel)]="leadsSource" [ngModelOptions]="{standalone: true}" (change)="fetchLeadsStats()">
                            <option value="">All</option>
                            <option *ngFor="let item of sourseList" value="{{item.pluginName}}">{{item.pluginName}}</option>
                        </select>

                        <select class="form-control" (change)="catSelected();fetchLeadsStats()" [(ngModel)]="categoryName">
                            <option *ngFor="let cat of dataList" [value]="cat.linkRewrite">{{cat.name}}</option>
                        </select>
                    </div>
                    <div class="leadchart-box" *ngIf="leadCollectedNotAvail == false">
                        <div>
                        <apx-chart *ngIf="isShowleadsStats"
                            [series]="leadsChartOptions.series"
                            [title]="leadsChartOptions.title"
                            [chart]="leadsChartOptions.chart"
                            [dataLabels]="leadsChartOptions.dataLabels"
                            [plotOptions]="leadsChartOptions.plotOptions"
                            [yaxis]="leadsChartOptions.yaxis"
                            [xaxis]="leadsChartOptions.xaxis"
                            [legend]="leadsChartOptions.legend"
                            [colors]="leadsChartOptions.colors"
                            [grid]="leadsChartOptions.grid"
                        ></apx-chart>
                    </div>
                    </div>
                    <div class="no-data" *ngIf="leadCollectedNotAvail">
                        No Data Available.
                    </div>
                </div>
                </div>

                <div class="form-row top">
                    <div class="col-5 align-self-center">
                        <h2>Campaign Stats</h2>
                    </div>
                    <div class="col-7 text-right">
                        <input type="text" class="form-control date-icon modal-space" readonly autocomplete="off"
                            ngxDaterangepickerMd
                            [(ngModel)]="campaignStatsDays"
                            [alwaysShowCalendars]="true"
                            [locale]="{format: 'DD/MM/YYYY'}"
                            [ranges]="ranges"
                            [autoApply]="false"
                            [opens]="'left'"
                            [maxDate]=""
                            placeholder="Select Date Range"
                            (datesUpdated)="fetchCampaignStats('dateUpdate')">
                        <!-- <select class="form-control" [(ngModel)]="campaignStatsDays" [ngModelOptions]="{standalone: true}" (change)="fetchCampaignStats();">
                            <option value="7">Last 7 days</option>
                            <option value="14">Last 14 days</option>
                            <option value="21">Last 21 days</option>
                            <option value="28">Last 28 days</option>
                        </select> -->
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-md-4">
                        <div class="position_relative">
                        <div class="overShadow" (click)="emailisCampaignStatsShow = true; fetchCampaignStats('email','reload')" *ngIf="!emailisCampaignStatsShow">
                            <div class="chart_title_">Email</div>
                            <div class="playButton">
                                <img src="assets/images/segmenteye-black.png">
                            </div>
                        </div>
                        <div class="spinner absolute_position" *ngIf="loader == 'email'">
                            <div class="loader">
                                <div class="la-ball-clip-rotate la-2x"><div></div></div>
                                <p *ngIf="timer > 20 && isLogin">&nbsp;<a [routerLink]="['/admin/dashboard']" (click)="httploading = false">Lost somewhere ?.. Go to Dashboard again !!!</a></p>
                            </div>
                        </div>
                        <ng-container *ngIf="campaignStatsDataEmail">
                        <div class="chart-box" (click)="showChart('email')" *ngIf="!campaignStatsDataEmail.error_msg">
                            
                            <apx-chart *ngIf="isCampaignStatsShow && emailisCampaignStatsShow && campaignStatsDataEmail"
                            [title]="chartOptionsEmail.titleEmail"
                            [series]="chartOptionsEmail.seriesEmail"
                            [chart]="chartOptionsEmail.chart"
                            [stroke]="chartOptionsEmail.stroke"
                            [xaxis]='chartOptionsEmail.xaxis'></apx-chart>
                            
                        </div>

                        <div class="chart-box error_txt_msg" *ngIf="campaignStatsDataEmail.error_msg">
                            {{campaignStatsDataEmail.error_msg}}
                        </div>
                        </ng-container>

                        <ng-container *ngIf="!campaignStatsDataEmail">
                            <div class="chart-box">
                                <div class="no-data">
                                    No Data Available.
                                </div>
                            </div>
                        </ng-container>

                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="position_relative">
                            <div class="overShadow" (click)="smsisCampaignStatsShow = true; fetchCampaignStats('sms','reload')" *ngIf="!smsisCampaignStatsShow">
                                <div class="chart_title_">SMS</div>
                                <div class="playButton">
                                    <img src="assets/images/segmenteye-black.png">
                                </div>
                            </div>
                            <div class="spinner absolute_position" *ngIf="loader == 'sms'">
                                <div class="loader">
                                    <div class="la-ball-clip-rotate la-2x"><div></div></div>
                                    <p *ngIf="timer > 20 && isLogin">&nbsp;<a [routerLink]="['/admin/dashboard']" (click)="httploading = false">Lost somewhere ?.. Go to Dashboard again !!!</a></p>
                                </div>
                            </div>
                            <ng-container *ngIf="campaignStatsDataSms">
                            <div class="chart-box" (click)="showChart('sms')" *ngIf="!campaignStatsDataSms.error_msg">
                                <apx-chart *ngIf="isCampaignStatsShow && smsisCampaignStatsShow && campaignStatsDataSms"
                                [title]="chartOptionsSms.titleSms"
                                [series]="chartOptionsSms.seriesSms"
                                [chart]="chartOptionsSms.chart"
                                [stroke]="chartOptionsSms.stroke"
                                [xaxis]='chartOptionsSms.xaxis'></apx-chart>
                            </div>

                            <div class="chart-box error_txt_msg" *ngIf="campaignStatsDataSms.error_msg">
                                {{campaignStatsDataSms.error_msg}}
                            </div>
                            </ng-container>

                            <ng-container *ngIf="!campaignStatsDataSms">
                                <div class="chart-box">
                                    <div class="no-data">
                                        No Data Available.
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="position_relative">
                        <div class="overShadow" (click)="whatsappisCampaignStatsShow = true; fetchCampaignStats('whatsapp','reload')" *ngIf="!whatsappisCampaignStatsShow">
                            <div class="chart_title_">Whatsapp</div>
                            <div class="playButton">
                                <img src="assets/images/segmenteye-black.png">
                            </div>
                        </div>
                        <div class="spinner absolute_position" *ngIf="loader == 'whatsapp'">
                            <div class="loader">
                                <div class="la-ball-clip-rotate la-2x"><div></div></div>
                                <p *ngIf="timer > 20 && isLogin">&nbsp;<a [routerLink]="['/admin/dashboard']" (click)="httploading = false">Lost somewhere ?.. Go to Dashboard again !!!</a></p>
                            </div>
                        </div>
                        <ng-container *ngIf="campaignStatsDataWhatsapp">
                        <div class="chart-box" (click)="showChart('whatsapp')" *ngIf="!campaignStatsDataWhatsapp.error_msg">
                            <apx-chart *ngIf="isCampaignStatsShow && whatsappisCampaignStatsShow"
                            [title]="chartOptionsWhatsapp.titleWhatsapp"
                            [series]="chartOptionsWhatsapp.seriesWhatsapp"
                            [chart]="chartOptionsWhatsapp.chart"
                            [stroke]="chartOptionsWhatsapp.stroke"
                            [xaxis]='chartOptionsWhatsapp.xaxis'></apx-chart>
                        </div>
                        
                        <div class="chart-box error_txt_msg" *ngIf="campaignStatsDataWhatsapp.error_msg">
                            {{campaignStatsDataWhatsapp.error_msg}}
                        </div>
                        </ng-container>

                        <ng-container *ngIf="!campaignStatsDataWhatsapp">
                            <div class="chart-box">
                                <div class="no-data">
                                    No Data Available.
                                </div>
                            </div>
                        </ng-container>
                        </div>
                    </div>

                     <div class="col-md-4">
                        <div class="position_relative">
                        <div class="overShadow" (click)="androidisCampaignStatsShow = true; fetchCampaignStats('android','reload')" *ngIf="!androidisCampaignStatsShow">
                            <div class="chart_title_">Android Push</div>
                            <div class="playButton">
                                <img src="assets/images/segmenteye-black.png">
                            </div>
                        </div>
                        <div class="spinner absolute_position" *ngIf="loader == 'android'">
                            <div class="loader">
                                <div class="la-ball-clip-rotate la-2x"><div></div></div>
                                <p *ngIf="timer > 20 && isLogin">&nbsp;<a [routerLink]="['/admin/dashboard']" (click)="httploading = false">Lost somewhere ?.. Go to Dashboard again !!!</a></p>
                            </div>
                        </div>

                        <ng-container *ngIf="campaignStatsDataAndroid">
                             <div class="chart-box" (click)="showChart('android')" *ngIf="!campaignStatsDataAndroid.error_msg">
                            <apx-chart *ngIf="isCampaignStatsShow && androidisCampaignStatsShow && campaignStatsDataAndroid"
                            [title]="chartOptionsAndroid.titleAndroid"
                            [series]="chartOptionsAndroid.seriesAndroid"
                            [chart]="chartOptionsAndroid.chart"
                            [stroke]="chartOptionsAndroid.stroke"
                            [xaxis]='chartOptionsAndroid.xaxis'></apx-chart>
                        </div>

                        <div class="chart-box error_txt_msg" *ngIf="campaignStatsDataAndroid.error_msg">
                            {{campaignStatsDataAndroid.error_msg}}
                        </div>
                        </ng-container>

                        <ng-container *ngIf="!campaignStatsDataAndroid">
                            <div class="chart-box">
                                <div class="no-data">
                                    No Data Available.
                                </div>
                            </div>
                        </ng-container>

                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="position_relative">
                        <div class="overShadow" (click)="iosisCampaignStatsShow = true; fetchCampaignStats('ios','reload')" *ngIf="!iosisCampaignStatsShow">
                            <div class="chart_title_">IOS Push</div>
                            <div class="playButton">
                                <img src="assets/images/segmenteye-black.png">
                            </div>
                        </div>
                        <div class="spinner absolute_position" *ngIf="loader == 'ios'">
                            <div class="loader">
                                <div class="la-ball-clip-rotate la-2x"><div></div></div>
                                <p *ngIf="timer > 20 && isLogin">&nbsp;<a [routerLink]="['/admin/dashboard']" (click)="httploading = false">Lost somewhere ?.. Go to Dashboard again !!!</a></p>
                            </div>
                        </div>
                        <ng-container *ngIf="campaignStatsDataIos">
                            <div class="chart-box" (click)="showChart('ios')" *ngIf="!campaignStatsDataIos.error_msg">
                                <apx-chart *ngIf="isCampaignStatsShow && iosisCampaignStatsShow && campaignStatsDataIos"
                                [title]="chartOptionsIos.titleIos"
                                [series]="chartOptionsIos.seriesIos"
                                [chart]="chartOptionsIos.chart"
                                [stroke]="chartOptionsIos.stroke"
                                [xaxis]='chartOptionsIos.xaxis'></apx-chart>
                            </div>
                            
                            <div class="chart-box error_txt_msg" *ngIf="campaignStatsDataIos.error_msg">
                                {{campaignStatsDataIos.error_msg}}
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!campaignStatsDataIos">
                            <div class="chart-box">
                                <div class="no-data">
                                    No Data Available.
                                </div>
                            </div>
                        </ng-container>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="position_relative">
                        <div class="overShadow" (click)="loader = true ; webisCampaignStatsShow = true; fetchCampaignStats('web','reload')" *ngIf="!webisCampaignStatsShow">
                            <div class="chart_title_">Web Push</div>
                            <div class="playButton">
                                <img src="assets/images/segmenteye-black.png">
                            </div>
                        </div>
                        <div class="spinner absolute_position" *ngIf="loader == 'web'">
                            <div class="loader">
                                <div class="la-ball-clip-rotate la-2x"><div></div></div>
                                <p *ngIf="timer > 20 && isLogin">&nbsp;<a [routerLink]="['/admin/dashboard']" (click)="httploading = false">Lost somewhere ?.. Go to Dashboard again !!!</a></p>
                            </div>
                        </div>
                        <ng-container *ngIf="campaignStatsDataWeb">
                            <div class="chart-box" (click)="showChart('web')" *ngIf="!campaignStatsDataWeb.error_msg">
                                <apx-chart *ngIf="isCampaignStatsShow && webisCampaignStatsShow && campaignStatsDataWeb"
                                [title]="chartOptionsWeb.titleWeb"
                                [series]="chartOptionsWeb.seriesWeb"
                                [chart]="chartOptionsWeb.chart"
                                [stroke]="chartOptionsWeb.stroke"
                                [xaxis]='chartOptionsWeb.xaxis'></apx-chart>
                            </div>

                            <div class="chart-box error_txt_msg" *ngIf="campaignStatsDataWeb.error_msg">
                                {{campaignStatsDataWeb.error_msg}}
                            </div>
                        </ng-container>

                        <ng-container *ngIf="!campaignStatsDataWeb">
                            <div class="chart-box">
                                <div class="no-data">
                                    No Data Available.
                                </div>
                            </div>
                        </ng-container>
                        </div>
                    </div> 

                </div>
                
                
                
            </div>
        </div>

    </section>
</main>

<ng-template #addLeadReports>
    <div class="forgot-password-modal addReportModal">
        <div class="row top" >
            <div class="col-md-12">
              <div class="modal-header">
              <h4 class="modal-title pull-left">Update Report</h4>
              <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                  <span aria-hidden="true">&times;</span>
              </button>
              </div>
            </div>
          </div>
        <div class="modal-body">
            <app-create-report-graph [modalRef]="modalRef" [refilEditVal]="refilEditVal" (recordUpdateAdded)="recordUpdateAdded($event)"></app-create-report-graph>
        </div>
    </div>
</ng-template>

<ng-template #campaignChart>
    <div class="modal-header">
        <h4 class="modal-title pull-left text-capitalize">{{campaignChartTitle}} Stats</h4>
        <button type="button" class="close" aria-label="Close" (click)="modalRef.hide()">
            <img src="./assets/images/times-circle2.svg" alt="">
        </button>
    </div>
    <div class="modal-body">
        <div class="form-row stats">
            <div *ngFor="let item of campaignStatsIndividualData" class="col-md-2 col-4">
                <div class="number">{{item.count}}</div>
                <h3>{{item.name}}</h3>
            </div>
        </div>
        <div class="stats-chart-box" *ngIf="campaignIndividualSeries.length > 0">
            <apx-chart
                [series]="chartIndividualOptions.series"
                [chart]="chartIndividualOptions.chart"
                [stroke]="chartIndividualOptions.stroke"
                [xaxis]='chartIndividualOptions.xaxis'></apx-chart>
        </div>
    </div>
</ng-template>

<ng-template #leadReportDeleteConfirm>
    <!-- delete popup -->
    <form>
        <div class="forgot-password-modal">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Delete Lead Report Chart</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body modal-user-management">
                <div class="form-group mb-0">
                    <p class="pt-2">
                        Are you sure, you want to delete this Lead Report Chart?
                    </p>
                    <div class="d-flex justify-content-end mt-5">
                        <div>
                            <button class="btn remove-user-btn mr-4" (click)="modalRef.hide();" type="button">Dismiss</button>
                        </div>
                        <div>
                            <button class="btn save-btn" type="button" (click)="deleteLeadReportChart();">Yes</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</ng-template>