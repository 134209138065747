import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({providedIn: 'root'})

export class SpinnerService {
    
    private spinner = new BehaviorSubject<boolean>(false);
    private spinnerTimer = new BehaviorSubject<number>(0);
    private timerInterval:any;
    returnSpinnerObservable(){
        return this.spinner.asObservable();
    }

    returnSpinnerShowTimeObservable(){
        return this.spinnerTimer.asObservable();
    }

    show(){
        this.spinner.next(true);
        var timer = 0;
        if(this.timerInterval) clearInterval(this.timerInterval);
        this.timerInterval = setInterval(() => {
            timer ++;
            this.spinnerTimer.next(timer);
        }, 1000);
    }

    hide(){
        this.spinner.next(false);
        if(this.timerInterval) clearInterval(this.timerInterval);
        this.spinnerTimer.next(0);
    }

}