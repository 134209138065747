import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { SwUpdate } from '@angular/service-worker';
import { SpinnerService } from '../services/spinner.service';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    private webVersion : any;
    private version: string;
    private updateCheckCount: number = 0;
    constructor(
        private toastr :ToastrService,
        private router: Router,
        private swUpdate: SwUpdate,
        private spinner: SpinnerService,
        private authenticationService: AuthenticationService,
    ) {
        this.version = '3.0.23';
        this.webVersion = this.version.toString().split('.');
    }
    getUserId() : string{
        return this.authenticationService.getUserId();
    }
    projectId() : string{
        return this.authenticationService.getProjectId();
    }
    getAuthtoken():string{
        return this.authenticationService.getAuthToken();
    }

    // getToken() : string{
    //     return this.authenticationService.getProjectToken();
    // }
    
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const authorization:string = "Y3JvbmJlcnJ5QHVzZXJuYW1lOmNyb25iZXJyeUBwYXNzd29yZA==";

        request = request.clone({ headers: request.headers.set('Authorization', 'Basic ' + authorization) });
        if(this.getUserId()){
            request = request.clone({ headers: request.headers.set('userId', this.getUserId()) });
        }
        if(this.projectId()){
            request = request.clone({ headers: request.headers.set('projectId', this.projectId()) });
        };
        if(this.getAuthtoken()){
            request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + this.getAuthtoken()) });
         };
        
        if(request.reportProgress){
            this.spinner.show();
        }
        request = request.clone({ reportProgress: true });
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    if(request.reportProgress){
                        setTimeout(() => {
                            this.spinner.hide();
                        }, 500);
                    }
                    // if(!event.body.status && typeof(event.body.error_msgs) == 'string' && event.body.error_msgs.indexOf('Action Not Allowed') > -1){
                    //    // this.toastr.error(event.body.error_msgs, 'Error');
                    // }
                    if(event.body.webVersion != undefined){
                        var apiVersion = (event.body.webVersion).toString().split('.');
                        if(Number(this.webVersion[0]) < Number(apiVersion[0])){
                            this.router.navigate(['/admin/sign-out']);
                            this.toastr.error('Your session has timed out. Please login again.', 'Error');
                            this.checkUpdate();
                        } else 
                        if(Number(this.webVersion[1]) < Number(apiVersion[1])){
                            this.checkUpdate();
                        } else  
                        if(Number(this.webVersion[2]) < Number(apiVersion[2])){
                            this.checkUpdate();
                        }
                    }
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                console.log('Web webVersion: ' + this.version);
                console.log(error);
                this.spinner.hide();
                // if(error.status == 403){
                //     this.toastr.error('Action Not Allowed', 'Error');
                // }
                if(error.status == 401){
                    this.authenticationService.logout('refresh');
                    this.router.navigate(['/admin/sign-out']);
                    this.toastr.error('Your session has timed out. Please login again.', 'Error');
                }
                return throwError(error);
            })
        );
    }

    checkUpdate(){
        this.updateCheckCount++;
        if(this.updateCheckCount > 1) { return };
        if(window.navigator && navigator.serviceWorker) {
            this.swUpdate.checkForUpdate().then( () => {
                console.log('check For Update');
            });
        } else {
            window.location.reload();
        }
    }
}