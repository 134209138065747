import { DatePipe } from '@angular/common';
import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { FormGroup, Validators, FormBuilder, ValidationErrors } from '@angular/forms';
import { BsModalRef, BsModalService, TabsetComponent } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { AppConstants } from '../../../../constants/app.constants';
import { ApiConstants } from '../../../../constants/api.constants';
import { AnnouncementService } from "../../../../core/services/announcement.service";
import { Global } from '../../../../shared/models/global.model';
import { noWhitespaceValidator } from '../../../../shared/models/no-whitespace-validator';
import { AnnouncementObj } from '../../../../core/modal/announcement';
import { ActivatedRoute, Router } from '@angular/router';
import { GalleryPopupComponent } from '../campaign-gallery/gallery-popup.component';
import { ProjectService } from '../../../../core/services/project.service';

declare const CKEDITOR;

@Component({
  selector: 'popup-component',
  templateUrl: './popup.component.html',
  styleUrls: ['./announcement.component.scss']
})

export class announcementPopupComponent implements OnInit, OnDestroy {
  	siteForm: FormGroup;
	config: any;
	CurrentPlugin: any;
	modalRefGallery: BsModalRef;
	modalRefConfirm: BsModalRef;
	isPreview: boolean = false; 
	saveMsg: string;
	@ViewChild("confirm") templateConfirm: TemplateRef<any>;
	previewURL: string;
	closeBtnColorValue: any = "#000";
	bgColorValue: any = "#FFF";
	ckeditorData:Subject<string> = new Subject();
	minDate: any = new Date();
	isUpdate: boolean = false;
	id: number;
	pageTitle:string = 'Create Popup Notification';
	type:number = 1;
	@ViewChild('pageTabs') pageTabs : TabsetComponent;
	constructor(
		private formBuilder: FormBuilder,
		public global: Global,
		private modalService: BsModalService,
		private toastr: ToastrService,
		private announcementService: AnnouncementService,
		private datePipe: DatePipe,
		private route: ActivatedRoute,
		private router: Router,
		private projectService: ProjectService
	) { 
    this.siteForm = this.formBuilder.group({
			name: [ "", Validators.compose([ Validators.required, noWhitespaceValidator, Validators.maxLength(100), Validators.minLength(0), Validators.pattern(this.global.AlphaNumPatternWithChar) ]) ],
			triggerEvent: ['0'],
			startTime: [ 0, Validators.compose([ Validators.required, noWhitespaceValidator, Validators.max(100), Validators.min(0) ]) ],
			duration: [ 0, Validators.compose([ Validators.required, noWhitespaceValidator, Validators.max(100), Validators.min(0) ]) ],
			width: [ 800, Validators.compose([ Validators.required, noWhitespaceValidator, Validators.max(1000), Validators.min(300) ]) ],
			bodyContent: ["", Validators.compose([ Validators.required, noWhitespaceValidator]) ],
			durationType: ["always"],
			clickable: [false],
			closable: [true],
			target: [false],
			link: [""],
			allowPages: [""],
			deleteStatus: [true],
			bgColor: ['#FFFFFF', Validators.compose([ Validators.required, noWhitespaceValidator])],
			closeBtnColor: ['#000000', Validators.compose([ Validators.required, noWhitespaceValidator])],
			fromDate: [new Date(), Validators.required],
			toDate: [new Date(), Validators.required],
			openbyClick: [""],
			repeatDays: ["0", Validators.compose([ noWhitespaceValidator, Validators.max(30), Validators.min(0) ]) ]
		});
  	}

  	ngOnInit() {
		this.route.params.subscribe( params => {
            if(params.id){
                this.id = params.id;
                this.isUpdate = true;
				this.pageTitle = 'Update Popup Notification';
                this.getData();
            }
        });
		this.config =  this.global.CKEditorConfig(250,'full');
	}

	ngOnDestroy(){
	
	}

	checkLinkClickable(value){
		if(value.currentTarget.checked){
			this.siteForm.get('link').setValidators([Validators.required]);
            this.siteForm.get('link').updateValueAndValidity();
		} else {
			this.siteForm.get('link').setValidators([]);
            this.siteForm.get('link').updateValueAndValidity();
		};
	}

	submitForm(type){
		Object.keys(this.siteForm.controls).forEach(key => {
			const controlErrors: ValidationErrors = this.siteForm.get(key).errors;
			if (controlErrors != null) {
				if(key == 'toDate' || key == 'fromDate')
				this.pageTabs.tabs[1].active = true;
				else if(key == 'name' || key == 'bodyContent' || key == 'width')
				this.pageTabs.tabs[0].active = true;
				else if(key == 'repeatDays')
				this.pageTabs.tabs[2].active = true;
			}
		});
		if (this.siteForm.invalid) {
            this.toastr.error(this.global.MessageRequired);
            this.global.validateAllFormFields(this.siteForm);
            return;
		}
		let options = `{
			isExitIntent: `+this.siteForm.value.triggerEvent+`,
			startTime:`+this.siteForm.value.startTime+`,
			openbyClick:'`+this.siteForm.value.openbyClick+`'
		}`
		let closeBtnHtml = '';
		if(this.siteForm.value.closable){
			closeBtnHtml = `<a href="javascript:void(0)" class="cb-close">
				<svg fill="`+this.siteForm.value.closeBtnColor+`" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"/></svg>
			</a>`
		};
		let onClick = ""
		if(this.siteForm.value.clickable){
			let target = '_self';
			if(this.siteForm.value.target) target = '_blank';
			onClick = `onclick="window.open('`+this.siteForm.value.link+`', '`+target+`');"`
        };
		var id = this.id != undefined ? this.id : 0;
		// var openbyClick = this.siteForm.value.openbyClick != '' ?  ','+this.siteForm.value.openbyClick : '';
		let html = `<div id="backdrop-modal-`+id+`" class="cb-modal-backdrop"></div>
        <div class="cb-modal" id="modal-`+id+`" data-rdays="`+this.siteForm.value.repeatDays+`"> <div class="cb-modal-dialog" style="max-width:`+this.siteForm.value.width+`px;">
			`+closeBtnHtml+`
			<div class="cb-modal-content" `+onClick+` style="background-color:`+this.siteForm.value.bgColor+`" > `+this.siteForm.value.bodyContent+` </div> 
		</div></div>
        <js>cpjq('#modal-`+id+`').showPopup(`+options+`)</js> <style>.cb-modal-content{height:calc(100vh - 5.5rem)} .cb-modal-content iframe{height:100% !important;}</style>`;

		var postData: AnnouncementObj = {
			data : JSON.stringify(this.siteForm.value),
			name : this.siteForm.value.name,
			flagPopup : 1,
			html : html,
			scheduleStart : this.datePipe.transform(this.siteForm.value.fromDate, "yyyy-MM-dd"),
			scheduleEnd : this.datePipe.transform(this.siteForm.value.toDate, "yyyy-MM-dd"),
			allowPages : this.siteForm.value.allowPages,
			repeatDays : this.siteForm.value.repeatDays,
			status : this.siteForm.value.deleteStatus && type == 'PUBLISH' ? 'ACTIVE' : 'HIDDEN',
			projectKey: this.projectService.projectDetails.projectKey
		};
		if(type == 'PREVIEW'){
			localStorage.setItem('cp_preview_html', html);
			window.open('/assets/preview.html','_blank');
		}
		if(this.id != undefined){
			postData.id = this.id;
			this.announcementService.updateAnnouncement(postData,this.type).subscribe( data => { 
				this.saveData(data, type);
			});
		} else {
			this.announcementService.createAnnouncement(postData,this.type).subscribe( data => { 
				this.saveData(data, type);
			});
		}
	}

	saveData(data, type){
		if(data.status){
			this.id = data.data.id;
			if(type == "PUBLISH"){
				if(!this.isUpdate){
					this.saveMsg = data.data.message;
					this.modalRefConfirm = this.modalService.show(this.templateConfirm, { backdrop: 'static' });
				} else {
					this.toastr.success('Successfully Updated');
					this.router.navigate(['/admin/announcement/popup']);
				}
			}
		} else {
			this.toastr.error(data.error_msgs, "Error");	
		}
	}

	getData(){
		this.announcementService.fetchAnnouncement({id:this.id},this.type).subscribe( data => {
			if(data.status){
				var popupData : AnnouncementObj =  data.data;
				this.setFormData(popupData);
			} else {
				this.toastr.error(data.error_msgs, "Error")
			}
		});
	}

	setFormData(data){
		this.isPreview = true;
		var jsonData = JSON.parse(data.data);
		this.siteForm.setValue(jsonData);
		this.siteForm.controls['fromDate'].setValue(new Date(jsonData.fromDate));
		this.siteForm.controls['toDate'].setValue(new Date(jsonData.toDate));
		this.ckeditorData.next(jsonData.bodyContent);
	}


	setBoolean(value){
		if(value) return 0;
		return 1;
	}

	openGalleryModal(){
		const initialState = {
            galleryType : 'all,form',
            modalRefGallery: this.modalRefGallery
        }
        this.modalRefGallery = this.modalService.show(GalleryPopupComponent,{
            initialState,
            backdrop: "static",
            class: "gallery-popup"
        });
        this.modalRefGallery.content.modalRefGallery = this.modalRefGallery;
        this.modalRefGallery.content.selectMediaEvent.subscribe(data => {
            this.getMedia(data);
        });
	}

	getMedia(item){
		let mediaHTML = '';
		if(item.type == AppConstants.FORM){
			mediaHTML = '<iframe src="'+ApiConstants.CUSTOM_FORM_LINK+item.linkRewrite+'" height="'+item.height+'" width="100%" title="'+item.name+'" style="border:none;"></iframe>'
		} else if(item.type === AppConstants.FILE){
			mediaHTML = '<a href="'+item.originalFile+'" target="_blank">'+item.mediaName+'</a>'
			this.toastr.success('Double click on "'+item.mediaName+'" to update.')
		} else {
			mediaHTML = '<img src="'+item.originalFile+'" width="200" alt="'+item.mediaName+'" />';
		}
		this.insertContent(mediaHTML);
	}

	insertContent(html) {
		for (var i in CKEDITOR.instances) {
			CKEDITOR.instances[i].insertHtml(html);
		}
		return true;
	}


	

}