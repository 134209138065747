
<main>
    <section class="inner-page">
        <div class="inner-page-header py-3 d-none d-md-block">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-6">
                        <h4 class="d-flex c-align">
                            <a class="back-link" (click)="back()"><img src="assets/images/angle-left.svg"></a>
                            
                            <span>Project Integration</span>

                        </h4>
                        <p>Provide project information to start integration.</p>
                    </div>
                    <div class="col-md-6 text-right align-self-end">
                        <a class="integration-link" [routerLink]="['/admin/settings/api-integration-kit']" target="_self">API Integration Kit</a>
                    </div>
                </div>
            </div>
        </div>

        <!-- Project Integration teb strat -->
        <div class="configuration project-integration-tab" *ngIf="Plugindata != undefined">
            <tabset #pluginTabs>
                <tab *ngFor="let item of getPluginData()" [heading]="item" (selectTab)="onTabSelect($event)">
                    <div class="inner-page-body">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-md-12">
                                    <ng-container *ngIf="item === 'Social Media'; else otherPluginWithForms">
                                        <h4 class="d-none d-md-block">{{ item }}</h4>
                                        <tabset #socialMedia>
                                            <tab *ngFor="let plugin of Plugindata[item]" [heading]="plugin.pluginName">
                                                <ng-container *ngIf="plugin.pluginSub === 'ENABLE' else otherPluginWithFormsSub">
                                                    <div class="text-right mb-1"><a href="{{this.CurrentPlugin.helpLink}}" target="_self">{{this.CurrentPlugin.helpLabel}}</a></div>
                                                    <div class="text-right mb-1" *ngIf="CurrentPlugin.status && CurrentPlugin.pluginSub === 'ENABLE' && facebookPageList.length>0"><a href="javascript:void(0);"  (click)="isPluginSubscribed(4,1)" class="borderradis add-box">
                                                      Refresh Facebook Token
                                                    </a></div>
                                                    
                                                    <div class="directory-col social-directory">
                                                        <div *ngFor="let page of facebookPageList" class="borderradis">
                                                            <div class="settings" *ngIf="page.status == 'ACTIVE'" (click)="showConfigTools(page.pluginId,'leadAssign');showLeadStatusModal(page.formName,page)"><img src="assets/images/setting.png" /></div>
                                                            <div class="settings configuration" (click)="showConfigTools(page.pluginId)"><img src="assets/images/configuration.svg" /></div>
                                                            <a class="inner-box" [routerLink]="getFaceBookPageURL(page.formName)" >
                                                                <div class="directory-col-detail">
                                                                    <img src="assets/images/facebook-ads-icon.png" alt="facebook-ads" width="22" />
                                                                    <h3>{{page.formName}}</h3>
                                                                    <p>{{page.formName}}</p>
                                                                </div>
                                                            </a>
                                                        </div>
                                                        <a href="javascript:void(0);" *ngIf="CurrentPlugin.status && CurrentPlugin.pluginSub === 'ENABLE' else disableFacebokAdd" (click)="isPluginSubscribed(CurrentPlugin.id)" class="borderradis add-box">
                                                            <div class="directory-col-detail">
                                                                <img src="assets/images/plus.svg" alt="Add" />
                                                                <h3>Add Form</h3>
                                                            </div>
                                                        </a>
                                                        <ng-template #disableFacebokAdd>
                                                            <div class="borderradis add-box disabled">
                                                                <div class="directory-col-detail">
                                                                    <img src="assets/images/plus.svg" alt="Add" />
                                                                    <h3>Add Form</h3>
                                                                </div>
                                                            </div>
                                                        </ng-template>
                                                    </div>
                                                    <br/>
                                                    <div class="custom-control custom-switch swich-button mr-5">
                                                        <span class="bm">Plugin Active: </span>
                                                        <input type="checkbox" name="CurrentPluginStatus" (change)="updatePluginStatusByMS()"
                                                            [(ngModel)]="this.CurrentPlugin.status" class="custom-control-input" id="customSwitchesFacebook" />
                                                        <label class="custom-control-label" for="customSwitchesFacebook"></label>
                                                        <!-- <span class="by" [ngClass]="{ pink: !this.CurrentPlugin.status }">Disable</span> -->
                                                    </div>
                                                    <!-- <div class="custom-control custom-switch swich-button mr-5">
                                                        <span class="bm">Lead updation: </span>
                                                        <input type="checkbox" name="CurrentPluginLead" (change)="updateLeadStatus()"
                                                            [(ngModel)]="this.CurrentPlugin.leadActiveStatus" class="custom-control-input" id="customSwitchesFacebookLead" />
                                                        <label class="custom-control-label" for="customSwitchesFacebookLead"></label>
                                                    </div>


                                                    <div class="custom-control custom-switch swich-button mr-5">
                                                        <span class="bm">Repeat Lead Assignment: </span>
                                                        <input type="checkbox" name="RepeatLeadAssignment" (change)="updateRepeatLeadAssignemnt()"
                                                            [(ngModel)]="this.CurrentPlugin.leadRepeatFlagStatus" class="custom-control-input" id="customSwitchesFacebookLeadAssignemnt" />
                                                        <label class="custom-control-label" for="customSwitchesFacebookLeadAssignemnt"></label>
                                                    </div> -->

                                                        <div class="custom-control custom-switch swich-button ml-4">
                                                            <span class="bm">Show Inactive: </span>
                                                            <input type="checkbox" name="updateListByStatus" (change)="updatePluginAction($event)"
                                                                [(ngModel)]="updateListByStatus" class="custom-control-input" id="customSwitchesActiveDeactive" />
                                                            <label class="custom-control-label" for="customSwitchesActiveDeactive"></label>
                                                            <!-- <span class="by" [ngClass]="{ pink: !this.CurrentPlugin.status }">Disable</span> -->
                                                        </div>

                                                    
                                                    <!-- <div class="custom-control custom-switch status">
                                                        <span class="bm">Is Plugin Subscribed: </span>
                                                        <span class="tag" [style.background-color]="CurrentPlugin.pluginSub === 'ENABLE' ? '#3dc151' : '#adb5bd'"></span>
                                                    </div> -->
                                                </ng-container>
                                                <ng-template #otherPluginWithFormsSub>
                                                    <app-page-disable></app-page-disable>
                                                </ng-template>
                                                <p class="imp_notes">
                                                    Note: Cronberry cannot be held responsible for any limitations or restrictions placed on our APIs by marketplaces, e-commerce service providers, or any other online lead generation source. While we strive to provide reliable access to data, third-party policies and decisions are outside of our control.
                                                </p>
                                            </tab>
                                        </tabset>
                                    </ng-container>
                                    <ng-template #otherPluginWithForms>
                                        <ng-container *ngIf="item === 'Forms'; else otherPlugin">
                                            <h4 class="d-none d-md-block">{{ item }}</h4>
                                            <tabset #formsTabs>
                                                <tab *ngFor="let plugin of Plugindata[item]" [heading]="plugin.pluginName" (selectTab)="onTabSelect($event)">
                                                    <ng-container *ngIf="plugin.pluginSub === 'ENABLE' else otherPluginSub">
                                                        <div class="text-right mb-1"><a href="{{plugin.helpLink}}" target="_self">{{this.CurrentPlugin.helpLabel}}</a></div>
                                                        <div class="directory-col"  *ngIf="plugin.id == 3; else otherForms">
                                                            <a *ngFor="let item of googleFormList" href="javascript:void(0);" class="borderradis">
                                                                <div class="settings" (click)="showConfigTools(item.pluginId,'leadAssign');showLeadStatusModal(item.formName)" *ngIf="item.status == 'ACTIVE'"><img src="assets/images/setting.png" /></div>
                                                                <div class="settings configuration" (click)="showConfigTools(item.pluginId)" *ngIf="item.status == 'ACTIVE'"><img src="assets/images/configuration.svg" /></div>
                                                                <div class="inner-box" (click)="GetGoogleFormData(item.id,item)">
                                                                    <div class="directory-col-detail">
                                                                        <img src="assets/images/google-form.png" alt="google form" />
                                                                        <p>{{item.formName}}</p>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                            <a *ngIf="plugin.status else disableAdd" href="javascript:void(0);" class="borderradis add-box" (click)="isPluginSubscribed(CurrentPlugin.id)">
                                                                <div class="inner-box"><div class="directory-col-detail">
                                                                    <img src="assets/images/plus.svg" alt="Add" />
                                                                    <h3>Add Form</h3>
                                                                </div></div>
                                                            </a>
                                                            <ng-template #disableAdd>
                                                                <div class="borderradis add-box disabled">
                                                                    <div class="inner-box"><div class="directory-col-detail">
                                                                        <img src="assets/images/plus.svg" alt="Add" />
                                                                        <h3>Add Form</h3>
                                                                    </div></div>
                                                                </div>
                                                            </ng-template>
                                                        </div>
                                                        <ng-template #otherForms>
                                                            <div class="directory-col">
                                                                <a *ngFor="let item of customFormList" class="borderradis">
                                                                    <div class="settings" (click)="showConfigTools(item.pluginFormId,'leadAssign'); showLeadStatusModal(item.name)" *ngIf="item.status == 'ACTIVE'"><img src="assets/images/setting.png" /></div>
                                                                    <div class="settings configuration" (click)="showConfigTools(item.pluginFormId)" *ngIf="item.status == 'ACTIVE'"><img src="assets/images/configuration.svg" /></div>
                                                                    <div class="inner-box" (click)="showCustomFormModal(item);">
                                                                        <div class="directory-col-detail">
                                                                            <img src="assets/images/custom-form.svg" alt="custom form" />
                                                                            <p>{{item.name}}</p>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                                <a [routerLink]="['/admin/custom-form']" *ngIf="CurrentPlugin.status else disableAddCF" class="borderradis add-box">
                                                                    <div class="inner-box"><div class="directory-col-detail">
                                                                        <img src="assets/images/plus.svg" alt="Add" />
                                                                        <h3>Add Form</h3>
                                                                    </div></div>
                                                                </a>
                                                                <ng-template #disableAddCF>
                                                                    <div class="borderradis add-box disabled">
                                                                        <div class="inner-box"><div class="directory-col-detail">
                                                                            <img src="assets/images/plus.svg" alt="Add" />
                                                                            <h3>Add Form</h3>
                                                                        </div></div>
                                                                    </div>
                                                                </ng-template>
                                                            </div>
                                                        </ng-template>
                                                        <br/>
                                                        <div class="custom-control custom-switch swich-button mr-5">
                                                            <span class="bm">Plugin Active: </span>
                                                            <input type="checkbox" name="CurrentPluginStatus" (change)="updatePluginStatus()"
                                                                [(ngModel)]="CurrentPlugin.status" class="custom-control-input" id="customSwitchesGoogleForm" />
                                                            <label class="custom-control-label" for="customSwitchesGoogleForm"></label>
                                                            <!-- <span class="by" [ngClass]="{ pink: !this.CurrentPlugin.status }">Disable</span> -->
                                                        </div>
                                                        <!-- <div class="custom-control custom-switch swich-button mr-5">
                                                            <span class="bm">Lead updation: </span>
                                                            <input type="checkbox" name="CurrentPluginLead" (change)="updateLeadStatus()"
                                                                [(ngModel)]="CurrentPlugin.leadActiveStatus" class="custom-control-input" id="customSwitchesGoogleLeadForm" />
                                                            <label class="custom-control-label" for="customSwitchesGoogleLeadForm"></label>
                                                        </div>

                                                        <div class="custom-control custom-switch swich-button mr-5">
                                                            <span class="bm">Repeat Lead Assignment: </span>
                                                            <input type="checkbox" name="RepeatLeadAssignment" (change)="updateRepeatLeadAssignemnt()"
                                                                [(ngModel)]="this.CurrentPlugin.leadRepeatFlagStatus" class="custom-control-input" id="customSwitchesGoogleLeadAssignemnt" />
                                                            <label class="custom-control-label" for="customSwitchesGoogleLeadAssignemnt"></label>
                                                        </div> -->

                                                        <div class="custom-control custom-switch swich-button ml-4">
                                                            <span class="bm">Show Inactive: </span>
                                                            <input type="checkbox" name="updateListByStatus" (change)="updatePluginAction($event)"
                                                                [(ngModel)]="updateListByStatus" class="custom-control-input" id="customSwitchesActiveDeactive" />
                                                            <label class="custom-control-label" for="customSwitchesActiveDeactive"></label>
                                                        </div>
                                                    </ng-container>
                                                    <ng-template #otherPluginSub>
                                                        <app-page-disable></app-page-disable>
                                                    </ng-template>
                                                    <p class="imp_notes">
                                                        Note: Cronberry cannot be held responsible for any limitations or restrictions placed on our APIs by marketplaces, e-commerce service providers, or any other online lead generation source. While we strive to provide reliable access to data, third-party policies and decisions are outside of our control.
                                                    </p>
                                                </tab>
                                            </tabset>
                                        </ng-container>
                                        <ng-template #otherPlugin>
                                            <ng-container *ngIf="item === 'Marketplace' || item === 'IVR'; else otherPluginWithoutMarketplace">
                                                <h4 class="d-none d-md-block">{{ item }} </h4>
                                                    <tabset #marketplaceTabs *ngIf="item === 'Marketplace'">
                                                    <tab *ngFor="let plugin of Plugindata[item]" [heading]="plugin.pluginName" id="{{item}}" (selectTab)="onTabSelect($event)">
                                                        <ng-container *ngIf="plugin.pluginSub === 'ENABLE' else otherPluginWithoutMarketplaceSub">
                                                            <div class="text-right mb-1"><a href="{{plugin.helpLink}}" target="_self">{{plugin.helpLabel}}</a></div>
                                                            <div class="directory-col">
                                                                <a *ngFor="let itemPlugin of plugin.formDataMap" href="javascript:void(0);" class="borderradis">
                                                                    <div class="settings" (click)="showConfigTools(itemPlugin.pluginId,'leadAssign'); showLeadStatusModal(itemPlugin.formName)"><img src="assets/images/setting.png" /></div>
                                                                    <div class="settings configuration" (click)="showConfigTools(itemPlugin.pluginId)"><img src="assets/images/configuration.svg" /></div>
                                                                    <div class="inner-box" (click)="fetchPluginsFrom(plugin,itemPlugin.formName)">
                                                                        <div class="directory-col-detail">
                                                                            <img src="{{plugin.pluginLogo}}" alt="{{itemPlugin.formName}}" />
                                                                            <p>{{itemPlugin.formName}}</p>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                                
                                                                <ng-container *ngIf="CurrentPlugin.multipleAdd == 'ENABLE' || (plugin.formDataMap != undefined && plugin.formDataMap.length == 0) ">
                                                                    <a *ngIf="CurrentPlugin.status && CurrentPlugin.pluginSub == 'ENABLE' else disableAdd" href="javascript:void(0);" class="borderradis add-box" (click)="fetchPluginsFrom(plugin,'add')">
                                                                        <div class="inner-box"><div class="directory-col-detail">
                                                                            <img src="assets/images/plus.svg" alt="Add" />
                                                                            <h3>Add {{item}}</h3>
                                                                        </div></div>
                                                                    </a>
                                                                    <ng-template #disableAdd>
                                                                        <div class="borderradis add-box disabled">
                                                                            <div class="inner-box"><div class="directory-col-detail">
                                                                                <img src="assets/images/plus.svg" alt="Add" />
                                                                                <h3>Add {{item}}</h3>
                                                                            </div></div>
                                                                        </div>
                                                                    </ng-template>
                                                                </ng-container>
                                                            </div>
                                                            <br/>
                                                            <div class="custom-control custom-switch swich-button mr-5">
                                                                <span class="bm">Plugin Active : </span> 
                                                                <input type="checkbox" name="CurrentPluginStatus" (change)="this.CurrentPlugin.categoryName === 'IVR' ?updatePluginStatusByMS():updatePluginStatus()"
                                                                    [(ngModel)]="this.CurrentPlugin.status" class="custom-control-input" id="customSwitchesGoogle" />

                                                                   
                                                                <label class="custom-control-label" for="customSwitchesGoogle"></label>
                                                                <!-- <span class="by" [ngClass]="{ pink: !this.CurrentPlugin.status }">Disable</span> -->
                                                            </div>
                                                            <!-- <div class="custom-control custom-switch swich-button mr-5">
                                                                <span class="bm">Lead updation : </span>
                                                                <input type="checkbox" name="CurrentPluginLead" (change)="updateLeadStatus()"
                                                                    [(ngModel)]="this.CurrentPlugin.leadActiveStatus" class="custom-control-input" id="customSwitchesGoogleLead" />
                                                                <label class="custom-control-label" for="customSwitchesGoogleLead"></label>
                                                            </div>


                                                            <div class="custom-control custom-switch swich-button mr-5">
                                                                <span class="bm">Repeat Lead Assignment: </span>
                                                                <input type="checkbox" name="RepeatLeadAssignment" (change)="updateRepeatLeadAssignemnt()"
                                                                    [(ngModel)]="this.CurrentPlugin.leadRepeatFlagStatus" class="custom-control-input" id="customSwitchesGoogleLeadAssignemnt" />
                                                                <label class="custom-control-label" for="customSwitchesGoogleLeadAssignemnt"></label>
                                                            </div> -->
                                                        </ng-container>
                                                        <ng-template #otherPluginWithoutMarketplaceSub>
                                                            <app-page-disable></app-page-disable>
                                                        </ng-template>
                                                    </tab>
                                                    </tabset>
                                                    <tabset #ivrTabs *ngIf="item === 'IVR'">
                                                        <tab *ngFor="let plugin of Plugindata[item]" [heading]="plugin.pluginName" id="{{item}}" (selectTab)="onTabSelect($event)">
                                                            <ng-container *ngIf="plugin.pluginSub === 'ENABLE' else otherPluginWithoutMarketplaceSub">
                                                                <div class="text-right mb-1"><a href="{{plugin.helpLink}}" target="_self">{{plugin.helpLabel}}</a></div>
                                                                <div class="directory-col">
                                                                    <a *ngFor="let itemPlugin of plugin.formDataMap" href="javascript:void(0);" class="borderradis">
                                                                        <div class="settings" (click)="showConfigTools(itemPlugin.pluginId,'leadAssign'); showLeadStatusModal(itemPlugin.formName)"><img src="assets/images/setting.png" /></div>
                                                                        <div class="settings configuration" (click)="showConfigTools(itemPlugin.pluginId)"><img src="assets/images/configuration.svg" /></div>
                                                                        <div class="inner-box" (click)="fetchPluginsFrom(plugin,itemPlugin.formName)">
                                                                            <div class="directory-col-detail">
                                                                                <img src="{{plugin.pluginLogo}}" alt="{{itemPlugin.formName}}" />
                                                                                <p>{{itemPlugin.formName}}</p>
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                    
                                                                    <ng-container *ngIf="CurrentPlugin.multipleAdd == 'ENABLE' || (plugin.formDataMap != undefined && plugin.formDataMap.length == 0) ">
                                                                        <a *ngIf="CurrentPlugin.status && CurrentPlugin.pluginSub == 'ENABLE' else disableAdd" href="javascript:void(0);" class="borderradis add-box" (click)="fetchPluginsFrom(plugin,'add')">
                                                                            <div class="inner-box"><div class="directory-col-detail">
                                                                                <img src="assets/images/plus.svg" alt="Add" />
                                                                                <h3>Add {{item}}</h3>
                                                                            </div></div>
                                                                        </a>
                                                                        <ng-template #disableAdd>
                                                                            <div class="borderradis add-box disabled">
                                                                                <div class="inner-box"><div class="directory-col-detail">
                                                                                    <img src="assets/images/plus.svg" alt="Add" />
                                                                                    <h3>Add {{item}}</h3>
                                                                                </div></div>
                                                                            </div>
                                                                        </ng-template>
                                                                    </ng-container>
                                                                </div>
                                                                <br/>
                                                                <div class="custom-control custom-switch swich-button mr-5">
                                                                    <span class="bm">Plugin Active : </span> 
                                                                    <input type="checkbox" name="CurrentPluginStatus" (change)="this.CurrentPlugin.categoryName === 'IVR'?updatePluginStatusByMS():updatePluginStatus()"
                                                                        [(ngModel)]="this.CurrentPlugin.status" class="custom-control-input" id="customSwitchesGoogle" />
                                                                    <label class="custom-control-label" for="customSwitchesGoogle"></label>
                                                                </div>
                                                                <!-- <div class="custom-control custom-switch swich-button mr-5">
                                                                    <span class="bm">Lead updation : </span>
                                                                    <input type="checkbox" name="CurrentPluginLead" (change)="updateLeadStatus()"
                                                                        [(ngModel)]="this.CurrentPlugin.leadActiveStatus" class="custom-control-input" id="customSwitchesGoogleLead" />
                                                                    <label class="custom-control-label" for="customSwitchesGoogleLead"></label>
                                                                </div>

                                                                <div class="custom-control custom-switch swich-button mr-5">
                                                                    <span class="bm">Repeat Lead Assignment: </span>
                                                                    <input type="checkbox" name="RepeatLeadAssignment" (change)="updateRepeatLeadAssignemnt()"
                                                                        [(ngModel)]="this.CurrentPlugin.leadRepeatFlagStatus" class="custom-control-input" id="customSwitchesGoogleLeadAssignemnt" />
                                                                    <label class="custom-control-label" for="customSwitchesGoogleLeadAssignemnt"></label>
                                                                </div> -->
                                                            </ng-container>
                                                            <ng-template #otherPluginWithoutMarketplaceSub>
                                                                <app-page-disable></app-page-disable>
                                                            </ng-template>
                                                        </tab>

                                                    
                                                    </tabset>
                                                    <p class="imp_notes">
                                                        Note: Cronberry cannot be held responsible for any limitations or restrictions placed on our APIs by marketplaces, e-commerce service providers, or any other online lead generation source. While we strive to provide reliable access to data, third-party policies and decisions are outside of our control.
                                                    </p>
                                            </ng-container>
                                            <ng-template #otherPluginWithoutMarketplace>
                                                <ng-container *ngIf="item === 'E Commerce'; else otherPluginWithoutEcommerce">
                                                    <h4 class="d-none d-md-block">{{ item }}</h4>
                                                    <tabset #eCommerce>
                                                        <tab *ngFor="let plugin of Plugindata[item]" [heading]="plugin.pluginName">
                                                            <ng-container *ngIf="plugin.pluginSub === 'ENABLE' else otherPluginWithFormsSub">
                                                                <div class="directory-col">
                                                                    <ng-container *ngIf="shopifyPageList">
                                                                    <div *ngFor="let page of shopifyPageList[plugin.pluginName]" class="borderradis">
                                                                        <div class="settings" (click)="showConfigTools(page.form_id,'leadAssign');showLeadStatusModal(page.formName,page)" *ngIf="page.status == 'ACTIVE'"><img src="assets/images/setting.png" /></div>
                                                                        <div class="settings configuration" (click)="showConfigTools(page.form_id)"><img src="assets/images/configuration.svg" /></div>
                                                                        <a class="inner-box" href="javascript:void(0)" (click)="openEcommerceForm(page,plugin)">
                                                                            <div class="directory-col-detail">
                                                                                <img *ngIf="plugin.pluginName == 'Shopify'" src="assets/images/Shopify-Logo.png" alt="facebook-ads" width="22" />
                                                                                <p>{{page.formName}}</p>
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                    </ng-container>

                                                                    <a href="javascript:void(0);" (click)="addEcommerceForm(plugin)" class="borderradis add-box">
                                                                        <div class="inner-box">
                                                                            <div class="directory-col-detail">
                                                                                <img src="assets/images/plus.svg" alt="Add" />
                                                                                <p>Add Form</p>
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                                <br/>
                                                                <div class="custom-control custom-switch swich-button mr-5">
                                                                    <span class="bm">Plugin Active: </span>
                                                                    <input type="checkbox" name="CurrentPluginStatus" (change)="updatePluginStatusByEcomm(plugin.pluginName)"
                                                                        [(ngModel)]="this.CurrentPlugin.status" class="custom-control-input" id="customSwitches_{{plugin.pluginName}}" />
                                                                    <label class="custom-control-label" for="customSwitches_{{plugin.pluginName}}"></label>
                                                                </div>
                                                            </ng-container>
                                                            <ng-template #otherPluginWithFormsSub>
                                                                <app-page-disable></app-page-disable>
                                                            </ng-template>
                                                            <p class="imp_notes">
                                                                Note: Cronberry cannot be held responsible for any limitations or restrictions placed on our APIs by marketplaces, e-commerce service providers, or any other online lead generation source. While we strive to provide reliable access to data, third-party policies and decisions are outside of our control.
                                                            </p>
                                                        </tab>
                                                    </tabset>
                                                </ng-container>
                                                
                                               <ng-template #otherPluginWithoutEcommerce>
                                                <h4 class="d-none d-md-block">{{ item }}</h4>
                                                <div class="directory-col">
                                                    <ng-container *ngFor="let plugin of this.Plugindata[item]">

                                                        <ng-container *ngIf="plugin.categoryName == 'API'">
                                                            <div  class="borderradis">
                                                                <div class="settings" (click)="showConfigTools(plugin.formDataMap[0].pluginId,'leadAssign');showLeadStatusModal(plugin.pluginName)"><img src="assets/images/setting.png" /></div>
                                                                <div class="settings configuration" (click)="showConfigTools(plugin.formDataMap[0].pluginId)"><img src="assets/images/configuration.svg" /></div>
                                                                <div class="inner-box" (click)="fetchPluginsFrom(plugin)">
                                                                    <div class="directory-col-detail">
                                                                        <img src="{{ plugin.pluginLogo }}" alt="{{plugin.pluginName}}" />
                                                                        <p>{{ plugin.pluginName }}</p>
                                                                    </div>
                                                                    <div class="radio-btn-green {{ plugin.pluginStatus.toLowerCase() }}d">
                                                                        <p>{{ plugin.pluginStatus == "ENABLE" ? "Enabled" : "Disabled" }} <span></span></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <p class="imp_notes">
                                                                Note: Cronberry cannot be held responsible for any limitations or restrictions placed on our APIs by marketplaces, e-commerce service providers, or any other online lead generation source. While we strive to provide reliable access to data, third-party policies and decisions are outside of our control.
                                                            </p>
                                                            </ng-container>

                                                        <ng-container *ngIf="plugin.categoryName != 'Bulk' && plugin.categoryName != 'API'">
                                                        <div  class="borderradis">
                                                            <div class="settings" (click)="showConfigTools(plugin.id,'leadAssign');showLeadStatusModal(plugin.pluginName)"><img src="assets/images/setting.png" /></div>
                                                            <div class="settings configuration" (click)="showConfigTools(plugin.id)"><img src="assets/images/configuration.svg" /></div>
                                                            <div class="inner-box" (click)="fetchPluginsFrom(plugin)">
                                                                <div class="directory-col-detail">
                                                                    <img src="{{ plugin.pluginLogo }}" alt="{{plugin.pluginName}}" />
                                                                    <p>{{ plugin.pluginName }}</p>
                                                                </div>
                                                                <div class="radio-btn-green {{ plugin.pluginStatus.toLowerCase() }}d">
                                                                    <p>{{ plugin.pluginStatus == "ENABLE" ? "Enabled" : "Disabled" }} <span></span></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <p class="imp_notes">
                                                            Note: Cronberry cannot be held responsible for any limitations or restrictions placed on our APIs by marketplaces, e-commerce service providers, or any other online lead generation source. While we strive to provide reliable access to data, third-party policies and decisions are outside of our control.
                                                        </p>
                                                        </ng-container>
                                                        <ng-container *ngIf="plugin.categoryName == 'Bulk'">

                                                            <a *ngFor="let itemPlugin of excelPageList" href="javascript:void(0);" class="borderradis">
                                                                <div class="settings" (click)="showConfigTools(itemPlugin.pluginId,'leadAssign');showLeadStatusModal(itemPlugin.formName)"><img src="assets/images/setting.png" /></div>
                                                                <div class="settings configuration" (click)="showConfigTools(itemPlugin.pluginId)"><img src="assets/images/configuration.svg" /></div>
                                                                <div class="inner-box" (click)="showDetailXlForm(itemPlugin)">
                                                                    <div class="directory-col-detail">
                                                                        <img src="{{plugin.pluginLogo}}" alt="{{itemPlugin}}" />
                                                                        <p>{{itemPlugin.formName}}</p>
                                                                    </div>
                                                                </div>
                                                            </a>


                                                            <a class="borderradis add-box" href="javascript:void(0);" (click)="addFormExcel()">
                                                                <div class="inner-box">
                                                                    <div class="directory-col-detail">
                                                                        <img alt="Add" src="assets/images/plus.svg">
                                                                        <h3>Add Form</h3>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                            <div class="width_100 m_t_20">
                                                                <div class="custom-control custom-switch swich-button">
                                                                    <span class="bm">Plugin Active: </span>
                                                                    <input type="checkbox" name="CurrentPluginStatus" (change)="updatePluginStatus()"
                                                                    [(ngModel)]="CurrentPlugin.status" class="custom-control-input" id="customSwitchesPlugin" />
                                                                    <label class="custom-control-label" for="customSwitchesPlugin"></label>
                                                                </div>
                                                                
                                                                <!-- <div class="custom-control custom-switch swich-button mr-5">
                                                                    <span class="bm">Lead updation : </span>
                                                                    <input type="checkbox" name="CurrentPluginLead" (change)="updateLeadStatus()"
                                                                        [(ngModel)]="this.CurrentPlugin.leadActiveStatus" class="custom-control-input" id="customSwitchesGoogleLead" />
                                                                    <label class="custom-control-label" for="customSwitchesGoogleLead"></label>
                                                                </div>

                                                                <div class="custom-control custom-switch swich-button mr-5">
                                                                    <span class="bm">Repeat Lead Assignment: </span>
                                                                    <input type="checkbox" name="RepeatLeadAssignment" (change)="updateRepeatLeadAssignemnt()"
                                                                        [(ngModel)]="this.CurrentPlugin.leadRepeatFlagStatus" class="custom-control-input" id="customSwitchesGoogleLeadAssignemnt" />
                                                                    <label class="custom-control-label" for="customSwitchesGoogleLeadAssignemnt"></label>
                                                                </div> -->
                                                            </div>
                                                        </ng-container>
                                               
                                                    </ng-container>
                                                </div>
                                                </ng-template>
                                               
                                                
                                            </ng-template>
                                            
                                        </ng-template>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </div>
                </tab>

                <tab *ngIf="this.Plugindata != undefined && getPluginData().length > 0" heading="Custom" (selectTab)="getUserRole()">
                    <div class="inner-page-body">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="table-user-identity pr-2 pl-2">
                                        <div class="table-body">
                                            <h4 class="mb-0">User Identity</h4>
                                            <p class="pb-4">
                                                These are important parameters for user engagement.
                                            </p>
                                            <div class="table-responsive table-height-scroll">
                                                <table class="table">
                                                    <tbody>
                                                        <tr>
                                                            <th>Label</th>
                                                            <th class="text-center">Type</th>
                                                            <th class="text-center">Action</th>
                                                        </tr>
                                                        <ng-container *ngFor="let item of fixedParams" >
                                                        <tr *ngIf="item.pluginName == 'static'">
                                                            <td>{{ item.paramName }}</td>
                                                            <td>
                                                                <div class="identity-box">
                                                                    {{ item.paramDatatype }}
                                                                </div>
                                                            </td>
                                                            <td class="text-center">
                                                                <button class="btn edit-btn border-grey" type="button" (click)="openModal(item, templateForStatic,'sysAttr')">
                                                                    <a href="javascript:void(0)" class="btn without-hover btn-sm"><img src="assets/images/segmenteye.png"></a>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </ng-container>

                                                        
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    
                                </div>

                                <div class="col-md-8">
                                    <div class="table-category-attributes pr-2 pl-2">
                                        <div class="table-body">
                                            <h4 class="mb-0">User defined Category & Attributes</h4>
                                            <p class="pb-3">
                                                Create more categories for collecting specific data from your users.
                                                These parameters will be
                                                helpful for segmentation of your specific users.
                                            </p>

                                            <div *ngIf="inputParams.length == 0" class="add-content text-center">
                                                <a (click)="openModal({}, template)">
                                                    <img alt="javascript:;" src="assets/images/plus.png" />
                                                </a>
                                            </div>

                                            <div *ngIf="inputParams.length > 0"
                                                class="table-responsive table-height-scroll text-center">
                                                <table class="table">
                                                    <tbody>
                                                        <tr>
                                                            <th>Label</th>
                                                            <th>Category</th>
                                                            <th>Type</th>
                                                            <th>Key</th>
                                                            <th>
                                                                <div class="w-80">Action</div>
                                                            </th>
                                                        </tr>
                                                        <tr *ngFor="let item of inputParams">
                                                            <ng-container *ngIf="item.pluginName != 'static' && item.paramOwner == 0">
                                                                <td>{{ item.paramName }}</td>
                                                                <td>{{item.paramCategory == 0 ? "Event" : "Attribute" }}</td>
                                                                <td>{{ item.paramDatatype }}</td>
                                                                <td>{{ item.paramKey }}</td>
                                                                <td>
                                                                    <div class="table-btn text-center">
                                                                        <button type="button"
                                                                            (click)="openModal(item, template)"
                                                                            class="btn edit-btn border-grey">
                                                                            <span class="icon-edit" tabindex="4"></span>
                                                                        </button>
                                                                        <button type="button" [disabled]="item.paramEditable == 'INACTIVE'"
                                                                            (click)="openModal(item, confirm)"
                                                                            class="btn trash-btn">
                                                                            <span class="icon-trash"
                                                                                tabindex="5"></span>
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                            </ng-container>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div class="add-row pt-2" *ngIf="inputParams.length > 0">
                                                <button type="button" (click)="openModal({}, template)" class="btn add-btn">
                                                    <span class="icon-plus" tabindex="6"></span> Add
                                                </button>
                                            </div>

                                            <div class="table-body">
                                                <h4 class="mb-0">System defined Attributes</h4>
                                                <p>&nbsp;</p>
                                            <div *ngIf="inputParams.length > 0"
                                                class="table-responsive table-height-scroll text-center">
                                                
                                                <table class="table">
                                                    <tbody>
                                                        <tr>
                                                            <th>Label</th>
                                                            <th>Category</th>
                                                            <th>Type</th>
                                                            <th>Key</th>
                                                            <th>
                                                                <div class="w-80">Action</div>
                                                            </th>
                                                        </tr>
                                                        <tr *ngFor="let item of inputParams">
                                                            <ng-container *ngIf="item.pluginName != 'static' && item.paramOwner == 1 && item.paramVisible == 1">
                                                                <td>{{ item.paramName }}</td>
                                                                <td>{{item.paramCategory == 0 ? "Event" : "Attribute" }}</td>
                                                                <td>{{ item.paramDatatype }}</td>
                                                                <td>{{ item.paramKey }}</td>
                                                                <td>
                                                                    <div class="text-center">
                                                                        <button type="button"
                                                                            (click)="openModal(item, templateForStatic,'sysAttr')"
                                                                            class="btn edit-btn">
                                                                            <a class="btn without-hover btn-sm" href="javascript:void(0)"><img _ngcontent-serverapp-c8="" src="assets/images/segmenteye.png"></a>
                                                                        </button>
                                                                       
                                                                    </div>
                                                                </td>
                                                            </ng-container>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            </div>

                                          
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </tab>
            </tabset>
        </div>
        <!-- Project Integration teb end -->

    </section>
</main>

<ng-template #template>
    <form [formGroup]="inputParamForm" (ngSubmit)="saveInputParams()">
        <div class="forgot-password-modal">
            <div class="modal-header">
                <h4 class="modal-title pull-left"> {{ inputParam.id == undefined ? "Create" : "Edit" }} label and type of value</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body pt-0 pb-0">
                <div class="form-group integration-modal">

                    <div class="row m_t_20">
                        <div class="col-6 align-self-center">
                            <div class="form-group m_0">
                                <span class="bm">Dynamic Category:</span>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group rolesSelector m_0">
                                <button class="btn p-1" type="button">
                                    <span class="info-circel color-grey" [tooltipAnimation]="true" tooltipPlacement="bottom"tooltip="The attribute will be show for selected category">
                                        <img src="assets/images/info-circle.svg " />
                                    </span>
                                </button>
                                <ng-select [items]="dynamicCategory" formControlName="category" class="params select2" bindLabel="name" [multiple]="true" placeholder="Select Dynamic Category"></ng-select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <div class=""></div>
                                <button class="btn p-1" type="button">
                                    <span class="info-circel color-grey" [tooltipAnimation]="true" tooltipPlacement="bottom"
                                        tooltip="Put your label name as simple which will be useful for filtering out your specific audience. For Example, If you want to target audience who buy green tea then you can keep label name as “Green Tea”">
                                        <img src="assets/images/info-circle.svg " />
                                    </span>
                                </button>
                                <input trim="blur" type="text" placeholder="Name" formControlName="paramName"
                                    class="form-control" maxlength="100">
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="inputParam.id == undefined || (inputParam.id != undefined && inputParam.paramEditable == 'ACTIVE')">
                        <div class="col-md-6">
                            <div class="form-group">
                                <select formControlName="paramCategory" class="form-control mr-sm-2"
                                    id="inlineFormCustomSelect">
                                    <option value="">Select Attribute Category</option>
                                    <option value="0" >Event</option>
                                    <option value="1">Attribute </option>
                                    <!-- <option value="Boolean">Boolean</option> -->
                                </select>
                                <a class="info" target="_self">What is Attributes and Events ?</a>
                                <span tooltip="Attributes:- Any entity represents the user properties i.e. Age, DOB, etc. Events:- Any action related to user i.e. Signup, Login, Logout, etc."
                                [tooltipAnimation]="true" tooltipPlacement="bottom"><img src="assets/images/info-circle.svg"></span>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <select formControlName="paramDatatype" class="form-control mr-sm-2" id="inlineFormCustomSelect">
                                    <option value="">Select Type</option>
                                    <option value="String">String</option>
                                    <option value="Numeric">Numeric</option>
                                    <option value="Date">Date</option>
                                    <option  *ngIf="inputParamForm.value.paramCategory != '0'" value="Email">Email</option>
                                    <option   *ngIf="inputParamForm.value.paramCategory != '0'" value="Mobile">Mobile</option>
                                    <option *ngIf="inputParamForm.value.paramCategory != '0'" value="File">File(max 5Mb)</option>
                                    <!-- <option value="Boolean">Boolean</option> -->
                                </select>
                            </div>
                        </div>

                        <ng-container *ngIf="inputParamForm.value.paramCategory == 0 ">
                            <div class="col-6 align-self-center">
                                <div class="custom-control custom-switch swich-button mr-2">
                                    <span class="bm">Hide attribute for role: </span>
                                </div>
                            </div>

                            <div class="col-6">
                                <div class="form-group rolesSelector">
                                    <button class="btn p-1" type="button">
                                        <span class="info-circel color-grey" [tooltipAnimation]="true" tooltipPlacement="bottom"
                                            tooltip="If no role is selected, then the attribute will be show for all roles">
                                            <img src="assets/images/info-circle.svg " />
                                        </span>
                                    </button>
                                    <ng-select [items]="userRoleListToHideInAdd" formControlName="hideUserRole" class="params select2" bindLabel="label" [multiple]="true" placeholder="Select User Role For Hide"></ng-select>
                                </div>
                            </div>
                        </ng-container>

                        <ng-container  *ngIf="inputParamForm.value.paramCategory == 1">
                            <ng-container *ngIf="inputParamForm.value.paramDatatype == 'String' || inputParamForm.value.paramDatatype == 'Numeric'">
                            <div class="col-12 align-self-center m_b_20">
                                <div class="custom-control custom-switch swich-button mr-2" >
                                    <span class="bm">Upload Default Attribute Values: </span>
                                    <input type="checkbox" formControlName="uploadDefaultValue" class="custom-control-input" id="uploadDefaultValue" (change)="fileFieldStatus($event)" />
                                    <label class="custom-control-label" for="uploadDefaultValue"></label>
                                </div>
                            </div>

                            <div class="col-12 form-group" *ngIf="!inputParamForm.value.uploadDefaultValue">
                                <textarea trim="blur" placeholder="Default Attribute value (Ex. Jaipur,Delhi,Mumbai)" formControlName="paramDefaultValue" class="form-control"></textarea>
                            </div>

                            <ng-container *ngIf="inputParamForm.value.uploadDefaultValue">
                                <div class="col-6">
                                    <span class="bm">Upload Default values:</span>
                                </div>

                                <div class="col-6 form-group" >
                                    <input class="form-control" accept=".csv" type="file" (change)="uploadDefaultValues($event)" name="csv_file_name" formControlName="csv_file_name">
                                    <input type="hidden" name="file" formControlName="file" /> 
                                </div>
                            </ng-container>
                            </ng-container>

                            <div class="col-12 align-self-center">
                                <div class="custom-control custom-switch swich-button mr-2">
                                    <span class="bm">Is Visible:</span>
                                    <input type="checkbox" formControlName="paramValueChange" class="custom-control-input" id="editableSwitche"/>
                                    <label class="custom-control-label" for="editableSwitche"></label>
                                </div>
                            </div>

                            <div class="col-6 align-self-center" *ngIf="inputParamForm.value.paramValueChange">
                                <div class="custom-control custom-switch swich-button mr-2">
                                    <span class="bm">Hide attribute for role: </span>
                                </div>
                            </div>

                            <div class="col-6" *ngIf="inputParamForm.value.paramValueChange">
                                <div class="form-group rolesSelector">
                                    <button class="btn p-1" type="button">
                                        <span class="info-circel color-grey" [tooltipAnimation]="true" tooltipPlacement="bottom"
                                            tooltip="If no role is selected, then the attribute will be show for all roles">
                                            <img src="assets/images/info-circle.svg " />
                                        </span>
                                    </button>
                                    <ng-select [items]="userRoleListToHide" formControlName="hideUserRole" class="params select2" bindLabel="label" [multiple]="true" placeholder="Select User Role For Hide" (change)="hideRoleLisUpdate($event)"></ng-select>
                                </div>
                            </div>

                            <div class="col-6 align-self-center">
                                <div class="custom-control custom-switch swich-button mr-2" *ngIf="inputParamForm.value.paramValueChange">
                                    <span class="bm">Is mandatory: </span>
                                    <input type="checkbox" formControlName="paramMandatory" class="custom-control-input" id="mandatorySwitche" (change)="mandatoryAction($event)" />
                                    <label class="custom-control-label" for="mandatorySwitche"></label>
                                </div>
                            </div>
                            <div class="col-6" *ngIf="inputParamForm.value.paramMandatory && inputParamForm.value.paramValueChange">
                                <div class="form-group rolesSelector m_0">
                                    <button class="btn p-1" type="button">
                                        <span class="info-circel color-grey" [tooltipAnimation]="true" tooltipPlacement="bottom"
                                            tooltip="If no role is selected, then the attribute will be mandatory to fill for all roles">
                                            <img src="assets/images/info-circle.svg " />
                                        </span>
                                    </button>
                                <ng-select [items]="userRoleListToAssign" formControlName="userRole" class="params select2" bindLabel="label" [multiple]="true" placeholder="Select User Role Mandatory" (change)="userRoleListToAssignUpdate()"></ng-select>
                            </div>
                            </div>

                            <div class="col-12 align-self-center" *ngIf="inputParamForm.value.paramDatatype == 'Date'">
                                <div class="custom-control custom-switch swich-button mr-2">
                                    <span class="bm">Allow Back Date: </span>
                                    <input type="checkbox" formControlName="backDataEditable" class="custom-control-input" id="backDataEditable"/>
                                    <label class="custom-control-label" for="backDataEditable"></label>
                                </div>
                            </div>

                            <div class="col-12 align-self-center m_t_10">
                                <div class="custom-control custom-switch swich-button mr-2" *ngIf="inputParamForm.value.paramValueChange">
                                    <span class="bm">Is Editable: </span>
                                    <input type="checkbox" formControlName="multipleTimeEditable" class="custom-control-input" id="multipleTimeEditable"/>
                                    <label class="custom-control-label" for="multipleTimeEditable"></label>
                                </div>
                            </div>

                        </ng-container>
                    </div>
                    
                    <div class="text-right mt-3">
                        <button class="btn btn-dark"
                            type="submit">{{inputParam.id == undefined?'Save':'Update'}}</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #templateForStatic>
    <form [formGroup]="inputParamFormFixedAttr" (ngSubmit)="saveInputParams('sysAttr')">
        <div class="forgot-password-modal">
            <div class="modal-header">
                <h4 class="modal-title pull-left"> Hide attribute for role:</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body pt-0 pb-0">
                <div class="form-group integration-modal">
                        <div><br></div>
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group rolesSelector">
                                    <button class="btn p-1" type="button">
                                        <span class="info-circel color-grey" [tooltipAnimation]="true" tooltipPlacement="bottom"
                                            tooltip="If no role is selected, then the attribute will be show for all roles">
                                            <img src="assets/images/info-circle.svg " />
                                        </span>
                                    </button>
                                    <ng-select [items]="userRoleListToHideInSysAttr" formControlName="hideUserRole" class="params select2" bindLabel="label" [multiple]="true" placeholder="Select User Role For Hide"></ng-select>
                                </div>
                            </div>  
                        </div>     
                        <div class="row">     
                    <div class="text-right mt-3 col-12">
                        <button class="btn btn-dark"
                            type="submit">{{inputParam.id == undefined?'Save':'Update'}}</button>
                    </div>
                </div>        
                </div>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #confirm>
    <!-- delete popup -->
    <form>
        <div class="forgot-password-modal">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Delete Attribute</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body modal-user-management">
                <div class="form-group mb-0">
                    <p class="pt-2">
                        Data will not be captured against <strong>{{inputParam.paramName}}</strong> attribute from any of your data forms and all data against this attribute will also be removed from all previous leads.  Are you sure want to remove? 
                    </p>
                    <div class="d-flex justify-content-end mt-5">
                        <div>
                            <button class="btn remove-user-btn mr-4" (click)="modalRef.hide()" type="button">
                                Dismiss
                            </button>
                        </div>
                        <div>
                            <button class="btn save-btn" type="button" (click)="deleteInputParams()">
                                Yes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #ecommerceForm>
    <form #eCommerceFormData="ngForm" (ngSubmit)="eCommerceFormSubmitted(eCommerceFormData.value,updateEcommerceForm)">
        <div class="forgot-password-modal">
            <div class="modal-header">
                <h4 class="modal-title pull-left">
                    Add Form
                </h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <label>Form Name</label>
                    <input type="text" class="form-control" name="formName" [(ngModel)]="eCommerceFormName" [readonly]="updateEcommerceForm == true">
                </div>

                <div class="form-group" *ngFor="let list of ecommerceFormFields">
                    <label>{{list.fieldName}}</label>
                    <input [type]="list.fieldType" class="form-control" [name]="list.fieldName" ngModel="{{list.fieldValue}}">
                </div>

                

                <div class="form-group text-right">
                    <div class="d-flex c-align j-space-between actionWrapper">
                    <div class="active_deactive">
                        <div class="custom-control custom-switch swich-button" *ngIf="updateEcommerceForm">
                            <span class="bm">Form Active: </span>
                            <input type="checkbox" [(ngModel)]="eCommerceFormDetailsStatus" name="marketplaceStatus" (change)="updateEcommFormStatus(eCommerceFormDetailsStatus)" class=" custom-control-input" id="customSwitchesEcom" />
                            <label class="custom-control-label" for="customSwitchesEcom"></label>
                        </div>
                    </div>
                    <div class="shopify_action_btn">
                    <button type="button" class="btn btn-dark" *ngIf="updateEcommerceForm == true" (click)="deleteEcomForm()">Delete</button> &nbsp; &nbsp;
                    <button type="submit" class="btn btn-dark">{{updateEcommerceForm == true ? 'Update' : 'Save'}}</button>
                </div>
                </div>
                </div>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #deleteEcommerceForm>
    <div class="forgot-password-modal">
            <div class="modal-header">
                <h4 class="modal-title pull-left">
                    Are you sure want to delete ?
                </h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body modal_height_apply">
                <div class="innerConfrm text-center">
                    <button class="btn btn-dark" (click)="deleteEcomFormYes()">Yes</button>
                    <button class="btn btn-dark red" (click)="modalRef.hide()">No</button>
                </div>
            </div>
    </div>
</ng-template>

<ng-template #excelForm>
    <form [class.deleteModal]="delete_action">
        <div class="forgot-password-modal">
            <div class="confrmtion_delete" *ngIf="delete_action">
                <div class="modal-header">
                    <h4 class="modal-title pull-left">
                        Are you sure want to delete ?
                    </h4>
                    <button type="button" class="close pull-right" aria-label="Close" (click)="delete_action = false">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body modal_height_apply">
                    <div class="innerConfrm text-center">
                        <button class="btn btn-dark" (click)="deleteXlForm(excelFormDetail)">Yes</button>
                        <button class="btn btn-dark red" (click)="delete_action = false">No</button>
                    </div>
                </div>
            </div>

            <div class="modal-header">
                <h4 class="modal-title pull-left overFlowAuto overFlow_y_hidden">
                    {{excelFormDetail?excelFormDetail.formName:'Form Name'}}
                </h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                
                <div class="form-group">
                    <label class="control-label pt-0">Alias</label>
                    <input trim="blur" class="form-control" type="text" [(ngModel)]="excelAlias" name="excelAlias" [disabled]="excelFormDetail" />
                </div>
                <ng-container *ngIf="excelFormDetail">
                    <div class="form-group" *ngIf="excelFormDetail.status == 'ACTIVE'">
                        <div class="upload_xl_outerbtn">
                        <div class="upload_xl_btn" (click)="formXlUpload(excelFormDetail)"><img src="assets/images/upload-audience-white.svg"> Upload</div>
                        </div>
                    </div>
                </ng-container>
                
                <div class="form-group text-center mt-3 bottom-border form-flex">
                    <div class="custom-control custom-switch swich-button">
                        <ng-container *ngIf="excelFormDetail">
                        <ng-container *ngIf="excelFormDetail.status">
                        <span class="bm">Form Active: </span>
                        <input type="checkbox" [(ngModel)]="marketplaceStatus" name="marketplaceStatus" (change)="updateExcelFormStatus(excelFormDetail.formName,marketplaceStatus)" class=" custom-control-input" id="customSwitches2" />
                        <label class="custom-control-label" for="customSwitches2"></label>
                        </ng-container>
                        </ng-container>
                    </div>
                    <div class="text-right">
                        <button class="btn btn-dark" (click)="delete_action = true" type="button" *ngIf="excelFormDetail"> Delete </button> &nbsp;
                        <button class="btn btn-dark" (click)="saveExcelPluginForm()" type="button" *ngIf="addNewExcel"> Save </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</ng-template>


<ng-template #basicForm>
    <form>
        <div class="forgot-password-modal">
            <div class="modal-header">
                <h4 class="modal-title pull-left">
                    {{ this.CurrentPlugin.pluginName }}
                </h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <a href="{{this.CurrentPlugin.helpLink}}" class="pull-right"
                    target="_self">{{this.CurrentPlugin.helpLabel}}</a>
                    <div class="form-group" *ngIf="CurrentPlugin.categoryName == 'Marketplace' && CurrentPlugin.multipleAdd == 'ENABLE'">
                        <label class="control-label pt-0">Alias</label>
                        <input trim="blur" #field [readonly]="!isMarketplaceCreate"
                            [(ngModel)]="marketplaceFormName" name="formName"
                            class="form-control" type="text" />
                    </div>
                <div class="form-group" *ngFor="let item of this.PluginForm">
                    <ng-container *ngIf="item.fieldType == 'text'">
                        <label class="control-label pt-0">{{ item.fieldName }}</label>
                        <input trim="blur" [pattern]="item.fieldRegex" (change)="this.con.log(item)" #field
                            [(ngModel)]="item.integrationPluginsFormsData.formFieldValue" name="{{ item.fieldName }}"
                            class="form-control" type="text" />
                    </ng-container>
                    <ng-container *ngIf="item.fieldType == 'date' && isMarketplaceCreate">
                        <label class="control-label pt-0">{{ item.fieldName }}</label>
                        <input type="text" placeholder="{{ item.fieldName }}" placement="top left" readonly class="form-control date-icon modal-space" bsDatepicker autocomplete="off" 
                        [(ngModel)]="item.integrationPluginsFormsData.formFieldValue" name="{{ item.fieldName }}" [maxDate]="toDate"
                            [bsConfig]="global.datePickerConfig">
                    </ng-container>
                    <ng-container *ngIf="item.fieldType == 'copy'">
                        <label class="control-label pt-0">{{ item.fieldName }}</label>
                        <div class="input-group">
                            <input trim="blur" [(ngModel)]="item.integrationPluginsFormsData.formFieldValue"
                                disabled="disabled" name="{{ item.fieldName }}" type="text" class="form-control" />
                            <span class="input-group-text pinkcolor mb-0 cursor-pointer"
                                (click)="copyText(item.integrationPluginsFormsData.formFieldValue)">Copy</span>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="item.fieldType == 'radio'">
                        <label class="control-label pt-0">{{ item.fieldName }}</label>
                        <input trim="blur" [(ngModel)]="item.integrationPluginsFormsData.formFieldValue"
                            name="{{ item.fieldName }}" class="form-control" type="text" />
                    </ng-container>
                    <ng-container *ngIf="item.fieldType == 'label'">
                        <label class="control-label pt-0">{{ item.fieldName }}</label>
                    </ng-container>
                </div>
                <span *ngIf="CurrentPlugin.categoryName == 'API'"><a (click)="goToCustomTab();" href="javascript:void();"> Click here </a> to add your custom attributes </span>
                <div class="text-center mt-3 bottom-border form-flex">
                    <div *ngIf="(CurrentPlugin.pluginType === 'push' && (CurrentPlugin.categoryName != 'Marketplace' || CurrentPlugin.categoryName === 'IVR') ) || (!isMarketplaceCreate)" class="custom-control custom-switch swich-button">
                        <span class="bm" *ngIf="CurrentPlugin.categoryName === 'IVR' || CurrentPlugin.categoryName === 'Marketplace' else formLabel">Form Active: </span>
                       <ng-template #formLabel>
                        <span class="bm">Plugin Active: </span>
                    </ng-template>
                            <input type="checkbox" *ngIf="CurrentPlugin.categoryName === 'IVR' || ((CurrentPlugin.categoryName == 'Marketplace')) else pluginStatus"
                             name="CurrentPluginStatus" (change)="CurrentPlugin.categoryName === 'IVR'? updateFormStatusByMS(marketplaceFormName):updatePluginStatus(marketplaceFormName)"
                            [(ngModel)]="marketplaceStatus" class=" custom-control-input" id="customSwitches" />
                        <ng-template #pluginStatus>

                            
                            <input type="checkbox"
                             name="CurrentPluginStatus" (change)="updatePluginStatus()"
                            [(ngModel)]="CurrentPlugin.status" class="custom-control-input" id="customSwitches" />
                        </ng-template>
                        <!-- <input type="checkbox" name="CurrentPluginStatus" (change)="updatePluginStatus()"
                            [(ngModel)]="CurrentPlugin.status" class="custom-control-input" id="customSwitches" /> -->
                        <label class="custom-control-label" for="customSwitches"></label>
                        <!-- <span class="by" [ngClass]="{ pink: !this.CurrentPlugin.status }">Disable</span> -->
                    </div>
                    
                    <ng-container *ngIf="CurrentPlugin.categoryName != 'API' &&  CurrentPlugin.categoryName != 'Marketplace' && CurrentPlugin.categoryName != 'IVR'" >
                        <div class="custom-control custom-switch swich-button mr-2">
                            <span class="bm">Lead updation: </span>
                            <input type="checkbox" name="CurrentPluginLead" (change)="updateLeadStatus()"
                                [(ngModel)]="CurrentPlugin.leadActiveStatus" class="custom-control-input" id="customSwitchesLead" />
                            <label class="custom-control-label" for="customSwitchesLead"></label>
                        </div>

                        <div class="custom-control custom-switch swich-button mr-5">
                            <span class="bm">Repeat Lead Assignment: </span>
                            <input type="checkbox" name="RepeatLeadAssignment" (change)="updateRepeatLeadAssignemnt()"
                                [(ngModel)]="this.CurrentPlugin.leadRepeatFlagStatus" class="custom-control-input" id="customSwitchesLeadRepeat" />
                            <label class="custom-control-label" for="customSwitchesLeadRepeat"></label>
                        </div>

                        <!-- <div class="custom-control custom-switch status">
                            <span class="bm">Is Plugin Subscribed: </span>
                            <span class="tag" [style.background-color]="CurrentPlugin.pluginSub === 'ENABLE' ? '#3dc151' : '#adb5bd'"></span>
                        </div> -->
                    </ng-container>
                    <div *ngIf="CurrentPlugin.multipleAdd != 'ENABLE' || isMarketplaceCreate"></div>
                    <div *ngIf="CurrentPlugin.categoryName == 'Marketplace'" class="text-right">
                        <button class="btn btn-dark" (click)="modalRef.hide(); openTemplateModal(marketplaceDeleteConfirm)" type="submit" *ngIf="CurrentPlugin.categoryName == 'Marketplace' && CurrentPlugin.multipleAdd == 'ENABLE' && !isMarketplaceCreate"> Delete </button> &nbsp;
                        <button class="btn btn-dark" (click)="savePluginFormData()" type="submit"> {{ CurrentPlugin.submitButtonName }} </button>
                    </div>
                </div>
                <div class="text-right" *ngIf="CurrentPlugin.categoryName != 'Marketplace'">
                    <button class="btn btn-dark" (click)="savePluginFormData()" type="submit"> {{ CurrentPlugin.submitButtonName }} </button>
                </div>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #configurationType>
    <ng-container *ngIf="confirmUpdateCategory">
        <div class="leadupdateConfirmModal updateAllPlugin text-center">
        <div class="innerBoxUpdateModal">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Are you sure want to update ?
                    <ng-container *ngIf="updateProp == 'categoryUpdate'">
                    <div class="rules_reset_msg">All rules will reset for this form</div>
                    </ng-container>
                </h4>
                <!-- <button type="button" class="close pull-right" (click)="confirmUpdateCategory = false">
                <span aria-hidden="true">&times;</span>
            </button> -->
            </div>
            <div class="modal-body">
                <div>
                <div>
                    <button class="btn btn btn-dark red" (click)="confirmUpdateCategory = false; resetUpdatePlugin()">No</button>
                    <button class="btn btn btn-dark green" (click)="confirmUpdatePlugin()">Yes</button>
                </div>
                </div>
            </div>
        </div>
        </div>
    </ng-container>
        <div class="forgot-password-modal">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Configuration</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="d-flex c-align j-space-between">
                <div class="custom-control custom-switch swich-button projct_intgrtion_cstm_switch">
                <div [class.activeSwitch]="formDetailData.leadActive == 'ACTIVE'">
                    <span class="bm">Lead Updation: </span>
                    <input type="checkbox" [(ngModel)]="leadUpdateStatus" [checked]="formDetailData.leadActive == 'ACTIVE'" (change)="confirmUpdateCategory =true; updateProp = 'updateStatus'" class=" custom-control-input" id="customSwitchesLeadUpdate" [ngModelOptions]="{standalone: true}" />
                    <label class="custom-control-label" for="customSwitchesLeadUpdate"></label>
                </div>
                <div [class.activeSwitch]="formDetailData.leadRepeatFlag == 'ACTIVE'">
                    <span class="bm">Repeat Lead Assignment: </span>
                    <input type="checkbox" [(ngModel)]="repeatLeadStatus" [checked]="formDetailData.leadRepeatFlag == 'ACTIVE'" (change)="confirmUpdateCategory =true; updateProp = 'repeatLead'" class=" custom-control-input" id="customSwitchesLeadRepeat" [ngModelOptions]="{standalone: true}" />
                    <label class="custom-control-label" for="customSwitchesLeadRepeat"></label>
                </div>
                </div>

                <div>
                    <select class="form-control catSelectroForm" [(ngModel)]="categorySelector" [ngModelOptions]="{standalone: true}" (change)="confirmUpdateCategory = true; updateProp = 'categoryUpdate'">
                        <option *ngFor="let category of dataList" [value]="category.linkRewrite">{{category.name}}</option>
                    </select>
                </div>

                </div>
            </div>
        </div>
</ng-template>

<ng-template #googleform>
    <form>
        <div class="forgot-password-modal">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Google Form</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body fix-1050">
                <div class="text-right" *ngIf="googleFormItem.status == 'ACTIVE'"><a href="/integration/#google" target="_self">Integeration Guide</a></div>
                <div class="row" *ngIf="googleFormItem.status == 'ACTIVE'">
                    <div class="col-md-12">
                        <div class="row form-align">
                            <div class="col-md-6">
                                <div class="form-group mb-0">
                                    <label class="control-label pb-0">Cronberry Fields</label>
                                </div>
                            </div>
                            <div class="col-md-6 pr-0">
                                <div class="form-group mb-0">
                                    <label class="control-label pb-0">Google Fields</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12" *ngFor="let items of FormsMapping; let i = index">
                        <div class="row form-align">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <select class="form-control" [(ngModel)]="items.paramId" [ngModelOptions]="{standalone: true}" [disabled]="googleFormItem.status == 'INACTIVE'" (change)="googleFormMap($event.target.value)">
                                        <option value="0">Select Field</option>
                                        <ng-container *ngFor="let item of showMapParams">
                                            <option *ngIf="item.paramVisible == 1" [value]="item.id" [disabled]="item.disableAttr"> {{item.paramName}} <p class="d-inline" *ngIf="(item.paramKey=='city' || item.paramKey=='state' || 
                                                item.paramKey=='message' || item.paramKey=='country' || item.paramKey=='pincode' || item.paramKey=='city' || item.paramKey=='product_name' ||  item.paramKey=='address') ">(default)</p></option>
                                        </ng-container>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input disabled class="form-control" type="text" [(ngModel)]="items.mapValue" [ngModelOptions]="{standalone: true}" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="text-center mt-3 bottom-border form-flex">
                    <div class="googleFormTools custom-control custom-switch swich-button">
                        <ng-container *ngIf="googleFormItem.status">
                            <div>
                            <span class="bm">Form Active: </span>
                            <input [(ngModel)]="activeInactiveStatus" [ngModelOptions]="{standalone: true}" type="checkbox" [checked]="googleFormItem.status == 'ACTIVE'" (change)="confirmUpdateCategory =true; updateProp = 'active'" class=" custom-control-input" id="customSwitches4" />
                            <label class="custom-control-label" for="customSwitches4"></label>
                            </div>
                        </ng-container>
                        </div>
                    <div>
                    <button class="btn btn btn-dark m_r_5" (click)="modalRef.hide(); openTemplateModal(googleFormDeleteConfirm)" type="submit">Delete</button>
                    <button class="btn btn btn-dark" (click)="saveGoogleFormsMapping()" type="submit">{{googleFormButton}}</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #googleFormAdd>
    <form>
        <div class="forgot-password-modal">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Google Form</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <label class="control-label pt-0">Form Name</label>
                    <input  trim="blur" type="text" class="form-control" [(ngModel)]="googleFormName" name="googleFormName" maxlength="50" />
                </div>
                <div class="text-center mt-3 bottom-border form-flex">
                    <div></div>
                    <button class="btn btn btn-dark" [disabled]="isGoogleFormButton" (click)="CreateGoogleForm();" type="submit">Submit</button>
                </div>
            </div>
        </div>
    </form>
</ng-template>


<ng-template #googleformMsg>
    <div class="forgot-password-modal">
        <div class="modal-header">
            <h4 class="modal-title pull-left">Google Form</h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="getGoogleFormList(); modalRef.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="form-group">
                <label class="control-label pt-0">Form Key</label>
                <div class="input-group">
                    <input trim="blur" disabled="disabled" value="{{googleFormCreateData.formKey}}" type="text" class="form-control" />
                    <span class="input-group-text pinkcolor mb-0 cursor-pointer"
                        (click)="copyText(googleFormCreateData.formKey)">Copy</span>
                </div>
            </div>
            <div class="form-group mb-0">
                <p><strong>Form Name:</strong> {{googleFormCreateData.formName}}</p>
                <p>{{googleFormCreateData.description}}</p>
            </div>
            <div class="text-center mt-3 bottom-border form-flex">
                <div>
                    <button class="btn btn btn-dark" (click)="modalRef.hide(); openTemplateModal(googleFormDeleteConfirm)" type="submit">Delete</button> &nbsp; 
                    <button class="btn btn btn-dark float-right" (click)="getGoogleFormList();modalRef.hide()" type="submit">Close</button>
                </div>
            </div>
            
        </div>
    </div>
</ng-template>

<ng-template #googleFormDeleteConfirm>
    <!-- delete popup -->
    <form>
        <div class="forgot-password-modal">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Delete Google Form</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body modal-user-management">
                <div class="form-group mb-0">
                    <p class="pt-2">
                        Leads from this form will not be captured in cronberry after you delete it. Are you sure, you want to delete this form ?
                    </p>
                    <div class="d-flex justify-content-end mt-5">
                        <div>
                            <button class="btn remove-user-btn mr-4" (click)="modalRef.hide()" type="button">
                                Dismiss
                            </button>
                        </div>
                        <div>
                            <button class="btn save-btn" type="button" (click)="deleteGoogleForm()">
                                Yes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #shopify>
    <form>
        <div class="forgot-password-modal">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Shopify</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                Status : 
                <span *ngIf="this.CurrentPlugin.pluginStatus == 'ENABLE'">Active</span> 
                <span *ngIf="this.CurrentPlugin.pluginStatus == 'DISABLE'">Inactive</span>
                <a href="{{this.CurrentPlugin.helpLink}}" class="pull-right"
                    target="_self">{{this.CurrentPlugin.helpLabel}}</a>
                <div class="form-group">
                    <label *ngIf="this.CurrentPlugin.pluginStatus == 'DISABLE'">
                        Now get customer data from your Shopify store and engage them with cronberry. Buy Shopify Plugin from Shopify app store: <a href="https://apps.shopify.com/cronberry-marketing-automation" target="_self">https://apps.shopify.com/cronberry-marketing-automation</a>
                    </label>
                    <label *ngIf="this.CurrentPlugin.pluginStatus == 'ENABLE'">
                        Your plugin is already activated.
                    </label>
                </div>

                <div class="text-center mt-3 bottom-border form-flex">
                    <div>
                        <div class="custom-control custom-switch swich-button">
                            <span class="bm">Plugin Active: </span>
                            <input type="checkbox"
                                name="CurrentPluginStatus" (change)="updatePluginStatus()"
                            [(ngModel)]="CurrentPlugin.status" class="custom-control-input" id="customSwitchesPlugin" />
                            <label class="custom-control-label" for="customSwitchesPlugin"></label>
                        </div>
                        <div class="custom-control custom-switch swich-button mr-5">
                            <span class="bm">Lead updation: </span>
                            <input type="checkbox" name="CurrentPluginLead" (change)="updateLeadStatus()"
                                [(ngModel)]="this.CurrentPlugin.leadActiveStatus" class="custom-control-input" id="customSwitchesLead" />
                            <label class="custom-control-label" for="customSwitchesLead"></label>
                        </div>

                        <div class="custom-control custom-switch swich-button mr-5">
                            <span class="bm">Repeat Lead Assignment: </span>
                            <input type="checkbox" name="RepeatLeadAssignment" (change)="updateRepeatLeadAssignemnt()"
                                [(ngModel)]="this.CurrentPlugin.leadRepeatFlagStatus" class="custom-control-input" id="customSwitchesLeadRepeat" />
                            <label class="custom-control-label" for="customSwitchesLeadRepeat"></label>
                        </div>

                        <!-- <div class="custom-control custom-switch status">
                            <span class="bm">Is Plugin Subscribed: </span>
                            <span class="tag" [style.background-color]="CurrentPlugin.pluginSub === 'ENABLE' ? '#3dc151' : '#adb5bd'"></span>
                        </div> -->
                    </div>
                    
                    <button class="btn btn btn-dark float-right" (click)="savePluginFormData()" type="submit">
                        Close
                    </button>
                </div>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #customForm>
    <ng-container *ngIf="confirmUpdateCategory">
        <div class="leadupdateConfirmModal updateAllPlugin text-center">
        <div class="innerBoxUpdateModal">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Are you sure want to update ?</h4>
            </div>
            <div class="modal-body">
                <div>
                <div>
                    <button class="btn btn btn-dark red" (click)="confirmUpdateCategory = false; resetUpdatePlugin()">No</button>
                    <button class="btn btn btn-dark green" (click)="confirmUpdatePlugin()">Yes</button>
                </div>
                </div>
            </div>
        </div>
        </div>
    </ng-container>
    <div class="forgot-password-modal">
        <div class="modal-header">
            <h4 class="modal-title pull-left overFlowAuto overFlow_y_hidden d-flex c-align">
                <b>{{customFormData.name}}</b>
            </h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
         <div class="row" *ngIf="customFormData.status == 'ACTIVE'">
            <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                <div *ngIf="customFormData.isPublic==1" class="text-center">    
                    <qr-code [value]="customFormData.url" [size]="130"></qr-code>
                    <qr-code class="d-none" [size]="500" id="qrScanner" [value]="customFormData.url"></qr-code>
                    <ng-container *ngIf="customFormData.status == 'ACTIVE' && customFormData.isPublic==1">
                        <div>
                            <button class="btn btn-dark cstm_frm_action_" type="button" (click)="downloadQr()" tooltip="Download QR code" tooltipPlacement="top"><i class="icon-download"></i></button>
                            <button class="btn btn-dark cstm_frm_action_" type="button" (click)="entrFormName()" tooltip="Copy form" tooltipPlacement="top">
                                <img src="assets/images/copy-white.png">
                            </button>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
            <div class="form-group"  *ngIf="customFormData.isPublic==1">
                <label class="control-label pt-0">Form Embed code</label>
                <div class="input-group">
                    <input trim="blur" disabled="disabled" value="{{customFormData.iframe}}" type="text" class="form-control" />
                    <span class="input-group-text pinkcolor mb-0 cursor-pointer" (click)="copyText(customFormData.iframe)">Copy</span>
                </div>
            </div>
            <div class="form-group"  *ngIf="customFormData.isPublic==1">
                <label class="control-label pt-0">Form Link</label>
                <div class="input-group">
                    <input trim="blur" disabled="disabled" value="{{customFormData.url}}" type="text" class="form-control" />
                    <span class="input-group-text pinkcolor mb-0 cursor-pointer" (click)="copyText(customFormData.url)">Copy</span>
                </div>
            </div>
           </div>
        </div>
        <div class="row form_activ_deactive">
            <div class="col-lg-4 col-md-4">
                <ng-container *ngIf="customFormData.status">
                    <div [class.activeSwitch]="customFormData.status == 'ACTIVE'" class="custom-control custom-switch swich-button">
                        <span class="bm">Form Active: </span>
                        <input type="checkbox" [(ngModel)]="activeInactiveStatus" [checked]="customFormData.status == 'ACTIVE'" (change)="confirmUpdateCategory =true; updateProp = 'active';" class=" custom-control-input" id="customSwitches3" />
                        <label class="custom-control-label" for="customSwitches3"></label>
                    </div>
                </ng-container>
            </div>
            
            <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
               <div class="categoryDropdown mobile_mt_20 d-flex c-align">
                <button *ngIf="isShortUrlSubscribe && customFormData.isPublic==1" class="btn btn btn-dark" (click)="getShortUrl(customFormData.url)">Short URL</button>
                <a class="btn btn btn-dark edit_margin" (click)="modalRef.hide();" [routerLink]="['/admin/custom-form/'+customFormData.id]">Edit</a>
               <button class="btn btn btn-dark" (click)="modalRef.hide(); openTemplateModal(customFormDeleteConfirm)">Delete</button>
                </div>
            </div>
            </div>
        </div>

        <div class="form_copy_name_field" *ngIf="formNameOpener">
            <span class="modal_closer" (click)="formNameOpener = false">&times;</span>
            <div class="formNameOuterBox">
            <div class="form-group">
                <input type="text" class="form-control" placeholder="Form Name" #formCopyName>
            </div>
            <div class="form-group form_name_submit">
                <button class="btn btn btn-dark" (click)="formCopySubmit(formCopyName.value)">Submit</button>
            </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #customFormDeleteConfirm>
    <!-- delete popup -->
    <form>
        <div class="forgot-password-modal">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Delete Form</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body modal-user-management">
                <div class="form-group mb-0">
                    <p class="pt-2">
                        Are you sure, you want to delete this form?
                    </p>
                    <div class="d-flex justify-content-end mt-5">
                        <div>
                            <button class="btn remove-user-btn mr-4" (click)="modalRef.hide()" type="button">Dismiss</button>
                        </div>
                        <div>
                            <button class="btn save-btn" type="button" (click)="deleteCustomForm()">Yes</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #marketplaceDeleteConfirm>
    <!-- delete popup -->
    <form>
        <div class="forgot-password-modal">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Delete Marketplace</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body modal-user-management">
                <div class="form-group mb-0">
                    <p class="pt-2">
                        Are you sure, you want to delete this Marketplace?
                    </p>
                    <div class="d-flex justify-content-end mt-5">
                        <div>
                            <button class="btn remove-user-btn mr-4" (click)="modalRef.hide()" type="button">Dismiss</button>
                        </div>
                        <div>
                            <button class="btn save-btn" type="button" (click)="deletePluginFormData()">Yes</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #leadUpdationByUser>
    <div *ngIf="updateConfirmModal" class="updateConfirmModal">
        <div class="forgot-password-modal">
            <div>
            <div class="modal-header">
                <h4 class="modal-title pull-left">Are you sure want to update ?</h4>
                <button type="button" class="close pull-right" (click)="cancelUpdateLeadAssign()">
                  <span aria-hidden="true">&times;</span>
              </button>
            </div>
      
      
      
            <div class="modal-body">
                <div>
                <div>
                    <button class="btn btn btn-dark red" (click)="cancelUpdateLeadAssign()">No</button>
                    <button class="btn btn btn-dark green" (click)="updateLeadStatusByUser()">Yes</button>
                </div>
                </div>
            </div>
            </div>
        </div>
      </div>

    <div class="forgot-password-modal leadUpdationModal" [class.lead-updation-modal]="updateConfirmModal == false" [class.autoAdjust]="updateConfirmModal == true">
        <div class="modal-header">
            <h4 class="modal-title pull-left">Lead Updation</h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="form-row">
                <div class="col-12 form-group" *ngIf="!uploadableSelected">
                    <label class="control-label pt-0">User List</label>
                    <ng-select [items]="subUserList" class="params userListMultiSelect" bindLabel="userProfileName" [multiple]="true" bindValue="id" [(ngModel)]="leadUpdateValue.userList"> </ng-select>
                </div>
                <div class="col-lg-6 form-group align-self-center">
                    <div class="custom-control custom-switch swich-button">
                        <span class="bm">Lead assign by Attribute:</span>
                        <input type="checkbox" [(ngModel)]="leadUpdateValue.isAssignAttribute" class="custom-control-input" id="mandatorySwitche" (change)="checkLeadAssignByAttr(leadUpdateValue)"/>
                        <label class="custom-control-label" for="mandatorySwitche"></label>
                    </div>
                </div>
                <div class="col-lg-6 form-group" *ngIf="leadUpdateValue.isAssignAttribute">
                    <div class="row">
                        <div class="col-lg-6 form-group">
                            <select class="form-control" [(ngModel)]="leadUpdateValue.paramId" [ngModelOptions]="{standalone: true}" (change)="getAttributeValue($event)">
                                <option value="0">Select Field</option>
                                <ng-container *ngFor="let item of inputParams">
                                    <option *ngIf="item.pluginName != 'static' && item.paramDatatype != 'Date'" [value]="item.paramKey">{{item.paramName}} <p class="d-inline" *ngIf="(item.paramKey=='city' || item.paramKey=='state' || 
                                        item.paramKey=='message' || item.paramKey=='country' || item.paramKey=='pincode' || item.paramKey=='city' || item.paramKey=='product_name' ||  item.paramKey=='address') ">(default)</p></option>
                                </ng-container>
                            </select>
                        </div>
                        <div class="col-lg-6 form-group">
                            <ng-container *ngIf="paramObjCheckOperator.paramDatatype == 'Numeric'">
                                <select class="form-control" (change)="operatorChange()" [(ngModel)]="leadUpdateValue.operator">
                                    <option value="GREATER_THEN_EQUAL">≥ Greater Than Equal</option>
                                    <option value="LESS_THEN_EQUAL">≤ Less Than Equal</option>
                                    <option value="EQUAL">= Equal</option>
                                    <option value="NOT_EQUAL">!= Not Equal</option>
                                    <option value="GREATER_THEN">> Greater Than</option>
                                    <option value="LESS_THEN">< Less Than</option>
                                    <option value="BETWEEN">Between</option>
                                    <!-- <option value="between">Between</option> -->
                                  </select>
                            </ng-container>
                            <ng-container *ngIf="paramObjCheckOperator.paramDatatype == 'String' || paramObjCheckOperator.paramDatatype == 'string' || paramObjCheckOperator.paramDatatype == 'Email' || paramObjCheckOperator.paramDatatype == 'Mobile'">
                                <select class="form-control" [(ngModel)]="leadUpdateValue.operator" (change)="operatorChange($event)">
                                    <option value="IN">IN</option>
                                    <option value="NOT_IN">Not IN</option>
                                    <option value="CONTAIN">Contains</option>
                                </select>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="leadUpdateValue.isAssignAttribute">
                <div class="mapping-list">
                    <ng-container *ngIf="uploadableSelected && CurrentPlugin.pluginStatus == 'ENABLE'">
                        <form [formGroup]="attributeCsvUpload" (ngSubmit)="saveAttributeCsv(attributeCsvUpload)">
                        <div class="form-row mt-3 mt-3">
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                               <a href="javascript:void(0)" (click)="downloadDataCsv()">Download File</a> 
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 text-right">
                                <input class="form-control modal-space pull-right file_input_width f-right" accept=".csv" type="file" (change)="onFileSelect($event)" name="file_name" formControlName="file_name">
                                <input type="hidden" name="file" formControlName="file" /> 
                            </div>
                        </div>

                        <div class="text-center mt-3 bottom-border form-flex">
                            <div></div>
                            <div>
                                <button class="btn btn btn-dark" type="submit">Submit</button>
                            </div>
                        </div>
                        </form>
                    </ng-container>
                    <ng-container *ngIf="!uploadableSelected">
                    <div class="form-row mt-3" *ngFor="let param of leadUpdateValue.paramMappingDTOs; let i = index">
                        <div class="col-lg-4 form-group">
                            <ng-container *ngIf="paramObjCheckOperator.paramDatatype == 'String' || paramObjCheckOperator.paramDatatype == 'string' || paramObjCheckOperator.paramDatatype == 'Email' || paramObjCheckOperator.paramDatatype == 'Mobile'">
                            <ng-container *ngIf="leadUpdateValue.operator == 'CONTAIN'">
                            <ng-autocomplete autocomplete="off" (keydown)="containValUpdate($event)" [(ngModel)]="param.paramValue" class="form-control" [data]="attributeParamValues" [searchKeyword]="keyword" [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
                            </ng-autocomplete>
                            </ng-container>

                            <ng-container *ngIf="catDataLoad == false">
                            <ng-container *ngIf="leadUpdateValue.operator == 'IN' || leadUpdateValue.operator == 'in'">
                                <ng-select [ngModelOptions]="{standalone: true}"  [ngClass]="" [items]="postDataFilter.values$ | async" bindLabel="paramValue" [multiple]="true" [hideSelected]="true"
                                [typeahead]="postDataFilter.valueInput$" [(ngModel)]="param.paramValue" >
                                <option *ngFor="let item of attributeParamValues" [value]="item.paramValue" [attr.disabled]="isOptionDisable(item.paramValue) ? 'disabled' : null">{{item.paramValue}}</option>    
                            </ng-select>
                            </ng-container>

                            <ng-container *ngIf="leadUpdateValue.operator == 'NOT_IN'">
                                <ng-select [ngModelOptions]="{standalone: true}"  [ngClass]="" [items]="postDataFilter.values$ | async" bindLabel="paramValue" [multiple]="true" [hideSelected]="true"
                                 [typeahead]="postDataFilter.valueInput$" [(ngModel)]="param.paramValue" >
                                 <option *ngFor="let item of attributeParamValues" [value]="item.paramValue" [attr.disabled]="isOptionDisable(item.paramValue) ? 'disabled' : null">{{item.paramValue}}</option>
                                </ng-select>
                            </ng-container>
                            </ng-container>
                            <ng-container *ngIf="catDataLoad && (leadUpdateValue.operator == 'NOT_IN' || leadUpdateValue.operator == 'IN' || leadUpdateValue.operator == 'in')">
                                <ng-select [ngModelOptions]="{standalone: true}" [items]="postDataFilter.values" bindLabel="paramValue" [multiple]="true" [(ngModel)]="param.paramValue" [isOpen]="!postDataFilter.values.length ? false : null">
                                </ng-select>
                            </ng-container>

                            </ng-container>
                            <ng-container *ngIf="paramObjCheckOperator.paramDatatype == 'Numeric'">
                            <ng-container>
                                <input type="text" class="form-control" [(ngModel)]="param.paramValue" onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.charCode == 44 || event.charCode == 46 ' (keydown)="containValUpdate($event,'betweenParam')">
                            </ng-container>
                            </ng-container>

                        </div>
                        <ng-container *ngIf="leadUpdateValue.operator">
                        <div class="col-lg-6 form-group">
                            <ng-select [items]="subUserList" class="params mb-0" bindLabel="userProfileName" [multiple]="true" bindValue="id" [(ngModel)]="param.userList" placeholder="Select Users" [ngClass]="{'invalid':((param.otherUserList == undefined || param.otherUserList.length == 0) && param.userList.length == 0 && leadUpdateValue.paramMappingDTOs.length != i + 1)}"></ng-select>
                        </div>
                        <div class="col-lg-2 action text-md-center text-right">
                            <button *ngIf="leadUpdateValue.paramMappingDTOs.length == i + 1" class="btn btn-sm btn-outline-success" (click)="addAttributeParam('add')">Add</button>
                            <button *ngIf="leadUpdateValue.paramMappingDTOs.length != i + 1" class="btn btn-sm btn-outline-danger" (click)="leadUpdateValue.paramMappingDTOs.splice(i,1)">Remove</button>
                        </div>
                    </ng-container>
                    </div>
                    <ng-template #noParamValue>
                        <div class="text-center no-record">Data not Available</div>
                    </ng-template>
                    </ng-container>
            </div>
            
            </ng-container>
            <ng-container *ngIf="!uploadableSelected">
                <div class="text-center mt-3 bottom-border form-flex">
                    <div></div>
                    <div>
                        <button class="btn btn btn-dark" (click)="confirmUpdate()" type="submit">Update</button> &nbsp; 
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</ng-template>


