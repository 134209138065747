import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../../core/services/authentication.service';
declare var $: any;
@Component({
  selector: 'static-header',
  templateUrl: './static-header.component.html', 
  styleUrls: ['./static-header.component.scss']
})


export class StaticHeaderComponent implements OnInit {
  isLogin: boolean = false;
constructor(
  private _router: Router,
  private authenticationService: AuthenticationService
) {}

  ngOnInit() {
    this.isLogin = localStorage.getItem('userId') != undefined && localStorage.getItem('userId') != null;
  }

  goToPosition(item: string) {
    let currentUrl = this._router.url;
    if(currentUrl!='/'){
      this._router.navigate(['']);
      setTimeout(()=>{ 
        var position = $("#" + item).position()
        window.scrollTo({ left: 0, top: position.top, behavior: 'smooth' }); 
      }, 500)
    }else{
      var position=$("#"+item).position()
       window.scrollTo({ left: 0, top: position.top, behavior: 'smooth' });
      }
  }

  openMenu() {
    $(".menu").slideToggle("slow");
  }
  
  gotoDashboard(){
    this._router.navigate(['/admin/'+this.authenticationService.getLandingPage()]);
  }

}
