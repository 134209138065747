<main>
    <section class="inner-page">
        <div class="inner-page-header">
            <div class="container-fluid">
                <h4>
                    <a class="back-link" (click)="back()"><img src="assets/images/angle-left.svg"></a>
                    Landing Page
                </h4>
            </div>
        </div>

        <div class="inner-page-body border-bottom-0">
            <div *ngIf="isSubscribe" class="container-fluid">
                <form *ngIf="!pageUnderProcess && domainData == undefined" class="site-form row" [formGroup]="siteForm">
                    <div class="form-group col-md-8">
                        <label>Enter Your subdomain Name</label>
                        <div class="input-group mb-3">
                            <input trim="blur" type="text" formControlName="domain" class="form-control" maxlength="50" (input)="resetForm();">
                            <span>{{domainSuffix}}</span>
                            <div class="input-group-append">
                                <button *ngIf="!isDomainChack" class="btn btn-dark" (click)="checkDomain()" type="button">Check Now</button>
                                <button *ngIf="isDomainChack" class="btn btn-dark btn-success" type="button">Available</button>
                            </div>
                        </div>
                        <div *ngIf="domainError!=''" class="form-error">{{domainError}}</div>
                    </div>
                    <br><br><br><br><br><br><br><br><br><br><br><br>
                    <div class="text-right col-md-12"><button class="btn btn-dark" [disabled]="!isDomainChack" (click)="showConfirmModal()" >Proceed</button></div>
                </form>

                <div *ngIf="!pageUnderProcess && domainData != undefined" class="site-page row">
                    <div class="col-md-8">
                        <ul class="info-list">
                            <li>
                                <div class="label">URL :</div>
                                <input type="text" class="form-control" value="{{domainData.domain}}" readonly>
                            </li>
                            <li>
                                <div class="label">ID:</div>
                                {{domainData.shopId}}
                            </li>
                            <li>
                                <div class="label">Create Date :</div>
                                {{domainData.startDate | date }}
                            </li>
                            <li>
                                <div class="label">Expire Date :</div>
                                {{domainData.expiryDate | date }}
                            </li>
                        </ul>
                        <br><br><br>
                        <div class="text-right"><button *ngIf="domainData.status == 'APPROVED'" (click)="opneLandingPage()" class="btn btn-dark">Open</button></div>
                    </div>
                </div>
            </div>
            <app-page-disable *ngIf="!isSubscribe"></app-page-disable>
        </div>

        <div *ngIf="pageUnderProcess" class="domain-create-loader">
            <div class="loader">
                <div class="la-ball-clip-rotate la-2x"><div></div></div>
                <h3>Loading landing page configurations.</h3>
            </div>
        </div>
        
    </section>
</main>

<ng-template #confirmModal>
    <div class="modal-body confirm-modal">
        <h3>{{this.siteForm.value.domain}}.{{domainSuffix}}</h3>
        <p>Are you sure want to take this subdomain. You will not be able to change this in the future.</p>
        <div class="text-center">
            <button class="btn mr-4" (click)="modalRef.hide()" type="button">No</button>
            <button class="btn save-btn" type="button" (click)="domainConfirm()">Yes, I accept</button>
        </div>
    </div>
</ng-template>