import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';

interface UserSessionModel {
  ipAddress: string;
  userAgent: string;
  sessionId: string;
  updated: string;
}

@Component({
  selector: 'app-loggedin-session',
  templateUrl: './loggedin-session.component.html',
  styleUrls: ['./loggedin-session.component.scss']
})


export class LoggedinSessionComponent implements OnInit {
  userSessionList: UserSessionModel[] = [];

  constructor(
    private _authService:AuthenticationService,
    private toastr:ToastrService,
    private location:Location
    ) { }

  ngOnInit() {
    this.loadAllSessions();
  }

  goToback()
  {
    this.location.back();
  }

  logoutSession(sessionId: string): void {
    this._authService.logoutSessionRequest({ loginAttemptId: sessionId }).subscribe(data => {

      if (data.status) {
        this.toastr.success("User Session Logout Successfully");
        this.loadAllSessions();
      } else {
        this.toastr.error(data.error_msgs, 'Error');
      }
    })
  }

  logoutAllSession(): void {
    this._authService.logoutSessionRequest({}).subscribe(data => {

      if (data.status) {
        this.toastr.success("All Session Logout Successfully");
        window.open("/", "_self");
      } else {
        this.toastr.error(data.error_msgs, 'Error');
      }
    })
  }

  private loadAllSessions() {

    this._authService.getAllSessionList({}).subscribe(data => {

      if (data.status) {
        const responseData = data.data;
        this.userSessionList = responseData;
        console.log("responseData ", responseData);
      } else {
        this.toastr.error(data.error_msgs, 'Error');
      }
    })
  }

}
