import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, NgZone } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ProjectService } from '../../../../core/services/project.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SpinnerService } from '../../../../core/services/spinner.service';
import { AttributeObj } from '../../../../core/modal/project';
declare var FB: any;

@Component({
    selector: 'manage-plugin-campaign',
    templateUrl: './manage-plugin-campaign.component.html',
    styleUrls: ['./manage-plugin-campaign.component.scss']
})

export class ManagePluginCampaignsComponent implements OnInit, OnDestroy {
    isShowCampaign: boolean;
    isShowForm: boolean;
    modalRef: BsModalRef;
    pageList: any[];
    campaignList: any[];
    waitCount: any = 2;
    campaignFields: any[];
    accessToken: string;
    AllInputParams: AttributeObj[] = [];
    mappingInputParams: AttributeObj[] = [];
    isLavel1: boolean;
    isLavel2: boolean;
    isLavel3: boolean;
    previous: string = "";
    next: string = "";
    noCampaignList: boolean;
    noCampaignFields: boolean;
    fbConfigData: any = {};
    isUpateRequest: boolean;
    campaignId: number;
    name: string;
    isGoogleForm: boolean;
    isFacebook: boolean;
    isFacebookRefresh: boolean;

    @ViewChild('facebook') facebook: TemplateRef<any>;
    @ViewChild('googleForm') googleForm: TemplateRef<any>;
    @ViewChild('facebookDeleteConfirm') facebookDeleteConfirm: TemplateRef<any>;
    constructor(
        private modalService: BsModalService,
        private spinner: SpinnerService,
        private ngZone: NgZone,
        private projectService: ProjectService,
        private toastr: ToastrService,
        private router: Router,
        private route: ActivatedRoute,
    ) {

    }

    ngOnDestroy() {

    }

    ngOnInit() {
        this.isLavel1 = false;
        this.isLavel2 = false;
        this.isLavel3 = false;
        this.noCampaignList = false;
        this.noCampaignFields = false;
        this.isShowCampaign = false;
        this.isShowForm = false;
        this.isUpateRequest = false;
        this.isGoogleForm = false;
        this.isFacebook = false;
        this.isFacebookRefresh=false;
        this.pageList = [];
        this.campaignList = [];
        this.campaignFields = [];

        this.route.params.subscribe(params => {
            if (params['type'] === 'facebook'  ) {
                this.isFacebook = true;
            }else if( params['type'] === 'facebookrefresh-token'){
                this.isFacebook = true;
                this.isFacebookRefresh = true;
            } else {
                this.router.navigate(['/admin/project-integration/Social Media/Facebook']);
            };
            if (params['id']) {
                this.isUpateRequest = true;
                this.campaignId = params['id'];
                this.name = params['id'];
            }
        });


        if (this.isFacebookRequest()) {
            this.facebookAsyncInit();
            this.openModalfacebook();
            // this.projectService.getFBAuthToken({ projectId: localStorage.getItem('projectId') }).subscribe(data => {
            //     if(data.status){
            //         this.getFbPageList(data.data.fbAuthToken);
            //     } else {
            //         this.openModalfacebook();
            //     }
            // });

        }

        if (this.isFacebookUpdatedRequest()) {
            this.fetchAllInputParams();
            this.getfacebookFormMapping();
        }

    }

    isFacebookRequest() {
        return !this.isUpateRequest && this.isFacebook;
    }

    isFacebookUpdatedRequest() {
        return this.isUpateRequest && this.isFacebook;
    }

    showCampaign() {
        this.isShowCampaign = true;
    }

    showForm() {
        this.isShowForm = true;
    }

    openModalfacebook() {
        this.modalRef = this.modalService.show(this.facebook, {
            backdrop: 'static'
        });
    }

    facebookFormDeleteConfirm() {
        this.modalRef = this.modalService.show(this.facebookDeleteConfirm, {
            backdrop: 'static'
        });
    }

    openModalGoogleForm() {
        this.modalRef = this.modalService.show(this.googleForm, {
            backdrop: 'static'
        });
    }

    facebookAsyncInit() {
        (window as any).fbAsyncInit = function () {
            FB.init({
                appId: '650726799076194',
                xfbml: false,
                version: 'v19.0'
            });
        };
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    }

    facebookLogin() {
        this.spinner.show();
        FB.login((response) => {
            this.getFbPageList(response);
            this.projectService.saveFBAuthToken({ fbAuthToken: response.authResponse.accessToken }).subscribe(data => {
              
                if(data.data == 'Success' && this.isFacebookRefresh){
                    
                    this.router.navigate(['/admin/project-integration/Social Media/Facebook']);
                    this.toastr.success("Facebook token is successfully refreshed and stored.");
                }
            });
        }, { scope: 'business_management,ads_management,pages_show_list,leads_retrieval,pages_manage_metadata,pages_manage_ads,pages_read_engagement,pages_read_user_content' });
    }

    getFbPageList(response) {
        FB.api('/me/accounts', (response) => {
            this.spinner.hide();
            if (response.data != undefined) {
                this.ngZone.run(() => {
                    this.isLavel1 = true;
                    this.isLavel2 = false;
                    this.isLavel3 = false;
                    this.pageList = response.data;
                    this.modalRef.hide();
                });
            }
            if (response.error != undefined) {
                this.toastr.error(response.error.message, 'Error');
            }
        });
    }

    leadgenForm(page_id, page_name, page_access_token) {
        if(page_access_token == undefined)
        {
            this.toastr.error('Page access token not available');
            return;
        }
        this.fbConfigData.fbPageName = page_name;
        this.fbConfigData.fbPageId = page_id;
        this.fbConfigData.fbPageAuthToken = page_access_token,
            this.accessToken = page_access_token;
        this.campaignFields = [];
        this.noCampaignList = false;
        this.spinner.show();
        FB.api('/' + page_id + '/leadgen_forms', 'get', { access_token: page_access_token }, (response) => {
            this.ngZone.run(() => {
                this.isLavel1 = false;
                this.isLavel2 = true;
                this.isLavel3 = false;
                this.campaignList = response.data;
                this.spinner.hide();
                this.next = "";
                this.previous = "";
                if (response.paging != undefined && response.paging.next != undefined)
                    this.next = response.paging.next;
                if (response.paging != undefined && response.paging.previous != undefined)
                    this.previous = response.paging.previous;

                if (this.campaignList.length == 0) {
                    this.noCampaignList = true;
                }
                if (this.isFacebookRequest()) {
                    this.getMapFormList(page_id);
                }
            });
        });

    }

    fetchPagingResult(next: string) {
        FB.api(next, 'get', {}, (response) => {
            this.ngZone.run(() => {
                this.campaignList = response.data;
                this.next = "";
                this.previous = "";
                if (response.paging != undefined && response.paging.next != undefined)
                    this.next = response.paging.next;
                if (response.paging != undefined && response.paging.previous != undefined)
                    this.previous = response.paging.previous;
                this.spinner.hide();
                if (this.campaignList.length == 0) {
                    this.noCampaignList = true;
                }

            });
        });
    }

    subscribedApps() {
        FB.api('/' + this.fbConfigData.fbPageId + '/subscribed_apps', 'post', { access_token: this.accessToken, subscribed_fields: ['leadgen'] }, (response) => {
        });
    }

    showLeads(lead_id, lead_name) {
        this.fbConfigData.fbFormName = lead_name;
        this.fbConfigData.fbFormId = lead_id;
        this.noCampaignFields = false;
        this.spinner.show();
        var fields: any[] = []
        FB.api('/' + lead_id + '/leads', 'get', { access_token: this.accessToken }, (response) => {
            this.ngZone.run(() => {
                this.isLavel1 = false;
                this.isLavel2 = false;
                this.isLavel3 = true;
                let fbFields: any[] = [];
                if (response.data.length > 0) {
                    fbFields = response.data[0].field_data;
                    fbFields.forEach((data, i) => {
                        this.campaignFields[i] = {
                            paramId: '',
                            mapValue: data.name,
                            paramKey: '',
                        }
                    });
                    if (this.campaignFields.length > 0) {
                        this.fetchAllInputParams();
                    } else {
                        this.noCampaignFields = true;
                    }
                } else {
                    this.campaignFields = [];
                    this.noCampaignFields = true;
                }
                this.spinner.hide();
            });
        });
    }

    fetchAllInputParams() {
        this.AllInputParams = [];
        this.projectService.fetchAttributeList().then(data => {
            if (data)
                this.AllInputParams = this.projectService.filterAttributeList(['all']);
            this.AllInputParams.forEach(element => {
                if (element.paramEditable === 'ACTIVE' || element.pluginName === 'static')
                    this.mappingInputParams.push(element)
            });
        });
    }

    hideModal() {
        this.modalRef.hide();
        this.router.navigate(['/admin/project-integration/Social Media/Facebook']);
    }

    saveFormsMapping() {
        var formValid = true;

        this.campaignFields.forEach(element => {

            if (element.paramId === '') {
                formValid = false;
            } else {
                let attributeDetails: any = this.AllInputParams.filter(AttributeObj => AttributeObj.id == element.paramId)
                if (attributeDetails.length > 0) {
                    element.paramKey = attributeDetails[0].paramKey;
                }
            }
        });

        if (!formValid) {
            this.toastr.error('Please fill all mandatory fields', 'Error');
            return;
        }
        var postData: any = {}
        if (this.isFacebookUpdatedRequest()) {
            postData = {
                "fbConfigDataId": this.fbConfigData.id,
                "mappedValues": this.campaignFields,
                "formName": decodeURIComponent(this.campaignId.toString()),
            };
        }
        if (this.isFacebookRequest()) {
            postData = {
                "fbConfigData": this.fbConfigData,
                "mappedValues": this.campaignFields,
                "formName": this.fbConfigData.fbFormName,
            };
        }
        this.projectService.savefbFormMapping(postData).subscribe(data => {
            if (data.status) {
                this.toastr.success('Success');
                this.router.navigate(['/admin/project-integration/Social Media/Facebook']);
                if (this.isFacebookRequest()) {
                    this.subscribedApps();
                }
            } else {
                this.toastr.error(data.error_msgs, 'Error');
            }
        });
    }

    getfacebookFormMapping() {
        this.projectService.getFacebookFormMapping({ formName: decodeURIComponent(this.name) }).subscribe(data => {
            if (data.status) {
                this.fbConfigData = data.data.fbConfigData;
                this.campaignFields = data.data.mappedValues;
            } else {
                this.toastr.error(data.error_msgs, 'Error');
            }
        })
    }

    updateFaceBookFormStatus(status: string) {
        this.projectService.updateFacebookFormStatus({ formName: decodeURIComponent(this.name), active: status }).subscribe(data => {
            if (data.status) {
                this.toastr.success(data.data);
                this.projectService.getUserSourceData('reload');
                this.fbConfigData.status = status;
            } else {
                this.toastr.error(data.error_msgs, 'Error');
            }
        });
    }

    deleteFacebookForm() {
        this.projectService.deleteFacebookForm({ formName: decodeURIComponent(this.name), fbConfigDataId: this.fbConfigData.id }).subscribe(data => {
            this.modalRef.hide();
            if (data.status) {
                this.toastr.success(data.data);
                this.router.navigate(['/admin/project-integration/Social Media/Facebook']);
            } else {
                this.toastr.error(data.error_msgs, 'Error');
            }
        });
    }

    getMapFormList(id) {
        this.projectService.getMapformList({ fbPageId: id }).subscribe(data => {
            this.modalRef.hide();
            if (data.status) {
                data.data.forEach(element => {
                    this.campaignList.forEach((data, i) => {
                        if (element.fbFormId == data.id) {
                            this.campaignList[i].mapped = true;
                        }
                    })
                });
            } else {
                this.toastr.error(data.error_msgs, 'Error');
            }
        });
    }


}
