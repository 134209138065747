import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { ReportService } from '../../../../core/services/report.service';

@Component({
  selector: 'app-follow-up',
  templateUrl: './follow-up.component.html',
  styleUrls: ['./follow-up.component.scss']
})
export class FollowUpComponent implements OnInit {
followUpList;
totalToday
totalMissed;
totalCompleted;
totalOverdue;
sortByDate:any;
  constructor(        
    private reportService: ReportService,
    private location:Location,
    private authenticService:AuthenticationService
    ) { 
      this.fetchUserProfile();
    }

  ngOnInit() {
    setTimeout(()=>{
      this.getFollowData();
    },1000)
  }

  goToBack()
  {
    this.location.back();
  }

  fetchUserProfile(){
    this.authenticService.getUser().subscribe(data=>{
        if(data.status){
            this.sortByDate = data.data.sortBy;
        }
    })
}

//@kapil 1124
  getFollowData()
  {
    this.reportService.followUpData({},this.sortByDate,"").subscribe(data=>{
      if(data.status)
      {
        this.followUpList = data.data;
        this.totalToday = this.followUpList.reduce((accumulator, current) => accumulator + current.today, 0);
        this.totalMissed = this.followUpList.reduce((accumulator, current) => accumulator + current.missed, 0);
        this.totalCompleted = this.followUpList.reduce((accumulator, current) => accumulator + current.completed, 0);
        this.totalOverdue = this.followUpList.reduce((accumulator, current) => accumulator + current.overdue, 0);
      }
    },(error)=>{
      console.log(error);
    })
  }

}
