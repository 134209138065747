import { NgModule } from "@angular/core";
import { ModalModule } from "ngx-bootstrap";
import { BaseService } from "../../core/services/base.service";
import { ShareModule } from "../../shared/models/share.module";
import { CampaignJourneyRouting } from "./campaign-journey.routing";
import { CampaignJourneyService } from "./campaign-journey.service";
import { CampaignManagerComponent } from "./manager/campaign-manager.component";
import { CreateJourneyComponent } from "./create-journey/create-journey.component";

@NgModule({
    imports: [
        CampaignJourneyRouting,
        ModalModule.forRoot(),
        ShareModule
    ],
    declarations: [
        CreateJourneyComponent,
        CampaignManagerComponent
    ],
    exports: [
    ],
    providers:[
        BaseService,
        CampaignJourneyService
    ]
})

export class CampaignJourneyModule {

}