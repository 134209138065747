import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../../../app/core/guards/auth.guard";
import { AuthLayoutComponent } from "../admin/components/auth-layout/auth-layout.component";
import { DashboardComponent } from "../admin/pages/dashboard/dashboard.component";
import { ViewAudienceComponent } from "../admin/pages/view-audience/view-audience.component";
import { CatalogueComponent } from "./catalogue.component";
import { EditCatalogueComponent } from "./edit-catalogue.component";

const routes: Routes = [
    {path:'admin',component:AuthLayoutComponent, canActivate:[AuthGuard], children:[
        {path:'catalogue', component: CatalogueComponent},
        {path:'edit-catalogue', component: EditCatalogueComponent},
        {path:'edit-catalogue/:id', component: EditCatalogueComponent},
        {path:'dashboard/new', component: DashboardComponent},
        { path: 'view-audience/new', component: ViewAudienceComponent}
    ]}
]

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})

export class CatalogueRouting {
    
}