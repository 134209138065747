import { NgModule } from "@angular/core";
import { BsDatepickerModule, ModalModule, TimepickerModule } from "ngx-bootstrap";
import { ColorPickerModule } from "ngx-color-picker";
import { DndModule } from "ngx-drag-drop";
import { ShareModule } from "../../shared/models/share.module";
import { BaseService } from "../../core/services/base.service";
import { CustomFormComponent } from "./customForm.component";
import { CustomFormRouting } from "./customForm.routing";
import { CustomFormService } from "./customForm.service";
import { TabsModule } from 'ngx-bootstrap/tabs';
import { NgSelectModule } from "@ng-select/ng-select";

@NgModule({
    imports: [
        CustomFormRouting,
        DndModule,
        ColorPickerModule,
        ModalModule.forRoot(),
        TabsModule.forRoot(),
        BsDatepickerModule.forRoot(),
        TimepickerModule.forRoot(),
        NgSelectModule,
        ShareModule
    ],
    declarations: [
        CustomFormComponent
    ],
    exports:[
    ],
    providers: [
        BaseService,
        CustomFormService
    ]
    
})
export class CustomFormModule {

}