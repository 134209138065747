<!-- <div class="spinner" *ngIf="!httploading && loading">
    <div class="loader">
        <div class="la-ball-clip-rotate la-2x"><div></div></div>
    </div>
</div> -->
<div class="spinner" *ngIf="httploading">
    <div class="loader">
        <div class="la-ball-clip-rotate la-2x"><div></div></div>
        <p *ngIf="timer > 60 && isLogin">&nbsp;<a [routerLink]="['/admin/dashboard']" (click)="httploading = false">Lost somewhere ?.. Go to Dashboard again !!!</a></p>
    </div>
</div>
