import { Injectable } from '@angular/core';
import { delay } from 'rxjs/operators';
import { ApiConstants } from '../../constants/api.constants';
import { AppConstants } from '../../constants/app.constants';
import { EmailTemplateObj, InappTemplateObj, PushTemplateObj, SmsTemplateObj, WhatsappTemplateObj } from '../modal/campaign';
import { BaseService } from './base.service';
import { catchError, retry, map, debounce } from 'rxjs/operators';
import { Observable, of, timer } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CampaignService {

    rootPath :string;
    pushTemplateData: PushTemplateObj[];
    emailTemplateData: EmailTemplateObj[];
    smsTemplateData: SmsTemplateObj[];
    WhatsappTemplateObj:WhatsappTemplateObj[];
    defaultEmailTemplateData: EmailTemplateObj[];
    defaultInappTemplateData: InappTemplateObj[];

    constructor (
        private baseService: BaseService
    ) { 

    }

    getCampaignsList(body:any,url?) {
        return this.baseService.postRequest(url+ApiConstants.CAMPAIGN_LIST, body)
    }

    getCampaign(body:any) {
        return this.baseService.postRequest(ApiConstants.VIEW_AUDIENCE_FILTERS, body)
    }

    getCampaignDetail(body:any , url) {
        return this.baseService.postRequest(url + ApiConstants.VIEW_AUDIENCE_REPORT_DETAIL, body)
    }

    getFilters(body:any) {
        return this.baseService.postRequest(ApiConstants.FETCH_CAMPAIGN_PARAMS, body)
    }

    checkSubscriptionForCampaign(body:any) {
        return this.baseService.postRequest(ApiConstants.CHECK_SUBSCRIPTION_FOR_CAMPAIGN, body)
    }

    getFilterValues(body:any) {
        return this.baseService.postRequest(ApiConstants.FETCH_CAMPAIGN_PARAMS_VALUES, body)
    }

    createCampaign(body:any) {
        return this.baseService.postRequest(ApiConstants.UPSERT_CAMPAIGN, body)
    }

    calculateAudience(body:any, menuParams) {
        return this.baseService.postRequest(ApiConstants.CALCULATE_AUDIENCE+'/'+menuParams, body)
    }

    searchParamData(body:any) {
        return this.baseService.postRequest(ApiConstants.SEARCH_PARAM_DATA, body)
    }

    savePushNotification(body:any,url) {
        return this.baseService.postRequest(url+ApiConstants.SAVE_PUSH_NOTIFICATION_CAMPAIGN, body)
    }

    saveSMSCampaign(body:any,url?) {
        return this.baseService.postRequest(url+ApiConstants.SAVE_CAMPAIGN, body)
    }

    // saveWhatsappCampaign(body:any,url) {
    //     return this.baseService.postRequest(url+ApiConstants.SAVE_WHATSAPP_CAMPAIGN, body)
    // }

    saveEmailCampaign(body:any,url?) {
        return this.baseService.postRequest(url+ApiConstants.SAVE_CAMPAIGN, body)
    }

    saveInAppNotification(body:any,url?) {
        return this.baseService.postRequest(url + ApiConstants.SAVE_INAPP_CAMPAIGN, body)
    }

    downloadReport(body:any,url) {
        return this.baseService.postRequest(url + ApiConstants.DOWNLOAD_CAMPAIGN_REPORT, body)
    }

    deleteReport(body:any,url) {
        return this.baseService.postRequest(url + ApiConstants.DELETE_CAMPAIGN, body)
    }
    disableReport(body:any,url) {
        return this.baseService.postRequest(url + ApiConstants.DISABLE_CAMPAIGN, body)
    }

    fetchCampaignDataForPaging(body:any) {
        return this.baseService.postRequest(ApiConstants.CAMPAIGN_LIST_FOR_PAGGING, body)
    }

    checkConfigurationForCampaign(body:any,url?) {
        return this.baseService.postRequest(url + ApiConstants.CHECK_CONFIGURATION_FOR_CAMPAIGN, body)
    }

    saveEmailTemplate(body:any) {
        return this.baseService.postRequest(ApiConstants.UPSERT_EMAIL_TEMPLATE, body);
    }
    emailTemplateList(url,body:any) {        
        return this.baseService.postRequest(url + ApiConstants.TEMPLATE_LIST, body);
    } 
    
    emailTemplate(body:any,url) {
        return this.baseService.postRequest(url + ApiConstants.GET_TEMPLATE, body);
    } 
    emailTemplateDelete(body:any) {
        return this.baseService.postRequest(ApiConstants.DELETE_EMAIL_TEMPLATE, body);
    } 

    savePushTemplate(body:any) {
        return this.baseService.postRequest(ApiConstants.UPSERT_PUSH_TEMPLATE, body);
    }
    pushTemplateList(body:any) {
        return this.baseService.postRequest(ApiConstants.PUSH_TEMPLATE_LIST, body);
    } 
    pushTemplate(body:any,url?) {
        return this.baseService.postRequest(url + ApiConstants.GET_TEMPLATE, body);
    } 

    whatsappTemplate(body:any,url?) {
        return this.baseService.postRequest(url + ApiConstants.GET_TEMPLATE, body);
    }


    pushTemplateDelete(body:any) {
        return this.baseService.postRequest(ApiConstants.DELETE_PUSH_TEMPLATE, body);
    } 
    
    saveSmsTemplate(body:any) {
        return this.baseService.postRequest(ApiConstants.UPSERT_SMS_TEMPLATE, body);
    }
    smsTemplateList(body:any,url?) {
        return this.baseService.postRequest(url + ApiConstants.TEMPLATE_LIST, body);
    } 
    smsTemplate(body:any,url?) {
        return this.baseService.postRequest(url + ApiConstants.GET_TEMPLATE, body);
    } 
    saveWhatsappTemplate(body:any,url?) {
        return this.baseService.postRequest(url + ApiConstants.UPSERT_TEMPLATE, body);
    }
    templateList(body:any,url) {
        return this.baseService.postRequest(url+ApiConstants.TEMPLATE_LIST, body);
    } 

    upsertTemplate(body:any,url) {
        return this.baseService.postRequest(url+ApiConstants.UPSERT_TEMPLATE, body);
    }
    
    getTemplate(body:any,url) {
        return this.baseService.postRequest(url+ApiConstants.GET_TEMPLATE, body);
    }

    deleteTemplate(body:any,url) {
        return this.baseService.postRequest(url+ApiConstants.DELETE_TEMPLATE, body);
    }
    
    
    
    whatsappTemplateDelete(body:any) {
        return this.baseService.postRequest(ApiConstants.DELETE_WHATSAPP_TEMPLATE, body);
    }

    smsTemplateDelete(body:any) {
        return this.baseService.postRequest(ApiConstants.DELETE_SMS_TEMPLATE, body);
    }

    imageFileList(body:any,size:any,page:any) {
        return this.baseService.postRequest(ApiConstants.IMAGE_FILE_LIST.replace(AppConstants.REPLACE_SIZE, size).replace(AppConstants.REPLACE_PAGE, page), body);
    }
    deleteImageFile(body:any) {
        return this.baseService.postRequest(ApiConstants.DELETE_IMAGE_FILE, body);
    }
    upsertImageFile(body:any) {
        return this.baseService.postRequestMultipart(ApiConstants.UPSERT_IMAGE_FILE, body);
    }

    // getShorten(body:any) {
    //   return this.http.post<any>(`https://api-ssl.bitly.com/v4/shorten`, body, { headers: this.global.bitlyHeader()});
    // }

    upsertBarNotification(body:any) {
        return this.baseService.postRequest(ApiConstants.UPSERT_BAR_NOTIFICATION, body);
    }
    upsertPopupNotification(body:any) {
        return this.baseService.postRequest(ApiConstants.UPSERT_POPUP_NOTIFICATION, body);
    }
    fetchPopupNotificationDetail(body:any) {
        return this.baseService.postRequest(ApiConstants.FETCH_POPUP_NOTIFICATION_DETAIL, body);
    }
    fetchBarNotificationDetail(body:any) {
        return this.baseService.postRequest(ApiConstants.FETCH_BAR_NOTIFICATION_DETAIL, body);
    }

    emailTemplateDefaultList(body:any,url) {
        return this.baseService.postRequest(url + ApiConstants.EMAIL_TEMPLATE_DEFAULT_LIST, body);
    }

    inappTemplateDefaultList(body:any) {
        return this.baseService.postRequest(ApiConstants.INAPP_TEMPLATE_LIST, body);
    }

    saveTemplateDefaultUser(body:any,url) {
        return this.baseService.postRequest(url+ApiConstants.SAVE_TEMPLATE_DEFAULT_USER, body);
    } 

    saveJourneyCampaign(body:any) {
        return this.baseService.postRequest(ApiConstants.SAVE_JOURNEY_CAMPAIGN, body);
    }
    
    async getPushTemplateData({},url?,reload?){
        if(reload == 'reload')
        {
            this.pushTemplateData = []
        }
        if(this.pushTemplateData == undefined || this.pushTemplateData.length == 0){
            var requestData = await this.templateList({},url).pipe(delay(200)).toPromise();
            this.pushTemplateData = requestData.data ? requestData.data : requestData.error_msgs;
        };
        return this.pushTemplateData;
    }

    async getEmailTemplateData(url?,reload?){        
        if(reload == 'reload')
        {
            this.emailTemplateData = undefined
        }
        if(this.emailTemplateData == undefined){
            var requestData = await this.emailTemplateList(url,{}).pipe(delay(200)).toPromise();
            this.emailTemplateData = requestData.data;
        };
        return this.emailTemplateData;
    }

    async getWhatsappTemplateData(reload?,url?){
        
        if(reload == 'reload')
        {
            this.WhatsappTemplateObj = undefined
        }
        if(this.WhatsappTemplateObj == undefined){
            var requestData = await this.templateList({},url).pipe(delay(200)).toPromise();
            this.WhatsappTemplateObj = requestData.data;
        };
        return this.WhatsappTemplateObj;
    }

    async getSmsTemplateData(url?,reload?){
        if(reload == 'reload')
        {
            this.emailTemplateData = undefined
        }
        if(this.smsTemplateData == undefined){
            var requestData = await this.templateList({},url).pipe(delay(200)).toPromise();
            this.smsTemplateData = requestData.data;
        };
        return this.smsTemplateData;
    }

    async getDefaultEmailTemplateData(url){
        if(this.defaultEmailTemplateData == undefined){
            var requestData = await this.emailTemplateDefaultList({},url).pipe(delay(200)).toPromise();
            this.defaultEmailTemplateData = requestData.data;
        };
        return this.defaultEmailTemplateData;
    }

    async getDefaultInappTemplateData(){
        if(this.defaultInappTemplateData == undefined){
            var requestData = await this.inappTemplateDefaultList({}).pipe(delay(200)).toPromise();
            this.defaultInappTemplateData = requestData.data;
        };
        return this.defaultInappTemplateData;
    }

    
  }