import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ElementRef, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, TabsetComponent } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AppConstants } from '../../../../../app/constants/app.constants';
import { CustomFormObj } from '../../../../../app/modules/customForm/customForm.modal';
import { CustomFormService } from '../../../../../app/modules/customForm/customForm.service';
import { CampaignService } from "../../../../core/services/campaign.service";
import { Global } from "../../../../shared/models/global.model";
import { noWhitespaceValidator } from '../../../../shared/models/no-whitespace-validator';
import { SpinnerService } from '../../../../core/services/spinner.service';
import {HostListener} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiConstants } from '../../../../../app/constants/api.constants';
import { AuthenticationService } from '../../../../core/services/authentication.service';

@Component({
    selector: 'gallery-popup',
    templateUrl: './gallery-popup.component.html',
    styleUrls: ['./campaign-gallery.component.scss']
})

export class GalleryPopupComponent implements OnInit {
    @ViewChild('imageInput') imageInput: ElementRef;
    @ViewChild('fileInput') fileInput: ElementRef;
    @Input() modalRefGallery: BsModalRef;
    @Input() galleryType: string = 'all';  //all, addMedia, image, file, form
    @Output() selectMediaEvent = new EventEmitter();
    modalRef: BsModalRef;
    mediaList:any = [];
    public pageNo: number;
    public dataSize: number;
    public totalDataSize: number;
    // public isMoreDataAvailable: boolean;
    // public isDataAvailable: boolean;
    // public isLoading:boolean;
    itemId: number;
    itemIndex: number;
    fileType: string;
    searchText: string;
    formGallery: FormGroup;
    fileToUpload : any;
    validFilters: any = false;
    // thumbnailFile: any;
    extension: string;
    shortURL: boolean = false;
    fileReadOnly: boolean = false;
    isFormShow:boolean = false;
    isMediaShow:boolean = true;
    customFormList: CustomFormObj[];
    imagesList:any[] = [];
    formData = new FormData();
    @ViewChild('pageTabs') pageTabs: TabsetComponent;
    parentClassName:string;
    //This code is for handling asset library to open ecomerrece project of cronberry. which is not on angular 
    // and build in prestashop. We are using postMessage communication for iframes.
    @HostListener('window:message', ['$event'])
    onMessage(event) {
      if (typeof event.data === 'string' || event.data instanceof String){
          this.parentClassName = event.data;
          this.pageTabs.tabs[1].active = true;
        }
    }
    isMediaSelect: boolean = true;
    isSubscribe: boolean = true;
    isCustomeFormSubscribe: boolean = true;
    isIframe:boolean = false;
    isAddFormShow: boolean;
    constructor(
        private campaignService: CampaignService,
        private toastr: ToastrService,
        private formBuilder: FormBuilder,
        public global: Global,
        private customFormService: CustomFormService,
        private spinner: SpinnerService,
        public router: Router,
        private route: ActivatedRoute,
        private authenticationService: AuthenticationService
    ) {
        
        this.isIframe = this.router.routerState.snapshot.url.indexOf('/admin/thumb-campaign-gallery') > -1;
        
        this.route.queryParamMap.subscribe(queryParams => {
            if(queryParams.get("type") == 'customForm')
            this.galleryType = 'none,form'
        });

        this.formGallery = this.formBuilder.group({
            imageName: [ "", Validators.compose([ Validators.required, noWhitespaceValidator, Validators.minLength(3), Validators.maxLength(200),Validators.pattern(this.global.AlphaNumPatternWithChar) ]) ],
            fileType: [ "IMAGE", Validators.compose([ Validators.required ]) ],
            originalFile: [""],
        });
        
        this.isSubscribe = this.authenticationService.isPageSubscribe('Asset Library');
        this.isCustomeFormSubscribe = this.authenticationService.isPageSubscribe('Custom Forms');
    }

    ngOnInit() {
        if(!this.isSubscribe)
        return;
        this.isAddFormShow = !this.isIframe && this.authenticationService.getUserRole() == AppConstants.ADMIN_ROLEID;
        this.fileType = '';
        this.isMediaShow = true;
        var galleryType =  this.galleryType.split(',');
        if(galleryType[0] == 'image'){
            this.fileType = 'IMAGE';
            this.formGallery.get('fileType').disable({ onlySelf: true });
            this.formGallery.get('fileType').setValue('IMAGE');
            this.fileReadOnly = true;
        } else if(galleryType[0] == 'file'){
            this.fileType = 'FILE';
            this.formGallery.get('fileType').disable({ onlySelf: true });
            this.formGallery.get('fileType').setValue('FILE');
            this.fileReadOnly = true;
        } else if(galleryType[0] == 'none'){
            this.isMediaShow = false;
        }
        if(galleryType[0] == AppConstants.FORM || (galleryType[1] != undefined && galleryType[1] == AppConstants.FORM)){
            this.isFormShow = true;
        }
        // if(this.isMediaShow){
        //     this.pageNo= 0;
        //     this.dataSize = 1000;
        //     this.getMediaList();
        // }
    }

    changeTab(event){
        if(event.id == 'tab2' && this.mediaList.length == 0){
            this.pageNo= 0;
            this.dataSize = 1000;
            this.getMediaList();
        }
        if(event.id == 'tab3' && this.customFormList == undefined){
            this.getCustomFormList();
        }
    }

    async selectMedia(item, type){
        if(this.isMediaSelect){
            this.isMediaSelect = false;
            item.type = type;
            this.selectMediaEvent.emit(item);
        }
        if(this.modalRefGallery != undefined)
        this.modalRefGallery.hide();
    }

    updateList(item, type){
        if(this.isMediaSelect){
            this.isMediaSelect = false;
            item.type = type;
            this.selectMediaEvent.emit(item);
        }
       if(this.modalRefGallery != undefined)
       this.modalRefGallery.hide();
    }

    /*
    This methoid is also using by prestashop iframe handler of cronberry ecommerce.
    Don't remove it
    */
    apply_img(url: string, type:string) {
        var fileUrl
        if(type == 'image')
            fileUrl = url;
        else 
            fileUrl = ApiConstants.CUSTOM_FORM_LINK+url
        
            window.parent.postMessage({
            'url': fileUrl,
            'pclassName':this.parentClassName,
            'type':'file'
        }, "*");
    }

    getMediaList(){
        this.campaignService.imageFileList({}, this.dataSize, this.pageNo).subscribe(data => {
            this.mediaList = [];
            if(data.status){
                if(this.pageNo==0){
                    this.totalDataSize = data.data.size;
                }
                data.data.mediaGallary.forEach(element => {
                    if(element.fileType == 'file'){
                        if(element.extention == 'pdf'){
                            element.thumbnailFile = 'assets/images/file-pdf.svg'
                        } else if(element.extention == 'txt'){
                            element.thumbnailFile = 'assets/images/file-edit.svg'
                        } else if(element.extention == 'zip'){
                            element.thumbnailFile = 'assets/images/file-archive.svg'
                        } else if(element.extention == 'xlsx' || element.extention == 'xls'){
                            element.thumbnailFile = 'assets/images/file-spreadsheet.svg'
                        } else if(element.extention == 'doc' || element.extention == 'docx'){
                            element.thumbnailFile = 'assets/images/file-word.svg'
                        } else if(element.extention == 'ppt' || element.extention == 'pptx'){
                            element.thumbnailFile = 'assets/images/file-powerpoint.svg'
                        } else {
                            element.thumbnailFile = '/assets/images/file-alt.svg'
                        }
                    }
                    this.mediaList.push(element);
                });
            }
        });
    }

    uploadimage(event): void {
        this.imagesList = [];
        var fileList: File[] = event.target.files;
        var fileSize = 0;
        var filesAmount = fileList.length
        if(filesAmount > 20){
            this.imageInput.nativeElement.value = '';
            this.toastr.error('Maximum 20 files should be upload.')
            return;
        }
        for (let i = 0; i < filesAmount; i++) {
            fileSize = Number(fileList[i].size) + Number(fileSize);
        }
        if(fileSize > 20971520){
            if(this.formGallery.controls.fileType.value == 'FILE'){
                this.fileInput.nativeElement.value = '';
            } else { 
                this.imageInput.nativeElement.value = '';
            }
            this.toastr.error('Maximum file size should not be more than 20 MB.')
             return;
        }
        var re = /(?:\.([^.]+))?$/;
        if(this.formGallery.controls.fileType.value == 'FILE'){
            this.fileToUpload = fileList[0];
            this.extension = re.exec(fileList[0].name)[1];
        } else {
            var fileList: File[] = event.target.files;
            var filesAmount = fileList.length
            for (let i = 0; i < filesAmount; i++) {
                var reader = new FileReader();
                reader.onload = (event:any) => {
                    var extension = '';
                    extension = re.exec(fileList[i].name)[1];
                    extension = extension.toLowerCase();
                    if(extension === 'jpg' || extension === 'jpeg' || extension === 'png' || extension === 'gif' || extension === 'svg'){
                        this.imagesList.push({'imageExtention':extension, 'originalFile':event.target.result })
                    } else {
                        this.toastr.error('File type is not allowed.', 'Error')
                    }
                }
                reader.readAsDataURL(event.target.files[i]);
            }
        }
    }

    saveGallery(){
        if (this.formGallery.invalid ) {
            this.toastr.error(this.global.MessageRequired);
            this.global.validateAllFormFields(this.formGallery);
            return;
        }
        let fileType: string = this.fileReadOnly ? this.fileType : this.formGallery.value.fileType;
        if(fileType == 'FILE'){
            if (this.fileToUpload == undefined ) {
                this.toastr.error(this.global.MessageRequired);
                return;
            }
            this.formData.append("file", this.fileToUpload);
            this.formData.append("extention", this.extension);
        } else {
            if (this.imagesList.length == 0) {
                this.toastr.error(this.global.MessageRequired);
                return;
            }
            this.formData.append("multiImages", JSON.stringify(this.imagesList));
        }
        this.formData.append("mediaName", this.formGallery.value.imageName);
        fileType = fileType == 'GALLERY' ?  'IMAGE' : fileType;
        this.formData.append("fileType", fileType);
        this.spinner.show();
        this.campaignService.upsertImageFile(this.formData).subscribe(data => {
            this.spinner.hide();
            if(data.status){
                if(this.galleryType!='addMedia'){
                    if(this.mediaList.length > 0)
                    this.getMediaList();
                    this.fileType = fileType;
                    this.pageTabs.tabs[1].active = true;
                    this.toastr.success(data.data, "Success");
                } else {
                    this.selectMedia({}, 'file');
                    this.toastr.success('Successfully Uploaded', "Success");
                }
                this.resetForm();
            } else {
                this.toastr.error(data.error_msgs, "Error");
                this.formGallery.reset();
                this.formGallery.get('fileType').setValue('IMAGE')
            }
        });
    }

    resetForm(){
        this.formGallery.reset();
        this.formGallery.get('fileType').setValue('IMAGE');
        this.imagesList = [];
        if(this.formData.get("fileType") == 'FILE'){
            this.fileInput.nativeElement.value = '';
            this.formData.delete('extention');
            this.formData.delete('file');
        } else {
            this.imageInput.nativeElement.value = '';
            this.formData.delete('multiImages');
        }
        this.formData.delete('mediaName');
        this.formData.delete('fileType');
    }

    getCustomFormList(){
        this.customFormList = []
        this.customFormService.fetchCustomForm({ }).subscribe( data => {
            if(data.status){
                this.customFormList = data.data;
            }
        })
    }


}
