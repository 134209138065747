import { NgModule } from "@angular/core";
import { UserService } from "../../core/services/user.service";
import { BaseService } from "../../core/services/base.service";
import { ShareModule } from "../../shared/models/share.module";
import { CallassistDashboardComponent } from "./callassist.dashboard.component";
import { CallassistDetailComponent } from "./callassist.detail.component";
import { CallassistRouting } from "./callassist.routing";
import { NgSelectModule } from "@ng-select/ng-select";
import { BsDatepickerModule } from "ngx-bootstrap";
import { shortTimePipe, TelleCallerStatusPipe } from "../../shared/pipes/short-time.pipe";
import { CallAssistLocationComponent } from "./call-assist-location/call-assist-location.component";
import { CallassistMissRecordingDetailsComponent } from "./callassist-miss-recording-details/callassist-miss-recording-details.component";

@NgModule({
    imports: [
        CallassistRouting,
        ShareModule,
        NgSelectModule,
        BsDatepickerModule.forRoot(),
    ],
    declarations: [
        CallassistDashboardComponent,
        CallassistDetailComponent,
        shortTimePipe,
        TelleCallerStatusPipe,
        CallAssistLocationComponent,
        CallassistMissRecordingDetailsComponent
    ],
    exports: [
    ],
    providers:[
       BaseService,
       UserService
    ]
})

export class CallAssistModule {

}