import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../../src/environments/environment';
import { EcommerceService } from '../../../../core/services/ecommerce.service';
import { noWhitespaceValidator } from '../../../../../app/shared/models/no-whitespace-validator';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { Location } from '@angular/common';

@Component({
    selector: 'app-landing-page',
    templateUrl: './landing-page.component.html',
    styleUrls: ['./landing-page.component.scss']
})

export class LandingPageComponent implements OnInit {
    @ViewChild('confirmModal') confirmModal: TemplateRef<any>;
    modalRef: BsModalRef;
    isDomainChack: boolean = false;
    siteForm: FormGroup;
    domainError: string = '';
    domainData: any;
    pageUnderProcess: boolean = false;
    domainSuffix: string;
    isSubscribe: boolean = true;
    constructor (
        private modalService: BsModalService,
        public formBuilder: FormBuilder,
        private toastr: ToastrService,
        private ecommerceService: EcommerceService,
        private router:Router,
        private authenticationService: AuthenticationService,
        private location:Location
    ) {
        this.isSubscribe = this.authenticationService.isPageSubscribe('Landing Page');
        this.siteForm = this.formBuilder.group({
            domain:[ "", Validators.compose([ Validators.required, noWhitespaceValidator, Validators.minLength(5), Validators.maxLength(50), Validators.pattern(/^[a-zA-Z0-9-_]*$/) ]) ],
        });
    }

    ngOnInit() {
        this.domainSuffix = environment.landingDomainSuffix;
        if(this.isSubscribe)
        this.getData();
    }

    back()
    {
        this.location.back();
    }


    showConfirmModal(){
        this.modalRef = this.modalService.show(this.confirmModal, {
            backdrop: 'static'
        });
    }

    resetForm(){
        this.isDomainChack = false;
        this.domainError = '';
    }

    checkDomain(){
        this.resetForm();
        if (this.siteForm.invalid ) {
            this.toastr.error('Please fill all mandatory fields');
            return;
        };
        this.ecommerceService.domainStatus(this.siteForm.value).subscribe(data => {
            if(data.status){
                this.isDomainChack = true;
            } else {
                this.domainError = data.error_msgs;
            }
        })
    }

    domainConfirm(){
        this.ecommerceService.domainCreate(this.siteForm.value).subscribe(data => {
            this.modalRef.hide();
            if(data.status){
                this.toastr.success(data.data, 'Success');
                setTimeout(() => {
                    this.pageUnderProcess = true;    
                }, 500);
                setTimeout(() => {
                    this.getData();    
                }, 15000);

            } else {
                this.toastr.error(data.error_msgs, 'Error')
                
            }
        });
    }

    getData(){
        this.pageUnderProcess = false;
        this.ecommerceService.domainList({}).subscribe(data => {
            if(data.status){
                if( data.data.length > 0){
                    this.domainData = data.data[0];
                    this.domainData.startDate = data.data[0].startDate.replace(' ', 'T');
                    this.domainData.expiryDate = data.data[0].expiryDate.replace(' ', 'T');
                }
            } else {
                this.toastr.error(data.error_msgs, 'Error')
            }
        });
    }

    opneLandingPage(){
        this.router.navigate(['/landing-page']);
    }
}
