<main>
    <section class="inner-page">
        <div class="inner-page-header">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <h4>
                            <a class="back-link" (click)="goToBack()">
                                <img src="assets/images/angle-left.svg">
                            </a>
                            <span>
                              Follow-up
                            </span>
                        </h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="inner-page-body border-bottom-0 follow-up-page">
            <div class="container-fluid p-l-0 p-r-0">
                <div class="row">
                    <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
                        <div class="table-reports">
                            <div class="table-body">
                                <div class="table-responsive table-height-scroll text-center">
                                    <table class="table">
                                        <tbody class="norecord-handling">
                                            <tr>
                                                <th>Name</th>
                                                <th>Today</th>
                                                <th>Missed</th>
                                                <th>Completed</th>
                                                <th>Overdue</th>
                                            </tr>
                                            <ng-container *ngIf="followUpList">
                                                <tr *ngFor="let list of followUpList" class="follow-up-middle-row">
                                                    <td>{{list.userName}}</td>
                                                    <td>{{list.today}}</td>
                                                    <td>{{list.missed}}</td>
                                                    <td>{{list.completed}}</td>
                                                    <td>{{list.overdue}}</td>
                                                </tr>
                                                <tr class="table-footer-follow-up">
                                                    <td>Total</td>
                                                    <td>{{totalToday}}</td>
                                                    <td>{{totalMissed}}</td>
                                                    <td>{{totalCompleted}}</td>
                                                    <td>{{totalOverdue}}</td>
                                                </tr>
                                            </ng-container>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>