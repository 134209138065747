import { Component, Input, OnInit, Output, EventEmitter, OnDestroy, ViewChild, ElementRef, forwardRef, NgZone, NgModule } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Observable } from 'rxjs';

declare const CKEDITOR;

@Component({
    selector: 'app-ckeditor',
    template: `
        <textarea #editor>
            {{value}}
        </textarea>
    `,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => CkEditorComponent),
        multi: true
    }]
})
export class CkEditorComponent implements OnInit, OnDestroy, ControlValueAccessor {

    @Input() config: any;
    @Input() editorData:Observable<string>;
    @Input() headerHtmlData:Observable<string>;
    @Input() footerHtmlData:Observable<string>;
    @Input() htmlSetHeadData:Observable<string>;
    @Input() htmlSetFooterData:Observable<string>;
    @Output() getHeaderHtmlVal = new EventEmitter<string>();
    @Output() getFooterHtmlVal = new EventEmitter<string>();
    @ViewChild('editor') editor: ElementRef;
    wait = false;

    instance: any;

    // config = {
    //     uiColor: '#F0F3F4',
    //     height: '100%'
    // };

    private _value = '';

    get value(): any { return this._value; }
    @Input() set value(v) {
        if (v !== this._value) {
            this._value = v;
            this.onChange(v);
        }
    }

    constructor(private zone: NgZone) { }

    ngOnInit() {
        this.instance = CKEDITOR.replace(this.editor.nativeElement, this.config);
        if(this.editorData != undefined)
        {
        this.editorData.subscribe(event => {
            setTimeout(() => {
                this.writeValue(event);    
            }, 200);
        });
        }        
        if(this.headerHtmlData != undefined){
            CKEDITOR.on('instanceReady', function (ev) {
                ev.editor.on('paste', function (ev) {
                    ev.data.dataValue = ev.data.dataValue.replaceAll(/(<p)/igm, '<div').replaceAll(/<\/p>/igm, '</div>');                    
                });
            });
        this.headerHtmlData.subscribe(event => {
            setTimeout(() => {
                this.writeValue(event);    
            }, 200);
        });
        }

        if(this.footerHtmlData != undefined){
            CKEDITOR.on('instanceReady', function (ev) {
                ev.editor.on('paste', function (ev) {
                    ev.data.dataValue = ev.data.dataValue.replaceAll(/(<p)/igm, '<div').replaceAll(/<\/p>/igm, '</div>');                    
                });
            });
        this.footerHtmlData.subscribe(event => {
            setTimeout(() => {
                this.writeValue(event);  
            }, 200);
        });
        }

        this.instance.setData(this._value);

        // CKEditor change event
        this.instance.on('change', () => {
            let value = this.instance.getData();            
            this.updateValue(value);
        });
        
        if(this.htmlSetHeadData != undefined)
        {
            this.instance.setData(this.htmlSetHeadData);   
        }

        if(this.htmlSetFooterData != undefined)
        {
            this.instance.setData(this.htmlSetFooterData);   
        }

    }
    

    /**
   * Value update process
   */
    updateValue(value: any) {
        this.zone.run(() => {
            this.value = value;
            this.getHeaderHtmlVal.emit(this.value);
            this.getFooterHtmlVal.emit(this.value);
            this.onChange(value);
            this.onTouched();
        });
    }

    /**
   * Implements ControlValueAccessor
   */
    writeValue(value: any) {
        this._value = value;
        if (this.instance) {
            this.instance.setData(value);
        }
    }
    onChange(_: any) {
        
     }
    onTouched() { 
        
    }
    registerOnChange(fn: any) { this.onChange = fn; }
    registerOnTouched(fn: any) { this.onTouched = fn; }



    ngOnDestroy() {
        if (this.instance) {
            setTimeout(() => {
                this.instance.removeAllListeners();
                CKEDITOR.instances[this.instance.name].destroy();
                this.instance.destroy();
                this.instance = null;
            });
        }
    }
}

@NgModule({
    imports: [],
    declarations: [CkEditorComponent],
    providers: [],
    exports: [CkEditorComponent]
})
export class CkEditorModule { }