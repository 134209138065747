<div class="text-right mb-3">
    <button class="btn white_add_btn" (click)="addRole()" tooltip="Add Role" [tooltipAnimation]="true" tooltipPlacement="top">
        <img src="assets/images/plus-icon.png"> &nbsp; Add
    </button>
</div>
<div class="table-responsive table-height-scroll table-reports">
    <table class="table callAssistDashboardTable th_font_14">
        <thead class="sticky-header" data-sticky-header="true">
            <tr class="callAssistDbRowTop">
                <th width="20" class="borderTh text-center">S.No.</th>
                <th width="200" class="borderTh">Name</th>
                <th width="200" class="borderTh text-center">Type</th>
                <th width="200" class="borderTh text-center">Report To</th>
                <th width="200" class="borderTh text-center">Level</th>
                <th class="text-center borderTh">Action</th>
            </tr>
        </thead>
        <tbody class="norecord-handling">
            <tr *ngFor="let item of roleList; let i = index;">
                <td class="text-center">{{i + 1}}</td>
                <td class="text-capitalize">{{item.name}}</td>
                <td class="text-capitalize text-center">{{item.roleTypeId==1 ? 'Parent':'Child'}}</td>
                <td class="text-center">
                    <ng-container *ngFor="let parentName of item.parent;let i=index">
                        <ng-container *ngIf="i > 0">, </ng-container>{{parentName}}
                    </ng-container>
                </td>
                <td class="text-center">{{item.level}}</td>
                <td class="text-center">
                    <a class="c-pointer" tooltip="Edit" tooltipPlacement="top" (click)="addRole(item)">
                        <img class="img" src="assets/images/pinkedit.png" />
                    </a>  &nbsp; 
                    <a class="c-pointer delete_btn_role" tooltip="Delete" tooltipPlacement="top" (click)="showDeleteModal(item.id)">
                        <img class="img" src="assets/images/delete.png" />
                    </a>
                </td>
            </tr>
            <tr *ngIf="roleList.length == 0"><td class="no-records text-center" colspan="6">No Records Yet</td></tr>
        </tbody>
    </table>
</div>

<ng-template #addRoleModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{isUpdated ? 'Update' : 'Add'}} Role</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="siteForm" (ngSubmit)="saveForm()">
            <div class="form-row">
                <div class="form-group col-12">
                    <label class="required">Name</label>
                    <input trim="blur" type="text" class="form-control" formControlName="name" maxlength="50">
                </div>
                <div class="form-group col-12">
                    <label class="required">Type</label>
                    <select formControlName="roleTypeId" class="form-control" [hidden]="isUpdated" >
                        <option value="">Select</option>
                        <option value="1">Parent</option>
                        <option value="2">Child</option>
                    </select>
                    <select [(ngModel)]="siteForm.value.roleTypeId" class="form-control" [hidden]="!isUpdated" disabled [ngModelOptions]="{standalone: true}">
                        <option value="">Select</option>
                        <option value="1">Parent</option>
                        <option value="2">Child</option>
                    </select>
                </div>

                <div class="form-group col-12">
                    <label class="">Report To</label>

                    <ng-select (add)="userRoleAdded($event)" (remove)="userRoleRemoved($event)" (clear)="resetAllUserList()" formControlName="parentRole"
                        [items]="roleListClone"
                        [multiple]="true"
                        bindLabel="name"
                        [closeOnSelect]="true"
                        placeholder="Select Role"
                        bindValue="id" class="w-100" [(ngModel)]="siteForm.value.parentRole">
                        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                            <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{standalone: true}" /> {{item.name}}
                        </ng-template>
                    </ng-select>

                </div>

                <div class="col-12 text-right mt-1 mb-2">
                    <button class="btn btn-dark" type="submit">{{isUpdated ? 'Update' : 'Save'}}</button>
                </div>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #confirmModal>
    <form>
        <div class="forgot-password-modal">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Do you want to remove?</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body modal-user-management">
                <div class="form-group mb-0">
                    <p class="pt-2">Are you sure, you want to delete this Role?</p>
                    <div class="d-flex justify-content-end mt-5">
                        <div><button class="btn remove-user-btn mr-4" (click)="modalRef.hide()" type="button">Dismiss</button></div>
                        <div><button class="btn save-btn" type="button" (click)="deleteRole()">Yes</button></div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</ng-template>