import { Injectable } from '@angular/core';
import { ApiConstants } from '../../constants/api.constants';
import { BaseService } from './base.service';

@Injectable({
    providedIn: 'root'
})
export class MasterService {

    constructor(
        private baseService: BaseService  
    ) { 
        
    }

    getCountries() {
        return this.baseService.getRequest(ApiConstants.FETCH_COUNTRIES);
    }

    getServiceProviders(body:any,url?) {
        return this.baseService.postRequest(url + ApiConstants.FETCH_SERVICE_PROVIDER, body);
    }
}
