import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ReportService } from '../../../../core/services/report.service';
import { BsModalRef, TabsetComponent } from 'ngx-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Global } from '../../../../shared/models/global.model';
import { ProjectService } from '../../../../core/services/project.service';
import { LeadStatusObj, AttributeObj } from '../../../../core/modal/project';
import { SubUserObj } from '../../../../core/modal/user';
import { UserService } from '../../../../core/services/user.service';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-audience-profile',
    templateUrl: './audience-profile.component.html',
    styleUrls: ['./audience-profile.component.scss']
})
export class AudienceProfileComponent implements OnInit {
    @Input() modalRef: BsModalRef;
    @Input() audienceId: number = 0;
    audience: any = {};
    siteForm: FormGroup;
    minDate: Date = new Date();
    minTime: Date = new Date();
    leadAssignData: any;
    isSubmitted: boolean = false;
    isSubmit: boolean = false;
    showAllparam:boolean = true;
    leadStatusList: LeadStatusObj[] = [];
    telecallerList: SubUserObj[] = [];
    otherParameters: AttributeObj[] = [];
    isOtherParametersShow: boolean = false;
    statusType: string = 'DEFAULT';
    paremType: string = '';
    @Input() audienceProfileClose: Function;
    userRole: number;
    @ViewChild('scrollblockLeft') scrollblockLeft: ElementRef;
    @ViewChild('scrollblockRight') scrollblockRight: ElementRef;
    @ViewChild('siteTabs') siteTabs: TabsetComponent;
    callLogs: any[];
    queryParamsFromMenu:any;

    constructor(
        private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private reportService: ReportService,
        private projectService: ProjectService,
        private userService: UserService,
        private global: Global,
        private datePipe: DatePipe,
        private authenticationService: AuthenticationService,
        private router:Router,
        private route:ActivatedRoute,
    ) {
        this.siteForm = this.formBuilder.group({
            status: ["", Validators.compose([Validators.required])],
            remarks: ["", Validators.compose([Validators.required])],
            telecallerId: [''],
            date: [null],
            time: [null]
        });
        this.fetchLeadStatus();
    }
    ngOnInit() {
        this.route.paramMap.subscribe(data=>{
            if(data){
                this.queryParamsFromMenu = data;
            }
        })

        if (this.audienceId !== null && this.audienceId !== undefined) {
            this.getProfile();
        }
        this.userRole = this.authenticationService.getUserRole();
    }

    getProfile() {
        let parentMenu;
        if(this.queryParamsFromMenu){
          parentMenu = this.queryParamsFromMenu['params'].dynamicparam;
        }
        this.reportService.fetchIndividualAudienceData({ id: this.audienceId }).subscribe(data => {
            if (data.status) {
                this.audience = data.data.audienceMasterDTO;
                this.audience.audienceDynamicDataList.forEach(element => {
                    element.editParam = false
                    if (element.paramValue.includes("http://") == true || element.paramValue.includes("https://") == true) {
                        element.paramValue = '<a href=' + element.paramValue + ' target="_blank" >Click to view</a>'
                    }
                });
                this.leadAssignData = data.data.assignDTO;
                if (this.leadAssignData != null) {
                    this.setLeadAssignData();
                    this.fetchUserList();
                }
                this.getOtherParameters();
            }
        });
    }

    setSiteFormValidators(type) {
        const remarksControl = this.siteForm.get('remarks');
        const telecallerIdControl = this.siteForm.get('telecallerId');
        const dateControl = this.siteForm.get('date');
        const timeControl = this.siteForm.get('time');
        this.statusType = type;
        if (type == 'CALL_LATER') {
            telecallerIdControl.setValidators([]);
            remarksControl.setValidators([Validators.required]);
            dateControl.setValidators([Validators.required]);
            timeControl.setValidators([Validators.required]);
            dateControl.setValue(new Date());
            timeControl.setValue(new Date());
        } else if (type == 'FOLLOW_UP') {
            remarksControl.setValidators([Validators.required]);
            telecallerIdControl.setValidators([Validators.required]);
            dateControl.setValidators([Validators.required]);
            timeControl.setValidators([Validators.required]);
            dateControl.setValue(new Date());
            timeControl.setValue(new Date());
        } else {
            remarksControl.setValidators([Validators.required]);
            telecallerIdControl.setValidators([]);
            dateControl.setValidators([]);
            timeControl.setValidators([]);
            dateControl.setValue(null);
            timeControl.setValue(null);
        }
        remarksControl.updateValueAndValidity();
        telecallerIdControl.updateValueAndValidity();
        dateControl.updateValueAndValidity();
        timeControl.updateValueAndValidity();
    }



    formSubmit() {
        let parentMenu;
            if(this.queryParamsFromMenu){
            parentMenu = this.queryParamsFromMenu['params'].dynamicparam;
        } 
        
        this.isSubmit = true;
        if (this.siteForm.value.status == '') {
            this.toastr.error('Please select status.');
            return;
        }
        if (this.siteForm.invalid) {
            this.toastr.error(this.global.MessageRequired);
            this.global.validateAllFormFields(this.siteForm);
            return;
        }
        var isParamMandatory = false;
        this.audience.audienceDynamicDataList.forEach(element => {
            if (element.editParam && element.paramMandatory == 'ACTIVE') {
                isParamMandatory = true;
            }
        });
        if (isParamMandatory) {
            this.siteTabs.tabs[0].active = true;
            this.toastr.error(this.global.MessageRequired);
            return;
        }

        var postData: any;
        postData = Object.assign({}, this.siteForm.value);
        var date = this.datePipe.transform(this.siteForm.value.date, "yyyy-MM-dd");
        var time = this.datePipe.transform(this.siteForm.value.time, "HH:mm:ss");
        if (
            (Number(this.datePipe.transform(this.minDate, "yyyyMMdd")) == Number(this.datePipe.transform(this.siteForm.controls.date.value, "yyyyMMdd"))) &&
            Number(this.datePipe.transform(this.minDate, "HHmmss")) > Number(this.datePipe.transform(this.siteForm.value.time, "HHmmss"))
        ) {
            this.toastr.error('Please select correct time.');
            return;
        }
        postData.followTime = date != null ? date + ' ' + time : null;
        postData.audMasterId = this.audienceId;
        postData.telecallerId = this.statusType == 'FOLLOW_UP' ? this.siteForm.value.telecallerId : null;
        this.isSubmitted = true;
        this.reportService.upsertUserLeadAssign(postData, parentMenu).subscribe(data => {
            this.modalRef.hide();
            if (data.status) {
                this.toastr.success('Details Saved Successfully', 'Success');
                this.reportService.leadReportsData = undefined;
                this.reportService.leadCountsReportsData = undefined;
                this.audienceProfileClose();
            } else {
                this.toastr.error(data.error_msgs, 'Error');
            }
        });
    }

    setLeadAssignData() {
        this.leadStatusList.forEach(element => {
            if (element.stageName == this.leadAssignData.status)
                this.setSiteFormValidators(element.stageType);
        });
        if (this.leadAssignData.followTime != null)
            var followtime = this.leadAssignData.followTime.split(' ', 2);
        this.siteForm.patchValue({
            status: this.leadAssignData.status,
            telecallerId: this.leadAssignData.userId,
            date: followtime != undefined ? new Date(followtime[0]) : null,
            time: this.leadAssignData.followTime
            // remarks: this.leadAssignData.remarks
        });
    };

    copyText(val: string) {
        this.global.copyText(val);
        this.toastr.success('Copied to clipboard');
    }

    fetchLeadStatus() {
        let parentMenu;
            if(this.queryParamsFromMenu){
            parentMenu = this.queryParamsFromMenu['params'].dynamicparam;
        }  
        this.leadStatusList = [];
        this.projectService.getLeadStatusData(parentMenu).then(data => {
            this.leadStatusList = data;
        })
    }

    fetchUserList() {
        this.telecallerList = [];
        this.userService.getUsersData().then(data => {
            if (data != undefined) {
                this.telecallerList = data.filter((item: SubUserObj) => {
                    if (item.idList != null && item.idList.filter(x => x == this.audience.formId).length > 0) {
                        return item;
                    }
                });
            }
            if (this.userRole == 1) {
                this.telecallerList.push({
                    id: this.authenticationService.getUserId(),
                    userName: this.authenticationService.getUserData().userName,
                    userProfileName: this.authenticationService.getUserData().userName
                });
            };
            if (this.telecallerList.filter(element => element.id == this.leadAssignData.userId).length == 0) {
                this.telecallerList.push(data.find(element => element.id == this.leadAssignData.userId))
            };
        });
    }

    getparemType(param, index) {
        this.projectService.fetchAttributeList().then(data => {
            var list = this.projectService.filterAttributeList([this.audience.source]);
            var type = list.find(x => x.id === param.id).paramDatatype;
            this.audience.audienceDynamicDataList[index].paramDatatype = type;
            if (type == 'Date') {
                this.audience.audienceDynamicDataList[index].paramValue = new Date(this.audience.audienceDynamicDataList[index].paramValue);
            }
        });
    }

    getOtherParameters() {
        this.otherParameters = [];
        this.projectService.fetchAttributeList().then(data => {
            var list = this.projectService.filterAttributeList(['all']);
            var otherParameters = list.filter(x => !this.audience.audienceDynamicDataList.filter(y => y.id === x.id).length);
            var otherValueChangeParameters = [];
            this.otherParameters = [];
            otherValueChangeParameters = otherParameters.filter(y => y.paramValueChange === "ACTIVE");
            otherValueChangeParameters.forEach(el => {
                if (el.paramMandatory === "ACTIVE") {
                    el.paramValue = '';
                    el.editParam = true;
                    this.audience.audienceDynamicDataList.push(el);
                } else {
                    this.otherParameters.push(el);
                }
            });
        });
    }

    updateAudience(param, index) {
        let parentMenu;
        if(this.queryParamsFromMenu){
        parentMenu = this.queryParamsFromMenu['params'].dynamicparam;
        }
        if (param.paramValue == undefined || param.paramValue == '') {
            this.toastr.error('Field is required', 'Error');
            return;
        }
        if (param.paramDatatype == "Date") {
            param.paramValue = this.datePipe.transform(param.paramValue, "yyyy-MM-dd HH:mm:ss");
        }
        let postData = {
            projectKey: this.projectService.projectDetails.projectKey,
            audienceId: this.audience.audienceId,
            paramList: [
                { paramKey: param.paramKey, paramValue: param.paramValue }
            ]
        }
        this.projectService.registerAudienceData(parentMenu,postData).subscribe(data => {
            if (data.status) {
                this.toastr.success(data.data, 'Success');
            } else {
                this.toastr.error(data.error_msgs, 'Error');
            }
            this.audience.audienceDynamicDataList[index].editParam = false;
        });
    }

    appendParameter(param, index) {
        param.paramValue = '';
        this.isOtherParametersShow = false;
        this.audience.audienceDynamicDataList.push(param);
        this.otherParameters.splice(index, 1);
        param.editParam = true;
        setTimeout(() => {
            this.scrollToBottom();
        }, 200);
    }

    private scrollToBottom(): void {
        var scrollblock;
        if (this.otherParameters.length % 2 == 1)
            scrollblock = this.scrollblockLeft.nativeElement;
        else
            scrollblock = this.scrollblockRight.nativeElement;

        scrollblock.scroll({
            top: scrollblock.scrollHeight,
            left: 0,
            behavior: 'smooth'
        });
    }

    getcallLogs() {
        if (this.callLogs != undefined) return;
        this.callLogs = [];
        this.reportService.fetchIndividualAudienceCallLog({ mobile: this.audience.mobile, userRoleId: this.authenticationService.getUserRole() }).subscribe(data => {
            if (data.status) {
                this.userService.getUsersData().then(users => {
                    this.callLogs = data.data.list;
                    var subUsers = JSON.parse(JSON.stringify(users));
                    if (this.userRole == 1) {
                        subUsers.push({
                            id: this.authenticationService.getUserId(),
                            userName: this.authenticationService.getUserData().userName,
                            userProfileName: this.authenticationService.getUserData().userName
                        });
                    };
                    this.callLogs.forEach(element => {
                        var telecaller = subUsers.find(x => x.id == element.userId);
                        element.telecaller = telecaller != undefined ? telecaller.userProfileName : '';
                        element.durationMin = this.getTimeInMin(element.duration);
                    });
                });
            } else {
                this.toastr.error(data.error_msgs, 'Error');
            }
        })
    }

    getTimeInMin(value: number): string {
        const minutes: number = Math.floor(value / 60);
        return minutes + '.' + (value - minutes * 60) + ' Min';
    }

    openAll(otherParameters){
        let list = JSON.parse(JSON.stringify(otherParameters));
        list.forEach((item, index)  => {
                this.appendParameter(item,index);

         });
         this.showAllparam = false;
    }
}
