<main>
  <section class="inner-page">
    <div class="inner-page-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <h4><a class="back-link" [routerLink]="['/admin/project-integration/Bulk/Excel Upload']"><img src="assets/images/angle-left.svg"></a> Bulk Upload</h4>
            <p>Bulk Upload : Here you can upload your bulk data through excel sheet. The fields generated in excel sheet will be the same which were defined by you in Category Attributes section.</p>
          </div>
        </div>
      </div>
    </div>

    <div class="inner-page-body project-integration">
      <div class="container-fluid">
            <div class="card">
              <div class="card-body">
                
                <form [formGroup]="formblukupload" (ngSubmit)="saveBlukupload(formblukupload)">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group row">
                        <label class="col-lg-9 col-md-12 col-12 col-sm-12 control-label">
                          <div class="step-title">Step 1.</div>
                          <div>
                            <span class="step-sub-title">Download excel format file.</span>
                            <br/>
                            <span class="step-body">Please download the excel file.</span>
                          </div>
                        </label>
                        <div class=" download-icon col-lg-3 text-right">
                          <!-- <button type="button" (click)="downloadSampleFile()" class="btn btn-border download-new-icon">
                            Download File</button> -->
                            <button type="button" (click)="openAttrPopup()" class="btn btn-border download-new-icon">
                              Download File</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group row">
                        <div class="col-md-12">
                          <div class="form-group row">
                            <label class="col-lg-5 col-md-12 col-12 col-sm-12 control-label">
                              <div class="step-title">Step 2.</div>
                              <div>
                                <span class="step-sub-title">Upload Excel file.</span>
                                <br/>
                                <span class="step-body">Please upload the excel file.</span>
                              </div>
                            </label>
  
                            <div class="col-lg-7 col-md-12 col-12 col-sm-12">
                              <button type="submit" class="btn btn-dark pull-right btn_space_bulk_upld">Save</button>
                        
                              <input class="form-control modal-space pull-right file_input_width f-right" accept=".xlsx" type="file" #fileInput (change)="onFileSelect($event)">
                                <input type="hidden" name="file" formControlName="file" /> 
                            </div>
                          </div>
                        </div>
                      
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="col-md-12">
                <div class="table-user-identitys pr-2 pl-2">
                  <div class="">
                    <h4 class="mb-1">Note</h4>
                    
                    <div class="">
                      <p>1.File format should be xlsx (ms excel format).</p>
                      <p>2.Don’t change the column key names.</p>
                      <p>3.Upload upto 10,000 records at a time.</p>
                      <p>4.You can leave field value as ‘blank’ if you have no relevant data in that column.</p>
                      <p>5.Keep file size upto 2 MB.</p>
                      <p>6.Don’t leave any row complete empty as system will consider it as end of records.</p>
                      <p>7.You need to update some unique values in “audience_id” as this is mandatory field to identify your audience uniquely.</p>
                      <p>8.If your field contains date value, make sure the format should be yyyy-mm-dd.</p>
                    </div>
                  </div>
                </div>
  
              </div>
            </div>         
      </div>
    </div>
  </section>
</main>

<ng-template #confirm>
  <!-- confirm popup -->
  <form>
    <div class="forgot-password-modal">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Upload Status</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body modal-user-management">
        <div class="form-group mb-0 pb-4 pt-4">
          <div class="icon-status text-center">
            <span class="check-icon"></span>
          </div>

          <div class="mt-2 text-center">
            <span class="pt-2 text-center add-btngreen">SUCCESS</span>
          </div>
          <p class="pt-2 text-center">File Successfully upload.</p>
          <div class="text-center  mt-1 mb-0">
            <div class="mt-4 mb-0">
              <button class="btn-dark" type="button" (click)="Done()">Done</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

</ng-template>



<ng-template #error>
  <!-- error popup -->
  <form>
    <div class="forgot-password-modal">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Upload Status</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body modal-user-management">
        <div class="form-group mb-0 pb-4 pt-4">
          <div class="icon-status text-center">
            <span class="times-circle-icon"></span>
          </div>

          <div class="mt-2 text-center">
            <span class="pt-2 text-center add-btnred">ERROR</span>
          </div>
          <p class="pt-2 text-center">{{errormsg}}.</p>
          <div class="text-center  mt-1 mb-0">
            <div class="mt-4 mb-0">
              <button class="btn-dark" type="button" (click)="modalRef.hide()">Upload Again</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

</ng-template>

<ng-template #attrListPopup>  
    <div class="forgot-password-modal excel_download_table_box">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Select excel column</h4>
        <div>
        <!-- <button [disabled]="xlsTable == undefined" type="submit" class="btn btn-border download-new-icon" (click)="downloadFile()">Download</button> -->
        <button aria-label="Close" class="close pull-right" type="button" (click)="modalRef.hide();">
          <span aria-hidden="true">×</span>
        </button>
        </div>
      </div>
      <div class="modal-body">
        <div class="table-reports">
          <div class="table-body padding_60">
            <div class="table-responsive">
              <table class="table" *ngIf="showMapParams.length > 0">
                <tr>
                  <td colspan="2" class="bg_light_white">
                    <input class="btn searchbtn margin-searchbtn" name="search" placeholder="Search" type="text" [(ngModel)]="searchAttribute" autocomplete="off">
                  </td>
                </tr>
                
                <tr>
                  <th>Attribute Name</th>
                  <th class="text-right"><input type="checkbox" [(ngModel)]="masterSelected" (change)="checkUncheckAll($event)" [tooltipAnimation]="true" tooltipPlacement="bottom" tooltip="Select All"></th>
                </tr>
                
                <tr *ngFor="let list of showMapParams | myFilter:searchAttribute;">
                  <td>{{list.paramName}} <p class="d-inline" *ngIf="(list.paramKey=='city' || list.paramKey=='state' || 
                    list.paramKey=='message' || list.paramKey=='country' || list.paramKey=='pincode' || list.paramKey=='city' || list.paramKey=='product_name' ||  list.paramKey=='address') ">(default)</p></td>
                  <td class="text-right"><input type="checkbox" [name]="list.paramKey" [(ngModel)]="list.checked" (change)="checkUncheckVal($event,list)"></td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer bulk_upld_mdl_footer">
        <button type="submit" [disabled]="xlsTableCol.length == 0" class="btn bg_333 txt_fff" (click)="downloadFile()">Download</button>
      </div>
    </div>
</ng-template>
<div *ngIf="xlsTableCol.length > 0" class="d-none">
<table id="xlsTable" #xlsTable>
<tr>
  <th *ngFor="let th of xlsTableCol">
    {{th.paramKey}}
  </th>
</tr>
</table>
</div>