import { Component, OnInit, TemplateRef, OnDestroy, ViewChild, Input } from '@angular/core';
import { BsModalService, BsModalRef, TabsetComponent } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../../../core/services/user.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Global } from '../../../../shared/models/global.model';
import { noWhitespaceValidator } from '../../../../shared/models/no-whitespace-validator';
import { pluginsObj, RoleObj, SubUserObj, MenuObj } from '../../../../core/modal/user';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { AppConstants } from '../../../../constants/app.constants';
import { Location } from '@angular/common';
import { ProjectService } from '../../../../core/services/project.service';

@Component({
    selector: 'app-user-management',
    templateUrl: './user-management.component.html',
    styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit, OnDestroy {
    modalRef: BsModalRef;
    confirmModalRef: BsModalRef;
    addForm: FormGroup;
    editForm: FormGroup;
    existingUsers: SubUserObj[] = [];
    telecallerUsers: any = [];
    managerUsers: SubUserObj[] = [];
    userData: any = {};
    pluginList: pluginsObj[] = [];
    selectedplugin = [];
    isSubscribe: boolean = true;
    @ViewChild('siteTabs') siteTabs: TabsetComponent;
    @ViewChild('roleTabs') roleTabs: TabsetComponent;

    roleList: RoleObj[] = [];
    menuList: MenuObj[] = [];
    isLandingPage: boolean = false;

    selectedActions: Number[] = []
    selectedLandingPage: Number;
    selectedRole: Number;
    selectedParentId: Number[] = [];
    activeTabHeading: string = '';
    selectedRoleTypeId: Number;
    otherPluginIds = [];
    userStatus:any;
    isTelecallerChanged: boolean = false;
    allUsersInEditForm:any=[]
    userRoleChange:boolean = false;
    userList:any;
    loggedInUserData:any;
    constructor(
        private modalService: BsModalService,
        private toastr: ToastrService,
        private global: Global,
        private formBuilder: FormBuilder,
        private userService: UserService,
        private authenticationService: AuthenticationService,
        private location:Location,
        private projectService:ProjectService
    ) { 

        this.isSubscribe = this.authenticationService.isPageSubscribe('User Management');
    }

    ngOnInit() {
        this.prepareAddForm();
        this.prepareEditForm();

        if (this.isSubscribe) {
            this.getUsers();
            this.getPluginListForLead();
            // this.fetchUserRole();
            this.changeTab('Roles')
        }
    }

    editRoleResponse(event){
       if(event){
        this.getUsers();
       }
    }


    back()
    {
        this.location.back();
    }

    ngOnDestroy(){
        if (this.modalRef) {

            this.modalRef.hide();
        }
    }

    prepareAddForm() {
        this.addForm = this.formBuilder.group({
            fullName: ['', Validators.compose([Validators.required, noWhitespaceValidator, Validators.pattern(this.global.AlphaPattern)])],
            email: ['', Validators.compose([Validators.required, noWhitespaceValidator, Validators.pattern(this.global.EmailPattern)])],
            type: ['', Validators.required],
        });
    }


    prepareEditForm() {
        var leadStatus = this.userData.leadStatus == 'ACTIVE';
        this.editForm = this.formBuilder.group({
            id: [this.userData.id, Validators.required],
            type: [this.userData.userRoleId, Validators.required],
            leadStatus: [leadStatus],
            idList: [''],
            userList: [''],
            telecallerId: [''],
            userRoleList:[''],
            wabaUser:['']
        });
    }

    get af() { return this.addForm.controls; }
    get ef() { return this.editForm.controls; }

    

    openModal(data: SubUserObj, template: TemplateRef<any>) {
        
        this.isTelecallerChanged = false;
        this.userData = data;
        this.selectedRoleTypeId = this.userData.roleTypeId;
        this.prepareEditForm();
        this.modalRef = this.modalService.show(template, { backdrop: 'static' });                
        let checkUserLevel = data.level;
        if(this.selectedRoleTypeId == 1){
            if(checkUserLevel){
                this.allUsersInEditForm = this.existingUsers.filter((user)=>{
                    return user.level == checkUserLevel+1 && user.status != 'INACTIVE' && user.parentIds.find(x=>x== this.userData.userRoleId);
            })
            }
            var userList = []
            if(data.userList){
            data.userList.forEach(id => {
                        if (this.existingUsers.find(x => x.id == id))
                            userList.push(id);
                    });
            this.editForm.controls.userList.setValue(userList);
            }
        }

        var idList = []
        this.otherPluginIds = []
        if(data.idList){
            data.idList.forEach(id => {
                if (this.pluginList.find(x => x.id.toString() == id)) {
                    idList.push(id);
                } else {
                    this.otherPluginIds.push(id)
                }
            })
            this.editForm.controls.idList.setValue(idList);
        }
    }

    openConfirmModal(template: TemplateRef<any>) {
        this.confirmModalRef = this.modalService.show(template, { backdrop: 'static' });
    }


    updateWabaUser(){

        let checkStatus = this.editForm.controls.wabaUser.value
        if(checkStatus == true){
            this.userService.createWabaUser({email:this.userData.email}).subscribe(data=>{
                if(data.status){
                    this.toastr.success('Success');
                    this.existingUsers.forEach(elem=>{
                        if(elem.id == this.userData.id){
                        elem.wabaUser = true;
                        }
                    })
                } else{
                    this.toastr.error(data.error_msgs);
                }
            })
        } else if(checkStatus == false){
            this.userService.removeWabaUser({email:this.userData.email}).subscribe(data=>{
                if(data.status){
                    this.toastr.success('Removed')
                    this.existingUsers.forEach(elem=>{
                        if(elem.id == this.userData.id){
                        elem.wabaUser = false;
                        }
                    })
                } else{
                    this.toastr.error(data.error_msgs);
                }
            })
        }
    }

   async getUsers() {
        
        let userData = this.authenticationService.getUserData();
        this.loggedInUserData = userData;
        let existingUsers = [];
       await this.userService.getUsersData('all','reload').then(data => {
            if (data != undefined) {
                existingUsers = data;
            }
        });
        if(userData.userRoleId == 1){
            this.existingUsers = existingUsers
        } else
            {                
            this.projectService.getChildUserData({}).subscribe(data=>{                
                this.existingUsers = []
                if(data.status){
                    for(let i=0; i<=data.data.userList.length;i++){
                        if(data.data.userList[i] == data.data.id){
                            delete data.data.userList[i];
                        }
                    }

                    data.data.userList = data.data.userList.filter(p=>{
                        return p!= null;
                    })
                    data.data.userList.forEach(id=>{
                        
                        let chekInUser = existingUsers.find(x=>x.id == id);
                        if(chekInUser){
                            this.existingUsers.push(chekInUser);
                        }
                    });                    
                }
            })   
        }
        

        // this.userService.getUsersData('all').then(data => {
        //     if (data != undefined) {
        //         this.telecallerUsers = [];
        //         this.existingUsers = [];
        //         this.managerUsers = [];
        //         this.existingUsers = data;
        //         console.log(this.existingUsers);
        //         let userId = this.authenticationService.getUserData().userId;
        //         console.log(userId);
                
                
        //         // if (this.authenticationService.getUserRoleType() != null && this.authenticationService.getUserRoleType() == 1) {
        //         //     this.existingUsers = data;
        //         // }
        //         // else {
        //         //     data.forEach(user => {
        //         //         if (user.id == this.authenticationService.getUserId() && user.userList != undefined) {
        //         //             data.forEach(tmpUsrObject => {
        //         //                 if (user.userList.includes(tmpUsrObject.id)) {
        //         //                     this.existingUsers.push(tmpUsrObject);
        //         //                 }
        //         //             });
        //         //         }
        //         //     });
        //         //     // this.existingUsers = data;
        //         // }
        //         // this.existingUsers.forEach(user => {
        //         //     if (user.status == "ACTIVE" && (user.roleTypeId == AppConstants.TELECALLER_ROLE_TYPE || user.roleTypeId == AppConstants.MARKETER_ROLE_TYPE)) {
        //         //         this.telecallerUsers.push(user);
        //         //     };
        //         // });
        //         // this.existingUsers.forEach(user => {
        //         //     if (user.roleTypeId == AppConstants.MANAGER_ROLE_TYPE) {
        //         //         this.managerUsers.push(user);
        //         //     };
        //         // });
        //     }
        // });
    }

    addUser() {
        if (this.addForm.invalid) {
            this.toastr.error(this.global.MessageRequired); this.global.validateAllFormFields(this.addForm);
            return;
        }
        this.userService.addUser({ email: this.af.email.value, userName: this.af.fullName.value, userRoleId: this.af.type.value }).subscribe(data => {
            if (data.status) {
                this.prepareAddForm();
                this.toastr.success('User Added');
                this.userService.subUserList = undefined;
                this.getUsers();
            } else {
                this.toastr.error(data.error_msgs, 'Error');
            }
        });
    }

    editUser() {
        if (this.selectedRoleTypeId == 3 && this.editForm.controls.leadStatus.value && (this.editForm.controls.idList.value == undefined || this.editForm.controls.idList.value == '')) {
            this.toastr.error('Please select plugin.');
            this.global.validateAllFormFields(this.editForm);
            return;
        };
        if (this.editForm.invalid) {
            this.toastr.error(this.global.MessageRequired); this.global.validateAllFormFields(this.editForm);
            return;
        }
        var idList = this.ef.idList.value == '' ? [] : this.ef.idList.value;
        idList = idList.concat(this.otherPluginIds);
        var paremData = {
            id: this.ef.id.value,
            userRoleId: this.ef.type.value,
            idList: idList,
            userList: this.ef.userList.value == '' ? [] : this.ef.userList.value,
            telecallerId: this.isTelecallerChanged ? this.ef.telecallerId.value : null
        }
        this.userService.editUser(paremData).subscribe(data => {
            this.modalRef.hide();
            if (data.status) {
                this.toastr.success('User Updated');
                this.userService.subUserList = undefined;
                this.projectService.getUserSourceData('reload');
                this.getUsers();
            } else{
                this.toastr.error(data.error_msgs);
            }
        });
    }

    removeUser() {
        this.userService.removeUser({ id: this.userData.id }).subscribe(data => {
            this.confirmModalRef.hide();
            if (data.status) {
                this.modalRef.hide();
                this.toastr.success('User Removed');
                this.userService.subUserList = undefined;
                this.getUsers();
            } else {
                this.toastr.error(data.error_msgs, 'Error');
            }
        });
    }
    toggleUserStage(){
        {
            this.userService.toggleUserStatus({ id: this.userData.id }).subscribe(data => {
                this.confirmModalRef.hide();
                if (data.status) {
                    this.modalRef.hide();
                    this.toastr.success('User is '+ this.userStatus +' now.');
                    this.userService.subUserList = undefined;
                    this.getUsers();
                } else {
                    this.toastr.error(data.error_msgs, 'Error');
                }
            });
        }
    }

    updateLeadStatus() {
        var leadStatus = this.editForm.controls.leadStatus.value ? 'ACTIVE' : 'INACTIVE';
        this.userService.leadStatusSubUser({ id: this.userData.id, leadStatus: leadStatus }).subscribe(data => {
            this.modalRef.hide();
            if (data.status) {
                this.toastr.success('Status Update');
            }
            this.userService.subUserList = undefined;
            this.getUsers();
        });
    }

    sendEmail(user) {
        user.status = 'PENDING';
        this.userService.sendVerificationLink({ id: user.id }).subscribe(data => {
            if (data.status) {
                this.toastr.success('Verification link resend');
            }
        });
    }

    getPluginListForLead() {
        this.userService.getPluginListForLead().then(data => {
            if (data != undefined)
                this.pluginList = data;
        });
    }

    getMenuList() {
        this.userService.getMenuActionData().then(data => {
            if (data != undefined) {
                this.selectedParentId = [];
                this.menuList = this.setMenuItem(data);
                this.selectedParentId = this.selectedParentId.filter((el, i, a) => i == a.indexOf(el))
                this.selectedParentId.forEach(data => {
                    this.selecteParentMenu(data);
                });
            }
        });
    }

    setMenuItem(menu): MenuObj[] {
        menu.forEach(element => {
            element.isSubMenuAdded = false;
            if (element.menuList != undefined) {
                element.menuList = this.setMenuItem(element.menuList);
            } else {
                element.isLandingPage = element.id === this.selectedLandingPage;
                if (element.menuActionList != undefined){
                    element.menuActionList.forEach(a => {
                        a.isSelecte = false;
                        if (this.selectedActions.filter(x => x === a.id).length > 0) {
                            a.isSelecte = true;
                            element.isSubMenuAdded = true;
                            if (element.parentId > 0) {
                                this.selectedParentId.push(element.parentId)
                            }
                        }
                    });
                    element.hideElem = false;
                } else{
                    element.hideElem = true;
                }
            }
        });
        return menu
    }

    selecteParentMenu(id) {
        this.menuList = this.setParentMenu(id, this.menuList);
    }

    setParentMenu(id, data) {
        data.forEach(u => {
            if (u.menuList != undefined) {
                if (u.id == id) {
                    u.isSubMenuAdded = true;
                    if (u.parentId > 0) {
                        this.selecteParentMenu(u.parentId)
                    }
                }
                u.menuList = this.setParentMenu(id, u.menuList);
            }
        });
        return data;
    }

    updateLandingPage(data) {        
        this.selectedLandingPage = undefined;
        this.menuList = this.setLandingPage(data.value, data.id, this.menuList);
    }

    setLandingPage(value, id, data): MenuObj[] {
        data.forEach(u => {
            if (u.menuList != undefined) {
                u.menuList = this.setLandingPage(value, id, u.menuList);
            } else {
                u.isLandingPage = u.id == id ? value : false;
                if (u.isLandingPage)
                    this.selectedLandingPage = u.id;
            }
        });
        return data;
    }

    changeTab(type) {
        this.activeTabHeading = type;
        if (this.activeTabHeading != 'Roles')
            this.fetchUserRole();
    }

    async fetchUserRole() {
        this.roleList = [];
        this.userService.getUserRoleData().then(data => {
            if (data != undefined) {
                this.roleList = data;
                let userLevel;
                this.authenticationService.getProfile().then(data=>{
                    userLevel = data.users.level;
                    if(userLevel){
                        this.roleList = this.roleList.filter(p=>{
                            return p.level > userLevel;
                        })
                    }
                });
                if (this.activeTabHeading == 'Permission' && this.roleList.length > 0) {
                    setTimeout(() => {
                        var id = this.roleTabs.tabs.find(x => x.active);
                        if(id){
                            this.changeRoleTab(id.id);
                        }
                    }, 300);
                }
            }
        })
    }

    changeRoleTab(id) {
        this.selectedRole = id;
        this.userService.getRolePermission({ userRoleId: this.selectedRole }).subscribe(data => {
            if (data.status) {
                this.selectedActions = data.data.userPermissionId;
                this.selectedLandingPage = data.data.landingPageId;
                this.getMenuList();
            }
        });
    }

    updateActionList(data) {
        if (data.value) {
            this.selectedActions.push(data.id);
        } else {
            var index = this.selectedActions.findIndex(x => x === data.id);
            this.selectedActions.splice(index, 1);
        }
        if (data.list.filter(x => x.isSelecte).length == 0) {
            // this.selecteParentMenu(data.list[0].menuId,false);
        }
    }

    updateRoleActions() {
        if (this.selectedActions.length == 0) {
            this.toastr.error('Please select Actions.', 'Error')
            return
        }
        if (this.selectedLandingPage == undefined) {
            this.toastr.error('Please select landing page.', 'Error')
            return
        }
        var formData = {
            userRoleId: this.selectedRole,
            userPermissionId: this.selectedActions,
            landingPageId: this.selectedLandingPage
        }
        this.userService.upsetRolePermission(formData).subscribe(data => {
            if (data.status) {
                this.toastr.success(data.data);
            } else {
                this.toastr.error(data.error_msgs)
            }
        })
    }

    getRoleTypeId(value) {
        let roles = []
        let userList = []
        this.roleList.forEach(elem=>{
            if(elem.parentRole && elem.parentRole !='0' ){
                let elemParentRole = elem.parentRole.split(',');
                let findParentRole = elemParentRole.find(x=>x == value);
                if(findParentRole){
                    roles.push(elem);
                    this.existingUsers.forEach(users=>{
                        if(users.userRoleId == elem.id){
                            userList.push(users);
                        }
                    });
                }
            }
        });
        
        this.userRoleChange = false;
        let checkRoleId = this.roleList.find(x => x.id == this.userData.userRoleId);
        let checkValue = this.roleList.find(x => x.id == value);
        this.allUsersInEditForm = userList;
        this.selectedRoleTypeId = checkValue.roleTypeId
        if(checkRoleId && checkRoleId.id == value){
            this.userRoleChange = false;
            this.allUsersInEditForm = this.existingUsers.filter(p=>{
                return p.level == this.userData.level+1 && p.id != this.userData.id;
            });
        }
        else if(checkValue && checkValue.roleTypeId == 1){            
            this.userRoleChange = true;             
            this.telecallerUsers = this.existingUsers.filter(p=>{
                return p.roleTypeId != 1 && p.level == this.userData.level
            });

            // this.allUsersInEditForm = this.existingUsers.filter(p=>{
            //     return p.level == checkValue.level+1 && p.id != this.userData.id;
            // });
        }
        this.ef.telecallerId.updateValueAndValidity();
        this.ef.idList.setValue('');
        this.ef.userList.setValue('');
        this.otherPluginIds = [];
    }
}