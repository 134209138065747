import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { delay } from 'rxjs/operators';
import { ApiConstants } from '../../constants/api.constants';
import { DatePickerObj } from '../modal/datePicker';
import { CampaignStats, LeadsStats  } from '../modal/dashboard';
import { BaseService } from './base.service';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
    leadsCollectedData: LeadsStats;
    campaignStatsData: CampaignStats;
    leadsDays:DatePickerObj;
    campaignStatsDays:DatePickerObj;
    leadsSource: string;
    sortByDate:any;
    constructor(
        private baseService: BaseService,
        private datePipe: DatePipe,
        private authenticService:AuthenticationService
    ) { 
    }
    
    fetchCampaignStats(body: any,url) {        
        return this.baseService.postRequest(url + ApiConstants.FETCH_CAMPAIGN_STATS, body, false);
    }

    fetchLeadsCollected(body: any) {
        return this.baseService.postRequest(ApiConstants.FETCH_LEADS_COLLECTED, body, false);
    }

    fetchCampaignStatsIndividual(body: any , url) {
        return this.baseService.postRequest(url + ApiConstants.FETCH_CAMPAIGN_STATS_INDIVIDUAL, body);
    }


    async getLeadsCollectedData(date:DatePickerObj, source, sortby, category?){
        if(this.leadsDays != undefined && 
            (!this.leadsDays.startDate.isSame(date.startDate) || 
            !this.leadsDays.endDate.isSame(date.endDate) || 
            source != this.leadsSource)){
                this.leadsCollectedData = undefined;
            }
            if(category){
                this.leadsCollectedData = undefined;
            }
        if(this.leadsCollectedData == undefined){
            var postData  = {
                fromDate: this.datePipe.transform(date.startDate, "yyyy-MM-dd"),
                toDate: this.datePipe.transform(date.endDate, "yyyy-MM-dd"),
                source: source,
                reportDateType:sortby,
                category:category
            }
            
            var requestData = await this.fetchLeadsCollected(postData).pipe(delay(200)).toPromise();
            this.leadsDays = date;
            this.leadsSource = source;
            this.leadsCollectedData = requestData.data;
        };
        return this.leadsCollectedData;
    }

    async getCampaignStatsData(date:DatePickerObj,url,campType,reload){
        if(reload == 'reload'){
            this.campaignStatsData = undefined;
        }
        if(this.campaignStatsDays != undefined &&
            (!this.campaignStatsDays.startDate.isSame(date.startDate) || 
            !this.campaignStatsDays.endDate.isSame(date.endDate))
            ){
                this.campaignStatsData = undefined;
            }
        if(this.campaignStatsData == undefined){
            var postData  = {
                fromDate: this.datePipe.transform(date.startDate, "yyyy-MM-dd"),
                toDate: this.datePipe.transform(date.endDate, "yyyy-MM-dd"),
                campType:JSON.parse(campType)
            }
            var requestData = await this.fetchCampaignStats(postData,url).pipe(delay(200)).toPromise();
            this.campaignStatsDays = date;
            this.campaignStatsData = requestData.data ? requestData.data : {error_msg:requestData.error_msgs};
        };
        return this.campaignStatsData;
    }
  
}