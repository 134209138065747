import { Component, OnInit, TemplateRef, OnDestroy, ViewChild, Renderer2 } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Global } from '../../../../shared/models/global.model';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { noWhitespaceValidator } from '../../../../shared/models/no-whitespace-validator';
import { SeoService } from '../../../../../../src/app/seo.service';
import { TabsetComponent } from 'ngx-bootstrap';
import { PathConstants } from '../../../../../app/constants/path.constants';
import { UserObj } from '../../../../core/modal/user';
import { AppConstants } from '../../../../constants/app.constants';
import { SpinnerService } from '../../../../core/services/spinner.service';
import { environment } from '../../../../../../src/environments/environment';

declare var grecaptcha: any;

@Component({
    selector: 'app-sign-in',
    templateUrl: './sign-in.component.html',
    styleUrls: ['./sign-in.component.scss']
})

export class SignInComponent implements OnInit, OnDestroy {

    loginForm: FormGroup;
    signupForm: FormGroup;
    forgotPwdForm: FormGroup;
    challengeForm: FormGroup;

    challengeName: string;
    challengeAttemptId: string;
    sessionListView:any;
    sessionTitle:any;
    returnUrl: string;
    editAccount: string;
    recaptchaResponse: string;
    isChallengeRequired: boolean = false;
    otpRequestId: string = '';
    challengeLabel: string = '';
    activeTab: string = "Sign in";
    environmentName:string = environment.name;
    modalRef: BsModalRef;
    @ViewChild('siteTabs') pluginTabs: TabsetComponent;
    @ViewChild('sessionList') sessionList: TemplateRef<any>;
    constructor(private modalService: BsModalService,
        private formBuilder: FormBuilder,
        private router: Router,
        private global: Global,
        private authenticationService: AuthenticationService,
        private toastr: ToastrService,
        private seoService: SeoService,
        private route: ActivatedRoute,
        private spinner: SpinnerService,
        private renderer: Renderer2
    ) {
        this.seoService.setSeoData('A Fresh Approach to Customer Engagement - Cronberry', 'Cronberry is the most affordable and simplest tool to engage users. Create a new account with an email address and secure password. These details will be required to access your account.');
        this.route.queryParams.subscribe(params => {
            if (params.type == 'signout') {
                this.authenticationService.logout();
                this.router.navigate(['/admin/sign-in']);
                setTimeout(() => {
                    window.location.reload();
                }, 100);
            } else {
                if (this.authenticationService.getUserId() != undefined && this.authenticationService.getProjectId() != undefined) {
                    if (this.authenticationService.getProjectId() == undefined) {
                        this.router.navigate([PathConstants.CREATE_ACCOUNT]);
                    } else {
                        this.router.navigate(['/admin/' + this.authenticationService.getLandingPage()]);
                    }
                }
            }
        });

    }

    changeTab($event) {
        this.activeTab = $event.heading;
    }

    openModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template, { backdrop: 'static' });
    }

    loadRecaptchaScript() {
        this.renderer.setProperty(window, 'onRecaptchaSuccess', this.onRecaptchaSuccess.bind(this));
        this.renderer.setProperty(window, 'onRecaptchaExpired', this.onRecaptchaExpired.bind(this));
        const script = this.renderer.createElement('script');
        script.src = AppConstants.GOOGLE_RECAPTCHA_API_URL;
        script.async = true;
        script.defer = true;
        this.renderer.appendChild(document.body, script);
    }

    removeRecaptchaScript() {
        const scripts = document.querySelectorAll('script[src="' + AppConstants.GOOGLE_RECAPTCHA_API_URL + '"]');
        scripts.forEach(script => this.renderer.removeChild(document.body, script));
    }

    onRecaptchaSuccess(response: string) {
        this.recaptchaResponse = response;
    }

    onRecaptchaExpired() {
        this.recaptchaResponse = undefined;
    }

    ngOnDestroy() {
        this.removeRecaptchaScript();
        if (this.modalRef) {
            this.modalRef.hide();
        }
    }

    ngOnInit() {
        console.log(this.environmentName)
        this.loadRecaptchaScript();
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.compose([Validators.required, noWhitespaceValidator, Validators.pattern(this.global.EmailPattern)])],
            password: ['', Validators.compose([Validators.required, noWhitespaceValidator, Validators.minLength(2), Validators.maxLength(32)])],
        });

        this.signupForm = this.formBuilder.group({
            email: ['', Validators.compose([Validators.required, noWhitespaceValidator, Validators.pattern(this.global.EmailPattern)])],
            password: ['', Validators.compose([Validators.required, noWhitespaceValidator, Validators.minLength(0), Validators.maxLength(32)])],
            confirmPassword: ['', Validators.compose([Validators.required, noWhitespaceValidator, Validators.minLength(0), Validators.maxLength(32)])],
        });

        this.forgotPwdForm = this.formBuilder.group({
            email: ['', Validators.compose([Validators.required, noWhitespaceValidator, Validators.pattern(this.global.EmailPattern)])],
        });

        this.challengeForm = this.formBuilder.group({
            challengeCode: ['', Validators.compose([Validators.required])],
        });

    }

    get f() { return this.loginForm.controls; }
    get sf() { return this.signupForm.controls; }
    get fp() { return this.forgotPwdForm.controls; }
    get cf() { return this.challengeForm.controls; }




    onSubmitChallenge() {
        if (this.challengeForm.invalid) {
            this.toastr.error(this.global.MessageRequired);
            return;
        } else if (this.cf.challengeCode.value.toString().length != 6) {
                        this.toastr.error("Allow Only 6 digit");
                        return;
        }

        this.authenticationService.validateChallenge({ challengeValue: this.cf.challengeCode.value, lastAttemptId: this.challengeAttemptId, challengeName: this.challengeName, otpRequestId: this.otpRequestId }).subscribe(data => {
            if (data.status) {
                const responseData = data.data;
                localStorage.setItem('userId', responseData.userId);
                localStorage.setItem('authToken', responseData.authToken);
                if (undefined != responseData.projectId) {
                    localStorage.setItem('projectId', responseData.projectId);
                }
                
                this.toastr.success("Login Successfully")
                if (data.data.status == 0) {
                    this.router.navigate([PathConstants.CREATE_ACCOUNT]);
                } else {
                    this.authenticationService.getProjectDetail();
                }
            } else {
                this.toastr.error(data.error_msgs, 'Error');
            }
            this.spinner.hide();
        })
    }

    logoutSession(sessionId?,type?){
        let sessionIdsList = [];
        if(type == 'single'){
            sessionIdsList.push(sessionId)
        } else{
            this.sessionListView.forEach(element => {
                sessionIdsList.push(element.sessionId);
            });
        }
        let postData = {sessionIds:sessionIdsList}
        this.authenticationService.logoutMaxSession(postData).subscribe(data=>{
            if(data.status){
                this.modalRef.hide();
            }
        })
    }

    onSubmit() {
        this.sessionListView = undefined;
        this.sessionTitle = undefined
        if (this.loginForm.invalid) {
            this.toastr.error(this.global.MessageRequired); this.global.validateAllFormFields(this.loginForm);
            this.refreshRecaptcha()
            return;
        }        

        this.authenticationService.login({ username: this.f.username.value, password: this.f.password.value, webToken: localStorage.getItem("token") }).subscribe(data => {
            if (data.status) {
                console.log(data);
                if(data.data.sessionList){
                    this.toastr.error(data.data.title);
                    this.sessionListView = data.data.sessionList;
                    this.sessionTitle = data.data.title
                    localStorage.setItem('userId', data.data.userId);
                    this.modalRef = this.modalService.show(this.sessionList, { backdrop: 'static' });
                    return
                }
                const responseData = data.data;
                if (undefined == responseData.authToken) {
                    this.isChallengeRequired = true;
                    this.challengeLabel = responseData.message;
                    localStorage.setItem('userId', responseData.userId);
                    this.challengeAttemptId = responseData.attemptId;
                    this.challengeName = responseData.challengeName;
                    if (this.challengeName == "OTP") {
                        this.otpRequestId = responseData.otpRequestId;
                    }
                } else {
            
                    localStorage.setItem('userId', responseData.userId);
                    localStorage.setItem('authToken', responseData.authToken);
                    if (undefined != responseData.projectId) {
                        localStorage.setItem('projectId', responseData.projectId);
                    }
                    this.toastr.success("Login Successfully")
                    // this.authenticationService.getProjectDetail();
                    if (data.data.status == 0) {
                        this.router.navigate([PathConstants.CREATE_ACCOUNT]);
                    } else {
                        this.authenticationService.getProjectDetail();
                    }
                    // window.open("/admin/dashboard", "_self");
                }

                // var usersDTO = data.data.usersDTO;
                // var userProjectsDTO = data.data.userProjectsDTO;
                // var userData: UserObj = {
                //     authToken: usersDTO.authToken,
                //     userId: usersDTO.id,
                //     email: usersDTO.email,
                //     accountCreated: usersDTO.status == "ACTIVE",
                //     userRoleId: usersDTO.userRoleId,
                //     userRoleName: usersDTO.userRoleName,
                //     emailVerified: usersDTO.emailVerified == "VERIFIED"
                // }
                // localStorage.setItem('userId', userData.userId);
                // localStorage.setItem('authToken', userData.authToken);
                // if (userProjectsDTO != null) {
                //     userData.projectId = userProjectsDTO.id;
                //     // userData.token = userProjectsDTO.token
                //     localStorage.setItem('projectId', userProjectsDTO.id);
                //     // localStorage.setItem('token',userProjectsDTO.token);
                // }
                // if (data.data.userProfileDTO != undefined && data.data.userProfileDTO != null) {
                //     userData.userName = data.data.userProfileDTO.firstName + ' ' + data.data.userProfileDTO.lastName;
                // }
                // this.authenticationService.setUserdata(userData);
                // if (data.data.userProfileDTO == null) {
                //     this.router.navigate([PathConstants.CREATE_ACCOUNT]);
                // } else {
                //     this.authenticationService.getProjectDetail();
                // }
                this.spinner.hide();
            } else {
                this.spinner.hide();
                this.toastr.error(data.error_msgs, 'Error');
            }
            this.refreshRecaptcha()
        });

    }

    checkPassLen(event) {
        let e_len = event.target.value;
        if (e_len.length > 19) {
            this.toastr.error('Password limit exceed');
            return
        }
        return true;

    }

    refreshRecaptcha() {
        if (grecaptcha) {
            grecaptcha.reset(0);
            // grecaptcha.reset(1);
            this.recaptchaResponse = undefined;
        }
    }

    onSignUp() {
        if (this.signupForm.controls.email.status == 'INVALID') {
            this.toastr.error('Please enter valid email');
            this.refreshRecaptcha();
            return;
        }

        if (this.signupForm.invalid) {
            this.toastr.error(this.global.MessageRequired); this.global.validateAllFormFields(this.signupForm);
            this.refreshRecaptcha();
            return;
        }

        if (undefined == this.recaptchaResponse) {
            this.toastr.error(this.global.GCAPTCHARequired);
            return;
        }

        var postData = {
            confirmPassword: this.sf.confirmPassword.value,
            email: this.sf.email.value,
            password: this.sf.password.value,
            userRoleDTO: { id: AppConstants.ADMIN_ROLEID },
            captchaToken: this.recaptchaResponse

        }

        let message = this.checkPasswordValidity(this.sf.password.value);
        if(message){
            this.toastr.error(message);
            return;
        }

        this.authenticationService.signup(postData).subscribe(data => {
            if (data.status) {
                var userData: UserObj = {
                    authToken: data.data.authToken,
                    userId: data.data.userId,
                    email: data.data.email,
                    accountCreated: data.data.status == "ACTIVE",
                    userRoleId: data.data.userRoleId,
                    userRoleName: data.data.userRoleName,
                    emailVerified: data.data.emailVerified == "VERIFIED"
                }
                localStorage.setItem('authToken', userData.authToken);
                localStorage.setItem('userId', userData.userId);
                this.authenticationService.setUserdata(userData);
                this.router.navigate([PathConstants.CREATE_ACCOUNT]);
            } else {
                this.toastr.error(data.error_msgs, 'Error');
            }
            this.refreshRecaptcha()
        });
    }


    checkPasswordValidity = (value) => {
        
        const combinedRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}[\]|\\:;"'<>,.?/_₹]).{8,20}$/;
      
        if (!combinedRegex.test(value)) {
            return "Passwords must have 1 digit, 1 uppercase, 1 lowercase, and a special character. The length should be between 8-20 characters.";
        }
        return null;
      }

    onForgotPwd() {
        if (this.forgotPwdForm.invalid) {
            this.toastr.error(this.global.MessageRequired); this.global.validateAllFormFields(this.forgotPwdForm);
            return;
        }
        this.authenticationService.forgot({ email: this.fp.email.value }).subscribe(data => {
            if (data.status) {
                this.toastr.success(data.data);
                this.forgotPwdForm.reset();
                this.modalRef.hide();
            }
            else {
                this.toastr.error(data.error_msgs, 'Error');
            }
        });
    }

    onInputChange(event: any): void {    
        let inputValue: string = event.target.value;   
        inputValue = inputValue.replace(/\D/g, '');    
        event.target.value = inputValue;
      }

}
