import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Router } from "@angular/router";
import { SubscriptionService } from '../../../../../../src/app/core/services/subscription.service';
import { Global } from '../../../../../../src/app/shared/models/global.model';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import {UserProjectDetail } from '../../../../../../src/app/core/modal/project';
import { ProjectService } from '../../../../../../src/app/core/services/project.service';
declare var $: any;

@Component({
  selector: 'app-subscription-invoice',
  templateUrl: './subscription-invoice.component.html',
  styleUrls: ['./subscription-invoice.component.scss']
})
export class SubscriptionInvoiceComponent implements OnInit {
  showToggle: boolean = false;
  modalRef: BsModalRef;
  currentPlan: any = {};
  subscriptions: any = [];
  userProjectDetail:UserProjectDetail = {}
  activateSource:any = [];
  activatedChannels:any = [];
  constructor(
    private _route: Router,
    private toastr: ToastrService,
    private subscriptionService: SubscriptionService,
    private location:Location,
    private projectService: ProjectService
  ) { }

  ngOnDestroy() {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }
  goToUpgrade() {
    this._route.navigate(['/admin/upgrade-subscription'])
  }
  ngOnInit() { 
    this.projectService.userProjecDetails().then(data=> {
      this.userProjectDetail = data;
    })
    this.getCurrentPlan(); 
    this.getSubscriptionInvoices();
    this.getSourceForInfoTable();
    this.getChannelForInfoTable();
  }

  getSourceForInfoTable(){
    this.projectService.fetchSourceList().then(data=>{
      if(data){
        console.log(data);
        let sourceAsKey = Object.keys(data);
        sourceAsKey.forEach(source=>{
          if(data[source] && data[source].pluginActive == 'ACTIVE'){
            this.activateSource.push(data[source])
          }
        });
        this.activateSource.sort((a,b)=>{
          if(a.pluginName < b.pluginName){
              return -1;
          }
          if(a.pluginName > b.pluginName){
              return 1;
          }
      })
      }
    })
  }



  getChannelForInfoTable(){
    this.projectService.fetchChannelList().then(data=>{
      if(data){
        this.activatedChannels = data;
        console.log(this.activatedChannels);
      }
    })
  }

  back()
  {
    this.location.back();
  }
 

  getCurrentPlan() {
    this.subscriptionService.getCurrentPlan({ }).subscribe( data => {
        if (data.status) {
          this.currentPlan = data.data[0];
        }
    });
  }

  getSubscriptionInvoices() {
    this.subscriptionService.getSubscriptionInvoices({}).subscribe( data => {
        if (data.status) {
          this.subscriptions = data.data;
        }
    });
  }

  downloadInvoice(id) {
    this.subscriptionService.downloadInvoice({ id: id}).subscribe( data => {
        if (data.status) {
          window.open(data.data, "_blank");
        }
    });
  }



  
}