import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Global } from '../../shared/models/global.model';
import { ProjectService } from './project.service';
import { ApiConstants } from '../../../app/constants/api.constants';
import { BaseService } from './base.service';
import { MenuObj, UserObj } from '../modal/user';
import { delay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { SpinnerService } from './spinner.service';
import { ProjectDetailObj } from '../modal/project';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    rootPath: string;
    userData: UserObj;
    siteMenuList: MenuObj[];
    profileDetail: any;
    projectDetails: ProjectDetailObj;
    landingUrl: string;
    currentMessage = new BehaviorSubject(null);
    constructor(
        public global: Global,
        private projectService: ProjectService,
        private baseService: BaseService,
        private router: Router,
        private spinner: SpinnerService,
        private toastr: ToastrService,
    ) {


    }


    validateChallenge(body) {
        return this.baseService.postRequest(ApiConstants.TWO_FACTOR_VALIDATE, body);
    }

    getUserTwoFactorDetails(body) {
        return this.baseService.postRequest(ApiConstants.AUTH_TWO_FACTOR_DETAILS, body);
    }

    logoutSessionRequest(body) {
        return this.baseService.postRequest(ApiConstants.AUTH_SESSION_LOGOUT, body);
    }

    logoutMaxSession(body) {
        return this.baseService.postRequest(ApiConstants.MAX_SESSION_LOGOUT, body);
    }

    getAllSessionList(body) {
        return this.baseService.postRequest(ApiConstants.AUTH_GET_ALL_SESSIONS, body);
    }

    validateGoogleAuth(body) {
        return this.baseService.postRequest(ApiConstants.AUTH_VALIDATE_GOOGLE_AUTH, body);

    }

    disableGoogleAuth(body) {
        return this.baseService.postRequest(ApiConstants.DISABLE_GOOGLE_AUTH, body);

    }

    setupOTPChallenge(body) {
        return this.baseService.postRequest(ApiConstants.AUTH_SETUP_OTP_CHALLENGE, body);
    }

    validateOTPChallenge(body) {
        return this.baseService.postRequest(ApiConstants.AUTH_VALIDATE_OTP_CHALLENGE, body);
    }

    setupGoogleAuth(body) {
        return this.baseService.postRequest(ApiConstants.AUTH_SETUP_GOOGLE_AUTH, body);
    }

    login(body) {
        return this.baseService.postRequest(ApiConstants.AUTH_SIGN_IN, body);
    }

    signout(body){        
        return this.baseService.postRequest(ApiConstants.SIGN_OUT, body);
    }

    getUser() {
        return this.baseService.postRequest(ApiConstants.FETCH_USER_PROFILE, {});
    }

    signup(body) {
        return this.baseService.postRequest(ApiConstants.SIGN_UP, body);
    }

    customerEnquiry(body) {
        return this.baseService.postRequest(ApiConstants.INSERT_CUSTOMER_ENQUIRY, body);
    }

    forgot(body) {
        return this.baseService.postRequest(ApiConstants.FORGOT_PASSWORD, body);
    }

    getSiteMenu(body) {
        return this.baseService.postRequest(ApiConstants.GET_MENU_LIST, body, false);
    }

    logout(reloadEvent?) {
        localStorage.clear();
        this.global.ClearChannel();
        this.projectService.setAttributeData(undefined);
        this.projectService.setLeadStatusData(undefined);
        this.projectService.sourceList = undefined;
        this.projectService.pluginData = undefined;
        this.projectService.projectDetails = undefined;
        this.projectService.followUpUnsubscribe();
        this.userData = undefined;
        this.siteMenuList = undefined;
        if (reloadEvent == 'refresh') {
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        }
    }

    setUserdata(data: UserObj) {
        this.userData = data;
    }

    getUserData(): UserObj {
        if (this.userData != undefined)
            return this.userData;
    }

    getUserRole(): number {
        if (this.userData != undefined)
            return this.userData.userRoleId;
        else
            return null
    }

    getUserRoleType(): number {
        if (this.userData != undefined)
            return this.userData.roleTypeId;
        else
            return null
    }

    getUserId(): string {
        if (this.userData != undefined)
            return this.userData.userId;
        else if (localStorage.getItem('userId'))
            return localStorage.getItem('userId')
        else
            return undefined
    }

    getAuthToken(): string {
        if (localStorage.getItem('authToken'))
            return localStorage.getItem('authToken')
        else
            return undefined
    }

    // getProjectToken() : string {
    //     if(this.userData != undefined)
    //         return this.userData.token;
    //     else if(localStorage.getItem('token'))
    //         return  localStorage.getItem('token')
    //     else
    //         return undefined
    // }

    getProjectId(): string {
        if (this.userData != undefined)
            return this.userData.projectId;
        else if (localStorage.getItem('projectId'))
            return localStorage.getItem('projectId')
        else
            return undefined
    }

    async getProfile(reload?) {
        if (reload) {
            this.profileDetail = undefined;
        }
        if (this.profileDetail == undefined) {
            var requestData = await this.getUser().pipe(delay(200)).toPromise();
            this.profileDetail = requestData.data;
        };
        return this.profileDetail;
    }

    async getSiteMenuData(reload?){
        if(reload == 'reload'){
            this.siteMenuList = undefined;
        }
        if(this.siteMenuList == undefined){
            var requestData = await this.getSiteMenu({}).pipe(delay(200)).toPromise();
            this.siteMenuList = requestData.data;
        };
        return this.siteMenuList;
    }

    isPageSubscribe(name) {
        var status = false;
        var list = [];
        this.projectDetails = this.projectService.projectDetails;
        if (this.projectDetails != undefined && this.projectDetails.pricingPlans != undefined) {
            list = this.projectDetails.pricingPlans.plugins.split(',');
            list.forEach(element => {
                if (element.toLocaleLowerCase() == name.toLocaleLowerCase()) {
                    status = true;
                }
            });
            return status;
        }
    }

    getProjectDetail() {
        this.spinner.show();
        this.projectService.getUserProjectDetails().then(data => {
            this.projectDetails = data;
            if (data != undefined) {
                var userData = {
                    userId: data.userId,
                    projectId: data.id,
                    email: data.email,
                    phone: data.phone,
                    accountCreated: data.status == "ACTIVE",
                    userRoleId: data.userRoleId,
                    userRoleName: data.roleName,
                    emailVerified: data.emailVerified == "VERIFIED",
                    userName: data.firstName + ' ' + data.lastName,
                    roleTypeId: data.roleTypeId,
                    ivrActivation:data.ivrActivation
                }
                this.setUserdata(userData);
                // this.projectService.setFollowUpTimer();
                this.getSiteMenuList();
            }
        });
    }

    getSiteMenuList() {
        this.getSiteMenuData().then(data => {
            if (data != undefined && data.length > 0) {
                this.getAttributeList();
            } else {
                this.toastr.error('You are not allowed to access this page. Contact your admin.');
                this.logout();
            }
        });
    }

    getAttributeList() {
        this.projectService.fetchAttributeList().then(data => {
            if (data != undefined) {
                this.spinner.hide();
                this.router.navigate(['/admin/' + this.getLandingPage()]);
            }
        });
    }

    getLandingPage() {
        if (this.siteMenuList != undefined && this.siteMenuList.length > 0) {
            this.landingPageUrl(this.siteMenuList, '');
            if (this.landingUrl != undefined) {
                return this.landingUrl;
            } else {
                this.siteMenuList[0].menuKey;
            }
        } else {
            return 'dashboard'
        }
    }


    landingPageUrl(data, parentUrl) {
        for (var i = 0; i < data.length; i++) {
            if (data[i].isLandingPage == "ACTIVE") {
                this.landingUrl = parentUrl + '/' + data[i].menuKey;
                return true
            } else if (data[i].menuList != undefined) {
                this.landingPageUrl(data[i].menuList, parentUrl + '/' + data[i].menuKey);
            }
        }
    }

}