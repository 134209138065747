import { Component, EventEmitter , Input, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { ToastrService } from "ngx-toastr";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CampaignService } from "../../../../core/services/campaign.service";
import { Subject, Observable, Subscription } from "rxjs";
import { Global } from "../../../../shared/models/global.model";
import { DatePipe } from "@angular/common";
import { Router } from "@angular/router";
import { noWhitespaceValidator } from "../../../../shared/models/no-whitespace-validator";
import { minLengthOrBlank } from "../../../../shared/models/min-length-validator.1";
import { ProjectService } from '../../../../core/services/project.service';
import { AppConstants } from "../../../../../app/constants/app.constants";
import { ApiConstants } from "../../../../../app/constants/api.constants";
import { GalleryPopupComponent } from "../campaign-gallery/gallery-popup.component";
import { UrlShortnerService } from "../../../../core/services/urlShortner.service";
import { EmailTemplateObj, PushTemplateObj, SmsTemplateObj } from "../../../../core/modal/campaign";
import { SpinnerService } from "../../../../core/services/spinner.service";
import { AuthenticationService } from "../../../../core/services/authentication.service";
import { ConfigurationService } from "../../../../core/services/configuration.service";
import { environment } from "../../../../../../src/environments/environment";
declare const CKEDITOR;

@Component({
    selector: "app-channel-type",
    templateUrl: "./channel-type.component.html",
    styleUrls: ["./channel-type.component.scss"]
})
export class ChannelTypeComponent implements OnInit {
    color: any = "";
    campaign: any = "Push";
    filters: any = [];
    selectedFilters: any = [];
    currentDate: any;
    iosPushNotification: boolean = false;
    androidPushNotification: boolean = false;
    webPushNotification: boolean = true;
    isEditorView: boolean = true;
    showAudience: boolean = false;
    audience: any = "0 User(s)";
    audienceCount: any = "";
    whatsappPushUrl:any = environment.whatsappApiUrl;
    contentLength: any = 0;
    smsTextLength: any = 0;
    modalRef: BsModalRef;
    formPushNotification: FormGroup;
    formSMSCampaign: FormGroup;
    formWhatsappCampaign: FormGroup;
    formEmailCampaign: FormGroup;
    formInAppNotification: FormGroup;
    validFilters: any = false;
    showAlertMessage: boolean;
    isShowEmoji: boolean = false;
    campaignName: string = '';
    campAttributes:any;
    headerUrl:any;
    headerUrlText:any;
    @ViewChild("templatePushNotification") templatePushNotification: TemplateRef<any>;
    @ViewChild("templateSMSCampaign") templateSMSCampaign: TemplateRef<any>;
    @ViewChild("templateEmailCampaign") templateEmailCampaign: TemplateRef<any>;
    @ViewChild("templateWhatsappCampaign") templateWhatsappCampaign: TemplateRef<any>;
    @ViewChild("templateInAppNotification")
    templateInAppNotification: TemplateRef<any>;
    filtersJson: any = {};
    filtersJsonObj: any;
    Status: boolean = true;
    inputParams : any = [];
    inputParamsSelected : any = [];
    pushTemplateList : PushTemplateObj[] = [];
    smsTemplateList : SmsTemplateObj[] = [];
    emailTemplateList : EmailTemplateObj[] = [];
    isDynamicValues: boolean = true;
    buttonText:any;
    instance: any;
    modalRefGallery: BsModalRef;
    config: any;
    ckeditorData:Subject<string> = new Subject();
    isJourney:boolean = false;
    dynamicListFields:any=new Array();
    inputParamsFileType:any = []
    wabaConfig:boolean = false;
    wpBDetailsList:any;
    wpBDetails:any;
    @Output() getAudienceCount = new EventEmitter();
    @Output() clearFilter = new EventEmitter();
    @Input() proceedEvent: Observable<any>;
    @Input() url:any;
    @Input() tatData:any;
    @Input() reloadTemplates:any;
    @Input() isIndividualCampaign: boolean;
    @Input() campaignSetting: any;
    @Input() campaignType:any;
    @Output() afterSend = new EventEmitter<any>();
    @Output() afterProcess = new EventEmitter<any>();
    private proceedEventsSubscription: Subscription;

    constructor(
        private modalService: BsModalService,
        private toastr: ToastrService,
        private campaignService: CampaignService,
        private formBuilder: FormBuilder,
        private datePipe: DatePipe,
        public global: Global,
        private router: Router,
        private projectService: ProjectService,
        private urlShortnerService: UrlShortnerService,
        private spinner: SpinnerService,
        private authenticationService: AuthenticationService,
        private configurationService:ConfigurationService
    ) {
        this.config =  this.global.CKEditorConfig(500,'email');
    }

    ngOnDestroy() {
        if (this.modalRef) {
            this.modalRef.hide();
        }
        if( this.proceedEventsSubscription !=undefined){
        this.proceedEventsSubscription.unsubscribe();}
    }

    AnyChannelSelected() {
        if (!this.global.AnyChannelSelected()) {
            this.clearFilter.emit();
            return false;
        }
        return true;
    }

    ngOnInit() {
        this.Status = true;
        if (!this.AnyChannelSelected() && !this.isIndividualCampaign) {
            return;
        }
        if(this.campaignSetting == null){
            this.campaignSetting = {
                date: new Date(),
                time: new Date(),
                send: "now",
                campFrequency: "everyday",
                paramId: "entry_date",
                paramWhen: "sameday",
                paramWhenDay: 0,
                weekDay: 1,
                monthDay: 1,
                paramInterval: 0.8,
                campExecutionTime: new Date(),
                toDate: new Date(),
            }
        }
        this.showAlertMessage = false;
        this.isJourney = this.global.CampaignData.campaign == 'journey'
        this.setCampaignData();
        // this.getFilters();
        this.proceedEventsSubscription = this.proceedEvent.subscribe( data => {
            this.setCampaignData();
            if(this.isJourney){
                this.savejourneyCampaign();
            } else {
                this.openModal();
            }
        })
        var t = new Date();
        t.setSeconds(t.getSeconds() - 50);
        this.currentDate = t;
        if(!this.isIndividualCampaign)
        this.calculateAudience();

        this.getInputParams();
        this.ckeditorData.next('');

        
    }
    ngOnChanges()
    {           
        if(this.reloadTemplates == 'reload')
        {
            if(this.url)
            {
            this.pushTemplateList = [];
            this.campaignService.getEmailTemplateData(this.url,'reload').then(data => {
                if(data != undefined)
                this.emailTemplateList = data;   
            });

            this.campaignService.getSmsTemplateData(this.url,'reload').then( data => {
                if(data != undefined)
                this.smsTemplateList = data;
            });
            }
        
        }

    }
    
    setCampaignData(){
        if(this.global.CampaignData != undefined){
            var data = this.global.CampaignData;            
            this.campaignName = data.campaignName;
            this.campaign = data.campaign;
            this.iosPushNotification = data.iosPushNotification;
            this.androidPushNotification = data.androidPushNotification;
            this.webPushNotification = data.webPushNotification;
            this.campAttributes = data.campAttributes
            if(data.audienceCount != undefined)
            this.audienceCount = data.audienceCount;
        }
    }

    prepareFormPushNotification() {
        this.formPushNotification = this.formBuilder.group({
        title: [ "",
            Validators.compose([
            Validators.required,
            noWhitespaceValidator,
            Validators.minLength(3),
            Validators.maxLength(700)
            ])
        ],
        message: [ "",
            Validators.compose([
            Validators.required,
            noWhitespaceValidator,
            Validators.minLength(5),
            Validators.maxLength(1024)
            ])
        ],
        deepLink: [""],
        notificationIcon: [""],
        notificationImage: [""],
        notImage: [""],
        paramsSelected: [''],
        template: ['']
        });
    }

    prepareFormSMSCampaign() {
        this.formSMSCampaign = this.formBuilder.group({
            smsText: [ "",
                Validators.compose([
                Validators.required,
                noWhitespaceValidator,
                Validators.minLength(5),
                Validators.maxLength(700)
                ])
            ],
            paramsSelected: [''],
            template: ['']
        });
        this.formSMSCampaign.get("smsText").valueChanges.subscribe(content => {
            this.smsTextLength = content.length;
        });
    }

    prepareFormWhatsappCampaign() {

        this.configurationService.getConfiguration({},this.whatsappPushUrl).subscribe(data => {
            if (data.status) {
              this.wpBDetailsList = data.data;            
              for(let i=0; i<this.wpBDetailsList.length; i++){
                if(this.wpBDetailsList[i].id > 0){
                  this.wpBDetails = this.wpBDetailsList[i];   
                }
              }
            }
          });

        this.formWhatsappCampaign = this.formBuilder.group({
            smsText: [ "",Validators.compose([Validators.maxLength(500)])],
            buttonText:[""],
            paramsSelected: [''],
            template: [''],
            headerUrl:[''],
            headerUrlText:['']
        });
        this.formWhatsappCampaign.get("smsText").valueChanges.subscribe(content => {
            if(content && content.length){
            this.smsTextLength = content.length;
            } else{
                this.smsTextLength = 0;
            }
        });

        
    }

    prepareFormEmailCampaign() {
        this.formEmailCampaign = this.formBuilder.group({
            emailSubject: [
                "",
                Validators.compose([
                Validators.required,
                noWhitespaceValidator,
                Validators.minLength(5),
                Validators.maxLength(1000)
                ])
            ],
            emailText: [
                "",
                Validators.compose([
                Validators.required,
                noWhitespaceValidator,
                Validators.minLength(5)
                ])
            ],
            paramsSelected: [''],
            template: ['']
        });
    }

    prepareFormInAppNotification() {
        this.formInAppNotification = this.formBuilder.group({
        image: [""],
        imagePath: [""],
        customButton: [false],
        title: [ "",
            Validators.compose([
            Validators.required,
            noWhitespaceValidator,
            Validators.minLength(5),
            Validators.maxLength(300)
            ])
        ],
        content: [ "",
            Validators.compose([minLengthOrBlank(5), Validators.maxLength(200)])
        ],
        buttonName: [""],
        buttonLink: [""],
        buttonColor: [""],
        toDate: [new Date()]
        });
        this.formInAppNotification.get("content").valueChanges.subscribe(content => {
            this.contentLength = content.length;
        });
    }

    openModal() {
        console.log(this.campaign);
        
        if (this.audienceCount == 0 &&  this.campaignSetting.send != "repeat" && !this.isIndividualCampaign ) {
            this.toastr.error("User(s) not found.","Error");
            return;
        } 
        if (this.Status == false) {
            return;
        }
        this.inputParamsSelected = [];
        let campaignName = this.campaignType != undefined ? this.campaignType : this.campaign ; 
        switch (campaignName) {
        case "SMS":
            this.prepareFormSMSCampaign();
            setTimeout(() => {
                this.getSmsTemplateList();
            }, 500);
            this.modalRef = this.modalService.show(this.templateSMSCampaign, {
            backdrop: "static",
            class: "modal-xl"
            });
            break;
        case "Email":
            this.prepareFormEmailCampaign();
            setTimeout(() => {
                this.getEmailTemplateList();                
            }, 500);
            this.modalRef = this.modalService.show(this.templateEmailCampaign, {
            backdrop: "static",
            class: "modal-xl"
            });
            break;
        case "whatsapp":
        this.prepareFormWhatsappCampaign();
        setTimeout(() => {
            this.getWhatsappTemplateList();
        }, 500);
        this.modalRef = this.modalService.show(this.templateWhatsappCampaign, {
        backdrop: "static",
        class: "modal-xl"
        });
        break;
        case "In-App":
            this.prepareFormInAppNotification();
            this.modalRef = this.modalService.show(this.templateInAppNotification, {
            backdrop: "static",
            class: "modal-xl"
            });
            break;
        default :
            this.prepareFormPushNotification();
            setTimeout(() => {
                this.getPushTemplateList();
            }, 500);
            this.modalRef = this.modalService.show(this.templatePushNotification, {
                backdrop: "static",
                class: "modal-xl"
            });
        break;
            break;
        }
    }

    openGalleryModal(type){
        const initialState = {
          galleryType : type,
          modalRefGallery: this.modalRefGallery
        }
        this.modalRefGallery = this.modalService.show(GalleryPopupComponent,{
            initialState,
            backdrop: "static",
            class: "gallery-popup"
        });
        this.modalRefGallery.content.modalRefGallery = this.modalRefGallery;
        this.modalRefGallery.content.selectMediaEvent.subscribe(data => {
            this.getMedia(data);
        });
      }

    removeAudience() {
        this.showAudience = false;
        this.audience = "0 User(s)";
    }

    removeFilter(obj) {
        this.removeAudience();
        this.filters.push(obj);
        for (let index = 0; index < this.selectedFilters.length; index++) {
            if (this.selectedFilters[index].id == obj.id) {
            this.selectedFilters.splice(index, 1);
            return;
            }
        }
    }

    removeFilters() {
        this.removeAudience();
        var length = this.selectedFilters.length;
        for (let index = 0; index < length; index++) {
            this.filters.push(this.selectedFilters[0]);
            this.selectedFilters.splice(0, 1);
        }
    }

    calculateAudience() {
        if (!this.AnyChannelSelected()) {
            return;
        }
        this.prepareWithAttributes();
        if(this.isJourney){
            this.audience = "0 User(s)";
            this.audienceCount = 0;
            this.getAudienceCount.emit(this.audienceCount);
            return;
        }
        this.getAudienceCount.emit(this.audienceCount); 
        let parentMenu = '';        
        this.global.Channel.Segments.forEach(element => {
            if(parentMenu != null){                
                parentMenu = parentMenu + element.category + ','
            }
        });

        this.global.categorySelectByCamapign.forEach(element => {
            parentMenu = parentMenu + element + ',';
        });
       
        this.campaignService.calculateAudience(this.filtersJsonObj,parentMenu != '' ? parentMenu.slice(0,-1) : 'allAudience').subscribe( data => {
            if (data.status) {
                this.showAudience = true;
                this.audience = data.data.totalElements + " User(s)";
                //this.audienceCount = data.data;
                this.audienceCount = data.data.totalElements;
                this.getAudienceCount.emit(this.audienceCount);
                this.toastr.success(this.audience + " Found");
            } else {
                this.toastr.error(data.error_msgs, "Error");
            }
        });
    }

    private prepareWithAttributes() {
        this.filtersJsonObj = {};
        this.filtersJson = '';
        var postForm : any = {};
        let paramList = [];
        this.global.Channel.Attributes.forEach(event => {
            paramList.push({
                paramKey: event.pluginName == 'dynamic'?'params.'+event.paramKey:event.paramKey,
                paramValue: event.paramValue,
                paramOprator: event.paramOprator
            });
        });
        this.global.Channel.Events.forEach(event => {
            paramList.push({
                paramKey: event.paramKey,
                paramValue: "'" + true + "'",
                paramOprator: "="
            });
        });
        if(this.global.categorySelectByCamapign.length > 0){
           
            let categoryName = "";
            let catOperator;
            if(this.global.categorySelectByCamapign.length == 1){
                catOperator = '=';
                this.global.categorySelectByCamapign.forEach(element => {
                    categoryName = element; 
                });
        }
        else if(this.global.categorySelectByCamapign.length > 1){
            catOperator = 'in'
            this.global.categorySelectByCamapign.forEach(element => {
                categoryName = categoryName ? categoryName + ',' + "'"+element+"'" : "'"+element+"'";
            });
        }
        

        if(categoryName != ""){
            paramList.push({
                paramKey: 'category',
                paramValue: categoryName,
                paramOprator: catOperator
            });
        }
        }

        postForm.searchstring = "";
        postForm.campaignType = this.campaign;
        postForm.fromDate = '';
        postForm.toDate = '';
        postForm.segment = [{'paramList':paramList,'pluginFormIds':this.global.Channel.sourceFormIds,'source':this.global.Channel.Source.map(function (val) {return val.pluginName; }).join(",")}];
        if(paramList.length == 0){
        delete postForm.segment[0].paramList
        }
        if(this.global.Channel.sourceFormIds.length == 0){
            delete postForm.segment[0].pluginFormIds
        }
        if(this.global.Channel.Source.length == 0){
            delete postForm.segment[0].source
        }
        if(paramList.length == 0 && this.global.Channel.sourceFormIds.length == 0 && this.global.Channel.Source.length == 0){
             postForm.segment = []
        }

        if (this.global.Channel.Segments != undefined && this.global.Channel.Segments.length > 0) {
            let segmentIds = [];
            let segmentData = this.global.Channel.Segments.map(function (val) {                
                let jsonParse = JSON.parse(val.jsonData);
                segmentIds.push(val.id)
                if(val.category){
                jsonParse.paramList.push({'paramKey':'category','paramOprator':'=','paramValue':val.category});
                }
                return { 'paramList': jsonParse.paramList, "source": jsonParse.source, "pluginFormIds": jsonParse.pluginFormIds };
            });
            postForm.segmentIds = segmentIds;
            postForm.segment = segmentData;
        }


       // segment:[{'paramList':this.paramList,'pluginFormIds':this.sourceFormIdList,'source':this.global.Channel.Source.map(function (val) {}).join(",")}],
        this.filtersJsonObj = postForm;
        this.filtersJson = JSON.stringify(postForm);
    }

    afterSave(data,type) {        
        if(this.modalRef){
            this.modalRef.hide();
        }
        if(data.status){
            if(this.isIndividualCampaign){
                this.toastr.success(this.campaign+ " sent",'Success');
                this.afterSend.emit('Send success');
            } else {
                this.toastr.success("Campaign Created");
                if(this.isJourney){
                    let journeyUserId =  encodeURIComponent(this.global.CampaignData.journeyUserId)
                    this.router.navigate(['admin/report/'+this.global.CampaignData.journeyName+'/'+journeyUserId],{queryParams:{type:type}});
                } else {
	                this.router.navigate(["admin/report"],{queryParams:{type:type}});
                }
                
            }
        } else {
            this.toastr.error(data.error_msgs,'Error');
            this.formWhatsappCampaign.disable();
        }
    }

    checkDynamicValues(msg, buttonText?, type?) : boolean{
        
        if(type == 'whatsapp'){
            let msgToArray
            let buttonAndBodyParams = []
            if(buttonText){
                buttonText = buttonText.split(',');
                buttonText = buttonText.filter(p=>{
                    return p!=null && p.indexOf('[') != -1 && p.indexOf(']') != -1;
                })
            }if(msg){
                msgToArray = msg.split(',');
                msgToArray = msgToArray.filter(p=>{
                    return p!=null && p.indexOf('[') != -1 && p.indexOf(']') != -1;
                });
            }
            
                if(buttonText && buttonText.length > 0){
                buttonText.forEach(element => {
                    buttonAndBodyParams.push(element.trim())
                });
            }
            if(msgToArray && msgToArray.length > 0){
                msgToArray.forEach(element => {
                    buttonAndBodyParams.push(element.trim())
                });
            }

        var isValueInclude: boolean = true;
        if(this.inputParamsSelected != undefined && msg != undefined && this.inputParamsSelected.length > 0){
            this.inputParamsSelected.forEach(element => {
                if(!buttonAndBodyParams.includes(element.paramValue)){
                isValueInclude = false;
                }
            });
        }
        return isValueInclude;
        }
        var isValueInclude: boolean = true;
        if(this.inputParamsSelected != undefined && msg != undefined && this.inputParamsSelected.length > 0){
            this.inputParamsSelected.forEach(element => {
                if(!msg.includes(element.paramValue)){
                isValueInclude = false;
                }
            });
        }
        return isValueInclude;
    }

    savePushNotification() {
        
        if (this.formPushNotification.invalid) {
            this.toastr.error(this.global.MessageRequired);
            this.global.validateAllFormFields(this.formPushNotification);
            return;
        }

        let postForm: any = this.getPostData(this.formPushNotification);

        if (!this.validFilters) {
            return;
        }

        if(!this.checkDynamicValues(postForm.message)){
            this.toastr.error("Please use selected Dynamic Values");
            return
        }
        let campaignType;
        if(this.iosPushNotification){
            campaignType = 'iospush'
        }
        if(this.androidPushNotification){
            campaignType = 'androidpush'
        }
        if(this.webPushNotification){
            campaignType = 'webpush'
        }
        postForm.userCampaigns.campaignType = campaignType;
        postForm.isIos = this.iosPushNotification;
        postForm.isAndroid = this.androidPushNotification;
        postForm.isWeb = this.webPushNotification;
        let dynamicVal = postForm.paramsSelected ? postForm.paramsSelected : [];
        this.dynamicListFields = []
        if(dynamicVal.length > 0){
        dynamicVal.forEach(element => {
            if(element.paramKey == 'name' || element.paramKey == 'email' || element.paramKey == 'mobile' || element.paramKey == 'android_fcm_token' || element.paramKey == 'ios_fcm_token' || element.paramKey == 'web_fcm_token' || element.paramKey == 'updated'){
                this.dynamicListFields.push(element.paramKey)
            } else{
                this.dynamicListFields.push('params.'+element.paramKey)
            }
        });
    }
    
        let campAttr = this.campAttributes ? ','+this.campAttributes : '';
        let dynamiCheck = this.dynamicListFields.length > 0 ? ','+this.dynamicListFields.join() : '';
        postForm.fields = 'audience_id'+ campAttr + dynamiCheck;
        let filterJson = JSON.parse(postForm.userCampaigns.filtersJson);
        filterJson.fields = postForm.fields;
        filterJson.campAttributes = this.campAttributes;
        postForm.userCampaigns.filtersJson = JSON.stringify(filterJson);
        delete postForm.paramsSelected;
        delete postForm.dynamicContentList;
        if(this.tatData){
            console.log(postForm);
            this.afterProcess.emit(postForm);
            this.modalRef.hide();
            return
        }
        if(this.url)
        {
            this.campaignService.savePushNotification(postForm,this.url).subscribe(data => {
                this.afterSave(data,campaignType);
            });
        }else{
            this.toastr.error('Something went wrong');
        }
    }

    private getPostData(form?) {
        this.validFilters = true;
        this.spinner.show();
        let postForm: any = form.value;

        postForm.countryId = 0;

        if(this.isIndividualCampaign){
            var dataJson = {
                searchString: this.global.CampaignData.audienceIds.join(","),
                campaignType: this.campaign,
                segment:[],
                page:0,
                limit:1
            };
            this.filtersJson = JSON.stringify(dataJson);
        } else {
            this.prepareWithAttributes();
        }
        var date = new Date();
        var toDate = new Date();
        if (this.campaignSetting.send == "schedule") {
            date = new Date( this.campaignSetting.date.toString().slice(0, 15) + this.campaignSetting.time.toString().slice(15) );
            postForm.scheduleDate = this.datePipe.transform( date, "yyyy-MM-dd HH:mm:ss" );
        } else if (this.campaignSetting.send == "repeat") {
            date = new Date( this.campaignSetting.date.toString().slice(0, 15) + new Date().toString().slice(15));
            toDate = new Date( this.campaignSetting.toDate.toString().slice(0, 15) + new Date().toString().slice(15));
            if (date > toDate) {
                this.validFilters = false;
                this.toastr.error("please fill correct date range");
                this.spinner.hide();
            }
            postForm.campExecutionTime = this.datePipe.transform(this.campaignSetting.campExecutionTime, "HH:mm" );
            postForm.scheduleDate = this.datePipe.transform( date, "yyyy-MM-dd ") + postForm.campExecutionTime;
        } else {
            postForm.scheduleDate = this.datePipe.transform( date, "yyyy-MM-dd HH:mm:ss" );
        }
        
        if (date < this.currentDate) {
            this.validFilters = false;
            this.toastr.error("please fill correct data & time");
            this.spinner.hide();
        }

        postForm.fromDate = this.datePipe.transform(date, "yyyy-MM-dd HH:mm:ss");
        postForm.toDate = this.datePipe.transform(toDate, "yyyy-MM-dd HH:mm:ss");
        postForm.scheduleType = this.campaignSetting.send;
        postForm.pushCampaignRepeatDTO = {};
        if (this.campaignSetting.send == "repeat") {
            if (this.campaignSetting.campFrequency == "everyday") {
                postForm.pushCampaignRepeatDTO = {
                    campFrequency: this.campaignSetting.campFrequency,
                    campExecutionTime: postForm.campExecutionTime,
                    fromDate: postForm.fromDate,
                    toDate: postForm.toDate
                };
            } else if (this.campaignSetting.campFrequency == "weekly") {
                postForm.pushCampaignRepeatDTO = {
                    campFrequency: this.campaignSetting.campFrequency,
                    weekDay: this.campaignSetting.weekDay,
                    campExecutionTime: postForm.campExecutionTime,
                    fromDate: postForm.fromDate,
                    toDate: postForm.toDate
                };
            } else if (this.campaignSetting.campFrequency == "monthly") {
                postForm.pushCampaignRepeatDTO = {
                    campFrequency: this.campaignSetting.campFrequency,
                    monthDay: this.campaignSetting.monthDay,
                    campExecutionTime: postForm.campExecutionTime,
                    fromDate: postForm.fromDate,
                    toDate: postForm.toDate
                };
            } else if (this.campaignSetting.campFrequency == "custom") {
                postForm.pushCampaignRepeatDTO = {
                    campFrequency: this.campaignSetting.campFrequency,
                    paramKey: this.campaignSetting.paramId,
                    paramWhen: this.campaignSetting.paramWhen,
                    paramWhenDay: this.campaignSetting.paramWhenDay,
                    campExecutionTime: postForm.campExecutionTime,
                    fromDate: postForm.fromDate,
                    toDate: postForm.toDate
                };
                if(this.isJourney){
                    postForm.pushCampaignRepeatDTO.paramWhen = 'sametime';
                    postForm.pushCampaignRepeatDTO.campFrequency = 'custom';
                }
            }
        }
        postForm.userCampaigns = {
            campaignName: this.campaignName,
            campaignType: "",
            audienceCount: this.audienceCount
        };
        postForm.userCampaigns.segmentIds = this.global.Channel.Segments.map(function (val) {
            return val.id;
        });
        postForm.userCampaigns.filterType = this.global.Channel.Segments.length > 0 ? "Segment" : "Attribute";
        postForm.userCampaigns.source = this.global.Channel.Source.map(function (val) {
            return val.pluginName;
        }).join(",");
        postForm.userCampaigns.filtersJson = this.filtersJson;
        postForm.dynamicContentList = this.inputParamsSelected;
        postForm.fields = "";
        let fields = []
        if(this.inputParamsSelected != undefined && this.inputParamsSelected.length > 0){
            this.inputParamsSelected.forEach(element => {
                fields.push(element.paramKey);
            });
            postForm.fields = fields.join();
            postForm.userCampaigns.contentType = 'dynamic'
        } else {
            postForm.userCampaigns.contentType = 'static'
        }
        let repeatType = "";
        if(this.campaignSetting.send == "repeat" && this.campaignSetting.campFrequency == "custom" && !this.isJourney){
            let paramValue: any;
            let unitType:any;
            let unitValue:any;
           
            if (this.campaignSetting.paramWhen == "sametime") {
                if(this.campaignSetting.paramInterval != undefined && this.campaignSetting.paramInterval !== 0){
                    repeatType ="sametime";
                    var time = this.campaignSetting.paramInterval.split('.');
                    paramValue = "interval "+ time[0] + " hour - "  + "interval " + time[1] +" minute"
                    if(time[0] > 0){
                    unitType = "hour"
                    unitValue =  "-"+time[0];
                    }  else{
                        unitType = "minutes"
                    unitValue =  "-"+time[1];
                    } 
                } else {
                    
                    unitType = "day"
                    unitValue = 0;
                    paramValue = "date(now())";
                }
            } else if (this.campaignSetting.paramWhen == "before") {
                repeatType ="before";
                unitType = "day";
                unitValue =   this.campaignSetting.paramWhenDay
                paramValue = "date(date_add(now(),interval " + this.campaignSetting.paramWhenDay + " day))";
            } else if (this.campaignSetting.paramWhen == "after") {
                repeatType ="after";
                unitType = "day";
                unitValue =   "-" +this.campaignSetting.paramWhenDay
                paramValue =  "date(date_add(now(),interval -" + this.campaignSetting.paramWhenDay + " day))";
            } 
            else {
                repeatType ="sameday";
                paramValue = "date(now())";
                unitType = "day";
                unitValue = 0;
            }
            this.filtersJsonObj.repatParamList = [];            
            this.filtersJsonObj.repatParamList.push({
               
                paramKey:this.campaignSetting.paramId != 'updated'?"params."+this.campaignSetting.paramId:this.campaignSetting.paramId,
                paramValue: paramValue,
                paramOprator: "=",
                unitType:unitType,
                unitValue:unitValue,
                repeatType:repeatType
            });
          //  postForm.userCampaigns.repeatType = repeatType;
            this.filtersJson = JSON.stringify(this.filtersJsonObj);
            postForm.userCampaigns.filtersJson = this.filtersJson;
        }
        return postForm;
    }

    saveSMSCampaign() {
        if (this.formSMSCampaign.invalid) {
            this.toastr.error(this.global.MessageRequired);
            this.global.validateAllFormFields(this.formSMSCampaign);
            return;
        }

        let postForm: any = this.getPostData(this.formSMSCampaign);
        if (!this.validFilters) {
            return;
        }

        if(!this.checkDynamicValues(postForm.smsText)){
            this.spinner.hide();
            this.toastr.error("Please use selected Dynamic Values");
            return
        }

        postForm.userCampaigns.campaignType = "Sms";
        let dynamicVal = postForm.paramsSelected ? postForm.paramsSelected : [];
        this.dynamicListFields = []
        if(dynamicVal.length > 0){
        dynamicVal.forEach(element => {
            if(element.paramKey == 'name' || element.paramKey == 'email' || element.paramKey == 'mobile' || element.paramKey == 'android_fcm_token' || element.paramKey == 'ios_fcm_token' || element.paramKey == 'web_fcm_token' || element.paramKey == 'updated'){
                this.dynamicListFields.push(element.paramKey)
            } else{
                this.dynamicListFields.push('params.'+element.paramKey)
            }
        });
        }        
        let campAttr = this.campAttributes ? this.campAttributes:'';
        if(campAttr == 'SMS'){
            campAttr = 'mobile'
        }
        let dynamiCheck = this.dynamicListFields.length > 0 ? ','+this.dynamicListFields.join() : '';       
        postForm.fields = 'audience_id,'+ campAttr + dynamiCheck;
        let filterJson = JSON.parse(postForm.userCampaigns.filtersJson);
        filterJson.fields = postForm.fields;
        filterJson.campAttributes = campAttr;
        postForm.userCampaigns.filtersJson = JSON.stringify(filterJson);
        delete postForm.paramsSelected;
        delete postForm.dynamicContentList;
        if(this.url)
        {
        this.campaignService.saveSMSCampaign(postForm,this.url).subscribe( data => {
            this.afterSave(data,'sms');
        });
        }
    }

    headerImageUrl($event,type){
        if(type == 'input'){
            this.headerUrl = []
        } else if(type == 'select'){
            this.headerUrlText = "";
        }
    }


    saveWhatsappCampaign() {
       
        if (this.formWhatsappCampaign.invalid) {
            this.toastr.error(this.global.MessageRequired);
            this.global.validateAllFormFields(this.formWhatsappCampaign);
            return;
        }

        this.formWhatsappCampaign.enable();

        let postForm: any = this.getPostData(this.formWhatsappCampaign);                
        if(postForm.headerUrl && postForm.headerUrlText == ""){
            let setHeaderKey = postForm.headerUrl.paramKey ? postForm.headerUrl.paramKey : postForm.headerUrl;
            postForm.headerUrl = '['+setHeaderKey+']';
          } else if(postForm.headerUrl == undefined || postForm.headerUrl.length == 0  && postForm.headerUrlText != ""){
              postForm.headerUrl = postForm.headerUrlText;
          }        
        delete postForm.headerUrlText;
        if (!this.validFilters) {
            return;
        }        
        
        if(!this.checkDynamicValues(postForm.smsText,postForm.buttonText,'whatsapp')){
            this.spinner.hide();
            this.toastr.error("Please use selected Dynamic Values");
            return
        }

        postForm.userCampaigns.campaignType = "Whatsapp-Api";
        let dynamicVal = postForm.paramsSelected ? postForm.paramsSelected : [];
        this.dynamicListFields = []
        if(dynamicVal.length > 0){
        dynamicVal.forEach(element => {
            if(element.paramKey == 'name' || element.paramKey == 'email' || element.paramKey == 'mobile' || element.paramKey == 'android_fcm_token' || element.paramKey == 'ios_fcm_token' || element.paramKey == 'web_fcm_token' || element.paramKey == 'updated')
            {
                this.dynamicListFields.push(element.paramKey)
            }
            else{
                this.dynamicListFields.push('params.'+element.paramKey)
            }
        });
        }

        let campAttr = this.campAttributes ? this.campAttributes : '';
        let checkNameInDynamic = this.dynamicListFields.filter((p)=>{
            if(p == 'name'){
                return true
            }
        });
        
        let dynamiCheck = this.dynamicListFields.length > 0 ? ','+this.dynamicListFields.join() : '';
        dynamiCheck = dynamiCheck+(checkNameInDynamic.length == 0 ? ',name' : '');
        let headerUrl = postForm.headerUrl && postForm.headerUrl != "" && postForm.headerUrl.includes('http') == false ? ', params.' + postForm.headerUrl.replace('[',"").replace(']',"") : ""
        postForm.fields = 'audience_id,'+ campAttr + dynamiCheck + headerUrl;
        let filterJson = JSON.parse(postForm.userCampaigns.filtersJson);
        filterJson.fields = postForm.fields;
        filterJson.campAttributes = this.campAttributes;
        postForm.userCampaigns.filtersJson = JSON.stringify(filterJson);
        delete postForm.paramsSelected;
        delete postForm.dynamicContentList;        
        this.campaignService.savePushNotification(postForm,this.url).subscribe( data => {
            this.afterSave(data,'whatsapp');
        });
    }

    saveEmailCampaign() {
        if (this.formEmailCampaign.invalid) {
            this.toastr.error(this.global.MessageRequired);
            this.global.validateAllFormFields(this.formEmailCampaign);
            return;
        }

        let postForm: any = this.getPostData(this.formEmailCampaign);

        if (!this.validFilters) {
            return;
        }
        // postForm.emailText = postForm.emailText.replace('<style','<head><style').replace('</style>','</style></head>')
        if(!this.checkDynamicValues(postForm.emailText)){
            this.spinner.hide();
            this.toastr.error("Please use selected Dynamic Values");
            return
        }

        postForm.userCampaigns.campaignType = "Email";
        let dynamicVal = postForm.paramsSelected ? postForm.paramsSelected : [];
        this.dynamicListFields = []
        if(dynamicVal.length > 0){
        dynamicVal.forEach(element => {
            if(element.paramKey == 'name' || element.paramKey == 'email' || element.paramKey == 'mobile' || element.paramKey == 'android_fcm_token' || element.paramKey == 'ios_fcm_token' || element.paramKey == 'web_fcm_token' || element.paramKey == 'updated'){
                this.dynamicListFields.push(element.paramKey)
            } else{
                this.dynamicListFields.push('params.'+element.paramKey)
            }
        });
        }
        
        let campAttr = this.campAttributes ? ','+this.campAttributes : '';
        
        if(campAttr == 'Email'){
            campAttr = 'email'
        } else if(campAttr == ',Email'){
            campAttr = ',email'
        }
        
        let dynamiCheck = this.dynamicListFields.length > 0 ? ','+this.dynamicListFields.join() : '';
        postForm.fields = 'audience_id'+ campAttr + dynamiCheck;
        let filterJson = JSON.parse(postForm.userCampaigns.filtersJson);
        filterJson.fields = postForm.fields;
        filterJson.campAttributes = campAttr;
        postForm.userCampaigns.filtersJson = JSON.stringify(filterJson);
        delete postForm.paramsSelected;
        delete postForm.dynamicContentList;
        if(this.tatData){
            console.log(postForm);
            this.afterProcess.emit(postForm);
            this.modalRef.hide();
            return
        }
        if(this.url)
        {
        this.campaignService.saveEmailCampaign(postForm, this.url).subscribe( data => {
            this.afterSave(data,'email');
        });
        }
    }

    saveInAppNotification() {
        if (this.formInAppNotification.invalid) {
            this.toastr.error(this.global.MessageRequired);
            this.global.validateAllFormFields(this.formInAppNotification);
            return;
        }
        if (!this.formInAppNotification.controls.image.value) {
            this.toastr.error("Please Select Image");
            return;
        }
        let postForm: any = this.getPostData(this.formInAppNotification);

        if (!this.validFilters) {
        return;
        }

        postForm.userCampaigns.campaignType = "Inapp";

        let dynamicVal = postForm.paramsSelected ? postForm.paramsSelected : [];
        this.dynamicListFields = []
        if(dynamicVal.length > 0){
        dynamicVal.forEach(element => {
            if(element.paramKey == 'name' || element.paramKey == 'email' || element.paramKey == 'mobile' || element.paramKey == 'android_fcm_token' || element.paramKey == 'ios_fcm_token' || element.paramKey == 'web_fcm_token' || element.paramKey == 'updated'){
                this.dynamicListFields.push(element.paramKey)
            } else{
                this.dynamicListFields.push('params.'+element.paramKey)
            }
        });
        }
        
        let campAttr = this.campAttributes ? this.campAttributes : '';
        let dynamiCheck = this.dynamicListFields.length > 0 ? ','+this.dynamicListFields.join() : '';
        postForm.fields = 'audience_id';
        let filterJson = JSON.parse(postForm.userCampaigns.filtersJson);
        filterJson.fields = postForm.fields;
        filterJson.campAttributes = this.campAttributes;
        postForm.userCampaigns.filtersJson = JSON.stringify(filterJson);
        delete postForm.paramsSelected;
        delete postForm.dynamicContentList;
        if(this.url){
        this.campaignService.saveInAppNotification(postForm,this.url).subscribe( data => {
            this.afterSave(data,'inapp');
        });
        }
    }

    getInputParams() {
        this.projectService.fetchAttributeList().then(data => {
            this.inputParams = [];
            this.inputParamsFileType = []
            if(data){
                var list = this.projectService.filterAttributeList();
                list.forEach(element => {
                    this.inputParams.push({
                        paramKey: element.paramKey,
                        id: element.id,
                        paramType:element.paramDatatype,
                        paramValue: '['+element.paramKey+']',
                        paramName: element.paramName
                    });
                    if(element.paramDatatype == 'File'){
                        this.inputParamsFileType.push(element);
                    }
                    this.filters.push(element);
                });
                this.filters.sort((a,b)=>{
                    if(a.paramName < b.paramName){ return -1}
                    if(a.paramName > b.paramName){ return 1 }
                })
                this.inputParams.sort((a,b)=>{
                    if(a.paramName < b.paramName){ return -1}
                    if(a.paramName > b.paramName){ return 1 }
                })
            }
        });
    }

    getSeletedParem(event, form, field){
        if(field == 'emailText'){
        if(event.paramType == 'file' || event.paramType == 'File')
        {        
        this.insertContent(event.paramValue,'link')
        return;
        }
            this.insertContent(event.paramValue);
        } else {
            var value = event.paramValue;
            let msg =  form.controls[field].value;
            msg = msg != null ? msg : '';
            form.controls[field].setValue(msg+' '+value); 
        }
    }

    removeParem(event, form, field){

        let paramValue = event.value.paramValue;
        let msg =  form.controls[field].value;
        msg = msg != null ? msg : '';
        msg = msg.split(paramValue).join('');
        // msg = msg.replace(paramValue,"");
        form.controls[field].setValue(msg);
    }

    addEmoji(event, form, field){
        let emoji = event.emoji.native
        if(field == 'emailText'){
            this.insertContent(emoji);
        } else {
            let msg =  form.controls[field].value;
            msg = msg != null ? msg : '';
            form.controls[field].setValue(msg+' '+emoji);
        }
        this.isShowEmoji = false;
    }

    getPushTemplateList(){        
        this.pushTemplateList = [];
        this.campaignService.getPushTemplateData({},this.url,'reload').then(data => {
            if(data != undefined)
            this.pushTemplateList = data;            
        });
    }

    getPushTemplateData(){
        if(this.formPushNotification.value.template != ''){
            this.spinner.show();
            this.campaignService.pushTemplate({id:this.formPushNotification.value.template}, this.url).subscribe(
                data => {
                this.spinner.hide();
                this.setPushData(data.data);
            }, error => {
                this.spinner.hide();
                this.toastr.error("Something went wrong", "Error");
            });
        } else {
            this.formPushNotification.patchValue({
                deepLink: "",
                message: "",
                title: "",
                notificationImage: "",
                notificationIcon: ""
            });
            this.inputParamsSelected = [];
        }
    }

    setPushData(data){
        this.formPushNotification.patchValue({
            templateName: data.templateName,
            deepLink: data.deepLink,
            message: data.message,
            title: data.title,
            notificationImage: data.notImage,
            notificationIcon: data.notIcon,
        });
        this.inputParamsSelected = data.dynamicContentList;
    }

    getSmsTemplateList(){
        this.pushTemplateList = [];
        this.campaignService.getSmsTemplateData(this.url).then( data => {
            if(data != undefined)
            this.smsTemplateList = data;
        });
    }

    getWhatsappTemplateList()
    {
        this.pushTemplateList = [];
        this.campaignService.getWhatsappTemplateData({},this.url).then( data => {
            if(data != undefined)
            this.smsTemplateList = data;
        });
    }

    getWhatsappTemplateData(){
        if(this.formWhatsappCampaign.value.template != ''){
            this.campaignService.whatsappTemplate({id:this.formWhatsappCampaign.value.template},this.url).subscribe( data => {
                this.setWhatsappData(data.data);
            });
        } else {
            this.formWhatsappCampaign.patchValue({
                templateName: '',
                smsText: ''
            });
            this.inputParamsSelected = []
        }
    }

    setWhatsappData(data){   
    let headerUrlSet;
    let headerUrl;
    if(data.headerUrl){
      if(data.headerUrl.includes('http://') == true || data.headerUrl.includes('https://') == true){
        headerUrlSet = data.headerUrl;
        this.headerUrlText = headerUrlSet;
        this.headerUrl = [];
      }
      if(data.headerUrl.includes('[') == true && data.headerUrl.includes(']') == true){
        headerUrl = data.headerUrl.replace('[','').replace(']','');
        this.headerUrl = headerUrl;
        this.headerUrlText = "";
      }
    }  
    
        let setHeaderVal
        if(data.headerUrl){
        if(data.headerUrl.includes('http://') == true || data.headerUrl.includes('https://') == true){
            setHeaderVal = true;
        } else{
            setHeaderVal = false;
        }
        }
        this.formWhatsappCampaign.patchValue({
            templateName: data.templateName,
            smsText: data.smsText,
            headerUrl:this.headerUrl,
            headerUrlText:this.headerUrlText,
            buttonText:data.buttonText
        });
        
        this.inputParamsSelected = data.dynamicContentList;
        this.formWhatsappCampaign.disable();
        this.formWhatsappCampaign.controls.template.enable();
    }

    getSmsTemplateData(){
        if(this.formSMSCampaign.value.template != ''){
            this.campaignService.smsTemplate({id:this.formSMSCampaign.value.template}, this.url).subscribe( data => {
                this.setSmsData(data.data);
            });
        } else {
            this.formSMSCampaign.patchValue({
                templateName: '',
                smsText: ''
            });
            this.inputParamsSelected = []
        }
    }

    setSmsData(data){
        this.formSMSCampaign.patchValue({
            templateName: data.templateName,
            smsText: data.smsText
        });
        this.inputParamsSelected = data.dynamicContentList;
    }


    getEmailTemplateList(){
        this.pushTemplateList = [];
        this.campaignService.getEmailTemplateData(this.url).then(data => {
            if(data != undefined)
            this.emailTemplateList = data;                        
        });
    }

    getEmailTemplateData(id){
        if(this.formEmailCampaign.value.template != ''){
            this.campaignService.emailTemplate({id:this.formEmailCampaign.value.template},this.url).subscribe( data => {
                this.setEmailData(data.data);
            });
        } else {
            this.formEmailCampaign.patchValue({
                templateName: '',
                emailSubject: '',
                emailText: ''
            });
            this.inputParamsSelected = [];
            this.isEditorView = true;
        }
    }

    setEmailData(data){
        this.formEmailCampaign.patchValue({
            templateName: data.templateName,
            emailSubject: data.emailSubject,
            emailText: data.emailText
        });
        this.inputParamsSelected = data.dynamicContentList;
        this.isEditorView = data.isEditorView;
    }

    getMedia(item){
        if(this.campaign == 'Email'){
            var mediaHTML: string = '';
            if(item.type == AppConstants.FILETYPE_FORM){
                mediaHTML = '<a href="'+ApiConstants.CUSTOM_FORM_LINK+item.linkRewrite+'" target="_blank">'+item.name+'</a>'
                this.toastr.success('Double click on "'+item.name+'" to update.')
            } else if(item.type === AppConstants.FILETYPE_FILE){
                mediaHTML = '<a href="'+item.originalFile+'" target="_blank">'+item.mediaName+'</a>'
                this.toastr.success('Double click on "'+item.mediaName+'" to update.')
            } else {
                mediaHTML = '<img src="'+item.originalFile+'" width="200" alt="'+item.mediaName+'" />';
            }
            this.insertContent(mediaHTML);
        } else if(this.campaign == 'SMS'){
            let msg =  this.formSMSCampaign.controls['smsText'].value;
            msg = msg != null ? msg : '';
            var itemUrl = '';
            if(item.type == AppConstants.FORM){
                var itemUrl = ApiConstants.CUSTOM_FORM_LINK+item.linkRewrite
            } else {
                itemUrl = item.originalFile
            }
            if(this.authenticationService.isPageSubscribe('Url Shortner')){
                this.urlShortnerService.getUrlShortner(itemUrl).then( data => {
                this.formSMSCampaign.controls['smsText'].setValue(msg+' '+data);
              })
            } else {
                this.formSMSCampaign.controls['smsText'].setValue(msg+' '+itemUrl);
            }
        } else if(this.campaign == 'In-App'){
            this.formInAppNotification.controls.imagePath.setValue(item.originalFile);
            this.formInAppNotification.controls.image.setValue(item.fileName);
        } else {
            if(item.fileType == AppConstants.FILETYPE_IMAGE || item.fileType == AppConstants.FILETYPE_GALLERY){
                this.formPushNotification.controls.notificationImage.setValue(item.originalFile);
                this.formPushNotification.controls.notImage.setValue(item.fileName);
            }
        }
    }

    insertContent(html,link?) {
        if(this.isEditorView){
            for (var i in CKEDITOR.instances) {
                if(link)
                {          
                CKEDITOR.instances[i].insertHtml('<a href="'+html+'">'+'Click Here'+'</a>');
                return;
                }
                CKEDITOR.instances[i].insertHtml(html);
            }
        } else {
            let msg =  this.formEmailCampaign.controls['emailText'].value;
            msg = msg != null ? msg : '';
            if(link)
            {          
                this.formEmailCampaign.controls['emailText'].setValue(msg+' '+'<a href="'+html+'">'+'Click Here'+'</a>');
                return;
            }
            this.formEmailCampaign.controls['emailText'].setValue(msg+' '+html);
        }
        return true;
    }

    removeLocalStorage(){
        localStorage.removeItem('selectedFilterCamp');
    }

    savejourneyCampaign(){
        let form  = {
            value : {}
        }
        let postForm: any = this.getPostData(form);
        postForm.journeyName= this.global.CampaignData.journeyName,
	    postForm.journeyUserId = this.global.CampaignData.journeyUserId,
        this.campaignService.saveJourneyCampaign(postForm).subscribe(data => {
            if(data.status){
            this.afterSave(data,'journey');
            } else{
                this.toastr.error(data.error_msgs);
            }
        })
    }
}