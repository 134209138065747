<main>
    <section class="inner-page">
        <div class="inner-page-header">
            <div class="container-fluid">
                <h4>
                    <a class="back-link" (click)="back()"><img src="assets/images/angle-left.svg"></a>
                    Attendance Dashboard
                </h4>
            </div>
        </div>

        <div *ngIf="isSubscribe" class="inner-page-body site-form">
            <div class="container-fluid">
                <div class="form-row mb-4 justify-content-between">

                    <div class="col-12 d-flex justify-content-end">

                        <div class="dropdown workScheduleBox m_r_15" *ngIf="showWorkSchedule">
                            <button class="btn dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" (click)="viewWorkingDay()">
                                Work Schedule
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenu2" #dropdownMenu2>
                                <form #scheduleForm="ngForm" (ngSubmit)="submitScheduleForm(scheduleForm.value)">
                                <ul class="weekdaysList">
                                    <li *ngFor="let list of weekDays" (click)="$event.stopPropagation()">
                                        <div class="borderradis">
                                            <div class="d-flex c-align">
                                                <label class="check">
                                                    <input type="checkbox" value="1" name="{{list.key}}" ngModel>
                                                    <span class="checkmark"></span>
                                                </label>
                                                <span class="c-pointer">{{list.value}}</span>
                                            </div>    
                                        </div>
                                    </li>
                                    <li class="checkInCheckOutLi" (click)="$event.stopPropagation()">
                                        <div class="checkIn-checkOut">
                                            <div class="checkInBox">
                                                <div>Check In</div>
                                                <select name="checkIn" [(ngModel)]="checkIn">
                                                    <option value="00">Select</option>
                                                    <option value="09:00">09:00</option>
                                                    <option value="09:30">09:30</option>
                                                    <option value="10:00">10:00</option>
                                                    <option value="10:30">10:30</option>
                                                    <option value="11:00">11:00</option>
                                                </select>
                                            </div>
                                            <div class="checkOutBox">
                                                <div>Check Out</div>
                                                <select name="checkOut" [(ngModel)]="checkOut">
                                                    <option value="00">Select</option>
                                                    <option value="18:00">06:00</option>
                                                    <option value="18:30">06:30</option>
                                                    <option value="19:00">07:00</option>
                                                    <option value="19:30">07:30</option>
                                                    <option value="20:00">08:00</option>
                                                </select>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="text-center">
                                        <button class="btn workScheduleBtn" type="submit">
                                            Save
                                        </button>
                                    </li>
                                </ul>
                                </form>

                                

                            </div>
                          </div>

                       <input type="text" placeholder="From Date"  
                       placement="top left" 
                       readonly 
                       [maxDate]="maxDate"
                       class="form-control date-icon modal-space max_width_160 m_r_15" 
                       [(ngModel)]="startDate"
                       bsDatepicker autocomplete="off"
                       [bsConfig]="global.datePickerConfig" 
                       (ngModelChange)="changeDate($event)"
                     [ngModelOptions]="{standalone: true}">

                     <button class="btn btn-dark" (click)="emailAddress='';downloadXl()" tooltip="Download Excel" [tooltipAnimation]="true" tooltipPlacement="top">
                        <i class="icon-download"></i>
                    </button>
                    </div>
                </div>
                <div class="table-responsive table-height-scroll table-reports">
                    <table class="table callAssistDashboardTable">
                            <thead class="sticky-header" data-sticky-header="true">
                            <tr class="callAssistDbRowTop" data-sticky-header="true">
                                <th rowspan="2" class="borderTh th_width_60">Sr. No.</th>
                                <th rowspan="2" class="borderTh text-center th_width_130">User Name</th>
                                <th *ngIf="tellyCallerAttendanceList.length > 0" rowspan="2" [class.borderTh]="tellyCallerAttendanceList.length > 0"><span  *ngIf="tellyCallerAttendanceList.length > 0">Location</span></th>
                                <th rowspan="2" class="borderTh th_width_70">In Time</th>
                                <th rowspan="2" class="borderTh th_width_70">Out Time</th>
                                <th colspan="5" class="text-center borderTh"> Calls </th>
                                <th colspan="2" class="text-center borderTh">Call Duration</th>
                                <th class="text-center borderTh" rowspan="2">GPS</th>
                                <th rowspan="2" class="borderTh th_width_110">Status</th>
                                <th rowspan="2" class="borderTh text-center">Last Call</th>
                            </tr>
                            <tr class="callAssistDbRowBottom">
                                <th class="borderTh">Total</th>
                                <th class="borderTh">Outgoing</th>
                                <th class="borderTh">Incoming</th>
                                <th class="borderTh">Connected</th>
                                <th class="borderTh th_width_100">Not Connected</th>
                                <th class="borderTh">Incoming</th>
                                <th class="borderTh">Outgoing</th>
                            </tr>
                            </thead>
                            <tbody class="norecord-handling">
                            <tr *ngFor="let item of tellyCallerAttendanceList; let i = index">
                                <td>{{i+1}}</td>
                                <td><a href="javascript:void(0)" class="text-capitalize" (click)="navigateWithState(item.userId)">{{item.userId | userFromId:telecallerList}}</a></td>
                                <td *ngIf="tellyCallerAttendanceList.length > 0"><button class="btn locatBtn" (click)="viewLocationHistory(item.userId)">View</button></td>
                                <td><span *ngIf="item.inTime != undefiend">{{item.inTime.replace(' ', 'T') | date:'HH:mm'}} </span><a *ngIf="item.lastLocation != null " target="_blank" [href]="item.lastLocation"><img class="width_10" src="assets/images/location.svg"></a><a *ngIf="item.lastLocation == null && item.location != null" target="_blank"  [href]="item.location"><img class="width_10" src="assets/images/location.svg"></a></td>
                                <td><span *ngIf="item.outTime != undefiend">{{item.outTime.replace(' ', 'T') | date:'HH:mm'}}</span></td>
                                <td>{{item.totalCalls}}</td>
                                <td>{{item.outgoingCount}}</td>
                                <td>{{item.incomingCount}}</td>
                                <td>{{item.totalConnected}}</td>
                                <td>{{item.totalUnconnected}}</td>
                                <td>{{item.incoming}}</td>
                                <td>{{item.outgoing}}</td>
                                <td *ngIf="item.enableGPS == undefiend" class="text-center">NA</td>
                                <td *ngIf="item.enableGPS != undefiend && item.enableGPS === true" class="text-center">On</td>
                                <td *ngIf="item.enableGPS != undefiend && item.enableGPS === false" class="text-center">Off</td>
                                <td><span *ngIf="item.outTime != undefiend">Log Out</span><span *ngIf="item.outTime == undefiend">{{item.lastCallLog | telleCallerStatus:item.inTime:item.outTime:item.breakStartTime}}</span></td>
                                <td><span *ngIf="item.lastCallLog != undefiend" class="font-11">{{item.lastCallLog.replace(' ', 'T') | date:'HH:mm'}}</span></td>
                            </tr>
                            <tr *ngFor="let item of tellyCallerNotLoggedIn; let i=index">
                                <td>
                                    <ng-container *ngIf="tellyCallerAttendanceList.length > 0">
                                        {{i+1+tellyCallerAttendanceList.length}}
                                    </ng-container>
                                    <ng-container *ngIf="tellyCallerAttendanceList.length == 0">
                                        {{i+1}}
                                    </ng-container>
                                </td>
                                <td class="text-capitalize">{{item.userId | userFromId:telecallerList}}</td>
                                <td *ngIf="tellyCallerAttendanceList.length > 0"></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>{{item.status}}</td>
                                <td></td>
                            </tr>
                            <tr *ngIf="tellyCallerAttendanceList.length == 0 && tellyCallerNotLoggedIn == 0">
                                <td class="no-records" colspan="14">No Records Found</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                
            </div>
        </div>
        <app-page-disable *ngIf="!isSubscribe"></app-page-disable>
    </section>
<ng-template #download_xl_modal>
    <div class="forgot-password-modal">
        <div class="modal-header">
            <h4 class="modal-title pull-left">Download Excel</h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body modal-user-management">
            <div class="form-group">
                <ng-select  [(ngModel)]="callassistName" class="form-control auto_height_select" [multiple]="true" >
                    <ng-option *ngFor="let item of tellyCallerAttendanceList" [value]="item.userId">
                        {{item.userId | userFromId:telecallerList}}
                    </ng-option>
                    <ng-option *ngFor="let item of tellyCallerNotLoggedIn" [value]="item.userId">
                        {{item.userId | userFromId:telecallerList}}
                    </ng-option>
                </ng-select>
            </div>
            <div class="form-group">
                <input type="text" class="form-control" [(ngModel)]="emailAddress" placeholder="Enter your email for report delivery.">
            </div>
            <div class="form-group d-flex j-space-between">
                <input type="text" class="width_auto min_width_200 form-control date-icon" readonly autocomplete="off" ngxDaterangepickerMd [(ngModel)]="filterDateRange" [alwaysShowCalendars]="true" [locale]="{format: 'DD/MM/YYYY'}" [ranges]="ranges" [autoApply]="false" [opens]="'left'" [maxDate]="maxDate_range"
                            placeholder="Select Date Range">
            
                <button class="btn btn-dark downlo_xl_cnfrm" type="button" (click)="send_email()">Send Email</button>
                <button class="btn btn-dark downlo_xl_cnfrm" type="button" (click)="download_cnfrm()">Download Excel</button>

            </div>
        </div>
    </div>
</ng-template>
</main>
