<main *ngIf="!showAlertMessage">
    <section class="inner-page">

        <div class="inner-page-header">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-6">
                    <h4><a class="back-link" [routerLink]="['/admin/dashboard']"><img src="assets/images/angle-left.svg"></a> {{pageTitle}}</h4>
                    </div>
                </div>
            </div>
        </div>

        <div class="inner-page-body">
            <div class="container-fluid">
                <form (keydown.enter)="$event.preventDefault()">
                    <div class="row">
                        <div class="col-md-12 col-12 col-sm-12">
                            <div class="form-group row">
                                <label class="col-lg-2 col-md-12 col-12 col-sm-12 control-label">Campaign Name</label>
                                <div class="col-lg-4 col-md-6 col-12 col-sm-12">
                                    <input trim="blur" type="text" class="form-control" name="campaignName" [(ngModel)]="campaignName" maxlength="100">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row"  *ngIf="isJourney">
                        <div class="col-md-12 col-12 col-sm-12">
                            <div class="form-group row">
                                <label class="col-lg-2 col-md-12 col-12 col-sm-12 control-label">Campaign Journey</label>
                                <div class="col-lg-4 col-md-6 col-12 col-sm-12">
                                    <select name="journey" [(ngModel)]="journeyDataName" class="form-control">
                                        <option value="">Select Campaign</option>
                                        <option *ngFor="let item of campaignJourneyList" value="{{item.campName}},{{item.journeyUserId}}">{{item.campName}} ({{item.userName}})</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="campaign == 'Push'">
                        <div class="col-md-12 col-12 col-sm-12">
                            <div class="form-group row">
                                <label class="col-md-2 col-12 col-sm-12 control-label lable-width">Platforms</label>
                                <div class="col-md-2 col-4">
                                    <label class="check">
                                        <span class="icon-globe"></span> Web
                                        <!-- <input type="checkbox" name="webPushNotification" [(ngModel)]="webPushNotification" [disabled]="isChannelType"> -->
                                        <input type="radio" name="platFormType" [disabled]="isChannelType" value="web" (change)="platFormSelect($event)">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <div class="col-md-2 col-4">
                                    <label class="check">
                                        <span class="icon-apple"></span> IOS
                                        <!-- <input type="checkbox" name="iosPushNotification" [(ngModel)]="iosPushNotification" [disabled]="isChannelType"> -->
                                        <input type="radio" name="platFormType" [disabled]="isChannelType" value="ios" (change)="platFormSelect($event)">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <div class="col-md-4 col-4">
                                    <label class="check">
                                        <span class="icon-android"></span> Android
                                        <!-- <input type="checkbox" name="androidPushNotification" [(ngModel)]="androidPushNotification" [disabled]="isChannelType"> -->
                                        <input type="radio" name="platFormType" [disabled]="isChannelType" value="android" (change)="platFormSelect($event)">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="mb-3">
                      <div class="form-group row">
                        <div class="col-lg-2 col-md-12"></div>
                        <div class="col-lg-10 col-md-12">
                          <label class="check">
                              Allow Duplicate Records
                              <input type="checkbox" name="allowDuplicate" [(ngModel)]="campaignSetting.allowDuplicate" [disabled]="isChannelType">
                              <span class="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </div>  -->

                    <div class="mb-3" *ngIf="alternateUse == 'Mobile' || alternateUse == 'Email'">
                      <div class="form-group row">
                        <label class="col-lg-2 col-md-12 control-label">Use Alternate {{alternateUse}}</label>
                        <div class="col-lg-10 col-md-12">
                          <ng-select [multiple]="true" class="form-control alternate_multi_select auto_height_select" [(ngModel)]="campaignSetting.alternateUse" name="alternate">
                            <ng-option *ngFor="let options of alternateData" [value]="options.paramKey">
                                {{options.paramName}}
                            </ng-option>
                          </ng-select>
                        </div>
                      </div>
                    </div> 
                    
                    <div class="mb-3">
                        <div class="form-group row">
                          <label class="col-lg-2 col-md-12 control-label">Send</label>
                          <div class="col-lg-10 col-md-12">
                            <div class="row">
                              <div class="col-lg-3 col-md-12">
                                <label class="default-radio">Now
                                  <input type="radio" value="now" [(ngModel)]="campaignSetting.send" name="send">
                                  <span class="checkmark"></span>
                                </label>
                              </div>
                              <div class="col-lg-4 col-md-12" *ngIf="!isJourney">
                                <label class="default-radio">Schedule
                                  <input type="radio" [(ngModel)]="campaignSetting.send" value="schedule" name="send">
                                  <span class="checkmark"></span>
                                </label>
                              </div>
                              <div class="col-lg-4 col-md-12" *ngIf="campaign != 'In-App'">
                                <label class="default-radio">Repeat
                                  <input type="radio" [(ngModel)]="campaignSetting.send" value="repeat" name="send">
                                  <span class="checkmark"></span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="mb-3" *ngIf="campaignSetting.send == 'schedule'">
                        <div class="form-group row">
                          <label class="col-lg-2 col-md-12 control-label">Date & Time</label>
                          <div class="col-lg-10 col-md-12">
                            <div class="row">
                              <div class="col-md-3">
                                <input type="text" placeholder="Datepicker" placement="top left" [(ngModel)]="campaignSetting.date"  name="date"
                                  class="form-control date-icon modal-space" [minDate]="minDate" bsDatepicker autocomplete="off"  [bsConfig]="global.datePickerConfig">
                              </div>
                              <div class="col-md-3">
                                <div class="timepicker-position">
                                  <timepicker [(ngModel)]="campaignSetting.time" [ngModelOptions]="{standalone: true}"></timepicker>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="mb-3" *ngIf="campaignSetting.send == 'repeat'">
                        <div class="form-group row">
                          <label class="col-lg-2 col-md-12 control-label">Select Attribute</label>
                          <div class="col-lg-10 col-md-12">
                            <div class="row">
                              <div class="col-lg-3 col-md-3 mt-1 pr-1" *ngIf="!isJourney">
                                <select class="form-control" [(ngModel)]="campaignSetting.campFrequency" name="campFrequency" (change)="campaignSetting.paramWhen = 'sameday';campaignSetting.paramId='entry_date'">
                                  <option value="everyday">Everyday</option>
                                  <option value="weekly">Weekly</option>
                                  <option value="monthly">Monthly</option>
                                  <option value="custom">Custom</option>
                                </select>
                              </div>
                              <div class="col-lg-3 col-md-3 mt-1 pl-1 pr-1" *ngIf="campaignSetting.campFrequency == 'weekly'">
                                <select class="form-control" [(ngModel)]="campaignSetting.weekDay" name="weekDay">
                                  <option disabled value="">Day</option>
                                  <option value="1">Mon</option>
                                  <option value="2">Tue</option>
                                  <option value="3">Wed</option>
                                  <option value="4">Thu</option>
                                  <option value="5">Fri</option>
                                  <option value="6">Sat</option>
                                  <option value="7">Sun</option>
                                </select>
                              </div>
                              <div class="col-lg-2 col-md-3 mt-1 pl-1 pr-1"
                                *ngIf="campaignSetting.campFrequency == 'monthly'">
                                <select [(ngModel)]="campaignSetting.monthDay" class="form-control" name="monthDay">
                                  <option disabled value="">Date</option>
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                  <option value="6">6</option>
                                  <option value="7">7</option>
                                  <option value="8">8</option>
                                  <option value="9">9</option>
                                  <option value="10">10</option>
                                  <option value="11">11</option>
                                  <option value="12">12</option>
                                  <option value="13">13</option>
                                  <option value="14">14</option>
                                  <option value="15">15</option>
                                  <option value="16">16</option>
                                  <option value="17">17</option>
                                  <option value="18">18</option>
                                  <option value="19">19</option>
                                  <option value="20">20</option>
                                  <option value="21">21</option>
                                  <option value="22">22</option>
                                  <option value="23">23</option>
                                  <option value="24">24</option>
                                  <option value="25">25</option>
                                  <option value="26">26</option>
                                  <option value="27">27</option>
                                  <option value="28">28</option>
                                  <option value="29">29</option>
                                  <option value="30">30</option>
                                  <option value="31">31</option>
                                </select>
                              </div>
                              <div class="col-lg-2 col-md-3 mt-1 pr-lg-0 pr-md-0"
                                *ngIf="campaignSetting.campFrequency == 'custom'">
                                <select class="form-control" [(ngModel)]="campaignSetting.paramId" name="paramId">
                                  <ng-container *ngFor="let item of filters">
                                    <option  [value]="item.paramKey" *ngIf="item.paramDatatype=='Date'">{{item.paramName}}</option>
                                  </ng-container>
                                </select>
                              </div>
                              <div class="col-lg-2 col-md-3 pl-1 pr-1 mt-1"
                                *ngIf="campaignSetting.campFrequency == 'custom' && !isJourney">
                                <select class="form-control" [(ngModel)]="campaignSetting.paramWhen" name="paramWhen">
                                  <option value="before">Before</option>
                                  <option value="after">After</option>
                                  <option value="sameday">Same Day</option>
                                  <option value="sametime">Same Time</option>
                                </select>
                              </div>
                              <div class="col-lg-2 col-md-3 pl-1 pr-1 mt-1"
                                *ngIf="campaignSetting.campFrequency == 'custom' && campaignSetting.paramWhen != 'sameday' && campaignSetting.paramWhen != 'sametime' && !isJourney">
                                <input trim="blur" type="number" [(ngModel)]="campaignSetting.paramWhenDay" class="form-control" name="paramWhenDay" placeholder="Days">
                              </div>
                              <div class="col-lg-2 col-md-3 pl-1 pr-1 mt-1"
                              *ngIf="campaignSetting.paramWhen != 'sametime' && !isJourney"
                                [ngClass]="{'': campaignSetting.campFrequency != 'everyday' && campaignSetting.campFrequency != 'custom'}">
                                <div class="timepicker-position">
                                  <timepicker [(ngModel)]="campaignSetting.campExecutionTime" [ngModelOptions]="{standalone: true}"></timepicker>
                                </div>
                              </div>
                              <div class="col-lg-2 col-md-3 pl-1 pr-1 mt-1" *ngIf="campaignSetting.paramWhen == 'sametime'">
                                <select class="form-control" [(ngModel)]="campaignSetting.paramInterval" name="paramInterval">
                                    <option value="0.8">Select Interval</option>
                                    <option value="0.0">Instant</option>
                                    <option value="0.5">5 Minutes</option>
                                    <option value="0.15">15 Minutes</option>
                                    <option value="0.30">30 Minutes</option>
                                    <option value="1.0">1 Hours</option>
                                    <option value="2.0">2 Hours</option>
                                    <option value="3.0">3 Hours</option>
                                    <option value="5.0">5 Hours</option>
                                    <option value="8.0">8 Hours</option>
                                    <option value="12.0">12 Hours</option>
                                    <option value="24.0">24 Hours</option>
                                </select>
                            </div>
                            </div>
                            <!-- <div class="form-text text-muted" *ngIf="isJourney">Custome campaign </div> -->
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-lg-2 col-md-12 control-label">Valid From/To</label>
                          <div class="col-lg-8 col-md-12">
                            <div class="row">
                              <div class="col-lg-4 col-md-4 pr-lg-0 pr-md-0">
                                <input type="text" placeholder="From Date" placement="top left" [(ngModel)]="campaignSetting.date" name="date" (bsValueChange)="campaignSetting.toDate = null"
                                  class="form-control date-icon modal-space" [minDate]="minDate" bsDatepicker autocomplete="off"  [bsConfig]="global.datePickerConfig">
                              </div>
                              <div class="col-lg-4 col-md-4">
                                <input type="text" placeholder="To Date" placement="top left" [(ngModel)]="campaignSetting.toDate" name="toDate"
                                  class="form-control date-icon modal-space" [minDate]="campaignSetting.date" bsDatepicker autocomplete="off"  [bsConfig]="global.datePickerConfig">
                              </div>
                            </div>
                          </div>
                        </div>
          
                        <div class="row">
                          <div class="col-lg-10 offset-lg-2">
                            <div class="push-note">
                              <p>
                                <strong>Note:</strong> 
                                <ng-container *ngIf="campaignSetting.paramWhen != 'sametime' ; else elseEmailCampaignNote">
                                  Repeated campaign will execute only when your customer count falls in between allowed limit as per your plan.
                                </ng-container>
                                <ng-template #elseEmailCampaignNote>
                                  Campaigns set with "sametime frequency" will be executed within 10 minutes of user creation time.
                                </ng-template>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    
                    <div class="row">
                        <div class="col-md-12 col-12 col-sm-12">
                            <div class="form-group row">
                                <label class="col-lg-2 col-md-12 col-12 col-sm-12 control-label">Select Audience</label>
                                <div class="col-lg-8 col-md-12 col-12 col-sm-12 d-none d-sm-block">
                                    <button type="submit" class="btn-green" [disabled]="isChannelType" (click)="goWithAllAudience()">Send to All Audience</button> &nbsp;
                                    <button type="submit" class="btn-green" [disabled]="isChannelType" [class.selected]=isShowCampaignCreate (click)="selectFreshAudienceModal(selectfreshAudience)">Select Fresh Audience</button> &nbsp;
                                    <button type="submit" class="btn-green" [disabled]="isChannelType" [class.selected]=isSegmentList (click)="selectSegments()">Select Segments</button>
                                </div>
                                <div class="col-lg-8 col-md-12 col-12 col-sm-12 d-block d-sm-none sm-btn-group">
                                    <button type="submit" class="btn-green" [disabled]="isChannelType" (click)="goWithAllAudience()">All Audience</button> 
                                    <button type="submit" class="btn-green" [disabled]="isChannelType" [class.selected]=isShowCampaignCreate (click)="selectFreshAudienceModal(selectfreshAudience)">Select Audience</button>
                                    <button type="submit" class="btn-green" [disabled]="isChannelType" [class.selected]=isSegmentList (click)="selectSegments()">Select Segments</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div *ngIf="isShowCampaignCreate">
                    <app-campaign-create (updateAudience)="selectFreshAudienceModal(selectfreshAudience)" (createAudienceClose)="hideAudience($event);" [closeFilter]="updateAudienceFilter" [isSourceUpdate]="isSourceUpdate"></app-campaign-create>
                </div>
                <div *ngIf="isChannelType" class="mb-4">
                    <app-channel-type (clearFilter)="clearFilter($event)" (getAudienceCount)="getAudienceCount($event)" [proceedEvent]="campaignProceed" [isIndividualCampaign]="false" [campaignSetting]="campaignSetting" [url]="push_url" [campaignType]="campaignSubmit"></app-channel-type>
                </div>
                <div class="table-reports" *ngIf="isSegmentList">
                    <div class="table-body">
                        <div class="table-responsive table-height-scroll text-center">
                            <table class="table">
                                <tbody class="norecord-handling">
                                    <tr>
                                        <th>
                                        <label class="check">
                                            <input type="checkbox" [(ngModel)]="selected" (change)="selectAll()">
                                            <span class="checkmark check-margin"></span>
                                        </label>
                                        </th>
                                        <th class="text-left">Segment Name</th>
                                        <th class="text-left">Category Name</th>
                                        <th class="text-left">Segment ID</th>
                                        <th class="text-left">Type</th>
                                        <th class="text-left">Last Modified Date</th>
                                        <th>View</th>
                                    </tr>
                                    <tr *ngFor="let item of segments | myFilter:segmentText let i = index;">
                                        <td>
                                            <label class="check">
                                                <input type="checkbox" (change)="assignSegmentChannel(item)" checked="true" [(ngModel)]="item.checked" [ngModelOptions]="{standalone: true}">
                                                <span class="checkmark check-margin"></span>
                                            </label>
                                        </td>
                                        <td class="text-left">{{item.segmentName}}</td>
                                        <td class="text-left">{{item.catName}}</td>
                                        <td class="text-left">{{item.segmentId}}</td>
                                        <td class="text-left">{{item.segmentType}}</td>
                                        <td class="text-left">{{item.lastModifyVal |date}}</td>
                                        <td><a class="btn without-hover btn-sm" (click)="openModal(item.id,webPushSegment)"><img src="assets/images/segmenteye.png" /></a></td>
                                    </tr>
                                    <tr class="d-none">
                                        <td class="no-records" colspan="8">No Segments Yet</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="table-footer margin-top-proceed-btn">
                        <div class="row mt-5">
                            <div class="ml-auto col-md-4 text-right col-12 col-sm-12">
                                <button type="button" class="btn btn-dark" (click)="gotoChannelType()">Calculate Audience</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="margin-top-proceed-btn">
                    <div class="row mt-5">
                        <div class="col-md-4 col-12 col-sm-12" *ngIf="isChannelType && !isJourney">
                            <div class="total-user-text">Total User</div>
                            <div class="total-user"><b>{{audienceCount}} User(s)</b></div>
                        </div>
                        <div class="ml-auto col-md-2 text-right col-12 col-sm-12">
                            <button type="button" class="btn btn-dark mr-3" [disabled]="!isChannelType" (click)="submitCampaign()">Proceed</button>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </section>
</main>

<div *ngIf="showAlertMessage" class="info-outer-box">
    <div class="inner-box">
        <div class="bg-color">
            <h6>{{alertTitle}}</h6>
            <p>{{alertMessage}}</p>
            <div class="footer text-center">
                <a *ngIf="userRoleId == '2'" [routerLink]="['/admin/dashboard']" class="btn btn-dark mr-3">OK</a>
                <a *ngIf="userRoleId != '2'" [routerLink]="['/admin/configuration']" class="btn btn-dark mr-3">OK</a>
                <a href="javascript:;" (click)="goBack()" class="btn btn-dark">Cancel</a>
            </div>
        </div>
    </div>
</div>

<ng-template #alertMessageModel>
  <div class="modal-header">
    <h5 class="modal-title pull-left font_17">{{alertTitle}}</h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
      <p>{{alertMessage}}</p>
      <div class="footer text-center">
        <button (click)="modalRef.hide()" class="btn btn-dark mr-3">OK</button>
    </div>
  </div>
</ng-template>

<ng-template #webPushSegment>
    <form>
        <div class="forgot-password-modal">
            <div class="modal-header segment_bg">
                <h4 class="modal-title pull-left">Web Push Segment</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()"><span aria-hidden="true"><img class="img" src="assets/images/times-circle_grey.svg"></span></button>
            </div>
            <div class="web-push-modal-body">
                <div class="row">
                    <div class="col-md-6">
                        <label class="control-label col-sm-5 label-left grey-pop-color" for="inputDefault">Segment Name:</label>
                        <label class="control-label col-sm-7 label-right" for="inputDefault">{{this.segmentdata.segmentName}}</label>
                    </div>
                    <div class="col-md-6">
                        <label class="control-label col-sm-5 label-left grey-pop-color" for="inputDefault">Last Modified:</label>
                        <label class="control-label col-sm-7 label-right" for="inputDefault">{{this.segmentdata.lastModifyVal |date :'d MMMM yyyy hh:mm:ss'}}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <label class="control-label col-sm-5 label-left grey-pop-color" for="inputDefault">Segment Type:</label>
                        <label class="control-label col-sm-7 label-right" for="inputDefault">{{this.segmentdata.segmentType}}</label>
                    </div>
                    <div class="col-md-6">
                        <label class="control-label col-sm-5 label-left grey-pop-color" for="inputDefault">Created:</label>
                        <label class="control-label col-sm-7 label-right" for="inputDefault">{{this.segmentdata.createdVal |date :'d MMMM yyyy hh:mm:ss'}}</label>
                    </div>
                </div>
            </div>
            <div *ngFor="let item of source let i = index;" class="">
                <div class="modal-header segment_bg"><h4 class="modal-title pull-left">Segment Group {{i+1}}</h4></div>
                <div class="web-push-modal-body">
                    <div class="row pt-2 pb-2">
                        <div class="col-md-4">
                            <div class="row pl-15">
                                <label class="control-label col-sm-5 label-left f-15" for="inputDefault">Event:</label>
                                <label class="control-label col-sm-7 label-right f-15" for="inputDefault">{{item.eventName!=undefiend?item.eventName:'All Events'}}</label>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="row pl-15">
                                <label class="control-label col-sm-5 label-left f-15" for="inputDefault">Last Active:</label>
                                <label class="control-label col-sm-7 label-right f-15 pr-0" for="inputDefault">{{item.lastActive!=''?item.lastActive:'From Beginning'}}</label>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="row pl-15">
                                <label class="control-label col-sm-5 label-left f-15" for="inputDefault">Source:</label>
                                <label class="control-label col-sm-7 label-right f-15 pr-0" for="inputDefault">{{item.source!=''?item.source:'All Sources'}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <ng-container *ngFor="let segment of item.segmentFilters; let j = index;">
                        <div class="col-md-4 border-grey-right">
                            <div class="row pl-15">
                                <label class="control-label col-sm-5 label-left grey-pop-color" for="inputDefault">Filter {{j+1}}:</label>
                                <label class="control-label col-sm-7 label-right" for="inputDefault">{{segment.projectInputParamName}}</label>
                            </div>
                            <div class="row pl-15">
                                <label class="control-label col-sm-5 label-left grey-pop-color" for="inputDefault">Values:</label>
                                <label class="control-label col-sm-7 label-right" for="inputDefault">{{segment.paramValues}}</label>
                            </div>
                        </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #selectfreshAudience>
    <app-fresh-audience-popup [modalRef]="modalRef" [filterClose]="filterClose.bind(this)" [usingFor]="'CampaignCreate'" [filterByCampaign]="filterByCampaign"></app-fresh-audience-popup>
</ng-template>
