<main>
  <section class="inner-page invoiceEstimatePage">
      <div class="inner-page-header">
          <div class="container-fluid">
              <div class="form-row d-flex headMainOuterBox">
                  <div class="headHalfBox">
                      <h4>
                          <a class="back-link" (click)="back()"><img src="assets/images/angle-left.svg"></a>
                          Quotation
                      </h4>
                  </div>
              </div>
          </div>
      </div>
      <div class="inner-page-body">
          <div class="container-fluid" *ngIf="isSubscribe">
            <div class="row">
              <div class="col-md-12 d-flex j-end top_tool_box j-space-between">
                <div class="d-flex">
                
                

                <div class="invoiceSearchAdvance m-r-10">
                  <select class="form-control" [(ngModel)]="createdBy" (change)="getInvoiceList($event)">
                    <option value="createdBy">Created By</option>
                    <option *ngIf="currentUser" [value]="currentUser.userId">{{currentUser.firstName}} {{currentUser.lastName}}</option>
                    <ng-container *ngIf="existingUsers.length > 0">
                    <option *ngFor="let list of existingUsers" [value]="list.id">{{list.userName}}</option>
                    </ng-container>
                  </select>
                </div>


                <div class="invoiceSearchAdvance m-r-10">
                  <select class="form-control" [(ngModel)]="filterByStatus" (change)="searchByStatus()">
                    <option value="Invoice Status">All</option>
                    <option value="SENT">Sent</option>
                    <option value="DRAFT">Draft</option>
                    <option value="EXPIRED">Expired</option>
                  </select>
                </div>

                <div class="invoiceSearchAdvance m-r-10">
                  <div class="form-group datepicker">
                    <input class="datePickerController date-icon" type="text" readonly autocomplete="off" ngxDaterangepickerMd [(ngModel)]="filterDateRange" [locale]="{format: 'DD/MM/YYYY'}" [alwaysShowCalendars]="true" [ranges]="dateRanges" [autoApply]="false" [opens]="'left'" [maxDate]="maxDate" placeholder="Select Date Range"
                    (datesUpdated)="invoiceCustomDateSelect($event)">
                </div>
                </div>

              </div>
                
                <div class="d-flex">

                <div class="searchInputBox m-r-10">
                  <div class="filter-input-space search-box">
                    <input autocomplete="off" class="btn" name="search" placeholder="Search" trim="blur" type="text" [(ngModel)]="invoiceSearch" (keyup.enter)="filterByStr()">
                    <button class="btn-search" tabindex="3" type="submit" (click)="filterByStr()">
                      <div class="sr-only">Search</div>
                    </button>
                  </div>
                </div>  

                <div class="createEstimatBtn m_0">
                  <div class="dropdown addDropdown">
                    <button class="btn dropdown-toggle" type="button" id="addButton" (click)="createAction()">
                      <img src="assets/images/plus-icon.png"> &nbsp; Add
                    </button>
                  </div>
                </div>
                </div>

              </div>
            </div>

          
            <ng-template #importCustomer>
              <div class="forgot-password-modal">
                <div class="modal-header">
                  <h4 class="modal-title pull-left">Import Customer Detail</h4>
                  <button aria-label="Close" class="close pull-right" type="button" (click)="modalRef.hide();openModel(estimateModal,'winSizeMax')">
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div class="modal-body">
                  
                <form class="" [formGroup]="addAudienceForm">
                  <div class="form-group" >
                    <input type="text"  class="form-control" [(ngModel)]="audForm.searchString" formControlName="searchString" placeholder="Search By Email/Mobile/Name/Audience ID" #audienceMobileNo >
                  </div>

                  <div class="submitBtnBox text-right">
                    <a href="javascript:void(0);" class="manually_link" (click)="modalRef.hide();addAudience()">Fill Details Manually</a>
                    <button type="submit" class="btn btn-dark" (click)="modalRef.hide();getAudienceData()">Submit</button>
                  </div>
                </form>
                </div>
                </div>

              </ng-template>
             
          <ng-template #estimateModal>
            <form #productsDataForm="ngForm" (keydown.enter)="$event.preventDefault()">
            
              <div class="forgot-password-modal">
                <div class="modal-header">
                  <h4 class="modal-title pull-left">
                    Quotation
                  </h4>
                  <div class="d-flex c-align">
                    <button class="btn btn_preview" *ngIf="billNum != '#id'" (click)="quotationToInvoice()">Convert to invoice</button>
                  <button aria-label="Close" class="close pull-right" type="button" (click)="modalRef.hide();resetCustomerDetails(); resetForm(); ">
                    <span aria-hidden="true">×</span>
                  </button>
                  </div>
                </div>
                <div class="modal-body">
                  <div class="estimateNoSum text-right" [class.invalid]="invalid_billNum" *ngIf="viewSrNo">
                    Quotation No. <span *ngIf="settingFormFields.quotationPrefix">{{settingFormFields.quotationPrefix}}</span>{{billNum}}<span *ngIf="settingFormFields.quotationSuffix"> {{settingFormFields.quotationSuffix}}</span>
                     <!-- <input type="text" (ngModelChange)="invoiceNumAdded($event)" onpaste="return false" disabled (keypress)="restrictSpecialChar($event)" class="estimateSerial" [(ngModel)]="billNum" name="billNum"> -->
                    <div class="error_info">Enter <span class="text_lower_case">Quotation </span> no.</div>
                  </div>
                  <div class="cstmrDetailDate">
                    <div class="row">
                    <div class="col-md-6">
                      <button *ngIf="customerDataFetch == null" class="btn btn-grey" (click)="modalRef.hide(); openModel(importCustomer); searchOptOfAud = 'Select';">
                        Add Customer Detail
                      </button>
                      <div class="customerDetailInfo" *ngIf="customerDataFetch">
                        <span class="reSelectAud" title="This will remove the complete customer details, are you sure? " (click)="resetCustomerDetails()">×</span>
                        <div class="two-col-box">
                          <div class="form-group">
                            <div class="icon_input_box" [class.invalid]="invalid_customerName">
                              <span>
                                <img src="assets/images/user-alt-ico.svg">
                              </span>
                              <span class="editIcon" (click)="showAlert('name')">
                                <img src="assets/images/edit.png">
                              </span>
                              <input type="text" class="form-control cstmrName" placeholder="Name" [(ngModel)]="customerName" name="customerName" title="{{customerName}}" disabled>
                              <div class="error_info">Enter customer name</div>
                            </div>
                          </div>
                          <div class="form-group">
                            <div class="icon_input_box" [class.invalid]="invalid_customerGstNum">
                              <span>
                                <img src="assets/images/gst.svg">
                              </span>
                            <input type="text" maxlength="15" onpaste="" class="form-control cstmrAddress" placeholder="GST Number" [(ngModel)]="customerGstNum" name="customerGstNum" title="{{customerGstNum}}" (keypress)="restrictSpecialChar($event)" (input)="gstPressing($event)">
                            <div class="error_info">Enter customer GST No.</div>
                          </div>
                          </div>
                        </div>
                        <div class="two-col-box">
                          <div class="form-group">
                            <div class="icon_input_box" [class.invalid]="invalid_customerMobile">
                              <span>
                                <img src="assets/images/phone-nbg.svg">
                              </span>
                              <span class="editIcon" (click)="showAlert('mobile no.')">
                                <img src="assets/images/edit.png">
                              </span>
                            <input type="text" onpaste="return false" class="form-control cstmrMobile" placeholder="Mobile No." (keypress)="numSymbol($event)" [(ngModel)]="customerMobile" name="customerMobile" title="{{customerMobile}}" maxlength="15" disabled>
                            <div class="error_info">Enter customer mobile no.</div>
                          </div>
                          </div>
                          <div class="form-group">
                            <div class="icon_input_box" [class.invalid]="invalid_customerEmail">
                              <span>
                                <img src="assets/images/envelope-nbg.svg">
                              </span>
                              <span class="editIcon" (click)="showAlert('email')">
                                <img src="assets/images/edit.png">
                              </span>
                            <input type="text" class="form-control cstmrEmail"  placeholder="Email ID" [(ngModel)]="customerEmail" name="customerEmail" title="{{customerEmail}}" maxlength="80" disabled>
                            <div class="error_info">Enter customer email</div>
                          </div>
                          </div>
                        </div>
                        <div class="two-col-box">
                        <div class="form-group">
                          <div class="icon_input_box" [class.invalid]="invalid_customerAddress">
                            <span>
                              <img src="assets/images/map-marker-nbg.svg">
                            </span>
                          <input type="text" class="form-control cstmrAddress" placeholder="Address" [(ngModel)]="customerAddress" name="customerAddress" title="{{customerAddress}}" (input)="checkShippingSame($event)"> 
                          <div class="error_info">Enter customer address</div>
                        </div>
                        <div class="icon_input_box m_t_3">
                          <span>
                            <img src="assets/images/company-name-input.png" class="max_width_12 opacity-7">
                          </span>
                          <input type="text" class="form-control" placeholder="Company name" [(ngModel)]="companyName" name="companyName" title="{{companyName}}">
                        </div>
                        </div>
                        <div class="form-group">
                          <div class="icon_input_box" [class.invalid]="invalid_ShippingAddress">
                            <span>
                              <img src="assets/images/shipping.svg">
                            </span>
                          <textarea class="bg_light_white border_0 form-control cstmrAddress p_l_20" title="{{customerShippingAddress}}" name="customerShippingAddress" [(ngModel)]="customerShippingAddress" placeholder="Shipping Address"></textarea>

                          <div class="shippingAddrCopy" *ngIf="customerAddress">
                            <input type="checkbox" [(ngModel)]="copyAddress" name="copyAddress" value="equal" (change)="copyAddr(copyAddress?'A':'B')"> Shipping address will be same as address 
                          </div>
                          <div class="error_info">Enter shipping address</div>
                        </div>
                        </div>
                      </div>
                        
                      </div>

                    </div>
                    <div class="col-md-6 rightAlignItems">
                      <div class="dateInnerRightAlign">
                      <div class="form-group">
                        <div class="row align_item_center">
                          <label class="col-md-3 text-right p_0 m_0">
                            Date
                          </label>
                          <div class="col-md-9" [class.invalid]="invalid_genDate">
                            <input type="text" [(ngModel)]="genDate" placement="top right" name="genDate" readonly class="form-control date-icon modal-space bgNone" [minDate]="" bsDatepicker autocomplete="off" [bsConfig]="{showWeekNumbers: false,containerClass:'dateLeft theme-green',dateInputFormat: 'DD/MM/YYYY'}"  (ngModelChange)="expDateAdded();">
                            <div class="error_info">Enter quotation date</div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group expDateCntnr">
                        <div class="row align_item_center">
                          <label class="col-md-3 text-right p_0 m_0">
                           <span> Exp Date</span>
                          </label>
                          <div class="col-md-9" [class.invalid]="invalid_expDate">
                            <input type="text" [class.invalid_date]="invalidExpDate" [disabled]="genDate == undefined || genDate == null || genDate == ''" [(ngModel)]="expDate" name="expDate" placement="top left" readonly class="form-control date-icon modal-space bgNone" [minDate]="expMinDate" bsDatepicker autocomplete="off" [bsConfig]="{showWeekNumbers: false,containerClass:'dateLeft theme-green', dateInputFormat: 'DD/MM/YYYY'}" (ngModelChange)="expDateCall();">
                            <div class="error_info">Enter exp date</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
                  </div>

                  <div class="html_header_">
                    <div class="accordion" id="accordionExample2">
                      <div class="card">
                        <div class="card-header" id="templateHeader">
                          <h2 class="mb-0">
                            <span class="templateOfHtmlHeading" data-toggle="collapse" data-target="#collapseheaderhtml" aria-expanded="true" aria-controls="collapseheaderhtml">
                             Template Header
                            </span>
                          </h2>
                        </div>
                    
                        <div id="collapseheaderhtml" class="collapse" aria-labelledby="templateHeader" data-parent="#accordionExample">
                          <div class="card-body">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="form-group">
                                  <label class="p_t_0">Template Header</label>
                                  <app-ckeditor
                                  [config]="config" [headerHtmlData]="headerHtmlData" [htmlSetHeadData]="headerHtml" (getHeaderHtmlVal)="getHeaderHtmlVal($event)"></app-ckeditor>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                  </div>

                  <div class="row">
                    <div class="col-md-3 m_b_5 m_t_17">
                      <div class="form-group">
                        <input type="text" class="form-control" [(ngModel)]="kindText" name="kindText" maxlength="200" [ngClass]="{'invalid-control' : invalidInput && kindText != ''}" (change)="invalidFilterRemove()">
                      </div>
                    </div>
                  </div>

                  <div class="item_table">
                    <div class="table-responsive">
                      <table class="table">
                        <tr>
                          <th class="min_width_70">Sr. No</th>
                          <th>HSN</th>
                          <th>Product/Service</th>
                          <th>Description</th>
                          <th>Image</th>
                          <th class="text-center min_width_100">Tax</th>
                          <th class="text-center">Qty/Unit</th>
                          <th class="min_width_55 text-right">Rate(&#8377;)</th>
                          <th class="min_width_120 text-right">Discount(%)</th>
                          <th class="min_width_55 text-right">Cess(&#8377;)</th>
                          <th class="max_width_80 text-right">Amount(&#8377;)</th>
                          <th class="text-right">Action</th>
                        </tr>
                        <ng-container *ngIf="selectedProductList.length > 0">
                          <tr *ngFor="let list of selectedProductList; let i=index">
                            <td>{{i+1}}</td>
                            <td>{{list.hsn}}</td>
                            <td>{{list.name}}</td>
                            <td>
                              <textarea [(ngModel)]="list.description" class="form-control" [ngModelOptions]="{standalone: true}"></textarea>
                            </td>
                            <td><img *ngIf="list.logo" [src]="list.logo" class="product_logo"></td>
                            <td class="text-center">{{list.taxMaster.value}} % {{list.taxMaster.name}}</td>
                            <td><input type="text" onpaste="return false" onkeypress='return event.charCode >= 48 && event.charCode <= 57' class="form-control max_width_50 text-right f_right" [(ngModel)]="list.qty" name="qty" [ngModelOptions]="{standalone: true}" (ngModelChange)="countUp(list.qty,list.rate,list.discount,list.cess,list.taxMaster.value,i)"></td>
                            <td><input type="text" onpaste="return false" (keypress)="numberOnly($event)" class="form-control max_width_70 text-right f_right" [(ngModel)]="list.rate" name="rate" [ngModelOptions]="{standalone: true}" (ngModelChange)="countUp(list.qty,list.rate,list.discount,list.cess,list.taxMaster.value,i)"> </td>
                            <td><input type="text" (keypress)="numberOnly($event,'discount')" onpaste="return false" oninput="this.value = this.value > 99.99?99.99:this.value" class="form-control max_width_90 f_right text-right" [(ngModel)]="list.discount" name="discount" [ngModelOptions]="{standalone: true}" (ngModelChange)="countUp(list.qty,list.rate,list.discount,list.cess,list.taxMaster.value,i)"></td>
                            <td><input type="text" (keypress)="numberOnly($event)" onpaste="return false" [(ngModel)]="list.cess" name="cess" class="form-control max_width_50 text-right f_right" [ngModelOptions]="{standalone: true}" (ngModelChange)="countUp(list.qty,list.rate,list.discount,list.cess,list.taxMaster.value,i)"></td>
                            <td>
                              <input class="form-control max_width_80 f_right text-right" type="text" [(ngModel)]="list.amount" name="amount" disabled [ngModelOptions]="{standalone: true}">
                              <input type="hidden" [(ngModel)]="list.id" name="id" [ngModelOptions]="{standalone: true}">
                            </td>
                            <td class="text-right">
                              <a href="javascript:void(0)" (click)="removeCnf(list)">
                                <img src="assets/images/form-trash-alt.svg" class="width_12" alt="">
                              </a>
                            </td>
                          </tr>
                        </ng-container>
                        <tr>
                          <td>
                            {{selectedProductList.length + 1}}
                          </td>
                          <td class="text-center" colspan="10">
                            <!-- <span class="redTxt bold cursor_pointer" (click)="addItemInTbl(); showAddTxt = !showAddTxt" >Add an item</span> -->
                            <div class="addtemShowList">
                              <div class="relatedInputCross">
                                <input type="text" class="form-control searchInputProd" placeholder="Search Product/Service" [(ngModel)]="prodSearchStr" name="prodSearchStr" (keyup)="searchProdData($event.target.value)">
                                <span class="texNull" *ngIf="prodSearchStr != null && prodSearchStr!= '' " (click)="emptyProdStr()">
                                  <img src="assets/images/cross.png">
                                </span>
                              </div>
                              <button class="btn btn-add" (click)="addProdToList();modalRef.hide();openModel(addItemsData);">+</button>
                            <ul class="prod_info_list">
                              <li *ngFor="let list of prodListData" (click)="selectProdToInvoice(list);">{{list.name}} - &#8377;{{list.rate}}</li>
                            </ul>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="11"></td>
                        </tr>
                        <tr>
                          <td colspan="11"></td>
                        </tr>
                        <tr>
                          <td colspan="11"></td>
                        </tr>
                        <tr>
                          <td colspan="11"></td>
                        </tr>
                        <tr>
                          <td colspan="11"></td>
                        </tr>
                       
                        <ng-container *ngIf="selectedProductList.length > 0">
                        
                        <tr class="bgGray">
                          <td colspan="11">
                            <div class="outerFlex">
                            <div class="invoiceRightCounter width_100">
                              <div>
                                Subtotal &nbsp; &nbsp;  <div class="rightCounts">{{subTotal | currency : 'INR'}}</div>
                              </div>
                              <div *ngIf="totalCess > 0">
                                Total Cess &nbsp; &nbsp;  <div class="rightCounts">{{totalCess | currency : 'INR'}}</div>
                              </div>
                              
                              <div class="" *ngIf="totalDiscount > 0">
                                Total Discount &nbsp; &nbsp; <div class="rightCounts">- {{totalDiscount | currency : 'INR'}}</div>
                              </div>
                              <div class="" *ngIf="totalTax > 0">
                                  Total Tax &nbsp; &nbsp;<div class="rightCounts">{{totalTax | currency : 'INR'}}</div>
                              </div>
                              <div class="" *ngIf="totalRoundOff > 0">
                                Round Off &nbsp; &nbsp; <div class="rightCounts">{{totalRoundOff | currency : 'INR'}}</div>
                              </div>
                              <div class="">
                                Total &nbsp; &nbsp; <div class="rightCounts">{{totalSum | currency : 'INR'}}</div>
                              </div>
                            </div>
                          </div>

                          </td>
                        </tr>
                        
                        </ng-container>
                      </table>
                    </div>
                    

                    <div class="item_notes">
                      <div class="form-group">
                        <div class="accordion m_t_35" id="accordionExample3">
                          <div class="card">
                            <div class="card-header" id="templateFooter">
                              <h2 class="mb-0">
                                <span class="templateOfHtmlHeading" data-toggle="collapse" data-target="#collapsefooterhtml" aria-expanded="true" aria-controls="collapsefooterhtml">
                                 Template Footer
                                </span>
                              </h2>
                            </div>
                        
                            <div id="collapsefooterhtml" class="collapse" aria-labelledby="templateFooter" data-parent="#accordionExample">
                              <div class="card-body">
                                <div class="row">
                                  <div class="col-md-12">
                                    <div class="form-group">
                                      <app-ckeditor (getFooterHtmlVal)="getFooterHtmlVal($event)"
                                      [config]="config" [htmlSetFooterData]="footerHtml" [footerHtmlData]="footerHtmlData"></app-ckeditor>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                      </div>
                    </div>

                    <div class="bottom_btn_box">
                      <button class="btn btn_preview" (click)="modalRef.hide();openModel(prevDetail,'winSizeMax');">Preview</button>
                      <div class="inner-flex-btn">
                      <button type="submit" (click)="sendData(productsDataForm.value);" class="button_type_box">{{actionName}}</button>
                      <div class="dropdown">
                      <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item" href="javascript:void(0)" (click)="actionSelect('0','Save')">Save</a>
                        <a *ngIf="customerEmail" class="dropdown-item" href="javascript:void(0)" (click)="actionSelect('1','Save & send email');">Save & send email</a>
                        <a *ngIf="customerMobile" class="dropdown-item" href="javascript:void(0)" (click)="actionSelect('2','Save & send sms');">Save & send sms</a>
                        <!-- <a class="dropdown-item" href="javascript:void(0)" (click)="actionSelect('2','Save & share link')">Save & share link</a> -->
                        <a *ngIf="customerMobile" class="dropdown-item" href="javascript:void(0)" (click)="actionSelect('3','Save and share on whatsapp'); ">Save and send on whatsapp</a>
                      </div>
                      </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </form>
            <ng-container *ngIf="itemDtlToRemove">
              <div class="fixedPopupClass">
              <div class="forgot-password-modal">
                <div class="modal-header j-center">
                  <h4 class="modal-title">Are you sure want to remove this item?</h4>
                </div>
                <div class="modal-body">
                  <div class="innerConfrm">
                    <button class="btn btn-dark" (click)="confirmRmItem()">Yes</button>
                    <button class="btn btn-dark red" (click)="cancelRmItem()">No</button>
                  </div>
                </div>
              </div>
            </div>
            </ng-container>
            </ng-template>
          

          <ng-template #prevDetail>

            <div class="modal-header">
              <button aria-label="Close" class="close pull-right" type="button" (click)="modalRef.hide(); useAsModal()">
                <span aria-hidden="true">×</span>
              </button>
            </div>
          <ng-container #templateAsHtml>
            <div class="templateInnerBox templateOne" id="" #templateAsHtmlNew [style.color]="color" *ngIf="templateSelected == 'PACIFIC'">
              
              
              <div class="template_container">
                
                <div class="row c-align">
                  <div class="col-md-6">
                    <div class="companyTitle" *ngIf="settingFormFields.businessName == undefined">Cronberry Pvt. Ltd.</div>
                    <div class="companyTitle" *ngIf="settingFormFields.businessName != undefined">{{settingFormFields.businessName}}</div>
                    <div class="smallFont_additionalDetail">
                      <div class="CompanyAddress" *ngIf="settingFormFields.address == undefined">
                        T2 Tirthraj Apartment, Jacob Road, Civil Lines, Jaipur, Rajasthan 302017
                      </div>
                      <div class="CompanyAddress" *ngIf="settingFormFields.address != undefined">
                        {{settingFormFields.address}}
                      </div>
                      <span *ngIf="settingFormFields.city">{{settingFormFields.city}},</span>
                          <span *ngIf="settingFormFields.state">{{settingFormFields.state}},</span>
                          <span *ngIf="settingFormFields.postalCode">{{settingFormFields.postalCode}}</span>
                      <div class="mobileNo" *ngIf="settingFormFields.phoneNumber == undefined">Mobile no.: +91 9878712123</div>
                      <div class="mobileNo" *ngIf="settingFormFields.phoneNumber != undefined">Mobile no.:{{settingFormFields.phoneNumber}}</div>

                      <div class="emailId" *ngIf="settingFormFields.email == undefined">Email : info@cronberry.com</div>
                      <div class="emailId" *ngIf="settingFormFields.email != undefined">Email : {{settingFormFields.email}}</div>

                      <div class="gstNumber" *ngIf="settingFormFields.gstNumber == undefined">GST NO.:</div>
                      <div class="gstNumber" *ngIf="settingFormFields.gstNumber != undefined">GST NO.: {{settingFormFields.gstNumber}}</div>
                    </div>
                  </div>
                  <div class="col-md-6 text-right">
                    <div [style.height]="settingFormFields.logoHeight" [style.width]="settingFormFields.logoWidth" class="logoOuterBox">
                    <img [src]="settingFormFields.logo?settingFormFields.logo:''" >
                    </div>
                  </div>
                </div>
              </div>

              

            <div class="taxInvoiceDetail">
                  <div class="taxInvoiceTitle" *ngIf="billNum != '' && billNum != '#id'">
                    Quotation No.<span *ngIf="settingFormFields.quotationPrefix">{{settingFormFields.quotationPrefix}}</span>{{billNum}}<span *ngIf="settingFormFields.quotationSuffix">{{settingFormFields.quotationSuffix}}</span>
                  </div>
                  <div class="row m_b_10" *ngIf="kindText">
                    <div class="col-md-12">
                      {{kindText}}
                    </div>
                  </div>
                  <div class="clear"></div>
                <div class="row">
                  <div class="col-md-4">
                    <div class="box_title_invoice">Quote To</div>
                    <div class="smallFont_additionalDetail">
                      <div class="firmName" *ngIf="customerName == null">N/A</div>
                      <div class="firmName" *ngIf="customerName != null">{{customerName}}</div>
                      <div class="firmName" *ngIf="companyName">{{companyName}}</div>
                      <div class="firmAddress" *ngIf="customerAddress == null">N/A</div>
                      <div class="firmAddress" *ngIf="customerAddress != null">{{customerAddress}}</div>

                      <div class="firmGstNum" *ngIf="customerGstNum == null">GST NO.: N/A</div>
                      <div class="firmGstNum" *ngIf="customerGstNum != null">GST NO.: {{customerGstNum}}</div>
                      <div class="firmGstNum" *ngIf="customerMobile != null">Phone No.: {{customerMobile}}</div>
                      <div class="firmGstNum" *ngIf="customerEmail != null">Email: {{customerEmail}}</div>
                    </div>
                  </div>

                  <div class="col-md-4" *ngIf="customerShippingAddress != null && customerShippingAddress != ''">
                    <div class="box_title_invoice">Ship To</div>
                    <div class="smallFont_additionalDetail">
                      <div class="firmAddress" *ngIf="customerShippingAddress == null">N/A</div>
                      <div class="firmGstNum" *ngIf="customerShippingAddress != null">{{customerShippingAddress}}</div>
                      <div class="firmGstNum" *ngIf="customerMobile != null">Phone No.: {{customerMobile}}</div>
                      <div class="firmGstNum" *ngIf="customerEmail != null">Email: {{customerEmail}}</div>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="box_title_invoice">&nbsp;</div>
                    <div class="smallFont_additionalDetail">
                      
                      
                      <div class="">Date :
                        <span class="semiBoldFont" *ngIf="genDate != undefined">{{genDate | date:'dd/MM/yyyy'}}</span>
                        <span class="semiBoldFont" *ngIf="genDate == undefined">N/A</span>
                      </div>
                      <div class="">Exp. Date :
                        <span class="semiBoldFont" *ngIf="expDate == undefined">N/A</span>
                        <span class="semiBoldFont" *ngIf="expDate != undefined">{{expDate | date:'dd/MM/yyyy'}}</span>
                      </div>
                      
                    </div>
                  </div>

                </div>

                <div class="row" *ngIf="headerHtml">
                  <div class="col-md-12">
                    <div class="htmlContainer p_l_0" [innerHTML]="headerHtml | safeHtml" [style.color]="color">
  
                    </div>
                  </div>
                </div>
                <div class="clear"></div>
                <div class="table_format">
                  <div class="table-responsive">
                    <table class="table">
                      <tr id="tableHead">
                        <th [style.color]="color" [style.background]="bGcolor">Sr No.</th>
                        <th [style.color]="color" [style.background]="bGcolor" *ngIf="hsnColHide">HSN/SAC</th>
                        <th [style.color]="color" [style.background]="bGcolor">Product/Service</th>
                        <th [style.color]="color" [style.background]="bGcolor">Description</th>
                        <th [style.color]="color" [style.background]="bGcolor" *ngIf="imageColShow">Image</th>
                        <th [style.color]="color" [style.background]="bGcolor" class="min_width_65">Tax</th>
                        <th [style.color]="color" [style.background]="bGcolor">Qty/Unit</th>
                        <th [style.color]="color" [style.background]="bGcolor" *ngIf="discountVisible == true">Discount(%)</th>
                        <th [style.color]="color" [style.background]="bGcolor" class="text-right">Rate</th>
                        <th [style.color]="color" [style.background]="bGcolor" class="text-right" *ngIf="cessVisible == true">Cess</th>
                        <th [style.color]="color" [style.background]="bGcolor" class="text-right">Amount</th>
                      </tr>
                      <ng-container *ngIf="selectedProductList">
                     
                      <tr *ngFor="let list of selectedProductList; let i=index">
                        <td [style.color]="color">{{i+1}}</td>
                        <td [style.color]="color" *ngIf="hsnColHide">{{list.hsn}}</td>
                        <td [style.color]="color">{{list.name}}</td>
                        <td [style.color]="color">{{list.description}}</td>
                        <td [style.color]="color" *ngIf="imageColShow"><img *ngIf="list.logo" [src]="list.logo" class="prev_prdct_logo"></td>
                        <td [style.color]="color">{{list.taxMaster.value}}% {{list.taxMaster.name}}</td>
                        <td [style.color]="color">{{list.qty}}</td>
                        <td [style.color]="color" *ngIf="discountVisible == true">{{list.discount}}</td>
                        <td [style.color]="color" class="text-right">&#8377;{{list.rate}}</td>
                        <td [style.color]="color" class="text-right" *ngIf="cessVisible == true">&#8377;{{list.cess}}</td>
                        <td [style.color]="color" class="text-right">&#8377;{{list.amount}}</td>
                      </tr>
                  

                      <tr class="countFormatRow">
                        <td [attr.colspan]="tableColspan">
                          <div class="left_bank_dtl_col semiBoldFont" [style.color]="color">
                            <ng-container *ngIf="partialPaymentData != null">
                            <ng-container *ngIf="partialPaymentData.length > 0">
                            <div class="m_b_20">
                            <div class="viewTitleTerms">
                              <strong>Payment Terms:</strong>
                            </div>
                            <div class="dtlGroup" *ngFor="let list of partialPaymentData">
                              <div class="min_width_40">{{list.partialNum}}% </div>
                              <div class="p_0_10">{{list.partialDescription}}</div>
                              <div class=""> {{list.partialAmount | currency : 'INR'}}</div>
                            </div>
                            </div>
                            </ng-container>
                            </ng-container>
                            <ng-container *ngIf="settingFormFields.showBankDetails == true">
                              <div class="viewTitleTerms">
                                <strong>Account Details:</strong>
                              </div>
                            <div class="dtlGroup">
                              <div class="colHeading">Account Holder's Name</div>
                              <div class="colDetail">{{settingFormFields.accountHolderName}}</div>
                            </div>
                            <div class="dtlGroup">
                              <div class="colHeading">Account Number</div>
                              <div class="colDetail">{{settingFormFields.accountNumber}}</div>
                            </div>
                            <div class="dtlGroup">
                              <div class="colHeading">Bank Name</div>
                              <div class="colDetail">{{settingFormFields.bankName}}</div>
                            </div>
                            <div class="dtlGroup">
                              <div class="colHeading">Branch Name</div>
                              <div class="colDetail">{{settingFormFields.branch}}</div>
                            </div>
                            <div class="dtlGroup">
                              <div class="colHeading">IFSC Code</div>
                              <div class="colDetail">{{settingFormFields.ifscCode}}</div>
                            </div>
                            <div class="dtlGroup">
                              <div class="colHeading">Company PAN</div>
                              <div class="colDetail">{{settingFormFields.companyPan}}</div>
                            </div>
                            </ng-container>
                          </div>

                          <div class="right_count_details semiBoldFont" [style.color]="color">
                            <div class="dtlGroup">
                              <div class="colHeading">Subtotal</div>
                              <div class="colDetail">{{subTotal | currency : 'INR'}}</div>
                            </div>

                            <div class="dtlGroup" *ngIf="totalCess > 0">
                              <div class="colHeading">Total Cess</div>
                              <div class="colDetail">{{totalCess | currency : 'INR'}}</div>
                            </div>
                            

                            <div class="dtlGroup" *ngIf="totalDiscount > 0">
                              <div class="colHeading">Total Discount</div>
                              <div class="colDetail">- {{totalDiscount | currency : 'INR'}}</div>
                            </div>

                            <div class="dtlGroup" *ngIf="totalTax > 0">
                                <div class="colHeading">Total tax</div>
                                <div class="colDetail">{{totalTax | currency : 'INR'}}</div>                              
                            </div>


                            <div class="dtlGroup" *ngIf="totalRoundOff > 0">
                              <div class="colHeading">Round off</div>
                              <div class="colDetail">{{totalRoundOff | currency : 'INR'}}</div>
                            </div>

                            <div class="dtlGroup">
                              <div class="colHeading">Total</div>
                              <div class="colDetail">{{totalSum | currency : 'INR'}}</div>
                            </div>
                          </div>

                        </td>
                      </tr>
                     
                    </ng-container>
                    </table>
                  </div>
                </div>
                <div class="row" *ngIf="footerHtml">
                  <div class="col-md-12">
                    <div class="htmlContainer" [innerHTML]="footerHtml | safeHtml" [style.color]="color">
  
                    </div>
                  </div>
                </div>
                <div class="row" *ngIf="settingFormFields">
                  <ng-container *ngIf="settingFormFields.signature">
                  <div class="col-md-12 text-right">
                    <div class="text-center d-inline-block margin_20_10">
                    <img [src]="settingFormFields.signature" [style.width]="settingFormFields.signatureWidth" [style.height]="settingFormFields.signatureHeight">
                    <div>Authorized Signatory</div>
                  </div>
                  </div>
                  </ng-container>
                </div>
              </div>
              
              
            </div>
          </ng-container>

          <ng-container #templateAsHtml>
            <div class="templateInnerBox templateTwo" id="" [style.color]="color"  *ngIf="templateSelected == 'ATLANTIC'">
              
              
              <div class="taxInvoiceTitle" *ngIf="billNum != '' && billNum != '#id'">                  
                    Quotation No. : <span *ngIf="settingFormFields.quotationPrefix">{{settingFormFields.quotationPrefix}}</span>{{billNum}}<span *ngIf="settingFormFields.quotationSuffix">{{settingFormFields.quotationSuffix}}</span>
              </div>
              <div class="template_container">
                <div class="row c-align">
                  <div class="col-md-6">
                    <div class="companyTitle" *ngIf="settingFormFields.businessName == undefined">Cronberry Pvt. Ltd.</div>
                    <div class="companyTitle" *ngIf="settingFormFields.businessName != undefined">{{settingFormFields.businessName}}</div>
                    <div class="smallFont_additionalDetail">
                      <div class="CompanyAddress" *ngIf="settingFormFields.address == undefined">
                        T2 Tirthraj Apartment, Jacob Road, Civil Lines, Jaipur, Rajasthan 302017
                      </div>
                      <div class="CompanyAddress" *ngIf="settingFormFields.address != undefined">
                        {{settingFormFields.address}}
                      </div>
                      <span *ngIf="settingFormFields.city">{{settingFormFields.city}},</span>
                          <span *ngIf="settingFormFields.state">{{settingFormFields.state}},</span>
                          <span *ngIf="settingFormFields.postalCode">{{settingFormFields.postalCode}}</span>
                      <div class="mobileNo" *ngIf="settingFormFields.phoneNumber == undefined">Mobile no.: +91 9878712123</div>
                      <div class="mobileNo" *ngIf="settingFormFields.phoneNumber != undefined">Mobile no.:{{settingFormFields.phoneNumber}}</div>

                      <div class="emailId" *ngIf="settingFormFields.email == undefined">Email : info@cronberry.com</div>
                      <div class="emailId" *ngIf="settingFormFields.email != undefined">Email : {{settingFormFields.email}}</div>

                      <div class="gstNumber" *ngIf="settingFormFields.gstNumber == undefined">GST NO.:</div>
                      <div class="gstNumber" *ngIf="settingFormFields.gstNumber != undefined">GST NO.: {{settingFormFields.gstNumber}}</div>
                    </div>
                  </div>
                  <div class="col-md-6 text-right">
                    <div [style.height]="settingFormFields.logoHeight" [style.width]="settingFormFields.logoWidth" class="logoOuterBox">
                    <img [src]="settingFormFields.logo?settingFormFields.logo:''">
                    </div>
                  </div>
                </div>
              </div>

              
              <div class="p_l_10">
              <div class="row m_b_10" *ngIf="kindText">
                <div class="col-md-12">
                  {{kindText}}
                </div>
              </div>
              </div>

            <div class="taxInvoiceDetail">
                  
              <div class="billToShipTo">
                <div class="one_half_detail">
                  <div class="box_title_cstmr_detail" [style.background]="bGcolor">
                    Quote To
                  </div>
                  <div class="cstmrDetailInner">
                    <div class="firmName" *ngIf="customerName == null">N/A</div>
                      <div class="firmName" *ngIf="customerName != null">{{customerName}}</div>
                      <div class="firmName" *ngIf="companyName">{{companyName}}</div>
                      <div class="firmAddress" *ngIf="customerAddress == null">N/A</div>
                      <div class="firmAddress" *ngIf="customerAddress != null">{{customerAddress}}</div>

                      <div class="firmGstNum" *ngIf="customerGstNum == null">GST NO.: N/A</div>
                      <div class="firmGstNum" *ngIf="customerGstNum != null">GST NO.: {{customerGstNum}}</div>
                      <div class="firmGstNum" *ngIf="customerMobile != null">Phone No.: {{customerMobile}}</div>
                      <div class="firmGstNum" *ngIf="customerEmail != null">Email: {{customerEmail}}</div>
                  </div>
                  </div>
                
                  <div class="one_half_detail" *ngIf="customerShippingAddress != null && customerShippingAddress != ''">
                    <div class="box_title_cstmr_detail" [style.background]="bGcolor">
                      Ship To
                    </div>
                    <div class="cstmrDetailInner">
                      <div class="firmAddress" *ngIf="customerShippingAddress == null">N/A</div>
                      <div class="firmGstNum" *ngIf="customerShippingAddress != null">{{customerShippingAddress}}</div>
                      <div class="firmGstNum" *ngIf="customerMobile != null">Phone No.: {{customerMobile}}</div>
                      <div class="firmGstNum" *ngIf="customerEmail != null">Email: {{customerEmail}}</div>
                    </div>
                  </div>
                </div>

              <div class="invoiceDetailsDue">
                <div class="table-responsive">
                  <table class="table">
                    <tr>
                      
                      <th [style.color]="color" [style.background]="bGcolor">Date</th>
                      <th [style.color]="color" [style.background]="bGcolor">Total Due</th>
                      <th [style.color]="color" [style.background]="bGcolor">Exp. Date</th>
                    </tr>
                    
                    <tr>
                      
                      <td [style.color]="color">{{genDate | date:'dd/MM/yyyy'}}</td>
                      <td [style.color]="color">{{totalSum | currency : 'INR'}}</td>
                      <td [style.color]="color">{{expDate | date:'dd/MM/yyyy'}}</td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="clear"></div>
              <div class="row" *ngIf="headerHtml">
                <div class="col-md-12">
                  <div class="htmlContainer" [innerHTML]="headerHtml | safeHtml" [style.color]="color">

                  </div>
                </div>
              </div>
              <div class="clear"></div>
                <div class="table_format">
                  <div class="table-responsive">
                    <table class="table">
                      <tr id="tableHead">
                        <th [style.color]="color" [style.background]="bGcolor">Sr No.</th>
                        <th [style.color]="color" [style.background]="bGcolor" *ngIf="hsnColHide">HSN/SAC</th>
                        <th [style.color]="color" [style.background]="bGcolor">Product/Service</th>
                        <th [style.color]="color" [style.background]="bGcolor">Description</th>
                        <th [style.color]="color" [style.background]="bGcolor" *ngIf="imageColShow">Image</th>
                        <th [style.color]="color" [style.background]="bGcolor" class="min_width_65">Tax</th>
                        <th [style.color]="color" [style.background]="bGcolor">Qty/Unit</th>
                        <th [style.color]="color" [style.background]="bGcolor" *ngIf="discountVisible == true">Discount(%)</th>
                        <th [style.color]="color" [style.background]="bGcolor" class="text-right">Rate</th>
                        <th [style.color]="color" [style.background]="bGcolor" class="text-right" *ngIf="cessVisible == true">Cess</th>
                        <th [style.color]="color" [style.background]="bGcolor" class="text-right">Amount</th>
                      </tr>
                      <ng-container *ngIf="selectedProductList">
                     
                      <tr *ngFor="let list of selectedProductList; let i=index">
                        <td [style.color]="color">{{i+1}}</td>
                        <td [style.color]="color" *ngIf="hsnColHide">{{list.hsn}}</td>
                        <td [style.color]="color">{{list.name}}</td>
                        <td [style.color]="color">{{list.description}}</td>
                        <td [style.color]="color" *ngIf="imageColShow"><img *ngIf="list.logo" [src]="list.logo" class="prev_prdct_logo"></td>
                        <td [style.color]="color">{{list.taxMaster.value}}% {{list.taxMaster.name}}</td>
                        <td [style.color]="color">{{list.qty}}</td>
                        <td [style.color]="color" *ngIf="discountVisible == true">{{list.discount}}</td>
                        <td [style.color]="color" class="text-right">&#8377;{{list.rate}}</td>
                        <td [style.color]="color" class="text-right" *ngIf="cessVisible == true">&#8377;{{list.cess}}</td>
                        <td [style.color]="color" class="text-right">&#8377;{{list.amount}}</td>
                      </tr>
                    
                      
                      <tr class="countFormatRow">
                        <td [attr.colspan]="tableColspan">
                          <div class="left_bank_dtl_col semiBoldFont" [style.color]="color">
                            <ng-container *ngIf="partialPaymentData != null">
                              <ng-container *ngIf="partialPaymentData.length > 0">
                                <div class="m_b_20">
                            <div class="viewTitleTerms">
                              <strong>Payment Terms:</strong>
                            </div>
                            <div class="dtlGroup" *ngFor="let list of partialPaymentData">
                              <div class="min_width_40">{{list.partialNum}}% </div>
                              <div class="p_0_10">{{list.partialDescription}}</div>
                              <div class=""> {{list.partialAmount | currency : 'INR'}}</div>
                            </div>
                            </div>
                            </ng-container>
                            </ng-container>
                            <ng-container *ngIf="settingFormFields.showBankDetails == true"> 
                              <div class="viewTitleTerms m_t_20">
                                <strong>Account Details:</strong>
                              </div>
                          
                            <div class="dtlGroup">
                              <div class="colHeading">Account Holder's Name</div>
                              <div class="colDetail">{{settingFormFields.accountHolderName}}</div>
                            </div>
                            <div class="dtlGroup">
                              <div class="colHeading">Account Number</div>
                              <div class="colDetail">{{settingFormFields.accountNumber}}</div>
                            </div>
                            <div class="dtlGroup">
                              <div class="colHeading">Bank Name</div>
                              <div class="colDetail">{{settingFormFields.bankName}}</div>
                            </div>
                            <div class="dtlGroup">
                              <div class="colHeading">Branch Name</div>
                              <div class="colDetail">{{settingFormFields.branch}}</div>
                            </div>
                            <div class="dtlGroup">
                              <div class="colHeading">IFSC Code</div>
                              <div class="colDetail">{{settingFormFields.ifscCode}}</div>
                            </div>
                            <div class="dtlGroup">
                              <div class="colHeading">Company PAN</div>
                              <div class="colDetail">{{settingFormFields.companyPan}}</div>
                            </div>
                          
                            </ng-container>
                          </div>

                          <div class="right_count_details semiBoldFont" [style.color]="color">
                            <div class="dtlGroup">
                              <div class="colHeading">Subtotal</div>
                              <div class="colDetail">{{subTotal | currency : 'INR'}}</div>
                            </div>

                            <div class="dtlGroup" *ngIf="totalCess > 0">
                              <div class="colHeading">Total Cess</div>
                              <div class="colDetail">{{totalCess | currency : 'INR'}}</div>
                            </div>

                            <div class="dtlGroup" *ngIf="totalDiscount > 0">
                              <div class="colHeading">Total Discount</div>
                              <div class="colDetail">- {{totalDiscount | currency : 'INR'}}</div>
                            </div>

                            <div class="dtlGroup" *ngIf="totalTax > 0">
                                <div class="colHeading">Total tax</div>
                                <div class="colDetail">{{totalTax | currency : 'INR'}}</div>                              
                            </div>

                            <div class="dtlGroup" *ngIf="totalRoundOff > 0">
                              <div class="colHeading">Round off</div>
                              <div class="colDetail">{{totalRoundOff | currency : 'INR'}}</div>
                            </div>
                          </div>

                        </td>
                      </tr>
                     
                    </ng-container>
                    </table>
                  </div>
                </div>
                
                <div class="row" *ngIf="footerHtml">
                  <div class="col-md-12">
                    <div class="htmlContainer" [innerHTML]="footerHtml | safeHtml" [style.color]="color">
  
                    </div>
                  </div>
                </div>

                <div class="row" *ngIf="settingFormFields">
                  <ng-container *ngIf="settingFormFields.signature">
                  <div class="col-md-12 text-right">
                    <div class="text-center d-inline-block margin_20_10">
                    <img [src]="settingFormFields.signature" [style.width]="settingFormFields.signatureWidth" [style.height]="settingFormFields.signatureHeight">
                    <div>Authorized Signatory</div>
                  </div>
                  </div>
                  </ng-container>
                </div>

              </div>

            </div>
          </ng-container>

          <ng-container #templateAsHtml>
            <div class="templateInnerBox templateThree" id="" *ngIf="templateSelected == 'INDIAN'" [style.color]="color">
              
              

              <div class="template_container">
                <div class="row c-align">
                  <div class="col-md-6">
                    <div class="companyTitle" *ngIf="settingFormFields.businessName == undefined">Cronberry Pvt. Ltd.</div>
                    <div class="companyTitle" *ngIf="settingFormFields.businessName != undefined">{{settingFormFields.businessName}}</div>
                    <div class="smallFont_additionalDetail">
                      <div class="CompanyAddress" *ngIf="settingFormFields.address == undefined">
                        T2 Tirthraj Apartment, Jacob Road, Civil Lines, Jaipur, Rajasthan 302017
                      </div>
                      <div class="CompanyAddress" *ngIf="settingFormFields.address != undefined">
                        {{settingFormFields.address}}
                      </div>
                      <span *ngIf="settingFormFields.city">{{settingFormFields.city}},</span>
                          <span *ngIf="settingFormFields.state">{{settingFormFields.state}},</span>
                          <span *ngIf="settingFormFields.postalCode">{{settingFormFields.postalCode}}</span>
                      <div class="mobileNo" *ngIf="settingFormFields.phoneNumber == undefined">Mobile no.: +91 9878712123</div>
                      <div class="mobileNo" *ngIf="settingFormFields.phoneNumber != undefined">Mobile no.:{{settingFormFields.phoneNumber}}</div>

                      <div class="emailId" *ngIf="settingFormFields.email == undefined">Email : info@cronberry.com</div>
                      <div class="emailId" *ngIf="settingFormFields.email != undefined">Email : {{settingFormFields.email}}</div>

                      <div class="gstNumber" *ngIf="settingFormFields.gstNumber == undefined">GST NO.:</div>
                      <div class="gstNumber" *ngIf="settingFormFields.gstNumber != undefined">GST NO.: {{settingFormFields.gstNumber}}</div>
                    </div>
                  </div>
                  <div class="col-md-6 text-right">
                    <div [style.height]="settingFormFields.logoHeight" [style.width]="settingFormFields.logoWidth" class="logoOuterBox">
                    <img [src]="settingFormFields.logo?settingFormFields.logo:''">
                    </div>
                  </div>
                </div>
              </div>
              

            <div class="taxInvoiceDetail">
                  <div class="taxInvoiceTitle" *ngIf="billNum != '' && billNum != '#id'">
                        Quotation No. : <span *ngIf="settingFormFields.quotationPrefix">{{settingFormFields.quotationPrefix}}</span>{{billNum}}<span *ngIf="settingFormFields.quotationSuffix">{{settingFormFields.quotationSuffix}}</span>
                  </div>

                  <div class="row m_b_10" *ngIf="kindText">
                    <div class="col-md-12">
                      {{kindText}}
                    </div>
                  </div>

                <div class="row">
                  <div class="col-md-4">
                    <div class="box_title_invoice">Quote To</div>
                    <div class="smallFont_additionalDetail">
                      <div class="firmName" *ngIf="customerName == null">N/A</div>
                      <div class="firmName" *ngIf="customerName != null">{{customerName}}</div>
                      <div class="firmName" *ngIf="companyName">{{companyName}}</div>
                      <div class="firmAddress" *ngIf="customerAddress == null">N/A</div>
                      <div class="firmAddress" *ngIf="customerAddress != null">{{customerAddress}}</div>

                      <div class="firmGstNum" *ngIf="customerGstNum == null">GST NO.: N/A</div>
                      <div class="firmGstNum" *ngIf="customerGstNum != null">GST NO.: {{customerGstNum}}</div>
                      <div class="firmGstNum" *ngIf="customerMobile != null">Phone No.: {{customerMobile}}</div>
                      <div class="firmGstNum" *ngIf="customerEmail != null">Email: {{customerEmail}}</div>
                    </div>
                  </div>

                  <div class="col-md-4" *ngIf="customerShippingAddress != null && customerShippingAddress != ''">
                    <div class="box_title_invoice">Ship To</div>
                    <div class="smallFont_additionalDetail">
                      <div class="firmAddress" *ngIf="customerShippingAddress == null">N/A</div>
                      <div class="firmGstNum" *ngIf="customerShippingAddress != null">{{customerShippingAddress}}</div>
                      <div class="firmGstNum" *ngIf="customerMobile != null">Phone No.: {{customerMobile}}</div>
                      <div class="firmGstNum" *ngIf="customerEmail != null">Email: {{customerEmail}}</div>
                    </div>
                  </div>

                  <div class="col-md-4 invoiceDateDetail">
                    <div class="box_title_invoice">&nbsp;</div>
                    <div class="smallFont_additionalDetail" [style.background]="bGcolor">
                      <table>
                       

                        <tr>
                          <td>
                            Exp. Date :
                          </td>
                          <td>
                            <span class="semiBoldFont"> {{expDate | date:'dd/MM/yyyy'}}</span>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            Date :
                          </td>
                          <td>
                            <span class="semiBoldFont"> {{genDate | date:'dd/MM/yyyy'}}</span>
                          </td>
                        </tr>
                        
                          
                      </table>
                    </div>
                  </div>

                </div>

                <div class="row" *ngIf="headerHtml">
                  <div class="col-md-12">
                    <div class="htmlContainer p_l_0" [innerHTML]="headerHtml | safeHtml" [style.color]="color">
  
                    </div>
                  </div>
                </div>
                <div class="clear"></div>
                <div class="table_format">
                  <div class="table-responsive">
                    <table class="table">
                      <tr id="tableHead">
                        <th [style.color]="color" [style.background]="bGcolor">Sr No.</th>
                        <th [style.color]="color" [style.background]="bGcolor" *ngIf="hsnColHide">HSN/SAC</th>
                        <th [style.color]="color" [style.background]="bGcolor">Product/Service</th>
                        <th [style.color]="color" [style.background]="bGcolor">Description</th>
                        <th [style.color]="color" [style.background]="bGcolor" *ngIf="imageColShow">Image</th>
                        <th [style.color]="color" [style.background]="bGcolor" class="min_width_65">Tax</th>
                        <th [style.color]="color" [style.background]="bGcolor">Qty/Unit</th>
                        <th [style.color]="color" [style.background]="bGcolor" *ngIf="discountVisible == true">Discount(%)</th>
                        <th [style.color]="color" [style.background]="bGcolor" class="text-right">Rate</th>
                        <th [style.color]="color" [style.background]="bGcolor" class="text-right" *ngIf="cessVisible == true">Cess</th>
                        <th [style.color]="color" [style.background]="bGcolor" class="text-right">Amount</th>
                      </tr>
                      <ng-container *ngIf="selectedProductList">
                     
                      <tr *ngFor="let list of selectedProductList; let i=index">
                        <td [style.color]="color">{{i+1}}</td>
                        <td [style.color]="color" *ngIf="hsnColHide">{{list.hsn}}</td>
                        <td [style.color]="color">{{list.name}}</td>
                        <td [style.color]="color">{{list.description}}</td>
                        <td [style.color]="color" *ngIf="imageColShow"><img *ngIf="list.logo" [src]="list.logo" class="prev_prdct_logo"></td>
                        <td [style.color]="color">{{list.taxMaster.value}}% {{list.taxMaster.name}}</td>
                        <td [style.color]="color">{{list.qty}}</td>
                        <td [style.color]="color" *ngIf="discountVisible == true">{{list.discount}}</td>
                        <td [style.color]="color" class="text-right">&#8377;{{list.rate}}</td>
                        <td [style.color]="color" class="text-right" *ngIf="cessVisible == true">&#8377;{{list.cess}}</td>
                        <td [style.color]="color" class="text-right">&#8377;{{list.amount}}</td>
                      </tr>
                    
                      
                      <tr class="countFormatRow">
                        <td [attr.colspan]="tableColspan">
                          <div class="left_bank_dtl_col semiBoldFont" [style.color]="color">
                            <ng-container *ngIf="partialPaymentData != null">
                              <ng-container *ngIf="partialPaymentData.length > 0">
                                <div class="m_b_20">
                                <div class="viewTitleTerms">
                                  <strong>Payment Terms:</strong>
                                </div>
                                <div class="dtlGroup" *ngFor="let list of partialPaymentData">
                                  <div class="min_width_40">{{list.partialNum}}% </div>
                                  <div class="p_0_10">{{list.partialDescription}}</div>
                                  <div class="">{{list.partialAmount | currency : 'INR'}}</div>
                                </div>
                              </div>
                            </ng-container>
                            </ng-container>
                            <ng-container *ngIf="settingFormFields.showBankDetails == true">
                              <div class="viewTitleTerms m_t_20">
                                <strong>Account Details:</strong>
                              </div>
                          
                            <div class="dtlGroup">
                              <div class="colHeading">Account Holder's Name</div>
                              <div class="colDetail">{{settingFormFields.accountHolderName}}</div>
                            </div>
                            <div class="dtlGroup">
                              <div class="colHeading">Account Number</div>
                              <div class="colDetail">{{settingFormFields.accountNumber}}</div>
                            </div>
                            <div class="dtlGroup">
                              <div class="colHeading">Bank Name</div>
                              <div class="colDetail">{{settingFormFields.bankName}}</div>
                            </div>
                            <div class="dtlGroup">
                              <div class="colHeading">Branch Name</div>
                              <div class="colDetail">{{settingFormFields.branch}}</div>
                            </div>
                            <div class="dtlGroup">
                              <div class="colHeading">IFSC Code</div>
                              <div class="colDetail">{{settingFormFields.ifscCode}}</div>
                            </div>
                            <div class="dtlGroup">
                              <div class="colHeading">Company PAN</div>
                              <div class="colDetail">{{settingFormFields.companyPan}}</div>
                            </div>
                          
                            </ng-container>
                           </div>

                          <div class="right_count_details semiBoldFont" [style.color]="color">
                            <div class="dtlGroup">
                              <div class="colHeading">Subtotal</div>
                              <div class="colDetail">{{subTotal | currency : 'INR'}}</div>
                            </div>

                            <div class="dtlGroup" *ngIf="totalCess > 0">
                              <div class="colHeading">Total Cess</div>
                              <div class="colDetail">{{totalCess | currency : 'INR'}}</div>
                            </div>

                            <div class="dtlGroup" *ngIf="totalDiscount > 0">
                              <div class="colHeading">Total Discount</div>
                              <div class="colDetail">- {{totalDiscount | currency : 'INR'}}</div>
                            </div>

                            <div class="dtlGroup" *ngIf="totalTax > 0">
                                <div class="colHeading">Total tax</div>
                                <div class="colDetail">{{totalTax | currency : 'INR'}}</div>
                            </div>

                            <div class="dtlGroup" *ngIf="totalRoundOff > 0">
                              <div class="colHeading">Round off</div>
                              <div class="colDetail">{{totalRoundOff | currency : 'INR'}}</div>
                            </div>

                            <div class="dtlGroup">
                              <div class="colHeading">Total</div>
                              <div class="colDetail">{{totalSum | currency : 'INR'}}</div>
                            </div>
                          </div>

                        </td>
                      </tr>
                     
                      
                    </ng-container>
                    </table>
                  </div>
                </div>

                <div class="row" *ngIf="footerHtml">
                  <div class="col-md-12">
                    <div class="htmlContainer" [innerHTML]="footerHtml | safeHtml" [style.color]="color">
  
                    </div>
                  </div>
                </div>

                <div class="row" *ngIf="settingFormFields">
                  <ng-container *ngIf="settingFormFields.signature">
                  <div class="col-md-12 text-right">
                    <div class="text-center d-inline-block margin_20_10">
                    <img [src]="settingFormFields.signature" [style.width]="settingFormFields.signatureWidth" [style.height]="settingFormFields.signatureHeight">
                    <div>Authorized Signatory</div>
                  </div>
                  </div>
                  </ng-container>
                </div>

              </div>

              
            </div>
          </ng-container>

          <ng-container #templateAsHtml>
            <div class="templateInnerBox templateFour" id="" *ngIf="templateSelected == 'ARCTIC'" [style.color]="color">
              
              <div class="template_container">
                <div class="taxInvoiceTitle text-center" *ngIf="billNum != '' && billNum != '#id'">
                      Quotation No. : <span *ngIf="settingFormFields.quotationPrefix">{{settingFormFields.quotationPrefix}}</span>{{billNum}}<span *ngIf="settingFormFields.quotationSuffix">{{settingFormFields.quotationSuffix}}</span>
                </div>
                <div class="row c-align">
                  <div class="col-md-6">
                    <div class="companyTitle" *ngIf="settingFormFields.businessName == undefined">Cronberry Pvt. Ltd.</div>
                    <div class="companyTitle" *ngIf="settingFormFields.businessName != undefined">{{settingFormFields.businessName}}</div>
                    <div class="smallFont_additionalDetail">
                      <div class="CompanyAddress" *ngIf="settingFormFields.address == undefined">
                        T2 Tirthraj Apartment, Jacob Road, Civil Lines, Jaipur, Rajasthan 302017
                      </div>
                      <div class="CompanyAddress" *ngIf="settingFormFields.address != undefined">
                        {{settingFormFields.address}}
                      </div>
                      <span *ngIf="settingFormFields.city">{{settingFormFields.city}},</span>
                          <span *ngIf="settingFormFields.state">{{settingFormFields.state}},</span>
                          <span *ngIf="settingFormFields.postalCode">{{settingFormFields.postalCode}}</span>
                      <div class="mobileNo" *ngIf="settingFormFields.phoneNumber == undefined">Mobile no.: +91 9878712123</div>
                      <div class="mobileNo" *ngIf="settingFormFields.phoneNumber != undefined">Mobile no.:{{settingFormFields.phoneNumber}}</div>

                      <div class="emailId" *ngIf="settingFormFields.email == undefined">Email : info@cronberry.com</div>
                      <div class="emailId" *ngIf="settingFormFields.email != undefined">Email : {{settingFormFields.email}}</div>

                      <div class="gstNumber" *ngIf="settingFormFields.gstNumber == undefined">GST NO.:</div>
                      <div class="gstNumber" *ngIf="settingFormFields.gstNumber != undefined">GST NO.: {{settingFormFields.gstNumber}}</div>
                    </div>
                  </div>
                  <div class="col-md-6 text-right">
                    <div [style.height]="settingFormFields.logoHeight" [style.width]="settingFormFields.logoWidth" class="logoOuterBox">
                    <img [src]="settingFormFields.logo?settingFormFields.logo:''">
                    </div>
                  </div>
                </div>
              </div>

              
              <div class="p_l_10">
              <div class="row m_b_10" *ngIf="kindText">
                <div class="col-md-12">
                  {{kindText}}
                </div>
              </div>
              </div>


              <div class="taxInvoiceDetail">
                <div class="row">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="box_title_invoice">Quote To</div>
                        <div class="smallFont_additionalDetail">
                            <div class="firmName" *ngIf="customerName == null">N/A</div>
                            <div class="firmName" *ngIf="customerName != null">{{customerName}}</div>
                            <div class="firmName" *ngIf="companyName">{{companyName}}</div>
                            <div class="firmAddress" *ngIf="customerAddress == null">N/A</div>
                            <div class="firmAddress" *ngIf="customerAddress != null">{{customerAddress}}</div>

                            <div class="firmGstNum" *ngIf="customerGstNum == null">GST NO.: N/A</div>
                            <div class="firmGstNum" *ngIf="customerGstNum != null">GST NO.: {{customerGstNum}}</div>
                            <div class="firmGstNum" *ngIf="customerMobile != null">Phone No.: {{customerMobile}}</div>
                      <div class="firmGstNum" *ngIf="customerEmail != null">Email: {{customerEmail}}</div>
                        </div>
                      </div>

                      <div class="col-md-6" *ngIf="customerShippingAddress != null && customerShippingAddress != ''">
                        <div class="box_title_invoice">Ship To</div>
                        <div class="smallFont_additionalDetail">
                            <div class="firmAddress" *ngIf="customerShippingAddress == null">N/A</div>
                            <div class="firmGstNum" *ngIf="customerShippingAddress != null">{{customerShippingAddress}}</div>
                            <div class="firmGstNum" *ngIf="customerMobile != null">Phone No.: {{customerMobile}}</div>
                            <div class="firmGstNum" *ngIf="customerEmail != null">Email: {{customerEmail}}</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                      <div class="invoiceDueDate">
                        <div class="invoiceDateDevider light_blue" [style.background]="bGcolor">
                          <div class="dateTitle">DATE :</div>
                          <div class="date_invoice_semiBold">{{genDate | date:'dd/MM/yyyy'}}</div>
                        </div>
                        <div class="invoiceDateDevider dark_blue" [style.background]="bGcolor">
                          <div class="dateTitle">PLEASE PAY :</div>
                          <div class="date_invoice_semiBold">&#8377;{{totalSum}}</div>
                        </div>
                        <div class="invoiceDateDevider light_blue" [style.background]="bGcolor">
                          <div class="dateTitle">EXP. DATE : </div>
                          <div class="date_invoice_semiBold">{{expDate | date:'dd/MM/yyyy'}}</div>
                        </div>
                        
                      </div>
                  </div>
                </div>
                  

                <div class="row" *ngIf="headerHtml">
                  <div class="col-md-12">
                    <div class="htmlContainer p_0 m_t_10" [innerHTML]="headerHtml | safeHtml" [style.color]="color">
  
                    </div>
                  </div>
                </div>

                <div class="clear"></div>
                <div class="table_format">
                  <div class="table-responsive">
                    <table class="table">
                      <tr id="tableHead">
                        <th [style.color]="color" [style.background]="bGcolor">Sr No.</th>
                        <th [style.color]="color" [style.background]="bGcolor" *ngIf="hsnColHide">HSN/SAC</th>
                        <th [style.color]="color" [style.background]="bGcolor">Product/Service</th>
                        <th [style.color]="color" [style.background]="bGcolor">Description</th>
                        <th [style.color]="color" [style.background]="bGcolor" *ngIf="imageColShow">Image</th>
                        <th [style.color]="color" [style.background]="bGcolor" class="min_width_65">Tax</th>
                        <th [style.color]="color" [style.background]="bGcolor">Qty/Unit</th>
                        <th [style.color]="color" [style.background]="bGcolor" *ngIf="discountVisible == true">Discount(%)</th>
                        <th [style.color]="color" [style.background]="bGcolor" class="text-right">Rate</th>
                        <th [style.color]="color" [style.background]="bGcolor" class="text-right" *ngIf="cessVisible == true">Cess</th>
                        <th [style.color]="color" [style.background]="bGcolor" class="text-right">Amount</th>
                      </tr>
                      <ng-container *ngIf="selectedProductList">
                     
                      <tr *ngFor="let list of selectedProductList; let i=index">
                        <td [style.color]="color">{{i+1}}</td>
                        <td [style.color]="color">{{list.hsn}}</td>
                        <td [style.color]="color">{{list.name}}</td>
                        <td [style.color]="color">{{list.description}}</td>
                        <td [style.color]="color" *ngIf="imageColShow"><img *ngIf="list.logo" [src]="list.logo" class="prev_prdct_logo"></td>
                        <td [style.color]="color">{{list.taxMaster.value}}% {{list.taxMaster.name}}</td>
                        <td [style.color]="color">{{list.qty}}</td>
                        <td [style.color]="color" *ngIf="discountVisible == true">{{list.discount}}</td>
                        <td [style.color]="color" class="text-right">&#8377;{{list.rate}}</td>
                        <td [style.color]="color" class="text-right" *ngIf="cessVisible == true">&#8377;{{list.cess}}</td>
                        <td [style.color]="color" class="text-right">&#8377;{{list.amount}}</td>
                      </tr>
                    
                      <tr class="countFormatRow">
                        <td [attr.colspan]="tableColspan">
                          <div class="left_bank_dtl_col semiBoldFont" [style.color]="color">
                            <ng-container *ngIf="partialPaymentData != null">
                              <ng-container *ngIf="partialPaymentData.length > 0">
                                <div class="m_b_20">
                              <div class="viewTitleTerms">
                                <strong>Payment Terms:</strong>
                              </div>
                              <div class="dtlGroup" *ngFor="let list of partialPaymentData">
                                <div class="min_width_40">{{list.partialNum}}% </div>
                              <div class="p_0_10">{{list.partialDescription}}</div>
                              <div class="">{{list.partialAmount | currency : 'INR'}}</div>
                              </div>
                              </div>
                              </ng-container>
                              </ng-container>
                            <ng-container *ngIf="settingFormFields.showBankDetails == true">
                                <div class="viewTitleTerms m_t_20">
                                  <strong>Account Details:</strong>
                                </div>
                          
                                <div class="dtlGroup">
                                  <div class="colHeading">Account Holder's Name</div>
                                  <div class="colDetail">{{settingFormFields.accountHolderName}}</div>
                                </div>
                                <div class="dtlGroup">
                                  <div class="colHeading">Account Number</div>
                                  <div class="colDetail">{{settingFormFields.accountNumber}}</div>
                                </div>
                                <div class="dtlGroup">
                                  <div class="colHeading">Bank Name</div>
                                  <div class="colDetail">{{settingFormFields.bankName}}</div>
                                </div>
                                <div class="dtlGroup">
                                  <div class="colHeading">Branch Name</div>
                                  <div class="colDetail">{{settingFormFields.branch}}</div>
                                </div>
                                <div class="dtlGroup">
                                  <div class="colHeading">IFSC Code</div>
                                  <div class="colDetail">{{settingFormFields.ifscCode}}</div>
                                </div>
                                <div class="dtlGroup">
                                  <div class="colHeading">Company PAN</div>
                                  <div class="colDetail">{{settingFormFields.companyPan}}</div>
                                </div>
                              </ng-container>
                          </div>
                        

                          <div class="right_count_details semiBoldFont" [style.color]="color">
                            <div class="dtlGroup">
                              <div class="colHeading">Subtotal</div>
                              <div class="colDetail">{{subTotal | currency : 'INR'}}</div>
                            </div>

                            <div class="dtlGroup" *ngIf="totalCess > 0">
                              <div class="colHeading">Total Cess</div>
                              <div class="colDetail">{{totalCess | currency : 'INR'}}</div>
                            </div>

                            <div class="dtlGroup" *ngIf="totalDiscount > 0">
                              <div class="colHeading">Total Discount</div>
                              <div class="colDetail">- {{totalDiscount | currency : 'INR'}}</div>
                            </div>

                            <div class="dtlGroup" *ngIf="totalTax > 0">
                                <div class="colHeading">Total tax</div>
                                <div class="colDetail">{{totalTax | currency : 'INR'}}</div>
                            </div>

                            <div class="dtlGroup" *ngIf="totalRoundOff > 0">
                              <div class="colHeading">Round off</div>
                              <div class="colDetail">{{totalRoundOff | currency : 'INR'}}</div>
                            </div>

                            <div class="dtlGroup">
                              <div class="colHeading">Total</div>
                              <div class="colDetail">{{totalSum | currency : 'INR'}}</div>
                            </div>
                          </div>

                        </td>
                      </tr>
                     
                    </ng-container>
                    </table>
                  </div>
                </div>
                <div class="row" *ngIf="footerHtml">
                  <div class="col-md-12">
                    <div class="htmlContainer" [innerHTML]="footerHtml | safeHtml" [style.color]="color">
  
                    </div>
                  </div>
                </div>
                <div class="row" *ngIf="settingFormFields">
                  <ng-container *ngIf="settingFormFields.signature">
                  <div class="col-md-12 text-right">
                    <div class="text-center d-inline-block margin_20_10">
                    <img [src]="settingFormFields.signature" [style.width]="settingFormFields.signatureWidth" [style.height]="settingFormFields.signatureHeight">
                    <div>Authorized Signatory</div>
                  </div>
                  </div>
                  </ng-container>
                </div>
              </div>

              

            </div>
          </ng-container>

          <ng-container #templateAsHtml>
            <div class="templateInnerBox templateFive" id="" *ngIf="templateSelected == 'SOUTHERN'" [style.color]="color">
              
              <div class="template_container">
                <div class="taxInvoiceTitle text-center" *ngIf="billNum != '' && billNum != '#id'">
                  <ng-container>
                    <ng-container>
                      Quotation No. : <span *ngIf="settingFormFields.invoicePrefix">{{settingFormFields.invoicePrefix}} </span> {{billNum}}<span *ngIf="settingFormFields.invoiceSuffix">{{settingFormFields.invoiceSuffix}}</span>
                    </ng-container>
                  </ng-container>
                </div>
              </div>

             
              <div class="p_l_0">
              <div class="row m_b_10" *ngIf="kindText">
                <div class="col-md-12">
                  {{kindText}}
                </div>
              </div>
              </div>

              <div class="row" *ngIf="headerHtml">
                <div class="col-md-12">
                  <div class="htmlContainer" [innerHTML]="headerHtml | safeHtml" [style.color]="color">

                  </div>
                </div>
              </div>

              <div class="clear"></div>
              <div class="taxInvoiceDetail">  
                <div class="table_format">
                  <div class="table-responsive">
                    <table class="table">
                      <tr id="tableHead">
                        <th [style.color]="color" [style.background]="bGcolor">Sr No.</th>
                        <th [style.color]="color" [style.background]="bGcolor" *ngIf="hsnColHide">HSN/SAC</th>
                        <th [style.color]="color" [style.background]="bGcolor">Product/Service</th>
                        <th [style.color]="color" [style.background]="bGcolor">Description</th>
                        <th [style.color]="color" [style.background]="bGcolor" *ngIf="imageColShow">Image</th>
                        <th [style.color]="color" [style.background]="bGcolor" class="min_width_65">Tax</th>
                        <th [style.color]="color" [style.background]="bGcolor">Qty/Unit</th>
                        <th [style.color]="color" [style.background]="bGcolor" *ngIf="discountVisible == true">Discount(%)</th>
                        <th [style.color]="color" [style.background]="bGcolor" class="text-right">Rate</th>
                        <th [style.color]="color" [style.background]="bGcolor" class="text-right" *ngIf="cessVisible == true">Cess</th>
                        <th [style.color]="color" [style.background]="bGcolor" class="text-right">Amount</th>
                      </tr>
                      <ng-container *ngIf="selectedProductList">
                     
                      <tr *ngFor="let list of selectedProductList; let i=index">
                        <td [style.color]="color">{{i+1}}</td>
                        <td [style.color]="color" *ngIf="hsnColHide">{{list.hsn}}</td>
                        <td [style.color]="color">{{list.name}}</td>
                        <td [style.color]="color">{{list.description}}</td>
                        <td [style.color]="color" *ngIf="imageColShow"><img *ngIf="list.logo" [src]="list.logo" class="prev_prdct_logo"></td>
                        <td [style.color]="color">{{list.taxMaster.value}}% {{list.taxMaster.name}}</td>
                        <td [style.color]="color">{{list.qty}}</td>
                        <td [style.color]="color" *ngIf="discountVisible == true">{{list.discount}}</td>
                        <td [style.color]="color" class="text-right">&#8377;{{list.rate}}</td>
                        <td [style.color]="color" class="text-right" *ngIf="cessVisible == true">&#8377;{{list.cess}}</td>
                        <td [style.color]="color" class="text-right">&#8377;{{list.amount}}</td>
                      </tr>
                    
                      <tr class="countFormatRow">
                        <td [attr.colspan]="tableColspan">
                          <div class="right_count_details semiBoldFont" [style.color]="color">
                            <div class="dtlGroup">
                              <div class="colHeading">Subtotal</div>
                              <div class="colDetail">{{subTotal | currency : 'INR'}}</div>
                            </div>

                            <div class="dtlGroup" *ngIf="totalCess > 0">
                              <div class="colHeading">Total Cess</div>
                              <div class="colDetail">{{totalCess | currency : 'INR'}}</div>
                            </div>

                            <div class="dtlGroup" *ngIf="totalDiscount > 0">
                              <div class="colHeading">Total Discount</div>
                              <div class="colDetail">- {{totalDiscount | currency : 'INR'}}</div>
                            </div>

                            <div class="dtlGroup" [class.cgst_sgst_col]="igstCheck == false" *ngIf="totalTax > 0">
                              <ng-container>
                                <ng-container *ngIf="igstCheck">
                                  <div class="colHeading">Total tax</div>
                                  <div class="colDetail">{{totalTax | currency : 'INR'}}</div>
                                </ng-container>
                            

                                <ng-container *ngIf="igstCheck == false">
                                  <ng-container *ngFor="let list of selectedProductList">
                                    <!-- <div class="cgst_sgst_outer">
                                      <div class="cgst_left_col">CGST &#8377; {{list.cgst}}</div>
                                      <div class="last_col_sgst">SGST &#8377; {{list.sgst}}</div>
                                    </div> -->
                                    <ng-container *ngIf="list.repeatTax == true && list.totalPrevTax != null">
                                      <div class="dtlGroup">
                                        <div class="colHeading">{{list.taxMaster.value}}% GST | CGST {{list.cgst | currency : 'INR'}}</div>
                                        <div class="colDetail">SGST {{list.sgst | currency : 'INR'}}</div>
                                      </div>
                                    </ng-container>
                                     <ng-container *ngIf="list.repeatTax == undefined && list.totalPrevTax == null">
                                       {{list.taxMaster.value}}% GST | CGST {{list.cgst | currency : 'INR'}} &nbsp; &nbsp; <div class="rightCounts">SGST {{list.sgst | currency : 'INR'}}</div><br>
                                      </ng-container>
                                  </ng-container>
                                </ng-container>
                              </ng-container>
                            </div>

                            <div class="dtlGroup" *ngIf="totalRoundOff > 0">
                              <div class="colHeading">Round off</div>
                              <div class="colDetail">{{totalRoundOff | currency : 'INR'}}</div>
                            </div>

                            <div class="dtlGroup">
                              <div class="colHeading">Total</div>
                              <div class="colDetail">{{totalSum | currency : 'INR'}}</div>
                            </div>
                          </div>

                        </td>
                      </tr>
                     
                    </ng-container>
                    </table>
                  </div>
                </div>
                <div class="row" *ngIf="footerHtml">
                  <div class="col-md-12">
                    <div class="htmlContainer" [innerHTML]="footerHtml | safeHtml" [style.color]="color">
  
                    </div>
                  </div>
                </div>
              </div>

              

            </div>
          </ng-container>
            
          </ng-template>
          
          <div id="templateAsHtml" #templateAsHtmlTemp *ngIf="templateSelected == 'PACIFIC'" class="d-none">
            <div class="templateInnerBox templateOne" [style.color]="color">
              

              <div class="template_container">
              
                <div class="row c-align">
                  <div class="col-md-6">
                    <div class="companyTitle" *ngIf="settingFormFields.businessName == undefined">Cronberry Pvt. Ltd.</div>
                    <div class="companyTitle" *ngIf="settingFormFields.businessName != undefined">{{settingFormFields.businessName}}</div>
                    <div class="smallFont_additionalDetail">
                      <div class="CompanyAddress" *ngIf="settingFormFields.address == undefined">
                        T2 Tirthraj Apartment, Jacob Road, Civil Lines, Jaipur, Rajasthan 302017
                      </div>
                      <div class="CompanyAddress" *ngIf="settingFormFields.address != undefined">
                        {{settingFormFields.address}}
                      </div>
                      <span *ngIf="settingFormFields.city">{{settingFormFields.city}},</span>
                          <span *ngIf="settingFormFields.state">{{settingFormFields.state}},</span>
                          <span *ngIf="settingFormFields.postalCode">{{settingFormFields.postalCode}}</span>
                      <div class="mobileNo" *ngIf="settingFormFields.phoneNumber == undefined">Mobile no.: +91 9878712123</div>
                      <div class="mobileNo" *ngIf="settingFormFields.phoneNumber != undefined">Mobile no.:{{settingFormFields.phoneNumber}}</div>

                      <div class="emailId" *ngIf="settingFormFields.email == undefined">Email : info@cronberry.com</div>
                      <div class="emailId" *ngIf="settingFormFields.email != undefined">Email : {{settingFormFields.email}}</div>

                      <div class="gstNumber" *ngIf="settingFormFields.gstNumber == undefined">GST NO.: </div>
                      <div class="gstNumber" *ngIf="settingFormFields.gstNumber != undefined">GST NO.: {{settingFormFields.gstNumber}}</div>
                    </div>
                  </div>
                  <div class="col-md-6 text-right">
                    <div [style.height]="settingFormFields.logoHeight" [style.width]="settingFormFields.logoWidth" class="logoOuterBox">
                    <img [src]="settingFormFields.logo?settingFormFields.logo:''">
                    </div>
                  </div>
                </div>
              </div>


              

              <div class="clear"></div>

                <div class="taxInvoiceDetail">
                  <div class="taxInvoiceTitle">
                    Quotation No. <span *ngIf="settingFormFields.quotationPrefix">{{settingFormFields.quotationPrefix}}</span>{{billNum}}<span *ngIf="settingFormFields.quotationSuffix">{{settingFormFields.quotationSuffix}}</span>
                  </div>
                  <div class="row font_14 m_b_10" *ngIf="kindText">
                    <div class="col-md-12">
                      {{kindText}}
                    </div>
                  </div>
                  <div class="clear"></div>

                <div class="row">
                  <div class="col-md-4">
                    <div class="box_title_invoice">Quote To</div>
                    <div class="smallFont_additionalDetail">
                      <div class="firmName" *ngIf="customerName == null">N/A</div>
                      <div class="firmName" *ngIf="customerName != null">{{customerName}}</div>
                      <div class="firmName" *ngIf="companyName">{{companyName}}</div>
                      <div class="firmAddress" *ngIf="customerAddress == null">N/A</div>
                      <div class="firmAddress" *ngIf="customerAddress != null">{{customerAddress}}</div>

                      <div class="firmGstNum" *ngIf="customerGstNum == null">GST NO.: N/A</div>
                      <div class="firmGstNum" *ngIf="customerGstNum != null">GST NO.: {{customerGstNum}}</div>
                      <div class="firmGstNum" *ngIf="customerMobile != null">Phone No.: {{customerMobile}}</div>
                      <div class="firmGstNum" *ngIf="customerEmail != null">Email: {{customerEmail}}</div>
                    </div>
                  </div>

                  <div class="col-md-4" *ngIf="customerShippingAddress != null && customerShippingAddress != ''">
                    <div class="box_title_invoice">Ship To</div>
                    <div class="smallFont_additionalDetail">
                      <div class="firmAddress" *ngIf="customerShippingAddress == null">N/A</div>
                      <div class="firmGstNum" *ngIf="customerShippingAddress != null">{{customerShippingAddress}}</div>
                      <div class="firmGstNum" *ngIf="customerMobile != null">Phone No.: {{customerMobile}}</div>
                      <div class="firmGstNum" *ngIf="customerEmail != null">Email: {{customerEmail}}</div>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="box_title_invoice">&nbsp;</div>
                    <div class="smallFont_additionalDetail">
                      
                      <div class="">Date :
                        <span class="semiBoldFont" *ngIf="genDate != undefined">{{genDate | date:'dd/MM/yyyy'}}</span>
                        <span class="semiBoldFont" *ngIf="genDate == undefined">7th Sep 2019</span>
                      </div>
                      <div class="">Exp. Date :
                        <span class="semiBoldFont" *ngIf="expDate == undefined"> 12th Sep 2019</span>
                        <span class="semiBoldFont" *ngIf="expDate != undefined">{{expDate | date:'dd/MM/yyyy'}}</span>
                      </div>
                       
                    </div>
                  </div>

                </div>
                <div class="clear"></div>
                <div class="headerHtml f_left width_100 p_0 margin_15_0" *ngIf="headerHtml">
                  <div class="" [innerHTML]="headerHtml | safeHtml">
                  </div>
                </div>
                <div class="clear"></div>
                <div class="table_format">
                  <div class="table-responsive">
                    <table class="table">
                      <tr id="tableHead">
                        <th [style.color]="color" [style.background]="bGcolor" class="font_11">Sr No.</th>
                        <th [style.color]="color" [style.background]="bGcolor" class="font_11" *ngIf="hsnColHide">HSN/SAC</th>
                        <th [style.color]="color" [style.background]="bGcolor" class="font_11">Product/Service</th>
                        <th [style.color]="color" [style.background]="bGcolor" class="font_11">Description</th>
                        <th [style.color]="color" [style.background]="bGcolor" class="font_11" *ngIf="imageColShow">Image</th>
                        <th [style.color]="color" [style.background]="bGcolor" class="font_11">Tax</th>
                        <th [style.color]="color" [style.background]="bGcolor" class="font_11">Qty/Unit</th>
                        <th [style.color]="color" [style.background]="bGcolor" class="font_11">Rate</th>
                        <th [style.color]="color" [style.background]="bGcolor" class="font_11" *ngIf="discountVisible == true">Discount</th>
                        <th [style.color]="color" [style.background]="bGcolor" class="font_11" *ngIf="cessVisible == true">Cess</th>
                        <th [style.color]="color" [style.background]="bGcolor" class="font_11">Amount</th>
                      </tr>
                      <ng-container *ngIf="selectedProductList">
                     
                      <tr *ngFor="let list of selectedProductList; let i=index">
                        <td [style.color]="color">{{i+1}}</td>
                        <td [style.color]="color" class="font_11" *ngIf="hsnColHide">{{list.hsn}}</td>
                        <td [style.color]="color" class="font_11">{{list.name}}</td>
                        <td [style.color]="color" class="font_11 text-left">{{list.description}}</td>
                        <td [style.color]="color" *ngIf="imageColShow"><img *ngIf="list.logo" [src]="list.logo" style="width: 50px;"></td>
                        <td [style.color]="color" class="font_11">{{list.taxMaster.value}}% {{list.taxMaster.name}}</td>
                        <td [style.color]="color" class="font_11">{{list.qty}}</td>
                        <td [style.color]="color" class="font_11">&#8377;{{list.rate}}</td>
                        <td [style.color]="color" class="font_11" *ngIf="discountVisible == true">{{list.discount}}%</td>
                        <td [style.color]="color" class="font_11" *ngIf="cessVisible == true">&#8377;{{list.cess}}</td>
                        <td [style.color]="color" class="font_11">&#8377;{{list.amount}}</td>
                      </tr>
                    
                      
                      <tr class="countFormatRow">
                        <td [attr.colspan]="tableColspan">
                          <div class="left_bank_dtl_col semiBoldFont" [style.color]="color">
                            <ng-container *ngIf="partialPaymentData != null">
                              <div *ngIf="partialPaymentData.length > 0">
                                <div class="font_600 m_b_10 font_15">
                                  <strong>Payment Terms:</strong>
                                </div>
                                <div class="dtlGroup" *ngFor="let list of partialPaymentData">
                                  <div class="f_left width_35 font_normal">{{list.partialNum}}% <div>( &#8377; {{list.partialAmount}} )</div></div>
                                  <div class="f_left font_normal width_60"> &nbsp; &nbsp; {{list.partialDescription}} </div>
                                </div>
                              </div>
                            </ng-container>
                            <div *ngIf="settingFormFields.showBankDetails == true">
                              <div class="clear"></div>
                              <div class="font_700 margin_10_0 font_15">
                                <strong>Account Details:</strong>
                              </div>
                              <div class="clear"></div>
                            <div class="dtlGroup">
                              <div class="colHeading">Account Holder's Name</div>
                              <div class="colDetail">{{settingFormFields.accountHolderName}}</div>
                            </div>
                            <div class="dtlGroup">
                              <div class="colHeading">Account Number</div>
                              <div class="colDetail">{{settingFormFields.accountNumber}}</div>
                            </div>
                            <div class="dtlGroup">
                              <div class="colHeading">Bank Name</div>
                              <div class="colDetail">{{settingFormFields.bankName}}</div>
                            </div>
                            <div class="dtlGroup">
                              <div class="colHeading">Branch Name</div>
                              <div class="colDetail">{{settingFormFields.branch}}</div>
                            </div>
                            <div class="dtlGroup">
                              <div class="colHeading">IFSC Code</div>
                              <div class="colDetail">{{settingFormFields.ifscCode}}</div>
                            </div>
                            <div class="dtlGroup">
                              <div class="colHeading">Company PAN</div>
                              <div class="colDetail">{{settingFormFields.companyPan}}</div>
                            </div>
                          </div>
                        </div>

                          <div class="right_count_details semiBoldFont" [style.color]="color">
                            <div class="font_600 m_b_10 font_15">
                              <strong>&nbsp;</strong>
                            </div>
                            <div class="dtlGroup">
                              <div class="colHeading">Subtotal</div>
                              <div class="colDetail">{{subTotal | currency : 'INR'}}</div>
                            </div>

                            <div class="dtlGroup" *ngIf="totalCess > 0">
                              <div class="colHeading">Total Cess</div>
                              <div class="colDetail">{{totalCess | currency : 'INR'}}</div>
                            </div>

                            <div class="dtlGroup" *ngIf="totalDiscount > 0">
                              <div class="colHeading">Total Discount</div>
                              <div class="colDetail">- &#8377;{{totalDiscount}}</div>
                            </div>

                            <div class="dtlGroup" *ngIf="totalTax > 0">
                                <div class="colHeading">Total tax</div>
                                <div class="colDetail">&#8377;{{totalTax}}</div>
                            </div>

                            <div class="dtlGroup" *ngIf="totalRoundOff > 0">
                              <div class="colHeading">Round off</div>
                              <div class="colDetail">&#8377;{{totalRoundOff}}</div>
                            </div>

                            <div class="dtlGroup">
                              <div class="colHeading">Total</div>
                              <div class="colDetail">&#8377; {{totalSum}}</div>
                            </div>
                          </div>

                        </td>
                      </tr>
                     
                     
                    </ng-container>
                    </table>
                  </div>
                </div>

                
                
              </div>

              <div class="clear"></div>

              <div class="footerHtml" *ngIf="footerHtml">
                <div class="" [innerHTML]="footerHtml | safeHtml">
              </div>
              </div>
              <div class="clear"></div>

              <div class="row" *ngIf="settingFormFields">
                <ng-container *ngIf="settingFormFields.signature">
                <div class="col-md-12 text-right signature_image">
                <div class="signature_inline_box">
                  <img [src]="settingFormFields.signature" [style.width]="settingFormFields.signatureWidth" [style.height]="settingFormFields.signatureHeight">
                  <div>Authorized Signatory</div>
                </div>
                </div>
                </ng-container>
              </div>

              

              
            </div>
          </div>
          
          <div id="templateAsHtml" #templateAsHtmlTemp *ngIf="templateSelected == 'ATLANTIC'" class="d-none">
          <div class="templateInnerBox templateTwo" [style.color]="color">
          
            <div class="taxInvoiceTitle">
          
              Quotation No. <span *ngIf="settingFormFields.quotationPrefix">{{settingFormFields.quotationPrefix}}</span>{{billNum}}<span *ngIf="settingFormFields.quotationSuffix">{{settingFormFields.quotationSuffix}}</span>
            </div>

            <div class="template_container">
              <div class="row c-align">
                <div class="col-md-6">
                  <div class="companyTitle" *ngIf="settingFormFields.businessName == undefined">Cronberry Pvt. Ltd.</div>
                  <div class="companyTitle" *ngIf="settingFormFields.businessName != undefined">{{settingFormFields.businessName}}</div>
                  <div class="smallFont_additionalDetail">
                    <div class="CompanyAddress" *ngIf="settingFormFields.address == undefined">
                      T2 Tirthraj Apartment, Jacob Road, Civil Lines, Jaipur, Rajasthan 302017
                    </div>
                    <div class="CompanyAddress" *ngIf="settingFormFields.address != undefined">
                      {{settingFormFields.address}}
                    </div>
                    <span *ngIf="settingFormFields.city">{{settingFormFields.city}},</span>
                          <span *ngIf="settingFormFields.state">{{settingFormFields.state}},</span>
                          <span *ngIf="settingFormFields.postalCode">{{settingFormFields.postalCode}}</span>
                    <div class="mobileNo" *ngIf="settingFormFields.phoneNumber == undefined">Mobile no.: +91 9878712123</div>
                    <div class="mobileNo" *ngIf="settingFormFields.phoneNumber != undefined">Mobile no.:{{settingFormFields.phoneNumber}}</div>

                    <div class="emailId" *ngIf="settingFormFields.email == undefined">Email : info@cronberry.com</div>
                    <div class="emailId" *ngIf="settingFormFields.email != undefined">Email : {{settingFormFields.email}}</div>

                    <div class="gstNumber" *ngIf="settingFormFields.gstNumber == undefined">GST NO.:</div>
                      <div class="gstNumber" *ngIf="settingFormFields.gstNumber != undefined">GST NO.: {{settingFormFields.gstNumber}}</div>
                  </div>
                </div>
                <div class="col-md-6 text-right">
                  <div [style.height]="settingFormFields.logoHeight" [style.width]="settingFormFields.logoWidth" class="logoOuterBox">
                  <img [src]="settingFormFields.logo?settingFormFields.logo:''">
                  </div>
                </div>
              </div>
            </div>

           
            <div class="clear"></div>
            <div class="p_l_0">
            <div class="row m_b_10 font_14" *ngIf="kindText">
              <div class="col-md-12">
                {{kindText}}
              </div>
            </div>
          </div>
            <div class="clear"></div>

          <div class="taxInvoiceDetail">
                
            <div class="billToShipTo">
              <div class="one_half_detail">
                <div class="box_title_cstmr_detail" [style.background]="bGcolor">
                  Quote To
                </div>
                <div class="cstmrDetailInner">
                  <div class="firmName" *ngIf="customerName == null">N/A</div>
                    <div class="firmName" *ngIf="customerName != null">{{customerName}}</div>
                    <div class="firmName" *ngIf="companyName">{{companyName}}</div>
                    <div class="firmAddress" *ngIf="customerAddress == null">N/A</div>
                    <div class="firmAddress" *ngIf="customerAddress != null">{{customerAddress}}</div>

                    <div class="firmGstNum" *ngIf="customerGstNum == null">GST NO.: N/A</div>
                    <div class="firmGstNum" *ngIf="customerGstNum != null">GST NO.: {{customerGstNum}}</div>
                    <div class="firmGstNum" *ngIf="customerMobile != null">Phone No.: {{customerMobile}}</div>
                      <div class="firmGstNum" *ngIf="customerEmail != null">Email: {{customerEmail}}</div>
                </div>
                </div>
              
                <div class="one_half_detail" *ngIf="customerShippingAddress != null && customerShippingAddress != ''">
                  <div class="box_title_cstmr_detail" [style.background]="bGcolor">
                    Ship To
                  </div>
                  <div class="cstmrDetailInner">
                    <div class="firmAddress" *ngIf="customerShippingAddress == null">N/A</div>
                    <div class="firmGstNum" *ngIf="customerShippingAddress != null">{{customerShippingAddress}}</div>
                    <div class="firmGstNum" *ngIf="customerMobile != null">Phone No.: {{customerMobile}}</div>
                    <div class="firmGstNum" *ngIf="customerEmail != null">Email: {{customerEmail}}</div>
                  </div>
                </div>
              </div>

            <div class="invoiceDetailsDue">
              <div class="table-responsive">
                <table class="table">
                  <tr>
                    
                    <th [style.color]="color" [style.background]="bGcolor">Date</th>
                    <th [style.color]="color" [style.background]="bGcolor">Total Due</th>
                    <th [style.color]="color" [style.background]="bGcolor">Exp. Date</th>
                  </tr>
                  <tr>
                    
                    <td [style.color]="color">{{genDate | date:'dd/MM/yyyy'}}</td>
                    <td [style.color]="color">&#8377;{{totalSum}}</td>
                    <td [style.color]="color">{{expDate | date:'dd/MM/yyyy'}}</td>
                  </tr>
                </table>
              </div>
            </div>

            <div class="clear:both"></div>
            <div class="headerHtml f_left width_100 p_0 margin_15_0" *ngIf="headerHtml">
              <div class="" [innerHTML]="headerHtml | safeHtml">
              </div>
            </div>

            <div class="clear"></div>
              <div class="table_format">
                <div class="table-responsive">
                  <table class="table">
                    <tr id="tableHead">
                      <th [style.color]="color" [style.background]="bGcolor" class="font_11">Sr No.</th>
                      <th [style.color]="color" [style.background]="bGcolor" class="font_11" *ngIf="hsnColHide">HSN/SAC</th>
                      <th [style.color]="color" [style.background]="bGcolor" class="font_11">Product/Service</th>
                      <th [style.color]="color" [style.background]="bGcolor" class="font_11">Description</th>
                      <th [style.color]="color" [style.background]="bGcolor" class="font_11" *ngIf="imageColShow">Image</th>
                      <th [style.color]="color" [style.background]="bGcolor" class="font_11">Tax</th>
                      <th [style.color]="color" [style.background]="bGcolor" class="font_11">Qty/Unit</th>
                      <th [style.color]="color" [style.background]="bGcolor" class="font_11">Rate</th>
                      <th [style.color]="color" [style.background]="bGcolor" class="font_11" *ngIf="discountVisible == true">Discount</th>
                      <th [style.color]="color" [style.background]="bGcolor" class="font_11" *ngIf="cessVisible == true">Cess</th>
                      <th [style.color]="color" [style.background]="bGcolor" class="font_11">Amount</th>
                    </tr>
                    <ng-container *ngIf="selectedProductList">
                   
                    <tr *ngFor="let list of selectedProductList; let i=index">
                      <td [style.color]="color">{{i+1}}</td>
                      <td [style.color]="color" class="font_11" *ngIf="hsnColHide">{{list.hsn}}</td>
                      <td [style.color]="color" class="font_11">{{list.name}}</td>
                      <td [style.color]="color" class="font_11 text-left">{{list.description}}</td>
                      <td [style.color]="color" *ngIf="imageColShow"><img *ngIf="list.logo" [src]="list.logo" style="width: 50px;"></td>
                      <td [style.color]="color" class="font_11">{{list.taxMaster.value}}% {{list.taxMaster.name}}</td>
                      <td [style.color]="color" class="font_11">{{list.qty}}</td>
                      <td [style.color]="color" class="font_11">&#8377;{{list.rate}}</td>
                      <td [style.color]="color" class="font_11" *ngIf="discountVisible == true">{{list.discount}}%</td>
                      <td [style.color]="color" class="font_11" *ngIf="cessVisible == true">&#8377;{{list.cess}}</td>
                      <td [style.color]="color" class="font_11">&#8377;{{list.amount}}</td>
                    </tr>
                   

                    <tr class="countFormatRow">
                      <td [attr.colspan]="tableColspan">
                        
                        <div class="left_bank_dtl_col semiBoldFont" [style.color]="color">
                          <ng-container *ngIf="partialPaymentData != null">
                          <div *ngIf="partialPaymentData.length > 0">
                            <div class="font_600 m_b_10 font_15">
                              <strong>Payment Terms:</strong>
                            </div>
                            <div class="dtlGroup" *ngFor="let list of partialPaymentData">
                              <div class="f_left width_35 font_normal">{{list.partialNum}}% <div>( &#8377;{{list.partialAmount}} )</div></div>
                              <div class="f_left width_60 font_normal"> &nbsp; &nbsp; {{list.partialDescription}} </div>
                            </div>
                          </div>
                          </ng-container>
                            <div *ngIf="settingFormFields.showBankDetails == true">
                              <div class="clear"></div>
                              <div class="font_700 margin_10_0 font_15">
                                <strong>Account Details:</strong>
                              </div>
                              <div class="clear"></div>
                            <div class="dtlGroup">
                              <div class="colHeading">Account Holder's Name</div>
                              <div class="colDetail">{{settingFormFields.accountHolderName}}</div>
                            </div>
                            <div class="dtlGroup">
                              <div class="colHeading">Account Number</div>
                              <div class="colDetail">{{settingFormFields.accountNumber}}</div>
                            </div>
                            <div class="dtlGroup">
                              <div class="colHeading">Bank Name</div>
                              <div class="colDetail">{{settingFormFields.bankName}}</div>
                            </div>
                            <div class="dtlGroup">
                              <div class="colHeading">Branch Name</div>
                              <div class="colDetail">{{settingFormFields.branch}}</div>
                            </div>
                            <div class="dtlGroup">
                              <div class="colHeading">IFSC Code</div>
                              <div class="colDetail">{{settingFormFields.ifscCode}}</div>
                            </div>
                            <div class="dtlGroup">
                              <div class="colHeading">Company PAN</div>
                              <div class="colDetail">{{settingFormFields.companyPan}}</div>
                            </div>
                          </div>
                        </div>

                        <div class="right_count_details semiBoldFont" [style.color]="color">
                          <div class="font_600 m_b_10 font_15">
                            <strong>&nbsp;</strong>
                          </div>
                          <div class="dtlGroup">
                            <div class="colHeading">Subtotal</div>
                            <div class="colDetail">{{subTotal | currency : 'INR'}}</div>
                          </div>

                          <div class="dtlGroup" *ngIf="totalCess > 0">
                            <div class="colHeading">Total Cess</div>
                            <div class="colDetail">{{totalCess | currency : 'INR'}}</div>
                          </div>

                          <div class="dtlGroup" *ngIf="totalDiscount > 0">
                            <div class="colHeading">Total Discount</div>
                            <div class="colDetail">- &#8377;{{totalDiscount}}</div>
                          </div>

                          <div class="dtlGroup" *ngIf="totalTax > 0">
                              <div class="colHeading">Total tax</div>
                              <div class="colDetail">&#8377;{{totalTax}}</div>
                          </div>

                          <div class="dtlGroup" *ngIf="totalRoundOff > 0">
                            <div class="colHeading">Round off</div>
                            <div class="colDetail">&#8377;{{totalRoundOff}}</div>
                          </div>
                        </div>

                      </td>
                    </tr>
                    
                   
                  </ng-container>
                  </table>
                </div>
              </div>
              
              

            </div>

            <div class="clear"></div>

            <div class="footerHtml" *ngIf="footerHtml">
              <div class="" [innerHTML]="footerHtml | safeHtml">
              </div>
            </div>

            <div class="clear"></div>

              <div class="row" *ngIf="settingFormFields">
                <ng-container *ngIf="settingFormFields.signature">
                <div class="col-md-12 text-right signature_image">
                <div class="signature_inline_box">
                  <img [src]="settingFormFields.signature" [style.width]="settingFormFields.signatureWidth" [style.height]="settingFormFields.signatureHeight">
                  <div>Authorized Signatory</div>
                </div>
                </div>
                </ng-container>
              </div>

          </div>
          </div>
          
          <div id="templateAsHtml" #templateAsHtmlTemp *ngIf="templateSelected == 'INDIAN'" class="d-none">
          <div class="templateInnerBox templateThree" [style.color]="color">
            
            <div class="template_container">
              <div class="row c-align">
                <div class="col-md-6">
                  <div class="companyTitle" *ngIf="settingFormFields.businessName == undefined">Cronberry Pvt. Ltd.</div>
                  <div class="companyTitle" *ngIf="settingFormFields.businessName != undefined">{{settingFormFields.businessName}}</div>
                  <div class="smallFont_additionalDetail">
                    <div class="CompanyAddress" *ngIf="settingFormFields.address == undefined">
                      T2 Tirthraj Apartment, Jacob Road, Civil Lines, Jaipur, Rajasthan 302017
                    </div>
                    <div class="CompanyAddress" *ngIf="settingFormFields.address != undefined">
                      {{settingFormFields.address}}
                    </div>
                    <span *ngIf="settingFormFields.city">{{settingFormFields.city}},</span>
                          <span *ngIf="settingFormFields.state">{{settingFormFields.state}},</span>
                          <span *ngIf="settingFormFields.postalCode">{{settingFormFields.postalCode}}</span>
                    <div class="mobileNo" *ngIf="settingFormFields.phoneNumber == undefined">Mobile no.: +91 9878712123</div>
                    <div class="mobileNo" *ngIf="settingFormFields.phoneNumber != undefined">Mobile no.:{{settingFormFields.phoneNumber}}</div>

                    <div class="emailId" *ngIf="settingFormFields.email == undefined">Email : info@cronberry.com</div>
                    <div class="emailId" *ngIf="settingFormFields.email != undefined">Email : {{settingFormFields.email}}</div>

                    <div class="gstNumber" *ngIf="settingFormFields.gstNumber == undefined">GST NO.:</div>
                      <div class="gstNumber" *ngIf="settingFormFields.gstNumber != undefined">GST NO.: {{settingFormFields.gstNumber}}</div>
                  </div>
                </div>
                <div class="col-md-6 text-right">
                  <div [style.height]="settingFormFields.logoHeight" [style.width]="settingFormFields.logoWidth" class="logoOuterBox">
                  <img [src]="settingFormFields.logo?settingFormFields.logo:''">
                  </div>
                </div>
              </div>
            </div>

            

            <div class="clear"></div>

          <div class="taxInvoiceDetail">
                <div class="taxInvoiceTitle">
                  Quotation No. <span *ngIf="settingFormFields.quotationPrefix">{{settingFormFields.quotationPrefix}}</span>{{billNum}}<span *ngIf="settingFormFields.quotationSuffix">{{settingFormFields.quotationSuffix}}</span>
                </div>

                
                  <div class="row m_b_10 font_14" *ngIf="kindText">
                    <div class="col-md-12">
                      {{kindText}}
                    </div>
                  </div>
                
                  <div class="clear"></div>

              <div class="row">
                <div class="col-md-4">
                  <div class="box_title_invoice">Quote To</div>
                  <div class="smallFont_additionalDetail">
                    <div class="firmName" *ngIf="customerName == null">N/A</div>
                    <div class="firmName" *ngIf="customerName != null">{{customerName}}</div>
                    <div class="firmName" *ngIf="companyName">{{companyName}}</div>
                    <div class="firmAddress" *ngIf="customerAddress == null">N/A</div>
                    <div class="firmAddress" *ngIf="customerAddress != null">{{customerAddress}}</div>

                    <div class="firmGstNum" *ngIf="customerGstNum == null">GST NO.: N/A</div>
                    <div class="firmGstNum" *ngIf="customerGstNum != null">GST NO.: {{customerGstNum}}</div>
                    <div class="firmGstNum" *ngIf="customerMobile != null">Phone No.: {{customerMobile}}</div>
                      <div class="firmGstNum" *ngIf="customerEmail != null">Email: {{customerEmail}}</div>
                  </div>
                </div>

                <div class="col-md-4" *ngIf="customerShippingAddress != null && customerShippingAddress != ''">
                  <div class="box_title_invoice">Ship To</div>
                  <div class="smallFont_additionalDetail">
                    <div class="firmAddress" *ngIf="customerShippingAddress == null">N/A</div>
                    <div class="firmGstNum" *ngIf="customerShippingAddress != null">{{customerShippingAddress}}</div>
                    <div class="firmGstNum" *ngIf="customerMobile != null">Phone No.: {{customerMobile}}</div>
                      <div class="firmGstNum" *ngIf="customerEmail != null">Email: {{customerEmail}}</div>
                  </div>
                </div>

                <div class="col-md-4 invoiceDateDetail">
                  <div class="box_title_invoice">&nbsp;</div>
                  <div class="smallFont_additionalDetail" [style.background]="bGcolor">
                    <table class="table">
                      
                      <tr>
                        <td class="p_0" [style.color]="color">
                          Exp. Date :
                        </td>
                        <td class="text-right p_0" [style.color]="color">
                          <span class="semiBoldFont"> {{expDate | date:'dd/MM/yyyy'}}</span>
                        </td>
                      </tr>

                      <tr>
                        <td class="p_0" [style.color]="color">
                          Date :
                        </td>
                        <td class="text-right p_0" [style.color]="color">
                          <span class="semiBoldFont"> {{genDate | date:'dd/MM/yyyy'}}</span>
                        </td>
                      </tr>

                      

                     
                    </table>
                  </div>
                </div>

              </div>

              <div class="headerHtml f_left width_100 p_0 margin_10_0" *ngIf="headerHtml">
                <div class="" [innerHTML]="headerHtml | safeHtml">
                </div>
              </div>
              <div class="clear"></div>
              <div class="table_format">
                <div class="table-responsive">
                  <table class="table">
                    <tr id="tableHead">
                      <th [style.color]="color" [style.background]="bGcolor" class="font_11">Sr No.</th>
                      <th [style.color]="color" [style.background]="bGcolor" class="font_11" *ngIf="hsnColHide">HSN/SAC</th>
                      <th [style.color]="color" [style.background]="bGcolor" class="font_11">Product/Service</th>
                      <th [style.color]="color" [style.background]="bGcolor" class="font_11">Description</th>
                      <th [style.color]="color" [style.background]="bGcolor" class="font_11" *ngIf="imageColShow">Image</th>
                      <th [style.color]="color" [style.background]="bGcolor" class="font_11">Tax</th>
                      <th [style.color]="color" [style.background]="bGcolor" class="font_11">Qty/Unit</th>
                      <th [style.color]="color" [style.background]="bGcolor" class="font_11">Rate</th>
                      <th [style.color]="color" [style.background]="bGcolor" class="font_11" *ngIf="discountVisible == true">Discount</th>
                      <th [style.color]="color" [style.background]="bGcolor" class="font_11" *ngIf="cessVisible == true">Cess</th>
                      <th [style.color]="color" [style.background]="bGcolor" class="font_11 text-right">Amount</th>
                    </tr>
                    <ng-container *ngIf="selectedProductList">
                   
                    <tr *ngFor="let list of selectedProductList; let i=index">
                      <td [style.color]="color">{{i+1}}</td>
                      <td [style.color]="color" class="font_11" *ngIf="hsnColHide">{{list.hsn}}</td>
                      <td [style.color]="color" class="font_11">{{list.name}}</td>
                      <td [style.color]="color" class="font_11 text-left">{{list.description}}</td>
                      <td [style.color]="color" *ngIf="imageColShow"><img *ngIf="list.logo" [src]="list.logo" style="width: 50px;"></td>
                      <td [style.color]="color" class="font_11">{{list.taxMaster.value}}% {{list.taxMaster.name}}</td>
                      <td [style.color]="color" class="font_11">{{list.qty}}</td>
                      <td [style.color]="color" class="font_11">&#8377;{{list.rate}}</td>
                      <td [style.color]="color" class="font_11" *ngIf="discountVisible == true">{{list.discount}}%</td>
                      <td [style.color]="color" class="font_11" *ngIf="cessVisible == true">&#8377;{{list.cess}}</td>
                      <td [style.color]="color" class="font_11 text-right">&#8377;{{list.amount}}</td>
                    </tr>
                  
                    
                    <!-- <tr class="countFormatRow">
                      <td colspan="5"></td>
                      <td colspan="2" class="semiBoldFont" [style.color]="color">SUBTOTAL</td>
                      <td colspan="2" class="text-right" [style.color]="color">&#8377; 675.00</td>
                    </tr> -->
                    <tr class="countFormatRow">
                      <td [attr.colspan]="tableColspan">
                        <div class="left_bank_dtl_col semiBoldFont" [style.color]="color">
                          <ng-container *ngIf="partialPaymentData != null">
                          <div *ngIf="partialPaymentData.length > 0">
                            <div class="font_600 m_b_10 font_15">
                              <strong>Payment Terms:</strong>
                            </div>
                            <div class="dtlGroup" *ngFor="let list of partialPaymentData">
                              <div class="f_left width_35 font_normal">{{list.partialNum}}% <div>( &#8377; {{list.partialAmount}} )</div></div>
                              <div class="f_left width_60 font_normal"> &nbsp; &nbsp; {{list.partialDescription}} </div>
                            </div>
                          </div>
                          </ng-container>
                            <div *ngIf="settingFormFields.showBankDetails == true">
                              <div class="clear"></div>
                              <div class="font_700 margin_10_0 font_15">
                                <strong>Account Details:</strong>
                              </div>
                              <div class="clear"></div>
                            <div class="dtlGroup">
                              <div class="colHeading">Account Holder's Name</div>
                              <div class="colDetail">{{settingFormFields.accountHolderName}}</div>
                            </div>
                            <div class="dtlGroup">
                              <div class="colHeading">Account Number</div>
                              <div class="colDetail">{{settingFormFields.accountNumber}}</div>
                            </div>
                            <div class="dtlGroup">
                              <div class="colHeading">Bank Name</div>
                              <div class="colDetail">{{settingFormFields.bankName}}</div>
                            </div>
                            <div class="dtlGroup">
                              <div class="colHeading">Branch Name</div>
                              <div class="colDetail">{{settingFormFields.branch}}</div>
                            </div>
                            <div class="dtlGroup">
                              <div class="colHeading">IFSC Code</div>
                              <div class="colDetail">{{settingFormFields.ifscCode}}</div>
                            </div>
                            <div class="dtlGroup">
                              <div class="colHeading">Company PAN</div>
                              <div class="colDetail">{{settingFormFields.companyPan}}</div>
                            </div>
                          </div>
                        </div>

                        <div class="right_count_details semiBoldFont" [style.color]="color">
                          <div class="font_600 m_b_10 font_15">
                            <strong>&nbsp;</strong>
                          </div>
                          <div class="dtlGroup">
                            <div class="colHeading">Subtotal</div>
                            <div class="colDetail">{{subTotal | currency : 'INR'}}</div>
                          </div>

                          <div class="dtlGroup" *ngIf="totalCess > 0">
                            <div class="colHeading">Total Cess</div>
                            <div class="colDetail">{{totalCess | currency : 'INR'}}</div>
                          </div>

                          <div class="dtlGroup" *ngIf="totalDiscount > 0">
                            <div class="colHeading">Total Discount</div>
                            <div class="colDetail">- &#8377;{{totalDiscount}}</div>
                          </div>

                          <div class="dtlGroup" *ngIf="totalTax > 0">
                              <div class="colHeading">Total tax</div>
                              <div class="colDetail">&#8377;{{totalTax}}</div>
                          </div>

                          <div class="dtlGroup" *ngIf="totalRoundOff > 0">
                            <div class="colHeading">Round off</div>
                            <div class="colDetail">&#8377;{{totalRoundOff}}</div>
                          </div>

                          <div class="dtlGroup">
                            <div class="colHeading">Total</div>
                            <div class="colDetail">&#8377; {{totalSum}}</div>
                          </div>
                        </div>

                      </td>
                    </tr>
                   
                  </ng-container>
                  </table>
                </div>
              </div>

              
            </div>

            <div class="clear"></div>

            <div class="footerHtml" *ngIf="footerHtml">
              <div class="" [innerHTML]="footerHtml | safeHtml">
              </div>
            </div>

            <div class="clear"></div>

              <div class="row" *ngIf="settingFormFields">
                <ng-container *ngIf="settingFormFields.signature">
                <div class="col-md-12 text-right signature_image">
                <div class="signature_inline_box">
                  <img [src]="settingFormFields.signature" [style.width]="settingFormFields.signatureWidth" [style.height]="settingFormFields.signatureHeight">
                  <div>Authorized Signatory</div>
                </div>
                </div>
                </ng-container>
              </div>

            

          </div>
          </div>
          
          <div id="templateAsHtml" #templateAsHtmlTemp *ngIf="templateSelected == 'ARCTIC'" class="d-none">
          <div class="templateInnerBox templateFour" [style.color]="color">
            
            

            <div class="template_container">
              <div class="taxInvoiceTitle" aria-colspan="text-center">
                Quotation No. <span *ngIf="settingFormFields.quotationPrefix">{{settingFormFields.quotationPrefix}}</span>{{billNum}}<span *ngIf="settingFormFields.quotationSuffix">{{settingFormFields.quotationSuffix}}</span>
              </div>
              <div class="row c-align">
                <div class="col-md-6">
                  <div class="companyTitle" *ngIf="settingFormFields.businessName == undefined">Cronberry Pvt. Ltd.</div>
                  <div class="companyTitle" *ngIf="settingFormFields.businessName != undefined">{{settingFormFields.businessName}}</div>
                  <div class="smallFont_additionalDetail">
                    <div class="CompanyAddress" *ngIf="settingFormFields.address == undefined">
                      T2 Tirthraj Apartment, Jacob Road, Civil Lines, Jaipur, Rajasthan 302017
                    </div>
                    <div class="CompanyAddress" *ngIf="settingFormFields.address != undefined">
                      {{settingFormFields.address}}
                    </div>
                    <span *ngIf="settingFormFields.city">{{settingFormFields.city}},</span>
                          <span *ngIf="settingFormFields.state">{{settingFormFields.state}},</span>
                          <span *ngIf="settingFormFields.postalCode">{{settingFormFields.postalCode}}</span>
                    <div class="mobileNo" *ngIf="settingFormFields.phoneNumber == undefined">Mobile no.: +91 9878712123</div>
                    <div class="mobileNo" *ngIf="settingFormFields.phoneNumber != undefined">Mobile no.:{{settingFormFields.phoneNumber}}</div>

                    <div class="emailId" *ngIf="settingFormFields.email == undefined">Email : info@cronberry.com</div>
                    <div class="emailId" *ngIf="settingFormFields.email != undefined">Email : {{settingFormFields.email}}</div>

                    <div class="gstNumber" *ngIf="settingFormFields.gstNumber == undefined">GST NO.:</div>
                      <div class="gstNumber" *ngIf="settingFormFields.gstNumber != undefined">GST NO.: {{settingFormFields.gstNumber}}</div>
                  </div>
                </div>
                <div class="col-md-6 text-right">
                  <div [style.height]="settingFormFields.logoHeight" [style.width]="settingFormFields.logoWidth" class="logoOuterBox">
                  <img [src]="settingFormFields.logo?settingFormFields.logo:''">
                  </div>
                </div>
              </div>
            </div>

            
            <div class="clear"></div>
            <div class="p_l_10">
            <div class="row m_b_10 font_14" *ngIf="kindText">
              <div class="col-md-12">
                {{kindText}}
              </div>
            </div>
            </div>
          
            <div class="clear"></div>

            <div class="taxInvoiceDetail">
              <div class="row">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="box_title_invoice">Quote To</div>
                      <div class="smallFont_additionalDetail">
                          <div class="firmName" *ngIf="customerName == null">N/A</div>
                          <div class="firmName" *ngIf="customerName != null">{{customerName}}</div>
                          <div class="firmName" *ngIf="companyName">{{companyName}}</div>
                          <div class="firmAddress" *ngIf="customerAddress == null">N/A</div>
                          <div class="firmAddress" *ngIf="customerAddress != null">{{customerAddress}}</div>

                          <div class="firmGstNum" *ngIf="customerGstNum == null">GST NO.: N/A</div>
                          <div class="firmGstNum" *ngIf="customerGstNum != null">GST NO.: {{customerGstNum}}</div>
                          <div class="firmGstNum" *ngIf="customerMobile != null">Phone No.: {{customerMobile}}</div>
                          <div class="firmGstNum" *ngIf="customerEmail != null">Email: {{customerEmail}}</div>
                      </div>
                    </div>

                    <div class="col-md-6" *ngIf="customerShippingAddress != null && customerShippingAddress != ''">
                      <div class="box_title_invoice">Ship To</div>
                      <div class="smallFont_additionalDetail">
                          <div class="firmAddress" *ngIf="customerShippingAddress == null">N/A</div>
                          <div class="firmGstNum" *ngIf="customerShippingAddress != null">{{customerShippingAddress}}</div>
                          <div class="firmGstNum" *ngIf="customerMobile != null">Phone No.: {{customerMobile}}</div>
                          <div class="firmGstNum" *ngIf="customerEmail != null">Email: {{customerEmail}}</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                    <div class="invoiceDueDate d-inline-block width_100">
                      <div class="invoiceDateDevider light_blue f_left width_30" [style.background]="bGcolor">
                        <div class="dateTitle">DATE :</div>
                        <div class="date_invoice_semiBold">{{genDate | date:'dd/MM/yyyy'}}</div>
                      </div>
                      <div class="invoiceDateDevider dark_blue f_left width_30" [style.background]="bGcolor">
                        <div class="dateTitle">PLEASE PAY :</div>
                        <div class="date_invoice_semiBold">&#8377;{{totalSum}}</div>
                      </div>
                      <div class="invoiceDateDevider light_blue f_left width_30" [style.background]="bGcolor">
                        <div class="dateTitle">EXP. DATE : </div>
                        <div class="date_invoice_semiBold">{{expDate | date:'dd/MM/yyyy'}}</div>
                      </div>
                      
                    </div>
                </div>
              </div>
                

              <div class="headerHtml f_left width_100 p_0 margin_10_0" *ngIf="headerHtml">
                <div class="" [innerHTML]="headerHtml | safeHtml">
                </div>
              </div>
              <div class="clear"></div>
              <div class="table_format">
                <div class="table-responsive">
                  <table class="table">
                    <tr id="tableHead">
                      <th [style.color]="color" [style.background]="bGcolor" class="font_11">Sr No.</th>
                      <th [style.color]="color" [style.background]="bGcolor" class="font_11" *ngIf="hsnColHide">HSN/SAC</th>
                      <th [style.color]="color" [style.background]="bGcolor" class="font_11">Product/Service</th>
                      <th [style.color]="color" [style.background]="bGcolor" class="font_11">Description</th>
                      <th [style.color]="color" [style.background]="bGcolor" class="font_11" *ngIf="imageColShow">Image</th>
                      <th [style.color]="color" [style.background]="bGcolor" class="font_11">Tax</th>
                      <th [style.color]="color" [style.background]="bGcolor" class="font_11">Qty/Unit</th>
                      <th [style.color]="color" [style.background]="bGcolor" class="font_11">Rate</th>
                      <th [style.color]="color" [style.background]="bGcolor" class="font_11" *ngIf="discountVisible == true">Discount</th>
                      <th [style.color]="color" [style.background]="bGcolor" class="font_11" *ngIf="cessVisible == true">Cess</th>
                      <th [style.color]="color" [style.background]="bGcolor" class="font_11">Amount</th>
                    </tr>
                    <ng-container *ngIf="selectedProductList">
                   
                    <tr *ngFor="let list of selectedProductList; let i=index">
                      <td [style.color]="color">{{i+1}}</td>
                      <td [style.color]="color" class="font_11" *ngIf="hsnColHide">{{list.hsn}}</td>
                      <td [style.color]="color" class="font_11">{{list.name}}</td>
                      <td [style.color]="color" class="font_11 text-left">{{list.description}}</td>
                      <td [style.color]="color" *ngIf="imageColShow"><img *ngIf="list.logo" [src]="list.logo" style="width: 50px;"></td>
                      <td [style.color]="color" class="font_11">{{list.taxMaster.value}} % {{list.taxMaster.name}}</td>
                      <td [style.color]="color" class="font_11">{{list.qty}}</td>
                      <td [style.color]="color" class="font_11">&#8377;{{list.rate}}</td>
                      <td [style.color]="color" class="font_11" *ngIf="discountVisible == true">{{list.discount}} %</td>
                      <td [style.color]="color" class="font_11" *ngIf="cessVisible == true">&#8377;{{list.cess}}</td>
                      <td [style.color]="color" class="font_11">&#8377;{{list.amount}}</td>
                    </tr>
                  
                    
                    <!-- <tr class="countFormatRow">
                      <td colspan="5"></td>
                      <td colspan="2" class="semiBoldFont" [style.color]="color">SUBTOTAL</td>
                      <td colspan="2" class="text-right" [style.color]="color">&#8377; 675.00</td>
                    </tr> -->
                    <tr class="countFormatRow">
                      <td [attr.colspan]="tableColspan">
                        <div class="left_bank_dtl_col semiBoldFont" [style.color]="color">
                          <ng-container *ngIf="partialPaymentData != null">
                          <div *ngIf="partialPaymentData.length > 0">
                            <div class="font_600 m_b_10 font_15">
                              <strong>Payment Terms:</strong>
                            </div>
                            <div class="clear"></div>
                            <div class="dtlGroup" *ngFor="let list of partialPaymentData">
                              <div class="f_left width_35 font_normal">{{list.partialNum}}% <div>( &#8377; {{list.partialAmount}} )</div></div>
                              <div class="f_left width_60 font_normal"> &nbsp; &nbsp; {{list.partialDescription}} </div>
                            </div>
                          </div>
                          </ng-container>
                            <div *ngIf="settingFormFields.showBankDetails == true" class="m_t_10">
                              <div class="clear"></div>
                              <div class="font_700 margin_10_0 font_15">
                                <strong>Account Details:</strong>
                              </div>
                              <div class="clear"></div>
                            <div class="dtlGroup">
                              <div class="colHeading">Account Holder's Name</div>
                              <div class="colDetail">{{settingFormFields.accountHolderName}}</div>
                            </div>
                            <div class="dtlGroup">
                              <div class="colHeading">Account Number</div>
                              <div class="colDetail">{{settingFormFields.accountNumber}}</div>
                            </div>
                            <div class="dtlGroup">
                              <div class="colHeading">Bank Name</div>
                              <div class="colDetail">{{settingFormFields.bankName}}</div>
                            </div>
                            <div class="dtlGroup">
                              <div class="colHeading">Branch Name</div>
                              <div class="colDetail">{{settingFormFields.branch}}</div>
                            </div>
                            <div class="dtlGroup">
                              <div class="colHeading">IFSC Code</div>
                              <div class="colDetail">{{settingFormFields.ifscCode}}</div>
                            </div>
                            <div class="dtlGroup">
                              <div class="colHeading">Company PAN</div>
                              <div class="colDetail">{{settingFormFields.companyPan}}</div>
                            </div>
                          </div>
                        </div>

                        <div class="right_count_details semiBoldFont" [style.color]="color">
                          <div class="font_600 m_b_10 font_15">
                            <strong>&nbsp;</strong>
                          </div>
                          <div class="dtlGroup">
                            <div class="colHeading">Subtotal</div>
                            <div class="colDetail">{{subTotal | currency : 'INR'}}</div>
                          </div>

                          <div class="dtlGroup" *ngIf="totalCess > 0">
                            <div class="colHeading">Total Cess</div>
                            <div class="colDetail">{{totalCess | currency : 'INR'}}</div>
                          </div>

                          <div class="dtlGroup" *ngIf="totalDiscount > 0">
                            <div class="colHeading">Total Discount</div>
                            <div class="colDetail">- &#8377;{{totalDiscount}}</div>
                          </div>

                          <div class="dtlGroup" *ngIf="totalTax > 0">
                              <div class="colHeading">Total tax</div>
                              <div class="colDetail">&#8377;{{totalTax}}</div>
                          </div>

                          <div class="dtlGroup" *ngIf="totalRoundOff > 0">
                            <div class="colHeading">Round off</div>
                            <div class="colDetail">&#8377;{{totalRoundOff}}</div>
                          </div>

                          <div class="dtlGroup">
                            <div class="colHeading">Total</div>
                            <div class="colDetail">&#8377; {{totalSum}}</div>
                          </div>
                        </div>

                      </td>
                    </tr>
                   
                    
                  </ng-container>
                  </table>
                </div>
              </div>
              
            </div>

            <div class="clear"></div>

            <div class="footerHtml" *ngIf="footerHtml">
              <div class="" [innerHTML]="footerHtml | safeHtml">
              </div>
            </div>

            <div class="clear"></div>

              <div class="row" *ngIf="settingFormFields">
                <ng-container *ngIf="settingFormFields.signature">
                <div class="col-md-12 text-right signature_image">
                <div class="signature_inline_box">
                  <img [src]="settingFormFields.signature" [style.width]="settingFormFields.signatureWidth" [style.height]="settingFormFields.signatureHeight">
                  <div>Authorized Signatory</div>
                </div>
                </div>
                </ng-container>
              </div>

            

          </div>
          </div>

          <div id="templateAsHtml" #templateAsHtmlTemp *ngIf="templateSelected == 'SOUTHERN'" class="d-none">
            <div class="templateInnerBox templateFive" [style.color]="color">
              
              
  
              <div class="template_container">
                <div class="taxInvoiceTitle text-center">
                  Quotation No. <span *ngIf="settingFormFields.quotationPrefix">{{settingFormFields.quotationPrefix}}</span>{{billNum}}<span *ngIf="settingFormFields.quotationSuffix">{{settingFormFields.quotationSuffix}}</span>
                </div>
               
              </div>
  
              
              <div class="clear"></div>
              <div class="p_l_10">
              <div class="row m_b_10 font_14" *ngIf="kindText">
                <div class="col-md-12">
                  {{kindText}}
                </div>
              </div>
              </div>
              <div class="clear"></div>
              <div class="headerHtml f_left width_100" *ngIf="headerHtml">
                <div class="" [innerHTML]="headerHtml | safeHtml">
                </div>
              </div>
            
              <div class="clear"></div>
  
              <div class="taxInvoiceDetail">
                <div class="table_format">
                  <div class="table-responsive">
                    <table class="table">
                      <tr id="tableHead">
                        <th [style.color]="color" [style.background]="bGcolor" class="font_11">Sr No.</th>
                        <th [style.color]="color" [style.background]="bGcolor" class="font_11" *ngIf="hsnColHide">HSN/SAC</th>
                        <th [style.color]="color" [style.background]="bGcolor" class="font_11">Product/Service</th>
                        <th [style.color]="color" [style.background]="bGcolor" class="font_11">Description</th>
                        <th [style.color]="color" [style.background]="bGcolor" class="font_11" *ngIf="imageColShow">Image</th>
                        <th [style.color]="color" [style.background]="bGcolor" class="font_11">Tax</th>
                        <th [style.color]="color" [style.background]="bGcolor" class="font_11">Qty/Unit</th>
                        <th [style.color]="color" [style.background]="bGcolor" class="font_11">Rate</th>
                        <th [style.color]="color" [style.background]="bGcolor" class="font_11" *ngIf="discountVisible == true">Discount</th>
                        <th [style.color]="color" [style.background]="bGcolor" class="font_11" *ngIf="cessVisible == true">Cess</th>
                        <th [style.color]="color" [style.background]="bGcolor" class="font_11">Amount</th>
                      </tr>
                      <ng-container *ngIf="selectedProductList">
                     
                      <tr *ngFor="let list of selectedProductList; let i=index">
                        <td [style.color]="color">{{i+1}}</td>
                        <td [style.color]="color" class="font_11" *ngIf="hsnColHide">{{list.hsn}}</td>
                        <td [style.color]="color" class="font_11">{{list.name}}</td>
                        <td [style.color]="color" class="font_11 text-left">{{list.description}}</td>
                        <td [style.color]="color" *ngIf="imageColShow"><img *ngIf="list.logo" [src]="list.logo" style="width: 50px;"></td>
                        <td [style.color]="color" class="font_11">{{list.taxMaster.value}} % {{list.taxMaster.name}}</td>
                        <td [style.color]="color" class="font_11">{{list.qty}}</td>
                        <td [style.color]="color" class="font_11">&#8377;{{list.rate}}</td>
                        <td [style.color]="color" class="font_11" *ngIf="discountVisible == true">{{list.discount}} %</td>
                        <td [style.color]="color" class="font_11" *ngIf="cessVisible == true">&#8377;{{list.cess}}</td>
                        <td [style.color]="color" class="font_11">&#8377;{{list.amount}}</td>
                      </tr>
                    
                      
                      <tr class="countFormatRow">
                        <td [attr.colspan]="tableColspan">
                          
                          <div class="right_count_details semiBoldFont" [style.color]="color">
                            <div class="font_600 m_b_10 font_15">
                              <strong>&nbsp;</strong>
                            </div>
                            <div class="dtlGroup">
                              <div class="colHeading">Subtotal</div>
                              <div class="colDetail">{{subTotal | currency : 'INR'}}</div>
                            </div>

                            <div class="dtlGroup" *ngIf="totalCess > 0">
                              <div class="colHeading">Total Cess</div>
                              <div class="colDetail">{{totalCess | currency : 'INR'}}</div>
                            </div>
  
                            <div class="dtlGroup" *ngIf="totalDiscount > 0">
                              <div class="colHeading">Total Discount</div>
                              <div class="colDetail">- &#8377;{{totalDiscount}}</div>
                            </div>
  
                            <div class="dtlGroup" *ngIf="totalTax > 0">
                                <div class="colHeading">Total tax</div>
                                <div class="colDetail">&#8377;{{totalTax}}</div>
                            </div>
  
                            <div class="dtlGroup" *ngIf="totalRoundOff > 0">
                              <div class="colHeading">Round off</div>
                              <div class="colDetail">&#8377;{{totalRoundOff}}</div>
                            </div>
  
                            <div class="dtlGroup">
                              <div class="colHeading">Total</div>
                              <div class="colDetail">&#8377; {{totalSum}}</div>
                            </div>
                          </div>
  
                        </td>
                      </tr>
                     
                      
                    </ng-container>
                    </table>
                  </div>
                </div>
                
              </div>
  
              <div class="clear"></div>
  
              <div class="footerHtml" *ngIf="footerHtml">
                <div class="" [innerHTML]="footerHtml | safeHtml">
                </div>
              </div>
  
              
  
            </div>
            </div>
          


<ng-template #sendWhatsappMsg>
  <div class="forgot-password-modal">
      <div class="modal-header">
          <h4 class="modal-title pull-left">Send WhatsApp Message</h4>
          <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide(); isReassignFormSubmit = false;">
            <span aria-hidden="true">&times;</span>
        </button>
      </div>



      <div class="modal-body">
          <form (keydown.enter)="$event.preventDefault()">
              Phone Number: {{whatsappMsgData.phone}}
              <div class="form-group">
                  <label class="control-label required pt-0">Message</label>
                  <textarea trim="blur" class="form-control" [ngClass]="{'invalid-control' : whatsappMsgData.smsText == ''}" [(ngModel)]="whatsappMsgData.smsText" rows="3" name="whatsappMsg"></textarea>
              </div>
              <div class="text-right">
                  <button class="btn btn btn-dark" (click)="sendWhatsapp()" type="submit">Submit</button>
              </div>
          </form>
      </div>
  </div>
</ng-template>



            <ng-template #addItemsData>
              <div class="forgot-password-modal">
                <div class="modal-header">
                  <h4 class="modal-title pull-left">Add Item</h4>
                  <button aria-label="Close" class="close pull-right" type="button" (click)="modalRef.hide(); resetProdAddForm();openModel(estimateModal,'winSizeMax');">
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div class="modal-body">
                  <form [formGroup]="addProdForm" (keydown.enter)="$event.preventDefault()">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                      <label>Product/Service Name</label>
                      <input type="text" class="form-control" [(ngModel)]="addProdField.name" formControlName="name">
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                      <label>Product/Service Description</label>
                      <input type="text" class="form-control" [(ngModel)]="addProdField.description" formControlName="description">
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    
                    <div class="col-md-6">
                      <div class="form-group">
                      <label>Rate</label>
                      <input type="text" class="form-control" onpaste="return false" [(ngModel)]="addProdField.rate" formControlName="rate" (keypress)="numberOnly($event)">
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                      <label class="width_100">HSN</label>
                      <input type="text" class="form-control" onpaste="return false" [(ngModel)]="addProdField.hsn" formControlName="hsn" onkeypress='return ( event.charCode >= 48 && event.charCode <= 57 )' maxlength="8">
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                      <label>Tax</label>
                      <select class="form-control" *ngIf="taxData.length > 0" [(ngModel)]="addProdField.taxSelector" formControlName="taxSelector">
                        <option value="Select Tax (If any)">Select Tax (If any)</option>
                        <option *ngFor="let list of taxData" value="{{list.id}}">
                          {{list.value}} % {{list.name}}
                        </option>
                      </select>
                      </div>
                    </div>

                    <div class="col-md-6 text-right">
                      <div class="form-group">
                        <label>&nbsp;</label>
                        <div class="">
                            <button (click)="openGalleryModal()" type="button" class="btn prdct_img_upld_btn">
                                <span
                                    *ngIf="addProdField.logo; else elsebgImage">
                                    <img [src]="addProdField.logo" class="prodct_image_logo"> Change Image</span>
                                <ng-template #elsebgImage>Select Image</ng-template>
                            </button>
                            <a href="javascript:void(0)" *ngIf="addProdField.logo && addProdField.logo != ''" (click)="addProdField.logo = '' "><img src="assets/images/form-trash-alt.svg" class="width_12" alt=""></a>
                        </div>
                    </div>
                    </div>

                  </div>

                  <div class="row">
                    <div class="col-md-12 text-center">
                      <div class="form-group">
                        <label class="width_100">&nbsp;</label>
                        <button class="btn btn-dark" (click)="addprod()">Submit</button>
                      </div>
                    </div>
                  </div>
                </form>
                 
                </div>
              </div>
              </ng-template>


            <ng-template #audDataList>
              <div class="forgot-password-modal">
                <div class="modal-header">
                  <h4 class="modal-title pull-left">
                    <img src="assets/images/angle-left.svg" class="cursor_pointer" (click)="modalRef.hide();openModel(estimateModal,'winSizeMax')">
                    &nbsp; Select Customer</h4>
                  <!-- <button aria-label="Close" class="close pull-right" type="button" (click)="modalRef.hide();openModel(estimateModal)">
                    <span aria-hidden="true">×</span>
                  </button> -->
                </div>
                <div class="modal-body">
                  <div class="audListDataBox">
                    <div class="table-responsive view_audience_tbl_parent">
                      <table class="table view_audience_tbl">
                        <tr>
                          <th>S.No.</th>
                          <th>Action</th>
                         <th>Name</th>
                          <th>Email</th>
                          <th>Phone No.</th>    
                          <th>Category</th>                      
                        </tr>
                        <ng-container *ngIf="audienceListData">
                        <tr *ngFor="let list of audienceListData; let i = index">
                          <td>{{i+1}} </td>
                          <td><button class="btn selectAudBtn" (click)="modalRef.hide();audSelected(list,estimateModal)">Select</button></td>
                          <td *ngIf="list.name != undefiend && list.name != ''">{{list.name}}</td>
                          <td *ngIf="list.name == undefiend ||  list.name == ''"></td>
                          <td *ngIf="list.email != undefiend && list.email != ''">{{list.email}}</td>
                          <td *ngIf="list.email == undefiend ||  list.email == ''"></td>
                          <td *ngIf="list.mobile != undefiend && list.mobile != ''">{{list.mobile}}</td>
                          <td *ngIf="list.mobile == undefiend ||  list.mobile == ''"></td>
                          <td *ngIf="list.category != undefiend && list.mcategoryobile != ''">{{list.categoryName}}</td>
                        </tr>
                      </ng-container>
                      <ng-container *ngIf="audienceListData.length == 0">
                        <tr>
                          <td class="no-records" colspan="8">No Records Yet</td>
                        </tr>
                      </ng-container>
                      </table>
                    </div>
                  </div>
                  <!-- <div *ngIf="audienceListData.length>0" class="table-footer footer-gray">
                    <pagination-controls id="foo" (pageChange)="dataForPaging($event)" maxSize="9" directionLinks="true" autoHide="true" class="pull-right" responsive="true" previousLabel="Previous" nextLabel="Next" screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
                        screenReaderCurrentLabel="You're on page">
                    </pagination-controls>
                    <div class="textblue text-left">
                        <div class="data-length"> Show
                            <select [(ngModel)]="limit" (change)="calculateAudience()">
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                </select>
                        </div>
                        <span> Total Count: {{totalRecords}}</span>
                    </div>
                </div> -->
                </div>
                </div>

              </ng-template>

            <div class="tableOuterContainer" *ngIf="invoiceListData">
              <div class="row">
                <div class="col-md-12">
                  <div class="table-reports">
                  <div class="table-body">
                    <div class="table-responsive min_width_200">
                      <table class="table reprot-table">
                        <tr>
                          <th>Sr. no.</th>
                          <th>Customer Name</th>
                          <th>Category</th>
                          <th>Quotation Date</th>
                          <th>Exp. Date</th>
                          <!-- <th>Quotation No.</th> -->
                          <th>Amount</th>
                          <th>Edit Count</th>
                          <th class="min_width_152">Actions</th>
                          <th class="min_width_100">Current Status</th>
                        </tr>
                        <ng-container *ngIf="invoiceListData">
                        <tr *ngFor="let list of invoiceListData | paginate: { itemsPerPage:dataSize, currentPage:page,totalItems:totalDataSize}; let i=index;">
                          <td>{{((page-1)*limit)+i+1}}</td>
                          <td><a routerLink="../../../admin/lead/{{list.category}}/{{list.audienceMasterId}}" target="_blank">{{list.customerName}}</a></td>
                          <td>{{list.categoryName}}</td>
                          <td>{{list.quotationDate | date:'dd/MM/yyyy HH:mm:ss'}}</td>
                          <td>{{list.expiryDate | date:'dd/MM/yyyy'}}</td>
                          <!-- <td>
                            <span *ngIf="settingFormFields.quotationPrefix">{{settingFormFields.quotationPrefix}}</span>{{list.quotationNumber}}<span *ngIf="settingFormFields.quotationSuffix">{{settingFormFields.quotationSuffix}}</span>
                          </td> -->
                          <td>{{list.total | currency : 'INR'}}</td>
                          <td>{{list.editCount}}</td>
                          <td class="iconTds">
                           
                            <a class="actionBtn" (click)="viewInvoice(list.id,list)" title="View">
                                <img  src="assets/images/segmenteye.png">
                            </a>
                            
                            <a *ngIf="list.invoiceStatus != 'COMPLETED'" title="Edit" class="actionBtn" (click)="viewSrNo = true ; prodListReset(); editInvoice(list); openModel(estimateModal,'winSizeMax');">
                              <img src="assets/images/edit-light.svg">
                            </a> 

                            <a class="actionBtn" title="Copy" (click)="viewSrNo = false ; prodListReset(); editInvoice(list,'copyInvoice'); openModel(estimateModal,'winSizeMax');">
                              <img src="assets/images/copy.svg">
                            </a> 

                            <a class="actionBtn" href="{{siteUrl}}quotation/download-quotation/{{list.hash}}" title="Download PDF">
                              <img src="assets/images/file-pdf.svg">
                            </a>
                           
                            <a class="actionBtn" (click)="deleteInvoice(list.id)" title="Delete Record">
                              <img src="assets/images/form-trash-alt.svg">
                            </a> 
                            
                          </td>

                          <td>
                            <div class="dropdown settingDropdown updateStatus">
                              <button class="btn btn-secondary btn-dark dropdown-toggle {{list.quotationStatus}}" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {{list.quotationStatus}}
                              </button>
                              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" *ngIf="list.invoiceStatus != 'COMPLETED'">
                                <a *ngIf="list.quotationStatus != 'DRAFT' && list.quotationStatus != 'SENT' || list.quotationStatus == 'EXPIRED'" class="dropdown-item" href="javascript:void(0);" (click)="invoiceStatusUpdate(list,'DRAFT')">DRAFT</a>
                                <a *ngIf="list.quotationStatus != 'SENT' && list.quotationStatus == 'DRAFT' || list.quotationStatus == 'EXPIRED'" class="dropdown-item" href="javascript:void(0);" (click)="invoiceStatusUpdate(list,'SENT')">SENT</a>
                                <a *ngIf="list.quotationStatus != 'EXPIRED' && list.quotationStatus != 'DRAFT' && list.quotationStatus != 'SENT'" class="dropdown-item" href="javascript:void(0);" (click)="invoiceStatusUpdate(list,'EXPIRED')">EXPIRED</a>
                              </div>
                            </div>
                          </td>
                        </tr>
                        </ng-container>

                        
                      </table>
                    </div>
                    <div *ngIf="invoiceListData.length > 0" class="table-footer footer-gray">
                      <pagination-controls (pageChange)="dataForPaging($event)" maxSize="9" directionLinks="true" autoHide="true" class="pull-right" responsive="true" previousLabel="Previous" nextLabel="Next" screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
                          screenReaderCurrentLabel="You're on page">
                      </pagination-controls>
                      <div class="textblue text-left">
                          <div class="data-length"> Show
                              <select [(ngModel)]="dataSize" (change)="invoiceListSize($event)">
                                  <option value="20">20</option>
                                  <option value="50">50</option>
                                  <option value="100">100</option>
                              </select>
                          </div>
                          <span>Total Count: {{totalDataSize}}</span>
                      </div>
                  </div>
                  <div *ngIf="invoiceListData.length == 0" class="no-records text-center">
                    No Record Found
                  </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
            <ng-template #popupConfirm>
              <div class="forgot-password-modal">
                <div class="modal-header j-center">
                  <h4 class="modal-title">Are you sure want to remove this record?</h4>
                </div>
                <div class="modal-body">
                  <div class="innerConfrm">
                    <button class="btn btn-dark" (click)="confirmDelete()">Yes</button>
                    <button class="btn btn-dark red" (click)="cancelDetete()">No</button>
                  </div>
                </div>
              </div>
            </ng-template>

            <ng-template #popupConfirmStatus>
              <div class="forgot-password-modal">
                <div class="modal-header j-center">
                  <h4 class="modal-title">Are you sure want to update status?</h4>
                </div>
                <div class="modal-body">
                  <div class="innerConfrm">
                    <button class="btn btn-dark" (click)="confirmUpdate()">Yes</button>
                    <button class="btn btn-dark red" (click)="cancelUpdate()">No</button>
                  </div>
                </div>
              </div>
            </ng-template>

            

          </div>

          <div class="container-fluid">
            <app-page-disable *ngIf="!isSubscribe"></app-page-disable>
          </div>

      </div>
</section>
</main>

<app-channel-type (afterSend)="sentStatus($event)" [proceedEvent]="campaignProceed" [isIndividualCampaign]="true" [reloadTemplates]="reloadTemps" [url]="apiUrl"></app-channel-type>