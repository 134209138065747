<main>
  <section class="inner-page">
    <div class="bgWhite" [ngClass]="{'inner-page-body':viewAudiencePage}" [ngClass]="{'border-bottom-0':viewAudiencePage}">
      <div class="" [ngClass]="{'container-fluid':viewAudiencePage}" [ngClass]="{'analysis-body':viewAudiencePage}">
        <div class="row top" *ngIf="viewAudiencePage">
          <div class="col-md-12">
            <div class="modal-header">
            <h4 class="modal-title pull-left">Create Graph</h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
            </div>
          </div>
        </div>
        <form [formGroup]="siteForm" [ngClass]="{submited:isSubmited}" (ngSubmit)="saveLeadReport()">
          <div class="form-group">
              <label class="control-label required pt-0">Report Name</label>
              <input trim="blur" formControlName="reportName" class="form-control" type="text" maxlength="50" />
          </div>
          <div class="form-group">
              <label class="control-label required pt-0">Chart Type</label>
              <select class="form-control" formControlName="reportType">
                  <option value="0">Bar</option>
                  <!-- <option value="1">Quantitative</option> -->
              </select>
          </div> 
          <div class="form-group">
              <label class="control-label pt-0">Source</label>
              <!-- <select class="form-control" formControlName="source" (change)="updateSource()">
                  <option value="">Select</option>
                  <option *ngFor="let item of sourseList" value="{{item.pluginId}}">{{item.pluginName}}</option>
              </select> -->
              <ng-select *ngIf="refilEditVal" formControlName="source" class="form-control auto_height_select" [multiple]="true" (change)="updateSource($event)">
                <ng-template ng-header-tmp>
                    <div>
                      <a href="javascript:void(0)" class="btn btn-link"
                              (click)="onSelectAllSource()">Select All</a>
                      <a href="javascript:void(0)" class="btn btn-link"
                              (click)="onClearAllSource()">Clear All</a>
                    </div>
                 </ng-template>    
                <ng-option *ngFor="let item of sourseList" value="{{item.pluginId}}" >
                      {{item.pluginName}}
                    </ng-option>
              </ng-select>

              <ng-select *ngIf="!refilEditVal" formControlName="source" class="form-control auto_height_select"  [multiple]="true" (change)="updateSource($event)">
                <ng-template ng-header-tmp>
                    <div>
                      <a href="javascript:void(0)" class="btn btn-link"
                              (click)="onSelectAllSource()">Select All</a>
                      <a href="javascript:void(0)" class="btn btn-link"
                              (click)="onClearAllSource()">Clear All</a>
                    </div>
                 </ng-template>
                <ng-option *ngFor="let item of sourseList" [value]="item.pluginId">
                  {{item.pluginName}}
                </ng-option>
                </ng-select>

          </div> 

          <div class="form-group">
            <label class="control-label  pt-0">Plugin Forms </label>

            <ng-select *ngIf="refilEditVal" class="form-control auto_height_select" [(ngModel)]="global.Channel.sourceFormIds" formControlName="pluginForms" [multiple]="true">
                <ng-template ng-header-tmp>
                    <div>
                      <a href="javascript:void(0)" class="btn btn-link"
                              (click)="onSelectAllPlugin()">Select All</a>
                      <a href="javascript:void(0)" class="btn btn-link"
                              (click)="onClearAllPlugin()">Clear All</a>
                    </div>
                 </ng-template> 
                <!-- <ng-option value="">Select</ng-option> -->
                <ng-option *ngFor="let item of pluginFormList" [value]="item.id">{{item.formName}}</ng-option>
            </ng-select>
            <ng-select *ngIf="!refilEditVal" class="form-control auto_height_select" [(ngModel)]="global.Channel.sourceFormIds" formControlName="pluginForms" [multiple]="true">
                <ng-template ng-header-tmp>
                    <div>
                      <a href="javascript:void(0)" class="btn btn-link"
                              (click)="onSelectAllPlugin()">Select All</a>
                      <a href="javascript:void(0)" class="btn btn-link"
                              (click)="onClearAllPlugin()">Clear All</a>
                    </div>
                 </ng-template> 
                <!-- <ng-option value="">Select</ng-option> -->
                <ng-option *ngFor="let item of pluginFormList" [value]="item.id">{{item.formName}}</ng-option>
            </ng-select>
            <!-- <span *ngFor="let item of pluginFormList" class="pluginFormName">
                {{item}} ,
            </span> -->
        </div> 
        

          <div class="form-group">
              <label class="control-label required pt-0">Attributes</label> 
              <ng-container *ngIf="siteForm.controls.reportType.value == 0; else quantitativeAttributes"> 
                  <select class="form-control" formControlName="reportColumnId" (change)="attributeSelect($event)">
                      <option value="">Select</option>
                      <ng-container *ngFor="let item of inputParams">
                          <option *ngIf="siteForm.controls.yaxisColumnId.value != item.id && siteForm.controls.eventTimeId.value != item.id" [disabled]="item.attrDisable == true" value="{{item.paramKey}}">{{item.paramName}}</option>
                      </ng-container>
                  </select>
              </ng-container>
              <ng-template #quantitativeAttributes>
                  <select class="form-control" formControlName="reportColumnId">
                      <option value="">Select</option>
                      <ng-container *ngFor="let item of inputParams">
                          <option *ngIf="item.paramDatatype == 'Numeric'" [disabled]="item.attrDisable == true" value="{{item.id}}">{{item.paramName}}</option>
                      </ng-container>
                  </select>
              </ng-template>
          </div>
          <div class="form-row" *ngIf="siteForm.controls.reportType.value == 0">
              <div class="form-group col-12">
                  <label class="control-label pt-0">Attributes Y-Axis</label>
                  <select class="form-control" formControlName="yaxisColumnId" (change)="setyAxisVal($event.target.value)">
                      <option value="">Select</option>
                      <ng-container *ngFor="let item of inputParams">
                          <option *ngIf="siteForm.controls.reportColumnId.value != item.id" [disabled]="item.attrDisable == true" value="{{item.paramKey}}">{{item.paramName}}</option>
                      </ng-container>
                  </select>
              </div>
              
          </div>
          <div class="form-row" *ngIf="siteForm.controls.reportType.value == 0">
            <div class="form-group col-6">
                <label class="control-label pt-0">Y-Axis Type</label>
                <ng-container *ngIf="siteForm.controls.yaxisColumnId.value != ''; else yaxisColumnDisabled"> 
                <select class="form-control" formControlName="ycolumnSum">
                    <option value="1" *ngIf="yaxisType == 'numeric'">Sum</option>
                    <option value="0">Count</option>
                </select>
                </ng-container>
                <ng-template #yaxisColumnDisabled>
                    <select class="form-control" name="ycolumnSum" disabled><option selected>Select</option></select>
                </ng-template>
            </div>
              <!-- <div class="form-group col-6">
                  <label class="control-label pt-0">Default Sort</label>
                  <select class="form-control" formControlName="defaultSort">
                      <option value="default">Default</option>
                      <option value="asc">ASC</option>
                      <option value="desc">DESC</option>
                  </select>
              </div> -->
              <div class="form-group col-6">
                  <label class="control-label pt-0">Show Data Greater Than</label>
                  <select class="form-control" formControlName="showDataGreater">
                      <option value="0">Select</option>
                      <option value="2">1</option>
                      <option value="3">2</option>
                      <option value="6">5</option>
                  </select>
              </div>
          </div>
          <div class="form-group">
              <label class="control-label required pt-0">Date Range Type</label>
              <select class="form-control" formControlName="eventTimeId">
                  <option value="">Select</option>
                  <ng-container *ngFor="let item of inputParams">
                      <option *ngIf="siteForm.controls.reportColumnId.value != item.id && item.paramDatatype == 'Date'" value="{{item.paramKey}}" [disabled]="item.disable == true">{{item.paramName}}</option>
                  </ng-container>
              </select>
          </div>
          <div class="form-group reportCheckShow">
            <div class="d-flex start-align">
              <label class="check">
                  Show on dashboard
                  <input type="checkbox" formControlName="showToDashboard" value="1">
                  <span class="checkmark"></span>
              </label>
              <span class="c-pointer"></span>
            </div> 
            <div class="note_max">Note:Max 4 reports will be displayed on Dashboard</div>
          </div>
          <div class="text-right mt-5">
              <button class="btn btn btn-dark" type="submit">Submit</button>
          </div>
        </form>
      </div>
    </div>
</section>
</main>