import { DatePipe, Location } from "@angular/common";
import { Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { telleCallerAttendance } from "../../core/modal/user";
import { AuthenticationService } from "../../core/services/authentication.service";
import { Global } from "../../shared/models/global.model";
import { SubUserObj } from "../../core/modal/user";
import { UserService } from "../../core/services/user.service";
import { CallassistService } from "./callassist.service";
import { AppConstants } from '../../constants/app.constants';
import { Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { DatePickerObj } from "../../core/modal/datePicker";
import * as moment from "moment";
import { FormGroup, NgForm } from "@angular/forms";
@Component({
    selector: 'callassist-dashboard',
    templateUrl: './callassist.dashboard.component.html',
    styleUrls:['./callassist.component.scss']
})

export class CallassistDashboardComponent implements OnInit {
   
    public startDate: Date;
    roleTypeId: number;
    ranges: any;
    callassistName:any;
    maxDate_range:any;
    public maxDate: Date;
    isSubscribe: boolean = true;
    public tellyCallerAttendanceList: telleCallerAttendance[] = [];
    public tellyCallerNotLoggedIn: any[] = [];
    modalRef: BsModalRef;
    telecallerList: SubUserObj[] = [];
    subTelecallerList: any = [];
    @ViewChild('download_xl_modal') download_xl_modal:TemplateRef<any>;
    public filterDateRange;
    weekDays:any = [{'key':'monday','value':'MON'},{'key':'tuesday','value':'TUE'},{'key':'wednesday','value':'WED'},{'key':'thursday','value':'THU'},{'key':'friday','value':'FRI'},{'key':'saturday','value':'SAT'},{'key':'sunday','value':'SUN'}];
    checkIn:any = '00';
    checkOut:any = '00';
    workingDaysForm:FormGroup;
    showWorkSchedule:boolean = false;
    emailAddress:any;
    @ViewChild('scheduleForm') scheduleForm:NgForm;
    @ViewChild('dropdownMenu2') workSchedule:ElementRef;

    constructor(
        private callassistService: CallassistService,
        private authenticationService: AuthenticationService,
        private toastr: ToastrService,
        private datePipe: DatePipe,
        public global: Global,
        private userService: UserService,
        public router: Router,
        private location:Location,
        private modalService: BsModalService,
    ) {
        this.isSubscribe = this.authenticationService.isPageSubscribe('Call Assist');
    }

    ngOnInit(): void {
        this.startDate= this.callassistService.startDate;
        this.maxDate=new Date();
        this.maxDate_range = moment();
        this.ranges = this.global.customDateRange();
        if(this.isSubscribe)
        this.getTelecallerList();
        this.roleTypeId = this.callassistService.roleTypeId;
        this.showWorkSchedule = this.authenticationService.getUserData().userRoleId == 1 ? true : false;        
    }

    viewWorkingDay(){
        this.callassistService.viewWorkingDay({}).subscribe(data=>{
            if(data.status){
                this.scheduleForm.setValue({
                    checkIn:data.data.checkIn,
                    checkOut:data.data.checkOut,
                    sunday:data.data.sunday,
                    monday:data.data.monday,
                    tuesday:data.data.tuesday,
                    wednesday:data.data.wednesday,
                    thursday:data.data.thursday,
                    friday:data.data.friday,
                    saturday:data.data.saturday,
                })

            }
        })
    }

    submitScheduleForm(event){
        let isSubmit:boolean = false;
        let getFormKeys = Object.keys(event);
        getFormKeys.forEach(elem=>{
               if(elem != "checkIn" && elem != "checkOut"){
               if(event[elem] == true){
                event[elem] = 1;
                isSubmit = true;
               } else {
                event[elem] = 0;
               }
            }
        })

        if(isSubmit == false){
            this.toastr.error('Please select at least one working day');
            return;
        }

        if(event.checkIn == '00' || event.checkOut == '00'){
            this.toastr.error('Please select check in / check out time');
            return
        }
        
        this.callassistService.createWorkingDay(event).subscribe(data=>{
            if(data.status){
                this.toastr.success(data.data);
                this.workSchedule.nativeElement.classList.remove('show');
            } else{
                this.toastr.error(data.error_msgs)
            }            
        })
        
    }

    back()
    {
        this.location.back();
    }

    send_email(){       
        let userObject = this.callassistName;
        var email_reg = /^[A-Za-z0-9]+[A-Za-z0-9._!#$%&'*+-/=?^_`{|}~]+@([A-Za-z0-9-]+[.]){1,2}[A-Za-z.]{2,10}$/;

        if(this.callassistName == undefined || this.callassistName.length == 0)
        {
            this.toastr.error('Please select telecaller');
            return;
        }
        if(!this.emailAddress || this.emailAddress == ""){
            this.toastr.error('Please enter email address');
            return;
        }

        if(email_reg.test(this.emailAddress) == false){
            this.toastr.error('Please enter valid email address');
            return
        }

        if(this.filterDateRange.startDate == null || this.filterDateRange.endDate == null)
        {
            this.toastr.error('Please select date range');
            return;
        }
        
        let fromDate = this.datePipe.transform(this.filterDateRange.startDate,'yyyy-MM-dd 00:00:00');
        let toDate = this.datePipe.transform(this.filterDateRange.endDate,'yyyy-MM-dd 23:59:59');
        
        let telId = encodeURIComponent(userObject);        
        this.callassistName.forEach(element => {
            element = encodeURIComponent(element);  
        });
        let emailSendParams = {'fromDate':fromDate,'toDate':toDate,'telecallerList':this.callassistName,'emailAddress':this.emailAddress}
        this.callassistService.downloadRprtRqst(emailSendParams).subscribe(data=>{
            if(data.status){
                this.toastr.success('Success')
            } else{
                this.toastr.error(data.error_msgs);
            }
        })
        
        
    }

    download_cnfrm()
    {        
        let userObject = this.callassistName;

        if(userObject == undefined)
        {
            this.toastr.error('Please select telecaller');
            return;
        }

        if(this.filterDateRange.startDate == null || this.filterDateRange.endDate == null)
        {
            this.toastr.error('Please select date range');
            return;
        }
        
        let fromDate = this.datePipe.transform(this.filterDateRange.startDate,'yyyy-MM-dd 00:00:00');
        let toDate = this.datePipe.transform(this.filterDateRange.endDate,'yyyy-MM-dd 23:59:59');
        
        let telId = encodeURIComponent(userObject);        
        let userListAsString
        this.callassistName.forEach(element => {
            element = encodeURIComponent(element);  
            userListAsString = userListAsString?userListAsString+'--'+element:element; 
        });
        
        let downloadParams = 'fromDate='+fromDate+'&toDate='+toDate+'&telecallerId='+userListAsString;
        this.callassistService.downloadAttendanceReport(downloadParams).subscribe(data=>{
            if(data.headers)
            {                
            var contentDisposition = data.headers.get('Content-Disposition');
            let fileName = contentDisposition.split(';')[1];
            let attachmentName = fileName.split('=')[1]
            var link = document.createElement('a');
            link.href = URL.createObjectURL(data.body);
            link.download = attachmentName;
            link.click();
            // this.modalRef.hide();
            }

        })
    }

    downloadXl()
    {        
        this.filterDateRange = undefined;
        this.callassistName = undefined;
        this.modalRef = this.modalService.show(this.download_xl_modal, {
            backdrop: 'static'
        });
    }

    fetchTellyCallerAttendace(reset:boolean){
        this.callassistService.getTelleCallerAttendanceList(reset).then(data => {
            let checkSelfInAttendance:boolean=false;
            if(data != undefined){
                this.tellyCallerAttendanceList  = data; 
                let checkSelfData = this.tellyCallerAttendanceList.find(x=>x.id == this.authenticationService.getUserData().userId);
                if(checkSelfData){
                    checkSelfInAttendance = true;
                }
            }
            this.tellyCallerNotLoggedIn = [];

            if(this.authenticationService.getUserData().userRoleId != 1){
            this.subTelecallerList.then(subData=>{
                subData.forEach(element => {
                    let checkNotLoggedIn = this.tellyCallerAttendanceList.find(x => x.userId == element)
                    if(checkNotLoggedIn == undefined){
                        this.tellyCallerNotLoggedIn.push({userId:element,status:'Not Logged In'});  
                    }
                });
            })
        }
        if(this.authenticationService.getUserData().userRoleId == 1){
            this.subTelecallerList.then(subData=>{
                subData.forEach(element => {
                    let checkNotLoggedIn = this.tellyCallerAttendanceList.find(x => x.userId == element.id)
                    if(checkNotLoggedIn == undefined){
                        this.tellyCallerNotLoggedIn.push({userId:element.id,status:'Not Logged In'});  
                    }
                });
            })
        }

        
            if(this.roleTypeId == 2){
                this.tellyCallerNotLoggedIn = []
            }   
        });
    }

    changeDate(event: any) {
        this.callassistService.startDate = this.startDate;
        this.fetchTellyCallerAttendace(true);
    }


    navigateWithState(userId: string) {
        let userObject = this.telecallerList.filter(x => x.id == userId);
        if(userObject.length > 0 ){
            this.callassistService.setUserObject(userObject[0]);
            this.router.navigateByUrl('/admin/call-assist-detail');
        }
    }

    viewLocationHistory(userId: string) {
        let userObject = this.telecallerList.filter(x => x.id == userId);
        let userIdEncod = encodeURI(userId);
        
        if(userObject.length > 0 ){
            this.callassistService.setUserObject(userObject[0]);
            this.router.navigate(['/admin/call-assist-location'],{ queryParams: { id: userIdEncod } });
        }
    }

    getTelecallerList(){
        this.startDate = this.callassistService.startDate ;
        this.userService.getUsersData().then( data => {            
            if(data!=undefined){
                if(this.roleTypeId == 1){
                    this.telecallerList = data;
                } else if(this.roleTypeId == 2){
                    this.telecallerList = []
                }                
                
                this.telecallerList.push({id:this.authenticationService.getUserData().userId, userName:this.authenticationService.getUserData().userName,userProfileName:this.authenticationService.getUserData().userName})
                this.subTelecallerList = this.userService.filterChildUsersCallAssist(data);                
                this.fetchTellyCallerAttendace(true);

            }
        });
    }


}