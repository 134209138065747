import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {

    transform(value: any, args?: any): any {
        let output = '';
        let re = /\d*(\d{10})/;
        let indianNumberRegex = /^[+0]{0,2}(91){0,1}(-){0,1}(\s)?[56789]{1}\d{9}$/;
        try {
            if(value.match(indianNumberRegex) != null){
                if(value != null && value != '')
                output = re.exec(value) != null ? re.exec(value)[1] : value;
            } else {
                output = null
            }
        } catch (e) { }
        return output;
    }

}
