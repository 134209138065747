<main>
    <section class="inner-page">
        <div class="create-campaign-block">
            <div class="container-fluid card">
                <div class="row pb-3 Create-Campaign">
                    <div class="col-md-9 col-12 col-sm-12">
                        <h4>Create Fresh Audience</h4>
                    </div>
                    <div class="col-md-3 col-12 col-sm-12">
                        <div class="filter-input-space">
                            <div class="form-group datepicker">
                                <input type="text" readonly autocomplete="off"
                                ngxDaterangepickerMd
                                [(ngModel)]="filterDateRange"
                                [alwaysShowCalendars]="true"
                                [ranges]="ranges"
                                [autoApply]="false"
                                [opens]="'left'"
                                [maxDate]="maxDate"
                                placeholder="Select Date Range"
                                (datesUpdated)="onDateSelection($event)">
                                <div class="input-group-append btn-calander">
                                  <button class="btn btn-outline-secondary calendar" type="button"></button>
                                </div>
                            </div>
                            
                        </div>
                        <div class="filter-input-space"><button (click)="storeCurrentFilter();updateAudience.emit()" class="btn btn-border" type="submit">+ Add Audience</button></div>
                    </div>
                </div>
                <div *ngIf="this.global.Channel.Source.length>0" class="col-md-12 col-12 col-sm-12 campaing-box">
                    <div class="heading">Source <button (click)="removeChannel('Source');" _ngcontent-serverapp-c3="" class="btn heading-btn" type="button"><img src="assets/images/cross.png"></button></div>
                    <div class="form-group detail row mb-0">
                        <div *ngFor="let item of this.global.Channel.Source" class="col-md-2 col-12 col-sm-12 source-detail">
                            <img (click)="this.global.ClearSpecificChannelId('Source', item.pluginId); removeFilters(); this.AnyChannelSelected();" src="assets/images/times-circle2.svg" />
                            <label>{{item.pluginName}}</label>
                        </div>
                    </div>
                </div>
                <div *ngIf="dateChecked" class="col-md-12 col-12 col-sm-12 campaing-box">
                    <div class="heading">Date Range Filter <button (click)="resetDate();" _ngcontent-serverapp-c3="" class="btn heading-btn" type="button"><img src="assets/images/cross.png"></button></div>
                    <div class="form-group detail row mb-0">
                        <div class="col-md-12 col-12 col-sm-12 source-detail">
                            <img (click)="resetDate();" src="assets/images/times-circle2.svg" />
                            <label>{{dateMsg}}</label>
                        </div>
                    </div>
                </div>
                <div *ngIf="this.global.Channel.Events.length>0" class="col-md-12 col-12 col-sm-12 campaing-box">
                    <div class="heading">Events <button (click)="removeChannel('Events')" _ngcontent-serverapp-c3="" class="btn heading-btn" type="button"><img src="assets/images/cross.png"></button></div>
                    <div class="form-group detail row mb-0">
                        <div *ngFor="let item of this.global.Channel.Events" class="col-md-2 col-12 col-sm-12 source-detail">
                            <img (click)="this.global.ClearSpecificChannelId('Events', item.id); this.AnyChannelSelected();" src="assets/images/times-circle2.svg" />
                            <label>{{item.paramName}}</label>
                        </div>
                    </div>
                </div>
                <div *ngIf="this.selectedFilters.length>0" class="col-md-12 col-12 col-sm-12 campaing-box">
                    <div class="heading">Attributes <button (click)="removeFilters()" _ngcontent-serverapp-c3="" class="btn heading-btn" type="button"><img src="assets/images/cross.png"></button></div>
                    <div class="detail">
                        <div *ngFor="let item of selectedFilters; let i = index" class="card-body tp-{{item.paramDatatype}}">
                            <div class="row">
                                <div class="col-md-2 col-12 col-sm-12"><label class="control-label">{{item.paramName}}</label></div>
                                <div class="col-md-6 col-12 col-sm-12 pr-0">
                                    <div *ngIf="item.paramDatatype=='String' || item.paramDatatype=='string' || item.paramDatatype=='Mobile' || item.paramDatatype=='Email'">
                                        <!-- <div class="row"  *ngIf="item.values.length > 0 "> -->
                                            <div class="row">
                                            <div class="col-md-4 col-12 col-sm-12">
                                                <select class="form-control" (change)="removeAudience(); itemOpratorSet(item); item.selected.text1 = ''" [(ngModel)]="item.selected.value">
                                                    <option value="contain">Contains</option>
                                                    <option value="in">IN</option>
                                                    <option value="not_in">Not IN</option>
                                                </select>
                                            </div>
                                            <div class="col-md-8 col-12 col-sm-12" *ngIf="item.selected.value == 'contain'">
                                                <ng-autocomplete 
                                                class="form-control"
                                                [data]="item.values"
                                                [(ngModel)]="item.selected.text1"
                                                [searchKeyword]="searchKeyword"
                                                [itemTemplate]="itemTemplate"
                                                [notFoundTemplate]="notFoundTemplate" autocomplete="off"></ng-autocomplete>
                                                <div class="containsError" *ngIf="item.conainsError">Contains value should be minimum 3 character</div>
                                            </div>
                                            <div class="col-md-8 col-12 col-sm-12" *ngIf="item.selected.value == 'in'">
                                                <ng-select [ngClass]="{'invalid-control' : !this.validFilters && (item.selected == undefined || item.selected.length == undefined || item.selected.length == 0)}" [items]="item.values" bindLabel="paramValue" [multiple]="true"
                                                [(ngModel)]="item.selected" (change)="selectedInFilter(i,'in')" (input)="fetchFilterDetails(item,$event)" (add)="storeParamVal(item)" (remove)="removeParamVal(item)" (clear)="clearStorage(item)"></ng-select>
                                            </div>
                                            <div class="col-md-8 col-12 col-sm-12" *ngIf="item.selected.value == 'not_in'">
                                                <ng-select [ngClass]="{'invalid-control' : !this.validFilters && (item.selected == undefined || item.selected.length == undefined || item.selected.length == 0)}" [items]="item.values" bindLabel="paramValue" [multiple]="true"
                                                [(ngModel)]="item.selected" (change)="selectedInFilter(i,'not_in')" (input)="fetchFilterDetails(item,$event)" (add)="storeParamVal(item)" (remove)="removeParamVal(item)" (clear)="clearStorage(item)"></ng-select>
                                            </div>
                                        </div>
                                        <ng-container *ngIf="item.values">
                                        <div *ngIf="item.values.length == 0" class="opacity-5">{{item.error_msgs}}</div>
                                        </ng-container>
                                    </div>
                                    <div class="row" *ngIf="item.paramDatatype=='Numeric'">
                                        <div class="col-md-6 col-12 col-sm-12">
                                            <select class="form-control" [(ngModel)]="item.selected.value">
                                                <option value=">=">≥ Greater Than Equal</option>
                                                <option value="<=">≤ Less Than Equal</option>
                                                <option value="=">= Equal</option>
                                                <option value="!=">!= Not Equal</option>
                                                <option value=">">> Greater Than</option>
                                                <option value="<"> < Less Than</option> <option value="between">Between</option>
                                            </select>
                                        </div>
                                        <div class="col-md-3 col-12 col-sm-12">
                                            <input trim="blur" [ngClass]="{'invalid-control' : item.selected.text1 == undefined || item.selected.text1 == ''}" type="text" [(ngModel)]="item.selected.text1" class="form-control" onkeypress='return (event.charCode >= 48 && event.charCode <= 57) || (event.charCode == 46)'>
                                        </div>
                                        <div class="col-md-3 col-12 col-sm-12" *ngIf="item.selected.value=='between'">
                                            <input trim="blur" [ngClass]="{'invalid-control' : !this.validFilters && (item.selected.text2 == undefined || item.selected.text2 == '')}" type="text" [(ngModel)]="item.selected.text2" class="form-control" onkeypress='return (event.charCode >= 48 && event.charCode <= 57) || (event.charCode == 46)'>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="item.paramDatatype=='Date'">
                                        <div class="col-md-4 col-12 col-sm-12 mb-1">
                                            <select [ngClass]="{'invalid-control' : !this.validFilters && (item.selected.value == undefined)}" class="form-control" [(ngModel)]="item.selected.value">
                                                <option value="between">Range</option>
                                                    <!-- <option value="day">Day</option>
                                                    <option value="week">Week</option>
                                                    <option value="month">Month</option>
                                                    <option value="daymonth">Day/Month</option> -->
                                            </select>
                                        </div>
                                        <div class="col-md-4 col-12 col-sm-12 mb-1">
                                            <span class="icontooltip"><img src="assets/images/calendar-day-black.svg " /></span>
                                            <input [ngClass]="{'invalid-control' : !this.validFilters && (item.selected.text1 == undefined || item.selected.text1 == '')}" type="text"  placeholder="From Date"
                                            placement="top left" [(ngModel)]="item.selected.text1"
                                            class="form-control date-icon modal-space" bsDatepicker autocomplete="off"  [bsConfig]="global.datePickerConfig">
                                            <span class="icontooltip"><img src="assets/images/calendar-day-black.svg " /></span>
                                        </div>
                                        <div class="col-md-4 col-12 col-sm-12" *ngIf="item.selected.value=='between'">
                                            <input [ngClass]="{'invalid-control' : !this.validFilters && (item.selected.text2 == undefined || item.selected.text2 == '' || item.selected.text1 > item.selected.text2)}" type="text" placeholder="To Date" placement="top left" 
                                            [(ngModel)]="item.selected.text2" class="form-control date-icon modal-space" bsDatepicker autocomplete="off"  [bsConfig]="global.datePickerConfig">
                                            <span class="icontooltip"><img src="assets/images/calendar-day-black.svg " /></span>
                                        </div>
                                    </div>

                                    <div class="row" *ngIf="item.paramDatatype=='Boolean'">
                                        <div class="col-md-5 col-12 col-sm-12"><input trim="blur" type="text" class="form-control"></div>
                                        <div class="col-md-5 col-12 col-sm-12">
                                            <select class="form-control">
                                                <option>1</option>
                                                <option>2</option>
                                                <option>3</option>
                                                <option>4</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-12 col-sm-12 ">
                                    <button type="button" (click)="removeFilter(item)" class="btn btn-campaign" type="button"><img src="assets/images/cross.png"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="campain-bottom-detail proceed-btn">
                    <button type="submit" (click)="gotoNext()" class="btn btn-dark">{{isJourney ? 'Apply' : 'Calculate Audience'}}</button>
                </div>
            </div>
        </div>
    </section>
</main>