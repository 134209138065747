<main>
	<section class="inner-page">
		<div class="inner-page-header py-3 d-none d-md-block">
			<div class="container-fluid">
				<div class="row">
					<div class="col-md-6">
						<h4>
							<a class="back-link" (click)="goToback();"><img src="assets/images/angle-left.svg"></a>
							Logged In Session List
						</h4>
					</div>
				</div>
			</div>
		</div>
    <div class="inner-page-body site-form">
      <div class="container-fluid">
        <div class="table-responsive table-height-scroll table-reports">
        <table class="table callAssistDashboardTable">
          <thead class="sticky-header" data-sticky-header="true">
            <tr class="callAssistDbRowTop">
              <th class="borderTh">IP Address</th>
              <th class="borderTh">User Agent</th>
              <th class="borderTh">Updated</th>
              <th class="borderTh min_width_200 text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of userSessionList">
              <td>{{ item.ipAddress }}</td>
              <td>{{ item.userAgent }}</td>
              <td>{{ item.updated }}</td>
              <td class="text-center">
                <button class="btn btn-dark sessionLogoutBtn" (click)="logoutSession(item.sessionId)">Logout Session</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
		<div class="logoutAllSessionBtn">
		  <button class="btn btn-dark" (click)="logoutAllSession()">Logout All Session</button>
    </div>
  </div>
</div>
	</section>
</main>