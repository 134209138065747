import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mappingFilter'
})
export class MappingFilterPipe implements PipeTransform {

  transform(items: any[], filter: Object): any {


    if (!items || !filter) {
        return items;
    }    
    
    function checkInside(item, term) {
      var toCompare = term.toLowerCase().trim();

      for (var property in item) {
          
          if (item[property] === null || item[property] == undefined) {
              continue;
          }
          if (typeof item[property] === 'object') {              
              if (checkInside(item[property], term)) {
                  return true;
              }
          }
          if (item[property].toString().toLowerCase().includes(toCompare)) {
              return true;
          }
      }
      return false;
  }
  return items.filter(function (item) {
        if(item){            
            if(filter == 'String' && item.paramDatatype == 'Numeric' || filter == 'String' && item.paramDatatype == 'Email' || filter == 'String' && item.paramDatatype == 'Mobile' || filter == 'Numeric' && item.paramDatatype == 'Mobile')
            {
                return checkInside(item,'');
            }
        }
      return checkInside(item, filter);
  });
}

}
