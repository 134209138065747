<static-header></static-header>

<div class="header-devide"></div> 

<div class="api-docs">
  <div class="api-bar d-sm-none d-md-none d-lg-none d-xl-none">
      <div> 
       <a href="javascript:void(0);" (click)="openSideMenu()"><img src="../../../../../assets/images/bars-solid.svg" class="img-fluid" /></a> 
         <h1 class="text-center"><b>API</b> Docs</h1>
      </div> 
    </div>
  <section class="sidebar">
    <ul>
			<li class="list"><a href="javascript:void(0);">Api Documentation</a>
				<ul class="dropdown">
					<li><a href="javascript:void(0);" (click)="goToPosition('start')">Getting Started</a></li>
					<li><a href="javascript:void(0);" (click)="goToPosition('prerequisite')">Prerequisite</a></li>
				</ul></li>
			<li class="list"><a href="javascript:void(0);" (click)="goToPosition('api-reference')">API Reference</a>
				<ul class="dropdown">
					<li><a href="javascript:void(0);" (click)="goToPosition('input')"><span>Input Parameters</span></a></li>
					<li><a href="javascript:void(0);" (click)="goToPosition('output')"><span>Output Parameters</span></a></li>
        </ul></li>
        <li class="list"><a href="javascript:void(0);" (click)="goToPosition('connector')">Connector</a>
          <ul class="dropdown">
            <li><a href="javascript:void(0);" (click)="goToPosition('google')"><span>Google Form Connector </span></a></li>
            <li><a href="javascript:void(0);" (click)="goToPosition('justdial')"><span>JustDial Connector</span></a></li>
            <li><a href="javascript:void(0);" (click)="goToPosition('indiamart')"><span>IndiaMart connector </span></a></li>
            <li><a href="javascript:void(0);" (click)="goToPosition('shopifyConnector')"><span>Shopify Connector </span></a></li>
            <li><a href="javascript:void(0);" (click)="goToPosition('integrateTradeIndia')"><span>Integrate TradeIndia </span></a></li>
            <li><a href="javascript:void(0);" (click)="goToPosition('customForm')"><span>Custom Form </span></a></li>
            <li><a href="javascript:void(0);" (click)="goToPosition('mytelly')"><span>MyTelly</span></a></li>
            <li><a href="javascript:void(0);" (click)="goToPosition('sulekha')"><span>Sulekha</span></a></li>
          </ul></li>
		</ul>
  </section>
  <section class="right-parent">
    <section class="right-child">
      <div class="box">
        <div class="box-content">
          <div id="start">
            <h1>Getting Started with Cronberry</h1>
            <p>With this page you will get started with Cronberry. </p>
          
          <hr>
          <h2>Introduction to Cronberry API</h2>
          <p>
            You can use this API for integrating your website or app so that you can create campaigns with Cronberry.
          </p>
        </div>
          <hr>

          <div id="prerequisite">
            <h2>Prerequisite</h2>
            <p>
              To start integration via API, you need:
            </p>
            <p>
              <b>Project Key:-</b> This is your account authentication id which is unique for every user project and required to identify users. It will be generated once you click on Integration Kit button in Project Integration screen. 
            </p>
            <p>
              <b>Audience Id:-</b>This is primary key which will be helpful to identify your subscribers uniquely. 
            </p>
          </div>
          <hr>
          <div id="api-reference">
            <h2>API Reference </h2>
            <p><b>Endpoint URL:-</b> &nbsp;<a href="javascript:void(0)">https://register.cronberry.com/api/campaign/register-audience-data</a></p>
            <p>
              <b>Note:-</b> It is mandatory to pass Project key and Audience Id.
            </p>
            <div id="input">
              <table class="table table-bordered table-striped table-site">
                <thead>
                  <tr>
                    <th colspan="2">Input Parameters</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Fixed</td>
                    <td>These are some pre-defined parameters which are helpful to identify your users. Ex:- Name, Email, Phone number, etc.</td>
                  </tr>
                  <tr>
                    <td>Variable</td>
                    <td>These are parameters created by you on project integration screen and will be used for filtering out your specific audience.</td>
                  </tr>
                  <tr>
                    <td>Project Key</td>
                    <td>Your unique Project Key generated for your Account.</td>
                  </tr>
                  <tr>
                    <td>Audience Id</td>
                    <td>This is primary key which will be helpful to identify your subscribers uniquely.</td>
                  </tr>
                  
                </tbody>
              </table>
            </div>
            <div id="output">
              <table class="table table-bordered table-striped table-site">
                <thead>
                  <tr>
                    <th colspan="2">Output Parameters</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Status</td>
                    <td>This field contains the data submission status. It is “success” if data is saved successfully else it will “fail”.</td>
                  </tr>
                  <tr>
                    <td>ErrorDesc</td>
                    <td>It contains the error description if the status is false else it will be null.</td>
                  </tr>
                  <tr>
                    <td>Data</td>
                    <td>This field contains the audienceId for which data is updated.</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <hr>
          <div id="connector">
            <div id="google">
              <h2>Google Form Connector</h2>
            <p><b>Integrate Google Forms with Cronberry</b></p>
            <p>This document depicts the procedure of mapping your Google form leads data on Cronberry. Google Forms is a web-based app used to create forms for data collection purposes.</p>
            <br>
            <p><b>Step 1:-</b> Go to  <a href="https://docs.google.com/forms" target="_blank">https://docs.google.com/forms</a> and create a form.</p>
            <div><img src="assets/images/google-form3.png" class="img-fluid"></div>
            <p>Using the form editor give your form a name and add your questions, one by one and set mandatory fields as required.</p>
            <br>
            <p><b>Step 2:-</b> Create Google Sheet that is attached to your google form. First, on your form in ‘edit’ mode, click on ‘responses’ tab and then click on the green ‘create sheet’ icon on the right top corner.</p>
            <div><img src="assets/images/google-form6.png" class="img-fluid" /></div>
            <p>And follow the prompt to <b>create a spreadsheet</b>. This will create your google sheet.</p>
            <div><img src="assets/images/google-form2.png" class="img-fluid" /></div>
            <br>
            <p><b>Step 3:-</b><b>Get you Cronberry Form-Key and ProjectKey.</b></p>
            <p>Copy the project key and the Form-Key generated on your Cronberry account as shown highlighted in below image.</p>
            <div><img src="assets/images/google-form1.png" class="img-fluid" /></div>

            <p><b>Step 4:-</b><b>Write a script to call the API</b></p>
            <p>Open your Google Sheet and on the top menu go to tools > script editor.</p>
            <p><b>Remove All the existing code from Script Editor.</b></p>
            <div><img src="assets/images/google-form7.png" class="img-fluid" /></div>
            <p><b>You need to add below code in your script editor.</b></p>
            <div class="code">
            <p>function googleForm(){{ '{' }}</p>
              <p>&nbsp; var sheet = SpreadsheetApp.getActiveSheet();</p>
              <p>&nbsp; var clientField = sheet.getRange(1,1,1,sheet.getLastColumn()).getValues();</p>
              <p>&nbsp; var clientData = sheet.getRange(sheet.getLastRow(),1,1,sheet.getLastColumn()).getValues();</p>
              <p>&nbsp; var data = {{ '{' }}</p>
              <p>&nbsp; &nbsp; key: clientField[0],</p>
              <p>&nbsp; &nbsp; value: clientData[0]</p>
              <p>&nbsp; {{ '}' }};</p>
              <p>&nbsp;&nbsp;</p>
              <p>&nbsp; var headers = {{ '{' }}</p>
              <p>&nbsp; &nbsp; &quot;Authorization&quot; : &quot;Basic Y3JvbmJlcnJ5QHVzZXJuYW1lOmNyb25iZXJyeUBwYXNzd29yZA==&quot;</p>
              <p>&nbsp; {{ '}' }};</p>
              <p>&nbsp;&nbsp;</p>
              <p>&nbsp; var options = {{ '{' }}</p>
              <p>&nbsp; &nbsp; headers:headers,</p>
              <p>&nbsp; &nbsp; method : &#39;post&#39;,</p>
              <p>&nbsp; &nbsp; contentType: &#39;application/json&#39;,</p>
              <p>&nbsp; &nbsp; payload : JSON.stringify(data)</p>
              <p>&nbsp; {{ '}' }};</p>
              <p>&nbsp; UrlFetchApp.fetch(&#39;https://cloud.api.cronberry.com/cronberry/api/push/google-form/<b>FORM-KEY/PROJECT-KEY</b>&#39;, options);</p>
              <p>&nbsp; Logger.log(JSON.stringify(data));</p>
              <p>{{ '}' }}</p>
            </div>
            <p><b>Replace FORM-KEY and PROJECT-KEY  with the Cronberry form-key and project-key you copied in step3.</b></p>
            <p>After copying the above script in script editor, Go to Edit > Current Project’s triggers.</p>
            <div><img src="assets/images/google-form5.png" class="img-fluid" /></div>
            <p>Now, You can add Trigger by clicking on the ADD TRIGGER button in the right corner bottom of the page.</p>
            <div><img src="assets/images/google-form4.png" class="img-fluid" /></div>
            <p>For Adding Trigger, you need to select options as shown in the screenshot below and then click on the “Save” button.<br>
              Now your configuration has been completed.</p>
            <div><img src="assets/images/google-form9.png" class="img-fluid" /></div>
            <p>Note* - Make sure that only one trigger is created for the Google Form. Since, multiple triggers will add duplicate entries in the record.</p>
              <br>
            <p><b>Step 4:-</b> It is mandatory to submit the Data from your Google Form Once to check that data is getting pushed to Cronberry. <br>
              Once your form data is submitted,  your google form fields will be automatically mapped with Cronberry fields as shown in below image.</p>
              <p>Change the Field mapping as per your requirement and click on Save.</p>
            <div><img src="assets/images/google-form8.png" class="img-fluid" /></div>
            </div>
            <hr>
            <div id="justdial">
              <h2>JustDial Connector </h2>
              <p>
                <b>Integrate JustDial with Cronberry</b>
              </p>
              <p>
                This document depicts the procedure of mapping your JustDial leads data on Cronberry.
              </p>
              <div>
                <p><b>Requirement:</b></p>
                <p>1. You must have admin account of JustDial to collect your leads on Cronberry.</p>
                <p>2. You must be a paid user of JustDial connector on Cronberry. </p>
              </div>
              <div>
                <p><b>Procedure:</b></p>
                <p>1. Go to Projects > Marketplace. </p>
                <p>2. Click on JustDial then connector pop-up will be displayed.  </p>
                <p>3.  Please provide this mentioned URL to your justdial provider and request them to post lead data on it. Once they start posting, leads data will be automatically captured in your Cronberry account.</p>
                <p>4.  Then your justdial leads will get started to capture automatically on your cronberry account.
                </p>
              </div>
              <div>
                <img src="../../../../../assets/images/api-screenshot9.png" class="img-fluid" />
              </div>
            </div>
            <hr>
            <div id="indiamart">
              <h2>Integrate IndiaMart with Cronberry
              </h2>
              <p>
                This document depicts the procedure of mapping your IndiaMart leads data on Cronberry.  
              </p>
              <div>
                <p><b>Requirement:</b></p>
                <p>1. You must have admin account of IndiaMart to collect your leads on Cronberry.</p>
                <p>2. You must be a paid user of IndiaMart connector on Cronberry. </p>
              </div>
              <div>
                <p><b>Procedure:</b></p>
                <p>1. Go to Projects > Marketplace. </p>
                <p>2. Click on IndiaMart then connector pop-up will be displayed.  </p>
                <p>3.  You need to fill out below required fields:</p>
                <p><b>Mobile Number:</b>This is the primary mobile number of your account registered with IndiaMART. This is a mandatory input parameter.
                </p>
                <p>
                  <b>Mobile Key:</b>This is a unique key which is received on your primary email & mobile number. This is also a mandatory input parameter.
                </p>
                <p>For Mobile Key, You need to login seller.indiamart.com and Go to Settings>Account Settings available on the left navigation and click on Generate Key. A unique CRM key will be received on your registered email-id & mobile number.</p>
                <p> <b>Note:</b> On clicking ‘Generate Key’, a new key will be generated & older one will expire.
                </p>
              </div>
              <div>
                <img src="../../../../../assets/images/api-screenshot10.png" class="img-fluid" />
              </div>
            </div>
            <hr>
            <div id="shopifyConnector">
              <h2>Integrate Shopify with Cronberry</h2>
              <p>This document depicts the procedure of mapping your Shopify store with Cronberry. Shopify is a cloud-based, SAAS shopping cart solution.</p>
              <ol>
                  <li class="list">On Shopify app store, search for Cronberry app. Now click on <strong>Add App</strong> button to integrate this app into your store.</li>
                  <li>Then login to your Shopify store.</li>
                  <li>After login, Go to <strong>Apps</strong> section and click on Cronberry app.</li>
                  <li>Here you need to fill out below details.
                      <ol class="ol1">
                          <li>
                              <strong>Authentication Key</strong>:- This is the project key generated on your Cronberry account as shown highlighted in below image.<br/><br/>
                              <img src="assets/images/picture1.png" alt="" ><br/><br/>
                          </li>
                          <li>
                              <strong>Username & Password</strong>:- these will be the same which is used for login into your Cronberry
                              account.<br/><br/>
                              <img src="assets/images/picture2.png" alt="" ><br/><br/>
                          </li>
                          <li>
                              <strong>Firebase Setting</strong>:- For this information, you need to login your firebase account <a href="https://firebase.google.com/" target="_blank" rel="noopener noreferrer">https://firebase.google.com/</a> then click on Go To Console button.<br/><br/>
                              <img src="assets/images/picture3.png" alt="" width="80%" ><br/><br/>
                              Then click on your listed firebase project. Under <strong>Project Overview</strong> > click on <strong>Project Settings</strong>.<br/><br/>
                              <img src="assets/images/picture4.png" alt="" width="80%" ><br/><br/>
                              In <strong>General</strong> section, When you will scroll down the page then all required Information you will get as per below screenshot.<br/><br/>
                              <img src="assets/images/picture5.png" alt="" ><br/><br/>
                              Please fill all these details in required fields as per below screenshot.<br/><br/>
                              <img src="assets/images/picture6.png" alt="" ><br/>
                              After filling all required details, click on <strong>submit</strong> button.<br/><br/>
                          </li>
                      </ol>
                  </li>
                  <li>Now your configuration has been completed. On Cronberry Admin Panel, Go to Projects > E - Commerce. Click on Shopify connector and now you will be able to collect your Shopify store leads and engage them with Cronberry.</li>
              </ol>
            </div>
            <hr>
            <div id="integrateTradeIndia">
              <h2>Integrate TradeIndia with Cronberry</h2>
              <p>This document depicts the procedure of mapping your TradeIndia leads data on Cronberry.</p>
              <br>
              <p>
              Requirement: <br>
              <strong>Pre-requisites</strong> - You must have an admin account of TradeIndia to collect your leads on Cronberry. <br>
              <strong>Procedure: </strong><br>
              1. Go to Dashboard > Project Integration > Marketplace.<br>
              2. Click on TradeIndia then connector pop-up will be displayed.<br>
              3. You need to fill out below required fields:<br>
                  <strong>User ID, Profile  ID and  Key</strong><br> 
              4. Login to your TradeIndia account > Go To Dashboard > Inquiries & Contacts > My Inquiry API<br>
              <img src="assets/images/integrateTradeIndia1.png" width="500" class="img-fluid" /><br><br>
              Click on the SAVE button to save the configuration. <br>
              <img src="assets/images/integrateTradeIndia2.png" class="img-fluid" />
            </p>
              <p><strong>Plugin Active:</strong> This toggle is used to activate the plugin to fetch the leads from TradeIndia.</p> 
              <p><strong>Lead updation:</strong> This toggle is used to assign the leads.</p> 
            </div>
            <hr>
            <div id="customForm">
              <h2>Integrate Custom Form with Cronberry</h2>
              <p>This document depicts the procedure of mapping your Custom Form leads data on Cronberry.</p>
              <br>
              <p>
                Requirement:<br>
                <strong>Procedure:</strong><br>
                1. Go to Dashboard > Project Integration > Forms > Custom Form<br>
                2. Click on Add Form <br>
                3. To create a custom form, you need to fill out required fields. <br>
                <img src="assets/images/custom-form1.png" class="img-fluid" width="700" /><br><br>
                <img src="assets/images/custom-form2.png" class="img-fluid" width="700" /><br><br>
                <img src="assets/images/custom-form3.png" class="img-fluid" width="700" /><br><br>
                4. Click on the PROCEED  button to create a custom form. Now you will be able to collect your Custom Form leads after submitting form details.<br>
                <img src="assets/images/custom-form4.png" class="img-fluid" width="700" /><br><br>
                5. You can use custom form templates for creating Email Campaign, Create Email Templates and Announcement Popup.<br><br>
                <b>Lead updation:</b> This toggle is used to assign the leads.<br><br>
                6. Use cases of custom form :
                <ol type="a">
                  <li>Go to <strong>Campaign Template</strong> > select <strong>Email Campaign</strong> > select <strong>Create Template</strong> > select custom form media > add custom form template and fill out all required fields > click on Save. <br>
                    <img src="assets/images/custom-form5.png" class="img-fluid" width="700" /><br><br>
                    <img src="assets/images/custom-form6.png" class="img-fluid" width="700" /><br><br>
                  </li>
                  <li>Go to <strong>Create Campaign</strong> > select (<strong>send to all audience/select fresh audience/select segments</strong>) >select <strong>Email Campaign</strong>> click on <strong>Proceed</strong> > open <strong>Email Campaign</strong> popup > select custom form media> add custom form template and fill out all required fields > click on <strong>Send</strong>.
                    <img src="assets/images/custom-form7.png" class="img-fluid" width="700" /><br><br>
                    <img src="assets/images/custom-form8.png" class="img-fluid" width="700" /><br><br>
                    <img src="assets/images/custom-form9.png" class="img-fluid" width="700" /><br><br>
                  </li>
                  <li>
                    Open <strong>Announcement Dropdown</strong> > open <strong>Popup Page</strong> > select custom form media>add custom form template and fill out all required fields > click on <strong>Preview</strong> to check customised form > click on <strong>Publish</strong> button .<br>
                    <img src="assets/images/custom-form10.png" class="img-fluid" width="700" /><br>
                    <img src="assets/images/custom-form11.png" class="img-fluid" width="700" /><br>
                  </li>
                </ol>
                

            </div>
            <hr>
            <div id="mytelly">
              <h2>Integrate MyTelly with Cronberry</h2>
              <p>This document depicts the procedure of mapping your My Telly leads data on Cronberry.</p>
              <p><strong>Requirement:</strong> <br>
              1. You must have an admin account of MyTelly to collect your leads on Cronberry.<br>
              2. You must be a paid user of the MyTelly connector on Cronberry.</p>
              <br>
              <p><strong>Procedure:</strong></p>
              <p>1. Login to MyTelly Dashboard</p>
              <img src="assets/images/my-telly8.png" class="img-fluid" width="700" /><br><br>
              <p>2. Click on the Profile icon and click the “Configuration” button</p>
              <img src="assets/images/my-telly1.png" class="img-fluid" width="700" /><br><br>
              <p>3. Click on "API configure"</p>
              <img src="assets/images/my-telly3.png" class="img-fluid" width="700" /><br><br>
              <p>4. Click on "Call Logs API"</p>
              <img src="assets/images/my-telly11.png" class="img-fluid" width="700" /><br><br>
              <p>5. Click on "Create new API"</p>
              <img src="assets/images/my-telly6.png" class="img-fluid" width="700" /><br><br>
              <p>6. Select "Process Type" as DCP.</p>
              <img src="assets/images/my-telly10.png" class="img-fluid" width="700" /><br><br>
              <p>7. Select Virtual Number.</p>
              <img src="assets/images/my-telly9.png" class="img-fluid" width="700" /><br><br>
              <p>8. Copy API URL from Cronberry.</p>
              <img src="assets/images/my-telly4.png" class="img-fluid" width="700" /><br><br>
              <p>9. Enter Copied Cronberry API URL in My Telly account.</p>
              <img src="assets/images/my-telly2.png" class="img-fluid" width="700" /><br><br>
              <p>10. Type API header  "Content-Type:application/json".</p>
              <img src="assets/images/my-telly12.png" class="img-fluid" width="700" /><br><br>
              <p>11. Choose API as "Other".</p>
              <img src="assets/images/my-telly5.png" class="img-fluid" width="700" /><br><br>
              <p>12. Tick Mark the call details you want to see on the Cronberry view audience section.</p>
              <img src="assets/images/my-telly7.png" class="img-fluid" width="700" /><br><br>
              <p>13. Scroll down and click on the "Create API" button.</p>
              <img src="assets/images/my-telly13.png" class="img-fluid" width="700" /><br><br>
            </div>
            <hr>
            <div id="sulekha">
              <h2>Integrate Sulekha with Cronberry</h2>
              <p>
                This document depicts the procedure of mapping your Sulekha leads data on Cronberry. <br>
                Requirement:<br>
                1. You must have admin account of Sulekha to collect your leads on Cronberry.<br>
                2. You must be a paid user of Sulekha connector on Cronberry.<br>
                Procedure:<br>
                1. Go to Projects > Marketplace.<br>
                2. Click on Sulekha then connector pop-up will be displayed.<br>
                3. Please provide this mentioned URL to your Sulekha provider and request them to post lead data on it. Once they start posting, leads data will be automatically captured in your Cronberry account.<br>
                4. Then your Sulekha leads will get started to capture automatically on your cronberry account.<br>
                </p>
                <img src="assets/images/sulekha-connector.png" class="img-fluid" width="700" alt="">
            </div>
            <hr>
            <div id="postmanCollection">
              <h2>Postman Collection</h2>
              <a href="https://static.cronberry.com/integration_guide/postman_collection.json" download target="_blank">click here</a> to download the postman json collection file
            </div>
          </div>
        </div>
       
      </div>
    </section>
  </section>
</div>
<static-footer></static-footer>