import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AppConstants } from '../../constants/app.constants';
import { PathConstants } from '../../constants/path.constants';
import { AuthenticationService } from '../services/authentication.service';
@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) {
        
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.authenticationService.getUserId() != undefined) {
            // if(this.authenticationService.getUserRole() == AppConstants.MARKETER_ROLEID){
            //     for(let i=0; i<PathConstants.MARKETER_URL.length; i++){
            //         if(state.url.indexOf(PathConstants.MARKETER_URL[i]) >= 0){
            //             return true;
            //         }
            //     }
            //     this.router.navigate([PathConstants.PROJECT_INTEGRATION]);
            //     return false;
            // } else if(this.authenticationService.getUserRole() == AppConstants.TELECALLER_ROLEID) {
            //     for(let i=0; i<PathConstants.TELECALLER_URL.length; i++){
            //         if(state.url.indexOf(PathConstants.TELECALLER_URL[i]) >= 0){
            //             return true;
            //         }
            //     }
            //     this.router.navigate([PathConstants.VIEW_AUDIENCE]);
            //     return false;
            // } else {
            //     return true;
            // }
            return true;
        } else {
            // not logged in so redirect to login page with the return url
            this.router.navigate([PathConstants.SIGN_IN], { queryParams: { returnUrl: state.url } });
            return false;
        }
    }

}
