import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../../core/guards/auth.guard';
import { AuthLayoutComponent } from '../admin/components/auth-layout/auth-layout.component';
import { CustomFormComponent } from './customForm.component';

const routes: Routes = [
    {path:'admin', component: AuthLayoutComponent, canActivate: [AuthGuard], children : [
        { path: 'custom-form', component: CustomFormComponent},
        { path: 'custom-form/:id', component: CustomFormComponent,  },
    ]}
]

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})

export class CustomFormRouting { };
