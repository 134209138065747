
import { Injectable } from '@angular/core';
import { ApiConstants } from '../../constants/api.constants';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class EcommerceService {


    constructor(
        private baseService: BaseService,
    ) { 
    }

    domainStatus(body:any) {
      return this.baseService.postRequest(ApiConstants.DOMAIN_STATUS, body);
    }
    domainCreate(body:any) {
      return this.baseService.postRequest(ApiConstants.ECOMMERCE_CREATE, body);
    }
    domainList(body:any) {
      return this.baseService.postRequest(ApiConstants.ECOMMERCE_FETCH_ALL, body);
    }


}
