import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { SeoService } from '../../../../../../src/app/seo.service';


declare var $: any;
@Component({
  selector: 'intergation',
  templateUrl: './intergration-doc.component.html', 
  styleUrls: ['./intergation.component.scss']
})


export class IntergationDocComponent implements OnInit {
  
  showToggle: boolean = false;
  modalRef: BsModalRef;
  
  constructor(
    private seoService: SeoService
    
    ) {
        this.seoService.setSeoData('API Documentation - Cronberry', 'You can use this API for integrating your website or app so that you can create campaigns with Cronberry.');
    }

  ngOnDestroy() {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }

  ngOnInit() {
    $('[data-toggle="tooltip"]').tooltip();
    if (window.matchMedia('(max-width:525px)').matches)
    {
    $(".dropdown li a").click(function() {
              $(".sidebar").hide();
        });
    }
  }
  openSideMenu(){
    $('.sidebar').toggle();
  }
  goToPosition(item: string) {
    var position = $("#" + item).position();
    window.scrollTo({ left: 0, top:position.top - 70, behavior: 'smooth' }); 
  }


  

}


