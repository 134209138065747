<main>
  <section class="inner-page">
      <div class="inner-page-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-6">
                <h4>
                    <a class="back-link" (click)="back()"><img src="assets/images/angle-left.svg"></a>
                    <span> Missed Recordings</span>
                </h4>
              </div>
              <div class="col-md-6 text-right datePickerCol">
                <input type="text" class="form-control date-icon modal-space datePickInline" readonly autocomplete="off"
                            ngxDaterangepickerMd
                            [(ngModel)]="callAssistLocationRange"
                            [alwaysShowCalendars]="true"
                            [locale]="{format: 'DD/MM/YYYY'}"
                            [ranges]="ranges"
                            [autoApply]="false"
                            [opens]="'left'"
                            [maxDate]="maxDate"
                            placeholder="Select Date Range"
                            (datesUpdated)="missingCallList()">
                            
              </div>
            </div>
          </div>
      </div>


      <div class="inner-page-body site-form">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="call_assist_location_tbl table-reports">
                <div class="table-responsive">
                  <table class="table">
                    <tr>
                      <th>S.No.</th>
                      <th>Name</th>
                      <th>Recording</th>
                      <th>Date & Time</th>
                    </tr>
                    
                  <tr *ngFor="let list of missingCallsLogs | paginate: { itemsPerPage:limit, currentPage:page,totalItems:totalRecords}; let i = index">
                    <td>{{((page - 1)*limit)+i+1}}</td>               
                    <td> {{list.name}}</td>
                    <td *ngIf="list.recordingURL !='' && list.recordingURL !=null" class="missedRecordingTd">
                      <audio controls="controls">
                          <source src={{list.recordingURL}}>
                      </audio>
                      <a class="rcrding_download" href="{{list.recordingURL}}" target="_blank"><span _ngcontent-serverapp-c29="" class="icon-download"></span></a>
                  </td>
                    <td>{{list.created.replace(' ', 'T') | date:'MMM dd, yyyy  HH:mm'}}</td>
                  </tr>
                  <tr *ngIf="missingCallsLogs.length == 0">
                    <td class="no-records" colspan="7">No Records Found</td>
                </tr>
                  </table>
                </div>
                <div class="table-footer footer-gray">
                  <ng-container *ngIf="totalRecords > 20">
                  <pagination-controls (pageChange)="dataForPaging($event)" maxSize="9" directionLinks="true" autoHide="true" class="pull-right" responsive="true" previousLabel="Previous" nextLabel="Next" screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
                      screenReaderCurrentLabel="You're on page">
                  </pagination-controls>
                </ng-container>
                  <div class="textblue text-left">

                    <ng-container *ngIf="totalRecords > 20">
                      <div class="data-length"> Show

                          <select [(ngModel)]="limit" (change)="dataSizeChange()">
                              <option value="20">20</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                          </select>
                      </div>
                    </ng-container>
                      <span>Total Count: {{totalRecords}}</span>
                  </div>
              </div>
              </div>
            </div>
          </div>
        </div>
    </div>
      

    </section>
</main>