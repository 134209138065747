import { Injectable } from '@angular/core';
import { ApiConstants } from '../../../app/constants/api.constants';
import { BaseService } from '../../core/services/base.service';

@Injectable({
	providedIn: 'root'
})
export class TemplateBuilderService {

	rootPath :string;

    constructor(
    	private baseService : BaseService
    ) { 

    }

 

}
