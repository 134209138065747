<main>
  <section class="inner-page">
    <div class="inner-page-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-3">
            <h4>
              <a class="back-link" (click)="back()"><img src="assets/images/angle-left.svg"></a>
              Segments
            </h4>
          </div>
          <div class="col-md-9 text-right right j-end d-flex">
            <input class="btn btn-search" placeholder="Search" type="text" [(ngModel)]="segmentText" autocomplete="off">
            <button tooltip="Create Segment" [tooltipAnimation]="true" tooltipPlacement="top" type="submit" class="btn white_add_btn d-flex c-align" [routerLink]="['/admin/create-segment']">
              <img src="assets/images/plus-icon.png"> &nbsp; Add
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="inner-page-body project-integration">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="table-reports">
              <div class="table-body">
                <div class="table-responsive table-height-scroll text-center">
                  <table class="table">
                    <tbody class="norecord-handling">
                      <tr>
                        <th>S.No.</th>
                        <th>Segment Name</th>
                        <th>Category Name</th>
                        <th>Segment ID</th>
                        <th>Last Modified date</th>
                        <!-- <th>View</th> -->
                        <th>Calculate Audience</th>
                        <th colspan="2">Action</th>
                      </tr>

                      <tr *ngFor="let item of inputParams | myFilter:segmentText let i = index;">
                        <td>{{i+1}}</td>
                        <td>{{item.segmentName}}</td>
                        <td>{{item.catName}}</td>
                        <td>{{item.segmentId}}</td>
                        <td>
                          {{item.lastModifyVal.replace(' ', 'T') | date}}
                        </td>
                        <!-- <td>
                          <a (click)="openModal(item.id,webPushSegment)">
                            <img src="assets/images/segmenteye.png" />
                          </a>
                        </td> -->
                        <td>
                          
                          <a href="javascript:void(0);" (click)="calculateAudience(item);">
                            <img src="assets/images/segmenteye.png" /></a>
                        
                        </td>
                        <td>
                          <a [routerLink]="['/admin/edit-segment', item.id]">
                            <img class="img" src="assets/images/pinkedit.png" />
                          </a>
                          <a class="c-pointer" (click)="openModalDelete(item, confirm)">
                            <img class="img" src="assets/images/delete.png" />
                          </a>
                        </td>
                      </tr>
                      <tr class="d-none">
                        <td class="no-records" colspan="8">No Segments Yet</td>
                      </tr>
                    </tbody>
                  </table>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="inner-page-footer"></div>

  </section>
</main>



<ng-template #webPushSegment>

  <form>
    <div class="forgot-password-modal">
      <div class="modal-header segment_bg">
        <h4 class="modal-title pull-left">Web Push Segment</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true"><img class="img" src="assets/images/times-circle_grey.svg"></span>
        </button>
      </div>
      <div class="web-push-modal-body">

        <div class="row">
            <div class="col-md-6">
                <label class="control-label col-5 label-left grey-pop-color" for="inputDefault">
                  Segment Name:
                </label>
                <label class="control-label col-7 label-right" for="inputDefault">{{this.segmentdata.segmentName}}</label>
              </div>
          <div class="col-md-6">
            <label class="control-label col-5 label-left grey-pop-color" for="inputDefault">
              Last Modified:
            </label>
            <label class="control-label col-7 label-right" for="inputDefault">{{this.segmentdata.lastModifyVal |date :'d MMMM yyyy hh:mm:ss'}}</label>
          </div>
        </div>

        <div class="row">
        
          <div class="col-md-6">
              <label class="control-label col-5 label-left grey-pop-color" for="inputDefault">
                Segment Type:
              </label>
              <label class="control-label col-7 label-right" for="inputDefault">{{this.segmentdata.segmentType}}</label>
            </div>
          <div class="col-md-6">
            <label class="control-label col-5 label-left grey-pop-color" for="inputDefault">
              Created:
            </label>
            <label class="control-label col-7 label-right" for="inputDefault">{{this.segmentdata.createdVal |date :'d MMMM yyyy hh:mm:ss'}}</label>
          </div>
        </div>
      </div>



          <div *ngFor="let item of source let i = index;" class="">
            <div class="modal-header segment_bg">
              <h4 class="modal-title pull-left">Segment Group {{i+1}}</h4>
            </div>
    
            <div class="web-push-modal-body">
              <div class="row pt-2 pb-2">
                <div class="col-md-4">
                  <div class="row pl-15">
                    <label class="control-label col-5 label-left f-15" for="inputDefault">
                      Event :
                    </label>
                    <label class="control-label col-7 label-right f-15" for="inputDefault">{{item.eventName!=undefiend?item.eventName:'All Events'}}</label>
                  </div>
                </div>
    
                <div class="col-md-4">
                  <div class="row pl-15">
                    <label class="control-label col-5 label-left f-15" for="inputDefault">
                      Last Active:
                    </label>
                    <label class="control-label col-7 label-right f-15 pr-0" for="inputDefault">{{item.lastActive!=''?item.lastActive:'From Beginning'}}</label>
                  </div>
                </div>
    
                <div class="col-md-4">
                    <div class="row pl-15">
                        <label class="control-label col-5 label-left f-15" for="inputDefault">
                          Source:
                        </label>
                        <label class="control-label col-7 label-right f-15 pr-0" for="inputDefault">{{item.source!=''?item.source:'All Sources'}}</label>
                    </div>
                </div>
              </div>
              <div class="row">
                <ng-container *ngFor="let segment of item.segmentFilters; let j = index;">
                    <div class="col-md-4 border-grey-right">
                    <div class="row pl-15">
                    <label class="control-label col-5 label-left grey-pop-color" for="inputDefault">
                      Filter {{j+1}}:
                    </label>
                    <label class="control-label col-7 label-right" for="inputDefault">{{segment.projectInputParamName}}</label>
                  </div>
                  <div class="row pl-15">
                    <label class="control-label col-5 label-left grey-pop-color" for="inputDefault">
                      Values:
                    </label>
                    <label class="control-label col-7 label-right" for="inputDefault">{{segment.paramValues}}</label>
                  </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
    </div>
  </form>

</ng-template>


<ng-template #confirm>
    <form>
      <div class="forgot-password-modal">
        <div class="modal-header">
          <h4 class="modal-title pull-left">Delete Segment</h4>
          <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body modal-user-management">
          <div class="form-group mb-0">
            <p class="pt-2">
              By deleting this segment, specific values will be cleared out from this field. Are you sure, you want to delete?
            </p>
            <div class="d-flex justify-content-end mt-5">
              <div>
                <button class="btn remove-user-btn mr-4" (click)="modalRef.hide()" type="button">
                  Dismiss
                </button>
              </div>
              <div>
                <button class="btn save-btn" type="button" (click)="deleteSegment()">
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </ng-template>
  
  <ng-template #confirmCalculateAudience>
    <form>
      <div class="forgot-password-modal">
        <div class="modal-header">
          <h4 class="modal-title pull-left">Calculate Audience</h4>
          <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body modal-user-management">
          <div class="form-group mb-0">
            <p class="pt-2">
              Are you sure, you want to calculate audience?
            </p>
            <div class="d-flex justify-content-end mt-5">
              <div>
                <button class="btn remove-user-btn mr-4" (click)="modalRef.hide()" type="button">
                  Dismiss
                </button>
              </div>
              <div>
                <button class="btn save-btn" type="button" (click)="calculateAudience()">
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </ng-template>