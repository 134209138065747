import { NgModule } from "@angular/core";
import { ModalModule } from "ngx-bootstrap";
import { ColorPickerModule } from "ngx-color-picker";
import { DndModule } from "ngx-drag-drop";
import { ShareModule } from "../../shared/models/share.module";
import { BaseService } from "../../core/services/base.service";
import { TemplateBuilderComponent } from "./templateBuilder.component";
import { TemplateBuilderRouting } from "./templateBuilder.routing";
import { TemplateBuilderService } from "./templateBuilder.service";
import { EmailEditorModule } from "angular-email-editor";


@NgModule({
    imports: [
        TemplateBuilderRouting,
        DndModule,
        ColorPickerModule,
        ModalModule.forRoot(),
        ShareModule,
        EmailEditorModule
    ],
    declarations: [
        TemplateBuilderComponent
    ],
    exports:[
    ],
    providers: [
        BaseService,
        TemplateBuilderService
    ]
    
})
export class TemplateBuilderModule {

}