<main>
  <section class="inner-page">
      <div class="inner-page-header">
          <div class="container-fluid">
              <div class="row">
                  <div class="col-md-6">
                      <h4>
                          <a class="back-link" (click)="back()"><img src="assets/images/angle-left.svg"></a>
                          Turn Around Time
                      </h4>
                  </div>
                  <div class="col-md-6 text-right j-end d-flex" >
                      <button class="btn white_add_btn d-flex c-align" (click)="addTat()" tooltip="Add Lead Status" [tooltipAnimation]="true" tooltipPlacement="top">
                          <img src="assets/images/plus-icon.png"> &nbsp; Add
                      </button>
                  </div>
              </div>
          </div>
      </div>

      <div class="inner-page-body site-form">
          <div class="container-fluid">
              <div class="table-responsive table-height-scroll table-reports">
                  <table class="table callAssistDashboardTable">
                        <thead class="sticky-header" data-sticky-header="true">
                          <tr class="callAssistDbRowTop">
                              <th width="20" class="borderTh">S.No.</th>
                              <th width="200" class="borderTh">Name</th>
                              <th class="borderTh">TAT Type</th>
                              <th class="borderTh">Category</th>
                              <th class="borderTh">TAT Field</th>
                              <th class="borderTh">Created Date</th>
                              <th class="borderTh">Status</th>
                              <th class="borderTh">Duration</th>
                              <th class="text-center borderTh">Action</th>
                          </tr>
                        </thead>
                        <tbody class="norecord-handling">
                          <tr class="callAssistDbRowBottom" *ngFor="let tatList of tatListData;let i=index;">
                            <td>{{i+1}}</td>
                            <td>{{tatList.name}}</td>
                            <td>{{tatList.type}}</td>
                            <td>{{tatList.category}}</td>
                            <td>{{tatList.tatField}}</td>
                            <td>{{tatList.createDate}}</td>
                            <td>{{tatList.status}}</td>
                            <td>{{tatList.tatDuration}}</td>
                            <td class="actionIconTatTable">
                              <span (click)="editTat(tatList)">
                                <img src="assets/images/pencil.svg">
                              </span>
                              <span (click)="deleteAction(tatList)">
                                <img src="assets/images/trash-alt-gray.svg">
                              </span>
                            </td>
                          </tr>
                      </tbody>
                  </table>
              </div>
          </div>
      </div>
  </section>
</main>


<ng-template #confirmModal>
  <form>
      <div class="forgot-password-modal">
          <div class="forgot-password-modal">
            <div class="modal-header">
                <h4 class="modal-title pull-left">
                    Are you sure want to delete ?
                </h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body modal_height_apply">
                <div class="innerConfrm text-center">
                    <button class="btn btn-dark" (click)="deleteTat()">Yes</button>
                    <button class="btn btn-dark red" (click)="modalRef.hide()">No</button>
                </div>
            </div>
          </div>
      </div>
  </form>
</ng-template>

<ng-template #addTatModal>
  <form #addTatForm="ngForm">
      <div class="forgot-password-modal">
          <div class="modal-header">
              <h4 class="modal-title pull-left">Add TAT</h4>
              <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
          </div>
          <div class="modal-body">
                <div class="mb-4">
                  <div class="form-group">
                      <div class="floatingLabel">
                         <div class="innerFloating">
                          <label class="control-label">Name</label>
                             <div class="textBox">
                              <input trim="blur" class="form-control" type="text" name="name" [(ngModel)]="tatFields.name" maxlength="50">
                             </div>
                         </div>
                     </div>
                 </div>
              </div>

              <div class="mb-4">
                <div class="form-group">
                    <div class="floatingLabel">
                       <div class="innerFloating">
                        <label class="control-label">TAT Field</label>
                           <div class="textBox">
                            <select class="form-control" name="paramKey" [(ngModel)]="tatFields.paramKey">
                              <option value="">Select Field</option>
                              <ng-container *ngFor="let optionList of paramKeylist">
                                <ng-container *ngIf="optionList.paramDatatype == 'date' || optionList.paramDatatype == 'Date'">
                                  <option [value]="optionList.paramKey">{{optionList.paramName}}</option>
                                </ng-container>
                              </ng-container>
                            </select>
                           </div>
                       </div>
                   </div>
               </div>
            </div>

            <div class="mb-4">
              <div class="form-group">
                  <div class="floatingLabel">
                     <div class="innerFloating">
                      <label class="control-label">TAT Time</label>
                         <div class="textBox">
                          <input trim="blur" class="form-control" type="text" name="tatDuration" [(ngModel)]="tatFields.tatDuration" maxlength="50">
                         </div>
                     </div>
                 </div>
             </div>
          </div>

          <div class="mb-4">
            <div class="form-group">
                <div class="floatingLabel">
                   <div class="innerFloating">
                    <label class="control-label">TAT Type</label>
                       <div class="textBox">
                        <select class="form-control" name="type" [(ngModel)]="tatFields.type" (change)="getTatType($event)">
                          <option>Select type</option>
                          <option value="EMAIL">Email</option>
                          <option value="ANDROID">Android</option>
                          <option value="IOS">IOS</option>
                          <option value="WEB">Web</option>
                          <option value="REASSIGN">Reassign</option>
                        </select>
                       </div>
                   </div>
               </div>
           </div>
        </div>


      <div class="mb-4" *ngIf="reassignActivate">
        <div class="form-group">
            <div class="floatingLabel">
               <div class="innerFloating">
                <label class="control-label">Users</label>
                   <div class="selectBoxTAtField">
                    <ng-select [items]="allUserFromCategory" class="params w-100" bindLabel="userName" [multiple]="true" bindValue="id" name="userIds" [(ngModel)]="tatFields.userIds"> </ng-select>
                   </div>
               </div>
           </div>
       </div>
    </div>

    <div class="mb-4" *ngIf="reassignActivate == false">
      <div class="form-group">
          <div class="floatingLabel">
             <div class="innerFloating">
              <label class="control-label">Roles</label>
                 <div class="selectBoxTAtField">
                  <ng-select [items]="roleListClone" class="params w-100" bindLabel="name" [multiple]="true" bindValue="id" name="roleId" [(ngModel)]="tatFields.roleId"> </ng-select>
                 </div>
             </div>
         </div>
     </div>
  </div>


    <div class="mb-3 text-right">
      <div class="form-group">
          <button type="button" class="btn btn-dark" (click)="saveTat(addTatForm.value)">Next</button>
     </div>
  </div>




          </div>
      </div>
  </form>
</ng-template>


<div *ngIf="showAlertMessage" class="info-outer-box">
  <div class="inner-box">
      <div class="bg-color">
          <h6>{{alertTitle}}</h6>
          <p>{{alertMessage}}</p>
          <div class="footer text-center">
              <a *ngIf="userRoleId == '2'" [routerLink]="['/admin/dashboard']" class="btn btn-dark mr-3">OK</a>
              <a *ngIf="userRoleId != '2'" [routerLink]="['/admin/configuration']" class="btn btn-dark mr-3">OK</a>
              <a href="javascript:;" (click)="closeAlert();" class="btn btn-dark">Cancel</a>
          </div>
      </div>
  </div>
</div>

<app-channel-type (afterProcess)="channelProcess($event)" [proceedEvent]="campaignProceed" [isIndividualCampaign]="true" [reloadTemplates]="reloadTemps" [url]="apiUrl" [tatData]="tatData" [campaignType]="campaignSubmit"></app-channel-type>
