import { Component, OnInit, OnDestroy, TemplateRef, ChangeDetectorRef, AfterViewInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { Global } from '../../../../../app/shared/models/global.model';
import { CampaignService } from "../../../../core/services/campaign.service";
import { GalleryPopupComponent } from './gallery-popup.component';
import { Location } from '@angular/common';

@Component({
    selector: 'campaign-gallery',
    templateUrl: './campaign-gallery.component.html',
    styleUrls: ['./campaign-gallery.component.scss']
})

export class CampaignGalleryComponent implements OnInit, AfterViewInit, OnDestroy {
  
    modalRef: BsModalRef;
    modalRefGallery: BsModalRef;
    mediaList:any = [];
    public pageNo: number;
    public dataSize: number;
    public totalDataSize: number;
    itemId: number;
    itemIndex: number;
    fileType: string;
    searchText: string;
    isSubscribe: boolean = true;
    

    constructor(
        private modalService: BsModalService,
        private campaignService: CampaignService,
        private toastr: ToastrService,
        private ChangeDetector : ChangeDetectorRef,
        private global: Global,
        private authenticationService: AuthenticationService,
        private location:Location
    ) { 
        this.isSubscribe = this.authenticationService.isPageSubscribe('Asset Library');
    }

    ngOnInit() {
        this.pageNo = 1;
        this.dataSize = 20;
        if(this.isSubscribe)
        this.getMediaList();
        this.fileType = '';
    }

    back()
    {
        this.location.back();
    }

    ngAfterViewInit() {
        this.ChangeDetector.detectChanges();
    }

    ngOnDestroy(){
    
    }

    updateList(){
        this.pageNo = 1;
        this.getMediaList(); 
    }


    getMediaList(){
        this.campaignService.imageFileList({'mediaName':this.searchText,'fileType':this.fileType}, this.dataSize, this.pageNo-1).subscribe(data => {
            if(data.status){
                this.mediaList = [];
                this.totalDataSize = data.data.size;
                data.data.mediaGallary.forEach((element, index) => {
                    element.sNo = index+1+(this.dataSize*(this.pageNo-1));
                    if(element.extention == 'pdf'){
                        element.thumbnailFile = '.../../../../../assets/images/file-pdf.svg'
                    } else if(element.extention == 'txt'){
                        element.thumbnailFile = '.../../../../../assets/images/file-edit.svg'
                    } else if(element.extention == 'zip'){
                        element.thumbnailFile = '.../../../../../assets/images/file-archive.svg'
                    } else if(element.extention == 'xlsx' || element.extention == 'xls'){
                        element.thumbnailFile = '.../../../../../assets/images/file-spreadsheet.svg'
                    } else if(element.extention == 'doc' || element.extention == 'docx'){
                        element.thumbnailFile = '.../../../../../assets/images/file-word.svg'
                    } else if(element.extention == 'ppt' || element.extention == 'pptx'){
                        element.thumbnailFile = '.../../../../../assets/images/file-powerpoint.svg'
                    }
                    this.mediaList.push(element);
                });
            }
        });
    }
    public dataForPaging(event) {
        this.pageNo = event;
        this.getMediaList();
    }

    showGallery(){
        const initialState = {
            galleryType : 'addMedia',
            modalRefGallery : this.modalRefGallery
        }
        this.modalRefGallery = this.modalService.show(GalleryPopupComponent,{
            initialState,
            backdrop: "static",
            class: "gallery-popup"
        });
        this.modalRefGallery.content.modalRefGallery = this.modalRefGallery;
        this.modalRefGallery.content.selectMediaEvent.subscribe(data => {
            this.updateList();
        });
    }

    openModalDelete(template: TemplateRef<any>, id: number, index: number) {
        if(id != undefined){
          this.itemId = id;
          this.itemIndex = index;
        }
        this.modalRef = this.modalService.show(template, { backdrop: 'static' }); 
    }

    deleteItem(){
        this.campaignService.deleteImageFile({id: this.itemId}).subscribe( data => {
            this.modalRef.hide();
            if (data.status) {
                this.toastr.success('Media Deleted Successfully');
                this.mediaList.splice(this.itemIndex, 1);
                this.getMediaList();
            } else {
              this.toastr.error(data.error_msgs, 'Error');
            }
        });
    }


    copyText(val: string){
        this.global.copyText(encodeURI(val));
        this.toastr.success('Copied to clipboard.');
    }
  
}