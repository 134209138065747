import { Component, ElementRef, Input, OnDestroy, OnInit, Output, ViewChild, EventEmitter } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap";
import { ToastrService } from "ngx-toastr";
import { AttributeObj } from "../../../../core/modal/project";
import { ProjectService } from "../../../../core/services/project.service";
import { Global } from "../../../../shared/models/global.model";
import { AuthenticationService } from "../../../../core/services/authentication.service";

@Component({
    selector: 'app-add-audience',
    templateUrl: './add-audience.component.html',
    styleUrls: ['../audience-profile/audience-profile.component.scss']
})

export class AddAudienceComponent implements OnInit {
    
    @Input() modalRef: BsModalRef;
    @Input() category:string;
    @Output() recordSubmit : EventEmitter<string> = new EventEmitter();
    @Output() leadSearchString : EventEmitter<string> = new EventEmitter();
    audienceDynamicDataList: any = [];
    attributesList: AttributeObj[] = [];
    isAttributesListShow: boolean = false;
    isSubmit: boolean = false;
    audienceParem: any;
    fixedParams:any;
    searchAttribute:any;
    dataList:any;
    categorySelector:any;
    categoryFromParent:boolean=false;
    attributesListStore:any;
    todayDate:any = new Date();
    @ViewChild('scrollblockLeft') scrollblockLeft: ElementRef;
    @ViewChild('scrollblockRight') scrollblockRight: ElementRef;
    constructor(
        private projectService: ProjectService,
        public global: Global,
        private toastr:ToastrService,
        private authenticService:AuthenticationService
    ){

    }

    ngOnInit() {

        this.fetchCategory();
        this.getAttributeList();
        this.getProjectDetails();

        this.audienceParem = {
            projectKey:'',
            audienceId:'',
            name:'',
            email:'',
            mobile:'',
            paramList:[]
        }
    }

    fetchCategory() {        
        this.dataList = [];
        this.projectService.fetchCategory({}).then(data => {
            if (data) {
                const dataList = data
                let id = this.authenticService.getUserId();
                if(this.authenticService.getUserRole() != 1){
                for(let i=0; i<dataList.length; i++){
                    if(dataList[i]){
                    let getUserList = dataList[i].userList.find(userid=>userid == id);
                    if(getUserList){
                        let checkToPush = this.dataList.find(x=>x.linkRewrite == dataList[i].linkRewrite);
                        if(checkToPush == undefined){
                            this.dataList.push(dataList[i])
                        }
                    }
                }
                }
            } else {
                this.dataList = dataList
              }

            console.log(this.dataList)
                let checkForAudCat = this.dataList.find(x=>x.linkRewrite == 'audience');
                    if(checkForAudCat){
                        this.categorySelector='audience';
                    } else{
                        this.categorySelector=this.dataList[0].linkRewrite;
                    }

                if(this.categoryFromParent == true){
                    this.category = this.categorySelector;
                }
                this.getStoredParams();                
            }
        })
    }


    getStoredParams(){

        var otherParemAdded= JSON.parse(localStorage.getItem('otherParemAdded'+this.category));
        if(otherParemAdded != null)
        {
        let filterStoreByCat = []
        let systemDefineAttr = []
        var list = this.projectService.filterAttributeList();
        otherParemAdded.forEach(element => {
            element.paramValue = "";
            let findAttrInList = list.find(x=>x.paramKey == element.paramKey)
            if(findAttrInList){
            if(findAttrInList.paramOwner == 0 && findAttrInList.pluginName != 'static'){
                if(findAttrInList.category){
                  let catToArr = findAttrInList.category.split(',');
                  catToArr = catToArr.filter(p=>{
                      return p != null && p != "";
                  });
                    let checkByCat = catToArr.find(x=>x == this.category);
                    if(checkByCat){                                                      
                        filterStoreByCat.push(element);
                    }   
                  }
              }
              if(findAttrInList.paramOwner == 1 && findAttrInList.pluginName != 'static'){
                systemDefineAttr.push(element)
              }

              if(findAttrInList.pluginName == 'static'){
                systemDefineAttr.push(element);
            }
        }


            
        });


        if(filterStoreByCat.length > 0){
            filterStoreByCat.forEach(elem=>{
                this.audienceParem.paramList.push(elem);
            })
            systemDefineAttr.forEach(elem=>{
                this.audienceParem.paramList.push(elem);
            })
          } 
          if(filterStoreByCat.length == 0){
              systemDefineAttr.forEach(elem=>{
                this.audienceParem.paramList.push(elem)
              })
          }

        }
    }


    getProjectDetails() {
        this.projectService.getUserProjectDetails().then( data => {
            if (data != undefined) {
                this.audienceParem.projectKey = data.projectKey;
            }
        });
    }

    updateCategory(){
                this.attributesList = this.attributesListStore;
                this.category = this.categorySelector;
                let filterStoreByCat = [];
                let systemDefineAttr = [];
                this.attributesList.forEach(elem=>{
                    if(elem.paramOwner == 0 && elem.pluginName != 'static'){
                        if(elem.category){
                          let catToArr = elem.category.split(',');
                          catToArr = catToArr.filter(p=>{
                              return p != null && p != "";
                          });
                            let checkByCat = catToArr.find(x=>x == this.category);
                            if(checkByCat){                              
                                filterStoreByCat.push(elem);
                            }   
                          }
                      }
                      if(elem.paramOwner == 1 && elem.pluginName != 'static'){
                        systemDefineAttr.push(elem)
                      }

                      if(elem.pluginName == 'static'){
                        systemDefineAttr.push(elem);
                    }
                });

                if(filterStoreByCat.length > 0){
                    this.attributesList = filterStoreByCat;
                    systemDefineAttr.forEach(elem=>{
                      this.attributesList.push(elem)
                    })
                  } 
                  if(filterStoreByCat.length == 0){
                    this.attributesList = []
                      systemDefineAttr.forEach(elem=>{
                        this.attributesList.push(elem)
                      })
                  }
                  this.attributesList.sort(function(a, b){
                    if(a.paramName < b.paramName) { return -1; }
                    if(a.paramName > b.paramName) { return 1; }
                    return 0;
                });
    }

    getAttributeList() {
        this.projectService.fetchAttributeList().then(data => {
            if(data){
                var list = this.projectService.filterAttributeList();
                 this.fixedParams = this.projectService.filterAttributeList();
                if(this.fixedParams)
                {
                this.fixedParams.forEach(elems=>{
                    if(elems.pluginName == 'static'){
                    if(elems.paramKey == "android_fcm_token" || elems.paramKey == 'ios_fcm_token' || elems.paramKey == 'web_fcm_token'){
                        this.attributesList.push(elems);
                    }
                    }
                })
                
                }
                list.forEach(element => {
                    if(element.paramEditable === 'ACTIVE' && element.pluginName != "static" && element.paramDatatype != 'File' && element.paramKey != 'email' && element.paramKey != 'name' && element.paramKey != 'mobile'){                 
                    this.attributesList.push(element);
                    }
                }); 
                this.attributesListStore = this.attributesList
                let filterStoreByCat = [];
                let systemDefineAttr = [];
                this.attributesList.forEach(elem=>{
                    if(elem.paramOwner == 0 && elem.pluginName != 'static'){
                        if(elem.category){
                          let catToArr = elem.category.split(',');
                          catToArr = catToArr.filter(p=>{
                              return p != null && p != "";
                          });
                            let checkByCat = catToArr.find(x=>x == this.category);
                            if(checkByCat){                              
                                filterStoreByCat.push(elem);
                            }   
                          }
                      }
                      if(elem.paramOwner == 1 && elem.pluginName != 'static'){
                        systemDefineAttr.push(elem)
                      }

                      if(elem.pluginName == 'static'){
                        systemDefineAttr.push(elem);
                    }
                });

                if(filterStoreByCat.length > 0){
                    this.attributesList = filterStoreByCat;
                    systemDefineAttr.forEach(elem=>{
                      this.attributesList.push(elem)
                    })
                  } 
                  if(filterStoreByCat.length == 0){
                    this.attributesList = []
                      systemDefineAttr.forEach(elem=>{
                        this.attributesList.push(elem)
                      })
                  }
                                
                this.attributesList.sort(function(a, b){
                    if(a.paramName < b.paramName) { return -1; }
                    if(a.paramName > b.paramName) { return 1; }
                    return 0;
                });
                this.audienceParem.paramList.forEach(element => {
                    this.attributesList.splice(element.index,1);
                });
                
                              
            }
        });
    }

    private scrollToBottom(): void {
        var scrollblock ;
        if(this.attributesList.length % 2 == 1)
            scrollblock = this.scrollblockLeft.nativeElement;
        else
            scrollblock = this.scrollblockRight.nativeElement;

        scrollblock.scroll({
            top: scrollblock.scrollHeight,
            left: 0,
            behavior: 'smooth'
        });
    }

    appendParameter(param, index){                
        param.paramValue = '';
        this.isAttributesListShow = false;
        this.audienceParem.paramList.push({
            paramKey:param.paramKey,
            paramValue:'',
            paramMandatory:param.paramMandatory,
            paramName:param.paramName,
            paramDatatype:param.paramDatatype,
            index:index,
            id:param.id,
            paramVisible:param.paramVisible,
            backDataEditable:param.backDataEditable,
            paramDefaultValueDTO:param.paramDefaultValueDTO ? param.paramDefaultValueDTO : undefined
        });
        
        localStorage.setItem('otherParemAdded'+this.category,JSON.stringify(this.audienceParem.paramList));
        this.attributesList.forEach(elems=>{
            if(elems.paramKey == param.paramKey)
            {   let indexOfElem = this.attributesList.indexOf(elems);
                this.attributesList.splice(indexOfElem,1);
            }
        })
        param.editParam = true;
        setTimeout(() => {
            this.scrollToBottom();
        }, 200);
    }
    removeAudience(index,data){        
        this.audienceParem.paramList.splice(index,1);
        this.attributesList.push(data);
        this.attributesList.sort(function(a, b){
            if(a.paramName < b.paramName) { return -1; }
            if(a.paramName > b.paramName) { return 1; }
            return 0;
        });
        localStorage.setItem('otherParemAdded',JSON.stringify(this.audienceParem.paramList));
    }

    numSymbol(evt)
    {
        var charCode = (evt.which) ? evt.which : evt.keyCode;    
        if (charCode != 43 && charCode != 45 && (charCode < 48 || charCode > 57))
            return false;
        return true;
    }

    submitData(){                
        this.isSubmit = true;
        var dataValid = true;
        let phoneRegex = /^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$/;
        if(this.audienceParem.email == '' && this.audienceParem.mobile == ''){
            this.toastr.error('Email or Phone is required','Error');
            return;
        }
        if(this.audienceParem.email != '' && this.audienceParem.email.match(this.global.EmailPattern) == null){
            this.toastr.error('Enter valid email','Error');
            return;
        }
        if(this.audienceParem.mobile != '' && phoneRegex.test(this.audienceParem.mobile) == false){
            this.toastr.error('Enter valid Phone','Error');
            return;
        }
        if(this.audienceParem.paramList){
        this.audienceParem.paramList.forEach(element => {
            if(element.paramMandatory == 'ACTIVE'){
                if(element.paramValue == ''){
                    dataValid = false;
                }
            }
            if(element.paramValue.length > 0){
                element.paramValue = element.paramValue.toString();
            }
            if(element.paramDatatype == 'Email' && element.paramValue != ""){
                let regex = /^[A-Za-z0-9]+[A-Za-z0-9._!#$%&'*+-/=?^_`{|}~]+@([A-Za-z0-9-]+[.]){1,2}[A-Za-z.]{2,10}$/;
            if(regex.test(element.paramValue) == false){
                this.toastr.error("Please enter valid email");
                this.isSubmit = false;
                return;
            }
            }

            if(element.paramDatatype == 'Mobile' && element.paramValue != ""){
                if(phoneRegex.test(element.paramValue) == false){
                    this.toastr.error("Please enter valid phone number");
                    this.isSubmit = false;
                    return;
                }
            }
        });
        }      
        if(!dataValid){
            this.toastr.error('Field is required','Error');
            return;    
        }
        if(!this.isSubmit){
            return
        }
        this.audienceParem.audienceId = new Date().getTime();
        
        this.projectService.registerAudienceData(this.category,this.audienceParem).subscribe(data => {
            if(data.status){
                this.modalRef.hide();
                if(data.data.indexOf("repeat") != -1){
                this.toastr.info(data.data,'Success');
                }else{
                    this.toastr.success(data.data,'Success');
                }
                setTimeout(() => {
                    this.recordSubmit.emit('true');
                    if(this.audienceParem.email){
                    this.leadSearchString.emit(this.audienceParem.email);
                    } else if(this.audienceParem.mobile){
                        this.leadSearchString.emit(this.audienceParem.mobile);
                    }
                  }, 1000);
                   
              
            } else {
                this.toastr.error(data.error_msgs,'Error');
            }
        });
    }


}