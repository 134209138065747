import { Component, OnInit, HostListener } from '@angular/core';
import { Global } from '../../../../shared/models/global.model';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { ProjectService } from '../../../../core/services/project.service';


@Component({
    selector: 'advance-popup-page',
    templateUrl: './advance-popup.component.html',
    styleUrls: ['./announcement.component.scss','../../components/auth-layout/auth-layout.component.scss']
})

export class AdvancePopupComponent implements OnInit {
    email: any = '';
    isVisible:boolean = true;
    ls: any = {};
    domain:any;
    private isInited;
    pageUnderProcess: boolean = true;
    isSubscribe: boolean = true;
    constructor (
        public global: Global,
        private sanitizer:DomSanitizer,
        private router: Router,
        private authenticationService: AuthenticationService,
        private projectService:ProjectService
    ) {
        this.ls = localStorage;
        this.isSubscribe = this.authenticationService.isPageSubscribe('Advance Popup Notification');
    }

    @HostListener('window:message', ['$event'])
    onMessage(event) {
        if (typeof event.data === 'string' && event.data == "refresh"){
            this.router.navigate(['admin/dashboard']);
        }
        if (typeof event.data === 'string' && event.data == "hide"){
            this.pageUnderProcess = false;
        }
    }

    ngOnInit() {
        var now = new Date();
        var time = now.getTime();
        time += 3600 * 1000;
        now.setTime(time);
        document.cookie = "u="+encodeURIComponent(this.authenticationService.getUserId())+"; expires=" + now.toUTCString() +"; domain=.cronberry.com; path=/; SameSite=Strict";
        document.cookie = "p="+encodeURIComponent(this.authenticationService.getProjectId())+"; expires=" + now.toUTCString() +"; domain=.cronberry.com; path=/; SameSite=Strict";
        document.cookie = "k="+encodeURIComponent(this.projectService.projectDetails.projectKey)+"; expires=" + now.toUTCString() +"; domain=.cronberry.com; path=/; SameSite=Strict";
        document.cookie = "a="+encodeURIComponent(this.authenticationService.getAuthToken())+"; expires=" + now.toUTCString() +"; domain=.cronberry.com; path=/; SameSite=Strict";

        this.domain = this.sanitizer.bypassSecurityTrustResourceUrl(environment.popupPageURL);
    }

    ngAfterViewInit() {
        this.isInited = true;
    }
 
    loadIframe(iframeTagName) {
        if (this.isInited) {
            // this.iframeTag = iframeTagName;    
            let message = {"email":'popup@cronberry.com',key:'1234abcd'};
            iframeTagName.contentWindow.postMessage(message , '*');
        }
    }
    
    transform(url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

   
}
