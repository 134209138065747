<main *ngIf="currentUser != undefined">
  <header class="page-header height_40" *ngIf="currentUser.id == undefined">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-5 col-9"></div>
        <div class="col-sm-7 col-3">
          <div class="profile-menu">
            <nav class="navbar navbar-default text-right">
              <div class="collapse navbar-collapse in show display-block">
                <ul class="nav navbar-nav navbar-right pr-0">
                    <li>
                      <a class="" (click)="signout()">Sign Out</a> 
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </header>  
  <section class="inner-page">
    <div class="inner-page-header">
      <div class="container-fluid">
          <h4>{{currentUser.id != undefined ? 'Edit':'Create'}} Account</h4>
          <p *ngIf="currentUser.users.userRoleId==1">Your personal and business information.</p>
          <p *ngIf="currentUser.users.userRoleId==2">Your personal information.</p>
      </div>
    </div>
    
    

    <div class="inner-page-body user-role">
      <form [formGroup]="editForm" (ngSubmit)="editUser()">

        <div class="container-fluid">

          <div class="row">
            <div class="col-12 col-sm-12 col-md-6 mb-3">
              <div class="form-group row">
                <label class="col-md-4 control-label required" for="inputDefault">First Name</label>
                <div class="col-md-8">
                  <input trim="blur" type="text" class="form-control" formControlName="firstName" id="inputDefault"
                    minlength="1" maxlength="74">
                </div>
              </div>
            </div>

            <div class="col-12 col-sm-12 col-md-6 mb-3">
              <div class="form-group row">
                <label class="col-md-4 control-label required" for="inputDefault">Last Name</label>
                <div class="col-md-8">
                  <input trim="blur" type="text" class="form-control" formControlName="lastName" id="inputDefault"
                    minlength="1" maxlength="74">
                </div>
              </div>
            </div>

            <div class="col-12 col-sm-12 col-md-6 mb-3">
              <div class="form-group row">
                <label class="col-md-4 control-label required" for="inputDefault">Gender</label>
                <div class="col-md-8">
                  <select class="form-control" formControlName="gender">
                    <option value="null">Select Gender</option>
                    <option value="1">Male</option>
                    <option value="0">Female</option>
                    <option value="2">Transgender</option>
                    <option value="3">Prefer not to say</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="col-12 col-sm-12 col-md-6 mb-3">
              <div class="form-group row">
                <label class="col-md-4 control-label required" for="inputDefault">Email ID</label>
                <div class="col-md-8">
                  <div class="input-group">
                    <input trim="blur" type="email" formControlName="email" [readonly]="!emailEdit" class="form-control"
                      aria-label="email" aria-describedby="basic-addon2">
                    <div class="input-group-append">
                      <button class="btn btn-input-edit verified-btn" *ngIf="currentUser.EmailStatus=='VERIFIED'" type="button">Verified</button>
                      <button class="btn btn-input-edit" *ngIf="currentUser.EmailStatus!='VERIFIED'" (click)="otp = '';  resendEmail('open');"
                        type="button">Verify</button>
                    </div>
                  </div>
                  <a class="verify text-left" *ngIf="currentUser.EmailStatus!='VERIFIED'">Please Verify your email
                    id</a>
                  <a class="verify pull-right color-blue" (click)='enableEmail()'>Edit</a>
                </div>
              </div>
            </div>

            <div class="col-12 col-sm-12 col-md-6">
              <div class="form-group row">
                <label class="col-md-4 control-label required" for="inputDefault">Phone/Mobile</label>
                <div class="col-md-8">
                  <input trim="blur" type="text" class="form-control" formControlName="phone" id="inputDefault"
                    minlength="7" maxlength="10">
                </div>
              </div>
            </div>


            <div class="col-12 col-sm-12 col-md-6 mb-3">
              <div class="form-group row">
                <label class="col-md-4 control-label required" for="inputDefault">Reports will Sort By</label>
                <div class="col-md-8">
                  <select class="form-control" formControlName="sortBy">
                    <option  value="params.entry_date">Lead Entry Date</option>
                    <option  selected value="updated">Lead Update Date</option>
                  </select>
                </div>
              </div>
            </div>


          </div>
          <hr>

          <div class="row">
            <ng-container *ngIf="currentUser.users.userRoleId==1">
              <div class="col-md-12">
                <h4>Business Information</h4>
              </div>

              <div class="col-12 col-sm-12 col-md-6">
                <div class="form-group row">
                  <label class="col-md-4 control-label required" for="inputDefault">Company Name</label>
                  <div class="col-md-8">
                    <input trim="blur" type="text" class="form-control" formControlName="companyName" id="inputDefault"
                      minlength="5" maxlength="50">
                  </div>
                </div>
              </div>

              <div class="col-12 col-sm-12 col-md-6">
                <div class="form-group row">
                  <label class="col-md-4 control-label" for="inputDefault">GSTIN/TAX ID</label>
                  <div class="col-md-8">
                    <input trim="blur" type="text" class="form-control" formControlName="companyGstno" id="inputDefault"
                      maxlength="50" (input)="gstPressing($event)">
                  </div>
                </div>
              </div>

              <div class="col-12 col-sm-12 col-md-12 mb-3">
                <div class="form-group row address-width">
                  <label class="col-md-2 control-label required" for="inputDefault">Address</label>
                  <div class="col-md-10">
                    <input trim="blur" type="text" class="form-control" formControlName="companyAddress" id="inputDefault"
                      maxlength="100">
                  </div>
                </div>
              </div>

              <div class="col-12 col-sm-12 col-md-6 mb-3">
                <div class="form-group row">
                  <label class="col-md-4 control-label" for="inputDefault">Pincode</label>
                  <div class="col-md-8">
                    <input trim="blur" type="text" class="form-control" formControlName="pincode" id="inputDefault"
                      maxlength="10">
                  </div>
                </div>
              </div>

              <div class="col-12 col-sm-12 col-md-6 mb-3">
                <div class="form-group row">
                  <label class="col-md-4 control-label required" for="inputDefault">City</label>
                  <div class="col-md-8">
                    <input trim="blur" type="text" class="form-control" formControlName="city" id="inputDefault"
                      maxlength="20">
                  </div>
                </div>
              </div>

              <div class="col-12 col-sm-12 col-md-6 mb-3">
                <div class="form-group row">
                  <label class="col-md-4 control-label required" for="inputDefault">State/Province</label>
                  <div class="col-md-8">
                    <input trim="blur" type="text" class="form-control" formControlName="state" id="inputDefault"
                      maxlength="20">
                  </div>
                </div>
              </div>

              <div class="col-12 col-sm-12 col-md-6 mb-3">
                <div class="form-group row">
                  <label class="col-md-4 control-label required" for="inputDefault">Country</label>
                  <div class="col-md-8">
                    <select class="form-control" formControlName="countryId">
                      <option value="null">Select Country</option>
                      <option *ngFor="let item of countries" [value]="item.id">{{item.name}}</option>
                    </select>
                  </div>
                </div>
              </div>
            </ng-container>
            <div class="col-12 col-sm-12 col-md-6">
              <label *ngIf="currentUser.id==undefined" class="check"> I accept <a href="{{global.StaticUrl}}integration_guide/termsandconditions.pdf" target="_blank" class="color-blue">Terms &
                  Conditions.</a>
                <input type="checkbox" formControlName="term1">
                <span class="checkmark"></span>
              </label>
              <label *ngIf="currentUser.id==undefined" class="check"> I permit Cronberry to send me emails regarding
                software update and features.
                <input type="checkbox" formControlName="term2">
                <span class="checkmark"></span>
              </label>
            </div>
            <div class="col-12 col-sm-12 col-md-12 text-right">
              <button class="btn btn-dark" [disabled]="isButtonDisable" type="submit">{{currentUser.id != undefined?'Update':'Submit'}}</button>
            </div>
          </div>
        </div>

      </form>
    </div>



  </section>
</main>

<ng-template #template>

  <form>
    <div class="forgot-password-modal">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Verify Email ID</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body verify-email">

        <div class="verify-email-detail">
          <p>Please enter the OTP to verify your email id.</p>
          <span>OTP has been sent to {{editForm.controls.email.value}}</span>
          <input trim="blur" [maxlength]='otpLength' type="tel" name='otp' [(ngModel)]="otp" class='otp' />
        </div>

        <div class="verify-email-bottom">
          <button type="button" (click)="verifyEmailPopup()" [disabled]="isEmailButtonDisable" class="btn btn-dark">Submit</button><br>
          <button *ngIf="value==0" type="button" (click)="resendEmail('')" class="btn resend-btn">Resend</button>
          <ng-container *ngIf="value!=0" [counter]="60" [interval]="1000" (value)="value = $event">
            <button type="button" class="btn resend-btn">Resend OTP in {{ value }} seconds</button>
          </ng-container>
        </div>


      </div>
    </div>
  </form>

</ng-template>