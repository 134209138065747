import { Injectable } from '@angular/core';
import { ApiConstants } from '../../constants/api.constants';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  rootPath :string;

    constructor(
        private baseService: BaseService
    ) { 
    }

    getConfiguration(body:any,url?)
    {
      return this.baseService.postRequest(url + ApiConstants.GET_CONFIGURATION, body);
    }

    getIosApp(body:any) {
      return this.baseService.postRequest(ApiConstants.GET_IOS_PUSH_CONFIGURATION, body);
    }
    
    getWindowsApp(body:any) {
      return this.baseService.postRequest(ApiConstants.GET_WINDOWS_PUSH_CONFIGURATION, body);
    }

    getUserJsConfiguration(body:any) {
      return this.baseService.postRequest(ApiConstants.GET_USER_JS_CONFIGURATION, body);
    }
    
    getWebApp(body:any) {
      return this.baseService.postRequest(ApiConstants.GET_WEB_PUSH_CONFIGURATION, body);
    }

    getSMSDetails(body:any,ur?) {
      return this.baseService.postRequest(ur + ApiConstants.GET_CONFIGURATION, body);
    }

    getwpBDetails(body:any,url?) {
      return this.baseService.postRequest(url + ApiConstants.GET_WPB_PUSH_CONFIGURATION, body);
    }

    getEmailDetails(body:any,url?) {
      return this.baseService.postRequest(url + ApiConstants.GET_CONFIGURATION, body);
    }

    upsertWindowsApp(body:any) {
      return this.baseService.postRequest(ApiConstants.UPSERT_WINDOWS_PUSH_CONFIGURATION, body);
    }

    upsertConfiguration(body:any,url?) {
      return this.baseService.postRequest(url + ApiConstants.UPSERT_CONFIGURATION, body);
    }

    sendTestMessage(body:any,url?) {
      return this.baseService.postRequest(url + ApiConstants.SEND_TEST_MESSAGE, body);
    }
    
    testWebNotification(body:any) {
      return this.baseService.postRequest(ApiConstants.SEND_TEST_WEB_PUSH_NOTIFICATION, body);
    }

    getWpPushConfiguration(body:any) {
      return this.baseService.postRequest(ApiConstants.GET_WP_PUSH_CONFIGURATION, body);
    }

    upsertWpPushConfiguration(body:any) {
      return this.baseService.postRequest(ApiConstants.UPSERT_WP_PUSH_CONFIGURATION, body);
    }
    
    wpScanConfiguration(body:any) {
      return this.baseService.postRequest(ApiConstants.WP_SCAN_CONFIGURATION, body, false);
    }

    resetPushConfiguration(body:any,url) {
      return this.baseService.postRequest( url + ApiConstants.RESET_PUSH_CONFIGURATION, body, false);
    }
    
    sendTestWhatsApp(body:any) {
      return this.baseService.postRequest(ApiConstants.SEND_TEST_WP, body);
    }

    
}
