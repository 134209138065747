
import { Injectable } from '@angular/core';
import { ApiConstants } from '../../constants/api.constants';
import { BaseService } from './base.service';

@Injectable({
	providedIn: 'root'
})
export class SegmentService {

	rootPath :string;

    constructor(
    	private baseService : BaseService
    ) { 

    }

    getSegmentlist(body:any) {
    	return this.baseService.postRequest(ApiConstants.SEGMENT_LIST, body);
    }

    addSegment(body:any) {
      	return this.baseService.postRequest(ApiConstants.CREATE_SEGMENT, body);
    }

    fetchSegment(body:any) {
      	return this.baseService.postRequest(ApiConstants.FETCH_SEGMENT, body);
    }

    saveFBCustomAudienceSegementMapping(body: any) {
        return this.baseService.postRequest(ApiConstants.SAVE_FB_CUSTOM_AUDIENCE_MAPPING, body);
    }

    updateFBCustomAudienceSegementMapping(body: any) {
        return this.baseService.postRequest(ApiConstants.UPDATE_FB_CUSTOM_AUDIENCE_MAPPING, body);
    }

    fetchFBCustomAudienceSegementMapping(body: any) {
        return this.baseService.postRequest(ApiConstants.FETCH_FB_CUSTOM_AUDIENCE_MAPPING, body);
    }


    

    deleteSegment(body: any) {
      	return this.baseService.postRequest(ApiConstants.DELETE_SEGMENT, body);
    }
}
