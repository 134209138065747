import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { ToastrService } from "ngx-toastr";
import { Subject } from "rxjs";
import { environment } from "../../../environments/environment";
import { AppConstants } from "../../../app/constants/app.constants";
import { Global } from "../../../app/shared/models/global.model";
import { noWhitespaceValidator } from "../../shared/models/no-whitespace-validator";
import { GalleryPopupComponent } from "../admin/pages/campaign-gallery/gallery-popup.component";
import { CatalogueObj, ProductsObj } from "./catalogue.modal";
import { CatalogueService } from "./catalogue.service";
import { DndDropEvent, DropEffect } from "ngx-drag-drop";
import { UrlShortnerService } from "../../core/services/urlShortner.service";
import { AuthenticationService } from "../../core/services/authentication.service";
import { ProjectService } from "../../core/services/project.service";
import { ConfigurationService } from "../../core/services/configuration.service";

@Component({
    selector: 'app-edit-catalogue',
    templateUrl: './edit-catalogue.component.html',
    styleUrls:['./catalogue.component.scss']
})

export class EditCatalogueComponent implements OnInit, OnDestroy {
    siteForm: FormGroup;
    products :FormArray;
    modalRefGallery: BsModalRef;
    modalRef: BsModalRef;
    eventType: number; //1: product add, 2: logo add & edit, 3: form add & edit, 4: product image replace
    isSubmit: boolean = false;
    postData : CatalogueObj;
    isUpdate: boolean = false;
    id: number;
    catalogueData: any;
    emailConfiguration: boolean = false;
    emailConfigUrl:any = environment.emailPushUrl;
    @ViewChild('galleryPopup') galleryPopup: TemplateRef<any>;
    @ViewChild('showLink') showLink: TemplateRef<any>;
    @ViewChild('emailConfigurationModal') emailConfigurationModal: TemplateRef<any>;
    @ViewChild('descriptionModal') descriptionModal: TemplateRef<any>;
    isMailConfigura : boolean = false;
    config: any;
    ckeditorData:Subject<string> = new Subject();
    productDescription: string = '';
    selectedProduct: number = null;
    domainMapping: string = '';
    productList: ProductsObj[] = [];
    isFormValid: boolean = false;
    isShortUrlSubscribe: boolean = true;
    enablePassword: boolean = false;
    captcha: number = 0;
    passwordUpdate: string = '';
    // @ViewChild('roleTabs') roleTabs:TabsetComponent;
    constructor(
        private formBuilder: FormBuilder,
        private modalService: BsModalService,
        private global: Global,
        private toastr: ToastrService,
        private router: Router,
        private route: ActivatedRoute,
        private catalogueService: CatalogueService,
        private configurationService:ConfigurationService,
        private urlShortnerService: UrlShortnerService,
        private authenticationService: AuthenticationService,
        private projectService: ProjectService
    ){
        this.domainMapping = environment.apiUrlCatalogue.replace('https://','').replace('/api/','')
        this.siteForm = this.formBuilder.group({
            title: ['', Validators.compose([ Validators.required, noWhitespaceValidator, Validators.max(250),Validators.pattern(this.global.AlphaNumPatternWithChar)])],
            sendCustomerEmail: [false],
            sendOwnerEmail: [false],
            logoPath: ['', Validators.compose([ Validators.required] )],
            whatsapp: ['', Validators.compose([Validators.maxLength(10), Validators.pattern(/^[6-9]\d{9}$/)] )],
            handle: ['', Validators.compose([Validators.required, Validators.maxLength(50), Validators.pattern(/^[a-zA-Z0-9-_]*$/), noWhitespaceValidator] )],
            domain: [this.domainMapping, Validators.compose([Validators.required, Validators.maxLength(50), Validators.pattern(/^([a-zA-Z0-9][a-zA-Z0-9-_]*\.)*[a-zA-Z0-9]*[a-zA-Z0-9-_]*[[a-zA-Z]+$/), noWhitespaceValidator] )],
            password: [''],
            metaTitle: ['', Validators.compose([Validators.maxLength(50)] )],
            metaKeywords: ['', Validators.compose([Validators.maxLength(450)] )],
            metaDescription: ['', Validators.compose([Validators.maxLength(150)] )],
            customJavascript: [''],
            catalogueDescription: [''],
            productsList: [],
            products: this.formBuilder.array([]),
            captcha :['']
        });
        this.products = this.siteForm.get('products') as FormArray;
        this.route.params.subscribe( params =>{
            if(params.id){
                this.id = params.id;
                this.isUpdate = true;
                localStorage.removeItem(AppConstants.STORAGE_CATALOGUE_KEY);
                this.fetchCatalogue();
            }
        });
        this.config = this.global.CKEditorConfig(250,'full');
    }

    ngOnInit(){
        if(this.authenticationService.getUserData() && this.authenticationService.getUserData().phone != undefined){
            this.siteForm.get('whatsapp').setValue(this.authenticationService.getUserData().phone);
        }
        if(!this.isUpdate && localStorage.getItem(AppConstants.STORAGE_CATALOGUE_KEY) != undefined){
             var data = localStorage.getItem(AppConstants.STORAGE_CATALOGUE_KEY)
            this.updateFormData(JSON.parse(data));
            this.changeEnablePassword();
        }
        this.getEmailDetails();
    }

    onSubmit(){
        if(this.isUpdate){
            this.updateForm();
        } else {
            this.submitForm();
        }
    }

    // createProduct(name?,price?,quantity?,image?,isUpdate?, id?,description? ):FormGroup{
    //     return this.formBuilder.group({
    //         name:[name, Validators.compose([ Validators.required, noWhitespaceValidator, Validators.max(190) ])],
    //         price:[price, Validators.compose([ Validators.required, noWhitespaceValidator, Validators.pattern('^[0-9]+(.[0-9]{0,9})?$')])],
    //         quantity:[quantity, Validators.compose([ Validators.required, noWhitespaceValidator, Validators.pattern(this.global.NumericPattern) ])],
    //         imagePath:[image],
    //         isUpdate:[isUpdate],
    //         id:[id],
    //         productDescription:[description]
    //     });
    // }
    createProduct(name?,price?,quantity?,image?,isUpdate?, id?,description? ){
        this.productList.push({
            name:name,
            price:price,
            quantity:quantity,
            imageList:image,
            isUpdate:isUpdate,
            id:id,
            productDescription:description,
            showAddImage:true
        })
    }

    showGallery(type, event, productIndex?, imageIndex?): void {
        this.eventType = event
        if(this.eventType == 1 && this.productList.length == 50){
            this.toastr.error("Maximum listing count 50.");
            return;
        }
        const initialState = {
            galleryType : type,
            modalRefGallery: this.modalRefGallery
        }
        this.modalRefGallery = this.modalService.show(GalleryPopupComponent,{
            initialState,
            backdrop: "static",
            class: "gallery-popup"
        });
        this.modalRefGallery.content.modalRefGallery = this.modalRefGallery;
        this.modalRefGallery.content.selectMediaEvent.subscribe(data => {
            this.getMedia(data, productIndex,imageIndex);
        });
    }

    removeProduct(i, id){
        if(this.productList.length == 1){
            this.toastr.error("Minimum listing count 1.");
            return;
        }
        if(this.isUpdate && id != null){
            this.catalogueService.deleteCatalogueProduct({id:id,catalogueId:this.id}).subscribe(data => {
                if(data.status){
                    this.toastr.success(data.data);
                } else {
                    this.toastr.error(data.error_msgs);
                }
            });
        } 
        // this.products.removeAt(i);
        this.productList.splice( i, 1 );
        
    }
    
    getMedia(item, i?, imageIndex?){
        if(this.eventType == 1){
            // this.products.push(this.createProduct(item.mediaName, null, 1, item.originalFile, false, null, null));
            var image = [item.originalFile];
            this.createProduct(item.mediaName, null, 1, image, false, null, null)
        } else if(this.eventType == 2){
            this.siteForm.get('logoPath').setValue(item.originalFile);
        } else if( this.eventType == 3){
            this.productList[i].showAddImage = false;
            if(imageIndex != undefined){
                this.productList[i].imageList[imageIndex] = item.originalFile;
            } else {
                this.productList[i].imageList.push(item.originalFile);
            }
            setTimeout(() => {
                this.productList[i].activeSlideIndex = this.productList[i].imageList.length - 1;
            }, 1000);
        }  else if( this.eventType == 4){
            this.productList[i].imagePath = item.originalFile
            this.updateProject(i);
        }
    }

    submitForm(){
        this.isSubmit = true;
        this.setPostData();
        this.postData.buildKey = this.projectService.projectDetails.projectKey;
        if(this.isFormValid && this.siteForm.valid){
            this.catalogueService.createCatalogue(this.postData).subscribe(data => {
                if(data.status){
                    this.toastr.success(data.data.message, 'Success');
                    this.catalogueData = data.data;
                    localStorage.removeItem(AppConstants.STORAGE_CATALOGUE_KEY);
                    this.isShortUrlSubscribe = this.authenticationService.isPageSubscribe('Url Shortner');
                    this.modalRef = this.modalService.show(this.showLink, {
                        backdrop: 'static'
                    });
                    this.cataloguesData(data.data.catalogueId);
                } else {
                    this.toastr.error(data.error_msgs);
                }
            })
        }
    }
    
    updateForm(){
        this.isSubmit = true;
        this.setPostData();
        this.postData.id = this.id;
        if(this.isFormValid && this.siteForm.valid){
            this.catalogueService.updateCatalogue(this.postData).subscribe(data => {
                if(data.status){
                    this.toastr.success(data.data.message,'Success');
                    localStorage.removeItem(AppConstants.STORAGE_CATALOGUE_KEY);
                    this.catalogueService.catalogueData = undefined;
                    this.cataloguesData(data.data.catalogueId);
                    this.router.navigate(['/admin/catalogue/']);
                } else {
                    this.toastr.error(data.error_msgs);
                }
            })
        }
    }

    setUdateProduct(){
        var updateProduct = [];
        this.postData.products.forEach( data => {
            if(data.id == null || (data.id != null && data.isUpdate)){
                updateProduct.push(data);
            }
        })
        return updateProduct;
    }

    setPostData(){
        this.isFormValid = false;
        this.postData = Object.assign({},this.siteForm.value);
      
        this.postData.sendOwnerEmail = this.siteForm.value.sendOwnerEmail ? 'TRUE' : 'FALSE';
        this.postData.captcha = this.siteForm.value.captcha;
        this.postData.sendCustomerEmail = this.siteForm.value.sendCustomerEmail ? 'TRUE' : 'FALSE';
        this.postData.handle = this.siteForm.value.handle != '' ? this.siteForm.value.handle.toLowerCase() : '';
        this.postData.products = [];
        for(var i = 0; i < this.productList.length; i++){
            var element = this.productList[i];
            if(element.price == '' || element.price == null || element.name == '' || element.quantity == ''){
                // this.siteForm.controls['productsList'].setErrors({'incorrect': true});
                this.toastr.error("Please fill all mandatory fields");
                return
            }
            element.position = i;
            element.imagePath = element.imageList.toString();
            // delete element.imageList;
            this.postData.products.push(element);
        }
        if(!this.isUpdate){
            localStorage.setItem(AppConstants.STORAGE_CATALOGUE_KEY, JSON.stringify(this.postData));
        }
        if(this.postData.logoPath == ''){
            this.toastr.error("Please upload logo.");
            return;
        }
        if(this.postData.products.length == 0){
            this.toastr.error("Please add products.");
            return;
        }
        if(!this.isMailConfigura && this.postData.sendCustomerEmail == 'TRUE'){
            this.modalRef = this.modalService.show(this.emailConfigurationModal, {
                backdrop: 'static'
            });
            return;   
        }
        if(this.postData.password != '' && (this.postData.password.length < 4 || this.postData.password.match(/^\S*$/) == null)){
            this.toastr.error("Enter minimum 4 characters and space is not allowed.");
            return;
        }
        if(this.siteForm.invalid){
            this.toastr.error("Please fill all mandatory fields");
            return;
        }
        if(this.enablePassword){
            this.postData.password = this.postData.password != '' ? this.postData.password : this.passwordUpdate;
        } else {
            this.postData.password = '';
        }
        

       
        

        this.isFormValid = true;
    }

    updateFormData(data){
        this.siteForm.patchValue({
            logoPath: data.logoPath,
            title: data.title,
            handle: data.handle,
            domain: data.domain,
            captcha: data.captcha,
            products: data.products,
            metaTitle: data.metaTitle,
            metaKeywords: data.metaKeywords,
            metaDescription: data.metaDescription,
            customJavascript: data.customJavascript,
            catalogueDescription: data.catalogueDescription,
            whatsapp: data.whatsapp != 0  ? data.whatsapp : '',
            sendCustomerEmail: data.sendCustomerEmail == 'TRUE',
            sendOwnerEmail: data.sendOwnerEmail == 'TRUE'
        });
        this.enablePassword = data.password != undefined && data.password != '';
        this.passwordUpdate = data.password != undefined ? data.password : '';
        this.productList =  data.products;
        this.productList.forEach(item => {
            item.imageList = item.imagePath.split(',');
            item.activeSlideIndex = 0;
        });
        this.ckeditorData.next(data.catalogueDescription);
        // data.products.forEach( item => {
        //     this.products.push(this.createProduct(item.name, item.price, item.quantity, item.imagePath, false, item.id, item.productDescription));
        // });
    }

    copyLink(link){
        this.global.copyText(link);
        this.toastr.success('Copied to clipboard');
    }

    hideShowLinkModal(){
        this.modalRef.hide();
        this.catalogueService.catalogueData = undefined;
        this.router.navigate(['/admin/catalogue/']);
    };

    fetchCatalogue(){
        this.catalogueService.fetchCatalogue({id:this.id}).subscribe( data => {
            if(data.status){
                this.updateFormData(data.data);
            } else {
                this.toastr.error(data.error_msgs);
            }
        })
    }

    updateProject(i){
        // this.siteForm.value.productsList[i].isUpdate = true;
        this.productList[i].isUpdate = true;
    }

    cataloguesData(id){
        var data = {
            catalogueTemplateId:id,
            mailCustomer:this.postData.sendCustomerEmail == "TRUE" ? '1' : '0',
            mailOwner:this.postData.sendOwnerEmail == "TRUE" && this.postData.sendCustomerEmail == "TRUE" ? '1' : '0'
        };
        this.projectService.getUserSourceData('reload');
        // this.catalogueService.cataloguesData(data).subscribe(data => {
        //     if(data.status){
        //         this.projectService.getUserSourceData('reload');
        //     }
        // });
    }

    getEmailDetails() {
        this.configurationService.getConfiguration({},this.emailConfigUrl).subscribe( data => {
            if (data.status) {
                this.isMailConfigura = true;
            } else {
                this.isMailConfigura = false;
            }
        });
    }

    showDescriptionModal(i): void {
        this.selectedProduct = i;
        // this.productDescription = this.products.value[i].productDescription
        this.productDescription = this.productList[i].productDescription
        this.modalRef = this.modalService.show(this.descriptionModal, {
            backdrop: 'static'
        });
    }

    submitDescription(): void{
        this.modalRef.hide();
        // (<FormArray>this.siteForm.get("products")).at(this.selectedProduct).get('productDescription').setValue(this.productDescription);
        this.productList[this.selectedProduct].productDescription = this.productDescription;
        this.updateProject(this.selectedProduct);
    }

    onDragged( item:any, effect:DropEffect ) {
        if( effect === "move" ) {
            const index = this.productList.indexOf( item );
            this.productList.splice( index, 1 );
        }
    }

    onDrop( event:DndDropEvent) {
        if( event.dropEffect === "copy" || event.dropEffect === "move" ) {
            let index = this.productList.length == 1 ? 0 : event.index;
            if( typeof index === "undefined" ) {
                index = this.productList.length;
            }
            this.productList.splice( index, 0, event.data );   
        }
    }

    ngOnDestroy() {
        if(this.modalRef && this.modalRefGallery){
            this.modalRef.hide();
            if( this.modalRefGallery){
                this.modalRefGallery.hide();
            }
            
        }
       
    }

    getShortUrl(url){
        this.urlShortnerService.getUrlShortner(url).then( data => {
            if(data != undefined){
                this.global.copyText(data);
                this.toastr.success('Copied to clipboard');
            }
        })
    }

    removeImage(imageIndex, listIndex){
        this.productList[listIndex].imageList.splice(imageIndex,1);
    }

    changeEnablePassword(){
        const passwordControl = this.siteForm.controls['password'];
        passwordControl.setValue('');
        if(this.enablePassword){
            passwordControl.setValidators(Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(50),Validators.pattern(/^\S*$/)]));
        } else {
            passwordControl.clearValidators();
        }
        passwordControl.updateValueAndValidity();
    }
}