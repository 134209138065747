import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { ToastrService } from "ngx-toastr";
import { Global } from "../../../../shared/models/global.model";
import { RoleObj } from "../../../../core/modal/user";
import { UserService } from "../../../../core/services/user.service";
import { noWhitespaceValidator } from "../../../../shared/models/no-whitespace-validator";

@Component({
    selector: 'role-app',
    templateUrl: './role-management.component.html',
    styleUrls: ['./user-management.component.scss']
})

export class RoleManagementComponent implements OnInit {
    modalRef: BsModalRef;
    @ViewChild('addRoleModal') addStatusModal: TemplateRef<any>;
    @ViewChild('confirmModal') confirmModal: TemplateRef<any>;
    @Output() eventEmit = new EventEmitter<any>();
    siteForm : FormGroup;
    roleList : RoleObj[];
    roleListClone:any;
    isUpdated: boolean = false;
    selectedId: number;
    roleType: any[] = [];
    editableUser:any;
    constructor(
        private modalService: BsModalService,
        private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private userService: UserService,
        private globle: Global
    ) {
    }

    ngOnInit() {
        this.roleType = [
            // {id:1,name:'Admin'},
            // {id:2,name:'Marketer'},
            // {id:3,name:'Telecaller'},
            // {id:4,name:'Manager'}
            {id:1,name:'Parent'},
            {id:2,name:'Child'}
        ]
        this.fetchUserRole();
    }

    addRole(data? : RoleObj) {
        // if(this.roleList.length >= 10 && data == undefined){
        //     this.toastr.error("Maximum role count 10.");
        //     return
        // }
        this.editableUser = data;
        this.prepareForm();
        this.isUpdated = false;
        this.roleListClone = this.roleList.filter(p=>{
            return p.roleTypeId == 1;
        });
        if(data != undefined){            
            let parentRole;
            let formFieldParentRole = [];            
            this.roleListClone = [];
            let levelOfUsers;
            if(data.parentRole != '0'){
                parentRole = data.parentRole.split(',');
            } else if(data.parentRole == '0'){
                parentRole = '0';
            }
            if(parentRole && parentRole != '0'){
            parentRole = parentRole.filter(p=>{
                return p != null
            });

            parentRole.forEach(element => {
                let checkParentRole = this.roleList.find(x=>x.id == element);
                if(checkParentRole){
                    formFieldParentRole.push(checkParentRole.id);
                    let getLevelData = this.roleList.find(x=>x.level == checkParentRole.level);
                    if(getLevelData){
                        levelOfUsers = getLevelData.level
                        this.roleListClone.push(getLevelData)
                    }
                }
            });
            }
            if(parentRole != '0'){
                this.roleListClone = this.roleList
            }
            if(data.parentRole == '0' && data.level == 1){
                this.roleListClone = this.roleList
            }
            if(levelOfUsers){
            this.roleListClone = this.roleList.filter(p=>{
                return p.level == levelOfUsers
            });
            }

            if(this.roleListClone){
                this.roleListClone = this.roleListClone.filter(p=>{
                    return p.roleTypeId == 1;
                })
            }

            if(data.level == 1 && data.roleTypeId == 1){
                this.roleListClone = []
            }

            this.siteForm.setValue({
                id: data.id,
                name : data.name,
                parentRole: formFieldParentRole,
                roleTypeId:data.roleTypeId
            });
            this.siteForm.value.parentRole = formFieldParentRole            
            this.isUpdated = true;
        }

        this.modalRef = this.modalService.show(this.addStatusModal, { class: "modal-addstatus", backdrop: 'static' });
    }

    saveForm(){        
        if (this.siteForm.invalid ) {
            this.toastr.error('Please fill all mandatory fields');
            return;
        };
        var formData = this.siteForm.value;
        formData.parentRole = formData.parentRole.toString();        
        this.userService.upsetUserRole(formData).subscribe(data => {
            if(data.status){
                this.toastr.success(data.data,'Success');
                this.modalRef.hide();
                this.userService.usreRoleList = undefined;
                this.fetchUserRole();
                this.eventEmit.emit(true);
            } else {
                this.toastr.error(data.error_msgs,'Error');
                this.roleListClone = this.roleList.filter(p=>{
                    return p.roleTypeId == 1
                });
            }
        })
    }

    fetchUserRole(){
        this.roleList = [];
        this.userService.getUserRoleData().then(data => {
            if(data != undefined){
                data.sort((a,b)=>{
                    if(a.level < b.level){
                        return -1;
                    }
                    if(a.level > b.level){
                        return 1;
                    }
                });
                this.roleList = data;
                this.roleList.forEach(elem=>{
                    elem.parent = []
                    if(elem.parentRole){
                        let parentRole = elem.parentRole.split(',');
                        parentRole = parentRole.filter(p=>{
                            return p != null;
                        });
                        parentRole.forEach(element => {
                            let findParent = this.roleList.find(x=>x.id == element)
                            if(findParent){
                                elem.parent.push(findParent.name)
                            }
                        });
                    }
                })
                this.roleListClone = data.filter(p=>{
                    return p.roleTypeId == 1
                });
            }
        })
    }

    roleListSort(rolelist){
        rolelist.sort((a,b)=>{
            if(a.level < b.level){
                return -1;
            }
            if(a.level > b.level){
                return 1;
            }
        });        
    }

    userRoleAdded(event){
        let role_level = event.level;
        this.roleListClone = this.roleList.filter((p)=>{
            return p.level == role_level && p.roleTypeId == 1;
        })
    }

    resetAllUserList(){
        this.roleListClone = this.roleList.filter(p=>{
            if(this.editableUser){
                return p.roleTypeId == 1 && p.id !=this.editableUser.id;
            } else{
                return p.roleTypeId == 1
            }
            
        });
    }

    userRoleRemoved(event){
        let parentRoleData = this.siteForm.value.parentRole;
        if(parentRoleData.length == 0){
            this.userService.getUserRoleData().then(data => {
                if(data != undefined){
                    this.roleListClone = data;
                    this.roleListClone = this.roleListClone.filter(p=>{
                        if(this.editableUser){
                            return p.roleTypeId == 1 && p.id != this.editableUser.id;
                        } else{
                            return p.roleTypeId == 1
                        }
                        
                    })
                }
            })
        }
    }

    prepareForm(){
        this.siteForm = this.formBuilder.group({
            id:["0"],
            name:[ "", Validators.compose([ Validators.required, noWhitespaceValidator, Validators.minLength(2), Validators.maxLength(50), Validators.pattern(this.globle.AlphaNumPatternWithChar) ]) ],
            roleTypeId:[ "", Validators.compose([ Validators.required ]) ],
            parentRole:[""]
        });
    }

    showDeleteModal(id){
        this.selectedId = id;
        this.modalRef = this.modalService.show(this.confirmModal, {
            backdrop: 'static'
        });
    }

    deleteRole(){
        this.userService.deleteUserRole({id:this.selectedId}).subscribe(data => {
            this.modalRef.hide();
            if(data.status){
                this.toastr.success(data.data,'Success');
                this.userService.usreRoleList = undefined;
                this.fetchUserRole();
            } else {
                this.toastr.error(data.error_msgs,'Error');
            }
        });
    }

}
