import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { ApiConstants } from '../../constants/api.constants';
import { delay } from 'rxjs/operators';
import { MenuObj, pluginsObj, RoleObj, SubUserObj } from '../modal/user';
import { AppConstants } from '../../constants/app.constants';
import { AuthenticationService } from './authentication.service';
import { ProjectService } from './project.service';

@Injectable({ providedIn: 'root' })

export class UserService {
    rootPath: string;
    subUserList: SubUserObj[];
    allSubUserList: SubUserObj[];
    pluginList: pluginsObj[];
    pluginUserList: any;
    usreRoleList: RoleObj[];
    menuActionList: MenuObj[];
    constructor(
        private baseService: BaseService,
        private authenticationService: AuthenticationService,
        private projectService:ProjectService
    ) {
        
    }

    upsertUserProfile(body: any) {
        return this.baseService.postRequest(ApiConstants.UPSERT_USE_RPROFILE, body);
    }

    getUsers(body:any) {
        return this.baseService.postRequest(ApiConstants.SEARCH_SUB_USER, body);
    }

    addUser(body: any) {
        return this.baseService.postRequest(ApiConstants.REGISTER_SUB_USER, body);
    }

    createWabaUser(body: any) {
        return this.baseService.postRequest(ApiConstants.CREATE_WABA_USER, body);
    }

    removeWabaUser(body: any) {
        return this.baseService.postRequest(ApiConstants.REMOVE_WABA_USER, body);
    }

    editUser(body: any) {
        return this.baseService.postRequest(ApiConstants.UPDATE_SUB_USER, body);
    }

    removeUser(body: any) {
        return this.baseService.postRequest(ApiConstants.DELETE_SUB_USER, body);
    }
    toggleUserStatus(body: any) {
        return this.baseService.postRequest(ApiConstants.TOGGLE_USER_STATUS, body);
    }
    
    leadStatusSubUser(body: any) {
        return this.baseService.postRequest(ApiConstants.LEAD_STATUS_SUB_USER, body);
    }

    sendVerificationLink(body: any) {
        return this.baseService.postRequest(ApiConstants.SEND_VERIFICATION_MAIL, body);
    }

    verifyEmailOTP(body: any) {
        return this.baseService.postRequest(ApiConstants.VERIFY_EMAIL_OTP, body);
    }

    requestEmailOTP(body: any) {
        return this.baseService.postRequest(ApiConstants.REQUEST_EMAIL_OTP, body);
    }

    changePassword(body: any) {
        return this.baseService.postRequest(ApiConstants.CHANGE_PASSWORD, body);
    }

    linkExpired(body: any) {
        return this.baseService.postRequest(ApiConstants.IS_LINK_EXPIRED, body);
    }

    linkExpiredForgotPassword(body: any) {
        return this.baseService.postRequest(ApiConstants.IS_LINK_EXPIRED_FORGOT_PASSWORD, body);
    }

    updateNewPassword(body: any) {
        return this.baseService.postRequest(ApiConstants.UPDATE_NEW_PASSWORD, body);
    }

    fetchPluginListForLead(body: any) {
        return this.baseService.postRequest(ApiConstants.FETCH_PLUGIN_LIST_FOR_LEAD, body);
    }
    fetchPluginListForUser(body: any) {
        return this.baseService.postRequest(ApiConstants.FETCH_PLUGIN_LIST_FOR_USER, body);
    }

    fetchUserListForPlugin(body: any,type) {
        return this.baseService.postRequest(ApiConstants.FETCH_USER_LIST_FOR_PLUGIN.replace(AppConstants.REPLACE_TYPE,type), body);
    }

    saveUserListForPlugin(body: any,type) {
        return this.baseService.postRequest(ApiConstants.SAVE_USER_LIST_FOR_PLUGIN.replace(AppConstants.REPLACE_TYPE,type), body);
    }

    getMenuActionList(body: any) {
        return this.baseService.postRequest(ApiConstants.GET_MENU_ACTION_LIST, body);
    }

    listUserRole(body: any) {
        return this.baseService.postRequest(ApiConstants.LIST_USER_ROLE, body);
    }

    upsetUserRole(body: any) {
        return this.baseService.postRequest(ApiConstants.UPSET_USER_ROLE, body);
    }
    
    deleteUserRole(body: any) {
        return this.baseService.postRequest(ApiConstants.DELETE_USER_ROLE, body);
    }

    getRolePermission(body: any) {
        return this.baseService.postRequest(ApiConstants.GET_ROLE_PERMISSION, body);
    }

    upsetRolePermission(body: any) {
        return this.baseService.postRequest(ApiConstants.UPSET_ROLE_PERMISSION, body);
    }

    async getUsersData(type?,reload?){
        if(reload == 'reload'){
            this.subUserList = undefined;
        }
        var paremValue = {
            userRoleId:this.authenticationService.getUserRole()
        }
        if(this.subUserList == undefined){
            var requestData = await this.getUsers(paremValue).pipe(delay(200)).toPromise();
            this.allSubUserList = requestData.data;
        };
        if(type == 'all'){
            this.subUserList = JSON.parse(JSON.stringify(this.allSubUserList));
        } else {
            var list = JSON.parse(JSON.stringify(this.allSubUserList));
            this.subUserList = list.filter(x => x.status == "ACTIVE");
        }
        return this.subUserList;
}


async filterChildUsersCallAssist(data:SubUserObj[]){
    let subUsers;
    var subUserList: SubUserObj[] = [];
    var userId = this.authenticationService.getUserId();
    var userList: SubUserObj[] = data.filter(x => x.status == "ACTIVE");
    if(this.authenticationService.getUserRole() == 1){
        subUserList = userList;
        return subUserList;
    };
    await this.projectService.getChildUserData({}).subscribe(users=>{
        subUsers = users.data.userList;
    if(this.authenticationService.getUserRoleType() == 1){
        var userData : SubUserObj;
        var managerUserData = [];
        var subUserId  = [];
        userData = userList.find(x => x.id == userId);

        userList.forEach(elem=>{
            let checkSubUser = subUsers.find(x=>x == elem.id);
            if(checkSubUser){
                subUserList.push(checkSubUser);
            }
        })
        return subUserList
    } 
    
     else {
        subUserList = userList;
    }
    });

    
    return subUserList;
}


    filterChildUsers(data:SubUserObj[]){
        var userList: SubUserObj[] = data.filter(x => x.status == "ACTIVE");
        var subUserList: SubUserObj[] = [];
        var userId = this.authenticationService.getUserId();
        if(this.authenticationService.getUserRole() == 1){
            subUserList = userList;
            return subUserList;
        };
        if(this.authenticationService.getUserRoleType() == 1){
            var userData : SubUserObj;
            var managerUserData = [];
            var subUserId  = [];
            userData = userList.find(x => x.id == userId);
            if(userData != undefined && userData.userList != undefined){
                userData.userList.forEach((item) => {
                    let checkItem = userList.find(x => x.id == item);
                    if(checkItem){
                    managerUserData.push(userList.find(x => x.id == item));
                    subUserList.push(userList.find(x => x.id == item))
                    }
                });
                managerUserData.forEach( item => {
                    if(item.userList != undefined)
                    subUserId = subUserId.concat(item.userList);
                })
            }
            subUserId.forEach( id => {
                let checkId = userList.find(x => x.id == id);
                if(checkId){
                subUserList.push(userList.find(x => x.id == id));
                }
            });
        } 
        // else if(this.authenticationService.getUserRoleType() == AppConstants.MANAGER_ROLE_TYPE){
        //     var userData : SubUserObj;
        //     var subUserId  = [];
        //     userData = userList.find(x => x.id == userId);
        //     if(userData != undefined && userData.userList != undefined){
        //         userData.userList.forEach( id => {
        //             let checkId = userList.find(x => x.id == id);
        //             if(checkId){
        //             subUserList.push(userList.find(x => x.id == id));
        //             }
        //         });
        //     }
        // }
         else {
            subUserList = userList;
        }
        return subUserList;
    }

    async getPluginListForLead(){
        if(this.pluginList == undefined){
            var requestData = await this.fetchPluginListForLead({}).pipe(delay(200)).toPromise();
            this.pluginList = requestData.data;
        };
        return this.pluginList;
    }

    async getUserRoleData(){
        if(this.usreRoleList == undefined){
            var requestData = await this.listUserRole({}).pipe(delay(200)).toPromise();
            this.usreRoleList = requestData.data;
        };
        return this.usreRoleList;
    }

    async getMenuActionData(){
        if(this.menuActionList == undefined){
            var requestData = await this.getMenuActionList({}).pipe(delay(200)).toPromise();
            this.menuActionList = requestData.data;
        };
        return this.menuActionList;
    }
}