import { Component, OnInit, OnDestroy, TemplateRef, ViewChild} from '@angular/core';
import { TabsetComponent } from 'ngx-bootstrap';
import { Options } from 'ng5-slider';
import { SubscriptionService } from '../../../../../../src/app/core/services/subscription.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from "@angular/router";
import { SeoService } from '../../../../../../src/app/seo.service';
declare var $: any;

@Component({
    selector: 'pricing',
    templateUrl: './pricing.component.html', 
    styleUrls: ['./pricing.component.scss']
})

export class PricingComponent implements OnInit, OnDestroy {
    planCreditRangeValue: any = 0;
    planCreditRangePrice: any = 0;
    activePlugins: any = [];
    planCreditsRange: any = [];
    activeChannels: any = [];
    pricingPlans: any = [];
    showLoader: boolean = true;
    totalSuccess: number = 0;
    currentPlan: any = {};
    yearly: boolean = false;
    userId: string;
    projectId: string;
    @ViewChild('templatePayPalBlock') templatePayPalBlock: TemplateRef<any>;
    @ViewChild('staticTabs') staticTabs: TabsetComponent;
    pricingPlanId: any = {};
    options: Options = {
        showTicksValues: true,
        stepsArray: [
            { value: 2000 },
            { value: 5000 },
            { value: 50000 },
            { value: 100000 },
            { value: 200000 },
            { value: 300000 },
            { value: 400000 }
        ],
        minLimit: 0,
        maxLimit: 10,
        ticksTooltip: (value: number) => {
            var obj = this.planCreditsRange[value];
            if (obj != null) {
                return obj.description;
            } else {
                return '';
            }
        },
        ticksValuesTooltip: (value: number) => {
            var obj = this.planCreditsRange[value];
            if (obj != null) {
                return obj.description;
            } else {
                return '';
            }
        }
    };
  
    constructor(
        private toastr: ToastrService,
        private subscriptionService: SubscriptionService,
        private router: Router,
        private seoService: SeoService
    ) {
        this.seoService.setSeoData('Flexible and Scalable Pricing Plan - Cronberry', 'Start off with a plan that\'s right for your organization. Check out our Pricing Plans for Cronberry, All-in-one Marketing Automation Tool for your Business. Signup Now!');
    }

    finalPrice() {
        var finalPrice = 0;

        if (!this.currentPlan.activeStatus)
        finalPrice += parseFloat(this.currentPlan.planCost);
        finalPrice += this.activePlugins.filter(function (item1: any) {
            return item1.checked == true && item1.activeFlag != "1";
        }).map(function (x) { return parseFloat(x.price); }).reduce(function (acc, val) { return acc + val; }, 0);

        finalPrice += this.activeChannels.filter(function (item1: any) {
            return item1.checked == true && item1.activeFlag != "1";
        }).map(function (x) { return parseFloat(x.price); }).reduce(function (acc, val) { return acc + val; }, 0);

        var rv = this.planCreditRangeValue;
        var planCreditRangeId = this.planCreditsRange.filter(function (x: any) { return x.countTo == rv && x.activeFlag != "1"; });
        if (planCreditRangeId.length > 0) {
            finalPrice += planCreditRangeId[0].price;
        }

        return finalPrice;
    }

    ngOnDestroy() {

    }

    ngOnInit() {
        this.userId =  '0';
        this.projectId= '1';
        // this.getPricingPlans();
        $('[data-toggle="tooltip"]').tooltip();
        $('.ng5-slider-inner-tooltip').tooltip();
    }

    upgrade() {
        this.router.navigate(['/admin/sign-in']);
    }


    getPricingPlans() {
        this.subscriptionService.getPricingPlan({ projectId: this.projectId }).subscribe( data => {
            this.totalSuccess++;
            if (this.showLoader || this.totalSuccess == 2) {
                this.showLoader = true;
            }
            if (data.status) {
                this.pricingPlans = data.data;
                this.currentPlan = this.pricingPlans.filter(x => x.activeStatus)[0];
                if (this.currentPlan == undefined) {
                    this.currentPlan = this.pricingPlans[0];
                }
                this.yearly = true;
                var freePlan = this.pricingPlans.filter(x => x.planType == 'FREE')[0];
                freePlan.planType = 'YEARLY';
                let freePlayYearly = Object.assign({}, freePlan);
                freePlayYearly.planType = 'MONTHLY';
                this.pricingPlans.unshift(freePlayYearly);
                this.onSelectionChange(this.currentPlan);
            }
            window.scroll(0, 0);
        }, error => {
            this.toastr.error('Something went wrong', 'Error');
        });
    }

    getPluginChannelRangeData() {
        this.subscriptionService.getPluginChannelRangeData({ id: this.pricingPlanId, projectId: this.projectId }).subscribe( data => {
            if (data.status) {
                this.activePlugins = data.data.activePlugins;
                this.activeChannels = data.data.activeChannels;
                this.activePlugins.forEach(element => {
                    element.checked = element.activeFlag == "1";
                });
                this.activeChannels.forEach(element => {
                    element.checked = element.activeFlag == "1";
                });
                this.planCreditsRange = data.data.planCreditsRange;
                var pcr = data.data.planCreditsRange.map(function (x: any) { return { value: x.countTo }; });
                var stepsArray = pcr;
                var minLimit = 0;
                var maxLimit = 10;
                var activeCredit = data.data.planCreditsRange.filter(function (item1: any) {
                    return item1.activeFlag == "1";
                });
                this.planCreditRangeValue = 0;
                if (activeCredit.length > 0) {
                    this.planCreditRangeValue = activeCredit[0].countTo;
                    var index = data.data.planCreditsRange.findIndex(function (item1: any) {
                        return item1.activeFlag == "1";
                    });
                    minLimit = index;
                    maxLimit = data.data.planCreditsRange.length;
                } else if (data.data.planCreditsRange.length > 0 && this.currentPlan.planName != 'FREE')
                    this.planCreditRangeValue = data.data.planCreditsRange[0].countTo;

                var optionsNew: Options = {
                    showTicksValues: true,
                    stepsArray: pcr,
                    minLimit: minLimit,
                    maxLimit: maxLimit,
                    ticksTooltip: (value: number) => {
                        var obj = this.planCreditsRange[value];
                        if (obj != null) {
                            return obj.description;
                        }
                        else {
                            return '';
                        }
                    },
                    ticksValuesTooltip: (value: number) => {
                        var obj = this.planCreditsRange[value];
                        if (obj != null) {
                            return obj.description;
                        }
                        else {
                            return '';
                        }
                    }
                };
                this.options = optionsNew;
                this.updatePrice();
                $('.ng5-slider-inner-tooltip').tooltip();
            }
        }, error => {
            this.toastr.error('Something went wrong', 'Error');
        });
    }

    updatePrice() {
        var rv = this.planCreditRangeValue;
        var lstDt = this.planCreditsRange.filter(function (x: any) { return x.countTo == rv; });
        if (lstDt.length > 0)
            this.planCreditRangePrice = lstDt[0].price;
    }

    onSelectionChange(item) {
        this.pricingPlanId = item.id;
        this.currentPlan = item;
        this.getPluginChannelRangeData();
    }
}
