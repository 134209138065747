import { Component, OnInit, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Global } from '../../../../shared/models/global.model';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectService } from '../../../../core/services/project.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { SpinnerService } from '../../../../core/services/spinner.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-bulk-upload',
  templateUrl: './bulk-upload.component.html',
  styleUrls: ['./bulk-upload.component.scss']
})
export class BulkUploadComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef;
  formblukupload: FormGroup;
  modalRef: BsModalRef;
  errormsg: any = '';
  file: any;
  bulkUpload: any = [];
  fixedParams:any;
  inputParams:any;
  fileTypeIn;
  masterSelected:any;
  searchAttribute:any;
  excelformName:any;
  showMapParams:any = new Array();
  xlsTableCol:any = new Array();
  @ViewChild('confirm') confirm: TemplateRef<any>;
  @ViewChild('error') error: TemplateRef<any>;
  @ViewChild('attrListPopup') attrListPopup: TemplateRef<any>;
  @ViewChild('xlsTable') xlsTable: ElementRef;

  id: any = 0;
  constructor(
    private formBuilder: FormBuilder,
    private global: Global,
    private toastr: ToastrService,
    private router: Router,
    private projectservice: ProjectService,
    private modalService: BsModalService,
    private spinner: SpinnerService,
    private route:ActivatedRoute
  ) { }

  ngOnInit() {
    this.prepareFormblukupload();
    this.getParams();
    this.route.queryParams.subscribe(data=>{
      this.excelformName = data.formName;
    })

//     let wb = XLSX.utils.book_new();

// wb.Props = {

//   Title: 'Excel Workbook',

//   Subject: 'Tutorial',

//   Author: 'Erich Buelow',

//   CreatedDate: new Date(),

// };

// let wsName = 'newSheet';

// let wsData = [
//   ['name', 'email']
// ];

// let ws = XLSX.utils.aoa_to_sheet(wsData);

// XLSX.utils.book_append_sheet(wb, ws, wsName);

// wb.Sheets["testSheet"] = ws;

// this.fileInput = XLSX.write(wb, {bookType:'xlsx', type:'binary'});


}

  prepareFormblukupload() {
    this.formblukupload = this.formBuilder.group({
      file: [''],
    });
  }
  openModal(confirm: TemplateRef<any>) {
    this.modalRef = this.modalService.show(confirm, { class: 'modal-sm', backdrop: 'static' });
  }
  errorModal(error: TemplateRef<any>) {
    this.modalRef = this.modalService.show(error, { class: 'modal-sm', backdrop: 'static' });
  }

  Done() {
    this.router.navigate(['admin/project-integration']);
    this.modalRef.hide();
  }

  saveBlukupload(form: any) {
    if (!this.formblukupload.controls.file.value) {
      this.toastr.error('Please Select Excel');
      return;
    }
    
    let formData: FormData = new FormData(); 
    formData.append("file", this.file);
    formData.append('formName', this.excelformName);
    this.spinner.show();
    this.projectservice.uploadAudienceDataExcel(formData).subscribe(data => {
      this.spinner.hide();
      if (data.status) {
        this.global.globallistdata = data.data;
        this.global.uploadlist = data.data.bulkUploadFailedList;
       // this.router.navigate(['admin/upload-confirm']);
       this.toastr.success(data.data);
       this.formblukupload.controls.file.setValue('');
       this.file = '';
       this.formblukupload.get('file').setValue('');
       this.fileInput.nativeElement.value = '';
      }
      else {
        this.errormsg = data.error_msgs;
        this.errorModal(this.error);
      }
    })

  }
  onFileSelect(event) {
    if (event.target.files.length > 0) {
      if(event.target.files[0].size > 2000000){
        this.fileInput.nativeElement.value = '';
        this.toastr.error('Maximum file size should be 2 MB.')
         return;
      }
      this.file = event.target.files[0];
      this.formblukupload.get('file').setValue(this.file);
    }
  }

  downloadSampleFile() {
    this.spinner.show();
    this.projectservice.downloadSampleFile({ }).subscribe(data => {
      this.spinner.hide();
        if (data.status) {
          window.open(data.data.outputExcelUrl, "_blank");
        }
      });
  }

  openAttrPopup()
  {
    this.searchAttribute = '';
    this.modalRef = this.modalService.show(this.attrListPopup, { class: '', backdrop: 'static' });
  }

  checkUncheckVal(event,colName)
  {
    
    if(event.target.checked == true)
    {
        this.xlsTableCol.push(colName);
        let checkSelected = [];        
        this.showMapParams.forEach(element => {
          
          if(element.checked == false)
          {            
            checkSelected.push(element);
          }
          
        });
        
        if(checkSelected.length == 0)
        {
          this.masterSelected = true;
        }
    }
    if(event.target.checked == false)
    {      
      this.masterSelected = false;
      let colIndex;
      colIndex = this.xlsTableCol.indexOf(colName);
      if(colIndex > -1)
      {
      this.xlsTableCol.splice(colIndex,1);      
      }
    }    
  }

  checkUncheckAll(event)
    {
      this.xlsTableCol = [];
      this.showMapParams.forEach(element => {
        if(event.target.checked == true )
        {
          element.checked = true;
          this.xlsTableCol.push(element);
        }
        else
        {
          element.checked = false;
        }
        
      });
    }

  getParams() {
    this.projectservice.fetchAttributeList().then(data => {
        if(data){
            this.inputParams = this.projectservice.filterAttributeList();
            // if(this.fixedParams)
            // {
            // this.fixedParams.forEach(elems=>{
            //     elems.showLabel = elems.paramName;
            //     this.showMapParams.push(elems);
            // })
            // }
            if(this.inputParams)
            {
            this.inputParams.forEach(element => {
                if(element.paramDatatype != 'File' && element.paramVisible == '1')
                {    
                element.showLabel = element.paramName;                    
                this.showMapParams.push(element)
                }
            });  
            }
            this.showMapParams.sort((a, b) => a.paramName.localeCompare(b.paramName))
            this.showMapParams.forEach(element => {
              element.checked = false;
            });
        }
    });
    
}

downloadFile() {
  if(this.xlsTable == undefined)
  {
    this.toastr.error('Select atleast one column to generate xls');
    return
  }
  const ws = XLSX.utils.table_to_sheet(this.xlsTable.nativeElement, { raw: true });
  let a = XLSX.utils.sheet_to_json(ws, { header: 1 });
  let worksheet = XLSX.utils.json_to_sheet(a, { skipHeader: true })
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, worksheet, 'Sheet1');
  /* save to file */
  XLSX.writeFile(wb, 'SampleUploadAudience.xlsx');
  this.xlsTableCol = [];
  this.modalRef.hide();
  this.masterSelected = false;
  this.showMapParams.forEach(element => {
      element.checked = false;
    });
  this.toastr.success('File Downloaded');
}

}
