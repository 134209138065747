<main>
    <section class="inner-page">
        <div class="inner-page-header">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-6">
                        <h4>
                            <a class="back-link" (click)="back()"><img src="assets/images/angle-left.svg"></a>
                            Lead Status
                        </h4>
                    </div>
                    <div class="col-md-6 text-right j-end d-flex" >
                        <!-- <button class="btn btn-dark btn-icon" (click)="addStatus()"><img src="assets/images/plus-light.svg" alt=""></button> -->
                        <button class="btn white_add_btn d-flex c-align" (click)="addStatus()" tooltip="Add Lead Status" [tooltipAnimation]="true" tooltipPlacement="top">
                            <img src="assets/images/plus-icon.png"> &nbsp; Add
                        </button>

                    </div>
                </div>
            </div>
        </div>

        <div class="inner-page-body site-form">
            <div class="container-fluid">
                <div class="table-responsive table-height-scroll table-reports">
                    <table class="table callAssistDashboardTable th_font_14">
                        <thead class="sticky-header" data-sticky-header="true">
                            <tr class="callAssistDbRowTop">
                                <th width="20" class="borderTh text-center">S.No.</th>
                                <th width="200" class="borderTh">Name</th>
                                <th class="borderTh text-center">Type</th>
                                <th class="text-center borderTh">Action</th>
                            </tr>
                        </thead>
                        <tbody class="norecord-handling">
                            <tr *ngFor="let item of dataList; let i = index;">
                                <td class="text-center">{{i + 1}}</td>
                                <td class="text-capitalize"><span class="tag" [style.background-color]="item.stageColour"></span> {{item.stageName}}</td>
                                <td class="text-center">{{item.stageType}}</td>
                                <td class="text-center">
                                    <!-- <a class="c-pointer view_tat_icon" tooltip="View TAT" tooltipPlacement="top" (click)="goToTat(item)">
                                        <img class="img" src="assets/images/view-dark.svg" />
                                    </a> &nbsp; -->
                                    <a class="c-pointer" tooltip="Edit" tooltipPlacement="top" (click)="viewTatWindow = false; addStatus(item)">
                                        <img class="img" src="assets/images/pinkedit.png" />
                                    </a> &nbsp;
                                    <a class="c-pointer" tooltip="Delete" tooltipPlacement="top" (click)="showDeleteModal(item.id)">
                                        <img class="img" src="assets/images/delete.png" />
                                    </a> &nbsp;
                                    <a class="c-pointer" tooltip="Data Flow" tooltipPlacement="top" (click)="showFlowDetail(item.id)" *ngIf="pushDataSubscribe">
                                        <img class="img data-icon-flow-data" src="assets/images/data-icon-push-data.png" />
                                    </a>
                                </td>
                            </tr>
                            <tr *ngIf="dataList.length == 0">
                                <td class="no-records text-center" colspan="6">No Records Yet</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>

</main>

<ng-template #cnfrimUpdateStatus>
        <div class="leadupdateConfirmModal forgot-password-modal text-center">
        <div class="modal-header">
            <h4 class="modal-title pull-left">Are you sure want to update ?</h4>
            <button type="button" class="close pull-right" (click)="modalRef.hide()">
              <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div>
            <div>
                <button class="btn btn btn-dark red" (click)="modalRef.hide()">No</button>
                <button class="btn btn btn-dark green" (click)="confirmChangeStatusType()">Yes</button>
            </div>
            </div>
        </div>
        </div>
    </ng-template>

<ng-template #addStatusModal>

    <ng-container *ngIf="viewPushDataWindow">
        <div class="modal-header">
            <h4 class="modal-title pull-left">Push Data</h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="viewPushDataWindow = false">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
           <form (ngSubmit)="pushDataSubmit()" [formGroup]="pushDataForm">
            <div class="form-group row c-align">
                <div class="config_outer">
                <label for="inputPassword" class="col-md-2 col-lg-2 col-sm-12 col-xs-12 col-form-label p_l_0"><strong>Configure</strong></label>
                <div class="col-md-10 col-lg-10 col-sm-12 col-xs-12 p_r_0">
                    <div class="outerRelated">
                  <input type="text" [readonly]="webUrlDisable" class="form-control" placeholder="Web address" #urlAddress formControlName="webhookUrl" [(ngModel)]="webhookUrl">
                  <span class="verifyButton" (click)="verifyUrl(urlAddress.value)">{{verifyTxt}}</span>
                  <span class="editAction" *ngIf="webUrlDisable" (click)="webUrlDisable = false">Edit</span>
                </div>
                </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                    <div><strong>Select Send Time</strong></div>
                    <div class="m_t_b_15">Once a contact meets the entry criteria for your automation, you can send your data instantly, or you can choose a number of days or hours to wait before you send.</div>
                </div>
              </div>
             
             
              <div class="row m_t_b_15">
                <div class="col-12">
                    <div class="m_b_7">When do you want to send data?</div>
                    <div class="d-flex send_unit_info c-align">
                        <div class="unit_input">
                            <input type="text" class="form-control" onkeypress='return event.charCode >= 48 && event.charCode <= 57' formControlName="intervalTime" [(ngModel)]="intervalTime">
                        </div>
                        <div>
                           <select class="form-control" [(ngModel)]="timeUnit" formControlName="timeUnit">
                            <option>Minute</option>
                            <option>Hour</option>
                            <option>Day</option>
                           </select> 
                        </div>
                       
                    </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                    <div class="m_b_7"><strong>Send Attributes</strong></div>
                    <ng-select class="form-control auto_height_select attributeDropdown" [multiple]="true" formControlName="fields" [(ngModel)]="fields"> 
                        <ng-option *ngFor="let item of paramKeylist" value="{{item.paramKey}}">
                              {{item.paramName}}
                        </ng-option>
                    </ng-select>
                </div>
              </div>

              <div class="row m_b_7">
                <div class="col-12">
                    <strong>Send Data</strong>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                    <div class="form-check form-check-inline" >
                        <input class="form-check-input" id="one_time" formControlName="sendDataType" type="radio" value="0" [(ngModel)]="sendDataType">
                        <label class="form-check-label" for="one_time">One Time</label>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                    <div class="form-check form-check-inline" >
                        <input class="form-check-input" id="every_update" formControlName="sendDataType" type="radio" value="1" [(ngModel)]="sendDataType">
                        <label class="form-check-label" for="every_update">On Every Update</label>
                    </div>
                </div>
              </div>

           
            <div class="saveTatButtonBox d-flex j-space-between">
                <div><button class="btn btn-dark red" type="button" (click)="deleteFlowData()" *ngIf="flowData">Delete</button></div>
                <div><button class="btn btn-dark" type="submit">{{pushDataStatus}}</button></div>
            </div>
        </form>
        </div>
    </ng-container>

    <ng-container *ngIf="viewTatWindow">
        <ng-container *ngIf="deleteConfirm">
            <div class="levelDeleteModel forgot-password-modal text-center">
                <div class="innerBoxConfirmModel">
                <div class="modal-header">
                    <h4 class="modal-title pull-left">Are you sure want to delete ?</h4>
                </div>
                <div class="modal-body">
                    <div>
                    <div>
                        <button class="btn btn btn-dark red" (click)="deleteConfirm = false">No</button>
                        <button class="btn btn btn-dark green" (click)="confirmDeleteLevel()">Yes</button>
                    </div>
                    </div>
                </div>
                </div>
                </div>
        </ng-container>
        <div class="modal-header">
            <h4 class="modal-title pull-left">TAT</h4>
            <button class="btn" id="addButtonWithIcon" type="button" (click)="levelAdd()"><img src="assets/images/plus-icon.png"> &nbsp; Level </button>
        </div>
        <div class="modal-body" *ngIf="LevelData">
            <form #tatJourneyForm="ngForm">
               <div class="levelGroup" *ngFor="let levelSeries of LevelData; let i=index">
                <div class="levelSettings">
                    <div class="leftSide">
                    <span class="levelTitle">Level {{i+1}}</span>
                    <div class="dropdown workScheduleBox m_r_15">
                        <button class="btn dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <ng-container *ngIf="levelSeries.level == 'LOW'">
                                <span class="levelColorStrip"></span>Low
                            </ng-container>

                            <ng-container *ngIf="levelSeries.level == 'MEDIUM'">
                                <span class="levelColorStrip medium"></span>Medium
                            </ng-container>

                            <ng-container *ngIf="levelSeries.level == 'HIGH'">
                                <span class="levelColorStrip high"></span>High
                            </ng-container>
                            
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenu2" #dropdownMenu2>
                            <ul class="weekdaysList">
                                <li (click)="selectTatLevel('LOW',i)" *ngIf="!levelSeries.disableLowType">
                                    <span class="levelColorStrip"></span> Low
                                </li>
                                <li (click)="selectTatLevel('MEDIUM',i)" *ngIf="!levelSeries.disableMediumType">
                                    <span class="levelColorStrip medium"></span> Medium
                                </li>
                                <li (click)="selectTatLevel('HIGH',i)">
                                    <span class="levelColorStrip high"></span> High
                                </li>
                            </ul>
                        </div>
                      </div>

                      <ng-container *ngIf="i == 0">
                        <span class="dropdownUnit bordered_column_inline m_l_0">
                            <select class="form-control" [(ngModel)]="levelSeries.paramKey" name="paramKey" [ngModelOptions]="{standalone: true}" (change)="paramKeySelected($event,i)">
                                <option value="">Select Field</option>
                              <ng-container *ngFor="let optionList of paramKeylist">
                                <ng-container *ngIf="optionList.paramDatatype == 'date' || optionList.paramDatatype == 'Date'">
                                  <option [value]="optionList.id">{{optionList.paramName}}</option>
                                </ng-container>
                              </ng-container>
                            </select>
                        </span>
                    </ng-container>
                    </div>

                    <div class="right_side">
                        <span class="trash_tat" (click)="trashTat(i)">
                            <img src="assets/images/trash-alt-gray.svg">
                        </span>
                        <!-- <span class="trash_tat" data-toggle="collapse" data-target="#levelSeriesData_{{i+1}}">
                            <img src="assets/images/plus-icon.png">
                        </span> -->
                        <a class="trash_tat" data-toggle="collapse" href="#levelSeriesData_{{i+1}}" role="button" aria-expanded="false" aria-controls="collapseExample" [class.collapsed]="!levelSeries.openCollapsible">
                            <img src="assets/images/plus-icon.png" class="plus_collapsed">
                            <img src="assets/images/minus-collapse.svg" class="minus_non_collapsed">
                        </a>
                    </div>
                </div>
                <div id="levelSeriesData_{{i+1}}" class="collapse" [class.show]="LevelData.length == 1 || levelSeries.openCollapsible">
                <div class="levelExecution">
                    <ng-container *ngIf="i > 0">
                    if <span class="levelExecutionInfo dropdownUnit bordered_column_inline">
                        <select class="form-control">
                            <ng-container *ngFor="let levelSeriesOptions of LevelData; let j = index">
                            <option *ngIf="j == i-1">
                                TAT Level {{j+1}}
                            </option>
                            </ng-container>
                        </select>
                    </span>
                    </ng-container> 
                    <span class="infoBySeverity" *ngIf="i > 0">executed an no activity within: </span>
                    <span class="infoBySeverity" *ngIf="i == 0">is not updated within: </span>
                    <div class="botton_time_info">
                    <ng-container *ngIf="i > 0">
                    <span class="previousTatTimeline bordered_column_inline p_5"> 
                        <ng-container *ngFor="let prevTatDuration of LevelData; let j=index">
                            <ng-container *ngIf="j < i">
                                {{prevTatDuration.tatDurationInput}} {{prevTatDuration.tatDurationUnit.substring(0,3)}}
                                <ng-container *ngIf="i > 1 && j < LevelData.length-2 && j != i-1">+</ng-container> 
                            </ng-container>
                        </ng-container> 
                    </span> + 
                    </ng-container>
                    <span class="bordered_column_inline inputUnit">
                        <input type="text" (input)="updateDuration($event,i)" [(ngModel)]="levelSeries.tatDurationInput" name="tatDurationInput" [ngModelOptions]="{standalone: true}" onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                    </span> 
                    <span class="dropdownUnit bordered_column_inline">
                        <select class="form-control tatDurationUnit" (change)="calculateDuration($event,i)" [(ngModel)]="levelSeries.tatDurationUnit" name="tatDurationUnit" [ngModelOptions]="{standalone: true}">
                            <option value="day">Day</option>
                            <option value="hours">Hours</option>
                            <option value="minutes">Minutes</option>
                        </select>
                    </span>
                    </div>
                </div>

                <div class="action_center">
                    <div class="action_title levelTitle">Action</div>
                    <ul class="action_list">
                        <li *ngIf="levelSeries.level == 'HIGH'">
                            <div class="borderradis sameLinebox required">
                               Reassign Lead
                            </div>
                            <div class="sameLinebox">
                                
                                <ng-select *ngIf="showRolesDropdown" class="form-control auto_height_select rolesDropdown" [multiple]="true" (change)="rolesSelected($event)"> 
                                    <ng-option *ngFor="let item of roleList" value="{{item.id}}">
                                          {{item.name}}
                                    </ng-option>
                                </ng-select>
                                
                                
                                <ng-select class="form-control auto_height_select" [multiple]="true" [(ngModel)]="levelSeries.userIds" name="userIds" [ngModelOptions]="{standalone: true}"> 
                                    
                                    <ng-template ng-header-tmp>
                                        <div>
                                          <button class="btn btn-link"
                                                  (click)="onSelectAllUser(i)">Select All</button>
                                          <button class="btn btn-link"
                                                  (click)="onClearAllUser(i)">Clear All</button>
                                        </div>
                                     </ng-template>

                                    <ng-option *ngFor="let item of tatUserList" value="{{item.id}}" [value]="item.id">
                                          {{item.userName}}
                                    </ng-option>
                                </ng-select>


                            </div>
                        </li>
                        <li>
                            <div class="borderradis sameLinebox">
                                <div class="d-flex start-align">
                                    <label class="check">
                                        <input type="checkbox" [disabled]="levelSeries.level == 'LOW'" [(ngModel)]="levelSeries.notifyOwner" name="notifyOwner" [ngModelOptions]="{standalone: true}">
                                        <span class="checkmark"></span>
                                    </label>
                                    <span class="c-pointer" [class.required]="levelSeries.level == 'LOW'">Inform Lead Owner</span>
                                </div>
                            </div>
                            <div class="borderradis sameLinebox" *ngIf="levelSeries.level == 'HIGH'">
                                <div class="d-flex start-align">
                                    <label class="check">
                                        <input type="checkbox" [(ngModel)]="levelSeries.notifyReassignOwner" name="notifyReassignOwner" [ngModelOptions]="{standalone: true}">
                                        <span class="checkmark"></span>
                                    </label>
                                    <span class="c-pointer">Inform Reassign User</span>
                                </div>
                            </div>
                        </li>
                        <li *ngIf="levelSeries.level != 'LOW'">
                            <div class="borderradis sameLinebox">
                                <div class="d-flex start-align">
                                    <label class="check">
                                        <input type="checkbox" [disabled]="levelSeries.level == 'MEDIUM'" [(ngModel)]="levelSeries.infoManager" name="infoManager" (change)="udpateinfoManager($event,i)" [ngModelOptions]="{standalone: true}">
                                        <span class="checkmark"></span>
                                    </label>
                                    <span class="c-pointer" [class.required]="levelSeries.level == 'MEDIUM'">Inform Manager</span>
                                </div>
                            </div>
                            <div class="sameLinebox">
                                <ng-select class="form-control auto_height_select" [multiple]="true" [(ngModel)]="levelSeries.roleIds" name="roleIds" [ngModelOptions]="{standalone: true}"> 
                                    <ng-container *ngIf="levelSeries.infoManager">
                                    <ng-option *ngFor="let item of parentRoleList" [value]="item.id" value="{{item.id}}">
                                          {{item.name}}
                                    </ng-option>
                                    </ng-container>
                                </ng-select>
                            </div>
                        </li>
                        <ng-container *ngIf="levelSeries.notifyOwner || levelSeries.notifyReassignOwner || levelSeries.infoManager ">
                        <li>
                            <div class="borderradis sameLinebox">
                                <div class="d-flex start-align">
                                    <label class="check">
                                        <input type="checkbox" (change)="loadEmailTemplateList($event,i)" [(ngModel)]="levelSeries.emailCheck" name="emailCheck" [ngModelOptions]="{standalone: true}">
                                        <span class="checkmark"></span>
                                    </label>
                                    <span class="c-pointer">Email Template</span>
                                </div>
                            </div>
                            <div class="sameLinebox">
                                <select class="form-control" (change)="templateSelected('email',$event,i);" [(ngModel)]="levelSeries.emailTemplateName" name="emailTemplateName" [ngModelOptions]="{standalone: true}">
                                    <option value="">Select Email Template</option>
                                    <ng-container *ngIf="levelSeries.emailCheck">
                                    <option *ngFor="let emailList of emailTemplateList" value="{{emailList.id}}">{{emailList.templateName}}</option>
                                    </ng-container>
                                </select>
                            </div>
                        </li>
                        <li>
                            <div class="borderradis sameLinebox">
                                <div class="d-flex start-align">
                                    <label class="check">
                                        <input type="checkbox" (change)="loadWebTemplateList($event,i)" [(ngModel)]="levelSeries.webCheck" name="webCheck" [ngModelOptions]="{standalone: true}">
                                        <span class="checkmark"></span>
                                    </label>
                                    <span class="c-pointer">Web Template</span>
                                </div>
                            </div>
                            <div class="sameLinebox">
                                <select class="form-control" (change)="templateSelected('web',$event,i)" [(ngModel)]="levelSeries.webTemplateName" name="webTemplateName" [ngModelOptions]="{standalone: true}">
                                    <option value="">Select web Template</option>
                                    <ng-container *ngIf="levelSeries.webCheck">
                                    <option *ngFor="let webTmpltList of webPushTemplateList" value="{{webTmpltList.id}}">{{webTmpltList.templateName}}</option>
                                    </ng-container>
                                </select>
                            </div>
                        </li>
                        <li>
                            <div class="borderradis sameLinebox">
                                <div class="d-flex start-align">
                                    <label class="check">
                                        <input type="checkbox" (change)="loadAndroidTemplateList($event,i)" [(ngModel)]="levelSeries.androidCheck" name="androidCheck" [ngModelOptions]="{standalone: true}">
                                        <span class="checkmark"></span>
                                    </label>
                                    <span class="c-pointer">Android Template</span>
                                </div>
                            </div>
                            <div class="sameLinebox">
                                <select class="form-control" (change)="templateSelected('android',$event,i)" [(ngModel)]="levelSeries.androidTemplateName" name="androidTemplateName" [ngModelOptions]="{standalone: true}">
                                    <option value="">Select Android Template</option>
                                    <ng-container *ngIf="levelSeries.androidCheck">
                                    <option *ngFor="let androidPushList of androidPushTemplateList" value="{{androidPushList.id}}">{{androidPushList.templateName}}</option>
                                    </ng-container>
                                </select>
                            </div>
                        </li>
                        </ng-container>
                        <li *ngIf="levelSeries.level == 'HIGH'">
                            <div class="borderradis sameLinebox">
                                <div class="d-flex start-align">
                                    <label class="check">
                                        <input type="checkbox" [(ngModel)]="levelSeries.repeatTat" name="repeatTat" [ngModelOptions]="{standalone: true}">
                                        <span class="checkmark"></span>
                                    </label>
                                    <span class="c-pointer">Repeat TAT on Reassignment</span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                </div>
               </div>
               <div class="saveTatButtonBox d-flex j-space-between">
                <button class="btn btn-dark red" type="button" (click)="viewTatWindow = false">Close</button>
                <button class="btn btn-dark" type="button" (click)="tatSubmit()">Save & Submit</button>
            </div>
            </form>
        </div>
    </ng-container>

    <ng-container *ngIf="!viewTatWindow && !viewPushDataWindow">
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{isUpdated ? 'Update' : 'Add'}} Lead Status</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="siteForm" (ngSubmit)="saveForm()">
            <div class="form-row">
                <div class="form-group col-12">
                    <label class="required">Name</label>
                    <input trim="blur" type="text" class="form-control" formControlName="stageName" (input)="isUpdateName($event.target.value)" maxlength="50">
                </div>
                <div class="form-group col-6">
                    <label class="required">Order</label>
                    <select class="form-control" formControlName="orderId">
                        <option value="">Select</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                        <option value="13">13</option>
                        <option value="14">14</option>
                        <option value="15">15</option>
                        <option value="16">16</option>
                        <option value="17">17</option>
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                        <option value="21">21</option>
                        <option value="22">22</option>
                        <option value="23">23</option>
                        <option value="24">24</option>
                        <option value="25">25</option>
                    </select>
                </div>
                <div class="form-group col-6">
                    <label class="required">Color Code</label>
                    <select class="form-control" formControlName="stageColour">
                        <option value="">Select</option>
                        <option value="#cd0141">Red</option>
                        <option value="#964B00">Brown</option>
                        <option value="#008000">Green</option>
                        <option value="#800080">Purple</option>
                        <option value="#4B0082">Indigo</option>
                        <!-- <option value="#FF0000">Red</option> -->
                        <option value="#C0C0C0">Silver</option>
                        <option value="#FFFF00">Yellow</option>
                        <option value="#008080">Teal</option>
                        <option value="#FFC0CB">Pink</option>
                    </select>
                </div>
                <div class="form-group col-12">
                    <label class="required">Type</label>
                    <div>
                        <div class="form-check form-check-inline" tooltip="You will get only remark field to update lead." tooltipPlacement="top">
                            <input class="form-check-input" type="radio" name="stageType" formControlName="stageType" id="stageType1" value="DEFAULT">
                            <label class="form-check-label" for="stageType1">Default</label>
                        </div>
                        <div class="form-check form-check-inline" tooltip="You will get date range selection, remark fields and switch to another user option." tooltipPlacement="top">
                            <input class="form-check-input" type="radio" name="stageType" formControlName="stageType" id="stageType2" value="FOLLOW_UP">
                            <label class="form-check-label" for="stageType2">Follow Up</label>
                        </div>
                        <div class="form-check form-check-inline" tooltip="You will get date range selection, remark fields for update leads." tooltipPlacement="top">
                            <input class="form-check-input" type="radio" name="stageType" formControlName="stageType" id="stageType3" value="CALL_LATER">
                            <label class="form-check-label" for="stageType3">Call Later</label>
                        </div>
                        <div class="form-check form-check-inline" tooltip="Archived type leads will not visible on audience dashboard." tooltipPlacement="top">
                            <input class="form-check-input" type="radio" name="stageType" formControlName="stageType" id="stageType4" value="ARCHIVED">
                            <label class="form-check-label" for="stageType4">Archived</label>
                        </div>
                        <div class="form-check form-check-inline" tooltip="Completed Leads are reference for close status." tooltipPlacement="top">
                            <input class="form-check-input" type="radio" name="stageType" formControlName="stageType" id="stageType4" value="COMPLETED">
                            <label class="form-check-label" for="stageType4">Completed</label>
                        </div>
                    </div>
                </div>
                <div class="form-group col-12" *ngIf="isUpdated && isShowChangeOlder">
                    <label class="required">Do you want to update old lead data with this lead status?</label>
                    <div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="changeOlder" formControlName="changeOlder" id="inlineRadio1" value="1">
                            <label class="form-check-label" for="inlineRadio1">Yes</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="changeOlder" formControlName="changeOlder" id="inlineRadio2" value="0">

                            <label class="form-check-label" for="inlineRadio2">No</label>
                        </div>
                    </div>
                </div>
                <div class="form-group col-12" >
                    <label class="">Auto Assignment</label>
                <div class="">
                    <p class="font-11">Select users to assign leads based on lead status. If lead status change from other status to this. lead will be assigned round robin basis on below selected user list. </p>
                    <label class="control-label pt-0">User List.</label>
                <ng-select [items]="subUserList"  class="params userListMultiSelect" bindLabel="userProfileName" [ngModelOptions]="{ standalone : true }"  [(ngModel)]="selectUserList"   fromControlName="userList" [multiple]="true" bindValue="id" > </ng-select>
                </div>
                </div>

                <div class="form-group col-12" >
                    <label class="">Allowed Roles</label>
                <div class="">
                    <!-- <label class="control-label pt-0">User List.</label> -->
                <ng-select [items]="roleList"  class="params userListMultiSelect" bindLabel="name" [ngModelOptions]="{ standalone : true }"  [(ngModel)]="selectUserRoles"   fromControlName="roles" [multiple]="true" bindValue="id" > </ng-select>
                </div>
                </div>

                <div class="form-group col-12" >
                    <label class="">Tag</label>
                <div class="">
                    <!-- <label class="control-label pt-0">User List.</label> -->
                    <input type="text" class="form-control" fromControlName="tags" [(ngModel)]="tags" [ngModelOptions]="{ standalone : true }">
                </div>
                </div>

                <div class="col-12 text-right mt-1 mb-2 d-flex levelOuterFlexWrapper" *ngIf="tatDataGet && tatDataGet.tatList">
                    <div class="tatlevelTitle">TAT :</div>
                    <div class="tatLeveButton" *ngFor="let tatList of tatDataGet.tatList; let i=index" [class.low]="tatList.level == 'LOW'" [class.medium]="tatList.level == 'MEDIUM'" [class.high]="tatList.level == 'HIGH'">
                        Level {{i+1}}
                        <span class="lowSign levelSign"></span>
                        <span class="mediumSign levelSign"></span>
                        <span class="highSign levelSign"></span>
                        <span class="levelEditIcon icon-edit" (click)="editLevel(tatList,tatDataGet.tatList,i)"></span>
                    </div>
                   </div>

                   <div class="form-group col-12" >
                    <label class="">Mandatory Attributes</label>
                <div class="">
                     <ng-select [items]="customAttributes"  class="params userListMultiSelect" bindLabel="paramName" [multiple]="true"  fromControlName="userList" [ngModelOptions]="{ standalone : true }" [(ngModel)]="selectAttributes" bindValue="paramKey" > </ng-select>
                </div>
                </div>
                
                <div class="col-12 text-right mt-1 mb-2">
                    <button class="btn btn-dark pushDataBtn" type="button" (click)="addPushData()" *ngIf="pushDataSubscribe">Push Data</button>
                    <button *ngIf="addTatEnable && isSubscribe" class="btn btn-dark addTatButton" type="button" (click)="disableAddLevel = false; addTat()">Add TAT</button>
                    <button class="btn btn-dark" type="submit">{{isUpdated ? 'Update' : 'Save'}}</button>
                </div>
            </div>
        </form>
    </div>
    </ng-container>
</ng-template>

<ng-template #confirmModal>
    <form>
        <div class="forgot-password-modal">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Do you want to remove?</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
            </div>
            <div class="modal-body modal-user-management">
                <div class="form-group mb-0">
                    <p class="pt-2">
                        If you will delete this status, Records previously marked with status also removed.
                    </p>
                    <div class="d-flex justify-content-end mt-5">
                        <div>
                            <button class="btn remove-user-btn mr-4" (click)="modalRef.hide()" type="button">
                  Dismiss
                </button>
                        </div>
                        <div>
                            <button class="btn save-btn" type="button" (click)="deleteItem()">
                  Yes
                </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</ng-template>



<ng-template #flowDataModal>
        <div class="forgot-password-modal">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Data Flow</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
            </div>
                <div class="modal-body">
                    <form (ngSubmit)="pushDataSubmit()" [formGroup]="pushDataForm">
                     <div class="form-group row c-align">
                         <div class="config_outer">
                         <label for="inputPassword" class="col-md-2 col-lg-2 col-sm-12 col-xs-12 col-form-label p_l_0"><strong>Configure</strong></label>
                         <div class="col-md-10 col-lg-10 col-sm-12 col-xs-12 p_r_0">
                             <div class="outerRelated">
                           <input type="text" [readonly]="webUrlDisable" class="form-control" placeholder="Web address" #urlAddress formControlName="webhookUrl" [(ngModel)]="webhookUrl">
                           <span class="verifyButton" (click)="verifyUrl(urlAddress.value)">{{verifyTxt}}</span>
                           <span class="editAction" *ngIf="webUrlDisable" (click)="webUrlDisable = false">Edit</span>
                         </div>
                         </div>
                         </div>
                       </div>
         
                       <div class="row">
                         <div class="col-12">
                             <div><strong>Select Send Time</strong></div>
                             <div class="m_t_b_15">Once a contact meets the entry criteria for your automation, you can send your data instantly, or you can choose a number of days or hours to wait before you send.</div>
                         </div>
                       </div>
                       
                       <div class="row m_t_b_15">
                         <div class="col-12">
                             <div class="m_b_7">Send this data in:</div>
                             <div class="d-flex send_unit_info c-align">
                                 <div class="unit_input">
                                     <input type="text" class="form-control" onkeypress='return event.charCode >= 48 && event.charCode <= 57' formControlName="intervalTime" [(ngModel)]="intervalTime">
                                 </div>
                                 <div>
                                    <select class="form-control" [(ngModel)]="timeUnit" formControlName="timeUnit">
                                     <option>Minute</option>
                                     <option>Hour</option>
                                     <option>Day</option>
                                    </select> 
                                 </div>
                                 <div>
                                     After a contact  enters the automation.
                                 </div>
                             </div>
                         </div>
                       </div>
         
                       <div class="row">
                         <div class="col-12">
                             <div class="m_b_7"><strong>Send Attributes</strong></div>
                             <ng-select class="form-control auto_height_select attributeDropdown" [multiple]="true" formControlName="fields" [(ngModel)]="fields"> 
                                 <ng-option *ngFor="let item of paramKeylist" value="{{item.paramKey}}">
                                       {{item.paramName}}
                                 </ng-option>
                             </ng-select>
                         </div>
                       </div>
         
                       <div class="row m_b_7">
                         <div class="col-12">
                             <strong>Send Data</strong>
                         </div>
                       </div>
         
                       <div class="row">
                         <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                             <div class="form-check form-check-inline" >
                                 <input class="form-check-input" id="one_time" formControlName="sendDataType" type="radio" value="0" [(ngModel)]="sendDataType">
                                 <label class="form-check-label" for="one_time">One Time</label>
                             </div>
                         </div>
                         <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                             <div class="form-check form-check-inline" >
                                 <input class="form-check-input" id="every_update" formControlName="sendDataType" type="radio" value="1" [(ngModel)]="sendDataType">
                                 <label class="form-check-label" for="every_update">On Every Update</label>
                             </div>
                         </div>
                       </div>
         
                    
                     <div class="saveTatButtonBox d-flex j-space-between">
                        <div><button class="btn btn-dark red" type="button" (click)="deleteFlowData()" *ngIf="flowData">Delete</button></div>
                         <div><button class="btn btn-dark" type="submit">{{pushDataStatus}}</button></div>
                     </div>
                 </form>
                 </div>
        </div>
</ng-template>
