<header id="site-menu">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-2 col-sm-5 col-8">
                <div class="logo">
                  <a href="javascript:void(0);"  (click)="goToPosition('banner-box')">
                    <img src="assets/images/cronberry-color.svg" alt="Cronberry" title="Cronberry" />
                  </a>
                </div>
            </div>
            <div class="col-md-10 col-sm-12 col-12 menu  d-md-block d-lg-block d-xl-block"> 
                <ul class="contact-info">
                    <!-- <li> <a href="tel:+919024466792"><img src="assets/images/phone.svg" title="Phone" alt="Phone" /> +91 9024466792</a></li> -->
                    <li> <a href="mailto:sales@cronberry.com"><img src="assets/images/envelope.svg" alt="email Us" title="email Us" /> sales@cronberry.com</a></li>
                    <li> <a href="https://blog.cronberry.com/" target="_blank">Blog</a></li>
                    <li dropdown class="dropdown"><a href="javascript:void(0);" dropdownToggle  class="dropdown-toggle">Channels <span class="caret"></span></a>
                        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                            <li role="menuitem"><a class="dropdown-item" [routerLink]="['/email-marketing-automation-software']">Email</a></li>
                            <li role="menuitem"><a class="dropdown-item" [routerLink]="['/sms-marketing-automation']">SMS </a></li>
                            <li role="menuitem"><a class="dropdown-item" [routerLink]="['/in-app-notification']">In-App </a></li>
                            <li role="menuitem"><a class="dropdown-item" [routerLink]="['/push-notification-service']">Push </a></li>
                        </ul>
                    </li>
                    <li dropdown class="dropdown"><a href="javascript:void(0);" dropdownToggle class="dropdown-toggle" >Markets <span class="caret"></span></a>
                        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                            <li role="menuitem"><a class="dropdown-item" [routerLink]="['/education-marketing-automation']">Education</a></li>
                            <li role="menuitem"><a class="dropdown-item" [routerLink]="['/real-estate-marketing-automation']">Real Estate </a></li>
                            <li role="menuitem"><a class="dropdown-item" [routerLink]="['/ecommerce-marketing-automation']">Ecommerce </a></li>
                            <li role="menuitem"><a class="dropdown-item" [routerLink]="['/insurance-marketing-automation-software']">Insurance </a></li>
                            <li role="menuitem"><a class="dropdown-item" [routerLink]="['/loan-marketing-automation']">Loans</a></li>
                            <li role="menuitem"><a class="dropdown-item" [routerLink]="['/digital-marketing-automation']">Digital Marketing </a></li>
                            <li role="menuitem"><a class="dropdown-item" [routerLink]="['/bank-marketing-automation-software']">Banking </a></li>
                            <li role="menuitem"><a class="dropdown-item" [routerLink]="['/healthcare-marketing-automation']">Healthcare </a></li>
                            <li role="menuitem"><a class="dropdown-item" [routerLink]="['/travel-marketing-automation-tool']">Travel </a></li>
                        </ul>
                    </li>
                    &nbsp;
                    <li>
                        <a href="javascript:void(0);" class="btn btn-default" [routerLink]="['/pricing']"
                            class="btn btn-outline-primary" >Features</a>
                    </li>
                    <li class="pr-0">
                        <a *ngIf="!isLogin" [routerLink]="['/admin/sign-in']" class="btn btn-default" class="btn btn-default" >Login</a>
                        <a *ngIf="isLogin" (click)="gotoDashboard()" class="btn btn-default" class="btn btn-default">Dashboard</a>
                    </li>
                </ul>
            </div>
            <div class="col-sm-7 col-4">
                <a class="d-md-none d-sm-block float-right click-menu" (click)="openMenu()">
                    <span class="icon-bar"></span> <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                </a>
            </div>
        </div>
    </div>
</header>
<div class="header-block"></div>
   
  
