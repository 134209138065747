import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { FormGroup, Validators, FormBuilder, NgForm } from '@angular/forms';
import { noWhitespaceValidator } from '../../../../shared/models/no-whitespace-validator';
import { BsModalRef, BsModalService, TabsetComponent } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Global } from "../../../../shared/models/global.model";
import { CampaignService } from "../../../../core/services/campaign.service";
import { ProjectService } from '../../../../core/services/project.service';
import { Subject } from 'rxjs';
import { AppConstants } from '../../../../../app/constants/app.constants';
import { CustomFormService } from '../../../../../app/modules/customForm/customForm.service';
import { ApiConstants } from '../../../../../app/constants/api.constants';
import { GalleryPopupComponent } from '../campaign-gallery/gallery-popup.component';
import { UrlShortnerService } from '../../../../core/services/urlShortner.service';
import { AndroidPushTemplateObj, EmailTemplateObj, IosPushTemplateObj, PushTemplateObj, SmsTemplateObj, WebPushTemplateObj, WhatsappTemplateObj } from '../../../../core/modal/campaign';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { environment } from '../../../../../environments/environment';
import { CacheService } from '../../../../core/services/cache.service';
import { ConfigurationService } from '../../../../core/services/configuration.service';
declare const CKEDITOR;

@Component({
    selector: 'campaign-template',
    templateUrl: './campaign-template.component.html',
    styleUrls: ['./campaign-template.component.scss']
})
export class CampaignTemplateComponent implements OnInit, OnDestroy {
    formPushNotification: FormGroup;
    @ViewChild("templatePushNotification") templatePushNotification: TemplateRef<any>;
    @ViewChild("templateEmailCampaign") templateEmailCampaign: TemplateRef<any>;
    @ViewChild("templateSMSCampaign") templateSMSCampaign: TemplateRef<any>;
    @ViewChild("templateWhatsappCampaign") templateWhatsappCampaign: TemplateRef<any>;
    @ViewChild("syncTemplateWhatsappCampaign") syncTemplateWhatsappCampaign: TemplateRef<any>;
    @ViewChild("syncTemplateList") syncTemplateList: TemplateRef<any>;
    @ViewChild("importEmailCampaign") importEmailCampaign: TemplateRef<any>;
    modalRef: BsModalRef;
    modalRefGallery: BsModalRef;
    modalimportEmail: BsModalRef;
    formSMSCampaign: FormGroup;
    formWhatsappCampaign: FormGroup;
    formEmailCampaign: FormGroup;
    smsTextLength: any = 0;
    isEditorView: boolean = true;
    inputParamsSelected : any = [];
    validFilters: any = false;
    inputParams: any = [];
    emailTemplateList: EmailTemplateObj[] = [];
    androidPushTemplateList: AndroidPushTemplateObj[] = [];
    iosPushTemplateList: IosPushTemplateObj[] = [];
    webPushTemplateList: WebPushTemplateObj[] = [];
    smsTemplateList: SmsTemplateObj[] = []; 
    whatsappTemplateList:WhatsappTemplateObj[] = []; 
    defaultEmailTemplateList: EmailTemplateObj[] = []; 
    isShowEmoji: boolean = false;
    activeTab: string = 'push';
    templateId: number;
    templateIndex: number;
    isUpdate:boolean = false;
    updateId:number = null;
    instance: any;
    config: any;
    push_url:any;
    pushServiceUrl:any = environment.pushServiceUrl;
    // iosPushUrl:any = environment.pushServiceUrl;
    // webPushUrl:any = environment.pushServiceUrl;
    smsPushUrl:any = environment.smsPushUrl;
    emailPushUrl:any = environment.emailPushUrl;
    whatsappPushUrl:any = environment.whatsappApiUrl;
    ckeditorData:Subject<string> = new Subject();
    headerUrl:any;
    headerUrlText:any;
    inputParamsFileType:any;
    wpBDetailsList:any;
    wpBDetails:any;
    wabaUrl:any = environment.wabaUrl;
    wabaTemplateListData:any;
    syncTemplateImport:any;
    synctemplateName:any;
    paramsSelected:any;
    syncheaderUrl:any;
    synclanguageCode:any;
    bodyParams:any=[];
    arrayForBodyParams:any=[];
    buttonsParamArray:any = [];
    allParamsForSync:any=[];
    invalidParams:boolean = false;
    @ViewChild('syncForm',null) syncForm:NgForm;
    @ViewChild('siteTab') siteTab : TabsetComponent;
    @ViewChild('pushTemplate') pushTemplate : TabsetComponent;
    constructor(
        private formBuilder: FormBuilder,
        private modalService: BsModalService,
        private toastr: ToastrService,
        public global: Global,
        private campaignService: CampaignService,
        private projectService: ProjectService,
        private customFormService: CustomFormService,
        private urlShortnerService: UrlShortnerService,
        private authenticationService: AuthenticationService,
        private route: ActivatedRoute,
        private location:Location,
        private cacheService:CacheService,
        private configurationService:ConfigurationService
    ) {
        this.config = this.global.CKEditorConfig(500,'email');
    }

    ngOnInit() {
        this.prepareFormPushNotification();
        this.prepareFormSMSCampaign();
        this.prepareFormWhatsappCampaign();
        this.prepareFormEmailCampaign();
        this.getInputParams();

        
          this.configurationService.getConfiguration({},this.whatsappPushUrl).subscribe(data => {
            if (data.status) {
              this.wpBDetailsList = data.data;            
              for(let i=0; i<this.wpBDetailsList.length; i++)
              {
                if(this.wpBDetailsList[i].id > 0)
                {
                  this.wpBDetails = this.wpBDetailsList[i];   
                }
              }
            }
          });

        this.route.params.subscribe(params => {
          if(params.type != undefined){
            this.activeTab = params.type;
            var i;
            if(this.activeTab == 'androidpush'){
              i = 0
              this.siteTab.tabs[i].active = true;
              this.activeTab = 'push';
            } 
            else if(this.activeTab == 'iospush'){
              i = 0
              this.siteTab.tabs[i].active = true;
              // this.pushTemplate.tabs[1].active = true
              this.activeTab = 'push';
            } else if(this.activeTab == 'webpush'){
              i = 0
              this.siteTab.tabs[i].active = true;
              // this.pushTemplate.tabs[2].active = true
              this.activeTab = 'push';
            } 
            else if(this.activeTab == 'sms'){
              i = 1
              this.siteTab.tabs[i].active = true;
            } else if(this.activeTab == 'email'){
              i = 2
              this.siteTab.tabs[i].active = true;
            } else if(this.activeTab == 'whatsapp'){
              i = 3
              this.siteTab.tabs[i].active = true;
            }
            
            
            
          }
          this.getList();
        })
    }

    allDynamicValRemoved(event){
      if(event == 'push'){
      this.formPushNotification.controls.message.setValue('');
      } else if(event == 'sms'){
        this.formSMSCampaign.controls.smsText.setValue('');
      } else if(event == 'whatsapp'){
        this.formWhatsappCampaign.controls.smsText.setValue('');
      } else if(event == 'email'){
        this.formEmailCampaign.controls.smsText.setValue('');
      }

      
    }

    back()
    {
        this.location.back();
    }

    ngOnDestroy(){
      if(this.modalRef)
      this.modalRef.hide();
      if(this.modalRefGallery)
      this.modalRefGallery.hide();
    }

    getList(pushServiceUrl?){      
        this.isUpdate = false;
        this.updateId = null;
        this.inputParamsSelected = [];
        if(this.activeTab == 'push'){
          // this.activeTab = 'push';
          this.getPushTemplateList('push','reload');
        } else if(this.activeTab == 'sms'){
            this.getPushTemplateList('sms');
        }else if(this.activeTab == 'email'){
            this.getPushTemplateList('email');
        }else if(this.activeTab == 'whatsapp'){          
          this.getPushTemplateList('whatsapp');
      }
    }


    prepareFormPushNotification() {
        this.formPushNotification = this.formBuilder.group({
            templateName: ["",
                Validators.compose([
                    Validators.required,
                    noWhitespaceValidator,
                    Validators.minLength(3),
                    Validators.maxLength(200),
                    Validators.pattern(this.global.AlphaNumPatternWithChar)
                ])
            ],
            title: [ "",
                Validators.compose([
                    Validators.required,
                    noWhitespaceValidator,
                    Validators.minLength(3),
                    Validators.maxLength(200),
                    Validators.pattern(this.global.AlphaNumPatternWithChar)
                ])
            ],
            paramsSelected: [''],
            message: [ "",
                Validators.compose([
                    Validators.required,
                    noWhitespaceValidator,
                    Validators.minLength(5),
                    Validators.maxLength(1024)
                ])
            ],
            deepLink: [""],
            notificationIcon: [""],
            notificationImage: [""],
            notImage: [""],
            platforms: [""]
        });
    }

    prepareFormSMSCampaign() {
        this.formSMSCampaign = this.formBuilder.group({
            templateName: [
                "",
                Validators.compose([
                Validators.required,
                noWhitespaceValidator,
                Validators.minLength(3),
                Validators.maxLength(200),
                Validators.pattern(this.global.AlphaNumPatternWithChar)
                ])
            ],
            smsText: [
                "",
                Validators.compose([
                Validators.required,
                noWhitespaceValidator,
                Validators.minLength(5),
                Validators.maxLength(701)
                ])
            ],
            paramsSelected: ['']
        });
        // this.setValidation(this.formSMSCampaign);
        this.formSMSCampaign.get("smsText").valueChanges.subscribe(content => {
          this.smsTextLength = content != null ? content.length : 0;
        });
    }


    prepareFormWhatsappCampaign() {
      this.formWhatsappCampaign = this.formBuilder.group({
          templateName: [
              "",
              Validators.compose([
              Validators.required,
              noWhitespaceValidator,
              Validators.minLength(3),
              Validators.maxLength(200),
              Validators.pattern(this.global.AlphaNumPatternWithChar)
              ])
          ],
          smsText: [
              "",
              Validators.compose([
              Validators.maxLength(500)
              ])
          ],
          paramsSelected: [''],
          headerUrl:[''],
          languageCode:[],
          headerUrlText:[]

      });
      // this.setValidation(this.formSMSCampaign);
      this.formWhatsappCampaign.get("smsText").valueChanges.subscribe(content => {
        this.smsTextLength = content != null ? content.length : 0;
      });
  }

    prepareFormEmailCampaign() {
        this.formEmailCampaign = this.formBuilder.group({
            templateName: [ "",
                Validators.compose([
                    Validators.required,
                    noWhitespaceValidator,
                    Validators.minLength(5),
                    Validators.maxLength(1000),
                    Validators.pattern(this.global.AlphaNumPatternWithChar)
                ])
            ],
            emailSubject: [ "",
                Validators.compose([
                    Validators.required,
                    noWhitespaceValidator,
                    Validators.minLength(5),
                    Validators.maxLength(1000)
                ])
            ],
            emailText: [ "",
                Validators.compose([
                    Validators.required,
                    noWhitespaceValidator,
                    Validators.minLength(5)
                ])
            ],
            paramsSelected: ['']
        });
        // this.setValidation(this.formEmailCampaign);
    }

    headerImageUrl($event,type){
      if(type == 'input'){
          this.headerUrl = [];
          this.syncheaderUrl = [];
      } else if(type == 'select'){
          this.headerUrlText = "";
      }
  }

  openModal(template: TemplateRef<any>, id?: number) {
    if(!this.isUpdate){
      this.inputParamsSelected = [];
      this.formPushNotification.reset();
      this.formSMSCampaign.reset();
      this.formEmailCampaign.reset();
    }
    if(id != undefined){
      this.templateId = id;
    }
    this.modalRef = this.modalService.show(template,{
      backdrop: "static",
      class: "modal-xl"
    });
  }

  openModalSync(template: TemplateRef<any>) {
    this.cacheService.getWabaSyncTmpltList(this.wabaUrl,this.wpBDetails.authorization,this.wpBDetails.isMigrated);
    if(!this.isUpdate){
      this.bodyParams = []
      this.buttonsParamArray = []
      this.allParamsForSync
      this.inputParamsSelected = [];
      this.formPushNotification.reset();
      this.formSMSCampaign.reset();
      this.formEmailCampaign.reset();
    }
    
    this.cacheService.sendWabaTemplateData().subscribe(data=>{
      if(data.status){
        this.wabaTemplateListData = data.data;
      }
      })
    
    this.modalRef = this.modalService.show(template,{
      backdrop: "static",
      class: "modal-md"
    });
    
  }

  importTmplt(data,editForm){
    if(editForm){
    this.syncTemplateImport = JSON.parse(data.templateJson);    
    this.syncTemplateImport.id = data.id;
    if(this.syncTemplateImport.bodyParameters && this.syncTemplateImport.bodyParameters.length > 0){
      this.syncTemplateImport.bodyParameters.forEach((element,index) => {
        if(element.param_text){
          this.bodyParams[index] = element.param_text
        } else if(element.param_select){
          this.bodyParams[index] = '['+element.param_select+']';
        }
        
      });
    }
    if(this.syncTemplateImport.buttonParameters && this.syncTemplateImport.buttonParameters.length > 0){
      this.syncTemplateImport.buttonParameters.forEach((element,index) => {
        if(element != "nonParameter"){
        if(element.param_text){
          this.buttonsParamArray[index] = element.param_text
        } else if(element.param_select){
          this.buttonsParamArray[index] = '['+element.param_select+']';
        }
      } 
      });
    }
    }
    if(!editForm){
      this.synclanguageCode = "";
      this.syncheaderUrl = [];
      this.headerUrlText = "";
      this.syncTemplateImport = data;
      this.syncTemplateImport.bodyParameters = [];
      this.syncTemplateImport.buttonParameters = [];
      this.syncTemplateImport.id = null
    }
    this.synctemplateName = this.syncTemplateImport.name;
    if(this.syncTemplateImport.components){
      this.syncTemplateImport.components.forEach((element,index) => {
        if(element.type == 'HEADER'){
          this.syncTemplateImport.headerParams = true;
          if(element.format){
            this.syncTemplateImport.components.headerFormat = element.format;
          }
          if(element.text){
            this.syncTemplateImport.components.headerText = element.text;
          }
        }

        if(element.type == 'BODY'){
          this.syncTemplateImport.bodyParams = true;
          if(element.text){
            if(element.tempBodytext == undefined){
            element.tempBodytext = element.text
            }
            this.syncTemplateImport.bodyText = element.text;
            this.syncTemplateImport.bodyParameters = this.syncTemplateImport.bodyParameters ? this.syncTemplateImport.bodyParameters :[]
            // this.syncTemplateImport.bodyParameters = element.example.body_text[0];
            if(!editForm){
            if(element.example && element.example.body_text[0] && element.example.body_text[0].length > 0){
              element.example.body_text[0].forEach(param => {
                this.syncTemplateImport.bodyParameters.push({'param_text':"",'type':'','param_select':''});
              });
            }
          }
          }
        }

        if(element.type == 'BUTTONS'){
          this.syncTemplateImport.buttonParams = true;
          if(element.buttons && element.buttons.length > 0){
            this.syncTemplateImport.components.buttons = element.buttons;
            this.syncTemplateImport.buttonParameters = this.syncTemplateImport.buttonParameters ? this.syncTemplateImport.buttonParameters :[]
            // this.syncTemplateImport.buttonParameters = element.buttons;
            if(!editForm){
            element.buttons.forEach(button => {
              if(!button.tempUrl){
                button.tempUrl = button.url;
              }
              if(button.url && button.url.indexOf('{{1}}') != -1){
              this.syncTemplateImport.buttonParameters.push({'param_text':"",'type':'','param_select':''});
              } else{
                this.syncTemplateImport.buttonParameters.push('nonParameter')
              }
            });
          }
          
          }
        }
      });
    }
    //this.setWhatsappData(data)
    if(this.modalRef){
    this.modalRef.hide();
    }
    this.openModal(this.syncTemplateWhatsappCampaign);
    this.syncheaderUrl = "";
    if(editForm){
    setTimeout(() => {
    let headerUrlSet;
    let headerUrl;
    if(data.headerUrl){
      if(data.headerUrl.includes('http://') == true || data.headerUrl.includes('https://') == true){
        headerUrlSet = data.headerUrl;
        this.headerUrlText = headerUrlSet;
        this.syncheaderUrl = [];
      }
      if(data.headerUrl.includes('[') == true && data.headerUrl.includes(']') == true){
        headerUrl = data.headerUrl.replace('[','').replace(']','');
        this.syncheaderUrl = headerUrl;
        this.headerUrlText = "";
      }
    }
    this.synclanguageCode = data.languageCode;
    }, 500);    
  }
  }

  bodyParamSelected(value,paramIndex){
    this.arrayForBodyParams[paramIndex] = value;
    this.arrayForBodyParams.forEach(element => {
       this.bodyParams[paramIndex] = '['+element+']';
    });
    this.syncTemplateImport.bodyParameters.forEach((elem,elemindex)=>{
      if(elemindex == paramIndex){
        elem.param_select = value;
        elem.param_text = "";
        elem.type = "select";
        this.bodyParams[elemindex] =  '['+value+']';
      }
    });
  }

  valueAddForTextParam(eventVal,index,type){
    let bodyParams = []
    if(type == 'bodyParams'){
    this.syncTemplateImport.bodyParameters.forEach((elem,elemindex)=>{
      if(elemindex == index){
        elem.param_select = "";
        elem.param_text = eventVal;
        elem.type = "text";
        this.bodyParams[elemindex] =  eventVal;
      }
    });
  } else if(type == 'buttonParams'){
    this.syncTemplateImport.buttonParameters.forEach((elem,elemindex)=>{
      if(elemindex == index){
        this.buttonsParamArray[index] = eventVal;
        elem.param_select = "";
        elem.param_text = eventVal;
        elem.type = "text";
      }
      if(elem == 'nonParameter'){
        this.buttonsParamArray[elemindex]='nonParameter'
      }
    });
  }
  }


  buttonParamSelected(value,buttonIndex){    
    this.buttonsParamArray[buttonIndex]='['+ value +']'
    this.allParamsForSync[buttonIndex]=value;

    this.syncTemplateImport.buttonParameters.forEach((elem,elemindex)=>{
      if(elemindex == buttonIndex){
        elem.param_select = value;
        elem.param_text = "";
        elem.type = "select";
      } 
      if(elem == 'nonParameter'){
        this.buttonsParamArray[elemindex]='nonParameter'
      }
    });
  }

  saveWabaForm(formVals){
    console.log(formVals)
    let formKeys = Object.keys(formVals);
    this.invalidParams = false
    if(this.syncTemplateImport.bodyParameters && this.syncTemplateImport.bodyParameters.length){
      this.syncTemplateImport.bodyParameters.forEach(element => {
        if(element.type == "" || (element.type == 'select' && element.param_select == "") || (element.type == 'text' && element.param_text == "")){
          this.toastr.error('All fields are required');
          this.invalidParams = true;
          return;
        }
      });
    }

    if(this.syncTemplateImport.buttonParameters && this.syncTemplateImport.buttonParameters.length){
      this.syncTemplateImport.buttonParameters.forEach(element => {
        if(element != 'nonParameter'){
        if(element.type == "" || (element.type == 'select' && element.param_select == "") || (element.type == 'text' && element.param_text == "")){
          this.toastr.error('All fields are required');
          this.invalidParams = true;
          return;
        }
      }
      });
    }    
    if(this.invalidParams){
    return
    }
    
    if(formVals.syncheaderUrl && formVals.headerUrlText == ""){
      let setHeaderKey = formVals.syncheaderUrl.paramKey ? formVals.syncheaderUrl.paramKey : formVals.syncheaderUrl;
      formVals.headerUrl = '['+setHeaderKey+']';
    } else if(formVals.syncheaderUrl == undefined || formVals.syncheaderUrl.length == 0  && formVals.headerUrlText != ""){
      formVals.headerUrl = formVals.headerUrlText;
    }
    
    let buttonParams;
    let allParams = [];
    let allParamsWithDetails = [];
    this.syncTemplateImport.buttonParameters = []
    if(this.buttonsParamArray){
    this.buttonsParamArray.forEach((element,index) => {
      this.syncTemplateImport.components.forEach((buttonelement) => {
        if(buttonelement.type == "BUTTONS"){
          buttonelement.buttons.forEach((button,buttonindex) => {
            if(buttonindex == index){
              if(button.tempUrl && button.tempUrl.indexOf('{{1}}') != -1){
              button.url =  button.tempUrl.replace('{{1}}',element);
              }
            }
          });
      }
    });
    if(element != 'nonParameter'){
      buttonParams = buttonParams ? buttonParams+','+element:element;
    } else if(element == 'nonParameter'){
      buttonParams = buttonParams ? buttonParams+','+"null" : "null";
    }
    });
    this.buttonsParamArray.forEach((element,index) => {
      if(element != 'nonParameter'){
      let elemObj = {'param_text':"",'type':'','param_select':''};
      if(element.indexOf('[') != -1 && element.indexOf(']') != -1){
        element = element.replace('[',"").replace(']',"");
        elemObj.param_select = element;
        elemObj.type = "select";
        this.syncTemplateImport.buttonParameters.push(elemObj);
      } else if(element != 'nonParameter'){
        elemObj.param_text = element;
        elemObj.type = "text";
        this.syncTemplateImport.buttonParameters.push(elemObj)
      }
      allParams.push(element);
    } else if(element == 'nonParameter'){
      this.syncTemplateImport.buttonParameters.push("nonParameter");
    }
    });  
  }
  let bodyParam = this.bodyParams ? this.bodyParams : [];
  this.syncTemplateImport.bodyParameters = [];
  this.syncTemplateImport.components.forEach(element => {
    if(element.type == "BODY"){
      this.syncTemplateImport.bodyText = element.tempBodytext;
    }
});


  bodyParam.forEach((element,index) => {
    let elemObj = {'param_text':"",'type':'','param_select':''};
    if(element.indexOf('[') != -1 && element.indexOf(']') != -1){
      element = element.replace('[',"").replace(']',"");
      elemObj.param_select = element;
      elemObj.type = 'select';
      this.syncTemplateImport.bodyParameters.push(elemObj);
      this.syncTemplateImport.bodyText = this.syncTemplateImport.bodyText.replace('{{'+(index+1)+'}}','['+element+']');
    } else{
      elemObj.param_text = element;
      elemObj.type = 'text'
      this.syncTemplateImport.bodyParameters.push(elemObj);
      if(this.syncTemplateImport.bodyText.indexOf('{{'+(index+1)+'}}') != -1){
      this.syncTemplateImport.bodyText = this.syncTemplateImport.bodyText.replace('{{'+(index+1)+'}}',element);
      }
      this.syncTemplateImport.bodyText
    }
    allParams.push(element)
    
  });
    this.syncTemplateImport.components.forEach(element => {
      if(element.type == "BODY"){
        element.text = this.syncTemplateImport.bodyText;
      }
    });
    if(allParams){
      allParams.forEach(elem=>{      
        if(elem.indexOf('[') != -1 && elem.indexOf(']') != -1){  
        elem = elem.replace('[',"").replace(']',"");
        }
        let checkInAttr = this.inputParams.find(x=>x.paramKey == elem);
        if(checkInAttr){
          allParamsWithDetails.push(checkInAttr);
        }
      
      })
    }

    if(formVals.syncheaderUrl){
      let checkInAttr = this.inputParams.find(x=>x.paramKey == formVals.syncheaderUrl);
        if(checkInAttr){
          allParamsWithDetails.push(checkInAttr);
        }
    }
    
    

    delete this.syncTemplateImport.bodyParams;
    delete this.syncTemplateImport.bodyText;
    delete this.syncTemplateImport.buttonParams;
    delete this.syncTemplateImport.headerParams;
    delete this.syncTemplateImport.components.buttons;
    delete this.syncTemplateImport.components.headerText;
    if(this.syncTemplateImport.components.headerFormat){
      this.syncTemplateImport.components.headerFormat = this.syncTemplateImport.components.headerFormat.toLowerCase()
    }
    let postData = 
      {
        "templateName":formVals.templateName,
        "smsText":this.bodyParams.toString(),
        "mediaType": this.syncTemplateImport.components.headerFormat,
        "buttonText": buttonParams?buttonParams:"",
        "provider": "cronberry",
        "paramsSelected":allParamsWithDetails,
          "headerUrl":formVals.headerUrl,
          "languageCode":formVals.synclanguageCode ? formVals.synclanguageCode : 'en',
          "id":this.syncTemplateImport.id,
          "dynamicContentList":allParamsWithDetails,
          "templateJson":JSON.stringify(this.syncTemplateImport)
        }
      
      this.campaignService.saveWhatsappTemplate(postData, this.whatsappPushUrl).subscribe( data => {
        if(data.status){
          this.afterSave();
          this.getPushTemplateList('whatsapp','reload');
        } else {
          this.toastr.error(data.error_msgs, "Error");
          this.modalRef.hide();
        }
      });
      
  }

  syncFormSubmitted(form){
  }

  openGalleryModal(type){
    const initialState = {
      galleryType : type,
      modalRefGallery: this.modalRefGallery
    }
    this.modalRefGallery = this.modalService.show(GalleryPopupComponent,{
        initialState,
        backdrop: "static",
        class: "gallery-popup"
    });
    this.modalRefGallery.content.modalRefGallery = this.modalRefGallery;
    this.modalRefGallery.content.selectMediaEvent.subscribe(data => {
        this.getMedia(data);
    });
  }

  openModalDelete(template: TemplateRef<any>, id: number, index: number,url?) {
    if(id != undefined){
      this.templateId = id;
      this.templateIndex = index;
      this.push_url = url
    }
    this.modalRef = this.modalService.show(template, { backdrop: 'static' });
  }

  checkDynamicValues(msg) : boolean{    
    
    var isValueInclude: boolean = true;
    if(msg != undefined && this.inputParamsSelected != undefined){
      this.inputParamsSelected.forEach(element => {
        if(!msg.includes(element.paramValue)){
          isValueInclude = false;
        }
      });
    }
    return isValueInclude;
  }

  private getPostData(form) {
    this.validFilters = true;
    let postForm: any = form.value;
    this.prepareWithAttributes(postForm);
    if (!this.validFilters) {
      return;
    }
    postForm.dynamicContentList = this.inputParamsSelected;
    return postForm;
  }


  private prepareWithAttributes(postForm: any) {
    if(this.isUpdate){
      postForm.id = this.updateId
    }
    if(postForm.paramList)
    {
    this.global.Channel.Attributes.forEach(event => {
      postForm.paramList.push({
        id: event.id,
        paramValue: event.paramValue,
        paramOprator: event.paramOprator
      });
    });
    }
    
  }

    getInputParams() {
        this.projectService.fetchAttributeList().then(data => {
            this.inputParams = [];
            this.inputParamsFileType = []
            if(data){
                var list = this.projectService.filterAttributeList(['all']);
                
                list.forEach((element, i) => {
                    this.inputParams.push({
                   // paramKey:element.pluginName == 'dynamic' ?'params.'+element.paramKey:element.paramKey,
                   paramKey:element.paramKey,
                    id: element.id,
                    paramType:element.paramDatatype,
                    paramValue: '['+element.paramKey+']',
                    paramName: element.paramName
                  });
                  if(element.paramDatatype == 'File'){
                    this.inputParamsFileType.push(element);
                  }
                });

                this.inputParamsFileType.sort((a,b)=>{
                  if(a.paramValue < b.paramValue){ return -1 }
                  if(a.paramValue > b.paramValue){ return 1}
                })

                this.inputParams.sort((a,b)=>{
                  if(a.paramValue < b.paramValue){ return -1 }
                  if(a.paramValue > b.paramValue){ return 1}
                })
            }
        });
    }

    getSeletedParemWhatsapp(event, form, field){    
      if(field == 'emailText'){
        if(event.paramType == 'file' || event.paramType == 'File')
        {        
          this.insertContent(event.paramValue,'link')
          return;
        }
        this.insertContent(event.paramValue);
      } else {
        var value = event.paramValue;
        let msg =  form.controls[field].value;      
        msg = msg.replaceAll(" ","") != null && msg.replaceAll(" ","") != ""  ? msg+',' : '';
        form.controls[field].setValue(msg+' '+value); 
      }
    }
  
    removeParemWhatsapp(event, form, field){    
      let paramValue = event.value.paramValue;    
      let msg =  form.controls[field].value;
      msg = msg != null ? msg : '';
      let checkComma =  msg.split(', '+paramValue);
      if(checkComma.length > 1){      
        msg = msg.split(', '+paramValue).join('');
      }
      else{
        let checkLastElem = msg.split(paramValue+',');
        if(checkLastElem.length > 1){
        msg = msg.split(paramValue+',').join('');
        }
        else{
          msg = msg.split(paramValue).join('');
        }
      }
      form.controls[field].setValue(msg);
    }

  getSeletedParem(event, form, field){    
    if(field == 'emailText'){
      if(event.paramType == 'file' || event.paramType == 'File')
      {        
        this.insertContent(event.paramValue,'link')
        return;
      }
      this.insertContent(event.paramValue);
    } else {
      var value = event.paramValue;
      let msg =  form.controls[field].value;
      msg = msg != null ? msg : '';
      form.controls[field].setValue(msg+' '+value); 
    }
  }

  removeParem(event, form, field){
    let paramValue = event.value.paramValue;
    let msg =  form.controls[field].value;
    msg = msg != null ? msg : '';
    msg = msg.split(paramValue).join('');
    form.controls[field].setValue(msg);
  }

  addEmoji(event, form, field){
    let emoji = event.emoji.native;
    if(field == 'emailText'){
      this.insertContent(emoji);
    } else {
      let msg =  form.controls[field].value;
      msg = msg != null ? msg : '';
      form.controls[field].setValue(msg+' '+emoji);
    }
    this.isShowEmoji = false;
  }

  changeListeners($event): void {
    this.uploadimage($event.target);
  }

  uploadimage(inputValue: any): void {
    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();
    myReader.onloadend = e => {
      this.formPushNotification.controls.notificationImage.setValue(
        myReader.result
      );
    };
    myReader.readAsDataURL(file);
  }

  afterSave() {
    if(this.isUpdate){
      this.toastr.success("Template Updated");
    } else {
      this.toastr.success("Template Created");
    }
    this.isUpdate = false;
    this.updateId = null;
    this.modalRef.hide();
  }

  
  deleteTemplate() {
    if(this.activeTab == 'push'){
      this.deletePushTemplate(this.push_url);
    } else if(this.activeTab == 'sms'){
      this.deletePushTemplate(this.smsPushUrl);
     // this.deleteSmsTemplate();
    }else if(this.activeTab == 'email'){
      this.deletePushTemplate(this.emailPushUrl);
     // this.deleteEmailTemplate();    
    }else if(this.activeTab == 'whatsapp'){
      this.deletePushTemplate(this.whatsappPushUrl);
     // this.deleteWhatsappTemplate();    
    }
  }

  deleteWhatsappTemplate(){
    this.campaignService.whatsappTemplateDelete({id: this.templateId}).subscribe( data => {
        if (data.status) {
          this.toastr.success('Template Deleted Successfully');
          this.whatsappTemplateList.splice(this.templateIndex, 1);
          this.modalRef.hide();
        }
        else {
          this.toastr.error(data.error_msgs, 'Error');
        }
      });
  }

  deleteEmailTemplate(){
    this.campaignService.emailTemplateDelete({id: this.templateId}).subscribe( data => {
        if (data.status) {
          this.toastr.success('Template Deleted Successfully');
          this.emailTemplateList.splice(this.templateIndex, 1);
          this.modalRef.hide();
        }
        else {
          this.toastr.error(data.error_msgs, 'Error');
        }
      });
  }

  deleteSmsTemplate(){
    this.campaignService.smsTemplateDelete({id: this.templateId}).subscribe( data => {
        if (data.status) {
          this.toastr.success('Template Deleted Successfully');
          this.smsTemplateList.splice(this.templateIndex, 1);
          this.modalRef.hide();
        }
        else {
          this.toastr.error(data.error_msgs, 'Error');
        }
      });
  }

  deletePushTemplate(url){    
    console.log(url)
    this.campaignService.deleteTemplate({id: this.templateId},url).subscribe( data => {
        if (data.status) {
          this.toastr.success('Template Deleted Successfully');
          let templateActive = this.activeTab;
          console.log(this.activeTab)
          this.getPushTemplateList(this.activeTab,'reload');
          this.modalRef.hide();
        }
        else {
          this.toastr.error(data.error_msgs, 'Error');
        }
      });
  }

  saveEmailTemplate() {
    if (this.formEmailCampaign.invalid) {
      this.toastr.error(this.global.MessageRequired);
      this.global.validateAllFormFields(this.formEmailCampaign);
      return;
    }
    let postForm: any = this.getPostData(this.formEmailCampaign);
    if (!this.validFilters) {
      return;
    }

    if(!this.checkDynamicValues(postForm.emailText)){
      this.toastr.error("Please use selected Dynamic Values");
      return
    }

    postForm.userCampaigns;
    postForm.isEditorView = this.isEditorView;
    this.campaignService.upsertTemplate(postForm,this.push_url).subscribe(data => {    
        if(data.status){    
          this.afterSave();
          this.formEmailCampaign.reset();
          // this.campaignService.emailTemplateData = undefined;
          // this.getEmailTemplateList();
          this.emailTemplateList = [];
          this.cacheService.syncTemplates(this.emailPushUrl,'email','reload');
          this.cacheService.getEmailTemplateData().subscribe(data=>{
            this.emailTemplateList = data;
          });

        } else {
          this.toastr.error(data.error_msgs, "Error");
        }
      });
  }

  saveSMSCampaign() {
    
    if (this.formSMSCampaign.invalid) {
      this.toastr.error(this.global.MessageRequired);
      this.global.validateAllFormFields(this.formSMSCampaign);
      return;
    }
    
    let postForm: any = this.getPostData(this.formSMSCampaign);

    if (!this.validFilters) {
      return;
    }
    
    if(!this.checkDynamicValues(postForm.smsText)){
      this.toastr.error("Please use selected Dynamic Values");
      return
    }

    postForm.userCampaigns;
    
    this.campaignService.upsertTemplate(postForm,this.push_url).subscribe( data => {
        if(data.status){
          this.afterSave();
          this.formSMSCampaign.reset();
         // this.campaignService.smsTemplateData = undefined;
         // this.getSMSTemplateList();
         this.smsTemplateList = [];
          this.cacheService.syncTemplates(this.smsPushUrl,'sms','reload');
          this.cacheService.getSmsTemplateData().subscribe(data=>{
            this.smsTemplateList = data;
          });
        } else {
          this.toastr.error(data.error_msgs, "Error");
        }
      });
  }

  

  saveWhatsappCampaign() {
    if (this.formWhatsappCampaign.invalid) {
      this.toastr.error(this.global.MessageRequired);
      this.global.validateAllFormFields(this.formWhatsappCampaign);
      return;
    }
    
    let postForm: any = this.getPostData(this.formWhatsappCampaign);
    if(postForm.headerUrl && postForm.headerUrlText == ""){
      let setHeaderKey = postForm.headerUrl.paramKey ? postForm.headerUrl.paramKey : postForm.headerUrl;
      postForm.headerUrl = '['+setHeaderKey+']';
    } else if(postForm.headerUrl == undefined || postForm.headerUrl.length == 0  && postForm.headerUrlText != ""){
        postForm.headerUrl = postForm.headerUrlText;
    } 
    delete postForm.headerUrlText;
    if (!this.validFilters) {
      return;
    }
    
    if(!this.checkDynamicValues(postForm.smsText)){
      this.toastr.error("Please use selected Dynamic Values");
      return
    }

    postForm.userCampaigns;
    this.campaignService.saveWhatsappTemplate(postForm, this.whatsappPushUrl).subscribe( data => {
        if(data.status){
          this.afterSave();
          this.formWhatsappCampaign.reset();
          this.campaignService.WhatsappTemplateObj = undefined;
          this.getPushTemplateList('whatsapp','reload');
        } else {
          this.toastr.error(data.error_msgs, "Error");
        }
      });
  }

  savePushNotification() {
    
    if (this.formPushNotification.invalid) {
      this.toastr.error(this.global.MessageRequired);
      this.global.validateAllFormFields(this.formPushNotification);
      return;
    }
    
    let postForm: any = this.getPostData(this.formPushNotification);

    if (!this.validFilters) {
      return;
    }
    if(!this.checkDynamicValues(postForm.message)){
      this.toastr.error("Please use selected Dynamic Values");
      return
    }
    // postForm.notImage = postForm.notificationImage;
    postForm.userCampaigns;
    this.campaignService.upsertTemplate(postForm,this.push_url).subscribe(data => {
        if(data.status){
          this.afterSave();
          // this.campaignService.pushTemplateData = undefined;
          // this.getPushTemplateList(this.push_url);
          if(this.activeTab == 'push'){
          this.androidPushTemplateList = [];
          this.cacheService.syncTemplates(this.pushServiceUrl,'push','reload');
          this.cacheService.getAndroidTemplateData().subscribe(data=>{
            this.androidPushTemplateList = data;
          });
          } else if(this.activeTab == 'ios'){
            this.iosPushTemplateList = [];
            this.cacheService.syncTemplates(this.pushServiceUrl,'ios','reload');
            this.cacheService.getIosTemplateData().subscribe(data=>{
              this.iosPushTemplateList = data;
            });
          } else if(this.activeTab == 'web'){
            this.webPushTemplateList = [];
            this.cacheService.syncTemplates(this.pushServiceUrl,'web','reload');
            this.cacheService.getWebTemplateData().subscribe(data=>{
              this.webPushTemplateList = data;
            });
          } else if(this.activeTab == 'sms'){
            this.smsTemplateList = [];
            this.cacheService.syncTemplates(this.smsPushUrl,'sms','reload');
            this.cacheService.getSmsTemplateData().subscribe(data=>{
              this.smsTemplateList = data;
            });
          } else if(this.activeTab == 'email'){
            this.emailTemplateList = [];
            this.cacheService.syncTemplates(this.emailPushUrl,'email','reload');
            this.cacheService.getEmailTemplateData().subscribe(data=>{
              this.emailTemplateList = data;
            });
          } else if(this.activeTab == 'whatsapp'){
            this.whatsappTemplateList = [];
            this.cacheService.syncTemplates(this.emailPushUrl,'whatsapp','reload');
            this.cacheService.getWhatsappTemplateData().subscribe(data=>{
              this.whatsappTemplateList = data;
            });
          }
        } else {
          this.toastr.error(data.error_msgs, "Error");
        }
      },
      error => {
        this.toastr.error("Something went wrong", "Error");
      }
    );
  }


  getPushTemplateList(type?,reload?){   
    console.log(type) 
    if(type == 'push'){
      if(reload == 'reload'){
        this.androidPushTemplateList = []
      }
      if(this.androidPushTemplateList.length == 0 || this.androidPushTemplateList == undefined){
      this.cacheService.syncTemplates(this.pushServiceUrl,type,'reload');
      this.cacheService.getAndroidTemplateData().subscribe(data=>{
        this.androidPushTemplateList = data;
      });
    }
    } else if(type == 'ios'){
      if(reload == 'reload'){
        this.iosPushTemplateList = []
      }
      if(this.iosPushTemplateList.length == 0 || this.iosPushTemplateList == undefined){
      this.cacheService.syncTemplates(this.pushServiceUrl,type,'reload');
      this.cacheService.getIosTemplateData().subscribe(data=>{
        this.iosPushTemplateList = data;
      });
      }
    } else if(type == 'web'){
      if(reload == 'reload'){
        this.webPushTemplateList = []
      }
      if(this.webPushTemplateList.length == 0 || this.webPushTemplateList == undefined){
      this.cacheService.syncTemplates(this.pushServiceUrl,type,'reload');
      this.cacheService.getWebTemplateData().subscribe(data=>{
        this.webPushTemplateList = data;
      });
      }
    } else if(type == 'sms'){
      if(reload == 'reload'){
        this.smsTemplateList = []
      }
      if(this.smsTemplateList.length == 0 || this.smsTemplateList == undefined){
      this.cacheService.syncTemplates(this.smsPushUrl,type,'reload');
      this.cacheService.getSmsTemplateData().subscribe(data=>{
        this.smsTemplateList = data;
      });
      }
    } else if(type == 'email'){
      if(reload == 'reload'){
        this.emailTemplateList = []
      }
      if(this.emailTemplateList.length == 0 || this.emailTemplateList == undefined){
      this.cacheService.syncTemplates(this.emailPushUrl,type,'reload');
      this.cacheService.getEmailTemplateData().subscribe(data=>{
        this.emailTemplateList = data;
      });
    }
    } else if(type == 'whatsapp'){
      if(reload == 'reload'){
        this.whatsappTemplateList = []
      }      
      if(this.whatsappTemplateList.length == 0 || this.whatsappTemplateList == undefined){
      this.cacheService.syncTemplates(this.whatsappPushUrl,type,'reload');
      this.cacheService.getWhatsappTemplateData().subscribe(data=>{
        this.whatsappTemplateList = data;
      });
    }
    }
  }
  
  getSMSTemplateList(){
    this.smsTemplateList = [];
    this.campaignService.getSmsTemplateData().then( data => {
        if(data != undefined){
          this.smsTemplateList = data;
        };
    })
  }

  getEmailTemplateData(id){
    this.campaignService.emailTemplate({id:id},this.emailPushUrl).subscribe( data => {
        this.setEmailData(data.data);
    });
  }

  getWhatsappTemplateData(id){
    this.campaignService.whatsappTemplate({id:id}, this.whatsappPushUrl).subscribe( data => {
        this.setWhatsappData(data.data);
    });
  }

  setEmailData(data){
    this.updateId = data.id;
    this.isUpdate = true;
    this.formEmailCampaign.patchValue({
      templateName: data.templateName,
      emailSubject: data.emailSubject,
      emailText: data.emailText,
      paramsSelected: data.dynamicContentList
    });
    this.inputParamsSelected = data.dynamicContentList;
    this.isEditorView = data.isEditorView;
    this.openModal(this.templateEmailCampaign);
    this.ckeditorData.next(data.emailText);
  }

  getSmsTemplateData(id,url){
    this.campaignService.getTemplate({id:id},url).subscribe(data => {
        this.setSmsData(data.data);
    });
  }

  setSmsData(data){
    this.updateId = data.id;
    this.isUpdate = true;
    this.formSMSCampaign.patchValue({
      templateName: data.templateName,
      smsText: data.smsText,
      paramsSelected: data.dynamicContentList
    });
    this.inputParamsSelected = data.dynamicContentList;
    this.openModal(this.templateSMSCampaign);
  }

  setWhatsappData(data){     
    let headerUrlSet;
    let headerUrl;
    if(data.headerUrl){
      if(data.headerUrl.includes('http://') == true || data.headerUrl.includes('https://') == true){
        headerUrlSet = data.headerUrl;
        this.headerUrlText = headerUrlSet;
        this.headerUrl = [];
      }
      if(data.headerUrl.includes('[') == true && data.headerUrl.includes(']') == true){
        headerUrl = data.headerUrl.replace('[','').replace(']','');
        this.headerUrl = headerUrl;
        this.headerUrlText = "";
      }
    }
    this.updateId = data.id;
    this.isUpdate = true;
    this.formWhatsappCampaign.patchValue({
      templateName: data.templateName,
      smsText: data.smsText,
      paramsSelected: data.dynamicContentList,
      headerUrl:this.headerUrl,
      headerUrlText:this.headerUrlText,
      languageCode:data.languageCode ? data.languageCode : 'en'
    });
    this.inputParamsSelected = data.dynamicContentList;

    if(data.provider == 'cronberry'){
      this.importTmplt(data,'editableForm')
    } else{
      this.openModal(this.templateWhatsappCampaign);
    }
  }

  resetWhatsAppData()
  {
    this.formWhatsappCampaign.patchValue({
      templateName: "",
      smsText: "",
      paramsSelected: "",
      headerUrl:"",
      languageCode:"en"
    });
    this.inputParamsSelected = "";
  }

  getPushTemplateData(id,url){
    
    this.updateId = id;
    this.isUpdate = true;
    this.campaignService.getTemplate({id:id},url).subscribe(data => {
      this.push_url = url
        this.setPushData(data.data);
      });
  }

  setPushData(data){
    this.updateId = data.id;
    this.isUpdate = true;
    this.formPushNotification.patchValue({
      templateName: data.templateName,
      smsText: data.smsText,
      deepLink: data.deepLink,
      message: data.message,
      title: data.title,
      notificationImage: data.notImage,
      notificationIcon: data.notIcon,
      paramsSelected: data.dynamicContentList
    });
    this.inputParamsSelected = data.dynamicContentList;
    this.openModal(this.templatePushNotification);
  }

  getMedia(item){
    switch (this.activeTab) {
      case 'email':
        var mediaHTML: string = '';
        if (item.type == AppConstants.FILETYPE_FORM) {
          mediaHTML = '<a href="' + ApiConstants.CUSTOM_FORM_LINK + item.linkRewrite + '" target="_blank">' + item.name + '</a>'
          this.toastr.success('Double click on "' + item.name + '" to update.')
        } else if (item.type === AppConstants.FILETYPE_FILE) {
          mediaHTML = '<a href="' + item.originalFile + '" target="_blank">' + item.mediaName + '</a>'
          this.toastr.success('Double click on "' + item.mediaName + '" to update.')
        } else {
          mediaHTML = '<img src="' + item.originalFile + '" width="200" alt="' + item.mediaName + '" />';
        }
        this.insertContent(mediaHTML);
        break;
      case 'sms':
        let msg = this.formSMSCampaign.controls['smsText'].value;
        msg = msg != null ? msg : '';
        var itemUrl = '';
        if (item.type == AppConstants.FORM) {
          var itemUrl = ApiConstants.CUSTOM_FORM_LINK + item.linkRewrite
        } else {
          itemUrl = item.originalFile
        }
        if (this.authenticationService.isPageSubscribe('Url Shortner')) {
          this.urlShortnerService.getUrlShortner(itemUrl).then(data => {
            this.formSMSCampaign.controls['smsText'].setValue(msg + ' ' + data);
          })
        } else {
          this.formSMSCampaign.controls['smsText'].setValue(msg + ' ' + itemUrl);
        }
        break;
      case 'android':
      case 'ios':
      case 'web':
      case 'push':
        if (item.fileType == AppConstants.FILETYPE_IMAGE || item.fileType == AppConstants.FILETYPE_GALLERY) {
          this.formPushNotification.controls.notificationImage.setValue(item.originalFile);
          this.formPushNotification.controls.notImage.setValue(item.fileName);
        }
        break;
      default:
        break;
    }
  }  

  copyText(val: string){
      this.global.copyText(val);
      this.toastr.success('File Path Copied.');
  }

  insertContent(html,link?) {
    if(this.isEditorView){
      for (var i in CKEDITOR.instances) {
        if(link)
        {          
          CKEDITOR.instances[i].insertHtml('<a href="'+html+'">'+'Click Here'+'</a>');
          return;
        }
        CKEDITOR.instances[i].insertHtml(html);
      }
    } else {
      let msg =  this.formEmailCampaign.controls['emailText'].value;
      msg = msg != null ? msg : '';
      if(link)
      {          
        this.formEmailCampaign.controls['emailText'].setValue(msg+' '+'<a href="'+html+'">'+'Click Here'+'</a>');
        return;
      }
      this.formEmailCampaign.controls['emailText'].setValue(msg+' '+html);
    }
    return true;
  }

  getformData(data){
      this.customFormService.getCustomFormData({id:data.id}).subscribe( data => {
        if (data.status) {
            this.insertForm(data.data)
        } else {
            this.toastr.error(data.error_msgs, 'Error');
        }
    }, error => {
        this.toastr.error('Something went wrong', 'Error');
    });
  }

  insertForm(data){
    var htmlForm
    htmlForm = this.customFormService.getEmailFormTemplate(data);
    this.insertContent(htmlForm);
  }

  getDefaultEmailTemplates(){
    this.defaultEmailTemplateList = [];
    this.campaignService.getDefaultEmailTemplateData(this.emailPushUrl).then( data => {
        if(data != undefined){
          this.defaultEmailTemplateList = data;
        };
    })
  }

  importTemplate(id){
    this.campaignService.saveTemplateDefaultUser({'id':id},this.emailPushUrl).subscribe(data => {
      if (data.status) {
        this.modalRef.hide();
        this.campaignService.emailTemplateData = undefined;
        this.getPushTemplateList('email','reload');
      } else {
          this.toastr.error(data.error_msgs, 'Error');
      }
    })
  }

}
