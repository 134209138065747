import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../../core/guards/auth.guard';
import { AuthLayoutComponent } from '../admin/components/auth-layout/auth-layout.component';
import { InvoiceEstimateComponent } from './invoice-estimate.component';
import { InvoiceProductListComponent } from './invoice-product-list/invoice-product-list.component';
import { InvoiceSettingComponent } from './invoice-setting/invoice-setting.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { QuotationComponent } from './quotation/quotation.component';

const routes: Routes = [{
  path:'admin',component:AuthLayoutComponent, canActivate:[AuthGuard], children:[
    {'path':'invoice-quotation/Invoice', component:InvoiceComponent},
    {'path':'invoice-quotation/quotation', component:QuotationComponent},
    {'path':'invoice-quotation/seller-configuration', component:InvoiceSettingComponent},
    {'path':'invoice-quotation/product', component:InvoiceProductListComponent}
  ]
}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class InvoiceEstimateRoutingModule { }
