import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { SwPush, SwUpdate } from '@angular/service-worker';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../environments/environment';
import { version } from '../../package.json';
import { SpinnerService } from './core/services/spinner.service';
import { AuthenticationService } from './core/services/authentication.service';
import { PathConstants } from './constants/path.constants';
import { ProjectService } from './core/services/project.service';
declare const navigator: any;

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
	
	title = 'cronberry';
	isLogin: boolean = false;
	readonly VAPID_PUBLIC_KEY = "BKbO7gV8dhCJqgf4wQFbye8V-Be5lgQQS318CQXwvJC9hwvxVqXSxSh43qiVuL-FiRujxKJ8ZXeajnjxZ_gZlUE";
	readonly VERSION = version;
	ishttpLoaded:boolean=false;
	spinnerTimer:number=0;
	PWADisplayModeOn:boolean = false;
	pageload:boolean = false;
	isTabActive:boolean = true;
	constructor(
		private router: Router,
		private swPush: SwPush,
		private swUpdate: SwUpdate,
		private toastr: ToastrService,
		private spinner: SpinnerService,
		private authenticationService: AuthenticationService,
		private projectService: ProjectService
	){
	
		this.checkUpdate();
		this.swPush.requestSubscription({
			serverPublicKey: this.VAPID_PUBLIC_KEY
		});
		if(this.getPWADisplayMode() == 'standalone' || this.getPWADisplayMode() == 'twa'){
			this.PWADisplayModeOn = true;
		}

	}

	

	ngOnInit(){
		this.isLogin = this.authenticationService.getUserId() != undefined && this.authenticationService.getUserId() != null;
		if(!this.isLogin)
		this.pageload = true;

		this.router.events.subscribe((evt) => {
			if(evt instanceof NavigationStart) {
				window.scrollTo(0, 0);
			} else if(evt instanceof NavigationEnd) {
				if(this.isLogin && this.PWADisplayModeOn && evt.url == '/'){
					this.router.navigate([PathConstants.DASHBOARD]);
				}
			};
			if (!(evt instanceof NavigationEnd)) {
				return;
			}
		});
		
		if (this.swPush.isEnabled) {
			this.swPush.notificationClicks.subscribe((result) => {								
				if (this.isLogin && this.router.routerState.snapshot.url.indexOf('/admin/audience/view-audience/audience') == -1) {
						this.router.navigate(['/'+result.notification.data]);
				}
			});
		}

		this.spinner.returnSpinnerObservable().subscribe( value => {
			this.ishttpLoaded = value;
		});
		this.spinner.returnSpinnerShowTimeObservable().subscribe( value => {
			this.spinnerTimer = value;
		});
		navigator.serviceWorker.addEventListener('message', (event) => {
		  });
	}

	getPWADisplayMode() {
		const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
		if (document.referrer.startsWith('android-app://')) {
			return 'twa';
		} else if (navigator.standalone || isStandalone) {
			return 'standalone';
		}
			return 'browser';
	}

	 checkUpdate(){
		this.swUpdate.available.subscribe( event => {
			this.toastr.success("We've published new version "+this.VERSION+" hence reloading update.");
			setTimeout(() => {
				this.swUpdate.activateUpdate().then(() => {
					document.location.reload()});
			}, 5000);
		}); 
	}

	siteLoad(){
		this.pageload = true;
	}
}
