import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AnnouncementService } from '../../../../core/services/announcement.service';
import { Global } from '../../../../shared/models/global.model';
import { AnnouncementObj } from '../../../../core/modal/announcement';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { Location } from '@angular/common';

@Component({
	selector: 'popup-component-list',
	templateUrl: './popup.component-list.html',
	styleUrls: ['./announcement.component.scss']
})

export class announcementPopupListComponent implements OnInit, OnDestroy {
	
	dataList:AnnouncementObj[];
    @ViewChild('deleteConfirm') deleteConfirm: TemplateRef<any>;
    modalRef: BsModalRef;
    id: number;
    selectedId: number;
    isSubscribe: boolean = true;
    pageNo: number = 1;
    dataSize: number = 20;
    public totalDataSize: number;
    type:number = 1;
	constructor(
		private global: Global,
        private toastr: ToastrService,
        private announcementService: AnnouncementService,
        private modalService: BsModalService,
        private authenticationService: AuthenticationService,
        private location:Location
	) {
        this.isSubscribe = this.authenticationService.isPageSubscribe('Popup Notification');
	}

	ngOnInit() {
		this.dataList = [];
        if(this.isSubscribe)
        this.getDataList();
	}

    back()
    {
        this.location.back();
    }

	copyText(text){
        this.global.copyText(text);
        this.toastr.success('Copied to clipboard');
    }

	ngOnDestroy(){
	
	}

    public dataForPaging(event) {
        this.pageNo = event;
        this.getDataList();
    }

	getDataList(){
        this.dataList = [];
        this.announcementService.fetchAnnouncementsList(this.type,this.dataSize, this.pageNo-1).subscribe(data => {
            if(data.status){
                this.dataList = [];
                if(data.data.creativePopupList != null){
                    data.data.creativePopupList.forEach(element => {
                        element.scheduleStart =  element.scheduleStart.replace(' ', 'T');
                        element.scheduleEnd = element.scheduleEnd.replace(' ', 'T');
                        this.dataList.push(element);
                    });
                }
                this.totalDataSize = data.data.totalCount
            }
        });
    }

    openModalDelete(id:number){
        this.selectedId = id;
        this.modalRef = this.modalService.show(this.deleteConfirm, {
            backdrop: 'static'
        });
    }

    delete(){
        this.announcementService.deleteAnnouncement({deleteUserPopup:this.selectedId},this.type).subscribe(data => {
            if(data.status){
                this.toastr.success(data.data, 'Success');
                this.modalRef.hide();
                this.getDataList();
            } else {
                this.toastr.error(data.error_msgs);
            }
        })
    }

	
}
