import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'myFilter'
})
export class MyFilterPipe implements PipeTransform {

  transform(items: any[], filter: Object): any {
    if (!items || !filter) {
        return items;
    }
    
    function checkInside(item, term) {
      var toCompare = term.toLowerCase().trim();

      for (var property in item) {
          if (item[property] === null || item[property] == undefined) {
              continue;
          }
          if (typeof item[property] === 'object') {
              if (checkInside(item[property], term)) {
                  return true;
              }
          }
          if (item[property].toString().toLowerCase().includes(toCompare)) {
              return true;
          }
      }
      return false;
  }
  return items.filter(function (item) {
      return checkInside(item, filter);
  });
}

}
