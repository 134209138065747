import { DatePipe, Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DatePickerObj } from '../../../core/modal/datePicker';
import * as moment from "moment";
import { Global } from '../../../shared/models/global.model';
import { ActivatedRoute } from '@angular/router';
import { CallassistService } from '../callassist.service';
import { SubUserObj } from '../../../core/modal/user';

@Component({
  selector: 'app-call-assist-location',
  templateUrl: './call-assist-location.component.html',
  styleUrls: ['./call-assist-location.component.scss']
})
export class CallAssistLocationComponent implements OnInit {
  callAssistLocationRange: DatePickerObj;
  startDate:any=new Date();
  maxDate:any;
  ranges:any;
  locationList:any[]=[];
  page: number = 1;
  limit: number = 20;
  totalRecords: number = 0;
  queryparamsid:any;
  public selectedUserobject:SubUserObj=undefined;

  constructor(
    private location:Location,
    private callassistService:CallassistService,
    private datePipe:DatePipe,
    private global:Global,
    private activatedRoute:ActivatedRoute
    ) { }

  ngOnInit() {
    this.activatedRoute.queryParamMap.subscribe((params)=>{
      this.queryparamsid = params;
    });
    let userObject = this.callassistService.getUserObject();
    if(userObject != undefined)
    {
      this.selectedUserobject = userObject
    }
    this.ranges = this.global.customDateRange();
    this.maxDate = moment();
    this.callAssistLocationRange = {
      startDate: moment().subtract(29, 'days'),
      endDate: moment(),
  };
  
  }

  public dataForPaging(event) {
  this.page = event;
  this.callAssistLocationList();
}

  back()
  {
    this.location.back();
  }

  callAssistLocationList()
  {
    let toDate = this.datePipe.transform(this.callAssistLocationRange.endDate,'yyyy-MM-dd HH:mm:ss');
    if(this.datePipe.transform(this.callAssistLocationRange.startDate,'yyyy-MM-dd HH:mm:ss') == this.datePipe.transform(this.callAssistLocationRange.endDate,'yyyy-MM-dd HH:mm:ss')){
      toDate = this.datePipe.transform(this.callAssistLocationRange.endDate,'yyyy-MM-dd 23:59:59')
    }
    let dateObj = {'fromDate':this.datePipe.transform(this.callAssistLocationRange.startDate,'yyyy-MM-dd HH:mm:ss'),'toDate':toDate,'idUser':this.queryparamsid.params.id};
    this.callassistService.callAssistLocation(dateObj,this.limit, this.page).subscribe(data=>{
      if(data.status)
      {
        this.locationList = data.data;
        this.locationList.forEach(elem=>{
          elem.currentLocation = false;
          if(this.datePipe.transform(elem.created,'yyyy-MM-dd') ==  this.datePipe.transform(new Date(),'yyyy-MM-dd')){
            elem.currentLocation = true;
          }
        })
        
      }
    },(error)=>{
      console.log(error);
    });
  }

}
