<form>
  <div class="forgot-password-modal campaign-tabing-modal">
    <div class="modal-header">
      <h4 class="modal-title pull-left">
        <div class="d-flex c-align">
        <div>Select Audience</div>
        </div>
      </h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="configuration campaign-tab-modal" [ngClass]="{leadReportForm:leadReportForm}" [class.campaign-tab-no-space]="usingFor == 'CampaignCreate'">

      <tabset #pageTabs>
        <tab heading="Category" (selectTab)="nextButtonShow = true" *ngIf="showCatTab">
          <ng-container *ngFor="let item of dataList; let i = index;">
            <div class="borderradis">
              <label class="check">
                <span>{{item.name}}</span>
                <input type="checkbox" name="{{item.id}}" (change)="setCategoryData(item,$event);" [checked]="item.checked">
                <span class="checkmark"></span>
              </label>
            </div>
          </ng-container>
        </tab>

        <tab heading="Source" (selectTab)="nextButtonShow = true" *ngIf="showCatTab == false || showCatTab == true">
          <div class="campaign-tab-modal source_select_tab">
            <input class="btn searchbtn margin-searchbtn" name="searchFilterInput" placeholder="Search" type="text" [(ngModel)]="fetchText" autocomplete="off">
            <div *ngIf="!isReassign" class="borderradis d-flex justify-content-between c-align">
              <div class="d-flex start-align">
                <label class="check radio">
                  <input type="radio" name="allSources"  [(ngModel)]="isAllSelected" [value]="true" (change)="showAllList = true; showInactiveList = false; selectAll('activeForms')">
                  <span class="checkmark"></span>
                </label>
                <span (click)="showAllList = true; showInactiveList = false; showAll('activeOnly')" class="c-pointer">All</span>
              </div>

              <div class="d-flex start-align">
                <label class="check radio">
                  <input type="radio" name="isAllSelectedInactiveForms" [value]="true"  [(ngModel)]="isAllSelectedInactiveForms" (change)="showAllList = false; showInactiveList = true;selectAll('inactiveForms')">
                  <span class="checkmark"></span>
                </label>
                <span (click)="showInactiveList = true; showAllList = false; showAll('allForms')" class="c-pointer">All With Inactive</span>
              </div>

              <div class="d-flex start-align">
                <button (click)="clearFilterSelect()" class="btn clearButtonFilter">
                  <img src="assets/images/cross.png">
                  <span class="c-pointer">Clear</span>
                </button>
                
                <!-- <label class="check radio">
                  <input type="radio" name="deselectAll" [value]="true"  [(ngModel)]="deselectAll" (change)="selectAll('deselectAll')">
                  <span class="checkmark"></span>
                </label> -->
                
              </div>

              <!-- <label class="check">
                <span>All Sources</span>
                <input type="checkbox" name="allSources"  [(ngModel)]="isAllSelected" (change)="selectAll()">
                <span class="checkmark"></span>
              </label> -->
            </div>
            <ng-container *ngFor="let item of fetchUser | myFilter:fetchText let i = index;">
            <div class="borderradis">
              <div class="d-flex start-align">
              <label class="check">
                <input type="checkbox" name="{{item.pluginId}}" (change)="setFilterData(item.pluginId,'source');" [checked]="item.checked">
                <span class="checkmark"></span>
              </label>
              <span (click)="item.showList = !item.showList;openFormList(item)" class="c-pointer">{{item.pluginName}}</span>
              </div>
              <div *ngIf="item.checked || item.showList" class="sub-checkbox">
                <div *ngFor="let subCheck of item.assignMappingDTOs" class="dtosList">
                  <ng-container *ngIf="(isAllSelectedInactiveForms == true || showInactiveList == true) && item.showAllForm">
                    <div class="d-flex">
                      <label class="check">
                        <input type="checkbox" name="{{subCheck.id}}" (change)="setFilterData(subCheck.id,'sourceForm',i,item);" [checked]="subCheck.checked">
                        <span class="checkmark"></span>
                      </label>
                      <span [class.inactiveForms]="subCheck.status == 'INACTIVE'">{{subCheck.formName}}</span>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="subCheck.status == 'ACTIVE' && isAllSelectedInactiveForms == false && showAllList == true && item.showActiveForm">
                    <div class="d-flex">
                      <label class="check">
                        <input type="checkbox" name="{{subCheck.id}}" (change)="setFilterData(subCheck.id,'sourceForm',i,item);" [checked]="subCheck.checked">
                        <span class="checkmark"></span>
                      </label>
                      <span>{{subCheck.formName}}</span>
                    </div>
                  </ng-container>
              </div>
            </div>
            </div>
          </ng-container>
          </div>
        </tab>
        <ng-container *ngIf="!leadReportForm">
        <tab heading="Events" (selectTab)="nextButtonShow = true" *ngIf="showCatTab == false || showCatTab == true">
          <div class="campaign-tab-modal">
            <input class="btn searchbtn margin-searchbtn" name="search" placeholder="Search" type="text" [(ngModel)]="EventText" autocomplete="off">
            <ng-container *ngFor="let item of filters | myFilter:EventText let i = index;">
              <div *ngIf="item && item.paramCategory=='0'" class="borderradis">
                <label class="check">
                  <span>{{item.paramName}}</span>
                  <input type="checkbox" name="{{item.id}}" (change)="setFilterData(item.id,'events');" [checked]="item.checked">
                  <span class="checkmark"></span>
                </label>
              </div>
            </ng-container>

          </div>
        </tab>
        <tab heading="Attributes" (selectTab)="nextButtonShow = true" *ngIf="showCatTab == false || showCatTab == true">
          <div class="margin-searchbtn">
            <input class="btn searchbtn margin-searchbtn" name="search" placeholder="Search" type="text" [(ngModel)]="AttributeText"
              autocomplete="off">
            <ng-container *ngFor="let item of filters | myFilter:AttributeText let i = index;">
              <div *ngIf="item && item.paramCategory=='1' && item.paramDatatype != 'File'" class="borderradis">
                <label class="check">
                  <span>{{item.paramName}} <p class="d-inline" *ngIf="(item.paramKey=='city' || item.paramKey=='state' || 
                    item.paramKey=='country' || item.paramKey=='lead_status' || item.paramKey=='telecaller_name' ||  item.paramKey=='source' ||  item.paramKey=='product_name') ">(default)</p></span>
                  <input type="checkbox" name="{{item.id}}" (change)="setFilterData(item.id, 'attributes');" [checked]="item.checked">
                  <span class="checkmark"></span>
                </label>
              </div>
            </ng-container>
          </div>
        </tab>
        </ng-container>
        <ng-container *ngIf="leadReportForm">
        <tab heading="Reports" *ngIf="showCatTab == false || showCatTab == true" (selectTab)="callNext = true;nextButtonShow = false">
          <div class="margin-searchbtn">
            <app-create-report-graph [modalRef]="modalRef" [callNext]="callNext" (recordUpdateAdded)="recordUpdateAdded($event)" [addAction]="addActionSent" [categoryName]="categoryName"></app-create-report-graph>
            <!-- <ng-container [ngTemplateOutlet]="leadReportForm"></ng-container> -->
          </div>
        </tab>
        </ng-container>
        
      </tabset>

      <div class="table-footer d-flex justify-content-between pb-5 pt-2" *ngIf="!leadReportForm">
        <button type="submit" (click)="refreshAttributes()" class="btn btn-link ml-3"> Refresh Attributes</button>
        <button type="submit" (click)="GoToNext()" class="btn btn-dark mr-3">Submit</button>
      </div>
      <div class="table-footer d-flex justify-content-between pb-5 pt-2" *ngIf="leadReportForm">
        <ng-container *ngIf="nextButtonShow">
          <div>&nbsp;</div>
          <button type="button" class="btn btn-dark mr-3" (click)="nextTabShow()">Next</button>
        </ng-container>
      </div>
    </div>


  </div>
</form>