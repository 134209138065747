<main>
    <section class="inner-page">
        <div class="inner-page-header">
            <div class="row">
                <div class="col-10">
                    <h4>Audience Profile <span class="repeat" *ngIf="leadAssignData != null && leadAssignData.audRepeatCnt > 0 ">Repeated({{leadAssignData.audRepeatCnt}})</span></h4>
                </div>
                <div class="col-2 text-right">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                        <span aria-hidden="true"><img class="img" src="assets/images/times-circle_grey.svg"></span>
                    </button>
                </div>
            </div>
        </div>
    </section>

    <section class="inner-page">
    
        <div class="inner-page-body configuration project-integration-tab">
            <tabset #siteTabs>
                <tab heading="Info">
                    <div class="profile-box">
                        <h2 *ngIf="audience.name != undefined && audience.name != null" class="name"><span>{{audience.name.charAt(0)}}</span> {{audience.name}}</h2>
                        <ul class="row">
                            <li class="col-md-3" *ngIf="audience.audienceId != null">
                                <img src="assets/images/p-id-card-alt.svg" />
                                <span>{{ audience.audienceId }}</span>
                            </li>
                            <li class="col-md-3" *ngIf="audience.mobile != null">
                                <img src="assets/images/p-phone.svg" alt="Phone" title="Phone" />
                                <span>{{ audience.mobile }}</span>
                            </li>
                            <li class="col-md-6" *ngIf="audience.email != null">
                                <img src="assets/images/p-envelope.svg" alt="Cronberry - Email" title="Cronberry - Email" />
                                <span><a href="mailto:{{ audience.email }}"> {{ audience.email }}</a></span>
                            </li>
                        </ul>
                    </div>
                    <div class="profile-detail-box" *ngIf="audience.web_fcm_token != null || audience.android_fcm_token != null || audience.ios_fcm_token != null">
                        <ul>
                            <li *ngIf="audience.web_fcm_token != null">
                                <img src="assets/images/code.svg"/>
                                <span class="cursor-pointer" tooltipPlacement="right" tooltip="{{ audience.web_fcm_token }}">{{audience.web_fcm_token}}</span>
                                <img class="copy-buton" *ngIf="audience.web_fcm_token != null" src="assets/images/copy.svg" (click)="copyText(audience.web_fcm_token)" />
                            </li>
                            <li *ngIf="audience.android_fcm_token != null">
                                <img src="assets/images/android.svg" />
                                <span>{{ audience.android_fcm_token }} </span>
                                <img class="copy-buton" src="assets/images/copy.svg" (click)="copyText(audience.android_fcm_token)" />
                            </li>
                            <li *ngIf="audience.ios_fcm_token != null">
                                <img src="assets/images/ios.svg" />
                                <span>{{ audience.ios_fcm_token }} </span>
                                <img class="copy-buton" src="assets/images/copy.svg" (click)="copyText(audience.ios_fcm_token)" />
                            </li>
                        </ul>
                    </div>
                    <div class="table-reports">
                        <div class="table-body form-row">
                            <div class="table-responsive table-height-scroll col-md-6" #scrollblockLeft>
                                <table class="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <th>Parameter</th>
                                            <th>Value</th>
                                        </tr>
                                        <ng-container *ngFor="let item of audience.audienceDynamicDataList; let i = index">
                                            <tr *ngIf="i % 2 != 1">
                                                <td>{{ item.paramName }}</td>
                                                <td>
                                                    <ng-container *ngIf="item.paramValueChange === 'ACTIVE'; else otherParam">
                                                        <div class="paremvalue" *ngIf="!item.editParam">
                                                            <span class="value" [innerHtml]="item.paramValue"></span>
                                                            <span (click)="item.editParam = true; getparemType(item,i)" class="icon"><img src="assets/images/pencil.svg" width="12"/></span>
                                                        </div>
                                                        <div *ngIf="item.editParam" class="input-group">
                                                            {{paremType}}
                                                            <input trim="blur" *ngIf="item.paramDatatype != 'Date'" type="text" class="form-control" [ngClass]="{'invalid-control' : isSubmit && item.paramMandatory == 'ACTIVE' && item.paramValue == ''}" [(ngModel)]="item.paramValue">
                                                                <input *ngIf="item.paramDatatype == 'Date'" type="text" placement="top left" [(ngModel)]="item.paramValue" [ngClass]="{'invalid-control' : isSubmit && item.paramMandatory == 'ACTIVE' && item.paramValue == ''}"
                                                                class="form-control date-icon modal-space" bsDatepicker autocomplete="off" [bsConfig]="global.datePickerConfig">
                                                            <div class="input-group-append">
                                                                <button class="input-group-text" (click)="updateAudience(item, i)">save</button>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                    <ng-template #otherParam>
                                                        <span [innerHtml]="item.paramValue"></span>
                                                    </ng-template>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-md-6">
                                <div class="table-responsive table-height-scroll" #scrollblockRight>
                                    <table class="table table-bordered">
                                        <tbody>
                                            <tr>
                                                <th>Parameter</th>
                                                <th>Value</th>
                                            </tr>
                                            <ng-container *ngFor="let item of audience.audienceDynamicDataList; let i = index">
                                                <tr *ngIf="i % 2 == 1">
                                                    <td>{{ item.paramName }}</td>
                                                    <td>
                                                        <ng-container *ngIf="item.paramValueChange === 'ACTIVE'; else otherParam">
                                                            <div class="paremvalue" *ngIf="!item.editParam">
                                                                <span class="value" [innerHtml]="item.paramValue"></span>
                                                                <span (click)="item.editParam = true; getparemType(item,i)" class="icon"><img src="assets/images/pencil.svg" width="12"/></span>
                                                            </div>
                                                            <div *ngIf="item.editParam" class="input-group">
                                                                {{paremType}}
                                                                <input trim="blur" *ngIf="item.paramDatatype != 'Date'" type="text" class="form-control" [ngClass]="{'invalid-control' : isSubmit && item.paramMandatory == 'ACTIVE' && item.paramValue == ''}" [(ngModel)]="item.paramValue">
                                                                <input *ngIf="item.paramDatatype == 'Date'" type="text" placement="top left" [(ngModel)]="item.paramValue" [ngClass]="{'invalid-control' : isSubmit && item.paramMandatory == 'ACTIVE' && item.paramValue == ''}"
                                                                class="form-control date-icon modal-space" bsDatepicker autocomplete="off" [bsConfig]="global.datePickerConfig">
                                                                <div class="input-group-append">
                                                                    <button class="input-group-text" (click)="updateAudience(item, i)">save</button>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                        <ng-template #otherParam>
                                                            <span [innerHtml]="item.paramValue"></span>
                                                        </ng-template>
                                                    </td>
                                                </tr>
                                            </ng-container>
                                        </tbody>
                                    </table>
                                </div>
                                <div   class="other-parem" *ngIf="otherParameters.length > 0 && showAllparam">
                                    <span  (click)="openAll(otherParameters)" > Show All Parameters </span>
                                    <span (click)="isOtherParametersShow = !isOtherParametersShow;" > Add Other Parameters <img class="img" src="assets/images/caret-down-dark.svg"></span>
                                    <ul *ngIf="isOtherParametersShow">
                                        <li *ngFor="let item of otherParameters; let i = index" (click)="appendParameter(item,i)">{{item.paramName}}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </tab>
                <tab *ngIf="leadAssignData != null" heading="Lead Status">
                    <form class="audience-form my-3" [formGroup]="siteForm" (ngSubmit)="formSubmit()" *ngIf="leadAssignData != null">
                        <div class="form-group" *ngIf="leadAssignData.remarks != null && leadAssignData.remarks != ''">
                            <label class="control-label">Note History</label>
                            <div class="note-box" [innerHTML]="leadAssignData.remarks"></div>
                        </div>
                        <div class="form-row">
                            <div *ngFor="let item of leadStatusList" class="col-6 col-md-3">
                                <div class="form-check form-check-inline" (change)="setSiteFormValidators(item.stageType)">
                                    <input class="form-check-input" type="radio" formControlName="status" id="status{{item.id}}" value="{{item.stageName}}">
                                    <label class="form-check-label text-capitalize" for="status{{item.id}}">{{item.stageName}}</label>
                                </div>
                            </div>
                        </div>
                        <div class="form-group" *ngIf="statusType != 'WRONG_CONTACT'">
                            <label class="control-label required">Note</label>
                                <textarea trim="blur" class="form-control" rows="4" formControlName="remarks"></textarea>
                        </div>
                        <div class="form-row" *ngIf="statusType == 'CALL_LATER' || statusType == 'FOLLOW_UP'">
                            <div class="col-12 col-md-6" *ngIf="statusType == 'FOLLOW_UP'">
                                <div class="form-group ">
                                    <label class="control-label pl-0 required">Telecaller</label>
                                    <select class="form-control text-capitalize" formControlName="telecallerId">
                                        <option value="">Select</option>
                                        <option *ngFor="let item of telecallerList" value="{{item.id}}">{{item.userProfileName}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-6 col-md-3">
                                <div class="form-group">
                                    <label class=" control-label required">Date</label>
                                    <input type="text" placement="top right" formControlName="date" readonly
                                    class="form-control date-icon modal-space" [minDate]="minDate" bsDatepicker autocomplete="off"
                                    [bsConfig]="{showWeekNumbers: false,dateInputFormat: 'DD/MM/YYYY',containerClass:'dateLeft theme-green'}">
                                </div>
                            </div>
                            <div class="col-6 col-md-3">
                                <div class="form-group">
                                    <label class=" control-label required">Time</label>
                                    <div class="timepicker-position">
                                        <timepicker formControlName="time"></timepicker>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-right"><button type="submit" class="btn btn-dark" [disabled]="isSubmitted">Submit</button></div>
                    </form>
                </tab>
                <tab *ngIf="audience.mobile != null" heading="Call History" (selectTab)="getcallLogs()">
                    <div class="table-responsive table-height-scroll table-reports">
                        <table class="table table-bordered" *ngIf="callLogs != undefined">
                            <tbody>
                                <tr>
                                    <th>Phone No.</th>
                                    <th>Duration</th>
                                    <th>Date & Time</th>
                                    <th>Type</th>
                                    <th>Called By</th>
                                    <th>Recording</th>
                                </tr>
                                <tr *ngFor="let item of callLogs">
                                    <td>{{item.number}}</td>
                                    <td>{{item.durationMin}}</td>
                                    <td>{{item.callDate | date:'MMM dd, yyyy  HH:mm'}}</td>
                                    <td [ngClass]="item.state">{{item.state}}</td>
                                    <td>{{item.telecaller}}</td>
                                    <td *ngIf="item.recordingUrl !='' && item.recordingUrl !=null">
                                        <audio controls="controls">
                                            <source src={{item.recordingUrl}}>
                                        </audio>
                                    </td>
                                </tr>
                                <tr *ngIf="callLogs.length == 0">
                                    <td class="no-records" colspan="5">No Records Found</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </tab>
            </tabset>
      </div>
  </section>
</main>
