import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Global } from '../../../../shared/models/global.model';
import { noWhitespaceValidator } from '../../../../shared/models/no-whitespace-validator';
import { UserService } from '../../../../core/services/user.service';
@Component({
  selector: 'app-restore-pass',
  templateUrl: './restore-pass.component.html',
  styleUrls: ['./restore-pass.component.scss']
})
export class RestorePassComponent implements OnInit {

  key: string;
  token: string;
  email: string;
  userId:any;
  form: FormGroup;
  constructor(private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    public global: Global,
    private toastr: ToastrService,
    private userservice: UserService
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      newPassword: ['', Validators.compose([Validators.required, noWhitespaceValidator, Validators.minLength(3), Validators.maxLength(32)])],
      confirmPassword: ['', Validators.compose([Validators.required, noWhitespaceValidator, Validators.minLength(3), Validators.maxLength(32)])],
      id: [this.userId],
    });
    
    this.key = this.route.snapshot.queryParamMap.get("key");
    this.token = this.route.snapshot.queryParamMap.get("token");
   
    
    this.linkExpired();
  }
  get af() { return this.form.controls; }

  linkExpired() {
    this.userservice.linkExpiredForgotPassword({ encodeUserId: this.key, encodedExpiryTime: this.token}).subscribe( data => {
        if (data.status) {
          this.userId = data.data.id;
        } else {
          this.toastr.error(data.error_msgs, 'Error');
          this.router.navigate(["/admin/sign-in"]);
        }
    });
  }

  restorePass(){
    
    if (this.form.invalid) {
      this.toastr.error(this.global.MessageRequired); this.global.validateAllFormFields(this.form);
      return;
    }


    let message = this.checkPasswordValidity(this.af.newPassword.value);
        if(message){
            this.toastr.error(message);
            return;
        }

    this.userservice.updateNewPassword({ password:this.af.newPassword.value, confirmPassword:this.af.confirmPassword.value, id : this.userId}).subscribe( data => {
        if(data.status){
          this.toastr.success('Update Password Successfully');
          this.router.navigate(["/admin/sign-in"]);
        } else {
          this.toastr.error(data.error_msgs,'Error');  
        }
    });
  }

  checkPasswordValidity = (value) => {
        
    const combinedRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}[\]|\\:;"'<>,.?/_₹]).{8,20}$/;
  
    if (!combinedRegex.test(value)) {
        return "Passwords must have 1 digit, 1 uppercase, 1 lowercase, and a special character. The length should be between 8-20 characters.";
    }
    return null;
  }
  
}
