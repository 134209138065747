<main>
  <section class="inner-page">
    <div class="inner-page-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <h4>
              <a class="back-link" (click)="back()"><img src="assets/images/angle-left.svg"></a>
              Subscription
            </h4>
            <p>Keep your plan updated as per requirement, It will stop collecting data once exceeds limit of your existing plan.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="inner-page-body">
      <div class="plan-space">
        <div class="container-fluid">
          <h5>Current Plan Detail</h5>
          <div class="field-section row">
              <div class="col-md-12">
                <div class="field-group">
                  <label>Plan Name:</label>
                  <div class="field-box red">
                    {{currentPlan.planName}}
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="field-group">
                  <label>Plugins :</label>
                  <div class="field-box">
                    {{currentPlan.plugins}}
                  </div>
                </div>
              </div>
              
              <div class="col-md-12">
                <div class="field-group">
                  <label>Marketing Channels :</label>
                  <div class="field-box ">
                    {{currentPlan.channels}}
                  </div>
                </div>
              </div>
              
              <div class="col-md-12">
                <div class="field-group">
                  <label>Assigned MAU :</label>
                  <div class="field-box ">
                    {{userProjectDetail.mauLimit}}
                  </div>
                </div>
              </div>


              <div class="col-md-12">
                <div class="field-group">
                  <label>Maximum Audience Count:</label>
                  <div class="field-box ">
                    {{userProjectDetail.maxUserLimit}}
                  </div>
                </div>
              </div>

              

              <div class="col-md-12">
                <div class="field-group" *ngIf="currentPlan.renewDate!=null">
                  <label>Next Renewal Date :</label>
                  <div class="field-box">
                    {{userProjectDetail.endDate | date}}
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>

      <ng-container *ngIf="activateSource.length > 0">
        <div class="container-fluid m_t_20 m_b_20">
          <h5 class="m_b_20">Allowed Plugins</h5>
          <div class="row">
            <div class="col-md-12">
            <div class="table-responsive table-height-scroll table-reports">
              <table class="table callAssistDashboardTable th_font_14">
                <thead class="sticky-header" data-sticky-header="true">
                  <tr class="callAssistDbRowTop">
                    <th class="borderTh text-center">Sr. No.</th>
                    <th class="borderTh">Plugin Name</th>
                    <th class="borderTh text-center">Max Count</th>
                    <th class="borderTh text-center">Lead Active</th>
                    <th class="borderTh text-center">Repeat Flag</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let subscribeData of activateSource; let i=index">
                  <tr *ngIf="subscribeData.pluginActive == 'ACTIVE'">
                    <td class="text-center">{{i+1}}</td>
                    <td>{{subscribeData.pluginName}}</td>
                    <td class="text-center">{{subscribeData.maxCount}}</td>
                    <td class="text-center">{{subscribeData.leadActive}}</td>
                    <td class="text-center">{{subscribeData.leadRepeatFlag}}</td>
                  </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
            </div>
          </div>
        </div>
      </ng-container>



      <ng-container *ngIf="activatedChannels.length > 0">
        <div class="container-fluid m_t_20 m_b_20">
          <h5 class="m_b_20">Allowed Channels</h5>
          <div class="row">
            <div class="col-md-12">
            <div class="table-responsive table-height-scroll table-reports">
              <table class="table callAssistDashboardTable th_font_14">
                <thead class="sticky-header" data-sticky-header="true">
                  <tr class="callAssistDbRowTop">
                    <th class="borderTh text-center">Sr. No.</th>
                    <th class="borderTh">Channel Name</th>
                    <th class="borderTh text-center">Status</th>
                    <th class="borderTh text-center">Instant Campaign Count</th>
                    <th class="borderTh text-center">Repeat Campaign Count</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let subscribeData of activatedChannels; let i=index">
                    <td class="text-center">{{i+1}}</td>
                    <td>
                      <ng-container *ngIf="subscribeData.intgChannelId == 1">SMS</ng-container>
                      <ng-container *ngIf="subscribeData.intgChannelId == 2">Email</ng-container>
                      <ng-container *ngIf="subscribeData.intgChannelId == 3">In App</ng-container>
                      <ng-container *ngIf="subscribeData.intgChannelId == 4">Push Notification</ng-container>
                      <ng-container *ngIf="subscribeData.intgChannelId == 5">Whatsapp</ng-container>
                      <ng-container *ngIf="subscribeData.intgChannelId == 6">Whatsapp Api</ng-container>
                    </td>
                    <td class="text-center">{{subscribeData.active}}</td>
                    <td class="text-center">{{subscribeData.instantCampaignCount}}</td>
                    <td class="text-center">{{subscribeData.repeatCampainCount}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            </div>
          </div>
        </div>
      </ng-container>



    </div>
  </section>
</main>












