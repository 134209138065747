import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { BsModalRef, BsDatepickerConfig } from "ngx-bootstrap";
import { ToastrService } from "ngx-toastr";
import { CampaignService } from "../../../../core/services/campaign.service";
import {
  distinctUntilChanged,
  debounceTime,
  switchMap,
  tap,
  catchError,
  map
} from "rxjs/operators";
import { Subject, of, concat, Observable, Subscription } from "rxjs";
import { Global } from "../../../../shared/models/global.model";
import { DatePipe } from "@angular/common";
// import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DatePickerObj } from "../../../../core/modal/datePicker";
import * as moment from "moment";
import {AuthenticationService} from "../../../../core/services/authentication.service"
import { ProjectService } from "../../../../core/services/project.service";
import { debounce } from 'lodash';
import { UserService } from "../../../../core/services/user.service";
@Component({
    selector: "app-campaign-create",
    templateUrl: "./campaign-create.component.html",
    styleUrls: ["./campaign-create.component.scss"]
})
export class CampaignCreateComponent implements OnInit {
    audienceJson: any = { text: "select audience" };
    selectedFilters: any = [];
    validFilters: any = true;
    modalRef: BsModalRef;
    dateMsg: any = '';
    startDate: any;
    endDate: any;
    dateChecked: boolean = false;
    bsConfig: Partial<BsDatepickerConfig>;
    colorTheme = "theme-red";
    maxDate : any;
    fromDate: any = '';
    toDate: any = '';
    searchKeyword: string = 'paramValue';
    @Output() createAudienceClose = new EventEmitter();
    @Output() updateAudience = new EventEmitter();
    @Input() closeFilter: Observable<any>;
    @Input() isSourceUpdate: boolean;
    private closeFilterSubscription: Subscription;
    public filterDateRange: DatePickerObj;
    public ranges: any;
    filters:any = []
    paramList:any;
    isJourney:boolean = false;
    dataList:any;
    constructor(
        private toastr: ToastrService,
        private campaignService: CampaignService,
        private datePipe: DatePipe,
        public global: Global,
        private authService:AuthenticationService,
        private projectService:ProjectService,
        private userService:UserService
    ) {
        this.fetchFilterDetails = debounce(this.fetchFilterDetails,500);
    }



    ngOnInit() {
        this.fetchCategory();
        this.isJourney = this.global.CampaignData.campaign == 'journey'
        this.ranges = this.global.customDateRange();
        this.updateChannelData();
        this.closeFilterSubscription = this.closeFilter.subscribe( data => {
            this.updateChannelData();
        })
        this.maxDate = moment();
    }

    fetchFilterDetails(obj?,filterData?){
        this.getFilterDetails(obj,filterData);
      }

    updateChannelData(){
        let selectedFilter = JSON.parse(localStorage.getItem('selectedFilterCamp'));        
        if (!this.AnyChannelSelected()) {
            return;
        }
        if(this.isSourceUpdate || this.global.Channel.Attributes.length == 0){
            var length = this.selectedFilters.length;
            for (let index = 0; index < length; index++) {
                this.selectedFilters.splice(0, 1);
            }
        }


        for(let i=0; i<this.selectedFilters.length; i++){
            let checkGobalFilter = this.global.Channel.Attributes.find(x=>x.id == this.selectedFilters[i].id);
            if(checkGobalFilter == undefined){
                delete this.selectedFilters[i]
            }
        }

        this.selectedFilters = this.selectedFilters.filter((p)=>{
            return p != null;
        })

        this.global.Channel.Attributes.forEach(element => {                   
            if (this.selectedFilters.filter(x => x.id == element.id).length == 0) {
                if(element.selected==undefined){
                    this.addFilter(JSON.stringify(element), null);
                } else {
                    this.selectedFilters = this.global.Channel.Attributes;
                }
            }
        });
        
        this.selectedFilters.forEach((element,i) => {                        
            if (this.global.Channel.Attributes.filter(x => x.id == element.id).length == 0) {
                this.removeFilter(element);
            }
            if(element.paramDatatype === "Date" && this.selectedFilters[i].selected != undefined){
                this.selectedFilters[i].selected.value = 'between';
            }
            if(element.paramDatatype === "Numeric"){   
                this.selectedFilters[i].selected = []
                this.selectedFilters[i].selected.value = '>=';
            }
            if(element.paramDatatype == 'String' || element.paramDatatype == 'string' || element.paramDatatype == 'Mobile' || element.paramDatatype == 'Email'){
                if(element.paramKey == 'lead_status'){
                    if(element.selected){
                        if(element.selected.length > 0){
                            element.selected = [];
                            element.addedValues = []
                        }
                    }
                }
                element.selected = [];
                 this.selectedFilters[i].selected.value = this.selectedFilters[i].selected.value ? this.selectedFilters[i].selected.value : 'in';
                
            }
            if(selectedFilter){
            let checkExistFilter = selectedFilter.find(x=>x.id == element.id);
            if(checkExistFilter){                
                if(checkExistFilter.paramDatatype == 'Date'){
                    checkExistFilter.selected.text1 = new Date(checkExistFilter.selected.text1)
                    checkExistFilter.selected.text2 = new Date(checkExistFilter.selected.text2)
                    if(checkExistFilter.selected.text1 == "Invalid Date"){
                        checkExistFilter.selected.text1 = ""  
                    }
                    if(checkExistFilter.selected.text2 == "Invalid Date"){
                        checkExistFilter.selected.text2 = ""  
                    }
                }
                if(checkExistFilter.operator){
                    checkExistFilter.selected.value = checkExistFilter.operator
                }
                setTimeout(() => {
                    this.selectedFilters[i] = checkExistFilter;
                }, 500);
                
            }
            }
            

        });
        if(this.global.Channel.dateMsg != undefined && this.global.Channel.dateMsg != ''){
            this.startDate =  this.global.Channel.fromDate;
            this.endDate = this.global.Channel.toDate;
            this.dateMsg = this.global.Channel.dateMsg;
        }        
    }

    ngOnDestroy() {
        this.closeFilterSubscription.unsubscribe();
    }

    AnyChannelSelected() {
        if (!this.global.AnyChannelSelected() && this.selectedFilters.length == 0) {
            this.createAudienceClose.emit(1);
            return false;
        }
        return true;
    }

    removeChannel(channelType) {
        this.global.ClearSpecificChannel(channelType);
        this.AnyChannelSelected();
    }

    addFilter(value, bypass) {
        this.audienceJson = JSON.parse(value);                
        if (this.audienceJson.id == undefined) {
            this.removeFilters();
            return;
        }
        if (!bypass) this.audienceJson.selected = {};
        if (this.audienceJson.paramDatatype == "String" || this.audienceJson.paramDatatype == "string" || this.audienceJson.paramDatatype == "Email" || this.audienceJson.paramDatatype == "Mobile") {
            this.getFilterDetails(this.audienceJson);
        } else {
            this.selectedFilters.push(this.audienceJson);
        }
    }

    removeFilter(obj) {
        for(let i=0; i<this.selectedFilters.length; i++){
            if(this.selectedFilters[i].id == obj.id){
                delete this.selectedFilters[i]
            }
        }
        this.selectedFilters = this.selectedFilters.filter((p)=>{
            return p != null;
        })
        this.global.ClearSpecificChannelId('Attributes', obj.id);
        this.AnyChannelSelected();
        localStorage.setItem('selectedFilterCamp',JSON.stringify(this.selectedFilters)); 
    }



    removeAudience() {
        
    }


    removeFilters() {
        var length = this.selectedFilters.length;
        for (let index = 0; index < length; index++) {
            this.selectedFilters[0].addedValues = []
            this.selectedFilters[0].selected = []
            this.selectedFilters.splice(0, 1);
        }
        this.removeChannel('Attributes');
        localStorage.removeItem('selectedFilterCamp');
    }

    stringify(input) {
        return JSON.stringify(input);
    }

    removeParamVal(item){
        setTimeout(() => {
          item.addedValues = item.selected;
        }, 100);
      }

      clearStorage(item){
        setTimeout(() => {
            item.addedValues = item.selected;
          }, 100);
      }
  
      itemOpratorSet(item){
        item.operator = item.selected.value;        
      }


      storeCurrentFilter(){
        const myClonedArray = [];
        this.selectedFilters.forEach(val => myClonedArray.push(Object.assign({}, val)));
        let myFilter = this.selectedFilters;
        setTimeout(() => {
        myFilter.forEach(element => {
            element.operator = element.selected.value;
            for(let key in element){                    
            if(key == 'values'){
                delete element[key];          
            }
            if(key == 'valueInput$'){
                delete element[key];          
            }
            if(key == 'values$'){
                delete element[key];          
            }
            }
        });
        this.selectedFilters = myClonedArray;    
        localStorage.setItem('selectedFilterCamp',JSON.stringify(myFilter));      
        }, 1000);
      }

      storeParamVal(item){
        setTimeout(() => {            
          item.addedValues.value = item.selected.value;
          item.operator = item.operator ? item.operator : 'in';
          item.selected.forEach(element => {          
            if(element){
              if(item.addedValues.length > 0){
                let checkVal = item.addedValues.find(x=>x.paramValue == element.paramValue);
                if(checkVal == undefined){
                item.addedValues.push(element);
                }
              } else{
                item.addedValues.push(element);
              }
            }
          });
          item.selected = item.addedValues;          
        const myClonedArray = [];
        this.selectedFilters.forEach(val => myClonedArray.push(Object.assign({}, val)));
        let myFilter = this.selectedFilters;
        setTimeout(() => {
        myFilter.forEach(element => {
            for(let key in element){                    
            if(key == 'values'){
                delete element[key];          
            }
            if(key == 'valueInput$'){
                delete element[key];          
            }
            if(key == 'values$'){
                delete element[key];          
            }
            }
        });
        this.selectedFilters = myClonedArray;    
        localStorage.setItem('selectedFilterCamp',JSON.stringify(myFilter));      
        }, 1000);
        }, 100);
        
      }

    fetchCategory() {        
        this.dataList = [];
        this.projectService.fetchCategory({}).then(data => {
            if (data) {
                this.dataList = data;
            }
        })
    }  

    getFilterDetails(obj,objEvent?) {        
        let checkOldFilter = JSON.parse(localStorage.getItem('selectedFilterCamp'));        
        if(checkOldFilter){
            let checkObjInFilter = checkOldFilter.find(x=>x.id == obj.id);
            if(checkObjInFilter){
                let checkInSelectedFilter = this.selectedFilters.find(x=>x.id == checkObjInFilter.id);
                if(checkInSelectedFilter == undefined){
                    checkObjInFilter.selected.value = checkObjInFilter.operator
                    this.selectedFilters.push(checkObjInFilter)
                }
            }
        }
      let setObjValue;
      if(objEvent){
        setObjValue = objEvent.target.value
      }
      if(this.selectedFilters.length > 0){
        let checkAvail = this.selectedFilters.find(x=>x.paramKey == obj.paramKey);
        if(checkAvail == undefined){
          obj.addedValues = []
          obj.selected = []
          obj.selected.value = 'in';
          this.selectedFilters.push(obj);
        } } else{
        obj.addedValues = []
        obj.selected = []
        obj.selected.value = 'in';        
        this.selectedFilters.push(obj);
      }      
      if(obj.paramKey == 'telecaller_name' && this.global.categorySelectByCamapign.length > 0){
        let existingUsers = [];
        this.projectService.getUserFromMaster().then(data => {
            if (data != undefined) {
                let userData = Object.keys(data);
                userData.forEach(elem=>{
                    if(data[elem].status == 'ACTIVE'){
                        existingUsers.push({'paramKey':'telecaller_name','paramValue':data[elem].userName});
                }
                });
                if(existingUsers){
                    obj.values = [];
                    obj.values = existingUsers
                    var selected = obj.selected;
                    this.loadAutoComplete(obj);
                    obj.selected = selected;
                    let checkBeforePush = this.selectedFilters.find(x=>x.paramKey == 'telecaller_name');
                  if(checkBeforePush){
                    for(let i=0; i<this.selectedFilters.length; i++){
                      if(this.selectedFilters[i].paramKey == 'telecaller_name'){
                        delete this.selectedFilters[i];
                      }
                    }
                    this.selectedFilters = this.selectedFilters.filter(p=>{
                      return p != null;
                    })
                    this.selectedFilters.push(obj);
                  } else{
                    this.selectedFilters.push(obj);
                  }
                }
            }
        });
        return
      }
      
      if(obj.paramKey == 'lead_status' && this.global.categorySelectByCamapign.length > 0){
        this.dataList = [];
        this.projectService.fetchCategory({}).then(data => {
        if (data) {
        this.dataList = data;
        let selectedCatStatus = []
        this.global.categorySelectByCamapign.forEach(category => {
            let checkCatSelected = this.dataList.find(x=>x.linkRewrite == category);
            if(checkCatSelected && checkCatSelected.leadStatusList){
                checkCatSelected.leadStatusList.forEach(status => {
                    let checkToPushSameStatus = selectedCatStatus.find(x=>x.paramValue == status.stageName);
                    if(checkToPushSameStatus == undefined){
                    selectedCatStatus.push({'paramKey':"lead_status",paramValue:status.stageName});
                    }
                  });
            }
        });
        if(selectedCatStatus.length > 0){
            obj.values = [];
            obj.values = selectedCatStatus
            var selected = obj.selected;
            this.loadAutoComplete(obj);
            obj.selected = selected;
            let checkBeforePush = this.selectedFilters.find(x=>x.paramKey == 'lead_status');
          if(checkBeforePush){
            for(let i=0; i<this.selectedFilters.length; i++){
              if(this.selectedFilters[i].paramKey == 'lead_status'){
                delete this.selectedFilters[i];
              }
            }
            this.selectedFilters = this.selectedFilters.filter(p=>{
              return p != null;
            })
            this.selectedFilters.push(obj);
          } else{
            this.selectedFilters.push(obj);
          }
        }        
    }
})
        return
      }
      
      if(!setObjValue && obj.paramKey != 'lead_status' && obj.paramKey != 'telecaller_name' && obj.paramKey != 'country'){
        return;
      }
        this.campaignService.searchParamData({ paramKey: obj.pluginName == 'dynamic'?'params.'+obj.paramKey:obj.paramKey,paramValue:setObjValue ? setObjValue : "" }).subscribe(data => {
            obj.values = [];
            if (!data.status) {
                obj.error_msgs = data.error_msgs;
            } else {
                delete obj.error_msgs;
                data.data.forEach(element => {
                    element.paramValue = element.paramValue.length > 50 ? element.paramValue.slice(0, 50) : element.paramValue;
                    obj.values.push(element);
                });                
                var selected = obj.selected;
                this.loadAutoComplete(obj);
               if(!obj.addedValues){
                obj.addedValues = []
               }
                if(obj.addedValues.length > 0){
                    obj.selected = obj.addedValues
                  } else{
                    obj.selected = selected
                  }

                obj.selected.value = selected.value != undefined ? selected.value : 'in'
            }            
            for(let i=0; i<this.selectedFilters.length; i++){
                if(this.selectedFilters[i].paramKey == obj.paramKey){
                   this.selectedFilters[i] = obj;
                }
              }
        });
    }

    private loadAutoComplete(obj) {
        obj.valueInput$ = new Subject<string>();
        obj.selected = <any>[];
        obj.values$ = concat(
        of([]),
        obj.valueInput$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            tap(() => (obj.loading = true)),
            switchMap(term =>
                this.getSourceData(obj.paramKey, term).pipe(
                    catchError(() => of([])),
                    tap(() => (obj.loading = false))
                )
            )
        )
        );
    }

    getSourceData(paramKey, term) {
        return this.campaignService.searchParamData({ paramKey: paramKey, paramValue: term }).pipe(map(res => res.data));
    }

    selectedInFilter(i,type){
        setTimeout(() => {
            this.selectedFilters[i].selected.value = type
        }, 100);
    }

    gotoNext() {
        let selectedFilter = JSON.parse(localStorage.getItem('selectedFilterCamp'));        
        var searchParameters = this.getSearchParam();
        this.paramList = searchParameters;
        if(!this.paramList){
            return
        }
        if (this.validFilters) {
            
            this.global.Channel.Attributes = searchParameters;
            if(this.dateChecked){
                this.global.Channel.fromDate = this.startDate;
                this.global.Channel.toDate = this.endDate;
                this.global.Channel.dateMsg = this.dateMsg;

                this.filters = this.projectService.filterAttributeList(['all']);
                this.authService.getProfile().then(data=>{
                    this.filters.forEach(element=>{
                        if(element.paramKey == data.sortBy){
                            element.selected = []
                            element.checked = true;
                            element.values = []
                            element.selected.value = 'between';
                            element.selected.text1 = new Date(this.startDate);
                            element.selected.text2 = new Date(this.endDate);
                            this.global.Channel.Attributes.push(element);
                            this.selectedFilters.push(element);
                            this.getSearchParam();
                        }
                    })
                })

            }
            
            if (this.global.AnyChannelSelected())
                this.createAudienceClose.emit(2);
                localStorage.removeItem('selectedFilterCamp');
        }
    }
  
    private getSearchParam() {
        this.validFilters = true;
        let paramList = [];
        for (let index = 0; index < this.selectedFilters.length; index++) {
        const element = this.selectedFilters[index];
        console.log(element)
        element.conainsError = false
        var parm = element;
        parm.paramValue ='';
        parm.paramOprator ='';
        if ( element.paramDatatype == "String" || element.paramDatatype == "string" || element.paramDatatype == "Email" || element.paramDatatype == "Mobile" ) {
            parm.paramOprator = element.selected.value;
            parm.paramValue = "";
            if (element.selected != undefined && (element.selected.value == 'in' || element.selected.value == 'not_in')) {
                for (let i = 0; i < element.selected.length; i++) {
                    const el = element.selected[i];
                    parm.paramValue +=
                    "'" +
                    el.paramValue +
                    "'" +
                    (element.selected.length == i + 1 ? "" : ",");
                }
            } else{
                if(element.selected.text1.paramValue != undefined){
                    element.selected.text1 = element.selected.text1.paramValue
                }
                parm.paramValue = element.selected.text1;

                let paramValSplitByComma = parm.paramValue.split(',');
                paramValSplitByComma = paramValSplitByComma.filter((p)=>{
                return p != null;
                })
                let storeWithoutSpace = []
                for(let i=0; i<paramValSplitByComma.length;i++){
                    if(paramValSplitByComma[i].length < 3){
                      element.conainsError = true;
                      return
                    }
                  }
                paramValSplitByComma.forEach(elem => {
                elem = elem.trim();
                storeWithoutSpace.push(elem);
                });
                parm.paramValue = storeWithoutSpace.join();   
            }
            if (parm.paramValue == "") {
                this.invalidateCalculateAudience(); 
            }
        } else if (element.paramDatatype == "Numeric") {
            parm.paramOprator = element.selected.value;
            if (element.selected.value == "between") {
                parm.paramValue =
                element.selected.text1 + " and " + element.selected.text2;
                if (
                    element.selected.text1 == undefined ||
                    element.selected.text1 === "" ||
                    element.selected.text2 === "" ||
                    element.selected.text2 == undefined ||
                    element.selected.text1.length > 10 ||
                    element.selected.text2.length > 10
                ) {
                    this.invalidateCalculateAudience();   
                }
            } else {
                element.selected.text1 = parseFloat(element.selected.text1);
                parm.paramValue = element.selected.text1;
                if(
                    element.selected.text1 == undefined ||
                    element.selected.text1 === "" ||
                    element.selected.text1.length > 10
                ) {
                    this.invalidateCalculateAudience();
                }
            }
            if (parm.paramValue == undefined || parm.paramValue === "" || Number.isNaN(parm.paramValue) == true) {
                this.invalidateCalculateAudience();
            }
        } else if (element.paramDatatype == "Date") {
            parm.paramOprator = element.selected.value;
            if (element.selected.value == "between") {
            
            if (
                element.selected.text1 > element.selected.text2 ||
                element.selected.text1 == undefined ||
                element.selected.text1 == "" ||
                element.selected.text2 == "" ||
                element.selected.text2 == undefined ||
                element.selected.text1.length > 10 ||
                element.selected.text2.length > 10
            ) {
                this.invalidateCalculateAudience(); 
            }
            parm.paramValue =
                "date('" +
                this.datePipe.transform(element.selected.text1, "yyyy-MM-dd") +
                " 00:00:00') and date('" +
                this.datePipe.transform(element.selected.text2, "yyyy-MM-dd") +
                " 23:59:59')";
            } else {
                if (
                    element.selected.text1 == undefined ||
                    element.selected.text1 == "" ||
                    element.selected.text1.length > 10
                ) {
                    this.invalidateCalculateAudience();
                }
                parm.paramValue = "date('" + this.datePipe.transform(
                    element.selected.text1,
                    "yyyy-MM-dd"
                )+"')";
            }
            if (parm.paramValue == undefined || parm.paramValue == "") {
                this.invalidateCalculateAudience();
            }
        }
            if(parm.error_msgs == undefined)
            paramList.push(parm);
        }
        return paramList;
    }

    private invalidateCalculateAudience() {
        this.validFilters = false;
        this.toastr.error("Please fill valid input in all mandatory fields");
    }

    filterClose() {
        this.global.Channel.Attributes.forEach(element => {            
            if (this.selectedFilters.filter(x => x.id == element.id).length == 0) {
                this.addFilter(JSON.stringify(element), null);
            }
        });
    }

    onDateSelection(date) {
        this.startDate = this.datePipe.transform(date.startDate, "yyyy-MM-dd");
        this.endDate = this.datePipe.transform(date.endDate, "yyyy-MM-dd");
        this.dateChecked = this.startDate!= undefined && this.startDate != null;
        this.dateMsg = this.datePipe.transform(date.startDate, "dd MMM") + " to " + this.datePipe.transform(date.endDate, "dd MMM yyyy");
    }

    resetDate() {
        this.startDate = null;
        this.endDate = null;
        this.dateChecked = false;
        this.filterDateRange = null;
        this.dateMsg = '';
        this.global.ClearSpecificChannel('date');
    }

}
