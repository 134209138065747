import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignInComponent } from './modules/admin/pages/sign-in/sign-in.component';
import { MarketerComponent } from './modules/admin/pages/marketer/marketer.component';
import { LayoutComponent } from './modules/admin/components/layout/layout.component';
import { ProjectIntegrationComponent } from './modules/admin/pages/project-integration/project-integration.component';
import { AuthLayoutComponent } from './modules/admin/components/auth-layout/auth-layout.component';
import { AuthGuard } from './core/guards/auth.guard';
import { UserManagementComponent } from './modules/admin/pages/user-management/user-management.component';
import { ManageCampaignsComponent } from './modules/admin/pages/manage-campaigns/manage-campaigns.component';
import { ConfigurationComponent } from './modules/admin/pages/configuration/configuration.component';
import { EditAccountComponent } from './modules/admin/pages/edit-account/edit-account.component';
import { IntegrationKitComponent } from './modules/admin/pages/integration-kit/integration-kit.component';
import { ChangePasswordComponent } from './modules/admin/pages/change-password/change-password.component';
import { RestorePassComponent } from './modules/admin/pages/restore-pass/restore-pass.component';
import { ViewAudienceComponent } from './modules/admin/pages/view-audience/view-audience.component';
import { AudienceProfileComponent } from './modules/admin/pages/audience-profile/audience-profile.component';   
import { BulkUploadComponent } from './modules/admin/pages/bulk-upload/bulk-upload.component';   
import { UploadConfirmComponent } from './modules/admin/pages/upload-confirm/upload-confirm.component';
import { CreateCampaignWithSegmentComponent } from './modules/admin/pages/create-campaign-with-segment/create-campaign-with-segment.component';
import { SegmentsComponent } from './modules/admin/pages/segments/segments.component';
import { CreateSegmentsComponent } from './modules/admin/pages/create-segments/create-segments.component';
import { SubscriptionInvoiceComponent } from './modules/admin/pages/subscription-invoice/subscription-invoice.component';
import { ChannelTypeComponent } from './modules/admin/pages/channel-type/channel-type.component';
import { CampaignCreateComponent } from './modules/admin/pages/campaign-create/campaign-create.component';
import { PricingComponent } from './modules/admin/pages/home/pricing.component';
import { IntergationDocComponent } from './modules/admin/pages/home/intergration-doc.component';
import { UpgradeSubscriptionComponent } from './modules/admin/pages/upgrade-subscription/upgrade-subscription.component';
import { ManagePluginCampaignsComponent } from './modules/admin/pages/manage-plugin-campaign/manage-plugin-campaign.component';
import { CampaignTemplateComponent } from './modules/admin/pages/campaign-template/campaign-template.component';
import { CampaignGalleryComponent } from './modules/admin/pages/campaign-gallery/campaign-gallery.component';
import { announcementPopupComponent } from './modules/admin/pages/announcement-template/popup.component';
import { DashboardComponent } from './modules/admin/pages/dashboard/dashboard.component';
import { AudienceReportComponent } from './modules/admin/pages/audience-report/audience-report.component';
import { AnalysisComponent } from './modules/admin/pages/analysis/analysis.component';
import { announcementBarListComponent } from './modules/admin/pages/announcement-template/bar.component-list';
import { announcementBarComponent } from './modules/admin/pages/announcement-template/bar.component';
import { announcementPopupListComponent } from './modules/admin/pages/announcement-template/popup.component-list';
import { GalleryPopupComponent } from './modules/admin/pages/campaign-gallery/gallery-popup.component';
import { LeadStatusComponent } from './modules/admin/pages/lead-status/lead-status.component';
import { UrlShortnerComponent } from './modules/admin/pages/url-shortner/url-shortner.component';
import { LandingPageComponent } from './modules/admin/pages/landing-page/landing-page.component';
import { LandingPageViewComponent } from './modules/admin/pages/landing-page-view/landing-page-view.component';
import { AdvancePopupComponent } from './modules/admin/pages/announcement-template/advance-popup.component';
import { AccountLayoutComponent } from './modules/admin/components/account-layout/account-layout.component';
import { DownloadAudienceDataComponent } from './modules/admin/pages/download-audience-data/download-audience-data.component';
import { AudienceDetailComponent } from './modules/admin/pages/audience-detail/audience-detail.component';
import { FollowUpComponent } from './modules/admin/pages/follow-up/follow-up.component';
import { AuthenticationMethodsComponent } from './modules/admin/components/authentication-methods/authentication-methods.component';
import { LoggedinSessionComponent } from './modules/admin/components/loggedin-session/loggedin-session.component';
import { CategoryComponent } from './modules/admin/pages/category/category.component'; 
import { TurnAroundTimeComponent } from './modules/admin/pages/turn-around-time/turn-around-time.component';


const routes: Routes = [
  { path: '', redirectTo : 'admin/sign-in', pathMatch: 'full' },
  { path: 'integration', component: IntergationDocComponent},
  { path: 'pricing', component: PricingComponent},
  { path: 'admin', component: LayoutComponent, children : [
    { path: '', redirectTo : 'sign-in', pathMatch: 'full' },
    { path: 'sign-in', component: SignInComponent },
    { path: 'sign-out', redirectTo : 'sign-in' },
    { path: 'marketer', component: MarketerComponent },
    { path: 'recover-pass', component: RestorePassComponent },
  ]},
  {   path: 'admin', component: AccountLayoutComponent, children : [
    { path: 'create-account', component: EditAccountComponent },
  ]},
  {   path: 'marketer', component: LayoutComponent, children : [
    { path: 'signup', component: MarketerComponent },
  ]},
    { path:'admin', component: AuthLayoutComponent, canActivate: [AuthGuard], children : [
    { path: 'project-integration/:type', component: ProjectIntegrationComponent,  },
    { path: 'project-integration/:cat/:pluginId', component: ProjectIntegrationComponent,  },
    { path: 'report', component: ManageCampaignsComponent,  },
    { path: 'report/:name/:id', component: ManageCampaignsComponent,  },
    { path: 'configuration', component: ConfigurationComponent,  },
    { path: 'configuration/:type', component: ConfigurationComponent,  },
    { path: 'user-management', component: UserManagementComponent,  },
    { path: 'edit-account', component: EditAccountComponent,  },
    { path: 'settings/api-integration-kit', component: IntegrationKitComponent,},
    { path: 'settings/authentication-methods', component: AuthenticationMethodsComponent,},
    { path: 'settings/logged-in-sessions', component: LoggedinSessionComponent,},
    { path: 'change-password', component: ChangePasswordComponent,  },
    { path: 'data/download-data', component: DownloadAudienceDataComponent,  },
    { path: 'lead/:dynamicparam/search/:id', component: ViewAudienceComponent,  },
    { path: 'lead/:dynamicparam', component: ViewAudienceComponent },
    { path: 'lead/:dynamicparam/:id', component: ViewAudienceComponent,},
    { path: 'lead/audience/view-audience/:type/:fromDate/:toDate/:filter1', component: ViewAudienceComponent,},
    { path: 'lead/audience/view-audience/:type/:fromDate/:toDate/:filter1/:filter2', component: ViewAudienceComponent,},
    { path: 'data/audience-report', component: AudienceReportComponent },
    { path: 'lead/audience/follow-up', component: FollowUpComponent },
    { path: 'data/analysis', component: AnalysisComponent  },
    { path: 'data/analysis/:dynamicparam', component: AnalysisComponent  },
    { path: 'lead/audience-profile/:id', component: AudienceProfileComponent},
    { path: 'settings/update-lead-status/:dynamicparam', component: LeadStatusComponent },
    { path: 'settings/update-lead-status/:dynamicparam/view-tat/:id', component: TurnAroundTimeComponent },
    { path: 'settings/create-category', component: CategoryComponent },
    { path: 'bulk-upload', component: BulkUploadComponent,  },
    { path: 'upload-confirm', component: UploadConfirmComponent,  },
    { path: 'create-campaign-segment/:type', component: CreateCampaignWithSegmentComponent },
    { path: 'edit-campaign-segment', component: CreateCampaignWithSegmentComponent },
    { path: 'segments', component: SegmentsComponent },
    { path: 'create-segment', component: CreateSegmentsComponent },
    { path: 'edit-segment/:id', component: CreateSegmentsComponent,  },
    { path: 'subscription', component: SubscriptionInvoiceComponent },
    { path: 'upgrade-subscription', component: UpgradeSubscriptionComponent },
    { path: 'channel-type', component: ChannelTypeComponent },
    { path: 'campaign-create', component: CampaignCreateComponent },
    { path: 'manage-plugin-campaigns/:type', component: ManagePluginCampaignsComponent },
    { path: 'manage-plugin-campaigns/:type/:id', component: ManagePluginCampaignsComponent },
    { path: 'campaign/campaign-template', component: CampaignTemplateComponent },
    { path: 'campaign/campaign-template/:type', component: CampaignTemplateComponent },
    { path: 'asset-library', component: CampaignGalleryComponent },
    { path: 'dashboard', component: DashboardComponent },
    { path: 'url-shortner', component: UrlShortnerComponent },
    { path: 'landing-page', component: LandingPageComponent },
    { path:'announcement',  children : [
      { path: 'bar', component: announcementBarListComponent},
      { path: 'edit-bar', component: announcementBarComponent},
      { path: 'edit-bar/:id', component: announcementBarComponent},
      { path: 'popup', component: announcementPopupListComponent},
      { path: 'edit-popup', component: announcementPopupComponent},
      { path: 'edit-popup/:id', component: announcementPopupComponent},
      { path: 'advance-popup', component: AdvancePopupComponent},
    ]}
  ] },
  { path:'landing-page', component: LandingPageViewComponent, canActivate: [AuthGuard]},
  { path: 'admin/thumb-campaign-gallery', component: GalleryPopupComponent, canActivate: [AuthGuard] },
  { path: 'analyse/*', redirectTo: '/' },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
