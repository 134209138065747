<main>
<section class="inner-page">
    <div *ngIf="isSubscribe">
        <iframe *ngIf="domain != undefined" [src]="domain"  #iframeTagName (load)="loadIframe(iframeTagName)" frameborder="0"></iframe>
        <div *ngIf="pageUnderProcess" class="page-loader">
            <div class="loader">
                <div class="la-ball-clip-rotate la-2x"><div></div></div>
                <h3>Advance Popup Loading...</h3>
            </div>
        </div>
    </div>

    <div *ngIf="!isSubscribe" class="inner-page-header">
        <div class="container-fluid">
            <h4>Advanced Popups</h4>
        </div>
    </div>
    <div *ngIf="!isSubscribe" class="inner-page-body">
        <app-page-disable ></app-page-disable>
    </div>
</section>
</main>