import { Component, OnInit, Input, ViewChild, Output, EventEmitter, TemplateRef, ElementRef } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { Global } from "./../../../../shared/models/global.model";
import { BsModalRef, TabsetComponent } from "ngx-bootstrap";
import { ProjectService } from "../../../../core/services/project.service";
import { AttributeObj, SourceObj } from "../../../../core/modal/project";
import { AuthenticationService } from "../../../../core/services/authentication.service";

@Component({
    selector: "app-fresh-audience-popup",
    templateUrl: "./fresh-audience-popup.component.html",
    styleUrls: ["./fresh-audience-popup.component.scss"]
})
export class FreshAudiencePopupComponent implements OnInit {
    @Input() modalRef: BsModalRef;
    @Input() usingFor: string;
    @Input() filterByCampaign:boolean
    @Input() paramList: any;
    @Input() filterClose: Function;
    @Output() filterClear = new EventEmitter<string>();
    @Input() addReport:any;
    @Input() notCloseOnSelect;
    @Input() leadReportForm:TemplateRef<any>;
    @Output() sendRecordStatus = new EventEmitter<any>();
    @Input() addAction :any;
    @Input() categoryName:any;
    @Input() category:any;
    // showInactiveForms:boolean=false;
    isAllSelectedInactiveForms:boolean = false;
    deselectAll:boolean = false;
    AttributeText: any;
    callNext:any;
    EventText: any;
    fetchText: any;
    filters: AttributeObj[] = [];
    fetchUser: SourceObj[] = [];
    removeExisting: any = false;
    addActionSent:boolean = false;
    existingSource: any = [];
    existingEvents: any = [];
    existingAttributes: any = [];
    showAllList:boolean = false;
    showInactiveList:boolean = false
    selectedSource : string[] = [];
    isSourceUpdate : boolean = false;
    isAllSelected: boolean;
    isReassign:boolean = false;
    showPropsStatus:boolean = false;
    nextButtonShow:boolean = true;
    dataList:any;
    checkDataStore:any = [];
    showCatTab:any;
    filtersStore:any;
    @ViewChild('pageTabs') pageTabs: TabsetComponent;    
    constructor(
        private toastr: ToastrService,
        public global: Global,
        private projectService: ProjectService,
        private authenticService:AuthenticationService
    ) { }

    ngOnChanges(){
    }

    ngOnInit() {  
        setTimeout(() => {
            if(this.usingFor != 'CampaignCreate'){
                this.showCatTab = false;
            } else{
                this.showCatTab = true
            }
        }, 100);
        this.addActionSent = this.addAction;
        this.isReassign = this.usingFor == 'reassign';
        if(this.isReassign)
            this.global.ClearChannel();
        
        this.getAttributeData()
        this.isAllSelected = false;
        if (this.usingFor == "CampaignCreate" || this.usingFor == "viewAudience") {
            this.removeExisting = true;
            this.existingSource = this.global.Channel.Source.filter(function (x) {
                return x.checked;
            });
            this.existingEvents = this.global.Channel.Events.filter(function (x) {
                return x.checked;
            });
            this.existingAttributes = this.global.Channel.Attributes.filter(function (x) {
                return x.checked;
            });
        };
        if(this.existingSource.length > 0){
            this.existingSource.forEach(element => {
                this.selectedSource.push(element.pluginName);
            });
        }
        if(this.leadReportForm == undefined){
        setTimeout(()=>{
            this.changeTabStatus();
            this.fetchCategory();
        },500)
        }
        
        
    }


    setCategoryData(item,event){
        this.filters = this.filtersStore;        
        this.global.categorySelectByCamapign = []
        this.dataList.forEach(element => {            
            if(element.checked == true){
                this.checkDataStore.push(element);
            }
            if(element.id == item.id){
                if(event.target.checked == true){
                    element.checked = true;
                    let checkToStore = this.checkDataStore.find(x=>x.id == item.id);
                    if(checkToStore == undefined){
                    this.checkDataStore.push(element)
                    }
                } else{
                    element.checked = false;
                    for(let i=0; i<this.checkDataStore.length; i++){
                        if(this.checkDataStore[i].id == item.id){
                            delete this.checkDataStore[i];
                        }
                    }
                }
                this.checkDataStore = this.checkDataStore.filter(p=>{
                    return p != null;
                });
                this.checkDataStore.forEach(element => {
                    let checkToPushStatus = this.global.categorySelectByCamapign.find(x=>x == element.linkRewrite);
                    if(checkToPushStatus == undefined){
                    this.global.categorySelectByCamapign.push(element.linkRewrite)
                    }
                });
            }
        });
        
        let filterStoreByCat = []
        let systemDefineAttr = []
        this.filters.forEach((elem,index,object)=>{
            if(elem.paramOwner == 0 && elem.pluginName != 'static'){
            if(elem.category){
                let catToArr = elem.category.split(',');
                catToArr = catToArr.filter(p=>{
                    return p != null && p != "";
                });
                if(this.global.categorySelectByCamapign.length > 0){
                    this.global.categorySelectByCamapign.forEach(selectedCats => {
                        let checkByCat = catToArr.find(x=>x == selectedCats);
                        if(checkByCat){
                            let checkToPush = filterStoreByCat.find(x=>x.paramKey == elem.paramKey)
                            if(checkToPush == undefined){
                            filterStoreByCat.push(elem);
                            }
                        }
                    });
            }
                
          }
        } 
        if(elem.paramOwner == 1 && elem.pluginName != 'static'){
            systemDefineAttr.push(elem)
        }
        if(elem.pluginName == 'static'){
            systemDefineAttr.push(elem);
        }
        })        
        if(filterStoreByCat.length > 0){
            this.filters = filterStoreByCat;
            systemDefineAttr.forEach(elem=>{
                this.filters.push(elem)
            })
        } 
        if(filterStoreByCat.length == 0){
            this.filters = []
            systemDefineAttr.forEach(elem=>{
                this.filters.push(elem)
            })
        }

        this.filters.sort((a, b) => a.paramName.localeCompare(b.paramName));   

        if(this.checkDataStore.length > 0){
            this.pageTabs.tabs[1].disabled = false;
            this.pageTabs.tabs[2].disabled = false;
            this.pageTabs.tabs[3].disabled = false;
        } else{
            this.pageTabs.tabs[1].disabled = true;
            this.pageTabs.tabs[2].disabled = true;
            this.pageTabs.tabs[3].disabled = true;
        }
    }

    fetchCategory() {        
        this.dataList = [];
        this.projectService.fetchCategory({}).then(data => {
            if (data) {
                const dataList = data;
                let id = this.authenticService.getUserId();
                if(this.authenticService.getUserRole() != 1){
                for(let i=0; i<dataList.length; i++){
                    if(dataList[i]){
                    let getUserList = dataList[i].userList.find(userid=>userid == id);
                    if(getUserList){
                        let checkToPush = this.dataList.find(x=>x.linkRewrite == dataList[i].linkRewrite);
                        if(checkToPush == undefined){
                            this.dataList.push(dataList[i])
                        }
                    }
                }
                }
            } 
            else{
                this.dataList = dataList
              }

                
                let checkForAudCat = this.dataList.find(x=>x.linkRewrite == 'audience');
                    if(checkForAudCat){
                        this.categoryName='audience';
                    } else{
                        this.categoryName=this.dataList[0].linkRewrite;
                    }
                    
                this.dataList.forEach(element => {
                    if(element.checked == true){
                        this.pageTabs.tabs[1].disabled = false;
                        this.pageTabs.tabs[2].disabled = false;
                        if(this.pageTabs.tabs.length > 3){
                        this.pageTabs.tabs[3].disabled = false;
                        }
                    }
                });
            }
        })
    }

    recordUpdateAdded(event)
    {
        if(event == 'recordSuccess'){
            this.sendRecordStatus.emit('recordSuccess');
        }
    }

    changeTabStatus(){        
        if(this.selectedSource.length > 0){
            this.pageTabs.tabs[1].disabled = false;
            this.pageTabs.tabs[2].disabled = false;
            if(this.pageTabs.tabs.length > 3){
            this.pageTabs.tabs[3].disabled = false;
            }
            this.getFilters();
        } else {
            if(this.pageTabs.tabs.length > 3){
            this.pageTabs.tabs[1].disabled = true;
            this.pageTabs.tabs[2].disabled = true;
            this.pageTabs.tabs[3].disabled = true;
            }
        }
    }

    nextTabShow()
    {
        this.callNext = true
        
        for(let i=0; i<this.pageTabs.tabs.length; i++){
            if(this.pageTabs.tabs[i].elementRef.nativeElement.classList.length > 1){
                for(let j=0; j<this.pageTabs.tabs[i].elementRef.nativeElement.classList.length; j++){
                    if(this.pageTabs.tabs[i].elementRef.nativeElement.classList[j] == "active" && i < this.pageTabs.tabs.length){
                        this.pageTabs.tabs[i+1].active = true;
                    }
                }
            }
        }
    }

    // showInactive(event)
    // {
    //     this.showInactiveForms = event.target.checked;
    // }

    filterChannel(Channel, id) {
        if (this.removeExisting) {
            if (id != null) {
                return (
                    Channel.filter(function (x) {
                        return x.id == id || x.pluginId == id;
                    }).length == 0
                );
            }
        }
        return true;
    }

    getAttributeData(){
        this.projectService.fetchAttributeList().then(data => {
            if(data){
                this.getFilters();
                this.getfetchUserSorce();
            }
        })
    }

	getFilters() {        
        let getDynamicAttr = this.isAllSelected == true ? 'getDynamic' : undefined;
        var data = JSON.parse(JSON.stringify(this.projectService.filterAttributeList()));                
        if (this.removeExisting) {
            data.forEach(element => {
                if (
                    this.global.Channel.Events.filter(function (x) {
                        return x.id == element.id && x.checked;
                    }).length > 0 || this.global.Channel.Attributes.filter(function (x) {
                        return x.id == element.id && x.checked;
                    }).length > 0
                ) {
                    element.checked = true;
                } else {
                    element.checked = false;
                }
            });
        }
        this.filters = data;     
        this.filtersStore = data;   

        if(this.filterByCampaign){
            
            let filterStoreByCat = []
            let systemDefineAttr = []
            this.filters.forEach((elem,index,object)=>{
                if(elem.paramOwner == 0 && elem.pluginName != 'static'){
                if(elem.category){
                    let catToArr = elem.category.split(',');
                    catToArr = catToArr.filter(p=>{
                        return p != null && p != "";
                    });
                    if(this.global.categorySelectByCamapign.length > 0){
                        this.global.categorySelectByCamapign.forEach(selectedCats => {
                            let checkByCat = catToArr.find(x=>x == selectedCats);
                            if(checkByCat){
                                let checkToPush = filterStoreByCat.find(x=>x.paramKey == elem.paramKey)
                                 if(checkToPush == undefined){
                                    filterStoreByCat.push(elem);
                                 }
                            }
                        });
                }
                    
              }
            } 
            if(elem.paramOwner == 1 && elem.pluginName != 'static'){
                systemDefineAttr.push(elem)
            }
            if(elem.pluginName == 'static'){
                systemDefineAttr.push(elem);
            }
            })
                
            if(filterStoreByCat.length > 0){
                this.filters = filterStoreByCat;
                systemDefineAttr.forEach(elem=>{
                    this.filters.push(elem)
                })
            } 
            if(filterStoreByCat.length == 0){
                this.filters = []
                systemDefineAttr.forEach(elem=>{
                    this.filters.push(elem)
                })
            }
    
            this.filters.sort((a, b) => a.paramName.localeCompare(b.paramName));       
        } 
        else{

        let filterStoreByCat = []
        let systemDefineAttr = []
        this.filters.forEach((elem,index,object)=>{
            if(elem.paramOwner == 0 && elem.pluginName != 'static'){
            if(elem.category){
                let catToArr = elem.category.split(',');
                catToArr = catToArr.filter(p=>{
                    return p != null && p != "";
                });
                if(this.category){
                let checkByCat = catToArr.find(x=>x == this.category)
                if(checkByCat){
                    let checkToPush = filterStoreByCat.find(x=>x.paramKey == elem.paramKey)
                    if(checkToPush == undefined){
                    filterStoreByCat.push(elem);
                    }
                }

            }
                
          }
        }
        if(elem.paramOwner == 1 && elem.pluginName != 'static'){
            systemDefineAttr.push(elem);
        }

        if(elem.pluginName == 'static'){
            systemDefineAttr.push(elem);
        }
        })

        if(filterStoreByCat.length > 0){
            this.filters = filterStoreByCat
            systemDefineAttr.forEach(elem=>{
                this.filters.push(elem);
            })
        }

        if(filterStoreByCat.length == 0){
            this.filters = []
            systemDefineAttr.forEach(elem=>{
                this.filters.push(elem)
            })
        }
        this.filters.sort((a, b) => a.paramName.localeCompare(b.paramName));
        }
        
        this.filtersStore.sort((a, b) => a.paramName.localeCompare(b.paramName));
                
	}

    getfetchUserSorce() {
        var data = JSON.parse(JSON.stringify(this.projectService.getSourceList()));
        let getSource = localStorage.getItem('sourceSelect');
        if(getSource == 'activeForms'){
            this.showAllList = true;
            this.showInactiveList = false;
        } else if(getSource == 'inactiveForms'){
            this.showInactiveList = true;
            this.showAllList = false;
        }
        if (this.removeExisting || this.isReassign) {
            data.forEach(element => {
                var x = this.global.Channel.Source.find( x => { return x.pluginId == element.pluginId && x.checked; })
                if ( x != undefined) {
                    element.checked = true;
                    if(x.assignMappingDTOs!=undefined)
                    element.assignMappingDTOs = x.assignMappingDTOs;
                } else {
                    element.checked = false;
                }
            });
        }
        this.fetchUser = data;
        this.fetchUser = this.fetchUser.filter(p=>{
            return p.assignMappingDTOs && p.assignMappingDTOs.length > 0
        })
        this.fetchUser.sort((a,b)=>{
            if(a.pluginName < b.pluginName){
                return -1;
            }
            if(a.pluginName > b.pluginName){
                return 1;
            }
        })
        this.fetchUser.forEach(elems=>{
            let checkAvail = this.selectedSource.find(x=>x == elems.pluginName);
            if(checkAvail){
                if(this.showAllList == true){
                    elems.showActiveForm = true;
                } else if(this.showInactiveList == true){
                    elems.showAllForm = true;
                } else{
                    this.showAllList = true
                    elems.showActiveForm = true;
                }
                elems.showList = true;             
            } else{
                elems.showList = false;
            }
            
        });

        if(this.leadReportForm){
            this.fetchUser.forEach(elem=>{
                elem.checked = false;
            })
        }
    }
    

    setFilterData(id, type,index?,item?){        
        if(type == 'source'){
            this.isSourceUpdate = true;
            this.selectedSource = [];
           // this.resetAttributes();
            for (let i = 0; i < this.fetchUser.length; i++) {
                if(this.fetchUser[i].pluginId == id){                    
                    this.fetchUser[i].checked =  !this.fetchUser[i].checked;
                    if(this.fetchUser[i].checked){                        
                        this.fetchUser[i].assignMappingDTOs.forEach(element => {
                            if(this.isAllSelectedInactiveForms == true || this.showInactiveList == true){
                                element.checked = true;
                                this.fetchUser[i].showActiveForm = false;
                                this.fetchUser[i].showAllForm = true;
                                this.showAllList = false;
                            } else {
                                this.isAllSelected = this.allSelected();
                                if(element.status == 'ACTIVE'){
                                    this.showAllList = true;
                                    element.checked = true;
                                    this.fetchUser[i].showActiveForm = true;
                                    this.fetchUser[i].showAllForm = false;
                                }
                            }
                            
                        })
                    }
                } else {
                    // if(this.isReassign)
                    // this.fetchUser[i].checked = false;
                }
                if(this.fetchUser[i].checked){
                    this.selectedSource.push(this.fetchUser[i].pluginName);
                } else {
                    this.fetchUser[i].assignMappingDTOs.forEach(element => {
                        element.checked = false;
                    });
                    this.global.Channel.Source = this.global.Channel.Source.filter((p)=>{
                        return p.checked == true
                    })
                }
            }
            if(this.leadReportForm == undefined){
           // this.changeTabStatus();
            }
            if(this.notCloseOnSelect != undefined){
                this.GoToNext();
            }
        } else if(type == 'sourceForm'){
            let checkSourceForm = []
            this.fetchUser.forEach(elem=>{
                if(elem.pluginId == item.pluginId)
                {
                    elem.assignMappingDTOs.forEach(element => {
                        if(element.id == id){
                            element.checked = !element.checked;
                        }
                        if(element.checked){
                            checkSourceForm.push(element);
                            elem.checked = true;
                        }
                        
                    })
                }
            })
            if(checkSourceForm.length > 0){
               // this.fetchUser[index].checked = true;
                this.selectedSource.push(this.fetchUser[index].pluginName);
                //this.callNext = this.selectedSource
            }
            else{
                this.fetchUser.forEach(elem=>{
                    if(elem.pluginId == item.pluginId)
                    {
                        elem.checked = false;
                        for(let i=0; i<this.selectedSource.length; i++){ 
                            if(this.selectedSource[i] == elem.pluginName){
                                delete this.selectedSource[i];
                            }
                        }
                    }
                });
                                 
                this.selectedSource = this.selectedSource.filter(p=>{
                    return p != null;
                })                
            }
            if(this.leadReportForm == undefined){
            //this.changeTabStatus();
            }
            if(this.notCloseOnSelect != undefined){
            this.GoToNext();
            }
        } else {
            for (let i = 0; i < this.filters.length; i++) {
                if(this.filters[i].id == id){
                this.filters[i].checked =  !this.filters[i].checked;
                return false;
                } 
            }
        }
    }

    GoToNext() {
        if(this.usingFor != 'viewAudience'){
            this.filterClear.emit('clear');
        }
        this.global.Channel.Segments = [];
        if (this.usingFor == "CampaignCreate") {
        } else {
            this.global.ClearChannel();
        }
        this.global.Channel.Events = this.filters.filter(function (item: any) {
            return item.paramCategory == "0" && item.checked == true;
        });
    
        this.global.Channel.Attributes = this.filters.filter(function(item: any) {
            return item.paramCategory == "1" && item.checked == true;
        });
        this.global.Channel.Source = this.fetchUser.filter(function (item: any) {
            return item.checked == true;
        });
        this.callNext = this.global.Channel.Source;
        if(this.paramList != undefined)
        {
        this.global.Channel.Attributes.forEach(element => {
           let paramVal = this.paramList.find(x=>x.id == element.id);
           if(paramVal)
           {
            element.paramVal = paramVal.paramValue;
           }
        });
        }
        
        this.global.Channel.Source.forEach(element => {
            element.selectedForms = [];
            element.selectedForms =  element.assignMappingDTOs.filter(x => x.checked);
            if(element.selectedForms!= undefined)
            element.selectedForms.forEach(form => {
                this.global.Channel.sourceFormIds.push(form.id);
            });
        });
        var event = this.isSourceUpdate ? 'isSourceUpdate' : ''
        this.filterClose(event);
        if (this.global.AnyChannelSelected()) {
            var isFormSelected = true;
            if(this.isReassign){
                this.global.Channel.Source.forEach(element => {
                    if(element.selectedForms.length == 0){
                        isFormSelected = false;
                        this.toastr.error('Please select source form.');
                    } else {
                        isFormSelected = true;
                    }
                });
            } 
            if(isFormSelected && this.notCloseOnSelect == undefined )
            this.modalRef.hide();
        } else {
            this.toastr.error('Please select any filter.');
        }
    }

    resetAttributes(){
        this.global.Channel.Events = [];
        this.global.Channel.Attributes = [];
    }

    showAll(event)
    {
        this.fetchUser.forEach(elem=>{
            elem.checked = false;
            elem.assignMappingDTOs.forEach(element => {
                element.checked = false;
            });
            elem.showList = true
            if(event == 'allForms'){
                elem.showAllForm = true
                elem.showActiveForm = false;
            } else if(event == 'activeOnly'){
                elem.showActiveForm = true;
                elem.showAllForm = false;
                this.showAllList = true;
            }
            
            
        })  
        this.global.Channel.sourceFormIds = [];
        this.global.Channel.Source = []
        this.isSourceUpdate = true;
        this.selectedSource = [];
        this.isAllSelectedInactiveForms = false;
        this.isAllSelected = false;
    }

    selectAll(event?) {
        this.global.Channel.sourceFormIds = [];
        this.global.Channel.Source = []
        this.isSourceUpdate = true;
        this.selectedSource = [];
       if(event == 'activeForms'){
        this.isAllSelectedInactiveForms = false;
        localStorage.setItem('sourceSelect','activeForms');
       }
       if(event == 'inactiveForms'){
        this.isAllSelected = false;
        localStorage.setItem('sourceSelect','inactiveForms');
       }

        this.fetchUser.forEach(element => {
            if(event == 'activeForms'){
                element.checked = this.isAllSelected;
                element.showActiveForm = true;
                element.showAllForm = false;
            }
            
            if(this.isAllSelected){
                this.selectedSource.push(element.pluginName);
            }
            else{
                this.selectedSource = [];
            }

            if(event == 'inactiveForms'){
                element.checked = this.isAllSelectedInactiveForms;
                if(this.isAllSelectedInactiveForms){
                    this.selectedSource.push(element.pluginName);
                    element.showActiveForm = false;
                    element.showAllForm = true;
                }
                else{
                    this.selectedSource = [];
                    //this.isAllSelected = false;
                }
            }

            // if(event == 'deselectAll'){
            //     this.selectedSource = [];
            //     element.checked = false;
            // }
        });

        for (let i = 0; i < this.fetchUser.length; i++) {
            if(this.fetchUser[i].checked){
                this.global.Channel.Source.push(this.fetchUser[i])
                this.fetchUser[i].assignMappingDTOs.forEach(element => {
                    if(event == 'inactiveForms' && this.isAllSelectedInactiveForms == true){
                        element.checked = true;
                        this.global.Channel.sourceFormIds.push(element.id)
                        this.selectedSource.push(this.fetchUser[i].pluginName);
                    } else if(event == 'activeForms'){
                        if(element.status=="ACTIVE"){
                            element.checked = true;
                            this.global.Channel.sourceFormIds.push(element.id)
                            this.selectedSource.push(this.fetchUser[i].pluginName);
                        } else{
                            element.checked = false;
                        }
                    }
                    
                })
                
            }
            else
            {
                this.fetchUser[i].assignMappingDTOs.forEach(element => {
                    element.checked = false;
                });
                this.selectedSource = [];
            }
        };
            
        if(this.leadReportForm == undefined){
        //this.changeTabStatus();
        }
    };

    openFormList(item){
        if(this.showInactiveList == true || this.isAllSelectedInactiveForms == true){
            item.showAllForm = true;
            item.showActiveForm = false;
        } else if(this.showAllList = true){
            item.showAllForm = false;
            item.showActiveForm = true;
        }
    }

    clearFilterSelect(){
        this.fetchUser.forEach(element => {
            element.checked = false;
            element.assignMappingDTOs.forEach(elements => {
                elements.checked = false;
            });
        });

        this.global.Channel.sourceFormIds = [];
        this.global.Channel.Source = []
        this.isSourceUpdate = true;
        this.selectedSource = [];
        this.isAllSelectedInactiveForms = false
        this.isAllSelected = false;
        this.showPropsStatus = false
        this.showAllList = false; 
        this.showInactiveList = false
        localStorage.removeItem('sourceSelect');
    }

    allSelected(){
        return this.fetchUser.filter(x => !x.checked).length == 0;
    }

    refreshAttributes(){
        this.projectService.setAttributeData(undefined);
        this.getAttributeData();
        this.dataList.forEach(elem=>{
             elem.checked = false;
        })
        // if(this.existingSource.length == 0){
        //     this.pageTabs.tabs[0].active = true;
        //     this.pageTabs.tabs[1].disabled = true;
        //     this.pageTabs.tabs[2].disabled = true;
        // }
    }
}
