export class AppConstants {
    public static get PROJECT(): string { return 'Cronberry'; }
    public static get REPLACE_USERID(): string { return ':userId'; }
    public static get REPLACE_PAGE(): string { return ':page'; }
    public static get REPLACE_SIZE(): string { return ':size'; }
    public static get REPLACE_TYPE(): string { return ':type'; }
    public static get ADMIN_ROLE_TYPE(): number { return 1; }
    public static get MARKETER_ROLE_TYPE(): number { return 2; }
    public static get TELECALLER_ROLE_TYPE(): number { return 2; }
    public static get MANAGER_ROLE_TYPE(): number { return 4; }
    public static get ADMIN_ROLEID(): number { return 1; }
    public static get BLANK_REQUEST(): any { return {}; }
    public static get STATUS_DELETED(): string { return 'DELETED' }
    public static get FORM(): string { return 'form' }
    public static get FILE(): string { return 'file' }
    public static get FILETYPE_FORM(): string { return 'form' }
    public static get FILETYPE_FILE(): string { return 'file' }
    public static get FILETYPE_IMAGE(): string { return 'image' }
    public static get FILETYPE_GALLERY(): string { return 'GALLERY' }
    public static get DATE_FORMAT_FOR_DATEPICKER(): string { return "yyyy-MM-dd" }
    public static get COPIED_CLIPBOARD(): string { return 'Copied to clipboard' }
    public static get SOMETHING_WENT_WRONG(): string { return 'Something went wrong' }
    public static get STRING_BLANK(): string { return '' }
    public static get PUSH_NOTIFICATION(): string { return 'Push Notification' }
    public static get SMS_CAMPAIGN(): string { return 'SMS Campaign' }
    public static get EMAIL_CAMPAIGN(): string { return 'Email Campaign' }
    public static get WHATSAPP_CAMPAIGN(): string { return 'Whatsapp Campaign' }
    public static get INAPP_NOTIFICATION(): string { return 'In-App Notification' }
    public static get CAMPAIGN_TYPE_PUSH(): string { return 'Push' }
    public static get CAMPAIGN_TYPE_SMS(): string { return 'mobile' }
    public static get CAMPAIGN_TYPE_EMAIL(): string { return 'Email' }
    public static get CAMPAIGN_TYPE_WHATSAPP(): string { return 'mobile' }
    public static get CAMPAIGN_TYPE_INAPP(): string { return 'In-App' }
    public static get STORAGE_CATALOGUE_KEY(): string { return 'cronwertycatalogue2gew' }
    public static get YES(): string { return 'YES' }
    public static get NO(): string { return 'NO' }
    public static get FOLLOWUP_TIMING(): number { return 15 }
    public static get FOLLOWUP_CHECK_TIMING(): number { return 10 }
    public static get CRONBERRY_COM(): string { return 'cronberry.com' }
    public static get LANDING_PAGE_ADMIN(): string { return 'admin411fq5w66' }
    public static get GOOGLE_RECAPTCHA_API_URL(): string { return 'https://www.google.com/recaptcha/api.js' } //https://www.google.com/recaptcha/api.js
}