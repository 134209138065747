import { Pipe, PipeTransform } from '@angular/core';
import { SubUserObj } from '../../core/modal/user';

@Pipe({name: 'userFromId'})

export class UserFromIdPipe implements PipeTransform {
  transform(items: string, lists: SubUserObj[]): string {
      if(lists.filter(x => x.id == items).length > 0){
        if(lists.filter(x => x.id == items)[0].userProfileName != undefined)
        return lists.filter(x => x.id == items)[0].userProfileName;
        else
        return lists.filter(x => x.id == items)[0].userName;
      }
      return '';
   
  }
}