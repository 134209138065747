import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'shortTime'})
export class shortTimePipe implements PipeTransform {
  minutsCounter:any;
  transform(time: number, type: string): string {
    
    if(time>0){  
    let minutes = time;
     if(type == 'second'){
        let minutes: number = Math.floor(time / 60);
      }
     if(minutes < 60 ){
        // return minutes + '.' + '0 Min';
        return minutes + ' Min';

    } else {
        const hours: number = Math.floor(minutes / 60);
        this.minutsCounter = minutes - hours * 60;
        if(this.minutsCounter < 10)
        {
          this.minutsCounter = '0' + this.minutsCounter;
        }
        return hours + '.' + this.minutsCounter + ' Hours';
    }
}
  }
}

@Pipe({name: 'telleCallerStatus'})
export class TelleCallerStatusPipe implements PipeTransform {
  transform(time: string, intime:string, outtime:string, outstartDate:string): string {
    
    

    var outtimeseconds = 0;
    if(outtime != undefined ){
       outtimeseconds = ((new Date()).valueOf() - (new Date(outtime)).valueOf())/1000;
       if(outtimeseconds > 0){
        return "Away";
       }
    }

    

    var intimeSeconds = ((new Date()).valueOf() - (new Date(intime)).valueOf())/1000;
    var seconds = ((new Date()).valueOf() - (new Date(time)).valueOf())/1000;
    
    var breakTimeStarting = 0;
     if(outstartDate != undefined){
      breakTimeStarting = ((new Date()).valueOf() - (new Date(outstartDate)).valueOf())/1000;
    }


    if(breakTimeStarting > 0 && breakTimeStarting <1800 && seconds>0 && (new Date(time) < new Date(outstartDate)) ){
      return "On break"
    }

    if( (seconds < 600 || intimeSeconds < 600) ) /* less than 5 min */{
        return "Active";
    }else if(seconds > 600 && seconds < 1800)/* greathan than 5 min  and less than 30 min*/{
        return "Idle";
    }else{
        return "Away";
    }
  }
}