import { Component, OnInit, TemplateRef, ViewChild} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { ToastrService } from "ngx-toastr";
import { Global } from "../../../../../app/shared/models/global.model";
import { UrlShortnerObj } from "../../../../core/modal/project";
import { UrlShortnerService } from "../../../../core/services/urlShortner.service";
import { noWhitespaceValidator } from "../../../../shared/models/no-whitespace-validator";
import { AuthenticationService } from "../../../../core/services/authentication.service";
import { Location } from "@angular/common";

@Component({
    selector: 'url-shortner-app',
    templateUrl: './url-shortner.component.html',
    styleUrls: ['./url-shortner.component.scss']
})

export class UrlShortnerComponent implements OnInit {
    modalRef: BsModalRef;
    @ViewChild('addUrlShortnerModal') addUrlShortnerModal: TemplateRef<any>;
    @ViewChild('confirmModal') confirmModal: TemplateRef<any>;
    siteForm: FormGroup;
    dataList: UrlShortnerObj[] = [];
    isShrotUrlCreated: boolean = false;
    selectedUrlShortnerId: number;
    sortUrl: string = "";
    pageNo: number = 1;
    dataSize: number = 20;
    public totalDataSize: number;
    isSubscribe: boolean = true;
    constructor(
        private modalService: BsModalService,
        private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private urlShortnerService: UrlShortnerService,
        private global: Global,
        private authenticationService: AuthenticationService,
        private location:Location
    ) {
        this.isSubscribe = this.authenticationService.isPageSubscribe('Url Shortner');
    }

    ngOnInit() {
        if(this.isSubscribe)
        this.listShortens();
    }

    back()
    {
        this.location.back();
    }

    listShortens(){
        this.urlShortnerService.listShortens({},this.dataSize, this.pageNo-1).subscribe(data => {
            if(data.status){
                this.dataList = data.data.shortnerList;
                this.totalDataSize = data.data.size;
            } else {
                this.toastr.error(data.error_msgs,'Error');
            }
        })
    }

    public dataForPaging(event) {
        this.pageNo = event;
        this.listShortens();
    }

    addUrlShortner(data? : UrlShortnerObj) {
        this.prepareForm();
        this.isShrotUrlCreated = false;
        this.sortUrl = '';
        this.modalRef = this.modalService.show(this.addUrlShortnerModal, { class: "modal-addUrlShortner", backdrop: 'static' });
    }

    prepareForm(){
        this.siteForm = this.formBuilder.group({
            actualLink : [ "", Validators.compose([Validators.required, Validators.maxLength(150), Validators.pattern(/^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z0-9]{2,5}[\.]{0,1}/), noWhitespaceValidator] ) ]
        });
    }
    showDeleteModal(id){
        this.selectedUrlShortnerId = id;
        this.modalRef = this.modalService.show(this.confirmModal, {
            backdrop: 'static'
        });
    }

    deleteItem(){
        this.urlShortnerService.deleteUrlShortner({id:this.selectedUrlShortnerId,status:'DELETED'}).subscribe(data => {
            this.modalRef.hide();
            if(data.status){
                this.toastr.success(data.data,'Success');
                this.listShortens();
            } else {
                this.toastr.error(data.error_msgs,'Error');
            }
        });
    }

    saveForm(){
        if (this.siteForm.invalid ) {
            this.toastr.error('Please fill all mandatory fields');
            return;
        };
        var formData = this.siteForm.value;
        this.urlShortnerService.createUrlShortner(formData).subscribe(data => {
            if(data.status){
                this.toastr.success('Shortner URL created.','Success');
                this.sortUrl = data.data;
               this.isShrotUrlCreated = true;
                this.listShortens();
            } else {
                this.toastr.error(data.error_msgs,'Error');
            }
        })
    }

    copyText(text){
        this.global.copyText(text);
        this.toastr.success('Copied to clipboard');
    }

}
