import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../../core/guards/auth.guard";
import { AuthLayoutComponent } from "../admin/components/auth-layout/auth-layout.component";
import { CampaignManagerComponent } from "./manager/campaign-manager.component";
import { CreateJourneyComponent } from "./create-journey/create-journey.component";

const routes: Routes = [
    {path:'admin/campaign',component:AuthLayoutComponent, canActivate:[AuthGuard], children:[
        {path:'create-campaign', component: CreateJourneyComponent},
        {path:'create-campaign/:name/:id', component: CreateJourneyComponent},
        {path:'campaign-manager', component: CampaignManagerComponent},
    ]}
]
@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})

export class CampaignJourneyRouting {
    
}