<footer id="footer">
        <div class="footer-top">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-lg-4 logo">
                            <a href="javascript:void(0);">
                                <img src="assets/images/cronberry-white.png" alt="Cronberry - Best automation Software" title="Cronberry - Best automation Software" />
                            </a>
                            <p>
                                We believe communication is the most effective way to engage the audience. Therefore, we’ve built an efficient tool helping the businesses boost their sales and increase conversion rates on the go.
                            </p>
                            <br />
                    </div>
                    <div class="col-xs-12 col-sm-12 col-lg-4 links">
                        <div class="footer-content inner">
                            <div class="row">
                                <div class="col-sm-6 col-6">
                                    <h4>Quick Links</h4>
                                    <ul class="bullets">
                                        <li><a target="_blank" href="https://www.youtube.com/channel/UCnBjzeM0Fbv_k9KBrnNJ_6A">Demo Video </a></li>
                                        <li><a target="_blank" href="https://medium.com/@gaurshubham238/why-you-need-push-notification-services-for-your-business-495b92da731d" >Blog/News</a></li>
                                        <li><a target="_blank" [routerLink]="['/case-study']">Case Study </a></li>
                                    </ul>
                                </div>
                                <div class="col-sm-6 col-6">
                                    <h4>Product</h4>
                                    <ul class="bullets">
                                        <li><a [routerLink]="['/pricing']">Pricing </a></li>
                                        <li><a target="_blank" href="https://analyser.cronberry.com/analyse/subject">Email subject analyser</a></li>
                                        <li><a target="_blank" href="https://analyser.cronberry.com/analyse/headline">Heading Analyser</a></li>
                                    </ul>
                                </div>
                                <div class="col-sm-6 col-6">
                                    <h4>Support</h4>
                                    <ul class="bullets">
                                        <li><a target="_blank" [routerLink]="['/integration']">API Document </a></li>
                                        <li><a target="_blank" href="{{global.StaticUrl}}integration_guide/FAQ.pdf">FAQ</a></li>
                                        <li><a [routerLink]="['/contact-us']">Contact</a></li>
                                        <li><a href="https://blog.cronberry.com/" target="_blank">Blog</a></li>
                                    </ul>
                                </div>
                                <div class="col-sm-6 col-6">
                                        <h4>Legal</h4>
                                        <ul class="bullets">
                                            <li><a target="_blank" href="{{global.StaticUrl}}integration_guide/termsandconditions.pdf">Terms & Conditions </a></li>
                                            
                                        </ul>
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-lg-4">
                            <div class="footer-content">
                                <div class="row">
                                    <div class=" col-md-12 col-12">
                                        <div class="footer-content contact-info">
                                            <h4>
                                                Contact Us
                                            </h4>
                                            <ul class="list-unstyled">
                                                <li>
                                                        <img src="assets/images/map-marker-alt.svg" alt="Map Location Marker" title="Map Location Marker" />
                                                    T-1 & 2, 4th Floor, Tirthraj Apartment, Jacob Road, Civil Lines, Jaipur, Rajasthan - 302006 
                                                </li>
                                                <!-- <li>
                                                  <a href="tel:+91 9024466792">
                                                        <img src="assets/images/phone.svg" alt="Phone" title="Phone" />+91 9024466792
                                                </a>
                                                </li> -->
                                                <li>
                                                    <a href="mailto:sales@cronberry.com">
                                                        <img src="assets/images/envelope.svg" alt="Cronberry - Email" title="Cronberry - Email" />sales@cronberry.com</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </div>
        <div class="footer-bottom">
            <p>Copyright© {{currentYear}} Cronberry. All right reserved.</p>
        </div>
    </footer> 