<div class="sign">
    <div class="container-sign">
      <div class="sign-left p-l-50 p-r-50 p-t-72 p-b-50">
        <div class="logo">
          <a [routerLink]="['']"><img  src="assets/images/logo.png"/></a>
        </div>
        
        <div class="sign-left-detail">
          <h3>Cronberry</h3>
          <p>is the most affordable and simplest tool to engage users.</p>
        </div>
      </div>
  
      <div class="sign-right">
        <div class="sign-right-logo text-center">
          <img src="assets/images/logo.png"/>
        </div>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  