<main>
    <section class="inner-page">
        <div class="inner-page-header">
            <div class="row">
                <div class="col-10">
                    <h4>Add Audience</h4>
                </div>
                <div class="col-2 text-right">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                        <span aria-hidden="true"><img class="img" src="assets/images/times-circle_grey.svg"></span>
                    </button>
                </div>
            </div>
        </div>
    </section>

    <section class="inner-page">
    
        <div class="inner-page-body configuration project-integration-tab">
            <div class="profile-box">
                <h2 class="name"> <input class="form-control" [(ngModel)]="audienceParem.name" placeholder="Name" width="w-50" autocomplete="off" > </h2>
                <ul class="row">
                    <li class="col-md-6">
                        <div class="input-group">
                            <input class="form-control" [(ngModel)]="audienceParem.mobile" placeholder="Phone" width="w-50" maxlength="18" autocomplete="off">
                            <span class="input-group-text"><img src="assets/images/p-phone.svg" alt="Phone" title="Phone" /></span>
                        </div>
                    </li>
                    <li class="col-md-6">
                        <div class="input-group">
                            <input class="form-control" [(ngModel)]="audienceParem.email" placeholder="Email" width="w-50">
                            <span class="input-group-text"><img src="assets/images/p-envelope.svg" alt="Email" title="Email" autocomplete="off" /></span>
                        </div>
                    </li>

                    <li class="col-md-6 m_t_5" *ngIf="categoryFromParent">
                        <div class="input-group">
                            <select class="form-control" [(ngModel)]="categorySelector" (change)="updateCategory()">
                                <option *ngFor="let option of dataList" [value]="option.linkRewrite">
                                    {{option.name}}
                                </option>
                            </select>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="table-reports" *ngIf="attributesList.length > 0 || audienceParem.paramList.length > 0">
                <div class="table-body form-row">
                    <div class="table-responsive table-height-scroll col-md-6" #scrollblockLeft>
                        <table class="table table-bordered">
                            <tbody>
                                <tr>
                                    <th>Parameter</th>
                                    <th>Value</th>
                                </tr>
                                <ng-container *ngFor="let item of audienceParem.paramList; let i = index">
                                    <tr *ngIf="i % 2 != 1">
                                        <td>{{ item.paramName }}</td>
                                        <td>
                                            <div class="input-group">
                                                <ng-container *ngIf="item.paramDefaultValueDTO == undefiend">
                                                    <input trim="blur" *ngIf="item.paramDatatype != 'Date' && item.paramDatatype != 'Mobile'" type="text" class="form-control" [ngClass]="{'invalid-control' : isSubmit && item.paramValue == '' && item.paramMandatory == 'ACTIVE'}" [(ngModel)]="item.paramValue">
                                                    <input trim="blur" *ngIf="item.paramDatatype == 'Mobile'" (keypress)="numSymbol($event)" type="text" class="form-control" [ngClass]="{'invalid-control' : isSubmit && item.paramValue == '' && item.paramMandatory == 'ACTIVE'}" [(ngModel)]="item.paramValue" maxlength="15">
                                                    <input *ngIf="item.paramDatatype == 'Date'" type="text" placement="top left" [(ngModel)]="item.paramValue" [ngClass]="{'invalid-control' : isSubmit && item.paramValue == '' && item.paramMandatory == 'ACTIVE'}"
                                                    class="form-control date-icon modal-space" bsDatepicker autocomplete="off" [bsConfig]="global.datePickerConfig" [minDate]="item.backDataEditable == 'INACTIVE' ? todayDate : undefined">
                                                    </ng-container>
                                                    <ng-container *ngIf="item.paramDefaultValueDTO">
                                                    <!-- <select class="form-control" [ngClass]="{'invalid-control' : isSubmit && item.paramValue == '' && item.paramMandatory == 'ACTIVE'}" [(ngModel)]="item.paramValue">
                                                        <option *ngFor="let option of item.paramDefaultValueDTO" [value]="option.paramValue">
                                                            {{option.paramValue}}
                                                        </option>
                                                    </select> -->
                                                    <ng-select [ngClass]="{'invalid-control' : isSubmit && item.paramValue == '' && item.paramMandatory == 'ACTIVE'}" *ngIf="item.paramDatatype != 'Date' && item.paramDatatype != 'Numeric'" [multiple]="true" class="form-control additional_detail_multi_select auto_height_select" [(ngModel)]="item.paramValue">
                                                        <ng-option *ngFor="let options of item.paramDefaultValueDTO" [value]="options.paramValue">
                                                            {{options.paramValue}}
                                                        </ng-option>
                                                    </ng-select>

                                                    <select [ngClass]="{'invalid-control' : isSubmit && item.paramValue == '' && item.paramMandatory == 'ACTIVE'}" *ngIf="item.paramDatatype == 'Numeric'" class="form-control" [(ngModel)]="item.paramValue">
                                                        <option *ngFor="let options of item.paramDefaultValueDTO" [value]="options.paramValue">
                                                            {{options.paramValue}}
                                                        </option>
                                                    </select>
                                                    </ng-container>
                                                <div class="input-group-append">
                                                    <button class="input-group-text" (click)="removeAudience(i, item)">x</button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-md-6">
                        <div class="table-responsive table-height-scroll" #scrollblockRight>
                            <table class="table table-bordered">
                                <tbody>
                                    <tr>
                                        <th>Parameter</th>
                                        <th>Value</th>
                                    </tr>
                                    <ng-container *ngFor="let item of audienceParem.paramList; let i = index">
                                        <tr *ngIf="i % 2 == 1">
                                            <td>{{ item.paramName }}</td>
                                            <td>
                                                <div class="input-group">
                                                    <ng-container *ngIf="item.paramDefaultValueDTO == undefiend">
                                                    <input trim="blur" *ngIf="item.paramDatatype != 'Date' && item.paramDatatype != 'Mobile'" type="text" class="form-control" [ngClass]="{'invalid-control' : isSubmit && item.paramValue == '' && item.paramMandatory == 'ACTIVE'}" [(ngModel)]="item.paramValue">
                                                    <input trim="blur" *ngIf="item.paramDatatype == 'Mobile'" (keypress)="numSymbol($event)" type="text" class="form-control" [ngClass]="{'invalid-control' : isSubmit && item.paramValue == '' && item.paramMandatory == 'ACTIVE'}" [(ngModel)]="item.paramValue" maxlength="15">
                                                    <!-- <input trim="blur" *ngIf="item.paramDatatype != 'Date'" type="text" class="form-control" [ngClass]="{'invalid-control' : isSubmit && item.paramValue == '' && item.paramMandatory == 'ACTIVE'}" [(ngModel)]="item.paramValue"> -->
                                                    <input *ngIf="item.paramDatatype == 'Date'" type="text" placement="top left" [(ngModel)]="item.paramValue" [ngClass]="{'invalid-control' : isSubmit && item.paramValue == '' && item.paramMandatory == 'ACTIVE'}"
                                                    class="form-control date-icon modal-space" bsDatepicker autocomplete="off" [bsConfig]="global.datePickerConfig" [minDate]="item.backDataEditable == 'INACTIVE' ? todayDate : undefined">
                                                    </ng-container>
                                                    <ng-container *ngIf="item.paramDefaultValueDTO">

                                                    <!-- <select class="form-control" [ngClass]="{'invalid-control' : isSubmit && item.paramValue == '' && item.paramMandatory == 'ACTIVE'}" [(ngModel)]="item.paramValue">
                                                        <option *ngFor="let option of item.paramDefaultValueDTO">
                                                            {{option.paramValue}}
                                                        </option>
                                                    </select> -->

                                                    <ng-select [ngClass]="{'invalid-control' : isSubmit && item.paramValue == '' && item.paramMandatory == 'ACTIVE'}" *ngIf="item.paramDatatype != 'Date' && item.paramDatatype != 'Numeric'" [multiple]="true" class="form-control additional_detail_multi_select auto_height_select" [(ngModel)]="item.paramValue">
                                                        <ng-option *ngFor="let options of item.paramDefaultValueDTO" [value]="options.paramValue">
                                                            {{options.paramValue}}
                                                        </ng-option>
                                                    </ng-select>

                                                    <select [ngClass]="{'invalid-control' : isSubmit && item.paramValue == '' && item.paramMandatory == 'ACTIVE'}" *ngIf="item.paramDatatype == 'Numeric'" class="form-control" [(ngModel)]="item.paramValue">
                                                        <option *ngFor="let options of item.paramDefaultValueDTO" [value]="options.paramValue">
                                                            {{options.paramValue}}
                                                        </option>
                                                    </select>

                                                    </ng-container>
                                                    <div class="input-group-append">
                                                        <button class="input-group-text" (click)="removeAudience(i,item)">x</button>
                                                    </div>
                                                </div>

                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="other-parem my-3" *ngIf="attributesList.length > 0">
                    <span (click)="isAttributesListShow = !isAttributesListShow; searchAttribute = '';"> Add Other Parameters  <img class="img" src="assets/images/caret-down-dark.svg"></span>
                    <div *ngIf="isAttributesListShow" class="otherParam_inner">
                        <div class="search_input_box"><input class="btn searchbtn margin-searchbtn" name="search" placeholder="Search" type="text" [(ngModel)]="searchAttribute" autocomplete="off"></div>
                    <ul>
                        <li class="input_search"></li>
                        <ng-container *ngFor="let item of attributesList | myFilter:searchAttribute; let i = index">
                            <ng-container *ngIf="item.paramVisible == 1">
                           <li (click)="appendParameter(item,i)"> {{item.paramName}} <p class="d-inline" *ngIf="(item.paramKey=='city' || item.paramKey=='state' || 
                            item.paramKey=='message' || item.paramKey=='country' || item.paramKey=='pincode' || item.paramKey=='city' || item.paramKey=='product_name' ||  item.paramKey=='address') ">(default)</p></li>
                            </ng-container>
                        </ng-container>
                    </ul>
                    </div>
                </div>
            </div>
            <div class="text-right"><button class="btn btn-primary" (click)="submitData()">Submit</button></div>
      </div>
  </section>
</main>
