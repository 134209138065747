import { Component, OnInit,Input, SimpleChange } from '@angular/core';

@Component({
    selector: 'app-spinner',
    templateUrl: 'spinner.component.html',
    styleUrls: ['spinner.component.scss']
})

export class SpinnerComponent implements OnInit {

    // @Input() loading:boolean = false;
    @Input() httploading:boolean = false;
    @Input() timer: number = 0;
    isLogin:boolean;

    ngOnInit() {
        this.isLogin = localStorage.getItem('userId') != undefined && localStorage.getItem('userId') != null;
    }

    ngOnChanges(changes:SimpleChange){
        
    }
}