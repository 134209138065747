import { Component, OnInit, TemplateRef, OnDestroy, Input, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService, TabsetComponent } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CampaignService } from '../../../../core/services/campaign.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ProjectService } from '../../../../core/services/project.service';
import { SourceObj } from '../../../../core/modal/project';
import { environment } from '../../../../../environments/environment';
import { CacheService } from '../../../../core/services/cache.service';
import { AndroidCampaignObj, EmailTemplateObj, InappTemplateObj, IosCampaignObj, JourneyTemplateObj, SmsTemplateObj, WebPushTemplateObj, WhatsappTemplateObj } from '../../../../core/modal/campaign';
import { CampaignJourneyService } from '../../../../modules/campaign-journey/campaign-journey.service';
import { UserService } from '../../../../core/services/user.service';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { AppConstants } from '../../../../constants/app.constants';
@Component({
  selector: 'app-manage-campaigns',
  templateUrl: './manage-campaigns.component.html',
  styleUrls: ['./manage-campaigns.component.scss']
})
export class ManageCampaignsComponent implements OnInit, OnDestroy {
  campaigns: any = [];
  androidCamplist:AndroidCampaignObj[]=[]
  iosCapmlist:IosCampaignObj[]=[]
  webCapmlist:WebPushTemplateObj[]=[]
  smsCapmlist:SmsTemplateObj[]=[]
  emailCapmlist:EmailTemplateObj[]=[]
  whatsappCapmlist:WhatsappTemplateObj[]=[]
  inappCapmlist:InappTemplateObj[]=[]
  journeyCapmlist:JourneyTemplateObj[]=[]
  campaignslist: any = [];
  campaign: any = [];
  sortedCollection: any[];
  modalRef: BsModalRef;
  id: any = 0;
  @Input() sample: any = [{}];
  total: number;
  items: any[];
  records: any = [];
  page: number = 1;
  pageSms:number = 1;
  pageEmail:number = 1;
  limit: number = 5;
  totalRecords: number = 0;
  paramList: any;
  fromDate: any = '';
  toDate: any = '';
  audienceAllData:any = '';
  reportSelected: any;
  reportIndex: number;
  sourceList: SourceObj[];
  isDeleteReportShow: boolean = true;
  campaignStatus: boolean = true;
  fetchUser:any;
  @ViewChild('audienceDetailPopup') audienceDetailPopup: TemplateRef<any>;
  @ViewChild('reportDeleteConfirm') reportDeleteConfirm: TemplateRef<any>;
  subUserList:any[] = [];
  journeyData:any;
  serviceUrl:any;
  campaignType:any;
  deleteUrl:any;
  campType:any;
  openTab:any;
  journeyTypeList:any;
  setJourneyData:any = new Array();
  showJourney:boolean=false;
  pushUrl:any = environment.pushServiceUrl;
  // iosUrl:any = environment.iosPushUrl;
  // webUrl:any = environment.webPushUrl;
  smsUrl:any = environment.smsPushUrl;
  emailUrl:any = environment.emailPushUrl
  whatsAppUrl:any = environment.whatsappApiUrl;
  inAppUrl:any = environment.webUrl;
  journeyUrl:any = environment.apiUrl;
  telecallerUsers:any;
  existingUsers:any;
  managerUsers:any;
  currentUser:any;
  @ViewChild('siteTab') siteTab : TabsetComponent;
  @ViewChild('pushTabs') pushTabs : TabsetComponent;
  constructor(private modalService: BsModalService,
    private toastr: ToastrService,
    private campaignService: CampaignService,
    private router: Router,
    private route:ActivatedRoute,
    private location:Location,
    private projectService:ProjectService,
    private cacheService:CacheService,
    private campaignJourneyService:CampaignJourneyService,
    private userService:UserService,
    private authenticationService:AuthenticationService
  ) {
    
  }

  ngOnDestroy() {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }

  getList()
  {

  }

  back()
  {
    this.location.back();
  }

  ngOnInit() {
    this.route.params.subscribe( params => {
      if(params.name != undefined && params.id != undefined){
        this.setJourneyData = []
          this.journeyData = params;
          this.showJourney = true;
          let journeyObj = {campName:this.journeyData.name,journeyUserId:decodeURIComponent(this.journeyData.id)};
          this.campaignJourneyService.fetchCampaignJourney(journeyObj).subscribe(data=>{
            this.journeyTypeList = data.data;
            this.journeyTypeList.campaignsList.forEach(element => {
              let index_find = this.setJourneyData.find(x=>x == element.campaignType)
              if(index_find == undefined){
                this.setJourneyData.push(element.campaignType)
              }
            });
            let callToApi;
            if(this.setJourneyData[0] == 'androidpush'){
              callToApi = 'android'
            } else if(this.setJourneyData[0] == 'iospush'){
              callToApi = 'ios'
            } else if(this.setJourneyData[0] == 'webpush'){
              callToApi = 'web'
            } else{
              callToApi = this.setJourneyData[0];
            }
            this.getCampaignsList(callToApi);
          })
      } 
    });

    setTimeout(() => {

      this.route.queryParams.subscribe(data => {
        if (data.type) {
          this.openTab = data.type;
          if (this.openTab == 'androidpush') {
            this.siteTab.tabs[0].active = true;
            this.pushTabs.tabs[0].active = true;
          } else if (this.openTab == 'iospush') {
            this.siteTab.tabs[0].active = true;
            this.pushTabs.tabs[1].active = true;
          } else if (this.openTab == 'webpush') {
            this.siteTab.tabs[0].active = true;
            this.pushTabs.tabs[2].active = true;
          } else if (this.openTab == 'sms') {
            this.siteTab.tabs[1].active = true;
          } else if (this.openTab == 'email') {
            this.siteTab.tabs[2].active = true;
          } else if (this.openTab == 'whatsapp') {
            this.siteTab.tabs[3].active = true;
          } else if (this.openTab == 'inapp') {
            this.siteTab.tabs[4].active = true;
          }
        } else {
          this.getCampaignsList(this.openTab);
        }
      })
    }, 500);

    
    this.page = 1;
    this.limit = 20;
    this.totalRecords = 0;
    if(this.showJourney == false){
      this.getCampaignsList('android');
    }
  }

  openModal(template: TemplateRef<any>, id?) {
    if(id != undefined){
      this.id = id;
    }
    this.modalRef = this.modalService.show(template, { backdrop: 'static' });
    this.getPreloadFilters(this.id);
  }


  async getUsers() {

    let userData = this.authenticationService.getUserData();
       let existingUsers = [];
       await this.userService.getUsersData('all').then(data => {
            if (data != undefined) {
                existingUsers = data;
                existingUsers.push({id:this.authenticationService.getUserData().userId, userName:this.authenticationService.getUserData().userName});
            }});
            if(userData.userRoleId == 1){
            this.existingUsers = existingUsers;            
          } else
            {                
              await this.projectService.getChildUserData({}).subscribe(data=>{
                this.existingUsers = [];
                this.existingUsers.push({id:this.authenticationService.getUserData().userId, userName:this.authenticationService.getUserData().userName});
                if(data.status){
                    for(let i=0; i<=data.data.userList.length;i++){
                        if(data.data.userList[i] == data.data.id){
                            delete data.data.userList[i];
                        }
                    }
                    data.data.userList = data.data.userList.filter(p=>{
                        return p!= null;
                    })
                    data.data.userList.forEach(id=>{
                        let chekInUser = existingUsers.find(x=>x.id == id);
                        if(chekInUser){
                            this.existingUsers.push(chekInUser);
                        }
                    }); 
                    this.existingUsers.forEach(element => {
                      if(element.id == this.audienceAllData.userId){
                        this.audienceAllData.userName = element.userName;                        
                      }
                    });                   
                }
            })   
        }
        if(this.existingUsers){
       this.existingUsers.forEach(element => {
        if(element.id == this.audienceAllData.userId){
          this.audienceAllData.userName = element.userName;          
        }
      });
    }
}

  

  viewAudienceDetal(id,url)
  {
    this.campaignService.getCampaignDetail({id:id},url).subscribe(data=>{
      if(data.status)
      {  
        this.getUsers();      
        this.audienceAllData = data.data;         
        this.audienceAllData.filtersJson = this.audienceAllData.filtersJson ? JSON.parse(this.audienceAllData.filtersJson) : null;
        this.audienceAllData.formName = [];        
        var list = this.projectService.filterAttributeList(['all']); 
       
        this.projectService.getUserSourceData().then(data => {          
          this.fetchUser = this.projectService.getSourceList();                
          this.fetchUser.forEach(element => {
            element.assignMappingDTOs.forEach(elements => {
              if(this.audienceAllData.filtersJson){
              if(this.audienceAllData.filtersJson.segment.length > 0 && this.audienceAllData.filtersJson.segment[0].pluginFormIds)
              {
                let checkPluginForm = this.audienceAllData.filtersJson.segment[0].pluginFormIds.find(x=>x == elements.id);
                if(checkPluginForm != undefined){
                  this.audienceAllData.formName.push(elements.formName) 
                }
              }
            }
            });
          });
      });
      
      if(this.audienceAllData.filtersJson){
      if(this.audienceAllData.filtersJson.segment.length > 0 && this.audienceAllData.filtersJson.segment[0].paramList){
        if(this.audienceAllData.filtersJson.segment[0].paramList.length > 0){
          this.audienceAllData.filter = []
          this.audienceAllData.filtersJson.segment[0].paramList.forEach(element => {
            if(element.paramKey.includes('params.') == true){
              element.paramKey = element.paramKey.split('params.')[1];
              let findParamName = list.find(x=>x.paramKey == element.paramKey);
              if(findParamName != undefined){
                this.audienceAllData.filter.push(findParamName.paramName +' ' + element.paramOprator + ' ' + element.paramValue );
              }
            }
            else{
              let findParamName = list.find(x=>x.paramKey == element.paramKey);
              if(findParamName != undefined){
                this.audienceAllData.filterType = this.audienceAllData.filterType + findParamName.paramName;
              }
            }
          });
        }
      }
    }

    if(this.audienceAllData.filtersJson){
        if(this.audienceAllData.filtersJson.paramList)
        {
        if(this.audienceAllData.filtersJson.segment[0].paramList.length > 0)
        {
        this.audienceAllData.paramName = []
        list.forEach(elems=>{
          let idMatch = this.audienceAllData.filtersJson.segment[0].paramList.find(x=>x.id == elems.id);
          if(idMatch != undefined)
          {
            
            this.audienceAllData.paramName.push(elems.paramName);            
            if(this.audienceAllData.filtersJson.segment[0].paramList[0].paramValue.includes("date(date_add(now()") == true)
            {
              let intValTime = this.audienceAllData.filtersJson.segment[0].paramList[0].paramValue.split(',')[1];
              let intval = intValTime.split('-')
              if(intval.length>0)
              {
               intval = intValTime.split('-')[1];
              }
              if(intval)
              intval = intval.substring(0,intval.length - 2)
              this.audienceAllData.intValTime = '+ ' + intval;
            }
            if(this.audienceAllData.filtersJson.segment[0].paramList[0].paramValue.includes("date('") == true)
            {
              let intValTime = this.audienceAllData.filtersJson.segment[0].paramList[0].paramValue.split("'")[1];
              this.audienceAllData.intValTime = intValTime;
            }
            if(this.audienceAllData.filtersJson.segment[0].paramList[0].paramValue.includes("interval") == true)
            {
              let intValTime = this.audienceAllData.filtersJson.segment[0].paramList[0].paramValue.split("interval");
              let hourTxt = intValTime[1].replace(/\s/g, "");
              
              hourTxt == '0hour-' || '-0day))' ? intValTime[1] = '':intValTime[1];
              if(intValTime.length > 2)
              {
              let mintText = intValTime[2].replace(/\s/g, "");
              mintText == '0minute' ? intValTime[2] = '' : intValTime[2];
              }
              intValTime[2] == '' ? intValTime[1] = intValTime[1].split('-')[0] : intValTime[1] ;
              let intVal
              if(intValTime.length > 2)
              {
                intVal = hourTxt == '0hour-' ? intValTime[2] : intValTime[1] + intValTime[2];
              }

              if(intValTime.length == 2)
              {
                
               intVal = hourTxt == '0hour-' ? intValTime[2] : intValTime[1];               
              }                            
              this.audienceAllData.intValTime = intVal != "" ? '+' + intVal : '';
              
            }
          }
        })
        }
        }
      }
        this.modalRef = this.modalService.show(this.audienceDetailPopup, {class: 'modal-lg', backdrop: 'static' });
        
      }
      else
      {
        this.toastr.error(data.error_msgs);
      }
    },error=>{
      console.log(error);
    })
  }

  getCampaignsList(url?,reload?) {   
    let pushType; 
    if(url == 'android' || url == 'androidpush'){
      this.serviceUrl = this.pushUrl;
      pushType = 'androidpush'
      this.campaignType = 'android'
    } else if(url == 'ios' || url == 'iospush'){
      pushType = 'iospush'
      this.serviceUrl = this.pushUrl;
      this.campaignType = 'ios'
    } else if(url == 'web' || url == 'webpush'){
      pushType = 'webpush'
      this.serviceUrl = this.pushUrl;
      this.campaignType = 'web'
    } else if(url == 'sms'){
      this.serviceUrl = this.smsUrl;
      this.campaignType = url
    } else if(url == 'email'){
      this.serviceUrl = this.emailUrl;
      this.campaignType = url
    } else if(url == 'whatsapp'){
      this.serviceUrl = this.whatsAppUrl;
      this.campaignType = url
    } else if(url == 'inapp'){
      this.serviceUrl = this.inAppUrl;
      this.campaignType = url
    } else if(url == 'journey'){
      this.serviceUrl = this.journeyUrl;
      this.campaignType = url
    }
    let postData;
    if(this.journeyData != undefined){
      postData = {
        journeyName :this.journeyData.name,
        journeyUserId: decodeURIComponent(this.journeyData.id),
        page: (this.page-1),
        limit: this.limit,
        campaignType:pushType
      }
    } else {
      postData = {
        page: (this.page-1),
        limit: this.limit,
        campaignType:pushType
      }
    }
    if(url == 'android' || url == 'androidpush'){
      if(reload == 'reload'){
        this.androidCamplist = [] ;
      }
      if(this.androidCamplist == undefined || this.androidCamplist.length == 0)
      {
      let androidData = []
    this.cacheService.getCampaignsList(postData,this.campaignType,this.serviceUrl,reload);
    this.cacheService.getAndroidCampList().subscribe(data=>{
      if(data){
      if(data.data){
      data.data.forEach(element => {
        element.repeatStatus = element.scheduleType == 'repeat' && element.campaignStatus == 'Under Process';
        androidData.push(element)
      });
      }
      this.androidCamplist = androidData;
      this.totalRecords = data.totalElements;
      }
    })
     }
    } else if(url == 'ios' || url == 'iospush'){
      if(reload == 'reload'){
        this.iosCapmlist = [] ;
      }
      if(this.iosCapmlist == undefined || this.iosCapmlist.length == 0)
      {
      let iosData = []
      this.cacheService.getCampaignsList(postData,this.campaignType,this.serviceUrl,reload);
      this.cacheService.getIosCampList().subscribe(data=>{
        if(data){
        if(data.data){
        data.data.forEach(element => {
          element.repeatStatus = element.scheduleType == 'repeat' && element.campaignStatus == 'Under Process';
          iosData.push(element)
        }); 
        }     
        this.iosCapmlist = iosData;
        this.totalRecords = data.totalElements;
      
      }
      })
      }
    } else if(url == 'web' || url == 'webpush'){
      if(reload == 'reload'){
        this.webCapmlist = [] ;
      }
      if(this.webCapmlist == undefined || this.webCapmlist.length == 0)
      {
      let webData = []
      this.cacheService.getCampaignsList(postData,this.campaignType,this.serviceUrl,reload);
      this.cacheService.getWebCampList().subscribe(data=>{
        if(data){       
        if(data.data){
        data.data.forEach(element => {
          element.repeatStatus = element.scheduleType == 'repeat' && element.campaignStatus == 'Under Process';
          webData.push(element)
        });   
        }   
        this.webCapmlist = webData;
        this.totalRecords = data.totalElements;
      
      }
      })
      }
    } else if(url == 'sms'){      
      if(reload == 'reload'){
        this.smsCapmlist = [] ;
      }
      if(this.smsCapmlist == undefined || this.smsCapmlist.length == 0){
      let smsData = []
      this.cacheService.getCampaignsList(postData,this.campaignType,this.serviceUrl,reload);
      this.cacheService.getSmsCampList().subscribe(data=>{
        if(data){
        if(data.data){
        data.data.forEach(element => {
          element.repeatStatus = element.scheduleType == 'repeat' && element.campaignStatus == 'Under Process';
          smsData.push(element)
        });  
        }    
        this.smsCapmlist = smsData;
        this.totalRecords = data.totalElements;
        
        }
      })
      }
    } else if(url == 'email'){
      if(reload == 'reload'){
        this.emailCapmlist = [] ;
      }
      if(this.emailCapmlist == undefined || this.emailCapmlist.length == 0)
      {
      let emailData = []
      this.cacheService.getCampaignsList(postData,this.campaignType,this.serviceUrl,reload);
      this.cacheService.getEmailCampList().subscribe(data=>{
        if(data){
        if(data.data){
        data.data.forEach(element => {
          element.repeatStatus = element.scheduleType == 'repeat' && element.campaignStatus == 'Under Process';
          emailData.push(element)
        });    
        }  
        this.emailCapmlist = emailData;
        this.totalRecords = data.totalElements;
        
        }
      })
      }
    } else if(url == 'whatsapp'){
      if(reload == 'reload'){
        this.whatsappCapmlist = [] ;
      }
      if(this.whatsappCapmlist == undefined || this.whatsappCapmlist.length == 0)
      {
      let whatsappData = []
      this.cacheService.getCampaignsList(postData,this.campaignType,this.serviceUrl,reload);
      this.cacheService.getWhatsappCampList().subscribe(data=>{
        if(data){
        if(data.data){
        data.data.forEach(element => {
         // element.repeatStatus = element.scheduleType == 'repeat' && element.campaignStatus == 'Under Process';
          whatsappData.push(element)
        });    
        }  
        this.whatsappCapmlist = whatsappData;
        this.totalRecords = data.totalElements;
      }
      })
      }
    } 
    else if(url == 'inapp'){
      if(this.inappCapmlist == undefined || this.inappCapmlist.length == 0)
      {
      let inappData = []
      this.cacheService.getCampaignsList(postData,this.campaignType,this.serviceUrl,reload);
      this.cacheService.getInappCampList().subscribe(data=>{
        if(data){
        if(data.data){
        data.data.forEach(element => {
          element.repeatStatus = element.scheduleType == 'repeat' && element.campaignStatus == 'Under Process';
          inappData.push(element)
        });    
        }  
        this.inappCapmlist = inappData;
        this.totalRecords = data.totalElements;
      }
      })
      }
    }
  }

  openCampaign() {
    this.modalRef.hide();
    this.router.navigate(['admin/create-campaign', { id: this.id }]);
  }

  getPreloadFilters(id) {
    this.campaign = [];
    this.campaignService.getCampaign({userCampaignId: id}).subscribe(data => {
        if (data.status) {
          for (let index = 0; index < data.data.length; index++) {
            var key = '';
            var value = '';
            const element = data.data[index];

            var selectedElement = element.projectInputParams;
            key = selectedElement.paramName;
            if (selectedElement.paramDatatype == 'String') {
              value = element.paramValues;
            }
            else {
              if (element.paramOperator != '=') {
                value = element.paramOperator;
              }
              value += ' ' + element.paramValues
            }
            this.campaign.push({ key: key, value: value });
          }

          if (data.data.length == 0) {
            this.campaign.push({ key: 'Campaign Audience', value: 'All Audience' });
          }
        }
        else {
          this.campaign.push({ key: 'Campaign Audience', value: 'All Audience' });
        }
    });
  }

  downloadReport(item,url) {
    let postForm: any = { campaignId: item.id, camptype: item.campaignType };
    this.campaignService.downloadReport(postForm,url).subscribe(data => {
        if (data.status) {
            window.open(data.data, "_blank");
        }
    });
  }

  deleteReport(item, index? , url? ,type?) {
    
    if(item != undefined){
      this.reportSelected = item;
      this.reportIndex = index;
      this.deleteUrl = url
      this.campType = type
      this.openModal(this.reportDeleteConfirm);
    } else {
      this.isDeleteReportShow = false;
      this.modalRef.hide();
      let postForm: any = { id: this.reportSelected.id, campaignType: this.reportSelected.campaignType };
      this.campaignService.deleteReport(postForm,this.deleteUrl).subscribe(data => {
        this.isDeleteReportShow = true;
        if(data.status){
         // this.campaignslist.splice(this.reportIndex, 1);
          this.toastr.success('Successfully deleted!', 'success');
          if(this.campType == 'android'){
            this.getCampaignsList('android','reload')
            //this.androidCamplist.splice(this.reportIndex, 1);
          } else if(this.campType == 'ios'){
            this.getCampaignsList('ios','reload')
           // this.iosCapmlist.splice(this.reportIndex, 1);
          } else if(this.campType == 'web'){
            this.getCampaignsList('web','reload')
            //this.webCapmlist.splice(this.reportIndex, 1);
          }else if(type == 'sms'){
            this.getCampaignsList('sms','reload')
           // this.smsCapmlist.splice(this.reportIndex, 1);
          }else if(this.campType == 'email'){
            this.getCampaignsList('email','reload')
           // this.emailCapmlist.splice(this.reportIndex, 1);
          } else if(this.campType == 'whatsapp'){
            this.getCampaignsList('whatsapp','reload')
           // this.whatsappCapmlist.splice(this.reportIndex, 1);
          } else if(this.campType == 'inapp'){
            this.getCampaignsList('inapp','reload')
           // this.whatsappCapmlist.splice(this.reportIndex, 1);
          } else if(this.campType == 'journey'){
            this.getCampaignsList('journey','reload')
           // this.whatsappCapmlist.splice(this.reportIndex, 1);
          }
        } else {
          this.toastr.error(data.error_msgs, 'Error');
        }
      });
    }
  }

  public dataForPaging(event,type) {
    this.page = event;
    this.getCampaignsList(type,'reload');
  }

  dataForPagingSms(event,type){
    this.pageSms = event;
    this.getCampaignsList(type);
  }

  dataForPagingEmail(event,type){
    this.pageEmail = event;
    this.getCampaignsList(type);
  }

  public repeatUpdateStatus(item,url,type){      
      let postForm: any = {
        campaignType: item.campaignType,
        id: item.id,
        campStatus: item.campaignStatus == "0" ? '1' : '0'
      };
      this.campaignService.disableReport(postForm,url).subscribe(data => {
        if(data.status){
          this.toastr.success(data.data, 'Success');
          this.getCampaignsList(type,'reload');
        } else {
          this.toastr.error(data.error_msgs, 'Error');
          item.campaignStatus = '0';
        }
      });
  }
}
