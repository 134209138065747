export class PushTemplateObj {
    deepLink?: string
    dynamicContentList?: string
    id?: number
    message?: string
    notIcon?: string
    notImage?: string
    projectId?: string
    status?: string
    templateName?: string
    title?: string
}

export class AndroidPushTemplateObj {
    deepLink?: string
    dynamicContentList?: string
    id?: number
    message?: string
    notIcon?: string
    notImage?: string
    projectId?: string
    status?: string
    templateName?: string
    title?: string
    url?:any;
}

export class AndroidCampaignObj {
    audienceCount?: string
    campStatus?: string
    campaignName?: number
    campaignPlatforms?: string
    campaignStatus?: string
    campaignType?: string
    id?: string
    limit?: string
    page?: string
    repeatStatus?: string
    scDate?:any;
    scheduleType?:any;
    statusDescription?:any;
    userId?:any;
}


export class IosPushTemplateObj {
    deepLink?: string
    dynamicContentList?: string
    id?: number
    message?: string
    notIcon?: string
    notImage?: string
    projectId?: string
    status?: string
    templateName?: string
    title?: string
    url?:any;
}

export class IosCampaignObj {
    audienceCount?: string
    campStatus?: string
    campaignName?: number
    campaignPlatforms?: string
    campaignStatus?: string
    campaignType?: string
    id?: string
    limit?: string
    page?: string
    repeatStatus?: string
    scDate?:any;
    scheduleType?:any;
    statusDescription?:any;
    userId?:any;
}

export class WebPushTemplateObj {
    deepLink?: string
    dynamicContentList?: string
    id?: number
    message?: string
    notIcon?: string
    notImage?: string
    projectId?: string
    status?: string
    templateName?: string
    title?: string
    url?:any;
}


export class EmailTemplateObj {
    dynamicContentList?: string
    emailSubject?: string
    emailText?: string
    id?: number
    isEditorView?: boolean
    status?: string
    templateName?: string
    imagePath?: string
}

export class SmsTemplateObj {
    dynamicContentList?: string
    id?: number
    smsText?: string
    status?: string
    templateName?: string
}

export class WhatsappTemplateObj {
    dynamicContentList?: string
    id?: number
    smsText?: string
    status?: string
    templateName?: string
}

export class InappTemplateObj {
    deepLink?: string
    dynamicContentList?: string
    id?: number
    message?: string
    notIcon?: string
    notImage?: string
    projectId?: string
    status?: string
    templateName?: string
    title?: string
}

export class JourneyTemplateObj {
    deepLink?: string
    dynamicContentList?: string
    id?: number
    message?: string
    notIcon?: string
    notImage?: string
    projectId?: string
    status?: string
    templateName?: string
    title?: string
}