import { Component, OnInit, OnDestroy, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { BsModalRef, BsModalService, TabsetComponent } from 'ngx-bootstrap';
import { Options } from 'ng5-slider';
import { SubscriptionService } from '../../../../core/services/subscription.service';
import { Global } from '../../../../../../src/app/shared/models/global.model';
import { ToastrService } from 'ngx-toastr';
import { Router } from "@angular/router";
import { MasterService } from '../../../../core/services/master.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { noWhitespaceValidator } from '../../../../shared/models/no-whitespace-validator';
import { SpinnerService } from '../../../../core/services/spinner.service';
declare var $: any;
declare var paypal: any;

@Component({
  selector: 'app-subscription',
  templateUrl: './upgrade-subscription.component.html',
  styleUrls: ['./upgrade-subscription.component.scss'],
})
export class UpgradeSubscriptionComponent implements OnInit, OnDestroy {
  lastClickTime: any = new Date();
  planCreditRangeValue: any = 0;
  planCreditRangePrice: any = 0;
  highValue: number = 400000;
  activePlugins: any = [];
  planCreditsRange: any = [];
  activeChannels: any = [];
  pricingPlans: any = [];
  upgradPlans: any = [];
  showLoader: boolean = true;
  totalSuccess: number = 0;
  currentPlan: any = {};
  yearly: boolean = false;
  plan: any = {};
  paymentHistory: any = [];
  paymentRefno: string = '';
  invoiceNumber: string = '';
  conversonRate: any;
  billAddress: any = {};
  date: any;
  countries: any = [];
  paymentID: any;
  paypalButtonJSRendered: boolean = false;

  @ViewChild('templatePayPalBlock') templatePayPalBlock: TemplateRef<any>;
  @ViewChild('staticTabs') staticTabs: TabsetComponent;
  options: Options = {
    showTicksValues: true,
    stepsArray: [
      { value: 2000 },
      { value: 5000 },
      { value: 50000 },
      { value: 100000 },
      { value: 200000 },
      { value: 300000 },
      { value: 400000 }
    ],
    minLimit: 0,
    maxLimit: 10,
    ticksTooltip: (value: number) => {

      var obj = this.planCreditsRange[value];
      if (obj != null) {
        return obj.description;
      }
      else {
        return '';
      }
    },
    ticksValuesTooltip: (value: number) => {

      var obj = this.planCreditsRange[value];
      if (obj != null) {
        return obj.description;
      }
      else {
        return '';
      }
    }
  };

  showToggle: boolean = false;
  modalRef: BsModalRef;
  modalRefe: BsModalRef;
  editAddressForm: FormGroup;
  pricingPlanId: any = {};
  selectedPlugins: any = [];
  selectedChannels: any = [];
  selectedPlugin: any = [];
  activePlugin: any = [];
  constructor(private modalService: BsModalService, 
    private spinner: SpinnerService,
    private global: Global,
    private toastr: ToastrService,
    private subscriptionService: SubscriptionService,
    private elementRef: ElementRef,
    private masterService: MasterService,
    private router: Router,
    private formBuilder: FormBuilder,
  ) {
    this.paymentRefno = this.random(10);
    this.invoiceNumber = this.random(8);
    this.date = new Date();
  }

  finalPrice() {
    var finalPrice = 0;

    if (!this.currentPlan.activeStatus)
      finalPrice += parseFloat(this.currentPlan.planCost);
    finalPrice += this.activePlugins.filter(function (item1: any) {
      return item1.checked == true && item1.activeFlag != "1";
    }).map(function (x) { return parseFloat(x.price); }).reduce(function (acc, val) { return acc + val; }, 0);

    finalPrice += this.activeChannels.filter(function (item1: any) {
      return item1.checked == true && item1.activeFlag != "1";
    }).map(function (x) { return parseFloat(x.price); }).reduce(function (acc, val) { return acc + val; }, 0);

    var rv = this.planCreditRangeValue;
    var planCreditRangeId = this.planCreditsRange.filter(function (x: any) { return x.countTo == rv && x.activeFlag != "1"; });
    if (planCreditRangeId.length > 0) {
      finalPrice += planCreditRangeId[0].price;
    }
    
    return finalPrice;
  }

  showModal(template: TemplateRef<any>, cls) {

    var dt: any = new Date();
    if ((dt - this.lastClickTime) < 3000) {
      return;
    }

    this.lastClickTime = new Date();
    this.modalRef.hide();
    var that = this;
    setTimeout(function () {
      that.modalRef = that.modalService.show(template, { backdrop: 'static', class: cls });
    }, 500);
  }

  ngOnDestroy() {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }

  ngOnInit() {
    this.prepareEditForm();
    this.getUSD2INR();
    this.getPricingPlans();
    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip();
      $('.ng5-slider-inner-tooltip').tooltip();  
    }, 1000);
    
  }

  private prepareEditForm() {
    this.editAddressForm = this.formBuilder.group({
      companyName: [this.billAddress.companyName, Validators.compose([Validators.required, noWhitespaceValidator, Validators.minLength(5), Validators.maxLength(50)])],
      phone: [this.billAddress.phone, Validators.compose([Validators.required, noWhitespaceValidator, Validators.minLength(7), Validators.maxLength(15), Validators.pattern(this.global.PhonePattern)])],
      email: [this.billAddress.email, Validators.compose([Validators.required, noWhitespaceValidator, Validators.pattern(this.global.EmailPattern)])],
      companyAddress: [this.billAddress.companyAddress, Validators.compose([Validators.required, noWhitespaceValidator, Validators.minLength(10), Validators.maxLength(100)])],
      city: [this.billAddress.city, Validators.compose([Validators.required, noWhitespaceValidator, Validators.minLength(3), Validators.maxLength(20), Validators.pattern(this.global.AlphaPattern)])],
      state: [this.billAddress.state, Validators.compose([Validators.required, noWhitespaceValidator, Validators.minLength(3), Validators.maxLength(20), Validators.pattern(this.global.AlphaPattern)])],
      countryId: [this.billAddress.countryId, Validators.compose([Validators.required, noWhitespaceValidator, Validators.min(0), Validators.pattern(this.global.NumericPattern)])],
      companyGstno: [this.billAddress.companyGstno, Validators.compose([Validators.maxLength(20), Validators.pattern(this.global.AlphaNumericPattern)])],
    });
  }
  openModal() {
    $('#plan-view').appendTo("body").modal('show');
  }

  upgrade(templateUpgrade: TemplateRef<any>) {
 
    this.modalRef = this.modalService.show(templateUpgrade, { backdrop: 'static' });
    this.GoToActive();


  }

  loadCountries() {
    this.masterService.getCountries().subscribe(
      data => {
        this.countries = data.data;
        this.prepareEditForm();
      }, error => {
        this.toastr.error('Something went wrong', 'Error');
      });
  }

  getUSD2INR() {
    this.subscriptionService.getUSD2INR().subscribe(
      data => {
        this.conversonRate = data.rates.INR;
      }, error => {
      });
  }

  getUserBillAddress() {
    this.spinner.show();
    this.subscriptionService.getUserBillAddress({ }).subscribe(
      data => {
        this.spinner.hide();
        if (data.status) {
          this.billAddress = data.data;
          this.prepareEditForm();
        }
      }, error => {
        this.spinner.hide();
        this.toastr.error('Something went wrong', 'Error');
      });
  }

  getUserPaymentHistory() {
    this.spinner.show();
    this.subscriptionService.getUserPaymentHistory({ }).subscribe(
      data => {
        this.totalSuccess++;
        if (this.showLoader || this.totalSuccess == 2) {
          this.spinner.hide();
          this.showLoader = true;
        }
        if (data.status) {
          this.paymentHistory = data.data;
        }
        window.scroll(0, 0);
      }, error => {
        this.spinner.hide();
        this.toastr.error('Something went wrong', 'Error');
      });
  }

  razorPay() {
    this.modalRef.hide();
    var that = this;
    this.addJsToElement('https://checkout.razorpay.com/v1/checkout.js').onload = () => {
      var options = {
        "key": this.global.razorpayCredentialLive,
        //"amount": (parseInt((this.conversonRate * this.upgradPlans.netAmount).toString()) * 100).toString(),
        "amount": (parseInt((this.upgradPlans.netAmount).toString()) * 100).toString(),
        name: "CronBerry",
        notes: this.plan,
        "handler": function (response) {
          that.afterPayment('Success', response.razorpay_payment_id, JSON.stringify(response));
        },
        "prefill": {
          "name": this.billAddress.companyName,
          "email": this.billAddress.email

        },
        "theme": {
          "color": "blue"
        }
      };

      var rzpay = new window["Razorpay"](options);
     

      rzpay.open();
    }
  }

  paypalPay(that) {
    document.getElementById('payPalBlock').innerHTML = '';

    if (!this.paypalButtonJSRendered) {
      that.addJsToElement('https://www.paypalobjects.com/api/checkout.js').onload = () => {
        this.paypalRender(that);
      };
    }
    else {
      this.paypalRender(that);
    }
  }

  paypalRender(that) {
    this.modalRef.hide();
    this.paypalButtonJSRendered = true;

    window["paypal"].Button.render({
      env: 'production',
      client: {
        production: 'EGdO-ItGNgH1n_4SXIgTDLeV8pdf1vB0crPLBz-omV8TVNkgGyvxnDymPTK9ZEMaDqhZqYzgSLsrxQym',
      },
      commit: true,
      payment: function (data, actions) {
        return actions.payment.create({
          payment: {
            transactions: [
              {
                amount: { total: that.upgradPlans.netAmount / this.conversonRate, currency: 'USD' },
                custome: "{id : " + that.paymentID + "}"
              }
            ]
          }
        })
      },
      onCancel: (data, actions) => {
        this.modalRef.hide();
        this.afterPayment('Cancel', '', '');
      },
      onError: (err) => {
        this.modalRef.hide();
        this.afterPayment('Error', '', JSON.stringify(err));
      },
      onAuthorize: function (data, actions) {
        return actions.payment.execute().then(function (payment) {
          this.afterPayment('Success', '', JSON.stringify(payment));
        })
      }
    }, '#payPalBlock');
  }

  addJsToElement(src: string): HTMLScriptElement {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    this.elementRef.nativeElement.appendChild(script);
    return script;
  }

  random(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }


  getPricingPlans() {

    this.spinner.show();
    this.subscriptionService.getPricingPlan({ }).subscribe(
      data => {
        this.totalSuccess++;
        if (this.showLoader || this.totalSuccess == 2) {
          this.spinner.hide();
          this.showLoader = true;
        }
        this.spinner.hide();
        if (data.status) {
          this.pricingPlans = data.data;


          this.currentPlan = this.pricingPlans.filter(x => x.activeStatus)[0];
          if (this.currentPlan == undefined) {
            this.currentPlan = this.pricingPlans[0];
          }
        
          this.yearly = true;
          var freePlan = this.pricingPlans.filter(x => x.planType == 'FREE')[0];
          freePlan.planType = 'YEARLY';
          let freePlayYearly = Object.assign({}, freePlan);
          freePlayYearly.planType = 'MONTHLY';
          this.pricingPlans.unshift(freePlayYearly);
          
          this.onSelectionChange(this.currentPlan);
        }
        window.scroll(0, 0);
      }, error => {
        this.spinner.hide();
        this.toastr.error('Something went wrong', 'Error');
      });
  }

  getPluginChannelRangeData() {

    this.spinner.show();
    this.subscriptionService.getPluginChannelRangeData({ id: this.pricingPlanId}).subscribe(
      data => {
        this.spinner.hide();
        if (data.status) {
          this.activePlugins = data.data.activePlugins;
          this.activeChannels = data.data.activeChannels;
          this.activePlugins.forEach(element => {
            element.checked = element.activeFlag == "1";
          });
          this.activeChannels.forEach(element => {
            element.checked = element.activeFlag == "1";
          });

          this.planCreditsRange = data.data.planCreditsRange;
          var pcr = data.data.planCreditsRange.map(function (x: any) { return { value: x.countTo }; });

          var stepsArray = pcr;
          var minLimit = 0;
          var maxLimit = 10;
          var activeCredit = data.data.planCreditsRange.filter(function (item1: any) {
            return item1.activeFlag == "1";
          });
          this.planCreditRangeValue = 0;
          if (activeCredit.length > 0) {
            this.planCreditRangeValue = activeCredit[0].countTo;
            var index = data.data.planCreditsRange.findIndex(function (item1: any) {
              return item1.activeFlag == "1";
            });
            
            minLimit = index;
            maxLimit = data.data.planCreditsRange.length;
          }
          else if (data.data.planCreditsRange.length > 0 && this.currentPlan.planName != 'FREE')
            this.planCreditRangeValue = data.data.planCreditsRange[0].countTo;
          var optionsNew: Options = {
            showTicksValues: true,
            stepsArray: pcr,
            minLimit: minLimit,
            maxLimit: maxLimit,
            ticksTooltip: (value: number) => {
              var obj = this.planCreditsRange[value];
              if (obj != null) {
                return obj.description;
              }
              else {
                return '';
              }
            },
            ticksValuesTooltip: (value: number) => {

              var obj = this.planCreditsRange[value];
              if (obj != null) {
                return obj.description;
              }
              else {
                return '';
              }
            }
          };
          this.options = optionsNew;
          this.updatePrice();
          $('.ng5-slider-inner-tooltip').tooltip();
        }
      }, error => {
        this.spinner.hide();
        this.toastr.error('Something went wrong', 'Error');
      });
  }

  GoToActive() {

    this.selectedPlugins = this.activePlugins.filter(function (item1: any) {
      return item1.checked == true;
    });
    this.activePlugin = [];
    this.selectedPlugins.forEach(element =>
      this.activePlugin.push({
        id: element.id,
        pluginName: element.pluginName,
        price: element.price
      }
      ))
    this.selectedChannels = this.activeChannels.filter(function (item: any) {
      return item.checked == true && item.activeFlag != "1";
    });
    this.selectedPlugin = [];
    this.selectedChannels.forEach(element1 =>
      this.selectedPlugin.push({
        id: element1.id,
        channelName: element1.channelName,
        price: element1.price
      }
      ))
    this.upgradePlanCost();
  }
  updatePrice() {
    var rv = this.planCreditRangeValue;
    var lstDt = this.planCreditsRange.filter(function (x: any) { return x.countTo == rv; });
    if (lstDt.length > 0)
      this.planCreditRangePrice = lstDt[0].price;
  }

  upgradePlanCost() {

    this.spinner.show();

    var selectedPlugins = this.activePlugins.filter(function (item1: any) {
      return item1.checked == true && item1.activeFlag != "1";
    }).map(function (x) { return { id: x.id }; });

    var dataIntegration = this.activePlugins.filter(function (item1: any) {
      return item1.checked == true;
    }).map(x => x.pluginName).join(', ');

    var selectedChannels = this.activeChannels.filter(function (item1: any) {
      return item1.checked == true && item1.activeFlag != "1";
    }).map(function (x) { return { id: x.id }; });

    var marketingChannels = this.activeChannels.filter(function (item1: any) {
      return item1.checked == true;
    }).map(x => x.channelName).join(', ');

    var rv = this.planCreditRangeValue;
    var planCreditRangeId = 0;
    var lstDt = this.planCreditsRange.filter(function (x: any) { return x.countTo == rv; });
    if (lstDt.length > 0)
      planCreditRangeId = lstDt[0].id;
    this.subscriptionService.upgradePlanCost({
      selectedPlugins: selectedPlugins, selectedChannels: selectedChannels,
      planCreditRangeId: planCreditRangeId, pricingPlanId: this.pricingPlanId,
      marketingChannels: marketingChannels,
      dataIntegration: dataIntegration,
    }).subscribe(
      data => {
        this.spinner.hide();
        if (data.status) {

          this.upgradPlans = data.data;
         
        }
      }, error => {
        this.spinner.hide();
        this.toastr.error('Something went wrong', 'Error');
      });
  }


  getNewPlanCost(pricingPlanId) {
    this.spinner.show();
    this.subscriptionService.getNewPlanCost({pricingPlanId: pricingPlanId }).subscribe(
      data => {
        this.spinner.hide();
        if (data.status) {
          this.plan = data.data;
          this.plan.id = pricingPlanId;
        }
      }, error => {
        this.spinner.hide();
        this.toastr.error('Something went wrong', 'Error');
      });
  }

  private paypal(): void {
    this.spinner.show();
    this.paypalButtonJSRendered = true;
    
    document.getElementById('payPalBlock').innerHTML = '';
    if (window['paypal']) {
      paypal.Button.render(this.paypalConfig, '#payPalBlock')
    } else {
      this.addScript('https://www.paypalobjects.com/api/checkout.js').then(() => {
        paypal.Button.render(this.paypalConfig, '#payPalBlock')
      }).catch(err => {
      })
    }
    setTimeout(() => {
      this.spinner.hide();
     
    }, 5000)
  }

  addScript(jsload) {
    return new Promise((resolve, reject) => {
      let scripttagElement = document.createElement('script');
      scripttagElement.src = jsload;
      scripttagElement.onload = resolve;
      document.body.appendChild(scripttagElement);
    })
  }

  paypalConfig = {
    env: 'production',
    client: {
      production: 'AWhXoFwkLLzo6AkpSzbgmxmbFaGgeD-FUNU6WdX6WwV7ARvmo_0xf5JSYtIlZsQ_5dfXJmAzsEEY8lag',
    },
    commit: true,
    payment: (data, actions) => {
      return actions.payment.create({
        payment: {
          transactions: [
            {
              amount: { total: this.plan.netAmount, currency: 'USD' },
              custom: "{id : " + this.paymentID + "}"
            }
          ]
        }
      });
    },
    onCancel: (data, actions) => {
      this.spinner.show();
      this.afterPayment('Cancel', '', '');
    },
    onError: (err) => {
      this.spinner.show();
      this.afterPayment('Error', '', JSON.stringify(err));

    },
    onAuthorize: (data, actions) => {
      return actions.payment.execute().then(function (payment) {
        this.afterPayment('Success', '', JSON.stringify(payment));
      })
    }
  };


  insertPaymentBeforeGateway(paymentMethod) {

    this.spinner.show();
    var postData = {
      pricingPlanId: this.pricingPlanId,
      actualAmount: this.upgradPlans.actualAmount,
      paymentMethod: paymentMethod,
      totalTax: this.upgradPlans.taxAmount,
      taxRate: this.upgradPlans.taxRate,
      totalAmount: (paymentMethod == 'paypal' ? (1 / this.conversonRate) : 1) * this.upgradPlans.netAmount,
      paymentRefno: this.paymentRefno,
      invoiceNumber: this.invoiceNumber,
      description: 'email support'
    };

    

    this.subscriptionService.insertPaymentBeforeGateway(postData).subscribe(
      data => {
        this.spinner.hide();
        if (data.status) {
          this.paymentID = data.data.id;
          if (paymentMethod == 'paypal') {
            this.modalRef.hide();
            var that = this;
            setTimeout(function () {
              that.modalRef = that.modalService.show(that.templatePayPalBlock, { backdrop: 'static' });
             
              that.paypal();
            }, 500);
          }
          else {
            this.razorPay();
          }
        }
      }, error => {
        this.spinner.hide();
        this.toastr.error('Something went wrong', 'Error');
      });
  }


  afterPayment(paymentStatus, paymentRefno, description) {

    var selectedPlugins = this.activePlugins.filter(function (item1: any) {
      return item1.checked == true && item1.activeFlag != "1";
    }).map(function (x) { return { id: x.id }; });

    var selectedChannels = this.activeChannels.filter(function (item1: any) {
      return item1.checked == true && item1.activeFlag != "1";
    }).map(function (x) { return { id: x.id }; });

    var rv = this.planCreditRangeValue;
    var planCreditRangeId = 0;
    var lstDt = this.planCreditsRange.filter(function (x: any) { return x.countTo == rv; });
    if (lstDt.length > 0)
      planCreditRangeId = lstDt[0].id;


    
    this.spinner.show();
    var postData = {
      id: this.paymentID,
      pricingPlanId: this.pricingPlanId,
      paymentStatus: paymentStatus,
      paymentRefno: paymentRefno,
      description: description,
      planCreditRangeId: planCreditRangeId,
      selectedPlugins: selectedPlugins,
      selectedChannels: selectedChannels
    };
    this.subscriptionService.insertPaymentAfterGatway(postData).subscribe(
      data => {
       
        this.modalRef.hide();
        window.scroll(0, 0);
        if (data.status) {
          window.scroll(1, 1);
          window.scroll(2, 2);
          this.showLoader = false;
          this.totalSuccess = 0;
          this.spinner.show();
          
          this.modalRef.hide();
          var that = this;
          
          that.toastr.success(data.data);
          window["location"].href = "/admin/subscription-invoice";
        
        }
      }, error => {
        this.spinner.hide();
        this.modalRef.hide();
        this.toastr.error('Something went wrong', 'Error');
      });


  }

  onSelectionChange(item) {
    this.pricingPlanId = item.id;
    this.currentPlan = item;
    
    this.getPluginChannelRangeData();
  }
}
