
<main>
	<section class="inner-page">
		<div class="inner-page-header py-3 d-none d-md-block">
			<div class="container-fluid">
				<div class="row">
					<div class="col-md-6">
						<h4>
							<a class="back-link" (click)="back()"><img src="assets/images/angle-left.svg"></a>
							Cronberry Api Integration Kit
						</h4>
					</div>	
					</div>	
					</div>		
					</div>
	
	
	<div class="inner-page-body border-bottom-0">
		<div class="container-fluid">
	
			<div  class="integration-body">
			<p>Below are two options to integrate Cronberry Apis into your existing website or forms. <br>
				The first option is directly calling cronberry api on any form submit or registration event while second option is to use a readymade javascript function which you need to include in your html head tag and call this function on any event.</p>
				<br>
			<tabset>
				<tab heading="Using Api">
					<div class="integration-box">
						<div class="table-responsive">
							<table>
								<tr>
									<td><div class="width_150">API Endpoint :</div></td>
									<td>{{userData.endUrl}}</td>
								</tr>
								<tr>
									<td><div class="width_150">API Content Type :</div></td>
									<td>JSON</td>
								</tr>
								<tr>
									<td><div class="width_150">API Call Type :</div></td>
									<td>POST</td>
								</tr>
							</table>
						</div>
					</div>
			
					<div class="integration-detail pl-20 pr-20">
						<h5>Input Parameters :</h5>
						<div class="integration-detail-content">
							<h6>Param</h6>
							<p>This includes below type of parameters.</p>
							<p>Fixed – There are some pre-defined parameters which are helpful to identify your users. These parameters are optional i.e. you don’t need to pass all parameters and you can choose only those parameters which you will need further. Ex:- Name, Email, Phone number, etc.</p>
							<p>Variable – These are parameters which you have created on project integration screen and will be used for filtering the audience while creating campaign.</p>
							<p>Note:- Project Key & audience Id are mandatory and should be passed for every API.</p>
							<p>Note:- "<b>audienceId</b>" parameter can be used for update same record. If you passed same value against this key. Cronberry will update records if it finds within your leads otherwise always create new lead.</p>
						</div>
						<div class="integration-detail-content">
							<h6>ProjectKey :</h6>
							<p>Your unique Project Key generated for your Account (as shown below)</p>
						</div>
					</div>
			
					<div class="integration-box">
						<p>Unique Generated Project Key : {{userData.paramData!=undefined?userData.paramData[0].projectKey:''}}</p>
					</div>
			
					<div class="integration-detail pl-20 pr-20">
						<h5 class="mb-1">Input Json Object:</h5>
						<p class="mb-2"><strong>Note:- </strong>If you want to send only single value then you can pass that value in paramValue else if you want to send multiple values against paramKey then you need to pass paramValue in Array format. Ex:- “paramKey”: “skill”, “paramValue”: = [“Java”, “SQL”, “PHP”]</p>
					</div>
			
					<div class="json-code integration-box pl-20 pr-20">
						<pre><code>{{JSON.stringify(fixedParamList, null, 4)}}</code></pre>
					</div>
			
					<div class="integration-detail pl-20 pr-20">
						<h5>Output Response</h5>
					</div>
					<div class="json-code integration-box pl-20 pr-20">
						<pre><code>{{json}}</code></pre>
					</div>
			
					<div class="output-response pl-20 pr-20">
						<h5>Output Response</h5>
						<div class="output-response-detail">
							<h6>Status</h6>
							<p>This field contains the data submission status. It is “success” if the data is saved successfully and it will be ‘fail’ if there was an error updating the details.</p>
						</div>
						<div class="output-response-detail">
							<h6>ErrorDesc :</h6>
							<p>It contains the error description if the status is false else it will be null.</p>
						</div>
						<div class="output-response-detail">
							<h6>Data</h6>
							<p>This field contains the audienceId for which data is updated.</p>
						</div>
					</div>
				</tab>
				<tab heading="Using JavaScript">
					<div class="json-code integration-box pl-20 pr-20">
						<pre><code>
	&lt;script  type="text/javascript" &gt;
	
	function cronberryTrigger()&#123;
		var username = document.getElementById("name").value; // replace with your field id
		var email = document.getElementById("email").value; // replace with your field id
		var mobile = document.getElementById("mobile").value; // replace with your field id
	
		var data = JSON.stringify({{JSON.stringify(fixedParamList, null, 4)}});
		var xhr = new XMLHttpRequest();
		xhr.addEventListener("readystatechange", function () &#123;
			if (this.readyState === 4) &#123;
				console.log(this.responseText);
			&#125;
		&#125;);
		xhr.open("POST", "{{userData.endUrl}}");
		xhr.setRequestHeader("Content-Type", "application/json");
		
		xhr.send(data);
	&#125;
	&lt;/script&gt;
						</code></pre>
					</div>
				</tab>
			</tabset>
	
			
		</div>
	
		</div>
	</div>
	
	</section>
	</main>