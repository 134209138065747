import { Global } from './../../../../shared/models/global.model';
import { Component, OnInit, AfterViewInit } from '@angular/core';

declare var $:any;

@Component({
  selector: 'static-footer',
  templateUrl: './static-footer.component.html',
  styleUrls: ['./static-footer.component.scss']
})

export class StaticFooterComponent implements OnInit, AfterViewInit {

  currentYear : any = (new Date()).getFullYear();

  constructor(public global: Global) {

  }

  ngOnInit() {
    
  }
  
  ngAfterViewInit(){
    // this.loadTawkAPI();
  }
  goToPosition(item: string) {
    var position = $("#" + item).position()
    window.scrollTo({ left: 0, top: position.top, behavior: 'smooth' }); 
  }

  // loadTawkAPI(){
  //   var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
  //   (()=>{
  //   var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
  //   s1.async=true;
  //   s1.src='https://embed.tawk.to/5d93176d6c1dde20ed045cd5/default';
  //   s1.charset='UTF-8';
  //   s1.setAttribute('crossorigin','*');
  //   s0.parentNode.insertBefore(s1,s0);
  //   })();
  // }

}
