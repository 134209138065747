<main>
    <section class="inner-page">
        <div class="inner-page-header">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-4">
                      <h4>
                        <a class="back-link" (click)="back()"><img src="assets/images/angle-left.svg"></a>
                        Asset Library
                      </h4>
                      <p>Don't upload senstive and confidential files here.</p>
                    </div>
                    <div class="col-md-8" *ngIf="isSubscribe">
                        
                        <div class="filter-input">
                          <div class="filter-input-space">
                            <select class="form-control" [(ngModel)]="fileType" (ngModelChange)="pageNo = 1; getMediaList()">
                                <option value="">File Type</option>
                                <option value="GALLERY">Gallery</option>
                                <option value="IMAGE">Your Image</option>
                                <option value="FILE">Your File</option>
                            </select>
                          </div>
                          
                          <div class="filter-input-space search-box">
                            <input trim="blur" class="btn" name="search" placeholder="Search" type="text" [(ngModel)]="searchText" autocomplete="off" (keyup.enter)="updateList()">
                            <button class="btn-search"  (click)="updateList()" tabindex="3" type="submit"><div class="sr-only">Search</div></button>
                          </div>  
                          
                          <div class="filter-input-space">
                            <button (click)="showGallery()" class="c-align d-flex btn white_add_btn" type="button" tooltip="Add Media" [tooltipAnimation]="true" tooltipPlacement="top" >
                              <img src="assets/images/plus-icon.png"> &nbsp; Add
                            </button>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="inner-page-body">
            <div *ngIf="isSubscribe" class="container-fluid">
                <div class="table-responsive table-reports">
                    <table class="table">
                        <tbody class="norecord-handling">
                            <tr>
                                <th>S.No.</th>
                                <th>Image</th>
                                <th>Tag Name</th>
                                <th class="d-none d-md-table-cell">Created Date</th>
                                <th class="text-center">Action</th>
                            </tr>
                            <tr *ngFor="let item of mediaList | paginate: { itemsPerPage:dataSize, currentPage:pageNo, totalItems:totalDataSize }; let i = index;">
                                <td>{{item.sNo}}</td>
                                <td>
                                  <div class="image-box">
                                    <a target="_blank" href="{{item.originalFile}}" >
                                      <img *ngIf="item.thumbnailFile != null; else fileImage" [src]="item.thumbnailFile" alt="{{item.mediaName}}" [ngClass]="item.fileType == 'file' ? 'file-icon' : ''" >
                                      <ng-template #fileImage>
                                        <img src="assets/images/file-alt.svg" alt="{{item.mediaName}}" class="file-icon" >
                                      </ng-template>
                                    </a>
                                  </div>
                                </td>
                                <td>{{item.mediaName}}</td>
                                <td class="d-none d-md-table-cell">{{item.updated | date}}</td>
                                <td class="text-center">
                                    <a class="c-pointer" (click)="copyText(item.originalFile)" tooltip="Copy media path" [tooltipAnimation]="true" tooltipPlacement="top" >
                                      <img class="img" src="assets/images/copy.svg" width="14" />
                                    </a>  &nbsp;  &nbsp;
                                    <a target="_blank" href="{{item.originalFile}}">
                                        <img class="img" src="assets/images/segmenteye.png" />
                                    </a>  &nbsp;  &nbsp; 
                                    <a *ngIf="item.fileType != 'GALLERY'" class="c-pointer" (click)="openModalDelete(confirm, item.id, i)" >
                                        <img class="img" src="assets/images/delete.png" />
                                    </a>
                                </td>
                            </tr>
                            <tr *ngIf="totalDataSize == 0">
                                <td class="no-records" colspan="5">No Records Yet</td>
                            </tr>
                        </tbody>
                    </table>
                      <div *ngIf="totalDataSize > 0" class="table-footer footer-gray">
                        <pagination-controls
                              (pageChange)="dataForPaging($event)"
                              maxSize="9"
                              directionLinks="true"
                              autoHide="true"
                              class="pull-right"
                              responsive="true"
                              previousLabel="Previous"
                              nextLabel="Next"
                              screenReaderPaginationLabel="Pagination"
                              screenReaderPageLabel="page"
                              screenReaderCurrentLabel="You're on page">
                        </pagination-controls>
                        <div class="textblue text-left">
                          <div class="data-length"> Show
                            <select [(ngModel)]="dataSize" (change)="getMediaList()">
                              <option value="20">20</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                            </select>
                          </div>
                          <span>Total Count: {{totalDataSize}}</span>
                        </div>
                     </div>
                </div>
                <br>    
            </div>
            <app-page-disable *ngIf="!isSubscribe"></app-page-disable>
        </div>
    </section>

</main>

<ng-template #confirm>
    <form>
      <div class="forgot-password-modal">
        <div class="modal-header">
          <h4 class="modal-title pull-left">Delete Media</h4>
          <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body modal-user-management">
          <div class="form-group mb-0">
            <p class="pt-2">
              Are you sure, you want to delete?
            </p>
            <div class="d-flex justify-content-end mt-5">
              <div>
                <button class="btn remove-user-btn mr-4" (click)="modalRef.hide()" type="button">
                  Dismiss
                </button>
              </div>
              <div>
                <button class="btn save-btn" type="button" (click)="deleteItem()">
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </ng-template>

<!-- <ng-template #addGalleryPopup>
  <gallery-popup *ngIf="isShowGalleryPopup" [modalRefGallery]="modalRefGallery" (selectMediaEvent)="getMedia($event)" [galleryType]="'addMedia'"></gallery-popup>
</ng-template> -->