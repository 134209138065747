import { Injectable } from '@angular/core';
import { Global } from '../../shared/models/global.model';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class SubscriptionService {

    rootPath: string;

    constructor(private global: Global, private http: HttpClient) {
        this.rootPath = this.global.ApiUrl + 'subscription/';
    }

    getFAQ() {
        return this.http.post<any>(this.rootPath + `get-content`,{contentType:'SUBSCRIPTION_FAQ'}, { headers: this.global.Header() });
    }

    getPricingPlans(body: any) {
        return this.http.post<any>(this.rootPath + `get-pricing-plans`, body, { headers: this.global.Header() });
    }

    getPricingPlansBeforeLogin(body: any) {
        return this.http.get<any>(this.rootPath + `fetch-pricing-plans`, { headers: this.global.Header() });
    }

    getNewPlanCost(body: any) {
        return this.http.post<any>(this.rootPath + `get-new-plan-cost`, body, { headers: this.global.Header() });
    }

    getUserBillAddress(body: any) {
        return this.http.post<any>(this.rootPath + `get-user-bill-address`, body, { headers: this.global.Header() });
    }

    upsertUserBillAddress(body: any) {
        return this.http.post<any>(this.rootPath + `upsert-user-bill-address`, body, { headers: this.global.Header() });
    }

    getUserPaymentHistory(body: any) {
        return this.http.post<any>(this.rootPath + `get-user-payment-history`, body, { headers: this.global.Header() });
    }

    insertPaymentBeforeGatewayConformation(body: any) {
        return this.http.post<any>(this.rootPath + `insert-payment-before-gateway-conformation`, body, { headers: this.global.Header() });
    }

    insertPaymentAfterConformation(body: any) {
        return this.http.post<any>(this.rootPath + `insert-payment-after-gateway-conformation`, body, { headers: this.global.Header() });
    }

    downloadPaymentInvoice(body: any) {
        return this.http.post<any>(this.rootPath + `download-payment-invoice`, body, { headers: this.global.Header() });
    }

    getUSD2INR() {
        return this.http.get<any>('https://api.exchangeratesapi.io/latest?base=USD&symbols=INR');
    }

    getCurrentPlan(body: any) {
        return this.http.post<any>(this.rootPath + `get-current-plan`, body, { headers: this.global.Header() });
    }

    getSubscriptionInvoices(body: any) {
        return this.http.post<any>(this.rootPath + `get-subscription-invoices`, body, { headers: this.global.Header() });
    }

    downloadInvoice(body: any) {
        return this.http.post<any>(this.rootPath + `download-invoice`, body, { headers: this.global.Header() });
    }

    getPricingPlan(body: any) {
        return this.http.post<any>(this.rootPath + `get-pricing-plan`, body, { headers: this.global.Header() });
    }

    getPluginChannelRangeData(body: any) {
        return this.http.post<any>(this.rootPath + `get-plugin-channel-range-data`, body, { headers: this.global.Header() });
    }

    upgradePlanCost(body: any) {
        return this.http.post<any>(this.rootPath + `upgrade-plan-cost`, body, { headers: this.global.Header() });
    }


    insertPaymentBeforeGateway(body: any) {
        return this.http.post<any>(this.rootPath + `insert-payment-before-gateway`, body, { headers: this.global.Header() });
    }

    insertPaymentAfterGatway(body: any) {
        return this.http.post<any>(this.rootPath + `insert-payment-after-gateway`, body, { headers: this.global.Header() });
    }
}