<main>
<div class="inner-page">
  <div class="inner-page-header">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6">
          <h4>
            <a class="back-link" (click)="back()"><img src="assets/images/angle-left.svg"></a>
            <span>Download Audience Data</span>
            <p class="note">Reports will be available for 7 days from the requested date. </p>
          </h4>
        </div>
        <div class="col-md-6 text-right">
          <select class="form-control d-inline-block width_auto" (change)="loadDownloadData()" [(ngModel)]="categoryName">
            <option *ngFor="let cat of dataList" [value]="cat.linkRewrite">{{cat.name}}</option>
          </select>
        </div>
      </div>
    </div>
  </div>

  <div class="inner-page-body border-bottom-0">
    <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
            <div class="downloadTableData">
              <div class="table-reports" *ngIf="downloadData != null ">
                <div class="table-body">
                  <div class="table-responsive table-height-scroll text-center">
                    <table class="table">
                        <tr>
                        <th>ID</th>
                        <!-- <th>Report Name</th> -->
                        <th>Report Link</th>
                        <th>Status</th>
                        <th>Requested Date</th>
                        <th>Created By</th>
                        </tr>
                        <tr *ngFor="let list of downloadData | paginate: { itemsPerPage:dataSize, currentPage:pageNo, totalItems:totalDataSize }; let i = index">
                          <td>
                            {{list.id}}
                          </td>
                          <!-- <td>
                            <div class="reportNameDwnldData">{{list.reportName}}</div>
                          </td> -->
                          <td>
                            <div> 
                              <a *ngIf="list.status == 'COMPLETED'" href="{{list.reportPath}}" target="_blank">Download</a>
                              <div *ngIf="list.status != 'COMPLETED'">-</div>
                            </div>
                          </td>
                          <td>
                            {{list.status}}
                          </td>
                          <td>
                            {{list.created}}
                          </td>
                          <td>{{list.userName}}</td>
                        </tr>

                        <tr *ngIf="totalDataSize == 0">
                          <td class="no-records" colspan="5">No Records Yet</td>
                      </tr>
                    </table>
                  </div>
                </div>

                <div *ngIf="downloadData.length > 20" class="table-footer footer-gray">
                  <pagination-controls
                      (pageChange)="dataForPaging($event)"
                      maxSize="9"
                      directionLinks="true"
                      autoHide="true"
                      class="pull-right"
                      responsive="true"
                      previousLabel="Previous"
                      nextLabel="Next"
                      screenReaderPaginationLabel="Pagination"
                      screenReaderPageLabel="page"
                      screenReaderCurrentLabel="You're on page">
                  </pagination-controls>
                  <div class="textblue text-left">
                      <div class="data-length"> Show
                      <select [(ngModel)]="dataSize" (change)="loadDownloadData()">
                          <option value="20">20</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                      </select>
                      </div>
                      <span>Total Count: {{totalDataSize}}</span>
                  </div>
                  </div>
              </div>
            </div>
          </div>
    </div>
  </div>
  </div>
</div>
</main>