import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Injectable} from '@angular/core';
import { Observable} from 'rxjs';
import { APIResponse } from '../modal/api.response';

@Injectable({
    providedIn: 'root'
})
export class BaseService {

    constructor (
        private http: HttpClient
    ) {

    }

    private requestHeader() {
        const headerSettings: { [name: string]: string | string[] } = {};
        headerSettings["Content-Type"] = "application/json";
        return new HttpHeaders(headerSettings);
    }

    public getRequest(apiURL: string): Observable<APIResponse> {
        return this.http.get<APIResponse>(apiURL, { headers: this.requestHeader() });
    }

    public getRequestDownloadRpot(apiURL: string,params): Observable<any> {
        return this.http.get(apiURL+'?'+params,{responseType:'blob',observe: 'response'});
    }

    public getRequestHTML(apiURL: string): Observable<any> {
        return this.http.get(apiURL, { responseType: 'text', headers: this.requestHeader() })
    }

    public postRequest(apiURL: string, param: any, isSpinner?): Observable<APIResponse> {
        const body = JSON.stringify(param);        
        if(isSpinner == false){
            isSpinner = false
        } else {
            isSpinner = true;
        }
        return this.http.post<APIResponse>(apiURL, body, { headers: this.requestHeader(), reportProgress:isSpinner });
    }

    public putRequest(apiURL: string, param: any, headers:any, isSpinner?): Observable<APIResponse> {
        const body = JSON.stringify(param);        
        if(isSpinner == false){
            isSpinner = false
        } else {
            isSpinner = true;
        }
        let headParams:HttpHeaders =  this.requestHeader();
        for (var key in headers) {            
            if (headers.hasOwnProperty(key) ) {
                headParams =  headParams.append(key,headers[key])
            }
        }
        return this.http.put<APIResponse>(apiURL, body, { headers: headParams, reportProgress:isSpinner });
    }

    public postRequestWithHeaders(apiURL: string, param: any, headers:any,isSpinner:boolean = true ): Observable<APIResponse> {
        const body = JSON.stringify(param);        
        let headParams:HttpHeaders =  this.requestHeader();
        for (var key in headers) {            
            if (headers.hasOwnProperty(key) ) {
                headParams =  headParams.append(key,headers[key])
            }
        }
        return this.http.post<APIResponse>(apiURL, body, { headers: headParams, reportProgress:isSpinner });
    }

    public deleteRequestWithHeaders(apiURL: string, param: any, headers:any,isSpinner:boolean = true){
        const body = JSON.stringify(param);
        const options = {
            headers: this.requestHeader(),
            body: body,
            reportProgress:isSpinner
          };
          for (var key in headers) {
            if (headers.hasOwnProperty(key) ) {
                options.headers =  options.headers.append(key,headers[key])
            }
        }
        return this.http.delete<APIResponse>(apiURL, options);
    }

    public postRequestXlFromData(apiURL,param: any): Observable<APIResponse> {
        const body = JSON.stringify(param);
        let headParams:HttpHeaders =  this.requestHeader();
        
        return this.http.post<APIResponse>(apiURL, body, { headers: headParams });
    }

    public postRequestMultipart(apiURL: string, param: any): Observable<APIResponse> {
        return this.http.post<APIResponse>(apiURL, param);
    }


    public postRequestProductUpload(apiURL: string, param: any): Observable<any> {
        return this.http.post<any>(apiURL, param);

        // return this.http.post<APIResponse>(apiURL, param,{headers:{'Content-Type': 'application/vnd.ms-excel',responseType:'application/vnd.ms-excel'}});
        //application/vnd.ms-excel
    }

    

}
