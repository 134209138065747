<main>
    <section class="inner-page">
        <div class="inner-page-header">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-6">
                        <h4>
                        <a class="back-link" (click)="back()"><img src="assets/images/angle-left.svg"></a>
                        Bar Notification
                        </h4>
                    </div>
                    <div *ngIf="isSubscribe" class="col-md-6 text-right d-flex j-end">
                        <a class="btn white_add_btn d-flex c-align" [routerLink]="['/admin/announcement/edit-bar']" tooltip="Create Bar Notification" [tooltipAnimation]="true" tooltipPlacement="top">
                            <img src="assets/images/plus-icon.png"> &nbsp; Add
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="inner-page-body site-form">
            <div *ngIf="isSubscribe" class="container-fluid">
                <div class="table-responsive table-height-scroll table-reports">
                    <table class="table">
                        <tbody class="norecord-handling">
                            <tr>
                                <th width="20">S.No.</th>
                                <th width="200">Name</th>
                                <th class="text-center">Published</th>
                                <th>Start on</th>
                                <th>Stop on</th>
                                <th class="text-center">Action</th>
                            </tr>
                            <tr *ngFor="let item of dataList | paginate: { itemsPerPage:dataSize, currentPage:pageNo, totalItems:totalDataSize }; let i = index;">
                                <td>{{i + 1}}</td>
                                <td class="text-capitalize">{{item.name}}</td>
                                <td class="text-center"><span class="tag {{item.status}}"></span> </td>
                                <td>{{item.scheduleStart | date}}</td>
                                <td>{{item.scheduleEnd | date}}</td>
                                <td class="text-center">
                                    <a [routerLink]="['/admin/announcement/edit-bar/'+item.id]" class="c-pointer">
                                      <img class="img" src="assets/images/pinkedit.png" />
                                    </a>  &nbsp; 
                                    <a class="c-pointer" (click)="openModalDelete(item.id)">
                                      <img class="img" src="assets/images/delete.png" />
                                    </a>
                                </td>
                            </tr>
                            <tr><td *ngIf="dataList.length == 0" class="no-records text-center" colspan="6">No Records Yet</td></tr>
                        </tbody>
                    </table>
                </div>
                <div *ngIf="dataList.length > 0" class="table-footer footer-gray">
                <pagination-controls
                    (pageChange)="dataForPaging($event)"
                    maxSize="9"
                    directionLinks="true"
                    autoHide="true"
                    class="pull-right"
                    responsive="true"
                    previousLabel="Previous"
                    nextLabel="Next"
                    screenReaderPaginationLabel="Pagination"
                    screenReaderPageLabel="page"
                    screenReaderCurrentLabel="You're on page">
                </pagination-controls>
                <div class="textblue text-left">
                    <div class="data-length"> Show
                    <select [(ngModel)]="dataSize" (change)="getDataList()">
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                    </div>
                    <span>Total Count: {{totalDataSize}}</span>
                </div>
                </div>
            </div>
            <app-page-disable *ngIf="!isSubscribe"></app-page-disable>
        </div>
    </section>

</main>


<ng-template #deleteConfirm>
  <form>
    <div class="forgot-password-modal">
        <div class="modal-header">
            <h4 class="modal-title pull-left">Delete Bar Notification</h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body modal-user-management">
            <div class="form-group mb-0">
                <p class="pt-2">Are you sure, you want to delete this Bar Notification?</p>
                <div class="d-flex justify-content-end mt-5">
                    <div><button class="btn remove-user-btn mr-4" (click)="modalRef.hide()" type="button">Dismiss</button></div>
                    <div><button class="btn save-btn" type="button" (click)="delete()">Yes</button></div>
                </div>
            </div>
        </div>
    </div>
</form>
  </ng-template>