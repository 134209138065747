import { DatePipe } from "@angular/common";
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { ToastrService } from "ngx-toastr";
import { element } from "protractor";
import { environment } from "../../../../environments/environment";
import { EmailTemplateObj, PushTemplateObj, SmsTemplateObj } from "../../../core/modal/campaign";
import { CampaignService } from "../../../core/services/campaign.service";
import { JourneyObj } from "../campaign-journey.modal";
import { CampaignJourneyService } from "../campaign-journey.service";

@Component({
    selector: 'app-create-journey',
    templateUrl: './create-journey.component.html',
    styleUrls: ['../campaign-journey.scss']
})

export class CreateJourneyComponent implements OnInit, OnDestroy {
    modalRef: BsModalRef;
    journeyCampaignName: string = '';
    journeyCampaign: Map<number, JourneyObj[]> = new Map<number, JourneyObj[]>();
    // journeyCampaign: any;
    activeTab: string;
    emailTemplateList: EmailTemplateObj[] = [];
    pushTemplateList: PushTemplateObj[] = [];
    smsTemplateList: SmsTemplateObj[] = [];
    templateList: any[] = []; 
    selectedIndex: number;
    selectedKey: number;
    selectedListIndex: number = null;
    selectedCampaign: JourneyObj;
    selectedPlatform : string[] = [];
    campaignType: string[];
    defualtCampaignObj: JourneyObj;
    @ViewChild('templateListModal') templateListModal: TemplateRef<any>;
    @ViewChild('sendTimeModal') sendTimeModal: TemplateRef<any>
    sendTime:any;
    isUpdated: boolean = false;
    selectedTemplate: any = {};
    platformList:any[] = [];
    frequencyStore:any=new Array({'intervalName':'days','intervalTime':'0','type':'1','mins':'0'});
    totalMinsInterval:any=0;
    dayDisplay:any;
    hourDisplay:any;
    minsDisplay:any;
    getJourneyDataStore:any;
    showMins:any;
    showHr:any;
    showDay:any;
    showCurrentCampMins:any;
    showCurrentCampHr:any;
    showCurrentCampDay:any;
    todayDate: any = new Date();
    journeyDate: any = new Date();
    templateData:any;
    campNameNotEdit:any;
    pushCampUrl:any = environment.pushServiceUrl;
    // iosCampUrl:any = environment.iosPushUrl
    // webCampUrl:any = environment.webPushUrl
    smsCampUrl:any = environment.smsPushUrl
    emailCampUrl:any = environment.emailPushUrl
    whatsappCampUrl:any = environment.whatsappApiUrl
    readonly lsKey: string = 'journeyData';
    readonly maxJourney:number = 10;
    readonly maxTemplate:number = 10;
    
    constructor(
        private modalService: BsModalService,
        private campaignService: CampaignService,
        private toastr: ToastrService,
        private campaignJourneyService: CampaignJourneyService,
        private route: ActivatedRoute,
        private router: Router,
        private datePipe: DatePipe,

    ){

    }

    ngOnInit() {
        this.campaignType = ['androidpush','iospush','webpush','email','sms','whatsapp'];
        this.platformList = [
            {name:'Web',value:'web',checked:false},
            {name:'IOS',value:'ios',checked:false},
            {name:'Android',value:'android',checked:false}
        ]
        this.journeyCampaign[1] = [];
        this.sendTime = {
            type:"0",
            intervalTime:0,
            intervalName:'days'
        }
        this.route.params.subscribe( params => {
            if(params.name != undefined && params.id != undefined){
                this.isUpdated = true;
                let id = decodeURIComponent(params.id)
                this.getJourneyData(params.name, id);
            }
        });

        var journeyData = localStorage.getItem(this.lsKey)        
        if(journeyData != null){
            this.setJourneyData(JSON.parse(journeyData))
        }

        this.getCampaignList();
        
    }

    ngOnDestroy(){
        if(this.modalRef)
        this.modalRef.hide();
    }

    openModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template, { backdrop: 'static', class:'journey-modal' });
    }

    getEmailTemplateList(){
        this.templateList = [];
        this.campaignService.getEmailTemplateData().then( data => {
            if(data != undefined){
                this.templateList = data;
                this.openModal(this.templateListModal)
            };
        });
    }

    getPushTemplateList(url?){
        let urlCheck
        if(url == 'web'){
            urlCheck = this.pushCampUrl;
        } else if(url == 'ios'){
            urlCheck = this.pushCampUrl
        } else if(url == 'android'){
            urlCheck = this.pushCampUrl
        }  else if(url == 'sms'){
            urlCheck = this.smsCampUrl
        } else if(url == 'email'){
            urlCheck = this.emailCampUrl
        } else if(url == 'whatsapp'){
            urlCheck = this.whatsappCampUrl
        }
        this.templateList = [];
        this.campaignService.getPushTemplateData({},urlCheck,'reload').then(data => {
            if(data != undefined){
                this.templateList = data;
                if(Array.isArray(this.templateList)){
                this.openModal(this.templateListModal)
                } else if(typeof(this.templateList) == 'string'){
                    this.toastr.error(this.templateList)
                }
            } else{
                this.toastr.error()
            }
        });
    }
      
    getSMSTemplateList(){
        this.templateList = [];
        this.campaignService.getSmsTemplateData().then( data => {
            if(data != undefined){
                this.templateList = data;
                this.openModal(this.templateListModal)
            };
        })
    }

    getinappTemplateList(){
        this.templateList = [];
        this.campaignService.getDefaultInappTemplateData().then( data => {
            if(data != undefined){
                this.templateList = data;
                this.openModal(this.templateListModal)
            };
        })
    }
    
    checkCampaginName(){
        let valid = true;
        if(this.journeyCampaignName == ''){
            valid = false;
            this.toastr.error('Enter Campaign Name.');
        }
        if(this.journeyCampaignName.match("^[a-z A-Z 0-9]*$") == null){
            valid = false;
            this.toastr.error('Campaign Name Should be Alphanumeric.');
        }
        return valid;
    }

    addCampaign(key){
        if(!this.checkCampaginName()){
            return;
        }
        this.journeyCampaign[key] = [];
        this.campaignType.forEach(type => {
            this.journeyCampaign[key].push(this.setDefualtCampaign(type,key));
        });
    }

    getCampaignList() {        
        if(this.journeyCampaign!= undefined)
        return Object.keys(this.journeyCampaign);        
    }

    setDefualtCampaign(type, key,timeType?,time?,days?,hrs?,mins?,setTime?,daysConvert?, hours?, minutes?){
        let obj = {
            templateId:[],
            templateName:[],
            campaignType:type,
            campaignPlatforms:[],
            campPosition:key,
            campIntervalName:timeType != undefined ? timeType : '',
            campIntervalTime:time != undefined ? time : null,
            days:days,
            hrs:hrs,
            mins:mins,
            setTime:setTime,
            daysConvert:daysConvert,
            hours:hours,
            minutes:minutes
            
        }
        return obj ;
    }

    addTemplate(data,i,key,listIndex?){        
        this.templateData = data
        this.selectedTemplate = {};
        this.platformList.forEach( x => x.checked = false);
        this.selectedIndex = i;
        this.selectedKey = key;
        let type = data.campaignType;
        this.selectedCampaign = data;
        if(listIndex != undefined){
            this.selectedListIndex = listIndex;
            this.selectedTemplate = {
                templateName: data.templateName[listIndex],
                id: data.templateId[listIndex]
            }
            // if(type == 'push'){
            //     this.platformList.forEach( x => {
            //         x.checked = data.campaignPlatforms[listIndex].find(y => y.trim() == x.value) != undefined;
            //     });
            // }
        }
        if(type == 'androidpush'){
            this.getPushTemplateList('android');
        } else if(type == 'iospush'){
            this.getPushTemplateList('ios');
        } else if(type == 'webpush'){
            this.getPushTemplateList('web');
        } else if(type == 'sms'){
            this.getPushTemplateList('sms');
        } else if(type == 'email'){
            this.getPushTemplateList('email');
        } else if(type == 'inapp'){
            this.getinappTemplateList()
        } else if(type == 'whatsapp'){
            this.getPushTemplateList('whatsapp')
        }
    }

    templateSelected(value,status)
    {    
    }

    selectTemplate(){
        let data = this.selectedTemplate;        
        let platforms = [];
        if(this.selectedCampaign.campaignType == 'push'){
            this.platformList.forEach(x => {
                if(x.checked)
                platforms.push(x.value);
                x.checked = false;
            });
            if(platforms.length == 0){
                this.toastr.error('Select platform.');
                return;
            }
        }
        if(data.templateName == undefined){
            this.toastr.error('Select template');
            return;
        }
        
        if(this.templateData.templateId.indexOf(data.id) !== -1)
        {
            this.toastr.error('This template already added');
            this.selectedTemplate = {};
            this.modalRef.hide();
            return;
        }
        else
        {
            
            
        if(this.selectedListIndex != null){
            this.journeyCampaign[this.selectedKey][this.selectedIndex].templateName[this.selectedListIndex] = data.templateName;
            this.journeyCampaign[this.selectedKey][this.selectedIndex].templateId[this.selectedListIndex] = data.id;
            if(this.selectedCampaign.campaignType == 'push'){
                this.journeyCampaign[this.selectedKey][this.selectedIndex].campaignPlatforms[this.selectedListIndex] = platforms;
            }
        } else {
            this.journeyCampaign[this.selectedKey][this.selectedIndex].templateName[0] = data.templateName;
            this.journeyCampaign[this.selectedKey][this.selectedIndex].templateId[0] = data.id;
            // this.journeyCampaign[this.selectedKey][this.selectedIndex].templateName.push(data.templateName);
            // this.journeyCampaign[this.selectedKey][this.selectedIndex].templateId.push(data.id);
            if(this.selectedCampaign.campaignType == 'push'){
                this.journeyCampaign[this.selectedKey][this.selectedIndex].campaignPlatforms.push(platforms);
            }
        }
        if(this.selectedCampaign.campaignType != 'push'){
            this.journeyCampaign[this.selectedKey][this.selectedIndex].campaignPlatforms = [];
        }
        if(this.maxJourney >= Number(this.selectedKey) && this.journeyCampaign[Number(this.selectedKey)+1] == undefined){
            this.journeyCampaign[Number(this.selectedKey)+1] = []; 
        }
        }
        
        this.selectedTemplate = {};
        this.modalRef.hide();
    }

    deleteTemplateName(type,i,key,listIndex){
        this.journeyCampaign[key][i].templateName.splice(listIndex,1);
        this.journeyCampaign[key][i].templateId.splice(listIndex,1);
        this.journeyCampaign[key][i].campaignPlatforms.splice(listIndex,1);
        if(this.journeyCampaign[key][i].templateName.length == 0){
            this.deleteTemplate(type,i,key);
        }
    }

    deleteTemplate(type,i,key){
        if(this.checkTemplate(key,i)){
            this.journeyCampaign[key][i] = this.setDefualtCampaign(type,key);
        } else {
            if(key > 1 && !this.checkTemplate(key+1)){
                this.journeyCampaign[key][i] = this.setDefualtCampaign(type,key);
                delete this.journeyCampaign[key+1];
            } else {
               // this.toastr.error('Cannot delete selected item.');
            }
        }
    }

    checkTemplate(key,j?){
        let item = this.journeyCampaign[key];
        if(item.length > 0){
            let isTemplate = false;
            for(let i = 0; i < item.length; i++){
                if(i != j && item[i].templateName.length > 0){
                    isTemplate = true;
                    break;
                }
            }
            return isTemplate;
        } else {
            return false
        }
    }

    selectFrequency(key){                 
        this.selectedKey = key;
        let prevElement = this.selectedKey - 1;        
        if(prevElement > 0)
        {
        let prevTabIntVal = this.journeyCampaign[prevElement][0].campIntervalTime;        
        let seconds = prevTabIntVal*60;
        this.showMins = Math.floor(seconds % 3600 / 60);
        this.showHr = Math.floor(seconds % (3600*24) / 3600);
        this.showDay = Math.floor(seconds / (3600*24));
        }
         if(prevElement == 0)
        {
            let freqStore ;
            if(this.frequencyStore[this.selectedKey])
            {
                 freqStore =  this.frequencyStore.slice(-1).pop();
                 freqStore = freqStore.minuts
            }
            else
            {
                freqStore = this.journeyCampaign[this.selectedKey][0].campIntervalTime
            }            
            let seconds = freqStore*60;
            this.showMins = Math.floor(seconds % 3600 / 60);
            this.showHr = Math.floor(seconds % (3600*24) / 3600);
            this.showDay = Math.floor(seconds / (3600*24));
            
        }

            
        if(this.journeyCampaign[key][0].campIntervalName == ''){
            this.sendTime = {
                type:"0",
                intervalTime:0,
                intervalName:'minutes'
            }
            if(key > 1)
            this.sendTime.type = "1";
        }
         else {
            
        if(this.journeyCampaign[key][0].campIntervalName == 'minutes' && this.journeyCampaign[key][0].campIntervalTime == 4){
            this.sendTime = {
                type:"0",
                intervalTime:0,
                intervalName:'minutes'
            }
        }
         else{             
                    if(this.frequencyStore[this.selectedKey])
                    {                                                
                        let currentTabIntVal = this.frequencyStore[this.selectedKey].minuts;
                        let minutsToHr = currentTabIntVal/60;
                        let hrToDays = minutsToHr/24;

                        let seconds = currentTabIntVal*60;
                        this.showCurrentCampMins = Math.floor(seconds % 3600 / 60);
                        this.showCurrentCampHr = Math.floor(seconds % (3600*24) / 3600);
                        this.showCurrentCampDay = Math.floor(seconds / (3600*24)); 
                        
                        if(minutsToHr > 0)
                        {
                            let hrToDaysFloat = Math.floor(hrToDays)
                            if(hrToDays%1 ==0)
                            {         
                                this.sendTime.intervalTime = hrToDays ;
                                this.sendTime.intervalName = 'days';
                            }
                            else if(hrToDays%1 !=0 && hrToDaysFloat > 0)
                            {         
                                this.sendTime.intervalTime = minutsToHr ;
                                this.sendTime.intervalName = 'hours';

                            }
                            else if(hrToDaysFloat < 1)
                            {
                                this.sendTime.intervalTime = minutsToHr ;
                                this.sendTime.intervalName = 'hours';
                            }
                            if(minutsToHr < 1)
                            {         
                                this.sendTime.intervalTime = currentTabIntVal ;
                                this.sendTime.intervalName = 'minutes';
                            }
                        }

                        else
                        {       
                            this.sendTime.intervalTime = currentTabIntVal ;
                        }
                    }

                    else if(this.journeyCampaign[key][0].setTime)
                    {           
                        let seconds = this.journeyCampaign[key][0].setTime*60;
                        let showMins = Math.floor(seconds % 3600 / 60);
                        let showHr = Math.floor(seconds % (3600*24) / 3600);
                        let showDay = Math.floor(seconds / (3600*24));
                        let setTimemints = this.journeyCampaign[key][0].setTime;
                        let minutsToHr = setTimemints/60;
                        let hrToDays = minutsToHr/24;
                        
                        if(minutsToHr > 0)
                        {
                            let hrToDaysFloat = Math.floor(hrToDays)
                            if(hrToDays%1 ==0)
                            {                                                                
                                this.sendTime.intervalTime = hrToDays ;
                                this.sendTime.intervalName = 'days';
                            }
                            else if(hrToDays%1 !=0 && hrToDaysFloat > 0)
                            {                          
                                this.sendTime.intervalTime = minutsToHr ;
                                this.sendTime.intervalName = 'hours';

                            }
                            else if(hrToDaysFloat < 1)
                            {
                                this.sendTime.intervalTime = minutsToHr ;
                                this.sendTime.intervalName = 'hours';
                            }
                            if(minutsToHr < 1)
                            {         
                                this.sendTime.intervalTime = setTimemints ;
                                this.sendTime.intervalName = 'minutes';
                            }
                        }

                        else
                        {                      
                            this.sendTime.intervalTime = setTimemints ;
                        }
                        
                    }

                    else
                    {
                        let seconds = this.journeyCampaign[key][0].campIntervalTime*60;
                        let showMins = Math.floor(seconds % 3600 / 60);
                        let showHr = Math.floor(seconds % (3600*24) / 3600);
                        let showDay = Math.floor(seconds / (3600*24));
                        let setTimemints = this.journeyCampaign[key][0].campIntervalTime;
                        let minutsToHr = setTimemints/60;
                        let hrToDays = minutsToHr/24;
                        
                        if(minutsToHr > 0)
                        {
                            let hrToDaysFloat = Math.floor(hrToDays)
                            if(hrToDays%1 ==0)
                            {                                                                
                                this.sendTime.intervalTime = hrToDays ;
                                this.sendTime.intervalName = 'days';
                            }
                            else if(hrToDays%1 !=0 && hrToDaysFloat > 0)
                            {                          
                                this.sendTime.intervalTime = minutsToHr ;
                                this.sendTime.intervalName = 'hours';

                            }
                            else if(hrToDaysFloat < 1)
                            {
                                this.sendTime.intervalTime = minutsToHr ;
                                this.sendTime.intervalName = 'hours';
                            }
                            if(minutsToHr < 1)
                            {         
                                this.sendTime.intervalTime = setTimemints ;
                                this.sendTime.intervalName = 'minutes';
                            }
                        }

                        else
                        {                      
                            this.sendTime.intervalTime = setTimemints ;
                        }
                    }
                        

                    this.sendTime = {
                        type:"1",
                        intervalName: this.sendTime.intervalName,
                        intervalTime : this.sendTime.intervalTime
                    }

                    
                    
                }
        }

        if(this.journeyCampaign[key][0].campIntervalName == 'hours' && this.journeyCampaign[key][0].fixTime > 24){
            this.sendTime = {
                type:"1",
                intervalTime:this.journeyCampaign[key][0].fixTime,
                intervalName:'hours'
            }
        }
        if(this.journeyCampaign[key][0].setTime && ! this.journeyCampaign[key][0].fixTime)
        {
        if(this.journeyCampaign[key][0].campIntervalName == 'hours' && this.journeyCampaign[key][0].setTime > 1440){
            let hoursConvert = this.journeyCampaign[key][0].setTime/60;
            hoursConvert = Math.floor(hoursConvert)
            this.sendTime = {
                type:"1",
                intervalTime:hoursConvert,
                intervalName:'hours'
            }
        }
        }
        this.openModal(this.sendTimeModal);
    }

    submitSendTime(){  
                      
        if(this.sendTime.type == 1){
            if(this.sendTime.intervalTime == null){
                this.toastr.error('Enter Number.')
                return
            }
            // if(this.sendTime.intervalTime.match(/^-?(0|[1-9]\d*)?$/) == null){
            //     this.toastr.error('Allow only number.')
            //     return
            // }
            if(this.sendTime.intervalName == 'minutes' && this.sendTime.intervalTime < 5 ){
                this.toastr.error('Time greater than 4 minutes.')
                return
            }
            if(this.sendTime.intervalTime < 1 || this.sendTime.intervalTime > 99){
                this.toastr.error('Value between 1 to 99')
                return
            }
        }
        let intervalName;
        let intervalTime;
        if(this.sendTime.type == "0"){
            this.sendTime.intervalName = 'minutes'
            this.sendTime.intervalTime = 4
        } else {
            intervalName = this.sendTime.intervalName
            intervalTime = this.sendTime.intervalTime
        }
        
        this.frequencyStore[this.selectedKey]=this.sendTime;        
        this.frequencyStore.forEach(element => {
            if(element.intervalName == 'days')
            {
                    element.minuts = element.intervalTime*1440;
            }
            if(element.intervalName == 'hours')
            {
                    element.minuts = element.intervalTime*60;
            }
            if(element.intervalName == 'minutes')
            {
                    element.minuts = element.intervalTime*1;
            }
        }); 
            let prevElement = this.selectedKey-1;
            
            if(prevElement > 0)
            {
                
               // this.totalMinsInterval = this.frequencyStore.reduce((sum, item) => sum + parseFloat(item.minuts), 0);
               if(this.selectedKey > 1)
               {
               this.totalMinsInterval = this.journeyCampaign[prevElement][0].campIntervalTime + this.frequencyStore[this.selectedKey].minuts;
               }

            }
            else
            {
                if(this.selectedKey > 1)
                {
                this.totalMinsInterval = this.frequencyStore.reduce((sum, item) => sum + parseFloat(item.minuts), 0);
                }

            } 
            
            if(this.selectedKey == 1)
            {
                
                if(this.sendTime.intervalTime > 0)
                {

                    this.totalMinsInterval = this.frequencyStore[this.selectedKey].minuts
                }

                else
                {
                this.totalMinsInterval = this.journeyCampaign[this.selectedKey][0].campIntervalTime
                }
            }
            
            let seconds = this.totalMinsInterval*60;
            let minsConvert = Math.floor(seconds % 3600 / 60);
            let hrsConvert = Math.floor(seconds % (3600*24) / 3600);
            let daysConvert = Math.floor(seconds / (3600*24));
            this.dayDisplay  = daysConvert > 0 ? daysConvert + (daysConvert == 1 ? " day " : " days, ") : "";
            this.hourDisplay = hrsConvert > 0 ? hrsConvert + (hrsConvert == 1 ? " hour " : " hours, ") : "";
            this.minsDisplay = minsConvert > 0 ? minsConvert + (minsConvert == 1 ? " minute " : " minutes, ") : "";            
            
            this.journeyCampaign[this.selectedKey].forEach(element => {
            element.days = this.dayDisplay;
            element.hrs  = this.hourDisplay;
            element.mins = this.minsDisplay;
            element.campIntervalTime = this.totalMinsInterval;
            element.campIntervalName = this.sendTime.intervalName;
            element.IntervalName = this.sendTime.intervalName;  
            element.fixTime = this.sendTime.intervalTime;            
            let dateOfJourney =  this.datePipe.transform(new Date().setDate(this.todayDate.getDate() + daysConvert ));
            element.daysConvert = dateOfJourney;
            element.hours =  hrsConvert < 10 ? '0' + hrsConvert : hrsConvert;
            element.minutes =  minsConvert < 10 ?'0' + minsConvert :minsConvert;            
            
        });
        
        this.sendTime = {
            type:"0",
            intervalTime:0,
            intervalName:'days'
        }
        this.modalRef.hide();    
    }

    submitJourney(type?){
        if(!this.checkCampaginName()){
            return;
        }
        var valid = true;
        var postData = {
            campName : this.journeyCampaignName.trim(),
            type : this.isUpdated ? 'update' : 'save',
            campaignsList : []
        };
        let isTemplateSelected = false;
        this.getCampaignList().forEach(key => {
            this.journeyCampaign[key].forEach(data => {
                if(data.templateName.length > 0){
                    postData.campaignsList.push(data);
                    if(data.campIntervalName == ''){
                        valid = false;
                    }
                    isTemplateSelected = true;
                }
            });
        });
        if(type == 'ls'){
            localStorage.setItem(this.lsKey,JSON.stringify(postData));
            return;
        }
        if(!valid){
            this.toastr.error('Select Frequency');
            return;
        } else if (!isTemplateSelected){
            this.toastr.error('Please select atleast one template.');
            return
        }
        postData.campaignsList.forEach(elems=>{
            elems.campIntervalName = 'minutes';
            if(!elems.campIntervalTimeOld)
            {
                elems.campIntervalTimeOld = elems.campIntervalTime;
            }
        })
        
        this.campaignJourneyService.saveUpdateCampaignJourney(postData).subscribe(data => {
            localStorage.removeItem(this.lsKey);
            if(data.status){
                this.toastr.success(data.data,'Success');
                this.router.navigate(['/admin/campaign/campaign-manager']);
            } else {
                this.toastr.error(data.error_msgs,'Error');
            }
        })
    }

    setJourneyData(data){   
        
        this.journeyCampaignName = data.campName;
        let journeyData = [];
        data.campaignsList.forEach(element => {            
            if(journeyData[element.campPosition] == undefined){
                journeyData[element.campPosition] = [];
            }
            journeyData[element.campPosition].push(element);            
        });

        
        let keyList = Object.keys(journeyData);
        keyList.forEach( key => {
            this.journeyCampaign[key] = [];            
            this.campaignType.forEach(type => {
                let data = journeyData[key].find(x => x.campaignType == type)                
                if(data != undefined){
                    this.journeyCampaign[key].push(data);                    
                } else {
                    this.journeyCampaign[key].push(this.setDefualtCampaign(type,key,journeyData[key][0].campIntervalName,journeyData[key][0].campIntervalTime,journeyData[key][0].days, journeyData[key][0].hrs, journeyData[key][0].mins,journeyData[key][0].setTime,journeyData[key][0].daysConvert,journeyData[key][0].hours,journeyData[key][0].minutes));
                }
            });
        });
        
        if(data.campaignsList.length > 0)
        this.journeyCampaign[Number(keyList.length)+1] = [];        
    }
    
    getJourneyData(name,id){      
        this.campaignJourneyService.fetchCampaignJourney({campName:name,journeyUserId:id}).subscribe(data => {
            if(data.status){ 
                this.campNameNotEdit = data.data.campName;
                data.data.campaignsList.forEach(element => {
                    let seconds = element.campIntervalTime*60;
                    let minsConvert = Math.floor(seconds % 3600 / 60);
                    let hrsConvert = Math.floor(seconds % (3600*24) / 3600);
                    let daysConvert = Math.floor(seconds / (3600*24));

                   // let dateOfJourney =  this.datePipe.transform(this.journeyDate.setTime(this.todayDate.getTime() + element.campIntervalTime*60000 ));
                    let dateOfJourney   = this.datePipe.transform(new Date().setDate(this.todayDate.getDate() + daysConvert));
                    element.daysConvert = dateOfJourney;
                    element.hours       = hrsConvert < 10 ?'0'+hrsConvert:hrsConvert;
                    element.minutes     = minsConvert < 10 ?'0'+minsConvert:minsConvert;
                    
                    element.days  = daysConvert > 0 ? daysConvert + (daysConvert == 1 ? " day " : " days, ") : "";
                    element.hrs = hrsConvert > 0 ? hrsConvert + (hrsConvert == 1 ? " hour " : " hours, ") : "";
                    element.mins = minsConvert > 0 ? minsConvert + (minsConvert == 1 ? " minute " : " minutes, ") : "";                    

                });

                let sortData = data.data.campaignsList.sort((a, b) => a.campPosition < b.campPosition ? -1 : 1)
                
                data.data.campaignsList = sortData;
                                
                for(let i=0; i < data.data.campaignsList.length; i++)
                {
                    if(i>0)
                    {
                        if(data.data.campaignsList[i-1].campIntervalTime > data.data.campaignsList[i].campIntervalTime)
                        {
                            data.data.campaignsList[i].setTime = data.data.campaignsList[i].campIntervalTime;
                        }
                        else
                        {
                            data.data.campaignsList[i].setTime = data.data.campaignsList[i].campIntervalTime - data.data.campaignsList[i-1].campIntervalTime;
                        }
                    }
                    
                }  
                                              
                setTimeout(()=>{
                    this.setJourneyData(data.data);
                },1000)
            } else {
                this.toastr.error(data.error_msgs,'Error');
            }
        });
    }
}