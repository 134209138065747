<main>
	<section class="inner-page">
		<div class="inner-page-header py-3 d-none d-md-block">
			<div class="container-fluid">
				<div class="row">
					<div class="col-md-6">
						<h4>
							<a class="back-link" (click)="goToBack()"><img src="assets/images/angle-left.svg"></a>
							Two Factor Authentication
						</h4>
					</div>
				</div>
			</div>
		</div>


		<div class="inner-page-body border-bottom-0" *ngIf="!setupChallenge">
			<div class="container-fluid">

				<div class="integration-body">

					<div *ngIf="!userChallenge">
						<div class="challengesTitle">Set Up Challenge</div>
						<div *ngFor="let option of allChallenges" class="d-flex start-align authMethodList">
							<!-- <label *ngIf="option.status == 1" class="check radio">
								<input type="radio" name="radioGroup" [value]="option.symbol"
									[(ngModel)]="userSelectChallenge" (change)="onChallengeSelect()" />
									<span class="checkmark"></span>
							</label> -->
							<span *ngIf="option.status == 1" class="google_auth_button c-pointer" (click)="onChallengeSelect(option.symbol)">
							<img src="assets/images/googleicon.png"> {{ option.name }}
							</span>
						</div>

					</div>

					<div *ngIf="userChallenge">
						<div class="challengesTitle">Set Up Challenge - {{userChallenge}}</div>
						<span class="google_auth_button c-pointer" (click)="actionProperty = userChallenge;   disableConfirm(disableConfirmWindow)">
							<img src="assets/images/googleicon.png"> Disable {{userChallenge}}
						</span>
					</div>
				</div>
			</div>
		</div>


		<ng-template #disableConfirmWindow>
			<div class="forgot-password-modal">
					<div class="modal-header">
						<h4 class="modal-title pull-left">
							Are you sure want to disable ?
						</h4>
						<button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body modal_height_apply">
						<div class="innerConfrm text-center">
							<button class="btn btn-dark" (click)="disableConfirmyes(disableAuthForm)">Yes</button>
							<button class="btn btn-dark red" (click)="modalRef.hide()">No</button>
						</div>
					</div>
			</div>
		</ng-template>


		<ng-template #disableAuthForm>
			<div class="forgot-password-modal">
					<div class="modal-header">
						<h4 class="modal-title pull-left">
							Disable {{actionProperty}}
						</h4>
						<button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body modal_height_apply">
						<div class="form-group">
							<input type="text" class="form-control" placeholder="Enter code" #gCodeValue>
						</div>
						<div class="form-group">
							<button type="button" class="btn btn-dark" (click)="disableSubmit(gCodeValue.value)">Submit</button>
						</div>
					</div>
			</div>
		</ng-template>


		<div class="inner-page-body border-bottom-0" *ngIf="setupChallenge">
			<div class="container-fluid">

				<div class="integration-body">

					<div *ngIf="setupGAuth">
						<div class="challengesTitle">Set up device</div>

						<div class="devide_by_nums">
							<div class="inner_shadowed_box">
								<div class="app_top_info">
									<div class="app_title">
										Authenticator app
									</div>
									<div class="app_bottom
									_info">
									A virtual MFA device is an application running on your device that you can configure  by scanning a QR code.
								</div>
								</div>
								<div class="padding_box_mfa">
							<div class="auth_app_info d-flex">
								<span class="numOfSteps">
									1
								</span>
								<div class="infoColumn one">
									Install a compatible application such as google authenticator, Duo mobile or any other authy app on your mobile device or computer.
								</div>
							</div>


							<div class="auth_app_info d-flex">
								<span class="numOfSteps">
									2
								</span>
								<div class="infoColumn">
									<div class="challengeQrCode d-flex c-align">
										<qr-code [value]="gAuthQRCode"
										   [size]="120"></qr-code>
										   <div class="qrInfo">
									   Open your authenticator app then use the app to scan the code. Alternatively, you can type a secret key.
									   <span class="copySecretKey" [tooltip]="secretKey" tooltipPlacement="top" (click)="copyText(secretKey)">Copy secret key</span>
									   </div>
									</div>
								</div>
							</div>

							<div class="auth_app_info d-flex">
								<span class="numOfSteps">
									3
								</span>
								<div class="infoColumn three">
									<div class="fillInputInfo">
									Fill in  two consecutive codes from your MFA device.
									</div>
									<div class="row">
										<div class="col-md-6">
									<div class="form-group">
										<input type="text" class="form-control" placeholder="MFA code 1" (input)="onInputChange($event)" maxlength="6" [(ngModel)]="gAuthFirstInput">
									</div>
								</div>

								<div class="col-md-6">
									<div class="form-group">
										<input type="text" class="form-control" placeholder="MFA code 2" (input)="onInputChange($event)" maxlength="6" [(ngModel)]="gAuthSecondInput">
									</div>
									</div>
									</div>
								</div>
							</div>

							<div class="form-group text-right m-b-0">
								<button type="button" class="btn btn-dark" (click)="validateGAuth()">
									Add MFA
								</button>
							</div>
							</div>

						</div>
					</div>
					</div>


					<div *ngIf="setupOTP">
						<div *ngIf="otpRequest">
							<div class="max_width_300">
							<div class="challengesTitle">Enter Your Number</div>
							<div class="form-group">
							<input type="number" maxlength="10" name="otpNumber" placeholder="Enter your phone number"
								[(ngModel)]="otpPhoneNumber" class="form-control" />
							</div>
							<div class="form-group">
								<button type="button" class="btn btn-dark" (click)="getOTP()">
									Get OTP
								</button>
							</div>
							</div>
						</div>

						<div *ngIf="!otpRequest">
							<div class="challengesTitle">Enter Your OTP Send To {{otpPhoneNumber}}</div>
							<div class="form-group">
							<input type="number" name="otpCode" placeholder="Enter your OTP Code"
								[(ngModel)]="otpCodeInput" class="form-control" />
							</div>
							<div class="form-group">
								<button type="button" class="btn btn-dark" (click)="validateOTP()">
									Submit
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</main>