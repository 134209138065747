<main>
    <section class="inner-page">
        <div class="inner-page-header">
            <div class="container-fluid">
                <div class="form-row d-flex headMainOuterBox">
                    <div class="headHalfBox">
                        <h4>
                            <a class="back-link" [routerLink]="['/admin/project-integration/Forms/Custom Forms']"><img src="assets/images/angle-left.svg"></a>
                            <ng-container *ngIf="isUpdate; else createTitle"> Update Form
                                <span class="cursor-pointer" (click)="copyLink()">
                                    <img src="assets/images/copy.svg">
                                </span>
                            </ng-container>
                            <ng-template #createTitle> Create Form </ng-template>
                        </h4>
                    </div>
                    <div class="headHalfBox text-md-right text-center text-input">
                        <input trim="blur" maxlength="200" class="form-control" type="text" [(ngModel)]="dataModel.name" [disabled]="disbaleName == true" [ngClass]="{'invalid':(dataModel.name == '' && isSubmit)}" placeholder="Form Name*" />

                    </div>
                </div>
            </div>
        </div>
        <div class="inner-page-body">
            <div class="container-fluid">
                <div *ngIf="loading" class="section-loader fixedTop">
                    <div class="loader">
                        <div class="la-ball-clip-rotate la-2x">
                            <div></div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-setting project-integration-tab configuration">
                            <tabset #pageTabs>
                                <tab heading="Fields">
                                    <div class="field-list">
                                        <label>Drag below Fields</label>
                                        <ul class="box form-row d-md-flex d-none">
                                            <li class="col-6" *ngFor="let item of fieldModels" [dndDraggable]="item" [dndEffectAllowed]="'copy'" [dndDisableIf]="item.type === 'file' && fileAddCount >= maxfileAdd">
                                                <span><img src="assets/images/{{item.icon}}"
                                                        alt="{{item.label}}">{{item.label}}</span>
                                            </li>
                                            <li class="col-6" (click)="addSteps()">
                                                <span><img src="assets/images/plus.svg" />Add Steps</span>
                                            </li>
                                        </ul>


                                        <div class="dropdown-list d-block d-md-none">
                                            <span (click)="isFieldListShow = !isFieldListShow;"> Add Fields <img
                                                    class="img" src="assets/images/caret-down-dark.svg"></span>
                                            <ul *ngIf="isFieldListShow">
                                                <li *ngFor="let item of fieldModels" (click)="appendField(item,dataModel.multiStep[currentIndex].attributes,0,'copy'); isFieldListShow = false" [class.disabled]="item.type === 'file' && fileAddCount >= maxfileAdd">
                                                    <span><img src="assets/images/{{item.icon}}"
                                                            alt="{{item.label}}">{{item.label}}</span>
                                                </li>
                                                <li class="col-6" (click)="addSteps()">
                                                    <span><img src="assets/images/plus.svg" />Add Steps</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </tab>
                                <tab heading="Style">
                                    <div class="custom-styles">
                                        <h3>Form Background</h3>
                                        <div class="form-group">
                                            <label>Background Image</label>
                                            <div class="upload-button">
                                                <button (click)="openGalleryModal('bg')" type="button">
                                                    <span
                                                        *ngIf="dataModel.multiStep[currentIndex].theme.backgroundImage != ''; else elsebgImage">Change
                                                        Image</span>
                                                    <ng-template #elsebgImage>Select Image</ng-template>
                                                </button>
                                                <a href="javascript:void(0)" *ngIf="dataModel.multiStep[currentIndex].theme.backgroundImage != ''" (click)="dataModel.multiStep[currentIndex].theme.backgroundImage = '' "><img src="assets/images/form-trash-alt.svg" class="width_12" alt=""></a>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label>Background Color</label>
                                            <div class="color-box" [cpPosition]="'bottom'" [(colorPicker)]="dataModel.multiStep[currentIndex].theme.bgColor" [style.background]="dataModel.multiStep[currentIndex].theme.bgColor">
                                            </div>
                                        </div>
                                        <h3>Form Text</h3>
                                        <div class="form-group">
                                            <label>Text Color</label>
                                            <div class="color-box" [cpPosition]="'bottom'" [(colorPicker)]="dataModel.multiStep[currentIndex].theme.textColor" [style.background]="dataModel.multiStep[currentIndex].theme.textColor">
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label>Font Family</label>
                                            <select class="form-control" [(ngModel)]="dataModel.multiStep[currentIndex].theme.fontFamily" [ngModelOptions]="{standalone: true}">
                                                <option value="">Select</option>
                                                <option value="Roboto">Roboto</option>
                                                <option value="Open Sans">Open Sans</option>
                                                <option value="Lato">Lato</option>
                                                <option value="Roboto Condensed">Roboto Condensed</option>
                                                <option value="Source Sans Pro">Source Sans Pro</option>
                                                <option value="Oswald">Oswald</option>
                                                <option value="Raleway">Raleway</option>
                                                <option value="PT Sans">PT Sans</option>
                                                <option value="PT Serif">PT Serif</option>
                                            </select>
                                        </div>
                                        <div class="form-group">
                                            <label>Default Font Size</label>
                                            <select class="form-control" [(ngModel)]="dataModel.multiStep[currentIndex].theme.fontSize">
                                                <option value="12px">12px</option>
                                                <option value="13px">13px</option>
                                                <option value="14px">14px</option>
                                                <option value="15px">15px</option>
                                                <option value="16px">16px</option>
                                                <option value="18px">18px</option>
                                                <option value="20px">20px</option>
                                            </select>
                                        </div>
                                        <h3>Form Fields</h3>
                                        <div class="form-group">
                                            <label>Fields Border Radius</label>
                                            <div class="input-group">
                                                <input trim="blur" class="form-control" type="number" max="100" min="0" [(ngModel)]="dataModel.multiStep[currentIndex].theme.borderRadius" placeholder="Border Radius" />
                                                <div class="input-group-append"><span class="input-group-text">px</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label>Fields Border Color</label>
                                            <div class="color-box" [cpPosition]="'bottom'" [(colorPicker)]="dataModel.multiStep[currentIndex].theme.borderColor" [style.background]="dataModel.multiStep[currentIndex].theme.borderColor">
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label>Fields Background Color</label>
                                            <div class="color-box" [cpPosition]="'bottom'" [(colorPicker)]="dataModel.multiStep[currentIndex].theme.fieldBgColor" [style.background]="dataModel.multiStep[currentIndex].theme.fieldBgColor">
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label>Fields Text Color</label>
                                            <div class="color-box" [cpPosition]="'bottom'" [(colorPicker)]="dataModel.multiStep[currentIndex].theme.fieldTextColor" [style.background]="dataModel.multiStep[currentIndex].theme.fieldTextColor">
                                            </div>
                                        </div>




                                        <label>Form Description</label>
                                        <app-ckeditor [(ngModel)]="dataModel.multiStep[currentIndex].description" [config]="config" [editorData]="ckeditorData"></app-ckeditor>

                                    </div>
                                </tab>

                                <tab heading="Advance">
                                    <div class="custom-styles">
                                        <h3>This setting is across all steps(in case multi step forms)</h3>
                                        <label class="mt-3">Form success message after submission</label>
                                        <textarea trim="blur" class="form-control" [(ngModel)]="dataModel.successMessage" [ngClass]="{'invalid':(dataModel.successMessage == '' && isSubmit)}"></textarea>
                                        <label class="mt-3">Form Resubmission Time</label>
                                        <select class="form-control" [(ngModel)]="dataModel.expireTime" [ngModelOptions]="{standalone: true}" placeholder="Expire Time">
                                            <option value="">Select</option>
                                            <option value="0">Always Allow</option>
                                            <option value="3600">1 Hours</option>
                                            <option value="7200">2 Hours</option>
                                            <option value="18000">5 Hours</option>
                                            <option value="36000">10 Hours</option>
                                            <option value="86400">24 Hours</option>
                                            <option value="864000">Never Allow</option>
                                        </select>
                                        <label class="mt-3">Form submission override logic</label>
                                        <select class="form-control" [(ngModel)]="dataModel.formHook" [ngModelOptions]="{standalone: true}" placeholder="Form Submission override logic">
                                            <option value=0>Don't override records for same audience(email/phone)</option>
                                            <option value=1>Override records with same audience(email/phone)</option>
                                           
                                        </select>
                                        <label class="mt-3">Captcha Preference</label>
                                        <select class="form-control" [(ngModel)]="dataModel.captcha" [ngModelOptions]="{standalone: true}" placeholder="Form Submission override logic">
                                            <option value=0>Google Captcha</option>
                                            <option value=1>Image Captcha</option>
                                           
                                        </select>

                                        <label class="mt-3">Do you want to use it as add audience?</label>
                                        <select class="form-control" [(ngModel)]="dataModel.isPublic" [ngModelOptions]="{standalone: true}" placeholder="Do you want to use this as add audience.">
                                            <option value=1>No</option>
                                            <option value=0>Yes</option>
                                        </select>


                                        <label class="mt-3">Valid From/To</label>
                                        <div class="form-row">
                                            <div class="col-6 single-date mb-2">
                                                <input type="text" placeholder="From Date" placement="top left"  (bsValueChange)="dataModel.endDate = null;" class="form-control date-icon modal-space" [minDate]="isUpdate ? null : minDate" [(ngModel)]="dataModel.startDate"
                                                    bsDatepicker autocomplete="off" [bsConfig]="global.datePickerConfig" [ngModelOptions]="{standalone: true}">
                                            </div>
                                            <div class="col-6 mb-2">
                                                <div class="timepicker-position" >
                                                    <timepicker [showMeridian]="false"  [(ngModel)]="dataModel.startDateTime"  [mousewheel]="false"></timepicker>
                                                </div>
                                            </div>
                                            <div class="col-6 single-date">
                                                <input type="text" placeholder="To Date" placement="top left" readonly class="form-control date-icon modal-space" [(ngModel)]="dataModel.endDate" [minDate]="dataModel.startDate" bsDatepicker autocomplete="off" [bsConfig]="global.datePickerConfig" [ngModelOptions]="{standalone: true}">
                                            </div>
                                            <div class="col-6">
                                                <div class="timepicker-position">
                                                    <timepicker [showMeridian]="false"  [(ngModel)]="dataModel.endDateTime" [mousewheel]="false"></timepicker>
                                                </div>
                                            </div>


                                            <label class="mt-3">After Successfull Submission Redirect To -</label>
                                            <input type="text" class="form-control" [(ngModel)]="dataModel.redirectUrl" />
                                            <div class="error-txt" *ngIf="urlError">Please provide proper URL or URI</div>



                                            <ng-container *ngIf="dataModel.multiStep.length>1">


                                                <label class="mt-3">Step Name</label>
                                                <input type="text" class="form-control" [(ngModel)]="dataModel.multiStep[currentIndex].stepName" />



                                                <label class="mt-3">Steps UI</label>
                                                <select class="form-control" [(ngModel)]="dataModel.stepUI" [ngModelOptions]="{standalone: true}" placeholder="Select Wizard theme">
                                                    <option value="wizard">Wizard</option>
                                                    <option value="wizardVertical">Wizard vertical</option>
                                                    <option value="steps">Steps</option>
                                                    <option value="hide">Don't Show</option>
                                                </select>

                                                <label class="mt-3">Send data on every Step</label>
                                                <select class="form-control" [(ngModel)]="dataModel.sendData" [ngModelOptions]="{standalone: true}">
                                                    <option value="yes" [attr.selected]="dataModel.sendData != 0">Yes</option>
                                                    <option value="no" [attr.selected]="dataModel.sendData == 0">No, we need data on completion</option>
                                                </select>

                                            </ng-container>


                                            <div class="custom-styles width_100" *ngIf="dataModel.multiStep.length > 1 &&  dataModel.stepUI != 'hide'">
                                                <div class="form-group">
                                                    <label>Steps Active Color</label>
                                                    <div class="color-box" [cpPosition]="'bottom'" [(colorPicker)]="dataModel.stepsActiveColor" [style.background]="dataModel.stepsActiveColor">
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <label>Steps Default Color</label>
                                                    <div class="color-box" [cpPosition]="'bottom'" [(colorPicker)]="dataModel.stepsDefaultColor" [style.background]="dataModel.stepsDefaultColor">
                                                    </div>
                                                </div>

                                            </div>


                                            <div class="custom-styles width_100" *ngIf="dataModel.stepUI != 'hide'">

                                                <div class="form-group">
                                                    <label>Background Image</label>
                                                    <div class="upload-button">
                                                        <button (click)="openGalleryModal('bodybg')" type="button">
                                                            <span
                                                                *ngIf="dataModel.bodybackgroundImage != ''; else elsebgImage">Change
                                                                Image</span>
                                                            <ng-template #elsebgImage>Select Image</ng-template>
                                                        </button>
                                                        <a href="javascript:void(0)" *ngIf="dataModel.bodybackgroundImage != ''" (click)="dataModel.bodybackgroundImage = '' "><img src="assets/images/form-trash-alt.svg" class="width_12" alt=""></a>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <label>Background Colour</label>
                                                    <div class="color-box" [cpPosition]="'bottom'" [(colorPicker)]="dataModel.bodyBackground" [style.background]="dataModel.bodyBackground">
                                                    </div>
                                                </div>

                                                <div class="form-group">
                                                    <label>Banner Image</label>
                                                    <div class="upload-button">
                                                        <!-- <button (click)="openGalleryModal('banner')" type="button"> -->
                                                        <button (click)="openGalleryModal('AllInOneBanner')" type="button">
                                                            <span
                                                                *ngIf="dataModel.allInOneBannerImage != ''; else elseBannerImage">Change
                                                                Image</span>
                                                            <ng-template #elseBannerImage> Select Image</ng-template>
                                                        </button>
                                                        <a href="javascript:void(0)" *ngIf="dataModel.allInOneBannerImage != ''" (click)="tracedBanner(); dataModel.allInOneBannerImage = '' "><img src="assets/images/form-trash-alt.svg" class="width_12" alt=""></a>
                                                    </div>
                                                </div>

                                                <!-- <div class="form-group">
                                                    <label>Banner Size</label>
                                                    <div class="upload-button">   
                                                        <button type="button" (click)="resizeBannerImg()" id="resizeBtn">
                                                            Resize
                                                        </button>
                                                        <button type="button" (click)="saveBannerImg()" id="saveResizeBtn">
                                                            Save
                                                        </button>
                                                    </div>
                                                </div> -->

                                                <div class="form-group">
                                                    <label>Banner Alignment</label>
                                                    <div class="iconsRightSide">
                                                        <span title="Left" class="alignIcon" (click)="bannerAlignAction('start')"><img src="assets/images/align-left.png"></span>
                                                        <span title="Center" class="alignIcon" (click)="bannerAlignAction('center')"><img src="assets/images/align-center.png"></span>
                                                        <span title="Right" class="alignIcon" (click)="bannerAlignAction('end')"><img src="assets/images/align-right.png"></span>
                                                    </div>
                                                </div>

                                            </div>


                                            <label class="mt-3">Custom CSS</label>
                                            <textarea trim="blur" class="form-control" [(ngModel)]="dataModel.userStyle" rows="6"></textarea>


                                            <label class="mt-3">Custom Javascript</label>
                                            <p>Important! - script tags must be there" </p>
                                            <textarea trim="blur" class="form-control" [(ngModel)]="dataModel.userJavascript" rows="6"></textarea>





                                        </div>
                                    </div>
                                </tab>
                            </tabset>
                        </div>
                        <div class="text-right mt-2 d-none d-md-block">
                            <button class="btn btn-dark" type="submit" (click)="formDeleteConfirm()" *ngIf="isUpdate">Delete</button> &nbsp;
                            <button class="btn btn-dark" type="submit" (click)="updateForm()">Save</button>
                        </div>
                    </div>
                    <div class="col-md-8" [ngClass]="dataModel.stepUI">
                        <div class="bordered_dashed">
                            <div class="formbuilder-block" [style.background-image]="dataModel.multiStep[currentIndex].theme.backgroundImage != '' ? 'url('+dataModel.multiStep[currentIndex].theme.backgroundImage+')' : ''" [ngStyle]="{'fontFamily':dataModel.multiStep[currentIndex].theme.fontFamily,'color':dataModel.multiStep[currentIndex].theme.textColor,'backgroundColor':dataModel.multiStep[currentIndex].theme.bgColor, 'fontSize':dataModel.multiStep[currentIndex].theme.fontSize}">

                                <div class="arrow">
                                    <ul class="nav nav-tabs" *ngIf="dataModel.multiStep.length>1 &&  dataModel.stepUI != 'hide'">
                                        <!---->
                                        <li *ngFor="let step of dataModel.multiStep; let valueIndexi=index;" class="nav-item ">
                                            <a class="nav-link " (click)="currentIndex=valueIndexi;" [ngClass]="{'active': valueIndexi == currentIndex}" id="" [ngStyle]="{'backgroundColor': (valueIndexi == currentIndex) ? dataModel.stepsActiveColor : dataModel.stepsDefaultColor }">

                                                <span class="before" [ngStyle]="{'borderBottomColor': (valueIndexi == currentIndex - 1) ? dataModel.stepsActiveColor : dataModel.stepsDefaultColor , 'borderTopColor': (valueIndexi == currentIndex - 1) ? dataModel.stepsActiveColor : dataModel.stepsDefaultColor }"></span>
                                                <span *ngIf="step.stepName != ''" class="stepNameBottom">
                                                <span *ngIf="step.stepName.length <= 10 ">{{step.stepName}}</span>
                                                <span *ngIf="step.stepName.length > 10 " class="stepNameBottom">{{step.stepName | slice:0:10}}...</span>
                                                </span>
                                                <!---->
                                                <!-- <span *ngIf="step.stepName == ''">{{valueIndexi + 1}}</span> -->
                                                <!---->
                                                <span [ngClass]="{stepActivate:step.stepName}" class="stepNumber">{{valueIndexi + 1}}</span>

                                                <span class="after" [ngStyle]="{'borderLeftColor': (valueIndexi == currentIndex) ? dataModel.stepsActiveColor : dataModel.stepsDefaultColor }"></span>
                                            </a>
                                            <a class="deleteicon wizardDelete" *ngIf="dataModel.multiStep.length>1 && valueIndexi != 0" (click)="currentIndex=valueIndexi; openDeleteStepsConfirmation()"><img src="/assets/images/delete.png"></a>

                                            <a class="deleteicon stepDelete" *ngIf="dataModel.multiStep.length>1 && valueIndexi != 0" (click)="currentIndex=valueIndexi; openDeleteStepsConfirmation()"><img src="/assets/images/cross.png"></a>
                                        </li>
                                    </ul>
                                </div>


                                <section dndDropzone (dndDrop)="onDrop($event,dataModel.multiStep[currentIndex].attributes)" class="drop-area" [ngClass]="{'empty':dataModel.multiStep[currentIndex].attributes.length == 1}">

                                    <div class="top" #formbuilderTop>
                                        <!-- <h2>{{dataModel.name}}</h2> -->

                                        

                                        <!-- <div [style.justify-content]="this.dataModel.bannerAlign" class="preview-image"
                                        *ngIf="dataModel.multiStep[currentIndex].theme.bannerImage != null && dataModel.multiStep[currentIndex].theme.bannerImage != ''"> -->
                                        <!-- <img [src]="dataModel.multiStep[currentIndex].theme.bannerImage" id="mainBannerImg" [style.height]="this.dataModel.bannerHeight" [style.width]="this.dataModel.bannerWidth">
                                        <div class="innerOfBanner" id="innerOfBanner" [ngStyle]="{'background-image': 'url(' + dataModel.multiStep[currentIndex].theme.bannerImage + ')'}" [style.height]="this.dataModel.bannerHeight" [style.width]="this.dataModel.bannerWidth"></div> -->
                                        <div [style.justify-content]="this.dataModel.bannerAlign" class="preview-image" *ngIf="dataModel.allInOneBannerImage != ''">
                                            <div class="bannerResizer" #bannerImage [style.height]="dataModel.bannerHeight" [style.width]="dataModel.bannerWidth">
                                                <img class="img-responsive mainBannerImage" [src]="dataModel.allInOneBannerImage" alt="" />
                                            </div>

                                        </div>

                                        <div class="p" *ngIf="dataModel.multiStep[currentIndex].description != ''" [innerHtml]="dataModel.multiStep[currentIndex].description | safeHtml">
                                        </div>
                                    </div>

                                    <div class="field {{item.type}}" *ngFor="let item of dataModel.multiStep[currentIndex].attributes;let i= index;" (dndMoved)="onDragged(item, dataModel.multiStep[currentIndex].attributes, 'move')" [dndEffectAllowed]="'all'" [dndDraggable]="item" [ngClass]="{'show-setting':item.toggle}">
                                        <div class="form-group mb-3">
                                            <i class="dnd-handle" dndHandle></i>
                                            <div class="control">
                                                <i (click)="removeField(item, i)"><img
                                                    src="assets/images/form-trash-alt.svg" class="width_12" alt=""></i>
                                                <i *ngIf="item.toggle" (click)="item.toggle=false"><img
                                                    src="assets/images/form-cog.svg" alt=""></i>
                                                <i *ngIf="!item.toggle" (click)="item.toggle=true"><img
                                                    src="assets/images/form-cog.svg" alt=""></i>
                                            </div>
                                            <ng-container  *ngIf="item.type=='text' && item.fieldType=='pincode'">
                                            <div>
                                                <label [ngStyle]="{'fontSize':dataModel.multiStep[currentIndex].theme.fontSize}">{{item.label}}<span *ngIf="item.required"
                                                    class="text-danger">*</span></label>
                                                <div class="info" *ngIf="item.hint != '' && item.hint != undefined">
                                                    {{item.hint}}</div>
                                                    <div class="related_parent_box">
                                                <input type="text" class="form-control" id="{{item.name}}" data-pincode="pincodeInput" name="{{item.ngName}}" placeholder="{{item.placeholder}}" [ngStyle]="{'borderRadius':dataModel.multiStep[currentIndex].theme.borderRadius+'px','border-color':dataModel.multiStep[currentIndex].theme.borderColor,'background-color':dataModel.multiStep[currentIndex].theme.fieldBgColor,'color':dataModel.multiStep[currentIndex].theme.fieldTextColor}">
                                                
                                            </div>
                                            </div>
                                            <div>
                                                <label [ngStyle]="{'fontSize':dataModel.multiStep[currentIndex].theme.fontSize}">City<span *ngIf="item.required"
                                                    class="text-danger">*</span></label>
                                                <div class="info" *ngIf="item.hint != '' && item.hint != undefined">
                                                    {{item.hint}}</div>
                                                <input type="text" class="form-control" id="city" name="city" placeholder="Enter your city name" [ngStyle]="{'borderRadius':dataModel.multiStep[currentIndex].theme.borderRadius+'px','border-color':dataModel.multiStep[currentIndex].theme.borderColor,'background-color':dataModel.multiStep[currentIndex].theme.fieldBgColor,'color':dataModel.multiStep[currentIndex].theme.fieldTextColor}">
                                            </div>
                                            <div>
                                                <label [ngStyle]="{'fontSize':dataModel.multiStep[currentIndex].theme.fontSize}">State<span *ngIf="item.required"
                                                    class="text-danger">*</span></label>
                                                <div class="info" *ngIf="item.hint != '' && item.hint != undefined">
                                                    {{item.hint}}</div>
                                                <input type="text" class="form-control" id="state" name="state" placeholder="Enter your state name" [ngStyle]="{'borderRadius':dataModel.multiStep[currentIndex].theme.borderRadius+'px','border-color':dataModel.multiStep[currentIndex].theme.borderColor,'background-color':dataModel.multiStep[currentIndex].theme.fieldBgColor,'color':dataModel.multiStep[currentIndex].theme.fieldTextColor}">
                                            </div>
                                            <div>
                                                <label [ngStyle]="{'fontSize':dataModel.multiStep[currentIndex].theme.fontSize}">Country<span *ngIf="item.required"
                                                    class="text-danger">*</span></label>
                                                <div class="info" *ngIf="item.hint != '' && item.hint != undefined">
                                                    {{item.hint}}</div>
                                                <input type="text" class="form-control" id="country" name="country" placeholder="Enter your country name" [ngStyle]="{'borderRadius':dataModel.multiStep[currentIndex].theme.borderRadius+'px','border-color':dataModel.multiStep[currentIndex].theme.borderColor,'background-color':dataModel.multiStep[currentIndex].theme.fieldBgColor,'color':dataModel.multiStep[currentIndex].theme.fieldTextColor}">
                                            </div>

                                            <div>
                                                <label [ngStyle]="{'fontSize':dataModel.multiStep[currentIndex].theme.fontSize}">Locality<span *ngIf="item.required"
                                                    class="text-danger">*</span></label>
                                                <div class="info" *ngIf="item.hint != '' && item.hint != undefined">
                                                    {{item.hint}}</div>
                                                <select class="form-control" id="locality" name="locality" [ngStyle]="{'borderRadius':dataModel.multiStep[currentIndex].theme.borderRadius+'px','border-color':dataModel.multiStep[currentIndex].theme.borderColor,'background-color':dataModel.multiStep[currentIndex].theme.fieldBgColor,'color':dataModel.multiStep[currentIndex].theme.fieldTextColor}">
                                                    
                                                </select>
                                            </div>


                                            </ng-container>
                                            <ng-container  *ngIf="item.type=='text' && item.fieldType!='pincode'">
                                            <div>
                                                <label [ngStyle]="{'fontSize':dataModel.multiStep[currentIndex].theme.fontSize}">{{item.label}}<span *ngIf="item.required"
                                                    class="text-danger">*</span></label>
                                                <div class="info" *ngIf="item.hint != '' && item.hint != undefined">
                                                    {{item.hint}}</div>
                                                <input type="text" class="form-control" id="{{item.name}}" name="{{item.ngName}}" placeholder="{{item.placeholder}}" [ngStyle]="{'borderRadius':dataModel.multiStep[currentIndex].theme.borderRadius+'px','border-color':dataModel.multiStep[currentIndex].theme.borderColor,'background-color':dataModel.multiStep[currentIndex].theme.fieldBgColor,'color':dataModel.multiStep[currentIndex].theme.fieldTextColor}">
                                            </div>
                                            </ng-container>
                                            <div *ngIf="item.type=='location'">
                                                <label [ngStyle]="{'fontSize':dataModel.multiStep[currentIndex].theme.fontSize}">{{item.label}}<span *ngIf="item.required"
                                                    class="text-danger">*</span></label>
                                                <div class="info" *ngIf="item.hint != '' && item.hint != undefined">
                                                    {{item.hint}}</div>
                                                <input type="button" class="locatorBtn btn" id="{{item.name}}" name="{{item.ngName}}" value="Get my location" [ngStyle]="{'borderRadius':dataModel.multiStep[currentIndex].theme.borderRadius+'px','border-color':dataModel.multiStep[currentIndex].theme.borderColor,'background-color':dataModel.multiStep[currentIndex].theme.fieldBgColor,'color':dataModel.multiStep[currentIndex].theme.fieldTextColor}">
                                            </div>
                                            <div *ngIf="item.type=='link'">
                                                <label [ngStyle]="{'fontSize':dataModel.multiStep[currentIndex].theme.fontSize}">{{item.label}}<span *ngIf="item.required"
                                                    class="text-danger">*</span></label>
                                                <div class="info" *ngIf="item.hint != '' && item.hint != undefined">
                                                    {{item.hint}}</div>
                                                <div class="linkTextAdmin" [style.color]="dataModel.linkTextColor">{{item.linkText}}</div>

                                            </div>
                                            <div *ngIf="item.type=='email'">
                                                <label [ngStyle]="{'fontSize':dataModel.multiStep[currentIndex].theme.fontSize}">{{item.label}}<span *ngIf="item.required"
                                                    class="text-danger">*</span></label>
                                                <div class="info" *ngIf="item.hint != '' && item.hint != undefined">
                                                    {{item.hint}}</div>
                                                <input type="email" class="form-control" id="{{item.name}}" name="{{item.ngName}}" placeholder="{{item.placeholder}}" [ngStyle]="{'borderRadius':dataModel.multiStep[currentIndex].theme.borderRadius+'px','border-color':dataModel.multiStep[currentIndex].theme.borderColor,'background-color':dataModel.multiStep[currentIndex].theme.fieldBgColor,'color':dataModel.multiStep[currentIndex].theme.fieldTextColor}">
                                            </div>
                                            <div *ngIf="item.type=='phone'">
                                                <label [ngStyle]="{'fontSize':dataModel.multiStep[currentIndex].theme.fontSize}">{{item.label}}<span *ngIf="item.required"
                                                    class="text-danger">*</span></label>
                                                <div class="info" *ngIf="item.hint != '' && item.hint != undefined">
                                                    {{item.hint}}</div>
                                                <input type="text" class="form-control" id="{{item.name}}" name="{{item.ngName}}" placeholder="{{item.placeholder}}" [ngStyle]="{'borderRadius':dataModel.multiStep[currentIndex].theme.borderRadius+'px','border-color':dataModel.multiStep[currentIndex].theme.borderColor,'background-color':dataModel.multiStep[currentIndex].theme.fieldBgColor,'color':dataModel.multiStep[currentIndex].theme.fieldTextColor}">
                                            </div>
                                            <div *ngIf="item.type=='number'">
                                                <label [ngStyle]="{'fontSize':dataModel.multiStep[currentIndex].theme.fontSize}">{{item.label}}<span *ngIf="item.required"
                                                    class="text-danger">*</span></label>
                                                <div class="info" *ngIf="item.hint != '' && item.hint != undefined">
                                                    {{item.hint}}</div>
                                                <input type="number" class="form-control" id="{{item.name}}" name="{{item.ngName}}" min="{{item.min}}" max="{{item.max}}" placeholder="{{item.placeholder}}" [ngStyle]="{'borderRadius':dataModel.multiStep[currentIndex].theme.borderRadius+'px','border-color':dataModel.multiStep[currentIndex].theme.borderColor,'background-color':dataModel.multiStep[currentIndex].theme.fieldBgColor,'color':dataModel.multiStep[currentIndex].theme.fieldTextColor}">
                                            </div>
                                            <div *ngIf="item.type=='date'">
                                                <label [ngStyle]="{'fontSize':dataModel.multiStep[currentIndex].theme.fontSize}">{{item.label}}<span *ngIf="item.required"
                                                    class="text-danger">*</span></label>
                                                <div class="info" *ngIf="item.hint != '' && item.hint != undefined">
                                                    {{item.hint}}</div>
                                                <input type="date" class="form-control" id="{{item.name}}" name="{{item.ngName}}" placeholder="{{item.placeholder}}" [ngStyle]="{'borderRadius':dataModel.multiStep[currentIndex].theme.borderRadius+'px','border-color':dataModel.multiStep[currentIndex].theme.borderColor,'background-color':dataModel.multiStep[currentIndex].theme.fieldBgColor,'color':dataModel.multiStep[currentIndex].theme.fieldTextColor}">
                                            </div>
                                            <div *ngIf="item.type=='file'">
                                                <label [ngStyle]="{'fontSize':dataModel.multiStep[currentIndex].theme.fontSize}">{{item.label}}<span *ngIf="item.required"
                                                    class="text-danger">*</span></label>
                                                <div class="info" *ngIf="item.hint != '' && item.hint != undefined">
                                                    {{item.hint}}</div>
                                                <div class="custom-file form-control" [ngStyle]="{'borderRadius':dataModel.multiStep[currentIndex].theme.borderRadius+'px','border-color':dataModel.multiStep[currentIndex].theme.borderColor,'background-color':dataModel.multiStep[currentIndex].theme.fieldBgColor,'color':dataModel.multiStep[currentIndex].theme.textColor,'fontSize':dataModel.multiStep[currentIndex].theme.fontSize}">
                                                    Choose file
                                                    <input type="file" class="custom-file-input" id="{{item.name}}" name="{{item.ngName}}" disabled>
                                                    <span class="right-label" [ngStyle]="{'color':item.textColor,'background-color':item.bgColor}">Browse</span>
                                                </div>
                                                <!-- <input type="file" class="form-control" id="{{item.name}}" name="{{item.ngName}}"  [ngStyle]="{'borderRadius':dataModel.multiStep[currentIndex].theme.borderRadius+'px','border-color':dataModel.multiStep[currentIndex].theme.borderColor,'background-color':dataModel.multiStep[currentIndex].theme.fieldBgColor}"> -->
                                            </div>
                                            <div *ngIf="item.type=='textarea'">
                                                <label [ngStyle]="{'fontSize':dataModel.multiStep[currentIndex].theme.fontSize}">{{item.label}}<span *ngIf="item.required"
                                                    class="text-danger">*</span></label>
                                                <div class="info" *ngIf="item.hint != '' && item.hint != undefined">
                                                    {{item.hint}}</div>
                                                <textarea class="form-control" id="{{item.name}}" name="{{item.ngName}}" placeholder="{{item.placeholder}}" [ngStyle]="{'borderRadius':dataModel.multiStep[currentIndex].theme.borderRadius+'px','border-color':dataModel.multiStep[currentIndex].theme.borderColor,'background-color':dataModel.multiStep[currentIndex].theme.fieldBgColor,'color':dataModel.multiStep[currentIndex].theme.fieldTextColor}"></textarea>
                                            </div>
                                            <div *ngIf="item.type=='autocomplete'">
                                                <label [ngStyle]="{'fontSize':dataModel.multiStep[currentIndex].theme.fontSize}">{{item.label}}<span *ngIf="item.required"
                                                    class="text-danger">*</span></label>
                                                <div class="info" *ngIf="item.hint != '' && item.hint != undefined">
                                                    {{item.hint}}</div>
                                                <select class="form-control" id="{{item.name}}" name="{{item.ngName}}" [ngStyle]="{'borderRadius':dataModel.multiStep[currentIndex].theme.borderRadius+'px','border-color':dataModel.multiStep[currentIndex].theme.borderColor,'background-color':dataModel.multiStep[currentIndex].theme.fieldBgColor,'color':dataModel.multiStep[currentIndex].theme.fieldTextColor}">
                                                <option>{{item.placeholder}}</option>
                                                <option *ngFor="let v of item.values" [value]="v.value">{{v.label}}
                                                </option>
                                            </select>
                                                <!-- <small class="form-text text-muted">{{item.description}}</small> -->
                                            </div>
                                            <div *ngIf="item.type=='multiselect'">
                                                <label [ngStyle]="{'fontSize':dataModel.multiStep[currentIndex].theme.fontSize}">{{item.label}}<span *ngIf="item.required"
                                                    class="text-danger">*</span></label>
                                                <div class="info" *ngIf="item.hint != '' && item.hint != undefined">
                                                    {{item.hint}}</div>
                                                <!-- <select class="form-control" id="{{item.name}}" name="{{item.ngName}}" [ngStyle]="{'borderRadius':dataModel.multiStep[currentIndex].theme.borderRadius+'px','border-color':dataModel.multiStep[currentIndex].theme.borderColor,'background-color':dataModel.multiStep[currentIndex].theme.fieldBgColor}">
                                                <option>{{item.placeholder}}</option>
                                                <option *ngFor="let v of item.values" [value]="v.value">{{v.label}}
                                                </option>
                                                </select> -->

                                                <ng-select [multiple]="true" class="form-control" id="{{item.name}}" [ngStyle]="{'borderRadius':dataModel.multiStep[currentIndex].theme.borderRadius+'px','border-color':dataModel.multiStep[currentIndex].theme.borderColor,'background-color':dataModel.multiStep[currentIndex].theme.fieldBgColor,'color':dataModel.multiStep[currentIndex].theme.fieldTextColor}" [style.height]="multiselectHeight">
                                                    <ng-option *ngFor="let v of item.values" [value]="v.value">
                                                        {{v.label}}
                                                    </ng-option>
                                                </ng-select>

                                                <!-- <small class="form-text text-muted">{{item.description}}</small> -->
                                            </div>
                                            <div *ngIf="item.type=='checkbox'">
                                                <label [ngStyle]="{'fontSize':dataModel.multiStep[currentIndex].theme.fontSize}">{{item.label}}<span *ngIf="item.required"
                                                    class="text-danger">*</span></label>
                                                <div class="info" *ngIf="item.hint != '' && item.hint != undefined">
                                                    {{item.hint}}</div>
                                                <div *ngFor="let v of item.values" class="inline-form-group">
                                                    <label class="check" [ngStyle]="{'fontSize':dataModel.multiStep[currentIndex].theme.fontSize}">
                                                    {{v.label}}
                                                    <input type="checkbox" [value]="v.value" name="{{item.ngName}}"
                                                        disabled>
                                                    <span class="checkmark"
                                                        [style.borderColor]="dataModel.multiStep[currentIndex].theme.borderColor"></span>
                                                </label>
                                                </div>
                                            </div>
                                            <div *ngIf="item.type=='radio'">
                                                <label [ngStyle]="{'fontSize':dataModel.multiStep[currentIndex].theme.fontSize}">{{item.label}}<span *ngIf="item.required"
                                                    class="text-danger">*</span></label>
                                                <div class="info" *ngIf="item.hint != '' && item.hint != undefined">
                                                    {{item.hint}}</div>
                                                <div *ngFor="let v of item.values" class="inline-form-group">
                                                    <label class="default-radio">
                                                    {{v.label}}
                                                    <input type="radio" [value]="v.value" name="{{item.ngName}}"
                                                        disabled>
                                                    <span class="checkmark"
                                                        [style.borderColor]="dataModel.multiStep[currentIndex].theme.borderColor"></span>
                                                </label>
                                                </div>
                                            </div>
                                            <div *ngIf="item.type=='button'" class="btn-cont" [ngClass]="item.className">
                                                <input type="submit" value="{{item.label}}" [ngClass]="item.btnSize +' btn '+ item.btnWidth" id="{{item.name}}" [ngStyle]="{'borderRadius':dataModel.multiStep[currentIndex].theme.borderRadius+'px','color':item.textColor,'background-color':item.bgColor}">
                                            </div>

                                        </div>
                                        <div *ngIf="item.toggle" class="toggle-wrapper form-row">
                                            <div class="form-group-toggle col-6" *ngIf="item.type!='button' && item.type!='link'">
                                                <label>Required</label>
                                                <div class="radio-toggle">
                                                    <i *ngIf="item.required" (click)="item.required=false"><img
                                                        src="assets/images/form-toggle-on.svg" alt="on"></i>
                                                    <i *ngIf="!item.required" (click)="item.required=true"><img
                                                        src="assets/images/form-toggle-off.svg" alt="off"></i>
                                                </div>




                                            </div>

                                            <div class="form-group-toggle col-6" *ngIf="item.type!='button' && item.type!='link' && item.type != 'multiselect'">
                                                <button class="btn btn-outline-danger" (click)="currentItem=item; openLogicModal('field');">Add Logic</button>
                                            </div>

                                            <div class="form-group-toggle col-6" *ngIf="item.type == 'multiselect'">
                                                &nbsp;
                                            </div>



                                            <div class="form-group-toggle inline-box col-12" *ngIf="item.type=='link'">
                                                <div class="innerFlexWrapper">
                                                    <label>Enter Link Url</label>
                                                    <input class="form-control" placeholder="http://google.com" type="text" [(ngModel)]="item.link" />
                                                </div>
                                                <div class="error-txt text-right" *ngIf="linkError">Please provide proper URL or URI</div>
                                            </div>

                                            <div class="form-group-toggle col-6" *ngIf="item.type=='link'">
                                                <label>Enter Link text</label>
                                                <input class="form-control" type="text" [(ngModel)]="item.linkText" />
                                            </div>

                                            <div class="form-group-toggle col-6">
                                                <label *ngIf="item.type!='link'">Label</label>
                                                <label *ngIf="item.type=='link'">Link Name</label>

                                                <input class="form-control" type="text" [(ngModel)]="item.label" />
                                            </div>



                                            <div class="form-group-toggle col-6" *ngIf="item.type!='button' && item.type!='link' && item.type!='multiselect' && item.type != 'text'">
                                                <label>Mapping</label>
                                                <select class="form-control" [ngClass]="{'invalid':(item.name == '' && isSubmit)}" id="name-{{item.name}}" [(ngModel)]="item.name" [ngModelOptions]="{standalone: true}">
                                                <option value="">Select</option>
                                                <!-- <option *ngFor="let param of inputParams | myFilter:item.subtype" [value]="param.paramKey" [attr.disabled]="isDisable(param.paramKey) ? 'disabled' : null">{{param.paramName}}</option> -->
                                                <ng-container
                                                    *ngFor="let param of mappingInputParams | mappingFilter:item.subtype">
                                                    <ng-container *ngIf="param.paramKey != undefined">
                                                    <option [value]="param.paramKey"
                                                        [attr.disabled]="isDisable(param.paramKey) ? 'disabled' : null">
                                                        {{param.paramName}} <p class="d-inline" *ngIf="(param.paramKey=='city' || param.paramKey=='state' || 
                                                        param.paramKey=='message' || param.paramKey=='country' || param.paramKey=='pincode' || param.paramKey=='city' || param.paramKey=='product_name' ||  param.paramKey=='address') ">(default)</p></option>
                                                    </ng-container>
                                                </ng-container>
                                            </select>
                                            </div>

                                            <div class="form-group-toggle col-6" *ngIf="item.type == 'text'">
                                                <label>Mapping</label>
                                                <select class="form-control" [ngClass]="{'invalid':(item.name == '' && isSubmit)}" id="name-{{item.name}}" [(ngModel)]="item.name" [ngModelOptions]="{standalone: true}" (change)="emailRegexAdd(item,$event)">
                                                <option value="">Select</option>
                                                <!-- <option *ngFor="let param of inputParams | myFilter:item.subtype" [value]="param.paramKey" [attr.disabled]="isDisable(param.paramKey) ? 'disabled' : null">{{param.paramName}}</option> -->
                                                <ng-container
                                                    *ngFor="let param of mappingInputParams | mappingFilter:item.subtype">
                                                    <ng-container *ngIf="param.paramKey != undefined">
                                                    <option [value]="param.paramKey"
                                                        [attr.disabled]="isDisable(param.paramKey) ? 'disabled' : null">
                                                        {{param.paramName}} <p class="d-inline" *ngIf="(param.paramKey=='city' || param.paramKey=='state' || 
                                                        param.paramKey=='message' || param.paramKey=='country' || param.paramKey=='pincode' || param.paramKey=='city' || param.paramKey=='product_name' ||  param.paramKey=='address') ">(default)</p></option>
                                                    </ng-container>
                                                </ng-container>
                                            </select>
                                            </div>

                                            <div class="form-group-toggle col-6" *ngIf="item.type == 'multiselect'">
                                                <label>Mapping</label>
                                                <select class="form-control" [ngClass]="{'invalid':(item.name == '' && isSubmit)}" id="name-{{item.name}}" [(ngModel)]="item.name" [ngModelOptions]="{standalone: true}">
                                                <option value="">Select</option>
                                                <!-- <option *ngFor="let param of inputParams | myFilter:item.subtype" [value]="param.paramKey" [attr.disabled]="isDisable(param.paramKey) ? 'disabled' : null">{{param.paramName}}</option> -->
                                                <ng-container
                                                    *ngFor="let param of mappingInputParams | mappingFilter:item.subtype">
                                                    <ng-container *ngIf="param.paramKey != undefined && param.paramDatatype != 'Numeric'">
                                                    <option [value]="param.paramKey"
                                                        [attr.disabled]="isDisable(param.paramKey) ? 'disabled' : null">
                                                        {{param.paramName}} <p class="d-inline" *ngIf="(param.paramKey=='city' || param.paramKey=='state' || 
                                                        param.paramKey=='message' || param.paramKey=='country' || param.paramKey=='pincode' || param.paramKey=='city' || param.paramKey=='product_name' ||  param.paramKey=='address') ">(default)</p></option>
                                                    </ng-container>
                                                </ng-container>
                                            </select>
                                            </div>
                                            <div class="form-group-toggle col-6" *ngIf="item.type=='file' && item.type!='link'">
                                                <label>Mapping</label>
                                                <select class="form-control"
                                                [ngClass]="{'invalid':(item.name == '' && isSubmit)}"
                                                id="name-{{item.name}}" [(ngModel)]="item.name"
                                                [ngModelOptions]="{standalone: true}">
                                                <option value="">Select</option>
                                                <ng-container
                                                    *ngFor="let param of mappingInputParams | myFilter:item.subtype">
                                                    <option *ngIf="param.paramKey != undefined"
                                                        [value]="param.paramKey"
                                                        [attr.disabled]="isDisable(param.paramKey) ? 'disabled' : null">
                                                        {{param.paramName}}</option>
                                                </ng-container>
                                                </select>
                                            </div>
                                            <div class="form-group-toggle col-6" *ngIf="item.type=='autocomplete'">
                                                <label>Sort By</label>
                                                <select class="form-control" [(ngModel)]="item.sort" [ngModelOptions]="{standalone: true}">
                                                <option value="default">Default</option>
                                                <option value="ascending">Ascending</option>
                                                <option value="descending">Descending</option>
                                            </select>
                                            </div>
                                            <div class="form-group-toggle col-6" *ngIf="item.type=='file'">
                                                <label>File Type</label>
                                                <select class="form-control" id="type-{{item.name}}" [(ngModel)]="item.fileType" [ngModelOptions]="{standalone: true}" (change)="updateFileType(item.fileType, i)">
                                                <option value="all">All</option>
                                                <option value="image">Image</option>
                                                <option value="file">File</option>
                                            </select>
                                            </div>



                                            <div class="form-group-toggle col-6" *ngIf="item.type!='button' && item.type!='link' && item.type!='location' && item.type!='radio' && item.type!='checkbox' && item.type!='file'">
                                                <label>Placeholder</label>
                                                <input class="form-control" type="text" [(ngModel)]="item.placeholder" />
                                            </div>
                                            <div class="form-group-toggle col-6" *ngIf="item.type=='text' || item.type=='email' || item.type=='phone'">
                                                <label>Regex</label>
                                                <input class="form-control" type="text" [(ngModel)]="item.regex" />
                                            </div>
                                            <div *ngIf="item.regex" class="form-group-toggle col-6">
                                                <label>Regex error</label>
                                                <input class="form-control" type="text" [(ngModel)]="item.errorText" placeholder="Please enter Regex error." />
                                            </div>
                                            <div class="form-group-toggle col-6" *ngIf="item.type=='number'">
                                                <label>Range</label>
                                                <div class="form-row">
                                                    <div class="col-6">
                                                        <input class="form-control" type="text" [(ngModel)]="item.min" placeholder="Min" />
                                                    </div>
                                                    <div class="col-6">
                                                        <input class="form-control" type="text" [(ngModel)]="item.max" placeholder="Max" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngIf=" item.type != 'button'" class="form-group-toggle col-6">
                                                <label>Hint</label>
                                                <input class="form-control" type="text" [(ngModel)]="item.hint" maxlength="300" />
                                            </div>



                                            <div class="form-group-toggle col-6" *ngIf="item.parent!=undefined">
                                                <label>Parent
                                                <div class="radio-toggle d-inline">
                                                    <i *ngIf="item.parent" (click)="enableParent(item)"><img
                                                            src="assets/images/form-toggle-on.svg" alt="on"></i>
                                                    <i *ngIf="!item.parent" (click)="enableParent(item)"><img
                                                            src="assets/images/form-toggle-off.svg" alt="off"></i>
                                                </div>
                                            </label>

                                                <select *ngIf="item.parent==true" class="form-control" (change)="selectedParentElement($event.target.value,item)">
                                                <option value="">Select Parent Element</option>
                                                <!-- <option *ngFor="let param of inputParams | myFilter:item.subtype" [value]="param.paramKey" [attr.disabled]="isDisable(param.paramKey) ? 'disabled' : null">{{param.paramName}}</option> -->

                                                <ng-container
                                                    *ngFor="let param of this.dataModel.multiStep ; let stepCount=index ">
                                                    <ng-container
                                                        *ngFor="let attributeParam of this.dataModel.multiStep[stepCount].attributes ">
                                                        <option
                                                            *ngIf="attributeParam.parent !=undefined && attributeParam.label != item.label  && stepCount <= currentIndex"
                                                            [value]="attributeParam.ngName" [selected]="item.parentAttributeName==attributeParam.ngName"  >{{attributeParam.label}}</option>
                                                    </ng-container>
                                                </ng-container>
                                            </select>
                                            </div>
                                            <div *ngIf="item.type=='radio'|| item.type=='checkbox' || item.type=='autocomplete' || item.type=='multiselect'" class="col-12">
                                                <div class="form-row option-list">
                                                    <div class="col-5">
                                                        <div class="form-group">
                                                            <label>Label</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-5">
                                                        <div class="form-group">
                                                            <label>Value</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-2">
                                                        <div class="form-group">
                                                            <label>Action</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div *ngIf="item.parentAttributeValue!=undefined && item.parentAttributeValue.length > 0">
                                                    <div *ngFor="let i of item.parentAttributeValue; let valueIndexi=index;" class="">


                                                        <div class="form-row option-list">
                                                            <div class="col-2 text-center option_label">
                                                                {{i.label}}</div>

                                                            <div class="col-10">
                                                                <div class="form-row ">
                                                                    <div class="col-4">
                                                                        <div class="form-group">
                                                                            <input class="form-control" type="text" #labeldata />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-4">
                                                                        <div class="form-group">
                                                                            <input class="form-control" type="text" #valuedata />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-4">
                                                                        <div class="form-group">
                                                                            <button class="btn btn-outline-success width_100" (click)="addParentValue(i, '',labeldata.value, valuedata.value,item)">Add Options</button>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                            </div>


                                                            <div *ngFor="let j of i.values; let valueIndexj=index;" class="col-12 ">
                                                                <div class=" form-row option-list ">
                                                                    <div class="col-5 parentchild" [ngClass]="{'last':(valueIndexj == i.values.length-1)}">
                                                                        <div class="form-group">
                                                                            <input class="form-control" type="text" [(ngModel)]="j.label" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-5">
                                                                        <div class="form-group">
                                                                            <input class="form-control" type="text" (ngModelChange)="changeOptions($event,item,j.value)" [(ngModel)]="j.value" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-1">
                                                                        <div class="form-group">
                                                                            <button class="btn btn-outline-danger" (click)="i.values.splice(valueIndexj,1);changeOptions('',item,j.value)">clear</button>
                                                                        </div>
                                                                    </div>
                                                                </div>





                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="!item.parent">
                                                    
                                                    <div class="col-12">
                                                        <div class="form-row option-list">
                                                            <div class="col-5">
                                                                <div class="form-group">
                                                                    <input class="form-control" type="text" [(ngModel)]="value[item.valueIndex].label" />
                                                                </div>
                                                            </div>
                                                            <div class="col-5">
                                                                <div class="form-group">
                                                                    <input class="form-control" type="text" [(ngModel)]="value[item.valueIndex].value" />
                                                                </div>
                                                            </div>
                                                            <div class="col-2">
                                                                <div class="form-group">
                                                                    <button class="btn btn-outline-success" (click)="addValue(item.values, item.valueIndex,item)">Add Options</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div *ngFor="let i of item.values; let valueIndex=index;" class="col-12">
                                                        <div class="form-row option-list">
                                                            <div class="col-5">
                                                                <div class="form-group">
                                                                    <input class="form-control" type="text" [(ngModel)]="i.label" />
                                                                </div>
                                                            </div>
                                                            <div class="col-5">
                                                                <div class="form-group">
                                                                    <input class="form-control" type="text" (ngModelChange)="changeOptions($event,item,i.value)" [(ngModel)]="i.value" />
                                                                </div>
                                                            </div>
                                                            <div class="col-2">
                                                                <div class="form-group">
                                                                    <button class="btn btn-outline-danger" (click)="item.values.splice(valueIndex,1);changeOptions('',item,i.value)">remove</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-6 form-group-toggle" *ngIf="item.type=='button'">
                                                <label>Text Color</label>
                                                <a (colorPickerChange)="changeButtonProperty($event,item)" [style.background]="item.textColor" [cpPosition]="'bottom'" class="color-field" [cpPositionRelativeToArrow]="true" [(colorPicker)]="item.textColor"></a>
                                            </div>

                                            <div class="col-6 form-group-toggle" *ngIf="item.type=='button' || item.type=='file'">
                                                <label>Background Color</label>
                                                <a (colorPickerChange)="changeButtonProperty($event,item)" [style.background]="item.bgColor" [cpPosition]="'bottom'" class="color-field" [cpPositionRelativeToArrow]="true" colorPickerChange [(colorPicker)]="item.bgColor"></a>
                                            </div>

                                            <div class="col-6 form-group-toggle" *ngIf="item.type=='link'">
                                                <label>Text Color</label>
                                                <a (colorPickerChange)="changeButtonProperty($event,item)" [style.background]="dataModel.linkTextColor" [cpPosition]="'bottom'" class="color-field" [cpPositionRelativeToArrow]="true" [(colorPicker)]="dataModel.linkTextColor"></a>
                                            </div>

                                            <div class="form-group-toggle col-6" *ngIf="item.type!='button' && item.type=='link'">
                                                <button class="btn btn-outline-danger" (click)="currentItem=item; openLogicModal('field');">Add Logic</button>
                                            </div>


                                            <div class="col-6 form-group-toggle" *ngIf="item.type=='button'">
                                                <label>Hover Color</label>
                                                <a [style.background]="item.bgHoverColor" [cpPosition]="'bottom'" class="color-field" [cpPositionRelativeToArrow]="true" (colorPickerChange)="changeButtonProperty($event,item)" [(colorPicker)]="item.bgHoverColor"></a>
                                            </div>
                                            <div class="col-6 form-group-toggle" *ngIf="item.type=='button'">
                                                <label>Align</label>
                                                <select class="form-control" id="align-{{item.name}}" (ngModelChange)="changeButtonProperty($event,item)" [(ngModel)]="item.className" [ngModelOptions]="{standalone: true}">
                                                <option value="left">Left</option>
                                                <option value="right">Right</option>
                                                <option value="center">Center</option>
                                            </select>
                                            </div>
                                            <div class="col-6 form-group-toggle" *ngIf="item.type=='button'">
                                                <label>Size</label>
                                                <select class="form-control" id="btnSize-{{item.name}}" (ngModelChange)="changeButtonProperty($event,item)" [(ngModel)]="item.btnSize" [ngModelOptions]="{standalone: true}">
                                                <option value="btn-sm">Small</option>
                                                <option value="btn-md">Medium</option>
                                                <option value="btn-lg">Large</option>
                                            </select>
                                            </div>
                                            <div class="col-6 form-group-toggle" *ngIf="item.type=='button'">
                                                <label>Width</label>
                                                <select (ngModelChange)="changeButtonProperty($event,item)" class="form-control" id="btnSize-{{item.name}}" [(ngModel)]="item.btnWidth" [ngModelOptions]="{standalone: true}">
                                                <option value="">Normal</option>
                                                <option value="btn-50">50%</option>
                                                <option value="btn-block">100%</option>
                                            </select>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="dnd-placeholder" dndPlaceholderRef></div>
                                </section>


                            </div>
                            <div class="col-12 mt-3 text-center white_bg">
                                <button class="btn btn-outline-danger" (click)="openLogicModal('form');">Add Step Logic</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 d-md-none d-block mt-3 text-right">
                        <button class="btn btn-dark" type="submit" (click)="formDeleteConfirm()" *ngIf="isUpdate">Delete</button> &nbsp;
                        <button class="btn btn-dark" type="submit" (click)="updateForm()">Save</button>
                    </div>
                </div>
                <div class="preview-block d-none">
                    <div class="top">Preview</div>
                    <div #formOutPut>
                        <div class="output container-fluid {{dataModel.stepUI+'Main'}}">
                            <!-- <h2>{{dataModel.multiStep[currentIndex].name}}</h2> -->
                            <!-- <div class="p" [innerHtml]="dataModel.multiStep[currentIndex].description"></div> -->
                            <div class="onSubmitLoader">
                                <div class="spinner-border" role="status">

                                </div>
                            </div>

                            <div class="arrow" *ngIf="dataModel.multiStep.length>1">
                                <ul class="nav nav-tabs ng-star-inserted">
                                    <ng-container *ngFor="let stepdata of dataModel.multiStep; let indexStepWizard=index;">
                                        <li class="nav-item ng-star-inserted">
                                            <a class="nav-link {{indexStepWizard}}_selected" [ngClass]="{active:indexStepWizard==0}">

                                                <span class="before"></span>
                                                <span *ngIf="stepdata.stepName != ''" class="stepContent stepNameBottom">
                                                    <span *ngIf="stepdata.stepName.length <= 10 ">{{stepdata.stepName}}</span>
                                                <span *ngIf="stepdata.stepName.length > 10 " class="stepNameBottom">{{stepdata.stepName | slice:0:10}}...</span>
                                                </span>
                                                <!---->
                                                <!-- <span *ngIf="stepdata.stepName == ''"
                                                    class="stepContent">{{indexStepWizard + 1}}</span> -->
                                                <span [ngClass]="{stepActivate:stepdata.stepName}" class="stepContent counterContent">{{indexStepWizard + 1}}</span>
                                                <!---->
                                                <span class="after"></span>

                                            </a>
                                        </li>
                                    </ng-container>
                                </ul>
                            </div>
                            <form id="crCustomForm" class="ftheme  " onsubmit="return false;">
                                <ng-container *ngFor="let stepdata of dataModel.multiStep; let indexStep=index;">
                                    <div class="steps step-{{indexStep}} row" id="{{indexStep}}_selected" [ngClass]="dataModel.multiStep.length > 1 ? 'multistepBox':''" [class.enableMode]="indexStep == '0'">
                                        <div class="custom-form-block mx-auto col-md-6 col-sm-12 g-5" [style.background-image]="dataModel.multiStep[indexStep].theme.backgroundImage != '' ? 'url('+dataModel.multiStep[indexStep].theme.backgroundImage+')' : ''" [ngStyle]="{'fontFamily':dataModel.multiStep[indexStep].theme.fontFamily,'color':dataModel.multiStep[indexStep].theme.textColor,'backgroundColor':dataModel.multiStep[indexStep].theme.bgColor, 'fontSize':dataModel.multiStep[indexStep].theme.fontSize}" [style.display]="userFormDisplay" >
                                            <span class="verticalWizardName">{{stepdata.stepName}}</span>
                                            <div class="preview-image" *ngIf="dataModel.allInOneBannerImage != ''" [style.justify-content]="this.dataModel.bannerAlign">
                                                <img [src]="dataModel.allInOneBannerImage" alt="{{stepdata.name}}" [style.height]="dataModel.bannerHeight" [style.width]="dataModel.bannerWidth">
                                            </div>
                                            <div class="p" *ngIf="dataModel.multiStep[indexStep].description != ''" [innerHtml]="dataModel.multiStep[indexStep].description">
                                            </div>


                                            <div *ngFor="let item of stepdata.attributes; let attributeindex=index" class="form-group  mb-3">
                                                <ng-container  *ngIf="item.type=='text' && item.fieldType=='pincode'">
                                                <div id={{item.name}} class="mb-3" attr.data-logic="{{ item.logic | json }}">
                                                    <label for="{{item.ngName}}{{i}}">{{item.label}} <span
                                                            *ngIf="item.required" class="text-danger">*</span></label>
                                                    <div class="info" *ngIf="item.hint != '' && item.hint != undefined">
                                                        {{item.hint}}</div>
                                                        <div class="related_parent_box">
                                                    <input type="text" class="form-control" name="{{item.ngName}}" attr.data-name="{{item.name}}" id="{{item.ngName}}{{i}}" placeholder="{{item.placeholder}}" [(ngModel)]="item.value" attr.data-required="{{item.required}}" attr.data-regex="{{item.regex}}"
                                                        attr.data-regexError="{{item.errorText}}" autocomplete="off" [ngModelOptions]="{standalone: true}">
                                                        <button onclick="submitPostCode()" class="btn pinCodeDetailBtn">Get Details</button>
                                                    </div>
                                                </div>
                                                <div id={{item.name}} class="mb-3" attr.data-logic="{{ item.logic | json }}">
                                                    <label for="city{{i}}">City <span
                                                            *ngIf="item.required" class="text-danger">*</span></label>
                                                    <div class="info" *ngIf="item.hint != '' && item.hint != undefined">
                                                        {{item.hint}}</div>
                                                    <input type="text" class="form-control" name="city" attr.data-name="city" id="city{{i}}" placeholder="Enter your city name" [(ngModel)]="item.value" attr.data-required="{{item.required}}" attr.data-regex="{{item.regex}}"
                                                        attr.data-regexError="{{item.errorText}}" autocomplete="off" [ngModelOptions]="{standalone: true}">

                                                </div>
                                                <div id={{item.name}} class="mb-3" attr.data-logic="{{ item.logic | json }}">
                                                    <label for="state{{i}}">State <span
                                                            *ngIf="item.required" class="text-danger">*</span></label>
                                                    <div class="info" *ngIf="item.hint != '' && item.hint != undefined">
                                                        {{item.hint}}</div>
                                                    <input type="text" class="form-control" name="state" attr.data-name="state" id="state{{i}}" placeholder="Enter your state name" [(ngModel)]="item.value" attr.data-required="{{item.required}}" attr.data-regex="{{item.regex}}"
                                                        attr.data-regexError="{{item.errorText}}" autocomplete="off" [ngModelOptions]="{standalone: true}">

                                                </div>

                                                <div id={{item.name}} class="mb-3" attr.data-logic="{{ item.logic | json }}">
                                                    <label for="country{{i}}">Country <span
                                                            *ngIf="item.required" class="text-danger">*</span></label>
                                                    <div class="info" *ngIf="item.hint != '' && item.hint != undefined">
                                                        {{item.hint}}</div>
                                                    <input type="text" class="form-control" name="country" attr.data-name="country" id="country{{i}}" placeholder="Enter your country name" [(ngModel)]="item.value" attr.data-required="{{item.required}}" attr.data-regex="{{item.regex}}"
                                                        attr.data-regexError="{{item.errorText}}" autocomplete="off" [ngModelOptions]="{standalone: true}">

                                                </div>


                                                <div id={{item.name}}>
                                                    <label [ngStyle]="{'fontSize':dataModel.multiStep[currentIndex].theme.fontSize}">Locality<span *ngIf="item.required"
                                                        class="text-danger">*</span></label>
                                                    <div class="info" *ngIf="item.hint != '' && item.hint != undefined">
                                                        {{item.hint}}</div>
                                                    <select class="form-control" id="locality" name="locality" [ngStyle]="{'borderRadius':dataModel.multiStep[currentIndex].theme.borderRadius+'px','border-color':dataModel.multiStep[currentIndex].theme.borderColor,'background-color':dataModel.multiStep[currentIndex].theme.fieldBgColor,'color':dataModel.multiStep[currentIndex].theme.fieldTextColor}">
                                                        
                                                    </select>
                                                    <input type="text" class="form-control" id="localityTextBox" name="locality" [ngStyle]="{'borderRadius':dataModel.multiStep[currentIndex].theme.borderRadius+'px','border-color':dataModel.multiStep[currentIndex].theme.borderColor,'background-color':dataModel.multiStep[currentIndex].theme.fieldBgColor,'color':dataModel.multiStep[currentIndex].theme.fieldTextColor}">
                                                </div>

                                                </ng-container>

                                                <ng-container  *ngIf="item.type=='text' && item.fieldType!='pincode'">
                                                    <div id={{item.name}} class="mb-3" attr.data-logic="{{ item.logic | json }}">
                                                        <label for="{{item.ngName}}{{i}}">{{item.label}} <span
                                                                *ngIf="item.required" class="text-danger">*</span></label>
                                                        <div class="info" *ngIf="item.hint != '' && item.hint != undefined">
                                                            {{item.hint}}</div>
                                                        <input type="text" class="form-control" name="{{item.ngName}}" attr.data-name="{{item.name}}" id="{{item.ngName}}{{i}}" placeholder="{{item.placeholder}}" [(ngModel)]="item.value" attr.data-required="{{item.required}}" attr.data-regex="{{item.regex}}"
                                                            attr.data-regexError="{{item.errorText}}" autocomplete="off" [ngModelOptions]="{standalone: true}">
    
                                                    </div>
                                                </ng-container>

                                                <div *ngIf="item.type=='location'" id={{item.name}} class="mb-3" attr.data-logic="{{ item.logic | json }}">
                                                    <label for="{{item.ngName}}{{i}}">{{item.label}} <span
                                                            *ngIf="item.required" class="text-danger">*</span></label>
                                                    <div class="info" *ngIf="item.hint != '' && item.hint != undefined">
                                                        {{item.hint}}</div>
                                                    <input type="text" class="form-control" name="{{item.ngName}}" attr.data-name="{{item.name}}" id="locationData" [(ngModel)]="item.value" attr.data-required="{{item.required}}" autocomplete="off" [ngModelOptions]="{standalone: true}">
                                                    <button type="button" class="btn locatorBtn" id="locatioGetBtn">Get my location</button>
                                                    <span class="locateMsg" id="locateMsg"></span>

                                                </div>
                                                <div *ngIf="item.type=='link' && item.linkText != '' && item.link != ''" id={{item.name}} class="mb-3" attr.data-logic="{{ item.logic | json }}">
                                                    <label for="{{item.ngName}}{{i}}">{{item.label}} <span
                                                            *ngIf="item.required" class="text-danger">*</span></label>
                                                    <div class="info" *ngIf="item.hint != '' && item.hint != undefined">
                                                        {{item.hint}}</div>
                                                    <a href="{{item.link}}" class="linkBtn" target="_blank">{{item.linkText}}</a>
                                                </div>
                                                <div *ngIf="item.type=='email'" id={{item.name}} attr.data-logic="{{ item.logic | json }}" class="mb-3">
                                                    <label for="{{item.ngName}}{{i}}">{{item.label}} <span
                                                            *ngIf="item.required" class="text-danger">*</span></label>
                                                    <div class="info" *ngIf="item.hint != '' && item.hint != undefined">
                                                        {{item.hint}}</div>
                                                    <input type="text" class="form-control" name="{{item.ngName}}" attr.data-name="{{item.name}}" id="{{item.ngName}}{{i}}" placeholder="{{item.placeholder}}" [(ngModel)]="item.value" attr.data-required="{{item.required}}" attr.data-regex="{{item.regex}}"
                                                        attr.data-regexError="{{item.errorText}}" autocomplete="off" [ngModelOptions]="{standalone: true}">

                                                </div>
                                                <div *ngIf="item.type=='phone'" id={{item.name}} class="mb-3" attr.data-logic="{{ item.logic | json }}">
                                                    <label for="{{item.ngName}}{{i}}" for="floatingInput">{{item.label}}
                                                        <span *ngIf="item.required" class="text-danger">*</span></label>
                                                    <div class="info" *ngIf="item.hint != '' && item.hint != undefined">
                                                        {{item.hint}}</div>
                                                    <input type="text" class="form-control" name="{{item.ngName}}" attr.data-name="{{item.name}}" id="{{item.ngName}}{{i}}" placeholder="{{item.placeholder}}" [(ngModel)]="item.value" attr.data-required="{{item.required}}" attr.data-regex="{{item.regex}}"
                                                        attr.data-regexError="{{item.errorText}}" autocomplete="off" [ngModelOptions]="{standalone: true}" maxlength="18">

                                                </div>
                                                <div *ngIf="item.type=='number'" id={{item.name}} class=" mb-5" attr.data-logic="{{ item.logic | json }}">
                                                    <label for="{{item.ngName}}{{i}}">{{item.label}} <span
                                                            *ngIf="item.required" class="text-danger">*</span></label>
                                                    <div class="info" *ngIf="item.hint != '' && item.hint != undefined">
                                                        {{item.hint}}</div>
                                                    <input type="number" class="form-control" name="{{item.ngName}}" attr.data-name="{{item.name}}" id="{{item.ngName}}{{i}}" min="{{item.min}}" max="{{item.max}}" attr.data-required="{{item.required}}" placeholder="{{item.placeholder}}" [required]="item.required"
                                                        [(ngModel)]="item.value" autocomplete="off" [ngModelOptions]="{standalone: true}">

                                                </div>
                                                <div *ngIf="item.type=='date'" id={{item.name}} class=" mb-5" attr.data-logic="{{ item.logic | json }}">
                                                    <label for="{{item.name}}{{i}}">{{item.label}} <span
                                                            *ngIf="item.required" class="text-danger">*</span></label>
                                                    <div class="info" *ngIf="item.hint != '' && item.hint != undefined">
                                                        {{item.hint}}</div>
                                                    <input type="date" class="form-control" name="{{item.ngName}}" attr.data-name="{{item.name}}" id="{{item.name}}{{i}}" placeholder="{{item.placeholder}}" [(ngModel)]="item.value" attr.data-required="{{item.required}}" autocomplete="off" [ngModelOptions]="{standalone: true}">

                                                </div>
                                                <div *ngIf="item.type=='file'" id={{item.name}} class="mb-3" attr.data-logic="{{ item.logic | json }}">
                                                    <label>{{item.label}} <span *ngIf="item.required"
                                                            class="text-danger">*</span></label>
                                                    <div class="info" *ngIf="item.hint != '' && item.hint != undefined">
                                                        {{item.hint}}</div>

                                                    <div class="custom-file form-control" [ngStyle]="{'color':item.fieldTextColor,'fontSize':dataModel.multiStep[currentIndex].theme.fontSize}">
                                                        <span class="custom-file-label">Choose file</span>
                                                        <input type="file" *ngIf="item.fileType == 'all'" name="{{item.ngName}}" attr.data-name="{{item.name}}" id="cf{{item.name}}" [(ngModel)]="item.value" attr.data-required="{{item.required}}" autocomplete="off" class="custom-file-input" accept=".xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.pdf,.zip,.csv,image/*"
                                                            [ngModelOptions]="{standalone: true}">
                                                        <input type="file" *ngIf="item.fileType == 'image'" name="{{item.ngName}}" attr.data-name="{{item.name}}" id="cf{{item.name}}" [(ngModel)]="item.value" attr.data-required="{{item.required}}" autocomplete="off" class="custom-file-input" accept="image/*;capture=camera" accept="image/*"
                                                            [ngModelOptions]="{standalone: true}">
                                                        <input type="file" *ngIf="item.fileType == 'file'" name="{{item.ngName}}" attr.data-name="{{item.name}}" id="cf{{item.name}}" [(ngModel)]="item.value" attr.data-required="{{item.required}}" autocomplete="off" class="custom-file-input" accept=".xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.pdf,.zip,.csv"
                                                            [ngModelOptions]="{standalone: true}">
                                                        <span class="right-label" [ngStyle]="{'color':item.textColor,'background-color':item.bgColor}">Browse</span>
                                                    </div>
                                                    <input type="hidden" id="file-{{item.name}}" name="fileParam" value="{{item.name}}">

                                                    <div class="info uploadFileInfo" *ngIf="item.defaultHint != '' && item.defaultHint != undefined">
                                                        {{item.defaultHint}}</div>
                                                </div>
                                                <div *ngIf="item.type=='textarea'" class="mb-3" id={{item.name}} attr.data-logic="{{ item.logic | json }}">
                                                    <label for="{{item.ngName}}{{i}}">{{item.label}} <span
                                                            *ngIf="item.required" class="text-danger">*</span></label>
                                                    <div class="info" *ngIf="item.hint != '' && item.hint != undefined">
                                                        {{item.hint}}</div>
                                                    <textarea class="form-control" name="{{item.ngName}}" attr.data-name="{{item.name}}" id="{{item.ngName}}{{i}}" placeholder="{{item.placeholder}}" [(ngModel)]="item.value" attr.data-required="{{item.required}}" autocomplete="off" [ngModelOptions]="{standalone: true}"></textarea>

                                                </div>
                                                <div *ngIf="item.type=='autocomplete'" class="mb-3" id={{item.name}} attr.data-logic="{{ item.logic | json }}">
                                                    <label>{{item.label}} <span *ngIf="item.required"
                                                            class="text-danger">*</span></label>
                                                    <div class="info" *ngIf="item.hint != '' && item.hint != undefined">
                                                        {{item.hint}}</div>

                                                    <select data-type="select" attr.data-id="{{item.ngName}}" attr.data-parent="{{item.parentAttributeName}}" class="form-select form-control" attr.data-name="{{item.name}}" id="cf{{item.name}}" attr.data-required="{{item.required}}">
                                                        <option value="">{{item.placeholder}}</option>
                                                        <ng-container *ngIf="!item.parent==true">
                                                            <option *ngFor="let v of item.values | sort:item.sort" [value]="v.value">
                                                                {{v.label}}
                                                            </option>
                                                        </ng-container>
                                                    </select>
                                                </div>
                                                <div *ngIf="item.type=='multiselect'" class="mb-3" id={{item.name}} attr.data-logic="{{ item.logic | json }}">
                                                    <label>{{item.label}} <span *ngIf="item.required"
                                                            class="text-danger">*</span></label>
                                                    <div class="info" *ngIf="item.hint != '' && item.hint != undefined">
                                                        {{item.hint}}</div>
                                                    <div class="related_parent_box">
                                                    <select data-type="multiselect" multiple="multiple" attr.data-id="{{item.ngName}}" attr.data-parent="{{item.parentAttributeName}}" class="form-select form-control multiselect_select" attr.data-name="{{item.name}}" id="cf{{item.name}}" attr.data-required="{{item.required}}">
                                                        <ng-container *ngIf="!item.parent==true">
                                                            <option *ngFor="let v of item.values | sort:item.sort" [value]="v.value">
                                                                {{v.label}}
                                                            </option>
                                                        </ng-container>
                                                    </select>
                                                    <span class="arrow_down_multiselect">
                                                        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAb0lEQVR4nO3QKw6DUBRF0TWcDgRTUwOmqq6mqqnCliGiGQfpl5ongPTz4Kkm7OTIs3PuZeF/WaFGMzF16A7I0KKLzAXrT8s2uEZI7ih+nZnj9kXywDb2Z7tQ6EZ5Ym8ihzeik5kce5JSIhXOqRILXiKAO3cw6mRIAAAAAElFTkSuQmCC">                                                     
                                                    </span>
                                                    </div>
                                                </div>
                                                <div *ngIf="item.type=='checkbox'" class="mb-3" id={{item.name}} attr.data-logic="{{ item.logic | json }}">
                                                    <label>{{item.label}} <span *ngIf="item.required"
                                                            class="text-danger">*</span></label>
                                                    <div class="info" *ngIf="item.hint != '' && item.hint != undefined">
                                                        {{item.hint}}</div>

                                                    <ng-container *ngIf="item.parent==true">
                                                        <div class="form-check">
                                                            <input data-type="checkbox" attr.data-parent="{{item.parentAttributeName}}" attr.data-required="{{item.required}}" type="hidden" [required]="item.required" attr.data-name="{{item.name}}" attr.data-id="{{item.ngName}}" [value]=" " class="form-check-input"
                                                                id="{{item.ngName}}">
                                                            <label class="form-check-label float-left" for="{{item.ngName}}"></label>

                                                        </div>
                                                        <div class="newelement"></div>
                                                    </ng-container>
                                                    <ng-container *ngIf="!item.parent==true">
                                                        <div class="form-check" *ngFor="let v of item.values; let i = index">
                                                            <input attr.data-required="{{item.required}}" data-type="checkbox" type="checkbox" [required]="item.required" attr.data-name="{{item.name}}" [value]="v.value" class="form-check-input" attr.data-id="{{item.ngName}}" id="{{item.ngName}}{{i}}">
                                                            <label class="form-check-label float-left" for="{{item.ngName}}{{i}}">{{v.label}}</label>

                                                        </div>
                                                    </ng-container>
                                                </div>
                                                <div *ngIf="item.type=='radio'" class="mb-3" id={{item.name}} attr.data-logic="{{ item.logic | json }}">
                                                    <label>{{item.label}} <span *ngIf="item.required"
                                                            class="text-danger">*</span></label>
                                                    <div class="info" *ngIf="item.hint != '' && item.hint != undefined">
                                                        {{item.hint}}</div>


                                                    <ng-container *ngIf="item.parent==true">
                                                        <div class="form-check">
                                                            <input data-type="radio" attr.data-parent="{{item.parentAttributeName}}" attr.data-required="{{item.required}}" type="hidden" [required]="item.required" attr.data-name="{{item.name}}" [value]=" " class="form-check-input" attr.data-id="{{item.ngName}}"
                                                                id="{{item.ngName}}">
                                                            <label class="form-check-label float-left" for="{{item.ngName}}"></label>

                                                        </div>
                                                        <div class="newelement"></div>
                                                    </ng-container>
                                                    <ng-container *ngIf="!item.parent==true">
                                                        <div class="form-check" *ngFor="let v of item.values; let i = index">
                                                            <input attr.data-required="{{item.required}}" id="{{item.ngName}}{{i}}" [value]="v.value" attr.data-name="{{item.name}}" attr.data-id="{{item.ngName}}" [required]="item.required" data-type="radio" type="radio" class="form-check-input">
                                                            <label class="form-check-label float-left" for="{{item.ngName}}{{i}}"> {{v.label}}</label>

                                                        </div>
                                                    </ng-container>
                                                </div>
                                                <div *ngIf="dataModel.captcha == 0 &&indexStep==dataModel.multiStep.length-1 && attributeindex == dataModel.multiStep[indexStep].attributes.length-1" class="g-recaptcha mb-5" attr.data-sitekey="{{captchaSiteKey}}">
                                                </div>
                                                <div *ngIf=" dataModel.captcha == 1 && indexStep==dataModel.multiStep.length-1 && attributeindex == dataModel.multiStep[indexStep].attributes.length-1">
                                                    <div class="form-group ">
                                                        <div class="m_b_10">
                                                            <div class="form-group row">
                                                                <label for="cronberry-recaptcha-response">Captcha <span
                                                                        class="text-danger">*</span></label>
                                                                <div class="col-md-6">
                                                                    <img alt="Captcha" id="recaptcha-image">
                                                                    <button type="button" class="btn btn-default btn-sm refresh_captcha" onclick="generateToken()">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                                                            <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"></path>
                                                                            <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"></path>
                                                                          </svg></button>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <input autocomplete="off" class="form-control ng-untouched ng-pristine ng-valid"
                                                                        data-regex="^(?!\s*$).+$" data-regexerror="Captcha needs to be Filled"
                                                                        data-required="true" id="cronberry-recaptcha-response"
                                                                        name="cronberry-recaptcha-response" placeholder="Enter Captcha" type="text">
                                                                </div>
                                                            </div>
                                                            <div class="d-none">
                                                                <input class="form-control ng-untouched ng-pristine ng-valid" id="session-id"
                                                                    name="session-id" type="text">
                                                            </div>
                                                        </div>
                                                    </div>
                                            </div>

                                            </div>




                                     

                                            <div class="row justify-content-around">
                                                <div *ngIf="dataModel.multiStep.length > 1 && indexStep !=0 && dataModel.stepUI != 'wizardVertical'" class="prev btn-cont mb-5 col-6">
                                                    <button attr.data-step="{{indexStep}}" id="prev-{{indexStep}}" type="button" [ngClass]="dataModel.button.btnSize +' btn prevStepBtn '+ dataModel.button.btnWidth" [style.backgroundColor]="dataModel.button.bgColor" [style.color]="dataModel.button.textColor">Prev</button>
                                                </div>
                                                <div *ngIf="dataModel.multiStep.length > 1 && indexStep ==0" class=" btn-cont mb-5 col-6">

                                                </div>
                                                <div *ngIf="dataModel.multiStep.length > 1 && indexStep !=dataModel.multiStep.length-1 && dataModel.stepUI != 'wizardVertical'" class="next btn-cont mb-5  col-6 text-end " attr.data-formlogic="{{ stepdata.logic | json }}">
                                                    <button attr.data-stepname="{{stepdata.stepName}}" attr.data-step="{{indexStep + 1}}" type="button" [ngClass]="dataModel.button.btnSize +' btn nextStepBtn'+ dataModel.button.btnWidth" id="next-{{indexStep}}" [style.backgroundColor]="dataModel.button.bgColor"
                                                        [style.color]="dataModel.button.textColor">Next</button>
                                                </div>

                                                <div *ngIf="indexStep == dataModel.multiStep.length-1" class="btn-cont mb-5" [ngClass]="dataModel.button.className" [class.col-6]="dataModel.multiStep.length > 1 && dataModel.stepUI != 'wizardVertical'" [class.col-12]="dataModel.stepUI == 'wizardVertical' || dataModel.multiStep.length == 1">
                                                    <button data-stepname="complete" attr.data-step="{{indexStep + 1}}" type="submit" [ngClass]="dataModel.button.btnSize +' btn '+ dataModel.button.btnWidth" id="cfFormSubmit" [style.backgroundColor]="dataModel.button.bgColor" [style.color]="dataModel.button.textColor">{{dataModel.button.label}}</button>
                                                </div>
                                            </div>
                                        </div>


                                        <div id="successMsg" class="alert fade">
                                            {{dataModel.successMessage}}</div>
                                        <input *ngIf="projectDetails != undefined && indexStep == 0" type="hidden" id="projectKey" data-name="projectKey" value="{{projectDetails.projectKey}}">
                                        <input *ngIf="projectDetails != undefined && indexStep == 0" type="hidden" id="stage" data-name="form_steps" value="">

                                    </div>
                                </ng-container>

                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>

<!-- {{model|json}} -->

<!-- <ng-template #galleryPopup>
    <gallery-popup [modalRefGallery]="modalRefGallery" (selectMediaEvent)="getMedia($event)" [galleryType]="'image'"></gallery-popup>
</ng-template> -->

<ng-template #deleteConfirm>
    <!-- delete popup -->
    <form>
        <div class="forgot-password-modal">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Delete Form</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body modal-user-management">
                <div class="form-group mb-0">
                    <p class="pt-2">
                        Are you sure, you want to delete this form?
                    </p>
                    <div class="d-flex justify-content-end mt-5">
                        <div>
                            <button class="btn remove-user-btn mr-4" (click)="modalRef.hide()" type="button">
                                Dismiss
                            </button>
                        </div>
                        <div>
                            <button class="btn save-btn" type="button" (click)="deleteForm()">
                                Yes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</ng-template>


<ng-template #deleteConfirmSteps>
    <!-- delete popup -->
    <form>
        <div class="forgot-password-modal">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Delete Form</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body modal-user-management">
                <div class="form-group mb-0">
                    <p class="pt-2">
                        Are you sure, you want to delete this step from the form?
                    </p>
                    <div class="d-flex justify-content-end mt-5">
                        <div>
                            <button class="btn remove-user-btn mr-4" (click)="modalRef.hide()" type="button">
                                Dismiss
                            </button>
                        </div>
                        <div>
                            <button class="btn save-btn" type="button" (click)="deleteSteps()">
                                Yes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #logic>
    <!-- delete popup -->
    <form>
        <div class="forgot-password-modald">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Add Logics/Conditions</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body modal-user-management">
                <div class="form-group mb-0">
                    <div *ngIf="logicCondition.conditions.length > 0 ">
                        <div *ngFor="let condition of logicCondition.conditions; let valueIndexi=index;">
                            <div class="row" *ngIf="isLogicConditionString(condition) ">
                                <div class="col-md-2">
                                </div>
                                <div class=" col-md-3 pl-md-0 pr-md-0 condition_logic">
                                    <select disabled class="form-control">
                                        <option value="or" [selected]="condition == 'or'">OR</option>
                                        <option value="and" [selected]="condition == 'and'">AND</option>
                                    </select>

                                </div>
                            </div>
                            <div class="row" *ngIf="isCondition(condition)">
                                <div class="col-md-2">
                                    <select *ngIf="valueIndexi==0" disabled class="form-control">
                                        <option>IF</option>
                                    </select>
                                </div>
                                <div class="col-md-3 pl-md-0 pr-md-0">
                                    <select disabled class="form-control">
                                        <option *ngFor="let item of allFields"
                                            [selected]="item.value == condition.field">{{item.label}}</option>
                                    </select>
                                </div>
                                <div class="col-md-2 pl-md-0 pr-md-0">
                                    <select disabled class="form-control">
                                        <option value="==" [selected]="condition.operator == '=='">IS</option>
                                        <option value="!=" [selected]="condition.operator == '!='">IS NOT</option>
                                    </select>
                                </div>
                                <div class="col-md-3 pl-md-0">
                                    <select disabled class="form-control">
                                        <option>{{condition.value}}</option>
                                    </select>
                                </div>

                                <div class="col-md-1">
                                    <button type="button" class="close pull-right" aria-label="Close" (click)="removeLogic(valueIndexi)">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div class="row" *ngIf="logicCondition.conditions.length>0">
                        <div class="col-md-2">
                        </div>
                        <div class=" col-md-3 pl-md-0 pr-md-0 condition_logic">
                            <select class="form-control" [(ngModel)]="conditionalOperator" [ngModelOptions]="{standalone: true}">
                                <option value="" [selected]="conditionalOperator==''">Select Logic Operator</option>
                                <option value="or">OR</option>
                                <option value="and">AND</option>
                            </select>
                        </div>
                    </div>



                    <div class="row">
                        <div class="col-md-2">
                            <select *ngIf="logicCondition.conditions.length==0" disabled class="form-control">
                                <option>IF</option>
                            </select>
                        </div>


                        <div class="col-md-3 pl-md-0 pr-md-0">
                            <select class="form-control" (change)="onChangeFieldSelect($event.target.value)" [(ngModel)]="selectedLogicNgName" [ngModelOptions]="{standalone: true}">
                                <option value="">Select Field</option>
                                <option *ngFor="let item of allFields" [value]="item.value">{{item.label}}</option>
                            </select>
                        </div>
                        <div class="col-md-2 pl-md-0 pr-md-0">
                            <select class="form-control" [(ngModel)]="selectedLogicOperator" [ngModelOptions]="{standalone: true}">
                                <option value="==">IS</option>
                                <option value="!=">IS NOT
                                </option>
                            </select>
                        </div>
                        <div class="col-md-3 pl-md-0">
                            <select class="form-control" [(ngModel)]="selectedLogicValue" [ngModelOptions]="{standalone: true}">
                                <option value="">Select Value</option>
                                <option *ngFor="let item of selectedFieldsValues" [value]="item.value">{{item.value}}
                                </option>
                            </select>
                        </div>
                        <div *ngIf="selectedFieldsValues.length>0" class="col-md-2">
                            <button class="btn remove-user-btn mr-4" (click)="addLogic()" type="button">
                                Add
                            </button>
                        </div>

                    </div>

                    <div class="row mt-3 mb-3">
                        <div class="col-md-2">
                            <select disabled class="form-control condition_select">
                                <option>Then</option>
                            </select>
                        </div>


                        <div class="form-group col-md-3 pl-md-0 pr-md-0" *ngIf="logicModalType=='field'">
                            <select class="form-control" [(ngModel)]="selectedOutcome" [ngModelOptions]="{standalone: true}">
                                <option value="">Select Outcome</option>
                                <option value="hide" [selected]="logicCondition.outcome == 'hide'">HIDE</option>
                                <option value="show" [selected]="logicCondition.outcome == 'show'">SHOW
                                </option>
                            </select>
                        </div>



                        <div class="col-md-3 pl-md-0 pr-md-0" *ngIf="logicModalType=='form'">
                            <select disabled class="form-control">
                                <option>Jump To</option>
                            </select>
                        </div>
                        <div class="col-md-5 pl-md-0 " *ngIf="logicModalType=='form'">
                            <select class="form-control" [(ngModel)]="selectedOutcome" [ngModelOptions]="{standalone: true}">
                                <option value="">Select Step</option>
                                <option [selected]="dataModel.multiStep[currentIndex].logic.outcome==indexcount"
                                    *ngFor="let step of dataModel.multiStep; let indexcount=index;"
                                    [value]="indexcount">
                                    Step {{indexcount + 1}}</option>
                            </select>
                        </div>
                    </div>


                    <div class="d-flex justify-content-end mt-5">
                        <div>
                            <button class="btn remove-user-btn mr-4" (click)="modalRef.hide()" type="button">
                                Dismiss
                            </button>
                        </div>
                        <div>
                            <button class="btn save-btn mr-4" type="button" (click)="saveLogic()">
                                Save
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </form>