<main>
    <div class="campaign-modal">
        <div *ngIf="!isIframe" class="modal-header">
            <h4 class="modal-title pull-left">Asset Library &nbsp;</h4>
            
            <p> (Don't upload senstive and confidential files here.)</p>
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRefGallery.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <!-- <div class="short-url" *ngIf="this.fileType != 'IMAGE'">
                <label class="check">
                    Short URL
                    <input type="checkbox" [(ngModel)]="shortURL" >
                    <span class="checkmark"></span>
                </label>
            </div> -->
            <tabset *ngIf="isSubscribe" #pageTabs>
                <tab *ngIf="isMediaShow" heading="Upload File" id="tab1" (selectTab)="changeTab($event)">
                    <form [formGroup]="formGallery" (ngSubmit)="saveGallery()" class="mt-4">
                        <div class="mb-3">
                            <div class="form-group row">
                                <label class="col-lg-4 col-md-12 control-label required">Tag Name</label>
                                <div class="col-lg-8 col-md-12">
                                    <input trim="blur" class="form-control" formControlName="imageName" type="text" maxlength="50">
                                </div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <div class="form-group row">
                                <label class="col-lg-4 col-md-12 control-label required">Type</label>
                                <div class="col-lg-8 col-md-12">
                                    <select class="form-control" formControlName="fileType">
                                        <option value="IMAGE">Image</option>
                                        <option value="FILE">File</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <div class="form-group row">
                                <label class="col-lg-4 col-md-12 control-label required">Upload Media</label>
                                <div class="col-lg-8 col-md-12">
                                    <div *ngIf="formGallery.controls.fileType.value == 'IMAGE'" >
                                        <input name="file" #imageInput type="file" accept="image/*" multiple="" class="form-control modal-space" (change)="uploadimage($event)">
                                        <input type="hidden" name="fileHidden" formControlName="originalFile"/> 
                                    </div>
                                    <div *ngIf="formGallery.controls.fileType.value == 'FILE'" >
                                        <input name="file" #fileInput type="file" accept=".xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.pdf,.zip" class="form-control modal-space" (change)="uploadimage($event)">
                                        <!-- <input type="hidden" name="fileHidden" formControlName="file"/>  -->
                                    </div>
                                    <span class="icontooltip" tooltip="Maximum image size - 20 MB. Maximum image upload - 20." [tooltipAnimation]="true" tooltipPlacement="right">
                                        <img src="assets/images/info-circle.svg " />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="text-right mt-1 mb-2">
                            <button class="btn btn-dark" type="submit">Save</button>
                        </div>
                    </form>
                </tab>
                <tab *ngIf="isMediaShow && galleryType!='addMedia'" heading="Media List" id="tab2" (selectTab)="changeTab($event)">
                    <div class="filter-input mt-2">
                        <input class="btn btn-search" name="search" placeholder="Search" type="text" [(ngModel)]="searchText" autocomplete="off">
                        <div class="filter-input-space">
                            <select class="form-control" [(ngModel)]="fileType">
                                <option value="">File Type</option>
                                <option [disabled]="galleryType == 'file'" value="GALLERY">Gallery</option>
                                <option [disabled]="galleryType == 'file'" value="IMAGE">Your Image</option>
                                <option [disabled]="galleryType == 'image'" value="FILE">Your File</option>
                            </select>
                        </div>
                    </div>
                    <div class="gallery-list">
                        <div *ngFor="let item of mediaList | myFilter:searchText ; let i = index;" class="item" (click)="selectMedia(item, item.fileType);apply_img(item.originalFile, 'image')" >
                            <span *ngIf="(fileType == '' || fileType == 'IMAGE') && item.fileType == 'image'" tooltip="{{item.mediaName}}" [tooltipAnimation]="true" tooltipPlacement="top">
                                <img [src]="item.thumbnailFile" alt="{{item.mediaName}}">
                            </span>
                            <span *ngIf="(fileType == '' || fileType == 'GALLERY') && item.fileType == 'GALLERY'" tooltip="{{item.mediaName}}" [tooltipAnimation]="true" tooltipPlacement="top">
                                <img [src]="item.thumbnailFile" alt="{{item.mediaName}}">
                            </span>
                            <span *ngIf="(fileType == '' || fileType == 'FILE') && item.fileType == 'file'" tooltip="{{item.mediaName}}" [tooltipAnimation]="true" tooltipPlacement="top">
                                <img [src]="item.thumbnailFile" alt="{{item.mediaName}}" class="file-icon" >
                            </span>
                        </div>
                        <br>
                        <ul *ngIf="(pageNo > 0 || isMoreDataAvailable) && isDataAvailable && !isLoading" class="pagination justify-content-center">
                            <li [ngClass]="pageNo > 0 ? 'page-item' : 'page-item disabled'"><a class="page-link" href="javascript:void(0);" (click)="previousPage()" ><i class="fal fa-angle-double-left"></i> Previous </a></li>
                            <li [ngClass]="isMoreDataAvailable ? 'page-item' : 'page-item disabled'"><a class="page-link" href="javascript:void(0);" (click)="nextPage()">Next <i class="fal fa-angle-double-right"></i></a></li>
                        </ul>
                    </div>
                </tab>
                <tab *ngIf="isFormShow && isCustomeFormSubscribe" heading="Custom Form" id="tab3" (selectTab)="changeTab($event)">
                    <div class="filter-input mt-2" *ngIf="isAddFormShow">
                        <div class="filter-input-space">
                            <a [routerLink]="['/admin/custom-form']" (click)="modalRefGallery.hide()" class="btn btn-border blue-border">Add Form</a>
                        </div>
                    </div>
                    <div class="form-list form-row" *ngIf="customFormList != undefined">
                        <div *ngFor="let item of customFormList; let i = index;" class=" col-md-3 col-6 item" (click)="selectMedia(item, 'form');apply_img(item.linkRewrite, 'form');" >
                            <span>
                                <img src="assets/images/file-alt.svg" alt="{{item.name}}" class="file-icon" >
                                <h4>{{item.name}}</h4>
                            </span>
                        </div>
                    </div>
                </tab>
            </tabset>
            <app-page-disable *ngIf="!isSubscribe"></app-page-disable>
        </div>
    </div>
</main>