<main>
    <section class="inner-page">
        <div class="inner-page-header">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-5 align-self-center">
                        <h4>
                            <!-- <a class="back-link" *ngIf="isUrlFilterApplied" (click)="back()"><img src="assets/images/angle-left.svg"></a> -->
                            <a class="back-link" (click)="back()"><img src="assets/images/angle-left.svg"></a>
                            <span>{{categoryNameShow}}</span>
                            <!-- <ng-template #elseHeading>View Leads</ng-template> -->
                        </h4>
                    </div>
                    <div class="col-7 text-right">
                        <div class="dropdown">
                            <button class="btn btn-dark dropdown-toggle" type="button" id="manageLeadsButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Audience Settings
                            </button>
                            <div class="dropdown-menu" aria-labelledby="manageLeadsButton">
                                <!-- <a *ngIf="leadStatusParamsId != undefined" class="dropdown-item" [routerLink]="['/admin/settings/update-lead-status']">Update Lead Status</a> -->
                                <a class="dropdown-item" [routerLink]="['/admin/settings/update-lead-status/',this.parentMenu]">Update Lead Status</a>
                                <a class="dropdown-item" href="javascript:void(0)" (click)="openModal(audienceSettingsForm,'');fetchAudienceColumns();">Customise Column</a>
                                <!-- <a class="dropdown-item" href="javascript:void(0)" (click)="addAudience();">Add Audience</a> -->
                                <!-- <a class="dropdown-item" href="javascript:void(0)" (click)="usingFor = 'reassign'; reassignLead = true; isShowCheckbox = false; calculateAudience();">Reassign</a> -->
                                <a class="dropdown-item" href="javascript:void(0)" (click)="isShowCheckbox = true; reassignLead = false; isReassignOn = false; usingFor = 'viewAudience'; calculateAudience();">Delete</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <app-page-disable *ngIf="!isSubscribe"></app-page-disable>
          </div>
          <ng-container *ngIf="isSubscribe">
        <div class="inner-page-body border-bottom-0 p_b_0">
            <div class="container-fluid">
                <div class="filter-input">
                    <div class="filter-input-space mr-auto"></div>
                    <div class="filter-input-space" *ngIf="userRoleType == telecallerRoleType">
                        <ng-select [items]="statusParams" [(ngModel)]="audienceStatus" class="params m-b-0 width_250" bindLabel="label" [multiple]="true" placeholder="Select Status" (change)="calculateAudience()">
                        </ng-select>
                    </div>
                    <div class="filter-input-space search-box">
                        <input trim="blur" class="btn" name="search" placeholder="Search by name, email, phone, audience Id" type="text" [(ngModel)]="searchText" autocomplete="off" (keyup.enter)="calculateAudience('reloadPagination','searchTxtSubmit')">
                        <button class="btn-search" (click)="calculateAudience('reloadPagination','searchTxtSubmit')" tabindex="3" type="submit"><div class="sr-only">Search</div></button>
                    </div>
                    <div class="filter-input-space" *ngIf="filterList != undefined && filterList.length > 0">
                        <div class="dropdown">
                            <button class="btn btn-dark dropdown-toggle" type="button" id="manageLeadsButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Filter by</button>
                            <div class="dropdown-menu" aria-labelledby="manageLeadsButton">
                                <a *ngFor="let item of filterList" class="dropdown-item" (click)="applyFilter(item)" href="javascript:void(0)">{{item.segmentName}}</a>
                            </div>
                        </div>
                    </div>
                    <div class="filter-input-space" >
                        <div class="dropdown">
                            <button class="btn btn-dark dropdown-toggle" type="button" id="customFormList" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <img src="assets/images/plus-icon-white.png"> &nbsp; Add</button>
                            <div class="dropdown-menu" aria-labelledby="customFormList">
                                <a class="dropdown-item" (click)="addAudience()" href="javascript:void(0)">API</a>
                                <ng-container  *ngIf="customFormList">
                                    <ng-container *ngFor="let item of customFormList">
                                <a *ngIf="item.isPublic==0 && item.status == 'ACTIVE'" class="dropdown-item" (click)="showForm(item)" href="javascript:void(0)">{{item.name}}</a>
                            </ng-container>
                            </ng-container>

                            </div>
                        </div>
                    </div>
                    <div class="filter-input-space">
                        <button class="btn btn-border blue-border" (click)="openModal(advanceSearchBlock,'viewAudience')" tabindex="4" type="submit">Advance Search</button>
                    </div>
                    <div class="filter-input-space">
                        <div class="form-group datepicker">
                            <input id="" type="text" readonly autocomplete="off" ngxDaterangepickerMd [(ngModel)]="filterDateRange" [locale]="{format: 'DD/MM/YYYY'}" [alwaysShowCalendars]="true" [ranges]="dateRanges" [autoApply]="false" [opens]="'left'" placeholder="Select Date Range"
                                (datesUpdated)="onDateSelectionEntryDate($event)">
                            <div class="input-group-append btn-calander">
                                <button class="btn btn-outline-secondary calendar" type="button"></button>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="filter-input-space">
                       
                        <button class="btn white_add_btn" type="button" (click)="addAudience();" title="Add Audience">
                            <img src="assets/images/plus-icon.png"> &nbsp; Add
                        </button>
                    </div> -->

                    

                    

                </div>
            </div>
        </div>
        <div *ngIf="startDate || global.Channel.Source.length>0 || global.Channel.Events.length>0 || selectedFilters.length>0 || selectedUserName != undefined" class="inner-page-header input-header mx-3" [class.collapseFilter]="queryStrParams == true || getFilterFromStorage != null && getFilterFromStorage.length > 0 || defaultDateRangeFilter == true" [class.collapseFilterShow]="queryStrParams2 == false">

            <div class="container-fluid card">
                <div class="pb-3"></div>
                <div class="filterTitle heading">
                    Filter
                    <span class="collapserFilterBox">
                        <span class="hidderFilter" (click)="collapsible('hide')">
                            <img src="assets/images/up.png">
                        </span>
                    <span class="showerFilter" (click)="collapsible('show')">
                            <img src="assets/images/down.png">
                        </span>
                    </span>
                </div>
                <div class="innerFilterBox">
                    <div *ngIf="startDate && followUpRequest != 'betweenDate'" class="col-md-12 col-12 col-sm-12 campaing-box">
                        <div class="heading">Date Range Filter <button (click)="resetDate(); calculateAudience();" class="btn heading-btn" type="button"><img src="assets/images/cross.png"></button></div>
                        <div class="form-group detail row mb-0">
                            <div class="col-md-6 col-12 col-sm-12 source-detail">
                                <img (click)="resetDate();" src="assets/images/times-circle2.svg" />
                                <label>{{dateMsg}}</label>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="selectedUserName != undefined" class="col-md-12 col-12 col-sm-12 campaing-box">
                        <div class="heading">User <button (click)="resetUser(); calculateAudience();" class="btn heading-btn" type="button"><img src="assets/images/cross.png"></button></div>
                        <div class="form-group detail row mb-0">
                            <div class="col-md-6 col-12 col-sm-12 source-detail">
                                <img (click)="resetUser();" src="assets/images/times-circle2.svg" />
                                <label>{{selectedUserName}}</label>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="this.global.Channel.Source.length>0" class="col-md-12 col-12 col-sm-12 campaing-box">
                        <!-- <div class="heading">Source <button (click)="removeChannel('Events'); removeChannel('Source'); removeFilters(); AnyChannelSelected();" class="btn heading-btn" type="button"><img src="assets/images/cross.png"></button></div> -->
                        <div class="heading">Source <button (click)="removeChannel('Source'); filterSourceRemove('clearAllSource'); AnyChannelSelected();" class="btn heading-btn" type="button"><img src="assets/images/cross.png"></button></div>
                        <div class="form-group detail mb-0 source-list">
                            <div *ngFor="let item of this.global.Channel.Source" class="source-detail">
                                <img (click)="clearSessionChannel(item.pluginId); this.global.ClearSpecificChannelId('Source', item.pluginId); filterSourceRemove('clearSpecific'); AnyChannelSelected();" src="assets/images/times-circle2.svg" />
                                <!-- <img (click)="this.global.ClearSpecificChannelId('Source', item.pluginId); removeFilters(); AnyChannelSelected();" src="assets/images/times-circle2.svg" /> -->
                                <label>{{item.pluginName}} <span *ngIf="item.selectedForms != undefined && item.selectedForms.length > 0">(<ng-container *ngFor="let name of item.selectedForms;let isLast=last">{{name.formName}}{{isLast ? '' : ', '}}</ng-container>)</span></label>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="this.global.Channel.Events.length>0" class="col-md-12 col-12 col-sm-12 campaing-box">
                        <div class="heading">Events <button (click)="removeChannel('Events')" class="btn heading-btn" type="button"><img src="assets/images/cross.png"></button></div>
                        <div class="form-group detail row mb-0">
                            <div *ngFor="let item of this.global.Channel.Events" class="col-md-4 col-6 col-lg-2 source-detail">
                                <img (click)="global.ClearSpecificChannelId('Events', item.id); AnyChannelSelected();" src="assets/images/times-circle2.svg" />
                                <label>{{item.paramName}}</label>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="this.selectedFilters.length>0" class="col-md-12 col-12 col-sm-12 campaing-box">
                        <div class="heading">Attributes <button (click)="removeFilters(); calculateAudience();" class="btn heading-btn" type="button"><img src="assets/images/cross.png"></button></div>
                        <div class="detail">
                            <div *ngFor="let item of selectedFilters; let i = index;" class="card-body tp-{{item.paramDatatype}}">
                                <div class="row">
                                    <div class="col-md-2 col-12 col-sm-12">
                                        <label class="control-label">{{item.paramName}}</label>
                                    </div>
                                    <div class="col-md-6 col-12 col-sm-12 pr-md-0">
                                        <div *ngIf="item.paramDatatype=='String' || item.paramDatatype=='string' || item.paramDatatype=='Email' || item.paramDatatype=='Mobile'">
                                            <!-- <div class="row" *ngIf="item.values.length > 0 "> -->
                                                <div class="row">
                                                <div class="col-md-4 col-12 col-sm-12 mb-2 mb-md-0">
                                                    <select class="form-control" (change)="removeAudience(item)" [(ngModel)]="item.selected.value">
                                                        <option value="contain">Contains</option>
                                                        <option value="in">IN</option>
                                                        <option value="not_in">Not IN</option>
                                                    </select>
                                                </div>
                                                <div class="col-md-8 col-12 col-sm-12" *ngIf="item.selected.value == 'contain'">
                                                    <!-- <input trim="blur" [ngClass]="{'invalid-control' : item.selected.text1 == undefined || item.selected.text1 == ''}" type="text" (change)="removeAudience()" [(ngModel)]="item.selected.text1" class="form-control"> -->
                                                    <ng-autocomplete autocomplete="off" class="form-control" [data]="item.values" [(ngModel)]="item.selected.text1" [searchKeyword]="'paramValue'" [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate" (inputCleared)="clearAutoComplete(item)">
                                                    </ng-autocomplete>
                                                    <!-- <ng-template #itemTemplate let-item>
                                                        <a [innerHTML]="item.paramValue"></a>
                                                    </ng-template> -->
                                                    <!-- <ng-template #notFoundTemplate let-notFound>
                                                        <div [innerHTML]="notFound"></div>
                                                    </ng-template> -->
                                                    <div class="containsError" *ngIf="item.conainsError">Contains value should be minimum 3 character</div>
                                                </div>
                                                <div class="col-md-8 col-12 col-sm-12" *ngIf="item.selected.value == 'in'">
                                                    <ng-select [ngClass]="{'invalid-control' : !this.validFilters && (item.selected == undefined || item.selected.length == undefined || item.selected.length == 0)}" [items]="item.values" bindLabel="paramValue" [multiple]="true" [(ngModel)]="item.selected"
                                                        (change)="selectedInFilter(i, 'in'); removeAudience()" (input)="fetchFilterDetails(item,'',$event)" (add)="storeParamVal(item)" (remove)="removeParamVal(item)" (clear)="clearStorage(item)" [isOpen]="!item.values.length ? false : null" [placeholder]="item.placeholder ? '' : 'Type to search'">
                                                    </ng-select>
                                                </div>
                                                <!-- <div class="col-md-8 col-12 col-sm-12" *ngIf="item.values.length>=100 && item.selected.value == 'in'">
                                                    <ng-select [ngClass]="{'invalid-control' : !this.validFilters && (item.selected == undefined || item.selected.length == undefined || item.selected.length == 0)}" [items]="item.values$ | async" bindLabel="paramValue" [multiple]="true" [hideSelected]="true"
                                                        [loading]="item.loading" [typeahead]="item.valueInput$" [(ngModel)]="item.selected" (change)="selectedInFilter(i, 'in')" (input)="getFilterDetails(item,'',$event)">
                                                    </ng-select>
                                                </div> -->
                                                <div class="col-md-8 col-12 col-sm-12" *ngIf="item.selected.value == 'not_in'">
                                                    <ng-select [ngClass]="{'invalid-control' : !this.validFilters && (item.selected == undefined || item.selected.length == undefined || item.selected.length == 0)}" [items]="item.values" bindLabel="paramValue" [multiple]="true" [(ngModel)]="item.selected"
                                                        (change)="selectedInFilter(i, 'not_in'); removeAudience()" (input)="fetchFilterDetails(item,'',$event)" (add)="storeParamVal(item)" (remove)="removeParamVal(item)" (clear)="clearStorage(item)" [isOpen]="!item.values.length ? false : null" [placeholder]="item.placeholder ? '' : 'Type to search'">
                                                    </ng-select>
                                                </div>
                                                <!-- <div class="col-md-8 col-12 col-sm-12" *ngIf="item.values.length>=100 && item.selected.value == 'not_in'">
                                                    <ng-select [ngClass]="{'invalid-control' : !this.validFilters && (item.selected == undefined || item.selected.length == undefined || item.selected.length == 0)}" [items]="item.values$ | async" bindLabel="paramValue" [multiple]="true" [hideSelected]="true"
                                                        [loading]="item.loading" [typeahead]="item.valueInput$" [(ngModel)]="item.selected" (change)="selectedInFilter(i, 'not_in')" (input)="getFilterDetails(item,'',$event)">
                                                    </ng-select>
                                                </div> -->
                                            </div>
                                            <div class="col-md-6 col-12 col-sm-12 pr-0 opacity-5" *ngIf="item.values.length == 0">{{item.error_msgs}}</div>
                                        </div>

                                        <div class="row" *ngIf="item.paramDatatype=='Numeric'">
                                            <div class="col-md-6 col-12 col-sm-12 mb-2 mb-md-0">
                                                <select class="form-control" (change)="removeAudience()" [(ngModel)]="item.selected.value">
                                                    <ng-container *ngIf="item.paramDatatype=='Mobile'">
                                                        <option value="=">= Equal</option>
                                                    </ng-container>
                                                    <ng-container *ngIf="item.paramDatatype != 'Mobile'">
                                                    <option value=">=">≥ Greater Than Equal</option>
                                                    <option value="<=">≤ Less Than Equal</option>
                                                    <option value="=">= Equal</option>
                                                    <option value="!=">!= Not Equal</option>
                                                    <option value=">">> Greater Than</option>
                                                    <option value="<">< Less Than</option>
                                                    <option value="between">Between</option>
                                                    </ng-container>
                                                </select>
                                            </div>

                                            <div class="col-md-3 col-6 col-sm-6">
                                                <input trim="blur" [ngClass]="{'invalid-control' : item.selected.text1 == undefined || item.selected.text1 === ''}" type="text" (change)="removeAudience()" [(ngModel)]="item.selected.text1" class="form-control" autocomplete="off" onkeypress='return (event.charCode >= 48 && event.charCode <= 57) || (event.charCode == 46)'>
                                            </div>

                                            <div class="col-md-3 col-6 col-sm-6" *ngIf="item.selected.value=='between'">
                                                <input trim="blur" [ngClass]="{'invalid-control' : !this.validFilters && (item.selected.text2 == undefined || item.selected.text2 == '')}" type="text" (change)="removeAudience()" [(ngModel)]="item.selected.text2" class="form-control" autocomplete="off" onkeypress='return (event.charCode >= 48 && event.charCode <= 57) || (event.charCode == 46)'>
                                            </div>
                                        </div>
                                        
                                        <ng-container *ngIf="item.paramDatatype=='Date'">
                                        <div class="row">

                                            <div class="col-md-4 col-12 col-sm-12 mb-2 mb-md-1">
                                                <select [ngClass]="{'invalid-control' : !this.validFilters && (item.selected.value == undefined)}" class="form-control" (change)="removeAudience()" [(ngModel)]="item.selected.value">
                                                    <option value="between">Range</option>
                                                    <!-- <option value="day">Day</option>
                                                    <option value="week">Week</option>
                                                    <option value="month">Month</option>
                                                    <option value="daymonth">Day/Month</option> -->
                                                </select>
                                            </div>
                                            <div class="col-md-4 col-6 col-sm-6 mb-1">
                                                <input [ngClass]="{'invalid-control' : !this.validFilters && (item.selected.text1 == undefined || item.selected.text1 == '')}" type="text" *ngIf="item.selected.value!='between'" placeholder="Select Date" placement="top left" (change)="removeAudience()"
                                                    [(ngModel)]="item.selected.text1" class="form-control date-icon modal-space" bsDatepicker autocomplete="off" [bsConfig]="global.datePickerConfig">
                                                <span class="icontooltip">
                                                <img src="assets/images/calendar-day-black.svg " />
                                            </span>
                                                <input [ngClass]="{'invalid-control' : !this.validFilters && (item.selected.text1 == undefined || item.selected.text1 == '')}" type="text" *ngIf="item.selected.value=='between'" placeholder="From Date" placement="top left" (change)="removeAudience()"
                                                    [(ngModel)]="item.selected.text1" class="form-control date-icon modal-space" bsDatepicker autocomplete="off" [bsConfig]="global.datePickerConfig">
                                                <span class="icontooltip">
                                                <img src="assets/images/calendar-day-black.svg " />
                                            </span>
                                            </div>
                                            <div class="col-md-4 col-6 col-sm-6" *ngIf="item.selected.value=='between'">
                                                <input [ngClass]="{'invalid-control' : !this.validFilters && (item.selected.text2 == undefined || item.selected.text2 == '' || item.selected.text1 > item.selected.text2)}" type="text" placeholder="To Date" placement="top left" (change)="removeAudience()"
                                                    [(ngModel)]="item.selected.text2" class="form-control date-icon modal-space" bsDatepicker autocomplete="off" [bsConfig]="global.datePickerConfig">
                                                <span class="icontooltip">
                                                <img src="assets/images/calendar-day-black.svg " />
                                            </span>
                                            </div>
                                        </div>
                                        
                                        </ng-container>

                                        <div class="row" *ngIf="item.paramDatatype=='Boolean'">
                                            <div class="col-md-5 col-12 col-sm-12">
                                                <input trim="blur" type="text" class="form-control">
                                            </div>
                                            <div class="col-md-5 col-12 col-sm-12">
                                                <select class="form-control">
                                                <option>1</option>
                                                <option>2</option>
                                                <option>3</option>
                                                <option>4</option>
                                                </select>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-md-4 col-12 col-sm-12 col-close">
                                        <button type="button" (click)="removeFilter(item)" class="btn btn-campaign-close" type="button"><img src="assets/images/cross.png"></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="filter-input-space text-right d-flex justify-content-space-between viewAudFilter">
                            <div class="dateRangeLeftSide">
                                <div class="form-group datepicker" *ngIf="!startDate || selectDateTypeTwo">
                                    <input id="" type="text" readonly autocomplete="off" ngxDaterangepickerMd [(ngModel)]="filterDateRange2" [locale]="{format: 'DD/MM/YYYY'}" [alwaysShowCalendars]="true" [ranges]="dateRanges" [autoApply]="false" [opens]="'right'" [maxDate]="" placeholder="Select Date Range"
                                        (datesUpdated)="onDateSelectionEntryDate($event)">
                                    <div class="input-group-append btn-calander no-image">
                                        <button class="btn btn-outline-secondary calendar" type="button" (click)="dateSelect()">Select Date</button>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <button class="btn btn-border m-2" (click)="clearFilters(); resetDate(); resetUser(); calculateAudience();" tabindex="3" type="submit">Clear</button>
                                <button class="btn btn-dark m-2" (click)="defaultDateRangeFilter = false; checkedListRemove(); isFilterApplied = true; followUpAllTypeApply=false; calculateAudience();" tabindex="3" type="submit">Apply</button>
                                <ng-container *ngIf="validFilters">
                                <button *ngIf="isFilterApplied || queryStrParams" class="btn btn-dark m-2" (click)="usingFor = 'reassign'; reassignLead = true; isShowCheckbox = false; calculateAudience();" tabindex="3" type="submit">Reassign</button>
                                <button *ngIf="isFilterApplied" class="btn btn-dark m-2" tabindex="3" (click)="openSelectAttr();" type="submit">Download Data</button>
                                <ng-container *ngIf="isFilterApplied && !isUrlFilterApplied">
                                    <button class="btn btn-dark m-2" (click)="applyFilterData.name = '';tempModalData = undefined; confirmModal(addFilterConfirm,'addFilter')" tabindex="3" type="submit" *ngIf="!applyFilterData.id && selectedFilters.length>0">Save Segment</button>
                                    <button class="btn btn-dark m-2" (click)="createGraph();" tabindex="3" type="submit">Create Graph</button>
                                </ng-container>
                                </ng-container>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <p class="info" *ngIf="defaultDateRangeFilter == true">
                ** Last 30 days records are displayed by default
            </p>
        </div>

        <ng-template #attrListPopup>  
            <div class="forgot-password-modal excel_download_table_box">
              <div class="modal-header">
                <h4 class="modal-title pull-left">Select excel column</h4>
                <div>
                <button aria-label="Close" class="close pull-right" type="button" (click)="modalRef.hide();">
                  <span aria-hidden="true">×</span>
                </button>
                </div>
              </div>
              <div class="modal-body">
                <div class="table-reports">
                  <div class="p_b_60">
                    <div class="table-responsive">
                      <table class="table" *ngIf="showMapParams.length > 0">
                        <tr>
                            <td colspan="2" class="bg_light_white">
                              <input class="btn searchbtn margin-searchbtn" name="search" placeholder="Search" type="text" [(ngModel)]="searchAttribute" autocomplete="off">
                            </td>
                          </tr>
                          
                        <tr>
                          <th>Attribute Name</th>
                          <th class="text-center"><input type="checkbox" [(ngModel)]="masterSelected" (change)="checkUncheckAll($event)" [tooltipAnimation]="true" tooltipPlacement="bottom" tooltip="Select All"></th>
                        </tr>
                        <tr *ngFor="let list of showMapParams_extra_field | myFilter:searchAttribute">
                            <td>{{list.paramName}}</td>
                            <td class="text-center" [class.default_checked]="list.paramKey == 'name' || list.paramKey == 'email' || list.paramKey == 'mobile'">
                                <input type="checkbox" [name]="list.paramKey" [checked]="list.paramKey == 'name' || list.paramKey == 'email' || list.paramKey == 'mobile'">
                            </td>
                        </tr>
                        <tr *ngFor="let list of showMapParams | myFilter:searchAttribute;">
                          <td>{{list.paramName}} <p class="d-inline" *ngIf="(list.paramKey=='city' || list.paramKey=='state' || 
                            list.paramKey=='message' || list.paramKey=='country' || list.paramKey=='pincode' || list.paramKey=='city' || list.paramKey=='product_name' ||  list.paramKey=='address') ">(default)</p></td>
                          <td class="text-center"><input type="checkbox" [name]="list.paramKey" [(ngModel)]="list.checked" (change)="checkUncheckVal($event,list)"></td>
                        </tr>
                        
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div class="modal-footer v_aud_mod_foot">
              <button type="submit" class="btn txt_fff bg_333" (click)="downloadAudienceData('ADVANCE')">Download</button>
              </div>


            </div>
        </ng-template>

        <div class="inner-page-body project-integration p_b_40">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <div class="table-reports">
                            <div class="table-body">
                                <div class="table-responsive table-height-scroll text-center">
                                    <table class="table view_audience_table">
                                        <tbody class="norecord-handling">
                                            <ng-container *ngIf="isRecords">
                                            <tr>
                                                <th *ngIf="records.length > 0 && (isShowCheckbox || isReassignOn || reassignLead)">
                                                    <label class="check">
                                                        <input type="checkbox" [(ngModel)]="allselected" name="checklist" (change)="selectAll();storeAllDataToLocal()"/>
                                                        <span class="checkmark check-margin"></span>
                                                    </label>
                                                </th>
                                                <th class="text-left">S.No.</th>
                                                <ng-container *ngFor="let columnName of audienceColumnName">
                                                    <th *ngIf="columnName != ''">{{columnName}}</th>
                                                </ng-container>
                                                
                                                <th>Action</th>
                                            </tr>
                                            <tr *ngFor="let item of records | paginate: { id: 'foo',itemsPerPage:limit, currentPage:page, totalItems:totalRecords }; let i = index">
                                                <td *ngIf="(isShowCheckbox || isReassignOn || reassignLead)">
                                                    <label class="check">
                                <input type="checkbox" [(ngModel)]="item.selected" name="checklist" value="{{item.id}}" (change)="isAllSelected();storeDataToLocal(item.selected,item.id)"/>
                                <span class="checkmark check-margin"></span>
                            </label>
                                                </td>
                                                <td>
                                                    <div class="d-flex c-align">
                                                    <span class="srNoViewAud">{{((page-1)*limit)+i+1}}.</span>
                                                    <span class="theme_txt_color min_width_10"><ng-container *ngIf="item.record['is_repeat'] == 1">R</ng-container></span>
                                                    <!-- <a (click)="openViewModal(audienceProfile, item.id,'detail')" class="btn without-hover viewBtnMobile btn-sm"><img src="assets/images/segmenteye.png"></a>  -->
                                                    </div>
                                                </td>  
                                                <ng-container *ngFor="let data of audienceColumnForTable; let i = index;">      
                                                                                        
                                                    <td *ngIf="data != 'email_valid' && data != 'is_repeat'">
                                                       <!-- {{item.record[audienceColumnForTable[i]]}} -->
                                                    <ng-container *ngIf="data == 'email' && item.record['email']">
                                                        
                                                        <span *ngIf="item.record[audienceColumnForTable[i]+'_valid'] == 0"  title ="Email is invalid">
                                                        {{item.record[audienceColumnForTable[i]].length > 20 ? item.record[audienceColumnForTable[i]].substring(0, 20)+'...' : item.record[audienceColumnForTable[i]]}}
                                                        </span>
                                                       <span *ngIf="item.record[audienceColumnForTable[i]+'_valid'] == 1" class="link" (click)="getEmailDetails(item.id, item.record[audienceColumnForTable[i]], 'Email')">{{item.record[audienceColumnForTable[i]].length > 20 ? item.record[audienceColumnForTable[i]].substring(0, 20)+'...' : item.record[audienceColumnForTable[i]]}}</span> 
                                                     </ng-container>
                                                     <ng-container *ngIf="data == 'mobile' && item.record['mobile']">
                                                     <ng-container *ngIf="(item.record['mobile'] | phone) != null else international">
                                                        <span class="link">
                                                        <span (click)="getEmailDetails(item.id,item.record['mobile'],'SMS','india')">{{item.record['mobile'] | phone}}</span>
                                                        <a class="whatsapp" (click)="getEmailDetails(item.id, item.record['mobile'], 'whatsapp','india')"><img src="assets/images/whatsapp2.svg" alt="whatsapp"></a>
                                                        <a class="whatsapp d-inline-block d-md-none v_align_top m_t_2" href="tel:{{item.record['mobile']}}"><img src="assets/images/phone2.svg" alt="phone"></a>
                                                        </span>
                                                    </ng-container>
                                                    <ng-template #international>
                                                        <span class="link">
                                                        <span (click)="getEmailDetails(item.id,item.record['mobile'],'SMS')">{{item.record['mobile']}}</span>
                                                        <a class="whatsapp" (click)="getEmailDetails(item.id, item.record['mobile'].replace('-', ''), 'whatsapp')"><img src="assets/images/whatsapp2.svg" alt="whatsapp"></a>
                                                        <a class="whatsapp d-inline-block d-md-none v_align_top m_t_2" href="tel:{{item.record['mobile']}}"><img src="assets/images/phone2.svg" alt="phone"></a>
                                                        </span>
                                                    </ng-template>
                                                    </ng-container>

                                                     <ng-container *ngIf="data != 'email' && data != 'mobile'">
                                                        <ng-container *ngIf="item.record[audienceColumnForTable[i]]">{{item.record[audienceColumnForTable[i]].length > 20 ? item.record[audienceColumnForTable[i]].substring(0,20)+'...' : item.record[audienceColumnForTable[i]]}}</ng-container>
                                                     </ng-container>
                                                    
                                                    
                                                     <ng-container *ngIf="item.record['params']">
                                                        <ng-container *ngIf="data == 'lead_status'">
                                                            <span class="lead-tag" [style.background]="getLeadStatusColor(item.record['params'][data])"></span>
                                                            <ng-container *ngIf="item.record['params'][data] && item.record['params'][data] != null">{{item.record['params'][data].length > 20 ? item.record['params'][data].substring(0,20)+'...' : item.record['params'][data]}}</ng-container>
                                                        </ng-container>
                                                        <ng-container *ngIf="data == 'lead_count'">
                                                            <ng-container *ngIf="item.record['params'][data] || item.record['params'][data] == 0">{{item.record['params'][data]}}</ng-container>
                                                        </ng-container>
                                                        <ng-container *ngIf="data != 'lead_status' && data != 'lead_count'">
                                                            <div *ngIf="item.record['params'][data] && item.record['params'][data] != null" [innerHTML]="item.record['params'][data].length > 20 ? item.record['params'][data].substring(0,20)+'...' : item.record['params'][data]">  </div>
                                                        </ng-container>
                                                     </ng-container>
                                                    </td> 
                                                    </ng-container>                                               
                                                    <ng-container *ngIf="item.record.length > 1">
                                                   
                                                    
                                                    <!-- <td *ngFor="let data of item.record | slice:2; let i = index">
                                                        <span *ngIf=" audienceColumnName[i] == '' || audienceColumnName[i] == 'Email' || audienceColumnName[i] == 'Phone Number' || audienceColumnName[i] == 'LeadStatus'; else remainingData">
                                    <span *ngIf="audienceColumnName[i] == 'Email' && data != null && item.record[1] == 0"  title ="Email is invalid">{{data.length > 20 ? data.substring(0, 20)+'...' : data}}</span>
                                    <span *ngIf="audienceColumnName[i] == 'Email' && data != null && item.record[1] == 1" class="link" (click)="getEmailDetails(item.id, data, 'Email')">{{data.length > 20 ? data.substring(0, 20)+'...' : data}}</span>
                                    <span *ngIf="audienceColumnName[i] == '' && data === true " class="theme_txt_color">R</span>
                                  
                                    <span class="link" *ngIf="audienceColumnName[i] == 'Phone Number' && data != '' && data != null">
                                        <ng-container *ngIf="(data | phone) != null else international">
                                            <span (click)="getEmailDetails(item.id,data,'SMS','india')">{{data | phone}}</span>
                                                        <a class="whatsapp" *ngIf="data != '' && data != null" (click)="getEmailDetails(item.id, data, 'whatsapp','india')"><img src="assets/images/whatsapp2.svg" alt="whatsapp"></a>
                                                        <a class="whatsapp d-inline-block d-md-none v_align_top m_t_2" href="tel:{{data}}"><img src="assets/images/phone2.svg" alt="phone"></a>
                                                </ng-container>
                                                <ng-template #international>
                                                    <span (click)="getEmailDetails(item.id,data,'SMS')">{{data}}</span>
                                                    <a class="whatsapp" *ngIf="data != '' && data != null" (click)="getEmailDetails(item.id, data.replace('-', ''), 'whatsapp')"><img src="assets/images/whatsapp2.svg" alt="whatsapp"></a>
                                                    <a class="whatsapp d-inline-block d-md-none v_align_top m_t_2" href="tel:{{data}}"><img src="assets/images/phone2.svg" alt="phone"></a>
                                                </ng-template>
                                                </span>
                                                <span *ngIf="audienceColumnName[i] == 'LeadStatus'" class="text-capitalize font_12">
                                                    <span class="lead-tag" [style.background]="getLeadStatusColor(data)"></span>
                                                    <span *ngIf="data!='' && data !=null">{{data}}</span> <span *ngIf="data=='' || data ==null">Not Assign</span></span>
                                                </span>
                                                <ng-template #remainingData>
                                                    <span *ngIf="data != null">{{data.length > 20 ? data.substring(0, 20)+'...' : data}}</span>
                                                </ng-template>
                                                </td> -->
                                                </ng-container>
                                                <td>
                                                    <a (click)="openViewModal(audienceProfile, item.id,'detail')" class="btn without-hover btn-sm"><img src="assets/images/segmenteye.png"></a>
                                                    <a (click)="openViewModal(deleteConfirm, item.id, 'delete')" class="btn without-hover btn-sm"><img src="assets/images/delete.png"></a>
                                                </td>
                                            </tr>
                                            </ng-container>
                                            <tr *ngIf="!isRecords">
                                                <td class="no-records" colspan="8">No Records Yet</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div class="table-footer footer-gray">
                                <pagination-controls  *ngIf="totalRecords>20" id="foo" (pageChange)="dataForPaging($event)" maxSize="3" directionLinks="true" autoHide="true" class="pull-right" responsive="true" previousLabel="Previous" nextLabel="Next" screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
                                    screenReaderCurrentLabel="You're on page">
                                </pagination-controls>
                                <div class="textblue text-left">
                                    <div class="data-length"   *ngIf="totalRecords>20" > Show
                                        <select   [(ngModel)]="limit" (change)="calculateAudience()">
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="500">500</option>
                            </select>
                                    </div>
                                    <span> Total Count: {{totalRecords}}</span>
                                </div>
                            </div>
                            <div class="text-right mt-2 fixed_btn_btm_rght" *ngIf="isReassignOn || reassignLead"><button class="btn btn-dark" (click)="audiencesReassign();">Reassign</button>&nbsp; &nbsp; <button class="btn btn-dark" (click)="delete_reassign()">Cancel</button></div>
                            <div class="text-right mt-2 fixed_btn_btm_rght" *ngIf="isShowCheckbox"><button class="btn btn-dark" (click)="openViewModal(deleteConfirm, 0, 'delete')">Delete</button> &nbsp; &nbsp; <button class="btn btn-dark" (click)="delete_cancel()">Cancel</button></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    </section>
</main>

<ng-template #advanceSearchBlock>
    <app-fresh-audience-popup [modalRef]="modalRef" (filterClear)="filterClear($event)" [filterClose]="filterClose.bind(this)" [usingFor]="usingFor" [paramList]="paramList" [category]="parentMenu"></app-fresh-audience-popup>
</ng-template>

<ng-template #audienceProfile>
    <app-audience-detail [modalRef]="modalRef" [audienceId]="viewAudienceId" [audienceProfileClose]="audienceProfileClose.bind(this)" [category]="parentMenu"></app-audience-detail>
</ng-template>

<ng-template #createGraphBlock>
    <app-create-report-graph [modalRef]="modalRef" [viewAudiencePage]="true" [paramKey]="paramKeySend" [categoryName]="parentMenu"></app-create-report-graph>
</ng-template>

<ng-template #customForm>
    <div class="forgot-password-modal" *ngIf="customFormData">
        <div class="modal-header">
            <h4 class="modal-title pull-left">{{customFormData.name}}</h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                  <span aria-hidden="true">&times;</span>
              </button>
        </div>
        <div class="modal-body modal-user-management">
            <iframe [src]="customFormData.url | safe" class="border_0"  allow="geolocation *"></iframe>
        </div>
    </div>
</ng-template>

<ng-template #deleteConfirm>
    <div class="forgot-password-modal">
        <div class="modal-header">
            <h4 class="modal-title pull-left">Delete Audience</h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                  <span aria-hidden="true">&times;</span>
              </button>
        </div>
        <div class="modal-body modal-user-management">
            <div class="form-group mb-0">
                <p class="pt-2">Are you sure, you want to delete {{checkListData.length > 0 ? checkListData.length : 'this'}} audience?</p>
                <div class="d-flex justify-content-end mt-5">
                    <div><button class="btn remove-user-btn mr-4" (click)="modalRef.hide()" type="button">Dismiss</button></div>
                    <div><button class="btn save-btn" type="button" (click)="deleteAudience()">Yes</button></div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #deleteFilterConfirm>
    <div class="forgot-password-modal">
        <div class="modal-header">
            <h4 class="modal-title pull-left">Delete Filter</h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body modal-user-management">
            <div class="form-group mb-0">
                <p class="pt-2">Are you sure, you want to delete this filter?</p>
                <div class="d-flex justify-content-end mt-5">
                    <div><button class="btn remove-user-btn mr-4" (click)="modalRef.hide()" type="button">Dismiss</button></div>
                    <div><button class="btn save-btn" type="button" (click)="deleteFilter()">Yes</button></div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #addFilterConfirm>
    <div class="forgot-password-modal">
        <div class="modal-header">
            <h4 class="modal-title pull-left">Add Segment</h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide(); validFilterData = false;">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body modal-user-management">
            <div class="form-group mb-0">
                <input trim="blur" [ngClass]="{'invalid-control' : submitSegment && validFilterData && (applyFilterData.name == '' || applyFilterData.name == undefined)}" type="text" maxlength="80" [(ngModel)]="applyFilterData.name" class="form-control" autocomplete="off">
                <div class="d-flex justify-content-end mt-5">
                    <div><button class="btn remove-user-btn mr-4" (click)="modalRef.hide(); validFilterData = false;" type="button">Dismiss</button></div>
                    <div><button class="btn save-btn" type="button" (click)="saveFilter()">Save Segment</button></div>

                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #audiencesReassignForm>
    <div class="forgot-password-modal">
        <div class="modal-header">
            <h4 class="modal-title pull-left">Select User</h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide(); isReassignFormSubmit = false;">
              <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <form>
                <div class="form-group">
                    <label class="control-label required pt-0">User</label>
                    <select *ngIf="!isReassignAllUser" class="form-control" [ngClass]="{'invalid-control': reassignUser == '' && isReassignFormSubmit}" [(ngModel)]="reassignUser" name="reassignUser">
                  <option value="">Select</option>
                  <option *ngFor="let item of reassignUserList" value="{{item.id}}">{{item.userProfileName}}</option>
              </select>
                    <select *ngIf="isReassignAllUser" class="form-control" [ngClass]="{'invalid-control': reassignUser == '' && isReassignFormSubmit}" [(ngModel)]="reassignUser" name="reassignUser">
                  <option value="">Select</option>
                  <option *ngFor="let item of allUserList" value="{{item.id}}">{{item.userProfileName}}</option>
              </select>
                </div>
                <div class="row">
                    <!-- <div class="col-6 align-self-center">
                        <div class="custom-control custom-switch swich-button">
                            <span class="bm">Show all users: </span>
                            <input type="checkbox" [(ngModel)]="isReassignAllUser" name="isReassignAllUser" class="custom-control-input" id="isReassignAllUser" />
                            <label class="custom-control-label" for="isReassignAllUser"></label>
                        </div>
                    </div> -->
                    <div class="col-12 text-right">
                        <button class="btn btn btn-dark" (click)="audienceReassign()" type="submit">Submit</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>

<ng-template #audienceSettingsForm>
    <div class="forgot-password-modal">
        <div class="modal-header">
            <h4 class="modal-title pull-left">Customise Column</h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide(); isReassignFormSubmit = false;">
              <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <form>
                <div class="form-group">
                    <label class="control-label pt-0">Attributes</label>
                    <ng-select [items]="attributesList" name="audienceColumn" [(ngModel)]="audienceColumn" class="ngselect2" bindLabel="paramName" bindValue="paramKey" [multiple]="true" placeholder="Select Status" [maxSelectedItems]="15">
                        <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                            {{item.paramName}}
                            <p class="d-inline" *ngIf="(item.paramKey=='city' || item.paramKey=='state' || 
                            item.paramKey=='message' || item.paramKey=='country' || item.paramKey=='pincode' || item.paramKey=='city' || item.paramKey=='product_name' ||  item.paramKey=='address') ">(default)</p>
                        </ng-template>
                    </ng-select>
                    <div class="text-right form-text">Maximum 15 attributes can be added.</div>
                </div>
                <div class="text-right">
                    <button class="btn btn btn-dark" (click)="changeAudienceColumn()" type="submit">Submit</button>
                </div>
            </form>
        </div>
    </div>
</ng-template>

<ng-template #sendWhatsappMsg>
    <div class="forgot-password-modal">
        <div class="modal-header">
            <h4 class="modal-title pull-left">Send WhatsApp Message</h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide(); isReassignFormSubmit = false;">
              <span aria-hidden="true">&times;</span>
          </button>
        </div>



        <div class="modal-body">
            <form>
                Phone Number: {{whatsappMsgData.phone}}
                <div class="form-group">
                    <label class="control-label required pt-0">Message</label>
                    <textarea trim="blur" class="form-control" [ngClass]="{'invalid-control' : whatsappMsgData.smsText == ''}" [(ngModel)]="whatsappMsgData.smsText" rows="3" name="whatsappMsg"></textarea>
                </div>
                <div class="text-right">
                    <button class="btn btn btn-dark" (click)="sendWhatsapp()" type="submit">Submit</button>
                </div>
            </form>
        </div>
    </div>
</ng-template>

<app-channel-type [proceedEvent]="campaignProceed" [isIndividualCampaign]="true" [url]="apiurl"></app-channel-type>

