import { Location } from "@angular/common";
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { ToastrService } from "ngx-toastr";

import { CampaignJourneyService } from "../campaign-journey.service";

@Component({
    selector: 'app-journey',
    templateUrl: './campaign-manager.component.html',
    styleUrls: ['../campaign-journey.scss']
})

export class CampaignManagerComponent implements OnInit, OnDestroy {
    campaignList:any[] = [];
    modalRef: BsModalRef;
    pageNo: number = 1;
    dataSize: number = 20;
    public totalDataSize: number;
    @ViewChild('deleteConfirm') deleteConfirm: TemplateRef<any>;
    selectedData: any;
    constructor(
        private toastr: ToastrService,
        private modalService: BsModalService,
        private campaignJourneyService: CampaignJourneyService,
        private location:Location
    ){

    }

    ngOnInit() {
        this.getList();
    }

    ngOnDestroy(){

    }

    back()
    {
        this.location.back();
    }


    getList(){
        this.campaignList = []
        this.campaignJourneyService.getCampaignJourneyList(this.dataSize, this.pageNo-1).subscribe(data => {
            if(data.status){
                data.data.data.forEach(element => {
                    if(element.campName.length > 14){
                    element.showName = element.campName.substring(0,14);
                    element.showName = element.showName + '...'
                    } else{
                        element.showName = element.campName;
                    }
                    element.journeyUserIdEncode = encodeURIComponent(element.journeyUserId);
                    this.campaignList.push(element);
                });
                this.totalDataSize = data.data.totalElements;
            }
        })
    }

    dataForPaging(page){
        this.pageNo = page;
        this.getList();
    }

    goToReports()
    {
        return '/admin/report/'+this.selectedData.campName+'/'+this.selectedData.journeyUserIdEncode;
    }
    
    deleteConfirmation(item){
        this.selectedData = item;
        this.modalRef = this.modalService.show(this.deleteConfirm, { backdrop: 'static' });
    }

    deleteCampaign(){
        let postData = {
            campName : this.selectedData.campName,
            journeyUserId : this.selectedData.journeyUserId
        }
        this.campaignJourneyService.deleteCampaignJourney(postData).subscribe( data => {
            this.modalRef.hide();
            if(data.status){
                this.toastr.success(data.data,'Success');
                this.getList();
            } else {
                this.toastr.error(data.error_msgs,'Error');
            }
        })
    }

}