import { InputParamComponent } from './modules/admin/pages/input-param/input-param.component';
import { AutoCompleteParamComponent } from './shared/components/auto-complete-param/auto-complete-param.component';
import { Ng5SliderModule } from 'ng5-slider';
import { IntergationDocComponent } from './modules/admin/pages/home/intergration-doc.component';
import { PricingComponent } from './modules/admin/pages/home/pricing.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CollapseModule, BsDropdownModule, TooltipConfig } from 'ngx-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SignInComponent } from './modules/admin/pages/sign-in/sign-in.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ModalModule } from 'ngx-bootstrap/modal';
import { MarketerComponent } from './modules/admin/pages/marketer/marketer.component';
import { LayoutComponent } from './modules/admin/components/layout/layout.component';
import { AuthLayoutComponent } from './modules/admin/components/auth-layout/auth-layout.component';
import { ProjectIntegrationComponent } from './modules/admin/pages/project-integration/project-integration.component';
import { NaturalTypePipe } from './shared/pipes/natural-type.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DefaultPipe } from './shared/pipes/default.pipe';
import { AnimatedIfDirective } from './shared/directives/animated-if.directive';
import { FocusDirective } from './shared/directives/focus.directive';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Global } from './shared/models/global.model';
import { UserManagementComponent } from './modules/admin/pages/user-management/user-management.component';
import { ManageCampaignsComponent } from './modules/admin/pages/manage-campaigns/manage-campaigns.component';
import { ConfigurationComponent } from './modules/admin/pages/configuration/configuration.component';
import { EditAccountComponent } from './modules/admin/pages/edit-account/edit-account.component';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ShortNamePipe,TelleCallerNamePipe } from './shared/pipes/short-name.pipe';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgSelectModule } from '@ng-select/ng-select';
import { ColorPickerModule } from 'ngx-color-picker';
import { DatePipe } from '@angular/common';
import { IntegrationKitComponent } from './modules/admin/pages/integration-kit/integration-kit.component';
import { CounterDirective } from './shared/directives/counter.directive';
import { ChangePasswordComponent } from './modules/admin/pages/change-password/change-password.component';
import { RestorePassComponent } from './modules/admin/pages/restore-pass/restore-pass.component';
import { StaticHeaderComponent } from './modules/admin/pages/static-header/static-header.component';
import { StaticFooterComponent } from './modules/admin/pages/static-footer/static-footer.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { ViewAudienceComponent } from './modules/admin/pages/view-audience/view-audience.component';
import { AudienceProfileComponent } from './modules/admin/pages/audience-profile/audience-profile.component';   
import { BulkUploadComponent } from './modules/admin/pages/bulk-upload/bulk-upload.component';   
import { UploadConfirmComponent } from './modules/admin/pages/upload-confirm/upload-confirm.component';
import { CreateCampaignWithSegmentComponent } from './modules/admin/pages/create-campaign-with-segment/create-campaign-with-segment.component';
import { SegmentsComponent } from './modules/admin/pages/segments/segments.component';
import { CreateSegmentsComponent } from './modules/admin/pages/create-segments/create-segments.component';
import { SubscriptionInvoiceComponent } from './modules/admin/pages/subscription-invoice/subscription-invoice.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { CampaignCreateComponent } from './modules/admin/pages/campaign-create/campaign-create.component';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FreshAudiencePopupComponent } from './modules/admin/pages/fresh-audience-popup/fresh-audience-popup.component';

import { UpgradeSubscriptionComponent } from './modules/admin/pages/upgrade-subscription/upgrade-subscription.component';
import { ManagePluginCampaignsComponent } from './modules/admin/pages/manage-plugin-campaign/manage-plugin-campaign.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { SeoService } from './seo.service';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { CampaignTemplateComponent } from './modules/admin/pages/campaign-template/campaign-template.component';
import { CampaignGalleryComponent } from './modules/admin/pages/campaign-gallery/campaign-gallery.component';
import { announcementBarComponent } from './modules/admin/pages/announcement-template/bar.component';
import { announcementPopupComponent } from './modules/admin/pages/announcement-template/popup.component';
import { HttpConfigInterceptor } from './core/intreceptor/httpconfig.interceptor';
import { UserService } from './core/services/user.service';
import { BaseService } from './core/services/base.service';
import { ShareModule } from './shared/models/share.module';
import { CustomFormModule } from './modules/customForm/customForm.module';
import { TemplateBuilderModule } from './modules/templateBuilder/templateBuilder.module';
import { DashboardComponent } from './modules/admin/pages/dashboard/dashboard.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { DashboardService } from './core/services/dashboard.service';
import { CatalogueModule } from './modules/catalogue/catalogue.module';
import { PhonePipe } from './shared/pipes/phone.pipe';
import { AudienceReportComponent } from './modules/admin/pages/audience-report/audience-report.component';
import { AnalysisComponent } from './modules/admin/pages/analysis/analysis.component';
import { announcementBarListComponent } from './modules/admin/pages/announcement-template/bar.component-list';
import { announcementPopupListComponent } from './modules/admin/pages/announcement-template/popup.component-list';
import { LeadStatusComponent } from './modules/admin/pages/lead-status/lead-status.component';
import { CategoryComponent } from './modules/admin/pages/category/category.component'; 
import { ServiceWorkerModule } from '@angular/service-worker';
import { UrlShortnerComponent } from './modules/admin/pages/url-shortner/url-shortner.component';
import { LandingPageComponent } from './modules/admin/pages/landing-page/landing-page.component';
import { LandingPageViewComponent } from './modules/admin/pages/landing-page-view/landing-page-view.component';
import { environment } from '../environments/environment';
import { AdvancePopupComponent } from './modules/admin/pages/announcement-template/advance-popup.component';
import { RoleManagementComponent } from './modules/admin/pages/user-management/role-management.component';
import { SplashScreenComponent } from './modules/splashScreen/splash-screen.component';
import { PermissionItemComponent } from './modules/admin/pages/user-management/permission-item.component';
import { AccountLayoutComponent } from './modules/admin/components/account-layout/account-layout.component';
import { QRCodeModule } from 'angular2-qrcode';
import { CallAssistModule } from './modules/callassist/callassist.module';
import { AttributesNamePipe } from './shared/pipes/attributes-name.pipe';
import { AddAudienceComponent } from './modules/admin/pages/add-audience/add-audience.component';
import { CampaignJourneyModule } from './modules/campaign-journey/campaign-journey.module';
import { DownloadAudienceDataComponent } from './modules/admin/pages/download-audience-data/download-audience-data.component';
import { DownloadAudienceDataReports } from './modules/admin/pages/download-audience-data-reports/download-audience-data-reports.component';
import { FollowUpComponent } from './modules/admin/pages/follow-up/follow-up.component';
import { CacheService } from './core/services/cache.service';
import { InvoiceEstimateModule } from './modules/invoice-estimate/invoice-estimate.module';
import { SafePipe } from './safe.pipe';
import { CreateReportGraphComponent } from './create-report-graph/create-report-graph.component';
import { AsyncPipe } from '../../node_modules/@angular/common';
import { TurnAroundTimeComponent } from '../app/modules/admin/pages/turn-around-time/turn-around-time.component';
import { LoggedinSessionComponent } from './modules/admin/components/loggedin-session/loggedin-session.component';
import { AuthenticationMethodsComponent } from './modules/admin/components/authentication-methods/authentication-methods.component';

@NgModule({
  declarations: [
    AppComponent,
    StaticHeaderComponent,
    StaticFooterComponent,
    SignInComponent,
    MarketerComponent,
    LayoutComponent,
    AuthLayoutComponent,
    AccountLayoutComponent,
    ProjectIntegrationComponent,
    NaturalTypePipe,
    DefaultPipe,
    AnimatedIfDirective,
    FocusDirective,
    UserManagementComponent,
    ManageCampaignsComponent,
    ConfigurationComponent,
    EditAccountComponent,
    ShortNamePipe,
    TelleCallerNamePipe,
    IntegrationKitComponent,
    CounterDirective,
    ChangePasswordComponent,
    RestorePassComponent,
    ViewAudienceComponent,
    AudienceReportComponent,
    AnalysisComponent,
    AudienceProfileComponent,
    BulkUploadComponent,
    UploadConfirmComponent,
    CreateCampaignWithSegmentComponent,
    SegmentsComponent,
    CreateSegmentsComponent,
    SubscriptionInvoiceComponent,
    CampaignCreateComponent,
    CampaignTemplateComponent,
    CampaignGalleryComponent,
    FreshAudiencePopupComponent,
    AutoCompleteParamComponent,
    InputParamComponent,
    PricingComponent,
    IntergationDocComponent,
    UpgradeSubscriptionComponent,
    DashboardComponent,
    ManagePluginCampaignsComponent,
    announcementPopupComponent,
    announcementPopupListComponent,
    announcementBarComponent,
    announcementBarListComponent,
    AdvancePopupComponent,
    PhonePipe,
    LeadStatusComponent,
    CategoryComponent,
    UrlShortnerComponent,
    LandingPageComponent,
    LandingPageViewComponent,
    RoleManagementComponent,
    SplashScreenComponent,
    PermissionItemComponent,
    AttributesNamePipe,
    AddAudienceComponent,
    DownloadAudienceDataComponent,
    DownloadAudienceDataReports,
    FollowUpComponent,
    SafePipe,
    CreateReportGraphComponent,
    TurnAroundTimeComponent,
    AuthenticationMethodsComponent,
    LoggedinSessionComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    TabsModule.forRoot(),
    ModalModule.forRoot(),
    AccordionModule.forRoot(),
    CollapseModule.forRoot(),
    BsDropdownModule.forRoot(),
    PopoverModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    // NgbModule.forRoot(),
   
    ShareModule,
    CustomFormModule,
    InvoiceEstimateModule,
    CatalogueModule,
    CampaignJourneyModule,
    CallAssistModule,
    TemplateBuilderModule,
    ColorPickerModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule, 
    ToastrModule.forRoot({
      preventDuplicates: true
    }),
    NgxCaptchaModule,
    Ng2SearchPipeModule,
    Ng5SliderModule,
    PickerModule,
    AutocompleteLibModule,
    NgApexchartsModule,
    QRCodeModule,
    ServiceWorkerModule.register('cronberry-worker.js', { enabled: environment.production }),
  
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    AsyncPipe,
    Global,
    DatePipe,
    PhonePipe,
    TooltipConfig,
    SeoService,
    BaseService,
    UserService,
    DashboardService,
    CacheService,
  ],
  entryComponents:[
    AddAudienceComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
