import { Component, OnInit, TemplateRef, OnDestroy, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService, BsDatepickerConfig } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CampaignService } from '../../../../core/services/campaign.service';
import { distinctUntilChanged, debounceTime, switchMap, tap, catchError, map } from 'rxjs/operators'
import { Subject, of, concat } from 'rxjs';
import { Global } from '../../../../shared/models/global.model';
import { DatePipe } from '@angular/common';
import { ReportService } from '../../../../core/services/report.service';
import { ProjectService } from '../../../../core/services/project.service';
import { AppConstants } from '../../../../constants/app.constants';
import { ActivatedRoute, Event, NavigationCancel, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Location } from '@angular/common'
import { DatePickerObj } from '../../../../core/modal/datePicker';
import * as moment from 'moment';
import { SpinnerService } from '../../../../core/services/spinner.service';
import { UserService } from '../../../../core/services/user.service';
import { SubUserObj, UserFilterObj } from '../../../../core/modal/user';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { AttributeObj, SourceObj } from '../../../../core/modal/project';
import { AddAudienceComponent } from '../add-audience/add-audience.component';
import { PhonePipe } from '../../../../shared/pipes/phone.pipe';
import { ConfigurationService } from '../../../../core/services/configuration.service';
import { CacheService } from '../../../../core/services/cache.service';
import { CustomFormService } from '../../../../modules/customForm/customForm.service';
import { CustomFormObj } from '../../../../modules/customForm/customForm.modal';
import { ApiConstants } from '../../../../constants/api.constants';
import { SegmentService } from '../../../../core/services/segment.service';
import { environment } from '../../../../../environments/environment';
import { AudienceFilter } from '../../../../core/services/cache.modal';
import { debounce } from 'lodash';
import { tick } from '@angular/core/testing';

@Component({
    selector: 'app-view-audience',
    templateUrl: './view-audience.component.html',
    styleUrls: ['./view-audience.component.scss']
})

export class ViewAudienceComponent implements OnInit, OnDestroy {
    records: any = [];
    isRecords: boolean = false;
    showFilter: boolean = false;

    campaign: any = 'Push';
    filters: any = [];
    selectedFilters: any = [];
    audienceJson: any = { 'text': 'select audience' };

    dateMsg: any = 'no date selected';
    startDate: any;
    endDate: any;
    dateChecked: boolean = false;

    showAudience: boolean = false;
    audience: any = 'Calculate Audience';
    modalRef: BsModalRef;

    validFilters: any = false;
    
    page: number = 1;
    limit: number = 20;
    totalRecords: number = 0;

    searchText: any = '';

    paramList: any;
    fromDate: any = '';
    toDate: any = '';

    bsConfig: Partial<BsDatepickerConfig>;
    maxDate : any;

    exportFilters: any = [];
    selectedAllParam: boolean = false;
    myObserver:any;
    viewAudienceId: any;
    audienceStatus: any;
    leadStatusParamsId: any;
    statusParams: any[];
    audienceLeadData: any[] = [];
    leadDefaultDate: DatePickerObj;
    userRole:number;
    userRoleType:number;
    isShowLeadReportBox: boolean = false;
    leadTotal : number[] = [];
    adminRoleid:number;
    telecallerRoleType:number;
    userId:string;
    fromParamDate: any;
    toParamDate: any;
    attributesParam:any;
    dateType:any;
    categoryNameShow:any;
    defaultDateRangeFilter:boolean = false;
    public showLead: boolean = false;
    public filterDateRange: DatePickerObj;
    public dateRanges: any;
    public selectedUserId: any = null;
    public selectedUserName: string;
    public selectedLeadStatus: any = '';
    public leasdStatusList: string[] = [];
    waitCount: any = 3;
    allselected:boolean = false;
    masterSelected:any=false;
    checkedCategoryList:any = [];
    pushNotificationId:any;
    leadParamsData:any;
    @ViewChild("audienceProfile") audienceProfile: TemplateRef<any>;
    @ViewChild("createGraphBlock") createGraphBlock: TemplateRef<any>;
    @ViewChild("customForm") customForm: TemplateRef<any>;
    @ViewChild("audiencesReassignForm") audiencesReassignForm: TemplateRef<any>;
    @ViewChild('attrListPopup') attrListPopup: TemplateRef<any>;

    isReassignFormSubmit: boolean = false;
    isReassignOn: boolean = false;
    sourceList: string[] = [];
    reassignUserList: SubUserObj[] = [];
    allUserList: SubUserObj[] = [];
    reassignUser: string = '';
    sourceFormIdList: any[] = [];
    usingFor: string = 'viewAudience';
    campaignProceed:Subject<object> = new Subject();
    isChannelType: boolean = false;
    isReassignAllUser: boolean = false;
    isUrlFilterApplied: boolean = false;
    tempModalData:any;
    filterList:UserFilterObj[] = [];
    applyFilterData: UserFilterObj = {};
    validFilterData: boolean = false;
    isFilterApplied: boolean = false;
    audienceColumn: any;
    audienceColumnName: string[] = [];
    attributesList:AttributeObj[] = [];
    isShowCheckbox: boolean = false;
    modalRefAddLead: BsModalRef;
    selectDateTypeTwo:any=false;
    followUpRequest;
    followParamId;
    dataParams;
    dataParamBetweenDate;
    queryStrParams:any=false;
    queryStrParams2:boolean;
    stageTypeList;
    followFilter;
    leadStatusSubscription;
    selectedFollowUp:any=new Array();
    cacheServiceProps;
    leadStatusData;
    leadStatusApi;
    filterLen;
    dataParamNotIn;
    startDateEntry;
    endDateEntry;
    dataRedirecting:any=false;
    followUpAllTypeApply:any=false;
    showEntryDateFilter:boolean=false;
    showDownloadBtn:boolean = false;
    sourceParamCheck:any;
    customFormList:any;
    customFormData:any;
    fixedParams:any;
    inputParams:any;
    searchAttribute:any;
    showMapParams:any= new Array();
    showMapParams_extra_field:any = new Array();
    xlsTableCol:any = new Array();
    checkListData:any = new Array();
    reassignLead:boolean = false;
    audienceColumnForTable:any = [];
    paramKeySend:any = true; 
    emailApiUrl:any = environment.emailPushUrl;
    smsApiUrl:any = environment.smsPushUrl;
    whatsaappApiUrl:any = environment.whatsappApiUrl;
    @ViewChild('sendWhatsappMsg') sendWhatsappMsg : TemplateRef<any>;
    whatsappMsgData : {phone:string,smsText:string};
    whatsappApiUrl:any = environment.whatsappApiUrl;
    apiurl:any;
    graphFilter:boolean = false;
    applyExtraFilter:boolean = false;
    graphAttrStore:any = []
    audienceFilter:AudienceFilter[] = [];
    getFilterFromStorage:any = []
    fetchUser:any;
    maxRange:any;
    maxRangeStore:any;
    fromDateSelect:any;
    searchString:any;
    parentMenu:string;
    storeSelectedValues:any = new Array();
    queryParamsFromMenu:any;
    submitSegment:boolean=false;
    dataList:any;
    subscriptionParams:any;
    isSubscribe: boolean = true;

    constructor(
        private modalService: BsModalService,
        private toastr: ToastrService,
        private campaignService: CampaignService,
        private reportService: ReportService,
        private datePipe: DatePipe,
        public global: Global,
        private projectService: ProjectService,
        private spinner: SpinnerService,
        private route: ActivatedRoute,
        private location: Location,
        private userService: UserService,
        private authenticationService: AuthenticationService,
        private phonePipe: PhonePipe,
        private configurationService: ConfigurationService,
        private router:Router,
        private cacheService:CacheService,
        private customFormService:CustomFormService,
        private Segmentservice: SegmentService
    ) {
      this.fetchFilterDetails = debounce(this.fetchFilterDetails,500);
      this.fetchAudienceData = debounce(this.fetchAudienceData,500);
      this.setStatus = debounce(this.setStatus,500);
    }


    ngAfterViewInit(){
      if(this.myObserver){
      this.myObserver.unsubscribe();
      }
      this.myObserver = this.router.events.subscribe((event: Event) => {        
        if (event instanceof NavigationEnd) {
            this.subscriptionParams = this.route.snapshot.paramMap.get('dynamicparam');
              let paramDataSet = {params:{dynamicparam:this.subscriptionParams}};
              this.global.Channel.Attributes = []
              this.selectedFilters = []
              this.dataList = [];
              this.global.ClearChannel();
              this.removeFilters();
              this.dataParams = paramDataSet; 
              this.queryParamsFromMenu = paramDataSet;
              this.parentMenu = this.subscriptionParams;          
              this.setStatus();
        }
    });
    }


    ngOnDestroy(){
      this.global.Channel.Attributes.forEach(element => {
        element.selected = [];
        element.addedValues = [];
      });
      if(this.leadStatusApi != undefined)
      {
        this.leadStatusApi.unsubscribe();
      }
      if(this.modalRef){
        this.modalRef.hide();
      }
      if(this.myObserver){
        this.myObserver.unsubscribe();
        }
    }

  

    ngOnInit() {
      this.fetchCategory()
      this.route.paramMap.subscribe(data=>{
        if(data){
            this.queryParamsFromMenu = data;
            this.parentMenu = this.queryParamsFromMenu['params'].dynamicparam;
            this.dataParams = data;
        }
    })
      this.myObserver = this.router.events.subscribe((event: Event) => {
        if (event instanceof NavigationStart) {
          let checkWithoutNotification;
          let checkWithNotifaction;
          this.route.paramMap.subscribe(data=>{
            this.leadParamsData = data;
            if(this.leadParamsData.params && this.leadParamsData.params.dynamicparam){
              checkWithoutNotification = true;
              this.route.queryParams.subscribe(data=>{
                if(data.notification){
                checkWithNotifaction = true;
                }
              });
              if(!checkWithNotifaction && checkWithoutNotification){
                if(this.modalRef){
                  this.modalRef.hide();
                  this.global.ClearChannel();
                  this.removeFilters();
                  this.myObserver.unsubscribe();
                  }
              }
            }
          });
          
          this.route.queryParams.subscribe(data=>{
            if(data.notification){
            this.calculateAudience();
            this.myObserver.unsubscribe();
            }
          });
        }
    });


        
        
        this.projectService.getMaxRange().then(data=>{
          this.maxRangeStore =  localStorage.getItem('maxRange');   
        })
        this.spinner.show()
        this.global.ClearChannel();
        this.maxDate = moment();
        this.dateRanges = this.global.customDateRange();
        this.adminRoleid = AppConstants.ADMIN_ROLEID;
        this.telecallerRoleType = AppConstants.TELECALLER_ROLE_TYPE;
        this.userRole = this.authenticationService.getUserRole();  
        this.userRoleType = this.authenticationService.getUserRoleType();
        
        let getFilter = JSON.parse(localStorage.getItem('filterData'));
        this.getFilterFromStorage = getFilter;        

        this.projectService.fetchCategory({}).then(data => {
          if (data) {
            this.statusParams = []
            this.dataList = data;
            // this.global.ClearChannel();
            // this.removeFilters();
            this.dataList.forEach(element => {
              if(element.linkRewrite == this.parentMenu){
                this.categoryNameShow = element.name;
                element.leadStatusList.forEach(status => {                  
                  this.statusParams.push({value:status.stageName, label:status.stageName, color:status.stageColour, type:status.stageType});              
                });
              }
              });
            this.route.queryParams.subscribe(data=>{
              if(data.notificatioId){
                this.pushNotificationId = data.notificatioId;
              }
              if(data.filterbycount != undefined){
                this.searchText = data.filterbycount;
              }
              if(data.datetype){
                if(data.paramtype == 'date'){           
                  this.audienceFilter.push({paramKey:data.datetype, paramValue:'date(' + this.datePipe.transform(data.fromDate, "yyyy-MM-dd 00:00:00") + ') and date(' + this.datePipe.transform(data.toDate, "yyyy-MM-dd 23:59:59") +')', text1:data.fromDate,text2:data.toDate });
                }
              }
            });            
            if(this.dataParams.params.type == 'audience') {
                this.calculateAudience();
                this.openViewModal(this.audienceProfile, this.dataParams.params.id);
              } else if(this.dataParams.params.type == 'search') {
                  this.searchText = this.dataParams.params.id;
                  this.calculateAudience();
              }
                                                    
              if(this.dataParams.params.id && ! this.dataParams.params.type){
                this.openViewModal(this.audienceProfile, this.dataParams.params.id, 'detail')
              }
              this.getFilters(); 
      }
    })

        this.leadDefaultDate = {startDate:moment().subtract(6, 'days'), endDate:moment()};
        this.getFilterList();
        this.getCustomFormList(); 
    }

    setStatus(){
      this.searchText = "";
      if(this.modalRef){
        this.modalRef.hide();
      }
      this.projectService.fetchCategory({}).then(data => {
        if (data) {
          this.statusParams = []
          this.global.Channel.Attributes = []
          this.selectedFilters = []
          this.dataList = data;
          this.dataList.forEach(element => {
            if(element.linkRewrite == this.parentMenu){
              this.categoryNameShow = element.name;
              element.leadStatusList.forEach(status => {                
                this.statusParams.push({value:status.stageName, label:status.stageName, color:status.stageColour, type:status.stageType});              
              });
              this.getFilterList()
            }
            }); 
          this.getFilters(); 
    }
  })
    }

    createGraph(){
      this.modalRef = this.modalService.show(this.createGraphBlock, {
        class: "modal-rightblock", backdrop: 'static'
    });
    }

    


    getCustomFormList() {
      this.customFormService.getCustomFormsData().then(data => {
          if (data != undefined) {
              this.customFormList = data;              
          }
      })
  }


  showForm(data: CustomFormObj) {
    
    this.customFormData = data;
    this.customFormData.url = ApiConstants.CUSTOM_FORM_LINK + data.linkRewrite;
    this.customFormData.iframe = '<iframe  src="' + ApiConstants.CUSTOM_FORM_LINK + data.linkRewrite + '" height="' + data.height + '" width="100%" title="' + data.name + '" style="border:none;"></iframe>'
    
    this.modalRef = this.modalService.show(this.customForm, {
        backdrop: 'static'
    });
}


    collapsible(e)
    {
      if(e=='show')
      {
        this.queryStrParams2 = false
      }

      else if(e == 'hide')
      {
        if(this.queryStrParams == false)
        {
          this.queryStrParams = true;
          return
        }
        this.queryStrParams2 = true;
      }
    }
    openDateRange()
    {      
      document.getElementById('openDateRangePicker').click;
    }

    getAudienceData(){
        // this.route.params.subscribe( params => {       
                var srchiveStatus = this.statusParams.find(x => x.type === 'ARCHIVED');
                if(srchiveStatus != undefined){
                  if(this.getFilterFromStorage == null || this.getFilterFromStorage.length == 0 || this.getFilterFromStorage == undefined){
                    this.filters.forEach( element => {
                        if(element.paramKey === 'lead_status'){
                            
                            element.selected = []
                            element.checked = true;
                            element.values = []
                            element.selected.value = 'not_in';
                            element.selected.push({paramKey:element.paramKey,paramValue:srchiveStatus.value});
                            element.values.push({paramKey:element.paramKey,paramValue:srchiveStatus.value})
                            element.addedValues = element.selected;
                            element.archive = true;

                            let checkToPush = this.selectedFilters.find(x=>x.paramKey == element.paramKey);
                            if(checkToPush == undefined){
                              this.global.Channel.Attributes.push(element);
                              this.selectedFilters.push(element); 
                            }                           
                        }
                    });
                    this.calculateAudience();
                  }
                } else {
                  if(this.selectedFilters.length == 0 && this.searchText == ""){
                  this.filters.forEach( element => {
                    if(element.paramKey === 'entry_date'){
                        element.selected = []
                        element.checked = true;
                        element.values = []
                        element.selected.value = 'between';
                        let fromDate = moment().subtract(30, 'days')
                        let todayDate = moment().subtract();
                        this.startDateEntry = this.datePipe.transform(fromDate,'yyyy-MM-dd');
                        this.endDateEntry = this.datePipe.transform(todayDate,'yyyy-MM-dd');
                        element.selected.text1 = new Date(this.startDateEntry);
                        element.selected.text2 = new Date(this.endDateEntry);
                        element.defaultDateFilter = true;
                        this.showEntryDateFilter = true;
                        this.global.Channel.Attributes.push(element);
                        this.selectedFilters.push(element);
                        }
                    });
                this.isFilterApplied = true;
                this.isUrlFilterApplied = false;
                this.defaultDateRangeFilter = true;
              }
                    this.calculateAudience('reloadPagination');
              }
            
            
            if(this.filterDateRange.startDate == null){
                this.filterDateRange =  {startDate: moment().subtract(29, 'days'), endDate: moment()};
            }
        // });    
    }


  

  AnyChannelSelected() {
    if (!this.global.AnyChannelSelected() && this.selectedFilters.length == 0) {
      this.usingFor = 'viewAudience';
      this.sourceFormIdList = [];
      this.isFilterApplied = false;
      this.clearFilters();
      this.calculateAudience('reloadPagination');
      return false;
    }
    return true;
  }

  removeChannel(channelType) {
    this.global.ClearSpecificChannel(channelType);
    this.AnyChannelSelected();
  }

  selectedInFilter(i, type){        
    setTimeout(() => {
      this.selectedFilters[i].selected.value = type;        
    }, 100);
  }

  removeAudience(item?) {
    if(item){
      item.selectType = item.selected.value;
    }
    this.showAudience = false;
    this.audience = 'Calculate Audience';
  }

    addFilter(value, bypass,isFilterApplied?) {
        this.removeAudience();
        var filterData ;
        this.audienceJson = JSON.parse(value);
        
        if (this.audienceJson.id == undefined) {
            this.removeFilters();
            return;
        }
        if(isFilterApplied){
            filterData = this.paramList.find(x => x.id === this.audienceJson.id);
        }
        if (!bypass)
            this.audienceJson.selected = {};

        let selectedFilter = JSON.parse(localStorage.getItem('selectedFilter'));
        let paramList = JSON.parse(localStorage.getItem('paramList'));
        
        if (this.audienceJson.paramDatatype == 'Numeric') {
            this.audienceJson.selected.value = ">=";

          if(selectedFilter != null)
          {
          selectedFilter.forEach(element => {
            
            let objId = element.id == this.audienceJson.id;
            if(objId)
            {
              this.audienceJson.selected = element.selected;              
            }
          });
          }

          if(paramList)
          {
          paramList.forEach(element => {
            let paramId = element.id == this.audienceJson.id;
            if(paramId)
            {
              this.audienceJson.selected.value = element.paramOprator;              
            }
          });
        }
        }
        if (this.audienceJson.paramDatatype == 'Date') {
          this.audienceJson.selected.value = "between"; 
          if(selectedFilter != null)
          {
          selectedFilter.forEach(element => {
            
            let objId = element.id == this.audienceJson.id;
            if(objId)
            {
              this.audienceJson.selected = element.selected;              
            }
          });
          }

          if(paramList)
          {
          paramList.forEach(element => {
            let paramId = element.id == this.audienceJson.id;
            if(paramId){
              var date = element.paramValue.replace("') and date('", ',').replace("date('","").replace("')","").split(',');
              if(date[0]){
              this.audienceJson.selected.text1 = new Date(date[0].split(' ')[0]);
              
              }
              if(date[1]){
              this.audienceJson.selected.text2 = new Date(date[1].split(' ')[0]);
              }
              this.audienceJson.selected.value = element.paramOprator;              
            }
          });
        }
        }
        if (this.audienceJson.paramDatatype == 'String' || this.audienceJson.paramDatatype == 'string' || this.audienceJson.paramDatatype == 'Email' || this.audienceJson.paramDatatype == 'Mobile') {
            this.getFilterDetails(this.audienceJson,filterData);
        } else {
            if(filterData != undefined){              
                this.audienceJson.selected.value = filterData.paramOprator;
                if (this.audienceJson.paramDatatype === 'Numeric') {
                    this.audienceJson.selected.text1 = filterData.paramValue;
                    if(filterData.paramOprator == 'between'){
                        var value = filterData.paramValue.split(' and ');
                        this.audienceJson.selected.text1 = value[0];
                        this.audienceJson.selected.text2 = value[1];
                    }
                }
                if (this.audienceJson.paramDatatype === 'Date') {
                    if (filterData.paramOprator == "between") {
                        var date = filterData.paramValue.replace("') and date('", ',').replace("date('","").replace("')","").split(',');
                        this.audienceJson.selected.text1 = new Date(date[0]);
                        this.audienceJson.selected.text2 = new Date(date[1]);
                    } else {
                        var date = filterData.paramValue.replace("date('", '').replace("')","");
                        this.audienceJson.selected.text1 = new Date(date);
                    }
                }
                if(JSON.parse(this.applyFilterData.filterJson).paramList.length == (this.selectedFilters.length + 1)){
                    setTimeout(() => {
                        this.calculateAudience('reloadPagination');
                    }, 100);
                }
                
            }
            this.selectedFilters.push(this.audienceJson);            
        }
        
        for (let index = 0; index < this.filters.length; index++) {
        if (this.filters[index].id == this.audienceJson.id) {
            this.filters.splice(index, 1);
            return;
        }
        }
    }

  removeFilter(obj) {
    if(obj.paramKey=='entry_date')
    {
      this.showEntryDateFilter = false;
      this.dataRedirecting = false;
      this.showDownloadBtn = false;
    }
    this.removeAudience();
    if(this.getFilterFromStorage){
    for (let index = 0; index < this.getFilterFromStorage.length; index++) {
      if (this.getFilterFromStorage[index].paramKey == obj.paramKey) {
        this.getFilterFromStorage.splice(index, 1);
      }
    }
  }

    for (let index = 0; index < this.selectedFilters.length; index++) {
      if (this.selectedFilters[index].id == obj.id) {
        this.selectedFilters.splice(index, 1);
        this.global.ClearSpecificChannelId('Attributes', obj.id);
        this.AnyChannelSelected();
        return;
      }
    }
  }

  removeFilters() {
    var length = this.selectedFilters.length;
    for (let index = 0; index < length; index++) {
      this.selectedFilters.splice(0, 1);
    }
    this.global.ClearSpecificChannel('Attributes');
    this.showEntryDateFilter = false;
    this.dataRedirecting = false;
    this.showDownloadBtn = false;
    this.getFilterFromStorage = [];
    this.filters = this.projectService.filterAttributeList(['all']);
  }

  delete_cancel()
  {
    localStorage.removeItem('checkedLeadDelete');
    this.isShowCheckbox = false;
    this.usingFor = 'viewAudience';
    this.clearFilters(); 
    this.resetDate(); 
    this.resetUser(); 
    this.calculateAudience();
  }

  delete_reassign()
  {
    localStorage.removeItem('checkedLeadReasign');
    this.isShowCheckbox = false;
    this.usingFor = 'viewAudience';
    this.clearFilters(); 
    this.resetDate(); 
    this.resetUser(); 
    this.calculateAudience();
  }

    getFilters(follow?) {    
      this.filters = this.projectService.filterAttributeList(['all']);
      this.fetchUser = JSON.parse(JSON.stringify(this.projectService.getSourceList()));
      if(this.getFilterFromStorage){        
      this.getFilterFromStorage.forEach(element => {        
         if(element.paramKey == 'source'){
          let splitSource = element.paramValue.split(',');
          let splitSourceId = element.text1.split(',');
          for(let i=0; i<splitSourceId.length; i++){
            let checkId = this.fetchUser.find(x=>x.pluginId == splitSourceId[i]);
            if(checkId){
              for (let i = 0; i < this.fetchUser.length; i++) {
                if(checkId == this.fetchUser[i]){
                  this.fetchUser[i].checked =  true
                }
            }
            }
          }
          this.global.Channel.Source = this.fetchUser.filter(function (item: any) {
            return item.checked == true;
          });
        } else if(element.paramKey == 'pluginFormIds'){
          this.sourceFormIdList = element.paramValue.split(',');
          for(let i=0; i<this.sourceFormIdList.length; i++){
            if(this.sourceFormIdList[i] == ""){
              delete this.sourceFormIdList[i]
            }
          }
          this.sourceFormIdList = this.sourceFormIdList.filter(p=>{
            return p != null;
          })          
          this.sourceFormIdList = this.sourceFormIdList.filter(p=>{
            return p != null;
          })
          this.global.Channel.sourceFormIds = this.sourceFormIdList;
          this.global.Channel.Source.forEach(element => {
            element.selectedForms = [];
            element.assignMappingDTOs.forEach(elem => {
              let checkPluginForm = this.sourceFormIdList.find(x=> x == elem.id);
              if(checkPluginForm){
                if(elem.id == checkPluginForm){
                elem.checked = true;
                element.selectedForms.push({formName:elem.formName})
                }
              }
            });
          });          
        } else if(element.paramKey == 'user') {          
          this.selectedUserId = decodeURIComponent(element.paramValue.split('&&')[0]);
          this.selectedUserName = element.text1;
          this.showLead = true;
        } else if(element.paramKey == 'userFromReport') {          
          this.selectedUserId = decodeURIComponent(element.paramValue);
          this.selectedUserName = element.text1;
          this.showLead = true;
        }
          else{
          let checkFilter = this.filters.find(x=>x.paramKey == element.paramKey);
          if(checkFilter != undefined){
            element.paramDatatype = checkFilter.paramDatatype;
          }
        }
      });

      this.filters.forEach(element => { 
        let getFilter = this.getFilterFromStorage.find(x=>x.paramKey == element.paramKey);
        if(getFilter != undefined){          
        if(getFilter.paramDatatype == 'Date'){      
        element.type = 'dateRange'
        element.selected = []
        element.checked = true;
        element.selected.value = 'between';
        element.selected.text1 = new Date(getFilter.text1);
        if(getFilter.text1){
          if (navigator.userAgent.search("Safari") >= 0 && navigator.userAgent.search("Chrome") < 0) 
          {
            getFilter.text1 = getFilter.text1.replace(' ','T');     
          }
          element.selected.text1 = new Date(getFilter.text1);  
        }
        if(getFilter.text2){
          if (navigator.userAgent.search("Safari") >= 0 && navigator.userAgent.search("Chrome") < 0) 
          {
            getFilter.text2 = getFilter.text2.trimStart().replace(' ','T');
          }
          element.selected.text2 = new Date(getFilter.text2);
        } else{
        element.selected.text2 = new Date(getFilter.text1);
        }
        if(getFilter.missedReport && getFilter.missedReport == 'missedDateTime'){
          element.selected.value2 = "missedDateTime";
        }
        if(getFilter.missedReport && getFilter.missedReport == 'upcoming'){
          element.selected.value2 = "upcoming";
        }
        if(getFilter.missedReport && getFilter.missedReport == 'upcoming_next'){
          element.selected.value2 = "upcoming_next";
        }

        if(getFilter.missedReport && getFilter.missedReport == 'upcoming_by_profile_type'){
          element.selected.value2 = "upcoming_by_profile_type";          
        }        
        this.global.Channel.Attributes.push(element);
        this.selectedFilters.push(element);
        }
        if(getFilter.paramDatatype == 'String'){
          element.selected = []
          element.checked = true;
          element.values = []
          element.selected.value = getFilter.paramOprator ? getFilter.paramOprator : 'in';
          if (getFilter.text1.charAt(0) === "'" && getFilter.text1.charAt(getFilter.text1.length -1) === "'"){
              getFilter.text1 = getFilter.text1.substr(1,getFilter.text1.length -2)
          }
          let checkTextLen = getFilter.text1.split("','");
          if(getFilter.reportType == undefined || getFilter.reportType != 'followup' && getFilter.reportType != 'completed' && getFilter.reportType != 'followupCompleted'){
          for(let i=0; i<checkTextLen.length; i++){
            element.selected.push({paramKey:element.paramKey,paramValue:checkTextLen[i]});
            element.values.push({paramKey:element.paramKey,paramValue:checkTextLen[i]})
          }
          }
          if(getFilter.reportType == 'followup'){
            this.statusParams.forEach(elementStatus => {                                                                           
              if(elementStatus.type == 'FOLLOW_UP' || elementStatus.type == 'CALL_LATER')
              {
                this.selectedFollowUp.push(elementStatus.label);
              }
              });
          }
          if(getFilter.reportType == 'completed'){
            this.statusParams.forEach(elementStatus => {                                                             
              if(elementStatus.type == 'COMPLETED')
              {
                this.selectedFollowUp.push(elementStatus.label);
              }
              });
          } if(getFilter.reportType == 'followupCompleted'){
            this.statusParams.forEach(elementStatus => {                                                             
              if(elementStatus.type == 'COMPLETED' || elementStatus.type == 'FOLLOW_UP' || elementStatus.type == 'CALL_LATER')
              {
                this.selectedFollowUp.push(elementStatus.label);
              }
              });
          } 
          if(this.selectedFollowUp.length > 0){
            
          for(let i=0; i<this.selectedFollowUp.length; i++){
            element.selected.push({paramKey:element.paramKey,paramValue:this.selectedFollowUp[i]});
            element.values.push({paramKey:element.paramKey,paramValue:this.selectedFollowUp[i]})
          }
          }
          this.global.Channel.Attributes.push(element);
          this.selectedFilters.push(element);
          }

          if(getFilter.paramDatatype == 'Numeric'){
            element.selected = []
            element.checked = true;
            element.values = []
            element.values.push({paramKey:element.paramKey,paramValue:getFilter.text1})
            element.selected.value = '=';
            element.selected = {text1: getFilter.text1, value: "="}
            this.global.Channel.Attributes.push(element);
            this.selectedFilters.push(element);
            }
      }
      });
      this.calculateAudience();
    }    
          this.projectService.fetchAttributeList().then(data => {
            if(data){
                this.attributesList = this.projectService.filterAttributeList(['all']);
               let filterStoreByCat = []
               let systemDefineAttr = []
                this.attributesList.forEach(elem=>{
                  if(elem.paramOwner == 0 && elem.pluginName != 'static'){
                    if(elem.category){
                      let catToArr = elem.category.split(',');
                      catToArr = catToArr.filter(p=>{
                          return p != null && p != "";
                      });
                      
                          let checkByCat = catToArr.find(x=>x == this.parentMenu);
                          if(checkByCat){                              
                              filterStoreByCat.push(elem);
                          }   
                      }
                  }
                  if(elem.paramOwner == 1 && elem.pluginName != 'static'){
                    systemDefineAttr.push(elem)
                  }
                  if(elem.pluginName == 'static'){
                    systemDefineAttr.push(elem);
                    }
                });
                if(filterStoreByCat.length > 0){
                  this.attributesList = filterStoreByCat;
                  systemDefineAttr.forEach(elem=>{
                    this.attributesList.push(elem)
                  })
                } 
                if(filterStoreByCat.length == 0){
                  this.attributesList = []
                    systemDefineAttr.forEach(elem=>{
                      this.attributesList.push(elem)
                    })
                }
                this.attributesList.sort((a,b)=>{
                  if(a.paramName < b.paramName){
                    return -1;
                  }
                  if(a.paramName > b.paramName){
                    return 1;
                  }
                })
                this.filters.forEach(element => {               
                    element.checked = false;  
                    if(follow == undefined)
                    {                      
                      if(element.paramKey == 'lead_status'){                    
                        this.leadStatusParamsId = element.id;                        
                        this.getAudienceData();
                    }
                    }
                    
                });                
            }
        });
    }

    removeParamVal(item){      
      setTimeout(() => {
        item.addedValues = item.selected;
        if(item.selected.length == 0){
          item.placeholder = false
        }
      }, 500);
    }

    clearStorage(item){
      setTimeout(() => {
        item.addedValues = item.selected;
        if(item.selected.length == 0){
          item.placeholder = false
        }
      }, 500);
    }

    clearAutoComplete(item){      
      setTimeout(() => {
        item.addedValues = [];
        const itemVal = item.selected.value;
        item.selected = [];
        item.selected.value = itemVal;
        
      }, 100);
    }

    storeParamVal(item){
      if(item.archive == true){
        item.archive = false
      }
      setTimeout(() => {
        item.addedValues.value = item.selected.value;
        item.selected.forEach(element => {          
          if(element){
            if(item.addedValues.length > 0){
              let checkVal = item.addedValues.find(x=>x.paramValue == element.paramValue);
              if(checkVal == undefined){
              item.addedValues.push(element);
              }
            } else{
              item.addedValues.push(element);
            }
          
          }
        });
        item.selected = item.addedValues;
      }, 100);
      
    }

    fetchFilterDetails(obj,filterData?,objEvent?){
      this.getFilterDetails(obj,filterData,objEvent);
    }


    fetchCategory() {        
      this.dataList = [];
      this.projectService.fetchCategory({}).then(data => {
          if (data) {
              this.dataList = data;
          }
      })
  }

  
    getFilterDetails(obj,filterData?,objEvent?) {              
      let setObjValue;
      if(objEvent){        
        setObjValue = objEvent.target.value
        if(setObjValue == ""){
          obj.placeholder = false  
        } else{
          obj.placeholder = true
        }
      }
     if(this.selectedFilters.length > 0){      
      let checkAvail = this.selectedFilters.find(x=>x.paramKey == obj.paramKey);
      if(checkAvail == undefined){        
        obj.addedValues = []
        obj.selected = []
        obj.values = []
        obj.paramVal = null
        obj.selected.value = 'in';
        this.selectedFilters.push(obj);
      } } else{
      obj.addedValues = []
      obj.selected = []
      obj.values = []
      //obj.paramVal = null
      obj.selected.value = 'in';
      this.selectedFilters.push(obj);
    }
    this.selectedFilters.forEach(element => {
      if(element.archive == true){        
        obj.addedValues = []
        obj.selected = []
        obj.values = []
        obj.selected.value = 'in';
      }
    });

    if(obj.paramKey == 'telecaller_name'){
      let existingUsers = []
      this.projectService.getUserFromMaster().then(data => {
        if (data != undefined) {
            let userData = Object.keys(data);
            userData.forEach(elem=>{
                if(data[elem].status == 'ACTIVE'){
                    existingUsers.push({'paramKey':'telecaller_name','paramValue':data[elem].userName});
                    if(existingUsers){
                        obj.values = [];
                        obj.values = existingUsers
                        var selected = obj.selected;
                        this.loadAutoComplete(obj);
                        obj.selected = selected;
                        let checkBeforePush = this.selectedFilters.find(x=>x.paramKey == 'telecaller_name');
                      if(checkBeforePush){
                        for(let i=0; i<this.selectedFilters.length; i++){
                          if(this.selectedFilters[i].paramKey == 'telecaller_name'){
                            delete this.selectedFilters[i];
                          }
                        }
                        this.selectedFilters = this.selectedFilters.filter(p=>{
                          return p != null;
                        })
                        this.selectedFilters.push(obj);
                      } else{
                        this.selectedFilters.push(obj);
                      }
                    }
            }
            });
        }
    });
      return
    }

      if(obj.paramKey == 'lead_status'){
        let getCategory = this.dataList.find(x=>x.linkRewrite == this.parentMenu);
        if(getCategory && getCategory.leadStatusList){
          let data=[];
          obj.values = [];
          getCategory.leadStatusList.forEach(element => {
            data.push({'paramKey':"lead_status",'paramValue':element.stageName});
          });
          obj.values = data
          var selected = obj.selected;
          this.loadAutoComplete(obj);
          obj.selected = selected;
          let checkBeforePush = this.selectedFilters.find(x=>x.paramKey == 'lead_status');
          if(checkBeforePush){
            for(let i=0; i<this.selectedFilters.length; i++){
              if(this.selectedFilters[i].paramKey == 'lead_status'){
                delete this.selectedFilters[i];
              }
            }
            this.selectedFilters = this.selectedFilters.filter(p=>{
              return p != null;
            })
            this.selectedFilters.push(obj);
          } else{
            this.selectedFilters.push(obj);
          }
          
        }
        return
      }

      if(!setObjValue && obj.paramKey != 'lead_status' && obj.paramKey != 'telecaller_name' && obj.paramKey != 'country'){
        return;
      }
      
      

        this.spinner.show();
        this.campaignService.searchParamData({ paramKey: obj.pluginName == 'dynamic'?'params.'+obj.paramKey:obj.paramKey,paramValue:setObjValue ? setObjValue : "" }).subscribe( data => {          
            if(this.attributesParam == undefined)
            this.spinnerHide()
            obj.values = [];
            if (!data.status) {
                obj.error_msgs = data.error_msgs;
                if(this.selectedFilters.length > 0){
                  let checkAvail = this.selectedFilters.find(x=>x.paramKey == obj.paramKey);
                  if(checkAvail == undefined){
                    this.selectedFilters.push(obj);
                  }
                } else{
                  this.selectedFilters.push(obj);
                }
            } else {
              delete obj.error_msgs;
                data.data.forEach(element => {
                    element.paramValue = element.paramValue;
                    obj.values.push(element);                    
                });
                this.loadAutoComplete(obj);                
                var selected = obj.selected;
                if(!obj.addedValues){
                  obj.addedValues = []
                }
                if(obj.addedValues.length > 0){
                  obj.selected = obj.addedValues
                } else{
                  obj.selected = selected
                }                             
                obj.selected.value = obj.selectType ? obj.selectType : 'in';
                for (let index = 0; index < this.filters.length; index++) {
                    if (this.filters[index].id == obj.id) {
                        this.filters.splice(index, 1);
                    return;
                    }
                }
                if(filterData != undefined && filterData != ''){                  
                    var paramValue = filterData.paramValue.replace(/[']+/g, '').split(',');
                    paramValue.forEach(element => {
                      var d = { paramKey:filterData.paramKey, paramOprator:filterData.paramOprator, paramValue: element };
                      obj.selected = []
                      obj.selected.push(d);
                    });
                    obj.selected.value = filterData.paramOprator;
                    if(JSON.parse(this.applyFilterData.filterJson).paramList.length == (this.selectedFilters.length + 1)){
                        setTimeout(() => {
                            this.calculateAudience('reloadPagination');
                        }, 100);
                    }
                }

                for(let i=0; i<this.selectedFilters.length; i++){
                  if(this.selectedFilters[i].paramKey == obj.paramKey){
                     this.selectedFilters[i] = obj;
                  }
                }                
            } 
        });
    }

    addProps(item,event){
     
    }

    removeProps(item,event){
     
    }

  private loadAutoComplete(obj) {
    
    let paramKey = obj.pluginName == 'dynamic' ? 'params.'+obj.paramKey : obj.paramKey;
    obj.valueInput$ = new Subject<string>();
    obj.selected = <any>[];
    obj.values$ = concat(
      of([]), // default items
      obj.valueInput$.pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap(() => obj.loading = true),
        switchMap(term => this.getSourceData(paramKey, term).pipe(
          catchError(() => of([])), // empty list on error
          tap(() => obj.loading = false)
        ))
      )
    );
  }

  getSourceData(paramKey, term) {
    return this.campaignService.searchParamData({ paramKey: paramKey, paramValue: term }).pipe(map(res => res.data));
  }

  checkedListRemove()
  {
    localStorage.removeItem('checkedLeadReasign');
    localStorage.removeItem('checkedLeadDelete');
  }

  calculateAudience(reloadPagination?,searchTxtSubmit?) {
    this.isSubscribe = true;
    if(this.parentMenu != 'audience'){
    this.isSubscribe = this.authenticationService.isPageSubscribe('Dynamic Category');
    }
    if(searchTxtSubmit && this.defaultDateRangeFilter){
      this.filters.forEach(elem=>{
        if(elem.defaultDateFilter){
          for(let i=0; i<this.selectedFilters.length; i++){
            if(this.selectedFilters[i].paramKey == elem.paramKey){
              delete this.selectedFilters[i]
            }
          }
          for(let key in elem){
            if(key == 'defaultDateFilter'){
              delete elem[key]
            }
          }
        }
      })
      this.selectedFilters = this.selectedFilters.filter((filter)=>{
        return filter != null;
      })
    }
    this.userId = this.authenticationService.getUserId();
    this.showLead = false;    
    if(this.selectedUserId != null && this.selectedUserId != 0){
      this.userId = this.selectedUserId;
      //this.searchText = '';
      this.showLead = true
    }
    this.paramList = this.getSearchParam();
    if(this.audienceStatus != undefined && this.audienceStatus.length > 0){ 
      var selectStatus = ''
      for (let i = 0; i < this.audienceStatus.length; i++) {
        const el = this.audienceStatus[i];
        selectStatus += "'" + el.value + "'" + (this.audienceStatus.length == (i + 1) ? '' : ',');
      }
      var param = { id:this.leadStatusParamsId, paramOprator:"in", paramValue: selectStatus, paramKey:'params.lead_status'}
      this.paramList.push(param);
    }

    if (!this.validFilters) {
      return;
    }

    if (this.dateChecked) {      
        this.fromDate = this.datePipe.transform(this.startDate, "yyyy-MM-dd 00:00:00");
        this.toDate = this.datePipe.transform(this.endDate, "yyyy-MM-dd 23:59:59");        
    } else {
      if (this.startDate && !this.endDate) {
        this.fromDate = this.datePipe.transform(this.startDate, "yyyy-MM-dd 00:00:00");
        this.toDate = this.datePipe.transform(this.startDate, "yyyy-MM-dd 23:59:59");
      }else if (this.fromParamDate != undefined && this.fromParamDate != null) {
        this.fromDate = this.fromParamDate;
        this.toDate = this.toParamDate;
      } else if(this.showLead){
        this.fromDate = this.datePipe.transform(this.leadDefaultDate.startDate, "yyyy-MM-dd");
        this.toDate = this.datePipe.transform(this.leadDefaultDate.endDate, "yyyy-MM-dd");
      } else {
        this.fromDate = '';
        this.toDate = '';
      }

      if(this.showEntryDateFilter == true || this.dataRedirecting == true)
      {
        this.fromDate = '';
        this.toDate = '';
      }
      
        this.fromDate = '';
        this.toDate = '';
      
    }

    var events = this.global.Channel.Events.map(function (val) {
      return val.id;
    });
    this.global.Channel.Events.forEach(event => {      
      this.paramList.push({
        paramKey: event.paramKey,
        paramValue: "'" + true + "'",
        paramOprator: "="
      });
    });

    if(this.followUpAllTypeApply == true && this.isFilterApplied == false){
      if(this.paramList.length > 1){
        this.paramList[1].paramValue = this.selectedFollowUp.join(",");
      }

       this.fromDate = "";
       this.toDate = "";
    }

    const myClonedArray = [];
    this.selectedFilters.forEach(val => myClonedArray.push(Object.assign({}, val)));
    let myFilter = this.selectedFilters;
    setTimeout(() => {
      myFilter.forEach(element => {
        for(let key in element){                    
          if(key == 'values'){
            delete element[key];          
          }
          if(key == 'valueInput$'){
            delete element[key];          
          }
          if(key == 'values$'){
            delete element[key];          
          }
        }
      });
    this.selectedFilters = myClonedArray;    
    localStorage.setItem('paramList',JSON.stringify(this.paramList));
    localStorage.setItem('selectedFilter',JSON.stringify(myFilter));      
    }, 1000);

      let sourceList = [];      
      if(this.global.Channel.Source.length > 0){
      this.global.Channel.Source.forEach(element => {
        sourceList.push(element.pluginName)
      });
      }      

      var filterData = {
      segment:[{'paramList':this.paramList,'pluginFormIds':this.sourceFormIdList,'source':sourceList.length > 0 ? sourceList.join() : ''}],
      userId: this.userId,
      fromDate: this.fromDate,
      toDate: this.toDate,
      searchString: this.searchText.trim(),
      page: 1,
      limit: this.limit,
      showLead: this.showLead,
    };
    this.searchString = localStorage.getItem('searchString');        
    if(this.searchString){
      filterData.searchString = this.searchString;
    }
    if(this.pushNotificationId){
      filterData.searchString = 'objectid:'+this.pushNotificationId;
    }

    if(this.selectedUserId != null && this.selectedUserId != 0){
      filterData.userId = this.selectedUserId
    } else{
      delete filterData.userId;
    }
    if(!this.paramList){
      return
    }
    if(this.paramList.length == 0){
     delete filterData.segment[0].paramList
    }
    if(this.sourceFormIdList.length == 0){
      delete filterData.segment[0].pluginFormIds
     }
     if(this.global.Channel.Source.length == 0){
      delete filterData.segment[0].source
     }
     if(this.paramList.length == 0 && this.sourceFormIdList.length == 0 && this.global.Channel.Source.length == 0)
     {
      filterData.segment = []
     }
    this.page = 1;
    this.records = [];
    this.totalRecords = 0;
    this.isReassignOn = this.usingFor == 'reassign';
    this.allselected = false;
    this.selectAll();
    if(this.attributesParam == undefined)
    this.spinner.show();
    this.fetchAudienceData(filterData,reloadPagination);    
  }

  
  fetchAudienceData(filterData,reloadPagination?){ 
    this.records = [];
    this.isRecords = false;
    this.totalRecords = reloadPagination != 'not_reload_pagination' ? 0 : this.totalRecords;

    let parentMenu;
    if(this.queryParamsFromMenu){
      parentMenu = this.queryParamsFromMenu['params'].dynamicparam;
    }

    this.reportService.fetchAudienceData(filterData, parentMenu).subscribe(data => {            
      this.spinnerHide()
      if (data.status) {        
        this.isRecords = true;        
        this.setRecords(data.data.data);
        this.audienceColumnName = [];
        this.audienceColumnForTable = [];
        if(data.data.columnList != null){
          this.audienceColumn  = data.data.columnList.split(',');          
          this.audienceColumn.forEach(element => {
              let value = this.attributesList.find(x => x.paramKey === element.replace("params.",""));              
              if(value != undefined)
              {
                if(value.paramKey != 'email_valid' && value.paramKey != 'is_repeat'){
                  this.audienceColumnName.push(value.paramName);
                }
              this.audienceColumnForTable.push(value.paramKey);
              }
          });          
        } else {
          this.audienceColumnName = JSON.parse(data.data.columnListName);
        }
        if(reloadPagination != 'not_reload_pagination')
        {
          delete filterData.page;
          delete filterData.limit;
        this.reportService.fetchLeadsCount(filterData, parentMenu).subscribe(data=>{
          if(data.status)
          {
            this.totalRecords = data.data.totalElements
          } else {
            this.records = [];
            this.isRecords = false;
            this.totalRecords = 0;
          }
        }) 
        }  
        localStorage.removeItem('searchString');         
      } else {
        localStorage.removeItem('searchString'); 
        this.toastr.error(data.error_msgs);
      }
    }); 
    
    
    
  }



  setRecords(data){       
    if(this.getFilterFromStorage){
      localStorage.removeItem('filterData');
     // this.getFilterFromStorage = []
    }         
    let checkListData = ""; 
    this.checkedCategoryList = []  
    this.checkListData = []; 
    if(this.isReassignOn || this.reassignLead){
      checkListData = JSON.parse(localStorage.getItem('checkedLeadReasign'));
    }
    if(this.isShowCheckbox){
      checkListData = JSON.parse(localStorage.getItem('checkedLeadDelete'));
    }    
    
    if(checkListData != null && checkListData != undefined && checkListData != ""){
      this.checkListData = checkListData
    }

    this.records = [];
    data.forEach(element => { 
      
      if(element.updated){
        if(element.updated.includes('.000Z') == true){
          element.updated = element.updated.split('.000Z')[0];
          element.updated= element.updated.split('T').join(' ');
        }
        }
      
      if(element.params){
        let paramsKeys = Object.keys(element.params);
        paramsKeys.forEach(key => {
          if(typeof(element.params[key]) == 'string'){
          if(element.params[key].includes('.000Z') == true){
            element.params[key] = element.params[key].split('.000Z')[0];
            element.params[key]= element.params[key].split('T').join(' ');
          }
          let pValue = element.params[key];
          let sanitiseParamValue = pValue.replace(/\\/g, '');
          element.params[key] = sanitiseParamValue;
          }
        });
        
      }    
     
      if(this.checkListData.length > 0){
              let checkedElem = this.checkListData.find(x=> x == element._id) != undefined ? true : false;
              if(checkedElem == true)
              {
              this.checkedCategoryList.push(element._id)
              }
              this.records.push({id:element._id, record:element, selected:checkedElem})
              }else{
                this.records.push({id:element._id, record:element})
              }
    });      
  }

  getLeadStatusColor(staus){
        var lead = this.statusParams.find(x => x.value === staus);
        return lead != undefined ? lead.color : '#5a8fd8';
  }

  public dataForPaging(event,reloadStatus?) {
    this.allselected = false;
    this.page = event;
    this.spinner.show();

    let sourceList = [];      
      if(this.global.Channel.Source.length > 0){
      this.global.Channel.Source.forEach(element => {
        sourceList.push(element.pluginName)
      });
      }

    var filterData = {
      segment:[{'paramList':this.paramList,'pluginFormIds':this.sourceFormIdList,'source':sourceList.join(",")}],
      userId: this.userId,
      fromDate: this.fromDate,
      toDate: this.toDate,
      searchString: this.searchText.trim(),
      page: (this.page),
      limit: this.limit,
      showLead: this.showLead,
    };

    if(this.paramList.length == 0){
     delete filterData.segment[0].paramList
    }
    if(this.sourceFormIdList.length == 0){
      delete filterData.segment[0].pluginFormIds
     }
     if(this.global.Channel.Source.length == 0){
      delete filterData.segment[0].source
     }
     if(this.paramList.length == 0 && this.sourceFormIdList.length == 0 && this.global.Channel.Source.length == 0)
     {
       filterData.segment = []
     }     
     if(reloadStatus){
      this.fetchAudienceData(filterData,'reloadPagination');
     } else{
      this.fetchAudienceData(filterData,'not_reload_pagination');
     }
    
  }

  private getSearchParam() {
    this.validFilters = true;
    let paramList = [];
    this.selectedFilters.forEach(element => {
      if(element.paramDatatype == "String" || element.paramDatatype == "string" || element.paramDatatype == "Email" || element.paramDatatype == "Mobile"){
        if(element.selected.value == 'in' || element.selected.value == 'not_in'){
          if(element.values.length == 0){
            this.invalidateCalculateAudience();
            return
          }
        }
      }
    });
    var selectedFilters  = this.selectedFilters.filter(function (item: any) {
      if(item.paramDatatype == "String" || item.paramDatatype == "string" || item.paramDatatype == "Email" || item.paramDatatype == "Mobile"){
        if(item.selected.value == 'contain'){
          return true
        }
        return item.values.length > 0;
      } else {
        return item;
      }
    });    
    for (let index = 0; index < selectedFilters.length; index++) {
      const element = selectedFilters[index];     
      element.conainsError = false                   
      var parm = { paramKey: element.pluginName == 'dynamic' ? 'params.'+element.paramKey : element.paramKey, paramValue: '', paramOprator: '',id:element.id };
      if (element.paramDatatype == "String" || element.paramDatatype == "string" || element.paramDatatype == "Email" || element.paramDatatype == "Mobile") {
        parm.paramOprator = element.selected.value;
        parm.paramValue = "";
        
        
        if (element.selected != undefined && (element.selected.value == 'in' || element.selected.value == 'not_in')) {
          for (let i = 0; i < element.selected.length; i++) {
            const el = element.selected[i];
            parm.paramValue += "'" + el.paramValue + "'" + (element.selected.length == (i + 1) ? '' : ',');
          }
        } else if (element.selected != undefined && (element.selected.value == 'contain')) {
          
          if(element.selected.length > 0)
          {
          if(element.selected[0].paramValue != undefined)
          {            
          for (let i = 0; i < element.selected.length; i++) {
            parm.paramValue = parm.paramValue + ',' +element.selected[i].paramValue;            
          }
          if(element.selected.text1)
          {
            parm.paramValue = element.selected.text1;

            let paramValSplitByComma = parm.paramValue.split(',');
            paramValSplitByComma = paramValSplitByComma.filter((p)=>{
              return p != null;
            })
            let storeWithoutSpace = []
            for(let i=0; i<paramValSplitByComma.length;i++){
              if(paramValSplitByComma[i].length < 3){
                element.conainsError = true;
                this.isFilterApplied = false
                return
              }
            }
            paramValSplitByComma.forEach(elem => {
              if(elem.length < 3){
                element.conainsError = true
                return
              }
              elem = elem.trim();
              storeWithoutSpace.push(elem);
            });
            parm.paramValue = storeWithoutSpace.join();   
          }
          if( parm.paramValue.charAt( 0 ) === ',' )
          parm.paramValue = parm.paramValue.slice( 1 );
          element.selected.text1 = parm.paramValue;
          }
          }
          if(element.selected.length == 0)
          {
          if(element.selected.text1)
          {
            parm.paramValue += element.selected.text1

            let paramValSplitByComma = parm.paramValue.split(',');
            paramValSplitByComma = paramValSplitByComma.filter((p)=>{
              return p != null;
            })
            let storeWithoutSpace = []
            for(let i=0; i<paramValSplitByComma.length;i++){
              if(paramValSplitByComma[i].length < 3){
                element.conainsError = true;
                this.isFilterApplied = false
                return
              }
            }
            paramValSplitByComma.forEach(elem => {
              if(elem.length < 3){
                element.conainsError = true
                return
              }
              elem = elem.trim();
              storeWithoutSpace.push(elem);
            });
            parm.paramValue = storeWithoutSpace.join();   
          }
          }
        }
        else {
          if(element.selected.text1.paramValue != undefined){
            element.selected.text1 = element.selected.text1.paramValue
          }
          parm.paramValue = element.selected.text1;
          let paramValSplitByComma = parm.paramValue.split(',');
            paramValSplitByComma = paramValSplitByComma.filter((p)=>{
              return p != null;
            })
            let storeWithoutSpace = []
            for(let i=0; i<paramValSplitByComma.length;i++){
              if(paramValSplitByComma[i].length < 3){
                element.conainsError = true;
                this.isFilterApplied = false
                return
              }
            }
            paramValSplitByComma.forEach(elem => {
              if(elem.length < 3){
                element.conainsError = true
                return
              }
              elem = elem.trim();
              storeWithoutSpace.push(elem);
            });
            parm.paramValue = storeWithoutSpace.join(); 
        }
        if (parm.paramValue == '') {
          this.invalidateCalculateAudience();
        }
      }
      else if (element.paramDatatype == 'Numeric') {
        parm.paramOprator = element.selected.value;
        if (element.selected.value == 'between') {
          parm.paramValue = element.selected.text1 + ' and ' + element.selected.text2;
          if (element.selected.text1 == undefined || element.selected.text1 == '' || element.selected.text2 == '' || element.selected.text2 == undefined || element.selected.text1.length > 10 || element.selected.text2.length > 10) {
            this.invalidateCalculateAudience();
          }
        }
        else {
          element.selected.text1 = parseFloat(element.selected.text1);
          parm.paramValue = element.selected.text1;
          element.selected.text1 = JSON.stringify(element.selected.text1)
          if (element.selected.text1 == undefined || element.selected.text1 === '' || Number.isNaN(element.selected.text1) || element.selected.text1.length > 15 ) {            
            this.invalidateCalculateAudience();
          }
        }
        if (parm.paramValue == undefined || parm.paramValue === '' || Number.isNaN(element.selected.text1)) {
          this.invalidateCalculateAudience();
        }        
      }
      else if (element.paramDatatype == 'Date') {
        
        parm.paramOprator = element.selected.value;
        if (element.selected.value == 'between' && !element.selected.value2) {          
          this.showDownloadBtn = true;
          if ((element.selected.text1 > element.selected.text2) || element.selected.text1 == undefined || element.selected.text1 == '' || element.selected.text2 == '' || element.selected.text2 == undefined || element.selected.text1.length > 10 || element.selected.text2.length > 10) {
            this.invalidateCalculateAudience();
          }
          parm.paramValue = "date('" + this.datePipe.transform(element.selected.text1, "yyyy-MM-dd") + " 00:00:00') and date('" + this.datePipe.transform(element.selected.text2, "yyyy-MM-dd") + " 23:59:59')";
        } else if (element.selected.value == 'between' && element.selected.value2 == 'missedDateTime') {
          this.showDownloadBtn = true;
          if ((element.selected.text1 > element.selected.text2) || element.selected.text1 == undefined || element.selected.text1 == '' || element.selected.text2 == '' || element.selected.text2 == undefined || element.selected.text1.length > 10 || element.selected.text2.length > 10) {
            this.invalidateCalculateAudience();
          }
          parm.paramValue = "date('" + this.datePipe.transform(element.selected.text1, "yyyy-MM-dd") + " 00:00:00') and date('" + this.datePipe.transform(element.selected.text2, "yyyy-MM-dd HH:mm:ss") + "')";
        } else if (element.selected.value == 'between' && element.selected.value2 == 'upcoming') {          
          this.showDownloadBtn = true;
          if ((element.selected.text1 > element.selected.text2) || element.selected.text1 == undefined || element.selected.text1 == '' || element.selected.text2 == '' || element.selected.text2 == undefined || element.selected.text1.length > 10 || element.selected.text2.length > 10) {
            this.invalidateCalculateAudience();
          }
          parm.paramValue = "date('" + this.datePipe.transform(new Date(), "yyyy-MM-dd HH:mm:ss") + "') and date('" + this.datePipe.transform(element.selected.text2, "yyyy-MM-dd") + " 23:59:59')";
        } else if(element.selected.value == 'between' && element.selected.value2 == 'upcoming_by_profile_type'){

          this.showDownloadBtn = true;
          if ((element.selected.text1 > element.selected.text2) || element.selected.text1 == undefined || element.selected.text1 == '' || element.selected.text2 == '' || element.selected.text2 == undefined || element.selected.text1.length > 10 || element.selected.text2.length > 10) {
            this.invalidateCalculateAudience();
          }
          parm.paramValue = "date('" + this.datePipe.transform(element.selected.text1, "yyyy-MM-dd HH:mm:ss") + "') and date('" + this.datePipe.transform(element.selected.text2, "yyyy-MM-dd") + " 23:59:59')";

        } else if (element.selected.value == 'between' && element.selected.value2 == 'upcoming_next'){          
          this.showDownloadBtn = true;
          if ((element.selected.text1 > element.selected.text2) || element.selected.text1 == undefined || element.selected.text1 == '' || element.selected.text2 == '' || element.selected.text2 == undefined || element.selected.text1.length > 10 || element.selected.text2.length > 10) {
            this.invalidateCalculateAudience();
          }
          parm.paramValue = "date('" + this.datePipe.transform(element.selected.text1, "yyyy-MM-dd") + " 00:00:00') and date('" + this.datePipe.transform(element.selected.text2, "yyyy-MM-dd") + " 23:59:59')";
        } 
        else {
          if (element.selected.text1 == undefined || element.selected.text1 == '' || element.selected.text1.length > 10) {
            this.showDownloadBtn = false;
            this.invalidateCalculateAudience();
          }
          parm.paramValue = "date('" + this.datePipe.transform(element.selected.text1, "yyyy-MM-dd") + "')";
        }
        if (parm.paramValue == undefined || parm.paramValue == '') {
          this.invalidateCalculateAudience();
        }
      }
      paramList.push(parm);
    }    
    return paramList;
  }

  private invalidateCalculateAudience() {
    this.validFilters = false;
    this.toastr.error('Please fill valid input in all mandatory fields');
    this.spinnerHide()
  }

  openModal(webPushSegment: TemplateRef<any>, type) {
    if(type == 'reportBlock'){
      this.modalRef = this.modalService.show(webPushSegment, { class: "modal-rightblock", backdrop: 'static' });
      return
    }
    if(type != ''){
      this.usingFor =  type;
      this.modalRef = this.modalService.show(webPushSegment, { class: "modal-rightblock", backdrop: 'static' });
    } else {
      this.modalRef = this.modalService.show(webPushSegment, { backdrop: 'static' });
    }
  }

  filterSourceRemove(event)
  {
    if(event == 'clearAllSource'){
      this.global.Channel.sourceFormIds = [];
      this.global.Channel.Source = [];
      this.filterClose('isFilterApplied','sourceRemove');
      return
    }
    let sourceForm = []
    this.global.Channel.Source.forEach(element => {
      element.assignMappingDTOs.forEach(elem => {
        sourceForm.push(elem)
      });
    });

    this.global.Channel.sourceFormIds.forEach(elems=>{
      let pluginFormFind = sourceForm.find(x=>x.id == elems)
        if(pluginFormFind == undefined){
          for(let i=0 ; i<this.global.Channel.sourceFormIds.length; i++){
            if(elems == this.global.Channel.sourceFormIds[i]){
              delete this.global.Channel.sourceFormIds[i]
            }
          }
        }
    })
    this.global.Channel.sourceFormIds = this.global.Channel.sourceFormIds.filter(p=>{
      return p != null;
    })
    this.filterClose('isFilterApplied');
  }

  clearSessionChannel(itemID){
if(this.getFilterFromStorage){
  if(this.getFilterFromStorage.length > 0){
    this.getFilterFromStorage.forEach(element => {              
      if(element.paramKey == 'source'){        
       let splitSource = element.paramValue.split(',');
       let splitSourceId = element.text1.split(',');
       for(let i=0; i<splitSourceId.length; i++){
         let checkId = this.fetchUser.find(x=>x.pluginId == splitSourceId[i]);
         if(checkId){
          if(checkId.pluginId == itemID){
            for(let i=0; i<splitSource.length; i++){
              if(splitSource[i] == checkId.pluginName){
                delete splitSource[i]
              }
            }
            for(let i=0; i<splitSourceId.length; i++){
              if(splitSourceId[i] == checkId.pluginId){
                delete splitSourceId[i]
              }
            }

            splitSourceId = splitSourceId.filter(p=>{
              return p != null;
            });
            splitSource = splitSource.filter(p=>{
              return p != null;
            });
            element.paramValue = splitSource.join();
            element.text1 = splitSourceId.join();
            for (let i = 0; i < this.fetchUser.length; i++) {
              this.fetchUser[i].checked =  false
          }
            
          }
          
          
         }
       }
      }
    });
  }
  }
       

  }

    filterClose(event?,removeHit?) {            
      this.defaultDateRangeFilter = false;            
        this.isShowLeadReportBox = false;
        this.showFilter = true;
        if(event != 'isParemFilter') {
            this.attributesParam = undefined;
        }

        this.selectedFilters.forEach((element , index) => {          
          if (this.global.Channel.Attributes.filter(x => x.id == element.id).length == 0) {
            this.selectedFilters.splice(index, 1);
          }
      });        
      this.global.Channel.Attributes.forEach(element => {          
          if (this.selectedFilters.filter(x => x.id == element.id).length == 0) {
            this.addFilter(JSON.stringify(element), null, event == 'isFilterApplied');
          }
      });

      
        if(this.getFilterFromStorage){
        if(this.getFilterFromStorage.length > 0){
          if(this.getFilterFromStorage){
            if(removeHit == 'sourceRemove'){
              for(let i=0; i<this.getFilterFromStorage.length; i++){
                if(this.getFilterFromStorage[i].paramKey == 'source' || this.getFilterFromStorage[i].paramKey == 'pluginFormIds'){
                  delete this.getFilterFromStorage[i];
                }
              }
            }
            this.getFilterFromStorage = this.getFilterFromStorage.filter(p=>{
              return p != null;
            })
            this.getFilterFromStorage.forEach(element => {              
               if(element.paramKey == 'source'){
                let splitSource = element.paramValue.split(',');
                let splitSourceId = element.text1.split(',');
                for(let i=0; i<splitSourceId.length; i++){
                  let checkId = this.fetchUser.find(x=>x.pluginId == splitSourceId[i]);
                  if(checkId){
                    for (let i = 0; i < this.fetchUser.length; i++) {
                      if(checkId == this.fetchUser[i]){
                        this.fetchUser[i].checked =  true
                      }
                  }
                  }
                }
      
                this.global.Channel.Source = this.fetchUser.filter(function (item: any) {
                  return item.checked == true;
                });
      
              } else if(element.paramKey == 'pluginFormIds'){
                this.sourceFormIdList = element.paramValue.split(',');
                for(let i=0; i<this.sourceFormIdList.length; i++){
                  if(this.sourceFormIdList[i] == ""){
                    delete this.sourceFormIdList[i]
                  }
                }
                this.sourceFormIdList = this.sourceFormIdList.filter(p=>{
                  return p != null;
                })                
                this.sourceFormIdList = this.sourceFormIdList.filter(p=>{
                  return p != null;
                })
                this.global.Channel.sourceFormIds = this.sourceFormIdList;
                //selectedForms;
                this.global.Channel.Source.forEach(element => {
                  element.selectedForms = [];
                  element.assignMappingDTOs.forEach(elem => {
                    let checkPluginForm = this.sourceFormIdList.find(x=> x == elem.id);
                    if(checkPluginForm){
                      element.selectedForms.push({formName:elem.formName})
                    }
                  });
                });          
              } else if(element.paramKey == 'user') {
                this.selectedUserId = decodeURIComponent(element.paramValue.split('&&')[0]);
                this.selectedUserName = element.text1;
                this.showLead = true;
              }else if(element.paramKey == 'userFromReport') {
                this.selectedUserId = decodeURIComponent(element.paramValue);
                this.selectedUserName = element.text1;
                this.showLead = true;
              }
                else{
                let checkFilter = this.filters.find(x=>x.paramKey == element.paramKey);
                if(checkFilter != undefined){
                  element.paramDatatype = checkFilter.paramDatatype;
                }
              }
            });
      
            this.filters.forEach(element => { 
              let getFilter = this.getFilterFromStorage.find(x=>x.paramKey == element.paramKey);
              if(getFilter != undefined){                                
              if(getFilter.paramDatatype == 'Date'){
              element.type = 'dateRange'
              element.selected = []
              element.checked = true;
              element.selected.value = 'between';
              element.selected.text1 = new Date(getFilter.text1);
              if(getFilter.text2){
              element.selected.text2 = new Date(getFilter.text2);
              } else{
              element.selected.text2 = new Date(getFilter.text1);
              }
              if(getFilter.missedReport && getFilter.missedReport == 'missedDateTime'){
                element.selected.value2 = "missedDateTime";
              }
              if(getFilter.missedReport && getFilter.missedReport == 'upcoming'){
                element.selected.value2 = "upcoming";
              }
              if(getFilter.missedReport && getFilter.missedReport == 'upcoming_next'){
                element.selected.value2 = "upcoming_next";
              }
              
              
              let checkSelectedFilters = this.selectedFilters.find(x=>x.paramKey == element.paramKey);
              if(checkSelectedFilters == undefined){
              this.global.Channel.Attributes.push(element);
              this.selectedFilters.push(element);
              }
              // this.filterClose('isFilterApplied');
              }
              if(getFilter.paramDatatype == 'String'){
                element.selected = []
                element.checked = true;
                element.values = []
                element.selected.value = getFilter.paramOprator ? getFilter.paramOprator : 'in';
                let checkTextLen = getFilter.text1.split("','");
                if(getFilter.reportType == undefined || getFilter.reportType != 'followup' && getFilter.reportType != 'completed' && getFilter.reportType != 'followupCompleted'){
                for(let i=0; i<checkTextLen.length; i++){
                  element.selected.push({paramKey:element.paramKey,paramValue:checkTextLen[i].replaceAll("'","")});
                  element.values.push({paramKey:element.paramKey,paramValue:checkTextLen[i].replaceAll("'","")})
                }
                }
                if(getFilter.reportType == 'followup'){
                  this.statusParams.forEach(elementStatus => {                                                                                 
                    if(elementStatus.type == 'FOLLOW_UP' || elementStatus.type == 'CALL_LATER')
                    {
                      this.selectedFollowUp.push(elementStatus.label);
                    }
                    });
                }
                if(getFilter.reportType == 'completed'){
                  this.statusParams.forEach(elementStatus => {                                                             
                    if(elementStatus.type == 'COMPLETED')
                    {
                      this.selectedFollowUp.push(elementStatus.label);
                    }
                    });
                } if(getFilter.reportType == 'followupCompleted'){
                  this.statusParams.forEach(elementStatus => {                                                             
                    if(elementStatus.type == 'COMPLETED' || elementStatus.type == 'FOLLOW_UP' || elementStatus.type == 'CALL_LATER')
                    {
                      this.selectedFollowUp.push(elementStatus.label);
                    }
                    });
                } 
                if(this.selectedFollowUp.length > 0){
                  
                for(let i=0; i<this.selectedFollowUp.length; i++){
                  element.selected.push({paramKey:element.paramKey,paramValue:this.selectedFollowUp[i]});
                  element.values.push({paramKey:element.paramKey,paramValue:this.selectedFollowUp[i]})
                }
                }
                
                let checkSelectedFilters = this.selectedFilters.find(x=>x.paramKey == element.paramKey);
                if(checkSelectedFilters == undefined){
                this.global.Channel.Attributes.push(element);
                this.selectedFilters.push(element);
                }
                
                }
      
                if(getFilter.paramDatatype == 'Numeric'){
                  element.selected = []
                  element.checked = true;
                  element.values = []
                  element.values.push({paramKey:element.paramKey,paramValue:getFilter.text1})
                  element.selected.value = '=';
                  element.selected = {text1: getFilter.text1, value: "="}
                  
                  let checkSelectedFilters = this.selectedFilters.find(x=>x.paramKey == element.paramKey);
                  if(checkSelectedFilters == undefined){
                  this.global.Channel.Attributes.push(element);
                  this.selectedFilters.push(element);
                  }
                  // this.filterClose('isFilterApplied');
                  // this.filterClose('isSourceUpdate');
                  }
            }
            });
        }
      }
    }
        
        
        this.sourceFormIdList = this.global.Channel.sourceFormIds;        
        if(event == 'isFilterApplied')
        this.calculateAudience('reloadPagination');
    }

  clearFilters() {
    this.global.ClearChannel();
    this.removeFilters();
    this.isUrlFilterApplied = false;
    this.showFilter = false;
    this.isReassignOn = false;
    this.checkedCategoryList = [];
    this.applyFilterData = {};
    this.validFilterData = false;
    this.isFilterApplied = false;
    this.selectDateTypeTwo = false;
    this.showEntryDateFilter = false;
    this.dataRedirecting = false;
    this.showDownloadBtn = false;
    this.usingFor = 'viewAudience'; 
    this.reassignLead = false; 
    this.isShowCheckbox = false;
  }

  filterClear(e)
  {
    if(e == 'clear')
    {
      this.clearFilters();
    }
  }

  onDateSelectionEntryDate(date)
  {  
   
    if(date.startDate != null && date.endDate != null)
    {    
      this.filters = this.projectService.filterAttributeList(['all']);        
      this.startDateEntry = this.datePipe.transform(date.startDate, "yyyy-MM-dd");
      this.endDateEntry = this.datePipe.transform(date.endDate, "yyyy-MM-dd");
      
      this.filters.forEach( element => {
      if(element.paramKey === 'entry_date'){
        this.removeFilter(element);
        this.showEntryDateFilter = false;
          element.selected = []
          element.checked = true;
          element.selected.value = 'between';
          element.selected.text1 = new Date(this.startDateEntry);
          element.selected.text2 = new Date(this.endDateEntry);
          this.showEntryDateFilter = true;
          this.global.Channel.Attributes.push(element);
          this.selectedFilters.push(element)
          this.filterClose('isFilterApplied');
          
      }
    });
    }
  }

  onDateSelection(date) {
    this.startDate = this.datePipe.transform(date.startDate, "yyyy-MM-dd");
    this.endDate = this.datePipe.transform(date.endDate, "yyyy-MM-dd");
    this.dateChecked = this.startDate!= undefined && this.startDate != null;
    this.dateMsg = this.datePipe.transform(date.startDate, "dd MMM") + " to " + this.datePipe.transform(date.endDate, "dd MMM yyyy");
  }

  onDateSelection2(date) {
   this.selectDateTypeTwo = true;
   this.onDateSelection(date);
  }


  resetDate() {
    this.sourceFormIdList = [];
    this.usingFor = 'viewAudience';
    this.startDate = null;
    this.endDate = null;
    this.dateChecked = false;
    this.toParamDate = null;
    this.fromParamDate = null;
    this.filterDateRange = undefined;
    this.dateMsg = 'no date selected';
    this.leadDefaultDate = {startDate:moment().subtract(6, 'days'), endDate:moment()};
    this.isShowLeadReportBox = false;
  }
  resetUser(){
    this.selectedUserId = null;
    this.selectedUserName = undefined;
    this.showLead = false;
  }
  
  openViewModal(audienceProfile: TemplateRef<any>, id, type?) {    
    this.viewAudienceId = id;
    if(type == 'delete'){
      if(id == 0 && this.checkedCategoryList.length == 0){
          this.toastr.error('Select Audiences', 'Error');
          return;
      } 
      this.checkedCategoryList.push(id);
    }
    var className = 'modal-lg'
    if(type == 'delete')
    {
       className = '';
    }
    
    else if(type == 'detail')
    {
       className = className+' detail_audience_popup';
    }
    if(this.modalRef) this.modalRef.hide();
    {
    if(type == 'detail')
    {
      this.modalRef = this.modalService.show(audienceProfile, { class:className , backdrop:false});
    }
    else
    {
      this.modalRef = this.modalService.show(audienceProfile, { class:className});
    }
  }
}


  goToAudDetail(id)
  {
    this.router.navigate(['admin/audience/view-audience',id])
  }



  deleteAudience(){
    let getCheckLead = JSON.parse(localStorage.getItem('checkedLeadDelete'));
    if(getCheckLead != null){
      this.checkedCategoryList = getCheckLead
    }
    
    this.projectService.deleteBulkAudienceData({idList:this.checkedCategoryList,deleteRecordCount:this.checkedCategoryList.length},this.parentMenu).subscribe(data => {
      this.checkedCategoryList = [];
      if(data.status){
        this.toastr.success(data.data, 'Success');
        setTimeout(() => {
          this.calculateAudience('reloadPagination');
        }, 1000);
         
        this.modalRef.hide();
        this.checkListData = []
        this.checkedCategoryList = []
        localStorage.removeItem('checkedLeadDelete');
        
      }else {
        this.toastr.error(data.error_msgs, 'Error')
      }
    })
  }

    back(): void {
        if(this.router.url.includes('notification') == true){
          this.router.navigate(['/admin/audience/view-audience']);
          localStorage.removeItem('searchString');
          this.calculateAudience()
          return
        }
        this.location.back();
    }

    spinnerHide() { 
        if (this.waitCount > 0)
            this.waitCount--;
        else
            this.spinner.hide();
            
    }

    audienceProfileClose(){   
        if(this.searchString){
         localStorage.setItem('searchString',this.searchString);
         this.calculateAudience()
        } else {
          this.dataForPaging(this.page,'reloadPagination')
        }
    }

    selectAll(){
        for (var i = 0; i < this.records.length; i++) {
            this.records[i].selected = this.allselected;
        }
        this.getCheckedItemList();
    }

    isAllSelected(){
        this.allselected = this.records.every(function(item:any) {
            return item.selected == true;
        })
        this.getCheckedItemList();
    }



    getCheckedItemList(){
        this.checkedCategoryList = [];
        for (var i = 0; i < this.records.length; i++) {
            if(this.records[i].selected)
            this.checkedCategoryList.push(this.records[i].id);
        }
    }


    storeAllDataToLocal()
    {
      
      for (var i = 0; i < this.records.length; i++) {
        if(this.records[i].selected){
        let idFind = this.checkListData.find(x=> x == this.records[i].id);
        if(idFind == undefined)
        {
        this.checkListData.push(this.records[i].id);
        }
        }
        else{
          for(let j=0; j<this.checkListData.length; j++){
            if(this.records[i].id == this.checkListData[j]){
              delete this.checkListData[j];
            }
          }
        }
    }

    this.checkListData = this.checkListData.filter((p)=>{
      return p != null;
    })    
    
      if(this.isReassignOn || this.reassignLead)
      {
      localStorage.setItem('checkedLeadReasign',JSON.stringify(this.checkListData)); 
      }
      if(this.isShowCheckbox)
      {
      localStorage.setItem('checkedLeadDelete',JSON.stringify(this.checkListData)); 
      }

    }

    storeDataToLocal(event,id)
    {
      if(event == true){
        this.checkListData.push(id)   
      }
      else if(event == false)
      {
        let index = this.checkListData.indexOf(id);
        this.checkListData.splice(index,1)
      }
      if(this.isReassignOn || this.reassignLead)
      {
      localStorage.setItem('checkedLeadReasign',JSON.stringify(this.checkListData)); 
      let checkedLeadReasign = JSON.parse(localStorage.getItem('checkedLeadReasign'));
      }
      if(this.isShowCheckbox)
      {
      localStorage.setItem('checkedLeadDelete',JSON.stringify(this.checkListData)); 
      } 
          
    }


    audiencesReassign(){
      this.allUserList = []
      let userData = this.authenticationService.getUserData();
      let findUserForParent:boolean = false;
        this.isReassignFormSubmit = true;
        if(this.checkedCategoryList.length == 0){
            this.toastr.error('Select Audiences', 'Error');
            return;
        }
        this.reassignUser = '';
        var formId;        
        if(this.global.Channel.Source.length > 0)
        {
          if(this.global.Channel.Source[0].selectedForms)
          {
            formId  = this.global.Channel.Source[0].selectedForms[0].id
          }
        }

        this.projectService.getUserFromMaster().then(data=>{
          let userData = Object.keys(data);
          this.projectService.fetchCategory({}).then(catData => {
            let catStore = [];
            if (catData) {
                catData.forEach(element => {                    
                    catStore.push(element)
                    if(this.parentMenu == element.linkRewrite){
                      
                      userData.forEach(elem=>{
                        if(data[elem].status == 'ACTIVE'){
                        if(element.userList && element.userList.length > 0){
                          let checkToPush = element.userList.find(x=>x == data[elem].id);
                          if(checkToPush){
                        
                        this.allUserList.push({
                            id:data[elem].id,
                            userName:data[elem].userName,
                            userProfileName:data[elem].userName
                        })
                        this.reassignUserList.push({
                          id:data[elem].id,
                          userName:data[elem].userName,
                          userProfileName:data[elem].userName
                      })
                     }
                    } else{
                        this.allUserList.push({
                            id:data[elem].id,
                            userName:data[elem].userName,
                            userProfileName:data[elem].userName
                        })
                        this.reassignUserList.push({
                          id:data[elem].id,
                          userName:data[elem].userName,
                          userProfileName:data[elem].userName
                      })
                     }
                     if(data[elem].userRoleId == 1){
                      let checkToPushSuperAdmin = this.allUserList.find(x=>x.id == data[elem].id);
                      if(checkToPushSuperAdmin == undefined){
                          this.allUserList.push({
                              id:data[elem].id,
                              userName:data[elem].userName,
                              userProfileName:data[elem].userName
                          })
                      }
                    }
                    }
                    });
                    }
                  })
                }
              });
        });

        this.allUserList.sort((a,b)=>{
          if(a.userProfileName < b.userProfileName){
            return -1;
          }
          if(a.userProfileName > b.userProfileName){
            return 1;
          }
        });
        this.reassignUserList.sort((a,b)=>{
          if(a.userProfileName < b.userProfileName){
            return -1;
          }
          if(a.userProfileName > b.userProfileName){
            return 1;
          }
        })
        this.isReassignAllUser = true;
        this.modalRef = this.modalService.show(this.audiencesReassignForm, { backdrop: 'static' });

        // this.userService.getUsersData().then( data => {
            // this.allUserList = [];
            // this.reassignUserList = [];
            // this.isReassignFormSubmit = false;
            // if (data != undefined) {
              //   var childUsers: SubUserObj[] = this.userService.filterChildUsers(data);
              //   childUsers.forEach((item: SubUserObj) => {
              //       if (item !=undefined && item.idList != null && item.idList.filter(x => x == formId).length > 0){
              //           this.reassignUserList.push(item);
              //       }
              //   });
                
              // this.allUserList = data;
              
              // let reportingChild = [];
              // let superAdmin_admin = [];

              // if(this.userRole != 1){
              //   if(userData.roleTypeId == 2){
              //   this.allUserList.forEach(elem=>{
              //     if(elem.userList){
              //       let checkUserId = elem.userList ? elem.userList.find(x=>x == userData.userId):null;
              //       if(checkUserId){
              //         findUserForParent = true;
              //         elem.userList.forEach(element=>{
              //             let checkOtherUserRole = this.allUserList.find(x=>x.id == element);
              //             if(checkOtherUserRole){
              //                 let checkToPush = reportingChild.find(x=>x == element);
              //                 if(checkToPush == undefined){
              //                 reportingChild.push(element);
              //                 }
              //                 let checkToPushElem = reportingChild.find(x=>x == elem.id);
              //                 if(checkToPushElem == undefined){
              //                 reportingChild.push(elem.id);
              //                 }
                                  
              //             }
              //         })
              //       } else{
              //       }
              //     }
              //   });
              // } else{
              //   this.allUserList.forEach(elem=>{                
              //     let checkUserId = elem.id == userData.userId;
              //     let checkUserList = elem.userList ? elem.userList.find(x=>x == userData.userId) : null;
              //     if(checkUserList){
              //         findUserForParent = true;
              //       elem.userList.forEach(element=>{
              //         let checkOtherUserRole = this.allUserList.find(x=>x.id == element);
              //             if(checkOtherUserRole){
              //                 if(checkOtherUserRole.userRoleName == userData.userRoleName){
              //                     let checkToPush = reportingChild.find(x=>x == element);
              //                     if(checkToPush == undefined){
              //                     reportingChild.push(element);
              //                     }
              //                     let checkToPushElem = reportingChild.find(x=>x == elem.id);
              //                     if(checkToPushElem == undefined){
              //                     reportingChild.push(elem.id);
              //                     }
              //                 }
              //             }
              //       })
              //     } 
              //     if(checkUserId && elem.userList){
              //       elem.userList.forEach(element=>{
              //         reportingChild.push(element);
              //       })
              //     }
              //   });
              // }
              // if(!findUserForParent){                
              //     this.projectService.getUserFromMaster().then(data=>{
              //         let userObj = Object.keys(data);
              //         userObj.forEach(elem=>{
              //           let checkSuperAdmin = superAdmin_admin.find(x=>x.id == data[elem].id);
              //             let ckeckInAlUser = this.allUserList.find(x=>x.id == data[elem].id);
                          
              //             if(data[elem].userRoleId == 1){
              //               if(checkSuperAdmin == undefined && ckeckInAlUser == undefined){
              //                 superAdmin_admin.push({
              //                     id:data[elem].id,
              //                     userName:data[elem].userName,
              //                     userProfileName:data[elem].userName
              //                     })
              //                 this.allUserList.push({
              //                 id:data[elem].id,
              //                 userName:data[elem].userName,
              //                 userProfileName:data[elem].userName
              //                 })
              //               }
              //             } else if(data[elem].level == 1 && data[elem].status == 'ACTIVE'){
              //                 superAdmin_admin.push({
              //                     id:data[elem].id,
              //                     userName:data[elem].userName,
              //                     userProfileName:data[elem].userName
              //                 })
              //             }
              //         });                    
              //     })
              // }
              
              
              // this.projectService.getUserFromMaster().then(data=>{
              //     let userObj = Object.keys(data);
              //     userObj.forEach(elem=>{
              //         if(data[elem].userRoleId == 1){
              //             let checkSuperAdmin = superAdmin_admin.find(x=>x.id == data[elem].id);
              //             let ckeckInAlUser = this.allUserList.find(x=>x.id == data[elem].id);
              //             if(checkSuperAdmin == undefined && ckeckInAlUser == undefined){
              //                 superAdmin_admin.push({
              //                     id:data[elem].id,
              //                     userName:data[elem].userName,
              //                     userProfileName:data[elem].userName
              //                     });

              //                     this.allUserList.push({
              //                       id:data[elem].id,
              //                       userName:data[elem].userName,
              //                       userProfileName:data[elem].userName
              //                       })
              //             }
              //         }
              //     });                    
              // })
              // }


              // let finalTelecallerList = []
              // if(reportingChild.length > 0){
              //     finalTelecallerList = this.allUserList.filter(p=>{
              //         return reportingChild.find(x=>x == p.id) && p.id != userData.userId;
              //     });
              // }
              // setTimeout(() => {
                
                // if(finalTelecallerList.length > 0 || superAdmin_admin.length > 0){
                //   this.allUserList = this.allUserList.filter(p=>{
                //       return finalTelecallerList.find(x=>x.id == p.id) || superAdmin_admin.find(x=>x.id == p.id);
                //   });
                // }

                // let checkUserInList = this.allUserList.find(x=>x.id == this.authenticationService.getUserId());

                // if(checkUserInList == undefined){
                // this.allUserList.push({
                //   id:this.authenticationService.getUserId(),
                //   userName:this.authenticationService.getUserData().userName,
                //   userProfileName:this.authenticationService.getUserData().userName
                // });
                // }                

                // this.allUserList.sort((a,b)=>{
                //   if(a.userProfileName < b.userProfileName){
                //     return -1;
                //   }
                //   if(a.userProfileName > b.userProfileName){
                //     return 1;
                //   }
                // });
                // this.reassignUserList.sort((a,b)=>{
                //   if(a.userProfileName < b.userProfileName){
                //     return -1;
                //   }
                //   if(a.userProfileName > b.userProfileName){
                //     return 1;
                //   }
                // })
                // this.isReassignAllUser = true;
                // this.modalRef = this.modalService.show(this.audiencesReassignForm, { backdrop: 'static' });
              // }, 500);
              

                
            // }
        // });
    }

    audienceReassign(){
      let parentMenu;
      if(this.queryParamsFromMenu){
        parentMenu = this.queryParamsFromMenu['params'].dynamicparam;
      }
        if(this.reassignUser == ''){
            this.toastr.error('Select User', 'Error');
            return;
        }
        let checkLeadList = JSON.parse(localStorage.getItem('checkedLeadReasign'));
        if(checkLeadList != null){
          this.checkedCategoryList = checkLeadList
        }
        
        var postData = {
            idList:this.checkedCategoryList,
            telecallerId:this.reassignUser,
        }
        this.reportService.upsertUserLeadAssignBulk(postData, parentMenu).subscribe ( data => {
            this.allselected = false;
            this.modalRef.hide();
            this.selectAll();
            if(data.status){
                this.checkListData = []
                this.checkedCategoryList = [];
                this.toastr.success(data.data, 'Success');
                localStorage.removeItem('checkedLeadReasign');
                this.calculateAudience();
            } else {
                this.toastr.error(data.error_msgs, 'Error');
            }
        });
    }

    sendEmailSMS(id,email,type){
      let campAttrtype;
      if(type == 'Email'){
        this.apiurl = this.emailApiUrl
        campAttrtype = "email";
      } else if(type == 'SMS'){
        this.apiurl = this.smsApiUrl;
        campAttrtype = "mobile";
      }
        this.isChannelType = true;
        var campaignData = {
            campaignName : email,
            campaign : type,
            audienceCount:1,
            audienceIds : ["objectId:"+id],
            campAttributes:campAttrtype
        }
        this.global.Channel.AllAudience = true;
        this.global.CampaignData = campaignData;
        this.campaignProceed.next(campaignData);
    }

    saveFilter(){
      this.submitSegment = true;
      let saveAsSegments = {
        id: 0,
        userProjects: null,
        projectId: this.authenticationService.getProjectId(),
        jsonData: {
          userId:this.userId,
          fromDate:'',
          toDate:'',
          searchString:'',
          page:1,
          limit:20,
          showLead:false,
          pluginFormIds:this.global.Channel.sourceFormIds,
          paramList: this.paramList,
          source: this.global.Channel.Source.map(function (val) { return val.pluginName; }).join(","),
          
        },
        segmentName: this.applyFilterData.name,
        segmentId: "",
        segmentType: "Dynamic",
        segmentCondition: "OR",
        segStatus: null,
        created: "",
        lastModify: "",
        category : this.parentMenu
      }; 
      
      var filterSave = JSON.parse(JSON.stringify(saveAsSegments));
      filterSave.jsonData = JSON.stringify(filterSave.jsonData); 
      this.validFilterData = true;
      if(this.applyFilterData.name == undefined || this.applyFilterData.name == ''){
          this.toastr.error('Enter Filter Name.','Error');
          return
      }
      this.Segmentservice.addSegment(filterSave).subscribe(data=>{
        if(data.status){
          this.submitSegment = false;
          this.toastr.success(data.data);
          this.modalRef.hide();
          this.getFilterList();
        } else{
          this.toastr.error(data.error_msgs);
        }
      })
    }

    getFilterList(){
       

        this.Segmentservice.getSegmentlist({category : this.parentMenu}).subscribe( data => {
          if (data.status) {
            this.filterList = data.data;            
          } else {
            this.filterList = [];
          }
      });
    }

    
  
    deleteFilter(){
        this.projectService.deleteUserFilter({id:this.tempModalData}).subscribe(data => {
            this.modalRef.hide();
            this.tempModalData = undefined;
            if(data.status){
                this.clearFilters();
                this.resetDate();
                this.resetUser();
                this.calculateAudience('reloadPagination');
                this.getFilterList();
                this.toastr.success(data.data, 'Successs');
            } else {
                this.toastr.error(data.data, 'Error');
            }
        })
    }

    applyFilter(filter){            
        this.clearFilters();
        this.resetDate();
        this.resetUser();
        this.isFilterApplied = true;
        this.applyFilterData = filter;
        
        var json = JSON.parse(filter.jsonData);
        this.applyFilterData.filterJson = filter.jsonData;
        if(json){
          this.projectService.getUserSourceData().then( data => { 
              if(data){
                if(json.source != undefined && json.source!=""){
                  let selectedSourceArray = json.source.split(",");                  
                  selectedSourceArray.forEach((element) => {                    
                    let sourceAttributes: Map<string, SourceObj> = this.projectService.sourceList;
                    let sourceObject = sourceAttributes[element];
                   if(sourceObject!= undefined){
                    let newSourceObject = JSON.parse(JSON.stringify(sourceObject));
                      newSourceObject.checked = true;
                      let assignMappingDtoList = sourceObject.assignMappingDTOs
                      newSourceObject.assignMappingDTOs = [];
                      newSourceObject.selectedForms = [];
                    if( json.pluginFormIds !=undefined && Array.isArray(json.pluginFormIds) ){
                      json.pluginFormIds.forEach((pluginFormId)=>{
                        if(assignMappingDtoList!=undefined && assignMappingDtoList.length>0){
                          let assignmappingDto  = assignMappingDtoList.filter(dtop => dtop.hasOwnProperty("id") && dtop["id"] == pluginFormId ) ;                              
                          if(assignmappingDto.length>0){
                            assignmappingDto.forEach(elem => {
                              this.global.Channel.sourceFormIds.push(elem.id);
                            });
                            

                            let assignmappingDtoClone= JSON.parse(JSON.stringify(assignmappingDto));
                            assignmappingDtoClone[0]["checked"] = true;
                          newSourceObject.assignMappingDTOs.push(assignmappingDtoClone[0]);
                          newSourceObject.selectedForms.push(assignmappingDtoClone[0]);
                          }
                        }
                      });
                    }
                    this.global.Channel.Source.push(newSourceObject);
                  }

                  });
                    
                  }

                  if(json.paramList != undefined && Array.isArray(json.paramList)){
                    json.paramList.forEach((inputParam) => {                                            
                     let attributeList: Map<string, AttributeObj> = this.projectService.attributeList;
                     for(let key in inputParam){                      
                      if(key == "paramKey"){
                        let paramKey
                        if(inputParam[key] != null){
                        if(inputParam[key].includes('params.') == true){
                          paramKey = inputParam[key].split('.')[1]
                        } else if(inputParam[key].includes('params.') == false){
                          paramKey = inputParam[key]
                        }
                      }
                        if(attributeList[paramKey]){
                          
                          let checkFilter = this.filters.find(x=>x.paramKey == paramKey);
                          if(checkFilter){                            
                            this.filters.forEach(element => {                              
                              if(element.paramKey == paramKey){
                                if((element.paramDatatype == 'String' || element.paramDatatype == 'string' || element.paramDatatype == 'Email' || element.paramDatatype == 'Mobile') && element.paramCategory == 0){
                                  this.global.Channel.Events.push(element)
                                }
                                if((element.paramDatatype == 'String' || element.paramDatatype == 'string' || element.paramDatatype == 'Email' || element.paramDatatype == 'Mobile') && element.paramCategory == 1){
                                  element.selected = []
                                  element.checked = true;
                                  element.values = []
                                  element.selected.value = inputParam.paramOprator;
                                  let checkTextLen = inputParam.paramValue.split("','");
                                  for(let i=0; i<checkTextLen.length; i++){
                                    element.selected.push({paramKey:element.paramKey,paramValue:checkTextLen[i].replaceAll("'","")});
                                    element.values.push({paramKey:element.paramKey,paramValue:checkTextLen[i].replaceAll("'","")})
                                  }
                                  this.global.Channel.Attributes.push(element);
                                  this.selectedFilters.push(element);
                                  }
                                  if(element.paramDatatype == 'Date'){
                                    element.selected = []
                                    element.checked = true;
                                    element.values = []
                                    let date = inputParam.paramValue.replaceAll("('","").replaceAll("')","").replaceAll('date','').replaceAll('"','');
                                    date = date.split('and');                                    
                                    let fromDate = date[0].split(' ')[0]
                                    let toDate = date[1].split(' ')[1]                                    
                                    element.selected.text1 = new Date(fromDate);
                                    element.selected.text2 = new Date(toDate);
                                    element.selected.value = inputParam.paramOprator;
                                    this.global.Channel.Attributes.push(element);
                                    this.selectedFilters.push(element);
                                    }

                                    if(element.paramDatatype == 'Numeric'){
                                      element.selected = []
                                      element.checked = true;
                                      element.values = []
                                      element.values.push({paramKey:element.paramKey,paramValue:inputParam.paramValue})
                                      element.selected.value = inputParam.paramOprator;
                                      element.selected = {text1: inputParam.paramValue, value: inputParam.paramOprator}
                                      this.global.Channel.Attributes.push(element);
                                      this.selectedFilters.push(element);
                                      
                                  }
                              }
                            });


                          // attributeList[paramKey].checked = true
                          // this.global.Channel.Attributes.push(attributeList[paramKey]);
                          }
                        }
                      }
                     }
                    });
                  }
                }
                this.paramList = json.paramList;
                this.userId = json.userId;
                this.fromDate = json.fromDate;
                this.toDate = json.toDate;        
                this.showLead = json.showLead;
                this.sourceFormIdList = json.pluginFormIds;
                if(this.fromDate != ''){
                    this.filterDateRange =  {startDate: moment(this.fromDate), endDate: moment(this.toDate)};
                    this.onDateSelection(this.filterDateRange);
                }
                //this.filterClose('isFilterApplied');
                this.calculateAudience();
          
              }); 
            }      
    }

    confirmModal(template: TemplateRef<any>, data?) {
        if(data == 'addFilter'){
            this.calculateAudience('reloadPagination');
            if (!this.validFilters) {
                return;
            }
        } else {
            this.tempModalData = data.id;
        }
        this.modalRef = this.modalService.show(template, {backdrop:'static'});
    }

    downloadAudience(){
      var filterData = {
        paramList: this.paramList,
        userId: this.userId,
        fromDate: this.fromDate,
        toDate: this.toDate,
        page: (this.page),
        limit: this.limit,
        searchString: this.searchText.trim(),
        showLead: this.showLead,
        pluginFormIds: this.sourceFormIdList,
        source : this.global.Channel.Source.map(function (val) {
          return val.pluginName;
        }).join(",")
      };
        this.reportService.downloadAudienceReport(filterData).subscribe(data => {
          if(data.status){
            this.toastr.success('You can download maximum 100 records at a time.');
            this.downloadFile(data.data);
          }
        })
    }

    downloadFile(filePath){
      var link=document.createElement('a');
      link.href = filePath;
      link.download = filePath.split('/').pop();
      link.click();
    }

    getEmailDetails(id,value,type, other?) {
      let url;
      if(type == 'Email'){
        url = this.emailApiUrl
      } else if(type == 'SMS'){
        url = this.smsApiUrl
      } else if(type == 'whatsapp'){
        url = this.whatsappApiUrl;
      }
        this.campaignService.checkConfigurationForCampaign({ campType: type, isAndroid: null, isIos: null, isWeb: null },url).subscribe( data => {
            var phone = value;
            if(other == 'india'){
                phone = this.phonePipe.transform(value);
            }
            if (data.status) {
                if(type=='whatsapp'){
                  this.whatsappMsgData = {
                      phone : phone,
                      smsText: null
                  };
                  window.open('https://wa.me/'+phone, '_blank').focus();
                 // this.toastr.error('Whatsapp configuration missing');
                  //this.openModal(this.sendWhatsappMsg,'');
                } else {
                  this.sendEmailSMS(id,value,type);
                }
            } else {
                if(type=='whatsapp'){
                  if(other == 'india'){
                    phone = '91' + phone;
                  }
                  window.open('https://wa.me/'+phone, '_blank').focus();
                } else{
                  this.toastr.error(data.error_msgs.errorMessage,'Error');
                }
            }
        });
    }

    changeAudienceColumn(){
      let email_key = this.audienceColumn.find(x=>x == 'email');
      if(email_key != undefined){
        let valid_email = this.audienceColumn.find(x=>x == 'email_valid');
        let is_repeat = this.audienceColumn.find(x=>x == 'is_repeat');
        valid_email == undefined ? this.audienceColumn.push('email_valid'): '';
        is_repeat == undefined ? this.audienceColumn.push('is_repeat') : '';
      }
        this.reportService.upsertAudienceColumn({columnList:this.audienceColumn,category:this.queryParamsFromMenu['params'].dynamicparam},this.queryParamsFromMenu['params'].dynamicparam).subscribe( data => {
          if(data.status){
            this.toastr.success(data.data);
            this.modalRef.hide();
            this.usingFor = 'viewAudience';
            this.calculateAudience('reloadPagination');
          } else {
            this.toastr.error(data.error_msgs);
          }
        })
    }

    fetchAudienceColumns(){
        this.reportService.fetchAudienceColumn({'category':this.queryParamsFromMenu['params'].dynamicparam}).subscribe( data => {
          if(data.status){            
            this.audienceColumn = data.data.split(',');
            for(let i=0; i<this.audienceColumn.length; i++)
            {
              let id_find = this.attributesList.find(x=>x.paramKey == this.audienceColumn[i]);
              if(id_find == undefined){
                delete this.audienceColumn[i];
              }
            }
            this.audienceColumn = this.audienceColumn.filter(p=>{
              return p != null
            })
          } else {
            this.toastr.error(data.error_msgs);
          }
        })
    }

    addAudience(){
        this.modalRefAddLead = this.modalService.show(AddAudienceComponent,{
            backdrop: "static",
            class:"modal-lg"
           
        });
        this.modalRefAddLead.content.modalRef = this.modalRefAddLead;
        this.modalRefAddLead.content.category = this.queryParamsFromMenu['params'].dynamicparam;
        this.modalRefAddLead.content.recordSubmit.subscribe(  data => {
            this.searchText = ""
            this.calculateAudience('reloadPagination');
        });
    }

    sendWhatsapp(){
        this.whatsappMsgData.smsText = this.whatsappMsgData.smsText != null ? this.whatsappMsgData.smsText : '';
        if(this.whatsappMsgData.smsText == ''){
          this.toastr.success(this.global.MessageRequired);
          return
        }
        this.whatsappMsgData.smsText = this.whatsappMsgData.smsText.replace(/^\s+\n|\s+$/g, '');
        this.configurationService.sendTestWhatsApp(this.whatsappMsgData).subscribe(data => {
          this.modalRef.hide();
          this.whatsappMsgData = { 
            phone : '',
            smsText: null
          };
          if(data.status){
              this.toastr.success(data.data);
          } else {
              this.toastr.error(data.error_msgs);
          }
      })
        
    }

    checkUncheckAll(event)
    {      
      this.showMapParams.forEach(element => {
        if(event.target.checked == true )
        {
          element.checked = true;
          this.xlsTableCol.push(element);
        }
        else
        {
          element.checked = false;
          this.xlsTableCol = [];
        }
        
      });
      if(event.target.checked == false)
      {
        this.showMapParams_extra_field.forEach(element => {
          this.xlsTableCol.push(element);
        });
      }
    }

    getParams() {
      this.xlsTableCol = [];
      this.showMapParams = [];
      this.showMapParams_extra_field = [];
      this.projectService.fetchAttributeList().then(data => {
          if(data){
              this.inputParams = this.projectService.filterAttributeList(['all']);
              if(this.inputParams)
              {
              this.inputParams.forEach(element => {
                element.checked = false;                                                  
                if( element.paramKey !='name' && element.paramKey !='email' && element.paramKey !='mobile' && element.paramDatatype != 'File' && element.pluginName)
                  {                      
                  element.showLabel = element.paramName;                    
                  this.showMapParams.push(element)
                  }

                  if(element.pluginName == "static" && element.paramDatatype != 'File' && element.paramKey =='name' || element.paramKey =='email' || element.paramKey =='mobile')
                   {                    
                    this.showMapParams_extra_field.push(element);                                        
                    this.xlsTableCol.push(element);                                    
                   }

              });  
              }

              let filterStoreByCat = [];
              let systemDefineAttr = [];
              this.showMapParams.forEach(elem=>{
                if(elem.paramOwner == 0 && elem.pluginName != 'static'){
                if(elem.category){
                  let catToArr = elem.category.split(',');
                  catToArr = catToArr.filter(p=>{
                      return p != null && p != "";
                  });
                  let checkByCat = catToArr.find(x=>x == this.parentMenu);
                  if(checkByCat){
                      console.log(elem);
                      filterStoreByCat.push(elem);
                  }
            }
          }
            if(elem.paramOwner == 1 && elem.pluginName != 'static'){
              systemDefineAttr.push(elem)
          }
          if(elem.pluginName == 'static'){
            systemDefineAttr.push(elem);
            }
              })

              if(filterStoreByCat.length > 0){
                this.showMapParams = filterStoreByCat;
                systemDefineAttr.forEach(elem=>{
                  this.showMapParams.push(elem)
                })
            } 
            if(filterStoreByCat.length == 0){
                this.showMapParams = []
                systemDefineAttr.forEach(elem=>{
                  this.showMapParams.push(elem)
                })
            }
              

              this.showMapParams_extra_field.sort((a, b) => a.paramName.localeCompare(b.paramName))
              this.showMapParams.sort((a, b) => a.paramName.localeCompare(b.paramName));

              let downloadDataXl = JSON.parse(localStorage.getItem('downloadDataXl'));
              if(downloadDataXl){
              downloadDataXl.forEach(element => {
                let checkElem = this.showMapParams.find(x=>x.paramKey == element.paramKey);
                if(checkElem){
                  checkElem.checked = true;
                  this.xlsTableCol.push(checkElem)
                }
              });
            }
          }
      });
      
  }
  

  openSelectAttr()
  {
    this.masterSelected = false;
    this.showMapParams.forEach(element => {
          element.checked = false;
    });
    this.xlsTableCol = [];
    this.searchAttribute = '';
    this.getParams();
    this.modalRef = this.modalService.show(this.attrListPopup, { class: '', backdrop: 'static' });
  }

  downloadXlFile()
  {

  }

  checkUncheckVal(event,colName)
  {    
    if(event.target.checked == true)
    {
        this.xlsTableCol.push(colName);
        let checkSelected = []
        this.showMapParams.forEach(element => {
          
          if(element.checked == false)
          {
            checkSelected.push(element);
          }
          
        });

        if(checkSelected.length == 0)
        {
          this.masterSelected = true;
        }
    }
    if(event.target.checked == false)
    {      
      this.masterSelected = false;
      let colIndex;
      colIndex = this.xlsTableCol.indexOf(colName);
      if(colIndex > -1)
      {
      this.xlsTableCol.splice(colIndex,1);      
      }
    }  
  }

    downloadAudienceData(type:string){
      let parentMenu;
        if(this.queryParamsFromMenu){
        parentMenu = this.queryParamsFromMenu['params'].dynamicparam;
      }

      this.calculateAudience();

      let selectedAttr = [];
      let matchArr = ['audience_id','name','email','android_fcm_token','ios_fcm_token','web_fcm_token','mobile','source','last_active','leadStatus'];
      this.xlsTableCol.forEach(element=>{ 
        
        if (element.pluginName != 'static') {
          if (matchArr.find(x => x === element.paramKey)) {
            selectedAttr.push({ id: 0, key: element.paramKey })
          } else {
            selectedAttr.push({ id: element.id, key: element.paramKey })
          }
        } else if (element.pluginName == 'static') {
          selectedAttr.push({ id: 0, key: element.paramKey })
        }
      })
      
     

      let tableKey = []
      this.xlsTableCol.forEach(element => {
        if(element.pluginName == 'dynamic'){
          tableKey.push('params.'+element.paramKey);
        } else{
          tableKey.push(element.paramKey);
        }
      });



      var filterData = {
        segment: [{
          "paramList": this.paramList,
          "pluginFormIds": this.sourceFormIdList,
          "source" : this.global.Channel.Source.map(function (val) { return val.pluginName; }).join(",")
        }],
        userId: this.userId,
        searchString: this.searchText.trim(),
        showLead: this.showLead,
        fields: tableKey.join(),
        reportName:new Date().toLocaleString(),
        
      };
      this.reportService.saveAudienceDataDownloadRequest(filterData, parentMenu).subscribe(data => {
        this.spinnerHide()
        if (data.status) {
          this.fromDate = '';
          this.toDate = '';
          this.toastr.success("Your request is captured successfully. You can visit download report menu  to watch status of report.", 'Success');
          this.modalRef.hide();
          this.masterSelected = false;
          this.showMapParams.forEach(element => {
              element.checked = false;
            });

        } else {
          this.toastr.error(data.error_msgs, 'Error');
        }
      });

      localStorage.setItem('downloadDataXl',JSON.stringify(this.xlsTableCol));

    }


}
