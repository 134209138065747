<main>
    <section class="inner-page">
        <div class="inner-page-header">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-6">
                        <h4>
                            <a class="back-link" (click)="back()"><img src="assets/images/angle-left.svg"></a>
                            Category 
                        </h4>
                    </div>
                    <div class="col-md-6 text-right j-end d-flex" >
                        <!-- <button class="btn btn-dark btn-icon" (click)="addStatus()"><img src="assets/images/plus-light.svg" alt=""></button> -->
                        <button class="btn white_add_btn d-flex c-align" (click)="repeatCheckBox = false; addStatus()" tooltip="Add Lead Status" [tooltipAnimation]="true" tooltipPlacement="top">
                            <img src="assets/images/plus-icon.png"> &nbsp; Add
                        </button>

                    </div>
                </div>
            </div>
        </div>

        <div class="inner-page-body site-form">
            <div class="container-fluid">
                <div class="table-responsive table-height-scroll table-reports">
                    <table class="table callAssistDashboardTable th_font_14">
                        <thead class="sticky-header" data-sticky-header="true">
                            <tr class="callAssistDbRowTop">
                                <th class="borderTh text-center">S.No.</th>
                                <th class="text-center borderTh">Name</th>
                                <th class="text-center borderTh">Action</th>
                            </tr>
                        </thead>
                        <tbody class="norecord-handling">
                            <tr *ngFor="let item of dataList; let i = index;">
                                <td class="text-center">{{i + 1}}</td>
                                <!-- <td  class="text-capitalize"><span class="tag" [style.background-color]="item.stageColour"></span> {{item.name}}</td> -->
                                <td  class="text-capitalize text-center">{{item.name}}</td>
                                <td class="text-center">
                                    <a class="btn without-hover btn-sm" [routerLink]="['/admin/settings/update-lead-status/',item.linkRewrite]"><img src="assets/images/segmenteye.png"></a>
                                    <a class="c-pointer" tooltip="Edit" tooltipPlacement="top" (click)="addStatus(item)">
                                        <img class="img" src="assets/images/pinkedit.png" />
                                    </a> &nbsp;
                                    <a class="c-pointer" tooltip="Delete" tooltipPlacement="top" (click)="showDeleteModal(item.id)">
                                        <img class="img" src="assets/images/delete.png" />
                                    </a>
                                </td>
                            </tr>
                            <tr *ngIf="dataList.length == 0">
                                <td class="no-records text-center" colspan="6">No Records Yet</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>

</main>

<ng-template #cnfrimUpdateStatus>
        <div class="leadupdateConfirmModal forgot-password-modal text-center">
        <div class="modal-header">
            <h4 class="modal-title pull-left">Are you sure want to {{isUpdated ? 'update' : 'add'}} ?</h4>
            <button type="button" class="close pull-right" (click)="modalRef.hide()">
              <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div>
            <div>
                <button class="btn btn btn-dark red" (click)="modalRef.hide()">No</button>
                <button class="btn btn btn-dark green" (click)="confirmChangeStatusType()">Yes</button>
            </div>
            </div>
        </div>
        </div>
    </ng-template>

<ng-template #addStatusModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{isUpdated ? 'Update' : 'Add'}} Category</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="siteForm" (ngSubmit)="saveForm()">
            <div class="form-row">
                <div class="form-group col-12">
                    <label class="required">Name</label>
                    <input trim="blur" type="text" class="form-control" formControlName="name" [readonly]="isUpdated" (input)="isUpdateName($event.target.value)" maxlength="50">
                </div>
            
                <div class="form-group col-12" >
                    <label class="control-label pt-0">User List.</label>
                    <ng-select [items]="subUserList"  class="params userListMultiSelect" bindLabel="userProfileName" [ngModelOptions]="{ standalone : true }"  [(ngModel)]="selectUserList"   fromControlName="userList" [multiple]="true" bindValue="id" > 
                        <ng-template ng-header-tmp>
                            <div>
                              <button type="button" class="btn btn-link"
                                      (click)="onSelectAllUser()">Select All</button>
                              <button type="button" class="btn btn-link"
                                      (click)="onClearAllUser()">Clear All</button>
                            </div>
                         </ng-template>
                    </ng-select>
                </div>

                <div class="form-group col-12" >
                    <div class="borderradis is_repeat_field">
                        <label class="check">
                          <span>Override Repeat Rule</span>
                          <input type="checkbox" fromControlName="repeatOverride" (change)="setRepeatStatus($event);" [(ngModel)]="repeatCheckBox" [ngModelOptions]="{ standalone : true }">
                          <span class="checkmark"></span>
                        </label>
                      </div>
                </div>

                <div class="col-12 text-right mt-1 mb-2">
                    <button class="btn btn-dark" type="submit">{{isUpdated ? 'Update' : 'Save'}}</button>
                </div>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #confirmModal>
    <form>
        <div class="forgot-password-modal">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Do you want to remove?</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
            </div>
            <div class="modal-body modal-user-management">
                <div class="form-group mb-0">
                    <p class="pt-2">
                        If you will delete this category, Records previously marked with category also removed.
                    </p>
                    <div class="d-flex justify-content-end mt-5">
                        <div>
                            <button class="btn remove-user-btn mr-4" (click)="modalRef.hide()" type="button">
                  Dismiss
                </button>
                        </div>
                        <div>
                            <button class="btn save-btn" type="button" (click)="deleteItem()">
                  Yes
                </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</ng-template>
<div class="hideLayOut">
<app-auth-layout [refreshMenuList]="refreshMenuList"></app-auth-layout>
</div>