<main>
  <section class="inner-page">
    <div class="inner-page-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <h4>Change Password</h4>
            <p>Enter the following information to change your password.</p>
          </div>
        </div>
      </div>
    </div>

    <div class="inner-page-body">
      <form [formGroup]="form" (ngSubmit)="changePassword()">

        <div class="container-fluid">

          <div class="row">

            <div class="col-12 col-sm-12 col-md-8 col-lg-6 mb-3 mt-3">

              <div class="form-group row mb-4">
                <label class="col-md-5 col-lg-5 control-label required">Old Password</label>
                <div class="col-md-7 col-lg-7">
                  <input type="password" class="form-control" formControlName="oldPassword" maxlength="32">
                </div>
              </div>

              <div class="form-group row mb-4">
                  <label class="col-md-5 col-lg-5 control-label required">New Password</label>
                  <div class="col-md-7 col-lg-7">
                    <input type="password" class="form-control" formControlName="newPassword" maxlength="20">
                  </div>
                </div>

                <div class="form-group row mb-4">
                    <label class="col-md-5 col-lg-5 control-label required">Re-enter New Password</label>
                    <div class="col-md-7 col-lg-7">
                      <input type="password" class="form-control" formControlName="confirmPassword" maxlength="20">
                    </div>
                  </div>

            </div>
            
            <div class="col-12 col-sm-12 col-md-12 col-lg-10 text-right">
              <button class="btn btn-dark" type="submit">Save</button>
            </div>

          </div>
        

      

        </div>

      </form>
    </div>

   

  </section>
</main>

