import { Component, OnInit, HostListener } from '@angular/core';
import { Global } from '../../../../shared/models/global.model';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { ProjectService } from '../../../../core/services/project.service';
import { ProjectDetailObj } from '../../../../core/modal/project';


@Component({
    selector: 'app-landing-view-page',
    templateUrl: './landing-page-view.component.html',
    styleUrls: ['./landing-page-view.component.scss','../../components/auth-layout/auth-layout.component.scss']
})

export class LandingPageViewComponent implements OnInit {
    email: any = '';
    isVisible:boolean = true;
    ls: any = {};
    domain:any;
    private isInited;
    pageUnderProcess: boolean = true;
    private iframeTag:any;
    innerWidth: number = 1200;
    parentClass: any = 'hide-menu';
    projectDetails:ProjectDetailObj;
    constructor (
        public global: Global,
        private sanitizer:DomSanitizer,
        private router: Router,
        private toastr: ToastrService,
        private authenticationService: AuthenticationService,
        private projectService: ProjectService
    ) {
        this.ls = localStorage;
    }

    @HostListener('window:message', ['$event'])
    onMessage(event) {
        if (typeof event.data === 'string' && event.data == "refresh"){
            this.router.navigate(['admin/dashboard']);
        }
        if (typeof event.data === 'string' && event.data == "hide"){
            this.pageUnderProcess = false;
        }
    }

    ngOnInit() {
        this.projectDetails = this.projectService.projectDetails;
        this.email = this.authenticationService.getUserData().email;
        if(this.projectDetails == undefined){
            this.router.navigate(['admin/landing-page']);
            return
        };
        this.innerWidth = window.innerWidth;
        if(this.innerWidth>1150){
            this.parentClass = 'show-menu';
        }
        if(this.innerWidth<575){
            this.router.events.subscribe((val) => {
                this.hideMenu();
            });
        }
        this.domain = this.sanitizer.bypassSecurityTrustResourceUrl(environment.landingPageURL);
    }

    ngAfterViewInit() {
        this.isInited = true;
    }
 
    loadIframe(iframeTagName) {
        if (this.isInited) {
            this.iframeTag = iframeTagName;    
            let message = {"email":this.email,key:this.projectDetails.projectKey,"tkn":this.authenticationService.getAuthToken()};
            iframeTagName.contentWindow.postMessage(message , '*');
        }
    }

    signout(){
        this.iframeTag.contentWindow.postMessage('signout' , '*');
        setTimeout(() => {
            this.router.navigate(['/admin/sign-out']);    
        }, 200);
    }
    
    transform(url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    @HostListener('contextmenu', ['$event'])
    onRightClick(event) {
        event.preventDefault();
    }
    showMenu() {
        // this.parentClass = 'show-menu';
        this.iframeTag.contentWindow.postMessage('openmenu' , '*');
    }
    hideMenu() {
        this.parentClass = 'hide-menu';
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if(this.innerWidth<=1150 && window.innerWidth>1150){
            this.parentClass = 'show-menu';
        }
        if(this.innerWidth>1150 && window.innerWidth<=1150){
            this.hideMenu();
        }
        this.innerWidth = window.innerWidth;
    }

    copyText(val: string){
        this.global.copyText(val);
        this.toastr.success('Copied to clipboard');
    }

}
