import { Component, OnInit, OnDestroy, TemplateRef, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { ToastrService } from "ngx-toastr";
import { Global } from "../../../../shared/models/global.model";
import { AttributeObj, LeadStatusObj } from "../../../../../app/core/modal/project";
import { ProjectService } from "../../../../../app/core/services/project.service";
import { noWhitespaceValidator } from "../../../../shared/models/no-whitespace-validator";
import { CacheService } from "../../../../core/services/cache.service";
import { Location } from "@angular/common";
import { SubUserObj } from "../../../../core/modal/user";
import { UserService } from "../../../../core/services/user.service";
import { AppConstants } from "../../../../constants/app.constants";
import { AuthenticationService } from "../../../../core/services/authentication.service";
import { ThrowStmt } from "@angular/compiler";
import { ActivatedRoute, Router } from '@angular/router';
import { CampaignService } from "../../../../core/services/campaign.service";
import { Subject } from "rxjs";
import { environment } from "../../../../../../src/environments/environment";
import { SpinnerService } from "../../../../core/services/spinner.service";
export class LevelData {
    tatDuration?:any;
    type?:any;
    level?:any;
    category?:any;
    paramKey?:any;
    paramKeyModified?:any;
    fields?:any;
    notifyOwner?:any;
    notifyReassignOwner?:any;
    userIds?:any = [];
    roleIds?:any = [];
    templateList?:any=[];
    tatDurationStore?:0;
    levelSeries?:any;
    disableLowType?:any;
    disableMediumType?:any;
    tatDurationUnit?:any;
    emailCheck?:any;
    webCheck?:any;
    androidCheck?:any;
    infoManager?:any;
    tatDurationInput?:any;
    emailTemplateName?:any;
    webTemplateName?:any;
    androidTemplateName?:any;    
    openCollapsible?:any;
    repeatTat?:boolean
}

@Component({
    selector: 'lead-status-app',
    templateUrl: './lead-status.component.html',
    styleUrls: ['./lead-status.component.scss']
})

export class LeadStatusComponent implements OnInit, OnDestroy {
    modalRef: BsModalRef;
    @ViewChild('addStatusModal') addStatusModal: TemplateRef<any>;
    @ViewChild('confirmModal') confirmModal: TemplateRef<any>;
    @ViewChild('cnfrimUpdateStatus') cnfrimUpdateStatus: TemplateRef<any>;
    @ViewChild('flowDataModal') flowDataModal:TemplateRef<any>;
    
    siteForm: FormGroup;
    tatForm:FormGroup;
    leadStatusApi;
    dataList: LeadStatusObj[] = [];
    subUserList: SubUserObj[] = [];
    leadStatusData: LeadStatusObj;
    isUpdated: boolean = false;
    selectedleadStatusId: number;
    isShowChangeOlder: boolean = false;
    allTelecallerUserList: SubUserObj[] = [];
    selectUserList: string[] = [];
    selectUserRoles:string[] = [];
    selectAttributes:string[] = [];
    roleList:any = new Array();
    tags:any;
    roleType:any;
    queryParamsFromMenu:any;
    customAttributes: any[] = [];
    fixedParams: AttributeObj[] = [];
    viewTatWindow:boolean=false;
    tatLevelType:any='low';
    LevelData:LevelData[] = []
    tatUserList:any=[];
    usersFromMaster:any;
    masterUsers:any=[]
    showRolesDropdown:boolean=false
    paramKeylist:any;
    emailTemplateList:any;
    isChannelType: boolean = false;
    campaignProceed: Subject<object> = new Subject();
    emailApiurl:any = environment.emailPushUrl;
    // webApiUrl:any = environment.webPushUrl;
    pushApiUrl:any=environment.pushServiceUrl;
    apiUrl:any;
    tatData:any=true;
    campaignSubmit:any;
    campaign:any;
    webPushTemplateList:any;
    androidPushTemplateList:any;
    tatDataGet;
    disableAddLevel:any;
    addTatEnable:any = true;
    isSubscribe: boolean = true;
    parentRoleList:any;
    deleteConfirm:boolean = false;
    deleteLevelIndex:any;
    viewPushDataWindow:any=false;
    pushDataStatus:any = "Submit";
    verifyTxt:any = "Verify";
    pushDataForm:FormGroup;
    webUrlDisable:any = false;
    verifiedUrl:any="";
    flowData:any
    webhookUrl:any;
    sendTimeType:any
    intervalTime:any;
    fields:any
    sendDataType:any;
    timeUnit:any = "Minute";
    statusId:any;
    pushDataSubscribe:any
    stageName:any;
    constructor(
        private modalService: BsModalService,
        private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private projectService: ProjectService,
        private globle: Global,
        private cacheService: CacheService,
        private userService: UserService,
        private location: Location,
        private authenticationService: AuthenticationService,
        private route:ActivatedRoute,
        private router:Router,
        private campaignService:CampaignService,
        private spinner:SpinnerService
    ) {
        this.isSubscribe = this.authenticationService.isPageSubscribe('TAT');
        this.pushDataSubscribe = this.authenticationService.isPageSubscribe('DataFlow')
    }

    ngOnInit() {
        this.route.paramMap.subscribe(data=>{
            if(data){
                this.queryParamsFromMenu = data;
            }
        })

        this.roleType = [
            {id:1,name:'Admin'},
            {id:2,name:'Marketer'},
            {id:3,name:'Telecaller'},
            {id:4,name:'Manager'}
        ]
        this.fetchLeadStatus();
        this.getSubUserList();
        this.fetchUserRole()
        this.getParams();
        this.getAllUserFromMaster();
        var list = this.projectService.filterAttributeList(['all']); 
        list.sort((a,b)=>(a.paramName > b.paramName)?1:-1);
        this.paramKeylist = list;
        let filterStoreByCat = [];
        let systemDefineAttr = [];
        this.paramKeylist.forEach(elem => {
            if(elem.paramOwner == 0 && elem.pluginName != 'static'){
              if(elem.category){                
                  let catToArr = elem.category.split(',');
                  catToArr = catToArr.filter(p=>{
                      return p != null && p != "";
                  });
                  let checkByCat = catToArr.find(x=>x == this.queryParamsFromMenu['params'].dynamicparam);
                  if(checkByCat){
                      filterStoreByCat.push(elem);
                  }   
            }
          } 
          if(elem.paramOwner == 1 && elem.pluginName != 'static'){
              systemDefineAttr.push(elem)
          }
          if(elem.pluginName == 'static'){
            systemDefineAttr.push(elem);
            }
          });
          
          if(filterStoreByCat.length > 0){
            this.paramKeylist = filterStoreByCat;
            systemDefineAttr.forEach(elem=>{
              this.paramKeylist.push(elem)
            })
        } 
        if(filterStoreByCat.length == 0){
          this.paramKeylist = []
            systemDefineAttr.forEach(elem=>{
              this.paramKeylist.push(elem)
            })
        }

        this.pushDataForm = this.formBuilder.group({
            // sendTimeType:[null,[Validators.required]],
            intervalTime:[null,[Validators.required]],
            sendDataType:[null,[Validators.required]],
            webhookUrl:[null,[Validators.required]],
            fields:[null,[Validators.required]],
            timeUnit:[null,[Validators.required]]

        })
    }

    showFlowDetail(id){
        this.getPushData(id,'iconClick');
    }

    deleteFlowData(){
        this.projectService.removePushdata(this.statusId).subscribe(data=>{
            if(data.status){
                this.toastr.success(data.data);
                if(this.viewPushDataWindow){
                    this.viewPushDataWindow = false
                } else{
                    this.modalRef.hide();
                }
                
            } else{
                this.toastr.error(data.error_msgs);
            }
        })
    }

    getPushData(id,event?){
        this.flowData = "";
        this.pushDataStatus = 'Submit'
        this.webhookUrl = ""
        this.sendTimeType = "";
        this.intervalTime = ""
        this.fields = []
        this.sendDataType = ""
        this.projectService.pushDataList(this.queryParamsFromMenu['params'].dynamicparam+'/'+id).subscribe(data=>{
            if(data.status){
                this.statusId = id;
                this.pushDataStatus = 'Update';
                
                if(event == 'iconClick'){
                    this.modalRef = this.modalService.show(this.flowDataModal, {
                        class:"status_details_modal",
                        backdrop: 'static'
                    });
                }
                this.flowData = data.data;
                this.stageName = this.flowData.statusName;
                let field = []
                let fields = this.flowData.fields.split(",");
                fields.forEach(element => {
                    if(element.includes("params.") == true){
                        element = element.replace("params.","");
                        field.push(element)
                    } else{
                        field.push(element)
                    }
                });
                this.webhookUrl = this.flowData.webhookUrl
                // this.sendTimeType = this.flowData.sendTimeType.toString();
                this.intervalTime = this.flowData.intervalTime
                this.fields = field
                this.sendDataType = this.flowData.sendDataType.toString()
                this.verifiedUrl = this.webhookUrl
                if(this.flowData.intervalTime >= 60 && this.flowData.intervalTime<1440){
                    if(this.flowData.intervalTime % 60 == 0){
                        this.intervalTime = this.flowData.intervalTime / 60;
                        this.timeUnit = 'Hour'
                    } else{
                        this.timeUnit = 'Minute'
                    }
                } else if(this.flowData.intervalTime >=1440){
                    if(this.flowData.intervalTime % 1440 == 0){
                        this.intervalTime = this.flowData.intervalTime/(60*24);
                        this.timeUnit = 'Day'
                    } else if(this.flowData.intervalTime % 60 == 0){
                        this.intervalTime = this.flowData.intervalTime/60;
                        this.timeUnit = 'Hour'
                    }
                } else if(this.flowData.intervalTime < 60){
                    this.timeUnit = 'Minute'
                }

            } else{
                if(event == 'iconClick'){
                this.toastr.error(data.error_msgs)
                }
            }
        })
    }

    pushDataSubmit(){
        if(this.pushDataForm.invalid){
            this.toastr.error("All fields are required");
            return;
        }

        if(this.verifiedUrl != this.pushDataForm.value.webhookUrl){
            this.toastr.error("Please verify configure url");
            return
        }

        if(this.timeUnit == 'Minute'){
        if(this.pushDataForm.value.intervalTime <= 2){
            this.toastr.error("Interval time should be greater then 2 minutes")
            return
        }
        } else{
            if(this.pushDataForm.value.intervalTime <= 0){
                this.toastr.error("Interval time should be greater then 0")
                return
            }
        }
        

        let fields = this.pushDataForm.value.fields;
        let paramKeys = []
        fields.forEach(elem=>{
            let findAttr = this.paramKeylist.find(x=>x.paramKey == elem);
            if(findAttr){
                if(findAttr.pluginName == "dynamic"){
                    paramKeys.push("params."+elem);
                } else{
                    paramKeys.push(elem);
                }
            }
        })
        
        let timeUnitInMinute = 0;
        if(this.timeUnit == 'Minute'){
            timeUnitInMinute = this.pushDataForm.value.intervalTime
        } else if(this.timeUnit == "Hour"){
            timeUnitInMinute = this.pushDataForm.value.intervalTime*60;
        } else if(this.timeUnit == 'Day'){
            timeUnitInMinute = this.pushDataForm.value.intervalTime*60*24
        }
        
        let postData = {
            webhookUrl:this.pushDataForm.value.webhookUrl,
            // sendTimeType:this.pushDataForm.value.sendTimeType,
            sendDataType:this.pushDataForm.value.sendDataType,
            intervalTime:timeUnitInMinute,
            fields:paramKeys.toString(),
            statusName:this.stageName,
            statusId:this.statusId,
            category:this.queryParamsFromMenu['params'].dynamicparam
        }
        
        this.projectService.pushDataSubmit(postData).subscribe(data=>{
            console.log(data);
            if(data.status){
                this.toastr.success(data.data);
                this.pushDataForm.reset();
                this.viewPushDataWindow = false;
                this.webUrlDisable = false
                this.modalRef.hide();
            } else{
                this.toastr.error(data.error_msgs)
            }
        })
    }

    verifyUrl(url){
        if(url){
            let postData = {webhookUrl:url}
            this.projectService.verifyDataPushUrl(postData).subscribe(data=>{
                if(data.status){
                    this.toastr.success("Success");
                    this.webUrlDisable = true;
                    this.verifiedUrl = data.data;
                } else{
                    this.toastr.error(data.error_msgs)
                }
            })
        } else{
            this.toastr.error("Please enter url")
        }
    }

    channelProcess(event){  
       
       
      }

    paramKeySelected(event,index){
        let eventValue = event.target.value;
        let checkParamKey = this.paramKeylist.find(x=>x.id == eventValue);
        if(checkParamKey && checkParamKey.pluginName == 'dynamic'){
            this.LevelData[index].paramKeyModified  = "params."+checkParamKey.paramKey
        } else if(checkParamKey && checkParamKey.pluginName != 'dynamic'){
            this.LevelData[index].paramKeyModified  = checkParamKey.paramKey
        }
    }

    levelAdd(){
        let getLastLevelSeries = this.LevelData.length;
        if(getLastLevelSeries == 0){
            this.LevelData.push({
                tatDuration:0,
                type:'NOTIFICATION',
                level:'LOW',
                category:this.queryParamsFromMenu['params'].dynamicparam,
                paramKey:'',
                paramKeyModified:'',
                fields:'',
                notifyOwner:true,
                notifyReassignOwner:false,
                userIds:[],
                roleIds:[],
                templateList:[],
                levelSeries:'level1',
                disableLowType:'',
                tatDurationUnit:'day',
                tatDurationStore:0,
                emailCheck:false,
                webCheck:false,
                androidCheck:false,
                infoManager:false,
                tatDurationInput:0,
                repeatTat:false
            });
            return
        }
        if(this.disableAddLevel){
            this.toastr.error("High level already added");
            return
        }
        if(getLastLevelSeries == 7){
            this.toastr.error('Maximum 7 levels are allowed.')
            return
        }
        let nextLevelSeries = getLastLevelSeries+1;
        let lastLevelType = this.LevelData[getLastLevelSeries-1].level;
        let currentLevelTATtype;
        let disableLowType = false;
        let disableMediumType = false;
        if(lastLevelType == 'LOW'){
            currentLevelTATtype = 'LOW'
        } else if(lastLevelType == 'MEDIUM'){
            currentLevelTATtype = 'MEDIUM';
            disableLowType = true
        } else if(lastLevelType == 'HIGH'){
            currentLevelTATtype = 'HIGH';
            disableLowType = true
            disableMediumType = true
        }
        this.LevelData.push({
                tatDuration:0,
                type:'NOTIFICATION',
                level:currentLevelTATtype,
                category:this.queryParamsFromMenu['params'].dynamicparam,
                paramKey:'',
                paramKeyModified:'',
                fields:'',
                notifyOwner:currentLevelTATtype == 'LOW' ? true :false,
                notifyReassignOwner:false,
                userIds:[],
                roleIds:[],
                templateList:[],
                levelSeries:'level'+nextLevelSeries,
                disableLowType:disableLowType,
                disableMediumType:disableMediumType,
                tatDurationUnit:'day',
                tatDurationStore:0,
                emailCheck:false,
                webCheck:false,
                androidCheck:false,
                infoManager:currentLevelTATtype == 'MEDIUM' ? true : false,
                tatDurationInput:0,
                openCollapsible:true,
                repeatTat:false
        });
        this.LevelData[getLastLevelSeries-1].openCollapsible = false;
    }

    confirmDeleteLevel(){
        this.disableAddLevel = false
        for(let i=0; i < this.LevelData.length; i++){
           delete this.LevelData[this.deleteLevelIndex];
        }
        this.LevelData = this.LevelData.filter(p=>{
            return p != null
        })
        this.deleteConfirm = false;
        this.deleteLevelIndex = undefined;

        this.LevelData.forEach(elem=>{
            if(elem.level == 'HIGH'){
                this.disableAddLevel = true
            }
        })
    }

    trashTat(index){
        this.deleteConfirm = true;
        this.deleteLevelIndex = index;
    }

    addPushData(){
        this.viewPushDataWindow = true;
    }

    addTat(){
        this.LevelData = []
        this.LevelData.push({
            tatDuration:0,
            type:'NOTIFICATION',
            level:'LOW',
            category:this.queryParamsFromMenu['params'].dynamicparam,
            paramKey:'',
            paramKeyModified:'',
            fields:'',
            notifyOwner:true,
            notifyReassignOwner:false,
            userIds:[],
            roleIds:[],
            templateList:[],
            levelSeries:'level1',
            disableLowType:false,
            disableMediumType:false,
            tatDurationUnit:'day',
            tatDurationStore:0,
            emailCheck:false,
            webCheck:false,
            androidCheck:false,
            infoManager:false,
            tatDurationInput:0,
            repeatTat:false
        })
        this.viewTatWindow = true;
        let parentMenu;
        if(this.queryParamsFromMenu){
          parentMenu = this.queryParamsFromMenu['params'].dynamicparam;
        }
    }

    udpateinfoManager(event,index){
        let infoCheckStatus = event.target.checked;
        if(infoCheckStatus == false){
            this.LevelData[index].roleIds = [] 
        }
    }

    loadWebTemplateList(event,index?){
        let eventStatus = event.target.checked;
        if(eventStatus == false){
            
            for(let i=0; i < this.LevelData[index].templateList.length; i++){
                if(this.LevelData[index].templateList[i].templateType == 'WEB'){                    
                    this.LevelData[index].webTemplateName = "";
                    delete this.LevelData[index].templateList[i]
                }
            }
            this.LevelData[index].templateList = this.LevelData[index].templateList.filter(p=>{
                return p != null
            })
            return
        }

            this.apiUrl = this.pushApiUrl;
            if(eventStatus == true && this.webPushTemplateList == undefined){
                this.campaignService.getEmailTemplateData(this.pushApiUrl,'reload').then(data => {
                    if(data != undefined)
                    this.webPushTemplateList = data;   
                    this.spinner.hide();
                });
                // this.campaignService.checkConfigurationForCampaign({ campType: 'Push', isAndroid: null, isIos: null, isWeb: true },this.webApiUrl).subscribe(data => {
                //     if (data.status) {
                //         this.spinner.show();
                //         this.campaignService.getEmailTemplateData(this.webApiUrl,'reload').then(data => {
                //             if(data != undefined)
                //             this.webPushTemplateList = data;   
                //             this.spinner.hide();
                //         });
                //     }
                // });
            }
    }

    loadAndroidTemplateList(event,index?){
        let eventStatus = event.target.checked;
        if(eventStatus == false){
            for(let i=0; i < this.LevelData[index].templateList.length; i++){
                if(this.LevelData[index].templateList[i].templateType == 'ANDROID'){                    
                    this.LevelData[index].androidTemplateName = "";
                    delete this.LevelData[index].templateList[i]
                }
            }
            this.LevelData[index].templateList = this.LevelData[index].templateList.filter(p=>{
                return p != null
            })
            return
        }
            this.apiUrl = this.pushApiUrl;
            if(eventStatus == true && this.androidPushTemplateList == undefined){                
               
                this.campaignService.getEmailTemplateData(this.pushApiUrl,'reload').then(data => {
                    if(data != undefined)
                    this.androidPushTemplateList = data;   
                    this.spinner.hide();
                });
               
                // this.campaignService.checkConfigurationForCampaign({ campType: 'Push', isAndroid: true, isIos: null, isWeb: null },this.pushApiUrl).subscribe(data => {
                //     if (data.status) {
                //         this.spinner.show();
                //         this.campaignService.getEmailTemplateData(this.pushApiUrl,'reload').then(data => {
                //             if(data != undefined)
                //             this.androidPushTemplateList = data;   
                //             this.spinner.hide();
                //         });
                //     }
                // });
            }
    }

    loadEmailTemplateList(event,index?){
        let eventStatus = event.target.checked;            
        if(eventStatus == false){
            
            for(let i=0; i < this.LevelData[index].templateList.length; i++){
                if(this.LevelData[index].templateList[i].templateType == 'EMAIL'){                    
                    this.LevelData[index].emailTemplateName = "";
                    delete this.LevelData[index].templateList[i]
                }
            }
            this.LevelData[index].templateList = this.LevelData[index].templateList.filter(p=>{
                return p != null
            })
            return
        }    
        this.apiUrl = this.emailApiurl;
        if(eventStatus == true && this.emailTemplateList == undefined){
            this.campaignService.checkConfigurationForCampaign({ campType: 'Email', isAndroid: null, isIos: null, isWeb: null },this.emailApiurl).subscribe(data => {
                if (data.status) {
                    this.spinner.show();
                    this.campaignService.getEmailTemplateData(this.emailApiurl,'reload').then(data => {
                        if(data != undefined)
                        this.emailTemplateList = data;   
                        this.spinner.hide();
                    });
                } else{
                    this.toastr.error(data.error_msgs.errorTitle);
                    this.LevelData[index].emailCheck = false
                }
            });
        }
    }
   async templateSelected(type,event,index){
        let templateId = event.target.value;        
        if(type == 'email' && templateId != ""){
       await this.campaignService.emailTemplate({id:templateId},this.emailApiurl).subscribe( data => {
        let templateAllData = data.data;
        let fields = []
        if(templateAllData.dynamicContentList){
            templateAllData.dynamicContentList.forEach(element => {
                    let checkDynamicParams = this.paramKeylist.find(x=>x.paramKey == element.paramKey);
                    if(checkDynamicParams && checkDynamicParams.pluginName == 'dynamic'){
                        fields.push('params.'+element.paramKey);
                    } else if(checkDynamicParams && checkDynamicParams.pluginName != 'dynamic'){
                        fields.push(element.paramKey);
                    }
            });
        }
        
        let selectedTemplateFromList = this.emailTemplateList.find(x=>x.id == templateId);
        if(selectedTemplateFromList){
            if(this.LevelData[index].templateList.length > 0){
                let checkEmailTemplateExist = this.LevelData[index].templateList.find(x=>x.templateType == 'EMAIL');
                if(checkEmailTemplateExist){
                    for(let i=0; i < this.LevelData[index].templateList.length; i++){
                        if(this.LevelData[index].templateList[i].templateType == 'EMAIL'){
                            delete this.LevelData[index].templateList[i]
                        }
                    }
                    this.LevelData[index].templateList = this.LevelData[index].templateList.filter(p=>{
                        return p != null
                    })
                }
            }
            this.LevelData[index].templateList.push({
                templateId:selectedTemplateFromList.id,
                templateName:selectedTemplateFromList.templateName,
                templateType:type.toUpperCase(),
                templateFields:fields.join(),
                templateTitle:selectedTemplateFromList.emailSubject,
                templateBody:selectedTemplateFromList.emailText
            })
        }
        });
    }

    if(type == 'web' && templateId != ""){
      await  this.campaignService.pushTemplate({id:templateId},this.pushApiUrl).subscribe( data => {
        let templateAllData = data.data;
        let fields = []
        if(templateAllData.dynamicContentList){
            templateAllData.dynamicContentList.forEach(element => {
                let checkDynamicParams = this.paramKeylist.find(x=>x.paramKey == element.paramKey);
                    if(checkDynamicParams && checkDynamicParams.pluginName == 'dynamic'){
                        fields.push('params.'+element.paramKey);
                    } else if(checkDynamicParams && checkDynamicParams.pluginName != 'dynamic'){
                        fields.push(element.paramKey);
                    }
            });
        }        
        let selectedTemplateFromList = this.webPushTemplateList.find(x=>x.id == templateId);
        if(selectedTemplateFromList){
            if(this.LevelData[index].templateList.length > 0){
                let checkEmailTemplateExist = this.LevelData[index].templateList.find(x=>x.templateType == 'WEB');
                if(checkEmailTemplateExist){
                    for(let i=0; i < this.LevelData[index].templateList.length; i++){
                        if(this.LevelData[index].templateList[i].templateType == 'WEB'){
                            delete this.LevelData[index].templateList[i]
                        }
                    }
                    this.LevelData[index].templateList = this.LevelData[index].templateList.filter(p=>{
                        return p != null
                    })
                }
            }
            this.LevelData[index].templateList.push({
                templateId:selectedTemplateFromList.id,
                templateName:selectedTemplateFromList.templateName,
                templateType:type.toUpperCase(),
                templateFields:fields.join(),
                templateTitle:selectedTemplateFromList.title,
                templateBody:selectedTemplateFromList.message
            })
        }
        });
    }

    if(type == 'android' && templateId != ""){
      await  this.campaignService.pushTemplate({id:templateId},this.pushApiUrl).subscribe( data => {
        let templateAllData = data.data;
        let fields = []
        if(templateAllData.dynamicContentList){
            templateAllData.dynamicContentList.forEach(element => {
                let checkDynamicParams = this.paramKeylist.find(x=>x.paramKey == element.paramKey);
                    if(checkDynamicParams && checkDynamicParams.pluginName == 'dynamic'){
                        fields.push('params.'+element.paramKey);
                    } else if(checkDynamicParams && checkDynamicParams.pluginName != 'dynamic'){
                        fields.push(element.paramKey);
                    }
            });
        }        
        let selectedTemplateFromList = this.androidPushTemplateList.find(x=>x.id == templateId);
        if(selectedTemplateFromList){
            if(this.LevelData[index].templateList.length > 0){
                let checkEmailTemplateExist = this.LevelData[index].templateList.find(x=>x.templateType == 'ANDROID');
                if(checkEmailTemplateExist){
                    for(let i=0; i < this.LevelData[index].templateList.length; i++){
                        if(this.LevelData[index].templateList[i].templateType == 'ANDROID'){
                            delete this.LevelData[index].templateList[i]
                        }
                    }
                    this.LevelData[index].templateList = this.LevelData[index].templateList.filter(p=>{
                        return p != null
                    })
                }
            }
            this.LevelData[index].templateList.push({
                templateId:selectedTemplateFromList.id,
                templateName:selectedTemplateFromList.templateName,
                templateType:type.toUpperCase(),
                templateFields:fields.join(),
                templateTitle:selectedTemplateFromList.title,
                templateBody:selectedTemplateFromList.message
            })
        }
        });
    }
    setTimeout(() => {
        this.LevelData.forEach(level=>{
            let allFieldsMerge=['params.lead_telecaller_id','audience_id']
            level.templateList.forEach(template=>{
                if(template.templateFields){
                    let strToArr = template.templateFields.split(',');
                    strToArr = strToArr.filter(p=>{
                        return p != null;
                    });
                    if(strToArr && strToArr.length > 0){
                        strToArr.forEach(element => {
                            let checkToPush = allFieldsMerge.find(x=>x == element);
                            if(checkToPush == undefined){
                                allFieldsMerge.push(element);
                            }
                        });
                    }
                }
            })
            level.fields =  allFieldsMerge.join();
        });
       
    }, 1000);

 

    }

    calculateDuration(event,index){
        let timeUnit = event.target.value;
        let minutes;
        if(timeUnit == 'day'){
            minutes = this.LevelData[index].tatDurationInput*24*60
        } else if(timeUnit == 'hours'){
            minutes = this.LevelData[index].tatDurationInput*60
        } else{
            minutes = this.LevelData[index].tatDurationInput
        }
        this.LevelData[index].tatDurationStore = minutes;        
    }

    updateDuration(event,index){
        let time = event.target.value;
        let minutes;
        if(this.LevelData[index].tatDurationUnit == 'day'){
            minutes = time*24*60
        } else if(this.LevelData[index].tatDurationUnit == 'hours'){
            minutes = time*60
        } else{
            minutes = time
        }
        this.LevelData[index].tatDurationStore = minutes;        
    }

    tatSubmit(){      
        let submit = true  

        if(this.LevelData.length > 0 && !this.LevelData[0].paramKey){
            submit = false
            this.toastr.error('Please select param key');
            return
        }

        this.LevelData.forEach((elem,index)=>{

            if(!elem.tatDurationStore){
                this.toastr.error('Please enter TAT duration in level '+(index+1));
                submit = false
                return
            }

            if(elem.level == 'MEDIUM' && elem.roleIds.length == 0){
                submit = false
                this.toastr.error('Manager roles are required in level '+(index+1));
                return
            }

            if(elem.level == 'HIGH' && elem.userIds.length == 0){
                submit = false;
                this.toastr.error('Please select users in level' + (index+1));
                return;
            }
                
            if((elem.level != 'HIGH' || elem.notifyOwner == true || elem.notifyReassignOwner == true || elem.infoManager == true) &&  elem.templateList.length == 0){
                submit = false;
                this.toastr.error('Templates are required in level '+(index+1));
                return
            }

            if(elem.tatDurationStore){
                elem.tatDuration = elem.tatDurationStore
            }

            elem.level = elem.level.toUpperCase();
            if(elem.userIds && elem.userIds.length > 0){
                elem.type = 'REASSIGN'
            }

            if(index > 0){
                elem.tatDuration = Number(elem.tatDuration) + Number(this.LevelData[index-1].tatDuration);
            }

            elem.paramKey = this.LevelData[0].paramKeyModified;
            
        })
        let tatFormObject ={
            'statusId':this.leadStatusData.id,
            'statusName':this.leadStatusData.stageName,
            'category':this.queryParamsFromMenu['params'].dynamicparam,
            'tatList':this.LevelData
        }
        if(submit == true){
        this.projectService.tatSave(tatFormObject).subscribe(data=>{
            if(data.status){
                this.toastr.success(data.data)
                this.viewTatWindow = false; 
                this.modalRef.hide()
            } else{
                this.toastr.error(data.error_msgs);
                this.viewTatWindow = false; 
                this.modalRef.hide()
            }
        });
    }
    }

    onSelectAllUser(index){
        const selected = this.tatUserList.map(item => item.id);
        this.LevelData[index].userIds = selected;
    }

    onClearAllUser(index){
    this.LevelData[index].userIds = [];
    }

    selectTatLevel(type,index){
        this.disableAddLevel = false;
        this.LevelData[index].level = type
        this.LevelData[index].levelSeries = 'level'+(index+1),
        this.LevelData[index].disableLowType = ''
        this.LevelData[index].openCollapsible = true

        this.LevelData.forEach(elem=>{
            if(elem.level == 'HIGH'){
                this.disableAddLevel = true;
                elem.infoManager = this.LevelData[index].infoManager;
                elem.type = 'REASSIGN'
            }
            if(elem.level == 'MEDIUM'){
                elem.infoManager = true
                elem.userIds = [];
                elem.repeatTat = false;
                elem.type = 'NOTIFICATION'
            }
            if(elem.level == 'LOW'){
                elem.notifyOwner = true
                elem.notifyReassignOwner = false;
                elem.infoManager = false
                elem.roleIds = [];
                elem.userIds = [];
                elem.repeatTat = false;
                elem.type = 'NOTIFICATION'
            }
        })
        // this.tatLevelType = type
    }

    goToTat(item){
       this.router.navigate(['/admin/settings/update-lead-status/'+this.queryParamsFromMenu['params'].dynamicparam+'/view-tat/'+item.id]);        
    }

    back() {
        this.location.back();
    }

    ngOnDestroy() {
        if (this.leadStatusApi != undefined) {
            this.leadStatusApi.unsubscribe();
        }
    }

    fetchUserRole(){
        this.roleList = [];
        this.userService.getUserRoleData().then(data => {
            if(data != undefined){
                this.roleList = data;
                this.parentRoleList = this.roleList.filter(p=>{
                    return p.roleTypeId == 1; 
                })
            }
        })
    }

    rolesSelected(event){
        this.tatUserList = [];
        let selectedRoles = event;
        if(selectedRoles.length > 0){
            this.masterUsers.forEach(element => {
                let checkUserByRoles = selectedRoles.find(x=>x == element.userRoleId);
                if(checkUserByRoles){
                    this.tatUserList.push(element)
                }
            });
        }
        
    }
    


    getSubUserList() {
        this.userService.getUsersData().then(data => {
            if (data != undefined) {
                 this.userService.getUsersData('all').then(data=>{
                    this.subUserList = data.filter(p=>{
                        return p.status != 'INACTIVE';
                    })
                })
            }
        });
    }

    getAllUserFromMaster(){
        this.projectService.getUserFromMaster().then(data=>{
            this.usersFromMaster = data;
            let userData = Object.keys(data);
            userData.forEach(elem=>{
                if(data[elem].status == 'ACTIVE'){                    
                    this.masterUsers.push(data[elem]);
                }
            });
        });
    }

    getTat(){
        this.addTatEnable = true
        this.projectService.tatList(this.queryParamsFromMenu['params'].dynamicparam+'/'+this.leadStatusData.id).subscribe(data=>{
            if(data.status){
                this.tatDataGet = data.data;
                if(this.tatDataGet.tatList && this.tatDataGet.tatList.length > 0){
                    this.addTatEnable = false
                }
            }
        })
    }

   

    editLevel(levelData,levelList,index){
        let getTatDurationCount;
        let tatDurationUnit;
        this.LevelData = [];
        this.disableAddLevel = false
        levelList.forEach((element,index) => {
            if(element.userIds && element.userIds.length > 0){
              element.userIds.forEach(userIds => {
                let checkUserInList = this.masterUsers.find(x=>x.id == userIds);
                if(checkUserInList){
                    let checkToPush = this.tatUserList.find(x=>x.id == checkUserInList.id);
                    if(checkToPush == undefined){
                    this.tatUserList.push(checkUserInList)
                    }
                }
              });
            }
            if(index>0){
                element.getTatDurationCount = Number(element.tatDuration - levelList[index-1].tatDuration);
                element.tatDurationStore = Number(element.tatDuration - levelList[index-1].tatDuration);
            } else if(index == 0){
                element.getTatDurationCount = Number(element.tatDuration)
                element.tatDurationStore = Number(element.tatDuration)
            }
            if(element.level == 'HIGH'){
                this.disableAddLevel = true;
            }
            if(element.getTatDurationCount >= 1440){
                if(element.getTatDurationCount % 1440 == 0){
                    getTatDurationCount = element.getTatDurationCount / 1440;
                    tatDurationUnit = 'day';
                } else if(element.getTatDurationCount % 60 == 0){
                    getTatDurationCount = element.getTatDurationCount / 60;
                    tatDurationUnit = 'hours'
                } else {
                    getTatDurationCount = element.getTatDurationCount
                    tatDurationUnit = 'minutes'
                }
            } else if(element.getTatDurationCount >= 60){
                if(element.getTatDurationCount % 60 == 0){
                    getTatDurationCount = element.getTatDurationCount / 60;
                    tatDurationUnit = 'hours'
                } else {
                    getTatDurationCount = element.getTatDurationCount;
                    tatDurationUnit = 'minutes'
                }
            } else{
                getTatDurationCount = element.getTatDurationCount;
                tatDurationUnit = 'minutes'
            }


            let removeparams;
            if(typeof(element.paramKey) == 'string' && element.paramKey.search('params.') > -1){
                removeparams = element.paramKey.replace('params.','');
            } else if(typeof(element.paramKey) == 'string'){
                removeparams = element.paramKey
            }
            let checkParamKey = this.paramKeylist.find(x=>x.paramKey == removeparams);
            if(checkParamKey){
                if(checkParamKey && checkParamKey.pluginName == 'dynamic'){
                    element.paramKeyModified  = "params."+checkParamKey.paramKey
                } else if(checkParamKey && checkParamKey.pluginName != 'dynamic'){
                    element.paramKeyModified  = checkParamKey.paramKey
                }
                element.paramKey  = checkParamKey.id;
            }
            
            this.LevelData.push({
                tatDuration:0,
                type:'NOTIFICATION',
                level:element.level,
                category:element.category,
                paramKey:element.paramKey,
                fields:element.fields,
                notifyOwner:element.notifyOwner,
                notifyReassignOwner:element.notifyReassignOwner,
                userIds:element.userIds,
                roleIds:element.roleIds,
                templateList:element.templateList,
                levelSeries:'level'+(index+1),
                disableLowType:'',
                tatDurationUnit:tatDurationUnit,
                tatDurationStore:element.tatDurationStore,
                emailCheck:false,
                webCheck:false,
                androidCheck:false,
                infoManager: element.roleIds && element.roleIds.length > 0 ?  true : false,
                tatDurationInput:getTatDurationCount,
                repeatTat:element.repeatTat,
                paramKeyModified:element.paramKeyModified
        })

        let eventFire = {target:{checked:true}}
        if(element.templateList && element.templateList.length > 0){
        element.templateList.forEach(elem=>{            
            if(elem.templateType == 'EMAIL'){
                this.LevelData[index].emailCheck = true
                this.loadEmailTemplateList(eventFire)
                this.LevelData[index].emailTemplateName = elem.templateId;
            } else if(elem.templateType == 'WEB'){
                this.loadWebTemplateList(eventFire)
                this.LevelData[index].webCheck = true
                this.LevelData[index].webTemplateName = elem.templateId;
            } else if(elem.templateType == 'ANDROID'){
                this.loadAndroidTemplateList(eventFire)
                this.LevelData[index].androidCheck = true;
                this.LevelData[index].androidTemplateName = elem.templateId; 
            }
        });
        }
        });
        this.viewTatWindow = true;
        this.LevelData[index].openCollapsible = true;
    }


    addStatus(data?: LeadStatusObj) {
        this.addTatEnable = true; 
        this.tatUserList = [];
        this.tatDataGet = undefined;                       
        if (this.dataList.length >= 25 && data == undefined) {
            this.toastr.error("Maximum lead status count 25.");
            return
        }
        this.prepareForm();
        this.isUpdated = false;
        this.selectUserList = [];
        this.selectUserRoles = [];
        this.selectAttributes = [];
        this.tags="";


        if (data != undefined) {
            this.tags = data.tags ? data.tags : "";
            this.leadStatusData = data;
            this.statusId = this.leadStatusData.id;
            this.stageName = this.leadStatusData.stageName;
            let parentMenu;
            if(this.queryParamsFromMenu){
            parentMenu = this.queryParamsFromMenu['params'].dynamicparam;
            }
            if(this.leadStatusData.userList && this.leadStatusData.userList.length > 0){
                this.leadStatusData.userList.forEach(elem=>{
                   let checkUserInStatus = this.masterUsers.find(x=>x.id == elem);
                   if(checkUserInStatus){
                       this.tatUserList.push(checkUserInStatus)
                   }
               })
           } else{
               this.projectService.fetchCategory({}).then(category=>{
                   category.forEach(element => {
                       if(element.linkRewrite == parentMenu){
                        if(element.userList.length > 0){
                           this.subUserList = this.subUserList.filter(p=>{
                               return element.userList.find(x=>x == p.id);
                           })
                           this.tatUserList = this.subUserList;
                        } else{
                            this.tatUserList = [];
                        }
                       }
                   });
                   
               })               
           }
           setTimeout(() => {
               if(this.tatUserList.length == 0){
                   this.showRolesDropdown = true
               } else{
                   this.showRolesDropdown = false;
               }
           }, 500);

           if (this.isSubscribe) {
            this.getTat();
           }

           if(this.authenticationService.isPageSubscribe('DataFlow')){
            this.getPushData(this.leadStatusData.id)
           }
           
            if(data.roles != undefined)
            {
                this.roleList.forEach(element => {
                    if(data.roles.find(x => x == element.id))
                    {
                        this.selectUserRoles.push(element.id);                        
                    }
                });
            }
            if (data.userList != undefined) {
                this.subUserList.forEach(userobject => {
                    if (data.userList.find(x => x == userobject.id)) {
                        this.selectUserList.push(userobject.id);
                    }
                });
            }

            if (data.attributes != undefined) {
                this.customAttributes.forEach(element => {
                    if (data.attributes.find(x => x == element.paramKey)) {
                        this.selectAttributes.push(element.paramKey);
                    }
                });
            }


            this.siteForm.setValue({
                id: data.id,
                stageName: data.stageName,
                stageColour: data.stageColour,
                stageType: data.stageType,
                orderId: data.orderId,
                changeOlder: 0,
                userList: this.selectUserList,
                roles:this.selectUserRoles,
                tags:this.tags,
                attributes:this.selectAttributes
            });

            this.isUpdated = true;
        }
        this.isShowChangeOlder = false;
        this.modalRef = this.modalService.show(this.addStatusModal, { class: "modal-addstatus status_details_modal", backdrop: 'static' });
    }

    saveForm() {        
        if (this.siteForm.invalid) {
            this.toastr.error('Please fill all mandatory fields');
            return;
        };

        this.modalRef.hide();

        this.modalRef = this.modalService.show(this.cnfrimUpdateStatus, { class: "", backdrop: 'static' });
    }

    confirmChangeStatusType(){
        let parentMenu;
        if(this.queryParamsFromMenu){
          parentMenu = this.queryParamsFromMenu['params'].dynamicparam;
        }

        this.siteForm.patchValue({
            userList: this.selectUserList,
            roles:this.selectUserRoles,
            tags:this.tags,
            attributes:this.selectAttributes
        });
        var formData = this.siteForm.value;
        
        this.projectService.upsetLeadStatus(parentMenu,formData).subscribe(data => {
            if (data.status) {
                this.toastr.success(data.data, 'Success');
                this.modalRef.hide();
                this.fetchLeadStatus();
                // this.updateLeadStatus();
            } else {
                this.toastr.error(data.error_msgs, 'Error');
            }
        })
    }




    fetchLeadStatus() {
        let parentMenu;
        if(this.queryParamsFromMenu){
          parentMenu = this.queryParamsFromMenu['params'].dynamicparam;
        }

        this.dataList = [];
        this.projectService.fetchLeadStatus(parentMenu,{}).subscribe(data => {
            if (data.status) {
                this.dataList = data.data;
                this.projectService.fetchCategory({},'reload').then(category=>{
                    category.forEach(element => {
                        if(element.linkRewrite == parentMenu && element.userList.length > 0){
                            this.subUserList = this.subUserList.filter(p=>{
                                return element.userList.find(x=>x == p.id);
                            })
                        }
                    });
                })
                this.projectService.setLeadStatusData(this.dataList);
            } else {
                this.toastr.error(data.error_msgs, 'Error');
            }
        })
    }

    prepareForm() {
        this.siteForm = this.formBuilder.group({
            id: ["0"],
            stageName: ["", Validators.compose([Validators.required, noWhitespaceValidator, Validators.minLength(2), Validators.maxLength(50), , Validators.pattern(this.globle.AlphaNumPattern)])],
            changeOlder: [0, Validators.compose([Validators.required])],
            orderId: ["", Validators.compose([Validators.required])],
            stageColour: ["", Validators.compose([Validators.required])],
            stageType: ["", Validators.compose([Validators.required])],
            userList: [],
            roles:["",[]],
            tags:["",[]],
            attributes:["",[]],
        });
    }

    showDeleteModal(id) {
        this.selectedleadStatusId = id;
        this.modalRef = this.modalService.show(this.confirmModal, {
            backdrop: 'static'
        });
    }

    deleteItem() {
        let parentMenu;
        if(this.queryParamsFromMenu){
          parentMenu = this.queryParamsFromMenu['params'].dynamicparam;
        }
        this.projectService.deleteLeadStatus(parentMenu,{ id: this.selectedleadStatusId }).subscribe(data => {
            this.modalRef.hide();
            if (data.status) {
                this.toastr.success(data.data, 'Success');
                this.fetchLeadStatus();
            } else {
                this.toastr.error(data.error_msgs, 'Error');
            }
        });
    }

    isUpdateName(value) {
        if (this.isUpdated) {
            this.isShowChangeOlder = false;
            this.siteForm.controls['changeOlder'].setValue(0);
            if (this.leadStatusData != undefined && this.leadStatusData.stageName != value) {
                this.isShowChangeOlder = true;
                this.siteForm.controls['changeOlder'].setValue(null);
            }
        }
    }

    getParams() {
        let parentMenu;
        if(this.queryParamsFromMenu){
          parentMenu = this.queryParamsFromMenu['params'].dynamicparam;
        }
        this.customAttributes = [];
        this.projectService.fetchAttributeList().then(data => {
            if (data) {
                this.fixedParams = this.projectService.filterAttributeList(['!static']);
                if (this.fixedParams) {
                    this.fixedParams.forEach(list => {
                        if(list.pluginName != 'static' && list.paramOwner == '0'){
                            let categoryArray: string[] = list.category.split(',');
                            categoryArray.forEach(cat =>{
                                if(cat == parentMenu){
                                    this.customAttributes.push(list); 
                                }
                            })
                        }
                    })
                }          
            }
        });
    }

}
