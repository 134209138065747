import { debounceTime, distinctUntilChanged, tap, switchMap, catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { concat } from 'rxjs';
import { Subject } from 'rxjs';
import { Component, NgZone, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ProjectService } from "../../../../core/services/project.service";
import { Global } from "../../../../shared/models/global.model";
import { CampaignService } from "../../../../core/services/campaign.service";
import { SegmentService } from "../../../../core/services/segment.service";
import { Router, ActivatedRoute } from "@angular/router";
import { DatePipe } from "@angular/common";
import { SpinnerService } from '../../../../core/services/spinner.service';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import * as internal from 'assert';
import { debounce } from 'lodash';
declare var FB: any;
export interface FBAudienceSegmentMapping {
  segmentAudienceId:string;
  status:string;	
  segmentId:number;
  audienceName:string;
  accountId:string;
}

@Component({
  selector: "app-create-segments",
  templateUrl: "./create-segments.component.html",
  styleUrls: ["./create-segments.component.scss"]
})



export class CreateSegmentsComponent implements OnInit {
  smsForm: FormGroup;
  addForm: FormGroup;
  modalRef: BsModalRef;
  fBAudienceSegmentMapping:FBAudienceSegmentMapping = undefined;
  Id: number;
  con: any;
  userId:any;
  allFilters:any = [];
  selectedFilters: any = [];
  filters: any = [];
  addtext: any = "addgroup";
  audienceJson: any;
  agebetween: any;
  showAudience: boolean = false;
  audience: any = "Calculate Audience";
  fetchUser: any = [];
  accountList: any = [];
  customAudienceList: any = [];
  selectedCustomAudience: any;
  selectedAccountId: any;
  validFilters: any = true;
  containValue;
  eventVal:boolean = false;
  events:any = []
  setmentGroup: any = {
    id: 0,
    segmentDetail: null,
    segmentDetailId: null,
    segmentFilters: [{
      id: 0,
      projectId: null,
      segGroupDetail: null,
      segGroupId: null,
      projectInputParams: null,
      projectInputParamId: 0,
      projectInputParamType: "",
      projectInputParamName: "",
      paramOprator: "=",
      paramValues: "34",
      created: null,
      selected: {
        value: ''
      }
    }],
    source: "",
    lastActive: "",
    event: "",
    created: null,
    updated: null
  };
  currentSegments: any = {};
  customAudienceName: string = "";
  customAudienceDesc: string = "";
  pluginFormsList:any = [];
  pluginForm:string[] = []
  invalidFilterDropdown:boolean= false;
  dataList:any;
  filtersStore:any;
  @ViewChild('facebook') facebook: TemplateRef<any>;
  @ViewChild('newFBCustomeAudience') newFBCustomeAudience: TemplateRef<any>
  constructor(
    private toastr: ToastrService,
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    public global: Global,
    private datePipe: DatePipe,
    private ngZone: NgZone,
    private segmentService: SegmentService,
    private campaignService: CampaignService,
    private router: Router,
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private authenticationService: AuthenticationService
  ) {
    this.fetchFilterDetails = debounce(this.fetchFilterDetails,500);
  }

  fetchFilterDetails(obj?,filterData?){
    this.getFilterDetails(obj,filterData);
  }

  ngOnInit() {
    this.userId = this.authenticationService.getUserId();
    this.con = console;
    this.prepareAddForm();
    this.getfetchUserSorce();
    this.facebookAsyncInit();
    this.fetchCategory();
    //this.openModalfacebook();
    this.selectedAccountId = 'Select Ad Account';
    this.selectedCustomAudience = 'Select Custom Audience';
  }

  fetchCategory() {        
    this.dataList = [];
    this.projectService.fetchCategory({}).then(data => {
        if (data) {
          const dataList = data;
            this.dataList = [];
            let id = this.authenticationService.getUserId();
                if(this.authenticationService.getUserRole() != 1){
                for(let i=0; i<dataList.length; i++){
                    if(dataList[i]){
                    let getUserList = dataList[i].userList.find(userid=>userid == id);
                    if(getUserList){
                      let checkToPush = this.dataList.find(x=>x.linkRewrite == dataList[i].linkRewrite);
                      if(checkToPush == undefined){
                          this.dataList.push(dataList[i])
                      }
                    }
                }
                }
            } else{
              this.dataList = dataList;
            }
        }
    })
}

  commaSeperate(e) {
    this.containValue = e.query + ',';
  }

  prepareParameters() {
    this.route.params.subscribe(params => {
      if (params["id"]) {
        this.prepareEditSegment(params["id"]);
      } else {
        this.prepareDefaultSegment();
      }
    });
  }

  facebookAsyncInit() {
    (window as any).fbAsyncInit = function () {
      FB.init({
        appId: '650726799076194',
        xfbml: false,
        version: 'v19.0'
      });
    };
    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }

  facebookLogin() {
    this.spinner.show();
    FB.login((response) => {
      this.getFBBusinessAccountId(response);
      this.projectService.saveFBAudeinceSegmentAuthToken({ fbAuthToken: response.authResponse.accessToken }).subscribe(data => {

      });
    }, { scope: 'business_management,ads_management,pages_show_list,leads_retrieval,pages_manage_metadata,pages_manage_ads,pages_read_engagement,pages_read_user_content' });
  }

  getFBBusinessAccountId(response) {
    let userId = response.authResponse.userID;
    let accessToken = response.authResponse.accessToken;
    const segmentComponentRef = this;
    FB.api('/me/adaccounts', (response) => {
      segmentComponentRef.spinner.show();

      segmentComponentRef.customAudienceList = [];
      if (response.data != undefined) {
        segmentComponentRef.ngZone.run(() => {
          segmentComponentRef.accountList = response.data;
          //this.accountList = response.data;
          // segmentComponentRef.modalRef.hide();
          segmentComponentRef.spinner.hide();
        });
      }
      if (response.error != undefined) {
        this.toastr.error(response.error.message, 'Error');
      }
    });
  }
  openModalfacebook() {
    this.modalRef = this.modalService.show(this.facebook, {
      backdrop: 'static'
    });
  }

  removeParamVal(item){
    if(item.selected.length == 0){
      item.placeholder = false
    }
  }


  fetchCustomAudiences() {
    this.spinner.show();
    const segmentComponentRef = this;
    segmentComponentRef.customAudienceList = [];
    this.selectedCustomAudience = this.selectedCustomAudience;
    FB.api(
      '/' + this.selectedAccountId + '/customaudiences',
      'get',
      { fields: 'id,name' }
      ,
      function (response: any) {
        segmentComponentRef.ngZone.run(() => {
          if (response && !response.error) {

            let audienceList = response.data;
            if (audienceList.length > 0) {

              segmentComponentRef.customAudienceList = response.data;
              // that.customAudienceList = response.data;
            }

          }
        });


        segmentComponentRef.spinner.hide();
      });

  }



  createCustomAudience() {
    if (this.customAudienceName == "") {
      this.toastr.error("Please fill custom audience name", 'Error');
    }
    if (this.customAudienceDesc == "") {
      this.toastr.error("Please fill custom audience description ", 'Error');
    }

    if (this.customAudienceName != "" && this.customAudienceDesc != "") {
      this.spinner.show();
      const segmentComponentRef = this;
      FB.api(
        '/' + segmentComponentRef.selectedAccountId + '/customaudiences',
        'post',
        {
          "name": segmentComponentRef.customAudienceName,
          "subtype": "CUSTOM",
          "description": segmentComponentRef.customAudienceDesc,
          "customer_file_source": "USER_PROVIDED_ONLY"
        },
        function (response: any) {
          segmentComponentRef.ngZone.run(() => {
            if (response && !response.error) {
              segmentComponentRef.selectedCustomAudience = response.id;
              segmentComponentRef.fetchCustomAudiences();
              segmentComponentRef.modalRef.hide();

            } else {
              segmentComponentRef.toastr.error(response.error.message, 'Error');
            }
          });


          segmentComponentRef.spinner.hide();
        });
    }

  }

  saveSegmentFBAudienceMapping(){
    if(this.currentSegments.id == 0){
      this.toastr.error("Please create segment first.", 'Error');
      return;
    }
    if(this.selectedCustomAudience == undefined || this.selectedCustomAudience == null){
      this.toastr.error("Please select audience.", 'Error');
      return;
    }
    let audienceName= "";
    this.customAudienceList.forEach(element => {
      if(element.id==this.selectedCustomAudience){
        audienceName =  element.name;
      }
    });
    this.segmentService.saveFBCustomAudienceSegementMapping({ segmentId:this.currentSegments.id,segmentAudienceId:this.selectedCustomAudience,accountId:this.selectedAccountId,audienceName:audienceName }).subscribe(data => {
          if(data.status){
            this.toastr.success("Saved Successfully");
            this.fetchFbAudienceSegmentMapping();
          }else{
            this.toastr.error(data.error_msgs, 'Error');
          }
    });
  
  }


  removeFBAudienceSegmentMapping(){
    this.segmentService.updateFBCustomAudienceSegementMapping({ segmentId:this.currentSegments.id,status:'DELETED' }).subscribe(data => {
      if(data.status){
        this.toastr.success("Removed Successfully");
        this.fBAudienceSegmentMapping = undefined;
      }else{
        this.toastr.error(data.error_msgs, 'Error');
      }
});
  }

  createNewFacebookCustomAudience(event) {
    if (event.target.value == 'new') {
      this.modalRef = this.modalService.show(this.newFBCustomeAudience, {
        backdrop: 'static'
      });
    }
  }


  prepareDefaultSegment() {    
    this.currentSegments = {
      id: 0,
      userProjects: null,
      projectId: null,
      jsonData: {
        userId:this.userId,
        fromDate:'',
        toDate:'',
        searchString:'',
        page:1,
        limit:20,
        showLead:false,
        pluginFormIds:[],
        paramList: [{
        id: 0,
        paramOprator: "=",
        paramValue: "",
        paramKey:'',
        selected: {}
        }],
        source: "",
        
      },
      segmentName: "",
      segmentId: "",
      segmentType: "Dynamic",
      segmentCondition: "OR",
      segStatus: null,
      created: "2020-02-07 09:53:44",
      lastModify: "2020-02-07 09:53:44"
    };    
    this.currentSegments.category = 'audience';

    let filterStoreByCat = [];
    let systemDefineAttr = [];
    this.filters = this.filtersStore
    this.filters.forEach(elem => {
      if(elem.paramOwner == 0 && elem.pluginName != 'static'){
        if(elem.category){
          
            let catToArr = elem.category.split(',');
            catToArr = catToArr.filter(p=>{
                return p != null && p != "";
            });
            let checkByCat = catToArr.find(x=>x == this.currentSegments.category);
            if(checkByCat){
                filterStoreByCat.push(elem);
            }   
      }
    } 
    if(elem.paramOwner == 1 && elem.pluginName != 'static'){
        systemDefineAttr.push(elem)
    }

    if(elem.pluginName == 'static'){
      systemDefineAttr.push(elem);
    }
    });
    
    if(filterStoreByCat.length > 0){
      this.filters = filterStoreByCat;
      systemDefineAttr.forEach(elem=>{
        this.filters.push(elem)
      })
  } 
  if(filterStoreByCat.length == 0){
    this.filters = []
      systemDefineAttr.forEach(elem=>{
        this.filters.push(elem)
      })
  }

  this.filters.sort((a,b)=>{
    if(a.paramName < b.paramName){ return -1 }
    if(a.paramName > b.paramName){ return 1 }
  });
  }

  prepareEditSegment(id) {                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          
    this.segmentService.fetchSegment({ id: id }).subscribe(data => {
      if (data.status) {
    let filterStoreByCat = [];
    let systemDefineAttr = [];

    this.filters = this.filtersStore
    this.filters.forEach(elem => {
      if(elem.paramOwner == 0 && elem.pluginName != 'static'){
        if(elem.category){   
            let catToArr = elem.category.split(',');
            catToArr = catToArr.filter(p=>{
                return p != null && p != "";
            });
            let checkByCat = catToArr.find(x=>x == data.data.category);
            if(checkByCat){
              filterStoreByCat.push(elem);
            }   
      }
    } 
    if(elem.paramOwner == 1 && elem.pluginName != 'static'){
        systemDefineAttr.push(elem)
    }

    if(elem.pluginName == 'static'){
      systemDefineAttr.push(elem);
    }
    });
    if(filterStoreByCat.length > 0){
      this.filters = filterStoreByCat;
      systemDefineAttr.forEach(elem=>{
        this.filters.push(elem)
      })
  } 
  if(filterStoreByCat.length == 0){
    this.filters = []
      systemDefineAttr.forEach(elem=>{
        this.filters.push(elem)
      })
  }

  this.filters.sort((a,b)=>{
    if(a.paramName < b.paramName){ return -1 }
    if(a.paramName > b.paramName){ return 1 }
  });




        let jsonData = JSON.parse(data.data.jsonData);           
        let events = [];
        for(let i=0; i < jsonData.paramList.length; i++){
          var filter = this.filters.find(x => x.id == jsonData.paramList[i].id); 
          if(filter){
            if(filter.paramCategory == '0'){
            events.push(filter);
            delete jsonData.paramList[i];
            }
            if( filter.paramOwner == 0 && filter.pluginName != 'static' && filter.category){
              let catToArr = filter.category.split(',');
              catToArr = catToArr.filter(p=>{
                return p != null;
              });
            let checkByCat = catToArr.find(x=>x == data.data.category);
            if(checkByCat == undefined){
              this.toastr.error('Attributes not visible for this category')
            } 
            }
            
          }  else{
            this.toastr.error('Attributes not visible for this role/category');
          }  
        } 
        jsonData.paramList = jsonData.paramList.filter((p)=>{
          return p != null;
        })
          jsonData.paramList.forEach((element, i, object) => {            
            element.paramValues = element.paramValue;
            var filter = this.filters.find(x => x.id == element.id);            
            if (filter != null) {
              // if(filter.paramCategory == '0')
              // {
              //   events.push(filter);
              //   object.splice(i,1);
              //   return;
              // }
              element.projectInputParamType = filter.paramDatatype;
            }
            element.values = [];
            this.setFilterValues(element.id, element, i);
            if (element.projectInputParamType == "String" || element.projectInputParamType == "string" || element.projectInputParamType == "Mobile" || element.projectInputParamType == "Email") {
              if ((element.paramOprator == 'in' || element.paramOprator == 'not_in') && element.paramValues != "") {                
                if (element.paramValues.charAt(0) === "'" && element.paramValues.charAt(element.paramValues.length -1) === "'"){
                  element.paramValues = element.paramValues.substr(1,element.paramValues.length -2);
                }

                if (element.paramValue.charAt(0) === "'" && element.paramValue.charAt(element.paramValue.length -1) === "'"){
                  element.paramValue = element.paramValue.substr(1,element.paramValue.length -2);
                }
                var lstValues
                if(element.paramValues.includes("','") == true){
                  lstValues = element.paramValues.split("','"); 
                } else{
                  lstValues = element.paramValues.split("','"); 
                }

                lstValues = lstValues.filter(p=>{
                  return p != null;
                })
                                
                element.selected = [];
                for (let i = 0; i < lstValues.length; i++) {
                  const el = lstValues[i];
                  element.selected.push({
                    paramValue: el
                  });
                }
              } else if (element.paramOprator == 'contain' && element.paramValues != "") {
                element.selected.text1 = element.paramValues;
              }
              element.selected.value = element.paramOprator;
            } else if (element.projectInputParamType == "Numeric") {
              element.selected = {};
              element.selected.value = element.paramOprator;
              element.selected.text1 = JSON.stringify(element.paramValues);
             // element.selected.text1 = element.paramValues.replace("'", "").replace("'", "");
              if (element.paramOprator == "between") {
                element.selected.text1 = element.paramValues.split(
                  " and "
                )[0]
                //.replace("'", "").replace("'", "");
                element.selected.text2 = element.paramValues.split(
                  " and "
                )[1]
                //.replace("'", "").replace("'", "");
              }
            } else if (element.projectInputParamType == "Date") {
              element.selected = {};
              element.selected.value = element.paramOprator;
              if (element.paramOprator == "between") {
                var date = element.paramValues.replace("') and date('", ',').replace("date('", "").replace("')", "").split(',');
                element.selected.text1 = new Date(date[0]);
                element.selected.text2 = new Date(date[1]);
              } else {
                var date = element.paramValues.replace("date('", '').replace("')", "");
                element.selected.text1 = new Date(date);
              }

            }


          });
        this.currentSegments = data.data;
        this.currentSegments.jsonData = jsonData;
        this.currentSegments.jsonData.userId = this.userId        
        let myFilter = [];
        this.currentSegments.jsonData.source = this.currentSegments.jsonData.source.split(',');
        this.filters.forEach(element => {
          let filter_data = this.currentSegments.jsonData.source.find(x=>x == element.pluginName) != undefined ? element : undefined;
          if(filter_data != undefined)
          {
            myFilter.push(filter_data)
          }          
        });
        this.allFilters.forEach(element => {
          if(element.pluginName == "dynamic" || element.pluginName == "static")
          {
            myFilter.push(element)
          }
        });
        
        this.filters = myFilter.sort((a,b)=>{ 
          if(a.paramName < b.paramName){ return -1 }
          if(a.paramName > b.paramName){ return 1 } });
        let elemSource = [];
        this.fetchUser.forEach(element => {
          if(this.currentSegments.jsonData.source.find(x=>x === element.pluginName))
          {
            elemSource.push(element);
            element.assignMappingDTOs.forEach(elements => {
              this.pluginFormsList.push(elements);
            });
          }
          
        });

        this.currentSegments.jsonData.source = elemSource;

        
        this.currentSegments.jsonData.pluginFormIds.forEach(elem=>{
          let findPluginForm = this.pluginFormsList.find(x=>x.id == elem);
          if(findPluginForm){
          this.pluginForm.push(findPluginForm)
          }
         });

         this.events = events;                  
        this.fetchFbAudienceSegmentMapping();
      }
    });

  }


  fetchFbAudienceSegmentMapping(){
       
        this.segmentService.fetchFBCustomAudienceSegementMapping({ segmentId: this.currentSegments.id }).subscribe(data => {
      if(data.status){
        this.fBAudienceSegmentMapping = data.data;
      }
    });
  }

  prepareAddForm() {
    this.addForm = this.formBuilder.group({
      Name: [],
      segmentName: [],
      segmentType: [],
      segmentCondition: [],
      segmentGroupDetail: this.formBuilder.array(
        this.Id ? [] : [this.initsegment()]
      )
    });
  }

  addgroup() {
    this.currentSegments.segmentGroupDetail.push(this.setmentGroup);
  }

  removeGroup() {
    this.currentSegments.segmentGroupDetail.pop();
  }

  addItem(segmentFilters: any) {
    this.validFilters = true;
    for(let i=0; i<segmentFilters.length; i++){
      if(segmentFilters[i].id == 0 && segmentFilters[i].paramValue == ""){
        return
      }
    }
    segmentFilters.push({
        id: 0,
        paramOprator: "=",
        paramValue: "",
        selected: {}
    });
  }

  removeItem(segmentFilters, i: number) {    
    segmentFilters.splice(i, 1);
  }

  initsegment() {
    return this.formBuilder.group({
      ProjectInputParamId: [],
      paramOprator: [],
      ParamValues: []
    });
  }

  setFilterValues(id, item, i , addfilter?) {    
    var filter = this.filters.find(x => x.id == id);    
    if (filter != null) {
      item.projectInputParamType = filter.paramDatatype;
      item.projectInputParamId = filter.id;
      item.id = item.projectInputParamId;
      item.paramKey = filter.pluginName == 'dynamic'?'params.'+filter.paramKey:filter.paramKey;
      item.selected = {};
      if (item.projectInputParamType == "String" || item.projectInputParamType == "string" || item.projectInputParamType == "Mobile" || item.projectInputParamType == "Email") {        
        item.selected.value = "in";
        item.values = [];
        // if(!addfilter){
        // this.getFilterDetails(item);
        // }
        this.getFilterDetails(item);
      } else if (item.projectInputParamType == "Date") {
        item.selected.value = "between";
        item.values = [];
        this.selectedFilters.push(item);
      } else if (item.projectInputParamType == "Numeric") {
        item.selected.text1 = "";
        item.selected.value = "=";
        item.selected.text2 = "";
        item.values = [];
        this.selectedFilters.push(item);
      }
    } else {
      item.projectInputParamType = "";
      item.projectInputParamId = 0;
    }

  }

  isDisable(segmentFilters, id) {
    return segmentFilters.find(x => x.projectInputParamId == id) != undefined;
  }
  

  totalFiltersCount() {
    return this.filters.filter(x => x.paramCategory == "1").length;
  }

  removeAudience(item?) {
    if(item){
    const itemSelectedVal = item.selected.value;
    item.selected = [];
    item.selected.value = itemSelectedVal;
    item.paramValue = ""
    }
    this.showAudience = false;
    this.audience = "Calculate Audience";
  }

  removeaudinceFilter(value, bypass) {
    this.agebetween = value;
    return;
  }

  addFilter(value: any) {
    this.agebetween = value;
  }


  removeFilters() {
    this.removeAudience();
    var length = this.selectedFilters.length;
    for (let index = 0; index < length; index++) {
      this.filters.push(this.selectedFilters[0]);
      this.selectedFilters.splice(0, 1);
    }
  }

  stringify(input) {
    return JSON.stringify(input);
  }

  getFilters() {
    this.projectService.fetchAttributeList().then(data => {
      if (data) {
        this.filters = this.projectService.filterAttributeList();     
        this.filtersStore = this.projectService.filterAttributeList();

        this.filters.sort((a,b)=>{
          if(a.paramName < b.paramName){ return -1 }
          if(a.paramName > b.paramName){ return 1 }
        });

        this.filtersStore.sort((a,b)=>{
          if(a.paramName < b.paramName){ return -1 }
          if(a.paramName > b.paramName){ return 1 }
        });

        this.allFilters = this.filters;
        this.prepareParameters();
      }
    })
  }

  categoryUpdate(event){    
    let categoryName = event.target.value
    let filterStoreByCat = [];
    let systemDefineAttr = [];
    this.filters = this.filtersStore
    this.filters.forEach(elem => {
      if(elem.paramOwner == 0 && elem.pluginName != 'static'){
        if(elem.category){   
            let catToArr = elem.category.split(',');
            catToArr = catToArr.filter(p=>{
                return p != null && p != "";
            });
            let checkByCat = catToArr.find(x=>x == categoryName);
            if(checkByCat){
              filterStoreByCat.push(elem);
            }   
      }
    } 
    if(elem.paramOwner == 1 && elem.pluginName != 'static'){
        systemDefineAttr.push(elem)
    }

    if(elem.pluginName == 'static'){
      systemDefineAttr.push(elem);
    }
    });
    if(filterStoreByCat.length > 0){
      this.filters = filterStoreByCat;
      systemDefineAttr.forEach(elem=>{
        this.filters.push(elem)
      })
  } 
  if(filterStoreByCat.length == 0){
    this.filters = []
      systemDefineAttr.forEach(elem=>{
        this.filters.push(elem)
      })
  }

  this.filters.sort((a,b)=>{
    if(a.paramName < b.paramName){ return -1 }
    if(a.paramName > b.paramName){ return 1 }
  });

    if(this.currentSegments.jsonData.paramList.length > 0){
    this.currentSegments.jsonData.paramList.forEach(elem=>{
      if(elem.paramKey == "params.lead_status"){
        elem.projectInputParamId = '0';
        elem.paramValue = '';
        elem.paramValues = '';
        elem.selected = [];
        elem.paramKey = '';
        elem.paramOprator = '';
      }
    })
  }
  }

  getFilterDetails(obj,event?) {                
    let eventVal;
    if(event){
      eventVal = event.target.value;
      if(eventVal == ""){
        obj.placeholder = false  
      } else{
        obj.placeholder = true
      }
      
    } 

    if(obj.paramKey == 'params.telecaller_name'){
      let userDataList=[];
      obj.values = [];
      this.projectService.getUserFromMaster().then(data => {
        if (data != undefined) {
            let userData = Object.keys(data);
            userData.forEach(elem=>{
                if(data[elem].status == 'ACTIVE'){
                  userDataList.push({'paramKey':'params.telecaller_name','paramValue':data[elem].userName});
                  }
            });
            if(userDataList.length > 0){
              obj.values = userDataList;
              var selected = obj.selected;
              this.loadAutoComplete(obj);
              obj.selected = selected;
              this.selectedFilters.push(obj);
            }
    }
  });
  return
}
    
    if(obj.paramKey == 'params.lead_status'){
    let getCategory = this.dataList.find(x=>x.linkRewrite == this.currentSegments.category);
    if(getCategory && getCategory.leadStatusList){
      let data=[];
      obj.values = [];
      getCategory.leadStatusList.forEach(element => {
        data.push({'paramKey':"params.lead_status",'paramValue':element.stageName});
      });
      obj.values = data
      var selected = obj.selected;
      this.loadAutoComplete(obj);
      obj.selected = selected;
      this.selectedFilters.push(obj);
    }
    return
  }
    
    if(eventVal == undefined && obj.paramKey != 'params.lead_status' && obj.paramKey != 'params.telecaller_name' && obj.paramKey != 'params.country'){
      return;
    }  

    this.campaignService.searchParamData({ paramKey: obj.paramKey,paramValue:eventVal ? eventVal:'' }).subscribe(data => {
      if (!data.status) {
        obj.values = []
        this.toastr.error(data.error_msgs);
      } else {
        obj.values = data.data;
        var selected = obj.selected;
        this.loadAutoComplete(obj);
        obj.selected = selected;
        this.selectedFilters.push(obj);
      }
    });
  }

  private loadAutoComplete(obj) {
    obj.valueInput$ = new Subject<string>();
    obj.selected = <any>[];
    obj.values$ = concat(of([]),
      // default items
      obj.valueInput$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => obj.loading = true),
        switchMap(term => this.getPeople(obj.paramKey, term).pipe(
          catchError(() => of([])), // empty list on error
          tap(() => obj.loading = false)
        ))
      )
    );
  }

  getPeople(paramKey, term) {
    return this.campaignService.searchParamData({ paramKey: paramKey, paramValue: term }).pipe(map(res => res.data));
  }

  getfetchUserSorce() {
    this.projectService.getUserSourceData().then(data => {
      this.fetchUser = this.projectService.getSourceList();
      this.fetchUser = this.fetchUser.filter(p=>{
        return p.assignMappingDTOs && p.assignMappingDTOs.length > 0
      })
      this.fetchUser = this.fetchUser.sort((a,b)=>{
        if(a.pluginName < b.pluginName){
          return -1
        }
        if(a.pluginName > b.pluginName){
          return 1;
        }
      })
     // this.fetchUser = data;      
      this.getFilters();
    });
  }

  selectedInFilter(i,type) {
    setTimeout(() => {
      this.currentSegments.jsonData.paramList[i].selected.value = type;
    }, 100);
  }

  onSelectAllSource(){
    const selected = this.fetchUser.map(item => item);
    this.currentSegments.jsonData.source = selected;
    this.updateSource(this.currentSegments.jsonData.source)
  }

  onSelectAllPlugin(){
    const selected = this.pluginFormsList.map(item => item);
    this.pluginForm = selected
  }

  onClearAllPlugin(){
    this.pluginForm = []
  }

  onClearAllSource(){
    this.currentSegments.jsonData.source = []
    this.updateSource([])
  }

  submit() {
    this.invalidFilterDropdown = false;
    this.validFilters = true
    if ( this.currentSegments.segmentName == "" || this.currentSegments.jsonData.paramList.length == 0 && this.events.length == 0) {
        this.validFilters = false;
        this.toastr.error("Please fill valid input in all mandatory fields");
        this.spinner.hide();        
        return
    }
    if(this.currentSegments.segmentName){
      let alphaNumWithChar  = /^[a-z A-Z0-9_/-]*$/g
      if(!alphaNumWithChar.test(this.currentSegments.segmentName)){
          this.toastr.error('Segment Name Should be Alphanumeric', 'Error');
          return;
      }
    }
    if(this.currentSegments.jsonData.paramList.length > 0){
      if((this.currentSegments.jsonData.paramList[0].projectInputParamType == 'String' || this.currentSegments.jsonData.paramList[0].projectInputParamType == 'string' || this.currentSegments.jsonData.paramList[0].projectInputParamType == 'Email' || this.currentSegments.jsonData.paramList[0].projectInputParamType == 'Mobile') && Array.isArray(this.currentSegments.jsonData.paramList[0].selected) == false && this.currentSegments.jsonData.paramList[0].selected.text1 == undefined){        
        this.validFilters = false;
        this.toastr.error("Please fill valid input in all mandatory fields");
        this.spinner.hide();        
        return
      }

      if(this.currentSegments.jsonData.paramList[0].projectInputParamType == 'Numeric' && this.currentSegments.jsonData.paramList[0].selected.text1 == ""){        
        this.validFilters = false;
        this.toastr.error("Please fill valid input in all mandatory fields");
        this.spinner.hide();
        return
      }
    }

    if(this.currentSegments.category == '0'){
      this.toastr.error('Please select category');
      this.validFilters = false;
      return
    }

    let lastField = this.currentSegments.jsonData.paramList[this.currentSegments.jsonData.paramList.length-1];
    if(lastField.id == 0 && lastField.paramValue == "" && this.events.length == 0 && this.currentSegments.jsonData.paramList.length > 0){
      this.toastr.error("Please fill valid input in all mandatory fields");
      this.spinner.hide();
      this.invalidFilterDropdown = true
      return
    }

    this.currentSegments.searchString = "";
    let pluginForms = [];
    pluginForms = this.pluginForm
    let pluginFormsIds = [];
    pluginForms.forEach(elem=>{
      if(elem.id)
      {
        pluginFormsIds.push(elem.id);
      }
      else
      {
        pluginFormsIds.push(elem);
      }
    })
    this.currentSegments.jsonData.pluginFormIds = pluginFormsIds;
    this.spinner.show();
    this.validFilters = true;
    this.currentSegments.projectId = this.authenticationService.getProjectId();
    let source = []
    if(this.currentSegments.jsonData.source.length > 0)
    {
    this.currentSegments.jsonData.source.forEach(element => {      
      if(element.pluginName)
      {
      source.push(element.pluginName);
      }
      else
      {
        source.push(element);
      }
    });
    }
    else
    {
     // this.validFilters = false
    }

    const myClonedArray = [];
    this.currentSegments.jsonData.paramList.forEach(val => myClonedArray.push(Object.assign({}, val)));   
    
    myClonedArray.forEach(elems=>{
      for(let key in elems)
      {
        if(key == 'valueInput$' || key == 'values' || key == 'values$')
        {
          delete elems[key]
        }
      }
    });    
    localStorage.setItem('paramList',JSON.stringify(myClonedArray));

    for(let i=0; i<this.currentSegments.jsonData.paramList.length; i++){
      this.currentSegments.jsonData.paramList[i].conainsError = false
      if(this.currentSegments.jsonData.paramList[i].projectInputParamType == "String" || 
      this.currentSegments.jsonData.paramList[i].projectInputParamType == "string" || 
      this.currentSegments.jsonData.paramList[i].projectInputParamType == "Email" || 
      this.currentSegments.jsonData.paramList[i].projectInputParamType == "Mobile"){
        if(this.currentSegments.jsonData.paramList[i].selected && this.currentSegments.jsonData.paramList[i].selected.value == 'contain'){
            let paramValSplitByComma = this.currentSegments.jsonData.paramList[i].selected.text1.split("','");
            paramValSplitByComma = paramValSplitByComma.filter((p)=>{
              return p != null;
            })
            let storeWithoutSpace = []
            for(let j=0; j<paramValSplitByComma.length;j++){
              if(paramValSplitByComma[j].length < 3){
                this.currentSegments.jsonData.paramList[i].conainsError = true;
                this.spinner.hide();
                return
              }
            }
        }
      }
    }

    this.currentSegments.jsonData.paramList.forEach(element => {   
        if (element.projectInputParamType == "String" || element.projectInputParamType == "string" || element.projectInputParamType == "Email" || element.projectInputParamType == "Mobile") {
          element.paramOprator = element.selected.value;
          element.paramValue = "";
          if (element.selected != undefined && (element.selected.value == 'in' || element.selected.value == 'not_in')) {
            for (let i = 0; i < element.selected.length; i++) {
              const el = element.selected[i];

              element.paramValue +=
                "'" +
                el.paramValue +
                "'" +
                (element.selected.length == i + 1 ? "" : ",");
            }
          } else {            
            if (element.selected.text1 && element.selected.text1.paramValue != undefined) {
              element.selected.text1 = element.selected.text1.paramValue
            }
            if(element.selected.text1){
            element.paramValue = element.selected.text1;
            let paramValSplitByComma = element.paramValue.split(',');
            paramValSplitByComma = paramValSplitByComma.filter((p)=>{
              return p != null;
            })
            let storeWithoutSpace = []
            paramValSplitByComma.forEach(element => {
              element = element.trim();
              storeWithoutSpace.push(element);
            });
            element.paramValue = storeWithoutSpace.join();            
            } else{
              this.invalidateRequest();
            }
          }

          if (element.paramValue == "") {
            this.invalidateRequest();
          }
          element.valueInput$ = '';
          element.values = [];
          element.values$ = '';
        } else if (element.projectInputParamType == "Numeric") {
          element.paramOprator = element.selected.value;
          if (element.selected.value == "between") {
            element.paramValue =
              element.selected.text1 + " and " + element.selected.text2;
            if (
              element.selected.text1 == undefined ||
              element.selected.text1 === "" ||
              element.selected.text2 === "" ||
              element.selected.text2 == undefined ||
              element.selected.text1.length > 10 ||
              element.selected.text2.length > 10
            ) {
              this.invalidateRequest();
            }
          } else {
            element.selected.text1 = parseInt(element.selected.text1);
            element.paramValue = element.selected.text1;
            if (
              element.selected.text1 == undefined ||
              element.selected.text1 === "" ||
              element.selected.text1.length > 10
            ) {
              this.invalidateRequest();
            }
          }
          if (element.paramValue == undefined || element.paramValue === "" || Number.isNaN(element.paramValue) == true) {
            this.invalidateRequest();
          }
        } else if (element.projectInputParamType == "Date") {
          element.paramOprator = element.selected.value;
          if (element.selected.value == "between") {
            if (
              element.selected.text1 > element.selected.text2 ||
              element.selected.text1 == undefined ||
              element.selected.text1 == "" ||
              element.selected.text2 == "" ||
              element.selected.text2 == undefined ||
              element.selected.text1.length > 10 ||
              element.selected.text2.length > 10
            ) {
              this.invalidateRequest();
            }
            element.paramValue =
              "date('" +
              this.datePipe.transform(element.selected.text1, "yyyy-MM-dd") +
              " 00:00:00') and date('" +
              this.datePipe.transform(element.selected.text2, "yyyy-MM-dd") +
              " 23:59:59')";
          } else {
            if (
              element.selected.text1 == undefined ||
              element.selected.text1 == "" ||
              element.selected.text1.length > 10
            ) {
              this.invalidateRequest();
            }
            element.paramValue = "date('" + this.datePipe.transform(element.selected.text1, "yyyy-MM-dd") + "')";
          }
          if (element.paramValue == undefined || element.paramValue == "") {
            this.invalidateRequest();
          }
        } else if(this.events.length == 0) {
          this.invalidateRequest();
        }

        for(let key in element)
        {          
          if(key == 'projectInputParamId' || key == 'paramValues' || key =='projectInputParamType' || key == 'selected' || key =='valueInput$' || key == 'values' || key == 'values$' || key == 'loading' || key == 'param_key')
          {            
            delete element[key];            
          }
        }
            
      });
   
      if (!this.validFilters) {
        let paramList = JSON.parse(localStorage.getItem('paramList'));
        paramList.forEach(element=>{
          if(element && element.selected){            
          if(element.projectInputParamType == 'Date'){
            if(element.selected.text1){
              element.selected.text1 = new Date(element.selected.text1);
            }
            if(element.selected.text2){
              element.selected.text2 = new Date(element.selected.text2);
            }
          } else{
          let operator = this.currentSegments.jsonData.paramList.find(x=>x.id == element.id)                      
            element.selected.value = operator.paramOprator;
            element.selected.text1 = operator.paramValue;
          }
            element.valueInput$ = '';
            element.values = [];
            element.values$ = '';    
      }  
        });
        this.currentSegments.jsonData.paramList = paramList;         
        this.toastr.error("Please fill valid input in all mandatory fields");
        this.spinner.hide();
        return;
    }

    let events;
    events = this.events;
    if(events.length > 0)
    {
      this.events.forEach(elems=>{
        this.currentSegments.jsonData.paramList.push({'id':elems.id,'paramOprator':"=",'paramValue':"'true'",'paramKey':elems.paramKey})
      })
    }  
    
    for(let i=0; i<this.currentSegments.jsonData.paramList.length; i++){
      if(this.currentSegments.jsonData.paramList[i].id == 0 && this.currentSegments.jsonData.paramList[i].paramValue == ""){
        delete this.currentSegments.jsonData.paramList[i];
      }
    }

    this.currentSegments.jsonData.paramList = this.currentSegments.jsonData.paramList.filter((p)=>{
      return p != null;
    })
    
    this.currentSegments.jsonData.source = source.join(',');    
    var cs = JSON.parse(JSON.stringify(this.currentSegments));
    cs.jsonData = JSON.stringify(cs.jsonData); 
    //@sunil
    this.segmentService.addSegment(cs).subscribe(
      data => {
        this.spinner.hide();
        if(this.selectedCustomAudience != "new" && this.selectedCustomAudience != "Select Custom Audience"){

        }
        if (data.status) {
          localStorage.removeItem('paramList');
          this.toastr.success('Segment created successfully')
          this.router.navigate(["admin/segments"]);
        }
        else {          
          if(events.length > 0)
          {
            this.currentSegments.jsonData.paramList.forEach((elems,i,object)=>{
              if(events.find(x=>x.id == elems.id) != undefined)
              {
                object.splice(i,1);
              }
            })
          }
          this.currentSegments.jsonData.source = source;  
          let paramList = JSON.parse(localStorage.getItem('paramList'));
          paramList.forEach(element=>{            
            if(element.projectInputParamType == 'Date'){
              if(element.selected.text1){
                element.selected.text1 = new Date(element.selected.text1);
              }
              if(element.selected.text2){
                element.selected.text2 = new Date(element.selected.text2);
              }
            } else{
            let operator = this.currentSegments.jsonData.paramList.find(x=>x.id == element.id);
            if(operator){
             // element.selected = []
              element.selected.value = operator.paramOprator;
              element.selected.text1 = operator.paramValue
            }
          }
              element.valueInput$ = '';
              element.values = [];
              element.values$ = '';         
          });
          this.currentSegments.jsonData.paramList = paramList;                    
          this.toastr.error(data.error_msgs, "Error");
        }
      });
  }

  private getEventName(eventId: any) {
    var eventName = "All Event";
    var filter = this.filters.find(x => x.id == eventId);
    if (filter != null) {
      eventName = filter.paramName;
    }
    return "'" + eventName + "'";
  }

  private invalidateRequest() {
    this.validFilters = false;
    this.invalidFilterDropdown = true
  }

  updateSource(type) { 
    if(type.length == 0)
    {
      this.pluginForm =[];
      this.pluginFormsList = []
      return
    }
    
    let myFilter = []
    this.allFilters.forEach(element => {
      let filter_data = type.find(x=>x.pluginName == element.pluginName) != undefined ? element : undefined;
      if(filter_data != undefined)
      {
        myFilter.push(filter_data)
      }
      
    });

    this.allFilters.forEach(element => {
      if(element.pluginName == "dynamic" || element.pluginName == "static")
      {
        myFilter.push(element)
      }
    });
    

    this.filters = myFilter.sort((a,b)=>{ 
      if(a.paramName < b.paramName){ return -1 }
      if(a.paramName > b.paramName){ return 1 } });

    this.currentSegments.jsonData.paramList.forEach((element,i,object) => {
      
      let findFilter = this.filters.find(x=>x.id == element.id);
      
      if(findFilter == undefined)
      {
        object.splice(i,1);
      }
      if(object.length == 0)
      {
        this.addItem(this.currentSegments.jsonData.paramList)
      }
    });

    
    let filterobject = []
    this.events.forEach((element,i,object) => {
      
      let findFilter = this.filters.find(x=>x.id == element.id);
      
      if(findFilter == undefined)
      {
        object.splice(i,1);
      }
      else
      {
        filterobject.push(element)
      }      
      
    });
    this.events = filterobject;    
    this.pluginFormsList = []
    let pluginNameStore = []
    let pluginNameExist = [];
    
    type.forEach((item, index, object) => {
      if(item.assignMappingDTOs)
      {
        item.assignMappingDTOs.forEach(element => {
          if(element.status == 'ACTIVE'){
          pluginNameStore.push(element);   
          }       
        });
      }
      else
      {
        pluginNameExist.push(item)
      }
    });  

    if(pluginNameExist.length > 0)
    {
      this.fetchUser.forEach(element => {
        if(pluginNameExist.find(x=>x === element.pluginName))
        {
          element.assignMappingDTOs.forEach(elements => {            
            if(elements.status == 'ACTIVE'){
            this.pluginFormsList.push(elements);
            }
          });
        }
        
      });
    }

    if(pluginNameStore.length > 0)
    {
      pluginNameStore.forEach(elems=>{
        this.pluginFormsList.push(elems);
      })
    }
    let pluginForm = []
    this.pluginFormsList.forEach(element => {
      if(this.pluginForm.find(x=>x == element))
      {
        pluginForm.push(element);
      }
    });

    this.pluginForm = pluginForm;    
  }

}
