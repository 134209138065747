      <div class="sign-right-form">
        <tabset #siteTabs>
          <tab heading="Sign in">
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" [hidden]="isChallengeRequired">
              <div class="signin-tab tab-pane fade in active show" id="signin">
                <p>Sign into your account with registered email and password.</p>
                <div class="sign-input">
                  <input trim="blur" class="sign-input-type" type="email" formControlName="username" placeholder="Email id">
                </div>
                <div class="sign-input">
                  <input trim="blur" class="sign-input-type" type="password" formControlName="password" placeholder="Password" maxlength="32">
                </div>                
                <div class="d-flex j-space-between sign_in_action">
                <div class="signin-btn">
                  <button type="submit" class="btn btn-dark">Sign in</button>
                </div>   
                <div class="forgot-password text-right w-full">
                  <a (click)="openModal(template)">Forgot password ?</a>
                </div>
                </div>            
              </div>
            </form>
            
            <form [formGroup]="challengeForm" (ngSubmit)="onSubmitChallenge()" [hidden]="!isChallengeRequired">
              <div class="signin-tab tab-pane fade in active show" id="signin">
                <p>{{challengeLabel}}</p>
                <div class="sign-input">
                  <input trim="blur" class="sign-input-type" type="number" (input)="onInputChange($event)" formControlName="challengeCode" placeholder="Enter Code">
                </div>                                                
                <div class="signin-btn">
                  <button type="submit" class="btn btn-dark">Submit</button>
                </div>               
              </div>
            </form>
        </tab>

        <tab heading="Sign up">
            <form [formGroup]="signupForm" (ngSubmit)="onSignUp()">
              <div class="tab-pane" id="signup">
                <p>Create new account with email address and secure password. These details will be required to access
                  your account.</p>
                <div class="sign-input">
                  <input trim="blur" class="sign-input-type" type="text" formControlName="email"
                    placeholder="Email id">
                </div>
                <div class="sign-input">
                  <input trim="blur" class="sign-input-type" (keypress)="checkPassLen($event)" type="password" formControlName="password"
                    placeholder="Password" maxlength="20">
                </div>
                <div class="sign-input">
                  <input trim="blur" class="sign-input-type" (keypress)="checkPassLen($event)" type="password" formControlName="confirmPassword"
                    placeholder="Confirm Password" maxlength="20">
                </div>
                <div *ngIf="environmentName == 'prod'" class="g-recaptcha" data-sitekey="6LfdkuwpAAAAAM74S5qYUADfwOKbl1UPwN-_HbZy" data-callback="onRecaptchaSuccess" data-expired-callback="onRecaptchaExpired"></div>
                <div *ngIf="environmentName != 'prod'" class="g-recaptcha" data-sitekey="6LdpolQpAAAAAJF-W_ln_hcjkXBTWuwkIpUxrt2Y" data-callback="onRecaptchaSuccess" data-expired-callback="onRecaptchaExpired"></div>
                
                <div class="signin-btn sign_in_action">
                  <button type="submit" class="btn btn-dark">Sign up</button>
                </div>
              </div>
            </form>
          </tab>
          

          
        </tabset>
      </div>

      <ng-template #template>
        <form [formGroup]="forgotPwdForm" (ngSubmit)="onForgotPwd()">
          <div class="forgot-password-modal">
            <div class="modal-header">
              <h4 class="modal-title pull-left">Forgot Password</h4>
              <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p>Please enter your registered email address</p>
              <div class="forgot-input">
                <input trim="blur" class="forgot-input-type" type="email" formControlName="email" placeholder="Email id">
              </div>
              <div class="forgot-btn text-right">
                <button type="submit" class="btn btn-dark">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </form>
      </ng-template>


      <ng-template #sessionList>
          <div class="forgot-password-modal">
            <div class="modal-header">
              <h4 class="modal-title pull-left">Session List
                <div class="session_title"><img src="assets/images/warning.png"> {{sessionTitle}}</div>
              </h4>
              <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="table-responsive table-height-scroll table-reports">
                <table class="table callAssistDashboardTable th_font_14">
                    <thead class="sticky-header" data-sticky-header="true">
                        <tr class="callAssistDbRowTop">
                            <th width="20" class="borderTh text-center">S.No.</th>
                            <th class="borderTh text-center">IP Address</th>
                            <th class="text-center borderTh">Action</th>
                        </tr>
                    </thead>
                    <tbody class="norecord-handling">
                        <tr *ngFor="let sessionListData of sessionListView; let i=index">
                          <td class="text-center">{{i+1}}</td>
                          <td class="text-center">{{sessionListData.ipAddress}}</td>
                          <td class="text-center">
                            <button class="btn btn-dark sessionLogoutBtn" (click)="logoutSession(sessionListData.sessionId,'single')">Logout</button>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="3" class="text-center">
                            <button class="btn btn-dark sessionLogoutBtn" (click)="logoutSession()">Logout All</button>
                          </td>
                        </tr>
                    </tbody>
                  </table>
                </div>
            </div>
          </div>
      </ng-template>
