import { DatePipe, Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DatePickerObj } from './../../../core/modal/datePicker';
import { SubUserObj } from './../../../core/modal/user';
import { CallassistService } from '../callassist.service';
import { Global } from  './../../../shared/models/global.model';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-callassist-miss-recording-details',
  templateUrl: './callassist-miss-recording-details.component.html',
  styleUrls: ['./callassist-miss-recording-details.component.scss']
})
export class CallassistMissRecordingDetailsComponent implements OnInit {
  callAssistLocationRange: DatePickerObj;
  startDate:any=new Date();
  maxDate:any;
  ranges:any;
  page: number = 1;
  limit: number = 20;
  totalRecords: number = 0;
  queryparamsid:any;
  missingCallsLogs;
  public selectedUserobject:SubUserObj=undefined;

  constructor(
    private location: Location,
    private callassistService:CallassistService,
    private datePipe:DatePipe,
    private global:Global,
    private activatedRoute:ActivatedRoute
  ) {}

  ngOnInit() {
    this.activatedRoute.queryParamMap.subscribe((params)=>{
      this.queryparamsid = params;
    });
    let userObject = this.callassistService.getUserObject();
    if(userObject != undefined)
    {
      this.selectedUserobject = userObject
    }
    this.ranges = this.global.customDateRange();
    this.maxDate = moment();
    this.callAssistLocationRange = {
      startDate: moment().subtract(29, 'days'),
      endDate: moment(),
  };
  
  }

  public dataForPaging(event) {
  this.page = event;
  this.missingCallList();
}

  back()
  {
    this.location.back();
  }

  dataSizeChange()
     {
         this.page = 1;
         this.missingCallList();
     }

  missingCallList(){
    let toDate = this.datePipe.transform(this.callAssistLocationRange.endDate,'yyyy-MM-dd 23:59:59');
    if(this.datePipe.transform(this.callAssistLocationRange.startDate,'yyyy-MM-dd ') == this.datePipe.transform(this.callAssistLocationRange.endDate,'yyyy-MM-dd')){
      toDate = this.datePipe.transform(this.callAssistLocationRange.endDate,'yyyy-MM-dd 23:59:59')
    }
    let userObject = this.callassistService.getUserObject();
    let dateObj = {'fromDate':this.datePipe.transform(this.callAssistLocationRange.startDate,'yyyy-MM-dd 00:00:00'),'toDate':toDate,'idUser':this.queryparamsid.params.id,'telecallerId':userObject.id};
    this.callassistService.missingRecording(dateObj,this.limit, this.page).subscribe(data=>{
      if(data.status)
      {
          this.totalRecords = data.data.size;
          this.missingCallsLogs = data.data.missingCallsLogs;
      }
    },(error)=>{
      console.log(error);
    });
  }

}


