<main>
  <section class="inner-page invoiceProdPage">
      <div class="inner-page-header">
          <div class="container-fluid">
              <div class="form-row d-flex j-end">
                  <div class="headHalfBox">
                      <h4>
                          <a class="back-link" (click)="back()"><img src="assets/images/angle-left.svg"></a>
                         Product List
                      </h4>
                  </div>
                  <div class="d-flex">

                    <div class="createEstimatBtn m-r-10">
                      <div class="dropdown addDropdown">
                        <button class="btn" type="button" (click)="openModel(uploadexcel);">
                          <img src="assets/images/upload-audience.svg" class="max_width_20"> Import
                        </button>
                          <!-- <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <img src="assets/images/plus-icon.png"> Add
                          </button>
                          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item" href="javascript:void(0);" (click)="openModel(addItemsData);">Add Single Product</a>
                            <a class="dropdown-item" href="javascript:void(0);" (click)="openModel(uploadexcel);">Upload Xls File</a>
                          </div> -->
                      </div>
                    </div>

                    <div class="searchInputBox m-r-10">
                      <div class="filter-input-space search-box">
                        <input autocomplete="off" class="form-control" name="search" placeholder="Search" trim="blur" type="text" [(ngModel)]="prodSearch" (keyup.enter)="filterByStr()">
                        <button class="btn-search" tabindex="3" type="submit" (click)="filterByStr()">
                          <div class="sr-only">Search</div>
                        </button>
                      </div>
                    </div>

                    <div class="createEstimatBtn">
                      <div class="dropdown addDropdown">
                        <button class="btn" type="button" (click)="openModel(addItemsData);actionTitle='Add Item'">
                          <img src="assets/images/plus-icon.png"> Add
                        </button>
                      </div>
                    </div>


                  </div>
              </div>
          </div>
      </div>
      <div class="inner-page-body">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="table-reports">
                  <div class="table-body">
                    <div class="table-responsive">
                      <table class="table">
                        <tr>
                          <th>Sr. No.</th>
                          <th>Name</th>
                          <th>Description</th>
                          <th>Image</th>
                          <th>Rate</th>
                          <th>HSN</th>
                          <th>TAX</th>
                          <th class="text-center">Action</th>
                        </tr>
                        <tr *ngFor="let prods of invoiceProdList | paginate: { itemsPerPage:dataSize, currentPage:page,totalItems:totalDataSize}; let i = index;">
                          <td>{{((page - 1)*dataSize)+i+1}}</td>
                          <td>{{prods.name}}</td>
                          <td>{{prods.description}}</td>
                          <td><img *ngIf="prods.logo" [src]="prods.logo" class="product_image_"></td>
                          <td>{{prods.rate}}</td>
                          <td>{{prods.hsn}}</td>
                          <td>{{prods.taxMaster.value}}% {{prods.taxMaster.name}}</td>
                          <td class="text-center iconTds">
                            <a href="javascript:void(0);" class="actionBtn" (click)="openModel(addItemsData,'editProd',prods); actionTitle = 'Edit Item'">
                              <img src="assets/images/edit-light.svg" title="Edit product">
                            </a>
                            <a class="actionBtn" (click)="deleteProdCnfrm(prods.id)" title="Delete Record">
                              <img src="assets/images/form-trash-alt.svg">
                            </a>
                          </td>
                        </tr>
                      </table>
                    </div>
                    <ng-container *ngIf="invoiceProdList">
                    <div *ngIf="invoiceProdList.length > 0" class="table-footer footer-gray">
                      <pagination-controls (pageChange)="dataForPaging($event)" maxSize="9" directionLinks="true" autoHide="true" class="pull-right" responsive="true" previousLabel="Previous" nextLabel="Next" screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
                          screenReaderCurrentLabel="You're on page">
                      </pagination-controls>
                      <div class="textblue text-left">
                          <div class="data-length"> Show
                              <select [(ngModel)]="dataSize" (change)="ProdListSize($event)">
                                  <option value="20">20</option>
                                  <option value="50">50</option>
                                  <option value="100">100</option>
                              </select>
                          </div>
                          <span>Total Count: {{totalDataSize}}</span>
                      </div>
                  </div>
                  <div *ngIf="invoiceProdList.length == 0" class="no-records text-center">
                    No Record Found
                  </div>
                </ng-container>
                  </div>
                </div>
              </div>
            </div>

            <ng-template #deleteCnfModal>
              <div class="forgot-password-modal">
                <div class="modal-header j-center">
                  <h4 class="modal-title">Are you sure want to remove this product?</h4>
                  <button aria-label="Close" class="close pull-right" type="button" (click)="modalRef.hide();">
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="innerConfrm">
                    <button class="btn btn-dark" (click)="confirmDelete()">Yes</button>
                    <button class="btn btn-dark red" (click)="cancelDetete()">No</button>
                  </div>
                </div>
              </div>
            </ng-template>


            <ng-template #addItemsData>
              <div class="forgot-password-modal">
                <div class="modal-header">
                  <h4 class="modal-title pull-left">{{actionTitle}}</h4>
                  <button aria-label="Close" class="close pull-right" type="button" (click)="modalRef.hide();">
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div class="modal-body">
                  <form [formGroup]="addProdForm">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                      <label>Product Name</label>
                      <input type="text" class="form-control" maxlength="100" [(ngModel)]="addProdField.name" formControlName="name">
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                      <label>Product Description</label>
                      <input type="text" class="form-control" [(ngModel)]="addProdField.description" formControlName="description">
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    
                    <div class="col-md-6">
                      <div class="form-group">
                      <label>Rate</label>
                      <input type="text" class="form-control" onpaste="return false" [(ngModel)]="addProdField.rate" formControlName="rate" (keypress)="numberOnly($event)">
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                      <label class="width_100">HSN</label>
                      <input type="text" class="form-control" onpaste="return false" [(ngModel)]="addProdField.hsn" formControlName="hsn" onkeypress='return ( event.charCode >= 48 && event.charCode <= 57 )' maxlength="8">
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                      <label>Tax</label>
                      <select class="form-control" *ngIf="taxData.length > 0" [(ngModel)]="addProdField.taxSelector" formControlName="taxSelector">
                        <option value="Select Tax (If any)">Select Tax (If any)</option>
                        <option *ngFor="let list of taxData" value="{{list.id}}">
                          {{list.value}} % {{list.name}}
                        </option>
                      </select>
                      </div>
                    </div>

                    <div class="col-md-6 text-right">
                      <div class="form-group">
                        <label>&nbsp;</label>
                        <div class="upload-button">
                            <button (click)="openGalleryModal()" type="button" class="btn prdct_img_upld_btn">
                                <span
                                    *ngIf="addProdField.logo; else elsebgImage">
                                    <img [src]="addProdField.logo" class="prodct_image_logo"> Change Image</span>
                                <ng-template #elsebgImage>Select Image</ng-template>
                            </button>
                            <a href="javascript:void(0)" *ngIf="addProdField.logo && addProdField.logo != ''" (click)="addProdField.logo = '' "><img src="assets/images/form-trash-alt.svg" class="width_12" alt=""></a>
                        </div>
                    </div>
                    </div>

                  </div>


                  <div class="row">
                    <div class="col-md-12 text-center">
                      <div class="form-group">
                        <label class="width_100">&nbsp;</label>
                        <button class="btn btn-dark" (click)="addprod()">Submit</button>
                      </div>
                    </div>
                  </div>

                </form>
                 
                </div>
              </div>
              </ng-template>

              <ng-template #confirmUploadXl>
                <div class="forgot-password-modal">
                  <div class="modal-header">
                    <h4 class="modal-title pull-left">Import Excel</h4>
                  </div>
                  <div class="modal-body">
                    <div class="">Success Records : {{uploadXlsData.successRecords}}</div>
                    <div class="redTxt">Failed Records : {{uploadXlsData.failedRecords}}</div>

                    <div class="okClose text-center">
                      <button class="btn" (click)="modalRef.hide();">OK</button>
                    </div>
                  </div>
                </div>
                </ng-template>

              <ng-template #uploadexcel>
                <div class="forgot-password-modal">
                  <div class="modal-header">
                    <h4 class="modal-title pull-left">Upload Product Excel</h4>
                    <button aria-label="Close" class="close pull-right" type="button" (click)="modalRef.hide();">
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <form [formGroup]="xlsUploaderForm">
                    
                        <div class="form-group">
                        <div class="text-center">
                          <button class="btn btn-dark" (click)="downlodSampleXl()"> Download Sample Excel</button>
                        </div>
                        </div>

                        <div class="orTxt"><span class="centerRoundBg">OR</span></div>

                        <div class="form-group">
                        <label>Select Excel File</label>
                        <input type="file" (change)="xlsSelected($event)" #fileInput class="form-control" placeholder="Upload file" [(ngModel)]="xlsSelector" formControlName="xlsSelector" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                        <input type="hidden" name="file" formControlName="file" /> 
                        </div>
                        
                        <div class="form-group text-right">
                          <label class="width_100">&nbsp;</label>
                          <button class="btn btn-dark" (click)="submitProdXls()">Import</button>
                        </div>
                       
                  </form>
                   
                  </div>
                </div>
                </ng-template>


          </div>
      </div>
    </section>
  </main>

  <table id="xlsTableProd" #xlsTable class="d-none">
    <tr>
      <th>Name</th>
      <th>Rate</th>
      <th>Tax</th>
      <th>HSN</th>
      <th>Description</th>
      <th>Image</th>
    </tr>
  </table>

