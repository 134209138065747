import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { Location } from '@angular/common';
import { Global } from '../../../../shared/models/global.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

interface TwoFactorAuthModel {
  name: string;
  symbol: string;
  status: number;
}

interface UserSessionModel {
  ipAddress: string;
  userAgent: string;
  sessionId: string;
  updated: string;
}
@Component({
  selector: 'app-authentication-methods',
  templateUrl: './authentication-methods.component.html',
  styleUrls: ['./authentication-methods.component.scss']
})
export class AuthenticationMethodsComponent implements OnInit {


  userChallenge: string | undefined;
  allChallenges: TwoFactorAuthModel[] = [];
  userSelectChallenge: string;

  userSessionList: UserSessionModel[] = [];


  //google auth variable
  gAuthQRCode: string;
  secretKey: string;
  gAuthFirstInput: string = '';
  gAuthSecondInput: string = '';

  //otp variable
  otpPhoneNumber: string = '';
  otpRequest: boolean = true;
  otpCodeInput: string = '';
  otpRequestId: string = '';
  actionProperty:any;
  setupChallenge: boolean = false;

  setupGAuth: boolean = false;
  setupOTP: boolean = false;
  @ViewChild('disableConfirmWindow') disableConfirmWindow:ElementRef;
  @ViewChild('disableAuthForm') disableAuthForm:ElementRef;
  
  modalRef: BsModalRef
  constructor(
    private _authService: AuthenticationService,
    private toastr: ToastrService,
    private location:Location,
    private global:Global,
    private modalService:BsModalService
  ) {

  }

  ngOnInit() {
    this.loadUserAuthentication()
    // this.loadAllSessions()
  }

  goToBack(){
    this.location.back()
  }


  disableConfirm(template){
    console.log('disable confirm called');
    this.openModal(template)
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { backdrop: 'static' });
  }

  private loadUserAuthentication() {
    this._authService.getUserTwoFactorDetails({}).subscribe(data => {
      if (data.status) {
        const responseData = data.data;
        if (null != responseData.userChallenge) {
          this.setupChallenge = false;
          this.userChallenge = responseData.userChallenge;
          console.log(this.userChallenge);
        }else{
          this.userChallenge = undefined;
        }
        this.allChallenges = responseData.allChallenges;
        console.log(this.allChallenges);
        
      } else {
        this.toastr.error(data.error_msgs, 'Error');
      }
    })
  }


  copyText(val: string){
    this.global.copyText(val);
    this.toastr.success('Copied to clipboard');
  }

  private loadAllSessions() {

    this._authService.getAllSessionList({}).subscribe(data => {

      if (data.status) {
        const responseData = data.data;
        this.userSessionList = responseData;
        console.log("responseData ", responseData);
      } else {
        this.toastr.error(data.error_msgs, 'Error');
      }
    })
  }

  logoutSession(sessionId: string): void {
    this._authService.logoutSessionRequest({ loginAttemptId: sessionId }).subscribe(data => {

      if (data.status) {
        this.toastr.success("User Session Logout Successfully");
        this.loadAllSessions();
      } else {
        this.toastr.error(data.error_msgs, 'Error');
      }
    })
  }

  logoutAllSession(): void {
    this._authService.logoutSessionRequest({}).subscribe(data => {

      if (data.status) {
        this.toastr.success("All Session Logout Successfully");
        window.open("/", "_self");
      } else {
        this.toastr.error(data.error_msgs, 'Error');
      }
    })
  }

  getOTP(): void {
    if (this.otpPhoneNumber.toString().length != 10) {
      this.toastr.error("Phone number should be 10 digit.");
      return
    }


    this._authService.setupOTPChallenge({ phoneNumber: this.otpPhoneNumber }).subscribe(data => {
      if (data.status) {
        var responseData = data.data;
        this.otpRequestId = responseData.otpRequestId;
        this.otpRequest = false;
      } else {
        this.toastr.error(data.error_msgs, 'Error');
      }
    })
  }

  validateOTP(): void {
    if (this.otpCodeInput.toString().length != 5) {
      this.toastr.error("OTP Code should be 5 digit")
      return;
    }

    this._authService.validateOTPChallenge({ phoneNumber: this.otpPhoneNumber, otpCode: this.otpCodeInput, otpRequestId: this.otpRequestId }).subscribe(data => {
      if (data.status) {
        this.toastr.success("Successfully Setup");
        this.loadUserAuthentication();
      } else {
        this.toastr.error(data.error_msgs, 'Error');
      }
    })
  }

  onChallengeSelect(event): void {
    console.log(this.userSelectChallenge);
    
    if (event == "GOOGLE_AUTH") {
      this._authService.setupGoogleAuth({}).subscribe(data => {
        if (data.status) {
          const responseData = data.data;
          this.gAuthQRCode = responseData.qrCodeURL;
          this.secretKey = responseData.secretKey;
          this.setupChallenge = true;
          this.setupGAuth = true;
        } else {
          this.toastr.error(data.error_msgs, 'Error');
        }
      })
    } else if (event == "OTP") {
      this.setupChallenge = true;
      this.setupOTP = true;
    } else {
      this.toastr.error("Something Went Wrong")
    }
  }

  onInputChange(event: any): void {    
    let inputValue: string = event.target.value;   
    inputValue = inputValue.replace(/\D/g, '');    
    event.target.value = inputValue;
  }

  disableConfirmyes(template)
  {
    this.modalRef.hide();
    console.log(this.actionProperty);
    this.openModal(template)
  }

  disableSubmit(event){
    console.log('sunil', event);
    this._authService.disableGoogleAuth({challengeValue:event}).subscribe(data=>{
      if(data.status){
        this.toastr.success(data.data);
        this.gAuthFirstInput="";
        this.gAuthSecondInput="";
        this.modalRef.hide();
        this.loadUserAuthentication();
      } else{
        this.toastr.error(data.error_msgs)
      }
    })
  }

  validateGAuth(): void {    
    if (this.gAuthFirstInput.toString().length != 6) {
      this.toastr.error('Enter 6 digit first input')
    } else if (this.gAuthSecondInput.toString().length != 6) {
      this.toastr.error('Enter 6 digit second input')
    } else {
      this._authService.validateGoogleAuth({ validateCodeOne: this.gAuthFirstInput, validateCodeTwo: this.gAuthSecondInput }).subscribe(data => {
        if (data.status) {
          this.toastr.success("Successfully Setup");
          this.loadUserAuthentication();
        } else {
          this.toastr.error(data.error_msgs, 'Error');
        }
      })
    }
  }

}
