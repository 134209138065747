import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'permission-item',
    template: `
    <li *ngFor="let item of menuList" class="menu-item item" [class.space-remove]="item.hideElem">
        <ng-container *ngIf="item.menuList != undefined else menuItem">
            <div class="item">
                <label><input class="checkbox" type="checkbox" name="page" [(ngModel)]="item.isSubMenuAdded" (change)="updateMenu($event.target.checked,item,menuList)"> {{item.menuName}}</label>
            </div>
            <ul [isAnimated]="false" [collapse]="!item.isSubMenuAdded" class="sub-menu">
                <permission-item (selectLandingPage)="updateLandingPageEvent($event)" (selectedActionsList)="updateActionEvent($event)" [menuList]="item.menuList"></permission-item>
            </ul>
        </ng-container>
        <ng-template #menuItem>
            <div class="item" *ngIf="item.hideElem == false">
                <label><input class="checkbox" type="checkbox" name="page" [(ngModel)]="item.isSubMenuAdded" (change)="updateMenu($event.target.checked,item,menuList)"> {{item.menuName}}</label>
                <label class="islanding" [ngClass]="{disable : !item.isSubMenuAdded}">Landing page <input type="checkbox" name="landingPage" [(ngModel)]="item.isLandingPage" [checked]="item.isLandingPage" (change)="updateLandingPage($event.target.checked,item.id);" [disabled]="!item.isSubMenuAdded"></label>
            </div>
            <div [isAnimated]="true" [collapse]="!item.isSubMenuAdded" [class.action-list]="item.hideElem == false">
                <ng-container *ngIf="item.menuActionList != undefined">
                    <h3>Actions</h3>
                    <ul>
                        <li class="item" *ngFor="let action of item.menuActionList"><label><input type="checkbox" name="page" [(ngModel)]="action.isSelecte" [checked]="action.isSelecte" (change)="updateAction($event.target.checked,action.id,item.menuActionList);"> {{action.actionName}}</label></li>
                    </ul>
                </ng-container>
            </div>
        </ng-template>
    </li>`,
    styleUrls: ['./user-management.component.scss']
})

export class PermissionItemComponent {
    @Input() menuList;
    @Output() selectLandingPage  = new EventEmitter();
    @Output() selectedActionsList  = new EventEmitter ();

    updateLandingPage(e,id){
        this.selectLandingPage.emit({value:e,id:id})
    }
    updateLandingPageEvent(data){
        this.selectLandingPage.emit(data);
    }

    updateAction(event,id,list){
        this.selectedActionsList.emit({value:event,id:id,list:list});
        // element.menuActionList.map(x => {
        //     x.isSelecte = event
        //     this.selectedActionsList.emit({value:event,id:x.id,list:element.menuActionList});
        // });
        
    }
    updateActionEvent(data){
        this.selectedActionsList.emit(data);
    }

    updateMenu(event,item,menuList){
        menuList.forEach(element => {
            if(element.id == item.id){
                if(item.menuList != undefined){
                    item.menuList.forEach(menu => {
                        menu.isSubMenuAdded = event;
                        this.updateMenu(event,menu,item.menuList)
                    }); 
                } else {
                    element.isLandingPage = !event ? false : element.isLandingPage;
                    this.updateLandingPage(element.isLandingPage,element.id);
                    if(element.menuActionList && element.menuActionList.length > 0){
                    element.menuActionList.map(x => {
                        x.isSelecte = event
                        this.updateAction(event,x.id,element.menuActionList);
                    });
                    element.hideElem = false
                    } else{
                        element.hideElem = true;
                    }
                }
            }
        });
    }
    
}