<main *ngIf="!showAlertMessage && !isIndividualCampaign">
    <section class="inner-page">
        <div class="container-fluid card p-0">
          <div class="close-box"><img (click)="removeLocalStorage();this.global.ClearChannel(); this.AnyChannelSelected(); this.calculateAudience();" src="assets/images/cross.png" /></div>
            <div *ngIf="this.global.Channel.AllAudience" class="channel_source">
                <div class="row pt-3">
                    <div class="col-7 channel-type">
                        <h4 class="ml-4">Audience</h4>
                    </div>
                </div>
                <div class="ml-4">
                  <div class="form-group row">
                      <div class="col-md-2 col-12 col-sm-12"><div class="channel-detail">
                        <img (click)="this.global.ClearSpecificChannelId('AllAudience'); this.AnyChannelSelected(); this.calculateAudience();" src="assets/images/times-circle2.svg" />
                        <label>All Audience</label>
                      </div></div>
                  </div>
                </div>
            </div>

            <div *ngIf="this.global.Channel.Segments.length>0" class="channel_source">
                <div class="row pt-3">
                    <div class="col-md-9 col-12 col-sm-12 channel-type">
                        <h4 class="ml-4">Segment Names</h4>
                    </div>
                </div>
                <div *ngIf="this.global.Channel.Segments.length>0" class="ml-4">
                    <div class="form-group row">
                        <div *ngFor="let item of this.global.Channel.Segments" class="col-md-2 col-12 col-sm-12"><div class="channel-detail">
                          <img (click)="this.global.ClearSpecificChannelId('Segments', item.id); this.AnyChannelSelected(); this.calculateAudience();" src="assets/images/times-circle2.svg" />
                          <label>{{item.segmentName}}</label>
                        </div></div>
                    </div>
                </div>
            </div>
        
        <div *ngIf="this.global.Channel.Source.length>0" class="channel_source">

        <div class="row pt-3">
          <div class="col-md-9 col-12 col-sm-12 channel-type">
            <h4 class="ml-4">Source</h4>
          </div>
        </div>

        <div *ngIf="this.global.Channel.Source.length>0" class="ml-4">
          <div class="form-group row">
            <div *ngFor="let item of this.global.Channel.Source" class="col-md-2 col-12 col-sm-12"><div class="channel-detail">
              <img (click)="this.global.ClearSpecificChannelId('Source', item.pluginId); this.AnyChannelSelected(); this.calculateAudience();"  src="assets/images/times-circle2.svg" />
              <label>{{item.pluginName}}</label>
            </div></div>
          </div>
        </div>

      </div>
      
      <div *ngIf="this.global.Channel.dateMsg != ''" class="channel_source">

        <div class="row pt-3">
          <div class="col-md-9 col-12 col-sm-12 channel-type">
            <h4 class="ml-4">Date Range Filter</h4>
          </div>
        </div>

        <div class="ml-4">
          <div class="form-group row">
            <div class="col-md-6 col-12 col-sm-12"><div class="channel-detail">
              <img (click)="this.global.ClearSpecificChannelId('date', ''); this.AnyChannelSelected(); this.calculateAudience();"  src="assets/images/times-circle2.svg" />
              <label>{{global.Channel.dateMsg}}</label>
            </div></div>
          </div>
        </div>

      </div>

      <div *ngIf="this.global.Channel.Events.length>0" class="channel_source">

        <div class="row pt-3">
          <div class="col-md-9 col-12 col-sm-12 channel-type">
            <h4 class="ml-4">Events</h4>
          </div>
        </div>

        <div *ngIf="this.global.Channel.Events.length>0" class="ml-4 ">
          <div class="form-group row">
            <div *ngFor="let item of this.global.Channel.Events" class="col-md-2 col-12 col-sm-12"><div class=" channel-detail">
              <img (click)="this.global.ClearSpecificChannelId('Events', item.id); this.AnyChannelSelected(); this.calculateAudience();"  src="assets/images/times-circle2.svg" />
              <label>{{item.paramName}}</label>
            </div></div>
          </div>
        </div>

      </div>
      <div *ngIf="this.global.Channel.Attributes.length>0" class="channel_source">
        <div class="row pt-3">
          <div class="col-md-9 col-12 col-sm-12 channel-type ml-4">
            <h4>Attributes</h4>
          </div>
        </div>

        <div *ngIf="this.global.Channel.Attributes.length>0" class="ml-4">
          <div class="form-group row">
            <div *ngFor="let item of this.global.Channel.Attributes" class="col-md-6 col-12 col-sm-12"> <div class="channel-detail">
              <img (click)="this.global.ClearSpecificChannelId('Attributes', item.id); this.AnyChannelSelected(); this.calculateAudience();"  src="assets/images/times-circle2.svg" />
              <label>{{item.paramName}} <span class="theme_txt_color"> {{item.selected.value}} </span>
                <ng-container *ngIf="item.selected.value == 'in' || item.selected.value == 'between'; else elseTemplate">
                  <span *ngIf="item.selected.value == 'between'">
                    <ng-container *ngIf="item.paramDatatype == 'Date'; else elseDatrTemplate1">
                      <span>{{item.selected.text1 | date:'mediumDate'}} and {{item.selected.text2 | date:'mediumDate'}}</span>
                    </ng-container>
                    <ng-template #elseDatrTemplate1>
                      <span>{{item.selected.text1}} and {{item.selected.text2}}</span>
                    </ng-template>
                    
                  </span>
                  <span *ngIf="item.selected.value == 'in'">
                    <span *ngFor="let param of item.selected; let last = last">{{param.paramValue}} <span *ngIf="!last">,</span></span>
                  </span>
                </ng-container>
                <ng-template #elseTemplate>
                    <ng-container *ngIf="item.paramDatatype == 'Date'; else elseDatrTemplate">
                      {{item.selected.text1 | date:'mediumDate'}}  
                    </ng-container>
                    <ng-template #elseDatrTemplate>
                      <span >{{item.selected.text1}}</span>   
                    </ng-template>
                </ng-template>
              </label>
            </div>
          </div></div>
        </div>

      </div>
      </div>
  </section>
</main>

<div *ngIf="showAlertMessage && !isIndividualCampaign" class="info-outer-box">
  <div class="inner-box">
    <div class="bg-color">
      <h6>{{alertTitle}}</h6>
      <p>{{alertMessage}}</p>
      <div class="footer text-center">
        <button *ngIf="ls.getItem('userRoleId') == '2'" (click)="showAlertMessage=false" class="btn btn-dark mr-3">OK</button>
        <a *ngIf="ls.getItem('userRoleId') == '1'" routerLinkActive="active" [routerLink]="['/admin/configuration']" class="btn btn-dark mr-3">OK</a>
        <button routerLinkActive="active" (click)="showAlertMessage=false" class="btn btn-dark">Cancel</button>
      </div>
    </div>
  </div>
</div>

<ng-template #templatePushNotification>
  <form [formGroup]="formPushNotification" (ngSubmit)="savePushNotification()">
    <div class="campaign-modal">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Push Notification</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-8 col-md-6 col-sm-12 col-12">
            <div class="mb-3">
              <div class="form-group row">
                <label class="col-lg-3 col-md-12 control-label required">Title</label>
                <div class="col-lg-9 col-md-12">
                  <input trim="blur" class="form-control" type="text" formControlName="title" maxlength="50">
                </div>
              </div>
            </div>
            <div class="mb-3">
              <div class="form-group row">
                <label class="col-lg-3 col-md-12 control-label">Templates</label>
                <div class="col-lg-9 col-md-12">
                  <select class="form-control" formControlName="template" (change)="getPushTemplateData()">
                    <option value="">Select Template</option>
                    <option *ngFor="let item of pushTemplateList" [value]="item.id">{{item.templateName}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="mb-3">
              <div class="form-group row">
                <label class="col-lg-3 col-md-12 control-label">Dynamic Values</label>
                <div class="col-lg-9 col-md-12">
                  <ng-select [items]="inputParams" formControlName="paramsSelected"class="params"
                  bindLabel="paramName" [multiple]="true" 
                  [(ngModel)]="inputParamsSelected" (add)="getSeletedParem($event, formPushNotification, 'message')" (remove)="removeParem($event, formPushNotification, 'message')" >
                  </ng-select>
                  <span class="icontooltip" tooltip="Create customise message for each user by selecting dynamic fields from here. The selected field in the message gets replaced with individual user values during submission." [tooltipAnimation]="true" tooltipPlacement="right">
                    <img src="assets/images/info-circle.svg " />
                  </span>
                  <!-- addEmoji($event, formPushNotification, 'message' ) -->
                </div>
              </div>
            </div>
            <div class="mb-3">
              <div class="form-group row">
                <label class="col-lg-3 col-md-12 control-label">Upload Image</label>
                <div class="col-lg-9 col-md-12">
                  <button (click)="openGalleryModal('image')" class="btn btn-border" type="button">Select Image</button>
                </div>
              </div>
            </div>
            <div class="mb-3">
              <div class="form-group row">
                <label class="col-lg-3 col-md-12 control-label required">Message</label>
                <div class="col-lg-9 col-md-12" #emojiElement>
                  <textarea class="form-control" rows="9" formControlName="message" maxlength="1024"></textarea>
                  <br/>
                  <div class="emojiIcon" (click)="isShowEmoji = !isShowEmoji"><img src="assets/images/smile.svg" alt=""></div>
                  <emoji-mart  *ngIf="isShowEmoji" title="Pick your emoji…" (emojiClick)="addEmoji($event, formPushNotification, 'message' )"></emoji-mart>
                </div>
              </div>
            </div>

            <div class="mb-3">
              <div class="form-group row">
                <label class="col-lg-3 col-md-12 control-label lable-span">Click Action
                  <span>(Optional)</span>
                </label>
                <div class="col-lg-9 col-md-12">
                  <input trim="blur" class="form-control" type="text" formControlName="deepLink">
                  <span class="icontooltip" tooltip="Write activityname to open android activity or write relative url (like /index.html) for opening web link" [tooltipAnimation]="true" tooltipPlacement="right">
                    <img src="assets/images/info-circle.svg " />
                  </span>
                </div>
              </div>
            </div>
            <div class="text-right mt-1 mb-2">
              <button class="btn btn-dark" type="submit">Send</button>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 col-12">
            <div class="screen-tab configuration pl-12">
              <tabset>

                <tab heading="IOS">
                  <div class="pushimg">
                    <div class="pushimg-detail-ios">
                   
                      <div class="push-width">
                        <div class="push-contant">
                          <div class="push-icon">
                            <span>
                                <img alt="" *ngIf="this.formPushNotification.controls.notificationIcon.value == null || this.formPushNotification.controls.notificationIcon.value == ''" src="{{'/assets/images/push_notification/blue-round.png'}}">
                                <img alt="" *ngIf="this.formPushNotification.controls.notificationIcon.value != null && this.formPushNotification.controls.notificationIcon.value != ''" src="{{this.formPushNotification.controls.notificationIcon.value}}">
                            </span>
                          </div>
                          <div class="push-icon-detail">
                            <h5>{{formPushNotification.controls.title.value||'Title'}}</h5>
                            <div [innerHTML]="formPushNotification.controls.message.value ||'Lorem Ipsum is simply dummy text of the printing
                            and typesetting industry'"></div>
                          </div>
                          <img class="notification-banner-img" alt="" *ngIf="this.formPushNotification.controls.notificationImage.value != null && this.formPushNotification.controls.notificationImage.value != ''" src="{{this.formPushNotification.controls.notificationImage.value}}">
                        </div>
                      </div>

                    </div>
                  </div>
                </tab>

                <tab heading="Android">
                  <div class="pushimg android-push-img">
                    <div class="pushimg-detail-android">
                      <div class="push-width">
                        <div class="push-contant">
                          <div class="push-icon">
                            <span>
                              <img alt="" *ngIf="this.formPushNotification.controls.notificationIcon.value == null || this.formPushNotification.controls.notificationIcon.value == ''" src="{{'/assets/images/push_notification/blue-round.png'}}">
                              <img alt="" *ngIf="this.formPushNotification.controls.notificationIcon.value != null && this.formPushNotification.controls.notificationIcon.value != ''" src="{{this.formPushNotification.controls.notificationIcon.value}}">
                            </span>
                          </div>
                          <div class="push-icon-detail">
                            <h5>{{formPushNotification.controls.title.value||'Title'}}</h5>
                            <div [innerHTML]="formPushNotification.controls.message.value ||'Lorem Ipsum is simply dummy text of the printing
                            and typesetting industry'"></div>
                          </div>
                          <img class="notification-banner-img" alt="" *ngIf="this.formPushNotification.controls.notificationImage.value != null && this.formPushNotification.controls.notificationImage.value != ''" src="{{this.formPushNotification.controls.notificationImage.value}}">
                        </div>
                      </div>
                    </div>
                  </div>
                </tab>

                <tab heading="Web">
                  <div class="pushimg web-push-img">
                    <div class="pushimg-detail-web">

                      <div class="push-width-manage">

                        <div class="push-width">

                          <div class="push-contant">
                            <div class="push-icon">
                              <span>
                                <img alt="" *ngIf="this.formPushNotification.controls.notificationIcon.value == null || this.formPushNotification.controls.notificationIcon.value == ''" src="{{'/assets/images/push_notification/blue-round.png'}}">
                                <img alt="" *ngIf="this.formPushNotification.controls.notificationIcon.value != null && this.formPushNotification.controls.notificationIcon.value != ''" src="{{this.formPushNotification.controls.notificationIcon.value}}">
                              </span>
                            </div>
                            <div class="push-icon-detail">
                              <h5>{{formPushNotification.controls.title.value||'Title'}}</h5>
                              <div [innerHTML]="formPushNotification.controls.message.value ||'Lorem Ipsum is simply dummy text of the printing
                            and typesetting industry'"></div>
                            </div>
                            <img class="notification-banner-img" alt="" *ngIf="this.formPushNotification.controls.notificationImage.value != null && this.formPushNotification.controls.notificationImage.value != ''" src="{{this.formPushNotification.controls.notificationImage.value}}">
                          </div>

                        </div>
                      </div>

                    </div>
                  </div>
                </tab>
              </tabset>

            </div>

          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #templateWhatsappCampaign>
  <form [formGroup]="formWhatsappCampaign" (ngSubmit)="saveWhatsappCampaign()">
    <div class="campaign-modal">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Whatsapp Campaign</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-6 col-md-12 col-sm-12 col-12" [class.wabaFieldsHeight]="wpBDetails && wpBDetails.providerName == 'Cronberry WABA'">
            <div class="mb-3">
              <div class="form-group row">
                <label class="col-lg-4 col-md-12 control-label">Templates</label>
                <div class="col-lg-8 col-md-12">
                  <select class="form-control" formControlName="template" (change)="getWhatsappTemplateData()">
                    <option value="">Select Template</option>
                    <option *ngFor="let item of smsTemplateList" [value]="item.id">{{item.templateName}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="mb-3" [class.wabaFieldsHidden]="wpBDetails && wpBDetails.providerName == 'Cronberry WABA'">
              <div class="form-group row">
                <label class="col-lg-4 col-md-12 control-label">Dynamic Values</label>
                <div class="col-lg-8 col-md-12">
                  <ng-select [items]="inputParams" formControlName="paramsSelected"
                  bindLabel="paramName" [multiple]="true" class="params"
                  [(ngModel)]="inputParamsSelected" (add)="getSeletedParem($event, formWhatsappCampaign, 'smsText')" (remove)="removeParem($event, formSMSCampaign, 'smsText')">
                  </ng-select>
                  <span class="icontooltip" tooltip="Create customise message for each user by selecting dynamic fields from here. The selected field in the message gets replaced with individual user values during submission." [tooltipAnimation]="true" tooltipPlacement="right">
                    <img src="assets/images/info-circle.svg " />
                  </span>
                </div>
              </div>
            </div>
            <div class="mb-3" [class.wabaFieldsHidden]="wpBDetails && wpBDetails.providerName == 'Cronberry WABA'">
              <div class="form-group row">
                <label class="col-lg-4 col-md-12 control-label">Header(Image/PDF)</label>
                <div class="col-lg-8 col-md-12">
                  <!-- <input class="form-control" formControlName="headerUrl"> -->
                  <ng-select [items]="inputParamsFileType" formControlName="headerUrl" bindLabel="paramName" [multiple]="false" class="params" [(ngModel)]="headerUrl" (change)="headerImageUrl($event,'select')">
                  </ng-select>
                  <div class="orIcon">
                    <span class="headerImageOr">OR</span>
                  </div>
                  <input class="form-control" formControlName="headerUrlText" placeholder="Type header image/pdf url" [(ngModel)]="headerUrlText" (input)="headerImageUrl($event,'input')">
                </div>
              </div>
            </div>
            <div class="mb-3" [class.wabaFieldsHidden]="wpBDetails && wpBDetails.providerName == 'Cronberry WABA'">
              <div class="form-group row">
                <label class="col-lg-4 col-md-12 control-label required">Body Text</label>
                <div class="col-lg-8 col-md-12">
                  <textarea class="form-control" rows="5" formControlName="smsText" maxlength="500"></textarea>
                  <span class="character-text">{{500 - smsTextLength}} left</span>
                  <div class="mediaLink float-left" (click)="openGalleryModal('all,form')" tooltipPlacement="top" tooltip="Select Media / Custom Form"><img src="assets/images/link.svg" alt=""></div>
                </div>
              </div>
            </div>

            <div class="mb-3" [class.wabaFieldsHidden]="wpBDetails && wpBDetails.providerName == 'Cronberry WABA'" *ngIf="wpBDetails && wpBDetails.providerName == 'Cronberry WABA'">
              <div class="form-group row">
                <label class="col-lg-4 col-md-12 control-label required">Button Text</label>
                <div class="col-lg-8 col-md-12">
                  <textarea class="form-control" rows="5" formControlName="buttonText" maxlength="500"></textarea>
                </div>
              </div>
            </div>

            <div class="text-right mt-1 mb-2">
              <button class="btn btn-dark" type="submit">Send</button>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 col-12 notification-hidden">

            <div class="pushimg" [class.pt-50]="wpBDetails && wpBDetails.providerName != 'Cronberry WABA'">
           

              <div class="sms-campaign-detail">
                <div class="campaign-content">
                  <p>
                    {{formWhatsappCampaign.controls.smsText.value||'Lorem ipsum dolor sit amet, tetur adipiscing elit, sed do ] labore aipsum dolor
                    sit amet, tetur'}}
                  </p>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>


<ng-template #templateSMSCampaign>
  <form [formGroup]="formSMSCampaign" (ngSubmit)="saveSMSCampaign()">
    <div class="campaign-modal">
      <div class="modal-header">
        <h4 class="modal-title pull-left">SMS Campaign</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="mb-3">
              <div class="form-group row">
                <label class="col-lg-4 col-md-12 control-label">Templates</label>
                <div class="col-lg-8 col-md-12">
                  <select class="form-control" formControlName="template" (change)="getSmsTemplateData()">
                    <option value="">Select Template</option>
                    <option *ngFor="let item of smsTemplateList" [value]="item.id">{{item.templateName}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="mb-3">
              <div class="form-group row">
                <label class="col-lg-4 col-md-12 control-label">Dynamic Values</label>
                <div class="col-lg-8 col-md-12">
                  <ng-select [items]="inputParams" formControlName="paramsSelected"
                  bindLabel="paramName" [multiple]="true" class="params"
                  [(ngModel)]="inputParamsSelected" (add)="getSeletedParem($event, formSMSCampaign, 'smsText')" (remove)="removeParem($event, formSMSCampaign, 'smsText')">
                  </ng-select>
                  <span class="icontooltip" tooltip="Create customise message for each user by selecting dynamic fields from here. The selected field in the message gets replaced with individual user values during submission." [tooltipAnimation]="true" tooltipPlacement="right">
                    <img src="assets/images/info-circle.svg " />
                  </span>
                </div>
              </div>
            </div>
            <div class="mb-3">
              <div class="form-group row">
                <label class="col-lg-4 col-md-12 control-label required">Message</label>
                <div class="col-lg-8 col-md-12">
                  <textarea class="form-control" rows="5" formControlName="smsText" maxlength="700"></textarea>
                  <span class="character-text">{{700 - smsTextLength}} left</span>
                  <div class="mediaLink float-left" (click)="openGalleryModal('all,form')" tooltipPlacement="top" tooltip="Select Media / Custom Form"><img src="assets/images/link.svg" alt=""></div>
                </div>
              </div>
            </div>
            <div class="text-right mt-1 mb-2">
              <button class="btn btn-dark" type="submit">Send</button>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 col-12 notification-hidden">

            <div class="pushimg pt-50">
           

              <div class="sms-campaign-detail">
                <div class="campaign-content">
                  <p>
                    {{formSMSCampaign.controls.smsText.value||'Lorem ipsum dolor sit amet, tetur adipiscing elit, sed do ] labore aipsum dolor
                    sit amet, tetur'}}
                  </p>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #templateEmailCampaign>
  <form [formGroup]="formEmailCampaign" (ngSubmit)="saveEmailCampaign()">
    <div class="campaign-modal">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Email Campaign</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body pb-40 mbp-20">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="mb-3">
              <div class="form-group row">
                <label class="col-lg-2 col-md-12 control-label">Templates</label>
                <div class="col-lg-10 col-md-12">
                  <select class="form-control" formControlName="template" (change)="getEmailTemplateData()">
                    <option value="">Select Template</option>
                    <option *ngFor="let item of emailTemplateList" [value]="item.id">{{item.templateName}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="mb-3">
              <div class="form-group row">
                <label class="col-lg-2 col-md-12 control-label required">Subject</label>
                <div class="col-lg-10 col-md-12">
                  <input trim="blur" type="text" class="form-control" formControlName="emailSubject" maxlength="500">
                </div>
              </div>
            </div>
            <div class="mb-3">
              <div class="form-group row">
                <label class="col-lg-2 col-md-12 control-label">Dynamic Values</label>
                <div class="col-lg-10 col-md-12">
                  <ng-select [items]="inputParams" formControlName="paramsSelected" class="params"
                  bindLabel="paramName" [multiple]="true"
                  [(ngModel)]="inputParamsSelected"  (add)="getSeletedParem($event, formEmailCampaign, 'emailText')"  (remove)="removeParem($event, formEmailCampaign, 'emailText')">
                  </ng-select>
                  <span class="icontooltip" tooltip="Create customise message for each user by selecting dynamic fields from here. The selected field in the message gets replaced with individual user values during submission." [tooltipAnimation]="true" tooltipPlacement="right">
                    <img src="assets/images/info-circle.svg " />
                  </span>
                </div>
              </div>
            </div>           
            <div class="mb-3">
              <div class="form-group row">
              <label class="col-lg-2 col-md-12 control-label required">Email</label>
              <div class="col-lg-10 col-md-12">
              <div class="mb-3"><button (click)="openGalleryModal('all,form')" class="btn btn-dark" type="button">Select Media / Custom Form</button></div>
              <textarea *ngIf="!isEditorView" class="form-control" rows="16" formControlName="emailText"></textarea>
              <app-ckeditor
                  *ngIf="isEditorView"
                  formControlName="emailText"
                  [config]="config"
                  [editorData]="ckeditorData"
              ></app-ckeditor>
              <div class="emojiIcon" (click)="isShowEmoji = !isShowEmoji"><img src="assets/images/smile.svg" alt=""></div>
              <emoji-mart *ngIf="isShowEmoji" title="Pick your emoji…" (emojiClick)="addEmoji($event, formEmailCampaign, 'emailText' )"></emoji-mart>
              <div class="text-right m_t_5 font-12" (click)="isEditorView = !isEditorView">
              <a href="javascript:;" *ngIf="!isEditorView">HTML View</a>
              <a href="javascript:;" *ngIf="isEditorView">Editor View</a>
              </div>
              </div>
              </div>
              </div>
            <div class="text-right mt-1">
              <button class="btn btn-dark" type="submit">Send</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #templateInAppNotification>
  <form [formGroup]="formInAppNotification" (ngSubmit)="saveInAppNotification()">
    <div class="campaign-modal">
      <div class="modal-header">
        <h4 class="modal-title pull-left">In App Notification</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body pb-40">
        <div class="row">
          <div class="col-lg-6 col-md-12 col-sm-12 col-12">

            <div class="mb-3">
              <div class="form-group row">
                <label class="col-lg-3 col-md-12 col-12 col-sm-12 control-label required">Header Image</label>
                <div class="col-lg-9 col-md-12 col-12 col-sm-12">
                  <button (click)="openGalleryModal('image')" class="btn btn-border" type="button">
                      <span *ngIf="formInAppNotification.controls.imagePath.value != null && formInAppNotification.controls.imagePath.value != ''; else elseSelectText">Change Image</span>
                      <ng-template #elseSelectText> Select Image</ng-template>
                  </button>
                </div>
              </div>
            </div>

            <div class="mb-3">
              <div class="form-group row">
                <label class="col-lg-3 col-md-12 col-12 col-sm-12 control-label required">Heading Text</label>
                <div class="col-lg-9 col-md-12 col-12 col-sm-12">
                  <input trim="blur" class="form-control modal-space" type="text" formControlName="title"
                    maxlength="100">
                </div>
              </div>
            </div>

            <div class="mb-3">
              <div class="form-group row">
                <label class="col-lg-3 col-md-12 col-12 col-sm-12 control-label">Content</label>
                <div class="col-lg-9 col-md-12 col-12 col-sm-12">
                  <textarea class="form-control modal-space" maxlength="200" rows="5"
                    formControlName="content"></textarea>
                  <span class="character-text">{{200 - contentLength}} left</span>
                </div>
              </div>
            </div>

            <div class="mb-3 pt-3">
              <div class="form-group row">

                <div class="col-lg-3 col-md-12 col-12 col-sm-12">
                  <label class="check"> Button
                    <input type="checkbox" formControlName="customButton">
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="col-lg-4 col-md-12 col-12 col-sm-12">
                  <input trim="blur" class="form-control modal-space btn-icon" formControlName="buttonName"
                    maxlength="10" type="text" placeholder="Button Name">
             

                </div>
                <div class="col-lg-2 col-md-12 col-12 col-sm-12 p-0 padding-extra">
                  <input trim="blur" class="form-control modal-space btn-icon p-1 r-mb-4" formControlName="buttonColor"
                    value="{{color}}" type="text" placeholder="Color">
              
                  <span class="color-bottom-text">Background Color</span>
                  <a class="color-icon icon-right-space" [style.color]="color" [cpPosition]="'bottom'"
                    [cpPositionOffset]="'50%'" [cpOutputFormat]="'hex'" [cpPositionRelativeToArrow]="true"
                    (colorPickerChange)="formInAppNotification.controls.buttonColor.setValue($event)"
                    [(colorPicker)]="color">
                    <span class="icon-palette"></span>
                  </a>
                </div>
                <div class="col-lg-3 col-md-12 col-12 col-sm-12">
                  <input trim="blur" class="form-control modal-space r-mt-10" type="text" formControlName="buttonLink"
                    placeholder="Link">
                </div>
              </div>
            </div>
            <div class="text-right mt-1">
              <button class="btn btn-dark" type="submit">Send</button>
            </div>


          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="screen-tab configuration pl-25">
              <tabset>
                <tab heading="IOS">

                  <div class="pushimg ios-notification-img">
                    <div class="notification-img-detail">
                      <div class="notification-contant">
                        <div class="notification-img">
                          <span>
                            <img alt=""
                              src="{{formInAppNotification.controls.imagePath.value || '/assets/images/app_notification/app-notification-ios.png'}}">
                          </span>
                        </div>
                        <div class="notification-img-content">
                          <h5>{{formInAppNotification.controls.title.value||'Lorem ipsum dolor sit amet,'}}</h5>
                          <p>{{formInAppNotification.controls.content.value||'Sed ut perspiciatis unde omnis iste natus error
                            sit voluptatem accusantium doloremque laudantium'}}</p>
                        </div>

                        <div class="notification-img-bottom">
                          <button type="button" class="btn btn-shere">
                            <img alt="" src="assets/images/app_notification/share-alt.svg"> Share
                          </button>
                          <button type="button" *ngIf="formInAppNotification.controls.customButton.value"
                            [style.background]="color"
                            class="btn read-more">{{formInAppNotification.controls.buttonName.value||'Read More'}}</button>

                        </div>


                      </div>
                    </div>
                  </div>


                </tab>
                <tab heading="Android">
                  <div class="pushimg android-notification-img">
                    <div class="notification-img-detail">
                  
                      <div class="notification-contant">
                        <div class="notification-img">
                          <span>
                            <img alt=""
                              src="{{formInAppNotification.controls.imagePath.value || '/assets/images/app_notification/app-notification-ios.png'}}">
                          </span>
                        </div>
                        <div class="notification-img-content">
                          <h5>{{formInAppNotification.controls.title.value||'Lorem ipsum dolor sit amet,'}}</h5>
                          <p>{{formInAppNotification.controls.content.value||'Sed ut perspiciatis unde omnis iste natus error
                            sit voluptatem accusantium doloremque laudantium'}}</p>
                        </div>
                        <div class="notification-img-bottom">
                          <button type="button" class="btn btn-shere">
                            <img alt="" src="assets/images/app_notification/share-alt.svg"> Share</button>
                          <button type="button" [style.background]="color"
                            *ngIf="formInAppNotification.controls.customButton.value"
                            class="btn read-more">{{formInAppNotification.controls.buttonName.value||'Read More'}}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </tab>
                <tab heading="Web">

                  <div class="pushimg pc-notification-img">
                    <div class="notification-img-detail">
                      
                      <div class="notification-contant">
                        <div class="notification-img">
                          <span>
                            <img alt=""
                              src="{{formInAppNotification.controls.imagePath.value || '/assets/images/app_notification/app-notification-ios.png'}}">
                          </span>
                        </div>
                        <div class="notification-img-content">
                          <h5>{{formInAppNotification.controls.title.value||'Lorem ipsum dolor sit amet,'}}</h5>
                          <p>{{formInAppNotification.controls.content.value||'Sed ut perspiciatis unde omnis iste natus error
                            sit voluptatem accusantium doloremque laudantium'}}</p>
                        </div>
                        <div class="notification-img-bottom">
                          <button type="button" class="btn btn-shere">
                            <img alt="" src="assets/images/app_notification/share-alt.svg"> Share</button>
                          <button type="button" [style.background]="color"
                            *ngIf="formInAppNotification.controls.customButton.value"
                            class="btn read-more">{{formInAppNotification.controls.buttonName.value||'Read More'}}</button>
                        </div>
                      </div>
                    </div>
                  </div>

                </tab>
              </tabset>

            </div>

          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>

<!-- <ng-template #galleryPopup>
  <gallery-popup [modalRefGallery]="modalRefGallery" (selectMediaEvent)="getMedia($event)" [galleryType]="galleryType"></gallery-popup>
</ng-template> -->