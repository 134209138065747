import { Injectable } from "@angular/core";
import { AppConstants } from "../../constants/app.constants";
import { ApiConstants } from "../../constants/api.constants";
import { BaseService } from "../../core/services/base.service";

@Injectable({
    providedIn: 'root'
})

export class CampaignJourneyService {

    constructor(
        private baseService : BaseService
    ){
        
    }


    saveUpdateCampaignJourney(body:any){
        return this.baseService.postRequest(ApiConstants.SAVE_UPDATE_CAMPAIGN_JOURNEY,body)
    }

    getCampaignJourneyList(size,page){
        return this.baseService.postRequest(ApiConstants.GET_CAMPAIGN_JOURNEY_LIST.replace(AppConstants.REPLACE_SIZE, size).replace(AppConstants.REPLACE_PAGE, page),{})
    }

    fetchCampaignJourney(body:any){
        return this.baseService.postRequest(ApiConstants.FETCH_CAMPAIGN_JOURNEY,body)
    }

    deleteCampaignJourney(body:any) {
        return this.baseService.postRequest(ApiConstants.DELETE_CAMPAIGN_JOURNEY, body);
    }

    
}