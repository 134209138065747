import { Component, OnInit, OnDestroy, TemplateRef, ViewChild, Output, EventEmitter } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { ToastrService } from "ngx-toastr";
import { Global } from "../../../../shared/models/global.model";
import { LeadStatusObj } from "../../../../../app/core/modal/project";
import { ProjectService } from "../../../../../app/core/services/project.service";
import { noWhitespaceValidator } from "../../../../shared/models/no-whitespace-validator";
import { CacheService } from "../../../../core/services/cache.service";
import { Location } from "@angular/common";
import { SubUserObj } from "../../../../core/modal/user";
import { UserService } from "../../../../core/services/user.service";
import { AppConstants } from "../../../../constants/app.constants";
import { AuthenticationService } from "../../../../core/services/authentication.service";
import { ThrowStmt } from "@angular/compiler";
import { ActivatedRoute, Router } from '@angular/router';
import { CategoryObj } from "../../../../../app/core/modal/project";

@Component({
    selector: 'category-app',
    templateUrl: './category.component.html',
    styleUrls: ['./category.component.scss']
})

export class CategoryComponent implements OnInit, OnDestroy {
    modalRef: BsModalRef;
    @ViewChild('addStatusModal') addStatusModal: TemplateRef<any>;
    @ViewChild('confirmModal') confirmModal: TemplateRef<any>;
    @ViewChild('cnfrimUpdateStatus') cnfrimUpdateStatus: TemplateRef<any>;
    
    siteForm: FormGroup;
    leadStatusApi;
    dataList: CategoryObj[] = [];
    subUserList: SubUserObj[] = [];
    leadStatusData: LeadStatusObj;
    isUpdated: boolean = false;
    selectedleadStatusId: number;
    isShowChangeOlder: boolean = false;
    allTelecallerUserList: SubUserObj[] = [];
    selectUserList: string[] = [];
    selectUserRoles:string[] = [];
    roleList:any = new Array();
    tags:any;
    roleType:any;
    refreshMenuList:any;
    repeatCheckBox:any=false;
    constructor(
        private modalService: BsModalService,
        private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private projectService: ProjectService,
        private globle: Global,
        private cacheService: CacheService,
        private userService: UserService,
        private location: Location,
        private authenticationService: AuthenticationService,
        private route:ActivatedRoute,
        private router:Router,
    ) {

    }

    ngOnInit() {
        this.roleType = [
            {id:1,name:'Admin'},
            {id:2,name:'Marketer'},
            {id:3,name:'Telecaller'},
            {id:4,name:'Manager'}
        ]
        this.fetchCategory();
        this.getSubUserList();
    }

    back() {
        this.location.back();
    }

    ngOnDestroy() {
        if (this.leadStatusApi != undefined) {
            this.leadStatusApi.unsubscribe();
        }
    }
    
    getSubUserList() {
        this.userService.getUsersData().then(data => {
            if (data != undefined) {
                 this.userService.getUsersData('all').then(data=>{
                    this.subUserList = data.filter(p=>{
                        return p.status != 'INACTIVE';
                    });

                    this.projectService.getUserFromMaster().then(data=>{
                        let dataObj = Object.keys(data);
                        dataObj.forEach(elem=>{
                            if(data[elem].userRoleId == 1){
                                data[elem].userRoleName = data[elem].userName;
                                data[elem].userProfileName = data[elem].userName;
                                this.subUserList.push(data[elem])
                            }
                        })
                    });            
                })
            }
        });
        
    }
    addStatus(data?: CategoryObj) {    
        this.prepareForm();
        this.selectUserList = [];
        this.isUpdated = false;
        this.tags="";

        if (data != undefined) {
            if (data.userList != undefined) {
                this.subUserList.forEach(userobject => {
                    if (data.userList.find(x => x == userobject.id)) {
                        this.selectUserList.push(userobject.id);
                    }
                });
            }
            this.siteForm.setValue({
                id: data.id,
                name: data.name,
                userList: this.selectUserList,
                repeatOverride:false
            });
            this.repeatCheckBox  = data.repeatOverride == 'ACTIVE' ? true : false;
            this.isUpdated = true;
        }
        this.isShowChangeOlder = false;
        this.modalRef = this.modalService.show(this.addStatusModal, { class: "modal-addstatus", backdrop: 'static' });
    }

    saveForm() {        
        if (this.siteForm.invalid) {
            this.toastr.error('Please fill all mandatory fields');
            return;
        };

        if(this.selectUserList.length == 0){
            this.toastr.error('Please select user');
            return;
        }

        this.modalRef.hide();

        this.modalRef = this.modalService.show(this.cnfrimUpdateStatus, { class: "", backdrop: 'static' });
    }

    onSelectAllUser(){
        const selected = this.subUserList.map(item => item.id);
        this.selectUserList = selected
    }

    onClearAllUser(){
        this.selectUserList = []
    }

    setRepeatStatus(event){
        if(event.target.checked == true){
            this.siteForm.get('repeatOverride').setValue('ACTIVE');
        } else {
            this.siteForm.get('repeatOverride').setValue('INACTIVE');
        }
    }

    confirmChangeStatusType(){
        this.siteForm.patchValue({
            userList: this.selectUserList,
            roles:this.selectUserRoles,
            tags:this.tags
        });
        var formData = this.siteForm.value;
        if(formData.repeatOverride == false){
            formData.repeatOverride = 'INACTIVE';
        }        
        
        this.projectService.createCategory(formData).subscribe(data => {
            if (data.status) {
                this.toastr.success(data.data, 'Success');
                this.modalRef.hide();
                this.fetchCategory('reload');
                this.refreshMenuList = true;
                window.location.reload();
                // this.updateLeadStatus();
            } else {
                this.toastr.error(data.error_msgs, 'Error');
            }
        })
    }

    fetchCategory(reload?) {        
        this.dataList = [];
        this.projectService.fetchCategory(reload).then(data => {
            if (data) {
                this.dataList = data;
                this.projectService.setCategoryData(this.dataList);
            } else {
                this.toastr.error(data.error_msgs, 'Error');
            }
        })
    }

    prepareForm() {
        this.siteForm = this.formBuilder.group({
            id: ["0"],
            name: ["", Validators.compose([Validators.required, noWhitespaceValidator, Validators.minLength(2), Validators.maxLength(50), , Validators.pattern(this.globle.AlphaNumPattern)])],
            userList: [],
            repeatOverride:new FormControl(false)
        });
    }

    showDeleteModal(id) {
        this.selectedleadStatusId = id;
        this.modalRef = this.modalService.show(this.confirmModal, {
            backdrop: 'static'
        });
    }

    deleteItem() {
        this.projectService.deleteCategory({ id: this.selectedleadStatusId }).subscribe(data => {
            this.modalRef.hide();
            if (data.status) {
                this.toastr.success(data.data, 'Success');
                this.fetchCategory('reload');
                window.location.reload();
            } else {
                this.toastr.error(data.error_msgs, 'Error');
            }
        });
    }

    isUpdateName(value) {
        if (this.isUpdated) {
            this.isShowChangeOlder = false;
            this.siteForm.controls['changeOlder'].setValue(0);
            if (this.leadStatusData != undefined && this.leadStatusData.stageName != value) {
                this.isShowChangeOlder = true;
                this.siteForm.controls['changeOlder'].setValue(null);
            }
        }
    }

}
