<main>
  <section class="inner-page">
    <div class="inner-page-header">
        <div class="container-fluid">
            <h4>
                <a class="back-link" (click)="back()"><img src="assets/images/angle-left.svg"></a>
                User Management
            </h4>
        </div>
    </div>

    <div class="inner-page-body user-role">
      <div *ngIf="isSubscribe" class="container-fluid configuration project-integration-tab">

        <tabset #siteTabs>
            <tab heading="Roles" (selectTab)="changeTab($event.heading)">
                <role-app *ngIf="activeTabHeading == 'Roles'" (eventEmit)="editRoleResponse($event)"></role-app>
            </tab>
            <tab heading="Users" (selectTab)="changeTab($event.heading)">
                <br>
                <div class="card">
                    <form [formGroup]="addForm" (ngSubmit)="addUser()">
                        <div class="card-body">
                            <h4>Assign a New User Role</h4>
                            <div class="row">
                                <div class="col-md-5">
                                    <div class="form-group row">
                                        <label class="col-md-3 control-label" for="inputDefault">Full Name</label>
                                        <div class="col-md-9">
                                            <input trim="blur" autofocus tabindex="1" type="text" formControlName="fullName" class="form-control" id="inputDefault" maxlength="150">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-5">
                                    <div class="form-group row">
                                        <label class="col-md-3 control-label">Email</label>
                                        <div class="col-md-9">
                                            <div class="input-group">
                                                <input trim="blur" type="text" class="form-control" formControlName="email" aria-label="Text input with segmented dropdown button" tabindex="2" maxlength="320">
                                                <select formControlName="type" class="input-group-append custom-select" id="inputGroupSelect03" tabindex="3">
                                                    <option value="">Select Role</option>
                                                    <option *ngFor="let item of roleList" value="{{item.id}}">{{item.name}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <button type="submit" class="btn btn-dark" tabindex="4">Add</button>
                                </div>
                            </div>
                            <!-- <div class="row">
                                <div class="offset-md-1 col-md-10">
                                <p *ngIf="addForm.controls.type.value=='1'">Admin will have full rights to access this account from editing configuration and project integration to
                                    upgrading/downgrading subscription plan. Admin can also remove existing and add new admin users.</p>
                                <p *ngIf="addForm.controls.type.value=='2'">Marketer will have only right to create new campaign and access the reports.</p>
                                <p *ngIf="addForm.controls.type.value=='3'">Telecaller will have only right to access Leads.</p>
                                </div>
                            </div> -->
                        </div>
                    </form>
                </div>
                <div class="table-body">
                    <h4>Existing Roles</h4>
                    <div class="table-responsive">
                        <table class="table">
                            <tbody>
                                <tr  *ngIf="existingUsers.length > 0">
                                    <td class="d-none d-md-table-cell"></td>
                                    <td>User</td>
                                    <td></td>
                                </tr>
                                <ng-container *ngIf="existingUsers.length==0">
                                    <tr><td colspan="3" class="no-record">No Roles Created</td></tr>
                                </ng-container>
                                <ng-container *ngFor="let user of existingUsers">
                                    <tr *ngIf="user.status != 'DELETED'">
                                        <td width="5%" class="d-none d-md-table-cell">
                                            <div class="round-tag">
                                                <span>{{user.userProfileName | shortName}}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="user-detail">
                                                <span class="detail">
                                                    <h5>{{user.userName}} <span *ngIf="user.userProfileName != user.userName">({{user.userProfileName}})</span> </h5>
                                                    <p>{{user.email}}</p>
                                                </span>
                                                <!-- <span *ngIf="user.status=='INACTIVE' || user.status=='PENDING'" class="pending">Pending</span> -->
                                                <span *ngIf="user.status=='INACTIVE'" class="pending c-pointer" (click)="sendEmail(user)">Resend verification link</span>
                                                <span *ngIf="user.status=='PENDING'" class="pending">Pending</span>
                                                <span *ngIf="user.status=='BLOCK'" class="deactivated">Deactivated</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="text-right">
                                                <!-- <button *ngIf="user.userRoleId=='3'" type="button" (click)="openModal(user, pluginTemplate)" class="btn btn-edit">Edit Plugin</button> &nbsp;  -->
                                                <span class="hover-btn">
                                                <button type="button" class="btn btn-blue without-hover role-btn text-capitalize" tabindex="6">{{user.userRoleName}}</button>
                                                <button type="button" (click)="userRoleChange=false;openModal(user, template)"
                                                    class="role-btn btn btn-edit on-hover">Edit</button>
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>
                </div>
            </tab>
            <tab heading="Permission" customClass="left-tab" (selectTab)="changeTab($event.heading)">
                <ng-container *ngIf="roleList.length > 0">
                    <tabset #roleTabs>
                        <tab *ngFor="let role of roleList" [heading]="role.name" [id]="role.id" (selectTab)="changeRoleTab($event.id)">
                            <ul class="permission-list" *ngIf="menuList.length > 0">
                                <permission-item (selectLandingPage)="updateLandingPage($event)" (selectedActionsList)="updateActionList($event)" [menuList]="menuList"></permission-item>
                            </ul>
                            <div class="text-right mt-3"><button type="submit" class="btn btn-dark" (click)="updateRoleActions()">Submit</button></div>
                        </tab>
                    </tabset>
                </ng-container>
                <div class="text-center" *ngIf="roleList.length == 0"><br><h4 class="no-record">There are no permissions available against any role. So please create role first.</h4></div>
            </tab>
        </tabset>
      </div>
      <app-page-disable *ngIf="!isSubscribe"></app-page-disable>
    </div>
    <div class="inner-page-footer"></div>
  </section>
</main>

<ng-template #template>
    
    <form [formGroup]="editForm" (ngSubmit)="editUser()">
        <div class="forgot-password-modal">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Edit User</h4>
               
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()"><span aria-hidden="true">&times;</span></button>
            </div>
            <div class="modal-body modal-user-management edit_user_management">
                
                <div class="d-flex j-space-between">
                <div style="padding-bottom:19px;" *ngIf="this.userData.status == 'ACTIVE' "> <button class="btn remove-user-btn" (click)="userStatus = 'deactivate'; openConfirmModal(incativeuser)" type="button">Deactivate User</button></div>
                <div style="padding-bottom:19px;" *ngIf="this.userData.status == 'BLOCK' "> <button class="btn remove-user-btn" (click)="userStatus = 'activate'; openConfirmModal(incativeuser)" type="button">Activate User</button></div>
                <ng-container *ngIf="userData && userData.status == 'ACTIVE'">
                <!-- <div *ngIf="userData && userData.wabaUser == false" style="padding-bottom:19px;"> <button class="btn remove-user-btn" (click)="wabaUserAdd()" type="button">Add Waba User</button></div>
                <div *ngIf="userData && userData.wabaUser == true" style="padding-bottom:19px;"> <button class="btn remove-user-btn" (click)="wabaUserRemove()" type="button">Remove Waba User</button></div> -->
                <div class="custom-control custom-switch swich-button" [class.inactive]="userData && userData.wabaUser == false" [class.active]="userData && userData.wabaUser == true">
                    <span class="bm">Waba user:</span>
                    <input type="checkbox" [checked]="userData && userData.wabaUser == true" name="wabaUser" (change)="updateWabaUser()"
                    formControlName="wabaUser" class="custom-control-input" id="customSwitchesWabaUser" />
                    <label class="custom-control-label" for="customSwitchesWabaUser"></label>
                </div>
                </ng-container>



            </div>
                
                <ng-container *ngIf="this.userData.status == 'ACTIVE' ">
                <div class="form-group">
                    <input type="hidden" formControlName="id">
                    <select formControlName="type" class="custom-select mr-sm-2" id="inlineFormCustomSelect" (change)="getRoleTypeId($event.target.value)" >
                        <option *ngFor="let item of roleList" value="{{item.id}}">{{item.name}}</option>
                    </select>
                </div>

                <div class="form-group" *ngIf="selectedRoleTypeId != 1">
                    <ng-select
                        formControlName="idList"
                        [items]="pluginList"
                        [multiple]="true"
                        bindLabel="formName"
                        groupBy="pluginName"
                        [selectableGroup]="true"
                        [selectableGroupAsModel]="false"
                        [closeOnSelect]="false"
                        placeholder="Select Plugin"
                        bindValue="id" class="w-100">
                        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                            <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{standalone: true}" /> {{item.pluginName | uppercase}}
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{standalone: true}" /> {{item.formName}}
                        </ng-template>
                    </ng-select>
                </div>

                <div class="form-group" *ngIf="selectedRoleTypeId == 1">
                    <label class="control-label pt-0">User List</label>
                    <ng-select [items]="allUsersInEditForm" class="params w-100" bindLabel="userProfileName" [multiple]="true" bindValue="id" formControlName="userList"> </ng-select>
                </div>
                </ng-container>
            <div class="d-flex justify-content-between">
            <div><button class="btn remove-user-btn" (click)="openConfirmModal(deleteConfirm)" type="button">Remove User</button></div>
            <div class="custom-control custom-switch swich-button" *ngIf="this.userData.status == 'ACTIVE' ">
                <span class="bm">Lead Assignment:</span>
                <input type="checkbox" name="currentStatus" (change)="updateLeadStatus()"
                formControlName="leadStatus" class="custom-control-input" id="customSwitches" />
                <label class="custom-control-label" for="customSwitches"></label>
            </div>
            <div><button class="btn save-btn" type="submit">Update</button></div>
            </div>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #deleteConfirm>
    <div class="forgot-password-modal">
        <div class="modal-header">
            <h4 class="modal-title pull-left">Delete User</h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="confirmModalRef.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body modal-user-management">
            <div class="form-group mb-0">
                <p class="pt-2">Are you sure, you want to delete this user?</p>
                <div class="d-flex justify-content-end mt-5">
                    <div><button class="btn remove-user-btn mr-4" (click)="confirmModalRef.hide()" type="button">Dismiss</button></div>
                    <div><button class="btn save-btn" type="button" (click)="removeUser()">Yes</button></div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #incativeuser>
    <div class="forgot-password-modal">
        <div class="modal-header">
            <h4 class="modal-title pull-left">Deactivate/Activate User</h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="confirmModalRef.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body modal-user-management">
            <div class="form-group mb-0">
                <p class="pt-2">Are you sure, you want to update status of this user?</p>
                <div class="d-flex justify-content-end mt-5">
                    <div><button class="btn remove-user-btn mr-4" (click)="confirmModalRef.hide()" type="button">Cancel</button></div>
                    <div><button class="btn save-btn" type="button" (click)="toggleUserStage()">Yes</button></div>
                </div>
            </div>
        </div>
    </div>
</ng-template>