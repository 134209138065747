import { Component, OnInit, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Global } from '../../../../shared/models/global.model';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { ProjectService } from '../../../../core/services/project.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

@Component({
  selector: 'app-upload-confirm',
  templateUrl: './upload-confirm.component.html',
  styleUrls: ['./upload-confirm.component.scss']
})
export class UploadConfirmComponent implements OnInit {
  @ViewChild('file') inputEl: ElementRef;
  formconfirm: FormGroup;
  modalRef: BsModalRef;
  errormsg: any = '';
  successymsg: any = '';
  file: any;
  model: any = {};
  records: any = [];
  uploadfaildata: any = {};
  @ViewChild('confirm') confirm: TemplateRef<any>;
  @ViewChild('error') error: TemplateRef<any>;
  constructor(

    private formBuilder: FormBuilder,
    private global: Global,
    private toastr: ToastrService,
    private router: Router,
    private projectservice: ProjectService,
    private modalService: BsModalService,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    
    this.records = this.global.uploadlist;
    this.uploadfaildata = this.global.globallistdata;
  }

  openModal(confirm: TemplateRef<any>) {
    this.modalRef = this.modalService.show(confirm, { class: 'modal-sm', backdrop: 'static' });
  }
  errorModal(error: TemplateRef<any>) {
    this.modalRef = this.modalService.show(error, { class: 'modal-sm', backdrop: 'static' });
  }

  afterSave() {
    

    this.openModal(this.confirm);
  }

  Done() {
    if(this.modalRef){
      this.modalRef.hide();
    }
    this.router.navigate(['admin/bulk-upload']);
  }
  saveConfirmupload() {
    this.projectservice.confirmUploadedExcel({ uploadFileId: this.uploadfaildata.uploadFileId }).subscribe(data => {
      if (data.status) {
        this.afterSave();
      }
      else {
        this.errormsg = data.error_msgs;
        this.errorModal(this.error);
      }
    })
  }

  downloadFailReport() {
    this.projectservice.downloadFailReport({uploadFileId: this.uploadfaildata.uploadFileId }).subscribe( data => {
        if (data.status) {
          window.open(data.data, "_blank");
        }
    });
  }

  
  cancelUpload() {
    this.projectservice.cancelUplodedExcel({uploadFileId: this.uploadfaildata.uploadFileId }).subscribe(data => {
      if (data.status) {
        this.Done();
      } else {
        this.errormsg = data.error_msgs;
        this.errorModal(this.error);
      }
    })

  }




}
