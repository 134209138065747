import { Component, OnInit, Input, ViewChild, ElementRef, TemplateRef, QueryList, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef, BsModalService, TabsetComponent } from 'ngx-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe, Location } from '@angular/common';
import { ActivatedRoute, Event, NavigationStart, Router } from '@angular/router';
import { ReportService } from '../../../../core/services/report.service';
import { Global } from '../../../../shared/models/global.model';
import { ProjectService } from '../../../../core/services/project.service';
import { AttributeObj, LeadStatusObj } from '../../../../core/modal/project';
import { SubUserObj } from '../../../../core/modal/user';
import { UserService } from '../../../../core/services/user.service';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { ThrowStmt } from '@angular/compiler';
import { CampaignService } from '../../../../core/services/campaign.service';
import { PhonePipe } from '../../../../shared/pipes/phone.pipe';
import { ConfigurationService } from '../../../../core/services/configuration.service';
import { SpinnerService } from '../../../../core/services/spinner.service';
import { environment } from '../../../../../environments/environment';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-audience-detail',
    templateUrl: './audience-detail.component.html',
    styleUrls: ['./audience-detail.component.scss'],
})
export class AudienceDetailComponent implements OnInit {
    @Input() modalRef: BsModalRef;
    @Input() audienceId: number = 0;
    @Input() category;
    audience: any;
    siteForm: FormGroup;
    moveCatForm: FormGroup;
    minDate: Date = new Date();
    minTime: Date = new Date();
    @Input() audienceProfileClose: Function;
    leadAssignData: any;
    isSubmitted: boolean = false;
    isSubmit: boolean = false;
    leadStatusList: LeadStatusObj[] = [];
    telecallerList: any = [];
    otherParameters: AttributeObj[] = [];
    isOtherParametersShow: boolean = false;
    statusType: string = 'DEFAULT';
    paremType: string = '';
    userRole: number;
    audNameInput: any = '';
    showEmailInput: boolean = false;
    showPhoneInput: boolean = false;
    showNameInput: boolean = false;
    communicationLogProp;
    viewTemplateProps: any = false;
    repeatLeadDynamicList: any;
    leadStatusDetail: any;
    keyword = 'paramValue';
    dynamicKeys:any;
    stageTag:any;
    invalidFile:boolean = false;
    inappUrl:any = environment.webUrl;
    emailUrl:any = environment.emailPushUrl;
    smsUrl:any = environment.smsPushUrl;
    whatsappUrl:any = environment.whatsappApiUrl;
    pushServiceUrl:any = environment.pushServiceUrl;
    // webPushUrl:any = environment.webPushUrl;
    // iosPushUrl:any = environment.iosPushUrl
    shareList:boolean=false;
    dataList:any
    moveCat:boolean= false;
    enableDateTimeControl:boolean=false;
    categoryInput:any;
    categoryUserist:any=[];
    repeatLeadDynamicListStore = new Map<string, number>();
    leadStatusByCat: any;
    moveCatStatus: any;
    moveCatTelecaller: any;
    masterUsers: any = [];
    todayDate: any = new Date();
    warnToOpenThirdPartyLink: boolean = false
    thirdPartyLink: any
    ivrCallLogs:any;
    @ViewChild('scrollblockLeft') scrollblockLeft: ElementRef;
    @ViewChild('scrollblockRight') scrollblockRight: ElementRef;
    @ViewChild('siteTabs') siteTabs: TabsetComponent;
    @ViewChild("audienceProfile") audienceProfile: TemplateRef<any>;
    @ViewChild("audienceDetailContainer") audienceData: QueryList<ElementRef>;
    @ViewChild('communicationTabs') tabset: any;
    @ViewChild('mainDetailsTabs') mainDetailsTabs: any;
    @ViewChild("detailsTabs") tabSetDetails: any;


    callLogs: any[];
    additionalForm: FormGroup;
    additionalFormField: any = {};
    audienceParem;
    screenWidth: any = 0;
    selectedStatus;
    timelineData;
    viewTemplate;
    timelineStats;
    currentDate;
    audienceIdData;
    callDataCounter;
    addedOn;
    updatedOn;
    lastActive;
    counterOfState;
    documentLocker: any = [];
    audStateData;
    pageNo: number = 1;
    dataSize: number = 20;
    clickRedirectAud: any = false;
    otherParamsList: any;
    showOtherParamsList: any;
    Plugindata: any;
    repeatLeadData: any;
    firstTimeCall: any;
    allRescourceName: any = new Array();
    disableDropDown: boolean = false;
    public totalDataSize: number;
    base64DataOfFileType: any = '';
    showInvoiceButton: any = false;
    showQuotationButton: any = false;
    showWhatsAppWindow: boolean = false;
    collapsibleStatus: any = false;
    selectTagsVals: any = new Array()
    selectTags: boolean = false;
    query_params: any;
    showStatusWindow: boolean = false;
    notification_view: boolean = false;
    lead_remarks: any;
    whatsAppApiUrl: any = environment.whatsappApiUrl;
    whatsappMsgData: { phone: string, smsText: string };
    nextId;
    queryParamsFromMenu: any;
    enableUserList: boolean = false;
    userSelectForShareLead: any = []
    userListToShare: any = []
    usersFromMaster: any = [];
    userListCache: any;
    showShareIcon: boolean = false;
    sharedUserList: any;
    moveStatusType: any;
    myObserver: any;
    leadParamsData: any;
    isSubscribe: boolean = false;
    assignToSuperAdmin: boolean = false;
    rolesList: any;
    userRoleLabel: any;
    ivrActivation:boolean=false;
    tagValue:any="";
    pincodeResponse:any;
    constructor(
        private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private reportService: ReportService,
        private projectService: ProjectService,
        private userService: UserService,
        private global: Global,
        private datePipe: DatePipe,
        private authenticationService: AuthenticationService,
        private route: ActivatedRoute,
        private modalService: BsModalService,
        private location: Location,
        private elemRef: ElementRef,
        private router: Router,
        private campaignService: CampaignService,
        private phonePipe: PhonePipe,
        private configurationService: ConfigurationService,
        private spinner: SpinnerService,
        private sanitizer: DomSanitizer,

    ) {
        this.siteForm = this.formBuilder.group({
            status: ["", Validators.compose([Validators.required])],
            remarks: ["", Validators.compose([Validators.required])],
            telecallerId: [''],
            date: [null],
            time: [null]
        });

        this.moveCatForm = this.formBuilder.group({
            categoryList: ["", [Validators.required]],
            userList: ["", []],
            statusList: ["", [Validators.required]],
            moveDate: ["", []],
            moveTime: ["", []]
        })
    }
    ngOnInit() {
        this.userService.getUserRoleData().then(data => {
            this.rolesList = data;
        });
        this.isSubscribe = this.authenticationService.isPageSubscribe('Dynamic Category');
        this.route.paramMap.subscribe(data => {
            if (data) {
                this.queryParamsFromMenu = data;
            }
        })
        if (this.authenticationService.isPageSubscribe('SHARE LEAD')) {
            this.showShareIcon = true;
        } else {
            this.showShareIcon = false
        }
        this.route.params.subscribe(params => {
            if (params.id) {
                if (params.type != 'search') {
                    this.audienceId = params.id;
                }
            }

            if (params.id && !params.type) {
                this.clickRedirectAud = true;
            }
        });

        this.route.queryParamMap.subscribe(data => {
            this.query_params = data;
            this.notification_view = this.query_params.params.notification_view == 'true' ? true : false;
        })


        this.audienceParem = {
            projectKey: '',
            audienceId: '',
            paramList: [],
            encryptAudienceId: ''
        }

        //this.audienceId = 4634259;
        if (this.audienceId !== null && this.audienceId !== undefined) {
            this.getProfile();
        }
        this.userRole = this.authenticationService.getUserRole();
        this.getProjectDetails();
        const screenWidth = screen.width;
        if (screenWidth <= 768) {
            this.screenWidth = 1;
        }
        if (screenWidth > 768) {
            this.screenWidth = 0;
            this.collapsibleStatus = true;
        }
    }

    fetchCategory() {
        this.dataList = [];
        this.projectService.fetchCategory({}).then(data => {
            if (data) {
                data.forEach(element => {
                    if (element.linkRewrite != this.audience.category) {
                        this.dataList.push(element);
                    }
                });
            }
        })
    }

    categorySelected() {
        this.enableUserList = false;
        this.assignToSuperAdmin = false;
        this.moveCatStatus = "";
        this.categoryUserist = []
        this.moveCatTelecaller = "";
        let categoryUserist;
        this.dataList.forEach(elem => {
            if (elem.linkRewrite == this.categoryInput) {
                categoryUserist = elem.userList
                if (elem.leadStatusList) {
                    this.leadStatusByCat = elem.leadStatusList;
                }
            }
        })
        if (categoryUserist && categoryUserist.length > 0) {
            categoryUserist.forEach(userId => {
                if (this.usersFromMaster[userId]) {
                    this.categoryUserist.push(this.usersFromMaster[userId]);
                }
            })
        }
    }

    saveLeadMoveByCat() {
        if (this.moveCatForm.invalid) {
            this.toastr.error('Fill all required fields');
            return;
        }

        var date;
        var time;

        if (this.moveStatusType == 'FOLLOW_UP' || this.moveStatusType == 'CALL_LATER') {
            date = this.datePipe.transform(this.moveCatForm.value.moveDate, "yyyy-MM-dd");
            time = this.datePipe.transform(this.moveCatForm.value.moveTime, "HH:mm:00");
            let checkSubmiTime = this.datePipe.transform(new Date(), "HH:mm");
            let setMinTime = checkSubmiTime.split(':');
            let setMinMinut = parseInt(setMinTime[1]) + 16;
            let followTimeToCompare = setMinTime[0] + ':' + setMinMinut;

            if (this.datePipe.transform(this.moveCatForm.value.moveTime, "HH:mm") < this.datePipe.transform(new Date(), "HH:mm") && date <= this.datePipe.transform(new Date(), "yyyy-MM-dd")) {
                this.toastr.error('Please select correct time.');
                return;
            }
            if (this.datePipe.transform(this.moveCatForm.value.moveTime, "HH:mm") < followTimeToCompare && date <= this.datePipe.transform(new Date(), "yyyy-MM-dd")) {
                this.toastr.error('Follow up time should be 16 minutes ahead of current time.');
                return;
            }
        }
        let postData = {
            'audMasterId': this.audience.audience_id,
            'encryptAudienceId': this.audience._id,
            'status': this.moveCatStatus,
            'telecallerId': this.moveCatTelecaller ? this.moveCatTelecaller : '0',
            'leadCategory': this.categoryInput,
            'followTime': date && time ? date + ' ' + time : null
        }

        this.reportService.moveLeadByCat(postData, this.queryParamsFromMenu.params.dynamicparam).subscribe(data => {
            if (data.status) {
                this.toastr.success('Success');
                this.moveCat = false;
                this.modalRef.hide();
                this.audienceProfileClose();
            } else {
                this.toastr.error(data.error_msgs);
            }
        })
    }

    callAssistAction(phoneNum) {
        this.projectService.audienceCall({ json: [phoneNum] }).subscribe(data => {
        })
    }


    goToInvoice(type) {
        if (type == 'invoice') {
            const link = this.router.serializeUrl(this.router.createUrlTree(['admin/invoice-quotation/Invoice'], { queryParams: { id: this.audienceId } }));
            window.open(link, '_blank');
        }
        if (type == 'quotation') {
            const link = this.router.serializeUrl(this.router.createUrlTree(['admin/invoice-quotation/quotation'], { queryParams: { id: this.audienceId } }));
            window.open(link, '_blank');
        }
    }

    goToViewAud() {
        if (this.notification_view == true) {
            this.location.back();
            return;
        }
        if (this.queryParamsFromMenu && this.queryParamsFromMenu['params'].dynamicparam) {
            this.router.navigate(['admin/lead/' + this.queryParamsFromMenu['params'].dynamicparam]);
            return
        }
        this.router.navigate(['admin/lead/audience']);
    }

    hideModal() {
        this.modalRef.hide();
    }

    collapsibleCommunication(e) {
        if (e == 'show') {
            this.collapsibleStatus = true;
        }
        else if (e == 'hide') {
            this.collapsibleStatus = false;
        }
    }

    downloadPdf(e, f) {

        var style = '.leftPersonalBox{border: 1px solid #cd0141; padding: 10px; min-width: 300px;} .stageDetailBottom{color: #007bff; font-size: 12px;} .leadStageOuter{margin-left: 10px; padding-left: 10px; border-left: 1px solid #ddd;} .iconWord{display:none;}';
        style = style + '.topaudiName{display:flex} .applicatinStageLeadStage{font-size: 9px; margin-top: 5px;display:flex;} .topTitleStage{font-weight: 600; font-size: 12px;} .audiDetailBottom{border-top: 1px solid #ddd; margin-top: 5px; padding-top: 10px; font-size: 14px;}'
        style = style + 'table{width:100%; text-align:left;} .communicationDetailAudi , .cursor-pointer ,.hiddenInPrint{display:none;} .table-reports table tr th{background-color: #F4F4F4; color: #333333; font-size: 14px; line-height: 16px; border-top: none; font-weight: 400}';
        style = style + '.table-reports table tr td{background-color: #F9F9F9;padding:.75rem; color: #7e7e7e; font-size: 13px; line-height: 15px; border-top: none; vertical-align: middle;border: 1px solid #dee2e6;} .table-reports table{border: 1px solid #dee2e6;margin-bottom: 0; border-collapse: collapse;}'
        style = style + '.table-reports{margin-top:20px;} .detailTitle{text-align:center; margin-top:20px; font-size:24px; font-weight:600;} .table-reports table tr th:nth-child(1) , .table-reports table tr td:nth-child(1){width: 130px;padding:.75rem} .table-reports table tr th {border: 1px solid #dee2e6;padding:.75rem;}'
        var htmlOfDetail = e.innerHTML + '<div class="detailTitle">Lead Details</div>' + f.innerHTML;
        let popupWin;
        popupWin = window.open('', '_blank', 'height=800,width=auto,status=no, toolbar=no,menubar=no,location=no');
        if (popupWin == null || popupWin == undefined) {
            window.alert('Please disable popup blocker.');
            return;
        }
        popupWin.document.open('', '_self', 'noopener=true');
        popupWin.document.open();
        popupWin.document.write(`
        <html>
            <head>
            <title>Print tab</title>
            <style>` + style + `</style>
            </head>
            <body onload="window.print();window.close()">`
            + htmlOfDetail +
            `</body>
        </html>`
        );
        setTimeout(() => {
            popupWin.document.close();
        }, 400);
    }

    goToBack() {
        this.location.back();
    }

    copyText(val: string) {
        this.global.copyText(val);
        this.toastr.success('Copied to clipboard');
    }

    callToIvr(){
        let dataBody = {mongoId:this.audience._id,contactNumber:this.audience.mobile}
        this.projectService.call_to_ivr(dataBody).subscribe(data=>{
            if(data.status){
                this.toastr.success(data.data)
            } else{
                this.toastr.error(data.error_msgs);
            }
        })
    }

    getProjectDetails() {
        this.projectService.getUserProjectDetails().then(data => {
            if (data != undefined) {
                this.audienceParem.projectKey = data.projectKey;
                this.ivrActivation = data.ivrActivation;
            }
        }, (error) => {
            console.log(error);
        });
    }

    public dataForPaging(event) {
        this.pageNo = event;
        this.nextId = undefined;
        this.getTimeline('');
    }

    additionalDtlSubmit(e) {
        let parentMenu;
        if (this.queryParamsFromMenu) {
            parentMenu = this.queryParamsFromMenu['params'].dynamicparam;
        }
        this.isSubmit = true
        this.audienceParem.paramList = [];
        let formVals = Object.keys(e);
        formVals.forEach(elems => {
            if (e[elems].length > 0) {
                e[elems] = e[elems].toString();
            }
            if (e[elems] != undefined && e[elems] != "") {
                let checkEmailMobileType = this.otherParameters.find(x => x.paramKey == elems)
                if (checkEmailMobileType) {
                    if (checkEmailMobileType.paramDatatype == 'Email') {
                        let regex = /^[A-Za-z0-9]+[A-Za-z0-9._!#$%&'*+-/=?^_`{|}~]+@([A-Za-z0-9-]+[.]){1,2}[A-Za-z.]{2,10}$/;
                        if (regex.test(e[elems]) == false) {
                            this.toastr.error("Please enter valid email");
                            this.spinner.hide();
                            this.isSubmit = false;
                            return;
                        }
                    } else if (checkEmailMobileType.paramDatatype == 'Mobile') {
                        let regex = /^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$/;
                        if (regex.test(e[elems]) == false) {
                            this.toastr.error("Please enter valid phone number");
                            this.spinner.hide();
                            this.isSubmit = false;
                            return;
                        }
                    }


                }
                this.audienceParem.paramList.push({ paramKey: elems, paramValue: e[elems] });
            }
            if (elems == "lead_followdate" || elems == "tm_generated") {
                e[elems] = this.datePipe.transform(e[elems], "yyyy-MM-dd HH:mm:ss");
            }
        });


        this.audienceParem.projectKey = this.projectService.projectDetails.projectKey;
        this.audienceParem.encryptAudienceId = this.audience._id;
        if (this.isSubmit == true) {
            this.projectService.registerAudienceData(parentMenu, this.audienceParem).subscribe(data => {
                if (data.status) {
                    this.otherParameters.forEach(element => {
                        if (element.fileNameOfFile) {
                            delete element.fileNameOfFile
                        }
                    })
                    setTimeout(() => {
                        this.getProfile();
                        // this.getTimeline();            
                    }, 1000);
                    this.toastr.success(data.data, 'Success');
                    this.isSubmit = false;
                    this.selectedStatus = undefined;
                    this.siteForm.reset();
                } else {
                    this.toastr.error(data.error_msgs, 'Error');
                    this.isSubmit = false;
                }
            });
        }


    }

    numSymbol(evt) {
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if (charCode != 43 && charCode != 45 && (charCode < 48 || charCode > 57))
            return false;
        return true;
    }

    htmlDecode(str) {
        var div = document.createElement("div");
        div.innerHTML = str;
        return div.textContent || div.innerText;
    }

    getProfile() {
        let parentMenu;
        if (this.queryParamsFromMenu) {
            parentMenu = this.queryParamsFromMenu['params'].dynamicparam;
        }
        this.documentLocker = [];


        this.reportService.fetchIndividualAudienceData({ _id: this.audienceId }).subscribe(data => {
            if (data.status) {
                this.audience = data.data.audienceMasterDTO;
                this.fetchCategory();
                this.audienceParem.audienceId = this.audience.audience_id;
                this.audienceIdData = this.audience.audience_id;
                if (this.audienceIdData && this.audienceIdData.length > 15) {
                    this.audienceIdData = this.audienceIdData.substring(0, 15);
                    this.audienceIdData = this.audienceIdData + '...';
                }

                if (this.audience.android_fcm_token != null) {
                    this.audience.android_fcm_token_data = this.audience.android_fcm_token;
                    if (this.audience.android_fcm_token_data.length > 15) {
                        this.audience.android_fcm_token_data = this.audience.android_fcm_token_data.substring(0, 15);
                        this.audience.android_fcm_token_data = this.audience.android_fcm_token_data + '...'
                    }
                }

                if (this.audience.ios_fcm_token != null) {
                    this.audience.ios_fcm_token_data = this.audience.ios_fcm_token;
                    if (this.audience.ios_fcm_token_data.length > 15) {
                        this.audience.ios_fcm_token_data = this.audience.ios_fcm_token_data.substring(0, 15);
                        this.audience.ios_fcm_token_data = this.audience.ios_fcm_token_data + '...'
                    }
                }

                if (this.audience.web_fcm_token != null) {
                    this.audience.web_fcm_token_data = this.audience.web_fcm_token;
                    if (this.audience.web_fcm_token_data.length > 10) {
                        this.audience.web_fcm_token_data = this.audience.web_fcm_token_data.substring(0, 10);
                        this.audience.web_fcm_token_data = this.audience.web_fcm_token_data + '...'
                    }
                }

                if (this.audience.email) {
                    this.audience.emailData = this.audience.email;
                    if (this.audience.emailData.length > 20) {
                        this.audience.emailData = this.audience.emailData.substring(0, 20);
                        this.audience.emailData = this.audience.emailData + '...'
                    }

                }
                this.addedOn = new Date(this.audience.params.entry_date.replace(".000Z", ""));
                this.addedOn = this.datePipe.transform(this.addedOn, "yyyy-MM-dd");
                this.updatedOn = this.datePipe.transform(new Date(this.audience.updated.replace(".000Z", "")), "yyyy-MM-dd HH:mm:ss");

                if (!this.audience.params['lead_status']) {
                    this.audience.params['lead_status'] = 'Open';
                }
                this.leadAssignData = this.audience.params;
                if (this.audience.params != null) {
                    let audienceDynamicDataList = []
                    var list = this.projectService.filterAttributeList(['all']);
                    list.sort((a, b) => (a.paramName > b.paramName) ? 1 : -1);
                    this.dynamicKeys = Object.keys(this.audience.params);
                    this.dynamicKeys.forEach(element => {
                        let keysInsertInDynamicList = list.find(x => x.paramKey == element);
                        if (keysInsertInDynamicList != undefined && this.audience.params.hasOwnProperty(element)) {
                            audienceDynamicDataList.push({
                                paramName: keysInsertInDynamicList.paramName,
                                paramMandatory: keysInsertInDynamicList.paramMandatory,
                                paramCategory: keysInsertInDynamicList.paramCategory,
                                paramValueChange: keysInsertInDynamicList.paramValueChange,
                                paramVisible: keysInsertInDynamicList.paramVisible,
                                paramOwner: keysInsertInDynamicList.paramOwner,
                                paramEditable: keysInsertInDynamicList.paramEditable,
                                paramDatatype: keysInsertInDynamicList.paramDatatype,
                                pluginName: keysInsertInDynamicList.pluginName,
                                editParam: false,
                                paramValue: this.htmlDecode(this.audience.params[element]),
                                paramKey: element,
                                category: keysInsertInDynamicList.category,
                                backDataEditable: keysInsertInDynamicList.backDataEditable,
                                multipleTimeEditable: keysInsertInDynamicList.multipleTimeEditable

                            })
                        }
                    });

                    this.audience.audienceDynamicDataList = audienceDynamicDataList;

                    this.audience.audienceDynamicDataList.sort((a, b) => {
                        if (a.paramName.toLowerCase() < b.paramName.toLowerCase()) {
                            return -1;
                        }
                        if (a.paramName.toLowerCase() > b.paramName.toLowerCase()) {
                            return 1;
                        }
                    });
                    this.audience.audienceDynamicDataList.forEach(element => {
                        if (element.multipleTimeEditable == 'INACTIVE' && element.paramValue) {
                            element.paramValueChange = 'INACTIVE';
                        }
                        if (element.paramOwner == 0) {
                            if (element.category) {
                                let catToArr = element.category.split(',');
                                catToArr = catToArr.filter(p => {
                                    return p != null && p != "";
                                });
                                let checkByCat = catToArr.find(x => x == this.category);
                                if (checkByCat == undefined) {
                                    element.paramValueChange = 'INACTIVE';
                                }
                            }
                        }


                        if (element.paramDatatype == 'Date') {
                            if (element.paramValue.includes('T') == true) {
                                element.paramValue = element.paramValue.split('.000Z')[0];
                                element.paramValue = element.paramValue.split('T').join(' ');
                            }
                        }
                        if (typeof (element.paramValue) == 'string') {
                            if (element.paramValue.includes('http:') == true || element.paramValue.includes('https:') == true) {
                                let findInValidStr = element.paramValue.substring(0, 10);
                                if (findInValidStr.includes('http:') == true || findInValidStr.includes('https:') == true) {
                                    this.documentLocker.push({ 'data': [element.paramName, element.paramValue] });
                                    if (element.paramValue.includes('download-invoice') == true || element.paramValue.includes('download-quotation') == true) {
                                        element.invoicepdf = true
                                    }

                                    element.fileLink = true;
                                    // element.paramValue = '<a href="'+ element.paramValue +'" target="_blank">Click to View</a>';           
                                }
                            }
                        }
                    });

                    if (this.audience.params['lead_status']) {
                        this.leadStatusDetail = this.audience.audienceDynamicDataList.find(x => x.paramKey == 'lead_status');
                        this.showStatusWindow = true;
                        let categoryList = []
                        this.projectService.fetchCategory({}).then(data => {
                            if (data) {
                                data.forEach(element => {
                                    categoryList.push(element);
                                });

                                categoryList.forEach(element => {
                                    if (element.linkRewrite == this.category) {
                                        if (element.leadStatusList && element.leadStatusList.length > 0) {
                                            let findStageInCatList = element.leadStatusList.find(x => x.stageName == this.leadStatusDetail.paramValue);
                                            if (findStageInCatList && findStageInCatList.attributes && findStageInCatList.attributes.length > 0) {
                                                findStageInCatList.attributes.forEach(attribute => {
                                                    let findInAttrList = list.find(x => x.paramKey == attribute);
                                                    if (findInAttrList) {
                                                        let checkInLeadDetails = this.audience.audienceDynamicDataList.find(x => x.paramKey == attribute);
                                                        if (checkInLeadDetails == undefined) {
                                                            this.audience.audienceDynamicDataList.push({
                                                                paramName: findInAttrList.paramName,
                                                                paramMandatory: this.audience.params[attribute] && this.audience.params[attribute].paramValue ? 'INACTIVE' : 'ACTIVE',
                                                                paramCategory: findInAttrList.paramCategory,
                                                                paramValueChange: this.audience.params[attribute] && this.audience.params[attribute].paramValue ? 'INACTIVE' : 'ACTIVE',
                                                                paramVisible: findInAttrList.paramVisible,
                                                                paramOwner: findInAttrList.paramOwner,
                                                                paramEditable: findInAttrList.paramEditable,
                                                                paramDatatype: findInAttrList.paramDatatype,
                                                                pluginName: findInAttrList.pluginName,
                                                                editParam: this.audience.params[attribute] && this.audience.params[attribute].paramValue ? false : true,
                                                                // paramValue:"abcd",
                                                                paramValue: this.audience.params[attribute] && this.audience.params[attribute].paramValue ? this.audience.params[attribute].paramValue : "",
                                                                paramKey: findInAttrList.paramKey,
                                                                category: findInAttrList.category,
                                                                backDataEditable: findInAttrList.backDataEditable,
                                                                multipleTimeEditable: findInAttrList.multipleTimeEditable,
                                                                temperoryAttr: true,
                                                                paramDefaultValueDTO: findInAttrList.paramDefaultValueDTO && findInAttrList.paramDefaultValueDTO.length > 0 ? findInAttrList.paramDefaultValueDTO : undefined
                                                            })
                                                        }
                                                    }
                                                });

                                            }
                                        }
                                    }
                                });
                            }
                        })
                        this.setLeadAssignData(this.leadStatusDetail, this.audience.params);
                        this.fetchUserList();
                    }

                    let checkDevide = this.audience.audienceDynamicDataList.length / 2;
                    if (checkDevide % 1 != 0) {
                        this.audience.firstHalf = Math.floor(this.audience.audienceDynamicDataList.length / 2);
                        this.audience.secondHalf = Math.ceil(this.audience.audienceDynamicDataList.length / 2);
                    }
                    if (checkDevide % 1 == 0) {
                        this.audience.firstHalf = this.audience.audienceDynamicDataList.length / 2;
                        this.audience.secondHalf = this.audience.audienceDynamicDataList.length / 2;
                        this.audience.secondHalf = this.audience.secondHalf + 1
                    }

                }
                // this.audience.audienceDynamicDataList = audienceDynamicDataList;

                if (this.leadAssignData.lead_followdate) {
                    this.leadAssignData.lead_followdate = this.leadAssignData.lead_followdate.split('.000Z')[0];
                    this.leadAssignData.lead_followdate = this.leadAssignData.lead_followdate.split('T').join(' ');
                }
                this.lead_remarks = this.leadAssignData.lead_remarks;
                if (this.lead_remarks != undefined) {
                    this.lead_remarks.forEach(element => {
                        element.created = element.created.replace(' ', 'T');
                    });
                    if (Array.isArray(this.lead_remarks)) {
                        if (this.lead_remarks.length > 0) {
                            this.lead_remarks.sort((a, b) => {
                                let c = new Date(a.created).getTime();
                                let d = new Date(b.created).getTime();
                                return d - c;
                            })
                        }
                    }
                }
                let date = new Date();
                this.currentDate = this.datePipe.transform(date, 'yyyy-M-dd');

                this.getOtherParameters();
                this.nextId = undefined;
                this.getTimeline('');
            } else {

                this.toastr.error('Detail not found');
                setTimeout(() => {
                    this.modalRef.hide();
                }, 1000);
            }
        });
    }

    downloadFileBylink(item, type?) {
        if (item.invoicepdf) {
            window.open(item.paramValue, "_blank");
            return
        }
        let downloadFileObj = { filePath: item.paramValue }
        if (type == 'documentLocker') {
            downloadFileObj.filePath = item.data[1];
        }

        this.reportService.downloadFileTypeData(downloadFileObj, this.category).subscribe(data => {
            if (data.status) {
                if (data.data.includes('s3') == true && data.data.includes('amazonaws.com') == true) {
                    window.open(data.data, "_blank");
                } else {
                    if (data.data.includes('cronberry.com') == true) {
                        window.open(data.data, "_blank");
                    } else {
                        this.warnToOpenThirdPartyLink = true;
                        this.thirdPartyLink = data.data
                    }
                }

            } else {
                this.toastr.error(data.error_msgs)
            }
        })
    }

    openToTab() {
        window.open(this.thirdPartyLink, "_blank");
        this.warnToOpenThirdPartyLink = false;
    }


    getTimeline(type?: any, loadmore?) {
        this.reportService.fetchIndividualAudienceTimelineEntry({ audienceId: this.audience._id, id: this.audience.id, type: type != '' ? type : undefined, nextId: this.nextId }).subscribe(data => {
            if (data.status) {
                if (type == '') {
                    if (loadmore == 'loadMore') {
                        data.data.timeLineData.forEach(element => {
                            this.timelineData.push(element);
                        });
                        data.data.statsData.forEach(element => {
                            this.timelineStats.push(element)
                        });
                    } else {
                        this.timelineData = data.data.timeLineData;
                        this.timelineStats = data.data.statsData;
                    }

                    if (this.timelineStats != null) {
                        this.timelineStats.forEach(element => {
                            element.type = element.type.replace(/_/g, " ");
                        });
                    }
                    this.timelineData.forEach(element => {
                        element.timelineDate = element.timelineDt.split(' ')[0];
                        element.timelineTime = element.timelineDt.split(' ')[1];
                        let telecaller_id = this.masterUsers.find(x => x.id == element.object)
                        if (telecaller_id != undefined) {
                            element.telecallerName = this.masterUsers.find(x => x.id == element.object).userName;
                        }
                        if (element.type == 'MOVE_CATEGORY') {
                            let fromCategory = element.object.split('to')[0];
                            let toCategory = element.object.split('to')[1];
                            this.projectService.fetchCategory({}).then(data => {
                                if (data) {
                                    let catDataList = data;
                                    let checkFromCatList = catDataList.find(x => x.linkRewrite == fromCategory.trim());
                                    let checkToCatList = catDataList.find(x => x.linkRewrite == toCategory.trim());
                                    if (checkFromCatList && checkToCatList) {
                                        element.object = checkFromCatList.name + ' to ' + checkToCatList.name;
                                    }
                                }
                            });

                        }
                        if (element.type == 'SHARE') {
                            let elemDetail = JSON.parse(element.object);
                            if (elemDetail.userList && elemDetail.userList.length > 0) {
                                elemDetail.userList.forEach(userId => {
                                    if (this.masterUsers && this.masterUsers.length > 0) {
                                        let checkUserName = this.masterUsers.find(x => x.id == userId);
                                        if (checkUserName) {
                                            element.shareTo = element.shareTo ? element.shareTo + ',' + checkUserName.userName : checkUserName.userName;
                                        }
                                    }
                                });
                            } else if (elemDetail.userList && elemDetail.userList.length == 0) {
                                element.shareTo = "";
                            }
                        }

                    });
                }
                if (type != '') {
                    this.timelineData = data.data.timeLineData;
                    if (this.timelineStats != null) {
                        this.timelineStats.forEach(element => {
                            element.type = element.type.replace(/_/g, " ");
                        });
                    }

                    this.timelineData.forEach(element => {
                        element.timelineDate = element.timelineDt.split(' ')[0];
                        element.timelineTime = element.timelineDt.split(' ')[1];
                    });
                }
                // if(prevTimelineData){
                //     Array.prototype.push.apply(prevTimelineData,this.timelineData);
                //     this.timelineData = prevTimelineData;
                // }
                this.totalDataSize = this.timelineData.length;
                this.nextId = data.data.nextId;
            }
        }, (error) => {
            console.log(error);
        })
    }

    viewTemplateData(id, list) {
        let url;
        if (list.type == 'EMAIL') {
            url = this.emailUrl
        } else if (list.type == 'SMS') {
            url = this.smsUrl;
        } else if (list.type == 'WHATSAPP') {
            url = this.whatsappUrl
        }else if (list.type == 'ANDROID_PUSH' || list.type == 'WEB_PUSH' || list.type == 'IOS_PUSH'){
            url = this.pushServiceUrl
        }
        // else if (list.type == 'WEB_PUSH'){
        //     url = this.pushServiceUrl
        // } else if (list.type == 'IOS_PUSH'){
        //     url = this.pushServiceUrl
        // }
        this.campaignAllData(id,url); 
    }

    campaignAllData(id, url) {
        this.reportService.fetchIndividualCampaignAllData({ id: id }, url).subscribe(data => {
            if (data.status) {
                this.viewTemplate = data.data;
                //this.modalRef = this.modalService.show(template,{class:'viewTimelineTemplate'});
                this.viewTemplateProps = true;
            } else {
                this.toastr.error(data.error_msgs)
            }
        }, (error) => {
            console.log(error);
        });
    }

    viewTemplateHide() {
        this.viewTemplateProps = false;
    }

    getNextTimelineData(e?: any, loadMore?) {
        if (e) {
            this.getTimeline(e, loadMore);
        } else {
            this.getTimeline('', loadMore);
        }
    }

    individualAudienceStats(e) {
        if (e == 'EMAIL') {
            this.tabset.tabs[0].active = true
            this.communicationLogProp = 'selected';
        }
        this.reportService.fetchIndividualAudienceStats({ audienceId: this.audience._id, id: this.audienceId, type: e }).subscribe(data => {
            if (data.status) {
                this.counterOfState = "";
                this.audStateData = data.data;
                this.nextId = undefined;
                this.timelineData = [];
                this.getTimeline(e);
                if (this.audStateData.length > 0) {
                    this.audStateData.forEach(element => {
                        this.counterOfState = element.count;
                    });
                }
            }
        }, (error) => {
            console.log(error);
        })
    }


    setSiteFormValidators(type) {
        const remarksControl = this.siteForm.get('remarks');
        const telecallerIdControl = this.siteForm.get('telecallerId');
        const dateControl = this.siteForm.get('date');
        const timeControl = this.siteForm.get('time');
        this.statusType = type;
        if (type == 'CALL_LATER') {
            telecallerIdControl.setValidators([]);
            remarksControl.setValidators([Validators.required]);
            dateControl.setValidators([Validators.required]);
            timeControl.setValidators([Validators.required]);
            dateControl.setValue(new Date());
            timeControl.setValue(new Date());
        } else if (type == 'FOLLOW_UP') {
            remarksControl.setValidators([Validators.required]);
            telecallerIdControl.setValidators([Validators.required]);
            dateControl.setValidators([Validators.required]);
            timeControl.setValidators([Validators.required]);
            dateControl.setValue(new Date());
            timeControl.setValue(new Date());
        } else {
            remarksControl.setValidators([Validators.required]);
            telecallerIdControl.setValidators([]);
            dateControl.setValidators([]);
            timeControl.setValidators([]);
            dateControl.setValue(null);
            timeControl.setValue(null);
        }
        remarksControl.updateValueAndValidity();
        telecallerIdControl.updateValueAndValidity();
        dateControl.updateValueAndValidity();
        timeControl.updateValueAndValidity();

    }

    statusChangeToMove() {
        this.moveCatTelecaller = "";
        this.enableUserList = false;
        this.assignToSuperAdmin = false;
        let checkSelectedStatus = this.leadStatusByCat.find(x => x.stageName == this.moveCatStatus);
        let checkCat = this.dataList.find(x => x.linkRewrite == this.categoryInput);
        if (checkCat && checkCat.userList && checkCat.userList.length > 0) {
            this.enableUserList = true;
        } else {
            this.assignToSuperAdmin = true;
        }
        if (checkSelectedStatus && checkSelectedStatus.userList && checkSelectedStatus.userList.length > 0) {
            this.enableUserList = false;
            this.assignToSuperAdmin = false;
        }
        if (checkSelectedStatus && (checkSelectedStatus.stageType == "FOLLOW_UP" || checkSelectedStatus.stageType == "CALL_LATER")) {
            this.enableDateTimeControl = true;
            this.moveCatForm.get('moveTime').setValue(new Date());
            this.moveCatForm.get('moveDate').setValue(new Date())
        } else {
            this.enableDateTimeControl = false
        }
        if (checkSelectedStatus) {
            this.moveStatusType = checkSelectedStatus.stageType
        }
    }

    activateStage(e, item?) {
        this.tagValue = "";
        this.selectTagsVals = [];
        this.selectedStatus = e;

        let leadStatusObject = this.leadStatusList[e];
        this.disableDropDown = false;
        if (leadStatusObject.userList && leadStatusObject.userList != undefined && leadStatusObject.userList.length > 0) {
            this.disableDropDown = true;
        }
        if (item) {
            for (let i = 0; i < this.audience.audienceDynamicDataList.length; i++) {
                if (this.audience.audienceDynamicDataList[i].temperoryAttr) {
                    delete this.audience.audienceDynamicDataList[i]
                }
            }
            this.audience.audienceDynamicDataList = this.audience.audienceDynamicDataList.filter(p => {
                return p != null;
            })
            let categoryList = []
            let list = this.projectService.filterAttributeList(['all']);
            this.projectService.fetchCategory({}).then(data => {
                if (data) {
                    data.forEach(element => {
                        categoryList.push(element);
                    });

                    categoryList.forEach(element => {
                        if (element.linkRewrite == this.category) {
                            if (element.leadStatusList && element.leadStatusList.length > 0) {
                                let findStageInCatList = element.leadStatusList.find(x => x.id == item.id);
                                if (findStageInCatList && findStageInCatList.attributes && findStageInCatList.attributes.length > 0) {
                                    findStageInCatList.attributes.forEach(attribute => {
                                        let findInAttrList = list.find(x => x.paramKey == attribute);
                                        if (findInAttrList) {
                                            let checkInLeadDetails = this.audience.audienceDynamicDataList.find(x => x.paramKey == attribute);
                                            if (checkInLeadDetails == undefined) {
                                                this.audience.audienceDynamicDataList.push({
                                                    paramName: findInAttrList.paramName,
                                                    paramMandatory: 'ACTIVE',
                                                    paramCategory: findInAttrList.paramCategory,
                                                    paramValueChange: 'ACTIVE',
                                                    paramVisible: findInAttrList.paramVisible,
                                                    paramOwner: findInAttrList.paramOwner,
                                                    paramEditable: findInAttrList.paramEditable,
                                                    paramDatatype: findInAttrList.paramDatatype,
                                                    pluginName: findInAttrList.pluginName,
                                                    editParam: true,
                                                    paramValue: "",
                                                    paramKey: findInAttrList.paramKey,
                                                    category: findInAttrList.category,
                                                    backDataEditable: findInAttrList.backDataEditable,
                                                    multipleTimeEditable: findInAttrList.multipleTimeEditable,
                                                    temperoryAttr: true,
                                                    paramDefaultValueDTO: findInAttrList.paramDefaultValueDTO && findInAttrList.paramDefaultValueDTO.length > 0 ? findInAttrList.paramDefaultValueDTO : undefined

                                                })
                                                this.getOtherParameters();
                                            }
                                        }
                                    });

                                }
                            }
                        }
                    });
                }
            })

            if (item.tags) {
                this.stageTag = item.tags.split(',');
                this.stageTag = this.stageTag.filter((p) => {
                    return p != null;
                })
                this.stageTag.forEach(element => {
                    this.selectTagsVals.push({ 'paramKey': 'tag', 'paramValue': element.trim() });
                });

                this.audience.audienceDynamicDataList.forEach(elementFilter => {
                    if (elementFilter.paramKey == 'tag') {
                        elementFilter.paramDefaultValueDTO = [];
                        this.stageTag.forEach(element => {
                            elementFilter.paramDefaultValueDTO.push({ paramValue: element.trim() });
                        });
                    }
                });

                this.selectTags = true;
            } else {
                this.audience.audienceDynamicDataList.forEach(elementFilter => {
                    if (elementFilter.paramKey == 'tag') {
                        elementFilter.paramDefaultValueDTO = [];
                    }
                });
            }
            this.getOtherParameters();
        }
        // this.modalRef = selectTags
    }

    tagSelected(value){
        this.tagValue = value;
        this.selectTags = false
    }

    tagSubmit(param) {
        let parentMenu;
        if (this.queryParamsFromMenu) {
            parentMenu = this.queryParamsFromMenu['params'].dynamicparam;
        }
        let postData = {
            projectKey: this.projectService.projectDetails.projectKey,
            audienceId: this.audience.audience_id,
            paramList: [
                { paramKey: 'tag', paramValue: param }
            ],
            encryptAudienceId: this.audience._id,
        }
        this.projectService.registerAudienceData(parentMenu, postData).subscribe(data => {
            if (data.status) {
                this.toastr.success(data.data, 'Success');
                this.selectTags = false;
                this.audience.audienceDynamicDataList.forEach(element => {
                    if (element.paramKey == 'tag') {
                        element.paramValue = param;
                    }
                });

                let checkTagAvailable = this.audience.audienceDynamicDataList.filter((p) => {
                    return p.paramKey == 'tag'
                });
                if (checkTagAvailable.length == 0) {
                    this.audience.audienceDynamicDataList.push({
                        paramName: "Tag",
                        paramMandatory: "INACTIVE",
                        paramCategory: 1,
                        paramValueChange: "ACTIVE",
                        paramVisible: 1,
                        paramOwner: 1,
                        paramEditable: "ACTIVE",
                        paramDatatype: "String",
                        pluginName: "dynamic",
                        editParam: false,
                        paramValue: param,
                        paramKey: "tag",
                        paramDefaultValueDTO: []
                    });
                    let setDefaultValDto = this.stageTag;
                    setDefaultValDto = setDefaultValDto.filter((p) => {
                        return p != null;
                    });

                    this.audience.audienceDynamicDataList.forEach(element => {
                        if (element.paramKey == 'tag') {
                            element.paramDefaultValueDTO = []
                            setDefaultValDto.forEach(tagElement => {
                                element.paramDefaultValueDTO.push({ paramValue: tagElement.trim() });
                            });
                        }
                    });



                    this.audience.audienceDynamicDataList.sort((a, b) => {
                        if (a.paramName.toLowerCase() < b.paramName.toLowerCase()) {
                            return -1;
                        }
                        if (a.paramName.toLowerCase() > b.paramName.toLowerCase()) {
                            return 1;
                        }
                    })
                }
            }
            else {
                this.toastr.error(data.error_msgs);
            }
        });
    }

    formSubmit() {

        let checkSubmiTime = this.datePipe.transform(new Date(), "HH:mm");
        let setMinTime = checkSubmiTime.split(':');
        let setMinMinut = parseInt(setMinTime[1]) + 16;
        let followTimeToCompare = setMinTime[0] + ':' + setMinMinut;
        this.isSubmit = true;

        let parentMenu;
        if (this.queryParamsFromMenu) {
            parentMenu = this.queryParamsFromMenu['params'].dynamicparam;
        }

        if (this.siteForm.value.status == '') {
            this.toastr.error('Please select status.');
            return;
        }
        if (this.siteForm.invalid) {
            this.toastr.error(this.global.MessageRequired);
            this.global.validateAllFormFields(this.siteForm);
            return;
        }
        var isParamMandatory = false;
        if (this.audience.audienceDynamicDataList) {
            this.audience.audienceDynamicDataList.forEach(element => {
                if (element.editParam && element.paramMandatory == 'ACTIVE') {
                    isParamMandatory = true;
                }
            });
            for (let i = 0; i < this.audience.audienceDynamicDataList.length; i++) {
                if (this.audience.audienceDynamicDataList[i].fileNameOfFile) {
                    if (this.audience.audienceDynamicDataList[i].tempFileName == undefined) {
                        this.toastr.error('Save lead details all fields before proceed');
                        return
                    }
                }
                if (this.audience.audienceDynamicDataList[i].editParam) {
                    this.toastr.error('Save fields before proceed');
                    return
                }
                if (this.audience.audienceDynamicDataList[i].invoice_quotation_hide != true) {
                    if (this.audience.audienceDynamicDataList[i].paramValue === '') {
                        this.toastr.error(this.global.MessageRequired)
                        return
                    }
                }

            }
        }
        if (isParamMandatory && this.siteTabs != undefined) {
            this.siteTabs.tabs[0].active = true;
            this.toastr.error(this.global.MessageRequired);
            return;
        }

        var postData: any;
        postData = Object.assign({}, this.siteForm.value);
        var date = this.datePipe.transform(this.siteForm.value.date, "yyyy-MM-dd");
        var time = this.datePipe.transform(this.siteForm.value.time, "HH:mm:00");

        if (this.statusType != 'COMPLETED' && this.statusType != 'DEFAULT' && this.statusType != 'ARCHIVED') {

            if (this.datePipe.transform(this.siteForm.value.time, "HH:mm") < this.datePipe.transform(new Date(), "HH:mm") && date <= this.datePipe.transform(new Date(), "yyyy-MM-dd")) {
                this.toastr.error('Please select correct time.');
                return;
            }
            if (this.datePipe.transform(this.siteForm.value.time, "HH:mm") < followTimeToCompare && date <= this.datePipe.transform(new Date(), "yyyy-MM-dd")) {
                this.toastr.error('Follow up time should be 16 minutes ahead of current time.');
                return;
            }
        }
        postData.followTime = date != null ? date + ' ' + time : null;
        postData.audMasterId = this.audience.audience_id;
        postData.encryptAudienceId = this.audience._id;
        postData.telecallerId = this.statusType == 'FOLLOW_UP' ? this.siteForm.value.telecallerId : null;
        if (postData.date) {
            delete postData.date
        }
        if (postData.time) {
            delete postData.time
        }
        if (this.statusType == 'COMPLETED') {

            this.audience.audienceDynamicDataList.forEach(element => {
                if (element.paramKey == 'lead_followdate') {
                    let currentDateTime = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss');
                    let followTime = element.paramValue
                    if (followTime > currentDateTime) {
                        postData.followTime = currentDateTime;
                    }
                }
            });

        }
        if(this.tagValue){
            postData.tag = this.tagValue;
        } else if(this.tagValue == "") {
            postData.tag = ""
        }
        this.isSubmitted = true;
        this.reportService.upsertUserLeadAssign(postData, parentMenu).subscribe(data => {
            //   this.modalRef.hide();
            if (data.status) {
                this.toastr.success('Details Saved Successfully', 'Success');
                this.modalRef.hide();
                this.reportService.leadReportsData = undefined;
                this.reportService.leadCountsReportsData = undefined;
                this.audienceProfileClose();
                if (this.notification_view == true) {
                    this.location.back();
                    // this.router.navigate(['admin/audience/view-audience']);
                }
            } else {
                this.isSubmitted = false;
                this.toastr.error(data.error_msgs, 'Error');
            }
        });
    }



    setLeadAssignData(leadDetail, detail?) {
        this.disableDropDown = false;
        this.projectService.fetchCategory({}).then(data => {
            if (data && data.length > 0) {
                data.forEach(element => {
                    if (element.linkRewrite == this.audience.category) {
                        this.leadStatusList = element.leadStatusList;
                    }
                });

                if (this.leadStatusList) {
                    this.leadStatusList.forEach(elems => {
                        if (elems.roles) {
                            if (elems.roles.length > 0) {
                                elems.showState = false;
                                elems.roles.forEach(element => {
                                    if (element == this.projectService.projectDetails.userRoleId) {
                                        elems.showState = true
                                    }
                                    if (this.projectService.projectDetails.userRoleId == 1) {
                                        elems.showState = true
                                    }
                                });
                            }
                            else if (elems.roles.length == 0) {
                                elems.showState = true
                            }
                        }
                        if (elems.roles == undefined) {
                            elems.showState = true
                        }
                    })
                }
                this.leadStatusList.forEach(element => {
                    if (element.stageName == this.leadStatusDetail.paramValue) {
                        this.audience.audienceDynamicDataList.forEach(elementFilter => {
                            if (elementFilter.paramKey == 'tag') {
                                let taglist = element.tags ? element.tags.split(',') : [];
                                taglist.filter((p) => {
                                    return p != null;
                                })
                                elementFilter.paramDefaultValueDTO = [];
                                taglist.forEach(element => {
                                    elementFilter.paramDefaultValueDTO.push({ paramValue: element.trim() });
                                });
                            }
                        });
                    }
                    if (element.stageName == leadDetail.paramValue) {
                        this.setSiteFormValidators(element.stageType);
                        if (element.userList) {
                            this.disableDropDown = true;
                        }
                    }
                });
                var setFollow;
                if (detail.lead_followdate != null) {
                    if (detail.lead_followdate.indexOf('T') > -1) {
                        var followtime = detail.lead_followdate.split('T')[1];
                        var dateForm = detail.lead_followdate.split('T')[0];
                    } else {
                        var followtime = detail.lead_followdate.split(' ')[1];
                        var dateForm = detail.lead_followdate.split(' ')[0];
                    }
                    if (followtime.indexOf('.000Z') > -1) {
                        followtime = followtime.split('.000Z')[0]
                    }
                    setFollow = moment(followtime + ".000+05:30", 'H:mm:ss').toDate();
                    this.siteForm.patchValue({
                        status: detail.lead_status,
                        date: dateForm != undefined ? new Date(dateForm) : null,
                        time: setFollow
                    });
                }
            }
        });




    }

    fetchLeadStatus() {
        let parentMenu;
        if (this.queryParamsFromMenu) {
            parentMenu = this.queryParamsFromMenu['params'].dynamicparam;
        }
        this.projectService.fetchCategory({}).then(data => {
            if (data && data.length > 0) {
                data.forEach(element => {
                    if (element.linkRewrite == this.audience.category) {
                        this.leadStatusList = [];
                        this.leadStatusList = element.leadStatusList;
                    }
                });
                if (this.leadStatusList) {
                    this.leadStatusList.forEach(elems => {
                        if (elems.roles) {
                            if (elems.roles.length > 0) {
                                elems.showState = false;

                                elems.roles.forEach(element => {

                                    if (element == this.projectService.projectDetails.userRoleId) {
                                        elems.showState = true
                                    }

                                    if (this.projectService.projectDetails.userRoleId == 1) {
                                        elems.showState = true
                                    }
                                });
                            }
                            else if (elems.roles.length == 0) {
                                elems.showState = true
                            }
                        }
                        if (elems.roles == undefined) {
                            elems.showState = true
                        }
                    })
                }
            }
        })
    }

    shareAction() {
        if (this.userListCache) {
            this.userListToShare = this.userListCache;
            return
        }
        if (this.audience.params.share_id && this.audience.params.share_id.length > 0) {
            this.audience.params.share_id.forEach(element => {
                let checkUserByActive = this.sharedUserList.find(x => x.id == element);
                if (checkUserByActive) {
                    this.userListToShare.push(element);
                    this.userSelectForShareLead.push(element);
                }
            });
        }
    }

    userSelectForShare(event) {
        this.userSelectForShareLead = event
    }

    userSubmitForShareLead() {
        if (this.audience.params.share_id && this.audience.params.share_id.length == 0) {
            if (this.userSelectForShareLead.length == 0) {
                this.toastr.error('Please select user');
                return
            }
        } else if (this.audience.params.share_id == undefined) {
            if (this.userSelectForShareLead.length == 0) {
                this.toastr.error('Please select user');
                return
            }
        }

        let postData = { audMasterId: this.audience.audience_id, encryptAudienceId: this.audience._id, userList: this.userSelectForShareLead }
        this.reportService.shareLead(postData, this.queryParamsFromMenu.params.dynamicparam).subscribe(data => {
            if (data.status) {
                this.toastr.success('Shared successfully');
                this.userListCache = this.userSelectForShareLead;
                this.shareList = false;
            } else {
                this.toastr.error(data.error_msgs);
            }
        });
    }

    async fetchUserList() {
        this.sharedUserList = []
        let getTelecallerFromMaster = {
            id: '',
            userName: '',
            userProfileName: ''
        };
        await this.projectService.getUserFromMaster().then(data => {
            this.usersFromMaster = data;
            let userData = Object.keys(data);
            let catStore = [];
            this.projectService.fetchCategory({}).then(catData => {
                if (catData) {
                    catData.forEach(element => {
                        catStore.push(element)
                        if (this.audience.category == element.linkRewrite) {

                            userData.forEach(elem => {
                                if (data[elem].status == 'ACTIVE') {
                                    this.masterUsers.push(data[elem]);
                                    if (element.userList && element.userList.length > 0) {
                                        let checkUserByCat = element.userList.find(x => x == data[elem].id);
                                        if (checkUserByCat) {
                                            this.telecallerList.push({
                                                id: data[elem].id,
                                                userName: data[elem].userName,
                                                userProfileName: data[elem].userName,
                                                userRoleId: data[elem].userRoleId
                                            })
                                            this.sharedUserList.push({
                                                id: data[elem].id,
                                                userName: data[elem].userName,
                                                userProfileName: data[elem].userName,
                                                userRoleId: data[elem].userRoleId
                                            })
                                        }
                                    } else {
                                        this.telecallerList.push({
                                            id: data[elem].id,
                                            userName: data[elem].userName,
                                            userProfileName: data[elem].userName,
                                            userRoleId: data[elem].userRoleId
                                        })
                                        this.sharedUserList.push({
                                            id: data[elem].id,
                                            userName: data[elem].userName,
                                            userProfileName: data[elem].userName,
                                            userRoleId: data[elem].userRoleId
                                        })
                                    }

                                    let checkToPushTelCaller = this.telecallerList.find(x => x.id == this.leadAssignData.lead_telecaller_id);
                                    if (checkToPushTelCaller == undefined && data[elem].id == this.leadAssignData.lead_telecaller_id) {
                                        this.telecallerList.push({
                                            id: data[elem].id,
                                            userName: data[elem].userName,
                                            userProfileName: data[elem].userName,
                                            userRoleId: data[elem].userRoleId
                                        })
                                    }

                                    if (data[elem].userRoleId == 1) {
                                        let checkToPushSuperAdmin = this.telecallerList.find(x => x.id == data[elem].id);
                                        if (checkToPushSuperAdmin == undefined) {
                                            this.telecallerList.push({
                                                id: data[elem].id,
                                                userName: data[elem].userName,
                                                userProfileName: data[elem].userName,
                                                userRoleId: data[elem].userRoleId
                                            })
                                        }
                                    }


                                }
                            });
                            let getForUserRoles = this.telecallerList.find(x => x.id == this.leadAssignData.lead_telecaller_id);
                            if (getForUserRoles && getForUserRoles.userRoleId == 1) {
                                this.userRoleLabel = 'Super Admin';
                            } else if (getForUserRoles) {
                                let checkUserRoleId = this.rolesList.find(x => x.id == getForUserRoles.userRoleId);
                                if (checkUserRoleId) {
                                    this.userRoleLabel = checkUserRoleId.name;
                                }
                            }
                        }
                    });
                }
            })

        });
        this.siteForm.patchValue({
            telecallerId: this.leadAssignData.lead_telecaller_id
        });
        // localStorage.removeItem("leadTelecaller");
        // this.telecallerList = [];
        // let findUserForParent:boolean = false;
        // let userData = this.authenticationService.getUserData();
        // this.userService.getUsersData().then( data => {            
        //     if (data != undefined) {                
        //         this.telecallerList= data;   
        //     }
        //     let reportingChild = [];
        // let superAdmin_admin = [];
        // if(this.userRole != 1){
        //   if(userData.roleTypeId == 2){
        //   this.telecallerList.forEach(elem=>{
        //     if(elem.userList){
        //       let checkUserId = elem.userList ? elem.userList.find(x=>x == userData.userId):null;
        //       if(checkUserId){
        //         console.log(checkUserId);
        //         findUserForParent = true;
        //         elem.userList.forEach(element=>{
        //             let checkOtherUserRole = this.telecallerList.find(x=>x.id == element);
        //             if(checkOtherUserRole){                            
        //                     // if(checkOtherUserRole.userRoleName == userData.userRoleName){
        //                         let checkToPush = reportingChild.find(x=>x == element);
        //                         if(checkToPush == undefined){
        //                         reportingChild.push(element);
        //                         }
        //                         let checkToPushElem = reportingChild.find(x=>x == elem.id);
        //                         if(checkToPushElem == undefined){
        //                         reportingChild.push(elem.id);
        //                         }
        //                     // }
        //             }
        //         })
        //       } else{
        //       }
        //     }
        //   });
        // } else{
        //   this.telecallerList.forEach(elem=>{                
        //     let checkUserId = elem.id == userData.userId;
        //     let checkUserList = elem.userList ? elem.userList.find(x=>x == userData.userId) : null;
        //     if(checkUserList){
        //         findUserForParent = true;
        //       elem.userList.forEach(element=>{
        //         let checkOtherUserRole = this.telecallerList.find(x=>x.id == element);
        //             if(checkOtherUserRole){
        //                 if(checkOtherUserRole.userRoleName == userData.userRoleName){
        //                     let checkToPush = reportingChild.find(x=>x == element);
        //                     if(checkToPush == undefined){
        //                     reportingChild.push(element);
        //                     }
        //                     let checkToPushElem = reportingChild.find(x=>x == elem.id);
        //                     if(checkToPushElem == undefined){
        //                     reportingChild.push(elem.id);
        //                     }
        //                 }
        //             }
        //       })
        //     } 
        //     if(checkUserId && elem.userList){
        //       elem.userList.forEach(element=>{
        //         reportingChild.push(element);
        //       })
        //     }
        //   });
        // }
        // if(!findUserForParent){                
        //     this.projectService.getUserFromMaster().then(data=>{
        //         let userObj = Object.keys(data);
        //         userObj.forEach(elem=>{
        //             let checkSuperAdmin = superAdmin_admin.find(x=>x.id == data[elem].id);
        //             let checkInAllUser = this.telecallerList.find(x=>x.id == data[elem].id);
        //             if(data[elem].userRoleId == 1){
        //                 superAdmin_admin.push({
        //                     id:data[elem].id,
        //                     userName:data[elem].userName,
        //                     userProfileName:data[elem].userName
        //                     })
        //                 this.telecallerList.push({
        //                 id:data[elem].id,
        //                 userName:data[elem].userName,
        //                 userProfileName:data[elem].userName
        //                 })
        //             } else if(data[elem].level == 1 && data[elem].status == 'ACTIVE'){
        //                 console.log(data[elem]);
        //                 superAdmin_admin.push({
        //                     id:data[elem].id,
        //                     userName:data[elem].userName,
        //                     userProfileName:data[elem].userName
        //                 })
        //             }
        //         });                    
        //     })
        // } else{
        //     this.projectService.getUserFromMaster().then(data=>{
        //         let userObj = Object.keys(data);
        //         userObj.forEach(elem=>{
        //             if(data[elem].userRoleId == 1){
        //                 let checkSuperAdmin = superAdmin_admin.find(x=>x.id == data[elem].id);
        //                 let checkInAllUser = this.telecallerList.find(x=>x.id == data[elem].id);
        //                 if(checkSuperAdmin == undefined && checkInAllUser == undefined){
        //                     superAdmin_admin.push({
        //                     id:data[elem].id,
        //                     userName:data[elem].userName,
        //                     userProfileName:data[elem].userName
        //                     });

        //                     this.telecallerList.push({
        //                     id:data[elem].id,
        //                     userName:data[elem].userName,
        //                     userProfileName:data[elem].userName
        //                     })
        //                 }
        //             }
        //         });                    
        //     })
        // }
        // }


        // let finalTelecallerList = []
        // if(reportingChild.length > 0){
        //     finalTelecallerList = this.telecallerList.filter(p=>{
        //         return reportingChild.find(x=>x == p.id) && p.id != userData.userId;
        //     });
        // }

        // if(finalTelecallerList.length > 0 || superAdmin_admin.length > 0){
        //     this.telecallerList = this.telecallerList.filter(p=>{
        //         return finalTelecallerList.find(x=>x.id == p.id) || superAdmin_admin.find(x=>x.id == p.id);
        //     });
        // }



        //let setTelecaller = this.telecallerList.find(x=>x.id == this.leadAssignData.lead_telecaller_id);

        // setTimeout(() => {
        // let checkMyTelName = this.telecallerList.find(x=>x.id == getTelecallerFromMaster.id);
        // if(checkMyTelName == undefined){
        //     this.telecallerList.push(getTelecallerFromMaster);
        // }

        // let checkUserInList = this.telecallerList.find(x=>x.id == this.authenticationService.getUserId());
        // if(checkUserInList == undefined){
        // this.telecallerList.push({
        //     id:this.authenticationService.getUserId(),
        //     userName:this.authenticationService.getUserData().userName,
        //     userProfileName:this.authenticationService.getUserData().userName
        // });
        // }

        // let getTelecaller = this.telecallerList.find(x=>x.id == this.leadAssignData.lead_telecaller_id);      
        // if(getTelecaller){                    
        // localStorage.setItem("leadTelecaller",JSON.stringify(getTelecaller));   
        // }
        // let setTelecaller = JSON.parse(localStorage.getItem("leadTelecaller"));
        // if(setTelecaller){  
        // let checkAvailUser = this.telecallerList.find(x=>x.id == setTelecaller.id);
        // if(checkAvailUser == undefined){
        //     this.telecallerList.push(setTelecaller);      
        // }        
        // this.siteForm.patchValue({
        //     telecallerId: this.leadAssignData.lead_telecaller_id
        // });
        // let telecallerSet = new Set(this.telecallerList.map(item=>item));                    
        // this.telecallerList = Array.from(telecallerSet);
        // }
        // }, 1000);

        // });
    }


    getparemType(param, index) {
        this.invalidFile = false;
        if (param.paramDatatype != 'File') {
            if (typeof (param.paramValue) == 'string') {
                if (param.paramValue.includes(',')) {
                    param.paramValue = param.paramValue.split(',');
                }
            }
        }

        this.projectService.fetchAttributeList().then(data => {
            var list = this.projectService.filterAttributeList();
            var type
            list.forEach(elems => {
                if (elems.paramKey == param.paramKey && param.paramKey != 'tag') {
                    this.audience.audienceDynamicDataList[index].paramDefaultValueDTO = elems.paramDefaultValueDTO;
                    if (this.audience.audienceDynamicDataList[index].paramDefaultValueDTO && this.audience.audienceDynamicDataList[index].paramDefaultValueDTO.length > 0) {
                        this.audience.audienceDynamicDataList[index].paramDefaultValueDTO.forEach(values => {
                            values.paramValuetemp = this.htmlDecode(values.paramValue);
                        });
                    }
                    type = elems.paramDatatype
                }
            })
            // type = list.find(x => x.id == param.id).paramDatatype;
            this.audience.audienceDynamicDataList[index].paramDatatype = type;
            if (type == 'Date') {
                this.audience.audienceDynamicDataList[index].paramValue = new Date(this.audience.audienceDynamicDataList[index].paramValue);
            }

            // if(param.paramDefaultValueDTO == undefined)
            // {
            //     param.paramValue = '';
            // }       
        });
    }

    getOtherParameters() {
        this.otherParameters = [];
        this.projectService.fetchAttributeList().then(data => {
            var list = this.projectService.filterAttributeList(['all']);
            list.sort((a, b) => (a.paramName > b.paramName) ? 1 : -1);
            if (this.audience.audienceDynamicDataList != null) {
                var otherParameters = list.filter(x => !this.audience.audienceDynamicDataList.filter(y => y.paramKey === x.paramKey).length);
                let filterStoreByCat = [];
                let systemDefineAttr = [];
                otherParameters.forEach(elem => {
                    if (elem.paramOwner == 0 && elem.pluginName != 'static') {
                        if (elem.category) {
                            let catToArr = elem.category.split(',');
                            catToArr = catToArr.filter(p => {
                                return p != null && p != "";
                            });

                            let checkByCat = catToArr.find(x => x == this.category);
                            if (checkByCat) {
                                filterStoreByCat.push(elem);
                            }
                        }
                    }
                    if (elem.paramOwner == 1 && elem.pluginName != 'static') {
                        systemDefineAttr.push(elem)
                    }
                    if (elem.pluginName == 'static') {
                        systemDefineAttr.push(elem);
                    }
                });



                if (filterStoreByCat.length > 0) {
                    otherParameters = filterStoreByCat;
                    systemDefineAttr.forEach(elem => {
                        otherParameters.push(elem)
                    })
                }
                if (filterStoreByCat.length == 0) {
                    otherParameters = []
                    systemDefineAttr.forEach(elem => {
                        otherParameters.push(elem)
                    })
                }

                var otherValueChangeParameters = [];
                this.otherParameters = [];
                otherValueChangeParameters = otherParameters.filter(y => y.paramValueChange === "ACTIVE");
                otherValueChangeParameters.forEach(el => {
                    if (el.paramDefaultValueDTO) {
                        el.paramDefaultValueDTO.forEach(element => {
                            // element.paramValue = this.HtmlToPlaintext.transform(element.paramValue);
                        });
                    }
                    let findAttrByUser;
                    let userRoleId = this.authenticationService.getUserRole();
                    let userRole = el.userRole != "" && el.userRole != null ? el.userRole.split(',') : "";
                    if (Array.isArray(userRole)) {
                        userRole = userRole.filter(p => {
                            return p != null;
                        });
                        findAttrByUser = userRole.find(x => x == userRoleId);
                    }
                    if (findAttrByUser) {
                        if (el.paramMandatory === "ACTIVE") {
                            el.paramValue = '';
                            el.editParam = true;
                            this.audience.audienceDynamicDataList.push(el);

                        }
                        else {
                            el.paramValue = '';
                            if (el.paramDefaultValueDTO) {
                                el.paramValue = []
                            }
                            this.otherParameters.push(el);
                        }
                    } else {
                        if (el.paramMandatory === "ACTIVE" && userRole == "") {
                            el.paramValue = '';
                            el.editParam = true;
                            this.audience.audienceDynamicDataList.push(el);
                        }
                        else {
                            el.paramValue = '';
                            if (el.paramDefaultValueDTO) {
                                el.paramValue = []
                            }
                            this.otherParameters.push(el);
                        }
                    }

                });

                
                let getLocalityParam = list.find(x=>x.paramKey == 'locality');
                let findLocalityInLeadDetails = this.audience.audienceDynamicDataList.find(x=>x.paramKey == 'locality');
                if(getLocalityParam && findLocalityInLeadDetails == undefined){
                    console.log(getLocalityParam)
                    let checkInOtherParams = this.otherParameters.find(x=>x.paramKey == 'locality');
                    if(checkInOtherParams == undefined){
                        if(getLocalityParam.pluginName == "dynamic" && getLocalityParam.paramOwner == 1){
                            this.otherParameters.push(getLocalityParam)
                        }
                    }
                }

                this.otherParameters.sort((a, b) => {
                    if (a.paramName.toLocaleLowerCase() < b.paramName.toLocaleLowerCase()) {
                        return -1;
                    }
                    if (a.paramName.toLocaleLowerCase() > b.paramName.toLocaleLowerCase()) {
                        return 1;
                    }
                });
                this.otherParameters.forEach((elem,index) => {
                    elem.paramValue = "";
                    if (elem.paramDefaultValueDTO && elem.paramDefaultValueDTO.length > 0) {
                        console.log(elem)
                        elem.paramDefaultValueDTO.forEach(defaultVal => {
                            defaultVal.paramValuetemp = this.htmlDecode(defaultVal.paramValue)
                        });
                    }
                    
                    if(elem.paramKey == 'pincode'){
                        let  pincodeindex = this.otherParameters[index];
                        this.otherParameters[this.otherParameters.length+1] = pincodeindex;
                        delete this.otherParameters[index];
                    }
                    if(elem.paramKey == 'city'){
                        let  cityIndex = this.otherParameters[index];
                        this.otherParameters[this.otherParameters.length+1] = cityIndex;
                        delete this.otherParameters[index]
                    }
                    if(elem.paramKey == 'state'){
                        let  stateIndex = this.otherParameters[index];
                        this.otherParameters[this.otherParameters.length+1] = stateIndex;
                        delete this.otherParameters[index]
                    }
                    if(elem.paramKey == 'country'){
                        let  countryIndex = this.otherParameters[index];
                        this.otherParameters[this.otherParameters.length+1] = countryIndex;
                        delete this.otherParameters[index]
                    }
                    if(elem.paramKey == 'locality'){
                        let  localityIndex = this.otherParameters[index];
                        localityIndex.showInput = true
                        this.otherParameters[this.otherParameters.length+1] = localityIndex;
                        delete this.otherParameters[index]
                    }
                });

                this.otherParameters = this.otherParameters.filter(p=>{
                    return p != null;
                })

                

                this.audience.audienceDynamicDataList.forEach(elem => {
                    if (elem.paramDefaultValueDTO && elem.paramDefaultValueDTO.length > 0) {
                        elem.paramDefaultValueDTO.forEach(defaultVal => {
                            defaultVal.paramValuetemp = this.htmlDecode(defaultVal.paramValue)
                        });
                    }

                    if (elem.fileNameOfFile) {
                        delete elem.fileNameOfFile;
                    }
                    if (typeof (elem.paramValue) == 'string') {
                        if (elem.paramValue.includes('href') == true) {
                            elem.fileTypeAttrLink = 'file'
                        }
                    }

                    if (elem.editParam == false && elem.paramValue === '') {
                        elem.invoice_quotation_hide = true;
                    }

                })
                if (this.otherParameters.length == 0 && this.tabSetDetails != undefined) {
                    this.tabSetDetails.tabs[0].active = true
                }
                
            }
        });

    }

    getPinCodeDetails(pincode){

        this.otherParameters.forEach((elem,index) => {
            if(elem.paramKey == 'city'){
               elem.paramValue = "";
           }
           if(elem.paramKey == 'state'){
               elem.paramValue = "";
           }
           if(elem.paramKey == 'country'){
               elem.paramValue = "";
           }
           if(elem.paramKey == 'locality'){
               elem.paramDefaultValueDTO = [];
               elem.showInput = true
               elem.paramValue = "";
           }
       });

        this.projectService.getDetailsByPinCode(pincode).subscribe(data=>{
            this.pincodeResponse = data
            console.log(this.pincodeResponse)
            if(this.pincodeResponse.success){
                this.otherParameters.forEach((elem,index) => {
                     if(elem.paramKey == 'city'){
                        elem.paramValue = this.pincodeResponse.postcode_details.city;
                    }
                    if(elem.paramKey == 'state'){
                        elem.paramValue = this.pincodeResponse.postcode_details.state;
                    }
                    if(elem.paramKey == 'country'){
                        elem.paramValue = this.pincodeResponse.postcode_details.country;
                    }
                    if(elem.paramKey == 'locality'){
                        console.log(elem)
                        elem.showInput = false;
                        this.pincodeResponse.postcode_details.locality.forEach(locals => {
                            elem.paramDefaultValueDTO.push({paramValue:locals})
                        });
                        // elem.paramDefaultValueDTO = this.pincodeResponse.postcode_details.locality;
                    }
                });
            } else{

            }
        })
    }

    emptyFileVal(item) {
        delete item.fileNameOfFile;
        item.paramValue = '';
    }

    custmAttrFIleUpload(event, item) {
        const file = event.target.files[0];
        let checkFileSize = file.size / 1024 / 1024;
        if (checkFileSize > 20) {
            this.toastr.error('File size must lower then 20 MB');
            return
        }
        item.fileNameOfFile = file.name;
        // if(item.fileNameOfFile.length > 30)
        // {
        //     item.fileNameOfFile = item.fileNameOfFile.substring(0,30);
        //     item.fileNameOfFile = item.fileNameOfFile + '...';
        // }
        let fileExt = event.target.value.split('.').pop();
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            this.base64DataOfFileType = reader.result.toString().replace(/^data:(.*,)?/, '');
            this.base64DataOfFileType = this.base64DataOfFileType + '~' + item.fileNameOfFile;
            item.paramValue = this.base64DataOfFileType;
            event.target.value = null;
        };
    }

    addAudEmail() {
        this.showEmailInput = true;
    }

    addAudPhone() {
        this.showPhoneInput = true;
    }

    addAudName() {
        this.audNameInput = this.audience.name ? this.audience.name : '';
        this.showNameInput = true;
    }

    saveCustomField(data, type) {
        let parentMenu;
        if (this.queryParamsFromMenu) {
            parentMenu = this.queryParamsFromMenu['params'].dynamicparam;
        }
        let postData = {
            projectKey: this.projectService.projectDetails.projectKey,
            encryptAudienceId: this.audience._id,
            email: '',
            mobile: '',
            name: ''
        }
        if (type == 'name') {
            if (data == '') {
                this.toastr.error("Name can't be blank");
                return
            }
            delete postData.mobile;
            delete postData.email;
            postData.name = data;
        }
        if (type == 'email') {
            var email_reg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
            if (email_reg.test(data) == false) {
                this.toastr.error('Please enter valid email');
                return;
            }

            delete postData.mobile;
            delete postData.name;
            postData.email = data;
        }
        if (type == 'mobile') {
            delete postData.name;
            delete postData.email;
            postData.mobile = data;
        }

        this.projectService.registerAudienceData(parentMenu, postData).subscribe(data => {
            if (data.status) {
                this.toastr.success(data.data, 'Success');
                this.showEmailInput = false;
                this.showPhoneInput = false;
                this.showNameInput = false;
                this.selectedStatus = undefined;
                this.siteForm.reset();
                this.getProfile();
            }
            else {
                this.toastr.error(data.error_msgs, 'Error');
            }
        }, (error) => {
            console.log(error);
        });
    }

    updateAudience(param, index) {
        let parentMenu;
        if (this.queryParamsFromMenu) {
            parentMenu = this.queryParamsFromMenu['params'].dynamicparam;
        }
        if (param.paramDatatype == 'File') {
            if (param.paramValue.includes('href="') == true) {
                this.toastr.error(param.paramName + ' is empty');
                this.invalidFile = true
                return
            }
        }

        if (param.paramDatatype == 'Email') {
            let regex = /^[A-Za-z0-9]+[A-Za-z0-9._!#$%&'*+-/=?^_`{|}~]+@([A-Za-z0-9-]+[.]){1,2}[A-Za-z.]{2,10}$/;
            if (regex.test(param.paramValue) == false) {
                this.toastr.error("Please enter valid email");
                this.spinner.hide();
                this.isSubmit = false;
                return;
            }
        } else if (param.paramDatatype == 'Mobile') {
            let regex = /^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$/;
            if (regex.test(param.paramValue) == false) {
                this.toastr.error("Please enter valid phone number");
                this.spinner.hide();
                this.isSubmit = false;
                return;
            }
        }

        if (param.paramValue == undefined || param.paramValue == '') {
            this.toastr.error('Field is required', 'Error');
            return;
        }

        if (param.paramValue.length > 0) {
            param.paramValue = param.paramValue.toString();
        }


        if (param.paramDatatype == "Date") {
            param.paramValue = this.datePipe.transform(param.paramValue, "yyyy-MM-dd HH:mm:ss");
        }
        let postData = {
            projectKey: this.projectService.projectDetails.projectKey,
            audienceId: this.audience.audience_id,
            encryptAudienceId: this.audience._id,
            paramList: [
                { paramKey: param.paramKey, paramValue: param.paramValue }
            ]
        }
        this.projectService.registerAudienceData(parentMenu, postData).subscribe(data => {
            if (data.status) {
                this.toastr.success(data.data, 'Success');
                this.audience.audienceDynamicDataList.forEach(element => {
                    if (element.fileNameOfFile) {
                        //delete element.fileNameOfFile;
                        element.paramValue = element.fileNameOfFile;
                        element.tempFileName = true;
                    }
                });
                //this.getProfile();
                this.nextId = undefined;
                //this.getTimeline();        
            } else {
                this.toastr.error(data.error_msgs, 'Error');
                this.audience.audienceDynamicDataList.forEach(element => {
                    if (element.fileNameOfFile) {
                        delete element.fileNameOfFile;
                        element.paramValue = '';
                    }
                });
                this.audience.audienceDynamicDataList[index].editParam = true;
                return
            }
            this.audience.audienceDynamicDataList[index].editParam = false;
        });
    }

    appendParameter(param, index) {
        param.paramValue = '';
        this.isOtherParametersShow = false;
        this.audience.audienceDynamicDataList.push(param);
        this.otherParameters.splice(index, 1);
        param.editParam = true;
        setTimeout(() => {
            this.scrollToBottom();
        }, 200);
    }

    private scrollToBottom(): void {
        var scrollblock;
        if (this.otherParameters.length % 2 == 1)
            scrollblock = this.scrollblockLeft.nativeElement;
        else
            scrollblock = this.scrollblockRight.nativeElement;

        scrollblock.scroll({
            top: scrollblock.scrollHeight,
            left: 0,
            behavior: 'smooth'
        });
    }


    closeWhatsAppWindow() {
        this.showWhatsAppWindow = false;
    }

    getRepeatLeads() {
        if (this.repeatLeadData) {
            return;
        }
        this.spinner.show();
        this.projectService.fetchRepeatLeads({ email: this.audience.email, phone: this.audience.mobile, id: this.audience._id }, this.queryParamsFromMenu['params'].dynamicparam).subscribe(data => {

            if (data.status) {
                this.repeatLeadData = data.data;
                if (this.repeatLeadData.length > 0) {
                    this.repeatLeadData.forEach(element => {
                        if (element.source.length > 10) {
                            element.source = element.source.substring(0, 10);
                            element.source = element.source + '...';
                        }
                    });
                    this.showDetailRepeatLead(this.repeatLeadData[0].id);
                }
            }
            else {
                this.toastr.error(data.error_msgs, 'Error');
            }
        })
    }

    showRepeatLeadTab() {
        this.mainDetailsTabs.tabs[5].active = true
    }

    showDetailRepeatLead(id) {
        let parentMenu;
        if (this.queryParamsFromMenu) {
            parentMenu = this.queryParamsFromMenu['params'].dynamicparam;
        }

        if (this.repeatLeadDynamicListStore.size > 0) {

            if (this.repeatLeadDynamicListStore.has(id)) {
                this.repeatLeadDynamicList = this.repeatLeadDynamicListStore.get(id);
                this.spinner.hide();
                return;
            }

        }


        this.reportService.fetchIndividualAudienceData({ _id: id }).subscribe(data => {
            if (data.status) {
                this.repeatLeadDynamicList = data.data.audienceMasterDTO;

                let audienceDynamicDataList = []
                //this.audience.audienceDynamicDataMap.sort((a, b) => (a.paramName > b.paramName) ? 1 : -1);

                var list = this.projectService.filterAttributeList(['all']);
                list.sort((a, b) => (a.paramName > b.paramName) ? 1 : -1);
                let dynamicKeys = Object.keys(data.data.audienceMasterDTO.params);
                dynamicKeys.forEach(element => {
                    let keysInsertInDynamicList = list.find(x => x.paramKey == element);
                    if (keysInsertInDynamicList != undefined && data.data.audienceMasterDTO.params[element]) {
                        audienceDynamicDataList.push({
                            paramName: keysInsertInDynamicList.paramName,
                            paramMandatory: keysInsertInDynamicList.paramMandatory,
                            paramCategory: keysInsertInDynamicList.paramCategory,
                            paramValueChange: keysInsertInDynamicList.paramValueChange,
                            paramVisible: keysInsertInDynamicList.paramVisible,
                            paramOwner: keysInsertInDynamicList.paramOwner,
                            paramEditable: keysInsertInDynamicList.paramEditable,
                            paramDatatype: keysInsertInDynamicList.paramDatatype,
                            pluginName: keysInsertInDynamicList.pluginName,
                            editParam: false,
                            paramValue: this.htmlDecode(data.data.audienceMasterDTO.params[element]),
                            paramKey: element
                        })
                    }
                });

                this.repeatLeadDynamicList = audienceDynamicDataList;
                this.repeatLeadDynamicList.forEach(element => {
                    if (element.paramDatatype == 'Date') {
                        if (element.paramValue.includes('000Z') == true) {
                            element.paramValue = element.paramValue.split('.000Z')[0];
                            element.paramValue = element.paramValue.split('T').join(' ');
                        }
                    }
                    if (typeof (element.paramValue) == 'string') {
                        if (element.paramValue.includes('http:') == true || element.paramValue.includes('https:') == true) {
                            let findInValidStr = element.paramValue.substring(0, 10);
                            if (findInValidStr.includes('http:') == true || findInValidStr.includes('https:') == true) {
                                this.documentLocker.push({ 'data': [element.paramName, element.paramValue] });
                                // element.paramValue = '<a href="'+ element.paramValue +'" target="_blank">Click to View</a>';
                                if (element.paramValue.includes('download-invoice') == true || element.paramValue.includes('download-quotation') == true) {
                                    element.invoicepdf = true
                                }
                                element.fileLink = true;
                            }
                        }
                    }
                });

                this.repeatLeadDynamicListStore.set(data.data.audienceMasterDTO._id, this.repeatLeadDynamicList)
                this.spinner.hide();
                return;
            }
            else {
                this.spinner.hide();
                this.toastr.error(data.error_msgs, 'Error');
            }
        });
    }

    getIvrcallLogs(){
        if(this.ivrCallLogs){
            return
        }
        this.reportService.fetchIvrCallLog({userNumber:this.audience.mobile}).subscribe(data=>{
            if(data.status){
                this.ivrCallLogs = data.data;
            } else{
                this.toastr.error(data.error_msgs)
            }
            
        })
    }

    getcallLogs() {
        let userData = this.authenticationService.getUserData();
        if (this.callLogs != undefined) return;
        if (this.audience.mobile == undefined || this.audience.mobile == null) return;
        this.callLogs = [];
        let roleTypeId;
        if (userData.userRoleId == 1) {
            roleTypeId = 0;
        } else {
            roleTypeId = this.authenticationService.getUserRoleType()
        }
        this.reportService.fetchIndividualAudienceCallLog({ mobile: this.audience.mobile, roleTypeId: roleTypeId }).subscribe(data => {
            if (data.status) {
                this.userService.getUsersData().then(users => {
                    this.callDataCounter = data.data.count;
                    this.callLogs = data.data.list;
                    this.callLogs.forEach(element => {
                        element.callDate = element.callDate.split('.')[0];
                        element.callDate = element.callDate.replace(/\s/g, 'T');
                    });
                    var subUsers = JSON.parse(JSON.stringify(users));
                    if (this.userRole == 1) {
                        subUsers.push({
                            id: this.authenticationService.getUserId(),
                            userName: this.authenticationService.getUserData().userName,
                            userProfileName: this.authenticationService.getUserData().userName
                        });
                    };
                    this.callLogs.forEach(element => {
                        var telecaller = subUsers.find(x => x.id == element.userId);
                        element.telecaller = telecaller != undefined ? telecaller.userProfileName : '';
                        element.durationMin = this.getTimeInMin(element.duration);
                    });
                });
            } else {
                this.toastr.error(data.error_msgs, 'Error');
            }
        })
    }

    goToCountFilter(data) {
        const link = this.router.serializeUrl(this.router.createUrlTree(['admin/lead/' + this.queryParamsFromMenu['params'].dynamicparam], { queryParams: { filterbycount: data } }));
        window.open(link, '_blank');
    }

    getTimeInMin(value: number): string {
        const minutes: number = Math.floor(value / 60);
        return minutes + '.' + (value - minutes * 60) + ' Min';
    }

    getEmailDetails(id, value, type, other?) {


        var phone = value;
        if (type == 'whatsapp') {
            if (other == 'india') {
                phone = this.phonePipe.transform(value);
                if (phone == null) {
                    phone = value
                    phone = phone.replace(/[^a-zA-Z0-9]/g, '');
                    //     this.toastr.error('Invalid phone number');
                    //  return
                } else {
                    phone = '91' + phone;
                }
            }
            window.open('https://wa.me/' + phone, '_blank').focus();
        }



        // this.campaignService.checkConfigurationForCampaign({ campType: type, isAndroid: null, isIos: null, isWeb: null },this.whatsAppApiUrl).subscribe( data => {
        //     var phone = value;
        //     if(other == 'india'){
        //         phone = this.phonePipe.transform(value);
        //         if(phone == null)
        //         {
        //             this.toastr.error('Invalid phone number');
        //             return
        //         }                
        //     }
        //     if (data.status) {
        //         if(type=='whatsapp'){
        //           this.whatsappMsgData = {
        //               phone : phone,
        //               smsText: null
        //           };
        //           this.showWhatsAppWindow = false;
        //          // this.openModal(this.sendWhatsappMsg);
        //         }
        //     } else {
        //         if(type=='whatsapp'){
        //           if(other == 'india'){
        //             phone = '91' + phone;
        //           }                  
        //           window.open('https://wa.me/'+phone, '_blank').focus();
        //         } else{
        //           this.toastr.error(data.error_msgs.errorMessage,'Error');
        //         }
        //     }
        // });
    }

    sendWhatsapp() {
        //this.whatsappMsgData.smsText = this.whatsappMsgData.smsText != null ? this.whatsappMsgData.smsText : '';

        if (this.whatsappMsgData.smsText == '') {
            this.toastr.success(this.global.MessageRequired);
            return
        }
        this.whatsappMsgData.smsText = this.whatsappMsgData.smsText.replace(/^\s+\n|\s+$/g, '');
        this.configurationService.sendTestWhatsApp(this.whatsappMsgData).subscribe(data => {
            this.modalRef.hide();
            this.whatsappMsgData = {
                phone: '',
                smsText: null
            };
            if (data.status) {
                this.toastr.success(data.data);
            } else {
                this.toastr.error(data.error_msgs);
            }
        })

    }

}

