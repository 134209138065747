export interface LeadStatus{
    changeOlder?:any,
    id?:any,
    orderId?:any,
    projectId?:any,
    stageColour?:any,
    stageName?:any,
    stageType?:any,
    status?:any
}

export class PushTemplate {
    deepLink?: string
    dynamicContentList?: string
    id?: number
    message?: string
    notIcon?: string
    notImage?: string
    projectId?: string
    status?: string
    templateName?: string
    title?: string
}

export class AudienceFilter {
    id?:any;
    paramKey:any;
    paramValue:any;
    paramDatatype?:any;
    text1?:any;
    text2?:any;
    missedReport?:any
    reportType?:any;
    paramOprator?:any;
}