<main>
    <section class="inner-page">
        <div class="inner-page-header">
            <div *ngIf="selectedUserobject != undefined" class="container-fluid">
                <h4>
                    <a class="back-link" [routerLink]="['/admin/call-assist']"><img src="/assets/images/angle-left.svg"></a>
                    {{selectedUserobject.userProfileName}} Call Logs</h4>
            </div>
        </div>

        <div class="inner-page-body site-form">
            <div class="container-fluid">
                <div class="form-row mb-4 justify-content-between">
                    <div class="col-lg-4 col-md-5 d-flex col-sm-12 col-xs-12">
                        <div class="search-box">
                            <input trim="blur" class="form-control" name="search" placeholder="Search by phone" type="text" [(ngModel)]="searchText" autocomplete="off" (keyup.enter)="fetchCallLog()">
                            <button class="btn-search" (click)="fetchCallLog()" tabindex="3" type="submit"><div class="sr-only">Search</div></button>
                        </div>
                    </div>
                    <div class="col-lg-8 col-md-7 d-flex col-sm-12 col-xs-12 right_filters">
                        <div class="filter_box c-align">
                            <div class="d-flex">
                                <div>Club &nbsp; </div>
                                <div class="custom-control custom-switch swich-button">
                                    <input type="checkbox" name="CurrentPluginStatus" (change)="fetchCallLogClubData($event)"
                                        [(ngModel)]="clubData" class="custom-control-input" id="clubData" />
                                    <label class="custom-control-label" for="clubData"></label>
                                    <!-- <span class="by" [ngClass]="{ pink: !this.CurrentPlugin.status }">Disable</span> -->
                                </div>
                            </div>
                            <!-- <div class="d-flex">
                                <a (click)="goToMissRecording()" href="javascript:void(0)" class="btn btn-outline-gray">Missing Recording</a>
                            </div> -->
                            <div class="filterOpener">
                                <img src="assets/images/icons-filter.png" (click)="openFilter()">
                            </div>
                            <div class="missed_recording">
                                <img src="assets/images/icon-mic.png" (click)="goToMissRecording()" tooltip="Missed Recordings" [tooltipAnimation]="true" tooltipPlacement="top">
                            </div>
                        </div>

                        
                        <input type="text" class="form-control date-icon date_range_input" readonly autocomplete="off" ngxDaterangepickerMd [(ngModel)]="filterDateRange" [alwaysShowCalendars]="true" [locale]="{format: 'DD/MM/YYYY'}" [ranges]="ranges" [autoApply]="false" [opens]="'left'" [maxDate]="maxDate"
                            placeholder="Select Date Range" (datesUpdated)="fetchCallLogByDate()">
                    
                        </div>
                </div>
                <div class="row stats" *ngIf="callSummary != undefined && callSummary.length > 0">
                    <div class="col-13 col-4" *ngFor="let item of callSummary">
                        
                         <a (click)="pageNo=1;dataSize=20;searchByState!=''?filterByState(''):filterByState(item.state)" class="fetchStates"> 
                            <span class="closeBtnFilter" *ngIf="searchByState == item.state">
                                &times;
                            </span>
                         <!--   <a (click)="fetchCallLog(item.state)"> -->
                            <div class="box">
                                <h3 [ngClass]="item.state">{{item.state}}</h3>
                                <div class="number">{{item.count}}</div>
                                <span class="time" *ngIf="item.totalTime > 0"><span class="d-md-inline d-none">Duration:</span> {{item.durationMin}}</span>
                            </div>
                        </a>
                    </div>
                </div>
                <div class="table-responsive table-height-scroll table-reports">
                    <table class="table">
                        <tbody class="norecord-handling">
                            <tr>
                                <th>Sr. No.</th>
                                <th>Name</th>
                                <th>Phone No.</th>
                                <th>Duration</th>
                                <th>Date & Time</th>
                                <th class="min_width_100">Type</th>
                                <th class="min_width_100">Recording</th>

                            </tr>
                            <tr *ngFor="let item of callLogs | paginate: { itemsPerPage:dataSize, currentPage:pageNo,totalItems:totalDataSize}; let i = index">
                                <td>{{((pageNo - 1)*dataSize)+i+1}}</td>
                                <td><a href="javascript:void(0);" (click)="getAudienceData(item)">{{item.cloudName}}</a></td>
                                <!-- <td><a href="javascript:void(0);" (click)="goToDtlbyCat(item)" [routerLink]="['/admin/audience/view-audience/search/'+item.number]">{{item.number}}</a> <span *ngIf="clubData && item.count>1"> ({{item.count}})</span></td> -->
                                <td><a href="javascript:void(0);" (click)="getAudienceData(item)">{{item.number}}</a> <span *ngIf="clubData && item.count>1"> ({{item.count}})</span></td>
                                <td>{{item.durationMin}}</td>
                                <td>{{item.callDate | date:'yyyy-MM-dd, HH:mm'}}</td>
                                <td [ngClass]="item.state">{{item.state}}</td>
                                <td *ngIf="item.recordingUrl !='' && item.recordingUrl !=null" class="audioTd">
                                    <audio controls="controls">
                                        <source src={{item.recordingUrl}} type="audio/mpeg">
                                    </audio>
                                    <a class="rcrding_download" href="{{item.downloadURL}}" target="_blank"><span _ngcontent-serverapp-c29="" class="icon-download"></span></a>
                                </td>

                            </tr>
                            <tr *ngIf="callLogs.length == 0">
                                <td class="no-records" colspan="7">No Records Found</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div *ngIf="callLogs.length > 0" class="table-footer footer-gray">
                    <ng-container *ngIf="totalDataSize > 20">
                        <pagination-controls (pageChange)="dataForPaging($event)" maxSize="9" directionLinks="true" autoHide="true" class="pull-right" responsive="true" previousLabel="Previous" nextLabel="Next" screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
                            screenReaderCurrentLabel="You're on page">
                        </pagination-controls>
                    </ng-container>
                    <div class="textblue text-left">
                        <ng-container *ngIf="totalDataSize > 20">
                            <div class="data-length"> Show
                                <select [(ngModel)]="dataSize" (change)="dataSizeChange()">
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                        </ng-container>
                        <span>Total Count: {{totalDataSize}}</span>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <ng-template #audDataList>
        <div class="forgot-password-modal">
          <div class="modal-header">
            <h4 class="modal-title pull-left">
              <!-- <img src="assets/images/angle-left.svg" class="cursor_pointer" (click)="modalRef.hide();"> &nbsp;  -->
              Select Customer</h4>
              <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="audListDataBox">
              <div class="table-responsive view_audience_tbl_parent">
                <table class="table view_audience_tbl no_border_tbl">
                  <tr>
                    <th>S.No.</th>
                    <th>Action</th>
                     <th>Name</th>
                    <th>Email</th>
                    <th>Phone No.</th>
                    <th>Category</th>
                  </tr>
                  <ng-container *ngIf="audienceListData">
                  <tr *ngFor="let list  of audienceListData ; let i = index">
                    <td>{{i+1}} </td>
                    <td><button class="btn selectAudBtn" (click)="modalRef.hide();goToCat(list)"><a href="javascript:void(0)">View</a></button></td>
                    <td *ngIf="list.name != undefiend && list.name != ''">{{list.name}}</td>
                    <td *ngIf="list.name == undefiend ||  list.name == ''"></td>
                    <td *ngIf="list.email != undefiend && list.email != ''">{{list.email}}</td>
                    <td *ngIf="list.email == undefiend ||  list.email == ''"></td>
                    <td *ngIf="list.mobile != undefiend && list.mobile != ''">{{list.mobile}}</td>
                    <td *ngIf="list.mobile == undefiend ||  list.mobile == ''"></td>
                    <td *ngIf="list.categoryName != undefiend && list.categoryName != ''">{{list.categoryName}}</td>
                  </tr>
                </ng-container>
                <ng-container *ngIf="audienceListData.length == 0">
                  <tr>
                    <td class="no-records" colspan="8">No Records Yet</td>
                  </tr>
                </ng-container>
                </table>
              </div>
            </div>
          </div>
          </div>

        </ng-template>

    <ng-template #audDetailTemplate>
        <app-audience-detail [modalRef]="modalRef" [audienceId]="viewAudienceId"></app-audience-detail>
    </ng-template>

    <ng-template #filterModalRef>
        <div class="forgot-password-modal" >
            <div class="modal-header">
                <h4 class="modal-title pull-left">Duration 
                </h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                      <span aria-hidden="true">&times;</span>
                  </button>
            </div>
            <div class="modal-body modal-user-management">
                <div class="form-group">
                    <select class="form-control" [(ngModel)]="operator" (change)="operator_select()">
                        <option value="select">Select</option>
                        <option value=">=">≥ Greater Than Equal</option>
                        <option value="<=">≤ Less Than Equal</option>
                        <option value="=">= Equal</option>
                        <option value="!=">!= Not Equal</option>
                        <option value=">">> Greater Than</option>
                        <option value="<">< Less Than</option>
                        <option value="between">Between</option>
                    </select>
                </div>
                <div class="form-group">
                    <div class="inputValBox">
                        <input type="text" class="form-control" [(ngModel)]="startVal" onkeypress='return event.charCode >= 48 && event.charCode <= 57' placeholder="Minutes">
                        <input type="text" class="form-control m_l_10" [(ngModel)]="endVal" *ngIf="operator == 'between'" onkeypress='return event.charCode >= 48 && event.charCode <= 57' placeholder="Minutes">
                    </div>
                </div>

                <div class="form-group text-right">
                    <button *ngIf="operator != 'select'" class="btn btn-dark font_16 line_height_18 m_r_5 reset_btn_padding" (click)="resetFilter()">Reset</button>
                    <button class="btn btn-dark font_16 line_height_18 m_r_5 reset_btn_padding"  (click)="fetchCallAssistDtl()">Submit</button>
                </div>

            </div>
        </div>
    </ng-template>

</main>