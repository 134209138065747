import { Component, OnInit, TemplateRef, ViewChild} from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { ToastrService } from "ngx-toastr";
import { ProjectService } from "../../../../core/services/project.service";
import { Global } from "../../../../shared/models/global.model";
import { CampaignService } from "../../../../core/services/campaign.service";
import { SegmentService } from "../../../../core/services/segment.service";
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstants } from "../../../../../app/constants/app.constants";
import { Subject } from "rxjs";
import { AuthenticationService } from "../../../../core/services/authentication.service";
import { CampaignJourneyService } from "../../../../modules/campaign-journey/campaign-journey.service";
import { environment } from "../../../../../environments/environment";
import { ThrowStmt } from "@angular/compiler";

@Component({
    selector: "app-create-campaign-with-segment",
    templateUrl: "./create-campaign-with-segment.component.html",
    styleUrls: ["./create-campaign-with-segment.component.scss", "../channel-type/channel-type.component.scss"]
})
export class CreateCampaignWithSegmentComponent implements OnInit {
    modalRef: BsModalRef;
    filters: any = [];
    fetchUser: any = [];
    segments: any = [];
    segmentText: any;
    source: any = [];
    segmentdata: any = {};
    inputParam: any = {};
    showAlertMessage: boolean;
    @ViewChild('alertMessageModel') alertMessageModel: TemplateRef<any>;
    alertTitle: any = '';
    alertMessage: any = '';
    selected: boolean;
    
    checkedList: any;
    totalAudience: number;
    totalSubscription: number;

    campaign: string = '';
    pageTitle: string = '';
    isShowCampaignCreate: boolean = false;
    isChannelType: boolean = false;
    audienceCount: number = 0;
    campaignName: string = '';
    iosPushNotification: boolean = false;
    androidPushNotification: boolean = false;
    pushType: string = '';
    webPushNotification: boolean = true;
    isSegmentList:boolean = false;
    campaignProceed:Subject<object> = new Subject();
    mauLimitLow: boolean = true;
    updateAudienceFilter:Subject<object> = new Subject();
    public userRoleId: number;
    isSourceUpdate: boolean;
    minDate: any = new Date();
    alternateUse:any;
    alternateData:any;
    dataList:any=[]
    campaignSetting = {
        date: new Date(),
        time: new Date(),
        send: "now",
        campFrequency: "everyday",
        paramId: 0,
        paramWhen: "sameday",
        paramWhenDay: 0,
        weekDay: 1,
        monthDay: 1,
        paramInterval: 0.8,
        campExecutionTime: new Date(),
        toDate: new Date(),
        isValid: false,
        alternateUse:[]
    };
    isJourney:boolean = false;
    campaignJourneyList:any[] = [];
    journeyDataName = '';
    platFormName:any;
    push_url:any;
    campaignSubmit:any;
    pushCheckConfig:boolean = false;
    filterByCampaign:boolean=true;
    constructor(
        private toastr: ToastrService,
        private router: Router,
        private route: ActivatedRoute,
        public global: Global,
        private modalService: BsModalService,
        private campaignService: CampaignService,
        private projectService: ProjectService,
        public segmentService: SegmentService,
        private authenticationService:AuthenticationService,
        private campaignJourneyService:CampaignJourneyService
    ) {
        this.selected = false;
    }

    ngOnInit() {
        this.fetchCategory();
        localStorage.removeItem('selectedFilterCamp')
        this.showAlertMessage = false;
        this.androidPushNotification = false;
        this.iosPushNotification = false;
        this.webPushNotification = false; 
        this.pushType = '';
        this.checkSubscriptionForCampaign();
        this.getFilters();
        this.getfetchUserSorce();
        this.route.params.subscribe(params => {
            if(params['type'] == 'push'){
                this.pushCheckConfig = true
            }
            if(params['type'] !== undefined){
                this.setCampaignType(params['type'])
                this.isJourney = params['type'] == 'journey';
                if(this.isJourney){
                    this.getJourneyList();
                    this.campaignSetting.campFrequency = 'custom';
                }
            }
        });
        if(this.router.routerState.snapshot.url!='/admin/edit-campaign-segment'){
            this.global.ClearChannel();
        }
        this.getSegments();
        this.userRoleId = this.authenticationService.getUserRole();
               
    }

    checkSubscriptionForCampaign() {
        this.campaignService.checkSubscriptionForCampaign({ }).subscribe(data => {
            if (data.status) {
                if(this.pushCheckConfig == false){
                this.checkConfigurationForCampaign()
                }
                // this.showAlertMessage = false;
            } else if (!data.error_msgs.status) {
                this.totalAudience = data.error_msgs.totalAudience;
                this.totalSubscription = data.error_msgs.noOfSubscription;
                this.alertTitle = data.error_msgs.errorTitle;
                this.alertMessage = data.error_msgs.errorMessage;
                this.modalRef = this.modalService.show(this.alertMessageModel, { backdrop: 'static', class:'model-md' });
            } else {
                this.showAlertMessage = false;
            }
        });
    }

    goBack(){
        window.history.back();
    }

    openModal(id: number, webPushSegment: TemplateRef<any>) {
        this.inputParam = id;
        this.modalRef = this.modalService.show(webPushSegment, { class: "modal-lg", backdrop: 'static' });
        this.getfetchSegment();
    }

    selectFreshAudienceModal(selectfreshAudience: TemplateRef<any>) {
        this.modalRef = this.modalService.show(selectfreshAudience, {
            class: "modal-rightblock",
            backdrop: "static"
        });
    }

    Proceed() {
        this.router.navigate(['admin/channel-type']);
    }

    getFilters() {
        this.projectService.fetchAttributeList().then(data => {
            this.filters = [];
            if(data){
                this.filters = this.projectService.filterAttributeList(['all']);
                this.filters = this.filters.sort((a,b)=>{
                    if(a.paramName < b.paramName){
                        return -1;
                    }
                    if(a.paramName > b.paramName){
                        return 1;
                    }
                })
                let alternateData = []                
                this.filters.forEach(element => {
                    if(element.paramDatatype == this.alternateUse){                        
                        alternateData.push(element);
                    }
                });
                this.alternateData = alternateData;
                this.filters.forEach(element => {
                    if (element.paramDatatype == "Date") {
                        if (this.campaignSetting.paramId == 0) {
                            this.campaignSetting.paramId = element.id;
                        }
                    }
                });
            }
        });
    }

    getfetchUserSorce() {
        this.projectService.getUserSourceData().then(data => {
            this.fetchUser = this.projectService.getSourceList();
            //this.fetchUser = data;
        });
    }

    fetchCategory() {        
        this.dataList = [];
        this.projectService.fetchCategory({}).then(data => {
            if (data) {
                this.dataList = data;
            }
        })
    }

    getSegments() {
        this.segmentService.getSegmentlist({ }).subscribe(data => {
            if (data.status && data.data) {
                data.data.forEach(element => {
                    if(this.global.Channel.Segments.filter(function (item: any) {return item.id==element.id && item.checked == true;}).length>0){
                    element.checked = true;
                    }
                });
                this.segments = data.data;
                if(this.segments.length > 0){
                    this.segments.forEach(element => {
                        let checkCatName = this.dataList.find(x=>x.linkRewrite == element.category);
                        if(checkCatName){
                            element.catName = checkCatName.name;
                        }
                    });
                }
                this.selected = this.allSelected();
            }
        });
    }

    getfetchSegment() {
        this.segmentService.fetchSegment({ id: this.inputParam}).subscribe(data => {
            if (data.status) {
                this.segmentdata = data.data;
                this.source = data.data.segmentGroupDetail;     
            }
        });
    }

    allSelected(){
        return this.segments.filter(x => !x.checked).length == 0;
    }

    resetSegments(){
        this.segments.forEach(element => {
            element.checked = false;
        });
    }
    selectAll() {
        let filterSegment: any[] = [] ;
        this.global.Channel.Segments = [];
        var toCompare = this.segmentText != undefined ? this.segmentText.toLowerCase().trim() : '';
        filterSegment = this.segments.filter((item: any) => {
            return item.segmentName.toString().toLowerCase().includes(toCompare);
        });
        filterSegment.forEach(element => {
            element.checked = this.selected;
            for (let i = 0; i < this.segments.length; i++) {
                if(element.id == this.segments[i].id){
                this.segments[i].checked = this.selected;
                }
            }
        });
        this.global.Channel.Segments = filterSegment;
        this.getCheckedItemList();
    }

    assignSegmentChannel(item) {   
        this.global.categorySelectByCamapign = []     
        this.global.Channel.Segments = this.segments.every(function (item: any) {
            return item.checked == true;
        })
        this.selected = this.allSelected();
        this.getCheckedItemList();
    }

    assignGroupsChannel() {
        this.global.ClearChannel();
        this.global.Channel.Source = this.fetchUser.every(function (item: any) {
            return item.checked == true;
        })
        this.getFetchUserList();
    }

    assignEventsChannel() {
        this.global.ClearChannel();
        this.global.Channel.Events = this.filters.every(function (item: any) {
            return item.checked == true;
        })
        this.getFetchEventList();
    }

    assignAttributesChannel() {
        this.global.ClearChannel();
        this.global.Channel.Attributes = this.filters.every(function (item: any) {
            return item.checked == true;
        })
        this.getFetchEventList();
    }

    getCheckedItemList() {
        this.checkedList = [];
        for (var i = 0; i < this.segments.length; i++) {
        if (this.segments[i].checked)
            this.checkedList.push(this.segments[i]);
        }
        this.checkedList = JSON.stringify(this.checkedList);
        this.global.Channel.Segments = this.checkedList;
    }
  
    getFetchUserList() {
        this.checkedList = [];
        for (var i = 0; i < this.fetchUser.length; i++) {
        if (this.fetchUser[i].checked)
            this.checkedList.push(this.fetchUser[i]);
        }
        this.checkedList = this.checkedList;
        this.global.Channel.Source = this.checkedList;
    }

    getFetchAttributesList() {
        this.checkedList = [];
        for (var i = 0; i < this.filters.length; i++) {
        if (this.filters[i].checked)
            this.checkedList.push(this.filters[i]);
        }
        this.checkedList = this.checkedList;
        this.global.Channel.Attributes = this.checkedList;
    }


    getFetchEventList() {
        this.checkedList = [];
        for (var i = 0; i < this.filters.length; i++) {
        if (this.filters[i].checked)
            this.checkedList.push(this.filters[i]);
        }
        this.checkedList = this.checkedList;
        this.global.Channel.Events = this.checkedList;
    }

    gotoChannelType(){
        this.global.ClearChannel();
        this.global.Channel.Segments = this.segments.filter(function (item: any) {
            return item.checked == true;
        });
        if(this.global.Channel.Segments.length>0){
            this.setCampaignData();
            this.isChannelType = true;
        } else {
            this.toastr.error('Please select segment.', 'Error')
        }
    }

    goWithAllAudience(){
        this.setCampaignData();
        this.global.ClearChannel();
        this.global.Channel.AllAudience = true;
        this.isChannelType = true;
        // this.router.navigate(['/admin/channel-type']);
    }

    filterClose(event?){
        if(!this.global.AnyChannelSelected()){
            this.hideAudience(1);
            return;
        }
        this.isSourceUpdate = event == 'isSourceUpdate';
        this.updateAudienceFilter.next();
        this.isShowCampaignCreate = true;
        this.isSegmentList = false;
        this.resetSegments();
        this.isChannelType = false;
        // this.router.navigate(['/admin/campaign-create']);
    }

    hideAudience(event){
        this.isShowCampaignCreate = false;
        if (event == 2){
            this.setCampaignData();
            this.isChannelType = true;
            localStorage.removeItem('selectedFilter');
        }
    }

    setCampaignType(type){        
        switch (type) {
            case "push":
                this.pageTitle = AppConstants.PUSH_NOTIFICATION;
                this.campaign = AppConstants.CAMPAIGN_TYPE_PUSH;
                this.push_url = environment.pushServiceUrl;
                if(this.webPushNotification == true){
                    this.alternateUse = 'web_fcm_token'
                } else if(this.iosPushNotification == true){
                    this.alternateUse = 'ios_fcm_token'
                } else if(this.androidPushNotification == true){
                    this.alternateUse = 'android_fcm_token'
                }
            break;
            case "sms":
                this.pageTitle = AppConstants.SMS_CAMPAIGN;
                this.campaign = AppConstants.CAMPAIGN_TYPE_SMS;
                this.push_url = environment.smsPushUrl;
                this.campaignSubmit = 'SMS'
                this.alternateUse = 'Mobile';
            break;
            case "email":
                this.pageTitle = AppConstants.EMAIL_CAMPAIGN;
                this.campaign = AppConstants.CAMPAIGN_TYPE_EMAIL;
                this.push_url = environment.emailPushUrl;
                this.alternateUse = 'Email';
            break;
            case "whatsapp":
                this.pageTitle = AppConstants.WHATSAPP_CAMPAIGN;
                this.campaign = AppConstants.CAMPAIGN_TYPE_WHATSAPP;
                this.push_url = environment.whatsappApiUrl;
                this.campaignSubmit = 'whatsapp'
                this.alternateUse = 'Mobile';
            break;
            case "inapp":
                this.pageTitle = AppConstants.INAPP_NOTIFICATION;
                this.campaign = AppConstants.CAMPAIGN_TYPE_INAPP;
                this.push_url = environment.webUrl;
            break;
            case "journey":
                this.pageTitle = 'Journey Campaign';
                this.campaign = 'journey';
            break;
            default: 
                this.router.navigate(['/admin/dashboard']);
            break;
        }
        this.setCampaignData();
    }

    checkConfigurationForCampaign() {
        

        this.mauLimitLow = false;
        if(this.audienceCount > (this.projectService.projectDetails.mauLimit * 1.25)){
            this.mauLimitLow = true;
            this.toastr.error('Campaign target audience count can not be greater than 1.25 times of assigned Monthly Active Users limit.', 'Error');
            return;
        }
        if((this.projectService.projectDetails.mauUsed - this.projectService.projectDetails.mauLimit) > (this.projectService.projectDetails.mauLimit / 4) ){
            this.mauLimitLow = true;
            this.toastr.error('Your have targeted 25% more than assigned MAU. Now you will have to increase your MAU limit. Please contact sales@cronberry.com', 'Error');
            return;
        }
        if(this.isJourney){
            this.showAlertMessage = false;
            return
        }        
        this.campaignService.checkConfigurationForCampaign({ campType: this.campaign, isAndroid: this.androidPushNotification, isIos: this.iosPushNotification, isWeb: this.webPushNotification, pushType: this.pushType },this.push_url).subscribe( data => {
            if (data.status) {
                this.showAlertMessage = false;
            } else if (!data.error_msgs.status) {
                this.alertTitle = data.error_msgs.errorTitle;
                this.alertMessage = data.error_msgs.errorMessage ? data.error_msgs.errorMessage : data.error_msgs;
                this.showAlertMessage = true;
            } else {
                this.showAlertMessage = false;
            }
        });
    }

    getAudienceCount(count){
        this.audienceCount = count;
        this.checkConfigurationForCampaign();
        this.isSegmentList = false;
        this.resetSegments();
    }

    selectSegments(){
        this.isSegmentList = true;
        this.isShowCampaignCreate = false;
    }

    submitCampaign(){        
        this.checkCampaignSetting();
        if(!this.campaignSetting.isValid) return;
        if(this.mauLimitLow && this.campaignSetting.send == 'now' && !this.isJourney){
           this.checkConfigurationForCampaign();
           return;
        }
        if(this.campaignName == ''){
            this.toastr.error('Campaign name required', 'Error');
            return;
        }
        if(this.campaignName){
            let alphaNumWithChar  = /^[a-z A-Z0-9_/-]*$/g
            if(!alphaNumWithChar.test(this.campaignName)){
                this.toastr.error('Campaign name should be Alphanumeric', 'Error');
                return;
            }

        }
        if (this.campaign == AppConstants.CAMPAIGN_TYPE_PUSH && (!this.iosPushNotification &&!this.androidPushNotification && !this.webPushNotification) ) {
            this.toastr.error("Please Select Platform",'Error');
            return;
        }
        if (this.isJourney && this.journeyDataName == '') {
            this.toastr.error("Please Select Campaign Journey",'Error');
            return;
        }
        this.setCampaignData();
        
    }

    setCampaignData(){
        if(this.isJourney){
            // var journeyData = this.campaignJourneyList.find(x => x.campName == this.journeyDataName);
            var journeyData;
            let journeyname = this.journeyDataName.split(',')[0];
            let journeyId = this.journeyDataName.split(',')[1];
            this.campaignJourneyList.forEach(elem=>{
                if(elem.campName == journeyname && elem.journeyUserId == journeyId){
                    journeyData = elem;
                }
            })

            if(journeyData == undefined){
                this.toastr.error("Please Select Campaign Journey",'Error');
                return;
            }
            let campaignData = {
                campaignName : this.campaignName,
                campaign: this.campaign,
                journeyName: journeyData.campName,
                journeyUserId: journeyData.journeyUserId,
            }
            
            
            this.global.CampaignData = campaignData;
            this.campaignProceed.next(campaignData);
            return;
        }
        let alternateName;
        if(this.alternateUse == 'Mobile'){
            alternateName = 'mobile';
        } else if(this.alternateUse == 'Email'){
            alternateName = 'email'
        } else{
            alternateName = this.alternateUse ? this.alternateUse : '';
        }
        let alternateUserString = "";
        if(this.campaignSetting.alternateUse.length>0){
            this.campaignSetting.alternateUse.forEach((element) =>{
                alternateUserString = alternateUserString+"params."+element+","
            })
            alternateUserString =    alternateUserString.slice(0, -1);
        }
       
        let alterNateUse = alternateUserString.length > 0 ? ',' + alternateUserString : ''
        
        let campaignData = {
            campaignName : this.campaignName,
            campaign: this.campaign,
            iosPushNotification :  this.iosPushNotification,
            androidPushNotification :  this.androidPushNotification,
            webPushNotification :  this.webPushNotification,
            campAttributes:alternateName + alterNateUse,
        }
        if(this.campaign == AppConstants.CAMPAIGN_TYPE_PUSH){
            campaignData.campaign = this.iosPushNotification ? 'ios,' : '';
            campaignData.campaign += this.androidPushNotification ? 'android,' : '';
            campaignData.campaign += this.webPushNotification ? 'web,' : '';
        }
        campaignData.campaign = campaignData.campaign.replace(/,\s*$/, "");
        this.global.CampaignData = campaignData;
        this.campaignProceed.next(campaignData);
    }

    clearFilter(){
        this.isChannelType = false;
        this.audienceCount = 0;
    }

    platFormSelect(event)
    {
        if(event.target.checked){
            this.platFormName = event.target.value;
            this.push_url = environment.pushServiceUrl;

            if(this.platFormName == 'android'){
                this.campaignSubmit = 'android'
                this.androidPushNotification = true;
                this.webPushNotification = false;
                this.iosPushNotification = false;
                this.alternateUse = 'android_fcm_token'
                this.pushType = 'ANDROID';
            } else if(this.platFormName == 'web'){
                this.webPushNotification = true;
                this.androidPushNotification = false;
                this.iosPushNotification = false;
                this.alternateUse = 'web_fcm_token'
                this.pushType = 'WEB';
            } else if(this.platFormName == 'ios'){
                this.iosPushNotification = true;
                this.webPushNotification = false;
                this.androidPushNotification = false;
                this.alternateUse = 'ios_fcm_token'
                this.pushType = 'IOS';
            }
            this.checkConfigurationForCampaign();
        }
    }

    checkCampaignSetting(){
        var postForm = this.campaignSetting;
        var t = new Date();
        t.setSeconds(t.getSeconds() - 50);
        var currentDate = t;
        var date = new Date();
        var toDate = new Date();
        if (postForm.send == "schedule") {
            date = new Date( postForm.date.toString().slice(0, 15) + postForm.time.toString().slice(15) );
        } else if (postForm.send == "repeat") {
            if(postForm.toDate == null || postForm.toDate == undefined){
                this.toastr.error("please fill To Date.");
                return;
            }
            date = new Date( postForm.date.toString().slice(0, 15) + new Date().toString().slice(15));
            toDate = new Date( postForm.toDate.toString().slice(0, 15) + new Date().toString().slice(15));
            if (date > toDate) {
                this.toastr.error("please fill correct date range");
                return;
            }
        }
        if (date < currentDate) {
            this.toastr.error("please fill correct date & time");
            return;
        }
        if (this.campaignSetting.paramWhen != 'sametime' && this.campaignSetting.send == "repeat" &&
        this.campaignSetting.date.toLocaleDateString() ==
        this.campaignSetting.toDate.toLocaleDateString()
        ) {
            this.toastr.error("Please select valid dates");
            return;
        }

        this.campaignSetting.isValid = true;
    }

    getJourneyList(){
        this.campaignJourneyList = []
        this.campaignJourneyService.getCampaignJourneyList(1000,0).subscribe(data => {
            if(data.status){
                this.campaignJourneyList = data.data.data;
            }
        })
    }


    

}
