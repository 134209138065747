export class PathConstants {
    
    public static get CANONICAL(): string { return 'canonical'; }
    
    public static get EDIT_ACCOUNT(): string { return '/admin/edit-account';}
    public static get CREATE_ACCOUNT(): string { return '/admin/create-account';}
    public static get CHANGE_PASSWORD(): string { return '/admin/change-password';}
    public static get CREATE_CAMPAIGN_SEGMENT(): string { return '/admin/create-campaign-segment';}
    public static get CHANNEL_TYPE(): string { return '/admin/channel-type';}
    public static get MANAGE_CAMPAIGNS(): string { return '/admin/report';}
    public static get USER_MANAGEMENT(): string { return '/admin/user-management';}
    public static get VIEW_AUDIENCE(): string { return '/admin/audience/view-audience';}
    public static get SIGN_IN(): string { return '/admin/sign-in';}
    public static get PROJECT_INTEGRATION(): string { return '/admin/project-integration';}
    public static get DASHBOARD(): string { return '/admin/dashboard';}


    public static get MARKETER_URL(): string[] { return [this.EDIT_ACCOUNT, this.CHANGE_PASSWORD, this.CREATE_CAMPAIGN_SEGMENT, this.CHANNEL_TYPE, this.MANAGE_CAMPAIGNS, this.DASHBOARD] }
    public static get TELECALLER_URL(): string[] { return [this.EDIT_ACCOUNT, this.CHANGE_PASSWORD, this.VIEW_AUDIENCE] }

}