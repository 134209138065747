import { Component, ElementRef, OnInit, TemplateRef, ViewChild,NgZone} from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { CampaignStats, CampaignStatsAndroid, CampaignStatsEmail, CampaignStatsInapp, CampaignStatsIndividual, CampaignStatsIos, CampaignStatsSms, CampaignStatsWeb, CampaignStatsWhatsapp, LeadsStats } from "../../../../core/modal/dashboard";
import { DashboardService } from "../../../../core/services/dashboard.service";
import { ChartOptions, ChartOptionsAndroid, ChartOptionsCount, chartOptionsEmail, chartOptionsInapp, ChartOptionsIos, chartOptionsSms, chartOptionsWeb, chartOptionsWhatsapp } from "../../../../core/modal/chartOptions";
import { ProjectService } from "../../../../core/services/project.service";
import { AppConstants } from "../../../../../app/constants/app.constants";
import { DatePickerObj } from "../../../../core/modal/datePicker";
import * as moment from "moment";
import { DatePipe } from "@angular/common";
import { Global } from "../../../../../app/shared/models/global.model";
import { AttributeObj, ProjectDetailObj } from "../../../../core/modal/project";
import { Router } from "@angular/router";
import { AuthenticationService } from "../../../../core/services/authentication.service";
import { ReportService } from "../../../../core/services/report.service";
import * as XLSX from 'xlsx';
import { SpinnerService } from "../../../../core/services/spinner.service";
import { environment } from "../../../../../environments/environment";
import { ToastrService } from "ngx-toastr";
import { AudienceFilter } from "../../../../core/services/cache.modal";


@Component({
    selector: 'dashboard-app',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit {
    // @ViewChild("chart") chart: ChartComponent;
    public chartOptions: Partial<ChartOptions>;
    public chartOptionsAndroid:Partial<ChartOptionsAndroid>;
    public chartOptionsIos:Partial<ChartOptionsIos>;
    public chartOptionsWeb:Partial<chartOptionsWeb>;
    public chartOptionsEmail:Partial<chartOptionsEmail>;
    public chartOptionsSms:Partial<chartOptionsSms>;
    public chartOptionsWhatsapp:Partial<chartOptionsWhatsapp>;
    public chartOptionsInapp:Partial<chartOptionsInapp>;
    public campaignStatsData: CampaignStats;
    public campaignStatsDataAndroid: CampaignStatsAndroid;
    public campaignStatsDataIos: CampaignStatsIos;
    public campaignStatsDataWeb: CampaignStatsWeb;
    public campaignStatsDataEmail: CampaignStatsEmail;
    public campaignStatsDataSms: CampaignStatsSms;
    public campaignStatsDataWhatsapp: CampaignStatsWhatsapp;
    public campaignStatsDataInapp: CampaignStatsInapp;
    public chartIndividualOptions: Partial<ChartOptions>;
    public leadsChartOptions: Partial<ChartOptions>;
    public pieChartOptions: Partial<ChartOptions>;
    public campaignStatsDays: DatePickerObj;  
    public leadsDays: DatePickerObj;
    public leadReportDays: number = 7;
    public leadsSource: string = AppConstants.STRING_BLANK;
    public isCampaignStatsShow: boolean = false;
    public modalRef: BsModalRef;
    public leadsStatsData: LeadsStats;
    public isShowleadsStats: boolean = false;
    public sourseList: any[] = [];
    public campaignStatsIndividualData: CampaignStatsIndividual[];
    public campaignIndividualSeries : any[] = [];
    public isShowMAUChart :boolean = false;
    public projectDetails : ProjectDetailObj;
    public campaignChartTitle: string;
    public userRoleId: any;
    public ranges: any;
    public maxDate: any;
    public isNoDataLeadChart: boolean = true;
    emailisCampaignStatsShow:boolean = false;
    smsisCampaignStatsShow:boolean = false;
    whatsappisCampaignStatsShow:boolean = false;
    inAppisCampaignStatsShow:boolean = false;
    androidisCampaignStatsShow:boolean = false;
    iosisCampaignStatsShow:boolean = false;
    webisCampaignStatsShow:boolean = false;
    followUpList: any[] = [];
    totalToday:any;
    totalMissed:any;
    totalCompleted:any;
    totalOverdue:any;
    followUpfilterDateRange: DatePickerObj;
    followUpFromDate: any;
    followUpToDate: any;
    missedToDate: any;
    followUpCompleteToDate: any;
    todayDate: any = new Date();
    positionOfTblFollow:any='vertical';
    positionOfTblLeadReport:any='vertical'
    positionOfTbSource:any='vertical'
    followUpReportShow:boolean = false;
    followReportResponse:boolean = true
    leadReportData:any;
    defaultDate:any;
    waitCount: number = 0;
    pushApiUrl:any = environment.pushServiceUrl;
    // iosApiUrl:any = environment.iosPushUrl;
    // webApiUrl:any = environment.webPushUrl;
    inAppApiUrl:any = environment.webUrl;
    emailApiUrl:any = environment.emailPushUrl;
    smsApiUrl:any = environment.smsPushUrl;
    whatsappApiUrl:any = environment.whatsappApiUrl;
    emailLoading:boolean = false;
    smsLoading:boolean = false;
    whatsappLoading:boolean = false;
    inAppLoading:boolean = false;
    androidLoading:boolean = false;
    iosLoading:boolean = false;
    webLoading:boolean = false;
    loader:any = false;
    refilEditVal:any;
    sortByDate:any;
    reportDetailData:any;
    selectedleadReportId:any;
    public inputParams: AttributeObj[];
    leadReportDaysInput: DatePickerObj;
    leadCollectedNotAvail:boolean = false;
    audienceFilter: AudienceFilter[] = []
    maxRange:any;
    dataList:any;
    categoryName:any;
    public leadReportChartOptions: Partial<ChartOptions>[] = [];
    @ViewChild('campaignChart') campaignChart: TemplateRef<any>;
    @ViewChild('addLeadReports') addLeadReports: TemplateRef<any>;
    @ViewChild('followUpReports') followUpReports: ElementRef;
    @ViewChild('leadReportDeleteConfirm') leadReportDeleteConfirm: TemplateRef<any>;
    // public isExpired : boolean = false;
    public userName : string = '';
    constructor(
        private modalService: BsModalService,
        private dashboardService: DashboardService,
        private projectService: ProjectService,
        private datePipe: DatePipe,
        private global: Global,
        private router: Router,
        private ngZone: NgZone,
        private authenticationService: AuthenticationService,
        private reportService:ReportService,
        private spinner: SpinnerService,
        private toastr:ToastrService,
        private authenticService:AuthenticationService

    ) { 
        
    }

    ngOnInit() {
        this.fetchCategory()
        this.fetchUserProfile();
        this.projectService.getMaxRange().then(data=>{
            this.maxRange = data
        });
        this.followUpfilterDateRange = {
            startDate: moment().subtract(6, 'days'),
            endDate: moment()
        };
        this.maxDate = moment();
        this.ranges = this.global.customDateRange();
        this.userRoleId = this.authenticationService.getUserRole();
        
        if(this.dashboardService.campaignStatsDays != undefined){
            this.campaignStatsDays = this.dashboardService.campaignStatsDays;
        } else {
            this.campaignStatsDays = {
                startDate: moment().subtract(29, 'days'),
                endDate: moment(),
            };
        }
        if(this.dashboardService.leadsDays != undefined){
            this.leadsDays = this.dashboardService.leadsDays;
            this.leadsSource = this.dashboardService.leadsSource;
        } else {
            this.leadsDays = {
                startDate: moment().subtract(29, 'days'),
                endDate: moment(),
            };
            this.leadsSource = '';
        }
        // this.fetchCampaignStats();
        if(this.reportService.leadsDays != undefined){
            this.leadReportDaysInput = this.reportService.leadsDays;
            this.spinner.show();
        } else {
            this.leadReportDaysInput = {
                startDate: moment().subtract(6, 'days'),
                endDate: moment(),
            };
        }
        this.getInputParams()
        this.getSourceList();
        this.getProjectDetail();
       this.emailisCampaignStatsShow=true;
       this.smsisCampaignStatsShow=true;
       this.whatsappisCampaignStatsShow=true;
        this.fetchCampaignStats('whatsapp','reload');
        this.fetchCampaignStats('email','reload');
        this.fetchCampaignStats('sms','reload');
        this.fetchLeadReports();        
        localStorage.removeItem('selectedFilterCamp');
    }

    getInputParams() {
        var source = [];
        this.inputParams = [];
        var list = this.projectService.filterAttributeList(source);
        list.forEach(element => {
            this.inputParams.push(element);
        });

        this.inputParams.sort((a,b)=>{
            if(a.paramName < b.paramName){
                return -1;
            }
            if(a.paramName > b.paramName){
                return 1;
            }
        });
    }

    spinnerHide() {
        if (this.waitCount > 0){
          this.waitCount--;
        } else {
          this.spinner.hide();
        }
    }

    refreshGraph(item)
    {
        this.loadReportDetail(item)
    }

    loadReportDetail(item)
    {
        item.loadingData = true;  
        let getDetail = {"id": item.data.id,"fromDate":this.datePipe.transform(this.leadReportDaysInput.startDate, "yyyy-MM-dd"),"toDate":this.datePipe.transform(this.leadReportDaysInput.endDate, "yyyy-MM-dd"),'reportDateType':this.sortByDate}
        this.reportService.reportDetail(getDetail).subscribe(data=>{
            if(data.status){
                item.showButton = false;
                this.reportDetailData = data;
                this.setLeadReports(this.reportDetailData,'reload')
            } else{
                this.toastr.error(data.error_msgs)
            }
            setTimeout(() => {
                item.loadingData = false;
            }, 1000);   
        })
    }

    setLeadReports(data,chartReload?){
        if(data != undefined){                    
            this.inputParams = this.projectService.filterAttributeList()    
            this.waitCount = data.length - 1;
            if(!chartReload){
            this.leadReportChartOptions = [];
            }
            this.leadReportData = data;
            if(chartReload == 'reload'){
                this.leadReportData = data.data
            }
            this.leadReportData.isBarChart = true;
                if(this.leadReportData.attributeName == undefined){
                    this.leadReportData.count = "[]";
                    this.leadReportData.attributeName = "[]";
                    this.leadReportData.count = "[]";
                    this.leadReportData.dataCount = 0;
                    this.spinnerHide();
                }
                this.leadReportData.dataCount = this.leadReportData.attributeName != "[]" ? this.leadReportData.attributeName.split(',').length : 0;
                        
                if(this.leadReportData.veriticalParamId){
                    this.inputParams.forEach(param => {                                                  
                        if(param.paramKey == this.leadReportData.veriticalParamId || 'params.'+param.paramKey == this.leadReportData.veriticalParamId){
                            this.leadReportData.yaxisAttributeName = param.paramName;
                            this.inputParams.forEach(param => {
                                if(param.paramKey == this.leadReportData.horizontalParamId || 'params.'+param.paramKey == this.leadReportData.horizontalParamId){
                                    this.leadReportData.xaxisAttributeName = param.paramName
                                    this.setLeadReportOption(this.leadReportData);
                                }
                            });
                        }
                    });
                } else {                    
                    this.inputParams.forEach(param => {
                        if(param.paramKey == this.leadReportData.horizontalParamId || 'params.'+param.paramKey == this.leadReportData.horizontalParamId){
                            this.leadReportData.xaxisAttributeName = param.paramName
                            this.setLeadReportOption(this.leadReportData);
                        }
                    });
                }
            
            this.isNoDataLeadChart = this.leadReportData.length == 0;
        }

    }

    fetchLeadReports(){
        let fromDate:any = this.leadReportDaysInput.startDate;
        let endDate:any =  this.leadReportDaysInput.endDate;
        let differenceBetweenDate = Math.floor((endDate - fromDate) / (1000 * 60 * 60 * 24));
        differenceBetweenDate = differenceBetweenDate + 1;
        if(differenceBetweenDate > this.maxRange){
            this.toastr.error('Max date range should not be greater than ' + this.maxRange + ' days');
            return
        }
        this.leadReportData = [];
        this.reportService.getReportListData(this.leadReportDaysInput,'dashboard','1','reload').then(data => {
            
            if(data != undefined){                
                this.waitCount = data.length - 1;
                this.leadReportChartOptions = [];
                this.leadReportData = data;
                this.leadReportData.forEach((element) => {
                    element.isBarChart = true;
                    if(element.attributeName == undefined){
                        element.count = "[]";
                        element.attributeName = "[]";
                        element.count = "[]";
                        element.dataCount = 0;
                    }
                    
                    element.dataCount = element.attributeName != "[]" ? element.attributeName.split(',').length : 0;                   
                    if(element.veriticalParamId){                        
                        let verticalParamId;
                        let missedAttribute;
                        let defaultMethod;
                        let missedAttr = [];  
                        if(element.veriticalParamId.includes('params.') == true){
                            verticalParamId = element.veriticalParamId.replace('params.','');
                        } else{
                            verticalParamId = element.veriticalParamId
                        }
    
                        let horizontalParamId;
                        if(element.horizontalParamId.includes('params.') == true){
                            horizontalParamId = element.horizontalParamId.replace('params.','');
                        } else{
                            horizontalParamId = element.horizontalParamId
                        }

                        this.inputParams.forEach(param => {                          
                            if(param.paramKey == element.veriticalParamId || 'params.'+param.paramKey == element.veriticalParamId){
                                element.yaxisAttributeName = param.paramName;
                                this.inputParams.forEach(param => {
                                    if(param.paramKey == element.horizontalParamId || 'params.'+param.paramKey == element.horizontalParamId){
                                        element.xaxisAttributeName = param.paramName
                                        defaultMethod = true
                                    }
                                });
                            }
                        });

                    let checkVerticalParamIdInAttr = this.inputParams.find(x=>x.paramKey == verticalParamId);
                    if(checkVerticalParamIdInAttr == undefined){
                        missedAttribute = true;
                        missedAttr.push(verticalParamId)
                    } else {
                        this.dataList.forEach(category => {
                            if(category.linkRewrite == element.category){
                                if(checkVerticalParamIdInAttr.category){
                                let checkAttrInCat = checkVerticalParamIdInAttr.category.split(',');
                                checkAttrInCat = checkAttrInCat.filter(p=>{
                                    return p!= null
                                })
                                if(checkAttrInCat && checkAttrInCat.length > 0){
                                   let checkAttrByCat = checkAttrInCat.find(x=>x == element.category)
                                    if(checkAttrByCat == undefined){


                                        if(checkVerticalParamIdInAttr.paramOwner == 0 && checkVerticalParamIdInAttr.pluginName != 'static'){
                                            missedAttribute = true;
                                            let checkToPush = missedAttr.find(x=>x == verticalParamId);
                                            if(checkToPush == undefined){
                                                missedAttr.push(verticalParamId)
                                            }
                                            }
                                    }
                                    
                                }
                            }
                          }
                        });
                    } 
                    
                   
                    let checkHorizontalIdInAttr = this.inputParams.find(x=>x.paramKey == horizontalParamId);
                    if(checkHorizontalIdInAttr == undefined){
                        missedAttribute = true;
                        missedAttr.push(horizontalParamId)
                    }
                    else {
                        this.dataList.forEach(category => {
                            if(category.linkRewrite == element.category){
                                if(checkHorizontalIdInAttr.category){
                                let checkAttrInCat = checkHorizontalIdInAttr.category.split(',');
                                checkAttrInCat = checkAttrInCat.filter(p=>{
                                    return p!= null
                                })
                                if(checkAttrInCat && checkAttrInCat.length > 0){
                                   let checkAttrByCat = checkAttrInCat.find(x=>x == element.category)
                                    if(checkAttrByCat == undefined){
                                        if(checkHorizontalIdInAttr.paramOwner == 0 && checkHorizontalIdInAttr.pluginName != 'static'){
                                            missedAttribute = true
                                            let checkToPush = missedAttr.find(x=>x == horizontalParamId);
                                            if(checkToPush == undefined){
                                                missedAttr.push(horizontalParamId)
                                            }
                                            }
                                        // missedAttribute = true
                                        // missedAttr.push(horizontalParamId)
                                    }
                                    
                                }
                            }
                          }
                        });
                    }

                    let filterJsonAsAttr = JSON.parse(element.filterJson);
                    if(filterJsonAsAttr.paramList && filterJsonAsAttr.paramList.length > 0){
                        filterJsonAsAttr.paramList.forEach(element => {
                            let elem_param_key;
                            if(element.paramKey.includes('params.') == true){
                                elem_param_key = element.paramKey.replace('params.','');
                            } else{
                                elem_param_key = element.paramKey;
                            }

                            let checkParam = this.inputParams.find(x=>x.paramKey == elem_param_key);
                            if(checkParam == undefined){
                                console.log(elem_param_key);
                                missedAttribute = true;
                                missedAttr.push(elem_param_key)
                            }
                        });
                    }

                    if(missedAttribute == true){
                        this.setLeadReportOption(element,'missedAttribute');
                    } else if(defaultMethod == true){
                        this.setLeadReportOption(element);
                    }
                    } else {   
                        
                        let missedAttribute;
                        let defaultMethod;  
                        let horizontalParamId;
                        let missedAttr = []
                        this.inputParams.forEach(param => {
                            if(param.paramKey == element.horizontalParamId || 'params.'+param.paramKey == element.horizontalParamId){
                                element.xaxisAttributeName = param.paramName
                                // this.setLeadReportOption(element);
                                defaultMethod = true
                            }
                        });

                        if(element.horizontalParamId.includes('params.') == true){
                            horizontalParamId = element.horizontalParamId.replace('params.','');
                        } else{
                            horizontalParamId = element.horizontalParamId
                        }

                        let checkVerticalParamIdInAttr = this.inputParams.find(x=>x.paramKey == horizontalParamId);
                    if(checkVerticalParamIdInAttr == undefined){
                        missedAttribute = true;
                        missedAttr.push(horizontalParamId)
                    } else{
                        this.dataList.forEach(category => {
                            if(category.linkRewrite == element.category){
                                if(checkVerticalParamIdInAttr.category){
                                let checkAttrInCat = checkVerticalParamIdInAttr.category.split(',');
                                checkAttrInCat = checkAttrInCat.filter(p=>{
                                    return p!= null
                                })
                                if(checkAttrInCat && checkAttrInCat.length > 0){
                                   let checkAttrByCat = checkAttrInCat.find(x=>x == element.category)
                                    if(checkAttrByCat == undefined){
                                        missedAttribute = true;
                                        missedAttr.push(horizontalParamId)
                                    }
                                    
                                }
                            }
                          }
                        });
                    }

                    let filterJsonAsAttr = JSON.parse(element.filterJson);
                    if(filterJsonAsAttr.paramList && filterJsonAsAttr.paramList.length > 0){
                        filterJsonAsAttr.paramList.forEach(element => {
                            let elem_param_key;
                            if(element.paramKey.includes('params.') == true){
                                elem_param_key = element.paramKey.replace('params.','');
                            } else{
                                elem_param_key = element.paramKey;
                            }

                            let checkParam = this.inputParams.find(x=>x.paramKey == elem_param_key);
                            if(checkParam == undefined){
                                missedAttribute = true
                                missedAttr.push(elem_param_key)
                            }
                        });
                    }

                    if(missedAttribute == true){
                        this.setLeadReportOption(element,'missedAttribute',missedAttr);
                    } else if(defaultMethod == true){
                        this.setLeadReportOption(element);
                    }
                    }
                });
                this.isNoDataLeadChart = this.leadReportData.length == 0;
            } else {
                this.spinner.hide();
                this.isNoDataLeadChart = true;
            }
          //  this.spinnerHide();
        });
    }


    setLeadReportOption(element,attrStatus?,missedAttr?){        
        const maxChartSize = 40;
        if(element.reportType == 0){            
            if(element.dataCount > maxChartSize && element.columnOutput == 0 && !element.countsMap){
                this.toastr.error(element.reportName+' can not be render on to dashboard because number of data records are more than expected. You can view graph for same date range in analysis section.');
                let countMap = []
                if(element.countsMap){
                    element.countsMap.forEach(element => {
                        element.data.forEach(elementData => {                            
                            countMap.push(elementData) 
                        });
                    });
                }
                let eventParamIdSet = element.eventParamId.includes('params.') == true ? element.eventParamId.split('.')[1] : element.eventParamId;
                let veriticalParamIdSet = element.veriticalParamId.includes('params.') == true ? element.veriticalParamId.split('.')[1] : element.veriticalParamId;
                const count = element.count ? eval(element.count) : countMap;
                const attribute = element.attributeName.split(',');
                const countGroups = count.map((e, i) => {
                    return i % maxChartSize === 0 ? count.slice(i, i + maxChartSize) : null; 
                }).filter(e => { return e; });
                const attributeGroups = attribute.map((e, i) => {
                    return i % maxChartSize === 0 ? attribute.slice(i, i + maxChartSize) : null; 
                }).filter(e => { return e; });
                console.log(attributeGroups);
                
                // for (let i = 0; i < countGroups.length; i++) {
                    var chartData = {
                        count : countGroups[0],
                        attributeName : attributeGroups[0],
                        dataCount : attributeGroups[0].length,
                        reportName : element.reportName,
                        isBarChart : true,
                        eventParamId: eventParamIdSet,
                        id: element.id,
                        horizontalParamId: element.horizontalParamId,
                        source: element.source,
                        veriticalParamId: veriticalParamIdSet,
                        part: 1,
                        columnOutput: element.columnOutput,
                        reportType: element.reportType,
                        xaxisAttributeName: element.xaxisAttributeName ? element.xaxisAttributeName : '',
                        yaxisAttributeName: element.yaxisAttributeName ? element.yaxisAttributeName : '',
                        showToDashboard:element.showToDashboard,
                        horizontalParamType:element.horizontalParamType,
                        defaultSort:element.defaultSort,
                        showDataGreater:element.showDataGreater,
                        filterJson:element.filterJson,
                        category:element.category,
                        countsMap:element.countsMap
                    }      
                    
                    if(attrStatus == 'missedAttribute'){
                        if(this.leadReportChartOptions.length > 0){
                        let checkAvail = this.leadReportChartOptions.find(x=>x.data.id == element.id);
                        if(checkAvail == undefined){
                            this.leadReportChartOptions.push(this.setLeadReportChart(chartData,attrStatus,missedAttr)); 
                            this.waitCount++;
                        } else{
                            for(let i=0; i<this.leadReportChartOptions.length; i++){
                                if(this.leadReportChartOptions[i].data.id == element.id){
                                    this.leadReportChartOptions[i] = this.setLeadReportChart(chartData,attrStatus,missedAttr);
                                }
                            }
                        }
                    } else{
                        if(this.setLeadReportChart(chartData,attrStatus) != undefined){
                            this.leadReportChartOptions.push(this.setLeadReportChart(chartData,attrStatus,missedAttr));  
                            this.waitCount++;
                            }
                    } 
                        return
                    }
                    
                    if(this.leadReportChartOptions.length > 0){
                        let checkAvail = this.leadReportChartOptions.find(x=>x.data.id == element.id);
                        if(checkAvail == undefined){
                            this.leadReportChartOptions.push(this.setLeadReportChart(chartData)); 
                            this.waitCount++;
                        } else{
                            for(let i=0; i<this.leadReportChartOptions.length; i++){
                                if(this.leadReportChartOptions[i].data.id == element.id){
                                    this.leadReportChartOptions[i] = this.setLeadReportChart(element);
                                }
                            }
                        }
                    } else{
                        if(this.setLeadReportChart(chartData) != undefined){
                            this.leadReportChartOptions.push(this.setLeadReportChart(chartData));  
                            this.waitCount++;
                            }
                        //this.leadReportChartOptions.push(this.setLeadReportChart(chartData)); 
                    }                        
                // }
            } else {

                if(attrStatus == 'missedAttribute'){

                    if(this.leadReportChartOptions.length > 0){  
                        let checkAvail = this.leadReportChartOptions.find(x=>x.data.id == element.id);
                                if(checkAvail == undefined){
                                    this.leadReportChartOptions.push(this.setLeadReportChart(element,attrStatus,missedAttr));
                                } else{
                                    for(let i=0; i<this.leadReportChartOptions.length; i++){
                                        if(this.leadReportChartOptions[i].data.id == element.id){
                                            this.leadReportChartOptions[i] = this.setLeadReportChart(element,attrStatus,missedAttr);
                                        }
                                    }
                                }  
                            } else{
                                this.leadReportChartOptions.push(this.setLeadReportChart(element,attrStatus,missedAttr));
                            }
                    return
                }
                
                if(this.leadReportChartOptions.length > 0){  
                    let checkAvail = this.leadReportChartOptions.find(x=>x.data.id == element.id);
                            if(checkAvail == undefined){
                                this.leadReportChartOptions.push(this.setLeadReportChart(element));
                            } else{
                                for(let i=0; i<this.leadReportChartOptions.length; i++){
                                    if(this.leadReportChartOptions[i].data.id == element.id){
                                        this.leadReportChartOptions[i] = this.setLeadReportChart(element);
                                    }
                                }
                            }  
                        } else{
                            this.leadReportChartOptions.push(this.setLeadReportChart(element));
                        }
            }
        } else {
            //this.leadReportChartOptions.push({data:element});    
            if(this.leadReportChartOptions.length > 0){  
            let checkAvail = this.leadReportChartOptions.find(x=>x.data.id == element.id);
                    if(checkAvail == undefined){
                        this.leadReportChartOptions.push(this.setLeadReportChart(element));
                    } else{
                        for(let i=0; i<this.leadReportChartOptions.length; i++){
                            if(this.leadReportChartOptions[i].data.id == element.id){
                                this.leadReportChartOptions[i] = this.setLeadReportChart(element);
                            }
                        }
                    }  
                } else{
                    this.leadReportChartOptions.push(this.setLeadReportChart(element));
                }      
            this.spinnerHide();
        }
    }

    setLeadReportChart(data,attrStatus?,missedAttr?) : Partial<ChartOptions> {   
        console.log(attrStatus);
        console.log(data);
        console.log(missedAttr);
        
        if(data.category){
            let checkCat = this.dataList.find(x=>x.linkRewrite == data.category);
            if(checkCat){
                data.categoryName = checkCat.name;
            }
        }
        
        var optionsBar : Partial<ChartOptions> = {};
        var optionsBarCount : Partial<ChartOptionsCount> = {};
        var optionsPie : Partial<ChartOptions> = {};
        var optionsStacked : Partial<ChartOptions> = {};
        var total = 0;
        var part = '';
        var stackedSeries : any[] = [];
        data.countArr = []
        let storeArrCount = []
        storeArrCount[0]=[]
        part = data.part != undefined ? '-Part '+data.part : '';

        if(attrStatus == 'missedAttribute'){
            data.dataCount = 0;
            data.missedAttribute = true
            if(missedAttr){
                data.misedAttrList = missedAttr.toString();      
                console.log(data);
            }
        }

        if(data.reportType == 0 && data.dataCount > 0){
            if(data.columnOutput == 1 ){
                stackedSeries = data.countsMap ? data.countsMap : [];
            } else {
                stackedSeries = data.countsMap ? data.countsMap : [];
                if(data.countsMap){
                data.countsMap.forEach(element => {
                    total = element.data.reduce((a, b) => a + b, 0);
                    storeArrCount[0].push(element.data);
                });
                } else if(data.count){
                    total =  (eval(data.count).reduce((a, b) => a + b, 0));
                }
                if(storeArrCount[0].length > 0)
                {   
                    let newStore = []
                    storeArrCount[0].forEach(element => {
                        element.forEach(elements => {
                            newStore.push(elements)
                        });
                    });
                    data.countArr = newStore;
                    data.seriesPie =  []
                    let setPieSeries = data.attributeName.split(',');
                    for(let i=0; i<setPieSeries.length; i++){
                        data.seriesPie.push(i);
                    }
                }
            }
        }
        
        optionsBar = {
            loader:true,
            title:{
                text: data.reportName + ' - ' +data.categoryName,
                align: 'left',
                margin:0,
                offsetX: 0,
                offsetY: 10,
                style: {
                    fontSize:'13px',
                }
            },
            series: data.countsMap,
            chart: {
                height: 300,
                type: "bar",
                stacked: true,
                fontFamily:'Open Sans',
                toolbar: {
                    show: true,
                    offsetX:0,
                    offsetY:10,
                    tools: {
                        download: false,
                        pan:false,
                        reset: '<img src="assets/images/redo-alt.svg" width="15" style="display:none;">'
                    }
                },
                animations: {
                    enabled: false
                },
                events: {
                    dataPointSelection: (event, chartContext, config) => {                        
                        this.spinner.show()
                        this.gotoDetailByGraph(data, config.dataPointIndex, config.w.config.series[config.seriesIndex].name);
                    },
                    mounted: (chartContext, config) => {
                        this.spinner.hide()
                    }
                }
            },
            xaxis: {
                categories: typeof(data.attributeName) == 'string' && data.attributeName != "'[]'" ? data.attributeName.split(',') : data.attributeName,
                type: 'category',
                labels : {
                    trim: true,
                    maxHeight: 90,
                    style: {
                        fontSize: '11px'
                    }
                },
                tickPlacement: 'on',
                title: {
                    text: data.xaxisAttributeName,
                }
            },
            yaxis: {
                title: {
                    text: data.yaxisAttributeName
                }
            },
            plotOptions: {
                bar: {
                    horizontal: false
                }
            },
            dataLabels: {
                enabled: false,
                offsetY: 20,
                style: {
                    fontSize: "12px",
                    colors: ["#868686"]
                }
            },
            legend: {
                position: "bottom",
                horizontalAlign: 'center'
            },
            tooltip: {
                custom: function ({ series, seriesIndex, dataPointIndex, w }) {                    
                return `<div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                <div class="apexcharts-tooltip-series-group" style="order: 2; display: flex;">
                <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                <div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-y-label">Total: </span>
                <span class="apexcharts-tooltip-text-y-value">${series
                    .map((s) => s[dataPointIndex])
                    .reduce((a, b) => a + b, 0)}</span>
                </div>
                    </div>
                </div>
                    </div>
                    <div class="apexcharts-tooltip-series-group" style="order: 1; display: flex;"><span class="apexcharts-tooltip-marker"
                            style="background-color: ${
                            w.globals.colors[seriesIndex]
                            };"></span>
                        <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                            <div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-y-label">${
                            w.config.series[seriesIndex].name
                            }: </span>
                                <span class="apexcharts-tooltip-text-y-value">${
                                series[seriesIndex][dataPointIndex]
                                }</span>
                            </div>
                        </div>
                    </div>
                    `;
                },
            },
            colors : ['#008FFB','#4CAF50','#FEB019','#FF4560','#775DD0','#2B908F','#F86624','#F9C80E','#38E6A5','#5C4742'],
            data: data,

        }

        optionsBarCount = {
            loader:true,
            title:{
                text: data.reportName+ ' - ' +data.categoryName,
                align: 'left',
                margin:0,
                offsetX: 0,
                offsetY: 10,
                style: {
                    fontSize:'13px',
                }
            },
            series: [{
                name: data.countsMap,
                data: eval(data.count)
            }],
            chart: {
                height: 250,
                type: "bar",
                fontFamily:'Open Sans',
                toolbar: {
                    show: true,
                    offsetX:0,
                    offsetY:10,
                    tools: {
                        download: false,
                        pan:false,
                        reset: '<img src="assets/images/redo-alt.svg" width="15" style="display:none;">'
                    }
                },
                animations: {
                    enabled: false
                },
                events: {
                    dataPointSelection: (event, chartContext, config) => {
                        this.spinner.show()
                        this.gotoDetailByGraph(data, config.dataPointIndex, config.w.config.series[config.seriesIndex].name);
                    },
                    mounted: (chartContext, config) => {
                        this.spinner.hide()
                    }
                }
            },
            colors: [
                "#CD0941",
                "#DA4171",
            ],
            xaxis: {
                categories: typeof(data.attributeName) == 'string' && data.attributeName != "'[]'" ? data.attributeName.split(',') : data.attributeName,
                type: 'category',
                labels : {
                    trim: true,
                    maxHeight: 90,
                    style: {
                        fontSize: '11px'
                    }
                },
                tickPlacement: 'on',
                title: {
                    text: data.xaxisAttributeName,
                }
            },
            yaxis: {
                title: {
                    text: data.yaxisAttributeName
                }
            },
            plotOptions: {
                bar: {
                    columnWidth: "45%",
                    distributed: true,
                    dataLabels: {
                        position: "top"
                    }
                }
            },
            dataLabels: {
                enabled: true,
                offsetY: -20,
                style: {
                    fontSize: "12px",
                    colors: ["#868686"]
                }
            },
            legend: {
                show: false
            },
            tooltip: {
                custom: function ({ series, seriesIndex, dataPointIndex, w }) {                    
                return `<div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                <div class="apexcharts-tooltip-series-group" style="order: 2; display: flex;">
                <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                <div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-y-label">Total: </span>
                <span class="apexcharts-tooltip-text-y-value">${series
                    .map((s) => s[dataPointIndex])
                    .reduce((a, b) => a + b, 0)}</span>
                </div>
                    </div>
                </div>
                    </div>
                    <div class="apexcharts-tooltip-series-group" style="order: 1; display: flex;"><span class="apexcharts-tooltip-marker"
                            style="background-color: ${
                            w.globals.colors[seriesIndex]
                            };"></span>
                        <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                            <div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-y-label">${
                            w.config.series[seriesIndex].name
                            }: </span>
                                <span class="apexcharts-tooltip-text-y-value">${
                                series[seriesIndex][dataPointIndex]
                                }</span>
                            </div>
                        </div>
                    </div>
                    `;
                },
            },
            data: data
        }

        optionsStacked = {
            loader:true,
            title:{
                text: data.reportName+ ' - ' +data.categoryName,
                align: 'left',
                margin:0,
                offsetX: 0,
                offsetY: 10,
                style: {
                    fontSize:'13px',
                }
            },
            series: stackedSeries,
            chart: {
                height: 250,
                type: "bar",
                stacked: true,
                fontFamily:'Open Sans',
                toolbar: {
                    show: true,
                    offsetX:0,
                    offsetY:10,
                    tools: {
                        download: false,
                        pan:false,
                        reset: '<img src="assets/images/redo-alt.svg" width="15" style="display:none;">'
                    }
                },
                animations: {
                    enabled: false
                },
                events: {
                    dataPointSelection: (event, chartContext, config) => {                        
                        this.spinner.show()
                        this.gotoDetailByGraph(data, config.dataPointIndex,config.seriesIndex);
                    },
                    mounted: (chartContext, config) => {
                        this.spinner.hide()
                    }
                }
            },
            xaxis: {
                categories: typeof(data.attributeName) == 'string' && data.attributeName != "'[]'" ? data.attributeName.split(',') : data.attributeName,
                type: 'category',
                labels : {
                    trim: true,
                    maxHeight: 90,
                    style: {
                        fontSize: '11px'
                    }
                },
                tickPlacement: 'on',
                title: {
                    text: data.xaxisAttributeName,
                }
            },
            yaxis: {
                title: {
                  text: data.yaxisAttributeName
                }
            },
            plotOptions: {
                bar: {
                    horizontal: false
                }
            },
            dataLabels: {
                enabled: false,
                offsetY: -20,
                style: {
                    fontSize: "12px",
                    colors: ["#868686"]
                }
            },
            legend: {
                position: "bottom",
                horizontalAlign: 'center'
            },
            tooltip: {
                custom: function ({ series, seriesIndex, dataPointIndex, w }) {                    
                return `<div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                <div class="apexcharts-tooltip-series-group" style="order: 2; display: flex;">
                <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                <div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-y-label">Total: </span>
                <span class="apexcharts-tooltip-text-y-value">${series
                    .map((s) => s[dataPointIndex])
                    .reduce((a, b) => a + b, 0)}</span>
                </div>
                    </div>
                </div>
                    </div>
                    <div class="apexcharts-tooltip-series-group" style="order: 1; display: flex;"><span class="apexcharts-tooltip-marker"
                            style="background-color: ${
                            w.globals.colors[seriesIndex]
                            };"></span>
                        <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                            <div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-y-label">${
                            w.config.series[seriesIndex].name
                            }: </span>
                                <span class="apexcharts-tooltip-text-y-value">${
                                series[seriesIndex][dataPointIndex]
                                }</span>
                            </div>
                        </div>
                    </div>
                    `;
                },
            },
            colors : ['#008FFB','#4CAF50','#FEB019','#FF4560','#775DD0','#2B908F','#F86624','#F9C80E','#38E6A5','#5C4742'],
            data: data
        }

        optionsPie = {
            loader:true,
            title:{
                text: data.reportName+ ' - ' +data.categoryName,
                align: 'left',
                margin:15,
                offsetX: 0,
                offsetY: 0,
                style: {
                    fontSize:'13px',
                }
            },
            seriesNon: data.count ?data.count :data.countArr,
            chart: {
                width: '100%',
                height: 215,
                type: "pie",
                events: {
                    dataPointSelection: (event, chartContext, config) => {
                        this.spinner.show()
                        this.gotoDetailByGraph(data, config.dataPointIndex, config.w.config.series[config.seriesIndex].name);
                    },
                    mounted: (chartContext, config) => {
                        this.spinner.hide()
                    }
                }
            },
            legend: {
                show: true,
                offsetY: 10,
            },
            labels: typeof(data.attributeName) == 'string' && data.attributeName != "'[]'" ? data.attributeName.split(',') : data.attributeName,
            stroke: {
                width: 0,
            },
            dataLabels: {
                enabled: true
            },
            data: data
        }
        
        if(data.reportType == 0 && data.columnOutput == 0){
            if(data.isBarChart){
                if(data.countsMap){
                    setTimeout(() => {
                        optionsBar.loader = false;
                    }, 1000);
                    return optionsBar;
                } else {
                    setTimeout(() => {
                        optionsBarCount.loader = false
                    }, 1000);
                    return optionsBarCount
                }
            } else {
                setTimeout(() => {
                    optionsPie.loader = false;
                }, 1000);
                return optionsPie;
            }
        } else if(data.reportType == 0 && data.columnOutput == 1) {
            setTimeout(() => {
                optionsStacked.loader = false
            }, 1000);
            return optionsStacked
        } else {
            return ;
        }
        
    }

    showDeleteModal(item){
        this.selectedleadReportId = item;
        this.modalRef = this.modalService.show(this.leadReportDeleteConfirm, {
            backdrop: 'static'
        });
    }

    deleteLeadReportChart(){
        this.reportService.deleteReport({id:this.selectedleadReportId.data.id}, this.selectedleadReportId.data.category).subscribe(data => {
            if(data.status){
                this.modalRef.hide();
                this.toastr.success(data.data,'Success');
                this.reportService.leadReportsData = undefined;
                this.fetchLeadReports();
            } else {
                this.toastr.error(data.error_msgs,'Error');
            }
        });
    }

    fetchCategory() {        
        this.dataList = [];
        this.projectService.fetchCategory({}).then(data => {
            if (data) {
                const dataList = data;
                let id = this.authenticService.getUserId();
                if(this.authenticService.getUserRole() != 1){
                for(let i=0; i<dataList.length; i++){
                    if(dataList[i]){
                    let getUserList = dataList[i].userList.find(userid=>userid == id);
                    if(getUserList){
                        let checkToPush = this.dataList.find(x=>x.linkRewrite == dataList[i].linkRewrite);
                        if(checkToPush == undefined){
                            this.dataList.push(dataList[i])
                        }                  
                    }
                }
                }
            } else{
                this.dataList = dataList
              }
                let checkCatName = localStorage.getItem('dashboardCatName');
                if(checkCatName){
                    this.categoryName = checkCatName;                    
                }else{
                    let checkForAudCat = this.dataList.find(x=>x.linkRewrite == 'audience');
                    if(checkForAudCat){
                        this.categoryName='audience';
                    } else{
                        this.categoryName=this.dataList[0].linkRewrite;
                    }
                }                
            }
        })
    }

    getLeadByCat(e){
    }

    fetchReport(data){
        this.setReportData(data);
    };

    setReportData(data){
        this.refilEditVal = JSON.stringify(data);
        this.modalRef = this.modalService.show(this.addLeadReports, { class: "modal-rightblock", backdrop: 'static' });
    }

    recordUpdateAdded(event){        
        if(event == 'recordSuccess'){
        this.fetchLeadReports()
        }
    }

    fetchUserProfile(){
        this.authenticService.getProfile().then(data=>{
            if(data.status){
                this.sortByDate = 'params.'+data.sortBy;
            }
        })
    }

    //@kapil 1124
    getFollowData() {
        this.reportService.followUpData(this.followUpfilterDateRange,this.sortByDate,"").subscribe(data => {
            if (data.status) {
                this.followUpList = data.data;                
                this.followUpList.forEach(element => {
                    element.userIdEncode = encodeURIComponent(element.userId);
                });
                this.totalToday = this.followUpList.reduce((accumulator, current) => accumulator + current.today, 0);
                this.totalMissed = this.followUpList.reduce((accumulator, current) => accumulator + current.missed, 0);
                this.totalCompleted = this.followUpList.reduce((accumulator, current) => accumulator + current.completed, 0);
                this.totalOverdue = this.followUpList.reduce((accumulator, current) => accumulator + current.upcoming, 0);
                this.followReportResponse = false;
            }
        }, (error) => {
            console.log(error);
        })
    }

    showFollowUpReport()
    {
        this.followUpReportShow = true;
    }

    vertical_tab(position,table)
    {        
        if(table == 'followUp')
        {
        if(position == 'vertical')
        {
            this.positionOfTblFollow = 'horizontal';
        }
        if(position == 'horizontal')
        {
            this.positionOfTblFollow = 'vertical';
        }
        }

        if(table == 'leadReport')
        {            
        if(position == 'vertical')
        {            
            this.positionOfTblLeadReport = 'horizontal';
        }
        if(position == 'horizontal')
        {            
            this.positionOfTblLeadReport = 'vertical';
        }
        }

        if(table == 'leadSource')
        {            
        if(position == 'vertical')
        {            
            this.positionOfTbSource = 'horizontal';
        }
        if(position == 'horizontal')
        {            
            this.positionOfTbSource = 'vertical';
        }
        }
        
    }

    updateFollowUpDateRange() {
        this.followUpFromDate = this.datePipe.transform(this.followUpfilterDateRange.startDate, AppConstants.DATE_FORMAT_FOR_DATEPICKER);
        this.followUpToDate = this.datePipe.transform(this.followUpfilterDateRange.endDate, AppConstants.DATE_FORMAT_FOR_DATEPICKER);
        this.followUpCompleteToDate =  this.followUpToDate;
        this.missedToDate = this.datePipe.transform(this.followUpfilterDateRange.endDate, AppConstants.DATE_FORMAT_FOR_DATEPICKER);
       
        if(this.todayDate ==  this.followUpToDate){
            let tmpFollowUpdate: any = new Date();
            this.missedToDate = this.datePipe.transform( tmpFollowUpdate.setDate(tmpFollowUpdate.getDate() - 1), AppConstants.DATE_FORMAT_FOR_DATEPICKER);
            let tmpCompleteTodayDate: any = new Date();
            this.followUpCompleteToDate = this.datePipe.transform( tmpCompleteTodayDate, AppConstants.DATE_FORMAT_FOR_DATEPICKER);
     
        }
        this.getFollowData();
    }

    downloadFile() {
        const ws = XLSX.utils.table_to_sheet(this.followUpReports.nativeElement, { raw: true });
       // const ws2 = XLSX.utils.table_to_sheet(this.leadReport.nativeElement, { raw: true });
       // const ws3 = XLSX.utils.table_to_sheet(this.sourceReports.nativeElement, { raw: true });

        let a = XLSX.utils.sheet_to_json(ws, { header: 1 });
        //let b = XLSX.utils.sheet_to_json(ws2, { header: 1 });
        //let c = XLSX.utils.sheet_to_json(ws3, { header: 1 });

       // a = a.concat([]).concat(b).concat(c);
        let worksheet = XLSX.utils.json_to_sheet(a, { skipHeader: true })


        const wb: XLSX.WorkBook = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(wb, worksheet, 'Sheet1');
        /* save to file */
        XLSX.writeFile(wb, 'SheetJS.xlsx');
    }


    setChart(){        
        if(this.campaignStatsDataAndroid){
        this.chartOptionsAndroid = {
                titleAndroid:{
                    text: 'Android',
                    align: 'left',
                    margin:0,
                    offsetX: 0,
                    offsetY: 10,
                    style: {
                        fontSize:'13px',
                    }
                },
                seriesAndroid: [{
                    name: "Stats",
                    data: eval(this.campaignStatsDataAndroid.push)
                }],
                
                chart: {
                    height: 200,
                    type: 'line',
                    zoom: {enabled: false},
                    fontFamily:'Open Sans',
                    toolbar: {show: false},
                },
                stroke: {
                    curve: 'smooth',
                    width: 1,
                    colors: ['#CB0C44']
                },
                xaxis: {
                    categories: eval(this.campaignStatsDataAndroid.dates),
                    type: 'datetime',
                    labels: {
                        datetimeUTC:true,
                        format:'dd MMM',
                        style: {
                            fontSize: '11px'
                        }
                    },
                }
            };
            }   

            if(this.campaignStatsDataIos){

            this.chartOptionsIos = {
                titleIos:{
                    text: 'Ios',
                    align: 'left',
                    margin:0,
                    offsetX: 0,
                    offsetY: 10,
                    style: {
                        fontSize:'13px',
                    }
                },
                seriesIos: [{
                    name: "Stats",
                    data: eval(this.campaignStatsDataIos.push)
                }],
                
                chart: {
                    height: 200,
                    type: 'line',
                    zoom: {enabled: false},
                    fontFamily:'Open Sans',
                    toolbar: {show: false},
                },
                stroke: {
                    curve: 'smooth',
                    width: 1,
                    colors: ['#CB0C44']
                },
                xaxis: {
                    categories: eval(this.campaignStatsDataIos.dates),
                    type: 'datetime',
                    labels: {
                        datetimeUTC:true,
                        format:'dd MMM',
                        style: {
                            fontSize: '11px'
                        }
                    },
                }
            };
            }


            if(this.campaignStatsDataWeb){

                this.chartOptionsWeb = {
                    titleWeb:{
                        text: 'Web',
                        align: 'left',
                        margin:0,
                        offsetX: 0,
                        offsetY: 10,
                        style: {
                            fontSize:'13px',
                        }
                    },
                    seriesWeb: [{
                        name: "Stats",
                        data: eval(this.campaignStatsDataWeb.push)
                    }],
                    
                    chart: {
                        height: 200,
                        type: 'line',
                        zoom: {enabled: false},
                        fontFamily:'Open Sans',
                        toolbar: {show: false},
                    },
                    stroke: {
                        curve: 'smooth',
                        width: 1,
                        colors: ['#CB0C44']
                    },
                    xaxis: {
                        categories: eval(this.campaignStatsDataWeb.dates),
                        type: 'datetime',
                        labels: {
                            datetimeUTC:true,
                            format:'dd MMM',
                            style: {
                                fontSize: '11px'
                            }
                        },
                    }
                };
                }

                if(this.campaignStatsDataEmail){

                    this.chartOptionsEmail = {
                        titleEmail:{
                            text: 'Email',
                            align: 'left',
                            margin:0,
                            offsetX: 0,
                            offsetY: 10,
                            style: {
                                fontSize:'13px',
                            }
                        },
                        seriesEmail: [{
                            name: "Stats",
                            data: eval(this.campaignStatsDataEmail.email)
                        }],
                        
                        chart: {
                            height: 200,
                            type: 'line',
                            zoom: {enabled: false},
                            fontFamily:'Open Sans',
                            toolbar: {show: false},
                        },
                        stroke: {
                            curve: 'smooth',
                            width: 1,
                            colors: ['#CB0C44']
                        },
                        xaxis: {
                            categories: eval(this.campaignStatsDataEmail.dates),
                            type: 'datetime',
                            labels: {
                                datetimeUTC:true,
                                format:'dd MMM',
                                style: {
                                    fontSize: '11px'
                                }
                            },
                        }
                    };
                    }
                    
                    
                    if(this.campaignStatsDataSms){

                        this.chartOptionsSms = {
                            titleSms:{
                                text: 'SMS',
                                align: 'left',
                                margin:0,
                                offsetX: 0,
                                offsetY: 10,
                                style: {
                                    fontSize:'13px',
                                }
                            },
                            seriesSms: [{
                                name: "Stats",
                                data: eval(this.campaignStatsDataSms.sms)
                            }],
                            
                            chart: {
                                height: 200,
                                type: 'line',
                                zoom: {enabled: false},
                                fontFamily:'Open Sans',
                                toolbar: {show: false},
                            },
                            stroke: {
                                curve: 'smooth',
                                width: 1,
                                colors: ['#CB0C44']
                            },
                            xaxis: {
                                categories: eval(this.campaignStatsDataSms.dates),
                                type: 'datetime',
                                labels: {
                                    datetimeUTC:true,
                                    format:'dd MMM',
                                    style: {
                                        fontSize: '11px'
                                    }
                                },
                            }
                        };
                        }
                    
                    
                    if(this.campaignStatsDataWhatsapp){

                        this.chartOptionsWhatsapp = {
                            titleWhatsapp:{
                                text: 'Whatsapp',
                                align: 'left',
                                margin:0,
                                offsetX: 0,
                                offsetY: 10,
                                style: {
                                    fontSize:'13px',
                                }
                            },
                            seriesWhatsapp: [{
                                name: "Stats",
                                data: eval(this.campaignStatsDataWhatsapp.push)
                            }],
                            
                            chart: {
                                height: 200,
                                type: 'line',
                                zoom: {enabled: false},
                                fontFamily:'Open Sans',
                                toolbar: {show: false},
                            },
                            stroke: {
                                curve: 'smooth',
                                width: 1,
                                colors: ['#CB0C44']
                            },
                            xaxis: {
                                categories: eval(this.campaignStatsDataWhatsapp.dates),
                                type: 'datetime',
                                labels: {
                                    datetimeUTC:true,
                                    format:'dd MMM',
                                    style: {
                                        fontSize: '11px'
                                    }
                                },
                            }
                        };
                    } 

                    if(this.campaignStatsDataInapp){

                        this.chartOptionsInapp = {
                            titleInapp:{
                                text: 'Whatsapp',
                                align: 'left',
                                margin:0,
                                offsetX: 0,
                                offsetY: 10,
                                style: {
                                    fontSize:'13px',
                                }
                            },
                            seriesInapp: [{
                                name: "Stats",
                                data: eval(this.campaignStatsDataInapp.push)
                            }],
                            
                            chart: {
                                height: 200,
                                type: 'line',
                                zoom: {enabled: false},
                                fontFamily:'Open Sans',
                                toolbar: {show: false},
                            },
                            stroke: {
                                curve: 'smooth',
                                width: 1,
                                colors: ['#CB0C44']
                            },
                            xaxis: {
                                categories: eval(this.campaignStatsDataInapp.dates),
                                type: 'datetime',
                                labels: {
                                    datetimeUTC:true,
                                    format:'dd MMM',
                                    style: {
                                        fontSize: '11px'
                                    }
                                },
                            }
                        };
                    } 


        // this.chartOptions = {
        //     titleSMS:{
        //         text: 'SMS',
        //         align: 'left',
        //         margin:0,
        //         offsetX: 0,
        //         offsetY: 10,
        //         style: {
        //             fontSize:'13px',
        //         }
        //     },
        //     titleEmail:{
        //         text: 'Email',
        //         align: 'left',
        //         margin:0,
        //         offsetX: 0,
        //         offsetY: 10,
        //         style: {
        //             fontSize:'13px',
        //         }
        //     },
        //     titlePush:{
        //         text: 'Push',
        //         align: 'left',
        //         margin:0,
        //         offsetX: 0,
        //         offsetY: 10,
        //         style: {
        //             fontSize:'13px',
        //         }
        //     },
        //     titleInApp:{
        //         text: 'In App',
        //         align: 'left',
        //         margin:0,
        //         offsetX: 0,
        //         offsetY: 10,
        //         style: {
        //             fontSize:'13px',
        //         }
        //     },
        //     seriesSMS: [{
        //         name: "Stats",
        //         data: eval(this.campaignStatsData.sms)
        //     }],
        //     seriesEmail: [{
        //         name: "Stats",
        //         data: eval(this.campaignStatsData.email)
        //     }],
        //     seriesPush: [{
        //         name: "Stats",
        //         data: eval(this.campaignStatsData.push)
        //     }],
        //     seriesInApp: [{
        //         name: "Stats",
        //         data: eval(this.campaignStatsData.inApp)
        //     }],
        //     chart: {
        //         height: 200,
        //         type: 'line',
        //         zoom: {enabled: false},
        //         fontFamily:'Open Sans',
        //         toolbar: {show: false},
        //     },
        //     stroke: {
        //         curve: 'smooth',
        //         width: 1,
        //         colors: ['#CB0C44']
        //     },
        //     xaxis: {
        //         categories: eval(this.campaignStatsData.dates),
        //         type: 'datetime',
        //         labels: {
        //             datetimeUTC:true,
        //             format:'dd MMM',
        //             style: {
        //                 fontSize: '11px'
        //             }
        //         },
        //     }
        // };

        this.loader = false
    }

    setIndividualChart(type){
        let datesPropertyByType;
        if(type == 'android'){
            datesPropertyByType = this.campaignStatsDataAndroid
        } else if(type == 'ios'){
            datesPropertyByType = this.campaignStatsDataIos
        } else if(type == 'web'){
            datesPropertyByType = this.campaignStatsDataWeb
        } else if(type == 'email'){
            datesPropertyByType = this.campaignStatsDataEmail
        } else if(type == 'sms'){
            datesPropertyByType = this.campaignStatsDataSms
        } else if(type == 'whatsapp'){
            datesPropertyByType = this.campaignStatsDataWhatsapp
        }
        this.chartIndividualOptions = {
            series: this.campaignIndividualSeries,
            chart: {
                height: 220,
                type: 'line',
                zoom: {enabled: false},
                fontFamily:'Open Sans',
                toolbar: {show: false},
            },
            stroke: {
                curve: 'straight',
                width: 1,
            },
            xaxis: {
                categories: eval(datesPropertyByType.dates),
                type: 'datetime',
                labels: {
                    datetimeUTC:true,
                    format:'dd MMM',
                }
            },
        };
    }

    setLeadChart(){        
        this.leadsChartOptions = {
            series: [{
                name: "Leads",
                data: eval(this.leadsStatsData.leadsCollected)
            }],
            title:{
                text: 'Leads Collected',
                align: 'left',
                margin:0,
                offsetY: 0,
                style: {
                    fontSize:'13px',
                }
            },
            chart: {
                height: 200,
                type: "bar",
                zoom: {enabled: false},
                fontFamily:'Open Sans',
                toolbar: {show: false},
                events: {
                    dataPointSelection: (event, chartContext, config) => {
                        // if(this.userRoleId == AppConstants.ADMIN_ROLEID)
                        this.gotoDetail(config.dataPointIndex);
                    }
                }
            },
            colors: [
                "#CD0941",
                "#DA4171",
            ],
            plotOptions: {
                bar: {
                    columnWidth: "45%",
                    distributed: true,
                    dataLabels: {
                        position: "top"
                    }
                }
            },
            dataLabels: {
                enabled: true,
                offsetY: -20,
                style: {
                    fontSize: "11px",
                    colors: ["#868686"]
                }
            },
            legend: {
                show: false
            },
            grid: {
                show: false
            },
            xaxis: {
                
                categories: eval(this.leadsStatsData.dates),
                type: 'datetime',
                labels: {
                    showDuplicates:false,
                    datetimeUTC:true,
                    format:'dd MMM',
                    style: {
                        fontSize: '11px'
                    }
                }
            }
        };
    }
  
    showChart(type){
        this.campaignIndividualSeries = [];
        var campType: string[] = [];
        let url;
        if(type == 'email'){
            this.campaignChartTitle = 'Email'
            campType.push('email');
            url = this.emailApiUrl
            // campType.push('android');
            // campType.push('ios');
            // campType.push('web');
        } else if(type == 'sms') {
            url = this.smsApiUrl
            this.campaignChartTitle = 'Sms'
            campType.push(type);
        } else if(type == 'whatsapp') {
            url = this.whatsappApiUrl
            this.campaignChartTitle = 'Whatsapp'
            campType.push(type);
        } else if(type == 'android') {
            url = this.pushApiUrl
            this.campaignChartTitle = 'Android'
            campType.push(type);
        } else if(type == 'ios') {
            url = this.pushApiUrl
            this.campaignChartTitle = 'IOS'
            campType.push(type);
        } else if(type == 'web') {
            url = this.pushApiUrl
            this.campaignChartTitle = 'Web'
            campType.push(type);
        } else if(type == 'inapp') {
            url = this.inAppApiUrl
            this.campaignChartTitle = 'Inapp'
            campType.push(type);
        }
        var postData = {
            fromDate: this.datePipe.transform(this.campaignStatsDays.startDate, "yyyy-MM-dd"),
            toDate: this.datePipe.transform(this.campaignStatsDays.endDate, "yyyy-MM-dd"),
            campType: campType
        }
        this.dashboardService.fetchCampaignStatsIndividual(postData,url).subscribe(data => {
            if (data.status) {
                this.campaignStatsIndividualData = data.data.value;
                this.setIndividualData(type);
                
            }
        });
    }

    setIndividualData(type){
        this.campaignStatsIndividualData.forEach(list => {
            this.campaignIndividualSeries.push({
                'name':list.name,
                'data':eval(list.data)
            })
        });
        this.modalRef = this.modalService.show(this.campaignChart, {
            backdrop: 'static',
            class: "modal-xl chart-modal"
        });
        this.setIndividualChart(type);
    }

     fetchCampaignStats(event,reload?){

        let fromDate:any = this.campaignStatsDays.startDate;
        let endDate:any =  this.campaignStatsDays.endDate;
        let differenceBetweenDate = Math.floor((endDate - fromDate) / (1000 * 60 * 60 * 24));
        differenceBetweenDate = differenceBetweenDate + 1;        
        if(differenceBetweenDate > this.maxRange){
            this.toastr.error('Max date range should not be greater than ' + this.maxRange + ' days');
            return
        }

       // this.spinner.show()
        if(this.emailisCampaignStatsShow || this.smsisCampaignStatsShow || this.whatsappisCampaignStatsShow || this.inAppisCampaignStatsShow || this.androidisCampaignStatsShow || this.iosisCampaignStatsShow || this.webisCampaignStatsShow)
        {            
            let url;
            var campType: string[] = [];
            this.loader = event;
            if(event == 'email'){
                url = this.emailApiUrl;
                this.emailLoading = true;
            } else if(event == 'sms'){
                url = this.smsApiUrl
                this.smsLoading = true
            } else if(event == 'inapp'){
                url = this.inAppApiUrl
                this.inAppLoading = true;
            } else if(event == 'ios'){
                campType.push(event)
                url = this.pushApiUrl
                this.iosLoading = true
            } else if(event == 'android'){
                campType.push(event)
                url = this.pushApiUrl
                this.androidLoading = true
            } else if(event == 'web'){
                campType.push(event)
                url = this.pushApiUrl
                this.webLoading = true
            } else if(event == 'whatsapp'){
                url = this.whatsappApiUrl
                this.whatsappLoading = true;
            }

            if(event == 'dateUpdate'){
                let getShowChart = [];                
                if(this.emailisCampaignStatsShow){
                    getShowChart.push({'event':'email','api':this.emailApiUrl})
                }
                if(this.smsisCampaignStatsShow){                    
                    getShowChart.push({'event':'sms','api':this.smsApiUrl})
                }
                if(this.whatsappisCampaignStatsShow){
                    getShowChart.push({'event':'whatsapp','api':this.whatsappApiUrl})
                }
                if(this.androidisCampaignStatsShow){
                    getShowChart.push({'event':'android','api':this.pushApiUrl})
                }
                if(this.iosisCampaignStatsShow){
                    getShowChart.push({'event':'ios','api':this.pushApiUrl})
                } 
                if(this.webisCampaignStatsShow){
                    getShowChart.push({'event':'web','api':this.pushApiUrl})
                }                

                if(getShowChart.length > 0)
                {
                    for(let i=0; i<getShowChart.length; i++){
                        
                        this.dashboardService.getCampaignStatsData(this.campaignStatsDays,getShowChart[i].api, JSON.stringify(campType), reload).then(data => {
                            if (data != undefined) {
                                if(getShowChart[i].event == 'android'){
                                    this.loader = getShowChart[i].event;
                                    this.campaignStatsDataAndroid = data;
                                }
                                if(getShowChart[i].event == 'ios'){
                                    this.loader = getShowChart[i].event;
                                    this.campaignStatsDataIos = data;
                                }
                                if(getShowChart[i].event == 'web'){
                                    this.loader = getShowChart[i].event;
                                    this.campaignStatsDataWeb = data;
                                }
                                if(getShowChart[i].event == 'email'){
                                    this.loader = getShowChart[i].event;
                                    this.campaignStatsDataEmail = data;
                                }
                                if(getShowChart[i].event == 'sms'){
                                    this.loader = getShowChart[i].event;
                                    this.campaignStatsDataSms = data;
                                }
                                if(getShowChart[i].event == 'whatsapp'){
                                    this.loader = getShowChart[i].event;
                                    this.campaignStatsDataWhatsapp = data;
                                }
                                this.isCampaignStatsShow = true;
                                this.setChart();
                               // this.spinner.hide();
                            }
                        });
                    }
                }

                return
            }
            
        this.dashboardService.getCampaignStatsData(this.campaignStatsDays,url,JSON.stringify(campType),reload).then(data => {
            if (data != undefined) {
                if(event == 'android'){
                    this.campaignStatsDataAndroid = data;
                } else if(event == 'ios'){                    
                    this.campaignStatsDataIos = data;
                } else if(event == 'web'){
                    this.campaignStatsDataWeb = data;
                } else if(event == 'email'){
                    this.campaignStatsDataEmail = data;
                } else if(event == 'sms'){
                    this.campaignStatsDataSms = data;
                } else if(event == 'whatsapp'){                    
                    this.campaignStatsDataWhatsapp = data;
                } else if(event == 'inapp'){
                    this.campaignStatsDataInapp = data;
                }
                
                this.isCampaignStatsShow = true;
                this.setChart();
               // this.spinner.hide();
            }
        });
        }
    }

    catSelected(){
        localStorage.setItem('dashboardCatName',this.categoryName);
    }

    fetchLeadsStats(){
        let fromDate:any = this.leadsDays.startDate;
        let endDate:any =  this.leadsDays.endDate;
        let differenceBetweenDate = Math.floor((endDate - fromDate) / (1000 * 60 * 60 * 24));
        differenceBetweenDate = differenceBetweenDate + 1;        
        if(differenceBetweenDate > this.maxRange){
            this.toastr.error('Max date range should not be greater than ' + this.maxRange + ' days');
            return
        }
        

        this.loader = 'leadsCollected';
        setTimeout(() => {

        this.dashboardService.getLeadsCollectedData(this.leadsDays, this.leadsSource, 'params.entry_date',this.categoryName).then(data => {
            if (data != undefined) {                
                this.leadsStatsData = data;
                if(eval(this.leadsStatsData.dates).length == 0 && eval(this.leadsStatsData.leadsCollected).length == 0){
                    this.leadCollectedNotAvail = true;
                } else
                {
                    this.leadCollectedNotAvail = false;
                }
                this.isShowleadsStats = true;
                this.setLeadChart();
                this.loader = false;
            }
        });
        }, 1000);
    }

    getSourceList(){
        this.projectService.fetchAttributeList().then( data => {
            if(data){
                this.sourseList = this.projectService.getSourceList();
                this.sourseList = this.sourseList.filter(p=>{
                    return p.assignMappingDTOs && p.assignMappingDTOs.length > 0
                })
            }
        });
    };

    getProjectDetail(){
        if(this.projectService.projectDetails != undefined && this.projectService.projectDetails.projectName != undefined){
            this.projectDetails = this.projectService.projectDetails;
            this.userName = this.authenticationService.getUserData().userName;
            this.isShowMAUChart = true;
            if(this.projectDetails.pricingPlans != undefined){
                // this.isExpired = new Date() > new Date(this.projectDetails.pricingPlans.renewDate);
                this.setMauChart();
            }
        } else {
            setTimeout(() => {
                this.getProjectDetail();
            }, 500);
        }
    }

    setMauChart(){
        var used = this.projectDetails.mauUsed;
        var remaining = this.projectDetails.mauLimit - this.projectDetails.mauUsed
        this.pieChartOptions = {
            seriesNon: [remaining, used],
            chart: {
                width: 100,
                height: 100,
                type: "pie"
            },
            dataLabels: {
                enabled: false
            },
            legend: {
                show: false
            },
            labels: ["Remaining MAU", "Used MAU"],
            colors: [
                "#CD0941",
                "#333333"
            ],
            stroke: {
                width: 0,
            }            
        };
    }

    gotoDetail(i){        
        var sourceId = ''
        if(this.leadsSource != '')
        this.sourseList.forEach( element => {
            if(element.pluginName == this.leadsSource){
                sourceId = element.pluginId;
            }
        });
        let storeNoParams = this.sortByDate
        if(this.sortByDate.includes('params.') == true){
            storeNoParams = this.sortByDate.split('params.')[1];
        }
        
        var date = eval(this.leadsStatsData.dates)[i];
        this.audienceFilter.push({paramKey:'entry_date',paramValue:date,text1:date,text2:date,paramDatatype:'Date'})
        this.audienceFilter.push({paramKey:'source',paramValue:this.leadsSource,text1: JSON.stringify(sourceId)});
        localStorage.setItem('filterData',JSON.stringify(this.audienceFilter));
        setTimeout(() => {
            //this.router.navigate(['/admin/audience/view-audience/source/'+date+'/'+date+'/'+this.leadsSource+'&&'+sourceId],{queryParams:{dataFromDb:true,datetype:this.sortByDate}});
         
            this.ngZone.run(() => {
            this.router.navigate(['/admin/lead/'+this.leadsStatsData.category]);
            });
        }, 100);
    }

    gotoDetailByGraph(data,i,seriesIndex?){
        this.audienceFilter = [];
        let filterJson = data.filterJson ? JSON.parse(data.filterJson) : [];
        let pluginFormIds = ""
        var attributeName = data.attributeName.split(",")[i].replaceAll("'","");
        data.horizontalParamId = data.horizontalParamId.includes('params.') == true ? data.horizontalParamId.split('.')[1]:data.horizontalParamId;
        if(filterJson.pluginFormIds != null){
         pluginFormIds = filterJson.pluginFormIds.join();
        }
        if(filterJson.paramList){
        if(filterJson.paramList.length > 0){
            for(let i=0; i<filterJson.paramList.length; i++){
                let paramKey = filterJson.paramList[i].paramKey.includes('params.') == true ? filterJson.paramList[i].paramKey.replace('params.','') : filterJson.paramList[i].paramKey;
                if(data.horizontalParamId == paramKey){
                    delete filterJson.paramList[i];
                }
                if(filterJson.paramList[i] != null){
                    if(typeof(filterJson.paramList[i].paramValue) == 'string'){
                if(filterJson.paramList[i].paramValue.includes('date(') == true){
                    let date = filterJson.paramList[i].paramValue.split('and');
                    let fromDate = date[0].replace("date('","").replace("')","");
                    let toDate = date[1].replace("date('","").replace("')","");
                    this.audienceFilter.push({paramKey:paramKey,paramValue:filterJson.paramList[i].paramValue, text1:fromDate, text2:toDate,paramOprator:filterJson.paramList[i].paramOprator});
                } else{
                   // filterJson.paramList[i].paramValue = filterJson.paramList[i].paramValue.replaceAll("'","");
                    this.audienceFilter.push({paramKey:paramKey,paramValue:filterJson.paramList[i].paramValue, text1:filterJson.paramList[i].paramValue,paramOprator:filterJson.paramList[i].paramOprator});
                }
                }
                }
                
            }
        }
    }
                
        
        var source = []
        let sourceMulti = data.source.split(',')
        
        
        this.sourseList.forEach( element => {
            let checkSource = sourceMulti.find(x=>x == element.pluginId);
            if(checkSource){
                source.push(element.pluginName);
            }
        });
        let sourcename = '';
        if(source.length > 0){
            sourcename = source.join();
        }        
        var fromDate = this.datePipe.transform(this.leadReportDaysInput.startDate, "yyyy-MM-dd");
        var toDate = this.datePipe.transform(this.leadReportDaysInput.endDate, "yyyy-MM-dd")
        setTimeout(() => {
                let paramsEventId = data.eventParamId.includes('params.') == true ? data.eventParamId.replace('params.','') : data.eventParamId;
                let removeParamsVerticalId = data.veriticalParamId.includes('params.') == true ? data.veriticalParamId.replace('params.','') : data.veriticalParamId;
                if(paramsEventId == data.horizontalParamId)
                {
                    paramsEventId = "";
                }
            if(seriesIndex != undefined && typeof(seriesIndex) == 'number'){
                let checkData = data.count ? data.count : data.countsMap
                let checkName = checkData != undefined ? checkData[seriesIndex].name : ''
                this.spinner.hide()
                
                this.audienceFilter.push({paramKey:paramsEventId,paramValue:'date(' + this.datePipe.transform(fromDate, "yyyy-MM-dd 00:00:00") + ') and date(' + this.datePipe.transform(toDate, "yyyy-MM-dd 23:59:59") +')',text1:fromDate,text2:toDate,paramDatatype:'Date'});
                this.audienceFilter.push({paramKey:data.horizontalParamId,paramValue:attributeName, text1:attributeName})
                this.audienceFilter.push({paramKey:'source',paramValue:sourcename,text1:data.source});
                this.audienceFilter.push({paramKey:removeParamsVerticalId,paramValue:checkName,text1:checkName});
                this.audienceFilter.push({paramKey:'pluginFormIds',paramValue:pluginFormIds});
                localStorage.setItem('filterData',JSON.stringify(this.audienceFilter));
                this.ngZone.run(() => {
            
                this.router.navigate(['/admin/lead/'+data.category]);
             });
            } else {
                let checkData = data.count ? data.count : data.countsMap
                let checkName = seriesIndex ? seriesIndex : '';
                this.spinner.hide()
                this.audienceFilter.push({paramKey:paramsEventId,paramValue:'date(' + this.datePipe.transform(fromDate, "yyyy-MM-dd 00:00:00") + ') and date(' + this.datePipe.transform(toDate, "yyyy-MM-dd 23:59:59") +')',text1:fromDate,text2:toDate,paramDatatype:'Date'});
                this.audienceFilter.push({paramKey:data.horizontalParamId,paramValue:attributeName, text1:attributeName})
                this.audienceFilter.push({paramKey:'source',paramValue:sourcename,text1:data.source});
                this.audienceFilter.push({paramKey:removeParamsVerticalId,paramValue:checkName,text1:checkName});
                this.audienceFilter.push({paramKey:'pluginFormIds',paramValue:pluginFormIds});
                localStorage.setItem('filterData',JSON.stringify(this.audienceFilter));                
               
                this.ngZone.run(() => {
                this.router.navigate(['/admin/lead/'+data.category]);
            });

            }
        }, 100);
    }

}
