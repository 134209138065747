<main>
  <section class="inner-page">
    <div class="inner-page-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <h4>
              <a class="back-link" (click)="back()"><img src="assets/images/angle-left.svg"></a>
              Reports
              <p class="info">*We keep last 15 days campaigns detailed reports only. Download link may appear after completion of campaign and will remain active for 7 days from the execution date.*</p>
            </h4>
          </div>
        </div>
      </div>
    </div>

    <div class="inner-page-body project-integration">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="configuration project-integration-tab">

              <tabset *ngIf="showJourney" class="reportTabsSet">
                <ng-container *ngFor="let item of setJourneyData">
                <tab *ngIf="item == 'androidpush'" heading="{{item == 'androidpush' ? 'Android':item}}" (selectTab)="page = 1; limit =20;getCampaignsList(item,'reload')">
                  <div class="inner-page-body">
                    <div class="container-fluid">
                      
                            <div class="table-responsive text-center">
                              <table class="table">
                                <tbody>
                                  <tr>
                                    
                                    <th>S.No.</th>
                                    <th>Campaign Name</th>
                                    <th>Schedule On</th>
                                    <th>Campaign Type</th>
                                    <th>No. of Users</th>
                                    <th>Created By</th>
                                    <th>Status</th>
                                    <th>Schedule Type</th>
                                    <th class="text-right action_col">Action</th>
                                  </tr>
                                <ng-container *ngIf="androidCamplist && androidCamplist.length > 0">
                                  <tr *ngFor="let item of androidCamplist | paginate: { id: 'foo',itemsPerPage: limit,currentPage: page,totalItems: totalRecords }; let i = index">
                                    <td>{{((page-1)*limit)+i+1}}.</td>
                                    <td>{{item.campaignName}}</td>
                                  <td>
                                    <div class="schedule-on">{{item.scDate.replace(' ', 'T') | date:'MMM dd, yyyy  HH:mm'}}</div>
                                  </td>
                                  <td>
                                    <div class="campaign-type">{{item.campaignType}}</div>
                                  </td>
                                  <td>{{item.audienceCount}} user(s)</td>
                                  
                                  <td class="text-capitalize">{{item.userId | telleCallerName:item.userId }}</td>
                                  <td>
                                    <!-- <span *ngIf="item.scheduleType == 'repeat'; else elseStatusTemplate" tooltip="{{item.statusDescription}}" [tooltipAnimation]="true" tooltipPlacement="top">
                                      Scheduled
                                    </span> -->
                                    <span tooltip="InActive" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='0' && item.scheduleType != 'repeat'">Pending</span>
                                    <span tooltip="Paused" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='0' && item.scheduleType == 'repeat'">Paused</span>
                                    <span tooltip="Active" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='1'">Active</span>
                                    <span tooltip="Completed" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='2'">Completed</span>
                                    <span tooltip="Error" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='3'">Error</span>
                                  
                                  </td>
                                  <td class="text-capitalize">
                                    <ng-container *ngIf="item.scheduleType == 'now' || item.scheduleType == null; else elseTypeTemplate">
                                      Instant
                                    </ng-container>
                                    <ng-template #elseTypeTemplate>
                                      {{item.scheduleType}}
                                    </ng-template>
                                  </td>
                                  <td class="text-right"> 
                                      <!-- Audience -->
                                      <!-- <a (click)="openModal(template, item.id)" class="without-hover"><img src="assets/images/segmenteye.png"></a> -->
                                      <a (click)="viewAudienceDetal(item.id,pushUrl)" class="without-hover"><img src="assets/images/segmenteye.png"></a>
                                      <a *ngIf="item.reportPath" style="color: inherit;
                                                      text-decoration: none;" href="{{item.reportPath}}" target="_blank" class="text_decoration_none"> &nbsp;
                                                        <span class="icon-download"></span>
                                                      </a>
                                       
                                      <!-- <span *ngIf="item.campaignStatus!='Completed'">N/A</span> -->
                                      <a *ngIf="item.campaignStatus!='Completed' && item.scheduleType != 'repeat' " (click)="deleteReport(item, i ,pushUrl,'android')"> &nbsp;
                                        <img class="img" src="assets/images/delete.png" />
                                      </a>
                                      <div *ngIf="(item.campaignStatus == '0' || item.campaignStatus == '1') && item.scheduleType == 'repeat' "  class="custom-control custom-switch swich-button" [class.activeSwitch]="item.campaignStatus == '1'"> &nbsp;
                                          <input type="checkbox" name="CurrentPluginLead" (change)="repeatUpdateStatus(item,pushUrl,'android')"
                                               class="custom-control-input" id="andrrepeatStatus{{i}}" />
                                          <label class="custom-control-label" for="andrrepeatStatus{{i}}"></label>
                                      </div>
                                  </td>
          
                                  </tr>
                                <tr *ngIf="androidCamplist.length==0">
                                  <td class="no-records" colspan="10">No Reports Yet</td>
                                </tr>
                                </ng-container>

                                </tbody>
                              </table>
                            </div>

                            <div class="table-footer footer-gray">
                              <pagination-controls id="foo" class="pull-right" (pageChange)="dataForPaging($event,'android')" maxSize="9" directionLinks="true" autoHide="true"
                                responsive="true" previousLabel="Previous" nextLabel="Next" screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
                                screenReaderCurrentLabel="You're on page">
                              </pagination-controls>
                              <div  class="textblue text-left">
                                <div class="data-length"> Show
                                  <select [(ngModel)]="limit" (change)="page = 1;getCampaignsList('android','reload')">
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                  </select>
                                </div>
                                <span> Total Count: {{totalRecords}}</span>  
                                </div>
                            </div>
                          
                    </div>
                  </div>
                </tab>

                <tab *ngIf="item == 'iospush'" heading="{{item == 'iospush' ? 'IOS' : item}}" (selectTab)="page = 1; limit =20;getCampaignsList(item,'reload')">
                  <div class="inner-page-body">
                    <div class="container-fluid">
                     
                            <div class="table-responsive text-center">
                              <table class="table">
                                <tbody>
                                  <tr>
                                    <th>S.No.</th>
                                    <th>Campaign Name</th>
                                    <th>Schedule On</th>
                                     <th>Campaign Type</th>
                                    <th>No. of Users</th>
                                    <th>Created By</th>
                                    <th>Status</th>
                                    <th>Schedule Type</th>
                                    <th class="text-right action_col">Action</th>
                                  </tr>

                                <ng-container *ngIf="iosCapmlist && iosCapmlist.length > 0">
                                  <tr *ngFor="let item of iosCapmlist | paginate: { id: 'foo',itemsPerPage: limit,currentPage: page,totalItems: totalRecords }; let i = index">
                                    <td>{{((page-1)*limit)+i+1}}.</td>
                                    <td>{{item.campaignName}}</td>
                                  <td>
                                    <div class="schedule-on">{{item.scDate.replace(' ', 'T') | date:'MMM dd, yyyy  HH:mm'}}</div>
                                  </td>
                                 
                                  <td>
                                    <div class="campaign-type">{{item.campaignType}}</div>
                                  </td>
                                  <td>{{item.audienceCount}} user(s)</td>
                                  <td class="text-capitalize">{{item.userId | telleCallerName:item.userId }}</td>
                                  <td>
                                    <!-- <span *ngIf="item.scheduleType == 'repeat'; else elseStatusTemplate" tooltip="{{item.statusDescription}}" [tooltipAnimation]="true" tooltipPlacement="top">
                                      Scheduled
                                    </span> -->
                                    <span tooltip="InActive" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='0' && item.scheduleType != 'repeat'">Pending</span>
                                    <span tooltip="Paused" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='0' && item.scheduleType == 'repeat'">Paused</span>
                                    <span tooltip="Active" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='1'">Active</span>
                                    <span tooltip="Completed" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='2'">Completed</span>
                                    <span tooltip="Error" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='3'">Error</span>
                                  
                                  </td>
                                  <td class="text-capitalize">
                                    <ng-container *ngIf="item.scheduleType == 'now' || item.scheduleType == null; else elseTypeTemplate">
                                      Instant
                                    </ng-container>
                                    <ng-template #elseTypeTemplate>
                                      {{item.scheduleType}}
                                    </ng-template>
                                  </td>
                                  <td class="text-right"> 
                                      <!-- Audience -->
                                      <!-- <a (click)="openModal(template, item.id)" class="without-hover"><img src="assets/images/segmenteye.png"></a> -->
                                      <a (click)="viewAudienceDetal(item.id,pushUrl)" class="without-hover"><img src="assets/images/segmenteye.png"></a>
                                      <!-- <a *ngIf="item.campaignStatus=='2'  || item.scheduleType == 'repeat'" (click)="downloadReport(item,pushUrl)"> &nbsp;
                                        <span class="icon-download"></span>
                                      </a> -->
                                      <a *ngIf="item.reportPath" style="color: inherit;
                                                      text-decoration: none;" href="{{item.reportPath}}" target="_blank" class="text_decoration_none"> &nbsp;
                                                        <span class="icon-download"></span>
                                                      </a>
                                      <!-- <span *ngIf="item.campaignStatus!='Completed'">N/A</span> -->
                                      <a *ngIf="item.campaignStatus!='2' && item.scheduleType != 'repeat' " (click)="deleteReport(item, i ,pushUrl,'ios')"> &nbsp;
                                        <img class="img" src="assets/images/delete.png" />
                                      </a>
                                      <div *ngIf="(item.campaignStatus == '0' || item.campaignStatus == '1') && item.scheduleType == 'repeat' "  class="custom-control custom-switch swich-button" [class.activeSwitch]="item.campaignStatus == '1'"> &nbsp;
                                          <input type="checkbox" name="CurrentPluginLead" (change)="repeatUpdateStatus(item,pushUrl,'ios')"
                                               class="custom-control-input" id="iosrepeatStatus{{i}}" />
                                          <label class="custom-control-label" for="iosrepeatStatus{{i}}"></label>
                                      </div>
                                  </td>
          
                                </tr>
                                <tr *ngIf="iosCapmlist.length==0">
                                  <td class="no-records" colspan="10">No Reports Yet</td>
                                </tr>
                                </ng-container>

                                </tbody>
                              </table>
                            </div>

                            <div class="table-footer footer-gray">
                              <pagination-controls id="foo" class="pull-right" (pageChange)="dataForPaging($event,'ios')" maxSize="9" directionLinks="true" autoHide="true"
                                responsive="true" previousLabel="Previous" nextLabel="Next" screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
                                screenReaderCurrentLabel="You're on page">
                              </pagination-controls>
                              <div  class="textblue text-left">
                                <div class="data-length"> Show
                                  <select [(ngModel)]="limit" (change)="page = 1;getCampaignsList('ios','reload')">
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                  </select>
                                </div>
                                <span> Total Count: {{totalRecords}}</span>  
                                </div>
                            </div>
                    </div>
                  </div>
                </tab>

                <tab *ngIf="item == 'webpush'" heading="{{item == 'webpush' ? 'Web' : item}}" (selectTab)="page = 1; limit =20;getCampaignsList(item,'reload')">
                  <div class="inner-page-body">
                    <div class="container-fluid">
                      
                            <div class="table-responsive text-center">
                              <table class="table">
                                <tbody>
                                  <tr>
                                    <th>S.No.</th>
                                    <th>Campaign Name</th>
                                    <th>Schedule On</th>
                                    <th>Campaign Type</th>
                                    <th>No. of Users</th>
                                    <th>Created By</th>
                                    <th>Status</th>
                                    <th>Schedule Type</th>
                                    <th class="text-right action_col">Action</th>
                                  </tr>
                                  
                                <ng-container *ngIf="webCapmlist && webCapmlist.length > 0">
                                  <tr *ngFor="let item of webCapmlist | paginate: { id: 'foo',itemsPerPage: limit,currentPage: page,totalItems: totalRecords }; let i = index">
                                    <td>{{((page-1)*limit)+i+1}}.</td>
                                    <td>{{item.campaignName}}</td>
                                  <td>
                                    <div class="schedule-on">{{item.scDate.replace(' ', 'T') | date:'MMM dd, yyyy  HH:mm'}}</div>
                                  </td>
                                 
                                  <td>
                                    <div class="campaign-type">{{item.campaignType}}</div>
                                  </td>
                                  <td>{{item.audienceCount}} user(s)</td>
                                  <td class="text-capitalize">{{item.userId | telleCallerName:item.userId }}</td>
                                  <td>
                                    <!-- <span *ngIf="item.scheduleType == 'repeat'; else elseStatusTemplate" tooltip="{{item.statusDescription}}" [tooltipAnimation]="true" tooltipPlacement="top">
                                      Scheduled
                                    </span> -->
                                    <span tooltip="InActive" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='0' && item.scheduleType != 'repeat'">Pending</span>
                                    <span tooltip="Paused" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='0' && item.scheduleType == 'repeat'">Paused</span>
                                    <span tooltip="Active" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='1'">Active</span>
                                    <span tooltip="Completed" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='2'">Completed</span>
                                    <span tooltip="Error" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='3'">Error</span>
                                  
                                  </td>
                                  <td class="text-capitalize">
                                    <ng-container *ngIf="item.scheduleType == 'now' || item.scheduleType == null; else elseTypeTemplate">
                                      Instant
                                    </ng-container>
                                    <ng-template #elseTypeTemplate>
                                      {{item.scheduleType}}
                                    </ng-template>
                                  </td>
                                  <td class="text-right"> 
                                      <!-- Audience -->
                                      <!-- <a (click)="openModal(template, item.id)" class="without-hover"><img src="assets/images/segmenteye.png"></a> -->
                                      <a (click)="viewAudienceDetal(item.id,pushUrl)" class="without-hover"><img src="assets/images/segmenteye.png"></a>
                                      <!-- <a *ngIf="item.campaignStatus=='2'  || item.scheduleType == 'repeat'" (click)="downloadReport(item,pushUrl)"> &nbsp;
                                        <span class="icon-download"></span>
                                      </a> -->
                                      <a *ngIf="item.reportPath" style="color: inherit;
                                                      text-decoration: none;" href="{{item.reportPath}}" target="_blank" class="text_decoration_none"> &nbsp;
                                                        <span class="icon-download"></span>
                                                      </a>
                                      <!-- <span *ngIf="item.campaignStatus!='Completed'">N/A</span> -->
                                      <a *ngIf="item.campaignStatus!='2' && item.scheduleType != 'repeat' " (click)="deleteReport(item, i, pushUrl,'web')"> &nbsp;
                                        <img class="img" src="assets/images/delete.png" />
                                      </a>
                                      <div *ngIf="(item.campaignStatus == '0' || item.campaignStatus == '1') && item.scheduleType == 'repeat' "  class="custom-control custom-switch swich-button" [class.activeSwitch]="item.campaignStatus == '1'"> &nbsp;
                                          <input type="checkbox" name="CurrentPluginLead" (change)="repeatUpdateStatus(item,pushUrl,'web')"
                                               class="custom-control-input" id="webrepeatStatus{{i}}" />
                                          <label class="custom-control-label" for="webrepeatStatus{{i}}"></label>
                                      </div>
                                  </td>
          
                                </tr>
                                <tr *ngIf="webCapmlist.length==0">
                                  <td class="no-records" colspan="10">No Reports Yet</td>
                                </tr>
                                </ng-container>
                                </tbody>
                              </table>
                            </div>
                            <div class="table-footer footer-gray">
                              <pagination-controls id="foo" class="pull-right" (pageChange)="dataForPaging($event,'web')" maxSize="9" directionLinks="true" autoHide="true"
                                responsive="true" previousLabel="Previous" nextLabel="Next" screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
                                screenReaderCurrentLabel="You're on page">
                              </pagination-controls>
                              <div  class="textblue text-left">
                                <div class="data-length"> Show
                                  <select [(ngModel)]="limit" (change)="page = 1;getCampaignsList('web','reload')">
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                  </select>
                                </div>
                                <span> Total Count: {{totalRecords}}</span>  
                                </div>
                            </div>
                    </div>
                  </div>
                </tab>

                <tab *ngIf="item == 'sms'" heading="{{item}}" (selectTab)="page = 1; limit =20;getCampaignsList(item,'reload')">
                  <div class="inner-page-body">
                    <div class="container-fluid">
                      
                            <div class="table-responsive text-center">
                              <table class="table">
                                <tbody>
                                  <tr>
                                    <th>S.No.</th>
                                    <th>Campaign Name</th>
                                    <th>Schedule On</th>
                                    
                                    <th>Campaign Type</th>
                                    <th>No. of Users</th>
                                    <th>Created By</th>
                                    <th>Status</th>
                                    <th>Schedule Type</th>
                                    <th class="text-right action_col">Action</th>
                                  </tr>
                                  
                                <ng-container *ngIf="smsCapmlist && smsCapmlist.length > 0">
                                  <tr *ngFor="let item of smsCapmlist | paginate: { id: 'foo',itemsPerPage: limit,currentPage: page,totalItems: totalRecords }; let i = index">
                                    <td>{{((page-1)*limit)+i+1}}.</td>
                                    <td>{{item.campaignName}}</td>
                                  <td>
                                    <div class="schedule-on">{{item.scDate.replace(' ', 'T') | date:'MMM dd, yyyy  HH:mm'}}</div>
                                  </td>
                                 
                                  <td>
                                    <div class="campaign-type">{{item.campaignType}}</div>
                                  </td>
                                  <td>{{item.audienceCount}} user(s)</td>
                                  <td class="text-capitalize">{{item.userId | telleCallerName:item.userId }}</td>
                                  <td>
                                    <!-- <span *ngIf="item.scheduleType == 'repeat'; else elseStatusTemplate" tooltip="{{item.statusDescription}}" [tooltipAnimation]="true" tooltipPlacement="top">
                                      Scheduled
                                    </span> -->
                                    <span tooltip="InActive" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='0' && item.scheduleType != 'repeat'">Pending</span>
                                    <span tooltip="Paused" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='0' && item.scheduleType == 'repeat'">Paused</span>
                                    <span tooltip="Active" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='1'">Active</span>
                                    <span tooltip="Completed" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='2'">Completed</span>
                                    <span tooltip="Error" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='3'">Error</span>
                                  
                                  </td>
                                  <td class="text-capitalize">
                                    <ng-container *ngIf="item.scheduleType == 'now' || item.scheduleType == null; else elseTypeTemplate">
                                      Instant
                                    </ng-container>
                                    <ng-template #elseTypeTemplate>
                                      {{item.scheduleType}}
                                    </ng-template>
                                  </td>
                                  <td class="text-right"> 
                                      <!-- Audience -->
                                      <!-- <a (click)="openModal(template, item.id)" class="without-hover"><img src="assets/images/segmenteye.png"></a> -->
                                      <a (click)="viewAudienceDetal(item.id,smsUrl)" class="without-hover"><img src="assets/images/segmenteye.png"></a>
                                      <!-- <a *ngIf="item.campaignStatus=='2'  || item.scheduleType == 'repeat'" (click)="downloadReport(item,smsUrl)"> &nbsp;
                                        <span class="icon-download"></span>
                                      </a> -->
                                      <a *ngIf="item.reportPath" style="color: inherit;
                                                      text-decoration: none;" href="{{item.reportPath}}" target="_blank" class="text_decoration_none"> &nbsp;
                                            <span class="icon-download"></span>
                                      </a>
                                      <!-- <span *ngIf="item.campaignStatus!='Completed'">N/A</span> -->
                                      <a *ngIf="item.campaignStatus!='2' && item.scheduleType != 'repeat' " (click)="deleteReport(item, i, smsUrl,'sms')"> &nbsp;
                                        <img class="img" src="assets/images/delete.png" />
                                      </a>
                                      <div *ngIf="(item.campaignStatus == '0' || item.campaignStatus == '1') && item.scheduleType == 'repeat' "  class="custom-control custom-switch swich-button" [class.activeSwitch]="item.campaignStatus == '1'" [class.inActiveSwitch]="item.campaignStatus == '0'"> &nbsp;
                                          <input type="checkbox" name="CurrentPluginLead" (change)="repeatUpdateStatus(item,smsUrl,'sms')"
                                               class="custom-control-input" id="smsrepeatStatus{{i}}" />
                                          <label class="custom-control-label" for="smsrepeatStatus{{i}}"></label>
                                      </div>
                                  </td>
          
                                </tr>
                                <tr *ngIf="smsCapmlist.length==0">
                                  <td class="no-records" colspan="10">No Reports Yet</td>
                                </tr>
                                </ng-container>
                                </tbody>
                              </table>
                            </div>

                            <div class="table-footer footer-gray">
                              <pagination-controls id="foo" class="pull-right" (pageChange)="dataForPaging($event,'sms')" maxSize="9" directionLinks="true" autoHide="true"
                                responsive="true" previousLabel="Previous" nextLabel="Next" screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
                                screenReaderCurrentLabel="You're on page">
                              </pagination-controls>
                              <div  class="textblue text-left">
                                <div class="data-length"> Show
                                  <select [(ngModel)]="limit" (change)="page = 1;getCampaignsList('sms','reload')">
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                  </select>
                                </div>
                                <span> Total Count: {{totalRecords}}</span>  
                                </div>
                            </div>
                    </div>
                  </div>
                </tab>

                <tab *ngIf="item == 'email'" heading="{{item}}" (selectTab)="page = 1; limit =20;getCampaignsList(item,'reload')">
                  <div class="inner-page-body">
                    <div class="container-fluid">
                      
                            <div class="table-responsive text-center">
                              <table class="table">
                                <tbody>
                                  <tr>
                                    <th>S.No.</th>
                                    <th>Campaign Name</th>
                                    <th>Schedule On</th>
                                    <th>Campaign Type</th>
                                    <th>No. of Users</th>
                                    <th>Created By</th>
                                    <th>Status</th>
                                    <th>Schedule Type</th>
                                    <th class="text-right action_col">Action</th>
                                  </tr>
                                  
                                <ng-container *ngIf="emailCapmlist && emailCapmlist.length > 0">
                                  <tr *ngFor="let item of emailCapmlist | paginate: { id: 'foo',itemsPerPage: limit,currentPage: page,totalItems: totalRecords }; let i = index">
                                    <td>{{((page-1)*limit)+i+1}}.</td>
                                    <td>{{item.campaignName}}</td>
                                  <td>
                                    <div class="schedule-on">{{item.scDate.replace(' ', 'T') | date:'MMM dd, yyyy  HH:mm'}}</div>
                                  </td>
                                  <td>
                                    <div class="campaign-type">{{item.campaignType}}</div>
                                  </td>
                                  <td>{{item.audienceCount}} user(s)</td>
                                  <td class="text-capitalize">{{item.userId | telleCallerName:item.userId }}</td>
                                  <td>
                                    <!-- <span *ngIf="item.scheduleType == 'repeat'; else elseStatusTemplate" tooltip="{{item.statusDescription}}" [tooltipAnimation]="true" tooltipPlacement="top">
                                      Scheduled
                                    </span> -->
                                    <span tooltip="InActive" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='0' && item.scheduleType != 'repeat'">Pending</span>
                                    <span tooltip="Paused" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='0' && item.scheduleType == 'repeat'">Paused</span>
                                    <span tooltip="Active" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='1'">Active</span>
                                    <span tooltip="Completed" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='2'">Completed</span>
                                    <span tooltip="Error" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='3'">Error</span>
                                  
                                  </td>
                                  <td class="text-capitalize">
                                    <ng-container *ngIf="item.scheduleType == 'now' || item.scheduleType == null; else elseTypeTemplate">
                                      Instant
                                    </ng-container>
                                    <ng-template #elseTypeTemplate>
                                      {{item.scheduleType}}
                                    </ng-template>
                                  </td>
                                  <td class="text-right action_col"> 
                                      <!-- Audience -->
                                      <!-- <a (click)="openModal(template, item.id)" class="without-hover"><img src="assets/images/segmenteye.png"></a> -->
                                      <a (click)="viewAudienceDetal(item.id,emailUrl)" class="without-hover"><img src="assets/images/segmenteye.png"></a>
                                      <a *ngIf="item.reportPath != null" href="{{item.reportPath}}" target="_blank" class="text_decoration_none"> &nbsp;
                                        <span class="icon-download"></span>
                                      </a>
                                      <!-- <span *ngIf="item.campaignStatus!='Completed'">N/A</span> -->
                                      <a *ngIf="item.campaignStatus!='2' && item.scheduleType != 'repeat' " (click)="deleteReport(item, i, emailUrl,'email')"> &nbsp;
                                        <img class="img" src="assets/images/delete.png" />
                                      </a>
                                      <div *ngIf="(item.campaignStatus == '0' || item.campaignStatus == '1') && item.scheduleType == 'repeat' "  class="custom-control custom-switch swich-button" [class.activeSwitch]="item.campaignStatus == '1'"> &nbsp;
                                          <input type="checkbox" name="CurrentPluginLead" (change)="repeatUpdateStatus(item,emailUrl,'email')"
                                               class="custom-control-input" id="emailrepeatStatus{{i}}" />
                                          <label class="custom-control-label" for="emailrepeatStatus{{i}}"></label>
                                      </div>
                                  </td>
          
                                </tr>
                                <tr *ngIf="emailCapmlist.length==0">
                                  <td class="no-records" colspan="10">No Reports Yet</td>
                                </tr>
                                </ng-container>
                                </tbody>
                              </table>
                            </div>
                            <div class="table-footer footer-gray" >
                              <pagination-controls id="foo" class="pull-right" (pageChange)="dataForPaging($event,'email')" maxSize="9" directionLinks="true" autoHide="true"
                                responsive="true" previousLabel="Previous" nextLabel="Next" screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
                                screenReaderCurrentLabel="You're on page">
                              </pagination-controls>
                              <div  class="textblue text-left">
                                <div class="data-length"> Show
                                  <select [(ngModel)]="limit" (change)="page = 1;getCampaignsList('email','reload')">
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                  </select>
                                </div>
                                <span> Total Count: {{totalRecords}}</span>  
                                </div>
                            </div>
                          
                    </div>
                  </div>
                </tab>

                <tab *ngIf="item == 'whatsapp'" heading="{{item}}" (selectTab)="page = 1; limit =20;getCampaignsList(item,'reload')">
                  <div class="inner-page-body">
                    <div class="container-fluid">
                      
                            <div class="table-responsive text-center">
                              <table class="table">
                                <tbody>
                                  <tr>
                                    <th>S.No.</th>
                                    <th>Campaign Name</th>
                                    <th>Schedule On</th>
                                    <th>Campaign Type</th>
                                    <th>No. of Users</th>
                                    <th>Created By</th>
                                    <th>Status</th>
                                    <th>Schedule Type</th>
                                    <th class="text-right action_col">Action</th>
                                  </tr>
                                  
                                <ng-container *ngIf="whatsappCapmlist && whatsappCapmlist.length > 0">
                                  <tr *ngFor="let item of whatsappCapmlist | paginate: { id: 'foo',itemsPerPage: limit,currentPage: page,totalItems: totalRecords }; let i = index">
                                    <td>{{((page-1)*limit)+i+1}}.</td>
                                    <td>{{item.campaignName}}</td>
                                  <td>
                                    <div class="schedule-on">{{item.scDate.replace(' ', 'T') | date:'MMM dd, yyyy  HH:mm'}}</div>
                                  </td>
                                  <td>
                                    <div class="campaign-type">{{item.campaignType}}</div>
                                  </td>
                                  <td>{{item.audienceCount}} user(s)</td>
                                  <td class="text-capitalize">{{item.userId | telleCallerName:item.userId }}</td>
                                  <td>
                                    <!-- <span *ngIf="item.scheduleType == 'repeat'; else elseStatusTemplate" tooltip="{{item.statusDescription}}" [tooltipAnimation]="true" tooltipPlacement="top">
                                      Scheduled
                                    </span> -->
                                    <span tooltip="InActive" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='0' && item.scheduleType != 'repeat'">Pending</span>
                                    <span tooltip="Paused" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='0' && item.scheduleType == 'repeat'">Paused</span>
                                    <span tooltip="Active" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='1'">Active</span>
                                    <span tooltip="Completed" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='2'">Completed</span>
                                    <span tooltip="Error" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='3'">Error</span>
                                        </td>
                                  <td class="text-capitalize">
                                    <ng-container *ngIf="item.scheduleType == 'now' || item.scheduleType == null; else elseTypeTemplate">
                                      Instant
                                    </ng-container>
                                    <ng-template #elseTypeTemplate>
                                      {{item.scheduleType}}
                                    </ng-template>
                                  </td>
                                  <td class="text-right"> 
                                      <!-- Audience -->
                                      <!-- <a (click)="openModal(template, item.id)" class="without-hover"><img src="assets/images/segmenteye.png"></a> -->
                                      <a (click)="viewAudienceDetal(item.id,whatsAppUrl)" class="without-hover"><img src="assets/images/segmenteye.png"></a>
                                      <a *ngIf="item.campaignStatus=='2' || item.scheduleType == 'repeat'" (click)="downloadReport(item,whatsAppUrl)"> &nbsp;
                                        <span class="icon-download"></span>
                                      </a>
                                      <!-- <span *ngIf="item.campaignStatus!='Completed'">N/A</span> -->
                                      <a *ngIf="item.campaignStatus!='2' && item.scheduleType != 'repeat' " (click)="deleteReport(item, i, whatsAppUrl,'whatsapp')"> &nbsp;
                                        <img class="img" src="assets/images/delete.png" />
                                      </a>
                                      <div *ngIf="(item.campaignStatus == '0' || item.campaignStatus == '1') && item.scheduleType == 'repeat' "  class="custom-control custom-switch swich-button" [class.activeSwitch]="item.campaignStatus == '1'"> &nbsp;
                                          <input type="checkbox" name="whatsappcampaignStatus" (change)="repeatUpdateStatus(item,whatsAppUrl,'whatsapp')"
                                               class="custom-control-input" id="whatsappcampaignStatus{{i}}" />
                                          <label class="custom-control-label" for="whatsappcampaignStatus{{i}}"></label>
                                      </div>
                                      
                                  </td>
          
                                </tr>
                                <tr *ngIf="whatsappCapmlist.length==0">
                                  <td class="no-records" colspan="10">No Reports Yet</td>
                                </tr>
                                </ng-container>
                                </tbody>
                              </table>
                            </div>
                            <div class="table-footer footer-gray">
                              <pagination-controls id="foo" class="pull-right" (pageChange)="dataForPaging($event,'whatsapp')" maxSize="9" directionLinks="true" autoHide="true"
                                responsive="true" previousLabel="Previous" nextLabel="Next" screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
                                screenReaderCurrentLabel="You're on page">
                              </pagination-controls>
                              <div  class="textblue text-left">
                                <div class="data-length"> Show
                                  <select [(ngModel)]="limit" (change)="page = 1;getCampaignsList('whatsapp','reload')">
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                  </select>
                                </div>
                                <span> Total Count: {{totalRecords}}</span>  
                                </div>
                            </div>
                          </div>
                       
                  </div>
                </tab>

              </ng-container>
              </tabset>

              <ng-template #audienceDetailPopup>
                <div class="forgot-password-modal">
                  <div class="modal-header">
                    <h4 class="modal-title">Audience</h4>
                    <button aria-label="Close" class="close pull-right" type="button" (click)="modalRef.hide();">
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div class="table-reports audDetail">
                      <div class="table-body">
                        <div class="table-responsive">
                          <table class="table" *ngIf="audienceAllData != ''">
                            <tr>
                              <th>User Name</th>
                              <td>{{audienceAllData.userName}}</td>
                            </tr>
                            <tr>
                              <th>Camp. Name</th>
                              <td>{{audienceAllData.campaignName}}</td>
                            </tr>
                            <tr *ngIf="audienceAllData.campSubject">
                              <th>Camp. Subject</th>
                              <td>{{audienceAllData.campSubject}}</td>
                            </tr>
                            <tr>
                              <th>Camp. Body</th>
                              <td [innerHTML]="audienceAllData.campBody"></td>
                            </tr>
                            
                            <tr>
                              <th>Camp. Type</th>
                              <td>{{audienceAllData.campaignType}}</td>
                            </tr>
                            <tr>
                              <th>Filter Type</th>
                              <td>
                                <div *ngFor="let filterList of audienceAllData.filter" class="filterAttrs">
                                  {{filterList}}
                                </div>
                                <ng-container *ngIf="audienceAllData.paramName">
                                  <ng-container *ngFor="let param_name of audienceAllData.paramName; let i=index">
                                    <ng-container *ngIf="i > 0">,</ng-container> {{param_name}}
                                  </ng-container>
                                </ng-container>
                                <span *ngIf="audienceAllData.intValTime">{{audienceAllData.intValTime}}, </span> 
                              </td>
                            </tr>
                            
                            <tr>
                              <th>IP Addr.</th>
                              <td>{{audienceAllData.ipAddr}}</td>
                            </tr>
                            <tr>
                              <th>Journey Name</th>
                              <td>{{audienceAllData.journeyName}}</td>
                            </tr>
                            <tr>
                              <th>
                                <ng-container *ngIf="audienceAllData.scheduleType == 'repeat' || audienceAllData.scheduleType == 'schedule'">
                                  Schedule Date
                                </ng-container>
                                <ng-container *ngIf="audienceAllData.scheduleType != 'repeat' && audienceAllData.scheduleType != 'schedule'">
                                  Date
                                </ng-container>
                                </th>
                              <td>{{audienceAllData.scheduleDate}}</td>
                            </tr>
                            <tr>
                              <th>schedule Type</th>
                              <td>{{audienceAllData.scheduleType}}</td>
                            </tr>
                            <tr>
                              <th>Last Update</th>
                              <td>{{audienceAllData.updated}}</td>
                            </tr>
                            
                            <tr>
                              <th>Content Type</th>
                              <td>{{audienceAllData.contentType}}</td>
                            </tr>

                            <tr>
                              <th>Source</th>
                              <td>
                                <ng-container *ngIf="audienceAllData.source">
                                  {{audienceAllData.source}}
                                </ng-container>
                                
                                <ng-container *ngIf="audienceAllData.formName.length > 0">
                                  (
                                  <span *ngFor="let list of audienceAllData.formName">
                                  <ng-container *ngIf="audienceAllData.source != list">
                                    {{list}}, 
                                  </ng-container>
                                  </span>
                                  )
                                </ng-container>
                              </td>
                            </tr>
                            
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
              
            <tabset #siteTab *ngIf="!showJourney">

              <tab heading="Push" id="tab1" (selectTab)="page = 1; limit =20;getCampaignsList('android','reload')">
                  <tabset #pushTabs>
                      <tab heading="Android" (selectTab)="page = 1; limit =20;getCampaignsList('android','reload')">
                          <div class="inner-page-body">
                              <div class="container-fluid">
                                    
                                          <div class="table-responsive text-center">
                                            <table class="table">
                                              <tbody>
                                                <tr>
                                                  <th>S.No.</th>
                                                  <th>Campaign Name</th>
                                                  <th>Schedule On</th>
                                                 
                                                  <th>Campaign Type</th>
                                                  <th>No. of Users</th>
                                                  <th>Created By</th>
                                                  <th>Status</th>
                                                  <th>Schedule Type</th>
                                                  <th class="text-right action_col">Action</th>
                                                </tr>
                                                  <tr *ngFor="let item of androidCamplist | paginate: { id: 'foo',itemsPerPage: limit,currentPage: page,totalItems: totalRecords }; let i = index">
                                                    <td>{{((page-1)*limit)+i+1}}.</td>
                                                    <td>{{item.campaignName}}</td>
                                                  <td>
                                                    <div class="schedule-on">{{item.scDate.replace(' ', 'T') | date:'MMM dd, yyyy  HH:mm'}}</div>
                                                  </td>
                                                 
                                                  <td>
                                                    <div class="campaign-type">{{item.campaignType}}</div>
                                                  </td>
                                                  <td>{{item.audienceCount}} user(s)</td>
                                                  <td class="text-capitalize">{{item.userId | telleCallerName:item.userId }}</td>
                                                  <td>
                                                    <!-- <span *ngIf="item.scheduleType == 'repeat'; else elseStatusTemplate" tooltip="{{item.statusDescription}}" [tooltipAnimation]="true" tooltipPlacement="top">
                                                      Scheduled
                                                    </span> -->
                                                 
                                                    <span tooltip="InActive" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='0' && item.scheduleType != 'repeat'">Pending</span>
                                                    <span tooltip="Paused" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='0' && item.scheduleType == 'repeat'">Paused</span>
                                                    <span tooltip="Active" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='1'">Active</span>
                                                    <span tooltip="Completed" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='2'">Completed</span>
                                                    <span tooltip="Error" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='3'">Error</span>
                                                            
                                                  </td>
                                                  <td class="text-capitalize">
                                                    <ng-container *ngIf="item.scheduleType == 'now' || item.scheduleType == null; else elseTypeTemplate">
                                                      Instant
                                                    </ng-container>
                                                    <ng-template #elseTypeTemplate>
                                                      {{item.scheduleType}}
                                                    </ng-template>
                                                  </td>
                                                  <td class="text-right"> 
                                                      <!-- Audience -->
                                                      <!-- <a (click)="openModal(template, item.id)" class="without-hover"><img src="assets/images/segmenteye.png"></a> -->
                                                      <a (click)="viewAudienceDetal(item.id,pushUrl)" class="without-hover"><img src="assets/images/segmenteye.png"></a>
                                                      <a *ngIf="item.reportPath" style="color: inherit;
                                                      text-decoration: none;" href="{{item.reportPath}}" target="_blank" class="text_decoration_none"> &nbsp;
                                                        <span class="icon-download"></span>
                                                      </a>
                                                      <!-- <span *ngIf="item.campaignStatus!='Completed'">N/A</span> -->
                                                      <a *ngIf="item.campaignStatus!='2' && item.scheduleType != 'repeat' " (click)="deleteReport(item, i ,pushUrl,'android')"> &nbsp;
                                                        <img class="img" src="assets/images/delete.png" />
                                                      </a>
                                                      <div *ngIf="(item.campaignStatus == '0' || item.campaignStatus == '1') && item.scheduleType == 'repeat' "  class="custom-control custom-switch swich-button" [class.activeSwitch]="item.campaignStatus == '1'"> &nbsp;
                                                          <input type="checkbox" name="CurrentPluginLead" (change)="repeatUpdateStatus(item,pushUrl,'android')"
                                                               class="custom-control-input" id="andrrepeatStatus{{i}}" />
                                                          <label class="custom-control-label" for="andrrepeatStatus{{i}}"></label>
                                                      </div>
                                                  </td>
                          
                                                </tr>
                                                <tr *ngIf="androidCamplist.length==0">
                                                  <td class="no-records" colspan="10">No Reports Yet</td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                          
                                          <div class="table-footer footer-gray">
                                            <pagination-controls id="foo" class="pull-right" (pageChange)="dataForPaging($event,'android')" maxSize="9" directionLinks="true" autoHide="true"
                                              responsive="true" previousLabel="Previous" nextLabel="Next" screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
                                              screenReaderCurrentLabel="You're on page">
                                            </pagination-controls>
                                            <div  class="textblue text-left">
                                              <div class="data-length"> Show
                                                <select [(ngModel)]="limit" (change)="page = 1;getCampaignsList('android','reload')">
                                                  <option value="20">20</option>
                                                  <option value="50">50</option>
                                                  <option value="100">100</option>
                                                </select>
                                              </div>
                                              <span> Total Count: {{totalRecords}}</span>  
                                              </div>
                                          </div>
                                          
                                        
                              </div>
                          </div>
                      </tab>

                      <tab heading="IOS" (selectTab)="page = 1; limit =20;getCampaignsList('ios','reload')">
                        <div class="inner-page-body">
                            <div class="container-fluid">
                                  
                                        <div class="table-responsive text-center">
                                          <table class="table">
                                            <tbody>
                                              <tr>
                                                <th>S.No.</th>
                                                <th>Campaign Name</th>
                                                <th>Schedule On</th>
                                              
                                                <th>Campaign Type</th>
                                                <th>No. of Users</th>
                                                <th>Created By</th>
                                                <th>Status</th>
                                                <th>Schedule Type</th>
                                                <th class="text-right action_col">Action</th>
                                              </tr>
                                                <tr *ngFor="let item of iosCapmlist | paginate: { id: 'foo',itemsPerPage: limit,currentPage: page,totalItems: totalRecords }; let i = index">
                                                  <td>{{((page-1)*limit)+i+1}}.</td>
                                                  <td>{{item.campaignName}}</td>
                                                <td>
                                                  <div class="schedule-on">{{item.scDate.replace(' ', 'T') | date:'MMM dd, yyyy  HH:mm'}}</div>
                                                </td>
                                              
                                                <td>
                                                  <div class="campaign-type">{{item.campaignType}}</div>
                                                </td>
                                                <td>{{item.audienceCount}} user(s)</td>
                                                <td class="text-capitalize">{{item.userId | telleCallerName:item.userId }}</td>
                                                <td>
                                                  <!-- <span *ngIf="item.scheduleType == 'repeat'; else elseStatusTemplate" tooltip="{{item.statusDescription}}" [tooltipAnimation]="true" tooltipPlacement="top">
                                                    Scheduled
                                                  </span> -->
                                                  <span tooltip="InActive" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='0' && item.scheduleType != 'repeat'">Pending</span>
                                                  <span tooltip="Paused" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='0' && item.scheduleType == 'repeat'">Paused</span>
                                                  <span tooltip="Active" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='1'">Active</span>
                                                  <span tooltip="Completed" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='2'">Completed</span>
                                                  <span tooltip="Error" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='3'">Error</span>
                                                          </td>
                                                <td class="text-capitalize">
                                                  <ng-container *ngIf="item.scheduleType == 'now' || item.scheduleType == null; else elseTypeTemplate">
                                                    Instant
                                                  </ng-container>
                                                  <ng-template #elseTypeTemplate>
                                                    {{item.scheduleType}}
                                                  </ng-template>
                                                </td>
                                                <td class="text-right"> 
                                                    <!-- Audience -->
                                                    <!-- <a (click)="openModal(template, item.id)" class="without-hover"><img src="assets/images/segmenteye.png"></a> -->
                                                    <a (click)="viewAudienceDetal(item.id,pushUrl)" class="without-hover"><img src="assets/images/segmenteye.png"></a>
                                                    <!-- <a *ngIf="item.campaignStatus=='2'  || item.scheduleType == 'repeat'" (click)="downloadReport(item,pushUrl)"> &nbsp;
                                                      <span class="icon-download"></span>
                                                    </a> -->
                                                    <a *ngIf="item.reportPath" style="color: inherit;
                                                      text-decoration: none;" href="{{item.reportPath}}" target="_blank" class="text_decoration_none"> &nbsp;
                                                        <span class="icon-download"></span>
                                                      </a>
                                                    <!-- <span *ngIf="item.campaignStatus!='Completed'">N/A</span> -->
                                                    <a *ngIf="item.campaignStatus!='2' && item.scheduleType != 'repeat' " (click)="deleteReport(item, i, pushUrl,'ios')"> &nbsp;
                                                      <img class="img" src="assets/images/delete.png" />
                                                    </a>
                                                    <div *ngIf="(item.campaignStatus == '0' || item.campaignStatus == '1') && item.scheduleType == 'repeat' "  class="custom-control custom-switch swich-button" [class.activeSwitch]="item.campaignStatus == '1'"> &nbsp;
                                                        <input type="checkbox" name="CurrentPluginLead" (change)="repeatUpdateStatus(item,pushUrl,'ios')"
                                                             class="custom-control-input" id="iosrepeatStatus{{i}}" />
                                                        <label class="custom-control-label" for="iosrepeatStatus{{i}}"></label>
                                                    </div>
                                                </td>
                        
                                              </tr>
                                              <tr *ngIf="iosCapmlist.length==0">
                                                <td class="no-records" colspan="10">No Reports Yet</td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                        
                                        <div class="table-footer footer-gray">
                                          <pagination-controls id="foo" class="pull-right" (pageChange)="dataForPaging($event,'ios')" maxSize="9" directionLinks="true" autoHide="true"
                                            responsive="true" previousLabel="Previous" nextLabel="Next" screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
                                            screenReaderCurrentLabel="You're on page">
                                          </pagination-controls>
                                          <div  class="textblue text-left">
                                            <div class="data-length"> Show
                                              <select [(ngModel)]="limit" (change)="page = 1;getCampaignsList('ios','reload')">
                                                <option value="20">20</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                              </select>
                                            </div>
                                            <span> Total Count: {{totalRecords}}</span>  
                                            </div>
                                        </div>
                                        
                                      
                            </div>
                        </div>
                    </tab>

                    <tab heading="Web" (selectTab)="page = 1; limit =20;getCampaignsList('web','reload')">
                      <div class="inner-page-body">
                          <div class="container-fluid">
                                
                                      <div class="table-responsive text-center">
                                        <table class="table">
                                          <tbody>
                                            <tr>
                                              <th>S.No.</th>
                                              <th>Campaign Name</th>
                                              <th>Schedule On</th>
                                            
                                              <th>Campaign Type</th>
                                              <th>No. of Users</th>
                                              <th>Created By</th>
                                              <th>Status</th>
                                              <th>Schedule Type</th>
                                              <th class="text-right action_col">Action</th>
                                            </tr>
                                              <tr *ngFor="let item of webCapmlist | paginate: { id: 'foo',itemsPerPage: limit,currentPage: page,totalItems: totalRecords }; let i = index">
                                                <td>{{((page-1)*limit)+i+1}}.</td>
                                                <td>{{item.campaignName}}</td>
                                              <td>
                                                <div class="schedule-on">{{item.scDate.replace(' ', 'T') | date:'MMM dd, yyyy  HH:mm'}}</div>
                                              </td>
                                              
                                              <td>
                                                <div class="campaign-type">{{item.campaignType}}</div>
                                              </td>
                                              <td>{{item.audienceCount}} user(s)</td>
                                              <td class="text-capitalize">{{item.userId | telleCallerName:item.userId }}</td>
                                              <td>
                                                <!-- <span *ngIf="item.scheduleType == 'repeat'; else elseStatusTemplate" tooltip="{{item.statusDescription}}" [tooltipAnimation]="true" tooltipPlacement="top">
                                                  Scheduled
                                                </span> -->
                                                <span tooltip="InActive" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='0' && item.scheduleType != 'repeat'">Pending</span>
                                                <span tooltip="Paused" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='0' && item.scheduleType == 'repeat'">Paused</span>
                                                <span tooltip="Active" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='1'">Active</span>
                                                <span tooltip="Completed" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='2'">Completed</span>
                                                <span tooltip="Error" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='3'">Error</span>
                                                    </td>
                                              <td class="text-capitalize">
                                                <ng-container *ngIf="item.scheduleType == 'now' || item.scheduleType == null; else elseTypeTemplate">
                                                  Instant
                                                </ng-container>
                                                <ng-template #elseTypeTemplate>
                                                  {{item.scheduleType}}
                                                </ng-template>
                                              </td>
                                              <td class="text-right"> 
                                                  <!-- Audience -->
                                                  <!-- <a (click)="openModal(template, item.id)" class="without-hover"><img src="assets/images/segmenteye.png"></a> -->
                                                  <a (click)="viewAudienceDetal(item.id,pushUrl)" class="without-hover"><img src="assets/images/segmenteye.png"></a>
                                                  <!-- <a *ngIf="item.campaignStatus=='2'  || item.scheduleType == 'repeat'" (click)="downloadReport(item,pushUrl)"> &nbsp;
                                                    <span class="icon-download"></span>
                                                  </a> -->
                                                  <a *ngIf="item.reportPath" style="color: inherit;
                                                      text-decoration: none;" href="{{item.reportPath}}" target="_blank" class="text_decoration_none"> &nbsp;
                                                        <span class="icon-download"></span>
                                                      </a>
                                                  <!-- <span *ngIf="item.campaignStatus!='Completed'">N/A</span> -->
                                                  <a *ngIf="item.campaignStatus!='2' && item.scheduleType != 'repeat' " (click)="deleteReport(item, i, pushUrl,'web')"> &nbsp;
                                                    <img class="img" src="assets/images/delete.png" />
                                                  </a>
                                                  <div *ngIf="(item.campaignStatus == '0' || item.campaignStatus == '1') && item.scheduleType == 'repeat' "  class="custom-control custom-switch swich-button" [class.activeSwitch]="item.campaignStatus == '1'"> &nbsp;
                                                      <input type="checkbox" name="CurrentPluginLead" (change)="repeatUpdateStatus(item,pushUrl,'web')"
                                                           class="custom-control-input" id="webrepeatStatus{{i}}" />
                                                      <label class="custom-control-label" for="webrepeatStatus{{i}}"></label>
                                                  </div>
                                              </td>
                      
                                            </tr>
                                            <tr *ngIf="webCapmlist.length==0">
                                              <td class="no-records" colspan="10">No Reports Yet</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                      
                                      <div class="table-footer footer-gray" >
                                        <pagination-controls id="foo" class="pull-right" (pageChange)="dataForPaging($event,'web')" maxSize="9" directionLinks="true" autoHide="true"
                                          responsive="true" previousLabel="Previous" nextLabel="Next" screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
                                          screenReaderCurrentLabel="You're on page">
                                        </pagination-controls>
                                        <div  class="textblue text-left">
                                          <div class="data-length"> Show
                                            <select [(ngModel)]="limit" (change)="page = 1;getCampaignsList('web','reload')">
                                              <option value="20">20</option>
                                              <option value="50">50</option>
                                              <option value="100">100</option>
                                            </select>
                                          </div>
                                          <span> Total Count: {{totalRecords}}</span>  
                                          </div>
                                      </div>
                                      
                                    
                          </div>
                      </div>
                  </tab>
                  </tabset>
              </tab>

              <tab heading="SMS" (selectTab)="page = 1; limit =20;getCampaignsList('sms','reload')">
                <div class="inner-page-body">
                    <div class="container-fluid">
                          
                                <div class="table-responsive text-center">
                                  <table class="table">
                                    <tbody>
                                      <tr>
                                        <th>S.No.</th>
                                        <th>Campaign Name</th>
                                        <th>Schedule On</th>
                                        
                                        <th>Campaign Type</th>
                                        <th>No. of Users</th>
                                        <th>Created By</th>
                                        <th>Status</th>
                                        <th>Schedule Type</th>
                                        <th class="text-right action_col">Action</th>
                                      </tr>
                                        <tr *ngFor="let item of smsCapmlist | paginate: { id: 'foo',itemsPerPage: limit,currentPage: page,totalItems: totalRecords }; let i = index">
                                          <td>{{((page-1)*limit)+i+1}}.</td>
                                          <td>{{item.campaignName}}</td>
                                        <td>
                                          <div class="schedule-on">{{item.scDate.replace(' ', 'T') | date:'MMM dd, yyyy  HH:mm'}}</div>
                                        </td>
                                        
                                        <td>
                                          <div class="campaign-type">{{item.campaignType}}</div>
                                        </td>
                                        <td>{{item.audienceCount}} user(s)</td>
                                        <td class="text-capitalize">{{item.userId | telleCallerName:item.userId }}</td>
                                        <td>
                                          <!-- <span *ngIf="item.scheduleType == 'repeat'; else elseStatusTemplate" tooltip="{{item.statusDescription}}" [tooltipAnimation]="true" tooltipPlacement="top">
                                            Scheduled
                                          </span> -->
                                          <span tooltip="InActive" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="campaignStatus=='0' && item.scheduleType != 'repeat'">Pending</span>
                                          <span tooltip="InActive" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='0' && item.scheduleType != 'repeat'">Pending</span>
                                          <span tooltip="Paused" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='0' && item.scheduleType == 'repeat'">Paused</span>
                                          <span tooltip="Active" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='1'">Active</span>
                                          <span tooltip="Completed" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='2'">Completed</span>
                                          <span tooltip="Error" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='3'">Error</span>
                                                    </td>
                                        <td class="text-capitalize">
                                          <ng-container *ngIf="item.scheduleType == 'now' || item.scheduleType == null; else elseTypeTemplate">
                                            Instant
                                          </ng-container>
                                          <ng-template #elseTypeTemplate>
                                            {{item.scheduleType}}
                                          </ng-template>
                                        </td>
                                        <td class="text-right"> 
                                            <!-- Audience -->
                                            <!-- <a (click)="openModal(template, item.id)" class="without-hover"><img src="assets/images/segmenteye.png"></a> -->
                                            <a (click)="viewAudienceDetal(item.id,smsUrl)" class="without-hover"><img src="assets/images/segmenteye.png"></a>
                                            <!-- <a *ngIf="item.campaignStatus=='2'  || item.scheduleType == 'repeat'" (click)="downloadReport(item,smsUrl)"> &nbsp;
                                              <span class="icon-download"></span>
                                            </a> -->

                                            <a *ngIf="item.reportPath" style="color: inherit;
                                                      text-decoration: none;" href="{{item.reportPath}}" target="_blank" class="text_decoration_none"> &nbsp;
                                                        <span class="icon-download"></span>
                                            </a>


                                            <!-- <span *ngIf="item.campaignStatus!='Completed'">N/A</span> -->
                                            <a *ngIf="item.campaignStatus!='2' && item.scheduleType != 'repeat' " (click)="deleteReport(item, i, smsUrl,'sms')"> &nbsp;
                                              <img class="img" src="assets/images/delete.png" />
                                            </a>
                                            <div *ngIf="(item.campaignStatus == '0' || item.campaignStatus == '1') && item.scheduleType == 'repeat' "  class="custom-control custom-switch swich-button" [class.activeSwitch]="item.campaignStatus == '1'" [class.inActiveSwitch]="item.campaignStatus == '0'"> &nbsp;
                                                <input type="checkbox" name="CurrentPluginLead" (change)="repeatUpdateStatus(item,smsUrl,'sms')"
                                                     class="custom-control-input" id="smsrepeatStatus{{i}}" />
                                                <label class="custom-control-label" for="smsrepeatStatus{{i}}"></label>
                                            </div>
                                        </td>
                
                                      </tr>
                                      <tr *ngIf="smsCapmlist.length==0">
                                        <td class="no-records" colspan="10">No Reports Yet</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                
                                <div class="table-footer footer-gray">
                                  <pagination-controls id="foo" class="pull-right" (pageChange)="dataForPaging($event,'sms')" maxSize="9" directionLinks="true" autoHide="true"
                                    responsive="true" previousLabel="Previous" nextLabel="Next" screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
                                    screenReaderCurrentLabel="You're on page">
                                  </pagination-controls>
                                  <div  class="textblue text-left">
                                    <div class="data-length"> Show
                                      <select [(ngModel)]="limit" (change)="page = 1;getCampaignsList('sms','reload')">
                                        <option value="20">20</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                      </select>
                                    </div>
                                    <span> Total Count: {{totalRecords}}</span>  
                                    </div>
                                </div>
                                
                              
                    </div>
                </div>
              </tab>

              <tab heading="Email" (selectTab)="page = 1; limit =20; getCampaignsList('email','reload')">
              <div class="inner-page-body">
                  <div class="container-fluid">
                        
                          <div class="table-responsive text-center">
                            <table class="table">
                              <tbody>
                                <tr>
                                  <th>S.No.</th>
                                  <th>Campaign Name</th>
                                  <th>Schedule On</th>
                                  
                                  <th>Campaign Type</th>
                                  <th>No. of Users</th>
                                  <th>Created By</th>
                                  <th>Status</th>
                                  <th>Schedule Type</th>
                                  <th class="text-right action_col">Action</th>
                                </tr>
                                  <tr *ngFor="let item of emailCapmlist | paginate: { id: 'foo',itemsPerPage: limit,currentPage: page,totalItems: totalRecords }; let i = index">
                                    <td>{{((page-1)*limit)+i+1}}.</td>
                                    <td>{{item.campaignName}}</td>
                                  <td>
                                    <div class="schedule-on">{{item.scDate.replace(' ', 'T') | date:'MMM dd, yyyy  HH:mm'}}</div>
                                  </td>
                                
                                  <td>
                                    <div class="campaign-type">{{item.campaignType}}</div>
                                  </td>
                                  <td>{{item.audienceCount}} user(s)</td>
                                  <td class="text-capitalize">{{item.userId | telleCallerName:item.userId }}</td>
                                  <td>
                                    <!-- <span *ngIf="item.scheduleType == 'repeat'; else elseStatusTemplate" tooltip="{{item.statusDescription}}" [tooltipAnimation]="true" tooltipPlacement="top">
                                      Scheduled
                                    </span> -->
                                    <span tooltip="InActive" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='0' && item.scheduleType != 'repeat'">Pending</span>
                                    <span tooltip="Paused" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='0' && item.scheduleType == 'repeat'">Paused</span>
                                    <span tooltip="Active" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='1'">Active</span>
                                    <span tooltip="Completed" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='2'">Completed</span>
                                    <span tooltip="Error" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='3'">Error</span>
                                            </td>
                                  <td class="text-capitalize">
                                    <ng-container *ngIf="item.scheduleType == 'now' || item.scheduleType == null; else elseTypeTemplate">
                                      Instant
                                    </ng-container>
                                    <ng-template #elseTypeTemplate>
                                      {{item.scheduleType}}
                                    </ng-template>
                                  </td>
                                  <td class="text-right"> 
                                      <!-- Audience -->
                                      <!-- <a (click)="openModal(template, item.id)" class="without-hover"><img src="assets/images/segmenteye.png"></a> -->
                                      <a (click)="viewAudienceDetal(item.id,emailUrl)" class="without-hover"><img src="assets/images/segmenteye.png"></a>
                                      <a *ngIf="item.reportPath != null" href="{{item.reportPath}}" target="_blank" class="text_decoration_none"> &nbsp;
                                        <span class="icon-download"></span>
                                      </a>
                                      <!-- <span *ngIf="item.campaignStatus!='Completed'">N/A</span> -->
                                      <a *ngIf="item.campaignStatus!='2' && item.scheduleType != 'repeat' " (click)="deleteReport(item, i, emailUrl,'email')"> &nbsp;
                                        <img class="img" src="assets/images/delete.png" />
                                      </a>
                                      <div *ngIf="(item.campaignStatus == '0' || item.campaignStatus == '1') && item.scheduleType == 'repeat' "  class="custom-control custom-switch swich-button" [class.activeSwitch]="item.campaignStatus == '1'"> &nbsp;
                                          <input type="checkbox" name="CurrentPluginLead" (change)="repeatUpdateStatus(item,emailUrl,'email')"
                                                class="custom-control-input" id="emailrepeatStatus{{i}}" />
                                          <label class="custom-control-label" for="emailrepeatStatus{{i}}"></label>
                                      </div>
                                  </td>

                                </tr>
                                <tr *ngIf="emailCapmlist.length==0">
                                  <td class="no-records" colspan="10">No Reports Yet</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <div class="table-footer footer-gray">
                            <pagination-controls id="foo" class="pull-right" (pageChange)="dataForPaging($event,'email')" maxSize="9" directionLinks="true" autoHide="true"
                              responsive="true" previousLabel="Previous" nextLabel="Next" screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
                              screenReaderCurrentLabel="You're on page">
                            </pagination-controls>
                            <div  class="textblue text-left">
                              <div class="data-length"> Show
                                <select [(ngModel)]="limit" (change)="page = 1;getCampaignsList('email','reload')">
                                  <option value="20">20</option>
                                  <option value="50">50</option>
                                  <option value="100">100</option>
                                </select>
                              </div>
                              <span> Total Count: {{totalRecords}}</span>  
                              </div>
                          </div>
                          
                  </div>
              </div>
              </tab>


              <tab heading="Whatsapp" (selectTab)="page = 1; limit =20;getCampaignsList('whatsapp','reload')">
              <div class="inner-page-body">
                <div class="container-fluid">
                      
                            <div class="table-responsive text-center">
                              <table class="table">
                                <tbody>
                                  <tr>
                                    <th>S.No.</th>
                                    <th>Campaign Name</th>
                                    <th>Schedule On</th>
                                    
                                    <th>Campaign Type</th>
                                    <th>No. of Users</th>
                                    <th>Created By</th>
                                    <th>Status</th>
                                    <th>Schedule Type</th>
                                    <th class="text-right action_col">Action</th>
                                  </tr>
                                    <tr *ngFor="let item of whatsappCapmlist | paginate: { id: 'foo',itemsPerPage: limit,currentPage: page,totalItems: totalRecords }; let i = index">
                                      <td>{{((page-1)*limit)+i+1}}.</td>
                                      <td>{{item.campaignName}}</td>
                                    <td>
                                      <div class="schedule-on">{{item.scDate.replace(' ', 'T') | date:'MMM dd, yyyy  HH:mm'}}</div>
                                    </td>
                                   
                                    <td>
                                      <div class="campaign-type">{{item.campaignType}}</div>
                                    </td>
                                    <td>{{item.audienceCount}} user(s)</td>
                                    <td class="text-capitalize">{{item.userId | telleCallerName:item.userId }}</td>
                                    <td>
                                      <!-- <span *ngIf="item.scheduleType == 'repeat'; else elseStatusTemplate" tooltip="{{item.statusDescription}}" [tooltipAnimation]="true" tooltipPlacement="top">
                                        Scheduled
                                      </span> -->
                                      <span tooltip="InActive" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='0' && item.scheduleType != 'repeat'">Pending</span>
                                      <span tooltip="Paused" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='0' && item.scheduleType == 'repeat'">Paused</span>
                                      <span tooltip="Active" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='1'">Active</span>
                                      <span tooltip="Completed" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='2'">Completed</span>
                                      <span tooltip="Error" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='3'">Error</span>
                                            </td>
                                    <td class="text-capitalize">
                                      <ng-container *ngIf="item.scheduleType == 'now' || item.scheduleType == null; else elseTypeTemplate">
                                        Instant
                                      </ng-container>
                                      <ng-template #elseTypeTemplate>
                                        {{item.scheduleType}}
                                      </ng-template>
                                    </td>
                                    <td class="text-right"> 
                                        <!-- Audience -->
                                        <!-- <a (click)="openModal(template, item.id)" class="without-hover"><img src="assets/images/segmenteye.png"></a> -->
                                        <a (click)="viewAudienceDetal(item.id,whatsAppUrl)" class="without-hover"><img src="assets/images/segmenteye.png"></a>
                                        <a *ngIf="item.campaignStatus=='2'  || item.scheduleType == 'repeat'" (click)="downloadReport(item,whatsAppUrl)"> &nbsp;
                                          <span class="icon-download"></span>
                                        </a>
                                        <!-- <span *ngIf="item.campaignStatus!='Completed'">N/A</span> -->
                                        <a *ngIf="item.campaignStatus!='2' && item.scheduleType != 'repeat' " (click)="deleteReport(item, i, whatsAppUrl,'whatsapp')"> &nbsp;
                                          <img class="img" src="assets/images/delete.png" />
                                        </a>
                                        <div *ngIf="(item.campaignStatus == '0' || item.campaignStatus == '1') && item.scheduleType == 'repeat' "  class="custom-control custom-switch swich-button" [class.activeSwitch]="item.campaignStatus == '1'"> &nbsp;
                                            <input type="checkbox" name="whatrepeatStatus" (change)="repeatUpdateStatus(item,whatsAppUrl,'whatsapp')"
                                                 class="custom-control-input" id="whatrepeatStatus{{i}}" />
                                            <label class="custom-control-label" for="whatrepeatStatus{{i}}"></label>
                                        </div>
                                    </td>

                                  </tr>
                                  <tr *ngIf="whatsappCapmlist.length==0">
                                    <td class="no-records" colspan="10">No Reports Yet</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>

                            <div class="table-footer footer-gray">
                              <pagination-controls id="foo" class="pull-right" (pageChange)="dataForPaging($event,'whatsapp')" maxSize="9" directionLinks="true" autoHide="true"
                                responsive="true" previousLabel="Previous" nextLabel="Next" screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
                                screenReaderCurrentLabel="You're on page">
                              </pagination-controls>
                              <div  class="textblue text-left">
                                <div class="data-length"> Show
                                  <select [(ngModel)]="limit" (change)="page = 1;getCampaignsList('whatsapp','reload')">
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                  </select>
                                </div>
                                <span> Total Count: {{totalRecords}}</span>  
                                </div>
                            </div>
                            
                </div>
              </div>
              </tab>

              <tab heading="In-App" (selectTab)="page = 1; limit =20; getCampaignsList('inapp','reload')">
              <div class="inner-page-body">
              <div class="container-fluid">
                    
                          <div class="table-responsive text-center">
                            <table class="table">
                              <tbody>
                                <tr>
                                  <th>S.No.</th>
                                  <th>Campaign Name</th>
                                  <th>Schedule On</th>
                                  <th>Campaign Type</th>
                                  <th>No. of Users</th>
                                  <th>Created By</th>
                                  <th>Status</th>
                                  <th>Schedule Type</th>
                                  <th class="text-right action_col">Action</th>
                                </tr>
                                  <tr *ngFor="let item of inappCapmlist | paginate: { id: 'foo',itemsPerPage: limit,currentPage: page,totalItems: totalRecords }; let i = index">
                                    <td>{{((page-1)*limit)+i+1}}.</td>
                                    <td>{{item.campaignName}}</td>
                                  <td>
                                    <div class="schedule-on">{{item.scDate.replace(' ', 'T') | date:'MMM dd, yyyy  HH:mm'}}</div>
                                  </td>
                                 
                                  <td>
                                    <div class="campaign-type">{{item.campaignType}}</div>
                                  </td>
                                  <td>{{item.audienceCount}} user(s)</td>
                                  <td class="text-capitalize">{{item.userId | telleCallerName:item.userId }}</td>
                                  <td>
                                    <span tooltip="InActive" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='0' && item.scheduleType != 'repeat'">Pending</span>
                                    <span tooltip="Paused" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='0' && item.scheduleType == 'repeat'">Paused</span>
                                    <span tooltip="Active" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='1'">Active</span>
                                    <span tooltip="Completed" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='2'">Completed</span>
                                    <span tooltip="Error" [tooltipAnimation]="true" tooltipPlacement="top" *ngIf="item.campaignStatus=='3'">Error</span>
                                        </td>
                                  <td class="text-capitalize">
                                    <ng-container *ngIf="item.scheduleType == 'now' || item.scheduleType == null; else elseTypeTemplate">
                                      Instant
                                    </ng-container>
                                    <ng-template #elseTypeTemplate>
                                      {{item.scheduleType}}
                                    </ng-template>
                                  </td>
                                  <td class="text-right"> 
                                      <!-- Audience -->
                                      <!-- <a (click)="openModal(template, item.id)" class="without-hover"><img src="assets/images/segmenteye.png"></a> -->
                                      <a (click)="viewAudienceDetal(item.id,inAppUrl)" class="without-hover"><img src="assets/images/segmenteye.png"></a>
                                      <a *ngIf="item.campaignStatus=='2'  || item.scheduleType == 'repeat'" (click)="downloadReport(item,inAppUrl)"> &nbsp;
                                        <span class="icon-download"></span>
                                      </a>
                                      <!-- <span *ngIf="item.campaignStatus!='Completed'">N/A</span> -->
                                      <a *ngIf="item.campaignStatus!='2' && item.scheduleType != 'repeat' " (click)="deleteReport(item, i, inAppUrl,'inapp')"> &nbsp;
                                        <img class="img" src="assets/images/delete.png" />
                                      </a>
                                      <div *ngIf="(item.campaignStatus == '0' || item.campaignStatus == '1') && item.scheduleType == 'repeat' "  class="custom-control custom-switch swich-button" [class.activeSwitch]="item.campaignStatus == '1'"> &nbsp;
                                          <input type="checkbox" name="CurrentPluginLead" (change)="repeatUpdateStatus(item,inAppUrl,'inapp')"
                                               class="custom-control-input" id="inApprepeatStatus{{i}}" />
                                          <label class="custom-control-label" for="inApprepeatStatus{{i}}"></label>
                                      </div>
                                  </td>

                                </tr>
                                <tr *ngIf="inappCapmlist.length==0">
                                  <td class="no-records" colspan="10">No Reports Yet</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <div class="table-footer footer-gray" >
                            <pagination-controls id="foo" class="pull-right" (pageChange)="dataForPaging($event,'inapp')" maxSize="9" directionLinks="true" autoHide="true"
                              responsive="true" previousLabel="Previous" nextLabel="Next" screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
                              screenReaderCurrentLabel="You're on page">
                            </pagination-controls>
                            <div  class="textblue text-left">
                              <div class="data-length"> Show
                                <select [(ngModel)]="limit" (change)="page = 1;getCampaignsList('inapp','reload')">
                                  <option value="20">20</option>
                                  <option value="50">50</option>
                                  <option value="100">100</option>
                                </select>
                              </div>
                              <span> Total Count: {{totalRecords}}</span>  
                              </div>
                          </div>
                          
              </div>
              </div>
              </tab>

            </tabset>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="inner-page-footer"></div>

  </section>
</main>

<ng-template #template>
  <form>
    <div class="forgot-password-modal">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Audience</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="audience-detail">
          <table>
            <tr *ngFor="let item of campaign">
              <td>{{item.key}} :</td>
              <td>{{item.value}}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </form>
</ng-template>


<ng-template #reportDeleteConfirm>
  <!-- delete popup -->
  <form>
      <div class="forgot-password-modal">
          <div class="modal-header">
              <h4 class="modal-title pull-left">Delete report</h4>
              <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                  <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <div class="modal-body modal-user-management">
              <div class="form-group mb-0">
                  <p class="pt-2">
                      Are you sure, you want to delete?
                  </p>
                  <div class="d-flex justify-content-end mt-5">
                      <div>
                          <button class="btn remove-user-btn mr-4" (click)="modalRef.hide()" type="button">Dismiss</button>
                      </div>
                      <div>
                          <button class="btn save-btn" type="button" *ngIf="isDeleteReportShow" (click)="deleteReport(undefined)">Yes</button>
                          <button class="btn save-btn" type="button" *ngIf="!isDeleteReportShow" disabled>Yes</button>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </form>
</ng-template>