import { HttpHeaders } from "@angular/common/http";
import { FormGroup, FormControl, FormArray } from "@angular/forms";
import { environment } from "../../../environments/environment";
import * as moment from 'moment';
import { ProjectDetailObj } from "../../core/modal/project";

export class Global {
    public uploadlist: any = [];
    public globallistdata: any = {};
    segmentId: any;
    planId: any={};
    segmentname: any;
    
    ApiUrl: string = environment.apiUrl;
    WebUrl: string = environment.webUrl;
    webInapp: string = environment.webInapp;
    StaticUrl: string = environment.staticUrl;

    re= /^9[47]\d{0,13}/;
    pattern= /(\d{1,4})/g;
    razorpayCredentialTest = "rzp_test_gLkTBEROQrR3j1";
    razorpayCredentialLive = "rzp_live_dgkaCGHsp9WjZK";
    RequiredRegx = '^(?!\s*$).+';
    AlphaPattern: string = "^[a-z A-Z]*$";
    AlphaNumPattern: string = "^[a-zA-Z0-9 ]*$";
    AlphaNumPatternWithAmpersand: string = "^[a-zA-Z0-9-_/\\ ,\\.\\&\\\\n]*$";
    AlphaNumPatternWithChar: string = "^[a-z A-Z0-9_/-]*$";
    AlphaNumPatternForAddress: string = "^[a-z A-Z0-9_,/-]*$";
    AlphaPatternWithSpace: string = "^[a-z A-Z]*$";
    AlphaNumericPattern: string =
        "^[a-zA-Z0-9\\/\\:\\_\\(\\)\\\\'\\\" ,\\.\\-\\$%&\\|/#\\n]*$";
    gst_regex = "^([0]{1}[1-9]{1}|[1-2]{1}[0-9]{1}|[3]{1}[0-7]{1})([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})*$"
    NumericPattern: string = "^[0-9]*$";
    PhonePattern: string = "^[+][0-9]{1,3}\\-[0-9]*$";
    EmailPattern: string = "^[A-Za-z0-9]+[A-Za-z0-9._!#$%&'*+-/=?^_`{|}~]+@([A-Za-z0-9-]+[.]){1,2}[A-Za-z.]{2,10}$";
    Url: string = "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?";
    User: any = JSON.parse(sessionStorage.getItem("currentUser"));
    MessageRequired: string = "Please fill all mandatory fields.";
    GCAPTCHARequired: string = "Please complete the reCAPTCHA verification.";
    projectDetails: ProjectDetailObj;

    CampaignData: any = {};
    datePickerConfig: any = {showWeekNumbers: false,dateInputFormat: 'DD/MM/YYYY'};
    
    Channel: any = {
        Segments: [],
        Events: [],
        Source: [],
        Attributes: [],
        sourceFormIds:[],
        fromDate: '',
        toDate: '',
        dateMsg: '',
        AllAudience: false,
    };

    categorySelectByCamapign:any=[];

    SearchParameters: any[];
    notificationSum:any;
    notificationDataSize:any = 20;
    ClearChannel() {
        this.Channel = {
            Segments: [],
            Events: [],
            Source: [],
            Attributes: [],
            sourceFormIds:[],
            fromDate: '',
            toDate: '',
            dateMsg: '',
            AllAudience: false
        };
    }

    AnyChannelSelected() {
        if (
            this.Channel.Segments.length > 0 ||
            this.Channel.Events.length > 0 ||
            this.Channel.Source.length > 0 ||
            this.Channel.Attributes.length > 0 || (this.Channel.AllAudience == true)
        ) {
            
        return true;
        }
        return false;
    }

    ClearSpecificChannel(channelType) {
        switch (channelType) {
        case "Segments":
            this.Channel.Segments = [];
            break;
        case "Events":
            this.Channel.Events = [];
            break;
        case "Source":
            this.Channel.Source = [];
            break;
        case "Attributes":
            this.Channel.Attributes = [];
            break;
        case "AllAudience":
            this.Channel.AllAudience = false;
            break;
            case "date":
            this.Channel.fromDate = '';
            this.Channel.toDate = '';
            this.Channel.dateMsg = '';
            break;
        default:
            break;
        }
    }

    ClearSpecificChannelId(channelType, id) {
        switch (channelType) {
        case "Segments":
            this.Channel.Segments = this.Channel.Segments.filter(function(x){ return x.id != id });
            break;
        case "Events":
            this.Channel.Events = this.Channel.Events.filter(function(x){ return x.id!=id });
            break;
        case "Source":
            this.Channel.Source = this.Channel.Source.filter(function(x){ return x.pluginId!=id });
            break;
        case "Attributes":
            this.Channel.Attributes = this.Channel.Attributes.filter(function(x){ return x.id!=id });
            break;
        case "AllAudience":
            this.Channel.AllAudience = false;
            break;
        case "date":
            this.Channel.fromDate = '';
            this.Channel.toDate = '';
            this.Channel.dateMsg = '';
            break;
        default:
            break;
        }
    }

    Header() {
        const headerSettings: { [name: string]: string | string[] } = {};

        headerSettings["Authorization"] =
            "Basic " + "Y3JvbmJlcnJ5QHVzZXJuYW1lOmNyb25iZXJyeUBwYXNzd29yZA==";
        headerSettings["Content-Type"] = "application/json";
        return new HttpHeaders(headerSettings);
    }

    multipartHeader() {
        const headerSettings: { [name: string]: string | string[] } = {};

        headerSettings["Authorization"] =
            "Basic " + "Y3JvbmJlcnJ5QHVzZXJuYW1lOmNyb25iZXJyeUBwYXNzd29yZA==";

        return new HttpHeaders(headerSettings);
    }
  
    bitlyHeader() {
        const headerSettings: { [name: string]: string | string[] } = {};
        headerSettings["Authorization"] =
            "Bearer " + "db91a01136c6093bbad05aca5aa0f6b85d04571e";
            headerSettings["Content-Type"] = "application/json";
        return new HttpHeaders(headerSettings);
    }

    validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            } else if (control instanceof FormArray) {
                control.controls.forEach(controlx => {
                    if (controlx instanceof FormGroup) {
                        this.validateAllFormFields(controlx);
                    }
                });
            } else {
            
            }
        });
    }

    CKEditorConfig(height, type){
        var data = {
            fullPage : false,
            disallowedContent:'[on*];script',
            height:height,
            extraPlugins : 'image2',
            autoParagraph : false,
            toolbar: []
        }
        if(type == 'full' || type == 'email' ){
            if(type == 'email'){
                data.fullPage = true
            }
            data.toolbar = [
                { name: 'document', items: [ 'Source'] },
                { name: 'clipboard', items: [ 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo' ] },
                { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat' ] },
                { name: 'links', items: [ 'Link', 'Unlink' ] },
                { name: 'insert', items: [ 'Image', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'Iframe' ] },
                { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
                { name: 'styles', items: [ 'Styles', 'Format', 'Font', 'FontSize' ] },
                { name: 'paragraph', items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'] },
                { name: 'tools', items: [ 'Maximize', 'ShowBlocks' ] },
            ]
        } else if(type == 'customform'){
            data.toolbar = [
                { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat' ] },
                { name: 'links', items: [ 'Link', 'Unlink' ] },
                { name: 'paragraph', items: [ 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'] },
                { name: 'styles', items: [ 'FontSize' ] },
            ]
        } else {
            data.toolbar = [
                { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat' ] },
                { name: 'paragraph', items: [ 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'] },
                { name: 'links', items: [ 'Link', 'Unlink' ] },
                { name: 'styles', items: [ 'Styles', 'Format', 'Font', 'FontSize' ] },
                { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
                { name: 'insert', items: [ 'Image', 'Smiley'] },
                { name: 'document', items: [ 'Source' ] },
            ]
        }
        return data;
    }

    copyText(val: string) {
        let selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    }

    customDateRange() {
        var range = { 
            'Today': [moment(), moment()],
            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
            // 'Last 30 Days': [moment().subtract(29, 'days'), moment()],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            'Last 4 Month': [moment().subtract(4, 'month'), moment().subtract(1, 'days')],
            'Last 6 Month': [moment().subtract(6, 'month'), moment().subtract(1, 'days')]
        }
        return range;
    }

    customDateRangeForInvoice() {
        var range = { 
            'Today': [moment(), moment()],
            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
            'This Month': [moment().startOf('month'), moment()],
            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            'Last 3 Month': [moment().subtract(3, 'month'), moment().subtract(1, 'days')],
            'Last 6 Month': [moment().subtract(6, 'month'), moment().subtract(1, 'days')],
            'Last 1 Year': [moment().subtract(1, 'years'), moment().subtract(1, 'days')],
        }
        return range;
    }

    isPageSubscribe(name){
        var status = false;
        var list = [];
        if(this.projectDetails!= undefined && this.projectDetails.pricingPlans != undefined){
            list = this.projectDetails.pricingPlans.plugins.split(',');
            list.forEach(element => {
                if(element == name){
                    status = true;
                }
            });
            return status;
        }
    }
}