import { AbstractControl, ValidatorFn } from '@angular/forms';

export function minLengthOrBlank(min: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
        if(control.value==null){
            return null;
        }
        const length = control.value.length;
        if (length == 0 || length >= min) {
            return null
        }
        return { 'minLengthOrBlank': true };
    };
}