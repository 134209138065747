<main>
    <section class="inner-page">
        <div class="inner-page-body border-bottom-0" *ngIf="audience != null && audience != undefined">
            <div class="container-fluid p-l-0 p-r-0">
                <div class="row">
                    <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
                        <div class="audianceDetailBox" #personalDetailContainer>
                            <div class="leftPersonalBox">
                                <div class="topaudiName">
                                    <div class="leftIconName" *ngIf="audience.name">
                                        <span class="iconWord" *ngIf="audience.name">{{audience.name.charAt(0)}}</span>
                                    </div>
                                    <div class="rightFullName">
                                        <div class="topFullName" *ngIf="audience.name && showNameInput == false">
                                            {{audience.name}}
                                            <span class="edit_icon_au_name" (click)="addAudName()">
                                                <img src="assets/images/edit.png">
                                            </span>
                                        </div>
                                    </div>
                                    <div class="leftIconName" *ngIf="audience.name == null">
                                        <span class="iconWord add_name">
                                            <img src="assets/images/plus-icon-white.png" title="Add name"
                                                (click)="addAudName()">
                                        </span>
                                    </div>
                                    <div class="rightFullName" *ngIf="showNameInput">
                                        <div class="input-group">
                                            <input type="text" class="form-control" #customeNameField
                                                [(ngModel)]="audNameInput">
                                            <div class="input-group-append">
                                                <button class="input-group-text"
                                                    (click)="saveCustomField(customeNameField.value,'name')">Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="applicatinStageLeadStage">
                                    <div class="StageOuterBox">
                                        <div class="topTitleStage">
                                            Audience ID
                                        </div>
                                        <div class="stageDetailBottom">
                                            {{audienceIdData}}
                                            <span class="cursor-pointer" *ngIf="audience.email">
                                                <img src="assets/images/copy.svg"
                                                    (click)="copyText(audience.audience_id)">
                                            </span>
                                        </div>
                                    </div>
                                    <div class="StageOuterBox leadStageOuter">
                                        <div class="topTitleStage">
                                            Lead Stage:
                                        </div>
                                        <div class="stageDetailBottom" *ngIf="audience">
                                            <ng-container *ngIf="audience.params">
                                                {{audience.params.lead_status}}
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                                <div class="audiDetailBottom">
                                    <table class="audiDetailTable">
                                        <tr>
                                            <th>Email:</th>
                                            <td><span (click)="goToCountFilter(audience.email)" class="linkToFilter">{{
                                                    audience.emailData }} </span>

                                                <span class="cursor-pointer" *ngIf="audience.email">
                                                    <img src="assets/images/copy.svg"
                                                        (click)="copyText(audience.email)">
                                                </span>
                                                <ng-container *ngIf="audience.email == null">
                                                    <span class="cursor-pointer addIco" (click)="addAudEmail()"
                                                        *ngIf="showEmailInput == false">
                                                        <img src="assets/images/plus-icon.png" title="Add email">
                                                    </span>
                                                    <div class="input-group" *ngIf="showEmailInput">
                                                        <input type="text" class="form-control" #customeEmailField>
                                                        <div class="input-group-append">
                                                            <button class="input-group-text"
                                                                (click)="saveCustomField(customeEmailField.value,'email')">Save</button>
                                                        </div>
                                                    </div>
                                                </ng-container>

                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Mobile:</th>
                                            <td class="mobileNumActions"><span
                                                    (click)="goToCountFilter(audience.mobile)" class="linkToFilter">{{
                                                    audience.mobile }} </span>
                                                <span class="cursor-pointer" *ngIf="audience.mobile">
                                                    <a class="whatsapp">
                                                        <img src="assets/images/copy.svg"
                                                            (click)="copyText(audience.mobile)">
                                                    </a>
                                                    <a href="javascript:void(0)" class="whatsapp web_action">
                                                        <img (click)="callAssistAction(audience.mobile)"
                                                            src="assets/images/form-phone.svg">
                                                    </a>
                                                    <a href="tel:{{audience.mobile}}" class="whatsapp mobile_action">
                                                        <img src="assets/images/form-phone.svg">
                                                    </a>
                                                    <a class="whatsapp"
                                                        (click)="getEmailDetails(audience._id,audience.mobile,'whatsapp','india')">
                                                        <img src="assets/images/whatsapp.svg">
                                                    </a>
                                                </span>

                                                <ng-container *ngIf="audience.mobile == null">
                                                    <span class="cursor-pointer addIco" (click)="addAudPhone()"
                                                        *ngIf="showPhoneInput == false">
                                                        <img src="assets/images/plus-icon.png" title="Add phone">
                                                    </span>
                                                    <div class="input-group" *ngIf="showPhoneInput">
                                                        <input type="text" class="form-control" #customePhoneField
                                                            onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                                            maxlength="12">
                                                        <div class="input-group-append">
                                                            <button class="input-group-text"
                                                                (click)="saveCustomField(customePhoneField.value,'mobile')">Save</button>
                                                        </div>
                                                    </div>
                                                </ng-container>

                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Added On:</th>
                                            <td>{{addedOn}}</td>


                                        </tr>
                                        <tr>

                                            <th>Updated On:</th>
                                            <td>{{updatedOn}}</td>

                                        </tr>

                                        <tr *ngIf="audience.ios_fcm_token != null">
                                            <th>Ios Token:</th>
                                            <td>{{audience.ios_fcm_token_data}}
                                                <span class="cursor-pointer">
                                                    <img src="assets/images/copy.svg"
                                                        (click)="copyText(audience.ios_fcm_token)">
                                                </span>
                                            </td>
                                        </tr>
                                        <tr *ngIf="audience.android_fcm_token != null">
                                            <th>Andorid Token:</th>
                                            <td>{{audience.android_fcm_token_data}}
                                                <span class="cursor-pointer">
                                                    <img src="assets/images/copy.svg"
                                                        (click)="copyText(audience.android_fcm_token)">
                                                </span>
                                            </td>
                                        </tr>
                                        <tr *ngIf="audience.web_fcm_token != null">
                                            <th>Web Token:</th>
                                            <td>{{audience.web_fcm_token_data}}
                                                <span class="cursor-pointer">
                                                    <img src="assets/images/copy.svg"
                                                        (click)="copyText(audience.web_fcm_token)">
                                                </span>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>

                            <div class="whatsAppConfigPopup" *ngIf="showWhatsAppWindow">
                                <div class="forgot-password-modal">
                                    <div class="modal-header">
                                        <h4 class="modal-title pull-left">Send WhatsApp Message</h4>
                                        <button type="button" class="close pull-right" (click)="closeWhatsAppWindow()">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>



                                    <div class="modal-body">
                                        <form>
                                            Phone Number: {{whatsappMsgData.phone}}
                                            <div class="form-group">
                                                <label class="control-label required pt-0">Message</label>
                                                <textarea trim="blur" class="form-control"
                                                    [ngClass]="{'invalid-control' : whatsappMsgData.smsText == ''}"
                                                    [(ngModel)]="whatsappMsgData.smsText" rows="3"
                                                    name="whatsappMsg"></textarea>
                                            </div>
                                            <div class="text-right">
                                                <button class="btn btn btn-dark" (click)="sendWhatsapp()"
                                                    type="submit">Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="selectTags">
                                <div class="whatsAppConfigPopup">
                                    <div class="forgot-password-modal">
                                        <div class="modal-header">
                                            <h4 class="modal-title pull-left">Select Tag</h4>
                                            <button type="button" class="close pull-right" (click)="selectTags = false">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>



                                        <div class="modal-body">
                                            <form>

                                                <div class="form-group">
                                                    <label class="control-label pt-0">Tag List</label>
                                                    <select class="form-control" #tagsSelector>
                                                        <option *ngFor="let tags of selectTagsVals"
                                                            [value]="tags.paramValue">{{tags.paramValue}}</option>
                                                    </select>
                                                </div>
                                                <!-- <div class="text-right">
                                                    <button class="btn btn btn-dark" type="submit"
                                                        (click)="tagSubmit(tagsSelector.value)">Submit</button>
                                                </div> -->
                                                <div class="text-right">
                                                    <button class="btn btn btn-dark" type="submit"
                                                        (click)="tagSelected(tagsSelector.value)">Submit</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="communicationDetailAudi w-100">
                                <div class="row" [class.collapseStatus]="collapsibleStatus">
                                    <div class="col-12 d-block d-md-none">
                                        <div class="filterTitle heading">
                                            Communication Details
                                            <span class="collapserFilterBox">
                                                <span class="hidderFilter" (click)="collapsibleCommunication('hide')">
                                                    <img src="assets/images/up.png">
                                                </span>
                                                <span class="showerFilter" (click)="collapsibleCommunication('show')">
                                                    <img src="assets/images/down.png">
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" *ngIf="collapsibleStatus">
                                    <div class="col-md-4 col-sm-6 col-xs-12 col-lg-4">
                                        <div class="statusTitle">Communication Status:</div>
                                        <div class="columnInnerData" *ngIf="timelineStats != null">
                                            <div *ngFor="let list of timelineStats" class="txtCamelCase">
                                                {{list.type}} sent - {{list.count}}
                                            </div>
                                        </div>
                                        <ng-container *ngIf="timelineStats != null">
                                            <div class="columnInnerData" *ngIf="timelineStats.length == 0">
                                                N/A
                                            </div>
                                        </ng-container>
                                    </div>

                                    <div class="col-md-4 col-sm-6 col-xs-12 col-lg-4">
                                        <div class="statusTitle">Call logs:</div>
                                        <div class="columnInnerData" *ngIf="callDataCounter == null">
                                            <a href="javascript:void(0);" (click)="getcallLogs()">Load Logs</a>
                                        </div>
                                        <ng-container *ngIf="callDataCounter != null">
                                            <div class="columnInnerData" *ngIf="callDataCounter.length == 0">
                                                N/A
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="callDataCounter != null && callDataCounter.length > 0">
                                            <div class="columnInnerData" *ngFor="let list of callDataCounter;">
                                                <span *ngIf="list.type == '0'">NOTYPE - {{list.totalCount}}</span>
                                                <span *ngIf="list.type == '1'">INCOMING - {{list.totalCount}}</span>
                                                <span *ngIf="list.type == '2'">OUTGOING - {{list.totalCount}}</span>
                                                <span *ngIf="list.type == '3'">MISSED - {{list.totalCount}}</span>
                                                <span *ngIf="list.type == '4'">VOICEMAIL - {{list.totalCount}}</span>
                                                <span *ngIf="list.type == '5'">REJECTED - {{list.totalCount}}</span>
                                                <span *ngIf="list.type == '6'">BLOCKED - {{list.totalCount}}</span>
                                            </div>
                                        </ng-container>
                                    </div>

                                    <div class="col-md-4 col-sm-6 col-xs-12 col-lg-4">
                                        <div class="statusTitle">Lead Owner:</div>
                                        <div class="columnInnerData txtCapitalize" *ngIf="audience">
                                            <ng-container
                                                *ngIf="audience.params">{{audience.params.telecaller_name}}</ng-container>
                                        </div>
                                    </div>

                                    <div class="col-md-4 col-sm-6 col-xs-12 col-lg-4">
                                        <div class="statusTitle">Recent Followup</div>
                                        <ng-container *ngIf="leadAssignData !=null">
                                            {{leadAssignData.lead_followdate}}

                                        </ng-container>

                                    </div>

                                    <div class="col-md-4 col-sm-6 col-xs-12 col-lg-4">
                                        <div class="statusTitle">Lead Source</div>
                                        <div class="columnInnerData">{{audience.source}}</div>
                                    </div>
                                    <ng-container *ngIf="leadAssignData">
                                        <div class="col-md-4 col-sm-6 col-xs-12 col-lg-4 theme_txt_color"
                                            *ngIf="audience.params!=undefined && audience.params.lead_count > 0">
                                            <div class="statusTitle">Repeat Count</div>
                                            <div class="columnInnerData c-pointer" (click)="showRepeatLeadTab()">
                                                {{audience.params.lead_count}}</div>
                                        </div>
                                    </ng-container>

                                    <div class="pdfDownloader shareIcon ivrCallIcon" tooltip="IVR Call"
                                    tooltipplacement="top" (click)="callToIvr()" *ngIf="ivrActivation && audience.mobile">
                                    <img src="assets/images/phone-call-svgrepo-com.svg">
                                    </div>

                                    <div class="pdfDownloader shareIcon moveCategory" tooltip="Move Category"
                                        tooltipplacement="top" (click)="moveCat = true;" *ngIf="isSubscribe">
                                        <img src="assets/images/Swap.svg">
                                    </div>



                                    <div class="pdfDownloader shareIcon" *ngIf="showShareIcon">
                                        <span class="pdf" tooltip="Share" tooltipplacement="top"
                                            (click)="shareAction(); shareList = true">
                                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24"
                                                height="24" viewBox="0 0 30 30">
                                                <path
                                                    d="M 23 3 A 4 4 0 0 0 19 7 A 4 4 0 0 0 19.09375 7.8359375 L 10.011719 12.376953 A 4 4 0 0 0 7 11 A 4 4 0 0 0 3 15 A 4 4 0 0 0 7 19 A 4 4 0 0 0 10.013672 17.625 L 19.089844 22.164062 A 4 4 0 0 0 19 23 A 4 4 0 0 0 23 27 A 4 4 0 0 0 27 23 A 4 4 0 0 0 23 19 A 4 4 0 0 0 19.986328 20.375 L 10.910156 15.835938 A 4 4 0 0 0 11 15 A 4 4 0 0 0 10.90625 14.166016 L 19.988281 9.625 A 4 4 0 0 0 23 11 A 4 4 0 0 0 27 7 A 4 4 0 0 0 23 3 z">
                                                </path>
                                            </svg>
                                        </span>
                                    </div>



                                    <div class="pdfDownloader">
                                        <span class="pdf" tooltip="Download PDF" tooltipplacement="top"
                                            (click)="downloadPdf(personalDetailContainer , leadDetailBox)">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                fill="currentColor" class="bi bi-file-pdf" viewBox="0 0 16 16">
                                                <path
                                                    d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z" />
                                                <path
                                                    d="M4.603 12.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.701 19.701 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.187-.012.395-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.065.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.716 5.716 0 0 1-.911-.95 11.642 11.642 0 0 0-1.997.406 11.311 11.311 0 0 1-1.021 1.51c-.29.35-.608.655-.926.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.27.27 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.647 12.647 0 0 1 1.01-.193 11.666 11.666 0 0 1-.51-.858 20.741 20.741 0 0 1-.5 1.05zm2.446.45c.15.162.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.881 3.881 0 0 0-.612-.053zM8.078 5.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z" />
                                            </svg>
                                        </span>
                                    </div>


                                    <div *ngIf="moveCat">
                                        <div class="whatsAppConfigPopup">
                                            <div class="forgot-password-modal userSelect">
                                                <div class="modal-header">
                                                    <h4 class="modal-title pull-left">Move Lead By Category</h4>
                                                    <button type="button" class="close pull-right"
                                                        (click)="moveCat = false">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>


                                                <form [formGroup]="moveCatForm">
                                                    <div class="modal-body">
                                                        <div class="form-group">
                                                            <label class="control-label pt-0">Select Category</label>
                                                            <select class="form-control" (change)="categorySelected()"
                                                                [(ngModel)]="categoryInput"
                                                                formControlName="categoryList">
                                                                <option *ngFor="let category of dataList"
                                                                    [value]="category.linkRewrite">{{category.name}}
                                                                </option>
                                                            </select>
                                                        </div>

                                                        <div class="form-group">
                                                            <label class="control-label pt-0">Status</label>
                                                            <select class="form-control" [(ngModel)]="moveCatStatus"
                                                                formControlName="statusList"
                                                                (change)="statusChangeToMove()">
                                                                <option value="">Select Status</option>
                                                                <option *ngFor="let status of leadStatusByCat"
                                                                    [value]="status.stageName">
                                                                    {{status.stageName}}
                                                                </option>
                                                            </select>
                                                            <div class="assignWarn" *ngIf="assignToSuperAdmin">Super
                                                                admin will receive this lead.</div>
                                                        </div>


                                                        <div class="form-group" *ngIf="enableUserList">
                                                            <label class="control-label pt-0">User List</label>
                                                            <select class="form-control" [(ngModel)]="moveCatTelecaller"
                                                                formControlName="userList">
                                                                <option value="">Select user</option>
                                                                <option *ngFor="let user of categoryUserist"
                                                                    [value]="user.id">
                                                                    {{user.userName}}
                                                                </option>
                                                            </select>
                                                        </div>

                                                        <ng-container *ngIf="enableDateTimeControl">
                                                            <div class="form-group">
                                                                <label class="control-label pt-0">Date</label>
                                                                <input type="text" placement="top right"
                                                                    formControlName="moveDate" readonly
                                                                    class="form-control date-icon modal-space"
                                                                    [minDate]="minDate" bsDatepicker autocomplete="off"
                                                                    [bsConfig]="{showWeekNumbers: false,dateInputFormat: 'DD/MM/YYYY',containerClass:'dateLeft theme-green'}">
                                                            </div>
                                                            <div class="form-group">
                                                                <label class=" control-label required">Time</label>
                                                                <div class="timepicker-position">
                                                                    <timepicker formControlName="moveTime"
                                                                        minuteStep="16"></timepicker>
                                                                </div>
                                                            </div>
                                                        </ng-container>


                                                        <div class="text-right">
                                                            <button class="btn btn btn-dark" type="submit"
                                                                (click)="saveLeadMoveByCat()">Submit</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>


                                    <div *ngIf="shareList">
                                        <div class="whatsAppConfigPopup">
                                            <div class="forgot-password-modal userSelect">
                                                <div class="modal-header">
                                                    <h4 class="modal-title pull-left">Select user</h4>
                                                    <button type="button" class="close pull-right"
                                                        (click)="shareList = false">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>



                                                <div class="modal-body">
                                                    <div class="form-group">
                                                        <label class="control-label pt-0">User List</label>
                                                        <ng-select [multiple]="true"
                                                            class="form-control autoHeightSelectUser"
                                                            (change)="userSelectForShare($event)"
                                                            [(ngModel)]="userListToShare">
                                                            <ng-option *ngFor="let user of sharedUserList"
                                                                [value]="user.id">
                                                                {{user.userProfileName}}
                                                            </ng-option>
                                                        </ng-select>
                                                    </div>
                                                    <div class="text-right">
                                                        <button class="btn btn btn-dark" type="submit"
                                                            (click)="userSubmitForShareLead()">Submit</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                                <div class="closePopupBtn">
                                    <button *ngIf="clickRedirectAud == false" type="button" class="close pull-right"
                                        aria-label="Close" (click)="hideModal()">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    <button *ngIf="clickRedirectAud == true" type="button" class="close pull-right"
                                        aria-label="Close" (click)="goToViewAud()">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="profileDetails m_t_20">
                            <div class="configuration project-integration-tab">
                                <tabset class="tab-container" #mainDetailsTabs>
                                    <div class="tab-content">
                                        <tab heading="" class="active tab-pane">
                                            <ng-template tabHeading>
                                                <span>Lead Details</span>
                                            </ng-template>
                                            <div class="">
                                                <div class="container-fluid p-l-0 p-r-0">
                                                    <div id="" class="tabDetailBox active">
                                                        <div class="bottom_profileSubMenu">
                                                            <div class="configuration project-integration-tab">
                                                                <tabset class="tab-container" #detailsTabs>
                                                                    <div class="tab-content">
                                                                        <tab heading="" #leadDetailBox>
                                                                            <ng-template tabHeading>
                                                                                <span>Lead Details</span>
                                                                            </ng-template>
                                                                            <div class="leadDetailInner active"
                                                                                id="leadDetailChild">

                                                                                <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12"
                                                                                    *ngIf="screenWidth != 1">

                                                                                    <div class="table-reports">
                                                                                        <div
                                                                                            class="table-body form-row">
                                                                                            <div class="table-responsive table-height-scroll no-max-height col-md-6 col-sm-12 col-xs-12 col-lg-6"
                                                                                                #scrollblockLeft>
                                                                                                <table
                                                                                                    class="table table-bordered table-striped">
                                                                                                    <tbody>
                                                                                                        <tr>
                                                                                                            <th>Parameter
                                                                                                            </th>
                                                                                                            <th>Value
                                                                                                            </th>
                                                                                                        </tr>
                                                                                                        <ng-container
                                                                                                            *ngIf="audience">
                                                                                                            <tr
                                                                                                                *ngIf="audience.repeatColumnHash">
                                                                                                                <td>Repeat
                                                                                                                    Hash
                                                                                                                </td>
                                                                                                                <td>{{audience.repeatColumnHash}}
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </ng-container>
                                                                                                        <ng-container
                                                                                                            *ngFor="let item of audience.audienceDynamicDataList; let i = index">
                                                                                                            <ng-container
                                                                                                                *ngIf="item.invoice_quotation_hide != true">
                                                                                                                <tr
                                                                                                                    *ngIf="i <= audience.firstHalf">
                                                                                                                    <td>{{
                                                                                                                        item.paramName
                                                                                                                        }}
                                                                                                                    </td>
                                                                                                                    <td>
                                                                                                                        <ng-container
                                                                                                                            *ngIf="item.paramValueChange === 'ACTIVE'; else otherParam">
                                                                                                                            <div class="paremvalue"
                                                                                                                                *ngIf="!item.editParam">
                                                                                                                                <span
                                                                                                                                    *ngIf="item.paramDatatype == 'File' && !item.fileLink"
                                                                                                                                    class="value"
                                                                                                                                    [innerHtml]="item.paramValue"></span>
                                                                                                                                <span
                                                                                                                                    *ngIf="item.paramDatatype != 'File' && !item.fileLink"
                                                                                                                                    class="value">{{item.paramValue}}</span>
                                                                                                                                <!-- <a *ngIf="item.fileLink == true" href="{{item.paramValue}}" target="_blank"> Click to view </a> -->
                                                                                                                                <a *ngIf="item.fileLink == true"
                                                                                                                                    href="javascript:void(0)"
                                                                                                                                    (click)="downloadFileBylink(item)">
                                                                                                                                    Click
                                                                                                                                    to
                                                                                                                                    view
                                                                                                                                </a>

                                                                                                                                <span
                                                                                                                                    (click)="item.editParam = true; getparemType(item,i)"
                                                                                                                                    class="icon">
                                                                                                                                    <ng-container
                                                                                                                                        *ngIf="item.fileTypeAttrLink != 'file'">
                                                                                                                                        <img src="assets/images/pencil.svg"
                                                                                                                                            width="12" />
                                                                                                                                    </ng-container>
                                                                                                                                    <ng-container
                                                                                                                                        *ngIf="item.fileTypeAttrLink == 'file'">
                                                                                                                                        Change
                                                                                                                                        File
                                                                                                                                    </ng-container>
                                                                                                                                </span>
                                                                                                                            </div>
                                                                                                                            <div *ngIf="item.editParam"
                                                                                                                                class="input-group">
                                                                                                                                {{paremType}}
                                                                                                                                <ng-container
                                                                                                                                    *ngIf="item.paramDefaultValueDTO == undefined">
                                                                                                                                    <textarea
                                                                                                                                        trim="blur"
                                                                                                                                        *ngIf="item.paramDatatype == 'String' || item.paramDatatype == 'string'"
                                                                                                                                        class="form-control"
                                                                                                                                        [ngClass]="{'invalid-control' : isSubmit && item.paramMandatory == 'ACTIVE' && item.paramValue == ''}"
                                                                                                                                        [(ngModel)]="item.paramValue"></textarea>
                                                                                                                                    <textarea
                                                                                                                                        trim="blur"
                                                                                                                                        *ngIf="item.paramDatatype == 'Numeric' || item.paramDatatype == 'numeric'"
                                                                                                                                        onkeypress='return (event.charCode >= 48 && event.charCode <= 57) || (event.charCode == 46)'
                                                                                                                                        class="form-control"
                                                                                                                                        [ngClass]="{'invalid-control' : isSubmit && item.paramMandatory == 'ACTIVE' && item.paramValue == ''}"
                                                                                                                                        [(ngModel)]="item.paramValue"></textarea>

                                                                                                                                    <input
                                                                                                                                        trim="blur"
                                                                                                                                        *ngIf="item.paramDatatype == 'Email'"
                                                                                                                                        type="text"
                                                                                                                                        class="form-control"
                                                                                                                                        [ngClass]="{'invalid-control' : isSubmit && item.paramMandatory == 'ACTIVE' && item.paramValue == ''}"
                                                                                                                                        [(ngModel)]="item.paramValue">
                                                                                                                                    <input
                                                                                                                                        trim="blur"
                                                                                                                                        *ngIf="item.paramDatatype == 'Mobile'"
                                                                                                                                        type="text"
                                                                                                                                        (keypress)="numSymbol($event)"
                                                                                                                                        class="form-control"
                                                                                                                                        [ngClass]="{'invalid-control' : isSubmit && item.paramMandatory == 'ACTIVE' && item.paramValue == ''}"
                                                                                                                                        [(ngModel)]="item.paramValue"
                                                                                                                                        maxlength="15">

                                                                                                                                    <input
                                                                                                                                        *ngIf="item.paramDatatype == 'Date'"
                                                                                                                                        type="text"
                                                                                                                                        placement="top left"
                                                                                                                                        [(ngModel)]="item.paramValue"
                                                                                                                                        [ngClass]="{'invalid-control' : isSubmit && item.paramMandatory == 'ACTIVE' && item.paramValue == ''}"
                                                                                                                                        class="form-control date-icon modal-space"
                                                                                                                                        bsDatepicker
                                                                                                                                        autocomplete="off"
                                                                                                                                        [bsConfig]="global.datePickerConfig"
                                                                                                                                        [minDate]="item.backDataEditable == 'INACTIVE' ? todayDate : undefined">
                                                                                                                                    <ng-container
                                                                                                                                        *ngIf="item.paramDatatype == 'File'">

                                                                                                                                        <div class="relatedOuterNgmodel"
                                                                                                                                            [ngClass]="{'invalid-control' : isSubmit && item.paramMandatory == 'ACTIVE' && item.paramValue == ''}">
                                                                                                                                            <input
                                                                                                                                                type="button"
                                                                                                                                                class="fileTypeButton form-control"
                                                                                                                                                [value]="item.fileNameOfFile?item.fileNameOfFile:'Choose File'"
                                                                                                                                                [ngClass]="{'invalid-control' : isSubmit && item.paramMandatory == 'ACTIVE' && item.paramValue == ''}">
                                                                                                                                            <input
                                                                                                                                                (change)="custmAttrFIleUpload($event,item)"
                                                                                                                                                [title]="item.fileNameOfFile?item.fileNameOfFile:'Choose file'"
                                                                                                                                                type="file"
                                                                                                                                                class="form-control absoluteTypeFileBtn"
                                                                                                                                                [ngClass]="{'invalid-control' : isSubmit && item.paramMandatory == 'ACTIVE' && item.paramValue == ''}">
                                                                                                                                            <span
                                                                                                                                                class="emptyValIcon"
                                                                                                                                                (click)="emptyFileVal(item)"
                                                                                                                                                *ngIf="item.fileNameOfFile != undefined">
                                                                                                                                                <img
                                                                                                                                                    src="assets/images/cross.png">
                                                                                                                                            </span>
                                                                                                                                        </div>

                                                                                                                                        <input
                                                                                                                                            type="hidden"
                                                                                                                                            [(ngModel)]="item.paramValue">
                                                                                                                                    </ng-container>
                                                                                                                                </ng-container>
                                                                                                                                <ng-container
                                                                                                                                    *ngIf="item.paramDefaultValueDTO != undefined">

                                                                                                                                    <ng-container
                                                                                                                                        *ngIf="item.paramDatatype != 'Date' && item.paramDatatype != 'Numeric' && item.paramKey != 'tag'">
                                                                                                                                        <ng-select
                                                                                                                                            [items]="item.paramDefaultValueDTO"
                                                                                                                                            [(ngModel)]="item.paramValue"
                                                                                                                                            class="form-control additional_detail_multi_select auto_height_select"
                                                                                                                                            bindLabel="paramValuetemp"
                                                                                                                                            bindValue="paramValuetemp"
                                                                                                                                            [multiple]="true"></ng-select>
                                                                                                                                    </ng-container>
                                                                                                                                    <select
                                                                                                                                        *ngIf="item.paramDatatype == 'Numeric' || item.paramKey == 'tag'"
                                                                                                                                        type="text"
                                                                                                                                        class="form-control"
                                                                                                                                        [ngClass]="{'invalid-control' : isSubmit && item.paramMandatory == 'ACTIVE' && item.paramValue == ''}"
                                                                                                                                        [(ngModel)]="item.paramValue">
                                                                                                                                        <option
                                                                                                                                            *ngFor="let list of item.paramDefaultValueDTO"
                                                                                                                                            [selected]="list.paramValue == item.paramValue">
                                                                                                                                            {{list.paramValue}}
                                                                                                                                        </option>
                                                                                                                                    </select>

                                                                                                                                </ng-container>

                                                                                                                                <div
                                                                                                                                    class="input-group-append">
                                                                                                                                    <button
                                                                                                                                        class="input-group-text"
                                                                                                                                        (click)="updateAudience(item, i)">save</button>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </ng-container>
                                                                                                                        <ng-template
                                                                                                                            #otherParam>
                                                                                                                            <!-- <span [innerHtml]="item.paramValue"></span> -->

                                                                                                                            <span
                                                                                                                                *ngIf="item.paramDatatype == 'File' && !item.fileLink"
                                                                                                                                class="value"
                                                                                                                                [innerHtml]="item.paramValue"></span>
                                                                                                                            <span
                                                                                                                                *ngIf="item.paramDatatype != 'File' && !item.fileLink"
                                                                                                                                class="value">{{item.paramValue}}</span>
                                                                                                                            <!-- <a *ngIf="item.fileLink == true" href="{{item.paramValue}}" target="_blank"> Click to view </a> -->
                                                                                                                            <a *ngIf="item.fileLink == true"
                                                                                                                                href="javascript:void(0)"
                                                                                                                                (click)="downloadFileBylink(item)">
                                                                                                                                Click
                                                                                                                                to
                                                                                                                                view
                                                                                                                            </a>
                                                                                                                        </ng-template>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                            </ng-container>
                                                                                                        </ng-container>

                                                                                                    </tbody>
                                                                                                </table>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-md-6 col-sm-12 col-xs-12 col-lg-6 scrollBlockTwo">
                                                                                                <div class="table-responsive table-height-scroll no-max-height"
                                                                                                    #scrollblockRight>
                                                                                                    <table
                                                                                                        class="table table-bordered table-striped">
                                                                                                        <tbody>
                                                                                                            <tr
                                                                                                                class="hiddenInPrint">
                                                                                                                <th>Parameter
                                                                                                                </th>
                                                                                                                <th>Value
                                                                                                                </th>
                                                                                                            </tr>
                                                                                                            <ng-container
                                                                                                                *ngFor="let item of audience.audienceDynamicDataList; let i = index">
                                                                                                                <ng-container
                                                                                                                    *ngIf="item.invoice_quotation_hide != true">
                                                                                                                    <tr
                                                                                                                        *ngIf="i >= audience.secondHalf">
                                                                                                                        <td>{{
                                                                                                                            item.paramName
                                                                                                                            }}
                                                                                                                        </td>
                                                                                                                        <td>
                                                                                                                            <ng-container
                                                                                                                                *ngIf="item.paramValueChange === 'ACTIVE'; else otherParam">
                                                                                                                                <div class="paremvalue"
                                                                                                                                    *ngIf="!item.editParam">
                                                                                                                                    <span
                                                                                                                                        *ngIf="item.paramDatatype == 'File' && !item.fileLink"
                                                                                                                                        class="value"
                                                                                                                                        [innerHtml]="item.paramValue"></span>
                                                                                                                                    <span
                                                                                                                                        *ngIf="item.paramDatatype != 'File' && !item.fileLink"
                                                                                                                                        class="value">{{item.paramValue}}</span>
                                                                                                                                    <a *ngIf="item.fileLink"
                                                                                                                                        href="javascript:void(0)"
                                                                                                                                        (click)="downloadFileBylink(item)">
                                                                                                                                        Click
                                                                                                                                        to
                                                                                                                                        view
                                                                                                                                    </a>
                                                                                                                                    <span
                                                                                                                                        (click)="item.editParam = true; getparemType(item,i)"
                                                                                                                                        class="icon">
                                                                                                                                        <ng-container
                                                                                                                                            *ngIf="item.fileTypeAttrLink != 'file'">
                                                                                                                                            <img src="assets/images/pencil.svg"
                                                                                                                                                width="12" />
                                                                                                                                        </ng-container>
                                                                                                                                        <ng-container
                                                                                                                                            *ngIf="item.fileTypeAttrLink == 'file'">
                                                                                                                                            Change
                                                                                                                                            File
                                                                                                                                        </ng-container>
                                                                                                                                    </span>
                                                                                                                                </div>
                                                                                                                                <div *ngIf="item.editParam"
                                                                                                                                    class="input-group">
                                                                                                                                    {{paremType}}
                                                                                                                                    <ng-container
                                                                                                                                        *ngIf="item.paramDefaultValueDTO == undefined">
                                                                                                                                        <!-- <textarea trim="blur" *ngIf="item.paramDatatype != 'Date' && item.paramDatatype != 'File'"  class="form-control" [ngClass]="{'invalid-control' : isSubmit && item.paramMandatory == 'ACTIVE' && item.paramValue == ''}" [(ngModel)]="item.paramValue"></textarea>                                                                                                                             -->

                                                                                                                                        <!-- <textarea trim="blur" *ngIf="item.paramDatatype != 'Date' && item.paramDatatype != 'File' && item.paramDatatype != 'Email' && item.paramDatatype != 'Mobile'"  class="form-control" [ngClass]="{'invalid-control' : isSubmit && item.paramMandatory == 'ACTIVE' && item.paramValue == ''}" [(ngModel)]="item.paramValue"></textarea>       -->
                                                                                                                                        <textarea
                                                                                                                                            trim="blur"
                                                                                                                                            *ngIf="item.paramDatatype == 'String' || item.paramDatatype == 'string'"
                                                                                                                                            class="form-control"
                                                                                                                                            [ngClass]="{'invalid-control' : isSubmit && item.paramMandatory == 'ACTIVE' && item.paramValue == ''}"
                                                                                                                                            [(ngModel)]="item.paramValue"></textarea>
                                                                                                                                        <textarea
                                                                                                                                            trim="blur"
                                                                                                                                            *ngIf="item.paramDatatype == 'Numeric' || item.paramDatatype == 'numeric'"
                                                                                                                                            onkeypress='return (event.charCode >= 48 && event.charCode <= 57) || (event.charCode == 46)'
                                                                                                                                            class="form-control"
                                                                                                                                            [ngClass]="{'invalid-control' : isSubmit && item.paramMandatory == 'ACTIVE' && item.paramValue == ''}"
                                                                                                                                            [(ngModel)]="item.paramValue"></textarea>

                                                                                                                                        <input
                                                                                                                                            trim="blur"
                                                                                                                                            *ngIf="item.paramDatatype == 'Email'"
                                                                                                                                            type="text"
                                                                                                                                            class="form-control"
                                                                                                                                            [ngClass]="{'invalid-control' : isSubmit && item.paramMandatory == 'ACTIVE' && item.paramValue == ''}"
                                                                                                                                            [(ngModel)]="item.paramValue">
                                                                                                                                        <input
                                                                                                                                            trim="blur"
                                                                                                                                            *ngIf="item.paramDatatype == 'Mobile'"
                                                                                                                                            type="text"
                                                                                                                                            (keypress)="numSymbol($event)"
                                                                                                                                            class="form-control"
                                                                                                                                            [ngClass]="{'invalid-control' : isSubmit && item.paramMandatory == 'ACTIVE' && item.paramValue == ''}"
                                                                                                                                            [(ngModel)]="item.paramValue"
                                                                                                                                            maxlength="15">


                                                                                                                                        <input
                                                                                                                                            *ngIf="item.paramDatatype == 'Date'"
                                                                                                                                            type="text"
                                                                                                                                            placement="top left"
                                                                                                                                            [(ngModel)]="item.paramValue"
                                                                                                                                            [ngClass]="{'invalid-control' : isSubmit && item.paramMandatory == 'ACTIVE' && item.paramValue == ''}"
                                                                                                                                            class="form-control date-icon modal-space"
                                                                                                                                            bsDatepicker
                                                                                                                                            autocomplete="off"
                                                                                                                                            [bsConfig]="global.datePickerConfig"
                                                                                                                                            [minDate]="item.backDataEditable == 'INACTIVE' ? todayDate : undefined">
                                                                                                                                        <ng-container
                                                                                                                                            *ngIf="item.paramDatatype == 'File'">
                                                                                                                                            <div class="relatedOuterNgmodel"
                                                                                                                                                [ngClass]="{'invalid-control' : isSubmit && item.paramMandatory == 'ACTIVE' && item.paramValue == ''}">
                                                                                                                                                <input
                                                                                                                                                    type="button"
                                                                                                                                                    class="fileTypeButton form-control"
                                                                                                                                                    [value]="item.fileNameOfFile?item.fileNameOfFile:'Choose File'"
                                                                                                                                                    [ngClass]="{'invalid-control' : invalidFile}"
                                                                                                                                                    [ngClass]="{'invalid-control' : isSubmit && item.paramMandatory == 'ACTIVE' && item.paramValue == ''}">
                                                                                                                                                <input
                                                                                                                                                    (change)="custmAttrFIleUpload($event,item)"
                                                                                                                                                    [title]="item.fileNameOfFile?item.fileNameOfFile:'Choose file'"
                                                                                                                                                    type="file"
                                                                                                                                                    class="form-control absoluteTypeFileBtn"
                                                                                                                                                    [ngClass]="{'invalid-control' : isSubmit && item.paramMandatory == 'ACTIVE' && item.paramValue == ''}">
                                                                                                                                                <span
                                                                                                                                                    class="emptyValIcon"
                                                                                                                                                    (click)="emptyFileVal(item)"
                                                                                                                                                    *ngIf="item.fileNameOfFile != undefined">
                                                                                                                                                    <img
                                                                                                                                                        src="assets/images/cross.png">
                                                                                                                                                </span>
                                                                                                                                            </div>

                                                                                                                                            <input
                                                                                                                                                type="hidden"
                                                                                                                                                [(ngModel)]="item.paramValue">
                                                                                                                                        </ng-container>
                                                                                                                                    </ng-container>
                                                                                                                                    <ng-container
                                                                                                                                        *ngIf="item.paramDefaultValueDTO != undefined">

                                                                                                                                        <ng-container
                                                                                                                                            *ngIf="item.paramDatatype != 'Date' && item.paramDatatype != 'Numeric' && item.paramKey != 'tag'">
                                                                                                                                            <ng-select
                                                                                                                                                [items]="item.paramDefaultValueDTO"
                                                                                                                                                [(ngModel)]="item.paramValue"
                                                                                                                                                class="form-control additional_detail_multi_select auto_height_select"
                                                                                                                                                bindLabel="paramValuetemp"
                                                                                                                                                bindValue="paramValuetemp"
                                                                                                                                                [multiple]="true"></ng-select>
                                                                                                                                        </ng-container>

                                                                                                                                        <!-- <select *ngIf="item.paramDatatype != 'Date'" type="text" class="form-control" [ngClass]="{'invalid-control' : isSubmit && item.paramMandatory == 'ACTIVE' && item.paramValue == ''}" [(ngModel)]="item.paramValue">
                                                                                                                                            <option *ngFor="let list of item.paramDefaultValueDTO">
                                                                                                                                                {{list.paramValue}}
                                                                                                                                            </option>
                                                                                                                                        </select> -->
                                                                                                                                        <!-- 
                                                                                                                                        <ng-select *ngIf="item.paramDatatype != 'Date' && item.paramDatatype != 'Numeric' && item.paramKey != 'tag'" [multiple]="true" class="form-control additional_detail_multi_select auto_height_select" [(ngModel)]="item.paramValue">
                                                                                                                                            <ng-option *ngFor="let options of item.paramDefaultValueDTO" [value]="options.paramValue">
                                                                                                                                                {{options.paramValue}}
                                                                                                                                            </ng-option>
                                                                                                                                        </ng-select> -->

                                                                                                                                        <select
                                                                                                                                            *ngIf="item.paramDatatype == 'Numeric' || item.paramKey == 'tag'"
                                                                                                                                            type="text"
                                                                                                                                            class="form-control"
                                                                                                                                            [ngClass]="{'invalid-control' : isSubmit && item.paramMandatory == 'ACTIVE' && item.paramValue == ''}"
                                                                                                                                            [(ngModel)]="item.paramValue">
                                                                                                                                            <option
                                                                                                                                                *ngFor="let list of item.paramDefaultValueDTO"
                                                                                                                                                [selected]="list.paramValue == item.paramValue">
                                                                                                                                                {{list.paramValue}}
                                                                                                                                            </option>
                                                                                                                                        </select>

                                                                                                                                    </ng-container>
                                                                                                                                    <div
                                                                                                                                        class="input-group-append">
                                                                                                                                        <button
                                                                                                                                            class="input-group-text"
                                                                                                                                            (click)="updateAudience(item, i)">save</button>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </ng-container>
                                                                                                                            <ng-template
                                                                                                                                #otherParam>
                                                                                                                                <!-- <span [innerHtml]="item.paramValue"></span> -->
                                                                                                                                <span
                                                                                                                                    *ngIf="item.paramDatatype == 'File' && !item.fileLink"
                                                                                                                                    class="value"
                                                                                                                                    [innerHtml]="item.paramValue"></span>
                                                                                                                                <span
                                                                                                                                    *ngIf="item.paramDatatype != 'File' && !item.fileLink"
                                                                                                                                    class="value">{{item.paramValue}}</span>
                                                                                                                                <!-- <a *ngIf="item.fileLink == true" href="{{item.paramValue}}" target="_blank"> Click to view </a> -->
                                                                                                                                <a *ngIf="item.fileLink == true"
                                                                                                                                    href="javascript:void(0)"
                                                                                                                                    (click)="downloadFileBylink(item)">
                                                                                                                                    Click
                                                                                                                                    to
                                                                                                                                    view
                                                                                                                                </a>
                                                                                                                            </ng-template>
                                                                                                                        </td>
                                                                                                                    </tr>
                                                                                                                </ng-container>
                                                                                                            </ng-container>
                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </div>
                                                                                                <!-- <div class="other-parem" *ngIf="otherParameters.length > 0">
                                                <span (click)="isOtherParametersShow = !isOtherParametersShow;" > Add Other Parameters <img class="img" src="assets/images/caret-down-dark.svg"></span>
                                                <ul *ngIf="isOtherParametersShow">
                                                    <li *ngFor="let item of otherParameters; let i = index" (click)="appendParameter(item,i)">{{item.paramName}}</li>
                                                </ul>
                                            </div> -->
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>

                                                                                <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12"
                                                                                    *ngIf="screenWidth == 1">

                                                                                    <div class="table-reports">
                                                                                        <div
                                                                                            class="table-body form-row">
                                                                                            <div class="table-responsive table-height-scroll col-md-6 col-sm-12 col-xs-12 col-lg-6"
                                                                                                #scrollblockLeft>
                                                                                                <table
                                                                                                    class="table table-bordered table-striped">
                                                                                                    <tbody>
                                                                                                        <tr>
                                                                                                            <th>Parameter
                                                                                                            </th>
                                                                                                            <th>Value
                                                                                                            </th>
                                                                                                        </tr>
                                                                                                        <ng-container
                                                                                                            *ngIf="audience">
                                                                                                            <tr
                                                                                                                *ngIf="audience.repeatColumnHash">
                                                                                                                <td>Repeat
                                                                                                                    Hash
                                                                                                                </td>
                                                                                                                <td>{{audience.repeatColumnHash}}
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </ng-container>
                                                                                                        <ng-container
                                                                                                            *ngFor="let item of audience.audienceDynamicDataList; let i = index">
                                                                                                            <ng-container
                                                                                                                *ngIf="item.invoice_quotation_hide != true">
                                                                                                                <tr>
                                                                                                                    <td>{{
                                                                                                                        item.paramName
                                                                                                                        }}
                                                                                                                    </td>
                                                                                                                    <td>
                                                                                                                        <ng-container
                                                                                                                            *ngIf="item.paramValueChange === 'ACTIVE'; else otherParam">
                                                                                                                            <div class="paremvalue"
                                                                                                                                *ngIf="!item.editParam">
                                                                                                                                <span
                                                                                                                                    *ngIf="item.paramDatatype == 'File' && !item.fileLink"
                                                                                                                                    class="value"
                                                                                                                                    [innerHtml]="item.paramValue"></span>
                                                                                                                                <span
                                                                                                                                    *ngIf="item.paramDatatype != 'File' && !item.fileLink"
                                                                                                                                    class="value">{{item.paramValue}}</span>
                                                                                                                                <a *ngIf="item.fileLink"
                                                                                                                                    href="javascript:void(0)"
                                                                                                                                    (click)="downloadFileBylink(item)">
                                                                                                                                    Click
                                                                                                                                    to
                                                                                                                                    view
                                                                                                                                </a>
                                                                                                                                <span
                                                                                                                                    (click)="item.editParam = true; getparemType(item,i)"
                                                                                                                                    class="icon">
                                                                                                                                    <ng-container
                                                                                                                                        *ngIf="item.fileTypeAttrLink != 'file'">
                                                                                                                                        <img src="assets/images/pencil.svg"
                                                                                                                                            width="12" />
                                                                                                                                    </ng-container>
                                                                                                                                    <ng-container
                                                                                                                                        *ngIf="item.fileTypeAttrLink == 'file'">
                                                                                                                                        Change
                                                                                                                                        File
                                                                                                                                    </ng-container>
                                                                                                                                </span>
                                                                                                                            </div>
                                                                                                                            <div *ngIf="item.editParam"
                                                                                                                                class="input-group">
                                                                                                                                {{paremType}}
                                                                                                                                <ng-container
                                                                                                                                    *ngIf="item.paramDefaultValueDTO == undefined">
                                                                                                                                    <!-- <textarea trim="blur" *ngIf="item.paramDatatype != 'Date' && item.paramDatatype != 'File'"  class="form-control" [ngClass]="{'invalid-control' : isSubmit && item.paramMandatory == 'ACTIVE' && item.paramValue == ''}" [(ngModel)]="item.paramValue"></textarea>                                                                                                                             -->

                                                                                                                                    <!-- <textarea trim="blur" *ngIf="item.paramDatatype != 'Date' && item.paramDatatype != 'File' && item.paramDatatype != 'Email' && item.paramDatatype != 'Mobile'"  class="form-control" [ngClass]="{'invalid-control' : isSubmit && item.paramMandatory == 'ACTIVE' && item.paramValue == ''}" [(ngModel)]="item.paramValue"></textarea>       -->
                                                                                                                                    <textarea
                                                                                                                                        trim="blur"
                                                                                                                                        *ngIf="item.paramDatatype == 'String' || item.paramDatatype == 'string'"
                                                                                                                                        class="form-control"
                                                                                                                                        [ngClass]="{'invalid-control' : isSubmit && item.paramMandatory == 'ACTIVE' && item.paramValue == ''}"
                                                                                                                                        [(ngModel)]="item.paramValue"></textarea>
                                                                                                                                    <textarea
                                                                                                                                        trim="blur"
                                                                                                                                        *ngIf="item.paramDatatype == 'Numeric' || item.paramDatatype == 'numeric'"
                                                                                                                                        onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                                                                                                                        class="form-control"
                                                                                                                                        [ngClass]="{'invalid-control' : isSubmit && item.paramMandatory == 'ACTIVE' && item.paramValue == ''}"
                                                                                                                                        [(ngModel)]="item.paramValue"></textarea>

                                                                                                                                    <input
                                                                                                                                        trim="blur"
                                                                                                                                        *ngIf="item.paramDatatype == 'Email'"
                                                                                                                                        type="text"
                                                                                                                                        class="form-control"
                                                                                                                                        [ngClass]="{'invalid-control' : isSubmit && item.paramMandatory == 'ACTIVE' && item.paramValue == ''}"
                                                                                                                                        [(ngModel)]="item.paramValue">
                                                                                                                                    <input
                                                                                                                                        trim="blur"
                                                                                                                                        *ngIf="item.paramDatatype == 'Mobile'"
                                                                                                                                        type="text"
                                                                                                                                        (keypress)="numSymbol($event)"
                                                                                                                                        class="form-control"
                                                                                                                                        [ngClass]="{'invalid-control' : isSubmit && item.paramMandatory == 'ACTIVE' && item.paramValue == ''}"
                                                                                                                                        [(ngModel)]="item.paramValue"
                                                                                                                                        maxlength="15">


                                                                                                                                    <input
                                                                                                                                        *ngIf="item.paramDatatype == 'Date'"
                                                                                                                                        type="text"
                                                                                                                                        placement="top left"
                                                                                                                                        [(ngModel)]="item.paramValue"
                                                                                                                                        [ngClass]="{'invalid-control' : isSubmit && item.paramMandatory == 'ACTIVE' && item.paramValue == ''}"
                                                                                                                                        class="form-control date-icon modal-space"
                                                                                                                                        bsDatepicker
                                                                                                                                        autocomplete="off"
                                                                                                                                        [bsConfig]="global.datePickerConfig"
                                                                                                                                        [minDate]="item.backDataEditable == 'INACTIVE' ? todayDate : undefined">
                                                                                                                                    <ng-container
                                                                                                                                        *ngIf="item.paramDatatype == 'File'">

                                                                                                                                        <div class="relatedOuterNgmodel"
                                                                                                                                            [ngClass]="{'invalid-control' : isSubmit && item.paramMandatory == 'ACTIVE' && item.paramValue == ''}">
                                                                                                                                            <input
                                                                                                                                                type="button"
                                                                                                                                                class="fileTypeButton form-control"
                                                                                                                                                [value]="item.fileNameOfFile?item.fileNameOfFile:'Choose File'"
                                                                                                                                                [ngClass]="{'invalid-control' : isSubmit && item.paramMandatory == 'ACTIVE' && item.paramValue == ''}">
                                                                                                                                            <input
                                                                                                                                                (change)="custmAttrFIleUpload($event,item)"
                                                                                                                                                [title]="item.fileNameOfFile?item.fileNameOfFile:'Choose file'"
                                                                                                                                                type="file"
                                                                                                                                                class="form-control absoluteTypeFileBtn"
                                                                                                                                                [ngClass]="{'invalid-control' : isSubmit && item.paramMandatory == 'ACTIVE' && item.paramValue == ''}">
                                                                                                                                            <span
                                                                                                                                                class="emptyValIcon"
                                                                                                                                                (click)="emptyFileVal(item)"
                                                                                                                                                *ngIf="item.fileNameOfFile != undefined">
                                                                                                                                                <img
                                                                                                                                                    src="assets/images/cross.png">
                                                                                                                                            </span>
                                                                                                                                        </div>

                                                                                                                                        <input
                                                                                                                                            type="hidden"
                                                                                                                                            [(ngModel)]="item.paramValue">
                                                                                                                                    </ng-container>
                                                                                                                                </ng-container>
                                                                                                                                <ng-container
                                                                                                                                    *ngIf="item.paramDefaultValueDTO != undefined">

                                                                                                                                    <ng-container
                                                                                                                                        *ngIf="item.paramDatatype != 'Date' && item.paramDatatype != 'Numeric' && item.paramKey != 'tag'">
                                                                                                                                        <ng-select
                                                                                                                                            [items]="item.paramDefaultValueDTO"
                                                                                                                                            [(ngModel)]="item.paramValue"
                                                                                                                                            class="form-control additional_detail_multi_select auto_height_select"
                                                                                                                                            bindLabel="paramValuetemp"
                                                                                                                                            bindValue="paramValuetemp"
                                                                                                                                            [multiple]="true"></ng-select>
                                                                                                                                    </ng-container>

                                                                                                                                    <!-- <select *ngIf="item.paramDatatype != 'Date'" type="text" class="form-control" [ngClass]="{'invalid-control' : isSubmit && item.paramMandatory == 'ACTIVE' && item.paramValue == ''}" [(ngModel)]="item.paramValue">
                                                                                                                                        <option *ngFor="let list of item.paramDefaultValueDTO">
                                                                                                                                            {{list.paramValue}}
                                                                                                                                        </option>
                                                                                                                                    </select> -->

                                                                                                                                    <!-- <ng-select *ngIf="item.paramDatatype != 'Date' && item.paramDatatype != 'Numeric' && item.paramKey != 'tag'" [multiple]="true" class="form-control additional_detail_multi_select auto_height_select" [(ngModel)]="item.paramValue">
                                                                                                                                        <ng-option *ngFor="let options of item.paramDefaultValueDTO" [value]="options.paramValue">
                                                                                                                                            {{options.paramValue}}
                                                                                                                                        </ng-option>
                                                                                                                                    </ng-select> -->

                                                                                                                                    <select
                                                                                                                                        *ngIf="item.paramDatatype == 'Numeric' || item.paramKey == 'tag'"
                                                                                                                                        type="text"
                                                                                                                                        class="form-control"
                                                                                                                                        [ngClass]="{'invalid-control' : isSubmit && item.paramMandatory == 'ACTIVE' && item.paramValue == ''}"
                                                                                                                                        [(ngModel)]="item.paramValue">
                                                                                                                                        <option
                                                                                                                                            *ngFor="let list of item.paramDefaultValueDTO"
                                                                                                                                            [selected]="list.paramValue == item.paramValue">
                                                                                                                                            {{list.paramValue}}
                                                                                                                                        </option>
                                                                                                                                    </select>

                                                                                                                                </ng-container>
                                                                                                                                <div
                                                                                                                                    class="input-group-append">
                                                                                                                                    <button
                                                                                                                                        class="input-group-text"
                                                                                                                                        (click)="updateAudience(item, i)">save</button>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </ng-container>
                                                                                                                        <ng-template
                                                                                                                            #otherParam>
                                                                                                                            <!-- <span [innerHtml]="item.paramValue"></span> -->
                                                                                                                            <span
                                                                                                                                *ngIf="item.paramDatatype == 'File' && !item.fileLink"
                                                                                                                                class="value"
                                                                                                                                [innerHtml]="item.paramValue"></span>
                                                                                                                            <span
                                                                                                                                *ngIf="item.paramDatatype != 'File' && !item.fileLink"
                                                                                                                                class="value">{{item.paramValue}}</span>
                                                                                                                            <!-- <a *ngIf="item.fileLink == true" href="{{item.paramValue}}" target="_blank"> Click to view </a> -->
                                                                                                                            <a *ngIf="item.fileLink == true"
                                                                                                                                href="javascript:void(0)"
                                                                                                                                (click)="downloadFileBylink(item)">
                                                                                                                                Click
                                                                                                                                to
                                                                                                                                view
                                                                                                                            </a>
                                                                                                                        </ng-template>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                            </ng-container>
                                                                                                        </ng-container>



                                                                                                    </tbody>
                                                                                                </table>
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>

                                                                                </div>


                                                                            </div>
                                                                        </tab>

                                                                        <tab heading=""
                                                                            *ngIf="otherParameters.length > 0">
                                                                            <ng-template tabHeading>
                                                                                <span>Additional Details</span>
                                                                            </ng-template>
                                                                            <div class="leadDetailInner"
                                                                                id="additionalDetailChild">

                                                                                <div
                                                                                    class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
                                                                                    <form #additionalDataForm="ngForm"
                                                                                        (ngSubmit)="additionalDtlSubmit(additionalDataForm.value)">
                                                                                        <div class="form-group"
                                                                                            *ngFor="let item of otherParameters; let i = index">
                                                                                            <div class="row pinCodeBox" *ngIf="item.paramKey == 'pincode' || item.paramKey == 'city' || item.paramKey == 'state' || item.paramKey == 'country' || item.paramKey == 'locality'">
                                                                                                <div
                                                                                                    class="col-md-4 col-sm-12 col-xs-12 col-lg-4">
                                                                                                    {{item.paramName}}  {{item.paramKey != 'locality' ? '(Default)':''}}
                                                                                                </div>
                                                                                                <div
                                                                                                class="col-md-8 col-sm-12 col-xs-12 col-lg-8">
                                                                                                <ng-container  *ngIf="item.paramKey == 'pincode'">
                                                                                                    <div class="relatedOuterParent">
                                                                                                        <input
                                                                                                            trim="blur"
                                                                                                            type="text"
                                                                                                            class="form-control"
                                                                                                            [(ngModel)]="item.paramValue"
                                                                                                            name="{{item.paramKey}}">
                                                                                                            <span class="getDetailBtn" (click)="getPinCodeDetails(item.paramValue)">Get Details</span>
                                                                                                    </div>
                                                                                                </ng-container>
                                                                                                <ng-container  *ngIf="item.paramKey == 'city'">
                                                                                                    <div class="">
                                                                                                        <input
                                                                                                            trim="blur"
                                                                                                            type="text"
                                                                                                            class="form-control"
                                                                                                            [(ngModel)]="item.paramValue"
                                                                                                            name="{{item.paramKey}}">
                                                                                                    </div>
                                                                                                </ng-container>
                                                                                                <ng-container  *ngIf="item.paramKey == 'state'">
                                                                                                    <div class="">
                                                                                                        <input
                                                                                                            trim="blur"
                                                                                                            type="text"
                                                                                                            class="form-control"
                                                                                                            [(ngModel)]="item.paramValue"
                                                                                                            name="{{item.paramKey}}">
                                                                                                    </div>
                                                                                                </ng-container>
                                                                                                <ng-container  *ngIf="item.paramKey == 'country'">
                                                                                                    <div class="">
                                                                                                        <input
                                                                                                            trim="blur"
                                                                                                            type="text"
                                                                                                            class="form-control"
                                                                                                            [(ngModel)]="item.paramValue"
                                                                                                            name="{{item.paramKey}}">
                                                                                                    </div>
                                                                                                </ng-container>

                                                                                                <ng-container *ngIf="item.paramKey == 'locality'">
                                                                                                    <ng-container *ngIf="item.showInput">
                                                                                                        <input type="text" class="form-control" [(ngModel)]="item.paramValue" name="{{item.paramKey}}">
                                                                                                    </ng-container>
                                                                                                    <ng-container *ngIf="!item.showInput">
                                                                                                    <select class="form-control"  [(ngModel)]="item.paramValue" name="{{item.paramKey}}">
                                                                                                        <option *ngFor="let values of item.paramDefaultValueDTO" [value]="values.paramValue">
                                                                                                            {{values.paramValue}}
                                                                                                        </option>
                                                                                                    </select>
                                                                                                </ng-container>
                                                                                                </ng-container>
                                                                                                </div>
                                                                                            </div>



                                                                                            <div class="row" *ngIf="item.paramKey != 'pincode' && item.paramKey != 'city' && item.paramKey != 'state' && item.paramKey != 'country'  && item.paramKey != 'locality'">
                                                                                                <div
                                                                                                    class="col-md-4 col-sm-12 col-xs-12 col-lg-4">
                                                                                                    {{item.paramName}}
                                                                                                    <p class="d-inline"
                                                                                                        *ngIf="( item.paramKey=='message' || item.paramKey=='product_name' ||  item.paramKey=='address') ">
                                                                                                        (default)</p>
                                                                                                </div>
                                                                                                <div
                                                                                                    class="col-md-8 col-sm-12 col-xs-12 col-lg-8">

                                                                                                    <ng-container
                                                                                                        *ngIf="item.paramDefaultValueDTO == undefined">
                                                                                                        <input
                                                                                                            trim="blur"
                                                                                                            *ngIf="item.paramDatatype != 'Date' && item.paramDatatype != 'File' && item.paramDatatype != 'Email' && item.paramDatatype != 'Mobile'"
                                                                                                            type="text"
                                                                                                            class="form-control"
                                                                                                            [(ngModel)]="item.paramValue"
                                                                                                            name="{{item.paramKey}}">
                                                                                                        <input
                                                                                                            trim="blur"
                                                                                                            *ngIf="item.paramDatatype == 'Email'"
                                                                                                            type="text"
                                                                                                            class="form-control"
                                                                                                            [(ngModel)]="item.paramValue"
                                                                                                            name="{{item.paramKey}}">
                                                                                                        <input
                                                                                                            trim="blur"
                                                                                                            *ngIf="item.paramDatatype == 'Mobile'"
                                                                                                            type="text"
                                                                                                            (keypress)="numSymbol($event)"
                                                                                                            class="form-control"
                                                                                                            [(ngModel)]="item.paramValue"
                                                                                                            name="{{item.paramKey}}"
                                                                                                            maxlength="15">
                                                                                                        <ng-container
                                                                                                            *ngIf="item.paramDatatype == 'File'">
                                                                                                            <div
                                                                                                                class="relatedOuterNgmodel">
                                                                                                                <input
                                                                                                                    type="button"
                                                                                                                    class="fileTypeButton form-control"
                                                                                                                    [value]="item.fileNameOfFile?item.fileNameOfFile:'Choose File'">
                                                                                                                <input
                                                                                                                    (change)="custmAttrFIleUpload($event,item)"
                                                                                                                    [title]="item.fileNameOfFile?item.fileNameOfFile:'Choose file'"
                                                                                                                    type="file"
                                                                                                                    class="form-control absoluteTypeFileBtn"
                                                                                                                    [style.opacity]="item.fileNameOfFile?0:1">
                                                                                                                <span
                                                                                                                    class="emptyValIcon"
                                                                                                                    (click)="emptyFileVal(item)"
                                                                                                                    *ngIf="item.fileNameOfFile != undefined && item.fileNameOfFile != ''">
                                                                                                                    <img
                                                                                                                        src="assets/images/cross.png">
                                                                                                                </span>
                                                                                                            </div>
                                                                                                            <input
                                                                                                                type="hidden"
                                                                                                                [(ngModel)]="item.paramValue"
                                                                                                                name="{{item.paramKey}}">
                                                                                                        </ng-container>
                                                                                                    </ng-container>
                                                                                                    <ng-container
                                                                                                        *ngIf="item.paramDefaultValueDTO != undefined">


                                                                                                        <ng-container
                                                                                                            *ngIf="item.paramDatatype != 'Date' && item.paramDatatype != 'Numeric'">
                                                                                                            <ng-select
                                                                                                                [items]="item.paramDefaultValueDTO"
                                                                                                                [(ngModel)]="item.paramValue"
                                                                                                                class="form-control additional_detail_multi_select auto_height_select"
                                                                                                                bindLabel="paramValuetemp"
                                                                                                                bindValue="paramValuetemp"
                                                                                                                [multiple]="true"
                                                                                                                name="{{item.paramKey}}"></ng-select>
                                                                                                        </ng-container>


                                                                                                        <!-- <ng-select *ngIf="item.paramDatatype != 'Numeric' && item.paramDatatype != 'Date'" [multiple]="true" class="form-control additional_detail_multi_select auto_height_select" [(ngModel)]="item.paramValue" name="{{item.paramKey}}">
                                                                                                        <ng-option *ngFor="let options of item.paramDefaultValueDTO" [value]="options.paramValue">
                                                                                                            {{options.paramValue}}
                                                                                                        </ng-option>
                                                                                                    </ng-select> -->

                                                                                                        <select
                                                                                                            *ngIf="item.paramDatatype == 'Numeric'"
                                                                                                            type="text"
                                                                                                            class="form-control"
                                                                                                            [(ngModel)]="item.paramValue"
                                                                                                            name="{{item.paramKey}}">
                                                                                                            <option
                                                                                                                *ngFor="let list of item.paramDefaultValueDTO">
                                                                                                                {{list.paramValue}}
                                                                                                            </option>
                                                                                                        </select>


                                                                                                    </ng-container>
                                                                                                    <input
                                                                                                        *ngIf="item.paramDatatype == 'Date'"
                                                                                                        type="text"
                                                                                                        placement="top left"
                                                                                                        [(ngModel)]="item.paramValue"
                                                                                                        name="{{item.paramKey}}"
                                                                                                        class="form-control date-icon modal-space"
                                                                                                        bsDatepicker
                                                                                                        autocomplete="off"
                                                                                                        [bsConfig]="global.datePickerConfig"
                                                                                                        [minDate]="item.backDataEditable == 'INACTIVE' ? todayDate : undefined">
                                                                                                </div>


                                                                                            </div>
                                                                                        </div>


                                                                                        <div class="row">
                                                                                            <div
                                                                                                class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
                                                                                                <div class="text-right">
                                                                                                    <button
                                                                                                        type="submit"
                                                                                                        class="btn btn-dark">Submit</button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </form>
                                                                                </div>
                                                                            </div>
                                                                        </tab>
                                                                    </div>
                                                                </tabset>
                                                            </div>
                                                        </div>



                                                    </div>
                                                </div>
                                            </div>
                                        </tab>

                                        <tab heading="" class="tab-pane">
                                            <ng-template tabHeading>
                                                <span
                                                    (click)="nextId = undefined; timelineData =[]; getTimeline('')">Timeline</span>
                                            </ng-template>
                                            <div class="">
                                                <div class="container-fluid">
                                                    <div id="timelineDetails" class="tabDetailBox">

                                                        <div class="detailsTimelineInner col-md-12 col-lg-12 col-xs-12 col-sm-12"
                                                            *ngIf="timelineData != null ">
                                                            <div class="scroller-max-height">
                                                                <div class="table-responsive">
                                                                    <table class="timelineTable">
                                                                        <!-- <tr *ngFor="let list of timelineData | paginate: { itemsPerPage:dataSize, currentPage:pageNo, totalItems:totalDataSize };"> -->
                                                                        <tr *ngFor="let list of timelineData">
                                                                            <td>
                                                                                <div>{{list.timelineDate}}</div>
                                                                                <div>{{list.timelineTime}}</div>
                                                                            </td>
                                                                            <td class="txt-capitalize">
                                                                                <div
                                                                                    *ngIf="list.telecallerName && list.type == 'REASSIGN'">
                                                                                    {{list.userProfileName}}
                                                                                    <ng-container
                                                                                        *ngIf="list.telecallerName">
                                                                                        reassign to
                                                                                        {{list.telecallerName}}</ng-container>
                                                                                </div>
                                                                                <div *ngIf="list.type == 'LEAD'">
                                                                                    {{list.userProfileName}} updated
                                                                                    Audience {{list.type}} stage to
                                                                                    {{list.status}} <ng-container
                                                                                        *ngIf="list.telecallerName">and
                                                                                        reassign to
                                                                                        {{list.telecallerName}}</ng-container>
                                                                                </div>
                                                                                <div
                                                                                    *ngIf="list.type == 'DATA' && list.status == 'save'">
                                                                                    {{list.userProfileName}} added
                                                                                    audience data.
                                                                                </div>
                                                                                <div
                                                                                    *ngIf="list.type == 'DATA' && list.status == 'update'">
                                                                                    {{list.userProfileName}} modified
                                                                                    audience data.
                                                                                </div>
                                                                                <div
                                                                                    *ngIf="list.type == 'MOVE_CATEGORY'">
                                                                                    {{list.userProfileName}} changed
                                                                                    category from - {{list.object}}.
                                                                                </div>
                                                                                <div
                                                                                    *ngIf="list.type == 'SHARE' && list.shareTo != ''">
                                                                                    {{list.userProfileName}} shared lead
                                                                                    to {{list.shareTo}}.
                                                                                </div>
                                                                                <div
                                                                                    *ngIf="list.type == 'SHARE' && list.shareTo == ''">
                                                                                    {{list.userProfileName}} removed
                                                                                    sharing option from the lead.
                                                                                </div>
                                                                                <div *ngIf="list.type == 'NOTES'">
                                                                                    {{list.userProfileName}} added
                                                                                    following comment - {{list.object}}.
                                                                                </div>
                                                                                <div
                                                                                    *ngIf="list.type == 'EMAIL' || list.type == 'SMS' || list.type=='WHATSAPP'">
                                                                                    {{list.userProfileName}} sent
                                                                                    {{list.type}} via <span
                                                                                        class="templateLink"
                                                                                        (click)="viewTemplateData(list.campaignId,list)">template</span>.
                                                                                    Status : {{list.status}}.
                                                                                </div>
                                                                                <div
                                                                                    *ngIf="list.type == 'ANDROID_PUSH' || list.type == 'WEB_PUSH' || list.type =='IOS_PUSH'">
                                                                                    <span
                                                                                        *ngIf="list.telecallerName">{{list.telecallerName}}</span><span
                                                                                        *ngIf="list.userProfileName">{{list.userProfileName}}</span>
                                                                                    sent push notification via <span
                                                                                        class="templateLink"
                                                                                        (click)="viewTemplateData(list.campaignId,list)">template</span>.
                                                                                    Status : {{list.status}}
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <ng-container *ngIf="timelineData != null ">
                                                            <div class="detailsTimelineInner col-md-12 col-lg-12 col-xs-12 col-sm-12"
                                                                *ngIf="timelineData.length == 0 ">
                                                                <div class="no-records">
                                                                    No Records Found
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                        <div class="text-right mt-2" *ngIf="nextId">
                                                            <button class="btn btn-dark"
                                                                (click)="getNextTimelineData('','loadMore')">Load
                                                                More...</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </tab>

                                        <tab heading="" class="tab-pane">
                                            <ng-template tabHeading>
                                                <span>Follow up & Notes</span>
                                            </ng-template>
                                            <div class="">
                                                <div class="container-fluid">
                                                    <ng-container *ngIf="lead_remarks">
                                                        <div id="followupDetails" class="tabDetailBox">
                                                            <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
                                                                <div class="notesDetailTab"
                                                                    *ngFor="let list of lead_remarks">
                                                                    {{list.created | date: 'dd-MM-yyyy H:mm:ss'}} :-
                                                                    {{list.notes}} ( {{list.user_id}} )
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container>


                                                    <ng-container>
                                                        <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12"
                                                            *ngIf="lead_remarks == undefined">
                                                            <div class="no-records">No Records Found</div>
                                                        </div>
                                                    </ng-container>

                                                </div>
                                            </div>
                                        </tab>

                                        <tab heading="" class="tab-pane">
                                            <ng-template tabHeading>
                                                <span (click)="getProfile()">Document Locker</span>
                                            </ng-template>
                                            <div class="">
                                                <div class="container-fluid">
                                                    <div id="followupDetails" class="tabDetailBox">

                                                        <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12">

                                                            <ng-container *ngIf="documentLocker">
                                                                <div class="documentLockerOuter"
                                                                    *ngIf="documentLocker.length > 0">
                                                                    <div class="docViewerBox">
                                                                        <div class="table-responsive">
                                                                            <table class="table">
                                                                                <tr *ngFor="let list of documentLocker">
                                                                                    <td>

                                                                                        <!-- <ng-container
                                                                                            *ngIf="list.data[0] == 'Invoice Url'">
                                                                                            Invoice
                                                                                        </ng-container>
                                                                                        <ng-container
                                                                                            *ngIf="list.data[0] == 'Quotation Url'">
                                                                                            Quotation
                                                                                        </ng-container>
                                                                                        <ng-container
                                                                                            *ngIf="list.data[0] != 'Quotation Url' && list.data[0] != 'Invoice Url'">
                                                                                            {{list.data[0]}}
                                                                                        </ng-container> -->
                                                                                        <ng-container>
                                                                                            {{list.data[0]}}
                                                                                        </ng-container>

                                                                                    </td>
                                                                                    <td>
                                                                                        <!-- <ng-container
                                                                                            *ngIf="list.data[0] == 'Invoice Url'">
                                                                                            <a href="javascript:void(0)"
                                                                                                (click)="goToInvoice('invoice')">Click
                                                                                                to view</a>
                                                                                        </ng-container>
                                                                                        <ng-container
                                                                                            *ngIf="list.data[0] == 'Quotation Url'">
                                                                                            <a href="javascript:void(0)"
                                                                                                (click)="goToInvoice('quotation')">Click
                                                                                                to view</a>
                                                                                        </ng-container>
                                                                                        <ng-container
                                                                                            *ngIf="list.data[0] != 'Quotation Url' && list.data[0] != 'Invoice Url'">
                                                                                            <a href="javascript:void(0)"
                                                                                                (click)="downloadFileBylink(list,'documentLocker')">Click
                                                                                                to view</a>

                                                                                        </ng-container> -->


                                                                                        <ng-container>
                                                                                            <a href="javascript:void(0)"
                                                                                                (click)="downloadFileBylink(list,'documentLocker')">Click
                                                                                                to view</a>
                                                                                        </ng-container>


                                                                                    </td>
                                                                                </tr>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                            <div *ngIf="documentLocker.length > 20"
                                                                class="table-footer footer-gray">
                                                                <pagination-controls
                                                                    (pageChange)="dataForPaging($event)" maxSize="9"
                                                                    directionLinks="true" autoHide="true"
                                                                    class="pull-right" responsive="true"
                                                                    previousLabel="Previous" nextLabel="Next"
                                                                    screenReaderPaginationLabel="Pagination"
                                                                    screenReaderPageLabel="page"
                                                                    screenReaderCurrentLabel="You're on page">
                                                                </pagination-controls>
                                                                <div class="textblue text-left">
                                                                    <div class="data-length"> Show
                                                                        <select [(ngModel)]="dataSize"
                                                                            (change)="getTimeline()">
                                                                            <option value="20">20</option>
                                                                            <option value="50">50</option>
                                                                            <option value="100">100</option>
                                                                        </select>
                                                                    </div>
                                                                    <span>Total Count: {{totalDataSize}}</span>
                                                                </div>
                                                            </div>

                                                            <div class="no-records" *ngIf="documentLocker.length == 0">
                                                                No Records Found
                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </tab>

                                        <tab heading="" class="tab-pane">
                                            <ng-template tabHeading>
                                                <span (click)="individualAudienceStats('EMAIL')">Communication
                                                    Logs</span>
                                            </ng-template>
                                            <div class="">
                                                <div class="container-fluid">
                                                    <div id="" class="tabDetailBox">
                                                        <div class="detailsTimelineInner">
                                                            <div class="bottom_profileSubMenu">
                                                                <div class="configuration project-integration-tab">
                                                                    <tabset class="tab-container" #communicationTabs>
                                                                        <div class="tab-content">
                                                                            <tab heading="">
                                                                                <ng-template tabHeading>
                                                                                    <span class="desktopViewHeading"
                                                                                        (click)="individualAudienceStats('EMAIL')">Email
                                                                                        Summary</span>
                                                                                    <span class="mobileViewHeading"
                                                                                        (click)="individualAudienceStats('EMAIL')">Email</span>
                                                                                </ng-template>
                                                                                <div class="leadDetailInner active"
                                                                                    id="leadDetailChild">
                                                                                    <div
                                                                                        class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
                                                                                        <ng-container
                                                                                            *ngIf="audStateData">
                                                                                            <div class="innerFlexBox mobileNowrap"
                                                                                                *ngIf="audStateData.length > 0">
                                                                                                <ng-container
                                                                                                    *ngFor="let list of audStateData">
                                                                                                    <div class="boxDevider"
                                                                                                        [class.logsOne]="list.name == 'Success'"
                                                                                                        [class.logsTwo]="list.name == 'open'"
                                                                                                        [class.logsThree]="list.name == 'delivered'"
                                                                                                        [class.error_box]="list.name == 'error'">
                                                                                                        <div
                                                                                                            class="dataCounter">
                                                                                                            <span>{{list.count}}</span>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            class="dataTitle">
                                                                                                            Email <span
                                                                                                                *ngIf="list.name == 'Success'">Sent</span>
                                                                                                            <span
                                                                                                                *ngIf="list.name == 'error'">Failed</span>
                                                                                                            <span
                                                                                                                *ngIf="list.name == 'open'">Open</span>
                                                                                                            <span
                                                                                                                *ngIf="list.name == 'delivered'">Delivered</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-container>

                                                                                                <div
                                                                                                    class="boxDevider logsTwo hide">
                                                                                                    <div
                                                                                                        class="dataCounter">
                                                                                                        20%</div>
                                                                                                    <div
                                                                                                        class="dataTitle">
                                                                                                        Open Rate</div>
                                                                                                </div>

                                                                                                <div
                                                                                                    class="boxDevider logsThree hide">
                                                                                                    <div
                                                                                                        class="dataCounter">
                                                                                                        12%</div>
                                                                                                    <div
                                                                                                        class="dataTitle">
                                                                                                        Click Rate</div>
                                                                                                </div>

                                                                                                <div
                                                                                                    class="boxDevider logsFour hide">
                                                                                                    <div
                                                                                                        class="dataCounter">
                                                                                                        0</div>
                                                                                                    <div
                                                                                                        class="dataTitle">
                                                                                                        Email Bounced
                                                                                                    </div>
                                                                                                </div>

                                                                                            </div>
                                                                                        </ng-container>

                                                                                        <div
                                                                                            class="scroller-max-height">
                                                                                            <div
                                                                                                class="table-responsive">
                                                                                                <ng-container
                                                                                                    *ngIf="timelineData">
                                                                                                    <table
                                                                                                        class="timelineTable"
                                                                                                        [class.single_elems]="timelineData.length == 1">
                                                                                                        <!-- <tr *ngFor="let list of timelineData | paginate: { itemsPerPage:dataSize, currentPage:pageNo, totalItems:totalDataSize };"> -->
                                                                                                        <tr
                                                                                                            *ngFor="let list of timelineData">
                                                                                                            <td>
                                                                                                                <div>
                                                                                                                    {{list.timelineDate}}
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    {{list.timelineTime}}
                                                                                                                </div>
                                                                                                            </td>
                                                                                                            <td
                                                                                                                class="txt-capitalize">
                                                                                                                <div
                                                                                                                    *ngIf="list.type == 'EMAIL'">
                                                                                                                    {{list.userProfileName}}
                                                                                                                    sent
                                                                                                                    {{list.type}}
                                                                                                                    via
                                                                                                                    <span
                                                                                                                        class="templateLink"
                                                                                                                        (click)="viewTemplateData(list.campaignId,list)">template</span>.
                                                                                                                    Status
                                                                                                                    :
                                                                                                                    {{list.status}}.
                                                                                                                </div>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </table>
                                                                                                </ng-container>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!-- <ng-container *ngIf="timelineData">
                                                                                            <div *ngIf="timelineData.length > 20" class="table-footer footer-gray">
                                                                                                <pagination-controls (pageChange)="dataForPaging($event)" maxSize="9" directionLinks="true" autoHide="true" class="pull-right" responsive="true" previousLabel="Previous" nextLabel="Next" screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
                                                                                                    screenReaderCurrentLabel="You're on page">
                                                                                                </pagination-controls>
                                                                                                <div class="textblue text-left">
                                                                                                    <div class="data-length"> Show
                                                                                                        <select [(ngModel)]="dataSize" (change)="getTimeline()">
                                                                                                        <option value="20">20</option>
                                                                                                        <option value="50">50</option>
                                                                                                        <option value="100">100</option>
                                                                                                    </select>
                                                                                                    </div>
                                                                                                    <span>Total Count: {{totalDataSize}}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </ng-container> -->
                                                                                        <ng-container
                                                                                            *ngIf="audStateData">
                                                                                            <div class="docViewerBox no-records"
                                                                                                *ngIf="audStateData.length == 0">
                                                                                                No Records Found
                                                                                            </div>
                                                                                        </ng-container>
                                                                                        <div class="text-right mt-2"
                                                                                            *ngIf="nextId">
                                                                                            <button class="btn btn-dark"
                                                                                                (click)="getNextTimelineData('EMAIL','loadMore')">Load
                                                                                                More...</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </tab>

                                                                            <tab heading="">
                                                                                <ng-template tabHeading>
                                                                                    <span class="desktopViewHeading"
                                                                                        (click)="individualAudienceStats('SMS')">SMS
                                                                                        Summary</span>
                                                                                    <span class="mobileViewHeading"
                                                                                        (click)="individualAudienceStats('SMS')">SMS</span>
                                                                                </ng-template>

                                                                                <div class="leadDetailInner">
                                                                                    <div
                                                                                        class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
                                                                                        <div class="innerFlexBox mobileNowrap"
                                                                                            *ngIf="audStateData">
                                                                                            <ng-container
                                                                                                *ngFor="let list of audStateData">
                                                                                                <div class="boxDevider"
                                                                                                    [class.logsOne]="list.name == 'SUCCESS'"
                                                                                                    [class.logsTwo]="list.name == 'open'"
                                                                                                    [class.logsThree]="list.name == 'delivered'"
                                                                                                    [class.error_box]="list.name == 'error'">
                                                                                                    <div
                                                                                                        class="dataCounter">
                                                                                                        <span>{{list.count}}</span>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        class="dataTitle">
                                                                                                        SMS <span
                                                                                                            *ngIf="list.name == 'SUCCESS'">Sent</span>
                                                                                                        <span
                                                                                                            *ngIf="list.name == 'error'">Failed</span>
                                                                                                        <span
                                                                                                            *ngIf="list.name == 'open'">Open</span>
                                                                                                        <span
                                                                                                            *ngIf="list.name == 'delivered'">Delivered</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </ng-container>

                                                                                            <div
                                                                                                class="boxDevider logsTwo hide">
                                                                                                <div
                                                                                                    class="dataCounter">
                                                                                                    0</div>
                                                                                                <div class="dataTitle">
                                                                                                    SMS Sent</div>
                                                                                            </div>

                                                                                        </div>


                                                                                        <ng-container
                                                                                            *ngIf="audStateData">
                                                                                            <div
                                                                                                class="scroller-max-height">
                                                                                                <div
                                                                                                    class="table-responsive">
                                                                                                    <ng-container
                                                                                                        *ngIf="timelineData">
                                                                                                        <table
                                                                                                            class="timelineTable"
                                                                                                            [class.single_elems]="timelineData.length == 1">
                                                                                                            <!-- <tr *ngFor="let list of timelineData | paginate: { itemsPerPage:dataSize, currentPage:pageNo, totalItems:totalDataSize };"> -->
                                                                                                            <tr
                                                                                                                *ngFor="let list of timelineData">
                                                                                                                <td>
                                                                                                                    <div>
                                                                                                                        {{list.timelineDate}}
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        {{list.timelineTime}}
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                                <td
                                                                                                                    class="txt-capitalize">
                                                                                                                    <div
                                                                                                                        *ngIf="list.type == 'SMS'">
                                                                                                                        {{list.userProfileName}}
                                                                                                                        sent
                                                                                                                        {{list.type}}
                                                                                                                        via
                                                                                                                        <span
                                                                                                                            class="templateLink"
                                                                                                                            (click)="viewTemplateData(list.campaignId,list)">template</span>.
                                                                                                                        Status
                                                                                                                        :
                                                                                                                        {{list.status}}.
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </table>
                                                                                                    </ng-container>
                                                                                                </div>
                                                                                            </div>
                                                                                        </ng-container>

                                                                                        <!-- <ng-container *ngIf="timelineData">
                                                                                            <div *ngIf="timelineData.length > 20" class="table-footer footer-gray">
                                                                                                <pagination-controls (pageChange)="dataForPaging($event)" maxSize="9" directionLinks="true" autoHide="true" class="pull-right" responsive="true" previousLabel="Previous" nextLabel="Next" screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
                                                                                                    screenReaderCurrentLabel="You're on page">
                                                                                                </pagination-controls>
                                                                                                <div class="textblue text-left">
                                                                                                    <div class="data-length"> Show
                                                                                                        <select [(ngModel)]="dataSize" (change)="getTimeline()">
                                                                                                        <option value="20">20</option>
                                                                                                        <option value="50">50</option>
                                                                                                        <option value="100">100</option>
                                                                                                    </select>
                                                                                                    </div>
                                                                                                    <span>Total Count: {{totalDataSize}}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </ng-container> -->
                                                                                        <ng-container
                                                                                            *ngIf="audStateData">
                                                                                            <div class="docViewerBox no-records"
                                                                                                *ngIf="audStateData.length == 0">
                                                                                                No Records Found
                                                                                            </div>
                                                                                        </ng-container>
                                                                                        <div class="text-right mt-2"
                                                                                            *ngIf="nextId">
                                                                                            <button class="btn btn-dark"
                                                                                                (click)="getNextTimelineData('SMS','loadMore')">Load
                                                                                                More...</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </tab>

                                                                            <tab heading="">
                                                                                <ng-template tabHeading>
                                                                                    <span class="desktopViewHeading"
                                                                                        (click)="individualAudienceStats('WHATSAPP')">Whatsapp
                                                                                        Summary</span>
                                                                                    <span class="mobileViewHeading"
                                                                                        (click)="individualAudienceStats('WHATSAPP')">Whatsapp</span>
                                                                                </ng-template>
                                                                                <div class="leadDetailInner">

                                                                                    <div
                                                                                        class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
                                                                                        <div class="innerFlexBox mobileNowrap"
                                                                                            *ngIf="audStateData">
                                                                                            <ng-container
                                                                                                *ngFor="let list of audStateData">
                                                                                                <div class="boxDevider"
                                                                                                    [class.logsOne]="list.name == 'Success'"
                                                                                                    [class.logsTwo]="list.name == 'open'"
                                                                                                    [class.logsThree]="list.name == 'delivered'"
                                                                                                    [class.error_box]="list.name == 'error'">
                                                                                                    <div
                                                                                                        class="dataCounter">
                                                                                                        <span>{{list.count}}</span>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        class="dataTitle">
                                                                                                        WhatsApp <span
                                                                                                            *ngIf="list.name == 'Success'">Sent</span>
                                                                                                        <span
                                                                                                            *ngIf="list.name == 'error'">Failed</span>
                                                                                                        <span
                                                                                                            *ngIf="list.name == 'open'">Open</span>
                                                                                                        <span
                                                                                                            *ngIf="list.name == 'delivered'">Delivered</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </ng-container>
                                                                                        </div>

                                                                                        <div
                                                                                            class="scroller-max-height">
                                                                                            <div
                                                                                                class="table-responsive">
                                                                                                <ng-container
                                                                                                    *ngIf="timelineData">
                                                                                                    <table
                                                                                                        class="timelineTable"
                                                                                                        [class.single_elems]="timelineData.length == 1">
                                                                                                        <!-- <tr *ngFor="let list of timelineData | paginate: { itemsPerPage:dataSize, currentPage:pageNo, totalItems:totalDataSize };"> -->
                                                                                                        <tr
                                                                                                            *ngFor="let list of timelineData">
                                                                                                            <td>
                                                                                                                <div>
                                                                                                                    {{list.timelineDate}}
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    {{list.timelineTime}}
                                                                                                                </div>
                                                                                                            </td>
                                                                                                            <td
                                                                                                                class="txt-capitalize">
                                                                                                                <div
                                                                                                                    *ngIf="list.type == 'WHATSAPP'">
                                                                                                                    {{list.userProfileName}}
                                                                                                                    sent
                                                                                                                    {{list.type}}
                                                                                                                    via
                                                                                                                    <span
                                                                                                                        class="templateLink"
                                                                                                                        (click)="viewTemplateData(list.campaignId,list)">template</span>.
                                                                                                                    Status
                                                                                                                    :
                                                                                                                    {{list.status}}.
                                                                                                                </div>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </table>
                                                                                                </ng-container>
                                                                                            </div>
                                                                                        </div>

                                                                                        <ng-container
                                                                                            *ngIf="audStateData">
                                                                                            <div class="docViewerBox no-records"
                                                                                                *ngIf="audStateData.length == 0">
                                                                                                No Records Found
                                                                                            </div>
                                                                                        </ng-container>
                                                                                        <div class="text-right mt-2"
                                                                                            *ngIf="nextId">
                                                                                            <button class="btn btn-dark"
                                                                                                (click)="getNextTimelineData('WHATSAPP','loadMore')">Load
                                                                                                More...</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </tab>

                                                                            <tab heading="">
                                                                                <ng-template tabHeading>
                                                                                    <span class="desktopViewHeading"
                                                                                        (click)="individualAudienceStats('ANDROID_PUSH')">Android
                                                                                        Push</span>
                                                                                    <span class="mobileViewHeading"
                                                                                        (click)="individualAudienceStats('ANDROID_PUSH')">Android
                                                                                        Push</span>
                                                                                </ng-template>

                                                                                <div class="leadDetailInner">
                                                                                    <ng-container *ngIf="audStateData">
                                                                                        <div
                                                                                            class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
                                                                                            <div class="innerFlexBox mobileNowrap"
                                                                                                *ngIf="audStateData">
                                                                                                <ng-container
                                                                                                    *ngFor="let list of audStateData">
                                                                                                    <div class="boxDevider"
                                                                                                        [class.logsOne]="list.name == 'Success' || list.name == 'SUCCESS'"
                                                                                                        [class.logsTwo]="list.name == 'open' || list.name == 'OPEN'"
                                                                                                        [class.logsThree]="list.name == 'delivered' || list.name == 'DELIVERED'"
                                                                                                        [class.error_box]="list.name == 'error' || list.name == 'ERROR'">
                                                                                                        <div
                                                                                                            class="dataCounter">
                                                                                                            <span>{{list.count}}</span>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            class="dataTitle">
                                                                                                            Notification
                                                                                                            <span
                                                                                                                *ngIf="list.name == 'SUCCESS'">Sent</span>
                                                                                                            <span
                                                                                                                *ngIf="list.name == 'error'">Failed</span>
                                                                                                            <span
                                                                                                                *ngIf="list.name == 'open'">Open</span>
                                                                                                            <span
                                                                                                                *ngIf="list.name == 'delivered'">Delivered</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-container>
                                                                                            </div>

                                                                                            <div
                                                                                                class="scroller-max-height">
                                                                                                <div
                                                                                                    class="table-responsive">
                                                                                                    <ng-container
                                                                                                        *ngIf="timelineData">
                                                                                                        <table
                                                                                                            class="timelineTable"
                                                                                                            [class.single_elems]="timelineData.length == 1">
                                                                                                            <!-- <tr *ngFor="let list of timelineData | paginate: { itemsPerPage:dataSize, currentPage:pageNo, totalItems:totalDataSize };"> -->
                                                                                                            <tr
                                                                                                                *ngFor="let list of timelineData">
                                                                                                                <td>
                                                                                                                    <div>
                                                                                                                        {{list.timelineDate}}
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        {{list.timelineTime}}
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                                <td
                                                                                                                    class="txt-capitalize">
                                                                                                                    <div
                                                                                                                        *ngIf="list.type == 'ANDROID_PUSH'">
                                                                                                                        {{list.userProfileName}}
                                                                                                                        Sent
                                                                                                                        Android
                                                                                                                        Push
                                                                                                                        Via
                                                                                                                        <span
                                                                                                                            class="templateLink"
                                                                                                                            (click)="viewTemplateData(list.campaignId,list)">template</span>.
                                                                                                                        Status
                                                                                                                        :
                                                                                                                        {{list.status}}.
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </table>
                                                                                                    </ng-container>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!-- <ng-container *ngIf="timelineData">
                                                                                                <div *ngIf="timelineData.length > 20" class="table-footer footer-gray">
                                                                                                    <pagination-controls (pageChange)="dataForPaging($event)" maxSize="9" directionLinks="true" autoHide="true" class="pull-right" responsive="true" previousLabel="Previous" nextLabel="Next" screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
                                                                                                        screenReaderCurrentLabel="You're on page">
                                                                                                    </pagination-controls>
                                                                                                    <div class="textblue text-left">
                                                                                                        <div class="data-length"> Show
                                                                                                            <select [(ngModel)]="dataSize" (change)="getTimeline()">
                                                                                                        <option value="20">20</option>
                                                                                                        <option value="50">50</option>
                                                                                                        <option value="100">100</option>
                                                                                                    </select>
                                                                                                        </div>
                                                                                                        <span>Total Count: {{totalDataSize}}</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </ng-container> -->
                                                                                            <ng-container
                                                                                                *ngIf="audStateData">
                                                                                                <div class="docViewerBox no-records"
                                                                                                    *ngIf="audStateData.length == 0">
                                                                                                    No Records Found
                                                                                                </div>
                                                                                            </ng-container>
                                                                                            <div class="text-right mt-2"
                                                                                                *ngIf="nextId">
                                                                                                <button
                                                                                                    class="btn btn-dark"
                                                                                                    (click)="getNextTimelineData('ANDROID_PUSH','loadMore')">Load
                                                                                                    More...</button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </ng-container>
                                                                                </div>
                                                                            </tab>

                                                                            <tab heading="">
                                                                                <ng-template tabHeading>
                                                                                    <span class="desktopViewHeading"
                                                                                        (click)="individualAudienceStats('IOS_PUSH')">IOS
                                                                                        Push</span>
                                                                                    <span class="mobileViewHeading"
                                                                                        (click)="individualAudienceStats('IOS_PUSH')">IOS
                                                                                        Push</span>
                                                                                </ng-template>

                                                                                <div class="leadDetailInner">
                                                                                    <ng-container *ngIf="audStateData">
                                                                                        <div
                                                                                            class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
                                                                                            <div class="innerFlexBox mobileNowrap"
                                                                                                *ngIf="audStateData">
                                                                                                <ng-container
                                                                                                    *ngFor="let list of audStateData">
                                                                                                    <div class="boxDevider"
                                                                                                    [class.logsOne]="list.name == 'Success' || list.name == 'SUCCESS'"
                                                                                                    [class.logsTwo]="list.name == 'open' || list.name == 'OPEN'"
                                                                                                    [class.logsThree]="list.name == 'delivered' || list.name == 'DELIVERED'"
                                                                                                    [class.error_box]="list.name == 'error' || list.name == 'ERROR'">
                                                                                                        <div
                                                                                                            class="dataCounter">
                                                                                                            <span>{{list.count}}</span>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            class="dataTitle">
                                                                                                            Notification
                                                                                                            <span
                                                                                                                *ngIf="list.name == 'SUCCESS'">Sent</span>
                                                                                                            <span
                                                                                                                *ngIf="list.name == 'error'">Failed</span>
                                                                                                            <span
                                                                                                                *ngIf="list.name == 'open'">Open</span>
                                                                                                            <span
                                                                                                                *ngIf="list.name == 'delivered'">Delivered</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-container>
                                                                                            </div>

                                                                                            <div
                                                                                                class="scroller-max-height">
                                                                                                <div
                                                                                                    class="table-responsive">
                                                                                                    <ng-container
                                                                                                        *ngIf="timelineData">
                                                                                                        <table
                                                                                                            class="timelineTable"
                                                                                                            [class.single_elems]="timelineData.length == 1">
                                                                                                            <!-- <tr *ngFor="let list of timelineData | paginate: { itemsPerPage:dataSize, currentPage:pageNo, totalItems:totalDataSize };"> -->
                                                                                                            <tr
                                                                                                                *ngFor="let list of timelineData">
                                                                                                                <td>
                                                                                                                    <div>
                                                                                                                        {{list.timelineDate}}
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        {{list.timelineTime}}
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                                <td
                                                                                                                    class="txt-capitalize">
                                                                                                                    <div
                                                                                                                        *ngIf="list.type == 'IOS_PUSH'">
                                                                                                                        {{list.userProfileName}}
                                                                                                                        Sent
                                                                                                                        IOS
                                                                                                                        Push
                                                                                                                        Notification
                                                                                                                        Via
                                                                                                                        <span
                                                                                                                            class="templateLink"
                                                                                                                            (click)="viewTemplateData(list.campaignId,list)">template</span>.
                                                                                                                        Status
                                                                                                                        :
                                                                                                                        {{list.status}}.
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </table>
                                                                                                    </ng-container>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!-- <ng-container *ngIf="timelineData">
                                                                                                <div *ngIf="timelineData.length > 20" class="table-footer footer-gray">
                                                                                                    <pagination-controls (pageChange)="dataForPaging($event)" maxSize="9" directionLinks="true" autoHide="true" class="pull-right" responsive="true" previousLabel="Previous" nextLabel="Next" screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
                                                                                                        screenReaderCurrentLabel="You're on page">
                                                                                                    </pagination-controls>
                                                                                                    <div class="textblue text-left">
                                                                                                        <div class="data-length"> Show
                                                                                                            <select [(ngModel)]="dataSize" (change)="getTimeline()">
                                                                                                        <option value="20">20</option>
                                                                                                        <option value="50">50</option>
                                                                                                        <option value="100">100</option>
                                                                                                    </select>
                                                                                                        </div>
                                                                                                        <span>Total Count: {{totalDataSize}}</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </ng-container> -->
                                                                                            <ng-container
                                                                                                *ngIf="audStateData">
                                                                                                <div class="docViewerBox no-records"
                                                                                                    *ngIf="audStateData.length == 0">
                                                                                                    No Records Found
                                                                                                </div>
                                                                                            </ng-container>
                                                                                            <div class="text-right mt-2"
                                                                                                *ngIf="nextId">
                                                                                                <button
                                                                                                    class="btn btn-dark"
                                                                                                    (click)="getNextTimelineData('IOS_PUSH','loadMore')">Load
                                                                                                    More...</button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </ng-container>
                                                                                </div>
                                                                            </tab>

                                                                            <tab heading="">
                                                                                <ng-template tabHeading>
                                                                                    <span class="desktopViewHeading"
                                                                                        (click)="individualAudienceStats('WEB_PUSH')">Web
                                                                                        Push</span>
                                                                                    <span class="mobileViewHeading"
                                                                                        (click)="individualAudienceStats('WEB_PUSH')">Web
                                                                                        Push</span>
                                                                                </ng-template>

                                                                                <div class="leadDetailInner">
                                                                                    <ng-container *ngIf="audStateData">
                                                                                        <div
                                                                                            class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
                                                                                            <div class="innerFlexBox mobileNowrap"
                                                                                                *ngIf="audStateData">
                                                                                                <ng-container
                                                                                                    *ngFor="let list of audStateData">
                                                                                                    <div class="boxDevider"
                                                                                                    [class.logsOne]="list.name == 'Success' || list.name == 'SUCCESS'"
                                                                                                    [class.logsTwo]="list.name == 'open' || list.name == 'OPEN'"
                                                                                                    [class.logsThree]="list.name == 'delivered' || list.name == 'DELIVERED'"
                                                                                                    [class.error_box]="list.name == 'error' || list.name == 'ERROR'">
                                                                                                        <div
                                                                                                            class="dataCounter">
                                                                                                            <span>{{list.count}}</span>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            class="dataTitle">
                                                                                                            Notification
                                                                                                            <span
                                                                                                                *ngIf="list.name == 'SUCCESS'">Sent</span>
                                                                                                            <span
                                                                                                                *ngIf="list.name == 'error'">Failed</span>
                                                                                                            <span
                                                                                                                *ngIf="list.name == 'open'">Open</span>
                                                                                                            <span
                                                                                                                *ngIf="list.name == 'delivered'">Delivered</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-container>
                                                                                            </div>

                                                                                            <div
                                                                                                class="scroller-max-height">
                                                                                                <div
                                                                                                    class="table-responsive">
                                                                                                    <ng-container
                                                                                                        *ngIf="timelineData">
                                                                                                        <table
                                                                                                            class="timelineTable"
                                                                                                            [class.single_elems]="timelineData.length == 1">
                                                                                                            <!-- <tr *ngFor="let list of timelineData | paginate: { itemsPerPage:dataSize, currentPage:pageNo, totalItems:totalDataSize };"> -->
                                                                                                            <tr
                                                                                                                *ngFor="let list of timelineData">
                                                                                                                <td>
                                                                                                                    <div>
                                                                                                                        {{list.timelineDate}}
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        {{list.timelineTime}}
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                                <td
                                                                                                                    class="txt-capitalize">
                                                                                                                    <div
                                                                                                                        *ngIf="list.type == 'WEB_PUSH'">
                                                                                                                        {{list.userProfileName}}
                                                                                                                        sent
                                                                                                                        Web
                                                                                                                        Push
                                                                                                                        Notification
                                                                                                                        Via
                                                                                                                        <span
                                                                                                                            class="templateLink"
                                                                                                                            (click)="viewTemplateData(list.campaignId,list)">template</span>.
                                                                                                                        Status
                                                                                                                        :
                                                                                                                        {{list.status}}.
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </table>
                                                                                                    </ng-container>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!-- <ng-container *ngIf="timelineData">
                                                                                                <div *ngIf="timelineData.length > 20" class="table-footer footer-gray">
                                                                                                    <pagination-controls (pageChange)="dataForPaging($event)" maxSize="9" directionLinks="true" autoHide="true" class="pull-right" responsive="true" previousLabel="Previous" nextLabel="Next" screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
                                                                                                        screenReaderCurrentLabel="You're on page">
                                                                                                    </pagination-controls>
                                                                                                    <div class="textblue text-left">
                                                                                                        <div class="data-length"> Show
                                                                                                            <select [(ngModel)]="dataSize" (change)="getTimeline()">
                                                                                                        <option value="20">20</option>
                                                                                                        <option value="50">50</option>
                                                                                                        <option value="100">100</option>
                                                                                                    </select>
                                                                                                        </div>
                                                                                                        <span>Total Count: {{totalDataSize}}</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </ng-container> -->
                                                                                            <ng-container
                                                                                                *ngIf="audStateData">
                                                                                                <div class="docViewerBox no-records"
                                                                                                    *ngIf="audStateData.length == 0">
                                                                                                    No Records Found
                                                                                                </div>
                                                                                            </ng-container>
                                                                                            <div class="text-right mt-2"
                                                                                                *ngIf="nextId">
                                                                                                <button
                                                                                                    class="btn btn-dark"
                                                                                                    (click)="getNextTimelineData('WEB_PUSH','loadMore')">Load
                                                                                                    More...</button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </ng-container>
                                                                                </div>
                                                                            </tab>

                                                                        </div>
                                                                    </tabset>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </tab>

                                        <tab *ngIf="audience.mobile != null" heading="" (selectTab)="getcallLogs()">
                                            <ng-template tabHeading>
                                                <span>Call History</span>
                                            </ng-template>
                                            <div class="table-responsive table-height-scroll table-reports">
                                                <table class="table table-bordered" *ngIf="callLogs != undefined">
                                                    <tbody>
                                                        <tr>
                                                            <th>Phone No.</th>
                                                            <th>Duration</th>
                                                            <th>Date & Time</th>
                                                            <th>Type</th>
                                                            <th>Called By</th>
                                                            <th>Recording</th>
                                                        </tr>
                                                        <tr *ngFor="let item of callLogs">
                                                            <td>{{item.number}}</td>
                                                            <td>{{item.durationMin}}</td>
                                                            <td>{{item.callDate | date:'yyyy-MM-dd H:mm:ss'}}</td>

                                                            <td [ngClass]="item.state">{{item.state}}</td>
                                                            <td>{{item.telecaller}}</td>
                                                            <td
                                                                *ngIf="item.recordingUrl !='' && item.recordingUrl !=null">
                                                                <!-- <audio controls="controls">
                                                                    <source src={{item.recordingUrl}}>
                                                                </audio> -->
                                                                <a href="{{item.recordingUrl}}" target="_blank">Download Call Recording</a>
                                                            </td>
                                                        </tr>
                                                        <tr *ngIf="callLogs.length == 0">
                                                            <td class="no-records" colspan="6">No Records Found</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </tab>


                                        <tab heading="Repeat Lead" (selectTab)="getRepeatLeads()" [active]="">
                                            <ng-template tabHeading>
                                                <span>Repeat Lead</span>
                                            </ng-template>
                                            <div class="table-responsive table-height-scroll table-reports">
                                                <tabset class="tab-container vertical_view_tab">
                                                    <div class="tab-content main_outer_content">
                                                        <tab heading=""
                                                            *ngFor="let list of repeatLeadData; let i = index;"
                                                            (selectTab)="showDetailRepeatLead(list.id,i)"
                                                            [active]="i == 0 ? true : false">
                                                            <ng-template tabHeading>
                                                                <span class="lead_form_name">
                                                                    <div class="lead_source_name">
                                                                        {{list.source}}
                                                                    </div>
                                                                </span>
                                                            </ng-template>
                                                            <div class="tab-content">
                                                                <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12"
                                                                    *ngIf="screenWidth != 1">

                                                                    <div class="table-reports">
                                                                        <div class="table-body form-row"
                                                                            *ngIf="repeatLeadDynamicList">
                                                                            <div class="table-responsive table-height-scroll col-md-6 col-sm-12 col-xs-12 col-lg-6"
                                                                                #scrollblockLeft>
                                                                                <table class="table table-bordered">
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <th>Parameter</th>
                                                                                            <th>Value</th>
                                                                                        </tr>
                                                                                        <ng-container
                                                                                            *ngFor="let item of repeatLeadDynamicList; let i = index">
                                                                                            <ng-container
                                                                                                *ngIf="item.invoice_quotation_hide != true">
                                                                                                <tr *ngIf="i % 2 != 1">
                                                                                                    <td>{{
                                                                                                        item.paramName
                                                                                                        }}</td>
                                                                                                    <td>
                                                                                                        <ng-container
                                                                                                            *ngIf="item.paramValueChange === 'ACTIVE'; else otherParam">
                                                                                                            <div class="paremvalue"
                                                                                                                *ngIf="!item.editParam">
                                                                                                                <span
                                                                                                                    *ngIf="item.paramDatatype == 'File' && !item.fileLink"
                                                                                                                    class="value"
                                                                                                                    [innerHtml]="item.paramValue"></span>
                                                                                                                <span
                                                                                                                    *ngIf="item.paramDatatype != 'File' && !item.fileLink"
                                                                                                                    class="value">{{item.paramValue}}</span>
                                                                                                                <a *ngIf="item.fileLink"
                                                                                                                    href="{{item.paramValue}}"
                                                                                                                    target="_blank">
                                                                                                                    Click
                                                                                                                    to
                                                                                                                    view
                                                                                                                </a>
                                                                                                            </div>
                                                                                                            <div *ngIf="item.editParam"
                                                                                                                class="input-group">
                                                                                                                {{paremType}}
                                                                                                                <ng-container
                                                                                                                    *ngIf="item.paramDefaultValueDTO == undefined">
                                                                                                                    <textarea
                                                                                                                        trim="blur"
                                                                                                                        *ngIf="item.paramDatatype != 'Date' && item.paramDatatype != 'File'"
                                                                                                                        class="form-control"
                                                                                                                        [ngClass]="{'invalid-control' : isSubmit && item.paramMandatory == 'ACTIVE' && item.paramValue == ''}"
                                                                                                                        [(ngModel)]="item.paramValue"></textarea>
                                                                                                                    <input
                                                                                                                        *ngIf="item.paramDatatype == 'Date'"
                                                                                                                        type="text"
                                                                                                                        placement="top left"
                                                                                                                        [(ngModel)]="item.paramValue"
                                                                                                                        [ngClass]="{'invalid-control' : isSubmit && item.paramMandatory == 'ACTIVE' && item.paramValue == ''}"
                                                                                                                        class="form-control date-icon modal-space"
                                                                                                                        bsDatepicker
                                                                                                                        autocomplete="off"
                                                                                                                        [bsConfig]="global.datePickerConfig"
                                                                                                                        [minDate]="item.backDataEditable == 'INACTIVE' ? todayDate : undefined">
                                                                                                                    <ng-container
                                                                                                                        *ngIf="item.paramDatatype == 'File'">

                                                                                                                        <div class="relatedOuterNgmodel"
                                                                                                                            [ngClass]="{'invalid-control' : isSubmit && item.paramMandatory == 'ACTIVE' && item.paramValue == ''}">
                                                                                                                            <input
                                                                                                                                type="button"
                                                                                                                                class="fileTypeButton form-control"
                                                                                                                                [value]="item.fileNameOfFile?item.fileNameOfFile:'Choose File'"
                                                                                                                                [ngClass]="{'invalid-control' : isSubmit && item.paramMandatory == 'ACTIVE' && item.paramValue == ''}">
                                                                                                                            <input
                                                                                                                                (change)="custmAttrFIleUpload($event,item)"
                                                                                                                                [title]="item.fileNameOfFile?item.fileNameOfFile:'Choose file'"
                                                                                                                                type="file"
                                                                                                                                class="form-control absoluteTypeFileBtn"
                                                                                                                                [ngClass]="{'invalid-control' : isSubmit && item.paramMandatory == 'ACTIVE' && item.paramValue == ''}">
                                                                                                                        </div>

                                                                                                                        <input
                                                                                                                            type="hidden"
                                                                                                                            [(ngModel)]="item.paramValue">
                                                                                                                    </ng-container>
                                                                                                                </ng-container>
                                                                                                                <ng-container
                                                                                                                    *ngIf="item.paramDefaultValueDTO != undefined">
                                                                                                                    <select
                                                                                                                        *ngIf="item.paramDatatype != 'Date'"
                                                                                                                        type="text"
                                                                                                                        class="form-control"
                                                                                                                        [ngClass]="{'invalid-control' : isSubmit && item.paramMandatory == 'ACTIVE' && item.paramValue == ''}"
                                                                                                                        [(ngModel)]="item.paramValue">
                                                                                                                        <option
                                                                                                                            *ngFor="let list of item.paramDefaultValueDTO"
                                                                                                                            [value]="list.paramValue">
                                                                                                                            {{list.paramValue}}
                                                                                                                        </option>
                                                                                                                    </select>
                                                                                                                </ng-container>
                                                                                                            </div>
                                                                                                        </ng-container>
                                                                                                        <ng-template
                                                                                                            #otherParam>
                                                                                                            <!-- <span [innerHtml]="item.paramValue"></span> -->
                                                                                                            <span
                                                                                                                *ngIf="item.paramDatatype == 'File' && !item.fileLink"
                                                                                                                class="value"
                                                                                                                [innerHtml]="item.paramValue"></span>
                                                                                                            <span
                                                                                                                *ngIf="item.paramDatatype != 'File' && !item.fileLink"
                                                                                                                class="value">{{item.paramValue}}</span>
                                                                                                            <!-- <a *ngIf="item.fileLink == true" href="{{item.paramValue}}" target="_blank"> Click to view </a> -->
                                                                                                            <a *ngIf="item.fileLink == true"
                                                                                                                href="javascript:void(0)"
                                                                                                                (click)="downloadFileBylink(item)">
                                                                                                                Click to
                                                                                                                view
                                                                                                            </a>
                                                                                                        </ng-template>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </ng-container>
                                                                                        </ng-container>

                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                            <div
                                                                                class="col-md-6 col-sm-12 col-xs-12 col-lg-6 scrollBlockTwo">
                                                                                <div class="table-responsive table-height-scroll"
                                                                                    #scrollblockRight>
                                                                                    <table class="table table-bordered">
                                                                                        <tbody>
                                                                                            <tr class="hiddenInPrint">
                                                                                                <th>Parameter</th>
                                                                                                <th>Value</th>
                                                                                            </tr>
                                                                                            <ng-container
                                                                                                *ngFor="let item of repeatLeadDynamicList; let i = index">
                                                                                                <ng-container
                                                                                                    *ngIf="item.invoice_quotation_hide != true">
                                                                                                    <tr
                                                                                                        *ngIf="i % 2 == 1">
                                                                                                        <td>{{
                                                                                                            item.paramName
                                                                                                            }}</td>
                                                                                                        <td>
                                                                                                            <ng-container
                                                                                                                *ngIf="item.paramValueChange === 'ACTIVE'; else otherParam">
                                                                                                                <div class="paremvalue"
                                                                                                                    *ngIf="!item.editParam">
                                                                                                                    <span
                                                                                                                        *ngIf="item.paramDatatype == 'File' && !item.fileLink"
                                                                                                                        class="value"
                                                                                                                        [innerHtml]="item.paramValue"></span>
                                                                                                                    <span
                                                                                                                        *ngIf="item.paramDatatype != 'File' && !item.fileLink"
                                                                                                                        class="value">{{item.paramValue}}</span>
                                                                                                                    <a *ngIf="item.fileLink"
                                                                                                                        href="{{item.paramValue}}"
                                                                                                                        target="_blank">
                                                                                                                        Click
                                                                                                                        to
                                                                                                                        view
                                                                                                                    </a>
                                                                                                                </div>
                                                                                                                <div *ngIf="item.editParam"
                                                                                                                    class="input-group">
                                                                                                                    {{paremType}}
                                                                                                                    <ng-container
                                                                                                                        *ngIf="item.paramDefaultValueDTO == undefined">
                                                                                                                        <textarea
                                                                                                                            trim="blur"
                                                                                                                            *ngIf="item.paramDatatype != 'Date' && item.paramDatatype != 'File'"
                                                                                                                            class="form-control"
                                                                                                                            [ngClass]="{'invalid-control' : isSubmit && item.paramMandatory == 'ACTIVE' && item.paramValue == ''}"
                                                                                                                            [(ngModel)]="item.paramValue"></textarea>
                                                                                                                        <input
                                                                                                                            *ngIf="item.paramDatatype == 'Date'"
                                                                                                                            type="text"
                                                                                                                            placement="top left"
                                                                                                                            [(ngModel)]="item.paramValue"
                                                                                                                            [ngClass]="{'invalid-control' : isSubmit && item.paramMandatory == 'ACTIVE' && item.paramValue == ''}"
                                                                                                                            class="form-control date-icon modal-space"
                                                                                                                            bsDatepicker
                                                                                                                            autocomplete="off"
                                                                                                                            [bsConfig]="global.datePickerConfig"
                                                                                                                            [minDate]="item.backDataEditable == 'INACTIVE' ? todayDate : undefined">
                                                                                                                        <ng-container
                                                                                                                            *ngIf="item.paramDatatype == 'File'">
                                                                                                                            <div class="relatedOuterNgmodel"
                                                                                                                                [ngClass]="{'invalid-control' : isSubmit && item.paramMandatory == 'ACTIVE' && item.paramValue == ''}">
                                                                                                                                <input
                                                                                                                                    type="button"
                                                                                                                                    class="fileTypeButton form-control"
                                                                                                                                    [value]="item.fileNameOfFile?item.fileNameOfFile:'Choose File'"
                                                                                                                                    [ngClass]="{'invalid-control' : isSubmit && item.paramMandatory == 'ACTIVE' && item.paramValue == ''}">
                                                                                                                                <input
                                                                                                                                    (change)="custmAttrFIleUpload($event,item)"
                                                                                                                                    [title]="item.fileNameOfFile?item.fileNameOfFile:'Choose file'"
                                                                                                                                    type="file"
                                                                                                                                    class="form-control absoluteTypeFileBtn"
                                                                                                                                    [ngClass]="{'invalid-control' : isSubmit && item.paramMandatory == 'ACTIVE' && item.paramValue == ''}">
                                                                                                                            </div>

                                                                                                                            <input
                                                                                                                                type="hidden"
                                                                                                                                [(ngModel)]="item.paramValue">
                                                                                                                        </ng-container>
                                                                                                                    </ng-container>
                                                                                                                    <ng-container
                                                                                                                        *ngIf="item.paramDefaultValueDTO != undefined">
                                                                                                                        <select
                                                                                                                            *ngIf="item.paramDatatype != 'Date'"
                                                                                                                            type="text"
                                                                                                                            class="form-control"
                                                                                                                            [ngClass]="{'invalid-control' : isSubmit && item.paramMandatory == 'ACTIVE' && item.paramValue == ''}"
                                                                                                                            [(ngModel)]="item.paramValue">
                                                                                                                            <option
                                                                                                                                *ngFor="let list of item.paramDefaultValueDTO">
                                                                                                                                {{list.paramValue}}
                                                                                                                            </option>
                                                                                                                        </select>
                                                                                                                    </ng-container>
                                                                                                                </div>
                                                                                                            </ng-container>
                                                                                                            <ng-template
                                                                                                                #otherParam>
                                                                                                                <!-- <span [innerHtml]="item.paramValue"></span> -->
                                                                                                                <span
                                                                                                                    *ngIf="item.paramDatatype == 'File' && !item.fileLink"
                                                                                                                    class="value"
                                                                                                                    [innerHtml]="item.paramValue"></span>
                                                                                                                <span
                                                                                                                    *ngIf="item.paramDatatype != 'File' && !item.fileLink"
                                                                                                                    class="value">{{item.paramValue}}</span>
                                                                                                                <!-- <a *ngIf="item.fileLink == true" href="{{item.paramValue}}" target="_blank"> Click to view </a> -->
                                                                                                                <a *ngIf="item.fileLink == true"
                                                                                                                    href="javascript:void(0)"
                                                                                                                    (click)="downloadFileBylink(item)">
                                                                                                                    Click
                                                                                                                    to
                                                                                                                    view
                                                                                                                </a>
                                                                                                            </ng-template>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </ng-container>
                                                                                            </ng-container>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                    </div>

                                                                </div>

                                                                <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12"
                                                                    *ngIf="screenWidth == 1">

                                                                    <div class="table-reports">
                                                                        <div class="table-body form-row"
                                                                            *ngIf="repeatLeadDynamicList">
                                                                            <div class="table-responsive table-height-scroll col-md-6 col-sm-12 col-xs-12 col-lg-6"
                                                                                #scrollblockLeft>
                                                                                <table class="table table-bordered">
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <th>Parameter</th>
                                                                                            <th>Value</th>
                                                                                        </tr>
                                                                                        <ng-container
                                                                                            *ngFor="let item of repeatLeadDynamicList.audienceDynamicDataList; let i = index">
                                                                                            <ng-container
                                                                                                *ngIf="item.invoice_quotation_hide != true">
                                                                                                <tr>
                                                                                                    <td>{{
                                                                                                        item.paramName
                                                                                                        }}</td>
                                                                                                    <td>
                                                                                                        <ng-container
                                                                                                            *ngIf="item.paramValueChange === 'ACTIVE'; else otherParam">
                                                                                                            <div class="paremvalue"
                                                                                                                *ngIf="!item.editParam">
                                                                                                                <span
                                                                                                                    *ngIf="item.paramDatatype == 'File' && !item.fileLink"
                                                                                                                    class="value"
                                                                                                                    [innerHtml]="item.paramValue"></span>
                                                                                                                <span
                                                                                                                    *ngIf="item.paramDatatype != 'File' && !item.fileLink"
                                                                                                                    class="value">{{item.paramValue}}</span>
                                                                                                                <a *ngIf="item.fileLink"
                                                                                                                    href="{{item.paramValue}}"
                                                                                                                    target="_blank">
                                                                                                                    Click
                                                                                                                    to
                                                                                                                    view
                                                                                                                </a>
                                                                                                            </div>
                                                                                                            <div *ngIf="item.editParam"
                                                                                                                class="input-group">
                                                                                                                {{paremType}}
                                                                                                                <ng-container
                                                                                                                    *ngIf="item.paramDefaultValueDTO == undefined">
                                                                                                                    <textarea
                                                                                                                        trim="blur"
                                                                                                                        *ngIf="item.paramDatatype != 'Date' && item.paramDatatype != 'File'"
                                                                                                                        class="form-control"
                                                                                                                        [ngClass]="{'invalid-control' : isSubmit && item.paramMandatory == 'ACTIVE' && item.paramValue == ''}"
                                                                                                                        [(ngModel)]="item.paramValue"></textarea>
                                                                                                                    <input
                                                                                                                        *ngIf="item.paramDatatype == 'Date'"
                                                                                                                        type="text"
                                                                                                                        placement="top left"
                                                                                                                        [(ngModel)]="item.paramValue"
                                                                                                                        [ngClass]="{'invalid-control' : isSubmit && item.paramMandatory == 'ACTIVE' && item.paramValue == ''}"
                                                                                                                        class="form-control date-icon modal-space"
                                                                                                                        bsDatepicker
                                                                                                                        autocomplete="off"
                                                                                                                        [bsConfig]="global.datePickerConfig"
                                                                                                                        [minDate]="item.backDataEditable == 'INACTIVE' ? todayDate : undefined">
                                                                                                                    <ng-container
                                                                                                                        *ngIf="item.paramDatatype == 'File'">

                                                                                                                        <div class="relatedOuterNgmodel"
                                                                                                                            [ngClass]="{'invalid-control' : isSubmit && item.paramMandatory == 'ACTIVE' && item.paramValue == ''}">
                                                                                                                            <input
                                                                                                                                type="button"
                                                                                                                                class="fileTypeButton form-control"
                                                                                                                                [value]="item.fileNameOfFile?item.fileNameOfFile:'Choose File'"
                                                                                                                                [ngClass]="{'invalid-control' : isSubmit && item.paramMandatory == 'ACTIVE' && item.paramValue == ''}">
                                                                                                                            <input
                                                                                                                                (change)="custmAttrFIleUpload($event,item)"
                                                                                                                                [title]="item.fileNameOfFile?item.fileNameOfFile:'Choose file'"
                                                                                                                                type="file"
                                                                                                                                class="form-control absoluteTypeFileBtn"
                                                                                                                                [ngClass]="{'invalid-control' : isSubmit && item.paramMandatory == 'ACTIVE' && item.paramValue == ''}">
                                                                                                                        </div>

                                                                                                                        <input
                                                                                                                            type="hidden"
                                                                                                                            [(ngModel)]="item.paramValue">
                                                                                                                    </ng-container>
                                                                                                                </ng-container>
                                                                                                                <ng-container
                                                                                                                    *ngIf="item.paramDefaultValueDTO != undefined">
                                                                                                                    <select
                                                                                                                        *ngIf="item.paramDatatype != 'Date'"
                                                                                                                        type="text"
                                                                                                                        class="form-control"
                                                                                                                        [ngClass]="{'invalid-control' : isSubmit && item.paramMandatory == 'ACTIVE' && item.paramValue == ''}"
                                                                                                                        [(ngModel)]="item.paramValue">
                                                                                                                        <option
                                                                                                                            *ngFor="let list of item.paramDefaultValueDTO">
                                                                                                                            {{list.paramValue}}
                                                                                                                        </option>
                                                                                                                    </select>
                                                                                                                </ng-container>

                                                                                                            </div>
                                                                                                        </ng-container>
                                                                                                        <ng-template
                                                                                                            #otherParam>
                                                                                                            <!-- <span [innerHtml]="item.paramValue"></span> -->
                                                                                                            <span
                                                                                                                *ngIf="item.paramDatatype == 'File' && !item.fileLink"
                                                                                                                class="value"
                                                                                                                [innerHtml]="item.paramValue"></span>
                                                                                                            <span
                                                                                                                *ngIf="item.paramDatatype != 'File' && !item.fileLink"
                                                                                                                class="value">{{item.paramValue}}</span>
                                                                                                            <!-- <a *ngIf="item.fileLink == true" href="{{item.paramValue}}" target="_blank"> Click to view </a> -->
                                                                                                            <a *ngIf="item.fileLink == true"
                                                                                                                href="javascript:void(0)"
                                                                                                                (click)="downloadFileBylink(item)">
                                                                                                                Click to
                                                                                                                view
                                                                                                            </a>
                                                                                                        </ng-template>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </ng-container>
                                                                                        </ng-container>

                                                                                    </tbody>
                                                                                </table>
                                                                            </div>

                                                                        </div>
                                                                    </div>

                                                                </div>


                                                            </div>
                                                        </tab>

                                                        <div class="col-12" *ngIf="repeatLeadData">
                                                            <div class="no-records" *ngIf="repeatLeadData.length == 0">
                                                                No Records Found
                                                            </div>
                                                        </div>

                                                    </div>
                                                </tabset>
                                            </div>
                                        </tab>


                                        <tab (selectTab)="getIvrcallLogs()" *ngIf="audience.mobile && ivrActivation">
                                            <ng-template tabHeading>
                                                <span>IVR</span>
                                            </ng-template>

                                            <div class="table-responsive table-height-scroll table-reports">
                                                <table class="table table-bordered">
                                                    <tbody *ngIf="ivrCallLogs && ivrCallLogs.length > 0">
                                                        <tr>
                                                            <th>Phone No.</th>
                                                            <th>Duration</th>
                                                            <th class="min_width_152">Date & Time</th>
                                                            <th>Type</th>
                                                            <th>Recording Url</th>
                                                            <th>Call Status</th>
                                                            <th class="min_width_152">Recording Duration</th>
                                                        </tr>
                                                        <tr *ngFor="let item of ivrCallLogs">
                                                            <td>{{item.contactNumber}}</td>
                                                            <td>{{item.callDuration}}</td>
                                                            <td>{{item.callDate}}</td>
                                                            <td>{{item.type}}</td>
                                                            <td>
                                                                <!-- <a href="{{item.recURL}}" target="_blank">
                                                                    Click to View
                                                                </a> -->
                                                                <audio controls>
                                                                    <source src="{{item.recURL}}" type="audio/ogg">
                                                                  </audio>
                                                                </td>
                                                            <td>{{item.callStatus}}</td>
                                                            <td>{{item.recDuration}}</td>
                                                        </tr>
                                                    </tbody>
                                                    <tfoot>
                                                        <tr *ngIf="!ivrCallLogs || ivrCallLogs.length == 0">
                                                            <td class="no-records" colspan="7">No Records Found</td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                        </tab>
                                    </div>
                                </tabset>
                            </div>
                        </div>

                        <div *ngIf="warnToOpenThirdPartyLink">
                            <div class="whatsAppConfigPopup">
                                <div class="forgot-password-modal">
                                    <div class="modal-header">
                                        <h4 class="modal-title pull-left">External Site</h4>
                                        <button type="button" class="close pull-right"
                                            (click)="warnToOpenThirdPartyLink = false">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>



                                    <div class="modal-body">
                                        <div class="form-group">
                                            <label class="control-label pt-0">You are being redirected away from
                                                cronberry to an external site.<br> Do you want to continue ?</label>
                                        </div>
                                        <div class="d-flex j-right">
                                            <button class="btn btn btn-dark redBtn m_r_15"
                                                (click)="warnToOpenThirdPartyLink = false">Go Back</button>
                                            <button class="btn btn btn-dark greenBtn"
                                                (click)="openToTab()">Continue</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <ng-container *ngIf="viewTemplateProps == true ">
                            <div class="modalPopupTemplate" *ngIf="viewTemplate">
                                <div class="modalInnerPopup">
                                    <div class="modal-header">
                                        <h4 class="modal-title pull-left" *ngIf="viewTemplate !='' ">
                                            {{viewTemplate.campaignName}}</h4>
                                        <button type="button" class="close pull-right" aria-label="Close"
                                            (click)="viewTemplateHide();">
                                            <span aria-hidden="true">×</span>
                                        </button>
                                    </div>
                                    <div class="modal-body" *ngIf="viewTemplate.campBody != ''"
                                        [innerHTML]="viewTemplate.campBody">
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                        <form [formGroup]="siteForm" *ngIf="showStatusWindow">
                            <div class="leadStatusBox ">
                                <div class="container-fluid">
                                    <div class="statusOuterBox">
                                        <ng-container *ngFor="let item of leadStatusList; let i=index">
                                            <div class="statusDevider" *ngIf="item.showState">
                                                <div [ngClass]="{'active': item.stageName == leadStatusDetail.paramValue}"
                                                    [class.hoverActive]="selectedStatus == i" class="statusInnerColmn"
                                                    id="outer_{{item.id}}">
                                                    <div class="form-check form-check-inline"
                                                        (change)="setSiteFormValidators(item.stageType)">
                                                        <input class="form-check-input" type="radio"
                                                            formControlName="status" id="status{{item.id}}"
                                                            value="{{item.stageName}}" (change)="activateStage(i,item)">
                                                        <label class="form-check-label text-capitalize"
                                                            for="status{{item.id}}"
                                                            (click)="activateStage(i,item)">{{item.stageName}}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group" *ngIf="statusType != 'WRONG_CONTACT'">
                                <label class="control-label required">Note</label>
                                <textarea trim="blur" rows="3" class="form-control"
                                    formControlName="remarks"></textarea>
                            </div>
                            <div class="form-row" *ngIf="statusType == 'CALL_LATER' || statusType == 'FOLLOW_UP'">
                                <div class="col-12 col-md-6" *ngIf="statusType == 'FOLLOW_UP'">
                                    <div class="form-group ">
                                        <label class="control-label pl-0 required">Lead Owner</label>
                                        <select *ngIf="!disableDropDown" class="form-control text-capitalize"
                                            [attr.disabled]="disableDropDown ? '' : null"
                                            formControlName="telecallerId">
                                            <option value="">Select</option>
                                            <option *ngFor="let item of telecallerList" value="{{item.id}}">
                                                {{item.userProfileName}}</option>
                                        </select>
                                        <p *ngIf="disableDropDown">Note* As you've selected auto assignment option for
                                            this lead status. Now leads will be auto assigned in round robin fashion
                                            after submission.</p>
                                    </div>
                                </div>
                                <div class="col-6 col-md-3">
                                    <div class="form-group">
                                        <label class=" control-label required">Date</label>
                                        <input type="text" placement="top right" formControlName="date" readonly
                                            class="form-control date-icon modal-space" [minDate]="minDate" bsDatepicker
                                            autocomplete="off"
                                            [bsConfig]="{showWeekNumbers: false,dateInputFormat: 'DD/MM/YYYY',containerClass:'dateLeft theme-green'}">
                                    </div>
                                </div>
                                <div class="col-6 col-md-3">
                                    <div class="form-group">
                                        <label class=" control-label required">Time</label>
                                        <div class="timepicker-position">
                                            <timepicker formControlName="time" minuteStep="16"></timepicker>
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div class="form-row">
                                <div class="col-md-12 text-right">
                                    <div class="text-right"><button type="button" class="btn btn-dark"
                                            [disabled]="isSubmitted" (click)="formSubmit()">Submit</button></div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>