import { NgModule } from "@angular/core";
import { QRCodeModule } from "angular2-qrcode";
import { CarouselModule, ModalModule } from "ngx-bootstrap";
import { DndModule } from "ngx-drag-drop";
import { BaseService } from "../../core/services/base.service";
import { ShareModule } from "../../shared/models/share.module";
import { CatalogueComponent } from "./catalogue.component";
import { CatalogueRouting } from "./catalogue.routing";
import { CatalogueService } from "./catalogue.service";
import { EditCatalogueComponent } from "./edit-catalogue.component";

@NgModule({
    imports: [
        CatalogueRouting,
        ModalModule.forRoot(),
        CarouselModule.forRoot(),
        ShareModule,
        DndModule,
        QRCodeModule
    ],
    declarations: [
        CatalogueComponent,
        EditCatalogueComponent
    ],
    exports: [
    ],
    providers:[
        BaseService,
        CatalogueService
    ]
})

export class CatalogueModule {

}