import { Component, OnInit, TemplateRef, OnDestroy, ViewChild } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../../../core/services/user.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { MasterService } from '../../../../core/services/master.service';
import { Router } from '@angular/router';
import { Global } from '../../../../shared/models/global.model';
import { noWhitespaceValidator } from '../../../../shared/models/no-whitespace-validator';
import { UserObj } from '../../../../core/modal/user';
import { AppConstants } from '../../../../constants/app.constants';
import { SpinnerService } from '../../../../core/services/spinner.service';

@Component({
    selector: 'app-edit-account',
    templateUrl: './edit-account.component.html',
    styleUrls: ['./edit-account.component.scss']
})
export class EditAccountComponent implements OnInit, OnDestroy {
    isEdit: boolean = false;
    emailEdit: boolean = false;
    otpLength: number = 6;
    countries: any = [];
    value: number = 0;
    editForm: FormGroup;
    modalRef: BsModalRef;
    currentUser: any;
    otp: any = '';
    isEmailButtonDisable: boolean;
    isButtonDisable: boolean;
    @ViewChild('template') template: TemplateRef<any>;
    constructor(
        private modalService: BsModalService,
        private toastr: ToastrService,
        private formBuilder: FormBuilder,
        private global: Global,
        private router: Router,
        private userService: UserService,
        private masterService: MasterService,
        private authenticationService: AuthenticationService,
        private spinner: SpinnerService,

    ) {
        
    }

    ngOnDestroy(){
        if(this.modalRef){
            this.modalRef.hide();
        }
    }

    gstPressing(event)
  {
    let getVal = event.target.value;
    this.editForm.controls.companyGstno.setValue(getVal.toUpperCase())
  }

    ngOnInit() {
        this.isEmailButtonDisable = false;
        this.isButtonDisable = false;
        this.loadCountries();
        this.loadUser();
    }

    signout(){
        this.authenticationService.logout();
        this.spinner.show();
        setTimeout(() => {
            this.spinner.hide()
            this.router.navigate(['/admin/sign-in'],{ queryParams:{type:'signout'}});
        }, 500);
    }

    htmlDecode (str) {        
        var div = document.createElement("div");
        div.innerHTML = str;        
        return div.textContent || div.innerText;
    }

    prepareForm() {
        if(this.currentUser && this.currentUser.companyAddress){
            this.currentUser.companyAddress = this.htmlDecode(this.currentUser.companyAddress)
        }
        if (this.currentUser.users.userRoleId != 1) {
            this.editForm = this.formBuilder.group({
                firstName: [this.currentUser.firstName, Validators.compose([Validators.required, noWhitespaceValidator, Validators.minLength(1), Validators.maxLength(74), Validators.pattern(this.global.AlphaPattern)])],
                lastName: [this.currentUser.lastName, Validators.compose([Validators.required, noWhitespaceValidator, Validators.minLength(2), Validators.maxLength(74), Validators.pattern(this.global.AlphaPattern)])],
                gender: [this.currentUser.gender, Validators.compose([Validators.required, noWhitespaceValidator, Validators.pattern(this.global.NumericPattern), Validators.min(0)])],
                email: [this.currentUser.users == undefined ? this.authenticationService.getUserData().email : this.currentUser.users.email, Validators.compose([Validators.required, noWhitespaceValidator, Validators.pattern(this.global.EmailPattern)])],
                phone: [this.currentUser.phone, Validators.compose([Validators.required, noWhitespaceValidator, Validators.minLength(7), Validators.maxLength(15), Validators.pattern(this.global.NumericPattern)])],
                term1: [false, Validators.required],
                term2: [false, Validators.required],
                sortBy:[this.currentUser.sortBy == undefined || this.currentUser.sortBy == "null"?'entry_date':this.currentUser.sortBy,Validators.compose([Validators.required, noWhitespaceValidator])],
       
            });
        }
        else {
            this.editForm = this.formBuilder.group({
                firstName: [this.currentUser.firstName, Validators.compose([Validators.required, noWhitespaceValidator, Validators.minLength(1), Validators.maxLength(74), Validators.pattern(this.global.AlphaPattern)])],
                lastName: [this.currentUser.lastName, Validators.compose([Validators.required, noWhitespaceValidator, Validators.minLength(2), Validators.maxLength(74), Validators.pattern(this.global.AlphaPattern)])],
                gender: [this.currentUser.gender, Validators.compose([Validators.required, noWhitespaceValidator, Validators.pattern(this.global.NumericPattern), Validators.min(0)])],
                email: [this.currentUser.users == undefined ? this.authenticationService.getUserData().email : this.currentUser.users.email, Validators.compose([Validators.required, noWhitespaceValidator, Validators.pattern(this.global.EmailPattern)])],
                phone: [this.currentUser.phone, Validators.compose([Validators.required, noWhitespaceValidator, Validators.minLength(7), Validators.maxLength(15), Validators.pattern(this.global.NumericPattern)])],
                companyName: [this.currentUser.companyName, Validators.compose([Validators.required, noWhitespaceValidator, Validators.minLength(5), Validators.maxLength(50),Validators.pattern(this.global.AlphaNumPatternWithAmpersand)])],
                companyGstno: [this.currentUser.companyGstno, Validators.compose([Validators.maxLength(20), Validators.pattern(this.global.gst_regex)])],
                companyAddress: [this.currentUser.companyAddress, Validators.compose([Validators.required, noWhitespaceValidator, Validators.minLength(10), Validators.maxLength(100), Validators.pattern(this.global.AlphaNumPatternWithAmpersand)])],
                pincode: [this.currentUser.pincode, Validators.compose([Validators.maxLength(10), Validators.pattern(this.global.NumericPattern)])],
                city: [this.currentUser.city, Validators.compose([Validators.required, noWhitespaceValidator, Validators.minLength(3), Validators.maxLength(20), Validators.pattern(this.global.AlphaNumPattern)])],
                state: [this.currentUser.state, Validators.compose([Validators.required, noWhitespaceValidator, Validators.minLength(3), Validators.maxLength(20), Validators.pattern(this.global.AlphaNumPattern)])],
                countryId: [this.currentUser.countryId, Validators.compose([Validators.required, noWhitespaceValidator, Validators.min(0), Validators.pattern(this.global.NumericPattern)])],
                term1: [false, Validators.required],
                term2: [false, Validators.required],
                sortBy:[this.currentUser.sortBy == undefined || this.currentUser.sortBy == "null"?'entry_date':this.currentUser.sortBy,Validators.compose([Validators.required, noWhitespaceValidator])],
            });
        }

        this.editForm.get('email').valueChanges.subscribe(email => {
            if (email != this.currentUser.users.email) {
                this.currentUser.EmailStatus = 'UNVERIFIED';
            } else {
                this.currentUser.EmailStatus = this.currentUser.users.emailVerified;
            }
        });
    }

    enableEmail() {
        this.emailEdit = true;
    }

    openModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template,{ backdrop: 'static'});
    }

    get ef() { return this.editForm.controls; }

    loadUser() {
        this.authenticationService.getUser().subscribe(data => {
            this.currentUser = data.data || {};
            if (data.status) {
                this.currentUser.EmailStatus = this.currentUser.users.emailVerified;
                this.prepareForm();
            } else {
                this.currentUser.users = {};
                this.currentUser.users.email = this.authenticationService.getUserData().email;
                this.currentUser.users.emailVerified = 'UNVERIFIED';
                this.toastr.error(data.error_msgs, 'Error');
            }
        });
    }

    loadCountries() {
        this.masterService.getCountries().subscribe( data => {
            if(data.status){
                this.countries = data.data;
            } else {
                this.toastr.error(data.error_msgs, 'Error');
            }
        });
    }

    editUser() {
        if (this.editForm.invalid) {
            this.toastr.error(this.global.MessageRequired); this.global.validateAllFormFields(this.editForm);
            return;
        }
        if (this.currentUser.EmailStatus!='VERIFIED') {
            this.toastr.error('Please Verify Email First');
            return;
        }
        if(this.ef.companyGstno){
        let gstNum = this.ef.companyGstno.value
        if(gstNum){
                var gst_regex = /^([0]{1}[1-9]{1}|[1-2]{1}[0-9]{1}|[3]{1}[0-7]{1})([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$/g
                if(gstNum !== gstNum.toUpperCase()){
                    this.toastr.error('GST number should be A-Z, 0-9');
                    return;
                }
                if(gst_regex.test(gstNum) == false){
                    this.toastr.error('Please enter valid GST number');
                    return;
                }
                }
            }

        let postForm: any = {};

        if (this.currentUser.users.userRoleId != 1) {
            postForm = {
                firstName: this.ef.firstName.value,
                lastName: this.ef.lastName.value,
                gender: this.ef.gender.value,
                phone: this.ef.phone.value,
                companyName: '',
                companyGstno: '',
                companyAddress: '',
                pincode: '',
                city: '',
                state: '',
                countryId: 0,
                accountType: 'Business',
                companyTeamsize: 0,
                sortBy:this.ef.sortBy.value,
            };
        } else {
            postForm = {
                firstName: this.ef.firstName.value,
                lastName: this.ef.lastName.value,
                gender: this.ef.gender.value,
                phone: this.ef.phone.value,
                companyName: this.ef.companyName.value,
                companyGstno: this.ef.companyGstno.value,
                companyAddress: this.ef.companyAddress.value,
                pincode: this.ef.pincode.value,
                city: this.ef.city.value,
                state: this.ef.state.value,
                countryId: this.ef.countryId.value,
                accountType: 'Business',
                companyTeamsize: 0,
                sortBy:this.ef.sortBy.value,
            };
        }

        if (this.currentUser.id != undefined) {
            postForm.id = this.currentUser.id;
        } else {
            if (!this.ef.term1.value) {
                this.toastr.error('Please Accept Terms & Conditions');
                return;
            }
        }

        this.isButtonDisable = true;
        this.userService.upsertUserProfile(postForm).subscribe(data => {
            if (data.status) {
                if (this.currentUser.id != undefined) {
                    this.toastr.success('User Updated');
                    this.router.navigate(['/admin/'+this.authenticationService.getLandingPage()]);
                    // if (this.authenticationService.getUserRole() == AppConstants.TELECALLER_ROLEID) {
                    //     this.router.navigate(['/admin/view-audience']);
                    // } else {
                    //     this.router.navigate(['/admin/dashboard']);
                    // }
                    this.authenticationService.getProfile('reload').then(data=>{ })
                } else {
                    localStorage.setItem('projectId', data.data.id);
                    this.toastr.success('User Created');
                    var userData:UserObj = {
                        // userId :  this.currentUser.users.userRoleId == AppConstants.ADMIN_ROLEID ? data.data.users.id : data.data.userId,
                        userId : this.currentUser.users.id,
                        email : this.currentUser.users.email,
                        projectId : data.data.id,
                        emailVerified : true,
                        accountCreated : true,
                        userRoleId : this.currentUser.users.userRoleId,
                        userRoleName : this.currentUser.users.userRoleName,
                    }
                    this.authenticationService.setUserdata(userData);
                    this.authenticationService.getProjectDetail();
                    // if (userData.userRoleId == AppConstants.TELECALLER_ROLEID) {
                    //     this.router.navigate(['/admin/view-audience/new']);
                    // } else {
                    //     this.router.navigate(['/admin/dashboard/new']);
                    // }
                }
                
            }
            else {
            this.toastr.error(data.error_msgs);
            }
        });
    }

    verifyEmailPopup() {
        if (this.otp == '') {
            this.toastr.error('Please Enter OTP');
            return;
        }
        if (this.otp.match(this.global.NumericPattern) == null ) {
            this.toastr.error('Invalid OTP');
            return;
        }
        if (this.otp.toString().length != this.otpLength) {
            this.toastr.error('Please Enter ' + this.otpLength + ' Digit OTP');
            return;
        }

        this.isEmailButtonDisable = true;
        this.userService.verifyEmailOTP({ email: this.editForm.controls.email.value, otp: this.otp }).subscribe(data => {
            this.otp = '';
            this.isEmailButtonDisable = false;
            if (data.status && data.data == 'Valid') {
                this.modalRef.hide();
                this.emailEdit = false;
                this.toastr.success('Email Verified Successfully');
                this.currentUser.EmailStatus = this.currentUser.users.emailVerified = "VERIFIED";
                this.currentUser.users.email = this.editForm.controls.email.value;
                this.authenticationService.userData.emailVerified = true;
                this.authenticationService.userData.email = this.editForm.controls.email.value;
            }
            else {
                this.toastr.error(data.error_msgs, 'Error');
            }
        });
    }

    resendEmail(type) {
        if(this.editForm.controls.email.invalid){
            return false;
        }
        this.userService.requestEmailOTP({ email: this.editForm.controls.email.value }).subscribe(data => {
            this.value = 60;
            if (data.status) {
                if(type == 'open'){
                    this.openModal(this.template);
                }
                this.toastr.success('OTP Sent');
            } else {
                this.toastr.error(data.error_msgs, 'Error');
            }
        });
    }

}
