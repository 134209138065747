import { Location } from '@angular/common';
import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ProjectService } from '../../../core/services/project.service';
import * as XLSX from 'xlsx';
import { SpinnerService } from '../../../core/services/spinner.service';
import { GalleryPopupComponent} from '../../admin/pages/campaign-gallery/gallery-popup.component';
declare const CKEDITOR;
@Component({
  selector: 'app-invoice-product-list',
  templateUrl: './invoice-product-list.component.html',
  styleUrls: ['./invoice-product-list.component.scss']
})
export class InvoiceProductListComponent implements OnInit {
  invoiceProdList:any;
  dataSize:any=20;
  page:any=1;
  totalDataSize:any;
  modalRef: BsModalRef;
  deleteProdId:any;
  prodSearch:any;
  addProdField: any = {};
  addProdForm:FormGroup;
  xlsUploaderForm:FormGroup;
  taxData:any;
  xlsSelector:any;
  actionTitle:any='Add Item'
  file:File = null;
  fileUrl:any;
  uploadXlsData:any;
  @ViewChild('deleteCnfModal') deleteCnfModal: TemplateRef<any>;
  @ViewChild('uploadexcel') uploadexcel: TemplateRef<any>;
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('xlsTable') xlsTable: ElementRef;
  @ViewChild('confirmUploadXl') confirmUploadXl: ElementRef;
  modalRefGallery: BsModalRef
  constructor(
    private projectService:ProjectService,
    private toaster: ToastrService,
    private modalService: BsModalService,
    private fb:FormBuilder,
    private location:Location,
    private spinner:SpinnerService
  ) { }

  ngOnInit() {
    this.xlsUploaderForm  = this.fb.group({
      xlsSelector:[null,[Validators.required]],
      file:[null,[]]
    })
    this.addProdForm = this.fb.group({
      name: [null, [Validators.required]],
      description: [null, []],
      rate: [null, [Validators.required]],
      hsn: [null, []],
      taxSelector: [null, [Validators.required]]
    });
    this.addProdField.taxSelector = 'Select Tax (If any)';
    this.getProdList();
    this.getTaxList();
  }

  openGalleryModal(){
		const initialState = {
            galleryType : 'all',
            modalRefGallery: this.modalRefGallery
        }
        this.modalRefGallery = this.modalService.show(GalleryPopupComponent,{
            initialState,
            backdrop: "static",
            class: "gallery-popup"
        });
        this.modalRefGallery.content.modalRefGallery = this.modalRefGallery;
        this.modalRefGallery.content.selectMediaEvent.subscribe(data => {
            console.log(data)
            this.addProdField.logo = data.originalFile
        });
	}

  xlsSelected(event)
  {
    if (event.target.files.length > 0) {
      if(event.target.files[0].size > 2000000){
        this.fileInput.nativeElement.value = '';
        this.toaster.error('Maximum file size should be 2 MB.')
         return;
      }
      this.file = event.target.files[0];
      this.xlsUploaderForm.get('file').setValue(this.file);
     // event.target.value = null;
    } 
  }
  

  downlodSampleXl()
  {
    const ws = XLSX.utils.table_to_sheet(this.xlsTable.nativeElement, { raw: true });
    let a = XLSX.utils.sheet_to_json(ws, { header: 1 });
    let worksheet = XLSX.utils.json_to_sheet(a, { skipHeader: true })
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, worksheet, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, 'SampleUploadProducts.xlsx');
    this.modalRef.hide();
    this.toaster.success('File Downloaded');
  }

  submitProdXls()
  {
    if (!this.xlsUploaderForm.controls.file.value) {
      this.toaster.error('Please Select Excel');
      return;
    }
    this.spinner.show();
    let formData: FormData = new FormData(); 
    formData.append('file', this.file);    
    this.projectService.uploadProductExcel(formData).subscribe(data=>{
      if(data.status)
      {
      this.spinner.show();
      this.uploadXlsData = data.data;
      this.modalRef.hide();
      this.modalRef = this.modalService.show(this.confirmUploadXl, {backdrop: 'static',keyboard:false });
      this.xlsUploaderForm.reset();
      this.getProdList();
      }
      else
      {
        this.toaster.error(data.error_msgs);
      }
    })
  }


  back()
  {
    this.location.back();
  }

  numberOnly(event): boolean {
    var valueOfInput = event.target.value;
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 46 || charCode > 46 && charCode < 48 || charCode > 57)) {
      return false;
    }
    if (valueOfInput.includes('.') == true) {
      var afterDot = valueOfInput.split('.')[1];
      if (afterDot.length > 2) {
        this.toaster.error('Only 3 numbers are allowed after point');
        return false;
      }
    }
    return true;

  }

  getProdList()
  {
    this.projectService.invoiceProdData(this.page,this.dataSize,{searchString:this.prodSearch}).subscribe(data=>{
      if(data.status)
      {
        this.invoiceProdList = data.data.productList;
        this.totalDataSize = data.data.size;
      }
      else
      {
        this.toaster.error(data.error_msgs);
      }
    },(error)=>{
      console.log(error);
    })
  }

  filterByStr()
  {
    this.getProdList();
  }

  confirmDelete()
  {
    this.projectService.removeInvoiceProd({id:this.deleteProdId}).subscribe(data=>{
      if(data.status)
      {
        this.toaster.success('Item removed');
        this.getProdList();
        this.modalRef.hide();
      }
      else
      {
        this.toaster.error(data.error_msgs);
        this.modalRef.hide();
      }
    },(error)=>{
      console.log(error);
    })
  }

  addprod() {

    if (this.addProdForm.valid) {
      if(this.addProdField.hsn)
      {
      if(this.addProdField.hsn.length < 4)
      {
        this.toaster.error('Invalid HSN');
        return;
      }
      }
      // if(this.addProdField.name){
      //   let alphaNumWithChar  = /^[a-z A-Z0-9_/-]*$/g
      //   if(!alphaNumWithChar.test(this.addProdField.name)){
      //       this.toaster.error('Product Name Should be Alphanumeric', 'Error');
      //       return;
      //   }
      // }
      // if(this.addProdField.description){
      //   let alphaNumWithChar  = /^[a-z A-Z0-9_/-]*$/g
      //   if(!alphaNumWithChar.test(this.addProdField.description)){
      //       this.toaster.error('Product Description Should be Alphanumeric', 'Error');
      //       return;
      //   }
      // }
      let formObjectToSubmit = {
        'taxMaster': { 'id': this.addProdField.taxSelector },
        'name': this.addProdField.name, 'description': this.addProdField.description,
        'rate': this.addProdField.rate, 'hsn': this.addProdField.hsn,'id':this.addProdField.id?parseInt(this.addProdField.id):null, logo:this.addProdField.logo
      }
      if (this.addProdField.taxSelector == 'Select Tax (If any)') {
        this.toaster.error('Please select tax');
        return;
      }
      this.projectService.addInvoiceProd(formObjectToSubmit).subscribe(data => {
        if (data.status) {
          this.toaster.success('Product Added');
          this.getProdList();
          this.resetProdAddForm();
          this.modalRef.hide();
        }
        else {
          this.toaster.error(data.error_msgs);
        }
      })
    }
    else {
      this.toaster.error('Fill all details');
    }

  }

  resetProdAddForm() {
    this.addProdField.name = '';
    this.addProdField.taxSelector = 'Select Tax (If any)';
    this.addProdField.description = '';
    this.addProdField.rate = '';
    this.addProdField.hsn = '';
  }


  deleteProdCnfrm(prodId)
  {
    this.deleteProdId = prodId;
    this.openModel(this.deleteCnfModal);
  }

  cancelDetete()
  {
    this.modalRef.hide();
  }

  openModel(event: TemplateRef<any>,action?,prodData?) {
    if(action == 'editProd')
    {      
      this.addProdField.name = prodData.name;
      this.addProdField.description = prodData.description;
      this.addProdField.rate = prodData.rate;
      this.addProdField.hsn = prodData.hsn;
      this.addProdField.taxSelector = prodData.taxMaster.id;
      this.addProdField.id = prodData.id;
      this.addProdField.logo = prodData.logo
    }
    this.modalRef = this.modalService.show(event, {backdrop: 'static',keyboard:false });
  }

  ProdListSize()
  {
      this.getProdList();
  }

  dataForPaging(event) {
    this.page = event;
    this.getProdList();
  }

  getTaxList() {
    this.projectService.getTaxData().subscribe(data => {
      if (data.status) {
        this.taxData = data.data;
      }
      else {
        this.toaster.error(data.error_msgs);
      }
    });
  }

}
