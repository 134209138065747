import { Component, ElementRef, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { DndDropEvent, DropEffect } from 'ngx-drag-drop';
import { Condition, CustomFormObj, DataModel, field, FormModel, LogicCondition, ParentValue, value } from './customForm.modal';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService, TabsetComponent } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ProjectService } from '../../core/services/project.service';
import { CustomFormService } from './customForm.service';
import { Global } from '../../../app/shared/models/global.model';
import { AppConstants } from '../../../app/constants/app.constants';
import { GalleryPopupComponent } from '../admin/pages/campaign-gallery/gallery-popup.component';
import { environment } from '../../../environments/environment';
import { Subject } from 'rxjs';
import { AttributeObj, ProjectDetailObj } from '../../core/modal/project';
import { DatePipe } from '@angular/common';
import { elementStyleProp } from '@angular/core/src/render3';
import { ThrowStmt } from '@angular/compiler';
import { css } from 'jquery';
import { ApiConstants } from '../../../app/constants/api.constants';

@Component({
    selector: 'customForm-app',
    templateUrl: './customForm.component.html',
    styleUrls: ['./customForm.component.scss'],
    styles: [
        "@import url('https://fonts.googleapis.com/css2?family=Roboto&family=Open+Sans&family=Lato&family=Roboto+Condensed&family=Source+Sans+Pro&family=Oswald&family=Raleway&family+Sans&family=PT+Serif&display=swap');"
    ],
    encapsulation: ViewEncapsulation.None
})

export class CustomFormComponent implements OnInit {
    value: value[] = [];
    success = false;
    fixedParams:any;
    disbaleName:boolean=false;
    linkRewrite = "";
    captchaSiteKey: string = environment.captchaKey;
    successMessage: string = '';
    fieldModels: Array<field> = [
        {
            type: "text",
            icon: "text-height.svg",
            label: "Text",
            description: "Enter your name",
            placeholder: "Enter your name",
            className: "form-control",
            subtype: "String",
            regex: "",
            handle: true,
            hint: "",


        },
        {
            type: "email",
            icon: "form-envelope.svg",
            required: true,
            label: "Email",
            description: "Enter your email",
            placeholder: "Enter your email",
            className: "form-control",
            subtype: "String",
            regex: "^[A-Za-z0-9]+[A-Za-z0-9._!#$%&'*+-/=?^_`{|}~]+@([A-Za-z0-9-]+[.]){1,2}[A-Za-z.]{2,10}$",
            errorText: "Please enter a valid email",
            handle: true,
            hint: "",


        },
        {
            type: "phone",
            icon: "form-phone.svg",
            label: "Phone",
            description: "Enter your phone",
            placeholder: "Enter your phone",
            className: "form-control",
            subtype: "String",
            regex: "^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$",
            errorText: "Please enter a valid phone number",
            handle: true,
            hint: "",


        },
        {
            type: "text",
            icon: "pincode.png",
            label: "Pincode",
            description: "Enter your pincode",
            placeholder: "Enter your pincode",
            className: "form-control",
            subtype: "String",
            regex: "",
            errorText: "Please enter a valid pincode number",
            handle: true,
            hint: "",
            fieldType:"pincode",


        },
        {
            type: "number",
            label: "Number",
            icon: "form-number1.svg",
            description: "Age",
            placeholder: "Enter your age",
            className: "form-control",
            value: "",
            subtype: "Numeric",
            min: null,
            max: null,
            hint: "",


        },
        {
            type: "textarea",
            icon: "form-text-width.svg",
            label: "Textarea",
            placeholder: "Message",
            subtype: "String",
            hint: "",


        },
        {
            type: "checkbox",
            required: true,
            label: "Checkbox",
            icon: "check-square.svg",
            description: "Checkbox",
            inline: true,
            subtype: "String",
            parentAttributeValue: [],
            parentAttributeName: "",
            parent: false,
            hint: "",
            values: [
                {
                    label: "Option 1",
                    value: "option-1"
                },
                {
                    label: "Option 2",
                    value: "option-2"
                }
            ],

        },
        {
            type: "radio",
            icon: "form-list-ul.svg",
            label: "Radio",
            description: "Radio boxes",
            subtype: "String",
            parent: false,
            parentAttributeValue: [],
            parentAttributeName: "",
            hint: "",
            values: [

                {
                    label: "Option 1",
                    value: "option-1"
                },
                {
                    label: "Option 2",
                    value: "option-2"
                }
            ],

        },
        {
            type: "autocomplete",
            icon: "hand-pointer.svg",
            label: "Select",
            description: "Select",
            placeholder: "Select",
            className: "form-control",
            subtype: "String",
            parent: false,
            parentAttributeValue: [],
            parentAttributeName: "",
            hint: "",

            values: [
                {
                    label: "Option 1",
                    value: "option-1"
                },
                {
                    label: "Option 2",
                    value: "option-2"
                },
                {
                    label: "Option 3",
                    value: "option-3"
                }
            ],

        },
        {
            type: "multiselect",
            icon: "hand-pointer.svg",
            label: "MultiSelect",
            description: "MultiSelect",
            placeholder: "MultiSelect",
            className: "form-control",
            subtype: "String",
            parent: undefined,
            parentAttributeValue: [],
            parentAttributeName: "",
            hint: "",

            values: [
                {
                    label: "Option 1",
                    value: "option-1"
                },
                {
                    label: "Option 2",
                    value: "option-2"
                },
                {
                    label: "Option 3",
                    value: "option-3"
                }
            ],

        },
        {
            type: "date",
            icon: "calendar-day2.svg",
            label: "Date",
            placeholder: "Date",
            className: "form-control",
            subtype: "Date",
            hint: "",


        },
        {
            type: "file",
            icon: "upload2.svg",
            label: "Upload file",
            className: "form-control",
            subtype: "String",
            fileType: "all",
            bgColor: "#007BFD",
            textColor: "#FFFFFF",
            hint: "",
            defaultHint: "Allowed file types: .xlsx, .xls, .doc, .docx, .ppt, .pptx, .txt, .pdf, .zip, .csv, .png, .jpg, .gif, .svg"
        }, 
        {
            type: "location",
            icon: "location.svg",
            label: "Location",
            bgColor: "#007BFD",
            textColor: "#FFFFFF",
            className: 'right',
            btnSize: 'btn-md',
            btnWidth: '',
            subtype:'string'
        },
        {
            type: "link",
            icon: "link-small.svg",
            label: "Link",
            className: '',
            link:'',
        },
        {
            type: "button",
            icon: "form-paper-plane.svg",
            label: "Submit",
            bgColor: "#007BFD",
            textColor: "#FFFFFF",
            className: 'right',
            btnSize: 'btn-md',
            btnWidth: ''
        },
       
    ];


    dataModel = new DataModel();

    currentIndex = 0;

    multiStep: Array<FormModel> = [];


    report = false;
    reports: any = [];
    bgColorValue: any = '';
    modalRefGallery: BsModalRef;
    inputParams: AttributeObj[] = [];
    mappingInputParams: AttributeObj[] = [];
    formbuilderInput: CustomFormObj;
    isSubmit: boolean = false;
    allFields = [];
    selectedFieldsValues = [];
    formId: number;
    isUpdate: boolean = false;
    modalRef: BsModalRef;
    fileAddCount: number = 0;
    submitButtonCount: number = 0;
    maxfileAdd: number = 10;
    logicCondition: LogicCondition = new LogicCondition();
    @ViewChild('formOutPut') formOutPut: ElementRef;
    @ViewChild('formbuilderTop') formbuilderTop: ElementRef;
    @ViewChild('deleteConfirm') deleteConfirm: TemplateRef<any>;
    @ViewChild('deleteConfirmSteps') deleteConfirmSteps: TemplateRef<any>;
    @ViewChild('logic') logicModal: TemplateRef<any>;
    currentItem: field;
    selectedLogicNgName: string = "";
    selectedLogicValue = "";
    selectedLogicOperator = "=="
    conditionalOperator = "";
    selectedOutcome = "";
    logicModalType = "field";
    urlError:any=false;
    ckeditorData: Subject<string> = new Subject();
    config: any;
    linkError:any=false;
    isFieldListShow: boolean = false;
    projectDetails: ProjectDetailObj;
    minDate: any = new Date();
    loading:any=false;
    multiselectHeight:any='40px'
    userFormDisplay:any='none';
    @ViewChild('pageTabs') pageTabs: TabsetComponent;
    @ViewChild('bannerImage') bannerImage:ElementRef;
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private modalService: BsModalService,
        private toastr: ToastrService,
        private projectService: ProjectService,
        private customFormService: CustomFormService,
        public global: Global,
        private datePipe: DatePipe,
    ) { }

    ngOnInit() {
        this.config = this.global.CKEditorConfig(150, 'customform');
        this.route.params.subscribe(params => {
            if (params.id) {
                this.formId = params.id;
                this.isUpdate = true;
                this.getFormData();
            }
        });
        this.getInputParams();
        this.dataModel.multiStep.push(new FormModel());
       


        this.projectDetails = this.projectService.projectDetails;
        var defaultColor = this.dataModel.stepsActiveColor != undefined ? this.dataModel.stepsActiveColor : this.dataModel.stepsDefaultColor;
                
        var styleElem = document.head.appendChild(document.createElement("style"));
        
        styleElem.innerHTML = '.arrow .nav-item a.active .after{border-left-color:'+ defaultColor +';} .arrow .nav .nav-link.active {background:'+ defaultColor +'; border-color:'+ defaultColor +';} .wizardVertical .nav-link::before{border-left-color:'+ this.dataModel.stepsDefaultColor +' !important;} .wizardVertical .nav-link.active::before{border-left-color:'+ this.dataModel.stepsActiveColor +' !important;}'            
        
    }
    

    emailRegexAdd(item,event){
        let eventVal = event.target.value;
        let checkParam = this.mappingInputParams.find(x=>x.paramKey == eventVal);
        if(checkParam){
            if(checkParam.paramDatatype == 'Email'){
                item.regex = "^[A-Za-z0-9]+[A-Za-z0-9._!#$%&'*+-/=?^_`{|}~]+@([A-Za-z0-9-]+[.]){1,2}[A-Za-z.]{2,10}$";
            } else{
                item.regex = "";
            }
        } else if(checkParam == undefined){
            item.regex = "";
        }
    }


    openGalleryModal(type) {
        const initialState = {
            galleryType: 'image',
            modalRefGallery: this.modalRefGallery
        }
        this.modalRefGallery = this.modalService.show(GalleryPopupComponent, {
            initialState,
            backdrop: "static",
            class: "gallery-popup"
        });
        this.modalRefGallery.content.modalRefGallery = this.modalRefGallery;
        this.modalRefGallery.content.selectMediaEvent.subscribe(data => {
            this.getMedia(data, type);
        });
    }

    getMedia(item, type) {
        if (type == 'bg')
            this.dataModel.multiStep[this.currentIndex].theme.backgroundImage = item.originalFile;
        else if(type == 'bodybg')
        {
            this.dataModel.bodybackgroundImage = item.originalFile; 
        }
        else if(type == 'AllInOneBanner')
        {
            this.dataModel.allInOneBannerImage = item.originalFile; 
        }
        else
        {
            this.dataModel.multiStep[this.currentIndex].theme.bannerImage = item.originalFile;
        }
    }

    onDragged(item: any, list: any[], effect: DropEffect) {

        if (effect === "move") {
            const index = list.indexOf(item);
            let data = list.splice(index, 1);
        }
    }

    enableParent(item: field) {
        if (item.parent) {
            item.parent = false;
            item.parentAttributeValue = [];
            item.parentAttributeName = "";
        } else {
            item.parent = true;
        }
    }

    changeOptions(event,item,oldvalue){
        for (var n = 0; n < this.dataModel.multiStep.length; n++) {
            for (var i = 0; i < this.dataModel.multiStep[n].attributes.length; i++) {
                if(this.dataModel.multiStep[n].attributes[i].parent &&
                    this.dataModel.multiStep[n].attributes[i].parentAttributeName == item.ngName){
                    if(oldvalue != ""){
                    const index = this.dataModel.multiStep[n].attributes[i].parentAttributeValue.findIndex(prop => prop.label === oldvalue);
                    this.dataModel.multiStep[n].attributes[i].parentAttributeValue.splice(index,1);
                    }
                    if(event != ''){
                    this.dataModel.multiStep[n].attributes[i].parentAttributeValue.push
                    ({ label: event, values: [] });
                    }

                }
            }
        }
    }

    selectedParentElement(seletedValue: string, item: field) {
        item.parentAttributeName = seletedValue;
        item.parentAttributeValue = [];
        for (var n = 0; n <= this.currentIndex; n++) {
            for (var i = 0; i < this.dataModel.multiStep[n].attributes.length; i++) {
               
                                       
                if (this.dataModel.multiStep[n].attributes[i].ngName == seletedValue) {
                    if (this.dataModel.multiStep[n].attributes[i].parent) {
                        if (this.dataModel.multiStep[n].attributes[i].parentAttributeValue.length > 0) {
                            for (var j = 0; j < this.dataModel.multiStep[n].attributes[i].parentAttributeValue.length; j++) {
                                if (this.dataModel.multiStep[n].attributes[i].parentAttributeValue[j].values.length > 0) {
                                    for (var k = 0; k < this.dataModel.multiStep[n].attributes[i].parentAttributeValue[j].values.length; k++) {
                                        {
                                            item.parentAttributeValue.push
                                                ({ label: this.dataModel.multiStep[n].attributes[i].parentAttributeValue[j].values[k].value, values: [{ label: "option1", value: "option1" }] });

                                        }
                                    }
                                }

                            }
                        }
                    } else {
                        if (this.dataModel.multiStep[n].attributes[i].values.length > 0) {
                            for (var j = 0; j < this.dataModel.multiStep[n].attributes[i].values.length; j++) {
                                item.parentAttributeValue.push
                                    ({ label: this.dataModel.multiStep[n].attributes[i].values[j].value, values: [{ label: "option1", value: "option1" }] });
                            }
                        }
                    }
                }
            }

        }

    }

    

    countItemElementByType(type: string) {
        let count = 0;
        this.dataModel.multiStep.forEach(step => {
            step.attributes.forEach(item => {
                if (item.type === type)
                    count++;
            });
        });
        return count;
    }


    copyLink() {
        this.global.copyText(ApiConstants.CUSTOM_FORM_LINK+this.linkRewrite);
        this.toastr.success(AppConstants.COPIED_CLIPBOARD);
    }

    onDrop(event: DndDropEvent, list?: any[]) {        

        if (event.data.type == "button" && this.countItemElementByType("button") >= 1 && event.dropEffect !== "move") {
            this.toastr.error("Only single submit button is allowed to add. Seems you have added it already.");
            return;

        }
        if (event.data.type == "location" && this.countItemElementByType("location") >= 1 && event.dropEffect !== "move") {
            this.toastr.error("Only single location button is allowed to add. Seems you have added it already.");
            return;
        }

        if (event.data.type == "email" && this.countItemElementByType("email") >= 1 && event.dropEffect !== "move") {
            this.toastr.error("Only single email field is allowed to add. Seems you have added it already.");
            return;
        }

        if (event.data.type == "phone" && this.countItemElementByType("phone") >= 1 && event.dropEffect !== "move") {
            this.toastr.error("Only single phone number field is allowed to add. Seems you have added it already.");
            return;
        }

        if (event.data.type == "file" && this.countItemElementByType("file") >= 10 && event.dropEffect !== "move") {
            this.toastr.error("You have added enough(10) file upload fields. You can't add more.");
            return;
        }

        if (list && (event.dropEffect === "copy" || event.dropEffect === "move")) {
            this.appendField(event.data, list, event.index, event.dropEffect);
        }
    }

    addSteps() {
        let formModel = new FormModel();
        formModel.stepName = "";
        this.dataModel.multiStep.push(formModel);
    }

    appendField(item, list, i, dropEffect?) {        
        var data = JSON.parse(JSON.stringify(item));        
        if (dropEffect === "copy" && data.type === 'file' && this.fileAddCount >= this.maxfileAdd) return;
        
        if (data.type == "radio" || data.type == 'checkbox' || data.type == 'autocomplete' || data.type == 'location' || data.type == 'multiselect') {
            this.value.push({ label: "", value: "" })
            data.valueIndex = this.value.length - 1
        }        
       // data.name = '';


        if (data.type == "button") {
            this.dataModel.button = data;
        }

        if (data.type == "location") {
            this.dataModel.location = data;
        }

        if (data.type == "email" && this.dataModel.multiStep[this.currentIndex].attributes.find(x => x.name == 'email') == undefined) {
            data.name = 'email';
        }
        if (data.type == "phone" && this.dataModel.multiStep[this.currentIndex].attributes.find(x => x.name == 'mobile') == undefined) {
            data.name = 'mobile';
        }
        data.ngName = data.type + new Date().getTime();

        let index = list.length == 1 ? list.length+1 : i;
        if (typeof index === "undefined") {
            index = list.length;
        }

        if(data.fieldType == "pincode"){
            let checkToAdd = true
            this.dataModel.multiStep.forEach(steps=>{
                steps.attributes.forEach(data=>{
                    if(data.name == "state" || data.name == "city" || data.name == "country"){
                        this.toastr.error(`Can't use pincode field because you have already used ${data.name}`);
                        checkToAdd = false
                    }
                })
            });
            if(checkToAdd == true){
                data.name = "pincode";
                list.splice(index, 0, data);
        //         let addFieldWithPincode = 
        //     [{
        //         className: "form-control",
        //         description: "Enter your city",
        //         errorText: "Please enter a valid city name",
        //         handle: true,
        //         hint:"",
        //         label: "City",
        //         ngName: data.type + new Date().getTime(),
        //         placeholder: "Enter your city",
        //         regex: "",
        //         subtype:"String",
        //         type: "text",
        //         name:"city"
        //     },
        //     {
        //         className: "form-control",
        //         description: "Enter your state",
        //         errorText: "Please enter a valid state name",
        //         handle: true,
        //         hint:"",
        //         label: "State",
        //         ngName: data.type + new Date().getTime(),
        //         placeholder: "Enter your state",
        //         regex: "",
        //         subtype:"String",
        //         type: "text",
        //         name:"state"
        //     },
        //     {
        //         className: "form-control",
        //         description: "Enter your country",
        //         errorText: "Please enter a valid country name",
        //         handle: true,
        //         hint:"",
        //         label: "Country",
        //         ngName: data.type + new Date().getTime(),
        //         placeholder: "Enter your country",
        //         regex: "",
        //         subtype:"String",
        //         type: "text",
        //         name:"country"
        //     }
        // ]

        // addFieldWithPincode.forEach((elem,elemIndex)=>{
        //     list.splice(index+elemIndex, 0, elem);   
        // })
            }
        } else{
            list.splice(index, 0, data);      
        }
          
    }

    addValue(values, i,item) {
        if ((this.value[i].label == "" || this.value[i].value == "")
            || (this.value[i].label == undefined || this.value[i].value == undefined)) {
            this.toastr.error("Fields are mandatory");
        } else {
            values.push(this.value[i]);
            this.changeOptions(this.value[i].value,item,'');
            this.value[i] = { label: "", value: "" };
        }
    }

    addParentValue(item, i, label, value,items) {
        if (label.trim() == "" || value.trim() == "") {
            this.toastr.error("Fields are mandatory");
        } else {
            item.values.push({ label: label.trim(), value: value.trim() });
            this.changeOptions(value.trim(),items,'');
            // this.changeOptions(this.value[i].value,item,'');
        }
    }


    removeField(item, i) {
        let error: boolean = false;
        if (item.type === 'file') this.fileAddCount--;
        this.dataModel.multiStep.forEach(element => {
            element.attributes.forEach(field => {
                if (field.parentAttributeName == item.ngName) {
                    error = true;

                }
            })
        })
        if (error) {
            this.toastr.error("You can't remove this field. As it is being used as parent for some other field like ");
        } else {
            this.dataModel.multiStep[this.currentIndex].attributes.splice(i, 1);
        }
    }

    checkAttributeMapped(name){
        for (var i = 0; i < this.dataModel.multiStep.length; i++) {
            for (let j = 0; j < this.dataModel.multiStep[i].attributes.length; j++) {
                if(this.dataModel.multiStep[i].attributes[j].name == name){
                    return true;
                    break;
                }
            }
        }
        return false;
    }

    updateForm() {
        
        this.loading = true;

        for(let i=0; i<this.dataModel.multiStep.length;i++)
        {
            for(let j=0; j<this.dataModel.multiStep[i].attributes.length;j++)
            {
                if(this.dataModel.multiStep[i].attributes[j].type == 'link')
                {
                    if(this.dataModel.multiStep[i].attributes[j].link == '')
                    {
                        this.toastr.error("Link url can't be empty");
                        this.loading = false;
                        return;
                    }
                    if(this.dataModel.multiStep[i].attributes[j].linkText == '' || this.dataModel.multiStep[i].attributes[j].linkText == undefined || this.dataModel.multiStep[i].attributes[j].linkText == null)
                    {
                        this.toastr.error("Link label can't be empty");
                        this.loading = false;
                        return;
                    }
                }
            }
        }

      
        if(this.dataModel.allInOneBannerImage != "")
        {
            var width = this.bannerImage.nativeElement.offsetWidth;
            var height = this.bannerImage.nativeElement.offsetHeight;
            this.dataModel.bannerHeight = height+'px';
            this.dataModel.bannerWidth = width+'px';
        }

        var redirecUrl = this.dataModel.redirectUrl;
        if(redirecUrl != "")
        {
        var httpFinder = redirecUrl.includes("http://");
        var httpsFinder = redirecUrl.includes("https://");
        if(httpFinder == false && httpsFinder == false)
        {
            this.urlError = true;
            this.loading = false;
            return;
        }
        else
        {
            this.urlError = false;
        }

        }
        
        this.isSubmit = true;
        this.dataModel.name = this.dataModel.name != undefined ? this.dataModel.name.trim() : '';
        this.dataModel.successMessage = this.dataModel.successMessage != undefined ? this.dataModel.successMessage.trim() : '';
        if (this.dataModel.name == '') {
            this.toastr.error("Form name is mandatory field.");
            this.loading = false;
            return;
        }
        if (this.dataModel.name) {
            let alphaNumWithChar  = /^[a-z A-Z0-9_/-]*$/g
            if(!alphaNumWithChar.test(this.dataModel.name)){
                this.toastr.error('Form Name Should be Alphanumeric', 'Error');
                this.loading = false;
                return;
            }
        }
        let fieldsError = false;
        this.dataModel.multiStep.forEach((element, index) => {
            if (element.attributes.length == 0) {
                fieldsError = true;
                this.loading = false;
                this.toastr.error("Please add field on step " + index+1);
            }
        });
        if (fieldsError) {
            this.loading = false;
            return;
        } else {
            var isButtonAdded = false;
            var emailAttribute = false;
            var phoneAttribute = false;
            for (var i = 0; i < this.dataModel.multiStep.length; i++) {
                for (let j = 0; j < this.dataModel.multiStep[i].attributes.length; j++) {
                    if (this.dataModel.multiStep[i].attributes[j].type == 'button') isButtonAdded = true;
                    
                    if(this.dataModel.multiStep[i].attributes[j].type == "link")
                    {
                        var linkUrl = this.dataModel.multiStep[i].attributes[j].link;
                        if(linkUrl != "")
                        {
                        var httpFinderLink = linkUrl.includes("http://");
                        var httpsFinderLink = linkUrl.includes("https://");
                        if(httpFinderLink == false && httpsFinderLink == false)
                        {
                            this.linkError = true;
                            this.loading = false;
                            return;
                        }
                        else
                        {
                            this.linkError = false;
                        }
                        }
                    }
                    
                    if(this.dataModel.multiStep[i].attributes[j].type != 'button' && this.dataModel.multiStep[i].attributes[j].type != 'link')
                    {
                    if (this.dataModel.multiStep[i].attributes[j].name == '' || this.dataModel.multiStep[i].attributes[j].name == undefined) {
                        this.dataModel.multiStep[i].attributes[j].toggle = true;
                        this.currentIndex = i;
                        this.toastr.error("Please select mapping.");
                        this.loading = false;
                        return
                    }
                    }
                    if (this.dataModel.multiStep[i].attributes[j].name == 'email') {
                        emailAttribute = true;
                    }
                    if (this.dataModel.multiStep[i].attributes[j].name == 'mobile') {
                        phoneAttribute = true;
                    }
                }
            }
            if (emailAttribute == true || phoneAttribute == true) {
            } else {
                this.toastr.error("At least one form field should map to email or phonenumber");
                this.loading = false;
                return;
            }
            if (!isButtonAdded) {
                this.toastr.error("Please add submit button.");
                this.loading = false;
                return
            }
        }

        

        var style = this.getCustomiseStyle();
        var javascript = this.getCustomiseJavscript();
        //var style="";
        var html: string = this.formOutPut.nativeElement.innerHTML;
        
        html = html.replace(/<!--.*?-->/sg, '').replace(/data-name/gi, 'name');
        html = '<div class="cr-custom-form">' + style + html + '</div>' + "<script>" + javascript + "</script>";
        html = html +  this.dataModel.userJavascript;
        var startDate = (this.datePipe.transform(this.dataModel.startDate, "yyyy-MM-dd") + ' ' + this.datePipe.transform(this.dataModel.startDateTime, "HH:mm:ss"));
        startDate = startDate.replace('T',' ');
        if(this.dataModel.endDate)
        {
        var  endDate = this.datePipe.transform(this.dataModel.endDate, "yyyy-MM-dd") + ' ' + this.datePipe.transform(this.dataModel.endDateTime, "HH:mm:ss");
        }
        else
        {
        var  endDate = (this.datePipe.transform(new Date(new Date().setFullYear(new Date().getFullYear() + 1)), "yyyy-MM-dd") + ' ' + this.datePipe.transform(this.dataModel.endDateTime, "HH:mm:ss"));
        }
        endDate = endDate.replace('T',' ');
        
        this.formbuilderInput = {
            isPublic: this.dataModel.isPublic,
            id: this.isUpdate ? this.formId : 0,
            name: this.dataModel.name,
            expireTime: this.dataModel.expireTime,
            json: JSON.stringify(this.dataModel),
            html: html,
            height: this.formOutPut.nativeElement.offsetHeight + 75 + 40,
            startDate: startDate,
            endDate: endDate
        }
        
        this.customFormService.saveCustomForm(this.formbuilderInput).subscribe(data => {
            if (data.status) {
                this.loading = false;
                this.toastr.success(data.data, 'Success');
                this.customFormService.customFormList = undefined;
                this.router.navigate(['/admin/project-integration/Forms/Custom Forms']);
            } else {
                this.loading = false;
                this.toastr.error(data.error_msgs, 'Error');
            }
        });
    }


    bannerAlignAction(e)
    {
        this.dataModel.bannerAlign = e;
    }

    tracedBanner()
    {
        this.dataModel.bannerAlign = "";  
        this.dataModel.bannerHeight= "";  
        this.dataModel.bannerWidth = "";    
    }

    getCustomiseStyle(): string {
        
        var bannerAlign = this.dataModel.bannerAlign != undefined && this.dataModel.bannerAlign != '' ? this.dataModel.bannerAlign : "start";
        var defaultColor = this.dataModel.stepsActiveColor != undefined ? this.dataModel.stepsActiveColor : '#cd0141';
        var txtColor = this.dataModel.stepsDefaultColor != undefined ? this.dataModel.stepsDefaultColor : '#f4f7f9';
        var bannerHeight = this.dataModel.bannerHeight != undefined && this.dataModel.bannerHeight != "" ? this.dataModel.bannerHeight : 'none';   
        var bannerWidth  = this.dataModel.bannerWidth != undefined && this.dataModel.bannerWidth != "" ? this.dataModel.bannerWidth : '100%'; 
        let fontFamilySet = new Set();
        let  backgroundBody = "body{ background-size: cover; background-color:" + this.dataModel.bodyBackground + ";background-image:url('" + this.dataModel.bodybackgroundImage + "');}" ;
        
        var style = "body,html{font-family:'" + 
        this.dataModel.multiStep[0].theme.fontFamily + 
        "'; font-size:" + this.dataModel.multiStep[0].theme.fontSize + "; color:" +
         this.dataModel.multiStep[0].theme.textColor + "} .steps{ text-align:center;}";
        style = style + ".nav-item a.nav-link{background-color:"+ this.dataModel.stepsDefaultColor+" !important } .locateMsg{display: none; vertical-align:middle; color: #fff; margin-left: 10px; background: green; width: 20px; height: 20px; line-height: 19px; text-align: center; border-radius: 50%;} .pinCodeDetailBtn{position: absolute; top: 1px; right: 0;} #locality{display:none}"  ;
        style = style + ".nav-item a.active{background-color:"+ defaultColor +"  !important } .preview-image{display:flex; justify-content:"+ bannerAlign +"}"  ;
        style = style + ".nav-item a .after {border-left-color:"+ this.dataModel.stepsDefaultColor+" !important }"  ;
        style = style + ".nav-item a .before {border-bottom-color:"+ this.dataModel.stepsDefaultColor+" !important ; } .preview-image img{max-width:"+ bannerWidth +"; max-height:"+ bannerHeight +"; width:100%; height:100%;}"  ;
        style = style + ".nav-item a .before {border-top-color:"+ this.dataModel.stepsDefaultColor+" !important ; } .nav-item a.active .after{border-left-width:32px;}"  ;
        style = style + ".nav-item a.active  .after {border-left-color:"+ defaultColor +" !important; border-left-width:31px; } .wizardMain .nav-link.active::before{content:''; border-bottom: 27px solid transparent; border-top: 27px solid transparent; display: block; height: 0;  margin-top: -38px; position: absolute; top: 71%; width: 0; border-left: 15px solid "+ defaultColor +";border-bottom-color:"+ defaultColor +" !important; border-top-color:"+ defaultColor +"!important; border-left-color:"+ this.dataModel.stepsDefaultColor +";left:-15px;}"  ;
        style = style + ".nav-item a.active  .before {border-bottom-color:"+ this.dataModel.stepsDefaultColor+" !important ; } .arrow>.nav .nav-link.active{color:"+ txtColor +";} .wizardMain .nav-item:first-child .nav-link.active::before{left:0 !important;}"  ;
        style = style + ".nav-item a.active  .before {border-top-color:"+ this.dataModel.stepsDefaultColor+" !important ; } .wizardMain .stepContent{line-height:55px; margin-top:0;}"  ;
        style = style + ".stepsMain .arrow > .nav .nav-link.active::after {display: block; content: ''; position: absolute; left: 100%; top: 20px; width: 40px; height: 7px;  border: none !important; margin: 0; animation: stepSlide 3s infinite;background:"+ this.dataModel.stepsActiveColor +";}"  ;
        style = style + ".stepsMain .arrow > .nav .nav-link.active { width: 50px; height: 50px; line-height: 50px; border-radius: 50%;background:"+ this.dataModel.stepsActiveColor +"!important; border: 1px solid"+ this.dataModel.stepsActiveColor +";} .prev.btn-cont .btn{ background:"+ this.dataModel.stepsActiveColor +";}"  ;
        style = style + ".custom-form-block .form-control.custom-file{overflow:visible;} .arrow_down_multiselect{position:absolute;top:4px;right:10px;z-index:0;} .related_parent_box{position:relative;} .select2-results__option--selected {display: none;} .select2-container{width:100% !important;} #recaptcha-image{width: 53%; height: 72%;border-radius: 2px;} .m_b_10{margin-bottom:10px;} .refresh_captcha{display: inline-block; margin-left: 10px; height: 65%;} .d-none{display:none;}"
        // New wizardVerticalMain style is here

        style = style + ".wizardVerticalMain {display: flex; justify-content: center; padding:30px 0;} .wizardVerticalMain div.arrow,.wizardVerticalMain form#crCustomForm{width:100%;}  .wizardVerticalMain .arrow> .nav{display: inline-block;width: 100%; background:none; border:none;} .wizardVerticalMain .arrow{max-width: 250px;} .wizardVerticalMain .arrow .nav .before , .wizardVerticalMain .arrow .nav .after{ display:none} .wizardVerticalMain .arrow>.nav a.nav-link.active:before{content: ''; position: absolute; left: 100%; height: 98%; width: 30px; top: 0; border-top: 23px solid transparent; border-bottom: 22px solid transparent; border-left: 16px solid "+ this.dataModel.stepsActiveColor +" !important;}";
        style = style + ".wizardVerticalMain .arrow>.nav .nav-link:before{content: ''; position: absolute; left: 100%; height: 98%; width: 30px; top: 0; border-top: 23px solid transparent; border-bottom: 22px solid transparent; border-left: 16px solid "+ this.dataModel.stepsDefaultColor +" !important;} .wizardVerticalMain .arrow>.nav .nav-link{min-height:45px; padding-left:0; line-height:45px; cursor:pointer;} .wizardVerticalMain .stepContent{ margin-top:0; }  .wizardVerticalMain .arrow .nav a.nav-link.active:after{content: ''; position: absolute; left: 0; height: 98%; width: 30px; top: 0; border-top: 23px solid transparent; border-bottom: 22px solid transparent; border-left: 16px solid #fff !important;}"
        style = style + ".wizardVerticalMain .arrow .nav>li{margin-bottom:10px;} .wizardVerticalMain .custom-form-block{ margin:0; position:relative; box-shadow:none; padding-top:25px;} .wizardVerticalMain .arrow { position: fixed; overflow: auto; max-height: 90%; left: 10px; top: 30px; z-index:20; } .wizardVerticalMain .steps{ display:block !important;margin-bottom:25px;position:relative;}  .wizardVerticalMain .arrow>.nav .nav-link:after{content: ''; position: absolute; left: 0; height: 98%; width: 30px; top: 0; border-top: 23px solid transparent; border-bottom: 22px solid transparent; border-left: 16px solid #fff !important;}"
        
        this.dataModel.multiStep.forEach((step, index) => {
            fontFamilySet.add(step.theme.fontFamily.replace(' ', '+'));
            style = style + '.step-' + index + '{' +
                'font-family:' + step.theme.fontFamily + ';font-size:' + step.theme.fontSize + ';color:' + step.theme.textColor +
                ';} .formbuilder-block .drop-area .form-group label{font-size:'+step.theme.fontSize+'}'  +
                ' .btn-cont .btn{border-radius:' + step.theme.borderRadius + 'px;}' +
                ' .btn-cont .btn:hover{background-color:' + this.dataModel.button.bgHoverColor + '!important;} .linkBtn{color:'+ this.dataModel.linkTextColor +';}' +
                ' .step-' + index + ' .form-group .form-control{border-radius:' + step.theme.borderRadius + 'px; border-color:' + step.theme.borderColor + '; background-color:' + step.theme.fieldBgColor + '!important; color:' + step.theme.fieldTextColor + ';}' +
                ' .step-' + index + ' label.default-radio .checkmark{border-color:' + step.theme.borderColor + '; background-color:' + step.theme.fieldBgColor + ';}' +
                ' .step-' + index + '  label.default-checkbox .checkmark{border-color:' + step.theme.borderColor + '; background-color:' + step.theme.fieldBgColor + ';}' +
                ' .step-' + index + '  label.default-checkbox .checkmark::after{border-color:' + step.theme.fieldBgColor + ';}' +
                ' .step-' + index + '  label.default-checkbox input:checked ~ .checkmark{background-color:' + step.theme.fieldTextColor + ';}' +
                ' .step-' + index + '  .custom-file .custom-file-label{color:' + step.theme.fieldTextColor + ';}'+
                ' .step-' + index + '  .form-check-input{border-color:' + step.theme.borderColor + '; } .form-check-input:checked{background-color:'+ step.theme.textColor +'} .locatorBtn{border:1px solid'+ step.theme.borderColor +'; background:'+ step.theme.fieldBgColor +';}';
        });

        var stepStyle = "";
        stepStyle = stepStyle + ".stepsMain .arrow > .nav {justify-content: center;padding: 20px 0; background: none; border: none;} .output.container-fluid{padding-left:0;padding-right:0;} .select2-container--default .select2-selection--multiple{background-color:transparent !important;} .select2-container{z-index:1;}";
        stepStyle = stepStyle + ".stepsMain .arrow > .nav .nav-link::after{display: none;} .form-control:focus{box-shadow: none;} .custom-form-block > .p{margin-bottom: 20px; text-align: center; border: 1px solid #999; padding: 10px 5px; border-radius: 5px;} .custom-form-block{border: 1px solid #ddd; padding: 15px; padding-bottom: 15px; border-radius: 5px; box-shadow: 0 0 5px #858585; margin-bottom: 40px; text-align:left; display:inline-block;}";
        stepStyle = stepStyle + ".custom-form-block .custom-file input{height: 100%;} .custom-form-block .form-control{overflow: hidden;} .custom-form-block label {width: 100%; margin-bottom: 5px;text-transform: capitalize;} .custom-form-block .error{margin-top: 6px; position: absolute; top: 0; right: 0; text-align: right; z-index: 2; color: #f00; border-bottom:1px solid;} .custom-form-block .related_parent_box .error{top: -25px;} .custom-form-block .custom-file .error {top: auto; bottom: 100%;}";
        stepStyle = stepStyle + ".custom-form-block .form-group {position: relative;}  .form-check {display: inline-block; width: 100%;} #locationData{position:absolute; opacity:0; z-index:-1;} .verticalWizardName{display:none;} .wizardVerticalMain .verticalWizardName{display: block; position: absolute; top: -12px; background: #fff; padding: 0px 5px; font-size: 16px; left: 10px;} .wizardVerticalMain .stepContent.stepNameBottom{display:none;}";
        stepStyle = stepStyle + ".stepsMain .arrow > .nav .nav-link {min-height: auto;margin: 0; padding: 0; text-transform: uppercase; width: 50px; height: 50px; line-height: 50px; border: 1px solid; border-radius: 50%; position: relative;display: flex; align-items: center; justify-content: center;}";
        stepStyle = stepStyle + ".stepsMain .arrow > .nav .nav-link::before {content: ''; position: absolute; left: 100%; top: 20px; width: 40px; height: 7px; background: #818181; border: none !important; margin: 0; } .onSubmitLoader .spinner-border{position:relative; top:50%; color:#fff} .onSubmitLoader{position:fixed; top:0; left:0; text-align:center; width:100%; height:100%; z-index:999999; background:rgba(0,0,0,0.5); display:none;}";
        stepStyle = stepStyle + ".stepsMain .arrow .nav-link span {padding: 0;display: inline-block;} .stepsMain .arrow .nav-link span.before, .stepsMain .arrow .nav-link span.after {display:none;} .stepsMain .stepContent{ margin:0;} .hide ul.nav-tabs{display:none;}";
        stepStyle = stepStyle + "@keyframes stepSlide { 0%   {width: 0;} 100% {width: 40px;} } .stepsMain .arrow .nav{min-height:auto;} body{background-size:cover;} .uploadFileInfo{margin-top:5px;} .arrow .nav{min-height:55px;}";
        stepStyle = stepStyle + ".stepsMain .arrow .nav > li {flex-grow: unset;margin: 0 20px; padding-bottom: 25px;} .stepsMain .arrow .nav > li:last-child .nav-link.active::before , .stepsMain .arrow .nav > li:last-child .nav-link::before , .stepsMain .arrow .nav > li:last-child .nav-link.active::after{ display:none; }";
        stepStyle = stepStyle + ".stepsMain .arrow > .nav .nav-item:last-child .nav-link::after,.stepsMain .arrow > .nav .nav-item:last-child .nav-link::before { display: none; } .wizardMain .arrow .nav-link span.stepActivate{display:none;} .wizardMain .arrow .nav-link span.stepNameBottom{position: initial;}";
        stepStyle = stepStyle + ".form-check{min-height: auto;} .btn-cont{margin: 0 !important;} .custom-form-block label.form-check-label{margin-bottom:0} .form-check-input:focus{box-shadow:none} .mb-5{margin-bottom:1rem!important} .stepNameBottom { position: absolute; top: 35px; text-transform: capitalize; }";        
        stepStyle = stepStyle + "@media only screen and (max-width:1080px){ .wizardVerticalMain .arrow{ position:inherit; } .custom-form-block { width:100%; }} @media only screen and (max-width:767px){ .stepsMain .arrow .nav > li{margin:0 4px;} .wizardVerticalMain .arrow{display:none;} .wizardVerticalMain .steps{padding:0 15px;} }"
        let family = "";
        fontFamilySet.forEach(function (set) {
            family = family + set + "|"
        });
        style = "<link href='https://fonts.googleapis.com/css2?family=" + family.slice(0, -1) + "&display=swap' rel='stylesheet'><style>" +backgroundBody+ style + this.dataModel.userStyle + stepStyle + "</style>";
        return style;
    }


    getCustomiseJavscript(): string {
        let javascript = "";
        var json = "";
        this.dataModel.multiStep.forEach((step, index) => {
            step.attributes.forEach((item, itemIndex) => {
                if (item.parent) {
                    var jsonObject: any = {};
                    jsonObject[item.parentAttributeName] = item.parentAttributeValue;
                    json = json + "; var " + item.parentAttributeName + item.ngName
                        + "=" + JSON.stringify(item.parentAttributeValue) + ";";
                }
            });

        });
        let sendData =  (this.dataModel.sendData == "no" ||  this.dataModel.sendData == undefined )?0:1;
        return javascript + " " + json + " var sendData=" +sendData +"; var redirectURL='"+this.dataModel.redirectUrl+"'; var formHook="+this.dataModel.formHook+"; var captcha="+this.dataModel.captcha+"; var isPublic="+this.dataModel.isPublic+";";
    }

    changeButtonProperty(event, item) {
        if (item.type == "button") {
            this.dataModel.button = item;
        }

        if (item.type == "button") {
            this.dataModel.linkTextColor = item;
        }
    }

    getFormData() {
        this.customFormService.getCustomFormData({ id: this.formId }).subscribe(data => {
            if (data.status) {
                if (data.data.status == AppConstants.STATUS_DELETED) {
                    this.toastr.error('Something went wrong', 'Error');
                    this.router.navigate(['/admin/project-integration/Forms/Custom Forms']);
                    return;
                }
                this.setFormData(data);
                
            } else {
                this.toastr.error(data.error_msgs, 'Error');
                this.router.navigate(['/admin/project-integration/Forms/Custom Forms']);
            }
        });
    }

    setFormData(data) {
       this.disbaleName= true;
        let jsonObject = JSON.parse(data.data.json);
        this.linkRewrite = data.data.linkRewrite;
        if (jsonObject.hasOwnProperty("multiStep")) {
            Object.assign(this.dataModel, JSON.parse(data.data.json));
            this.dataModel.multiStep.forEach((element, index) => {
                let formMode = new FormModel();
                this.dataModel.multiStep[index] = Object.assign(formMode, element);
                this.dataModel.multiStep[index].attributes.forEach((fieldsValue, fieldIndex) => {
                    let fields = new field();
                    this.dataModel.multiStep[index].attributes[fieldIndex] = Object.assign(fields, fieldsValue);
                    if (Array.isArray(this.dataModel.multiStep[index].attributes[fieldIndex].values)) {
                        this.dataModel.multiStep[index].attributes[fieldIndex].values.forEach((valueobj, valueindex) => {
                            let tmpValue = new value();
                            this.dataModel.multiStep[index].attributes[fieldIndex].values[valueindex] = Object.assign(tmpValue, valueobj);
                        });
                    }
                    if (this.dataModel.multiStep[index].attributes[fieldIndex].logic != undefined) {
                        let tmpLogicCondition = new LogicCondition();
                        this.dataModel.multiStep[index].attributes[fieldIndex].logic = Object.assign(tmpLogicCondition, this.dataModel.multiStep[index].attributes[fieldIndex].logic);

                        this.dataModel.multiStep[index].attributes[fieldIndex].logic.conditions.forEach((logicobj, logicindex) => {
                            var tmpcondition;
                            if (logicobj == "or" || logicobj == "and") {

                            } else {
                                tmpcondition = new Condition();
                                this.dataModel.multiStep[index].attributes[fieldIndex].logic.conditions[logicindex] = Object.assign(tmpcondition, logicobj);

                            }
                        });
                    }

                    if (this.dataModel.multiStep[index].logic != undefined) {
                        let tmpLogicCondition = new LogicCondition();
                        this.dataModel.multiStep[index].logic = Object.assign(tmpLogicCondition, this.dataModel.multiStep[index].logic);

                        this.dataModel.multiStep[index].logic.conditions.forEach((logicobj, logicindex) => {
                            var tmpcondition;
                            if (logicobj == "or" || logicobj == "and") {

                            } else {
                                tmpcondition = new Condition();
                                this.dataModel.multiStep[index].logic.conditions[logicindex] = Object.assign(tmpcondition, logicobj);

                            }
                        });
                    }

                    if (this.dataModel.multiStep[index].attributes[fieldIndex].parentAttributeValue != undefined && Array.isArray(this.dataModel.multiStep[index].attributes[fieldIndex].parentAttributeValue)) {
                        this.dataModel.multiStep[index].attributes[fieldIndex].parentAttributeValue.forEach((parentAttrobj, parentAttrindex) => {
                            let tmpParent = new ParentValue();
                            this.dataModel.multiStep[index].attributes[fieldIndex].parentAttributeValue[parentAttrindex] = Object.assign(tmpParent, parentAttrobj);
                        });
                    }
                });
            })
        } else {
            let formModel: FormModel = new FormModel();
            formModel.attributes = jsonObject.attributes;
            formModel.theme = jsonObject.theme;
            formModel.description = jsonObject.description;
            this.dataModel.multiStep.splice(0, this.dataModel.multiStep.length);
            this.dataModel.multiStep.push(formModel);
            this.dataModel.name = jsonObject.name;
            this.dataModel.successMessage = jsonObject.successMessage;
            this.dataModel.userStyle = jsonObject.userStyle
        }
        
        this.dataModel.multiStep.forEach(step => {
            step.attributes.forEach(element => {
                
                if (element.type == "radio" || element.type == 'checkbox' || element.type == 'autocomplete' || element.type == 'multiselect') {
                    this.value.push({ label: "", value: "" })
                    element.valueIndex = this.value.length - 1
                }
                if(element.type != 'link')
                {
                    if (this.mappingInputParams.find(x => x.paramKey === element.name) == undefined) {
                        this.mappingInputParams.push(this.inputParams.find(x => x.paramKey === element.name))
                    }
                }
            });
            this.ckeditorData.next(step.description);
        });
        if (data.data.startDate != '') {
            this.dataModel.startDate = new Date(data.data.startDate.replace(" ","T")+".000+05:30");
            this.dataModel.startDateTime = new Date(data.data.startDate.replace(" ","T")+".000+05:30");
        } else {
            this.dataModel.startDate = new Date();
            this.dataModel.startDateTime = new Date();
        }
        setTimeout(() => {
            if (data.data.endDate != '') {
                this.dataModel.endDate = new Date(data.data.endDate.replace(" ","T")+".000+05:30");
                this.dataModel.endDateTime = new Date(data.data.endDate.replace(" ","T")+".000+05:30");
            } else {
                this.dataModel.endDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
                this.dataModel.endDateTime = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
            }
        }, 100);

    }

    getInputParams() {
        this.inputParams = [];
        this.projectService.fetchAttributeList().then(data => {
            if (data)
            this.inputParams = this.projectService.filterAttributeList();            
            this.inputParams.forEach(element => {
                if (element.paramVisible == 1 && element.paramDatatype != 'File') 
                this.mappingInputParams.push(element)
            });
            this.mappingInputParams.sort(function(a, b){
                if(a.paramName < b.paramName) { return -1; }
                if(a.paramName > b.paramName) { return 1; }
                return 0;
            })             
            
        });        
    }

    isDisable(key) {
        return this.checkAttributeMapped(key);
        //return this.dataModel.multiStep[this.currentIndex].attributes.find(x => x.name == key) != undefined;
    }

    formDeleteConfirm() {
        this.modalRef = this.modalService.show(this.deleteConfirm, {
            backdrop: 'static'
        });
    }

    openDeleteStepsConfirmation() {
        this.modalRef = this.modalService.show(this.deleteConfirmSteps, {
            backdrop: 'static'
        });



    }

    onChangeFieldSelect(value) {
        this.selectedFieldsValues = [];
        if (value != "") {
            this.dataModel.multiStep.forEach(element => {
                element.attributes.forEach(field => {
                    if (value == field.name) {
                        if (field.parent) {
                            if (field.parentAttributeValue.length > 0) {
                                for (var j = 0; j < field.parentAttributeValue.length; j++) {
                                    if (field.parentAttributeValue[j].values.length > 0) {
                                        for (var k = 0; k < field.parentAttributeValue[j].values.length; k++) {
                                            {
                                                this.selectedFieldsValues.push
                                                    ({ label: field.parentAttributeValue[j].values[k].value, value: field.parentAttributeValue[j].values[k].value });

                                            }
                                        }
                                    }

                                }
                            }
                        } else {

                            if (field.values.length > 0) {
                                for (var j = 0; j < field.values.length; j++) {
                                    this.selectedFieldsValues.push
                                        ({ label: field.values[j].value, value: field.values[j].value });
                                }
                            }


                        }
                    }
                });

            });
        }


    }

    addLogic() {
        if (this.selectedLogicNgName != "" && this.selectedLogicValue != "" && (this.logicCondition.conditions.length == 0 || this.conditionalOperator != "")) {
            if (this.conditionalOperator != "")
                this.logicCondition.conditions.push(this.conditionalOperator);
            let condition: Condition = new Condition();
            condition.field = this.selectedLogicNgName;
            condition.operator = this.selectedLogicOperator;
            condition.value = this.selectedLogicValue;
            this.logicCondition.conditions.push(condition);
            this.conditionalOperator = "";
        } else {
            this.toastr.error("Please select all mandatory fields", 'Error');
        }
    }

    

    saveLogic() {

        if (this.logicModalType == "field") {
            this.currentItem.logic.outcome = this.selectedOutcome;

            
            if (this.logicCondition.conditions.length  > 0 && this.logicCondition.outcome == "") {
                this.toastr.error("Please select outcome", 'Error');
                return;
            }

            if (this.logicCondition.outcome == "hide" && this.currentItem.required) {
                this.toastr.error("You have to disable required flag first.", 'Error');
                return;
            }


            this.currentItem.logic = this.logicCondition;
        } else {

            this.dataModel.multiStep[this.currentIndex].logic.outcome = this.selectedOutcome;

            if (this.logicCondition.conditions.length == 0) {
                this.toastr.error("Please add logics", 'Error');
                return;
            }
            if (this.logicCondition.outcome == "") {
                this.toastr.error("Please select outcome", 'Error');
                return;
            }


            this.dataModel.multiStep[this.currentIndex].logic = this.logicCondition;

        }
        // this.logicCondition.conditions.splice(0,  this.logicCondition.conditions.length)
        this.modalRef.hide();



    }

    removeLogic(index) {
        this.logicCondition.conditions.splice(index, 1);
        if (index != 0)
            this.logicCondition.conditions.splice(index - 1, 1);
    }

    isCondition(candidate) {
        return candidate instanceof Condition;
    }


    isLogicConditionString(candidate) {

        if (candidate == "or" || candidate == "and") {
            return true;
        }
        return false;
    }
    openLogicModal(type) {
        this.allFields = [];
        this.logicModalType = type;
        if (type == "field") {
            this.dataModel.multiStep.forEach((element, index) => {
                if (index <= this.currentIndex) {
                    element.attributes.forEach(field => {
                        if (field.parent != undefined && this.currentItem.ngName != field.ngName) {
                           if(field.name == "" || field.name == undefined){
                            this.toastr.success("Please set mapping of all fields before adding logic.", 'Error');
                           }else{
                            this.allFields.push({ label: field.label, value: field.name });
                           }
                        }
                    });
                }
            });
            if (this.currentItem.logic == undefined) {
                this.currentItem.logic = new LogicCondition();
            }
            this.logicCondition = this.currentItem.logic;
            this.selectedOutcome = this.currentItem.logic.outcome
        } else {
            if (this.dataModel.multiStep.length <= 1) {
                this.toastr.success("This funtionality works with multi step forms", 'Success');
                return;
            }
            this.dataModel.multiStep.forEach((element, index) => {
                if (index <= this.currentIndex) {
                    element.attributes.forEach(field => {
                        if (field.parent != undefined )  {
                            if(field.name == "" || field.name == undefined){
                                this.toastr.success("Please set mapping of all fields before adding logic.", 'Error');
                           
                            }else{
                            this.allFields.push({ label: field.label, value: field.name });
                            }
                        }
                    });
                }
            });
            if (this.dataModel.multiStep[this.currentIndex].logic == undefined) {
                this.dataModel.multiStep[this.currentIndex].logic = new LogicCondition();
            }
            this.logicCondition = this.dataModel.multiStep[this.currentIndex].logic;
            this.selectedOutcome = this.dataModel.multiStep[this.currentIndex].logic.outcome;

        }
        this.modalRef = this.modalService.show(this.logicModal, {
            backdrop: 'static'
        });
    }

    deleteSteps() {
        this.dataModel.multiStep.splice(this.currentIndex, 1);
        this.currentIndex = this.currentIndex - 1;
        this.modalRef.hide();
    }


    deleteForm() {
        this.modalRef.hide();
        this.customFormService.deleteCustomForm({ id: this.formId }).subscribe(data => {
            if (data.status) {
                this.toastr.success(data.data, 'Success');
                this.modalRef.hide();
            } else {
                this.toastr.error(data.error_msgs, 'Error');
            }
            this.customFormService.customFormList = undefined;
            this.router.navigate(['/admin/project-integration/Forms/Custom Forms']);
        });
    }

    updateFileType(type, i) {
        if (type == 'all')
            this.dataModel.multiStep[this.currentIndex].attributes[i].defaultHint = "Allowed file types: .xlsx, .xls, .doc, .docx, .ppt, .pptx, .txt, .pdf, .zip, .csv, .png, .jpg, .gif, .svg"
        else if (type == 'file')
            this.dataModel.multiStep[this.currentIndex].attributes[i].defaultHint = "Allowed file types: .xlsx, .xls, .doc, .docx, .ppt, .pptx, .txt, .pdf, .zip, .csv"
        else if (type == 'image')
            this.dataModel.multiStep[this.currentIndex].attributes[i].defaultHint = "Allowed file types: .png, .jpg, .gif, .svg"
    }
}
