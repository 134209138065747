import { Location } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CampaignService } from '../../../core/services/campaign.service';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { ProjectService } from '../../../core/services/project.service';
import { GalleryPopupComponent } from '../../admin/pages/campaign-gallery/gallery-popup.component';
import { InvoiceSetting } from '../invoice-modal.modal';

@Component({
  selector: 'app-invoice-setting',
  templateUrl: './invoice-setting.component.html',
  styleUrls: ['./invoice-setting.component.scss']
})
export class InvoiceSettingComponent implements OnInit {
  modalRefGallery: BsModalRef;
  color:any='#333';
  bGcolor:any='#E3FAFF';
  settingForm:FormGroup;
  userProfileData:any;
  emailTemplateList:any;
  smsTemplateList:any;
  settingFormFields:InvoiceSetting  ={};
  templateSelected:any = 'PACIFIC';
  fixedParams;
  inputParams;
  showMapParams:any= new Array();
  @ViewChild('logoImage') logoImage:ElementRef;
  @ViewChild('signImage') signImage:ElementRef;
  constructor(
    private location:Location, 
    private FB:FormBuilder,
    private modalService: BsModalService,
    private projectService:ProjectService,
    private toaster:ToastrService,
    private authenticationService:AuthenticationService,
    private campaignService:CampaignService
    ) { }

  ngOnInit() {    
    this.settingFormFields.countryCode = '+91'
    this.settingFormFields.status='ACTIVE';
    this.settingFormFields.templateType = 'PACIFIC';
    this.settingFormFields.font = 'Roboto'
    this.settingFormFields.backgroundColor = this.bGcolor;
    this.settingFormFields.fontColor = this.color;
     this.getSellerConfig();
     this.getParams();
  }

  back()
  {
    this.location.back();
  }

  getParams() {
    this.projectService.fetchAttributeList().then(data => {
        if (data) {
            this.inputParams = this.projectService.filterAttributeList(['all']);
            
            if (this.inputParams) {
                this.inputParams.forEach(element => {
                    if (element.pluginName != "static" && element.paramDatatype != 'File') {
                        this.showMapParams.push(element)
                    }
                });
            }     
            
            this.showMapParams.sort((a, b) => a.paramName.localeCompare(b.paramName))            
        }
    });
    
}

  getEmailTemplateList(){
    if(this.emailTemplateList)
    {
      return;
    }
    this.campaignService.getEmailTemplateData('reload').then(data => {      
        if(data != undefined)
        this.emailTemplateList = data;    
                            
    });
  }

  getSmsTemplateList(){
    this.campaignService.getSmsTemplateData('reload').then( data => {
        if(data != undefined)
        this.smsTemplateList = data;
    });
}


  gstPressing(event)
  {
    let getVal = event.target.value;
    this.settingFormFields.gstNumber = getVal.toUpperCase();
  }

  gstPressingPan(event)
  {
    let getVal = event.target.value;
    this.settingFormFields.companyPan = getVal.toUpperCase();
  }

  openGalleryModal(type?) {
    const initialState = {
        galleryType: 'image',
        modalRefGallery: this.modalRefGallery
    }
    this.modalRefGallery = this.modalService.show(GalleryPopupComponent, {
        initialState,
        backdrop: "static",
        class: "gallery-popup"
    });
    this.modalRefGallery.content.modalRefGallery = this.modalRefGallery;
    this.modalRefGallery.content.selectMediaEvent.subscribe(data => {
        this.getMedia(data,type);
    });
}

getMedia(item,type)
{
  if(type == 'logo')
  {
    this.settingFormFields.logo = item.originalFile;
  }
  else if(type == 'sign')
  {
    this.settingFormFields.signature = item.originalFile;
  }
  
}

restrictSpecialChar(event): boolean
{
  var valueOfInput = event.target.value;
  const code = (event.which) ? event.which : event.keyCode;  
  if (!(code == 32) && !(code > 47 && code < 58) && !(code > 64 && code < 91) && !(code > 96 && code < 123)) { // lower alpha (a-z)
    event.preventDefault();
}
  return true
}


  settingFormSubmitted()
  {        
    var email_reg = /^[A-Za-z0-9]+[A-Za-z0-9._!#$%&'*+-/=?^_`{|}~]+@([A-Za-z0-9-]+[.]){1,2}[A-Za-z.]{2,10}$/;
    var ifsc_regex = /^[A-Z]{4}0[A-Z0-9]{6}$/; 
    var pan_regex = /(^([a-zA-Z]{5})([0-9]{4})([a-zA-Z]{1})$)/;
    if(this.settingFormFields.email == '')
    {
      this.toaster.error('Please enter email');
      return;
    }

    if(this.settingFormFields.email)
    {
      if( email_reg.test(this.settingFormFields.email) == false)
      {
        this.toaster.error('Please enter valid email');
        return;
      }
    }

    if(this.settingFormFields.phoneNumber)
    {
      // if(this.settingFormFields.phoneNumber.length < 10)
      // {
      //   this.toaster.error('Please enter valid phone');
      //   return
      // } else{
      //   this.settingFormFields.phoneNumber = this.settingFormFields.phoneNumber+this.settingFormFields.countryCode
      // }
      this.settingFormFields.phoneNumber = this.settingFormFields.countryCode+this.settingFormFields.phoneNumber
    }


    if(this.settingFormFields.gstNumber)
    {
      var gst_regex = /^([0]{1}[1-9]{1}|[1-2]{1}[0-9]{1}|[3]{1}[0-7]{1})([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$/g

      if(this.settingFormFields.gstNumber !== this.settingFormFields.gstNumber.toUpperCase())
      {
        this.toaster.error('GST number should be A-Z, 0-9');
        return;
      }
      if(gst_regex.test(this.settingFormFields.gstNumber) == false)
      {
        this.toaster.error('Please enter valid GST number');
        return;
      }
    }


    if(this.settingFormFields.ifscCode)
    {
      if(this.settingFormFields.ifscCode !== this.settingFormFields.ifscCode.toUpperCase())
      {
        this.toaster.error('IFSC code should be A-Z, 0-9');
        return;
      }
      if( ifsc_regex.test(this.settingFormFields.ifscCode) == false )
      {
        this.toaster.error('Please enter valid IFSC code');
        return;
      }
      
    }
    
    if(this.settingFormFields.companyPan)
    {      
      if(this.settingFormFields.companyPan.length < 10)
      {
        this.toaster.error('Company PAN should be 10 character');
        return;
      }
      if(this.settingFormFields.companyPan !== this.settingFormFields.companyPan.toUpperCase())
      {
        this.toaster.error('Company PAN should be A-Z, 0-9');
        return;
      }
      if( pan_regex.test(this.settingFormFields.companyPan) == false )
      {
        this.toaster.error('Please enter valid PAN Number');
        return;
      }
    }

    this.settingFormFields.gstFieldId == 'select' ? this.settingFormFields.gstFieldId = '' : this.settingFormFields.gstFieldId;
    this.settingFormFields.addressFieldId == 'select' ? this.settingFormFields.addressFieldId = '' : this.settingFormFields.addressFieldId;
    this.settingFormFields.companyNameFieldId == 'select' ? this.settingFormFields.companyNameFieldId = '' : this.settingFormFields.companyNameFieldId;
    
    var signWidth = this.signImage ? this.signImage.nativeElement.offsetWidth:'';
    var signHeight =this.signImage ? this.signImage.nativeElement.offsetHeight:'';
    var width = this.logoImage ? this.logoImage.nativeElement.offsetWidth:'';
    var height =this.logoImage ? this.logoImage.nativeElement.offsetHeight:'';
    this.settingFormFields.logoHeight = height+'px';
    this.settingFormFields.logoWidth = width+'px';
    this.settingFormFields.signatureHeight = signHeight+'px';
    this.settingFormFields.signatureWidth = signWidth+'px';
    this.settingFormFields.fontColor = this.color;
    this.settingFormFields.backgroundColor = this.bGcolor;
    this.settingFormFields.id = null;
    this.settingFormFields.updateInvoiceCounter = this.settingFormFields.updateInvoiceCounter != null ? this.settingFormFields.updateInvoiceCounter : false;
    this.settingFormFields.updateQuotationCounter = this.settingFormFields.updateQuotationCounter != null ? this.settingFormFields.updateQuotationCounter : false;
    let alphaNumWithChar  = /^[a-z A-Z0-9_/-]*$/
    if(this.settingFormFields.businessName){
      let alphaNumWithChar  = /^[a-zA-Z0-9-_/\\ ,\\.\\&\\\\n]*$/g
      if(!alphaNumWithChar.test(this.settingFormFields.businessName)){
          this.toaster.error('Company Name Should be Alphanumeric', 'Error');
          return;
      }
    }


    if(this.settingFormFields.address){
      let alphaNumWithChar  = /^[a-zA-Z0-9-_/\\ ,\\.\\&\\\\n]*$/g
      if(!alphaNumWithChar.test(this.settingFormFields.address)){
          this.toaster.error('Address Should be Alphanumeric', 'Error');
          return;
      }
    }

    if(this.settingFormFields.bankName){
      if(!alphaNumWithChar.test(this.settingFormFields.bankName)){
          this.toaster.error('Bank Name Should be Alphanumeric', 'Error');
          return;
      }
    }

    if(this.settingFormFields.accountHolderName){
      if(!alphaNumWithChar.test(this.settingFormFields.accountHolderName)){
          this.toaster.error('Account Holder Name Should be Alphanumeric', 'Error');
          return;
      }
    }

    if(this.settingFormFields.branch){
      if(!alphaNumWithChar.test(this.settingFormFields.branch)){
          this.toaster.error('Branch Name Should be Alphanumeric', 'Error');
          return;
      }
    }

    if(this.settingFormFields.city){
      if(!alphaNumWithChar.test(this.settingFormFields.city)){
          this.toaster.error('City Name Should be Alphanumeric', 'Error');
          return;
      }
    }

    if(this.settingFormFields.state){
      if(!alphaNumWithChar.test(this.settingFormFields.state)){
          this.toaster.error('State Name Should be Alphanumeric', 'Error');
          return;
      }
    }



      this.projectService.createSellerConf(this.settingFormFields).subscribe(data=>{
        if(data.status)
        {
          this.toaster.success('Added successfully');
          this.getSellerConfig();
        }
        else
        {
          this.toaster.error(data.error_msgs);
        }
      })
  }

  getSellerConfig()
  {
    this.projectService.getSellerConf({}).subscribe(data=>{            
      if(data.status)
      {
        if(data.data == null)
        {
          this.settingFormFields.invoiceEmailTemplate == null ? this.settingFormFields.invoiceEmailTemplate = 'select' : this.settingFormFields.invoiceEmailTemplate = this.settingFormFields.invoiceEmailTemplate;
          this.settingFormFields.quotationEmailTemplate == null ? this.settingFormFields.quotationEmailTemplate = 'select' : this.settingFormFields.quotationEmailTemplate = this.settingFormFields.quotationEmailTemplate;
          this.settingFormFields.invoiceSmsTemplate == null ? this.settingFormFields.invoiceSmsTemplate = 'select' : this.settingFormFields.invoiceSmsTemplate = this.settingFormFields.invoiceSmsTemplate;
          this.settingFormFields.quotationSmsTemplate == null ? this.settingFormFields.quotationSmsTemplate = 'select' : this.settingFormFields.quotationSmsTemplate = this.settingFormFields.quotationSmsTemplate;
  
          this.getUserData();
        }
        
        if(data.data != null)
        {          
        this.settingFormFields = data.data;         
        this.settingFormFields.invoiceEmailTemplate == null ? this.settingFormFields.invoiceEmailTemplate = 'select' : this.settingFormFields.invoiceEmailTemplate = this.settingFormFields.invoiceEmailTemplate;
        this.settingFormFields.quotationEmailTemplate == null ? this.settingFormFields.quotationEmailTemplate = 'select' : this.settingFormFields.quotationEmailTemplate = this.settingFormFields.quotationEmailTemplate;
        this.settingFormFields.invoiceSmsTemplate == null ? this.settingFormFields.invoiceSmsTemplate = 'select' : this.settingFormFields.invoiceSmsTemplate = this.settingFormFields.invoiceSmsTemplate;
        this.settingFormFields.quotationSmsTemplate == null ? this.settingFormFields.quotationSmsTemplate = 'select' : this.settingFormFields.quotationSmsTemplate = this.settingFormFields.quotationSmsTemplate;
        if(this.settingFormFields.invoiceCounter)
        {       
          if(this.settingFormFields.invoiceCounter.toString().length == 1)
          {          
            this.settingFormFields.invoiceCounter = '000'+this.settingFormFields.invoiceCounter;
          }
          if(this.settingFormFields.invoiceCounter.toString().length == 2)
          {          
            this.settingFormFields.invoiceCounter = '00'+this.settingFormFields.invoiceCounter;
          }
          if(this.settingFormFields.invoiceCounter.toString().length == 3)
          {
            this.settingFormFields.invoiceCounter = '0'+this.settingFormFields.invoiceCounter;
          }
        }

        if(this.settingFormFields.quotationCounter)
        {       
          if(this.settingFormFields.quotationCounter.toString().length == 1)
          {          
            this.settingFormFields.quotationCounter = '000'+this.settingFormFields.quotationCounter;
          }
          if(this.settingFormFields.quotationCounter.toString().length == 2)
          {          
            this.settingFormFields.quotationCounter = '00'+this.settingFormFields.quotationCounter;
          }
          if(this.settingFormFields.quotationCounter.toString().length == 3)
          {
            this.settingFormFields.quotationCounter = '0'+this.settingFormFields.quotationCounter;
          }
        }
         
        this.getUserData();   
        if(this.settingFormFields.fontColor != undefined)
        {
          this.color = this.settingFormFields.fontColor;
        }
        if(this.settingFormFields.templateType != undefined)
        {
          this.templateSelected = this.settingFormFields.templateType
        }
        if(this.settingFormFields.backgroundColor != undefined)
        {
          this.bGcolor = this.settingFormFields.backgroundColor;
        }
        
        if(this.settingFormFields.gstFieldId == null)
        {
          this.settingFormFields.gstFieldId = 'select';
        }

        if(this.settingFormFields.companyNameFieldId == null)
        {
          this.settingFormFields.companyNameFieldId = 'select';
        }

        if(this.settingFormFields.addressFieldId == null)
        {
          this.settingFormFields.addressFieldId = 'select';
        }
      }
      }
      else
      {
        this.toaster.error('Something went wrong');
      }
    })
  }


  getUserData()
  {
    this.authenticationService.getUser().subscribe(data => {
      if(data.data)
      {
        this.userProfileData = data.data;

        if(this.settingFormFields.businessName == '' || this.settingFormFields.businessName == undefined)
        {
          this.settingFormFields.businessName = this.userProfileData.companyName;
        }
        
        if(this.settingFormFields.phoneNumber == '' || this.settingFormFields.phoneNumber == undefined)
        {
          this.settingFormFields.phoneNumber = this.userProfileData.phone;
        }

        if(this.settingFormFields.phoneNumber){
          let getPincode = this.settingFormFields.phoneNumber.substring(0,4);
          let countryCodeData = ['+1','+27','+44','+91','+971'];
          countryCodeData.forEach(code=>{
           if(this.settingFormFields.phoneNumber.includes(code)){
            let charCount = this.settingFormFields.phoneNumber.length
            let phoneNumberStart;
            if(code == '+1'){
              phoneNumberStart = 2
              this.settingFormFields.countryCode = '+1';
              console.log(this.settingFormFields.countryCode)
            } else if(code == '+971'){
              phoneNumberStart = 4
              this.settingFormFields.countryCode = '+971';
            } else{
              phoneNumberStart = 3
              this.settingFormFields.countryCode = code
            }
            this.settingFormFields.phoneNumber = this.settingFormFields.phoneNumber.substring(phoneNumberStart,charCount);
            console.log(getPincode)
           }
          })
        }

        if(this.settingFormFields.email == '' || this.settingFormFields.email == undefined)
        {
          this.settingFormFields.email = this.userProfileData.users.email;
        }

        if(this.settingFormFields.address == '' || this.settingFormFields.address == undefined)
        {
          this.settingFormFields.address = this.userProfileData.companyAddress;
        }

        if(this.settingFormFields.gstNumber == '' || this.settingFormFields.gstNumber == undefined)
        {
          if(this.userProfileData.companyGstno)
          {
          this.settingFormFields.gstNumber = this.userProfileData.companyGstno;
          }
        }

        if(this.settingFormFields.city == '' || this.settingFormFields.city == undefined)
        {
          this.settingFormFields.city = this.userProfileData.city;
        }

        if(this.settingFormFields.state == '' || this.settingFormFields.state == undefined)
        {
          this.settingFormFields.state = this.userProfileData.state;
        }

        if(this.settingFormFields.postalCode == '' || this.settingFormFields.postalCode == undefined)
        {
          if(this.userProfileData.pincode)
          {
            this.settingFormFields.postalCode = this.userProfileData.pincode;
          }
        }
      }
      else
      {
        this.toaster.error('Something went wrong');
      }
    });
  }

  tempSelect(tempNum)
  {
    let tempSelect = tempNum;
    
    this.templateSelected = tempNum;
    
  }

}
