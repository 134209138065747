import { environment } from '../../environments/environment';

export class ApiConstants {
    
    public static get API_URL(): string { return environment.apiUrl; }
    public static get TAT_URL(): string { return environment.tatUrl; }
    public static get TAT_BASE_URL(): string {return environment.dataPushUrl}
    public static get AUTH_URL(): string { return environment.authUrl; }
    public static get WABA_URL(): string { return environment.wabaUrl; }
    public static get ASSETLIBRARY_URL(): string { return environment.assetLibarary; }
    public static get CART_URL(): string { return environment.cartUrl }
    public static get REG_URL(): string { return environment.registerUrl; }
    public static get INVOICE_URL(): string { return environment.apiUrlInvoice; }
    public static get FORMS_URL(): string { return environment.formUrl; }
    public static get FB_API_URL(): string { return environment.fbApiUrl; }
    public static get XL_API_URL(): string { return environment.xlsUrl; }
    public static get WHATSAPP_API_URL(): string { return environment.whatsappApiUrl; }
    public static get API_URL_CATALOGUE(): string { return environment.apiUrlCatalogue; }
    public static get API_URL_ANNOUNCEMENT(): string { return environment.apiUrlAnnouncement; }
    public static get API_URL_SHORTNER(): string {return environment.apiUrlShortner;}
    public static get API_URL_CALLASSIST(): string {return environment.apiCallassist;}
    public static get API_URL_INDIAMART(): string {return environment.apiUrlIndiaMart;}
    public static get API_URL_TRADEINDIA(): string {return environment.apiUrlTradeIndia;}
    public static get API_URL_JUSTDIAL(): string {return environment.apiUrlJustDial;}
    public static get WEB_URL(): string { return environment.webUrl; }
    public static get STATIC_URL(): string { return environment.staticUrl; }
    public static get C2C_URL():string{ return environment.c2cUrl}
    public static get CALL_TO_IVR(): string { return environment.c2cUrl+'user/outgoing-call-request'; }
    public static get CUSTOM_FORM_LINK(): string { return environment.formUrl+'customform/get-form-link?linkRewrite='; }
    public static get UPSERT_USE_RPROFILE() : string { return this.API_URL + 'user/upsertUserProfile'}
    public static get REGISTER_SUB_USER() : string { return this.API_URL + 'user/register-sub-user'}
    public static get SEARCH_SUB_USER() : string { return this.API_URL + 'user/search-sub-user'}
    public static get SIGN_IN() : string { return this.API_URL + 'user/sign-in'}
    public static get AUTH_SIGN_IN() : string { return this.AUTH_URL + 'user/sign-in'}
    public static get AUTH_TWO_FACTOR_DETAILS() : string { return this.AUTH_URL + 'user/get-two-factor-details'}
    public static get AUTH_GET_ALL_SESSIONS() : string { return this.AUTH_URL + 'user/get-all-sessions'}
    public static get AUTH_SESSION_LOGOUT() : string { return this.AUTH_URL + 'user/logout-session'}
    public static get MAX_SESSION_LOGOUT() : string { return this.AUTH_URL + 'user/logout-max-session'}
    public static get AUTH_SETUP_GOOGLE_AUTH() : string { return this.AUTH_URL + 'user/setup-google-auth-request'}
    public static get AUTH_SETUP_OTP_CHALLENGE() : string { return this.AUTH_URL + 'user/setup-otp-request'}
    public static get AUTH_VALIDATE_OTP_CHALLENGE() : string { return this.AUTH_URL + 'user/validate-otp-request'}
    public static get AUTH_VALIDATE_GOOGLE_AUTH() : string { return this.AUTH_URL + 'user/validate-google-auth-request'}
    public static get DISABLE_GOOGLE_AUTH() : string { return this.AUTH_URL + 'user/remove-gauth'}
    public static get TWO_FACTOR_VALIDATE() : string { return this.AUTH_URL + 'user/two-factor-validation'}
    public static get SIGN_OUT() : string { return this.API_URL + 'user/logout'}
    public static get SIGN_UP() : string { return this.AUTH_URL + 'user/sign-up'}
    public static get FORGOT_PASSWORD() : string { return this.API_URL + 'user/forgot-password'}
    public static get FETCH_USER_PROFILE() : string { return this.API_URL + 'user/fetch-user-profile'}
    public static get INSERT_CUSTOMER_ENQUIRY() : string { return this.API_URL + 'user/insert-customer-enquiry'}
    public static get UPDATE_SUB_USER() : string { return this.API_URL + 'user/update-sub-user'}
    public static get CREATE_WABA_USER() : string { return this.API_URL + 'waba/create-team-member'}
    public static get REMOVE_WABA_USER() : string { return this.API_URL + 'waba/delete-team-member'}
    public static get DELETE_SUB_USER() : string { return this.API_URL + 'user/delete-sub-user'}
    public static get TOGGLE_USER_STATUS() : string { return this.API_URL + 'user/toggleUserStage'}    
    public static get LEAD_STATUS_SUB_USER() : string { return this.API_URL + 'user/lead-status-sub-user'}
    public static get SEND_VERIFICATION_MAIL() : string { return this.API_URL + 'user/send-verification-mail'}
    public static get VERIFY_EMAIL_OTP() : string { return this.API_URL + 'user/verify-email-otp'}
    public static get REQUEST_EMAIL_OTP() : string { return this.API_URL + 'user/request-email-otp'}
    public static get CHANGE_PASSWORD() : string { return this.API_URL + 'user/change-password'}
    public static get IS_LINK_EXPIRED() : string { return this.API_URL + 'user/is-link-expired'}
    public static get IS_LINK_EXPIRED_FORGOT_PASSWORD() : string { return this.API_URL + 'user/is-link-expired-forgot-password'}
    public static get UPDATE_NEW_PASSWORD() : string { return this.API_URL + 'user/update-new-password'}
    public static get FETCH_PLUGIN_LIST_FOR_LEAD() : string { return this.API_URL + 'user/fetch-plugin-list-for-lead'}
    public static get FETCH_PLUGIN_LIST_FOR_USER() : string { return this.API_URL + 'user/fetch-plugin-list-for-user'}
    public static get FETCH_USER_LIST_FOR_PLUGIN() : string { return this.API_URL + 'user/fetch-user-list-for-plugin?formName=:type'}
    public static get SAVE_USER_LIST_FOR_PLUGIN() : string { return this.API_URL + 'user/save-user-list-for-plugin?formName=:type'}
    public static get UPSET_USER_FILTER() : string { return this.API_URL + 'user/upset-user-filter'}
    public static get LIST_USER_FILTER() : string { return this.API_URL + 'user/list-user-filter'}
    public static get DELETE_USER_FILTER() : string { return this.API_URL + 'user/delete-user-filter'}
    public static get ADD_INVOICE_PRODUCT() : string { return this.INVOICE_URL + 'process/upsert-product'}
    public static get GET_INVOICE_PRODUCT() : string { return this.INVOICE_URL + 'process/product/list'}
    public static get INSERT_INVOICE() : string { return this.INVOICE_URL + 'invoice/upsert-invoice'}
    public static get INSERT_QUOTATION() : string { return this.INVOICE_URL + 'quotation/upsert-quotation'}
    public static get GET_INVOICE_LIST() : string { return this.INVOICE_URL + 'invoice/list?size=:size&page=:page'}
    public static get GET_QUOTATION_LIST() : string { return this.INVOICE_URL + 'quotation/list?size=:size&page=:page'}
    public static get VIEW_INVOICE_LIST() : string { return this.INVOICE_URL + 'invoice/get-invoice'}
    public static get UPDATE_REPEAT_LEAD_PREFERENCE() : string { return this.API_URL + 'project/lead-repeat-column'}
    public static get VIEW_QUOTATION_LIST() : string { return this.INVOICE_URL + 'quotation/get-quotation'}
    public static get DELETE_INVOICE() : string { return this.INVOICE_URL + 'invoice/delete-invoice'}
    public static get DELETE_QUOTATION() : string { return this.INVOICE_URL + 'quotation/delete-quotation'}
    public static get CREATE_SELLER_CONFIG() : string { return this.INVOICE_URL + 'process/create-seller-configuration'}
    public static get GET_SELLER_CONFIG() : string { return this.INVOICE_URL + 'process/get-seller-configuration'}
    public static get UPDATE_INVOICE_STATUS() : string { return this.INVOICE_URL + 'invoice/update-invoice-status'}
    public static get UPDATE_QUOTATION_STATUS() : string { return this.INVOICE_URL + 'quotation/update-quotation-status'}
    public static get FETCH_REPEAT_LEADS() : string { return this.API_URL + 'report/fetch-repeat-leads'}
    public static get REMOVE_INVOICE_ITEM() : string { return this.INVOICE_URL + 'invoice/delete-invoice-item'}
    public static get GET_PROD_LIST_INVOICE() : string { return this.INVOICE_URL + 'process/product/list-all?size=:size&page=:page'}
    public static get REMOVE_INVOICE_PROD() : string { return this.INVOICE_URL + 'process/delete-product'}
    public static get ADD_INVOICE_PROD_XLS() : string { return this.INVOICE_URL + 'process/product/import'}
    public static get LOAD_GRAPH_DATA_INVOICE() : string { return this.INVOICE_URL + 'invoice/graph-data'}
    public static get LIST_USER_ROLE() : string { return this.API_URL + 'user/list-user-role'}
    public static get UPSET_USER_ROLE() : string { return this.API_URL + 'user/upset-user-role'}
    public static get DELETE_USER_ROLE() : string { return this.API_URL + 'user/delete-user-role'}
    public static get UPSET_ROLE_PERMISSION() : string { return this.API_URL + 'user/upset-role-permission'}
    public static get GET_ROLE_PERMISSION() : string { return this.API_URL + 'user/get-role-permission'}
    public static get SEGMENT_LIST() : string { return this.API_URL + 'segment/segment-list'}
    public static get CREATE_SEGMENT() : string { return this.API_URL + 'segment/create-segment'}
    public static get FETCH_SEGMENT() : string { return this.API_URL + 'segment/fetch-segment'}
    public static get DELETE_SEGMENT() : string { return this.API_URL + 'segment/delete-segment'}
    public static get UPDATE_CUSTOM_FORM_STATUS() : string { return this.FORMS_URL + 'customform/update-form-status'}
    public static get UPDATE_GOOGLE_FORM_STATUS() : string { return this.API_URL + 'project/update-google-form-status'}
    public static get FETCH_AUDIENCE_DATA() : string { return this.API_URL + 'report/fetch-leads-list'}
    public static get FOLLOW_UP_COUNTS_USERS() : string { return this.API_URL + 'report/follow-up-counts-users'}
    public static get FOLLOW_UP_COUNTS_USERS_SEND_EMAIL() : string { return this.API_URL + 'report/send-email-lead-reports'}
    public static get LEAD_REPORT_SEND_EMAIL() : string { return this.API_URL + 'report/lead-counts-send-email'}

    public static get FETCH_AUDIENCE_DATA_FOR_PAGGING() : string { return this.API_URL + 'report/fetch-audience-data-for-pagging'}
    public static get FETCH_INDIVIDUAL_AUDIENCE_DATA() : string { return this.API_URL + 'report/fetch-lead-detail'}
    public static get FETCH_INDIVIDUAL_AUDIENCE_TIMELINE_ENTRY() : string { return this.API_URL + 'report/fetch-individual-audience-timeline-entry'}
    public static get DOWNLOAD_AUDIENCE_DETAIL_FILE() : string { return this.API_URL + 'report/lead-document-download/'}
    public static get FETCH_INDIVIDUAL_CAMPAIGN_ALL_DATA() : string { return 'campaign/view-campaign-all-data'}
    public static get FETCH_INDIVIDUAL_AUDIENCE_STATS() : string { return this.API_URL + 'report/fetch-individual-audience-stats'}
    public static get UPSERT_USER_LEAD_ASSIGN() : string { return this.REG_URL + 'campaign/upsert-user-lead-assign'}
    public static get SHARE_LEAD() : string { return this.REG_URL + 'campaign/share-lead'}
    public static get LEAD_COUNTS_FOR_ALL_USERS() : string { return this.API_URL + 'report/lead_counts_for_all_users'}
    public static get LEAD_COUNTS_USERS() : string { return this.API_URL + 'report/lead-counts-user'}
    public static get LEAD_COUNTS_SOURCE() : string { return this.API_URL + 'report/lead-counts-source'}
    public static get DELETE_AUDIENCE_DATA() : string { return this.API_URL + 'report/delete-audience-data'}
    public static get DELETE_BULK_AUDIENCE_DATA() : string { return this.REG_URL + 'campaign/delete-audience'}
    public static get CREATE_REPORT() : string { return this.API_URL + 'report/create-report'}
    public static get LIST_REPORTS() : string { return this.API_URL + 'report/list-reports'}
    public static get REPORT_DETAIL() : string { return this.API_URL + 'report/report-detail'}
    public static get DELETE_REPORT() : string { return this.API_URL + 'report/delete-report'}
    public static get FETCH_REPORT() : string { return this.API_URL + 'report/fetch-report-count'}
    public static get FOLLOW_UP_REPORT() : string { return this.API_URL + 'notification/follow-up-Web-notification'}
    public static get NEW_LEAD_REPORT() : string { return this.API_URL + 'notification/new-Lead-Web-notification'}
    public static get UPSERT_USER_LEAD_ASSIGN_BULK() : string { return this.REG_URL + 'campaign/upsert-bulk-user-lead-assign'}
    public static get FETCH_INDIVIDUAL_AUDIENCE_CALL_LOG() : string { return this.API_URL_CALLASSIST + 'user/fetch-individual-audience-call-log'}
    public static get FETCH_IVR_CALL_LOG() : string { return this.C2C_URL + 'user/get-call-log'}
    public static get DOWNLOAD_AUDIENCE_REPORT() : string { return this.API_URL + 'report/download-audience-report'}
    public static get FETCH_AUDIENCE_COLUMN() : string { return this.API_URL + 'report/fetch-audience-column'}
    public static get FETCH_LEADS_COUNT() : string { return this.API_URL + 'report/fetch-leads-count'}
    public static get UPSERT_AUDIENCE_COLUMN() : string { return this.API_URL + 'report/upsert-audience-column'}
    public static get SAVE_AUDIENCE_DATA_DOWNLOAD_REQUEST() : string { return this.API_URL + 'report/download-audience-report-all'}
    public static get AUDIENCE_DATA_DOWNLOAD() : string { return this.API_URL + 'report/get-audience-report-list'}
    public static get MOVE_LEAD_BY_CATEGORY() : string { return this.REG_URL + 'campaign/move-lead-category'}
    public static get FETCH_TAX_DATA() : string { return this.INVOICE_URL + 'process/tax/list'}
    public static get FETCH_FIXED_PARAM() : string { return this.API_URL + 'param/fetch-fixed-param'}
    public static get GET_MAGIC_LINK() : string { return this.WABA_URL + 'get-magic-link'}
    public static get GET_CONFIG_SYNC() : string { return this.WABA_URL + 'fetch-api-key'}
    public static get UPSERT_INPUT_PARAMS() : string { return this.API_URL + 'param/create-input-param'}
    public static get UPDATE_INPUT_PARAMS() : string { return this.API_URL + 'param/update-input-param'}
    public static get DELETE_INPUT_PARAMS() : string { return this.API_URL + 'param/delete-input-param'}
    public static get FETCH_INPUT_PARAMS() : string { return this.API_URL + 'param/fetch-input-params'}
    public static get FETCH_ATTRIBUTE_SOURCE_MAPPING() : string { return this.API_URL + 'project/master'}
    public static get FETCH_PROJECT_DETAIL() : string { return this.API_URL + 'project/fetch-project-detail'}
    public static get UPSERT_PROJECT() : string { return this.API_URL + 'project/upsert-project'}
    public static get DOWNLOAD_INTIGRATION_KIT() : string { return this.API_URL + 'project/download-intigration-kit'}
    public static get DOWNLOAD_SAMPLE_FILE() : string { return this.API_URL + 'project/download-sample-file'}
    public static get UPLOAD_AUDIENCE_DATA_EXCEL() : string { return this.XL_API_URL + 'upload-audience-excel'}
    public static get UPLOAD_ATTRIBUTE_DATA_CSV() : string { return this.API_URL + 'user/upload-attribute-csv'}
    public static get DOWNLOAD_ATTRIBUTE_DATA_CSV() : string { return this.API_URL + 'user/export-attribute-csv'}
    public static get DOWNLOAD_FAILD_REPORT() : string { return this.API_URL + 'project/download-faild-report'}
    public static get CONFIRM_UPLOADED_EXCEL() : string { return this.API_URL + 'project/confirm-uploaded-excel'}
    public static get FETCH_USER_SOURCE() : string { return this.API_URL + 'project/fetch-user-source'}
    public static get FETCH_USER_PLUGINS() : string { return this.API_URL + 'project/fetch-user-plugins'}
    public static get FETCH_PLUGINS_FROM() : string { return 'fetch-plugin-form'}
    public static get FETCH_XL_FROM() : string { return this.XL_API_URL + 'fetch-plugin-form'}
    public static get FETCH_ALL_INPUT_PARAMS() : string { return this.API_URL + 'param/fetch-all-input-params'}
    public static get FETCH_FORMS_MAPPING() : string { return this.API_URL + 'project/fetch-forms-mapping'}
    public static get SAVE_FORMS_MAPPING() : string { return this.API_URL + 'project/save-forms-mapping'}
    public static get SAVE_PLUGINS_FORM_DATA() : string { return 'save-plugin-form-data'}
    public static get UPDATE_PLUGIN_STATUS() : string { return 'update-plugin-status?formName=:type'}
    public static get UPDATE_PLUGIN_STATUS_MS() : string { return 'update-plugin-status'}
    public static get UPDATE_FORM_STATUS_MS() : string { return 'update-form-status'}
    public static get CANCEL_UPLODED_EXCEL() : string { return this.API_URL + 'project/cancel-uploded-excel'}
    public static get SAVE_FB_FORM_MAPPING() : string { return this.FB_API_URL + 'save-form-mapping'}
    public static get FETCH_FACEBOOK_PAGES() : string { return this.API_URL + 'project/fetch-facebook-forms-list'}
    public static get FETCH_EXCEL_PAGES() : string { return this.API_URL + 'project/fetch-bulk-upload-forms-list'}
    public static get FETCH_SHOPIFY_PAGES() : string { return this.CART_URL + 'application/get-forms'}
    public static get FETCH_ECOMMERCE_FORMS() : string { return this.CART_URL + 'application/fetch-form'}
    public static get SAVE_ECOMMERCE_FORMS() : string { return this.CART_URL + 'application/save-form'}
    public static get UPDATE_ECOMMERCE_FORMS() : string { return this.CART_URL + 'application/update-form'}
    public static get DEL_ECOMMERCE_FORMS() : string { return this.CART_URL + 'application/delete-form'}
    public static get FETCH_FACEBOOK_FORM_MAPPING() : string { return this.FB_API_URL + 'fetch-form-mapping'}
    public static get FETCH_GOOGLEFORM_LIST() : string { return this.API_URL + 'project/fetch-googleform-list'}
    public static get CREATE_GOOGLE_FORM() : string { return this.API_URL + 'project/create-google-form'}
    public static get FETCH_GOOGLEFORMS_MAPPING() : string { return this.API_URL + 'project/fetch-googleforms-mapping'}
    public static get SAVE_GOOGLEFORMS_MAPPING() : string { return this.API_URL + 'project/save-googleforms-mapping'}
    public static get DELETE_GOOGLE_FORM() : string { return this.API_URL + 'project/delete-google-form'}
    public static get UPDATE_XL_FORM_STATUS() : string { return this.XL_API_URL + 'update-form-status'}
    public static get UPDATE_ECOMM_FORM_STATUS() : string { return this.CART_URL + 'application/update-status'}

    public static get DELETE_FB_PAGES() : string { return this.FB_API_URL + 'delete-form'}
    public static get UPDATE_FB_FORM_STATUS() : string { return this.FB_API_URL + 'update-form-status'}
    public static get FETCH_FB_FORMS() : string { return this.FB_API_URL + 'fetch-page-forms'}
    public static get IS_PLUGIN_SUBSCRIBED() : string { return this.API_URL + 'project/is-plugin-subscribed'}
    public static get SAVE_FB_AUTH_TOKEN() : string { return this.FB_API_URL + 'save-auth-token'}
    public static get FETCH_FB_AUTH_DETAILS() : string { return this.FB_API_URL + 'fetch-auth-details'}
    public static get UPDATE_LEAD_ACTIVE_STATUS() : string { return this.API_URL + 'project/update-lead-active-status'}
    public static get UPDATE_LEAD_REPEAT_STATUS() : string { return this.API_URL + 'project/update-repeat-active-status'}
    public static get SAVE_FB_CUSTOM_AUDIENCE_MAPPING() : string { return this.FB_API_URL + 'create-audience-segment-mapping'}
    public static get UPDATE_FB_CUSTOM_AUDIENCE_MAPPING() : string { return this.FB_API_URL + 'update-audience-segment-status'}
    public static get SAVE_FB_AUDIENCE_AUTH_TOKEN() : string { return this.FB_API_URL + 'save-segment-auth-token'}
    public static get FETCH_FB_CUSTOM_AUDIENCE_MAPPING() : string { return this.FB_API_URL + 'fetch-audience-segment-mapping'}
    public static get DELETE_PLUGINS_FORM_DATA() : string { return 'delete-plugin-form-data'}
    public static get DELETE_XL_FORM_DATA() : string { return this.XL_API_URL + 'delete-plugin-form-data'}
    public static get FETCH_COUNTRIES() : string { return this.API_URL + 'masters/fetch-countries'}
    public static get GET_IOS_PUSH_CONFIGURATION() : string { return this.API_URL + 'configuration/get-ios-push-configuration'}
    public static get GET_WINDOWS_PUSH_CONFIGURATION() : string { return this.API_URL + 'configuration/get-windows-push-configuration'}
    public static get GET_USER_JS_CONFIGURATION() : string { return this.API_URL + 'configuration/get-user-js-configuration'}
    public static get GET_WEB_PUSH_CONFIGURATION() : string { return this.API_URL + 'configuration/get-web-push-configuration'}
    public static get GET_WPB_PUSH_CONFIGURATION() : string { return this.API_URL + 'configuration/get-whatsapp-push-configuration'}
    public static get UPSERT_IOS_PUSH_CONFIGURATION() : string { return this.API_URL + 'configuration/upsert-ios-push-configuration'}
    public static get UPSERT_WINDOWS_PUSH_CONFIGURATION() : string { return this.API_URL + 'configuration/upsert-windows-push-configuration'}
    public static get UPSERT_WEB_PUSH_CONFIGURATION() : string { return this.API_URL + 'configuration/upsert-web-push-configuration'}
    public static get UPSERT_WPB_PUSH_CONFIGURATION() : string { return this.API_URL + 'configuration/upsert-whatsapp-push-configuration'}
    public static get UPSERT_EMAIL_PUSH_CONFIGURATION() : string { return this.API_URL + 'configuration/upsert-email-push-configuration'}
    public static get SEND_TEST_WEB_PUSH_NOTIFICATION() : string { return this.API_URL + 'configuration/send-test-web-push-notification'}
    public static get SEND_TEST_EMAIL() : string { return this.API_URL + 'configuration/send-test-email'}
    public static get SEND_TEST_SMS() : string { return this.API_URL + 'configuration/send-test-sms'}
    public static get SEND_TEST_WPB() : string { return this.API_URL + 'configuration/send-test-whatsapp'}
    public static get GET_MENU_LIST() : string { return this.API_URL + 'configuration/get-menu-list'}
    public static get GET_CHILD_USERS() : string { return this.API_URL + 'user/get-user-childList'}
    public static get GET_MENU_ACTION_LIST() : string { return this.API_URL + 'configuration/get-menu-action-list'}
    public static get GET_WP_PUSH_CONFIGURATION() : string { return this.API_URL + 'configuration/get-wp-push-configuration'}
    public static get UPSERT_WP_PUSH_CONFIGURATION() : string { return this.API_URL + 'configuration/upsert-wp-push-configuration'}
    public static get WP_SCAN_CONFIGURATION() : string { return this.API_URL + 'configuration/wp-scan-configuration'}
    public static get RESET_WINDOWS_PUSH_CONFIGURATION() : string { return this.API_URL + 'configuration/reset-windows-push-configuration'}
    public static get RESET_WEB_PUSH_CONFIGURATION() : string { return this.API_URL + 'configuration/reset-web-push-configuration'}
    public static get RESET_SMS_PUSH_CONFIGURATION() : string { return this.API_URL + 'configuration/reset-sms-push-configuration'}
    public static get RESET_WPB_PUSH_CONFIGURATION() : string { return this.API_URL + 'configuration/reset-whatsapp-push-configuration'}
    public static get RESET_EMAIL_PUSH_CONFIGURATION() : string { return this.API_URL + 'configuration/reset-email-push-configuration'}
    public static get RESET_WP_PUSH_CONFIGURATION() : string { return this.API_URL + 'configuration/reset-wp-push-configuration'}
    public static get SEND_TEST_WP() : string { return this.API_URL + 'configuration/send-test-wp'}
    public static get UPSERT_EMAIL_TEMPLATE() : string { return this.API_URL + 'configuration/upsert-email-template'}
    public static get DELETE_EMAIL_TEMPLATE() : string { return this.API_URL + 'configuration/delete-email-template'}
    public static get UPSERT_PUSH_TEMPLATE() : string { return this.API_URL + 'configuration/upsert-push-template'}
    public static get PUSH_TEMPLATE_LIST() : string { return this.API_URL + 'configuration/push-template-list'}
    public static get FETCH_PUSH_TEMPLATE() : string { return this.API_URL + 'configuration/fetch-push-template'}
    public static get DELETE_PUSH_TEMPLATE() : string { return this.API_URL + 'configuration/delete-push-template'}
    public static get UPSERT_SMS_TEMPLATE() : string { return this.API_URL + 'configuration/upsert-sms-template'}
    public static get UPSERT_WHATSAPP_TEMPLATE() : string { return this.API_URL + 'configuration/upsert-whatsapp-template'}
    public static get DELETE_SMS_TEMPLATE() : string { return this.API_URL + 'configuration/delete-sms-template'}
    public static get DELETE_WHATSAPP_TEMPLATE() : string { return this.API_URL + 'configuration/delete-whatsapp-template'}
    public static get IMAGE_FILE_LIST() : string { return this.ASSETLIBRARY_URL + 'configuration/image-file-list?size=:size&page=:page'}
    public static get DELETE_IMAGE_FILE() : string { return this.ASSETLIBRARY_URL + 'configuration/delete-image-file'}
    public static get UPSERT_IMAGE_FILE() : string { return this.ASSETLIBRARY_URL + 'configuration/upsert-image-file'}
    public static get INAPP_TEMPLATE_LIST() : string { return this.API_URL + 'configuration/inApp-template-list'}
    public static get VIEW_AUDIENCE_FILTERS() : string { return this.API_URL + 'campaign/view-audience-filters'}
    public static get VIEW_AUDIENCE_REPORT_DETAIL() : string { return 'campaign/view-campaign-all-data'}
    public static get FETCH_CAMPAIGN_PARAMS() : string { return this.API_URL + 'campaign/fetch-campaign-params'}
    public static get CHECK_SUBSCRIPTION_FOR_CAMPAIGN() : string { return this.API_URL + 'campaign/check-subscription-for-campaign'}
    public static get FETCH_CAMPAIGN_PARAMS_VALUES() : string { return this.API_URL + 'campaign/fetch-campaign-params-values'}
    public static get UPSERT_CAMPAIGN() : string { return this.API_URL + 'campaign/upsert-campaign'}
    public static get SEARCH_PARAM_DATA() : string { return this.API_URL + 'param/search-param-data'}
    public static get SAVE_EMAIL_CAMPAIGN() : string { return this.API_URL + 'campaign/save-email-campaign'}
    public static get CAMPAIGN_LIST_FOR_PAGGING() : string { return this.API_URL + 'campaign/campaign-list-for-pagging'}
    public static get SAVE_JOURNEY_CAMPAIGN() : string { return this.API_URL + 'campaign/save-journey-campaign'}
    public static get DELETE_CAMPAIGN_JOURNEY() : string { return this.API_URL + 'campaign/delete-campaign-journey'}
    public static get FETCH_BAR_NOTIFICATIONS_LIST() : string { return this.API_URL_ANNOUNCEMENT + 'notification/fetch-bar-notifications-list'}
    public static get UPSERT_BAR_NOTIFICATION() : string { return this.API_URL_ANNOUNCEMENT + 'notification/upsert-bar-notification'}
    public static get FETCH_BAR_NOTIFICATION_DETAIL() : string { return this.API_URL_ANNOUNCEMENT + 'notification/fetch-bar-notification-detail'}
    public static get DELETE_BAR_NOTIFICATION() : string { return this.API_URL_ANNOUNCEMENT + 'notification/delete-bar-notification'}
    public static get FETCH_POPUP_NOTIFICATIONS_LIST() : string { return this.API_URL_ANNOUNCEMENT + 'notification/fetch-popup-notifications-list'}
    public static get UPSERT_POPUP_NOTIFICATION() : string { return this.API_URL_ANNOUNCEMENT + 'notification/upsert-popup-notification'}
    public static get FETCH_POPUP_NOTIFICATION_DETAIL() : string { return this.API_URL_ANNOUNCEMENT + 'notification/fetch-popup-notification-detail'}
    public static get DELETE_POPUP_NOTIFICATION() : string { return this.API_URL_ANNOUNCEMENT + 'notification/delete-popup-notification'}
    public static get GET_NOTIFICATION() : string { return this.API_URL + 'notification/get-notifications?size=:size&page=:page'}
    public static get GET_UNREAD_NOTIFICATION() : string { return this.API_URL + 'notification/get-notification-count'}
    public static get VIEW_NOTIFICATION() : string { return this.API_URL + 'notification/view-notifications'}
    public static get CREATE_POPUP_USER() : string { return this.API_URL_ANNOUNCEMENT + 'creative/create-popup-user/:type'}
    public static get UPDATE_POPUP_USER() : string { return this.API_URL_ANNOUNCEMENT + 'creative/update-popup-user/:type'}
    public static get LIST_ANNOUNCEMENTS() : string { return this.API_URL_ANNOUNCEMENT + 'creative/list-announcements/:type?size=:size&page=:page'}
    public static get DELETE_POPUP_USER() : string { return this.API_URL_ANNOUNCEMENT + '/creative/delete-popup-user/:type'}
    public static get FETCH_POPUP_USER() : string { return this.API_URL_ANNOUNCEMENT + '/creative/fetch-popup-user/:type'}
    public static get SAVE_CUSTOM_FORM() : string { return this.FORMS_URL + 'customform/save-custom-form'}
    public static get FETCH_CUSTOM_FORM() : string { return this.FORMS_URL + 'customform/fetch-custom-form'}
    public static get FETCH_CUSTOM_FORM_BY_ID() : string { return this.FORMS_URL + 'customform/fetch-by-id-custom-form'}
    public static get DELETE_CUSTOM_FORM() : string { return this.FORMS_URL + 'customform/delete-custom-form'}
    public static get FETCH_FORM_DETAIL() : string { return this.API_URL + 'project/fetch-form-detail'}
    public static get UPDATE_FORM_DETAIL() : string { return this.API_URL + 'project/update-form-detail'}
    public static get FETCH_CAMPAIGN_STATS() : string { return 'dashboard/fetch-campaign-stats'}
    public static get FETCH_LEADS_COLLECTED() : string { return this.API_URL + 'dashboard/fetch-leads-collected'}
    public static get GET_CURRENT_PLAN() : string { return this.API_URL + 'subscription/get-current-plan'}
    public static get CREATE_CATALOGUE() : string { return this.API_URL_CATALOGUE + 'catalogue/create-catalogue'}
    public static get COPY_CATALOGUE() : string { return this.API_URL_CATALOGUE + 'catalogue/copy-catalogue'}
    public static get UPDATE_CATALOGUE() : string { return this.API_URL_CATALOGUE + 'catalogue/update-catalogue'}
    public static get FETCH_CATALOGUE() : string { return this.API_URL_CATALOGUE + 'catalogue/fetch-catalogue'}
    public static get LIST_OF_CATALOGUES() : string { return this.API_URL_CATALOGUE + 'catalogue/listOfCatalogues'}
    public static get DELETE_CATALOGUE() : string { return this.API_URL_CATALOGUE + 'catalogue/delete-catalogue'}
    public static get UPDATE_CATALOGUE_PRODUCT() : string { return this.API_URL_CATALOGUE + 'catalogue/update-catalogue-product'}
    public static get DELETE_CATALOGUE_PRODUCT() : string { return this.API_URL_CATALOGUE + 'catalogue/delete-catalogue-product'}
    public static get CATALOGUES_DATA() : string { return this.API_URL + 'project/rampup/catalogues-data'}
    public static get FETCH_USER_LEAD_ASSIGN_STATUS() : string { return this.API_URL + 'leads/list-lead-status'}
    public static get UPSET_USER_LEAD_ASSIGN_STATUS() : string { return this.API_URL + 'leads/create-lead-status'}
    public static get DELETE_USER_LEAD_ASSIGN_STATUS() : string { return this.API_URL + 'leads/delete-lead-status'}
    public static get LIST_SHORTENS(): string { return this.API_URL_SHORTNER + 'list-shortens?size=:size&page=:page'}
    public static get UPDATE_SHORTNER(): string {return this.API_URL_SHORTNER + 'update-shortner'}
    public static get CREATE_URL_SHORTNER(): string{ return this.API_URL_SHORTNER + 'create-shortner'}
    public static get DOMAIN_STATUS() : string { return this.API_URL + 'ecommerce/domain-status'}
    public static get ECOMMERCE_CREATE() : string { return this.API_URL + 'ecommerce/create'}
    public static get ECOMMERCE_FETCH_ALL() : string { return this.API_URL + 'ecommerce/fetch-all'}
    public static get REGISTER_AUDIENCE_DATA() : string { return this.REG_URL + 'campaign/push-audience'}
    public static get FETCH_CALL_LOGS() : string { return this.API_URL_CALLASSIST + 'user/fetch-call-logs?size=:size&page=:page'}
    public static get CREATE_WORKING_DAY() : string { return this.API_URL_CALLASSIST + 'attendance/create-working-day'}
    public static get VIEW_WORKING_DAY() : string { return this.API_URL_CALLASSIST + 'attendance/view-working-day'}
    public static get FETCH_ATTENDANCE_LOGS() : string { return this.API_URL_CALLASSIST + 'attendance/list-attendance'}
    public static get DOWNLOAD_ATTENDANCE_REPORT() : string { return this.API_URL_CALLASSIST + 'attendance/export-attendance'}
    public static get DOWNLOAD_REPORT_REQUEST() : string { return this.API_URL_CALLASSIST + 'attendance/download-report-request'}
    public static get FETCH_MISSING_RECORDING() : string { return this.API_URL_CALLASSIST + 'user/fetch-missed-call-logs?size=:size&page=:page'}
    public static get FETCH_CALL_ASSIST_LOCATION() : string { return this.API_URL_CALLASSIST + 'attendance/location-history?size=:size&page=:page'}
    public static get SAVE_UPDATE_CAMPAIGN_JOURNEY() : string { return this.API_URL + 'campaign/save-update-campaign-journey'}
    public static get GET_CAMPAIGN_JOURNEY_LIST() : string { return this.API_URL + 'campaign/get-campaign-journey-list?size=:size&page=:page'}
    public static get FETCH_CAMPAIGN_JOURNEY() : string { return this.API_URL + 'campaign/fetch-campaign-journey'}
    public static get UPSERT_TEMPLATE() : string { return 'template/upsert-template'}
    public static get GET_TEMPLATE() : string { return 'template/get-template'}
    public static get DELETE_TEMPLATE() : string { return 'template/delete-template'}
    public static get SAVE_PUSH_NOTIFICATION_CAMPAIGN() : string { return 'campaign/save-campaign'}
    public static get CAMPAIGN_LIST() : string { return 'campaign/campaign-list'}
    public static get SAVE_CAMPAIGN() : string { return 'campaign/save-campaign'}
    public static get CHECK_CONFIGURATION_FOR_CAMPAIGN() : string { return 'configuration/check-configuration-for-campaign'}
    public static get GET_CONFIGURATION() : string { return 'configuration/get-configuration'}
    public static get SEND_TEST_MESSAGE() : string { return 'configuration/send-test-message'}
    public static get UPSERT_CONFIGURATION() : string { return 'configuration/upsert-configuration'}
    public static get FETCH_SERVICE_PROVIDER() : string { return 'configuration/fetch-service-provider'}
    public static get CALCULATE_AUDIENCE() : string { return this.API_URL + 'report/fetch-leads-count'}
    public static get SAVE_INAPP_CAMPAIGN() : string { return 'campaign/save-inapp-campaign'}
    public static get DELETE_CAMPAIGN() : string { return 'campaign/delete-campaign'}
    public static get FETCH_SMS_TEMPLATE() : string { return this.API_URL + 'configuration/fetch-sms-template'}
    public static get TEMPLATE_LIST() : string { return 'template/template-list'}
    public static get DOWNLOAD_CAMPAIGN_REPORT() : string { return 'campaign/download-campaign-report'}
    public static get FETCH_CAMPAIGN_STATS_INDIVIDUAL() : string { return 'dashboard/fetch-campaign-stats-individual'}
    public static get EMAIL_TEMPLATE_DEFAULT_LIST() : string { return 'template/template-default-list'}
    public static get SAVE_TEMPLATE_DEFAULT_USER() : string { return 'template/save-template-default-user'}
    public static get DISABLE_CAMPAIGN() : string { return 'campaign/disable-campaign'}
    public static get RESET_PUSH_CONFIGURATION() : string { return 'configuration/reset-configuration'}
    public static get AUDIENCE_CALL() : string { return this.API_URL + 'notification/audience-call'}
    public static get FETCH_CATEGORY() : string { return this.API_URL + 'leads/list-lead-category'}
    public static get DELETE_CATEGORY() : string { return this.API_URL + 'leads/delete-lead-category'}
    public static get CREATE_CATEGORY() : string { return this.API_URL + 'leads/create-lead-category'}
    public static get TAT_SAVE() : string { return this.TAT_URL+'/upsert'}
    public static get TAT_LIST() : string { return this.TAT_URL+'/list'}
    public static get VERIFY_URL() : string { return this.TAT_BASE_URL+'verify-dataflow'}
    public static get PUST_DATA_STORE() : string { return this.TAT_BASE_URL+'save-dataflow'}
    public static get PUST_DATA_LIST() : string { return this.TAT_BASE_URL+'get-dataflow'}
    public static get REMOVE_DATA_LIST() : string { return this.TAT_BASE_URL+'remove-dataflow'}
    public static get REMOVE_TAT() : string { return this.TAT_URL}
    public static get EDIT_TAT() : string { return this.TAT_URL}
    public static get GET_IVR_STATUS() : string { return this.C2C_URL + 'user/check-user-ivr-status'}1
    public static get UPDATE_IVR_STATUS() : string { return this.C2C_URL + 'user/update-agent-status'}
}
