import { Injectable, RendererFactory2, Inject, ViewEncapsulation } from "@angular/core";
import { Title, Meta } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class SeoService {

    link: HTMLLinkElement;
    constructor(
        private titleService: Title,
        private metaTagService: Meta,
        private rendererFactory: RendererFactory2,
        @Inject(DOCUMENT) private document: any,

    ) { }


    setSeoData(title, description){
        this.titleService.setTitle(title);
        this.metaTagService.updateTag(
            { name: 'description', content: description}
        );
        this.removeCanonicalLink();
        this.addTag();
    }

    addTag() {
            const renderer = this.rendererFactory.createRenderer(this.document, {
                id: '-1',
                encapsulation: ViewEncapsulation.None,
                styles: [],
                data: {}
            });
            this.link = this.document.createElement('link');
            this.link.setAttribute('rel', 'canonical');
            this.document.head.appendChild(this.link);
            this.link.setAttribute('href', this.document.URL);
    }


    removeCanonicalLink() {
        
        const renderer = this.rendererFactory.createRenderer(this.document, {
            id: '-1',
            encapsulation: ViewEncapsulation.None,
            styles: [],
            data: {}
        });
        const canonical = document.querySelector("link[rel='canonical']")
        const head = this.document.head;

        if (head === null) {
            throw new Error('<head> not found within DOCUMENT.');
        }
        if (!!canonical) {
            renderer.removeChild(head, canonical);
        }
    }
  
  
}