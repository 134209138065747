import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ReportService } from '../../../../core/services/report.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectService } from '../../../../core/services/project.service';
import { AuthenticationService } from '../../../../core/services/authentication.service';

@Component({
  selector: 'app-download-audience-data',
  templateUrl: './download-audience-data.component.html',
  styleUrls: ['./download-audience-data.component.scss']
})
export class DownloadAudienceDataComponent implements OnInit {
downloadData;
pageNo: number = 1;
dataSize: number = 20;
public totalDataSize: number;
queryParamsFromMenu:any;
dataList:any;
categoryName:any;
  constructor(   
    private reportService: ReportService,
    private route:ActivatedRoute,
    private router:Router,
    private location:Location,
    private projectService:ProjectService,
    private authenticService:AuthenticationService
    ) { }

  ngOnInit() {
    this.fetchCategory();
  }

  fetchCategory() {        
    this.dataList = [];
    this.projectService.fetchCategory({}).then(data => {
        if (data) {
          const dataList = data
            let id = this.authenticService.getUserId();
                if(this.authenticService.getUserRole() != 1){
                for(let i=0; i<dataList.length; i++){
                    if(dataList[i]){
                    let getUserList = dataList[i].userList.find(userid=>userid == id);
                    if(getUserList){
                      let checkToPush = this.dataList.find(x=>x.linkRewrite == dataList[i].linkRewrite);
                      if(checkToPush == undefined){
                          this.dataList.push(dataList[i])
                      }
                    }
                }
                }
            } else{
              this.dataList = dataList
            }
            let checkCat = localStorage.getItem('downloadDataCat');
            if(checkCat){
              this.categoryName = checkCat;
              this.loadDownloadData();
              return;
            }
            let checkForAudCat = this.dataList.find(x=>x.linkRewrite == 'audience');
                    if(checkForAudCat){
                        this.categoryName='audience';
                    } else{
                        this.categoryName=this.dataList[0].linkRewrite;
                    }
            this.loadDownloadData();
        }
    })
}

  back()
  {
      this.location.back();
  }

  loadDownloadData(){
    localStorage.setItem('downloadDataCat',this.categoryName); 
    this.reportService.AudienceDataDownload(this.categoryName,'page='+this.pageNo+'&'+'size='+this.dataSize).subscribe(data=>{
      if(data.status){
      this.downloadData = data.data.data;
      this.totalDataSize = data.data.totalElements;
      }
    },(error)=>{
      console.log(error);
    })

  }

  public dataForPaging(event) {
    this.pageNo = event;
    this.loadDownloadData();
}

}
