<main>
    <section class="inner-page">
        <div class="inner-page-header">
            <div class="container-fluid">
                <div class="row mobil_nowWrap">
                    <div class="col-md-6">
                        <h4>
                            <a class="back-link" (click)="back()"><img src="assets/images/angle-left.svg"></a>
                            Catalogues</h4>
                    </div>
                    <div *ngIf="isSubscribe" class="col-md-6 text-right d-flex j-end">
                        <a class="btn white_add_btn d-flex c-align" [routerLink]="['/admin/edit-catalogue']" tooltip="Create Catalogue" [tooltipAnimation]="true" tooltipPlacement="top">
                            <img src="assets/images/plus-icon.png"> &nbsp; Add
                        </a>
                       
                    </div>
                </div>
            </div>
        </div>
        <div class="inner-page-body">
            <div *ngIf="isSubscribe" class="container-fluid">
                <div class="table-responsive table-height-scroll table-reports table-height-scroller">
                    <table class="table">
                        <tbody class="norecord-handling">
                            <tr>
                                <th width="20">S.No.</th>
                                <th width="110">Catalogue Id</th>
                                <th width="200">Name</th>
                                <th>Without Price Link</th>
                                <th>With Price Link</th>
                                <th class="text-center">Action</th>
                            </tr>
                            <tr *ngFor="let item of catalogueList; let i = index;">
                                <td>{{i + 1}}</td>
                                <td>{{item.id}}</td>
                                <td class="text-capitalize">{{item.title}}</td>
                                <td class="copyLink"><span tooltip="{{item.withoutPriceLink}}" [tooltipAnimation]="true" tooltipPlacement="top" (click)="copyText(item.withoutPriceLink)">
                                    <p>{{item.withoutPriceLink}}</p>
                                    <span>&nbsp;<img src="assets/images/copy.svg" width="12"></span></span>&nbsp;
                                    <span tooltip="PDF" tooltipPlacement="top" (click)="downloadPdf(item.withoutPriceLink);" class="pdf">
                                        <img class="img" src="assets/images/file-pdf-1.svg" width="12" />
                                    </span>&nbsp;
                                    <span *ngIf="isShortUrlSubscribe" tooltip="Get Short URL" tooltipPlacement="top" (click)="getShortUrl(item.withoutPriceLink);" class="pdf">
                                        <img class="img" src="assets/images/link.svg" width="14" />
                                    </span>
                                </td>
                                <td class="copyLink"> <span tooltip="{{item.withPriceLink}}" [tooltipAnimation]="true" tooltipPlacement="top" (click)="copyText(item.withPriceLink)">
                                    <p>{{item.withPriceLink}}</p>
                                    <span>&nbsp;<img src="assets/images/copy.svg" width="12"></span></span>&nbsp;
                                    <span tooltip="PDF" tooltipPlacement="top" (click)="downloadPdf(item.withPriceLink);" class="pdf">
                                        <img class="img" src="assets/images/file-pdf-1.svg" width="12" />
                                    </span>&nbsp;
                                    <span *ngIf="isShortUrlSubscribe" tooltip="Get Short URL" tooltipPlacement="top" (click)="getShortUrl(item.withPriceLink)" class="pdf">
                                        <img class="img" src="assets/images/link.svg" width="14" />
                                    </span>
                                </td>
                                <td class="text-center min_width_120">
                                    <a class="c-pointer" (click)="showQRcode(item.id)" >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-qr-code" viewBox="0 0 16 16">
                                            <path d="M2 2h2v2H2V2Z"/>
                                            <path d="M6 0v6H0V0h6ZM5 1H1v4h4V1ZM4 12H2v2h2v-2Z"/>
                                            <path d="M6 10v6H0v-6h6Zm-5 1v4h4v-4H1Zm11-9h2v2h-2V2Z"/>
                                            <path d="M10 0v6h6V0h-6Zm5 1v4h-4V1h4ZM8 1V0h1v2H8v2H7V1h1Zm0 5V4h1v2H8ZM6 8V7h1V6h1v2h1V7h5v1h-4v1H7V8H6Zm0 0v1H2V8H1v1H0V7h3v1h3Zm10 1h-1V7h1v2Zm-1 0h-1v2h2v-1h-1V9Zm-4 0h2v1h-1v1h-1V9Zm2 3v-1h-1v1h-1v1H9v1h3v-2h1Zm0 0h3v1h-2v1h-1v-2Zm-4-1v1h1v-2H7v1h2Z"/>
                                            <path d="M7 12h1v3h4v1H7v-4Zm9 2v2h-3v-1h2v-1h1Z"/>
                                          </svg>
                                        </a>  &nbsp; 
                                    <a class="c-pointer" [routerLink]="['/admin/edit-catalogue/'+item.id]" >
                                    <img class="img" src="assets/images/pinkedit.png" />
                                    </a>  &nbsp;
                                    <a href="javascript:void(0)" (click)="copyCatalogue(item)" tooltip="Copy Catalogue" tooltipPlacement="top">
                                        <img src="assets/images/copy.svg" class="width_13">
                                    </a>
                                    &nbsp; 
                                    <a class="c-pointer" (click)="openModalDelete(item.id)">
                                    <img class="img" src="assets/images/delete.png" />
                                    </a>
                                </td>
                            </tr>
                            <tr><td *ngIf="catalogueList.length == 0" class="no-records text-center" colspan="6">No Records Yet</td></tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <app-page-disable *ngIf="!isSubscribe"></app-page-disable>
        </div>
    </section>
</main>

<ng-template #copyCatalogueProp>
        <div class="forgot-password-modal">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Copy Catalogue</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <input type="text" class="form-control" placeholder="Catalogue Name" #catalogName>
                </div>
                <div class="form-group">
                    <input type="text" class="form-control" placeholder="Catalogue Handle" #catalogHandle>
                </div>
                <div class="form-group mb-0">
                    <button class="btn btn-dark" (click)="submitCopyCatalogue(catalogName.value,catalogHandle.value)">Submit</button>
                </div>
            </div>
        </div>
</ng-template>

<ng-template #deleteConfirm>
    <!-- delete popup -->
    <form>
        <div class="forgot-password-modal">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Delete Catalogue</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body modal-user-management">
                <div class="form-group mb-0">
                    <p class="pt-2">Are you sure, you want to delete this catalogue?</p>
                    <div class="d-flex justify-content-end mt-5">
                        <div><button class="btn remove-user-btn mr-4" (click)="modalRef.hide()" type="button">Dismiss</button></div>
                        <div><button class="btn save-btn" type="button" (click)="deleteCatalogue()">Yes</button></div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #showQrCode>
    <!-- delete popup -->
    <form>
        <div class="forgot-password-modal">
            <div class="modal-header">
                <h4 class="modal-title pull-left">QR codes's for {{selectedCatalogue.title}}</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body modal-user-management">
                <div class="form-group mb-0">
                    <div class = "row">
                        <div class="col-6 text-center"> 
                             <div>    
                                <qr-code [value]="selectedCatalogue.withPriceLink" [size]="130"></qr-code>
                                <qr-code class="d-none" [size]="500" id="qrScanner" [value]="selectedCatalogue.withPriceLink"></qr-code>
                                </div>
                             <h5><button class="btn btn-dark ctlg_btn" type="button" (click)="downloadQr()"><i class="icon-download"></i></button> With price</h5>
                        </div>
                        <div class="col-6 text-center"> 
                             <div>    
                                <qr-code [value]="selectedCatalogue.withoutPriceLink" [size]="130"></qr-code>
                                <qr-code class="d-none" [size]="500" id="qrScannerwithoutPrice" [value]="selectedCatalogue.withoutPriceLink"></qr-code>
                            </div>
                             <h5><button class="btn btn-dark ctlg_btn" type="button" (click)="downloadQrWithoutPrice()"><i class="icon-download"></i></button> Without price</h5>
                        </div>
                   
                  
                    </div>
                    <div class="d-flex justify-content-end mt-5">
                        <div><button class="btn remove-user-btn mr-4" (click)="modalRef.hide()" type="button">Dismiss</button></div>
                   
                    </div>
                </div>
            </div>
        </div>
    </form>
</ng-template>

