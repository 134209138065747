<main>
  <section class="inner-page">
    <div class="inner-page-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6">
            <h4>Upload Confirm</h4>
          </div>
        </div>
      </div>
    </div>

    


  </section>

  <section class="inner-page">

    <div class="inner-page-body project-integration">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="table-reports">
              <div class="table-body">
                <div class="table-responsive table-height-scroll">
                  <table class="table table-bordered">
                    <tbody>
                      <tr>
                        <th class="width_150">Success:</th>
                        <td class="colorSuccess">{{this.uploadfaildata.noOfSuccessAudience}}
                        </td>
                      </tr>
                      <tr>
                        <th class="width_150">Fail:</th>
                        <td class="textblue theme_txt_color">{{this.uploadfaildata.noOfFaildRecords}}
                          
                        </td>
                      </tr>
                    </tbody>
                  </table>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="table-reports">
              <div class="table-body" *ngIf="this.uploadfaildata.noOfFaildRecords>0">
                <div class="table-responsive table-height-scroll text-center">
                  <table class="table">
                    <tbody>
                      <tr class="gray-back">
                        <th>S.No.</th>
                        <th>Audience id</th>
                        <th>Param Key</th>
                        <th>Param Value</th>
                        <th>Faild Error</th>
                      </tr>
                      <tr *ngFor="let item of records; let i = index">
                        <td>{{i+1}}</td>
                        <td>{{item.audienceId}}</td>
                        <td>{{item.paramKey}}</td>
                        <td>{{item.paramValue}}</td>
                        <td>
                          {{item.faildError}}
                        </td>
                      </tr>
                      <tr>
                        <td *ngIf="this.records==null" class="no-records" colspan="5">No Records Yet</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="table-footer footer-gray p_b_46" >

                <button type="submit" class="btn btn-dark pull-right btn_space_bulk_upld" (click)="saveConfirmupload()" >Confirm</button>
                <button type="submit" (click)="cancelUpload()" class="btn btn-border back pull-right">Back</button>
               
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="inner-page-footer"></div>

  </section>
</main>


<ng-template #confirm>
  
  <form>
    <div class="forgot-password-modal">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Upload Status</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="Done()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body modal-user-management">
        <div class="form-group mb-0 pb-4 pt-4">
          <div class="icon-status text-center">
            <span class="check-icon"></span>
          </div>

          <div class="mt-2 text-center">
            <span class="pt-2 text-center add-btngreen">SUCCESS</span>
          </div>
          
          <!-- <p class="pt-2 text-center">{{successymsg}}</p> -->
          <p class="pt-2 text-center">Record uploaded succesfully. Audience data will appear in 5-10 mins</p>
          <div class="text-center  mt-1 mb-0">
            <div class="mt-4 mb-0">
              <button class="btn-dark" type="button" (click)="Done()">Done</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

</ng-template>



<ng-template #error>
  
  <form>
    <div class="forgot-password-modal">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Upload Status</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body modal-user-management">
        <div class="form-group mb-0 pb-4 pt-4">
          <div class="icon-status text-center">
            <span class="times-circle-icon"></span>
          </div>

          <div class="mt-2 text-center">
            <span class="pt-2 text-center add-btnred">ERROR</span>
          </div>
          <p class="pt-2 text-center">{{errormsg}}</p>
          <div class="text-center  mt-1 mb-0">
            <div class="mt-4 mb-0">
              <button class="btn-dark" type="button" (click)="Done()">Try Again</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

</ng-template>