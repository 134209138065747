import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { EmailEditorComponent } from "angular-email-editor";

@Component({
  selector: 'templateBuilder-app',
  templateUrl: './templateBuilder.component.html',
  styleUrls: ['./templateBuilder.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class TemplateBuilderComponent implements OnInit {
    @ViewChild(EmailEditorComponent)
    private emailEditor: EmailEditorComponent;
    constructor(
        
    ) { 
      
    }

    ngOnInit() {
        
    }


   

}
