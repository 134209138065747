<main>
    <section class="inner-page">
        <div class="inner-page-header">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-5 align-self-center">
                        <h4>
                            <a class="back-link" (click)="back()"><img src="assets/images/angle-left.svg"></a>
                            Audience Report
                            <!-- <p class="font-10">Note:- Reports are based on leads updated date.</p> -->
                        </h4>
                    </div>
                    <div class="col-7 text-right d-flex justify-content-end">
                        <select class="form-control report_page_cat_input" (change)="refreshAllReportData()" [(ngModel)]="categoryName">
                            <option *ngFor="let cat of dataList" [value]="cat.linkRewrite">{{cat.name}}</option>
                        </select>
                        <button class="btn btn-dark m_r_15" (click)="downloadXl()" tooltip="Send Report To Email" tooltipplacement="top">
                            <i class="icon-download"></i>
                        </button>
                        <button (click)="downloadFile()" class="btn btn-dark"><i class="icon-download"></i> Download Excel</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="inner-page-body border-bottom-0">
            <div class="container-fluid">

                <div class="table-reports">
                    <div class="row c-align m-b-20">
                        <div class="col-5">
                            <h5 class="m-b-0">Follow up 
                                <span class="icon_vertical" (click)="vertical_tab(positionOfTblFollow,'followUp')">
                                    <img src="assets/images/vertical-to-horizontal.png" *ngIf="positionOfTblFollow == 'vertical'">
                                    <img src="assets/images/horizontal-to-vertical.png" *ngIf="positionOfTblFollow == 'horizontal'">
                                </span>
                            </h5>
                           
                        </div>

                        <div class="col-7 d-flex-justify-end">
                            <input type="text" class="form-control date-icon modal-space width_260 f-right" readonly autocomplete="off" ngxDaterangepickerMd [(ngModel)]="followUpfilterDateRange" [locale]="{format: 'DD/MM/YYYY'}" [alwaysShowCalendars]="true" [ranges]="ranges"
                                [autoApply]="false" [opens]="'left'" placeholder="Select Date Range" (datesUpdated)="updateFollowUpDateRange()">
                        </div>
                    </div>

                    <div class="table-body">
                        <div class="table-responsive table-height-scroll text-center">
                            <table class="table" #followUpReports>
                                <tbody class="norecord-handling" *ngIf="followUpList.length>0">
                                    <tr class="d-none">
                                        <th colspan="5" align="center" class="font_16">Follow Up Report</th>
                                        <!-- <p class="font_10">Note:- Reports are based on leads updated date.</p> -->
                                    </tr>
                                    <ng-container *ngIf="positionOfTblFollow == 'horizontal'">
                                        <tr>
                                            <th>Name</th><th *ngFor="let list of followUpList">
                                                <a class="w1" href="javascript:void(0)" (click)="goToDetail('followUpByUser',followUpFromDate,followUpToDate,list.userIdEncode,list.userName,'FOLLOW UP')">    
                                                    {{list.userName}}
                                                </a>
                                            </th><td>Total</td>
                                        </tr>
                                        <tr>
                                            <th>Missed</th>
                                            <td *ngFor="let list of followUpList">
                                                <a class="w1" *ngIf="list.Missed>0" href="javascript:void(0)" (click)="goToDetail('followUpByUserMissed',followUpFromDate,followUpToDate,followUpFromDate,todayDate,list.userIdEncode,list.userName,'FOLLOW UP')">
                                                    {{list.Missed}}
                                                    </a>
                                                    <span  *ngIf="list.Missed==0">
                                                        {{list.Missed}}
                                                        </span>
                                            </td>
                                            <td>{{totalMissed}}</td>
                                        </tr>
                                        <tr>
                                            <th>Completed</th>
                                            <td *ngFor="let list of followUpList">
                                                <a class="w1" *ngIf="list.Completed>0" href="javascript:void(0)" (click)="goToDetail('followUpByUserCompleted',followUpFromDate,followUpToDate,followUpFromDate,todayDate,list.userIdEncode,list.userName,'COMPLETED')">
                                                    {{list.Completed}}
                                                </a>
                                                <span  *ngIf="list.Completed==0">
                                                    {{list.Completed}}
                                                    </span>
                                            </td>
                                            <td>{{totalCompleted}}</td>
                                        </tr>
                                        <tr>
                                            <th>Upcoming</th>
                                            <td *ngFor="let list of followUpList">
                                                <a class="w1" *ngIf="list.UpComming>0" (click)="goToDetail('followUpByUserUpcoming',followUpFromDate,followUpToDate,todayDate,followUpToDate,list.userIdEncode,list.userName,'FOLLOW UP')">
                                                    {{list.UpComming}}
                                                    </a>
                                                    <span  *ngIf="list.UpComming==0">
                                                    {{list.UpComming}}
                                                    </span>
                                            </td>
                                            <td>{{totalOverdue}}</td>
                                        </tr>
                                        <tr>
                                            <th>Shared</th>
                                            <td *ngFor="let list of followUpList">
                                                <a class="w1" *ngIf="list.Shared>0" (click)="goToDetail('followUpByUserUpcoming',followUpFromDate,followUpToDate,todayDate,followUpToDate,list.userIdEncode,list.userName,'FOLLOW UP')">
                                                    {{list.Shared}}
                                                    </a>
                                                    <span  *ngIf="list.Shared==0">
                                                    {{list.Shared}}
                                                    </span>
                                            </td>
                                            <td>{{totalOverdue}}</td>
                                        </tr>
                                    </ng-container>

                                    <ng-container *ngIf="positionOfTblFollow == 'vertical'">
                                    <tr>
                                        <th>Name</th>
                                        <th>Missed</th>
                                        <th>Completed</th>
                                        <th>Upcoming</th>
                                        <th>Shared</th>
                                    </tr>
                                    <ng-container *ngIf="followUpList">
                                        <tr *ngFor="let list of followUpList" class="follow-up-middle-row">
                                            <td>
                                                <a class="w1" href="javascript:void(0)" (click)="goToDetail('followUpByUser',followUpFromDate,followUpToDate,list.userIdEncode,list.userName,'FOLLOW UP')">    
                                                    {{list.userName}}
                                                </a>
                                            </td>
                                            <td>
                                                <a class="w1" *ngIf="list.Missed>0" href="javascript:void(0)" (click)="goToDetail('followUpByUserMissed',followUpFromDate,followUpToDate,followUpFromDate,todayDate,list.userIdEncode,list.userName,'FOLLOW UP')">
                                                {{list.Missed}}
                                                </a>
                                                <span  *ngIf="list.Missed==0">
                                                    {{list.Missed}}
                                                    </span>

                                            </td>
                                            <td>
                                                    <a class="w1" *ngIf="list.Completed>0" href="javascript:void(0)" (click)="goToDetail('followUpByUserCompleted',followUpFromDate,followUpToDate,followUpFromDate,todayDate,list.userIdEncode,list.userName,'COMPLETED')">
                                                    {{list.Completed}}
                                                </a>
                                                <span  *ngIf="list.Completed==0">
                                                    {{list.Completed}}
                                                    </span>
                                            </td>
                                            <td>
                                                    <a class="w1" *ngIf="list.UpComming>0" href="javascript:void(0)" (click)="goToDetail('followUpByUserUpcoming',followUpFromDate,followUpToDate,todayDate,followUpToDate,list.userIdEncode,list.userName,'FOLLOW UP')">
                                                    {{list.UpComming}}
                                                </a>
                                                <span  *ngIf="list.UpComming==0">
                                                {{list.UpComming}}
                                                </span>
                                            </td>

                                            <td>
                                                
                                                    <a class="w1" *ngIf="list.Shared>0" href="javascript:void(0)" (click)="goToDetail('followUpByUserSharing',followUpFromDate,followUpToDate,list.userIdEncode,list.userName,'updated')">
                                                    {{list.Shared}}
                                                </a>
                                                <span  *ngIf="list.Shared==0">
                                                {{list.Shared}}
                                                </span>
                                            </td>

                                            
                                        </tr>
                                        <tr class="table-footer-follow-up">
                                            <td>Total</td>
                                            <!-- <td>{{totalToday}}</td> -->
                                            <td>{{totalMissed}}</td>
                                            <td>{{totalCompleted}}</td>
                                            <td>{{totalOverdue}}</td>
                                            <td>{{totalShared}}</td>
                                        </tr>
                                        <tr class="table-footer-follow-up d-none">
                                            <td colspan="5"></td>
                                        </tr>
                                    </ng-container>
                                    </ng-container>
                                </tbody>

                                <tr *ngIf="followUpList.length == 0">
                                    <td class="no-records" colspan="5">No Records Yet</td>
                                </tr>
                            </table>


                        </div>
                    </div>
                </div>

                <div class="table-reports">
                    <div class="row pb-3">
                        <div class="col-5">
                            <h5>Lead Report <span>(All Channels)</span>
                                <span class="icon_vertical" (click)="vertical_tab(positionOfTblLeadReport,'leadReport')">
                                    <img src="assets/images/vertical-to-horizontal.png" *ngIf="positionOfTblLeadReport == 'vertical'">
                                    <img src="assets/images/horizontal-to-vertical.png" *ngIf="positionOfTblLeadReport == 'horizontal'">
                                </span>
                            </h5>
                         
                        </div>
                        <div class="col-7 d-flex-justify-end">
                            <input type="text" class="form-control date-icon modal-space width_260 f-right" readonly autocomplete="off" ngxDaterangepickerMd [(ngModel)]="filterDateRange" [locale]="{format: 'DD/MM/YYYY'}" [alwaysShowCalendars]="true" [ranges]="ranges" [autoApply]="false" [opens]="'left'" placeholder="Select Date Range" (datesUpdated)="updateLeadReport()">
                        </div>
                    </div>
                    <div class="table-body">
                        <div class="table-responsive reprot-table">
                            

                            <ng-container *ngIf="positionOfTblLeadReport == 'horizontal'">
                                <table class="table" #leadReport>
                                    <tr class="d-none">
                                        <th colspan="9" align="center" class="font_16">Lead Report</th>
                                    </tr>
                                <tr *ngIf="audienceLeadData.length > 0" class="leadReportHorizontal">
                                    <th><span class="top_name">Name</span>
                                        <table class="table itemTableTd">
                                            <tr *ngFor="let item of leasdStatusList">
                                                <td>
                                                    {{item}}
                                                </td>
                                            </tr>
                                        </table>
                                    </th>
                                    <!-- <th class="text-capitalize" *ngFor="let item of leasdStatusList">{{item}}</th> -->
                                    <ng-container *ngFor="let leadData of getLeadMangerName();">
                                    <th *ngIf="audienceLeadDataGroup[leadData][1].parentUserName != 'hidden' "><span class="top_name">{{leadData != 'null' ? audienceLeadDataGroup[leadData][1].parentUserName : 'Other'}}</span>
                                        <table class="table">
                                            <tr *ngFor="let count of audienceLeadDataGroup[leadData][0].count; let i = index">
                                                <td>
                                                    {{count}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{{audienceLeadDataGroup[leadData][1].total}}</td>
                                            </tr>
                                        </table>
                                    </th>
                                    <th *ngFor="let item of audienceLeadDataGroup[leadData] | slice:1">
                                        <a href="javascript:void(0)" (click)="goToDetailLeadReport('byuser',fromDate,toDate,item.userIdEncode,item.userName,sortByDate)"><span class="top_name">&nbsp;{{item.userName}}</span></a>
                                    <table class="table">
                                        <tr *ngFor="let count of item.count; let i = index">
                                            <td>
                                                <ng-container *ngIf="count != '0'">
                                                <a href="javascript:void(0)" *ngIf="leasdStatusList[i] != 'Total'" (click)="goToDetailLeadReport('byStatus',fromDate,toDate,item.userIdEncode,item.userName,leasdStatusList[i],sortByDate)" >{{count}}</a>
                                                <a href="javascript:void(0)" *ngIf="leasdStatusList[i] == 'Total'" (click)="goToDetailLeadReport('byuser',fromDate,toDate,item.userIdEncode,item.userName,sortByDate)">{{count}}</a>
                                                </ng-container>
                                                <ng-container *ngIf="count == '0'">
                                                    <a href="javascript:void(0)" *ngIf="leasdStatusList[i] != 'Total'">{{count}}</a>
                                                    <a href="javascript:void(0)" *ngIf="leasdStatusList[i] == 'Total'">{{count}}</a>
                                                </ng-container>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {{item.total}}
                                            </td>
                                        </tr>
                                    </table>
                                    </th>
                                    
                                    </ng-container>
                                </tr>
                                </table>
                            </ng-container>

                            <ng-container *ngIf="positionOfTblLeadReport == 'vertical'">
                                <table class="table" #leadReport>
                                        <thead *ngIf="audienceLeadData.length > 0">
                                    <tr class="d-none">
                                        <th colspan="9" align="center" class="font_16">Lead Report</th>
                                    </tr>
                                    <tr>
                                        <th>Name</th>
                                        <th class="text-capitalize" *ngFor="let item of leasdStatusList">{{item}}</th>
                                    </tr>
                                </thead>
                                <tbody *ngFor="let leadData of getLeadMangerName();">
                                    <tr *ngIf="audienceLeadDataGroup[leadData][1].parentUserName != 'hidden' ">
                                        <th class="text-capitalize">{{leadData != 'null' ? audienceLeadDataGroup[leadData][1].parentUserName : 'Other'}}</th>
                                        <th *ngFor="let count of audienceLeadDataGroup[leadData][0].count; let i = index">
                                            {{count}}
                                        </th>
                                        <th>{{audienceLeadDataGroup[leadData][0].total}}</th>
                                    </tr>
                                    <tr *ngFor="let item of audienceLeadDataGroup[leadData] | slice:1">
                                        <td class="text-capitalize">
                                            <!-- <a class="w1" (click)="goToDetailLeadReport(fromDate,toDate,item.userIdEncode,item.userName)" [routerLink]="['/admin/audience/view-audience/lead/'+fromDate+'/'+toDate+'/'+item.userIdEncode+'&&'+item.userName]" [queryParams]="{datetype:sortByDate,telecaller:true}">{{item.userName}}</a> -->
                                            <a class="w1" href="javascript:void(0)" (click)="goToDetailLeadReport('byuser',fromDate,toDate,item.userIdEncode,item.userName,sortByDate)">{{item.userName}}</a>
                                        </td>
                                        <td *ngFor="let count of item.count; let i = index">
                                            <ng-container *ngIf="count != '0'">
                                            <a *ngIf="leasdStatusList[i] != 'Total'" (click)="goToDetailLeadReport('byStatus',fromDate,toDate,item.userIdEncode,item.userName,leasdStatusList[i],sortByDate)">{{count}}</a>
                                            <a *ngIf="leasdStatusList[i] == 'Total'" (click)="goToDetailLeadReport('byuser',fromDate,toDate,item.userIdEncode,item.userName,sortByDate)">{{count}}</a>
                                            </ng-container>

                                            <ng-container *ngIf="count == '0'">
                                                <a *ngIf="leasdStatusList[i] != 'Total'">{{count}}</a>
                                                <a *ngIf="leasdStatusList[i] == 'Total'">{{count}}</a>
                                            </ng-container>
                                        </td>
                                        <td>
                                            {{item.total}}
                                        </td>
                                    </tr>
                                    <tr class="table-footer-follow-up d-none">
                                        <td colspan="9"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </ng-container>

                        <div *ngIf="audienceLeadData.length == 0">
                            <div class="no-records">No Records Yet</div>
                        </div>
                        </div>
                    </div>
                </div>

                <div class="table-reports">
                    <h5>Lead report as per source
                        <span class="icon_vertical" (click)="vertical_tab(positionOfTbSource,'leadSource')">
                            <img src="assets/images/vertical-to-horizontal.png" *ngIf="positionOfTbSource == 'vertical'">
                            <img src="assets/images/horizontal-to-vertical.png" *ngIf="positionOfTbSource == 'horizontal'">
                        </span>
                    </h5>
                    
                    <div class="table-body">
                        <div class="table-responsive">
                            <table class="table" #sourceReports *ngIf="audienceSourceData">
                                <tbody>
                                    <tr class="d-none">
                                        <th colspan="9" align="center" class="font_16">Lead report as per source</th>
                                    </tr>
                                    <ng-container *ngIf="positionOfTbSource == 'horizontal'">
                                        <tr class="sourceHorizontalTbl">
                                            <th *ngIf="leasdStatusList.length > 0"><span class="top_name">Source</span>
                                                <table class="table itemTableTd">
                                                    <tr *ngFor="let item of leasdStatusList">
                                                        <td>{{item}}</td>
                                                    </tr>
                                                </table>
                                            </th>
                                            <th *ngFor="let item of audienceSourceData">
                                                <!-- <a class="" [routerLink]="['/admin/audience/view-audience/source/'+fromDate+'/'+toDate+'/'+item.source+'&&'+item.sourceId]" [queryParams]="{datetype:sortByDate,source:true}"><span class="top_name">{{item.source}}</span></a> -->
                                                <a href="javascript:void(0)" (click)="goToDetailBySource('sourceName',fromDate,toDate,item.source,item.sourceId,sortByDate)"><span class="top_name">{{item.source}}</span></a>
                                                <table class="table">
                                                <tr *ngFor="let count of item.count; let i = index">
                                                    <td>
                                                        <ng-container *ngIf="count != '0'">
                                                        <a href="javascript:void(0)" *ngIf="leasdStatusList[i] != 'Total'" (click)="goToDetailBySource('leadStatus',fromDate,toDate,item.source,item.sourceId,leasdStatusList[i],sortByDate)">{{count}}</a>
                                                        <a href="javascript:void(0)" *ngIf="leasdStatusList[i] == 'Total'" (click)="goToDetailBySource('sourceName',fromDate,toDate,item.source,item.sourceId,sortByDate)" class="font-weight-bold">{{count}}</a>
                                                        </ng-container>

                                                        <ng-container *ngIf="count == '0'">
                                                            <a href="javascript:void(0)" *ngIf="leasdStatusList[i] != 'Total'">{{count}}</a>
                                                            <a href="javascript:void(0)" *ngIf="leasdStatusList[i] == 'Total'" class="font-weight-bold">{{count}}</a>
                                                        </ng-container>
                                                    </td>
                                                </tr>
                                            </table>
                                            </th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="positionOfTbSource == 'vertical'">
                                    <tr *ngIf="leasdStatusList.length > 0">
                                        <th>Source</th>
                                        <th class="text-capitalize" *ngFor="let item of leasdStatusList">{{item}}</th>
                                    </tr>
                                    <tr *ngFor="let item of audienceSourceData">
                                        <td>
                                            <!-- <a class="w1" [routerLink]="['/admin/audience/view-audience/source/'+fromDate+'/'+toDate+'/'+item.source+'&&'+item.sourceId]" [queryParams]="{datetype:sortByDate,source:true}">{{item.source}}</a> -->
                                            <a class="w1" href="javascript:void(0)" (click)="goToDetailBySource('sourceName',fromDate,toDate,item.source,item.sourceId,sortByDate)">{{item.source}}</a>
                                        </td>
                                        <td *ngFor="let count of item.count; let i = index">
                                            <ng-container *ngIf="count == '0'">
                                            <a href="javascript:void(0)" *ngIf="leasdStatusList[i] != 'Total'">{{count}}</a>
                                            <a href="javascript:void(0)" *ngIf="leasdStatusList[i] == 'Total'" class="font-weight-bold">{{count}}</a>
                                            </ng-container>
                                            <ng-container *ngIf="count != '0'">
                                            <a href="javascript:void(0)" *ngIf="leasdStatusList[i] != 'Total'" (click)="goToDetailBySource('leadStatus',fromDate,toDate,item.source,item.sourceId,leasdStatusList[i],sortByDate)">{{count}}</a>
                                            <a href="javascript:void(0)" *ngIf="leasdStatusList[i] == 'Total'" (click)="goToDetailBySource('sourceName',fromDate,toDate,item.source,item.sourceId,sortByDate)" class="font-weight-bold">{{count}}</a>
                                            </ng-container>
                                        </td>
                                    </tr>
                                    <tr class="table-footer-follow-up d-none">
                                        <td colspan="9"></td>
                                    </tr>
                                    </ng-container>
                                </tbody>
                                <tr *ngIf="audienceSourceData.length == 0">
                                    <td class="no-records" colspan="9">No Records Yet</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <ng-template #download_xl_modal>
        <div class="forgot-password-modal">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Audience Report</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body modal-user-management">
                <!-- <div class="form-group">
                    <ng-select  [(ngModel)]="users" class="form-control auto_height_select" [multiple]="true" >
                        <ng-option *ngFor="let item of usersNameList" [value]="item.id">
                            {{item.userName}}
                        </ng-option>
                    </ng-select>
                </div> -->
                <div class="form-group">
                    <input type="text" class="form-control" [(ngModel)]="emailAddress" placeholder="Enter your email for report delivery.">
                </div>
                <div class="form-group d-flex j-right">
                    <button class="btn btn-dark downlo_xl_cnfrm" type="button" (click)="send_email()">Send Email</button>    
                </div>
            </div>
        </div>
    </ng-template>
</main>