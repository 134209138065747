import { Component, OnInit, TemplateRef, ViewChild,NgZone } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ChartOptions, ChartOptionsCount } from '../../../../core/modal/chartOptions';
import { ProjectService } from '../../../../core/services/project.service';
import { noWhitespaceValidator } from '../../../../../app/shared/models/no-whitespace-validator';
import { ReportService } from '../../../../core/services/report.service';
import { DatePipe, Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Global } from '../../../../../app/shared/models/global.model';
import { DatePickerObj } from '../../../../core/modal/datePicker';
import { AttributeObj, SourceObj } from '../../../../core/modal/project';
import { SpinnerService } from '../../../../core/services/spinner.service';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { AudienceFilter } from '../../../../core/services/cache.modal';

@Component({
    selector: 'app-analysis',
    templateUrl: './analysis.component.html',
    styleUrls: ['./analysis.component.scss','../dashboard/dashboard.component.scss']
})
export class AnalysisComponent implements OnInit {
    public leadReportChartOptions: Partial<ChartOptions>[] = [];
    leadReportChartOptionsDetail:any= [] ;
    public siteForm: FormGroup;
    public inputParams: AttributeObj[];
    public leadReportData: any;
    public selectedleadReportId: number;
    public isNoDataLeadChart: boolean ;
    addAction:boolean = false;
    public modalRef: BsModalRef;
    public sourseList: SourceObj[] = [];
    leadReportListData:any;
    ranges: any;
    maxDate: any;
    refilEditVal:any;
    leadReportDays: DatePickerObj;
    waitCount: number = 0;
    @ViewChild('addLeadReports') addLeadReports: TemplateRef<any>;
    @ViewChild('addLeadReportBlock') addLeadReportBlock: TemplateRef<any>;
    @ViewChild('leadReportDeleteConfirm') leadReportDeleteConfirm: TemplateRef<any>;
    defaultDate:boolean = true;
    entryDateId:number ;
    isSubmited:boolean = false;
    showFilter:boolean = false;
    selectedFilters:any = new Array();
    sourceFormIdList:any;
    addReport:boolean = true;
    notCloseOnSelect:any = true;
    reportDetailData:any;
    loadingData:boolean = false;
    sortByDate:any;
    maxRange:any;
    queryParamsFromMenu:any;
    parentMenu:string;
    audienceFilter: AudienceFilter[] = []
    dataList:any=[];
    categoryName:any;
    constructor(
        private reportService: ReportService,
        private spinner: SpinnerService,
        private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private projectService: ProjectService,
        private modalService:BsModalService,
        private datePipe: DatePipe,
        private router: Router,
        private global: Global,
        private location:Location,
        private ngZone: NgZone,
        private route:ActivatedRoute,
        private authenticService:AuthenticationService
    ) {
        this.siteForm = this.formBuilder.group({
            reportName: [ "", Validators.compose([Validators.required, noWhitespaceValidator, Validators.maxLength(100), Validators.minLength(1) ]) ],
            source: [ "", Validators.compose([Validators.required]) ],
            reportColumnId: [ "", Validators.compose([Validators.required])],
            yaxisColumnId:[ "" ],
            eventTimeId:["",Validators.compose([Validators.required]) ],
            reportType:['',Validators.compose([Validators.required]) ],
            ycolumnSum:['',Validators.compose([Validators.required]) ],
            showToDashboard:['',null],
            defaultSort:['default' ],
            showDataGreater:[0],
            id:[null],
        });
    }

    openModal(addLeadReportBlock: TemplateRef<any>) {
        this.modalRef = this.modalService.show(addLeadReportBlock, { class: "modal-rightblock", backdrop: 'static' });
      }

      filterClose(event?) {            
        this.showFilter = true;

        if(event == ""){
          this.selectedFilters = [];
        }
        
        this.selectedFilters.forEach((element , index) => {          
            if (this.global.Channel.Attributes.filter(x => x.id == element.id).length == 0) {
              this.selectedFilters.splice(index, 1);
            }
        });        
        this.global.Channel.Attributes.forEach(element => {          
            // if (this.selectedFilters.filter(x => x.id == element.id).length == 0) {
            //   this.addFilter(JSON.stringify(element), null, event == 'isFilterApplied');
            // }
        });
        this.sourceFormIdList = this.global.Channel.sourceFormIds;
    }

    back()
    {
        this.location.back();
    }

    ngOnInit() {

        this.fetchCategory();

        this.route.paramMap.subscribe(data=>{
            if(data){
                this.queryParamsFromMenu = data;
            }
        });

        if(this.queryParamsFromMenu && this.queryParamsFromMenu['params'].dynamicparam){
            this.parentMenu = this.queryParamsFromMenu['params'].dynamicparam;
          }

        this.route.queryParamMap.subscribe(data=>{
            let cat = data.get('category');
            if(cat){
                this.parentMenu = cat;
            }
        })

        

        this.maxDate = moment();
        this.projectService.getMaxRange().then(data=>{
            this.maxRange = data
        });
        this.ranges = this.global.customDateRange();
        if(this.reportService.leadsDays != undefined){
            this.leadReportDays = this.reportService.leadsDays;
            this.spinner.show();
        } else {
            this.leadReportDays = {
                startDate: moment().subtract(6, 'days'),
                endDate: moment(),
            };
        }
        
        this.getAttributeData();
        this.fetchUserProfile();
    }

    fetchCategory() {   
        if(this.categoryName){
            this.fetchLeadReports();
            return
        };     
        this.dataList = [];
        this.projectService.fetchCategory({}).then(data => {
            if (data) {
                const dataList = data;
                let id = this.authenticService.getUserId();
                if(this.authenticService.getUserRole() != 1){
                for(let i=0; i<dataList.length; i++){
                    if(dataList[i]){
                    let getUserList = dataList[i].userList.find(userid=>userid == id);
                    if(getUserList){
                        let checkToPush = this.dataList.find(x=>x.linkRewrite == dataList[i].linkRewrite);
                        if(checkToPush == undefined){
                            this.dataList.push(dataList[i])
                        }
                                            
                    }
                }
                }
            } else{
                this.dataList = dataList
              }
    
                let analysisCatName = localStorage.getItem("analysisCatName");
                if(this.parentMenu){
                   this.categoryName =  this.parentMenu;
                   localStorage.removeItem("analysisCatName")
                } else if(analysisCatName){
                    this.categoryName = analysisCatName;
                    this.fetchLeadReports();
                    return
                }  else{
                    let checkForAudCat = this.dataList.find(x=>x.linkRewrite == 'audience');
                    if(checkForAudCat){
                        this.categoryName='audience';
                    } else{
                        this.categoryName=this.dataList[0].linkRewrite;
                    }
                }
                if(this.categoryName){
                this.fetchLeadReports();
                }
            }
        })
    }

    fetchUserProfile(){
        this.authenticService.getUser().subscribe(data=>{
            if(data.status){
                this.sortByDate = data.data.sortBy;
            }
        })
    }

    reloadList(event)
    {
        if(event == 'recordSuccess'){
        this.fetchLeadReports()
        }
    }

    getAttributeData(){
        this.projectService.fetchAttributeList().then( data => { 
            if(data){
                this.sourseList = this.projectService.getSourceList();
                this.sourseList.sort((a,b)=>{
                    if(a.pluginName < b.pluginName){
                        return -1;
                    }
                    if(a.pluginName > b.pluginName){
                        return 1;
                    }
                })
                this.getInputParams();
            }
        });
    };
    
    getInputParams(type?) {
        var source = [];
        this.inputParams = [];
        this.sourseList.forEach(element => {
            if(element.pluginId == this.siteForm.controls.source.value){
                source.push(element.pluginName);
            }
        });
        var list = this.projectService.filterAttributeList(source);
        list.forEach(element => {
            this.inputParams.push(element);
            if(element.paramKey == 'entry_date'){
                this.entryDateId = element.id
            }
        });

        this.inputParams.sort((a,b)=>{
            if(a.paramName < b.paramName){
                return -1;
            }
            if(a.paramName > b.paramName){
                return 1;
            }
        });
    }

    addLeadChart(){  
        if(this.leadReportData != undefined && this.leadReportData.length > 0)
        {      
        if(this.leadReportData.length >= 10 ){
            this.toastr.error("Maximum Lead Report Count 10.");
            return;
        }
        }
        this.siteForm.reset();
        this.siteForm.get('source').setValue('');
        this.siteForm.get('eventTimeId').setValue(this.entryDateId);
        this.updateSource();
        this.isSubmited = false;
        this.addAction = true;
        this.modalRef = this.modalService.show(this.addLeadReportBlock, { class: "modal-rightblock", backdrop: 'static' });
    }

    saveLeadReport(){
        this.isSubmited = true;
        if (this.siteForm.invalid ) {
            this.toastr.error('Please fill all mandatory fields');
            return;
        };
        if(this.siteForm.controls.yaxisColumnId.value == ''){
            this.siteForm.controls.ycolumnSum.setValue(0);
        }
        var formData = this.siteForm.value;
        // formData.range = this.datePipe.transform(this.siteForm.value.range.startDate, "yyyy-MM-dd") + '-' + this.datePipe.transform(this.siteForm.value.range.endDate, "yyyy-MM-dd")
        this.reportService.createReport(formData, this.parentMenu).subscribe(data => {
            this.siteForm.reset();
            if(data.status){
                this.toastr.success(data.data,'Success');
                this.reportService.leadReportsData = undefined;
                    this.getInputParams();
                    this.modalRef.hide();
                    this.fetchLeadReports();
            } else {
                this.modalRef.hide();
                this.toastr.error(data.error_msgs,'Error');
            }
        })
    }


    refreshGraph(item,parent)
    {
        this.loadReportDetail(item,parent)
    }


    setLeadReportChart(data,attrStatus?,missedAttr?) : Partial<ChartOptions> {     
        
        if(data.attributeName != '[]' && typeof(data.attributeName) != 'string'){
            data.attributeName = data.attributeName.join();
        }
        data.attributeNameReplaced = ""
        if(data.attributeName.includes("'") == true){
       // data.attributeNameReplaced = data.attributeName.replaceAll("'",""); 
        data.attributeNameReplaced = data.attributeName;
        } 
        var optionsBar : Partial<ChartOptions> = {};
        var optionsBarCount : Partial<ChartOptionsCount> = {};
        var optionsPie : Partial<ChartOptions> = {};
        var optionsStacked : Partial<ChartOptions> = {};
        var total = 0;
        var part = '';
        var stackedSeries : any[] = [];
        data.countArr = []
        data.countArr.data = []
        let storeArrCount = []
        storeArrCount[0]=[]
        part = data.part != undefined ? '-Part '+data.part : '';
        if(attrStatus == 'missedAttribute'){
            data.dataCount = 0;
            data.missedAttribute = true
            if(missedAttr){
                data.misedAttrList = missedAttr.toString();                
            }
        }
        
        if(data.reportType == 0 && data.dataCount > 0){
            if(data.columnOutput == 1 ){
                stackedSeries = data.countsMap ? data.countsMap : [];
            } else {
                stackedSeries = data.countsMap ? data.countsMap : [];
                if(data.countsMap){
                data.countsMap.forEach(element => {
                    total = element.data.reduce((a, b) => a + b, 0);
                    storeArrCount[0].push(element.data);
                });
                } else if(data.count){
                    total =  (eval(data.count).reduce((a, b) => a + b, 0));
                }
                if(storeArrCount[0].length > 0)
                {   
                    let newStore = []
                    storeArrCount[0].forEach(element => {
                        element.forEach(elements => {
                            newStore.push(elements)
                        });
                    });
                    data.countArr = newStore;
                    data.seriesPie =  []
                    let setPieSeries = data.attributeNameReplaced != "" ? data.attributeNameReplaced.split(',') : "";
                    for(let i=0; i<setPieSeries.length; i++){
                        data.seriesPie.push(i);
                    }
                    
                }
               // total = data.countsMap.reduce((a, b) => a + b, 0);
                //total =  (eval(data.count).reduce((a, b) => a + b, 0));
            }
        }        
        optionsBar = {
            title:{
                text: data.reportName+part,
                align: 'left',
                margin:0,
                offsetX: 0,
                offsetY: 10,
                style: {
                    fontSize:'13px',
                }
            },
            series: data.countsMap,
            chart: {
                height: 400,
                type: "bar",
                stacked: true,
                fontFamily:'Open Sans',
                toolbar: {
                    show: true,
                    offsetX:0,
                    offsetY:10,
                    tools: {
                        download: false,
                        pan:false,
                        reset: '<img src="assets/images/redo-alt.svg" width="15" style="display:none">'
                    }
                },
                animations: {
                    enabled: false
                },
                events: {
                    dataPointSelection: (event, chartContext, config) => {
                      //  this.spinner.show()
                        config.w.config.series[config.seriesIndex]
                        this.gotoDetail(data, config.dataPointIndex, config.w.config.series[config.seriesIndex].name);
                    },
                    mounted: (chartContext, config) => {
                        this.spinner.hide()
                    }
                }
            },
            // colors: [
            //     "#CD0941",
            //     "#DA4171",
            // ],
            xaxis: {
                categories: data.attributeNameReplaced != "" ? data.attributeNameReplaced.split("','") : "",
                type: 'category',
                labels : {
                    trim: true,
                    maxHeight: 90,
                    style: {
                        fontSize: '11px'
                    }
                },
                tickPlacement: 'on',
                title: {
                    text: data.xaxisAttributeName,
                }
            },
            yaxis: {
                title: {
                    text: data.yaxisAttributeName
                }
            },
            plotOptions: {
                bar: {
                    horizontal: false
                    // columnWidth: "45%",
                    // distributed: true,
                    // dataLabels: {
                    //     position: "top"
                    // }
                }
            },
            dataLabels: {
                enabled: false,
                offsetY: 20,
                style: {
                    fontSize: "12px",
                    colors: ["#868686"]
                }
            },
            legend: {
                position: "bottom",
                horizontalAlign: 'center'
            },

            tooltip: {
                            custom: function ({ series, seriesIndex, dataPointIndex, w }) {                    
                            return `<div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                            <div class="apexcharts-tooltip-series-group" style="order: 2; display: flex;">
                            <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                            <div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-y-label">Total: </span>
                            <span class="apexcharts-tooltip-text-y-value">${series
                                .map((s) => s[dataPointIndex])
                                .reduce((a, b) => a + b, 0)}</span>
                            </div>
                                </div>
                            </div>
                                </div>
                                <div class="apexcharts-tooltip-series-group" style="order: 1; display: flex;"><span class="apexcharts-tooltip-marker"
                                        style="background-color: ${
                                        w.globals.colors[seriesIndex]
                                        };"></span>
                                    <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                                        <div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-y-label">${
                                        w.config.series[seriesIndex].name
                                        }: </span>
                                            <span class="apexcharts-tooltip-text-y-value">${
                                            series[seriesIndex][dataPointIndex]
                                            }</span>
                                        </div>
                                    </div>
                                </div>
                                `;
                            },
                        },
            colors : ['#008FFB','#4CAF50','#FEB019','#FF4560','#775DD0','#2B908F','#F86624','#F9C80E','#38E6A5','#5C4742'],
            data: data
        }

        optionsBarCount = {
            title:{
                text: data.reportName+part + '('+ total +')',
                align: 'left',
                margin:0,
                offsetX: 0,
                offsetY: 10,
                style: {
                    fontSize:'13px',
                }
            },
            series: [{
                name: data.countsMap? data.countsMap : data.attributeName.replaceAll("'","").split(","),
                data: eval(data.count)
            }],
            chart: {
                height: 250,
                type: "bar",
                fontFamily:'Open Sans',
                toolbar: {
                    show: true,
                    offsetX:0,
                    offsetY:10,
                    tools: {
                        download: false,
                        pan:false,
                        reset: '<img src="assets/images/redo-alt.svg" width="15" style="display:none">'
                    }
                },
                animations: {
                    enabled: false
                },
                events: {
                    dataPointSelection: (event, chartContext, config) => {
                      //  this.spinner.show()
                        this.gotoDetail(data, config.dataPointIndex, config.w.config.series[config.seriesIndex].name);
                    },
                    mounted: (chartContext, config) => {
                        this.spinner.hide()
                    }
                }
            },
            colors: [
                "#CD0941",
                "#DA4171",
            ],
            xaxis: {
                categories: data.attributeName != "'[]'" ? data.attributeNameReplaced.split("','") : 0,
                type: 'category',
                labels : {
                    trim: true,
                    maxHeight: 90,
                    style: {
                        fontSize: '11px'
                    }
                },
                tickPlacement: 'on',
                title: {
                    text: data.xaxisAttributeName,
                }
            },
            yaxis: {
                title: {
                    text: data.yaxisAttributeName
                }
            },
            plotOptions: {
                bar: {
                    columnWidth: "45%",
                    distributed: true,
                    dataLabels: {
                        position: "top"
                    }
                }
            },
            dataLabels: {
                enabled: true,
                offsetY: -20,
                style: {
                    fontSize: "12px",
                    colors: ["#868686"]
                }
            },
            legend: {
                show: false
            },
            tooltip: {
                custom: function ({ series, seriesIndex, dataPointIndex, w }) {         
                return `<div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                <div class="apexcharts-tooltip-series-group" style="order: 2; display: flex;">
                <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                <div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-y-label">Total: </span>
                <span class="apexcharts-tooltip-text-y-value">${series
                    .map((s) => s[dataPointIndex])
                    .reduce((a, b) => a + b, 0)}</span>
                </div>
                    </div>
                </div>
                    </div>
                    <div class="apexcharts-tooltip-series-group" style="order: 1; display: flex;"><span class="apexcharts-tooltip-marker"
                            style="background-color: ${
                            w.globals.colors[seriesIndex]
                            };"></span>
                        <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                            <div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-y-label">${
                            w.config.series[seriesIndex].name[dataPointIndex]
                            }: </span>
                                <span class="apexcharts-tooltip-text-y-value">${
                                series[seriesIndex][dataPointIndex]
                                }</span>
                            </div>
                        </div>
                    </div>
                    `;
                },
            },
            data: data
        }

        optionsStacked = {
            title:{
                text: data.reportName,
                align: 'left',
                margin:0,
                offsetX: 0,
                offsetY: 10,
                style: {
                    fontSize:'13px',
                }
            },
            series: stackedSeries,
            chart: {
                height: 250,
                type: "bar",
                stacked: true,
                fontFamily:'Open Sans',
                toolbar: {
                    show: true,
                    offsetX:0,
                    offsetY:10,
                    tools: {
                        download: false,
                        pan:false,
                        reset: '<img src="assets/images/redo-alt.svg" width="15" style="display:none">'
                    }
                },
                animations: {
                    enabled: false
                },
                events: {
                    dataPointSelection: (event, chartContext, config) => {
                       // this.spinner.show()
                        this.gotoDetail(data, config.dataPointIndex,config.seriesIndex);
                    },
                    mounted: (chartContext, config) => {
                        this.spinner.hide()
                    }
                }
            },
            xaxis: {
                categories: data.attributeNameReplaced != "" ? data.attributeNameReplaced.split("','") : "",
                type: 'category',
                labels : {
                    trim: true,
                    maxHeight: 90,
                    style: {
                        fontSize: '11px'
                    }
                },
                tickPlacement: 'on',
                title: {
                    text: data.xaxisAttributeName,
                }
            },
            yaxis: {
                title: {
                  text: data.yaxisAttributeName
                }
            },
            plotOptions: {
                bar: {
                    horizontal: false
                    // columnWidth: "45%",
                    // distributed: true,
                    // dataLabels: {
                    //     position: "top"
                    // }
                }
            },
            dataLabels: {
                enabled: false,
                offsetY: -20,
                style: {
                    fontSize: "12px",
                    colors: ["#868686"]
                }
            },
            legend: {
                position: "bottom",
                horizontalAlign: 'center',
            },
            tooltip: {
                custom: function ({ series, seriesIndex, dataPointIndex, w }) {                    
                return `<div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                <div class="apexcharts-tooltip-series-group" style="order: 2; display: flex;">
                <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                <div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-y-label">Total: </span>
                <span class="apexcharts-tooltip-text-y-value">${series
                    .map((s) => s[dataPointIndex])
                    .reduce((a, b) => a + b, 0)}</span>
                </div>
                    </div>
                </div>
                    </div>
                    <div class="apexcharts-tooltip-series-group" style="order: 1; display: flex;"><span class="apexcharts-tooltip-marker"
                            style="background-color: ${
                            w.globals.colors[seriesIndex]
                            };"></span>
                        <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                            <div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-y-label">${
                            w.config.series[seriesIndex].name
                            }: </span>
                                <span class="apexcharts-tooltip-text-y-value">${
                                series[seriesIndex][dataPointIndex]
                                }</span>
                            </div>
                        </div>
                    </div>
                    `;
                },
            },
            colors : ['#008FFB','#4CAF50','#FEB019','#FF4560','#775DD0','#2B908F','#F86624','#F9C80E','#38E6A5','#5C4742'],
            data: data
        }

        optionsPie = {
            title:{
                text: data.reportName,
                align: 'left',
                margin:15,
                offsetX: 0,
                offsetY: 0,
                style: {
                    fontSize:'13px',
                }
            },
            seriesNon: data.count ? eval(data.count) :data.seriesPie,
            chart: {
                width: '100%',
                height: 215,
                type: "pie",
                events: {
                    dataPointSelection: (event, chartContext, config) => {
                       // this.spinner.show()
                        this.gotoDetail(data, config.dataPointIndex, config.w.config.series[config.seriesIndex].name);
                    },
                    mounted: (chartContext, config) => {
                        this.spinner.hide()
                    }
                }
            },
            legend: {
                show: true,
                offsetY: 10,
            },
            labels: data.attributeNameReplaced != "" ? data.attributeNameReplaced.split("','") : "",
            stroke: {
                width: 0,
            },
            dataLabels: {
                enabled: true
            },
            data: data

            
        }       
        
        if(data.reportType == 0 && data.columnOutput == 0){
            if(data.isBarChart){
                if(data.countsMap){
                    return optionsBar
                } else {
                    return optionsBarCount
                }
            } else {
                return optionsPie;
            }
        } else if(data.reportType == 0 && data.columnOutput == 1) {
            return optionsStacked
        } else {
            return ;
        }
        
    }


    loadReportDetail(item,parent)
    {
        parent.loadingData = true;        
        //fromDate: this.datePipe.transform(date.startDate, "yyyy-MM-dd"),
        //toDate: this.datePipe.transform(date.endDate, "yyyy-MM-dd"),
        let getDetail = {"id": item.id,"fromDate":this.datePipe.transform(this.leadReportDays.startDate, "yyyy-MM-dd"),"toDate":this.datePipe.transform(this.leadReportDays.endDate, "yyyy-MM-dd"),'reportDateType':this.sortByDate}
        this.reportService.reportDetail(getDetail).subscribe(data=>{
            if(data.status){ 
                item.showButton = false;
                this.reportDetailData = data;
                this.setLeadReports(this.reportDetailData,'reload');
            } else{
                this.toastr.error(data.error_msgs)
            }
            setTimeout(() => {
                parent.loadingData = false;
            }, 1000);   
        })
    }

    recordUpdateAdded(event){
        if(event == 'recordSuccess'){
            this.fetchLeadReports()
            }
    }

    setLeadReports(data,chartReload?){
        if(data != undefined){        
            this.inputParams = this.projectService.filterAttributeList()    
            this.waitCount = data.length - 1;
            this.leadReportChartOptions = [];
            this.leadReportData = data;
            if(chartReload == 'reload'){
                this.leadReportData = data.data
            }
            this.leadReportData.isBarChart = true;
                if(this.leadReportData.attributeName == undefined){
                    this.leadReportData.count = "[]";
                    this.leadReportData.attributeName = "[]";
                    this.leadReportData.count = "[]";
                    this.leadReportData.dataCount = 0;
                    this.spinnerHide();
                }
                this.leadReportData.dataCount = this.leadReportData.attributeName != "[]" ? this.leadReportData.attributeName.split(',').length : 0;
                let missedAttr = [];        
                if(this.leadReportData.veriticalParamId){
                    let verticalParamId;
                    let missedAttribute;
                    let defaultMethod;
                    
                    if(this.leadReportData.veriticalParamId.includes('params.') == true){
                        verticalParamId = this.leadReportData.veriticalParamId.replace('params.','');
                    } else{
                        verticalParamId = this.leadReportData.veriticalParamId
                    }

                    let horizontalParamId;
                    if(this.leadReportData.horizontalParamId.includes('params.') == true){
                        horizontalParamId = this.leadReportData.horizontalParamId.replace('params.','');
                    } else{
                        horizontalParamId = this.leadReportData.horizontalParamId
                    }

                    
                    this.inputParams.forEach(param => {                                                  
                        if(param.paramKey == this.leadReportData.veriticalParamId || 'params.'+param.paramKey == this.leadReportData.veriticalParamId){
                            this.leadReportData.yaxisAttributeName = param.paramName;
                            this.inputParams.forEach(param => {
                                if(param.paramKey == this.leadReportData.horizontalParamId || 'params.'+param.paramKey == this.leadReportData.horizontalParamId){
                                    this.leadReportData.xaxisAttributeName = param.paramName
                                    defaultMethod = true
                                }
                            });
                        }
                    });
                    let checkVerticalParamIdInAttr = this.inputParams.find(x=>x.paramKey == verticalParamId);
                    if(checkVerticalParamIdInAttr == undefined){
                        missedAttribute = true
                        missedAttr.push(verticalParamId)
                        // this.setLeadReportOption(this.leadReportData,'missedAttribute');
                        // return
                    } else{
                        this.dataList.forEach(category => {
                            if(category.linkRewrite == this.categoryName){
                                if(checkVerticalParamIdInAttr.category){
                                let checkAttrInCat = checkVerticalParamIdInAttr.category.split(',');
                                checkAttrInCat = checkAttrInCat.filter(p=>{
                                    return p!= null
                                })
                                
                                if(checkAttrInCat && checkAttrInCat.length > 0){
                                   let checkAttrByCat = checkAttrInCat.find(x=>x == this.categoryName)
                                    if(checkAttrByCat == undefined){
                                        if(checkVerticalParamIdInAttr.paramOwner == 0 && checkVerticalParamIdInAttr.pluginName != 'static'){
                                        missedAttribute = true;
                                        let checkToPush = missedAttr.find(x=>x == verticalParamId);
                                        if(checkToPush == undefined){
                                            missedAttr.push(verticalParamId)
                                        }
                                        }
                                    }
                                    
                                }
                            }
                          }
                        });
                    }
                    let checkHorizontalIdInAttr = this.inputParams.find(x=>x.paramKey == horizontalParamId);
                    if(checkHorizontalIdInAttr == undefined){
                        missedAttribute = true
                        let checkToPush = missedAttr.find(x=>x == horizontalParamId);
                        if(checkToPush == undefined){
                            missedAttr.push(horizontalParamId)
                        }
                        // this.setLeadReportOption(this.leadReportData,'missedAttribute');
                        // return
                    } else{
                        this.dataList.forEach(category => {
                            if(category.linkRewrite == this.categoryName){
                                if(checkHorizontalIdInAttr.category){
                                let checkAttrInCat = checkHorizontalIdInAttr.category.split(',');
                                checkAttrInCat = checkAttrInCat.filter(p=>{
                                    return p!= null
                                })
                                
                                if(checkAttrInCat && checkAttrInCat.length > 0){
                                   let checkAttrByCat = checkAttrInCat.find(x=>x == this.categoryName)
                                    if(checkAttrByCat == undefined){
                                        if(checkHorizontalIdInAttr.paramOwner == 0 && checkHorizontalIdInAttr.pluginName != 'static'){
                                        missedAttribute = true
                                        let checkToPush = missedAttr.find(x=>x == horizontalParamId);
                                        if(checkToPush == undefined){
                                            missedAttr.push(horizontalParamId)
                                        }
                                        }
                                    }
                                }
                            }
                          }
                        });
                    }

                    let filterJsonAsAttr = JSON.parse(this.leadReportData.filterJson);
                    if(filterJsonAsAttr.paramList && filterJsonAsAttr.paramList.length > 0){
                        filterJsonAsAttr.paramList.forEach(element => {
                            let elem_param_key;
                            if(element.paramKey.includes('params.') == true){
                                elem_param_key = element.paramKey.replace('params.','');
                            } else{
                                elem_param_key = element.paramKey;
                            }

                            let checkParam = this.inputParams.find(x=>x.paramKey == elem_param_key);
                            if(checkParam == undefined){
                                missedAttribute = true;
                                let checkToPush = missedAttr.find(x=>x == elem_param_key);
                                if(checkToPush == undefined){
                                    missedAttr.push(elem_param_key)
                                }
                            } else{
                                this.dataList.forEach(category => {
                                    if(category.linkRewrite == this.categoryName){
                                        if(checkParam.category){
                                        let checkAttrInCat = checkParam.category.split(',');
                                        checkAttrInCat = checkAttrInCat.filter(p=>{
                                            return p!= null
                                        })
                                        if(checkAttrInCat && checkAttrInCat.length > 0){
                                           let checkAttrByCat = checkAttrInCat.find(x=>x == this.categoryName)
                                            if(checkAttrByCat == undefined){
                                                if(checkParam.paramOwner == 0 && checkParam.pluginName != 'static'){
                                                missedAttribute = true;
                                                let checkToPush = missedAttr.find(x=>x == elem_param_key);
                                                if(checkToPush == undefined){
                                                missedAttr.push(elem_param_key)
                                                }
                                                }
                                            }
                                            
                                        }
                                    }
                                  }
                                });
                            }
                            
                        });
                    }

                    if(missedAttribute == true){
                        this.setLeadReportOption(this.leadReportData,'missedAttribute',missedAttr);
                    } else if(defaultMethod == true){
                        this.setLeadReportOption(this.leadReportData);
                    }
                } else {        
                    let missedAttribute;
                    let defaultMethod;  
                    this.inputParams.forEach(param => {
                        if(param.paramKey == this.leadReportData.horizontalParamId || 'params.'+param.paramKey == this.leadReportData.horizontalParamId){
                            this.leadReportData.xaxisAttributeName = param.paramName;
                            defaultMethod = true
                        }
                    });

                    let horizontalParamId;
                    if(this.leadReportData.horizontalParamId.includes('params.') == true){
                        horizontalParamId = this.leadReportData.horizontalParamId.replace('params.','');
                    } else{
                        horizontalParamId = this.leadReportData.horizontalParamId
                    }

                    let checkVerticalParamIdInAttr = this.inputParams.find(x=>x.paramKey == horizontalParamId);
                    if(checkVerticalParamIdInAttr == undefined){
                        missedAttribute = true
                        // this.setLeadReportOption(this.leadReportData,'missedAttribute');
                        // return
                    } else{                        
                        this.dataList.forEach(category => {
                            if(category.linkRewrite == this.categoryName){
                                if(checkVerticalParamIdInAttr.category){
                                let checkAttrInCat = checkVerticalParamIdInAttr.category.split(',');
                                checkAttrInCat = checkAttrInCat.filter(p=>{
                                    return p!= null
                                })
                                if(checkAttrInCat && checkAttrInCat.length > 0){
                                   let checkAttrByCat = checkAttrInCat.find(x=>x == this.categoryName)
                                    if(checkAttrByCat == undefined){
                                        if(checkVerticalParamIdInAttr.paramOwner == 0 && checkVerticalParamIdInAttr.pluginName != 'static'){
                                        missedAttribute = true;
                                        let checkToPush = missedAttr.find(x=>x == horizontalParamId);
                                        if(checkToPush == undefined){
                                        missedAttr.push(horizontalParamId)
                                        }
                                    }
                                    }
                                    
                                }
                            }
                          }
                        });
                    }

                    let filterJsonAsAttr = JSON.parse(this.leadReportData.filterJson);
                    if(filterJsonAsAttr.paramList && filterJsonAsAttr.paramList.length > 0){
                        filterJsonAsAttr.paramList.forEach(element => {
                            let elem_param_key;
                            if(element.paramKey.includes('params.') == true){
                                elem_param_key = element.paramKey.replace('params.','');
                            } else{
                                elem_param_key = element.paramKey;
                            }

                            let checkParam = this.inputParams.find(x=>x.paramKey == elem_param_key);
                            if(checkParam == undefined){
                                missedAttribute = true;
                                let checkToPush = missedAttr.find(x=>x == elem_param_key);
                                if(checkToPush == undefined){
                                    missedAttr.push(elem_param_key)
                                }
                            } else{
                                this.dataList.forEach(category => {
                                    if(category.linkRewrite == this.categoryName){
                                        if(checkParam.category){
                                        let checkAttrInCat = checkParam.category.split(',');
                                        checkAttrInCat = checkAttrInCat.filter(p=>{
                                            return p!= null
                                        })
                                        if(checkAttrInCat && checkAttrInCat.length > 0){
                                           let checkAttrByCat = checkAttrInCat.find(x=>x == this.categoryName)
                                            if(checkAttrByCat == undefined){
                                                if(checkParam.paramOwner == 0 && checkParam.pluginName != 'static'){
                                                missedAttribute = true;
                                                let checkToPush = missedAttr.find(x=>x == elem_param_key);
                                                if(checkToPush == undefined){
                                                    missedAttr.push(elem_param_key)
                                                }
                                                }
                                            }
                                            
                                        }
                                    }
                                  }
                                });
                            }
                            
                        });
                    }

                    if(missedAttribute == true){
                        this.setLeadReportOption(this.leadReportData,'missedAttribute',missedAttr);
                    } else if(defaultMethod == true){
                        this.setLeadReportOption(this.leadReportData);
                    }
                }
            
            this.isNoDataLeadChart = this.leadReportData.length == 0;
        }

    }

    fetchLeadReports(){
        if(this.categoryName == undefined){
            return
        }
        localStorage.setItem("analysisCatName",this.categoryName);
        let fromDate:any = this.leadReportDays.startDate;
        let endDate:any =  this.leadReportDays.endDate;
        let differenceBetweenDate = Math.floor((endDate - fromDate) / (1000 * 60 * 60 * 24));
        differenceBetweenDate = differenceBetweenDate + 1;
        if(differenceBetweenDate > this.maxRange){
            this.toastr.error('Max date range should not be greater than ' + this.maxRange + ' days');
            return
        }

        this.leadReportChartOptionsDetail = []
        this.spinner.show();
        this.defaultDate = this.datePipe.transform(this.leadReportDays.startDate, "yyyy-MM-dd") ==  this.datePipe.transform( moment().subtract(6, 'days'), "yyyy-MM-dd") && this.datePipe.transform(this.leadReportDays.endDate, "yyyy-MM-dd") ==  this.datePipe.transform( moment(), "yyyy-MM-dd");
        this.reportService.getReportListData(this.leadReportDays,this.categoryName,'0','reload').then(data => {            
            if(data != undefined){
                this.leadReportListData = data;
                data.forEach(element => {
                    this.setLeadReports(element);
                });
            } else{
                this.isNoDataLeadChart = true;
            } });
    }

    setLeadReportOption(element,attrStatus?,missedAttr?){       
        const maxChartSize = 40;
        if(element.reportType == 0){
            if(element.dataCount > maxChartSize && element.columnOutput == 0 && !element.countsMap){
                let countMap = []
                if(element.countsMap){
                    element.countsMap.forEach(element => {
                        element.data.forEach(elementData => {                            
                            countMap.push(elementData) 
                        });
                    });
                }
                let eventParamIdSet = element.eventParamId.includes('params.') == true ? element.eventParamId.split('.')[1] : element.eventParamId;
                let veriticalParamIdSet = element.veriticalParamId.includes('params.') == true ? element.veriticalParamId.split('.')[1] : element.veriticalParamId;
                const count = element.count ? eval(element.count) : countMap;
                const attribute = element.attributeName.split(',');
                const countGroups = count.map((e, i) => {
                    return i % maxChartSize === 0 ? count.slice(i, i + maxChartSize) : null; 
                }).filter(e => { return e; });
                const attributeGroups = attribute.map((e, i) => {
                    return i % maxChartSize === 0 ? attribute.slice(i, i + maxChartSize) : null; 
                }).filter(e => { return e; });
                for (let i = 0; i < countGroups.length; i++) {
                    var chartData = {
                        count : countGroups[i],
                        attributeName : attributeGroups[i],
                        dataCount : attributeGroups[i].length,
                        reportName : element.reportName,
                        isBarChart : true,
                        eventParamId: eventParamIdSet,
                        id: element.id,
                        horizontalParamId: element.horizontalParamId,
                        source: element.source,
                        veriticalParamId: veriticalParamIdSet,
                        part: i+1,
                        columnOutput: element.columnOutput,
                        reportType: element.reportType,
                        xaxisAttributeName: element.xaxisAttributeName ? element.xaxisAttributeName : '',
                        yaxisAttributeName: element.yaxisAttributeName ? element.yaxisAttributeName : '',
                        showToDashboard:element.showToDashboard,
                        horizontalParamType:element.horizontalParamType,
                        defaultSort:element.defaultSort,
                        showDataGreater:element.showDataGreater,
                        filterJson:element.filterJson,
                        category:element.category,
                        countsMap:element.countsMap
                    }
                    if(attrStatus == 'missedAttribute'){
                        this.setLeadReportChart(chartData,attrStatus,missedAttr)
                    }                    
                    if(this.setLeadReportChart(chartData) != undefined){
                    this.leadReportChartOptions.push(this.setLeadReportChart(chartData));  
                    this.waitCount++;
                    } 
                    if(this.leadReportChartOptions.length > 0){
                    if(this.leadReportChartOptionsDetail.length > 0){
                        let checkAvail = this.leadReportChartOptionsDetail.find(x=>x.id == element.id);
                        if(checkAvail == undefined){
                            this.leadReportChartOptionsDetail.push({'id':this.leadReportChartOptions[0].data.id,'value':this.leadReportChartOptions});
                        } else{
                            for(let i=0; i<this.leadReportChartOptionsDetail.length; i++){
                                if(this.leadReportChartOptionsDetail[i].id == element.id){
                                    delete this.leadReportChartOptionsDetail[i];
                                }
                            }
                            this.leadReportChartOptionsDetail.push({'id':this.leadReportChartOptions[0].data.id,'value':this.leadReportChartOptions});
                            this.leadReportChartOptionsDetail = this.leadReportChartOptionsDetail.filter(p=>{
                                return p != null;
                            })
                        }
                        }else{
                            this.leadReportChartOptionsDetail.push({'id':this.leadReportChartOptions[0].data.id,'value':this.leadReportChartOptions});
                        }
                    }                        
                }
            } else {
                if(attrStatus == 'missedAttribute'){
                    this.leadReportChartOptions.push(this.setLeadReportChart(element,attrStatus,missedAttr));
                    if(this.leadReportChartOptionsDetail.length > 0){
                        let checkAvail = this.leadReportChartOptionsDetail.find(x=>x.id == element.id);
                        if(checkAvail == undefined){
                            this.leadReportChartOptionsDetail.push({'id':this.leadReportChartOptions[0].data.id,'value':this.leadReportChartOptions});
                        } else{
                            for(let i=0; i<this.leadReportChartOptionsDetail.length; i++){
                                if(this.leadReportChartOptionsDetail[i].id == element.id){
                                    delete this.leadReportChartOptionsDetail[i];
                                }
                            }
                            this.leadReportChartOptionsDetail.push({'id':this.leadReportChartOptions[0].data.id,'value':this.leadReportChartOptions});
                            this.leadReportChartOptionsDetail = this.leadReportChartOptionsDetail.filter(p=>{
                                return p != null;
                            })
                        }
                        }else{
                            this.leadReportChartOptionsDetail.push({'id':this.leadReportChartOptions[0].data.id,'value':this.leadReportChartOptions});
                        }
                    return
                }
                this.leadReportChartOptions.push(this.setLeadReportChart(element));
                
                if(this.leadReportChartOptionsDetail.length > 0){
                let checkAvail = this.leadReportChartOptionsDetail.find(x=>x.id == element.id);
                if(checkAvail == undefined){
                    this.leadReportChartOptionsDetail.push({'id':this.leadReportChartOptions[0].data.id,'value':this.leadReportChartOptions});
                } else{
                    for(let i=0; i<this.leadReportChartOptionsDetail.length; i++){
                        if(this.leadReportChartOptionsDetail[i].id == element.id){
                            delete this.leadReportChartOptionsDetail[i];
                        }
                    }
                    this.leadReportChartOptionsDetail.push({'id':this.leadReportChartOptions[0].data.id,'value':this.leadReportChartOptions});
                    this.leadReportChartOptionsDetail = this.leadReportChartOptionsDetail.filter(p=>{
                        return p != null;
                    })
                }
                }else{
                    this.leadReportChartOptionsDetail.push({'id':this.leadReportChartOptions[0].data.id,'value':this.leadReportChartOptions});
                }
            }
        } else {
            this.leadReportChartOptions.push({data:element});
            if(this.leadReportChartOptionsDetail.length > 0){
                let checkAvail = this.leadReportChartOptionsDetail.find(x=>x.id == element.id);
                if(checkAvail == undefined){
                    this.leadReportChartOptionsDetail.push({'id':this.leadReportChartOptions[0].data.id,'value':this.leadReportChartOptions});
                } else{
                    for(let i=0; i<this.leadReportChartOptionsDetail.length; i++){
                        if(this.leadReportChartOptionsDetail[i].id == element.id){
                            delete this.leadReportChartOptionsDetail[i];
                        }
                    }
                    this.leadReportChartOptionsDetail.push({'id':this.leadReportChartOptions[0].data.id,'value':this.leadReportChartOptions});
                    this.leadReportChartOptionsDetail = this.leadReportChartOptionsDetail.filter(p=>{
                        return p != null;
                    })
                }
                }else{
                    this.leadReportChartOptionsDetail.push({'id':this.leadReportChartOptions[0].data.id,'value':this.leadReportChartOptions});
                }
            this.spinnerHide();
        }
    }

    fetchReport(data){
        this.setReportData(data);
    };

    setReportData(data){
        this.refilEditVal = JSON.stringify(data.value[0].data);
        this.modalRef = this.modalService.show(this.addLeadReports, { class: "modal-rightblock", backdrop: 'static' });
    }

    changeChartType(i, id){
        this.leadReportChartOptionsDetail.forEach(element => {            
            if(element.id == id){
                element.value[0].data.isBarChart = !element.value[0].data.isBarChart;
                this.leadReportChartOptionsDetail[i].value[0] = this.setLeadReportChart(this.leadReportChartOptionsDetail[i].value[0].data);                
            }
        });
    }

    deleteLeadReportChart(){
        this.reportService.deleteReport({id:this.selectedleadReportId},this.categoryName).subscribe(data => {
            if(data.status){
                this.modalRef.hide();
                this.toastr.success(data.data,'Success');
                this.reportService.leadReportsData = undefined;
                this.fetchLeadReports();
            } else {
                this.toastr.error(data.error_msgs,'Error');
            }
        });
    }

    showDeleteModal(id){
        this.selectedleadReportId = id;
        this.modalRef = this.modalService.show(this.leadReportDeleteConfirm, {
            backdrop: 'static'
        });
    }

    gotoDetail(data,i,seriesIndex?){       
        this.audienceFilter = [];
        let filterJson = data.filterJson ? JSON.parse(data.filterJson) : [];
        let pluginFormIds = ""
        var attributeName = data.attributeName.split("','")[i].replaceAll("'","");
        data.horizontalParamId = data.horizontalParamId.includes('params.') == true ? data.horizontalParamId.split('.')[1]:data.horizontalParamId;
        if(filterJson.pluginFormIds != null){
         pluginFormIds = filterJson.pluginFormIds.join();
        }
        if(filterJson.paramList){
        if(filterJson.paramList.length > 0){
            for(let i=0; i<filterJson.paramList.length; i++){
                let paramKey = filterJson.paramList[i].paramKey.includes('params.') == true ? filterJson.paramList[i].paramKey.replace('params.','') : filterJson.paramList[i].paramKey;
                if(data.horizontalParamId == paramKey){
                    delete filterJson.paramList[i];
                }
                if(filterJson.paramList[i] != null){
                    if(typeof(filterJson.paramList[i].paramValue) == 'string'){
                if(filterJson.paramList[i].paramValue.includes('date(') == true){
                    let date = filterJson.paramList[i].paramValue.split('and');
                    let fromDate = date[0].replace("date('","").replace("')","");
                    let toDate = date[1].replace("date('","").replace("')","");
                    this.audienceFilter.push({paramKey:paramKey,paramValue:filterJson.paramList[i].paramValue, text1:fromDate, text2:toDate,paramOprator:filterJson.paramList[i].paramOprator});
                } else{
                    this.audienceFilter.push({paramKey:paramKey,paramValue:filterJson.paramList[i].paramValue, text1:filterJson.paramList[i].paramValue,paramOprator:filterJson.paramList[i].paramOprator});
                }
                }
                } 
            }
        }
    }
        var source = []
        let sourceMulti = data.source.split(',')
        this.sourseList.forEach( element => {
            let checkSource = sourceMulti.find(x=>x == element.pluginId);
            if(checkSource){
                source.push(element.pluginName);
            }
        });
        let sourcename = '';
        if(source.length > 0){
            sourcename = source.join();
        }        
        var fromDate = this.datePipe.transform(this.leadReportDays.startDate, "yyyy-MM-dd");
        var toDate = this.datePipe.transform(this.leadReportDays.endDate, "yyyy-MM-dd")
        setTimeout(() => {
                let paramsEventId = data.eventParamId.includes('params.') == true ? data.eventParamId.replace('params.','') : data.eventParamId;
                let removeParamsVerticalId = data.veriticalParamId.includes('params.') == true ? data.veriticalParamId.replace('params.','') : data.veriticalParamId;
                if(paramsEventId == data.horizontalParamId){
                    paramsEventId = "";
                }
            if(seriesIndex != undefined && typeof(seriesIndex) == 'number'){
                let checkData = data.count ? data.count : data.countsMap
                let checkName = checkData != undefined ? checkData[seriesIndex].name : ''
                this.spinner.hide()
                
                this.audienceFilter.push({paramKey:paramsEventId,paramValue:'date(' + this.datePipe.transform(fromDate, "yyyy-MM-dd 00:00:00") + ') and date(' + this.datePipe.transform(toDate, "yyyy-MM-dd 23:59:59") +')',text1:fromDate,text2:toDate,paramDatatype:'Date'});
                this.audienceFilter.push({paramKey:data.horizontalParamId,paramValue:attributeName, text1:attributeName})
                this.audienceFilter.push({paramKey:'source',paramValue:sourcename,text1:data.source});
                this.audienceFilter.push({paramKey:removeParamsVerticalId,paramValue:checkName,text1:checkName});
                this.audienceFilter.push({paramKey:'pluginFormIds',paramValue:pluginFormIds});
                localStorage.setItem('filterData',JSON.stringify(this.audienceFilter));
                this.ngZone.run(() => {                
                this.router.navigate(['/admin/lead/',this.categoryName]);
             });
            } else {
                let checkData = data.count ? data.count : data.countsMap
                let checkName = seriesIndex ? seriesIndex : '';
                this.spinner.hide()
                this.audienceFilter.push({paramKey:paramsEventId,paramValue:'date(' + this.datePipe.transform(fromDate, "yyyy-MM-dd 00:00:00") + ') and date(' + this.datePipe.transform(toDate, "yyyy-MM-dd 23:59:59") +')',text1:fromDate,text2:toDate,paramDatatype:'Date'});
                this.audienceFilter.push({paramKey:data.horizontalParamId,paramValue:attributeName, text1:attributeName})
                this.audienceFilter.push({paramKey:'source',paramValue:sourcename,text1:data.source});
                this.audienceFilter.push({paramKey:removeParamsVerticalId,paramValue:checkName,text1:checkName});
                this.audienceFilter.push({paramKey:'pluginFormIds',paramValue:pluginFormIds});
                localStorage.setItem('filterData',JSON.stringify(this.audienceFilter));                               
                this.ngZone.run(() => {
                this.router.navigate(['/admin/lead/',this.categoryName]);
            });

            }
        }, 100);
    }

    updateSource(type?){
        this.inputParams = [];
        if(this.siteForm.controls.source.value != ''){
            this.siteForm.get('reportColumnId').enable();
            this.siteForm.get('yaxisColumnId').enable();
            this.siteForm.get('eventTimeId').enable();
            if(type!='update'){
                this.siteForm.get('ycolumnSum').setValue(0);
                this.siteForm.get('yaxisColumnId').setValue('');
                this.siteForm.get('eventTimeId').setValue('');
            }
            this.getInputParams(this.siteForm.controls.source.value);
        } else {
            this.siteForm.get('reportColumnId').disable();
            this.siteForm.get('yaxisColumnId').disable();
            this.siteForm.get('eventTimeId').disable();
            // this.siteForm.setValue({
            //     id:null,
            //     reportName:'',
            //     source:'',
            //     reportColumnId:'',
            //     yaxisColumnId:'',
            //     eventTimeId:'',
            //     reportType:0,
            //     ycolumnSum:0,
            //     defaultSort:'default',
            //     showDataGreater:0
            // })
        }
    }

    spinnerHide() {
        if (this.waitCount > 0){
          this.waitCount--;
        } else {
          this.spinner.hide();
        }
    }

}
