<main>
    <section class="inner-page">
        <div class="inner-page-header">
            <div class="container-fluid">
                <h4><a class="back-link" [routerLink]="['/admin/catalogue']"><img src="assets/images/angle-left.svg"></a>
                    <span *ngIf="isUpdate; else createTitle"> Update Catalogue </span>
                    <ng-template #createTitle> Create Catalogue </ng-template>
                </h4>
            </div>
        </div>
        <div class="inner-page-body">
            <div class="container-fluid catalogue-layout">
                <form [formGroup]="siteForm" [ngClass]="{'submitted':isSubmit}" (ngSubmit)="onSubmit()">
                    <div class="pade-header">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="logo-placehoder" *ngIf="siteForm.controls.logoPath.value == undefined || siteForm.controls.logoPath.value == '' ; else showLogo" (click)="showGallery('image',2)">
                                    <img src="assets/images/upload-gray.svg" alt="">
                                    Upload Logo
                                </div>
                                <ng-template #showLogo>
                                    <div class="logo-catalogue" (click)="showGallery('image',2)"><img [src]="siteForm.controls.logoPath.value" alt=""></div>
                                </ng-template>
                            </div>
                            <div class="col-md-6 mt-md-0 mt-2 text-md-right text-center text-input"><input trim="blur" type="text" placeholder="Enter Title Name" formControlName="title"  maxlength="50"></div>
                        </div>
                    </div>

                    <div class="pade-body">
                        <div *ngIf="productList != undefined" class="row" dndDropzone
                            (dndDrop)="onDrop($event)">
                            <div *ngFor="let item of productList; let i = index;" class="col-md-3" (dndMoved)="onDragged(item, 'move')" [dndEffectAllowed]="'all'" [dndDraggable]="item" >
                                <div class="item-box">
                                    <div class="top">
                                        <i class="dnd-handle" dndHandle></i>
                                        <div *ngIf="item.imageList.length <= 3" tooltip="Add Product Image" tooltipPlacement="top" class="add" (click)="showGallery('image',3,i);"><img src="assets/images/plus.svg" alt="Add"></div>
                                        <div tooltip="Delete Product" tooltipPlacement="top" class="delete" (click)="removeProduct(i,item.id);" ><img src="assets/images/trash-alt-gray.svg" alt="delete"></div>
                                    </div>
                                    <carousel *ngIf="item.imageList != undefined" [interval]="9999999" [showIndicators]="false" [(activeSlide)]="item.activeSlideIndex" [startFromIndex]="item.activeSlideIndex">
                                        <slide *ngFor="let image of item.imageList; let j = index;">
                                            <div (click)="showGallery('image',3,i,j)" class="image">
                                                <img [src]="image.replace('image_file/originalFile','image_file/thumbnail-originalFile')">
                                                <div *ngIf="item.imageList.length > 1" class="delete1" (click)="removeImage(j,i)">Delete Image</div>
                                            </div>
                                        </slide>
                                        <slide *ngIf="item.imageList.length == 0" >
                                            <div class="item-placeholder" (click)="showGallery('image',3,i)">
                                                <img src="assets/images/upload-gray.svg" alt="">
                                                Add Image
                                            </div>
                                        </slide>
                                    </carousel>
                                    <!-- <div class="image" (click)="showGallery('image',4, i)" >
                                        <img [src]="item.imagePath" [alt]="item.name">
                                    </div> -->
                                    <div class="detail">
                                        <div class="form-group">
                                            <label>Name</label>
                                            <div class="form-input  ml-auto">
                                                <input trim="blur" type="text" class="input" [ngClass]="{'invalid':(item.name == '')}" [(ngModel)]="item.name" maxlength="50" (change)="updateProject(i)" [ngModelOptions]="{standalone: true}">
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label>Price</label>
                                            <div class="form-input ml-auto">
                                                <input trim="blur" data-maxlength="13" oninput="this.value=this.value.slice(0,this.dataset.maxlength)" type="number" class="input" [ngClass]="{'invalid':(item.price == '' || item.price == null)}" [(ngModel)]="item.price" (change)="updateProject(i)" [ngModelOptions]="{standalone: true}">
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label>Min. Qty.</label>
                                            <div class="form-input ml-auto">
                                                <input trim="blur" type="number" class="input" [ngClass]="{'invalid':(item.quantity == '')}" [(ngModel)]="item.quantity" maxlength="5" (change)="updateProject(i)" [ngModelOptions]="{standalone: true}">
                                            </div>
                                        </div>
                                        <div class="form-group description">
                                            <a href="javascript:void(0)" (click)="showDescriptionModal(i)">
                                                <span *ngIf="item.productDescription == null; else updateDescription">Add Description</span>
                                                <ng-template #updateDescription>
                                                    Update Description
                                                </ng-template>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3" dndPlaceholderRef><div class="dnd-placeholder"></div></div>
                            <div class="col-md-3">
                                <div class="item-placeholder" (click)="showGallery('image',1)">
                                    <img src="assets/images/upload-gray.svg" alt="">
                                    Add Product
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="page-footer">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="accordion catalogue-field mt-0" id="catalogueFields">
                                    <div class="card">
                                        <div class="card-header" id="headingCatalogueDescription">
                                            <button type="button" data-toggle="collapse" data-target="#catalogueDescription" aria-expanded="false" aria-controls="collapseTwo">Catalogue Description</button>
                                        </div>
                                        <div id="catalogueDescription" class="collapse show" aria-labelledby="headingCatalogueDescription" data-parent="#catalogueFields">
                                            <div class="card-body">
                                                <app-ckeditor
                                                formControlName="catalogueDescription"
                                                [config]="config"
                                                [editorData]="ckeditorData"
                                                ></app-ckeditor>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="headingSeoData">
                                            <button type="button" data-toggle="collapse" class="collapsed" data-target="#seoData" aria-expanded="true" aria-controls="seoData">SEO Data</button>
                                        </div>
                                        <div id="seoData" class="collapse" aria-labelledby="headingSeoData" data-parent="#catalogueFields">
                                            <div class="card-body">
                                                <div class="text-input">
                                                    <input trim="blur" type="text" placeholder="Meta Title" formControlName="metaTitle" maxlength="50">
                                                </div>
                                                <div class="text-input">
                                                    <textarea trim="blur" type="text" placeholder="Meta Description" formControlName="metaDescription" maxlength="150"></textarea>
                                                </div>
                                                <div class="text-input">
                                                    <textarea trim="blur" type="text" placeholder="Meta Keywords" formControlName="metaKeywords" maxlength="450"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="headingCustomJavascripts">
                                            <button type="button" class="collapsed" data-toggle="collapse" data-target="#customJavascripts" aria-expanded="false" aria-controls="customJavascripts">Custom Javascripts</button>
                                        </div>
                                        <div id="customJavascripts" class="collapse" aria-labelledby="headingCustomJavascripts" data-parent="#catalogueFields">
                                            <div class="card-body">
                                                <div class="text-input javascript">
                                                    <textarea trim="blur" type="text" placeholder="Custom Javascript" formControlName="customJavascript" maxlength="450"></textarea>
                                                    <span class="form-text">Ex : Add script like &lt;script&gt; your javascript should be here&lt;/script&gt; or &lt;script src="your javascript path here" &gt; &lt;/script&gt;</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="card">
                                        <div class="card-header" id="headingDomainMapping">
                                            <button type="button" class="collapsed" data-toggle="collapse" data-target="#domainMapping" aria-expanded="false" aria-controls="domainMapping">Domain Mapping</button>
                                        </div>
                                        <div id="domainMapping" class="collapse" aria-labelledby="headingDomainMapping" data-parent="#catalogueFields">
                                            <div class="card-body">
                                                <div class="text-input handle">
                                                    <input trim="blur" type="text" placeholder="Domain Mapping" formControlName="domain" maxlength="100">
                                                    <span class="form-text">Use case : 
                                                        Purchase your domain from any dns vendors and map &#123;15.207.214.146&#125; to your domain.
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="text-input handle">
                                    <input trim="blur" type="text" placeholder="Domain Mapping" formControlName="domain" maxlength="100">
                                    <span class="form-text">Use case : 
                                        Purchase your domain from any dns vendors and map &#123;3.109.22.178&#125; to your domain.
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="text-input handle">
                                    <input trim="blur" type="text" placeholder="Enter Catalogue Handle" formControlName="handle" maxlength="50" [readonly]="isUpdate">
                                    <span class="form-text">Use only- "Alphanumeric, - , _" <br> Catalogue URL would be: 
                                        https://{{siteForm.controls.domain.value}}/<b *ngIf="siteForm.controls.handle.value == ''">&#123;catalogue-handle&#125;</b><b *ngIf="siteForm.controls.handle.value != ''">{{siteForm.controls.handle.value}}</b>
                                    </span>
                                </div>
                            </div>
                            

                            <div class="col-md-12 my-4">
                            <label class="mb-2">Captcha Preference </label>
                                        <select class="form-control col-md-6" formControlName="captcha"  >
                                            <option value=0>Google Captcha</option>
                                            <option value=1>Image Captcha</option>
                                           
                                        </select>
                               
                            </div>

                            <div class="col-md-6 my-4">
                                <label class="check mb-2">
                                    Enable password protection.
                                    <input type="checkbox" [(ngModel)]="enablePassword" [ngModelOptions]="{standalone: true}" (change)="changeEnablePassword()">
                                    <span class="checkmark"></span>
                                </label>
                            </div>


                            
                            <div class="col-md-6 my-4" *ngIf="enablePassword">
                                <div class="text-input handle">
                                    <input trim="blur" type="password" [attr.placeholder]="passwordUpdate != '' ? 'Change Catalogue Password' : 'Enter Catalogue Password'" formControlName="password" maxlength="30">
                                </div>
                            </div>
                            <div class="col-md-7">
                                <label class="check mb-2">
                                    Do you want to send acknowledgement email to user for received leads?
                                    <input type="checkbox" formControlName="sendCustomerEmail" >
                                    <span class="checkmark"></span>
                                </label>
                                <label class="check" *ngIf="siteForm.controls.sendCustomerEmail.value">
                                    Do you want to get emails for leads?
                                    <input type="checkbox" formControlName="sendOwnerEmail" >
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            <div class="col-md-5">
                                <div class="d-flex justify-content-end align-items-center">
                                    <div class="text-input"><input trim="blur" type="tel" class="whatsapp" placeholder="Enter Whatsapp Number" formControlName="whatsapp" maxlength="10"></div>
                                    <button type="submit" class="btn btn-dark">
                                        <span *ngIf="!isUpdate; else updateButton">Generate Link</span>
                                        <ng-template #updateButton>
                                            Update
                                        </ng-template>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </section>
</main>

<ng-template #showLink>
    <!-- delete popup -->
    <form>
        <div class="forgot-password-modal">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Catalogue Links</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="hideShowLinkModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body modal-user-management catalogue-link">
                <div class="mb-4">
                    <strong>With Price Link </strong> 
                    <a class="link" href="{{catalogueData.withPriceLink}}" target="_blank"> {{catalogueData.withPriceLink}}</a>
                    <img tooltip="Copy URL" tooltipPlacement="top" (click)="copyLink(catalogueData.withPriceLink)"  src="assets/images/copy.svg">
                    <img *ngIf="isShortUrlSubscribe" tooltip="Get Short URL" tooltipPlacement="top" (click)="getShortUrl(catalogueData.withPriceLink)" src="assets/images/link.svg">
                </div>
                <div>
                    <strong>Without Price Link </strong> 
                    <a class="link" href="{{catalogueData.withoutPriceLink}}" target="_blank"> {{catalogueData.withoutPriceLink}}</a>
                    <img tooltip="Copy URL" tooltipPlacement="top" (click)="copyLink(catalogueData.withoutPriceLink)" src="assets/images/copy.svg">
                    <img *ngIf="isShortUrlSubscribe" tooltip="Get Short URL" tooltipPlacement="top" (click)="getShortUrl(catalogueData.withoutPriceLink)" src="assets/images/link.svg">
                </div>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #emailConfigurationModal>
    <!-- delete popup -->
    <form>
        <div class="forgot-password-modal">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Email Configuration</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body modal-user-management">
                <div class="form-group mb-0">
                    <div>You have not configured email settings. Please add setting over <a (click)="modalRef.hide()" [routerLink]="['/admin/configuration/email']">here</a> </div>
                    <br>
                </div>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #descriptionModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Product  Description</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <app-ckeditor
            [(ngModel)]="productDescription"
            [config]="config"
            [editorData]="ckeditorData"
        ></app-ckeditor>
        <div class="d-flex justify-content-end mt-2">
            <div><button class="btn btn-dark" (click)="submitDescription()" type="button">Submit</button></div>
        </div>
    </div>
</ng-template>

