<section class="wrapper-body {{ parentClass }}">
    <a id="show-sidebar" class="btn btn-sm btn-dark sidebar-open" (click)="showMenu()">
        <img alt="" src="assets/images/open-menu.svg" />
    </a>
    <div class="inner-wrapper">
        <!-- start: sidebar -->
        <aside class="sidebar-left">
            <div class="version">Version : {{VERSION}}</div>
            <div class="sidebar-content">
                <div class="top-logo">
                <!-- <a [routerLink]="['/admin/dashboard']" class="logo">
                    <img src="assets/images/logo.png" alt="" />
                </a> -->

                <a href="javascript:void(0)" class="logo">
                    <ng-container *ngIf="projectIdEncrpted != 'DhU0BkfOhtrtHK2xdp+pew=='">
                        <img src="assets/images/logo.png" alt="" />
                    </ng-container>
                    <ng-container *ngIf="projectIdEncrpted == 'DhU0BkfOhtrtHK2xdp+pew=='">
                        <img src="assets/images/SBI-logo.png" alt="" [class.sbiLogoImage]="projectIdEncrpted != 'DhU0BkfOhtrtHK2xdp+pew=='" />
                    </ng-container>
                </a>
                <span *ngIf="innerWidth <= 1150" class="close close-menu" (click)="hideMenu()">
                    <img alt="" src="assets/images/cancel-menu.svg" />
                </span>
                </div>
                <ng-container *ngIf="userData != undefined">
                    <nav class="nav-main" *ngIf="userData.accountCreated">
                        <ul>
                            <ng-container *ngFor="let menu of menuItems" >
                                <li *ngIf="menu.menuList == undefined">
                                    <a *ngIf="menu.menuKey != 'WABA'" routerLinkActive="active" [routerLink]="['/admin/'+menu.menuKey]">
                                        <span class="font-icon icon-img view-audience-icons">
                                            <img class="view-dark" src="assets/images/menu-{{menu.menuKey}}.svg">
                                        </span>
                                        <span>{{menu.menuName}}</span>
                                    </a>

                                    <a *ngIf="menu.menuKey == 'WABA'" routerLinkActive="active" (click)="callWabaApi()" href="javascript:void(0);">
                                        <span class="font-icon icon-img view-audience-icons">
                                            <img class="view-dark" src="assets/images/menu-{{menu.menuKey}}.svg">
                                        </span>
                                        <span>{{menu.menuName}}</span>
                                    </a>

                                </li>
                                <li *ngIf="menu.menuList != undefined" class="drop-li" [ngClass]="{'collapsed': menu.isSubMenuAdded}">
                                    <a class="drop-menu 4" (click)="menu.isSubMenuAdded = !menu.isSubMenuAdded">
                                        <div>
                                            <span class="font-icon icon-img view-audience-icons"><img class="view-dark" src="assets/images/menu-{{menu.menuKey}}.svg"></span>
                                            <span>{{menu.menuName}}</span>
                                        </div>
                                        <div [ngClass]="{'rotate180': !menu.isSubMenuAdded}"><span class="icon-angle-down-1"></span></div>
                                    </a>
                                    <ul *ngIf="!menu.isSubMenuAdded">
                                        <ng-container *ngFor="let subMenu of menu.menuList">
                                            <li *ngIf="subMenu.menuList == undefined" routerLinkActive="active">
                                                <a routerLinkActive="active" [routerLink]="['/admin/'+menu.menuKey+'/'+subMenu.menuKey]" (click)="categoryMenuStatusGet(subMenu,menu); checkListDestroy(subMenu)">
                                                    <span>{{subMenu.menuName}}</span>
                                                </a>
                                            </li>
                                            <li *ngIf="subMenu.menuList != undefined" class="drop-li" [ngClass]="{'collapsed': subMenu.isSubMenuAdded}">
                                                <a class="drop-menu 1" (click)="subMenu.isSubMenuAdded = !subMenu.isSubMenuAdded;">
                                                    <div>
                                                        <span>{{subMenu.menuName}}</span>
                                                    </div>
                                                    <div [ngClass]="{'rotate180': !subMenu.isSubMenuAdded}"><span class="icon-angle-down-1"></span></div>
                                                </a>
                                                <ul *ngIf="!subMenu.isSubMenuAdded">
                                                  <li *ngFor="let subSubMenu of subMenu.menuList" routerLinkActive="active">
                                                      <a routerLinkActive="active 2" [routerLink]="['/admin/'+menu.menuKey+'/'+subMenu.menuKey+'/'+subSubMenu.menuKey]">
                                                        {{subSubMenu.menuName}}
                                                      </a>
                                                  </li>
                                                </ul>
                                            </li>
                                        </ng-container>
                                    </ul>
                                </li>
                            </ng-container>
                        </ul>
                    </nav>
                    <nav class="nav-main" *ngIf="!userData.accountCreated">
                        <ul>
                            <li routerLinkActive="active-x" class="side-menu-active">
                                <a routerLinkActive="active-x" [routerLink]="['/admin/edit-account']">
                                    <span class="font-icon icon-cogs"></span>
                                    <span>Create Account</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </ng-container>
            </div>
        </aside>

        <!-- end: sidebar -->
        <section class="content-body">
        <header class="page-header" *ngIf="projectDetails != undefined" >
            <div class="container-fluid">
            <div class="row">
                <div class="col-sm-5 col-8">
                <form [formGroup]="projectForm">
                    <div *ngIf="this.router.routerState.snapshot.url != '/admin/project-integrationx' ">
                    <div class="form-group row edit-project-block">
                        <div class="col-md-6"  *ngIf="this.isVisible == true">
                        <label class="control-label pl-0" for="inputDefault">
                            <ng-container *ngIf="projectDetails.projectName == 'Untitle Project'">
                                Untitled Project
                            </ng-container>
                            <ng-container *ngIf="projectDetails.projectName != 'Untitle Project'">
                                {{projectDetails.projectName}}
                            </ng-container>
                            
                        </label>
                        <button *ngIf="userData.userRoleId == 1" type="button" (click)="EnableInput(false)" class="btn edit-btn edit-btn-right">
                            <span class="icon-edit " tabindex="4"></span>
                        </button>
                        </div>
                        <div class="input-group col-md-6" *ngIf="this.isVisible == false ">
                        <input trim="blur" type="text" class="form-control" id="inputDefault" formControlName="projectName" />
                            <button class="btn save save_btn" (click)="saveProject()">
                            <img src="assets/images/save.png">
                            </button>
                        </div>
                        <div class="copybtn">
                            <p>{{projectDetails.projectKey}}</p>
                            <span class="cursor-pointer">
                            <img src="assets/images/copy.svg" (click)="copyText(projectDetails.projectKey)"></span>
                        </div>
                    </div>
                    </div>
                </form>
                </div>
                <div class="col-sm-7 col-4">
                <div class="profile-menu">
                    <nav class="navbar navbar-default text-right">
                    <div class="collapse navbar-collapse" [collapse]="isCollapsed">
                        <ul class="nav navbar-nav navbar-right pr-0">
                        
                        <li class="item d-none d-sm-block"><span class="reload" (click)="reload()" title="Refresh Page"><img src="assets/images/refresh-page.svg"></span></li>
                        <li dropdown class="dropdown notification_li item">
                            <a href="javascript:void(0);" dropdownToggle class="dropdown-toggle">
                                <span class="notification_icon">
                                    <img (click)="getNotificationList();" src="assets/images/Bell.png">
                                <span class="unreadCount" *ngIf="global.notificationSum">
                                    {{global.notificationSum}}
                                </span>
                                </span>
                            </a>
                            <!-- $event.stopPropagation(); -->
                            <ng-container *ngIf="notificationList">
                            <ul *dropdownMenu class="dropdown-menu notificationUl" role="menu">
                                <li *ngFor="let list of notificationList" (click)="goToViewAud(list)" [class.viewedNotification]="list.status == '1'" [class.redTxt]="list.notStats == 'Permission Change'">
                                    {{list.message}} - {{list.catName ? list.catName : ''}}
                                    <div class="ago_time_show">
                                        <ng-container *ngIf="list.day > 0">{{list.day > 0 ? list.day + ' days' : list.day + ' day'}} ago</ng-container>
                                        <ng-container *ngIf="list.day == 0 && list.hour > 0">{{list.hour > 0 ? list.hour + ' hours':list.hour + ' hour'}} ago</ng-container>
                                        <ng-container *ngIf="list.day == 0 && list.hour == 0 && list.minute > 0">{{list.minute > 0 ? list.minute + ' minutes' : list.minute + ' minute'}} ago</ng-container>
                                        <ng-container *ngIf="list.day == 0 && list.hour == 0 && list.minute == 0 && list.second > 0">{{list.second > 0 ? list.second + ' seconds' : list.second + ' second'}} ago</ng-container>
                                    </div>
                                </li>
                                <li *ngIf="showReadMore" (click)="$event.stopPropagation()">
                                    <a href="javascript:void(0)" *ngIf="loadingNotificationData == false" (click)="getNotificationList('nextPage');">Read More</a>
                                    <ng-container *ngIf="loadingNotificationData">
                                    <svg version="1.1" id="L3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                    viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
                                    <circle fill="none" stroke="#cd0141" stroke-width="4" cx="50" cy="50" r="44" style="opacity:0.5;"/>
                                    <circle fill="#000" stroke="#e74c3c" stroke-width="2" cx="16" cy="50" r="16" >
                                    <animateTransform
                                    attributeName="transform"
                                    dur="1.5s"
                                    type="rotate"
                                    from="0 50 48"
                                    to="360 50 52"
                                    repeatCount="indefinite" />
                                    </circle>
                                    </svg>
                                    </ng-container>
                                </li>
                                <li class="noRecord" *ngIf="notificationList.length == 0">No Record Found</li>
                            </ul>
                            </ng-container>
                        </li>
                        <li *ngIf="userData.userRoleId != 3 && projectDetails.mauLimit != 0 && projectDetails.mauLimit != undefined" class="item d-none d-sm-block"><span>Assigned MAU</span> {{projectDetails.mauLimit}}</li>
                        <li *ngIf="userData.userRoleId != 3 && projectDetails.mauUsed != 0 && projectDetails.mauUsed != undefined" class="item d-none d-sm-block"><span>Remaining MAU</span> {{projectDetails.mauLimit - projectDetails.mauUsed}}</li>
                        <li class="dropdown" dropdown>
                            <a href="javascript:void(0);" dropdownToggle class="dropdown-toggle userDtlCol">
                            <span class="user">{{ userData.userName | shortName }}</span>
                            <!-- <span class="email"> {{ userData.email }}</span> -->
                            <span class="email"> {{ userData.userName }}<div class="text-left">{{userData.userRoleName}}</div></span>
                            <span class="icon-angle-down-1"></span>
                            </a>
                            <ul *dropdownMenu class="dropdown-menu" role="menu">
                            <li class="d-block d-sm-none">
                                <span class="dropdown-item"> {{ userData.userName }}<div>{{userData.userRoleName}}</div></span>
                            </li>
                            <li role="menuitem" *ngIf="userData.accountCreated">
                                <a class="dropdown-item" [routerLink]="['/admin/edit-account']">Edit Account</a>
                            </li>
                            <!-- <li role="menuitem" *ngIf="!userData.accountCreated">
                                <a class="dropdown-item" [routerLink]="['/admin/edit-account']">Create Account</a>
                            </li> -->
                            <li role="menuitem">
                                <a class="dropdown-item" [routerLink]="['/admin/change-password']">Change Password</a>
                            </li>
                            <li role="menuitem">
                                <a class="dropdown-item" [routerLink]="['/admin/settings/authentication-methods']">Authentication Methods</a>
                            </li>
                            <li role="menuitem">
                                <a class="dropdown-item" [routerLink]="['/admin/settings/logged-in-sessions']">Logged In Session</a>
                            </li>
                            <li role="menuitem" *ngIf="projectDetails.ivrActivation ==  true">
                                <div class="custom-control custom-switch swich-button dropdown-item ivrSwitch">
                                    <span class="bm">IVR Status</span>
                                    <input class="custom-control-input" id="switchForIvrStatus" name="switchForIvrStatus" type="checkbox" [(ngModel)]="ivrStatusInput" [checked]="ivrStatus" (change)="ivrStatusUpdate($event)">
                                    <label class="custom-control-label" for="switchForIvrStatus"></label>
                                </div>
                            </li>
                            <li role="menuitem">
                                <a class="dropdown-item" (click)="signout()">Sign Out</a>
                            </li>
                            </ul>
                        </li>
                        </ul>
                    </div>
                    </nav>
                </div>
                </div>
            </div>
            <ul class="mau-item d-sm-none d-flex" *ngIf="userData.userRoleId != 3 && projectDetails.mauLimit != 0 && projectDetails.mauLimit != undefined">
                <li class="item"><span>Assigned MAU</span> {{projectDetails.mauLimit}}</li>
                <li class="item reload"><span (click)="reload()" title="Refresh Page"><img src="assets/images/refresh-page.svg"></span></li>
                <li class="item"><span>Remaining MAU</span> {{projectDetails.mauLimit - projectDetails.mauUsed}}</li>
            </ul>
            </div>
        </header>
        <section class="page-body" *ngIf="isBrowser">
            <router-outlet></router-outlet>
        </section>
        </section>
    </div>
    <ng-template #popupConfirm>
        <div class="forgot-password-modal">
          <div class="modal-header j-center">
            <h4 class="modal-title">Are you sure want to update?</h4>
          </div>
          <div class="modal-body">
            <div class="innerConfrm">
              <button class="btn btn-dark" (click)="confirmUpdate()">Yes</button>
              <button class="btn btn-dark red" (click)="modalRef.hide(); ivrStatusInput = ivrStatus">No</button>
            </div>
          </div>
        </div>
      </ng-template>
</section>

