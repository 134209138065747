<main>
    <section class="inner-page">
        <div class="inner-page-header">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-6">
                        <h4><a class="back-link" [routerLink]="['/admin/campaign/campaign-manager']"><img src="assets/images/angle-left.svg"></a> {{isUpdated ? 'Update' : 'Create'}} Campaign</h4>
                    </div>
                </div>
            </div>
        </div>

        <div class="inner-page-body create-journey">
            <div class="name">
                <div class="container-fluid">
                    <label>What is the name of this campaign?</label>
                    <div class="form-group">
                        <input type="text" name="campaign-name" class="form-control campaignName" [(ngModel)]="journeyCampaignName" name="journeyCampaignName" maxlength="35" [disabled]="campNameNotEdit">
                    </div>
                </div>
            </div>
            <div class="journey-flow">
                <ng-container *ngIf="journeyCampaign!= undefined">
                    <div class="box-group addmore" *ngFor="let key of getCampaignList()">
                        <div class="flow-link" [ngClass]="{'addmore' : journeyCampaign[key].length > 0}">
                            <button (click)="addCampaign(key)" *ngIf="!checkTemplate(key)" class="btn btn-light btn-lg">Add Item</button>
                            <button (click)="selectFrequency(key)" *ngIf="checkTemplate(key)" class="btn btn-light btn-lg">
                                <ng-container *ngIf="journeyCampaign[key][0].campIntervalName == ''; else updateFeq">
                                    Select Frequency
                                </ng-container>
                                <!-- <ng-template #updateFeq>
                                    {{journeyCampaign[key][0].campIntervalName == 'minutes' && journeyCampaign[key][0].campIntervalTime == 4 ? 'Send Instantly' : 'Wait ' + journeyCampaign[key][0].campIntervalTime + ' '+ journeyCampaign[key][0].campIntervalName | titlecase}}
                                </ng-template> -->

                                <ng-template #updateFeq>
                                    {{journeyCampaign[key][0].campIntervalName == 'minutes' && journeyCampaign[key][0].campIntervalTime == 4 ? 'Send Instantly' : 'Wait '}}
                                    <span *ngIf="journeyCampaign[key][0].days">
                                        {{journeyCampaign[key][0].days}}
                                    </span>
                                    
                                    <span *ngIf="journeyCampaign[key][0].hrs">
                                        {{journeyCampaign[key][0].hrs}}
                                    </span>
                                    <span *ngIf="journeyCampaign[key][0].mins && journeyCampaign[key][0].campIntervalTime != 4">
                                        {{journeyCampaign[key][0].mins}}
                                    </span>
                                    <span class="approx_date" *ngIf="journeyCampaign[key][0].minutes > '04' && journeyCampaign[key][0].hours != '00' || journeyCampaign[key][0].days != '' ">
                                        Approx Date
                                        {{journeyCampaign[key][0].daysConvert}} {{journeyCampaign[key][0].hours}}:{{journeyCampaign[key][0].minutes}}
                                    </span>
                                </ng-template>
                                
                            </button>
                            
                        </div>
                        <div class="type row flex_now_wrap">
                            <div *ngFor="let item of journeyCampaign[key]; let i = index" class="box journeyTypeList">
                                <div class="text-center" *ngIf="item.templateName.length == 0">
                                    <button class="btn btn-light" (click)="addTemplate(item,i,key)"><img src="assets/images/{{item.campaignType}}-2.svg" alt=""> {{item.campaignType}}</button>
                                </div>
                                <div class="add" *ngIf="item.templateName.length > 0">
                                    <div class="top">
                                        <h3><img src="assets/images/{{item.campaignType}}-2.svg" alt=""> {{item.campaignType}}</h3>
                                        <!-- <span class="delete" (click)="deleteTemplate(item.campaignType,i,key)"><img src="assets/images/trash-alt-gray.svg" alt=""></span> -->
                                    </div>
                                    <div class="body">
                                        <div *ngFor="let temp of item.templateName;let j = index" class="btn btn-gray btn-block">
                                            <button (click)="addTemplate(item,i,key,j)">{{temp | titlecase}}</button>
                                            <span (click)="deleteTemplateName(item.campaignType,i,key,j)" class="delete"><img src="assets/images/times-circle-gray.svg" alt=""></span>
                                        </div>
                                        <div class="add-btn" *ngIf="maxTemplate > item.templateName.length" (click)="addTemplate(item,i,key)"><button class="btn btn-gray">Add</button></div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="text-right mt-3 mr-4">
                <button class="btn btn-dark" (click)="submitJourney()">{{isUpdated ? 'Update' : 'Save'}}</button>
            </div>
        </div>
    </section>
</main>

<ng-template #templateListModal>
    <div class="campaign-modal">
        <div class="modal-header">
            <h4 class="modal-title pull-left">Template</h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()"><span aria-hidden="true">&times;</span></button>
        </div>
        <div class="modal-body">
            <div class="d-flex justify-content-between mb-2 align_center" *ngIf="selectedCampaign.campaignType != 'inapp'">
                <div class="check-list form-group m_0">
                    <div *ngIf="selectedCampaign.campaignType =='android'">
                        <label class="check">
                            <span class="icon-android"></span>
                            Android
                            <input type="radio" name="platFormCheck" value="android" [(ngModel)]="pushType" (change)="templateSelected('android',$event.target.checked)">
                            <!-- <input type="checkbox" name="{{platform.value}}" value="{{platform.value}}" [(ngModel)]="platform.checked"> -->
                            <span class="checkmark"></span>
                        </label>
                    </div>
                    <div *ngIf="selectedCampaign.campaignType =='ios'">
                        <label class="check">
                            <span class="icon-apple"></span>
                            IOS
                            <input type="radio" name="platFormCheck" value="ios" [(ngModel)]="pushType" (change)="templateSelected('ios',$event.target.checked)">
                            <!-- <input type="checkbox" name="{{platform.value}}" value="{{platform.value}}" [(ngModel)]="platform.checked"> -->
                            <span class="checkmark"></span>
                        </label>
                    </div>
                    <div *ngIf="selectedCampaign.campaignType =='web'">
                        <label class="check">
                            <span class="icon-globe"></span>
                            Web
                            <input type="radio" name="platFormCheck" value="web" [(ngModel)]="pushType" (change)="templateSelected('web',$event.target.checked)">
                            <!-- <input type="checkbox" name="{{platform.value}}" value="{{platform.value}}" [(ngModel)]="platform.checked"> -->
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </div>
                <div><a *ngIf="templateList.length > 0" class="btn btn-outline-dark btn-sm" [routerLink]="['/admin/campaign/campaign-template/'+selectedCampaign.campaignType]" (click)="submitJourney('ls')">Add Template</a></div>
            </div>
            <ul class="template-list" *ngIf="templateList.length > 0">
                <li *ngFor="let item of templateList" (click)="selectedTemplate = item" [ngClass]="{'active' : selectedTemplate.id == item.id}">{{item.templateName}}</li>
            </ul>
            <div *ngIf="templateList.length == 0" class="text-center my-5">
                <a *ngIf="selectedCampaign.campaignType != 'inapp'" class="btn btn-outline-dark" [routerLink]="['/admin/campaign/campaign-template/'+selectedCampaign.campaignType]" (click)="submitJourney('ls')">Add Template</a>
                <div *ngIf="selectedCampaign.campaignType == 'inapp'" class="no-records">No Template Found</div>
            </div>
            <div class="text-right mt-3">
                <button class="btn btn-dark" (click)="selectTemplate()">Submit</button>
            </div>
        </div>
    </div>
</ng-template>  

<ng-template #sendTimeModal>
    <div class="campaign-modal">
        <div class="modal-header">
            <h4 class="modal-title pull-left">Select Send Time</h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()"><span aria-hidden="true">&times;</span></button>
        </div>
        <div class="modal-body time-detail">
            <ng-container *ngIf="selectedKey == 1">
            <p><strong>When do you want to send this campaign?</strong><br>
                Once a contact meets the entry criteria for your automation, you can send your first campaign instantly, or you can choose a number of days or hours to wait before you send.</p>
                <div class="row radio-list">
                    <div class="col-md-6 form-check">
                        <input class="form-check-input" type="radio" name="sendType" id="sendType0" value="0" checked [(ngModel)]="sendTime.type">
                        <label class="form-check-label" for="sendType0">Send the first campaign instantly</label>
                    </div>
                    <div class="col-md-6 form-check">
                        <input class="form-check-input" type="radio" name="sendType" id="sendType1" value="1" [(ngModel)]="sendTime.type">
                        <label class="form-check-label" for="sendType1">Wait to send the first campaign</label>
                    </div>
                </div>
            <hr>
            </ng-container>
            <ng-container *ngIf="sendTime.type == 1">
                <p><strong>How long do you want to wait to send the first campaign?</strong> <br>
                    For example, if a contact enters your series on Monday, and you send the first campaign 3 days later, your contact will receive the campaign on Thursday.</p>
                <div class="time">
                    <div class="sendTitle">Send this campaign in:</div>
                    <ng-container *ngIf="selectedKey != 1">
                    <span class="days_counter previousSelectedUnit currentTime" *ngIf="showMins || showHr || showDay">
                        <span *ngIf="showDay">
                            {{showDay}} Days
                        </span>
                        <span *ngIf="showHr">
                            {{showHr}} Hr
                        </span>
                        <span *ngIf="showMins">
                            {{showMins}} Minutes
                        </span>
                    </span>
                </ng-container>
                    <!-- <ng-template *ngIf="sendTime.intervalName == hours && sendTime.intervalTime > 24">
                        {{sendTime.intervalTime}}
                    </ng-template> -->
                    <input class="form-control intervalTime" type="text" [(ngModel)]="sendTime.intervalTime" maxlength="2" oninput="this.value=this.value.replace(/[^0-9]/g,'')">
                    <select class="form-control" [(ngModel)]="sendTime.intervalName">
                        <option value="days">Day(s)</option>
                        <option value="hours">Hour(S)</option>
                        <option value="minutes">Minute(S)</option>
                    </select>
                    after a contact enters the automation.
                </div>
            </ng-container>
            <div class="text-right mt-3 mr-4">
                <button class="btn btn-dark" (click)="submitSendTime()">Submit</button>
            </div>
        </div>
    </div>
</ng-template>