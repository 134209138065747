import { DatePipe, Location } from '@angular/common';
import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Global } from '../../../../../app/shared/models/global.model';
import { AppConstants } from '../../../../../app/constants/app.constants';
import { ReportService } from '../../../../core/services/report.service';
import * as moment from 'moment';
import { DatePickerObj } from '../../../../core/modal/datePicker';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import * as XLSX from 'xlsx';
import { ThrowStmt } from '@angular/compiler';
import { AudienceFilter } from '../../../../core/services/cache.modal';
import { ActivatedRoute, Event, NavigationStart, Router } from '@angular/router';
import { ProjectService } from '../../../../core/services/project.service';
import { ToastrService } from 'ngx-toastr';
import { LeadStatusObj } from '../../../../core/modal/project';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { SpinnerService } from '../../../../core/services/spinner.service';

@Component({
    selector: 'app-audience-report',
    templateUrl: './audience-report.component.html',
    styleUrls: ['./audience-report.component.scss']
})
export class AudienceReportComponent implements OnInit {
    leadTotal: number[] = [];
    audienceLeadData: any[] = [];
    audienceLeadDataCSV: any[] = [];
    audienceLeadDataGroup: any[] = [];
    audienceSourceData: any;
    filterDateRange: DatePickerObj;
    followUpfilterDateRange: DatePickerObj;
    fromDate: any;
    toDate: any;
    followUpFromDate: any;
    followUpToDate: any;
    missedToDate: any;
    followUpCompleteToDate: any;
    todayDate: any = new Date();
    tomorrowDate: any = new Date();
    oneYearAfterTodayDate: any = new Date();
    userRole: number;
    adminRoleid: number;
    ranges: any;
    maxDate: any;
    filterData;
    followUpList: any[] = [];
    totalToday
    totalMissed;
    totalCompleted;
    totalOverdue;
    userid:any;
    sortByDate:any;
    maxRange:any;
    positionOfTblFollow:any='vertical';
    positionOfTblLeadReport:any='vertical'
    positionOfTbSource:any='vertical'
    entry_update_selector_follow:any;
    userRoleType:any;
    entry_update_selector_lead_report:any;
    fixedOneWeek: any = moment().subtract(6, 'days')
    @ViewChild('download_xl_modal') download_xl_modal:TemplateRef<any>;
    @ViewChild('download_xl_modal_lead_report') download_xl_modal_lead_report:TemplateRef<any>;
    public leasdStatusList: string[] = [];
    audienceFilter:AudienceFilter[] = [];
    leadStatusData:LeadStatusObj[] = [];
    removedIndex:any = [];
    removedIndex2:any = [];
    queryParamsFromMenu:any;
    parentMenu:string;
    modalRef: BsModalRef;
    totalShared:any;
    emailAddress:any;
    @ViewChild('leadReport') leadReport: ElementRef;
    @ViewChild('followUpReports') followUpReports: ElementRef;
    @ViewChild('sourceReports') sourceReports: ElementRef;
    myObserver:any;
    categoryParams:any;
    dataList:any;
    categoryName:any;
    usersNameList:any;
    allUserFromMaster:any
    users:any;
    reportUsers:any;
    reportemailAddress:any;
    reportUsersList:any;
    filterDateRangeDownloadWindow:any;
    constructor(
        private reportService: ReportService,
        private datePipe: DatePipe,
        private global: Global,
        private authenticationService: AuthenticationService,
        private location:Location,
        private authenticService:AuthenticationService,
        private router:Router,
        private projectService:ProjectService,
        private toastr:ToastrService,
        private route:ActivatedRoute,
        private modalService:BsModalService,
        private spinner: SpinnerService,
    ) {
        this.fetchUserProfile();
    }

    ngOnInit() {
        this.projectService.getUserFromMaster().then(data=>{
            console.log(data);
            this.allUserFromMaster = [];
            let userId = Object.keys(data);
            userId.forEach(elem=>{
                if(data[elem].status == 'ACTIVE'){
                    this.allUserFromMaster.push({id:data[elem].id,userName:data[elem].userName});
                }
            })
        })
        this.fetchCategory();
        this.route.queryParams.subscribe(data=>{
            if(data.category){
                this.categoryName = data.category;
            }
        })
        this.route.paramMap.subscribe(data=>{
            if(data){
                this.queryParamsFromMenu = data;
            }
        })
        if(this.queryParamsFromMenu){
            this.parentMenu = this.queryParamsFromMenu['params'].categoryparam;
          }                    
        this.projectService.fetchLeadStatus('audience',{}).subscribe(data => {            
            if (data.status) {
                this.leadStatusData = data.data;    
            } else {
                this.toastr.error(data.error_msgs, 'Error');
            }
        })
        this.projectService.getMaxRange().then(data=>{
            this.maxRange = data
        });
        this.entry_update_selector_follow = 'entry_date';
        this.entry_update_selector_lead_report = 'entry_date';
        this.maxDate = moment();
        this.ranges = this.global.customDateRange();
        this.userRole = this.authenticationService.getUserRole();
        this.adminRoleid = AppConstants.ADMIN_ROLEID;
        this.followUpfilterDateRange = {
            startDate: moment().subtract(6, 'days'),
            endDate: moment()
        };
        if (this.reportService.leadCountsDaysFollow != undefined) {
            this.followUpfilterDateRange = this.reportService.leadCountsDaysFollow;
        } else {
            this.followUpfilterDateRange = {
                startDate: moment().subtract(6, 'days'),
                endDate: moment(),
            };
        }

        if (this.reportService.leadCountsDays != undefined) {
            this.filterDateRange = this.reportService.leadCountsDays;
        } else {
            this.filterDateRange = {
                startDate: moment().subtract(6, 'days'),
                endDate: moment(),
            };
        }

        this.followUpFromDate = this.datePipe.transform(this.followUpfilterDateRange.startDate, AppConstants.DATE_FORMAT_FOR_DATEPICKER);
        this.followUpToDate = this.datePipe.transform(this.followUpfilterDateRange.endDate, AppConstants.DATE_FORMAT_FOR_DATEPICKER);
        this.followUpCompleteToDate =  this.followUpToDate;
        this.missedToDate = this.datePipe.transform(this.followUpfilterDateRange.endDate, AppConstants.DATE_FORMAT_FOR_DATEPICKER);
        if(this.datePipe.transform(this.todayDate, AppConstants.DATE_FORMAT_FOR_DATEPICKER) ==  this.followUpToDate){
            let tmpFollowUpdate: any = new Date();
            this.missedToDate = this.datePipe.transform( tmpFollowUpdate.setDate(this.todayDate.getDate() - 1), AppConstants.DATE_FORMAT_FOR_DATEPICKER);
            let tmpCompleteTodayDate: any = new Date();
            this.followUpCompleteToDate = this.datePipe.transform( tmpCompleteTodayDate, AppConstants.DATE_FORMAT_FOR_DATEPICKER);
        }
        this.tomorrowDate = this.datePipe.transform(this.tomorrowDate.setDate(this.todayDate.getDate() + 1), AppConstants.DATE_FORMAT_FOR_DATEPICKER);
        this.oneYearAfterTodayDate = this.datePipe.transform(this.oneYearAfterTodayDate.setFullYear(this.todayDate.getFullYear() + 1), AppConstants.DATE_FORMAT_FOR_DATEPICKER);
        this.todayDate = this.datePipe.transform(this.todayDate, AppConstants.DATE_FORMAT_FOR_DATEPICKER);
    
        this.myObserver = this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationStart) {
              this.route.paramMap.subscribe(data=>{
                this.categoryParams = data;
                if(this.categoryParams.params.categoryparam){
                    this.updateFollowUpDateRange();
                    this.updateLeadReport()
                    this.myObserver.unsubscribe();
                }
              });
            }
        });
        
    }

    send_email(){     
        this.spinner.show();
        const ws = XLSX.utils.table_to_sheet(this.followUpReports.nativeElement, { raw: true });
        const ws2 = XLSX.utils.table_to_sheet(this.leadReport.nativeElement, { raw: true });
        const ws3 = XLSX.utils.table_to_sheet(this.sourceReports.nativeElement, { raw: true });
        let a = XLSX.utils.sheet_to_json(ws, { header: 1 });
        let b = XLSX.utils.sheet_to_json(ws2, { header: 1 });
        let c = XLSX.utils.sheet_to_json(ws3, { header: 1 });
        a = a.concat([]).concat(b).concat(c);
        let worksheet = XLSX.utils.json_to_sheet(a, { skipHeader: true })
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, worksheet, 'Sheet1');
        const fileToSend: ArrayBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const fileToSendBlob = new Blob([fileToSend], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        let postData = {"file":"","email":""}
        let readerResult 
        const reader = new FileReader();
        reader.readAsDataURL(fileToSendBlob);
        reader.onload = () => {
            console.log(reader.result);
            readerResult = reader.result
            postData.file = readerResult;        
        var email_reg = /^[A-Za-z0-9]+[A-Za-z0-9._!#$%&'*+-/=?^_`{|}~]+@([A-Za-z0-9-]+[.]){1,2}[A-Za-z.]{2,10}$/;
        if(!this.emailAddress || this.emailAddress == ""){
            this.toastr.error('Please enter email address');
            return;
        }
        if(email_reg.test(this.emailAddress) == false){
            this.toastr.error('Please enter valid email address');
            return
        }
        postData.email = this.emailAddress;
        this.reportService.sendFollowUpEmail(postData).subscribe(data=>{
            if(data.status){
                this.toastr.success(data.data);
                this.modalRef.hide();
                this.spinner.show();
            } else{
                this.toastr.error(data.error_msgs);
                this.spinner.show();
            }
        })
    }
    }

    downloadXl()
    {        
        this.users = [];
        this.emailAddress = "";
        this.modalRef = this.modalService.show(this.download_xl_modal, {
            backdrop: 'static'
        });
    }

    downloadXlReport(){
        this.reportUsers = [];
        this.reportemailAddress = "";
        this.modalRef = this.modalService.show(this.download_xl_modal_lead_report, {
            backdrop: 'static'
        });  
    }

    fetchCategory() {        
        this.dataList = [];
        this.projectService.fetchCategory({}).then(data => {
            if (data) {
                const dataList = data;   
                let id = this.authenticService.getUserId();
                if(this.authenticService.getUserRole() != 1){
                for(let i=0; i<dataList.length; i++){
                    if(dataList[i]){
                    let getUserList = dataList[i].userList.find(userid=>userid == id);
                    if(getUserList){
                        let checkToPush = this.dataList.find(x=>x.linkRewrite == dataList[i].linkRewrite);
                        if(checkToPush == undefined){
                            this.dataList.push(dataList[i])
                        }                   
                    }
                }
                }
            }  else{
                this.dataList = dataList
              }
                let checkCatName = localStorage.getItem('audReportCatName');
                if(checkCatName){
                    this.categoryName = checkCatName;
                    this.fetchLeadStatus();
                    this.updateFollowUpDateRange();
                    this.updateLeadReport();
                    return
                }
                if(!this.categoryName){
                    let checkForAudCat = this.dataList.find(x=>x.linkRewrite == 'audience');
                    if(checkForAudCat){
                        this.categoryName='audience';
                    } else{
                        this.categoryName=this.dataList[0].linkRewrite;
                    }
                // this.categoryName = 'audience';
                }
            }
        })
    }
    

    back()
    {
        this.location.back();
    }

    vertical_tab(position,table)
    {        
        if(table == 'followUp')
        {
        if(position == 'vertical')
        {
            this.positionOfTblFollow = 'horizontal';
        }
        if(position == 'horizontal')
        {
            this.positionOfTblFollow = 'vertical';
        }
        }

        if(table == 'leadReport')
        {            
        if(position == 'vertical')
        {            
            this.positionOfTblLeadReport = 'horizontal';
        }
        if(position == 'horizontal')
        {            
            this.positionOfTblLeadReport = 'vertical';
        }
        }

        if(table == 'leadSource')
        {            
        if(position == 'vertical')
        {            
            this.positionOfTbSource = 'horizontal';
        }
        if(position == 'horizontal')
        {            
            this.positionOfTbSource = 'vertical';
        }
        }
        
    }

    downloadFile() {
        const ws = XLSX.utils.table_to_sheet(this.followUpReports.nativeElement, { raw: true });
        const ws2 = XLSX.utils.table_to_sheet(this.leadReport.nativeElement, { raw: true });
        const ws3 = XLSX.utils.table_to_sheet(this.sourceReports.nativeElement, { raw: true });

        let a = XLSX.utils.sheet_to_json(ws, { header: 1 });
        let b = XLSX.utils.sheet_to_json(ws2, { header: 1 });
        let c = XLSX.utils.sheet_to_json(ws3, { header: 1 });

        a = a.concat([]).concat(b).concat(c);
        let worksheet = XLSX.utils.json_to_sheet(a, { skipHeader: true })


        const wb: XLSX.WorkBook = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(wb, worksheet, 'Sheet1');
        /* save to file */
        XLSX.writeFile(wb, 'SheetJS.xlsx');
    }

    update_entry_date_follow()
    {
        this.entry_update_selector_follow;
    }

    update_entry_date_lead_rprt()
    {
        this.entry_update_selector_lead_report;
        setTimeout(() => {
            this.getReportData();
            this.getLeadCountSource();
        }, 1000);  
    }

    fetchLeadStatus() {
        let parentMenu;
        if(this.queryParamsFromMenu){
            parentMenu = this.queryParamsFromMenu['params'].categoryparam;
        }
        
        this.projectService.fetchLeadStatus(this.categoryName,{}).subscribe(data => {
        if (data.status) {
            this.leadStatusData = data.data;
        } else {
            this.toastr.error(data.error_msgs, 'Error');
        }
    })
    }

     updateLeadReport() {

        let fromDate:any = this.filterDateRange.startDate;
        let endDate:any =  this.filterDateRange.endDate;
        let differenceBetweenDate = Math.floor((endDate - fromDate) / (1000 * 60 * 60 * 24));
        differenceBetweenDate = differenceBetweenDate + 1;
        if(differenceBetweenDate > this.maxRange){
            this.toastr.error('Max date range should not be greater than ' + this.maxRange + ' days');
            return
        }

        this.fromDate = this.datePipe.transform(this.filterDateRange.startDate, AppConstants.DATE_FORMAT_FOR_DATEPICKER);
        this.toDate = this.datePipe.transform(this.filterDateRange.endDate, AppConstants.DATE_FORMAT_FOR_DATEPICKER);
         setTimeout(() => {
            this.getReportData();
            this.getLeadCountSource()
        }, 1000);
    }

    updateFollowUpDateRange() {

        let fromDate:any = this.followUpfilterDateRange.startDate;
        let endDate:any =  this.followUpfilterDateRange.endDate;
        let differenceBetweenDate = Math.floor((endDate - fromDate) / (1000 * 60 * 60 * 24));
        differenceBetweenDate = differenceBetweenDate + 1;
    
        if(differenceBetweenDate > this.maxRange){
            this.toastr.error('Max date range should not be greater than ' + this.maxRange + ' days');
            return
        }

        this.followUpFromDate = this.datePipe.transform(this.followUpfilterDateRange.startDate, AppConstants.DATE_FORMAT_FOR_DATEPICKER);
        this.followUpToDate = this.datePipe.transform(this.followUpfilterDateRange.endDate, AppConstants.DATE_FORMAT_FOR_DATEPICKER);
        this.followUpCompleteToDate =  this.followUpToDate;
        this.missedToDate = this.datePipe.transform(this.followUpfilterDateRange.endDate, AppConstants.DATE_FORMAT_FOR_DATEPICKER);
       
        if(this.todayDate ==  this.followUpToDate){
            let tmpFollowUpdate: any = new Date();
            this.missedToDate = this.datePipe.transform( tmpFollowUpdate.setDate(tmpFollowUpdate.getDate() - 1), AppConstants.DATE_FORMAT_FOR_DATEPICKER);
            let tmpCompleteTodayDate: any = new Date();
            this.followUpCompleteToDate = this.datePipe.transform( tmpCompleteTodayDate, AppConstants.DATE_FORMAT_FOR_DATEPICKER);
     
        }
        setTimeout(() => {
            this.getFollowData();
        }, 1000);
    }



    async getReportData() {
        this.userRoleType = this.authenticService.getUserRoleType();
        this.userid = this.authenticService.getUserId();
        let userData = this.authenticationService.getUserData();
        this.removedIndex2 = []
        let removedIndex = [];
        let parentMenu;
        if(this.queryParamsFromMenu){
            parentMenu = this.queryParamsFromMenu['params'].categoryparam;
        }
        await this.reportService.getleadCountsData(this.filterDateRange,this.sortByDate,this.categoryName).then(data => {
            if (data.status) {         
                console.log(data);
                       
                this.audienceLeadDataGroup = [];
                this.audienceLeadData = data.data;  
                this.reportUsersList = []                                              
                if (this.audienceLeadData.length > 0) {
                    this.leasdStatusList = []
                    this.leasdStatusList = eval(this.audienceLeadData[0].attributeName);
                    this.leasdStatusList.push('Total');  
                    if(userData.userRoleId != 1){
                    this.leasdStatusList.forEach((elem,i)=>{
                        let checkAttrByRole = this.leadStatusData.find(x=>x.stageName == elem);
                        if(checkAttrByRole){
                            if(checkAttrByRole.roles && checkAttrByRole.roles.length > 0){
                                let checkUserRoleToShowAttr = checkAttrByRole.roles.find(x=>x==userData.userRoleId);
                                if(checkUserRoleToShowAttr == undefined){
                                    this.removedIndex2.push(i);
                                    this.leasdStatusList.splice(i,1);
                                }
                            }
                        }
                    })
                }
                    this.audienceLeadData.forEach((element, j) => {   
                        console.log(element);
                        
                        element.userIdEncode = encodeURIComponent(element.userId);
                        this.reportUsersList.push({userName:element.userName,id:element.userIdEncode})
                        element.count = eval(element.count);
                        element.total = element.count.reduce((acc, cur) => acc + cur, 0);
                        element.count.forEach((item, i) => {
                            if(this.removedIndex2.length > 0){
                            this.removedIndex2.forEach((elem)=>{
                                if(i == elem){
                                    element.count.splice(i,1);
                                }
                            })
                        }

                            if (j == 0) {
                                this.leadTotal[i] = item;
                            } else {
                                this.leadTotal[i] += item;
                            }
                        });
                        let parentId = element.parentUserId;
                        if (this.audienceLeadDataGroup[parentId] == undefined) {
                            this.audienceLeadDataGroup[parentId] = [];
                            this.audienceLeadDataGroup[parentId][0] = { count: [] };
                        }
                        this.audienceLeadDataGroup[parentId].push(element);                        
                    });
                }
                if (this.audienceLeadData.length > 0) {
                    
                    this.getLeadMangerName().forEach(data => {  
                                            
                        if (data.length > 0) {                            
                            this.audienceLeadDataGroup[data].forEach((element, j) => {  
                                if(element.userId == this.userid){
                                    if(data == element.parentUserId){
                                        data = "";
                                        element.parentUserName = 'hidden';
                                        element.parentUserId = 'hidden';
                                        return
                                    }
                                }

                                this.audienceLeadDataGroup[data][0].totalCount = []                           
                                
                                element.count.forEach((item, i) => {
                                    if (j == 1) {
                                        this.audienceLeadDataGroup[data][0].count[i] = item;  
                                        this.audienceLeadDataGroup[data][0].totalCount.push(this.audienceLeadDataGroup[data][0].count[i])                                         
                                    } else {
                                        this.audienceLeadDataGroup[data][0].count[i] += item;    
                                        this.audienceLeadDataGroup[data][0].totalCount.push(this.audienceLeadDataGroup[data][0].count[i])                                    
                                    }
                                    element.total = element.count.reduce((acc, cur) => acc + cur, 0);

                                });

                                this.audienceLeadDataGroup[data][0].total = this.audienceLeadDataGroup[data][0].totalCount.reduce((acc, cur) => acc + cur, 0);
                            });                            
                        }
                        
                    })
                } else if(this.audienceLeadData.length == 0){
                    this.leasdStatusList = []
                }
            } else{
                this.audienceLeadData = [];
                this.leasdStatusList = [];
                this.toastr.error(data.error_msgs)
            }
        });

    }

    getLeadCountSource()
    {
        this.removedIndex = [];
        let removedIndex = [];
        let userData = this.authenticationService.getUserData();
        let postData  = {
            startDate: this.datePipe.transform(this.filterDateRange.startDate, "yyyy-MM-dd"),
            endDate: this.datePipe.transform(this.filterDateRange.endDate, "yyyy-MM-dd"),
            reportDateType:this.sortByDate
        }        
        postData.startDate = postData.startDate.split('T')[0];
        postData.endDate = postData.endDate.split('T')[0];

        let parentMenu;
        if(this.queryParamsFromMenu){
            parentMenu = this.queryParamsFromMenu['params'].categoryparam;
        }
        
        this.reportService.leadCountsSource(postData, this.categoryName).subscribe(data=>{
            if(data.status){
                this.audienceSourceData = data.data;                
                if (this.audienceSourceData.length > 0) {
                    this.leasdStatusList = []
                    this.leasdStatusList = eval(this.audienceSourceData[0].attributeName);
                    this.leasdStatusList.push('Total');  
                    if(userData.userRoleId != 1){
                    this.leasdStatusList.forEach((elem,i)=>{
                        let checkAttrByRole = this.leadStatusData.find(x=>x.stageName == elem);
                        if(checkAttrByRole){
                            if(checkAttrByRole.roles && checkAttrByRole.roles.length > 0){
                                let checkUserRoleToShowAttr = checkAttrByRole.roles.find(x=>x==userData.userRoleId);
                                if(checkUserRoleToShowAttr == undefined){
                                    this.removedIndex.push(i);
                                    this.leasdStatusList.splice(i,1);
                                }
                            }
                        }
                    })

                }
                    this.audienceSourceData.forEach((element, j) => {
                        element.count = eval(element.count);
                        element.count.forEach((item, i) => {
                            if(this.removedIndex.length > 0){
                                this.removedIndex.forEach((elem)=>{
                                if(i == elem){
                                    element.count.splice(i,1);
                                }
                            })
                        }
                        });
                        element.count.push(element.count.reduce((acc, cur) => acc + cur, 0));
                    });
                }
            } else{
                this.audienceSourceData = [];
                this.toastr.error(data.error_msgs)
            }
        })
    }


    getLeadMangerName() {        
        if (this.audienceLeadData.length > 0)        
            return Object.keys(this.audienceLeadDataGroup);
    }

    fetchUserProfile(){
        this.authenticService.getProfile().then(data=>{
            if(data.status){
                this.sortByDate = data.sortBy;
            }
        })
    }

    goToDetail(param1,param2,param3,param4,param5,param6,param7,param8,param9){        
        if(param1 == 'followUpByUser'){
            this.audienceFilter.push({paramKey:'lead_followdate',paramValue:'date(' + this.datePipe.transform(param2, "yyyy-MM-dd 00:00:00") + ') and date(' + this.datePipe.transform(param3, "yyyy-MM-dd 23:59:59") +')',text1:param2,text2:param3});
            this.audienceFilter.push({paramKey:'userFromReport',paramValue:param4,text1:param5});
            this.audienceFilter.push({paramKey:'lead_status',paramValue:param6,text1:param6,reportType:'followupCompleted'});
            localStorage.setItem('filterData',JSON.stringify(this.audienceFilter));
            this.router.navigate(['/admin/lead/',this.categoryName]);
        }

        if(param1 == 'followUpByUserToday'){
            let removeParam = param9;            
            if(param9.includes('params.') == true){
                removeParam = param9.split('params.')[1];                
            }            
            this.audienceFilter.push({paramKey:removeParam,paramValue:'date(' + this.datePipe.transform(param2, "yyyy-MM-dd 00:00:00") + ') and date(' + this.datePipe.transform(param3, "yyyy-MM-dd 23:59:59") +')',text1:param2,text2:param3});
            this.audienceFilter.push({paramKey:'lead_followdate',paramValue:'date(' + this.datePipe.transform(param4, "yyyy-MM-dd 00:00:00") + ') and date(' + this.datePipe.transform(param5, "yyyy-MM-dd 23:59:59") +')',text1:param4,text2:param5});
            this.audienceFilter.push({paramKey:'userFromReport',paramValue:param6,text1:param7});
            this.audienceFilter.push({paramKey:'lead_status',paramValue:param8,text1:param8,reportType:'followup'});
            localStorage.setItem('filterData',JSON.stringify(this.audienceFilter));
            this.router.navigate(['/admin/lead/',this.categoryName]);
        }

        if(param1 == 'followUpByUserMissed'){

            let todayDateTime = this.datePipe.transform(new Date(), "yyyy-MM-dd HH:mm:ss");
            let todayDate = this.datePipe.transform(new Date(), "yyyy-MM-dd"); 
            let toDate
            let calendar = this.followUpfilterDateRange
            let startDateCalendar = this.datePipe.transform(calendar.startDate,"yyyy-MM-dd HH:mm:ss");
            let endDateCalendar = this.datePipe.transform(calendar.endDate,"yyyy-MM-dd");
            let endDateSet = this.datePipe.transform(calendar.endDate,"yyyy-MM-dd 23:59:59");
            if(endDateCalendar > todayDate){
                toDate = todayDateTime
            } else if(endDateCalendar == todayDate){
                toDate = todayDateTime
            } else if(endDateCalendar < todayDate) {
                toDate = endDateSet
            }                        
            this.audienceFilter.push({paramKey:'lead_followdate',paramValue:'date(' + this.datePipe.transform(param4, "yyyy-MM-dd 00:00:00") + ') and date(' + toDate +')',text1:param4,text2:toDate,missedReport:'missedDateTime'});
            this.audienceFilter.push({paramKey:'userFromReport',paramValue:param6,text1:param7});
            this.audienceFilter.push({paramKey:'lead_status',paramValue:param8,text1:param8,reportType:'followup'});
            localStorage.setItem('filterData',JSON.stringify(this.audienceFilter));
            this.router.navigate(['/admin/lead/',this.categoryName]);
        }

        if(param1 == 'followUpByUserCompleted'){
            let todayDateTime = this.datePipe.transform(new Date(), "yyyy-MM-dd HH:mm:ss");
            let todayDate = this.datePipe.transform(new Date(), "yyyy-MM-dd");
            let toDate
            let calendar = this.followUpfilterDateRange
            let startDateCalendar = this.datePipe.transform(calendar.startDate,"yyyy-MM-dd HH:mm:ss");
            let endDateCalendar = this.datePipe.transform(calendar.endDate,"yyyy-MM-dd");
            let endDateSet = this.datePipe.transform(calendar.endDate,"yyyy-MM-dd 23:59:59");
            if(endDateCalendar > todayDate){
                toDate = todayDateTime;
            } else if(endDateCalendar == todayDate){
                toDate = todayDateTime
            } else{
                toDate = endDateSet
            }
            this.audienceFilter.push({paramKey:'lead_followdate',paramValue:'date(' + this.datePipe.transform(param4, "yyyy-MM-dd 00:00:00") + ') and date(' + toDate +')',text1:param4,text2:toDate,missedReport:'missedDateTime'});
            this.audienceFilter.push({paramKey:'userFromReport',paramValue:param6,text1:param7});
            this.audienceFilter.push({paramKey:'lead_status',paramValue:param8,text1:param8,reportType:'completed'});
            localStorage.setItem('filterData',JSON.stringify(this.audienceFilter));
            this.router.navigate(['/admin/lead/',this.categoryName]);
        }

        if(param1 == 'followUpByUserUpcoming'){
            let calendar = this.followUpfilterDateRange
            let startDateCalendar = this.datePipe.transform(calendar.startDate,"yyyy-MM-dd HH:mm:ss");
            let endDateCalendar = calendar.endDate;
            let todayDateTime = this.datePipe.transform(new Date(), "yyyy-MM-dd HH:mm:ss");
            let todayDate = this.datePipe.transform(new Date(), "yyyy-MM-dd HH:mm");
            let followFromDate = this.datePipe.transform(this.followUpFromDate, "yyyy-MM-dd HH:mm");
            if(todayDate < followFromDate){
                this.audienceFilter.push({paramKey:'lead_followdate',paramValue:'date(' + this.followUpFromDate + ') and date(' + this.datePipe.transform(param5, "yyyy-MM-dd 23:59:59") +')',text1:this.followUpFromDate,text2:this.datePipe.transform(param5, "yyyy-MM-dd 23:59:59"),missedReport:'upcoming_next'});
            } else{
                this.audienceFilter.push({paramKey:'lead_followdate',paramValue:'date(' + todayDateTime + ') and date(' + this.datePipe.transform(param5, "yyyy-MM-dd 23:59:59") +')',text1:todayDateTime,text2:this.datePipe.transform(param5, "yyyy-MM-dd 23:59:59"),missedReport:'upcoming'});
            }
            this.audienceFilter.push({paramKey:'userFromReport',paramValue:param6,text1:param7});
            this.audienceFilter.push({paramKey:'lead_status',paramValue:param8,text1:param8,reportType:'followup'});
            localStorage.setItem('filterData',JSON.stringify(this.audienceFilter));
            this.router.navigate(['/admin/lead/',this.categoryName]);
        }

        if(param1 == 'followUpByUserSharing'){            
            let removeParam = param6;            
            if(param6.includes('params.') == true){
                removeParam = param6.split('params.')[1];                
            }
            this.audienceFilter.push({paramKey:removeParam,paramValue:'date(' + this.datePipe.transform(param2, "yyyy-MM-dd 00:00:00") + ') and date(' + this.datePipe.transform(param3, "yyyy-MM-dd 23:59:59") +')',text1:param2,text2:param3});
            this.audienceFilter.push({paramKey:'userFromReport',paramValue:param4,text1:param5});
            this.audienceFilter.push({paramKey:'is_shared',paramValue:1,text1:1,reportType:'followupShared'});
            localStorage.setItem('filterData',JSON.stringify(this.audienceFilter));
            this.router.navigate(['/admin/lead/'+this.categoryName]);
        }
    }

    goToDetailLeadReport(param1,param2,param3,param4,param5,param6,param7){
        if(param1 == 'byuser'){
            let removeParam = param6;            
            if(param6.includes('params.') == true){
                removeParam = param6.split('params.')[1];                
            }
            this.audienceFilter.push({paramKey:removeParam,paramValue:'date(' + this.datePipe.transform(param2, "yyyy-MM-dd 00:00:00") + ') and date(' + this.datePipe.transform(param3, "yyyy-MM-dd 23:59:59") +')',text1:param2,text2:param3});
            this.audienceFilter.push({paramKey:'userFromReport',paramValue:param4,text1:param5});
            localStorage.setItem('filterData',JSON.stringify(this.audienceFilter));
            this.router.navigate(['/admin/lead/',this.categoryName]);
        }

        if(param1 == 'byStatus'){
            let removeParam = param7;            
            if(param7.includes('params.') == true){
                removeParam = param7.split('params.')[1];                
            }
            this.audienceFilter.push({paramKey:removeParam,paramValue:'date(' + this.datePipe.transform(param2, "yyyy-MM-dd 00:00:00") + ') and date(' + this.datePipe.transform(param3, "yyyy-MM-dd 23:59:59") +')',text1:param2,text2:param3});
            this.audienceFilter.push({paramKey:'userFromReport',paramValue:param4,text1:param5});
            this.audienceFilter.push({paramKey:'lead_status',paramValue:param6,text1:param6});
            localStorage.setItem('filterData',JSON.stringify(this.audienceFilter));
            this.router.navigate(['/admin/lead/',this.categoryName]);
        }
    }

    goToDetailBySource(param1,param2,param3,param4,param5,param6,param7){
        if(param1 == 'sourceName'){
            let removeParam = param6;            
            if(param6.includes('params.') == true){
                removeParam = param6.split('params.')[1];                
            }
            this.audienceFilter.push({paramKey:removeParam,paramValue:'date(' + this.datePipe.transform(param2, "yyyy-MM-dd 00:00:00") + ') and date(' + this.datePipe.transform(param3, "yyyy-MM-dd 23:59:59") +')',text1:param2,text2:param3});
            this.audienceFilter.push({paramKey:'source',paramValue:param4,text1:JSON.stringify(param5)});
            localStorage.setItem('filterData',JSON.stringify(this.audienceFilter));
            this.router.navigate(['/admin/lead/',this.categoryName]);
        }

        if(param1 == 'leadStatus'){
            let removeParam = param7;            
            if(param7.includes('params.') == true){
                removeParam = param7.split('params.')[1];                
            }
            this.audienceFilter.push({paramKey:removeParam,paramValue:'date(' + this.datePipe.transform(param2, "yyyy-MM-dd 00:00:00") + ') and date(' + this.datePipe.transform(param3, "yyyy-MM-dd 23:59:59") +')',text1:param2,text2:param3});
            this.audienceFilter.push({paramKey:'source',paramValue:param4,text1:JSON.stringify(param5)});
            this.audienceFilter.push({paramKey:'lead_status',paramValue:param6,text1:param6});
            localStorage.setItem('filterData',JSON.stringify(this.audienceFilter));
            this.router.navigate(['/admin/lead/',this.categoryName]);
        }
    }


    getFollowData() {
       
        this.reportService.followUpData(this.followUpfilterDateRange,this.sortByDate, this.categoryName).subscribe(data => {
            if (data.status) {
                this.followUpList = data.data;
                this.usersNameList = []
                this.followUpList.forEach(element => {
                    element.userIdEncode = element.userId;
                    let checkById =  this.allUserFromMaster.find(x=>x.id == element.userIdEncode);
                    if(checkById){
                        this.usersNameList.push(checkById);
                    }
                    
                });
                this.totalToday = this.followUpList.reduce((accumulator, current) => accumulator + current.Today, 0);
                this.totalMissed = this.followUpList.reduce((accumulator, current) => accumulator + current.Missed, 0);
                this.totalCompleted = this.followUpList.reduce((accumulator, current) => accumulator + current.Completed, 0);
                this.totalOverdue = this.followUpList.reduce((accumulator, current) => accumulator + current.UpComming, 0);
                this.totalShared = this.followUpList.reduce((accumulator, current) => accumulator + current.Shared, 0);
            } else{
                this.toastr.error(data.error_msgs);
                this.followUpList = [];
            }
        }, (error) => {
            console.log(error);
        })
    }

    refreshAllReportData(){
        localStorage.setItem('audReportCatName',this.categoryName);
        this.fetchLeadStatus();
        this.updateFollowUpDateRange();
        this.updateLeadReport();
    }

}
