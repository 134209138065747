import { Pipe, PipeTransform } from '@angular/core';
import { ProjectService } from '../../core/services/project.service';

@Pipe({
  name: 'shortName'
})
export class ShortNamePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let output = '';
    try {
      output += value.split(' ')[0][0].toUpperCase();
      output += value.split(' ')[1][0].toUpperCase();
    } catch (e) { }
    return output;
  }
  
}


@Pipe({name: 'telleCallerName'})
export class TelleCallerNamePipe implements PipeTransform {
  constructor(private projectService: ProjectService) {
       
  }
  
  transform(userId: string): string {
    if(this.projectService.userList[userId]!=undefined){
      return this.projectService.userList[userId]["userName"]
    }
    return "";
  }
}