<main>
  <section class="inner-page">
    <div class="inner-page-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <h4><a class="back-link" [routerLink]="['/admin/subscription-invoice']"><img src="assets/images/angle-left.svg"></a> Pricing Plans</h4>
            <p>Keep your plan updated as per requirement, it will stop collecting data once limit of your existing plan
              is over.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="inner-page-body">
      <div class="container-fluid">
        <div class="row">
          <div  class="col-md-4 plan-option  ">
            <form>
              
              
              <div class=""  >
              <div class="custom-control custom-switch swich-button" *ngIf="false">
                <span class="bm" [ngClass]="{pink: !yearly}">Bill Monthly</span>
                <input type="checkbox" name="yrly" [(ngModel)]="yearly" class="custom-control-input" id="customSwitches">
                <label class="custom-control-label" for="customSwitches"></label>
                <span class="by" [ngClass]="{pink: yearly}">Bill Yearly</span>
              </div>
            </div>
            </form>

            <ng-container *ngFor="let item of pricingPlans">
              <div *ngIf="yearly ? item.planType=='YEARLY':item.planType!='YEARLY'" class="box">
                <label>
                <div>
                  <div>
                    <div class="box-inner d-flex justify-content-between">
                      <input name="radio" type="radio" [disabled]="this.currentPlan.planName!='FREE' && this.currentPlan.activeStatus" [checked]="item.id == this.currentPlan.id" value="item.id" (change)="onSelectionChange(item)"> 
                      <span class="checkmark"></span> 
                      <span class="text">{{item.planName}}</span>
                      <div class="right">
                        <!-- <p *ngIf="item.planDesc.startsWith('Starting')">Starting at</p> -->
                        <span  *ngIf="item.planType=='MONTHLY'" ><strong>₹{{item.planCost | number: '1.0-0'}}/</strong>month</span>
                        <span *ngIf="item.planType=='YEARLY'"  ><strong>₹{{item.planCost | number: '1.0-0'}}/</strong>yearly</span>
                      </div>
                    </div>
                  </div>
                </div>
                </label>
              </div>
          </ng-container>
            
          </div>
          <div class="col-md-8 plan-desc">
              <h4>Make Your Plan</h4>
              <div class="row checklist">
                <div class="col-md-6">
                  <h6>Data Integration</h6>
                  <ul *ngFor="let item of activePlugins; let i = index">
                    <li>
                      <label tooltip="{{item.priceDesc}}">{{item.pluginName}}
                        <input type="checkbox" [disabled]="activePlugins[i].activeFlag=='1'" name="item.id" (change)="activePlugins[i].checked = !activePlugins[i].checked" [checked]="activePlugins[i].checked">
                        <span class="checkmark"></span>
                      </label>
                    </li>
                    
                  </ul>
                </div>
                <div class="col-md-6">
                  <h6>Marketing Channels</h6>
                  <ul>
                    <li *ngFor="let item of activeChannels; let i = index">
                      <label tooltip="₹{{item.price}}">{{item.channelName}}
                        <input type="checkbox" [disabled]="activeChannels[i].activeFlag=='1'" name="item.id" (change)="activeChannels[i].checked = !activeChannels[i].checked" [checked]="activeChannels[i].checked">
                        <span class="checkmark"></span>
                      </label>
                    </li>
                 
                   
                  </ul>
                </div>
              </div>
              <div class="row">
                <div class="col-md-11 p-0">
                  <div class="subs-box">
                    <h4 class="mb-3">SUBSCRIBERS</h4>
                    <p  *ngIf="this.currentPlan.planName!='FREE'">Select subscriber limit for your account and see how it affects pricing</p>
                    <div class="custom-slider">
                      <div>
                    <ng5-slider *ngIf="this.currentPlan.planName!='FREE'" (valueChange)="updatePrice()" [(value)]="this.planCreditRangeValue"  [options]="options"></ng5-slider>
                    </div>
                  </div>
                  <div class="subs-count">
                    <strong *ngIf="this.currentPlan.planName!='FREE'" class="red">{{this.planCreditRangeValue}}</strong>
                    <span *ngIf="this.currentPlan.planName=='FREE'" > Free subscriber limit for your account - <strong class="red">250</strong></span>
                  </div>
                    
                  </div>
                  <div class="subs-box d-flex justify-content-between pr-3">
                    <h4 *ngIf="(this.currentPlan.planName=='FREE' && yearly) || (this.currentPlan.planName!='FREE' && this.currentPlan.planType != 'MONTHLY')">Yearly Cost</h4>
                    <h4 *ngIf="(this.currentPlan.planName=='FREE' && !yearly) || (this.currentPlan.planName!='FREE' && this.currentPlan.planType == 'MONTHLY')">Monthly Cost</h4>
                    <h4 *ngIf="this.currentPlan.planName=='FREE'">₹0</h4>
                    <h4 *ngIf="this.currentPlan.planName!='FREE'">₹{{this.finalPrice()}}</h4>
                  </div>
                  <div class="subs-box  pr-3 text-right">
                  
                    <a class="btn btn-default" *ngIf="this.finalPrice()>0" (click)="upgrade(templateUpgrade)">Upgrade</a>
                    <a class="btn btn-default" *ngIf="this.finalPrice()==0" disabled="disabled">Upgrade</a>
                    
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
    
  </section>
</main>

<ng-template #templateUpgrade>
  <form>
    <div class="view">
      <div class="modal-header">
        <h4 class="modal-title">Upgrade Plan</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" *ngIf="this.upgradPlans">
        <div class="field-section row">
          <div class="col-md-12 section-box">
            <div class="col-md-12">
              <div class="field-group">
                <label>Plan Name:</label>
                <div class="field-box red">
                {{this.upgradPlans.planName}}
                </div>
              </div>
            </div>
            <div class="col-md-12" *ngIf="this.upgradPlans.Validity">
              <div class="field-group">
                <label>Valid up to :</label>
                <div class="field-box">
                  {{this.upgradPlans.Validity}}
                </div>
              </div>
            </div>
            
            <div class="col-md-12" *ngIf="this.upgradPlans.marketingChannels">
              <div class="field-group">
                <label>Marketing Channels :</label>
                <div class="field-box">
                  {{this.upgradPlans.marketingChannels}}
                </div>
              </div>
            </div>
            
            <div class="col-md-12" *ngIf="this.upgradPlans.dataIntegration">
              <div class="field-group">
                <label>Data Integration</label>
                <div class="field-box ">
                  {{this.upgradPlans.dataIntegration}}
                </div>
              </div>
            </div>
            
            <div class="col-md-12" *ngIf="this.upgradPlans.subscriberLimit">
              <div class="field-group">
                <label>Subscriber Limit :</label>
                <div class="field-box">
                  {{this.upgradPlans.subscriberLimit}}
                </div>
              </div>
            </div>

            <div class="col-md-12" *ngIf="this.upgradPlans.subscriberAmount">
              <div class="field-group">
                <label>Subscriber Amount :</label>
                <div class="field-box">
                  ₹{{this.upgradPlans.subscriberAmount}}
                </div>
              </div>
            </div>

            
            <div class="col-md-12" *ngIf="this.upgradPlans.pricingPlanCost">
              <div class="field-group">
                <label>Yearly Cost :</label>
                <div class="field-box">
                  ₹{{this.upgradPlans.pricingPlanCost}}
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12 section-box fix-250" *ngIf="(this.upgradPlans.selectedPlugins!=null && this.upgradPlans.selectedPlugins.length>0) || (this.upgradPlans.selectedChannels!=null && this.upgradPlans.selectedChannels.length>0)">
            <div class="col-md-12">
              <h4 class="pt-3 pb-3">Additional Chanels & Integration :</h4>
            </div>
            <ng-container *ngFor="let item of this.upgradPlans.selectedPlugins">
            
            <div class="col-md-12" >
              <div  class="field-group">
                <label>{{item.pluginName}} :</label>
                <div class="field-box">
                  ₹{{item.price}}
                </div>
              </div>
            </div>
          </ng-container>
          
            <ng-container *ngFor="let item of this.upgradPlans.selectedChannels">
            <div class="col-md-12">
              <div class="field-group">
                <label>{{item.channelName}}</label>
                <div class="field-box ">
                  ₹{{item.price}}
                </div>
              </div>
            </div>
          </ng-container>
          </div>

          <div class="col-md-12 section-box pt-3">
            <div class="col-md-12">
              <div class="field-group">
                <label>GST    {{this.upgradPlans.taxRate}}%</label>
                <div class="field-box">
                  ₹{{this.upgradPlans.taxAmount}}
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="field-group">
                <label><strong class="black">Net Amount :</strong></label>
                <div class="field-box">
                  <strong>₹{{this.upgradPlans.netAmount}}</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer subs-box justify-content-between">
        <p><span class="red">*</span>Inclusive of local taxes</p>
        <a class="btn btn-default" (click)="showModal(templatePaymentOptions)">Upgrade</a>
      </div>
    </div>
   
     

  
  </form>

</ng-template>




<ng-template #templatePaymentOptions>

  <form class="payment-mode-model">
    <div class="forgot-password-modal">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Payment Mode</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-6" (click)="insertPaymentBeforeGateway('razorpay')">
            <div class="payment-detail text-center background-grey">
              <a href="javascript:;">
                <span class="icon-credit-card-front"></span>
                <p>Netbanking/Credit/Debit Card</p>
              </a>
            </div>
          </div>
          <div class="col-md-6" (click)="insertPaymentBeforeGateway('paypal')">
            <div class="payment-detail text-center">
              <a href="javascript:;">
                <span class="icon-paypal"></span>
                <p>Paypal</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

</ng-template>

<ng-template #templatePayPalBlock>

  <form>
    <div class="forgot-password-modal">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Paypal Checkout</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row text-center" id="payPalBlock">
        </div>
      </div>
    </div>
  </form>

</ng-template>











