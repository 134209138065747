export const environment = {
  production: true,
  siteUrl: "https://console.cronberry.com/",
  apiUrl: "https://api4.deve.cronberry.com/api/",
  authUrl: "https://api4.deve.cronberry.com/auth/",  
  apiUrlInvoice:"https://invoice4.dev.cronberry.com/api/",
  formUrl: "https://form4.dev.cronberry.com/api/",
  xlsUrl:"https://xl4.dev.cronberry.com/api/excel/",
  fbApiUrl: "https://fb4.dev.cronberry.com/api/facebook/",
  apiUrlCatalogue: "https://catalogue4.dev.cronberry.com/api/",
  apiUrlAnnouncement: "https://announcement4.dev.cronberry.com/api/",
  apiUrlShortner: "https://shortner4.dev.cronberry.com/api/",
  apiCallassist: "https://callassist4.dev.cronberry.com/api/",
  apiUrlIndiaMart: "https://im.dev.cronberry.com/api/",
  apiUrlTradeIndia: "https://td.dev.cronberry.com/api/",
  apiUrlJustDial: "https://justdial.dev.cronberry.com/api/",
  pushServiceUrl:'https://androidpushservice.deve.cronberry.com/api/',
  // iosPushUrl:'https://iospushservice.dev.cronberry.com/api/',
  // webPushUrl:'https://webpushservice.dev.cronberry.com/api/',
  smsPushUrl:'https://smsservice.deve.cronberry.com/api/',
  emailPushUrl:'https://emailservice.deve.cronberry.com/api/',
  whatsappApiUrl:"https://wpbs.dev.cronberry.com/api/",
  webUrl: "https://inapp4.dev.cronberry.com/api/",
  webInapp: "https://inapp4.cronberry.com/",
  staticUrl: "https://static4.dev.cronberry.com/",
  registerUrl:"https://register.deve.cronberry.com/api/",
  captchaKey: "6LeKYAAhAAAAAALAUZXh9utHyi_819FF5fESHGBM",
  landingDomainSuffix: "dravyavati.com",
  landingPageURL: "https://liveqa.cronberry.com/admin411fq5w66",
  popupPageURL: "https://popup.cronberry.com/admin311namakf",
   assetLibarary: "https://asset4.dev.cronberry.com/api/",
   cartUrl:"https://cart4.dev.cronberry.com/api/",
   wabaUrl:"https://wabaservice.dev.cronberry.com/api/waba/",
   tatUrl:"https://tat.dev.cronberry.com/api/tat",
   c2cUrl:"https://c2c.dev5.cronberry.com/api/ivr/",
   dataPushUrl:"https://tat.dev.cronberry.com/cronberry-dataflow-service/api/",
   name:"dev"

};