    <form [formGroup]="form" (ngSubmit)="restorePass()" class="sign-right-form" >
    <div class="marketer">  
        <h4>Setup Password</h4>
        <p>Create new password to access your account. Make it strong with combination of alphanumeric and special characters.</p>
        <div class="sign-input">

          <input trim="blur" class="sign-input-type" type="password" formControlName="newPassword" placeholder="Type Password" maxlength="20">
      
        </div>
        <div class="sign-input">

            <input trim="blur" class="sign-input-type"  type="password"  formControlName="confirmPassword"  placeholder="Re-type Password" maxlength="20">
       
        </div>
        <div class="signin-btn">
          <button type="submit" class="btn btn-dark">Save</button>
        </div>
    </div>
</form>


