import { DatePipe,Location } from "@angular/common";
import { Component, OnInit, TemplateRef, ViewChild} from "@angular/core";
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import { SubUserObj } from "../../core/modal/user";
import { DatePickerObj } from "../../core/modal/datePicker";
import { UserService } from "../../core/services/user.service";
import { Global } from "../../shared/models/global.model";
import { AppConstants } from '../../constants/app.constants';
import { AuthenticationService } from "../../core/services/authentication.service";
import { CallassistService } from "./callassist.service";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { ReportService } from "../../core/services/report.service";
import { ProjectService } from "../../core/services/project.service";


@Component({
    selector: 'call-log-app',
    templateUrl: './callassist.detail.component.html',
    styleUrls: ['./callassist.component.scss']
})

export class CallassistDetailComponent implements OnInit {
      public filterDateRange: DatePickerObj;
    public maxDate: any;
    ranges: any;
    public pageNo: number;
    public dataSize: number;
    public totalDataSize: number;
    public searchText: string = ''
    public searchUser: any ;
    operator:any;
    clubData:any = false;
    callLogs: any[] = [];
    callSummary: any[] = [];
    telecallerList: SubUserObj[] = [];
    roleTypeId: number;
    public searchByState: string = '';
    modalRef: BsModalRef;
    startVal:any='';
    endVal:any='';
    viewAudienceId:any;
    filterData:any={"userId":"","searchString":"","fields":""}
    dataList:any;
    audienceListData:any;
    audienceColumnName:any;
    audienceColumn:any;
    attributesList:any;
    public selectedUserobject:SubUserObj=undefined;
    @ViewChild('filterModalRef') filterModalRef:TemplateRef<any>;
    @ViewChild('audDetailTemplate') audDetailTemplate:TemplateRef<any>;
    @ViewChild('audDataList') audDataList: TemplateRef<any>;
    userId:any;

    constructor(
        private callassistService: CallassistService,
        private toastr: ToastrService,
        private datePipe: DatePipe,
        private global: Global,
        private userService: UserService,
        private authenticationService: AuthenticationService,
        public activatedRoute: ActivatedRoute,
        private _location: Location,
        private router:Router,
        private modalService: BsModalService,
        private reportService:ReportService,
        private projectService:ProjectService
        
    ) {
        
    }

    ngOnInit() {
        this.fetchCategory();
        this.attributeData();
        this.userId = this.authenticationService.getUserId();
        this.operator = 'select';
        this.pageNo = 1;
        this.dataSize = 20;
        this.maxDate = moment();
        this.ranges = this.global.customDateRange();
        this.filterDateRange = {
            startDate: moment(this.callassistService.startDate),
            endDate: moment(this.callassistService.startDate),
        };
        this.roleTypeId = this.authenticationService.getUserRoleType();
        
        //this.getTelecallerList();
    }

    ngOnDestroy(){
        if(this.modalRef){
        this.modalRef.hide();
        }
    }

    goToMissRecording(){
        this.router.navigate(['admin/missed-recordings'])        
    }

    fetchCallAssistDtl()
    {


        if(this.operator == 'select')
        {
            this.toastr.error('Please select operator');
            return;
        }

      
        if(this.endVal == '' && this.operator == 'between' )
        {
            this.toastr.error('Please enter mintues ranges to filter');
            return
        }

        if(this.startVal == '' )
        {
            this.toastr.error('Please enter minutes to filter');
            return;
        }

        if(this.operator == 'between')
        {
            if(this.endVal < this.startVal)
            {
                this.toastr.error('Please enter valid mintues ranges');
                return;
            }    
        }
        
        this.fetchCallLog();
    }

    openFilter()
    {
        this.modalRef = this.modalService.show(this.filterModalRef, {
            backdrop: 'static'
        });
    }

    operator_select()
    {
    }

    fetchCallLogByDate()
    {
        this.fetchCallLog();
    }

    goToDtlbyCat(item){
        this.getAudienceData(item);    
    }

    getAudienceData(item) {
        if (item) {
          this.filterData.userId = this.userId;
          this.filterData.searchString = item.number ;
          this.filterData.fields = "email,mobile,name,category" ;
          this.fetchAudienceData(this.filterData);          
       }
      }

      fetchCategory() {        
        this.dataList = [];
        this.projectService.fetchCategory({}).then(data => {
            if (data) {
                this.dataList = data;
            }
        })
      }

      attributeData(){
        this.projectService.fetchAttributeList().then(data => {
            if(data){
                this.attributesList = this.projectService.filterAttributeList(['all']);
                this.attributesList.sort((a,b)=>{
                  if(a.paramName < b.paramName){
                    return -1;
                  }
                  if(a.paramName > b.paramName){
                    return 1;
                  }
                })
              }});
      }



      fetchAudienceData(filterData) {
        this.reportService.fetchAudienceData(filterData, 'allAudience').subscribe(data => {
          if (data.status) {
            data.data.data.forEach(element => {
              if(element.category){
                let getCatname = this.dataList.find(x=>x.linkRewrite == element.category);
                if(getCatname){
                  element.categoryName = getCatname.name;
                }
              }
            });
            this.audienceListData = data.data.data;

            if(this.audienceListData.length > 1){
            // this.audienceColumnName = JSON.parse(data.data.columnListName);
            this.audienceColumnName = [];
            if(data.data.columnList != null){
              this.audienceColumn  = data.data.columnList.split(',');
              this.audienceColumn.forEach(element => {
                if(element != null)
                {
                  let value = this.attributesList.find(x => x.paramKey === element);
                  if(value != undefined){
                    this.audienceColumnName.push(value.paramName)
                  }
                }
              });
            } else {
              this.audienceColumnName = JSON.parse(data.data.columnListName);
            }
            this.modalRef = this.modalService.show(this.audDataList, { class: "select_customer_call_assist", backdrop: 'static',keyboard:false });
        } else{
            this.router.navigate(['/admin/lead/'+this.audienceListData[0].category+'/search/'+this.audienceListData[0]._id]);
        }
          } else {
            this.toastr.error(data.error_msgs);
          }
        });
      }


      goToCat(list){
        this.router.navigate(['/admin/lead/'+list.category+'/search/'+list._id]);
      }


    goToAudDtl(audID,item){
        this.viewAudienceId = audID;
        this.modalRef = this.modalService.show(this.audDetailTemplate,{class:'modal-lg detail_audience_popup',backdrop:false});
    }

    downloadXl()
    {
        let fromDate = this.datePipe.transform(this.filterDateRange.startDate,'yyyy-MM-dd 00:00:00');
        let toDate = this.datePipe.transform(this.filterDateRange.endDate,'yyyy-MM-dd 23:59:59');
        let userObject = this.callassistService.getUserObject();
        let telId = userObject.id.includes('&') == true ? userObject.id.replace('&','%26') : userObject.id;
        let downloadParams = 'fromDate='+fromDate+'&toDate='+toDate+'&telecallerId='+telId;
        this.callassistService.downloadAttendanceReport(downloadParams).subscribe(data=>{
            var contentDisposition = data.headers.get('content-disposition');

            const href = URL.createObjectURL(data);
            window.open(href, "_top");

        })
    }

    fetchCallLogClubData(event)
    {
        this.pageNo = 1;
        this.fetchCallLog(this.searchByState);        
    }
    
    resetFilter()
    {
        this.operator = 'select';
        this.startVal = '';
        this.endVal = '';
        this.modalRef.hide();
        this.fetchCallLog(this.searchByState);
    }

    filterByState(state:string){
        this.searchByState = state;
        this.fetchCallLog();
     }

     dataSizeChange()
     {
         this.pageNo = 1;
         this.fetchCallLog();
     }


    fetchCallLog(state?:string,pageNo?:any,dataSizeNum?){   
        
        let userObject = this.callassistService.getUserObject();
        if(userObject!=undefined){
            this.selectedUserobject = userObject;
            var fromDate = this.datePipe.transform(this.filterDateRange.startDate, "yyyy-MM-dd 00:00:00");
            var toDate = this.datePipe.transform(this.filterDateRange.endDate, "yyyy-MM-dd 23:59:59");
            this.callLogs = [];
            let startVal  = this.startVal ? this.startVal * 60 : '';
            let endVal   = this.endVal ? this.endVal * 60 :'';
            let operatorVal = this.operator == 'between' ? startVal + ' and ' + endVal : startVal;
            let param = {fromDate:fromDate,toDate:toDate,search:this.searchText.trim(),telecallerId:userObject.id,clubData:this.clubData,durationFilter:{}};

            if(this.operator != 'select')
            {
                param.durationFilter = {paramOprator:this.operator,paramValue:operatorVal}
            }
            if(this.operator == 'select')
            {
                delete param.durationFilter;
            }
            let callType={};
            if( this.searchByState != ""){
                callType={callType:this.searchByState};
            }
            else
            {
                callType={};
            }
            var params = {...param, ...callType};                        
            this.callassistService.fetchCallLogs(params,this.dataSize, this.pageNo).subscribe( data => {                               
                if(data.status){                    
                    this.totalDataSize = data.data.size 
                    this.callSummary = data.data.callLogSummary;  
                    // if(this.callSummary.length == 0)
                    // {
                    // this.callSummary = data.data.callLogSummary;  
                    // }                  
                    this.callLogs = data.data.callLogHistory;
                    this.callLogs.forEach(element => {
                        element.durationMin = this.getTimeInMin(element.duration);
                        element.callDate = element.callDate.replace(' ', 'T');
                    });
                    this.callSummary.forEach(element => {
                        element.durationMin = this.getTimeInMin(element.totalTime);
                    });
                    if(this.modalRef)
                    {
                    this.modalRef.hide();
                    }

                } else {
                    this.toastr.error(data.error_msgs, 'Error');
                }
            })
        } else {
            this._location.back();
        }
    }


    getTimeInMin(seconds: number): string {
        const minutes: number = Math.floor(seconds / 60);
        if(minutes < 60 ){
            return minutes + '.' + (seconds - minutes * 60) + ' Min';
        } else {
            const hours: number = Math.floor(minutes / 60);
            return hours + '.' + (minutes - hours * 60) + ' Hours';
        }
    }

    public dataForPaging(event) {        
        this.pageNo = event;
        this.fetchCallLog('',event);
    }

    getTelecallerList(){
        this.userService.getUsersData().then( data => {
            if(data!=undefined){
                this.telecallerList = data.filter(x => x.roleTypeId == 2);
                if(this.roleTypeId == 1)
                this.telecallerList.push({id:this.authenticationService.getUserData().userId, userName:this.authenticationService.getUserData().userName})
            }
        });
    }

}
