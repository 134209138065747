<main>
    <form onkeydown="return event.key != 'Enter';">

        <section class="inner-page">
            <div class="inner-page-header">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12">
                            <h4>
                                <a class="back-link" [routerLink]="['/admin/segments']"><img src="assets/images/angle-left.svg"></a> Segments</h4>
                        </div>
                    </div>
                </div>
            </div>

            <div class="inner-page-body configuration">
                <div class="container-fluid">
                    <div class="card">
                        <div class="card-header">
                            <h4>Step 1</h4>
                        </div>
                        <div class="card-body">
                            <div class="form-group row">
                                <div class="col-md-6">
                                    <div class="row">
                                    <label class="col-md-2 control-label card-header-top-heading" for="inputDefault">Name</label>
                                    <div class="col-md-10 form-group">
                                    <input trim="blur" [ngClass]="{'invalid-control' : !this.validFilters && currentSegments.segmentName == ''}" [(ngModel)]="currentSegments.segmentName" [pattern]="this.global.RequiredRegx" type="text" class="form-control" id="inputDefault" [ngModelOptions]="{standalone: true}" maxlength="100">
                                    </div>
                                    </div>
                                </div>
                                
                                <div class="col-md-6">
                                    <div class="row">
                                    <label class="col-md-3 control-label card-header-top-heading desktop-text-right" for="inputDefault">Category</label>
                                    <div class="col-md-9 form-group">
                                    <select class="form-control" [(ngModel)]="currentSegments.category" [ngModelOptions]="{standalone: true}" (change)="categoryUpdate($event)" [ngClass]="{'invalid-control' : !this.validFilters && currentSegments.category == '0'}">
                                        <option value="0">Select Category</option>
                                        <option *ngFor="let category of dataList" [value]="category.linkRewrite">{{category.name}}</option>
                                    </select>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </section>

        <!-- <section *ngIf="currentSegments!=undefined &&  currentSegments.jsonData!=undefined && currentSegments.jsonData.paramList.length > 0" class="inner-page"> -->
            <section *ngIf="currentSegments!=undefined &&  currentSegments.jsonData!=undefined" class="inner-page">
            <div class="inner-page-body configuration">
                <div class="container-fluid">
                    <div class="card">

                        <div class="card-header">
                            <h4>Step 2</h4>
                        </div>

                        <div class="card-body">
                            <div class="card-body-heading">
                                <p>A segment group contains filters that you apply to base segments. If you choose an imported segment as a base segment you can’t use other imported segments as base segments nor add an additional segment group.</p>
                            </div>

                            <div class="sms-servic-2">

                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="sms-second-tab">

                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="form-group row">
                                                                <label class="col-md-2 control-label">Source</label>
                                                                <div class="col-md-4">
                                                                    <ng-select  [(ngModel)]="currentSegments.jsonData.source" class="form-control" (change)="updateSource($event)" [ngModelOptions]="{standalone: true}" style="height:auto;" [multiple]="true" >
                                                                        <ng-template ng-header-tmp>
                                                                            <div>
                                                                              <button class="btn btn-link"
                                                                                      (click)="onSelectAllSource()">Select All</button>
                                                                              <button class="btn btn-link"
                                                                                      (click)="onClearAllSource()">Clear All</button>
                                                                            </div>
                                                                         </ng-template>
                                                                        <ng-option *ngFor="let item of fetchUser" [value]="item">
                                                                            {{item.pluginName}}
                                                                        </ng-option>
                                                                    </ng-select>
                                                                </div>
                                                                <label class="col-md-2 control-label">Plugin Form(s)</label>
                                                                <div class="col-md-4">
                                                                    <ng-select name='plugin_forms' [items]="pluginFormsList" bindLabel="formName" [multiple]="true" [(ngModel)]="pluginForm" class="segment-select"  [ngModelOptions]="{standalone: true}">
                                                                        <ng-template ng-header-tmp>
                                                                            <div>
                                                                              <a href="javascript:void(0)" class="btn btn-link"
                                                                                      (click)="onSelectAllPlugin()">Select All</a>
                                                                              <a href="javascript:void(0)" class="btn btn-link"
                                                                                      (click)="onClearAllPlugin()">Clear All</a>
                                                                            </div>
                                                                         </ng-template> 
                                                                    </ng-select>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="form-group row">
                                                                <label class="col-md-2 control-label pr-0 after-border">
                                                                 <span></span> Add Event</label>
                                                            <div class="col-md-4">
                                                                <ng-select [(ngModel)]="events" class="form-control" [ngModelOptions]="{standalone: true}" style="height:auto;" [multiple]="true">
                                                                    <ng-container *ngFor="let c of filters">
                                                                        <ng-option *ngIf="c.paramCategory=='0'" [value]="c">
                                                                            {{c.paramName}}
                                                                        </ng-option>
                                                                    </ng-container>
                                                                </ng-select>
                                                                
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div class="add-filters">
                                                        <div>
                                                            <div class="row">
                                                                <div class="col-md-12">
                                                                    <ng-container *ngFor="let item of currentSegments.jsonData.paramList; let i=index">
                                                                        
                                                                        <div *ngIf="i>0">
                                                                            <div class="col-md-12 border-grey-and andbtn-cr-seg">
                                                                                <button class="btn btmadd">AND</button>
                                                                            </div>
                                                                        </div>

                                                                        <div class="form-group row">
                                                                            <label class="col-md-2 control-label pr-0">
                                                                            <span></span> Add Filter</label>
                                                                            <div class="col-md-2">
                                                                                <select class="form-control" (change)="setFilterValues($event.target.value, item, i,'addfilter')" name='{{"projectInputParamId"+ i}}' [(ngModel)]="item.projectInputParamId" [ngClass]="{'invalid-control' : !item.projectInputParamId && invalidFilterDropdown}" [ngModelOptions]="{standalone: true}">
                                                                                <option value="0">Select Filter</option>
                                                                                <ng-container *ngFor="let c of this.filters">
                                                                                    <option *ngIf="c.paramCategory=='1'" [value]="c.id" [attr.disabled]="isDisable(currentSegments.jsonData.paramList, c.id) ? 'disabled' : null">{{c.paramName}}<p class="d-inline" *ngIf="(c.paramKey=='city' || c.paramKey=='state' || 
                                                                                       c.paramKey=='country' || c.paramKey == 'source' || c.paramKey == 'product_name' || c.paramKey == 'telecaller_name' || c.paramKey == 'lead_status') "> (default)</p></option>
                                                                                </ng-container>
                                                                                </select>
                                                                            </div>

                                                                            <div class="col-md-7 row" *ngIf="item.projectInputParamType=='String' || item.projectInputParamType=='string' || item.projectInputParamType=='Mobile' || item.projectInputParamType=='Email'">
                                                                                <div class="col-md-3 col-12 col-sm-12">
                                                                                    <select class="form-control" (change)="removeAudience(item)" [(ngModel)]="item.selected.value" [ngModelOptions]="{standalone: true}">
                                                                                    <option value="contain">Contains</option>
                                                                                    <option value="in">IN</option>
                                                                                    <option value="not_in">Not IN</option>
                                                                                    </select>
                                                                                </div>
                                                                                <div class="col-md-8 col-12 col-sm-12" *ngIf="item.selected.value == 'contain'">
                                                                                    <!-- <input trim="blur" [ngClass]="{'invalid-control' : item.selected.text1 == undefined || item.selected.text1 == ''}" type="text" (change)="removeAudience()" [(ngModel)]="item.selected.text1" name="item.selected.text1"
                                                                                    class="form-control"> -->
                                                                                    <ng-autocomplete class="form-control" [ngClass]="{'invalid-control' : item.selected.text1 == ''}" [data]="item.values" [(ngModel)]="item.selected.text1" [searchKeyword]="'paramValue'" [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate" [ngModelOptions]="{standalone: true}" #containVal (keyup.enter)="commaSeperate(containVal);containVal.query=containValue">
                                                                                    </ng-autocomplete>
                                                                                    <div class="containsError" *ngIf="item.conainsError">Contains value should be minimum 3 character</div>
                                                                                    <!-- <ng-template #itemTemplate let-item>
                                                                                        <a [innerHTML]="item.paramValue"></a>
                                                                                    </ng-template> -->
                                                                                    <!-- <ng-template #notFoundTemplate let-notFound>
                                                                                        <div [innerHTML]="notFound"></div>
                                                                                    </ng-template> -->
                                                                                </div>
                                                                                <div class="col-md-8 col-12 col-sm-12" *ngIf="item.selected.value == 'in'">
                                                                                    <ng-select [ngClass]="{'invalid-control' : !this.validFilters && (item.selected == undefined || item.selected.length == undefined || item.selected.length == 0)}" name='filterValue1{{item.id}}' [items]="item.values" bindLabel="paramValue" [multiple]="true" (input)="fetchFilterDetails(item,$event)"
                                                                                        [(ngModel)]="item.selected" class="segment-select" (change)="selectedInFilter(i,'in'); removeAudience()" [ngModelOptions]="{standalone: true}" [isOpen]="!item.values.length ? false : null" [placeholder]="item.placeholder ? '' : 'Type to search'" (remove)="removeParamVal(item)" (clear)="item.placeholder = false">
                                                                                    </ng-select>
                                                                                </div>
                                                                                <!-- <div class="col-md-8 col-12 col-sm-12" *ngIf="item.values.length>=100 && item.selected.value == 'in'">
                                                                                    <ng-select [ngClass]="{'invalid-control' : !this.validFilters && (item.selected == undefined || item.selected.length == undefined || item.selected.length == 0)}" [items]="item.values$ | async" bindLabel="paramValue" [multiple]="true" [hideSelected]="true"
                                                                                        class="segment-select" [loading]="item.loading" [typeahead]="item.valueInput$" [(ngModel)]="item.selected" (change)="selectedInFilter(i,'in'); removeAudience()" [ngModelOptions]="{standalone: true}">
                                                                                    </ng-select>
                                                                                </div> -->
                                                                                <div class="col-md-8 col-12 col-sm-12" *ngIf="item.selected.value == 'not_in'">
                                                                                    <ng-select [ngClass]="{'invalid-control' : !this.validFilters && (item.selected == undefined || item.selected.length == undefined || item.selected.length == 0)}" [items]="item.values" bindLabel="paramValue" [multiple]="true" [(ngModel)]="item.selected" (input)="fetchFilterDetails(item,$event)"
                                                                                        [ngModelOptions]="{standalone: true}" class="segment-select" (change)="selectedInFilter(i,'not_in'); removeAudience()" [isOpen]="!item.values.length ? false : null" [placeholder]="item.placeholder ? '' : 'Type to search'" (remove)="removeParamVal(item)" (clear)="item.placeholder = false">
                                                                                    </ng-select>
                                                                                </div>
                                                                                <!-- <div class="col-md-8 col-12 col-sm-12" *ngIf="item.values.length>=100 && item.selected.value == 'not_in'">
                                                                                    <ng-select [ngClass]="{'invalid-control' : !this.validFilters && (item.selected == undefined || item.selected.length == undefined || item.selected.length == 0)}" [items]="item.values$ | async" bindLabel="paramValue" [multiple]="true" [hideSelected]="true"
                                                                                        class="segment-select" [loading]="item.loading" [typeahead]="item.valueInput$" [ngModelOptions]="{standalone: true}" [(ngModel)]="item.selected" (change)="selectedInFilter(i,'not_in'); removeAudience()">
                                                                                    </ng-select>
                                                                                </div> -->
                                                                            </div>

                                                                            <div class="col-md-7 row" *ngIf="item.projectInputParamType=='Numeric'">
                                                                                <div class="col-md-3 col-12 col-sm-12">
                                                                                    <select [ngClass]="{'invalid-control' : !this.validFilters && (item.selected.value == undefined)}" class="form-control" (change)="removeAudience()" [(ngModel)]="item.selected.value" [ngModelOptions]="{standalone: true}">
                                                                                        <option value=">=">≥ Greater Than Equal</option>
                                                                                        <option value="<=">≤ Less Than Equal</option>
                                                                                        <option value="=">= Equal</option>
                                                                                        <option value="!=">!= Not Equal</option>
                                                                                        <option value=">">&gt; Greater Than</option>
                                                                                        <option value="<"> &lt; Less Than</option><option value="between">Between </option>
                                                                                    </select>
                                                                                </div>
                                                                                <div class="col-md-4 col-12 col-sm-12">
                                                                                    <input trim="blur" [ngClass]="{'invalid-control' : item.selected.text1 == undefined || item.selected.text1 == ''}" type="number" (change)="removeAudience()" [(ngModel)]="item.selected.text1" class="form-control" [ngModelOptions]="{standalone: true}">
                                                                                </div>
                                                                                <div class="col-md-4 col-12 col-sm-12" *ngIf="item.selected.value=='between'">
                                                                                    <input trim="blur" [ngClass]="{'invalid-control' : !this.validFilters && (item.selected.text2 == undefined || item.selected.text2 == '')}" type="number" (change)="removeAudience()" [(ngModel)]="item.selected.text2" class="form-control" [ngModelOptions]="{standalone: true}">
                                                                                </div>
                                                                            </div>

                                                                            <div class="col-md-7 row" *ngIf="item.projectInputParamType=='Date'">
                                                                                <div class="col-md-3 col-12 col-sm-12">
                                                                                    <select [ngClass]="{'invalid-control' : !this.validFilters && (item.selected.value == undefined)}" class="form-control" (change)="con.log(item.selected.value)" [(ngModel)]="item.selected.value" [ngModelOptions]="{standalone: true}">
                                                                                        <option value="between">Range</option>
                                                                                        <!-- <option value="day">Day</option>
                                                                                        <option value="week">Week</option>
                                                                                        <option value="month">Month</option>
                                                                                        <option value="daymonth">Day/Month</option> -->
                                                                                    </select>
                                                                                </div>
                                                                                <div class="col-md-4 col-12 col-sm-12" *ngIf="item.selected.value!='between'">
                                                                                    <input [ngClass]="{'invalid-control' : !this.validFilters && (item.selected.text1 == undefined || item.selected.text1 == '')}" type="text" placeholder="From Date" placement="top left" (change)="removeAudience()"
                                                                                           [(ngModel)]="item.selected.text1" class="date-icon-end form-control date-icon modal-space" bsDatepicker autocomplete="off" [bsConfig]="global.datePickerConfig" [ngModelOptions]="{standalone: true}">
   
                                                                                   </div>
                                                                                <div class="col-md-4 col-12 col-sm-12">
                                                                                 <input [ngClass]="{'invalid-control' : !this.validFilters && (item.selected.text1 == undefined || item.selected.text1 == '')}" type="text" *ngIf="item.selected.value=='between'" placeholder="From Date" placement="top left" (change)="removeAudience()"
                                                                                        [(ngModel)]="item.selected.text1" class="date-icon-end form-control date-icon modal-space" bsDatepicker autocomplete="off" [bsConfig]="global.datePickerConfig" [ngModelOptions]="{standalone: true}">

                                                                                </div>
                                                                                <div class="col-md-4 col-12 col-sm-12" *ngIf="item.selected.value=='between'">
                                                                                    <input [ngClass]="{'invalid-control' : !this.validFilters && (item.selected.text2 == undefined || item.selected.text2 == '' || item.selected.text1 > item.selected.text2)}" type="text" placeholder="To Date" placement="top left" (change)="removeAudience()"
                                                                                        [(ngModel)]="item.selected.text2" class="date-icon-end form-control date-icon modal-space" bsDatepicker autocomplete="off" [bsConfig]="global.datePickerConfig" [ngModelOptions]="{standalone: true}">

                                                                                </div>
                                                                            </div>

                                                                            <button style="height:35px" (click)="removeItem(currentSegments.jsonData.paramList,i)" *ngIf="i>0" class="btn btn-campaign" type="button">
                                                                                <img src="assets/images/times.svg">
                                                                            </button>

                                                                        </div>
                                                                    </ng-container>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                    <!-- <div *ngIf="currentSegments.jsonData.paramList.length < totalFiltersCount()" class="row"> -->
                                                        <div class="col-md-12">
                                                            <button (click)="invalidFilterDropdown=false;addItem(currentSegments.jsonData.paramList)" class="Addfil">
                                                            <span class="icon-plus"></span> Add Filter</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>


                            </div>

                        </div>

                    </div>


                </div>
            </div>


        </section>


    </form>
    <div class="container-fluid">
        <div class="mt-2 pl-3 pr-3 campain-bottom-detail">
            <!-- <button *ngIf="currentSegments!=undefined &&  currentSegments.segmentGroupDetail!=undefined && currentSegments.segmentGroupDetail.length==1" class="btn btn-border on-hover" (click)="addgroup()">
        <span class="icon-plus"></span> Add Group</button> -->
            <div>
                <!-- <button *ngIf="currentSegments.id > 0" class="btn btn-border"(click)="calculateAudience()">Calculate Audience</button> &nbsp;  -->
                <button type="button" (click)="submit()" class="btn btn-dark">
          {{this.currentSegments.id > 0 ? "Update" : "Create" }} Segment</button>
            </div>
        </div>
    </div>


    <section class="inner-page" *ngIf="currentSegments.id !=0">
        <div class="inner-page-body configuration fb_col_btm">
            <div class="container-fluid">
                <div class="card">
                    <div class="card-header">
                        <h4>Step 3 (Optional)</h4>
                        <!-- <h4>Sync Segment Data to Facebook Custom Audience(Remarketing)</h4> -->
                    </div>
                  
                    <div class="card-body">
                            <div class="card-body-heading">
                                <h4>Send this data to facebook custom audience</h4>
                                <p class="m_b_5">This would allow you to push audience data to   'Facebook Custom audience' directly from cronberry.</p>
                                <br/>
                                <p *ngIf="fBAudienceSegmentMapping != undefined"><b>{{fBAudienceSegmentMapping.audienceName}}</b> - {{fBAudienceSegmentMapping.segmentAudienceId}}</p>
                            </div>
                            <div class="form-group row" *ngIf="fBAudienceSegmentMapping != undefined">
                                <div class="col-md-12 text-right">
                                    <button (click)="removeFBAudienceSegmentMapping()"  class="btn btn-dark fbcreateBtn">Remove Facebook Audience Mapping</button>
                                </div>
                            </div>
                    </div>
                    <div *ngIf="fBAudienceSegmentMapping == undefined">
                    <div *ngIf="accountList.length>0">
                    <div class="card-body" >
                        <div class="form-group row">
                            <label class="col-md-2 control-label card-header-top-heading" for="inputDefault">Name
            </label>
                            <div class="col-md-6">
                                <select [(ngModel)]="selectedAccountId"  (change)="fetchCustomAudiences()"  class="form-control"  [ngModelOptions]="{standalone: true}">
                                    <option value="Select Ad Account" selected disabled>Select Ad Account</option>
                                    <option *ngFor="let item of accountList" [value]="item.id" >{{item.id}}</option>
                                  </select>
                        
                            </div>
                        </div>
                    </div>
                    <div class="card-body" *ngIf="selectedAccountId!='Select Ad Account'">
                        <div class="form-group row">
                            <label class="col-md-2 control-label card-header-top-heading" for="inputDefault">List of custom Audience
            </label>
                            <div class="col-md-6">
                                <select [(ngModel)]="selectedCustomAudience"   (change)="createNewFacebookCustomAudience($event)"   class="form-control"  [ngModelOptions]="{standalone: true}">
                                    <option value="Select Custom Audience" selected disabled>Select Custom Audience</option>
                                    <option *ngFor="let audience of customAudienceList" [value]="audience.id">{{audience.name}}</option>
                                    <option value="new"  >Create New Facebook Custom Audience </option>
                                  </select>
                        
                            </div>
                        </div>
                    </div>

                    <div class="form-group row" *ngIf="selectedAccountId!='Select Ad Account' && selectedCustomAudience!='Select Custom Audience' && selectedCustomAudience !='new'">
                        <div class="col-md-12 text-right">
                            <button (click)="saveSegmentFBAudienceMapping()"  class="btn btn-dark fbcreateBtn">Send Segment Data to Facebook Audience</button>
                        </div>
                    </div>
                </div>
                <div *ngIf="accountList.length == 0" class="loginArea" >
                    <div class="modal-body modal-user-management text-center p_t_0">
                        
                        <div class="facebook-button">
                            <!-- <button class="btn btn-primary" (click)="facebookLogin();"> <img src="assets/images/fb.png" alt="Facebook" (click)="facebookLogin();"> Connect with Facebook</button> -->
                            <img src="assets/images/fb.png" alt="Facebook" (click)="facebookLogin();">
                        </div>
                        <p class="text-muted">Click on above <strong>Facebook</strong> icon to allow Cronberry to access your Facebook ad accounts.</p>
                    </div>
                </div>
                </div>
                </div>
            </div>
        </div>
    </section>

    


<ng-template #newFBCustomeAudience>
    <div class="fbCreateModal">
        <div class="modal-header">
            <h4 class="modal-title pull-left">Create Facebook Custom Audience</h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()"><span aria-hidden="true">&times;</span></button>
        </div>
        <div class="modal-body">
            <form>
            <div class="form-group row">
                <label class="col-md-3 control-label card-header-top-heading" for="inputDefault">Name </label>
                <div class="col-md-9">
                    <input [(ngModel)]="customAudienceName"  [ngModelOptions]="{standalone: true}" class="form-control ng-untouched ng-pristine ng-valid" type="text">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-md-3 control-label card-header-top-heading" for="inputDefault">Description </label>
                <div class="col-md-9">
                    <input  [(ngModel)]="customAudienceDesc"  [ngModelOptions]="{standalone: true}" class="form-control ng-untouched ng-pristine ng-valid" type="text">
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-12 text-right">
                    <button (click)="createCustomAudience()"  class="btn btn-dark fbcreateBtn">Create Facebook Custom Audience</button>
                </div>
            </div>
        </form>
        </div>
    </div>
</ng-template>


</main>