import { Injectable } from "@angular/core";
import { ApiConstants } from "../../../app/constants/api.constants";
import { AppConstants } from "../../constants/app.constants";
import { BaseService } from "../../core/services/base.service";
import { delay } from "rxjs/operators";
import { SubUserObj, telleCallerAttendance } from "../../core/modal/user";
import { AuthenticationService } from "../../core/services/authentication.service";
import { DatePipe } from "@angular/common";


@Injectable({
    providedIn: 'root'
})

export class CallassistService {
   
    public userObject:SubUserObj; 
    public telleCallerAttendance: telleCallerAttendance[];
    public startDate: Date;
    roleTypeId: number;
    constructor(
        private baseService: BaseService,
        private authenticationService: AuthenticationService,
        private datePipe: DatePipe,
    ){
        this.roleTypeId = this.authenticationService.getUserRoleType();
        this.startDate = new Date();
    }

    setUserObject(data:SubUserObj){
        this.userObject=data;
    }

    public getUserObject(){
       return this.userObject;
    }

    fetchCallLogs(body:any, size, page) {
        return this.baseService.postRequest(ApiConstants.FETCH_CALL_LOGS.replace(AppConstants.REPLACE_SIZE, size).replace(AppConstants.REPLACE_PAGE, page), body);
    }

    callAssistLocation(body:any,size, page) {
        return this.baseService.postRequest(ApiConstants.FETCH_CALL_ASSIST_LOCATION.replace(AppConstants.REPLACE_SIZE, size).replace(AppConstants.REPLACE_PAGE, page), body);
    }

    missingRecording(body:any,size, page) {
        return this.baseService.postRequest(ApiConstants.FETCH_MISSING_RECORDING.replace(AppConstants.REPLACE_SIZE, size).replace(AppConstants.REPLACE_PAGE, page), body);
    }

    downloadRprtRqst(body:any) {
        return this.baseService.postRequest(ApiConstants.DOWNLOAD_REPORT_REQUEST, body);
    }

    fetchAttendanceLogs(body:any) {
        return this.baseService.postRequest(ApiConstants.FETCH_ATTENDANCE_LOGS, body);
    }

    createWorkingDay(body:any) {
        return this.baseService.postRequest(ApiConstants.CREATE_WORKING_DAY, body);
    }

    viewWorkingDay(body:any) {
        return this.baseService.postRequest(ApiConstants.VIEW_WORKING_DAY, body, false);
    }

    downloadAttendanceReport(body:any) {        
        return this.baseService.getRequestDownloadRpot(ApiConstants.DOWNLOAD_ATTENDANCE_REPORT,body);
    }

    async getTelleCallerAttendanceList(reset:boolean){
        if(this.telleCallerAttendance == undefined || reset){
            var requestData = await this.fetchAttendanceLogs({fromDate:this.datePipe.transform(this.startDate, "yyyy-MM-dd 00:00:00"),toDate:this.datePipe.transform(this.startDate, "yyyy-MM-dd 23:59:59"),userRoleId:this.roleTypeId}).pipe(delay(200)).toPromise();
           // var requestData = await this.fetchAttendanceLogs({fromDate:this.datePipe.transform(this.startDate, "yyyy-MM-dd"),userRoleId:this.roleTypeId}).pipe(delay(200)).toPromise();
            if(requestData.status){
                this.telleCallerAttendance = requestData.data;
            } 
        };
        return this.telleCallerAttendance;
    }   

    
    
}