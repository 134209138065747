import { DatePipe } from '@angular/common';
import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { FormGroup, Validators, FormBuilder, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService, TabsetComponent } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { ProjectService } from '../../../../core/services/project.service';
import { AnnouncementObj} from '../../../../core/modal/announcement';
import { AnnouncementService } from '../../../../core/services/announcement.service';
import { Global } from '../../../../shared/models/global.model';
import { noWhitespaceValidator } from '../../../../shared/models/no-whitespace-validator';
import { GalleryPopupComponent } from '../campaign-gallery/gallery-popup.component';
declare const CKEDITOR;

@Component({
	selector: 'bar-component',
	templateUrl: './bar.component.html',
	styleUrls: ['./announcement.component.scss']
})

export class announcementBarComponent implements OnInit, OnDestroy {
	siteForm: FormGroup;
	config: any;
	CurrentPlugin: any;
	modalRefGallery: BsModalRef;
	modalRefConfirm: BsModalRef;
	isPreview: boolean = false;  
	saveMsg: string;
	@ViewChild("confirm") templateConfirm: TemplateRef<any>;
	previewURL: string;
	closeBtnColorValue: any = '#000';
	bgColorValue: any = '#FFF';
	minDate: any = new Date();
	ckeditorData:Subject<string> = new Subject();
	isUpdate: boolean = false;
	id: number;
	pageTitle: string = 'Create Bar Notification';
	isSubscribe:boolean = true;
	@ViewChild('pageTabs') pageTabs : TabsetComponent;
	type:number = 0;
	constructor(
		private formBuilder: FormBuilder,
		public global: Global,
		private modalService: BsModalService,
		private toastr: ToastrService,
		private announcementService: AnnouncementService,
		private datePipe: DatePipe,
		private route: ActivatedRoute,
		private router: Router,
		private projectService:ProjectService
	) {
		this.siteForm = this.formBuilder.group({
			name: [ "", Validators.compose([ Validators.required, noWhitespaceValidator, Validators.maxLength(100), Validators.minLength(0),Validators.pattern(this.global.AlphaNumPatternWithChar) ]) ],
			startTime: [ "0", Validators.compose([ Validators.required, noWhitespaceValidator, Validators.max(100), Validators.min(0) ]) ],
			duration: [ "5", Validators.compose([ Validators.required, noWhitespaceValidator, Validators.max(100), Validators.min(0) ]) ],
			height: [ "50", Validators.compose([ Validators.required, noWhitespaceValidator, Validators.max(100), Validators.min(30) ]) ],
			bodyContent: ["", Validators.compose([ Validators.required, noWhitespaceValidator]) ],
			durationType: ["always"],
			clickable: [false],
			closable: [true],
			target: [false],
			link: [""],
			allowPages: [""],
			barPosition: ["top"],
			deleteStatus: [true],
			bgColor: ['#FFFFFF', Validators.compose([ Validators.required, noWhitespaceValidator])],
			closeBtnColor: ['#000000', Validators.compose([ Validators.required, noWhitespaceValidator])],
			fromDate: [new Date(), Validators.required],
			toDate: [new Date(), Validators.required],
			openbyClick: [""],
			repeatDays: [ "0", Validators.compose([ noWhitespaceValidator, Validators.max(30), Validators.min(0) ]) ]
		});
	}

	ngOnInit() {

		this.config =  this.global.CKEditorConfig(150,'full');
		this.route.params.subscribe( params =>{
            if(params.id){
                this.id = params.id;
                this.isUpdate = true;
				this.pageTitle = 'Update Bar Notification';
                this.getData();
            }
        });
	}

	ngOnDestroy(){
	
	}

	checkLinkClickable(value){
		if(value.currentTarget.checked){
			this.siteForm.get('link').setValidators([Validators.required]);
            this.siteForm.get('link').updateValueAndValidity();
		} else {
			this.siteForm.get('link').setValidators([]);
			this.siteForm.get('link').updateValueAndValidity();
		};
	}

	submitForm(type){
		if(this.siteForm.controls.height.invalid)
		{
			this.toastr.error('Please enter valid height');
			return;
		}
		
		Object.keys(this.siteForm.controls).forEach(key => {
			const controlErrors: ValidationErrors = this.siteForm.get(key).errors;
			if (controlErrors != null) {
				if(key == 'toDate' || key == 'fromDate' || key == 'startTime')
				this.pageTabs.tabs[1].active = true;
				else if(key == 'name' || key == 'bodyContent' || key == 'height')
				this.pageTabs.tabs[0].active = true;
				else if(key == 'repeatDays')
				this.pageTabs.tabs[2].active = true;
			}
		});
		
		if (this.siteForm.invalid) {
            this.toastr.error(this.global.MessageRequired);
            this.global.validateAllFormFields(this.siteForm);
            return;
		}
		let barHideTime = this.siteForm.value.durationType != 'always' ? this.siteForm.value.duration : '-1';
		let options = `{
			position:'`+this.siteForm.value.barPosition+`',
			height:`+this.siteForm.value.height+`,
			barHideTime:`+barHideTime+`,
			barStartTime:`+this.siteForm.value.startTime+`,
			openbyClick:'`+this.siteForm.value.openbyClick+`'
		}`
		let closeBtnHtml = '';
		if(this.siteForm.value.closable){
			closeBtnHtml = `<a href="javascript:void(0)" class="cb-close">
				<svg fill="`+this.siteForm.value.closeBtnColor+`" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"/></svg>
			</a>`
		};
		let onClick = ""
		if(this.siteForm.value.clickable){
			let target = '_self';
			if(this.siteForm.value.target) target = '_blank';
			onClick = `onclick="window.open('`+this.siteForm.value.link+`', '`+target+`');"`
        };
		var id = this.id != undefined ? this.id : 0;
		// var openbyClick = this.siteForm.value.openbyClick != '' ?  ','+this.siteForm.value.openbyClick : '';
		let html = `<div id="bar-`+id+`" data-rdays="`+this.siteForm.value.repeatDays+`" class="cb-topbar cb-show" style="background-color: `+this.siteForm.value.bgColor+`; height:`+this.siteForm.value.height+`px;">
			<div class="cb-block">
				`+closeBtnHtml+`
			<div class="cb-content" `+onClick+`>
				<div>`+this.siteForm.value.bodyContent+`</div>
			</div>
			</div>
		</div>
		<js>cpjq('#bar-`+id+`').showBar(`+options+`);</js>`
		let postData: AnnouncementObj = {
			data : JSON.stringify(this.siteForm.value),
			name : this.siteForm.value.name,
			flagPopup : 0,
			html : html,
			scheduleStart : this.datePipe.transform(this.siteForm.value.fromDate, "yyyy-MM-dd"),
			scheduleEnd : this.datePipe.transform(this.siteForm.value.toDate, "yyyy-MM-dd"),
			allowPages : this.siteForm.value.allowPages,
			repeatDays : this.siteForm.value.repeatDays,
			status : this.siteForm.value.deleteStatus && type == 'PUBLISH' ? 'ACTIVE' : 'HIDDEN',
			projectKey: this.projectService.projectDetails.projectKey
		}
		if(type == 'PREVIEW'){
			localStorage.setItem('cp_preview_html', html);
			window.open('/assets/preview.html','_blank');
		}
		if(this.id != undefined){
			postData.id = this.id;
			this.announcementService.updateAnnouncement(postData,this.type).subscribe( data => { 
				this.saveData(data,type);
			})
		} else {
			this.announcementService.createAnnouncement(postData,this.type).subscribe( data => { 
				this.saveData(data,type);
			})
		}
	}

	saveData(data,type){
		if(data.status){
			this.id = data.data.id;
			if(type == "PUBLISH"){
				if(!this.isUpdate){
					this.saveMsg = data.data.message;
					this.modalRefConfirm = this.modalService.show(this.templateConfirm, { backdrop: 'static' });
				} else {
					this.toastr.success('Successfully Updated');
					this.router.navigate(['/admin/announcement/bar']);
				}
			}
		} else {
			this.toastr.error(data.error_msgs, "Error");	
		}
	}

	getData(){
		this.announcementService.fetchAnnouncement({id:this.id},this.type).subscribe( data => {
			if(data.status){
				var barData : AnnouncementObj =  data.data;
				this.setFormData(barData);
			} else {
				this.toastr.error(data.error_msgs, "Error")
			}
		})
	}

	setFormData(data){
		this.isPreview = true;
		var jsonData = JSON.parse(data.data);
		this.siteForm.setValue(jsonData);
		this.siteForm.controls['fromDate'].setValue(new Date(jsonData.fromDate));
		this.siteForm.controls['toDate'].setValue(new Date(jsonData.toDate));
		this.siteForm.controls['deleteStatus'].setValue(data.status=='ACTIVE');
		this.ckeditorData.next(jsonData.bodyContent);
	}

	setBoolean(value){
		if(value) return 0;
		return 1;
	}

	openGalleryModal(){
		const initialState = {
            galleryType : 'image',
            modalRefGallery: this.modalRefGallery
        }
        this.modalRefGallery = this.modalService.show(GalleryPopupComponent,{
            initialState,
            backdrop: "static",
            class: "gallery-popup"
        });
        this.modalRefGallery.content.modalRefGallery = this.modalRefGallery;
        this.modalRefGallery.content.selectMediaEvent.subscribe(data => {
            this.getMedia(data);
        });
	}

	getMedia(item){	
		let image = '<img src="'+item.originalFile+'" width="200" alt="'+item.mediaName+'" />';
        this.insertContent(image);
	}

	insertContent(html) {
		for (var i in CKEDITOR.instances) {
			CKEDITOR.instances[i].insertHtml(html);
		}
		return true;
	}


}
