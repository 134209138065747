export class BarObj {
    id: number;
    name:string;
    filePath?: string;
    startTime: number;
    duration: number;
    bodyContent: string;
    allowPages: string;
    pageUrl?: string;
    clickable: number;
    closable: number;
    link: string;
    barPosition: 'TOP' | 'BOTTOM';
    effect: string;
    height: number;
    bgColor: string;
    closeBtnColor: string;
    target: number;
    domain: string;
    publishStatus?: string;
    deleteStatus?: string;
    siteUrl?: string;
    type?: number;
    barHash?:string;
    fromDate: string
    toDate: string
}

export class PopupObj {
    id?: number;
    name: string;
    triggerEvent: number;
    filePath?: string;
    startTime: number;
    duration: number;
    bodyContent: string;
    allowPages: string;
    clickable: number;
    closable: number;
    link?: string;
    effect?: string;
    width: number;
    bgColor: string;
    closeBtnColor: string;
    target: number;
    domain?: string;
    fromDate: string;
    toDate: string;
    popupHash?: string;
    deleteStatus?: string;
    publishStatus?: string;
}

export class AnnouncementObj{
    id?:number
    data: string
    name: string
    flagPopup: number
    html: string
    scheduleEnd: string
    scheduleStart: string
    allowPages: string
    status: string
    repeatDays: any
    projectKey?: any
}

export class AnnouncementListObj {
    creativePopupList: AnnouncementObj[]
    totalCount: number
}