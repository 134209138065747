import { Location } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { TatFormFields } from './tat.model';
import { ProjectService } from '../../../../core/services/project.service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { CampaignService } from '../../../../core/services/campaign.service';
import { Global } from '../../../../shared/models/global.model';
import { UserService } from '../../../../core/services/user.service';

@Component({
  selector: 'app-turn-around-time',
  templateUrl: './turn-around-time.component.html',
  styleUrls: ['./turn-around-time.component.scss']
})
export class TurnAroundTimeComponent implements OnInit {
  @ViewChild('cnfrimUpdateStatus') cnfrimUpdateStatus: TemplateRef<any>;
  @ViewChild('addTatModal') addTatModal: TemplateRef<any>;
  @ViewChild('confirmModal') confirmModal: TemplateRef<any>;
  tatForm:FormGroup;
  modalRef: BsModalRef;
  formBuilder:FormBuilder;
  tatFields:TatFormFields = {};
  allUserFromCategory:any = [];
  dataList:any;
  dynamicParams:any;
  allUserData:any = [];
  paramKeylist:any;
  campaignSubmit:any;
  apiUrl:any;
  campaign:any;
  showAlertMessage:boolean=false;
  alertMessage:any='';
  alertTitle: any = '';
  goToChannel:boolean=false;
  campaignProceed: Subject<object> = new Subject();
  pushServiceUrl:any = environment.pushServiceUrl;
  // iosPushUrl:any = environment.iosPushUrl;
  // webPushUrl:any = environment.webPushUrl;
  emailPushUrl:any = environment.emailPushUrl;
  androidPushNotification:boolean = false;
  iosPushNotification:boolean = false;
  webPushNotification:boolean = false;
  reloadTemps:any="reload";
  tatData:any=true;
  tatListData:any=[];
  viewTatData:any;
  reassignActivate:boolean = false;
  deleteData:any;
  roleList:any;
  roleListClone:any;
  tatObj={
      statusId:0,
      tatDuration:0,
      type:'',
      category:'',
      paramKey:'',
      userIds:'',
      fields:'',
      templateTitle:'',
      templateBody:'',
      name:'',
      roleId:''
  }
  constructor(
    private router:Router,
    private location:Location,
    private modalService:BsModalService,
    private projectService:ProjectService,
    private route:ActivatedRoute,
    private toastr: ToastrService,
    private campaignService:CampaignService,
    public global: Global,
    private userService:UserService
  ) { }

  ngOnInit() {
    var list = this.projectService.filterAttributeList(['all']); 
    list.sort((a,b)=>(a.paramName > b.paramName)?1:-1);
    this.paramKeylist = list;
    this.paramKeylist.forEach(elem=>{
      if(elem.pluginName == 'dynamic'){
        elem.paramKey = "params."+elem.paramKey
      }
    })
    this.fetchCategory();
    this.route.paramMap.subscribe(data=>{
      this.dynamicParams = data;
    })
    this.tatList();
    this.fetchUserRole();
  }


  fetchUserRole(){
    this.roleList = [];
    this.userService.getUserRoleData().then(data => {
        if(data != undefined){
            data.sort((a,b)=>{
                if(a.level < b.level){
                    return -1;
                }
                if(a.level > b.level){
                    return 1;
                }
            });
            console.log(data);
            this.roleList = data;
            this.roleList.forEach(elem=>{
                console.log(elem);
                elem.parent = []
                if(elem.parentRole){
                    let parentRole = elem.parentRole.split(',');
                    parentRole = parentRole.filter(p=>{
                        return p != null;
                    });
                    parentRole.forEach(element => {
                        let findParent = this.roleList.find(x=>x.id == element)
                        if(findParent){
                            elem.parent.push(findParent.name)
                        }
                    });
                }
            })
            this.roleListClone = data.filter(p=>{
                return p.roleTypeId == 1
            });
        }
    })
}

  deleteAction(item){
    this.deleteData = item
    this.modalRef = this.modalService.show(this.confirmModal, { class: "", backdrop: 'static' });
  }

  deleteTat(){
    this.projectService.deleteTat(this.deleteData.statusId+'/'+this.deleteData.id).subscribe(data=>{
      if(data.status){
        this.toastr.success(data.data);
        this.tatList();
      } else{
        this.toastr.error(data.error_msgs);
      }
    })
  }

  getTatType(event){
    console.log(event.target.value);
    let selectedType = event.target.value;
    if(selectedType == 'REASSIGN'){
      this.reassignActivate = true
    } else{
      this.reassignActivate = false
    }
  }

  editTat(item){
    console.log(item);
    this.tatFields = {}
    this.modalRef = this.modalService.show(this.addTatModal, { class: "modal-rightblock", backdrop: 'static' });
    // this.tatFields = item;
    this.projectService.editTat(item.statusId+'/'+item.id).subscribe(data=>{
      if(data.status){
        console.log(data);
        this.tatFields = data.data;
        if(this.tatFields.type == 'REASSIGN'){
          this.reassignActivate = true
        } else{
          this.reassignActivate = false;
        }
      } else{
        this.toastr.error(data.data);
      }
    })
  }

  tatList(){
    this.projectService.tatList(parseInt(this.dynamicParams.params.id)).subscribe(data=>{
      console.log(data);
      if(data.status){
        this.tatListData = data.data;
        this.tatListData.forEach(elem=>{
          if(elem.paramKey && elem.paramKey.includes('params.')){
            // elem.tatField = elem.paramKey.replace('params.','');
            let findAttr = this.paramKeylist.find(x=>x.paramKey == elem.paramKey);
            if(findAttr){
              elem.tatField = findAttr.paramName;
            }
          }
        })
      }
    })
  }

  back(){
    this.location.back();
  }

  fetchCategory() {        
    this.dataList = [];
    this.projectService.fetchCategory({}).then(data => {
        if (data) {
            this.dataList = data;
            this.dataList.forEach(elem=>{
              if(elem.linkRewrite == this.dynamicParams.params.dynamicparam){
                this.projectService.getUserFromMaster().then(userData=>{
                  if(userData){
                    let userKeys = Object.keys(userData);
                    userKeys.forEach(user=>{
                      if(userData[user].status == 'ACTIVE'){
                        this.allUserData.push(userData[user]);
                      }
                    })
                  }
                })
              }
            })
            this.allUserFromCategory = this.allUserData;
        }
    })
}

channelProcess(event){  
  if(this.campaign == 'Email'){
    this.tatObj.templateTitle = event.emailSubject;
    this.tatObj.templateBody = event.emailText;
    this.tatObj.fields = event.fields;
  } else if(this.campaign == 'Push'){
    this.tatObj.templateTitle = event.title;
    this.tatObj.templateBody = event.message;
    this.tatObj.fields = event.fields;
  }
  this.projectService.tatSave(this.tatObj).subscribe(data=>{
    if(data.status){
      this.tatFields = {}
      this.toastr.success(data.data);
      this.modalRef.hide();
      this.tatList();
    } else{
      this.toastr.error(data.error_msgs);
    }
  })
}

  saveTat(formFields){
    let formSubmit = true
    let formKeys = Object.keys(formFields);
    formKeys.forEach(elem=>{
      if(formFields[elem] == undefined){
        this.toastr.error('All fields are required');
        formSubmit = false
        return
      }
    });
if(formSubmit){
    this.tatObj.statusId = parseInt(this.dynamicParams.params.id);
    this.tatObj.tatDuration =parseInt(formFields.tatDuration);
    this.tatObj.type = formFields.type;
    this.tatObj.category = this.dynamicParams.params.dynamicparam;
    this.tatObj.paramKey = formFields.paramKey;
    this.tatObj.userIds = formFields.userIds;
    this.tatObj.name = formFields.name;
    this.tatObj.roleId = formFields.roleId
    var campaignData = {
      campaign:'',
      audienceCount: 1,
      audienceIds:[]
    }
    if(formFields.type == 'EMAIL'){
      this.apiUrl = this.emailPushUrl;
      this.campaignSubmit = 'Email';
      this.campaign = 'Email'
    } else if(formFields.type == 'ANDROID'){
      this.androidPushNotification = true;
      this.apiUrl = this.pushServiceUrl;
      this.campaignSubmit = 'Push';
      this.campaign = 'Push'
    } else if(formFields.type == 'IOS'){
      this.iosPushNotification = true;
      this.apiUrl = this.pushServiceUrl;
      this.campaignSubmit = 'Push';
      this.campaign = 'push'
    } else if(formFields.type == 'WEB'){
      this.webPushNotification = true;
      this.apiUrl = this.pushServiceUrl;
      this.campaignSubmit = 'Push';
      this.campaign = 'push'
    } else if(formFields.type == 'REASSIGN'){
      //this.apiUrl = this.webPushUrl;
      this.projectService.tatSave(this.tatObj).subscribe(data=>{
        if(data.status){
          this.tatFields = {}
          this.toastr.success(data.data);
          this.modalRef.hide();
          this.tatList();
        } else{
          this.toastr.error(data.error_msgs);
        }
      })
      return;
    }
    campaignData.campaign = this.campaignSubmit;
    this.checkConfigurationForCampaign(campaignData);
  }

  }

  checkConfigurationForCampaign(campData) {
    this.campaignService.checkConfigurationForCampaign({ campType: this.campaign, isAndroid: this.androidPushNotification, isIos: this.iosPushNotification, isWeb: this.webPushNotification },this.apiUrl).subscribe( data => {
        if (data.status) {
            this.showAlertMessage = false;
            this.goToChannel = true;
            this.global.Channel.AllAudience = true;
            this.global.CampaignData = campData;
            this.campaignProceed.next(campData);
        } else if (!data.error_msgs.status) {
            this.modalRef.hide();
            this.alertTitle = data.error_msgs.errorTitle;
            this.alertMessage = data.error_msgs.errorMessage ? data.error_msgs.errorMessage : data.error_msgs;
            this.showAlertMessage = true;
        } else {
            this.showAlertMessage = false;
            this.goToChannel = true;
            this.campaignProceed.next(campData);
        }
    });
}

closeAlert(){
  this.showAlertMessage = false; 
  this.goToChannel=false;
  this.tatFields = {}
}

  addTat(){
    this.reassignActivate = false;
    this.allUserFromCategory = this.allUserData;
    this.tatFields = {}
    this.modalRef = this.modalService.show(this.addTatModal, { class: "modal-rightblock", backdrop: 'static' });
  }

}
