<main>
    <section class="inner-page">

        <div class="inner-page-header">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <h4>
                            <a class="back-link" [routerLink]="['/admin/project-integration/Social Media/Facebook']"><img src="assets/images/angle-left.svg"></a> 
                            <ng-container *ngIf="isUpateRequest; else createRequest"> Update Form </ng-container>
                            <ng-template #createRequest> Create Form </ng-template>
                        </h4>
                    </div>
                </div>
            </div>
        </div>

        <div class="inner-page-body campaign-body">
            <div class="container-fluid ">
                <div class="row">
                    <ng-container *ngIf="!isUpateRequest && isFacebook; else upateRequest">
                        <div class="col-md-3 box" *ngIf="pageList.length > 0">
                            <h3 [class]="isLavel1 ? 'clPrimary' : ''">Facebook Pages</h3>
                            <ul class="campaign-list">
                                <li *ngFor="let page of pageList; let i = index" [ngClass]="page.id == fbConfigData.fbPageId ? 'active' : ''" ><a href="javascript:void(0);" (click)="leadgenForm(page.id, page.name, page.access_token)">{{page.name}}</a></li>
                            </ul>
                        </div>
                        <div class="col-md-3 box" *ngIf="campaignList.length > 0">
                            <h3 [class]="isLavel2 ? 'clPrimary' : ''">Lead Forms</h3>
                            <ul class="campaign-list">
                                <li *ngFor="let campaign of campaignList" [ngClass]="campaign.id == fbConfigData.fbFormId ? 'active' : ''">
                                    <a *ngIf="campaign.mapped; else otherCampaign" class="disabled" href="javascript:void(0);">{{campaign.name}} (Mapped)</a>
                                    <ng-template #otherCampaign>
                                        <a  href="javascript:void(0);" (click)="showLeads(campaign.id, campaign.name)">{{campaign.name}}</a>
                                    </ng-template>
                                </li>
                            </ul>
                            <button *ngIf="previous != ''" (click)="fetchPagingResult(previous)" class="btn btn btn-dark"> Prev </button>
                            <button *ngIf="next != ''" (click)="fetchPagingResult(next)" class="btn btn btn-dark m_l_10"> Next </button>
                        </div>
                        <div *ngIf="noCampaignList" class="col-md-6 box empty-page d-flex align-items-center justify-content-center">No Records Found</div>
                    </ng-container>
                    <ng-template #upateRequest>
                        <div class="col-md-3 box">
                            <h3>Facebook Pages</h3>
                            <ul class="campaign-list">
                                <li><div>{{fbConfigData.formName}}</div></li>
                            </ul>
                        </div>
                        <div class="col-md-3 box">
                            <h3>Lead Forms</h3>
                            <ul class="campaign-list">
                                <li ><div>{{fbConfigData.formName}}</div></li>
                            </ul>
                            
                        </div>
                    </ng-template>
                    
                    
                    <div class="col-md-6 box" *ngIf="campaignFields.length > 0 ">
                        <div class="top d-flex justify-content-between" *ngIf="fbConfigData.status == 'ACTIVE' ||  !fbConfigData.status ">
                            <h3 [class]="isLavel3 ? 'clPrimary' : ''">Form Fields</h3>
                            <!-- <a target="_blank" href="/integration/#facebook">Integeration Guide</a> -->
                        </div>
                        <div class="row ">
                            <ng-container *ngIf="fbConfigData.status == 'ACTIVE' ||  !fbConfigData.status">
                            <div class="col-md-12">
                                <div class="row form-align">
                                    <div class="col-6">
                                        <div class="form-group mb-0">
                                            <label class="control-label pb-0">Cronberry Fields</label>
                                        </div>
                                    </div>
                                    <div class="col-6 pr-0">
                                        <div class="form-group mb-0">
                                            <label class="control-label pb-0">Facebook Fields</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="col-md-12" *ngFor="let field of campaignFields; let i = index">
                                <div class="row form-align">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <select class="form-control" [(ngModel)]="field.paramId">
                                                <option value="" disabled>Select Field</option>
                                                <!-- <option *ngFor="let item of this.AllInputParams" [value]="item.id">{{item.paramName}}</option> -->
                                                <ng-container *ngFor="let item of AllInputParams">
                                                    <option [value]="item.id" *ngIf="item.paramVisible == 1  && item.paramDatatype !='File'">{{item.paramName}} <p class="d-inline" *ngIf="(item.paramKey=='city' || item.paramKey=='state' || 
                                                        item.paramKey=='message' || item.paramKey=='country' || item.paramKey=='pincode' || item.paramKey=='city' || item.paramKey=='product_name' ||  item.paramKey=='address') ">(default)</p></option>
                                                </ng-container>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <input class="form-control" [(ngModel)]="field.mapValue" value="{{field.mapValue}}" disabled type="text">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </ng-container>
                            
                            <div class=" col-md-12 mt-3">
                                <div class="float-right">
                                    <ng-container *ngIf="isUpateRequest; else createRequestBtn">
                                        <ng-container *ngIf="fbConfigData.status == 'ACTIVE'">
                                        <button class="btn btn btn-dark" (click)="facebookFormDeleteConfirm();" type="submit">Delete</button> &nbsp; 
                                        <button class="btn btn btn-dark" (click)="saveFormsMapping()" type="submit">Update</button>&nbsp; 
                                        </ng-container>
                                        <button *ngIf="fbConfigData.status == 'ACTIVE'" class="btn btn btn-dark" (click)="updateFaceBookFormStatus('INACTIVE')" type="submit">Disable</button>
                                        <button *ngIf="fbConfigData.status != 'ACTIVE'" class="btn btn btn-dark" (click)="updateFaceBookFormStatus('ACTIVE')" type="submit">Enable</button>
                                    </ng-container>
                                    <ng-template #createRequestBtn> <button class="btn btn btn-dark" (click)="saveFormsMapping()" type="submit">Save</button> </ng-template>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="noCampaignFields" class="col-md-6 box empty-page d-flex align-items-center justify-content-center">No Records Found</div>
                </div>
                <div *ngIf="pageList.length == 0 && !isUpateRequest" class="empty-page" >No Records Found</div>   
            </div>
        </div>
    </section>
</main>

<ng-template #facebook>
    <div class="facebook-modal">
        <div class="modal-header">
            <h4 class="modal-title pull-left">Facebook</h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal();">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body modal-user-management text-center">
            <h3>Configuring this app would allow you to capture leads from 'Facebook Lead Ads' directly into cronberry.</h3>
            <div class="facebook-button">
                <button class="btn btn-primary" (click)="facebookLogin();"> <img src="assets/images/facebook-square.svg" alt="Facebook"> Connect with Facebook</button>
            </div>
            <p class="text-muted">Click Connect with Facebook which allows Cronberry to access your Facebook pages.</p>
        </div>
    </div>
</ng-template>

<ng-template #facebookDeleteConfirm>
    <!-- delete popup -->
    <form>
        <div class="forgot-password-modal">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Delete Google Form</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body modal-user-management">
                <div class="form-group mb-0">
                    <p class="pt-2">
                        Leads from this form will not be captured in cronberry after you delete it. Are you sure, you want to delete this form ?
                    </p>
                    <div class="d-flex justify-content-end mt-5">
                        <div>
                            <button class="btn remove-user-btn mr-4" (click)="modalRef.hide()" type="button">
                                Dismiss
                            </button>
                        </div>
                        <div>
                            <button class="btn save-btn" type="button" (click)="deleteFacebookForm()">
                                Yes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</ng-template>