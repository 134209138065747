<main>
    <section class="inner-page">
        <div class="inner-page-header">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-4">
                        <h4>
                            <a class="back-link" (click)="back()"><img src="assets/images/angle-left.svg"></a>
                            Analysis</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="inner-page-body border-bottom-0">
            <div class="container-fluid analysis-body">
                <div class="row top">
                    <div class="col-md-6">
                        <h2>Lead Reports</h2>
                    </div>
                    <div class="col-md-6 text-right right d-flex j-end c-align">
                        <select class="form-control m_r_5" (change)="fetchLeadReports()" [(ngModel)]="categoryName">
                            <option *ngFor="let cat of dataList" [value]="cat.linkRewrite">{{cat.name}}</option>
                        </select>
                        
                        <input type="text" class="form-control date-icon modal-space" readonly autocomplete="off"
                            ngxDaterangepickerMd
                            [locale]="{format: 'DD/MM/YYYY'}"
                            [(ngModel)]="leadReportDays"
                            [alwaysShowCalendars]="true"
                            [ranges]="ranges"
                            [autoApply]="false"
                            [opens]="'left'"
                            [maxDate]=""
                            placeholder="Select Date Range"
                            (datesUpdated)="fetchCategory()">
                            &nbsp; 
                            <button type="button" class="btn white_add_btn" *ngIf="!isNoDataLeadChart" tooltip="Add Report Chart" [tooltipAnimation]="true" tooltipPlacement="top" (click)="global.Channel.sourceFormIds = []; global.Channel.Source = [];addLeadChart()">
                                <img src="assets/images/plus-icon.png"> &nbsp; Add
                            </button>
                    </div>
                </div>
                <div class="form-row" *ngIf="!isNoDataLeadChart">

                    

                    <div *ngFor="let items of leadReportListData; let i = index" class="col-md-12 mb-4">
                        
                        
                        <!-- <div class="position_relative minHeight_250 " *ngIf="items.showButton">
                            <div class="overShadow analysisPage">
                                <div class="spinner absolute_position" *ngIf="items.loadingData">
                                    <div class="loader">
                                        <div class="la-ball-clip-rotate la-2x"><div></div></div>
                                        <p *ngIf="timer > 20 && isLogin">&nbsp;<a [routerLink]="['/admin/dashboard']" (click)="httploading = false">Lost somewhere ?.. Go to Dashboard again !!!</a></p>
                                    </div>
                                </div>
                                <div class="chart_title_">{{items.reportName}}</div>
                                <div class="playButton" (click)="loadingData = true; loadReportDetail(items)">
                                    <img src="assets/images/segmenteye-black.png">
                                </div>
                            </div>
                        </div> -->

                        <div *ngIf="leadReportChartOptions">
                            <div *ngFor="let itemss of leadReportChartOptionsDetail; let i = index">
                                <ng-container *ngIf="itemss.id == items.id">
                                <ng-container *ngFor="let item of itemss.value">
                                <div [class]="item.data.part == undefined || item.data.part == 1 ? 'part chart-box graphOuterBox':'otherPart chart-box graphOuterBox'" [ngClass]="{'no-chart-icon' : item.data.reportType == 1 || item.data.dataCount == 0}"  >
                                    <div class="chart-delete" (click)="showDeleteModal(item.data.id)"><img tooltip="Delete" tooltipPlacement="top" src="assets/images/delete.png" alt=""></div>
                                    <div class="chart-edit" *ngIf="!item.data.missedAttribute" (click)="fetchReport(itemss)"><img tooltip="Edit" tooltipPlacement="top" src="assets/images/pencil.svg" alt=""></div>
                                    
                                    <ng-container *ngIf="item.data.reportType == 0; else quantitativeReport">
                                        <div *ngIf="item.data.dataCount > 0">
                                            <div class="chart-edit chart-reset" (click)="refreshGraph(itemss,items)"><img src="assets/images/reset-icon.png" width="15" class="103" [class.reload]="items.loadingData"></div>
                                            <!-- <div *ngIf="item.data.dataCount <= 10 && item.data.columnOutput == 0" class="leadchart-type" (click)="changeChartType(i, item.data.id)">
                                                <img tooltip="Switch to Bar Chart" tooltipPlacement="top" *ngIf="item.chart.type == 'pie'" src="assets/images/chart-bar.svg" alt="">
                                                <img tooltip="Switch to Pie Chart" tooltipPlacement="top" *ngIf="item.chart.type == 'bar'" src="assets/images/chart-pie-alt.svg" alt="">
                                            </div> -->
                                            <apx-chart *ngIf="item.chart.type == 'bar'"
                                                [title]="item.title"
                                                [series]="item.series"
                                                [chart]="item.chart"
                                                [xaxis]='item.xaxis'
                                                [yaxis]='item.yaxis'
                                                [dataLabels]="item.dataLabels"
                                                [legend]="item.legend"
                                                [colors]="item.colors"
                                                [plotOptions]="item.plotOptions"
                                                [tooltip]="item.tooltip"
                                            ></apx-chart>
                                            <apx-chart *ngIf="item.chart.type == 'pie'"
                                                [title]="item.title"
                                                [series]="item.seriesNon"
                                                [chart]="item.chart"
                                                [labels]="item.labels"
                                                [legend]="item.legend"
                                                [colors]="item.colors"
                                                [stroke]="item.stroke"
                                                [dataLabels]="item.dataLabels"
                                            ></apx-chart>
                                        </div>
                                        <div class="no-data" *ngIf="!item.data.missedAttribute && item.data.dataCount == 0">
                                            <h3>{{item.data.reportName}}</h3>
                                            No Data Available.
                                        </div>
                                        <div class="no-data" *ngIf="item.data.missedAttribute && item.data.dataCount == 0">
                                            <h3>{{item.data.reportName}}</h3>
                                            Report is based on {{item.data.misedAttrList}}. Attribute is not having visibility for your role. Hence Report will not render. 
                                        </div>

                                    </ng-container>
                                    <ng-template #quantitativeReport>
                                        <div class="quantative-box">
                                            <h3>{{item.data.reportName}}</h3>
                                            <div [class]="item.data.per < 0 ? 'value red' : 'value' ">{{item.data.xaxisAttributeName}} {{item.data.curCount}}
                                                <span><img [src]="item.data.per < 0 ? 'assets/images/caret-down.svg' : 'assets/images/caret-up.svg'" alt=""> {{item.data.per}}%</span>
                                            </div>
                                            <div class="info">Compared from: {{item.data.fromDate | date}} - {{item.data.toDate | date}}</div>
                                        </div>
                                    </ng-template>
                                </div>
                                </ng-container>
                            </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- addLeadChart() -->
                <div *ngIf="isNoDataLeadChart" class="leadreport-placeholder" (click)="global.Channel.Source = []; openModal(addLeadReportBlock);" >
                    <img src="assets/images/plus.svg" alt="">
                    <h3>Add Report Chart</h3>
                </div>

            </div>
        </div>

    </section>
</main>

<ng-template #addLeadReportBlock>
    <app-fresh-audience-popup [leadReportForm]="true" [modalRef]="modalRef" [filterClose]="filterClose.bind(this)" [addReport]="addReport" [notCloseOnSelect]="notCloseOnSelect" (sendRecordStatus)="reloadList($event)" [addAction]="addAction" [categoryName]="categoryName"></app-fresh-audience-popup>
</ng-template>

<ng-template #addLeadReports>
    <div class="forgot-password-modal addReportModal">
        <div class="row top" >
            <div class="col-md-12">
              <div class="modal-header">
              <h4 class="modal-title pull-left">Update Report</h4>
              <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                  <span aria-hidden="true">&times;</span>
              </button>
              </div>
            </div>
          </div>
        <div class="modal-body">
            <app-create-report-graph [modalRef]="modalRef" [refilEditVal]="refilEditVal" (recordUpdateAdded)="recordUpdateAdded($event)"></app-create-report-graph>
        </div>
    </div>
</ng-template>

<ng-template #leadReportDeleteConfirm>
    <!-- delete popup -->
    <form>
        <div class="forgot-password-modal">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Delete Lead Report Chart</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body modal-user-management">
                <div class="form-group mb-0">
                    <p class="pt-2">
                        Are you sure, you want to delete this Lead Report Chart?
                    </p>
                    <div class="d-flex justify-content-end mt-5">
                        <div>
                            <button class="btn remove-user-btn mr-4" (click)="modalRef.hide();" type="button">Dismiss</button>
                        </div>
                        <div>
                            <button class="btn save-btn" type="button" (click)="deleteLeadReportChart();">Yes</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</ng-template>