import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { delay } from 'rxjs/operators';
import { AppConstants } from '../../../app/constants/app.constants';
import { ApiConstants } from '../../constants/api.constants';
import { BaseService } from './base.service';


@Injectable({
  providedIn: 'root'
})

export class UrlShortnerService {
    
    constructor(
        private baseService: BaseService,
        private toastr: ToastrService
    ) { 
        
    }
    listShortens(body:any,size,page){
        return this.baseService.postRequest(ApiConstants.LIST_SHORTENS.replace(AppConstants.REPLACE_SIZE, size).replace(AppConstants.REPLACE_PAGE, page), body)
    }

    deleteUrlShortner(body:any) {
        return this.baseService.postRequest(ApiConstants.UPDATE_SHORTNER, body)
    }

    createUrlShortner(body:any) {
        return this.baseService.postRequestWithHeaders(ApiConstants.CREATE_URL_SHORTNER, body,{"link":body.actualLink})
    }

    async getUrlShortner(url:string){
        var shortURL = await this.createUrlShortner({actualLink:url}).pipe(delay(200)).toPromise();
        if(shortURL.status){
            return shortURL.data;
        } else {
            this.toastr.error(shortURL.error_msgs, 'Error');
            return undefined;
        }
    }
}