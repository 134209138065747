import { Component, OnInit, TemplateRef, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { BsModalRef, BsModalService, TabsetComponent } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ProjectService } from '../../../../core/services/project.service';
import { Global } from '../../../../shared/models/global.model';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomFormService } from '../../../../../app/modules/customForm/customForm.service';
import { CustomFormObj } from '../../../../../app/modules/customForm/customForm.modal';
import { AppConstants } from '../../../../../app/constants/app.constants';
import { ApiConstants } from '../../../../../app/constants/api.constants';
import { DatePipe, Location } from '@angular/common';
import { UserService } from '../../../../core/services/user.service';
import { UrlShortnerService } from '../../../../core/services/urlShortner.service';
import { AttributeObj, FacebookPageObj, GoogleFormObj, PlguinDataObj, UserAssignLeadbyPluginObj } from '../../../../core/modal/project';
import { SubUserObj } from '../../../../core/modal/user';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { CampaignService } from '../../../../core/services/campaign.service';
import { concat, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { SpinnerService } from '../../../../core/services/spinner.service';

@Component({
    selector: 'app-project-integration',
    templateUrl: './project-integration.component.html',
    styleUrls: ['./project-integration.component.scss']
})
export class ProjectIntegrationComponent implements OnInit, OnDestroy {
    keyword = 'paramValue';
    in_not_in_selector:any='in';
    modalRef: BsModalRef;
    fixedParams: AttributeObj[] = [];
    inputParams: AttributeObj[] = [];
    googleMappingParams: AttributeObj[] = [];
    inputParam: AttributeObj;
    marketplace: any = [];
    Plugindata: Map<string, PlguinDataObj[]>;
    PluginForm: any = [];
    FormsMapping: any = [];
    CurrentPlugin: any = {};
    Forms: any = [];
    waitCount: any = 2;
    isVisible: boolean = true;
    addform: FormGroup;
    inputParamForm: FormGroup;
    inputParamFormFixedAttr:FormGroup
    Id: number;
    facebookPageList: FacebookPageObj[] = [];
    googleFormList: GoogleFormObj[] = [];
    googleFormListCache:any;
    googleFormName: string;
    googleFormCreateData: string;
    googleFormButton: string;
    isGoogleFormButton: boolean;
    googleFormId: number;
    pageType: string = '';
    customFormList: CustomFormObj[] = [];
    customFormListCache:any;
    customFormData: CustomFormObj;
    marketplaceFormName: string = null;
    marketplaceStatus: boolean = false;
    isMarketplaceCreate: boolean = false;
    lead_email_atrribute: boolean = false;
    lead_mobile_atrribute: boolean = false;
    toDate: any = new Date();
    subUserList: SubUserObj[] = [];
    allTelecallerUserList: SubUserObj[] = [];
    // selectedUsers: any[] = [];
    pageParams: any;
    userRoleList: any[] = [];
    showMapParams:any=new Array();
    paramValue:any[] = [];
    paramValStore:any=[];
    paramMappingDTOsStores:any = [];
    operatorStore:any;
    excelAlias:any;
    excelActive:any;
    updateConfirmModal:any=false;
    formNameOpener:any=false;
    excelFormDetail:any;
    excelPageList:any;
    shopifyPageList:any
    addNewExcel:any=false;
    delete_action:any = false;
    updateListByStatus:any;
    googleFormItem:any;
    facebookPageListCache:any;
    inactiveStore:any = new Array();
    ecommerceFormFields:any;
    eCommercePluginName:any;
    eCommerceFormName:any;
    paramObjCheckOperator:any={'paramDatatype':''};
    updateEcommerceForm:any=false;
    eCommerceFormId:any;
    eCommerceFormDetails:any;
    eCommerceFormDetailsStatus:any;
    uploadableSelected:boolean = false;
    categorySelector:any;
    dataList:any;
    pluginId:any;
    updateProp:any;
    activeInactiveStatus:any;
    leadUpdateStatus:any;
    repeatLeadStatus:any;
    getPluginId:any;
    selectfile:any;
    catDataLoad:boolean=false;
    dataStoreSubmit = {'formName':'','id':1,'isAssignAttribute':true,'paramId':1,'paramMappingDTOs':[],'userList':[],'operator':''};
    @ViewChild('shopify') shopify: TemplateRef<any>;
    @ViewChild('basicForm') basicForm: TemplateRef<any>;
    @ViewChild('googleform') googleform: TemplateRef<any>;
    @ViewChild('googleformMsg') googleformMsg: TemplateRef<any>;
    @ViewChild('googleFormAdd') googleFormAdd: TemplateRef<any>;
    @ViewChild('pluginTabs') pluginTabs: TabsetComponent;
    // @ViewChild('pageTabs') pageTabs: TabsetComponent;
    @ViewChild('formsTabs') formsTabs: TabsetComponent;
    @ViewChild('socialMedia') socialMedia: TabsetComponent;
    @ViewChild('marketplaceTabs') marketplaceTabs: TabsetComponent;
    @ViewChild('ivrTabs') ivrTabs: TabsetComponent;
    @ViewChild('customForm') customForm: TemplateRef<any>;
    @ViewChild('leadUpdationByUser') leadUpdationByUser: TemplateRef<any>;
    @ViewChild('excelForm') excelForm: TemplateRef<any>;
    @ViewChild('ecommerceForm') ecommerceForm: TemplateRef<any>;
    @ViewChild('deleteEcommerceForm') deleteEcommerceForm: TemplateRef<any>;
    @ViewChild('configurationType') configurationType: TemplateRef<any>;
    @ViewChild('fileInput') fileInput: ElementRef;
    file: any;
    excelformName:any;
    attributeCsvUpload: FormGroup;
    isGetPageData: boolean = false;
    isShortUrlSubscribe: boolean = true;
    leadUpdateValue: UserAssignLeadbyPluginObj;
    attributeParamValues: any[] = [];
    inputParamFormObj:any={};
    postDataFilter:any={values:[]};
    popVal:any = new Array();
    formDetailData:any;
    confirmUpdateCategory:boolean=false;
    dynamicCategory: any[] = [];
    userRoleListToAssign:any;
    userRoleListToHide:any;
    userRoleListToHideInAdd:any;
    userRoleListToHideInSysAttr:any;
    constructor(
        private modalService: BsModalService,
        private toastr: ToastrService,
        private global: Global,
        private formBuilder: FormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private projectService: ProjectService,
        private customFormService: CustomFormService,
        private userService: UserService,
        private datePipe: DatePipe,
        private authenticationService: AuthenticationService,
        private urlShortnerService: UrlShortnerService,
        private campaignService: CampaignService,
        private location: Location,
        private spinner:SpinnerService
    ) {
        this.route.params.subscribe(params => {
            if (params['type'] !== undefined) {
                this.pageType = params['type'];
            };
            if (params['cat'] !== undefined) {
                this.pageParams = params;
            }
            if (this.Plugindata != undefined) {
                this.activePageTab();
            }
        });
        this.checkSubscription();
        this.fetchuserPlugins();
        this.fetchPluginFormXl();

        this.userService.getUsersData().then(data => {
            if (data != undefined) {
                this.subUserList = data;
            }
        });
    }

    ngOnDestroy() {
        if (this.modalRef) {
            this.modalRef.hide();
        }
    }

    back() {
        this.location.back();
    }

    htmlDecode (str) {
        var div = document.createElement("div");
        div.innerHTML = str;
        return div.textContent || div.innerText;
    }

    downloadQr() {
        var downloadableImg = document.getElementById('qrScanner').firstElementChild;
        const imageUrl = downloadableImg.attributes[2].nodeValue;
        const a: any = document.createElement('a');
        a.href = imageUrl;
        a.download = this.customFormData.name + '.png';
        document.body.appendChild(a);
        a.style = 'display: none';
        a.click();
        a.remove();
    }



    activePageTab() {
        if (this.pageType == 'Custom') {
            this.pluginTabs.tabs[this.getPluginData().length].active = true;
            return
        }
        if (this.pageParams != undefined && this.pageParams.cat != undefined) {
            // this.pageTabs.tabs[1].active = true;
            var index: number = this.getPluginData().findIndex(x => x === this.pageParams.cat);
            var pluginIndex: number = this.Plugindata[this.pageParams.cat].findIndex(x => x.pluginName === this.pageParams.pluginId);
            if (index >= 0) {
                this.pluginTabs.tabs[index].active = true;
            }
            if (pluginIndex >= 0) {
                if (this.pageParams.cat == 'Forms') {
                    this.formsTabs.tabs[pluginIndex].active = true;
                } else if (this.pageParams.cat == 'Marketplace') {
                    this.marketplaceTabs.tabs[pluginIndex].active = true;
                } else if (this.pageParams.cat == 'IVR') {
                    this.ivrTabs.tabs[pluginIndex].active = true;
                }
                let plugin = this.Plugindata[this.pageParams.cat][pluginIndex];
                plugin.status = plugin.pluginStatus == 'ENABLE';
                plugin.leadActiveStatus = plugin.leadActive == 'ENABLE';
                plugin.leadRepeatFlagStatus = plugin.leadRepeatFlag  == 'ENABLE';
                this.CurrentPlugin = plugin;
            }
        }
    }

    ngOnInit() {
        this.fetchCategory();
        this.getDynamicCategory();
        this.isGoogleFormButton = false;
        this.init();
        this.attributeCsvUpload = this.formBuilder.group({
            file: [''],
            file_name:['']
          });
    }

    private loadAutoComplete(obj) {        
        obj.valueInput$ = new Subject<string>();
        obj.selected = <any>[];
        obj.values$ = concat(
          of([]), // default items
          obj.valueInput$.pipe(
            debounceTime(1000),
            distinctUntilChanged(),
            tap(() => obj.loading = true),
            switchMap(term => this.getAttributesVal(this.leadUpdateValue.paramId, term).pipe(
              catchError(() => of([])), // empty list on error
              tap(() => obj.loading = false)
            ))
          )
        );
      }


      getAttributesVal(paramKey, term) {
        let paramKeyParam = this.inputParams.find(x=>x.paramKey == paramKey);
        if(paramKeyParam != undefined){
            paramKey = paramKeyParam.pluginName == 'dynamic' ? 'params.'+paramKey : paramKey;
            return this.campaignService.searchParamData({ paramKey: paramKey, paramValue: term }).pipe(map(res => res.data));
        }
      }

     

    init(){
        this.preparegoogleForm();
        this.getParams();
        this.getSubUserList();
        if(this.authenticationService.projectDetails != null && this.authenticationService.projectDetails.repeatColumn){
                if(this.authenticationService.projectDetails.repeatColumn.indexOf('EMAIL')>=0){
                    this.lead_email_atrribute = true;

                }
                if(this.authenticationService.projectDetails.repeatColumn.indexOf('MOBILE')>=0){
                    this.lead_mobile_atrribute = true;
                    
                }
        }
    };

    checkSubscription() {
        this.isShortUrlSubscribe = this.authenticationService.isPageSubscribe('Url Shortner');
        if (this.authenticationService.isPageSubscribe('Google Forms')) this.getGoogleFormList();
        if (this.authenticationService.isPageSubscribe('Facebook')) this.getFacebookPages();
        if (this.authenticationService.isPageSubscribe('Custom Forms')) this.getCustomFormList();
        if (this.authenticationService.isPageSubscribe('shopify')) this.fetchPluginFormShopify();
    }


    googleFormMap(event){        
            this.showMapParams.forEach(element => {
                let checkAttr = this.FormsMapping.find(x=>x.paramId == element.id);
                if(checkAttr){
                    element.disableAttr = true;
                } else {
                    element.disableAttr = false;
                }
            });
    }

    getUserRole() {
        this.userService.getUserRoleData().then(data => {
            let rolesData = data;
            let userData = this.authenticationService.getUserData();            
            let userLevel;
            this.authenticationService.getProfile().then(userData=>{
                userLevel = userData.users.level;
                if(userLevel || userLevel == 0 ){
                    if (data != undefined) {
                    if(rolesData.length > 0){
                        this.projectService.getUserFromMaster().then(data=>{
                            let userObj = Object.keys(data);
                            userObj.forEach(elem=>{
                                if(data[elem].userRoleId == 1){
                                    this.userRoleList.push({
                                        value:data[elem].userRoleId,
                                        label:'Super Admin',
                                        })
                                }
                            });
                        });
                        rolesData.forEach(element=>{
                            this.userRoleList.push({ value: element.id, label: element.name })
                        })
                        this.userRoleListToAssign = this.userRoleList
                        this.userRoleListToHide = this.userRoleList.filter(p=>{
                            return p.value != 1
                        });
                        this.userRoleListToHideInAdd = this.userRoleList.filter(p=>{
                            return p.value != 1
                        })
                        this.userRoleListToHideInSysAttr = this.userRoleList.filter(p=>{
                            return p.value != 1
                        })
                        this.userRoleList.sort((a, b) => a.label.toLocaleLowerCase().localeCompare(b.label.toLocaleLowerCase()));
                        this.userRoleListToHideInAdd.sort((a, b) => a.label.toLocaleLowerCase().localeCompare(b.label.toLocaleLowerCase()));
                        this.userRoleListToHideInSysAttr.sort((a, b) => a.label.toLocaleLowerCase().localeCompare(b.label.toLocaleLowerCase()));
                        } 
                    }
                }
            });
        })
    }


    hideRoleLisUpdate(){
        let hideSelectedValues = this.inputParamForm.controls.hideUserRole.value
        if(hideSelectedValues && hideSelectedValues.length > 0){            
            hideSelectedValues.forEach(element => {
                let checkToRemove = this.userRoleListToAssign.find(x=>x.label == element.label);
                if(checkToRemove){
                    this.userRoleListToAssign = this.userRoleListToAssign.filter(p=>{
                        return p.label != element.label
                    })
                }
            });
        } else{
            this.userRoleListToAssign = this.userRoleList
        }
    }   

    userRoleListToAssignUpdate(){
        let hideSelectedValues = this.inputParamForm.controls.userRole.value
        if(hideSelectedValues && hideSelectedValues.length > 0){            
            hideSelectedValues.forEach(element => {
                let checkToRemove = this.userRoleListToHide.find(x=>x.label == element.label);
                if(checkToRemove){
                    this.userRoleListToHide = this.userRoleListToHide.filter(p=>{
                        return p.label != element.label && p.value != 1
                    })
                }
            });
        } else{
            this.userRoleListToHide = this.userRoleList.filter(p=>{
                return p.value != 1;
            })
        }
    }

    prepareImputParamFormSysAttr(){
        let hideUserRole  = []
        console.log(this.inputParam)
        if (this.inputParam.hideUserRole != undefined){
            this.inputParam.hideUserRole.split(',').forEach(element => {
                if (element != '')
                    var role = this.userRoleList.find(x => x.value.toString() == element)
                if (role != undefined)
                hideUserRole.push(role);                    
            });
            this.userRoleListToAssign = this.userRoleListToAssign.filter(p=>{
                return hideUserRole.find(x=>x.label != p.label);
            });
            this.userRoleListToAssign.sort((a, b) => a.label.toLocaleLowerCase().localeCompare(b.label.toLocaleLowerCase()));
            } else{
                this.userRoleListToAssign = this.userRoleList
            }
        this.inputParamFormFixedAttr = this.formBuilder.group({
            hideUserRole:[hideUserRole,[]]
        });
    }

    prepareInputParamForm() {        
        var paramValueChange = this.inputParam.paramValueChange == 'ACTIVE' ? true : false;
        var paramMandatory = this.inputParam.paramMandatory == 'ACTIVE' ? true : false;
        var backDataEditable = this.inputParam.backDataEditable == 'ACTIVE' ? true : false;
        var multipleTimeEditable = this.inputParam.multipleTimeEditable == 'ACTIVE' ? true : false;
        var userRole = [];
        var hideUserRole = [];
        var paramDefaultValue = '';
        var paramDefaultArray = [];
        var category = [];
        if (this.inputParam.paramDefaultValueDTO != undefined) {
            this.inputParam.paramDefaultValueDTO.forEach(element => {
                let paramValText = this.htmlDecode(element.paramValue);
                paramDefaultArray.push(paramValText);
            })
            paramDefaultValue = paramDefaultArray.toString();
        }

        if (this.inputParam.userRole != undefined){
            this.inputParam.userRole.split(',').forEach(element => {
                if (element != '')
                    var role = this.userRoleList.find(x => x.value.toString() == element)
                if (role != undefined)
                    userRole.push(role);                    
            });
           if(userRole.length > 0){
            this.userRoleListToHide = this.userRoleListToHide.filter(p=>{
                return userRole.find(x=>x.value != p.value);
            });
            this.userRoleListToHide.sort((a, b) => a.label.toLocaleLowerCase().localeCompare(b.label.toLocaleLowerCase()));
            }
            } else{
                this.userRoleListToHide = this.userRoleList.filter(p=>{
                    return p.value != 1;
                });
                this.userRoleListToHide.sort((a, b) => a.label.toLocaleLowerCase().localeCompare(b.label.toLocaleLowerCase()));
            }

            if (this.inputParam.hideUserRole != undefined){
            this.inputParam.hideUserRole.split(',').forEach(element => {
                if (element != '')
                    var role = this.userRoleList.find(x => x.value.toString() == element)
                if (role != undefined)
                hideUserRole.push(role);                    
            });
            this.userRoleListToAssign = this.userRoleListToAssign.filter(p=>{
                return hideUserRole.find(x=>x.label != p.label);
            });
            this.userRoleListToAssign.sort((a, b) => a.label.toLocaleLowerCase().localeCompare(b.label.toLocaleLowerCase()));
            } else{
                this.userRoleListToAssign = this.userRoleList
            }
            let inputParamCat
            if (this.inputParam.category){
            inputParamCat = this.inputParam.category.split(',');
            inputParamCat = inputParamCat.filter(p=>{
                return p != null;
            })
            inputParamCat.forEach(element => {
                let dynamicCategory = this.dynamicCategory.find(x => x.linkRewrite == element)
                if (dynamicCategory)
                category.push(dynamicCategory);                    
            })
        }

        this.inputParamForm = this.formBuilder.group({
            paramName: [this.inputParam.paramName, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100), Validators.pattern(this.global.AlphaNumPattern)])],
            paramCategory: [this.inputParam.paramCategory ? this.inputParam.paramCategory : "", Validators.required],
            paramDatatype: [this.inputParam.paramDatatype ? this.inputParam.paramDatatype : "", Validators.required],
            userRole: [userRole],
            hideUserRole: [hideUserRole],
            multipleTimeEditable: [multipleTimeEditable],
            backDataEditable: [backDataEditable],
            category : [category, Validators.required],
            paramValueChange: [paramValueChange, Validators.required],
            paramMandatory: [paramMandatory, Validators.required],
            paramDefaultValue: [paramDefaultValue],
            uploadDefaultValue:[null,[]],
            csv_file_name:[null,[]],
            file:[null]
        });
    }

    preparegoogleForm() {
        this.addform = this.formBuilder.group({
            googleformDetail: this.formBuilder.array(
                this.Id ? [] : [this.initgoogle()]
            )
        });
    }

    openModal(item: any, template: TemplateRef<any>,type?) {
        this.inputParam = item;
        this.modalRef = this.modalService.show(template, { backdrop: 'static' });
        if(type == 'sysAttr'){
            this.prepareImputParamFormSysAttr();
            return
        }
        this.prepareInputParamForm();
    }

    openTemplateModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template, { backdrop: 'static' });
    }

    mandatoryAction(event){
        let eventAction = event.target.checked;
        if(eventAction == false){
            this.inputParamForm.get('userRole').setValue([]);
        } 
    }

    getParams() {
        this.projectService.fetchAttributeList().then(data => {
            if (data) {
                this.fixedParams = this.projectService.filterAttributeList(['static']);
                this.inputParams = this.projectService.filterAttributeList();                
                this.fixedParams.sort((a, b) => a.paramName.toLocaleLowerCase().localeCompare(b.paramName.toLocaleLowerCase()));
                this.inputParams.sort((a, b) => a.paramName.toLocaleLowerCase().localeCompare(b.paramName.toLocaleLowerCase()));
                if (this.fixedParams) {
                    this.fixedParams.forEach(elems => {
                        this.showMapParams.push(elems);
                    })
                }
                if (this.inputParams) {
                    this.inputParams.forEach(element => {
                        if (element.pluginName != "static" && element.paramDatatype != 'File') {
                            this.showMapParams.push(element)
                        }
                    });
                }                
                              
            }
        });
    }

    entrFormName()
    {
        this.formNameOpener = true;
    }

    formCopySubmit(formName)
    {        
        if(formName == '')
        {
            this.toastr.error("Form name can't be empty");
            return;
        }

        let newFormData = {name:formName,formId:this.customFormData.id};
        this.customFormService.saveCustomForm(newFormData).subscribe(data=>{
            if(data.status)
            {
                this.formNameOpener = false;
                this.modalRef.hide();
                this.customFormService.getCustomFormsData('reload').then(data => {
                    if (data != undefined) {
                        this.customFormList = data;
                    }
                })
                this.toastr.success(data.data);
            }
            else
            {
                this.toastr.error(data.error_msgs);
            }
        })
    }

    deleteInputParams() {
        this.projectService.deleteInputParams({ id: this.inputParam.id }).subscribe(data => {
            if (data.status) {
                this.toastr.success(data.data);
                this.projectService.setAttributeData(undefined);
                this.getParams();
                this.modalRef.hide();
            }
            else {
                this.toastr.error(data.error_msgs, 'Error');
            }
        });
    }

    get if() { return this.inputParamForm.controls; }

    saveInputParams(type) {   
        console.log(this.inputParam)  
        if(type == 'sysAttr'){
            let hideUserRole;
            let hideUserRoleInput =  this.inputParamFormFixedAttr.value.hideUserRole;
            if(hideUserRoleInput && hideUserRoleInput.length > 0){
                hideUserRoleInput.forEach(element => {
                    hideUserRole = hideUserRole ? hideUserRole + ','+ element.value:element.value;
                });
            }
            let formDataForSysAttr: FormData = new FormData(); 
            formDataForSysAttr.append('id',this.inputParam.id.toString());
            formDataForSysAttr.append('paramOwner',this.inputParam.paramOwner);
            formDataForSysAttr.append('paramTypeValue',this.inputParam.paramType);
            if(hideUserRole){
                formDataForSysAttr.append('hideUserRole', hideUserRole);
            } else{
                formDataForSysAttr.append('hideUserRole', "");
            }
            this.projectService.updateInputParams(formDataForSysAttr).subscribe(data => {
                if (data.status) {
                    this.toastr.success(data.data);
                    this.projectService.setAttributeData(undefined);
                    this.getParams();
                    this.modalRef.hide();
                    this.selectfile = undefined
                } else {
                    this.toastr.error(data.error_msgs, 'Error');                    
                }
            });
            return
        }
        if (this.inputParamForm.controls.paramDatatype.value == 'File') {
            if (this.inputParamForm.controls.paramCategory.value == '0') {
                this.toastr.error('Please select label category');
                return
            }
        }

        if (this.inputParamForm.invalid) {
            this.toastr.error(this.global.MessageRequired); this.global.validateAllFormFields(this.inputParamForm);
            return;
        }
        if(this.inputParamForm.value.uploadDefaultValue == true && (this.inputParamForm.value.file == null || this.inputParamForm.value.file == "")){
            this.toastr.error('Please select file');
            return
        }

        var userRole = ''
        if (this.if.userRole.value != null && this.if.userRole.value.length > 0) {
            this.if.userRole.value.forEach(element => {
                userRole += element.value + ',';
            });
        }

        var hideUserRole = ''
        if (this.if.hideUserRole.value != null && this.if.hideUserRole.value.length > 0) {
            this.if.hideUserRole.value.forEach(element => {
                hideUserRole += element.value + ',';
            });
        }

        var category = ''
        if (this.if.category.value != null && this.if.category.value.length > 0) {
            this.if.category.value.forEach(element => {
                category += element.linkRewrite + ',';
            });
        }

        var paramDefaultValue = this.if.paramDefaultValue.value.split(',');
        var paramDefaultValueDTO;
        if (paramDefaultValue != undefined && paramDefaultValue.length) {
            paramDefaultValue.forEach(element => {
                if (element != ''){
                    // paramDefaultValueDTO.push(element.trim());
                    paramDefaultValueDTO = paramDefaultValueDTO ? paramDefaultValueDTO + ',' + element.trim() : element.trim()
                }
            });
        }
        let formData: FormData = new FormData(); 
        if(this.selectfile){
            formData.append("file", this.selectfile);
        }
        if(this.if.paramName.value){
            formData.append('paramName', this.if.paramName.value);
        }
        if(this.if.paramDatatype.value){
            if(this.if.paramDatatype.value != 'String' && this.if.paramDatatype.value != 'Numeric'){
                paramDefaultValueDTO = "";
            }
            formData.append('paramDatatype', this.if.paramDatatype.value);
        }
        if(this.if.paramCategory.value){
            formData.append('paramCategory', this.if.paramCategory.value);
        }
        if(userRole){
            formData.append('userRole', userRole);
        }
        if(hideUserRole){
            formData.append('hideUserRole', hideUserRole);
        }
        if(category != ''){
            formData.append('category', category);
        }
        if(paramDefaultValueDTO){
            formData.append('defaultValues', paramDefaultValueDTO);
        }
        
        formData.append('paramType', 'Optional');
        formData.append('paramTypeValue',this.inputParam.paramType);
        formData.append('paramValueChange', this.if.paramValueChange.value ? 'ACTIVE' : 'INACTIVE');
        formData.append('paramMandatory', this.if.paramMandatory.value ? 'ACTIVE' : 'INACTIVE');
        formData.append('backDataEditable', this.if.backDataEditable.value ? 'ACTIVE' : 'INACTIVE');
        formData.append('multipleTimeEditable', this.if.multipleTimeEditable.value ? 'ACTIVE' : 'INACTIVE');
        if (this.inputParam.id != undefined) {
            formData.append('id',this.inputParam.id.toString());
            formData.append('paramOwner','0');
            this.projectService.updateInputParams(formData).subscribe(data => {
                if (data.status) {
                    this.toastr.success(data.data);
                    this.projectService.setAttributeData(undefined);
                    this.getParams();
                    this.modalRef.hide();
                    this.selectfile = undefined
                } else {
                    this.toastr.error(data.error_msgs, 'Error');
                    this.inputParamForm.get('file').setValue("");
                    this.inputParamForm.get('csv_file_name').setValue("");
                    this.selectfile = undefined;
                    
                }
            });
        } else{
            this.projectService.upsertInputParams(formData).subscribe(data => {
                if (data.status) {
                    this.toastr.success(data.data);
                    this.projectService.setAttributeData(undefined);
                    this.getParams();
                    this.modalRef.hide();
                    this.selectfile = undefined
                } else {
                    this.toastr.error(data.error_msgs, 'Error');
                    this.inputParamForm.get('file').setValue("");
                    this.inputParamForm.get('csv_file_name').setValue("");
                    this.selectfile = undefined
                }
            });

        }

        
        
    }

    initgoogle() {
        return this.formBuilder.group({
            Cronberry: [],
            google: [],
        });
    }

    googleformDetail() {
        return (this.addform.get('googleformDetail') as any).FormArray;
    }

    getPluginData() {
        if (this.Plugindata != undefined)        
            return Object.keys(this.Plugindata);
    }

    fetchFormsMapping(plugin) {
        this.projectService.fetchFormsMapping({ pluginId: plugin.id }).subscribe(data => {
            if (data.status) {
                this.FormsMapping = data.data;                
                if (this.FormsMapping.length > 0) {
                    this.getParams();
                    this.modalRef = this.modalService.show(this.googleform, { backdrop: 'static' });
                } else {
                    this.fetchPluginsFormPushPull(plugin);
                }
            }
        });
    }

    formXlUpload(data)
    {
        this.router.navigate(['../../admin/bulk-upload'],{queryParams:{formName:data.formName}})
    }


    updateExcelFormStatus(formName,status) {        
        let fromStatus = status == false ? 'INACTIVE' : 'ACTIVE';
        this.excelFormDetail.status = fromStatus;
        this.projectService.updateExcelFormStatus({ formName: decodeURIComponent(formName), active: fromStatus }).subscribe(data => {
            if (data.status) {
                this.toastr.success(data.data);
                this.projectService.getUserSourceData('reload');
                this.fetchPluginFormXl();
            } else {
                this.toastr.error(data.error_msgs, 'Error');
            }
        });
    }

    fetchuserPlugins(reload?) {
        this.projectService.getPluginData(reload).then(data => {
            if (data != undefined)
                this.Plugindata = data;                            
            setTimeout(() => {
                this.activePageTab();
            }, 500)
        });
       
    }


    fetchPluginFormShopify() {
        this.projectService.getShopifyPagesData('reload').then(data => {
            if (data != undefined) {
                this.shopifyPageList = data;                
            }
        });
    }

    fetchPluginFormXl() {
        this.projectService.getExcelPagesData('reload').then(data => {
            if (data != undefined) {
                this.excelPageList = data;                
            }
        });
    }
    

    showDetailXlForm(pluginForm)
    {        
         this.addNewExcel = false;
        // this.excelFormDetail = pluginForm;
        // this.excelAlias = this.excelFormDetail.formName;
        // this.marketplaceStatus = this.excelFormDetail.status == 'ACTIVE' ? true : false;
        // this.modalRef = this.modalService.show(this.excelForm, { backdrop: 'static' });

        this.projectService.fetchXLPluginsFrom({ projectKey: this.projectService.projectDetails.projectKey, pluginId: pluginForm.id, formName: pluginForm.formName},{ }).subscribe(data=>{
            if(data.status)
            {
                this.excelFormDetail = data.data;
                this.excelAlias = this.excelFormDetail.formName;
                this.marketplaceStatus = this.excelFormDetail.status == 'ACTIVE' ? true : false;
                this.modalRef = this.modalService.show(this.excelForm, { backdrop: 'static' });
            }
            else
            {
                this.toastr.error(data.error_msgs);
                return;
            }
        })
        
    }


    fetchPluginsFrom(plugin, formName?) {                
        this.isMarketplaceCreate = false;
        this.marketplaceFormName = formName != undefined ? formName : null;
        if (this.marketplaceFormName == 'add') {
            this.marketplaceFormName = null;
            this.isMarketplaceCreate = true;
        }
        if(plugin.categoryName == 'IVR' && plugin.pluginType == "pull"){
            this.isMarketplaceCreate = true;
            this.marketplaceFormName = plugin.pluginName;
        }
        plugin.status = plugin.pluginStatus == 'ENABLE';
        plugin.leadActiveStatus = plugin.leadActive == 'ENABLE';
        plugin.updateLeadRepeatStatus = plugin.leadRepeatFlag == 'ENABLE';
        this.CurrentPlugin = plugin;
        if (plugin.id == 7) {
            this.modalRef = this.modalService.show(this.shopify, { backdrop: 'static' });
            return
        }
        if (this.CurrentPlugin.pluginType == "Forms") {
            this.fetchFormsMapping(plugin);
        } else {
            this.fetchPluginsFormPushPull(plugin);
        }
    }

    fetchPluginsFormPushPull(plugin) {
        if (plugin.pluginSub != 'ENABLE') {
            this.toastr.info('No Configuration available for ' + plugin.pluginName);
            return;
        }
        this.projectService.fetchPluginsFrom({ projectKey: this.projectService.projectDetails.projectKey, pluginId: plugin.id, formName: this.marketplaceFormName }, plugin.categoryName === "IVR" ? { pluginName: plugin.pluginName } : {}, plugin.cloudPluginUrl).subscribe(data => {
            if (data.status) {
                this.PluginForm = data.data;
                this.PluginForm.forEach(element => {
                    if (element.fieldType == "date") {
                        if (element.integrationPluginsFormsData.formFieldValue != null) {
                            element.integrationPluginsFormsData.formFieldValue = new Date(element.integrationPluginsFormsData.formFieldValue);
                        } else {
                            element.integrationPluginsFormsData.formFieldValue = new Date();
                        }
                    }
                });
                this.marketplaceStatus = this.PluginForm[0].integrationPluginsFormsData.pluginFormStatus == 'ACTIVE';
                this.modalRef = this.modalService.show(this.basicForm, { backdrop: 'static' });
                if (this.CurrentPlugin.pluginType == "Push") {
                    var pluginIndex = this.projectService.pluginData[this.CurrentPlugin.categoryName].indexOf(this.CurrentPlugin);
                    if (this.projectService.pluginData[this.CurrentPlugin.categoryName][pluginIndex].formDataMap.length == 0) {
                        this.marketplaceFormName = this.CurrentPlugin.pluginName;
                        this.projectService.pluginData[this.CurrentPlugin.categoryName][pluginIndex].formDataMap.push(this.marketplaceFormName);
                    }
                }
            }
            else {
                if (data.error_msgs != undefined && data.error_msgs != "") {
                    this.toastr.info(data.error_msgs);
                } else {
                    this.toastr.info('No Configuration available for ' + plugin.pluginName);
                }
            }
        });
    }

    saveFormsMapping() {
        var formValid = true;
        this.FormsMapping.forEach(element => {
            if (element.paramId == 0) {
                formValid = false;
            }
        });

        if (!formValid) {
            this.toastr.error(this.global.MessageRequired, 'Error');
            return;
        }

        var formData = this.FormsMapping.map(function (x) { return { paramId: x.paramId, mapValue: x.mapValue }; });

        var postData = {
            "pluginId": this.CurrentPlugin.id,
            "mappedValues": formData
        };
        this.projectService.saveFormsMapping(postData).subscribe(data => {
            if (data.status) {
                this.modalRef.hide();
            }
        });
    }

    savePluginFormData() {        
        if (this.CurrentPlugin.pluginType == "" || this.CurrentPlugin.pluginType == "Push" || this.CurrentPlugin.pluginType == "Forms") {
            this.modalRef.hide();
            return;
        }
        var formValid = true;
        this.PluginForm.forEach(element => {
            if (element.fieldRegex != null && element.fieldRegex.length > 0) {
                var regexp = new RegExp(element.fieldRegex);
                if (!regexp.test(element.integrationPluginsFormsData.formFieldValue)) {
                    formValid = false;
                }
            }
            if (element.integrationPluginsFormsData.formFieldValue == '') {
                formValid = false;
            }
        });
        if (this.CurrentPlugin.categoryName == 'Marketplace' && this.CurrentPlugin.multipleAdd == 'ENABLE') {
            if (this.marketplaceFormName == '' || this.marketplaceFormName == null) {
                formValid = false;
            }
        }

        if (!formValid) {
            this.toastr.error(this.global.MessageRequired, 'Error');
            return;
        }
        this.PluginForm.forEach(x => {
            if (x.fieldType == "date") {
                x.integrationPluginsFormsData.formFieldValue = this.datePipe.transform(x.integrationPluginsFormsData.formFieldValue, "yyyy-MM-dd");
            }
        });
        var formData = this.PluginForm.map(function (x) {
            return {
                formFieldId: x.id,
                formFieldValue: x.integrationPluginsFormsData.formFieldValue
            };
        });

        var postData: any;
        if (this.CurrentPlugin.categoryName == 'Marketplace' && this.CurrentPlugin.multipleAdd == 'ENABLE') {
            
            if(this.marketplaceFormName){
                let alphaNumWithChar  = /^[a-z A-Z0-9_/-]*$/g
                if(!alphaNumWithChar.test(this.marketplaceFormName)){
                    this.toastr.error('Form Name Should be Alphanumeric', 'Error');
                    return;
                }
            }
            
            postData = {
                "pluginId": this.CurrentPlugin.id,
                "formData": formData,
                'formName': this.marketplaceFormName,
                'newRecored': this.isMarketplaceCreate ? 1 : 0

            };
            this.pageType = this.CurrentPlugin.pluginName;
        } else {
            postData = {
                "pluginId": this.CurrentPlugin.id,
                "formData": formData,
                'newRecored': this.isMarketplaceCreate ? 1 : 0

            };
        }
        Object.assign(postData, {'projectKey': this.projectService.projectDetails.projectKey});       
        let header={};
        if(this.CurrentPlugin.categoryName == 'IVR'){
         header =  {pluginName:this.CurrentPlugin.pluginName};
         if(this.marketplaceFormName){
            let alphaNumWithChar  = /^[a-z A-Z0-9_/-]*$/g
            if(!alphaNumWithChar.test(this.marketplaceFormName)){
                this.toastr.error('Form Name Should be Alphanumeric', 'Error');
                return;
            }
        }
         Object.assign(postData, {'formName': this.marketplaceFormName});    
        } 
        this.projectService.savePluginsFormData(postData,header,this.CurrentPlugin.cloudPluginUrl).subscribe( data => {
            if (data.status) {
                this.modalRef.hide();
                if (this.isMarketplaceCreate) {
                    var pluginIndex = this.projectService.pluginData[this.CurrentPlugin.categoryName].indexOf(this.CurrentPlugin);
                    this.projectService.pluginData[this.CurrentPlugin.categoryName][pluginIndex].formDataMap.push(this.marketplaceFormName);
                    this.fetchuserPlugins('reload')
                }
                // this.Plugindata = this.projectService.getPluginData();
                this.toastr.success('Details saved successfully');
            } else {
                this.toastr.error(data.error_msgs, 'Error');
            }
        });
    }

    saveExcelPluginForm()
    {
        if(this.excelAlias == '')
        {
            this.toastr.error('Please fill form name');
            return
        }
        if(this.excelAlias)
        {
            let alphaNumWithChar  = /^[a-z A-Z0-9_/-]*$/g
            if(!alphaNumWithChar.test(this.excelAlias)){
                this.toastr.error('Form Name Should be Alphanumeric', 'Error');
                return;
            }
        }
        let header={};
        let postData = {formName:this.excelAlias}
        this.projectService.savePluginsFormData(postData,header,this.CurrentPlugin.cloudPluginUrl).subscribe( data => {
            if(data.status)
            {
                this.fetchPluginFormXl();
                this.toastr.success('Form added successfully');
                this.modalRef.hide();
            }
            else
            {
                this.toastr.error(data.error_msgs);
            }
        });
    }

    updatePluginStatus(formName?) {
        var active: string;
        if (formName != undefined) {
            active = this.marketplaceStatus ? "ACTIVE" : "INACTIVE";
        } else {
            active = this.CurrentPlugin.status ? "ACTIVE" : "INACTIVE";
        }
        var postData = {
            "pluginId": this.CurrentPlugin.id,
            "active": active,
            "formName": formName != undefined ? formName : null
        };

        this.CurrentPlugin.pluginStatus = this.CurrentPlugin.status ? "ENABLE" : "DISABLE";
        var type = this.CurrentPlugin.title.replace(' ', '').toLowerCase();
        this.projectService.updatePluginStatus(formName == undefined ? ApiConstants.API_URL + "project/" : this.CurrentPlugin.cloudPluginUrl, postData, type).subscribe(data => {
            if (data.status) {

            }
        });
    }


    updatePluginStatusByMS(formName?) {
        var active: string;
        if (formName != undefined) {
            active = this.marketplaceStatus ? "ACTIVE" : "INACTIVE";
        } else {
            active = this.CurrentPlugin.status ? "ACTIVE" : "INACTIVE";
        }
        var postData = {
            "active": active,
            "formName": formName,
        };

        this.CurrentPlugin.pluginStatus = this.CurrentPlugin.status ? "ENABLE" : "DISABLE";
        this.projectService.updatePluginStatusByMS(this.CurrentPlugin.cloudPluginUrl, postData, { pluginName: this.CurrentPlugin.pluginName }).subscribe(data => {
            if (data.status) {

            }
        });
    }

    updatePluginStatusByEcomm(formName?) {
        var active: string;
        active = this.CurrentPlugin.status ? "ACTIVE" : "INACTIVE";
        var postData = {
            "active": active,
            "formName": formName,
            "pluginId": this.CurrentPlugin.id,
        };

        this.CurrentPlugin.pluginStatus = this.CurrentPlugin.status ? "ENABLE" : "DISABLE";
        this.projectService.updatePluginStatusByEcomm(this.CurrentPlugin.cloudPluginUrl, postData,{pluginName:this.CurrentPlugin.pluginName}).subscribe(data => {
            if (data.status) {

            }
        });
    }

    updateFormStatusByMS(formName?) {
        var active: string;
        if (formName != undefined) {
            active = this.marketplaceStatus ? "ACTIVE" : "INACTIVE";
        }
        var postData = {
            "active": active,
            "formName": formName,
        };

        this.CurrentPlugin.pluginStatus = this.CurrentPlugin.status ? "ENABLE" : "DISABLE";
        this.projectService.updateFormStatusByMS(this.CurrentPlugin.cloudPluginUrl, postData, { pluginName: this.CurrentPlugin.pluginName }).subscribe(data => {
            if (data.status) {

            }
        });
    }





    updateLeadStatus(plugin) {        
        var postData = {
            "pluginId": plugin,
            "leadActive": this.formDetailData.leadActive == "ACTIVE" ? "INACTIVE" : "ACTIVE"
        };
        this.projectService.updateLeadActiveStatus(postData).subscribe(data => {
            if(data.status){
                this.toastr.success(data.data);
                this.confirmUpdateCategory = false;
                this.modalRef.hide();
            } else{
                this.toastr.error(data.error_msgs);
                this.confirmUpdateCategory = false;
                this.modalRef.hide();
            }
        });
    }

    copyText(val: string) {
        this.global.copyText(val);
        this.toastr.success(AppConstants.COPIED_CLIPBOARD);
    }

    isDisable(id) {
        return this.FormsMapping.find(x => x.paramId == id) != undefined;
    }

    getFacebookPages() {
        this.projectService.getFacebookPagesData('reload').then(data => {
            if (data != undefined) {
                this.facebookPageList = data;
                this.facebookPageListCache = data;

                let getActInact = JSON.parse(localStorage.getItem('activeInactive'));
                this.inactiveStore = getActInact != null ? getActInact : [];                
                if(this.inactiveStore.length > 0){
                let checkAvail = this.inactiveStore.find(x=>x.heading == 'Facebook');
                if(checkAvail){
                this.updateListByStatus = checkAvail.showInactive;
                } else if(checkAvail == undefined){
                this.updateListByStatus = false;
                } 

                this.facebookPageList = this.facebookPageListCache.filter(p=>{
                    if(this.updateListByStatus == false){
                    return p.status == 'ACTIVE';
                    } else{
                    return p.status == 'INACTIVE';
                    }
                    })
                } else{
                    this.facebookPageList = this.facebookPageListCache.filter(p=>{
                        return p.status == 'ACTIVE';
                        })
                }

                // this.facebookPageList = this.facebookPageList.filter(p=>{
                //     return p.status == 'ACTIVE';
                // })
                
            }
        });
    }

    getGoogleFormList(reload?) {
        this.projectService.getGoogleFormsData(reload).then(data => {
            if (data != undefined) {
                this.googleFormList = data;
                this.googleFormListCache = data;
                let getActInact = JSON.parse(localStorage.getItem('activeInactive'));
                this.inactiveStore = getActInact != null ? getActInact : [];
                if(this.inactiveStore.length > 0){
                let checkAvail = this.inactiveStore.find(x=>x.heading == 'Google Forms');
                if(checkAvail){
                this.updateListByStatus = checkAvail.showInactive;
                } else if(checkAvail == undefined){
                this.updateListByStatus = false;
                }                 
                this.googleFormList = this.googleFormListCache.filter(p=>{
                if(this.updateListByStatus == false){
                return p.status == 'ACTIVE';
                } else{
                return p.status == 'INACTIVE';
                }
                })
                } else{
                    this.googleFormList = this.googleFormListCache.filter(p=>{
                        return p.status == 'ACTIVE';
                        })
                }
            }
        })
    }

    

    getCustomFormList(reload?) {        
        this.customFormService.getCustomFormsData(reload).then(data => {
            if (data != undefined) {
                this.customFormList = data; 
                if(this.updateListByStatus == true){
                this.customFormList = this.customFormList.filter(p=>{
                    return p.status == 'INACTIVE';
                })
                }
                if(this.updateListByStatus == false || this.updateListByStatus == undefined){
                    this.customFormList = this.customFormList.filter(p=>{
                        return p.status == 'ACTIVE';
                })
                }
                
                this.customFormListCache = data;           
            }
        })
    }

    isPluginSubscribed(id, refresh = 0) {
        this.projectService.isPluginSubscribed({ pluginDetailId: id }).subscribe(data => {
            if (data.data) {
                if (id == 3) {
                    this.openModal({}, this.googleFormAdd);
                }
                if (id == 4 && refresh == 0) {
                    this.router.navigate(['/admin/manage-plugin-campaigns/facebook']);
                }
                if (id == 4 && refresh == 1) {
                    this.router.navigate(['/admin/manage-plugin-campaigns/facebookrefresh-token']);
                }
            } else {
                this.toastr.error(data.error_msgs, 'Error');
            }
        })
    }

    CreateGoogleForm() {
        var formValid = true;
        this.googleFormName = this.googleFormName.trim();
        if (this.googleFormName !== '' && this.googleFormName !== null) {
            formValid = false;
        }
        if (formValid) {
            this.toastr.error('Please fill all mandatory fields', 'Error');
            return;
        }
        if(this.googleFormName){
            let alphaNumWithChar  = /^[a-z A-Z0-9_/-]*$/g
            if(!alphaNumWithChar.test(this.googleFormName)){
                this.toastr.error('Form Name Should be Alphanumeric', 'Error');
                return;
            }
        }
        this.isGoogleFormButton = true;
        this.projectService.createGoogleForm({ formName: this.googleFormName }).subscribe(data => {
            this.isGoogleFormButton = false;
            if (data.status) {
                this.projectService.googleFormList = undefined;
                if (data.data.formKey != undefined && data.data.formKey != null) {
                    this.googleFormCreateData = data.data;
                    this.modalRef.hide();
                    this.modalRef = this.modalService.show(this.googleformMsg, { backdrop: 'static' });
                    this.googleFormName = '';
                } else {
                    this.toastr.error(data.data.description, 'Error');
                }

            } else {
                this.toastr.error(data.error_msgs, 'Error');
            }
        });
    }

    GetGoogleFormData(id,item) {        
        this.googleFormId = id;
        this.FormsMapping = [];
        this.googleMappingParams = [];
        this.inputParams.forEach(element => {
            if (element.paramEditable === 'ACTIVE' || element.pluginName === 'static')
                this.googleMappingParams.push(element)
        });

        this.projectService.fetchFormDetail({pluginId:item.pluginId}).subscribe(detailData=>{
            if(detailData.status){
            this.formDetailData = detailData.data;
            this.categorySelector = this.formDetailData.category;
            this.leadUpdateStatus = this.formDetailData.leadActive == 'ACTIVE' ? true : false;
            this.repeatLeadStatus = this.formDetailData.leadRepeatFlag == 'ACTIVE' ? true : false;
            } else{
                this.toastr.error(detailData.error_msgs);
                return
            }
        })

        this.projectService.getGoogleFormData({ googleFormId: id }).subscribe(data => {
            if (data.status) {
                this.googleFormItem = item ;
                this.activeInactiveStatus = this.googleFormItem.status == 'INACTIVE' ? false : true;
                if (data.data.formKey != undefined && data.data.formKey != null) {
                    this.googleFormCreateData = data.data;
                    this.modalRef = this.modalService.show(this.googleformMsg, { backdrop: 'static' });
                }
                if (data.data.length > 0) {
                    this.googleFormButton = 'Save';
                    data.data.forEach((element, i) => {
                        if (element.paramId > 0 && this.googleMappingParams.find(x => x.id === element.paramId) == undefined) {
                            this.googleMappingParams.push(this.inputParams.find(x => x.id === element.paramId))
                        }
                        this.FormsMapping[i] = {
                            paramId: element.paramId,
                            mapValue: element.mapValue
                        }
                    });
                    this.modalRef = this.modalService.show(this.googleform, { backdrop: 'static' });
                    if (this.FormsMapping[0].paramId > 0) {
                        this.googleFormButton = 'Update';

                        this.showMapParams.forEach(element => {
                            let checkAttr = this.FormsMapping.find(x=>x.paramId == element.id);
                            if(checkAttr){
                                element.disableAttr = true;
                            } else {
                                element.disableAttr = false;
                            }
                        });
                    }
                }
            } else {
                this.toastr.error(data.error_msgs, 'Error');
            }
        });
    }

    saveGoogleFormsMapping() {
        var formValid = true;
        this.FormsMapping.forEach(element => {
            if (element.paramId == 0) {
                formValid = false;
            }
        });
        if (!formValid) {
            this.toastr.error(this.global.MessageRequired, 'Error');
            return;
        }
        var postData = {
            "googleFormId": this.googleFormId,
            "mappedValues": this.FormsMapping
        };
        this.projectService.saveGoogleFormsMapping(postData).subscribe(data => {
            if (data.status) {
                this.modalRef.hide();
            }
        });
    }

    addFormExcel()
    {
        this.excelFormDetail = undefined;
        this.addNewExcel = true;
        this.marketplaceStatus = true;
        this.excelAlias = '';
        this.modalRef = this.modalService.show(this.excelForm, { backdrop: 'static' });
    }

    updateEcommFormStatus(status) {        
        let fromStatus = status == false ? 'INACTIVE' : 'ACTIVE';
       // this.excelFormDetail.status = fromStatus;
        this.projectService.updateEcommFormStatus({ formId:this.eCommerceFormId,status:fromStatus },{pluginName: this.eCommercePluginName}).subscribe(data => {
            if (data.status) {
                this.toastr.success(data.data);
                this.fetchPluginFormShopify()
            } else {
                this.toastr.error(data.error_msgs, 'Error');
            }
        });
    }


    openEcommerceForm(item,plugin){        
        this.eCommerceFormDetails = item        
        this.eCommerceFormId = item.formId;
        this.eCommercePluginName = plugin.pluginName;
        this.eCommerceFormName = item.formName;
        this.updateEcommerceForm = true;
        this.projectService.fetchEcommerceForms({formId:item.formId},{pluginName: this.eCommercePluginName}).subscribe(data=>{
            if(data.status){
                this.modalRef = this.modalService.show(this.ecommerceForm, { backdrop: 'static' });
                this.ecommerceFormFields = data.data;
                this.eCommerceFormDetailsStatus = this.ecommerceFormFields[0].status == 'ACTIVE' ? true : false;
            } else {
                this.toastr.error(data.error_msgs);
            }
        })

        // this.eCommerceFormData.setValue({
        //     formName:this.ecommerceFormFields.formName,
        //     store_url:this.ecommerceFormFields.store_url,
        //     shopify_access_token:this.ecommerceFormFields.shopify_access_token
        // });
    }

    addEcommerceForm(plugin){
        this.eCommerceFormDetails = ""
        //this.eCommerceFormDetails.status = true;
        this.updateEcommerceForm = false;
        this.eCommerceFormName = "";
        this.eCommercePluginName = plugin.pluginName;
        this.projectService.fetchEcommerceForms({},{pluginName: this.eCommercePluginName}).subscribe(data=>{
            if(data.status){
                this.ecommerceFormFields = data.data;
                this.modalRef = this.modalService.show(this.ecommerceForm, { backdrop: 'static' });
            } else {
                this.toastr.error(data.error_msgs);
            }
        })
        
    }

    deleteEcomForm(){
        this.modalRef.hide();
        this.modalRef = this.modalService.show(this.deleteEcommerceForm, { backdrop: 'static' });
        
    }

    deleteEcomFormYes(){
        this.projectService.delEcommerceForms({formId:this.eCommerceFormId},{pluginName: this.eCommercePluginName}).subscribe(data=>{
            if(data.status){
                this.toastr.success(data.data);
                this.modalRef.hide();
                this.fetchPluginFormShopify()
            } else{
                this.toastr.error(data.error_msgs);
            }
        })
    }

    eCommerceFormSubmitted(event,formType){
        if(event.formName){
            let alphaNumWithChar  = /^[a-z A-Z0-9_/-]*$/g
            if(!alphaNumWithChar.test(event.formName)){
                this.toastr.error('Form Name Should be Alphanumeric', 'Error');
                return;
            }
        }

        let formBody = {formName:event.formName,formData:[],formId:''}
        let formKeys = Object.keys(event);
        formKeys.forEach(elements => {
            this.ecommerceFormFields.forEach(element => {
                if(element.fieldName == elements){
                    formBody.formData.push({id:element.id,value:event[elements]})
                }
            });
        });

        for(let i=0; i<formBody.formData.length; i++){
            if(formBody.formData[i].value == ''){
                this.toastr.error('Please fill all fields');
                return
            }
        }
        if(formBody.formName == ''){
            this.toastr.error('Please fill all fields');
            return
        }

        if(formType == true){
            formBody.formId = this.eCommerceFormId
        } else{
            delete  formBody.formId
        }
        if (this.eCommerceFormDetails && this.eCommerceFormDetails != "") {

            this.projectService.updateEcommerceForms(formBody,{pluginName:this.eCommercePluginName}).subscribe(data=>{
                if(data.status){
                    this.fetchPluginFormShopify();
                    this.toastr.success(data.data)
                } else{
                    this.toastr.error(data.error_msgs);
                    this.fetchPluginFormShopify();
                }
            })

            return
            
        }
        
        this.projectService.saveEcommerceForms(formBody,{pluginName:this.eCommercePluginName}).subscribe(data=>{
            if(data.status){
                this.fetchPluginFormShopify();
                this.modalRef.hide();
                this.toastr.success(data.data)
            } else{
                this.toastr.error(data.error_msgs);
                this.fetchPluginFormShopify();
            }
        })
    }



    onTabSelect(event) {        
        let getActInact = JSON.parse(localStorage.getItem('activeInactive'));
        this.inactiveStore = getActInact != null ? getActInact : [];
        if(this.inactiveStore.length > 0){
            let checkAvail = this.inactiveStore.find(x=>x.heading == event.heading);
            if(checkAvail){
                this.updateListByStatus = checkAvail.showInactive;
            } else if(checkAvail == undefined){
                this.updateListByStatus = false;
            } 
        }
        var isfound = false;        
        if (event.heading == "Forms" || event.heading == "Social Media") {
            isfound = true;
            this.formsTabs.tabs[0].active = true;
            if(event.heading == "Forms"){
            if(this.inactiveStore.length > 0){
                let checkAvail = this.inactiveStore.find(x=>x.heading == this.formsTabs.tabs[0].heading);                
                if(checkAvail){
                    this.updateListByStatus = checkAvail.showInactive;
                } else if(checkAvail == undefined){
                    this.updateListByStatus = false;
                } 

                this.googleFormList = this.googleFormListCache.filter(p=>{
                    if(this.updateListByStatus == false){
                        return p.status == 'ACTIVE';
                    } else{
                        return p.status == 'INACTIVE';
                    }
                    
                })
            }
            }
            if(event.heading == "Social Media"){

                if(this.inactiveStore.length > 0){
                    let checkAvail = this.inactiveStore.find(x=>x.heading == this.socialMedia.tabs[0].heading);                    
                    if(checkAvail){
                        this.updateListByStatus = checkAvail.showInactive;
                    } else if(checkAvail == undefined){
                        this.updateListByStatus = false;
                    } 
                    this.facebookPageList = this.facebookPageListCache.filter(p=>{
                        if(this.updateListByStatus == false){
                            return p.status == 'ACTIVE';
                        } else{
                            return p.status == 'INACTIVE';
                        }
                        
                    })
                }
                
            }
            let plugin = this.Plugindata[event.heading][0];
            plugin.status = plugin.pluginStatus == 'ENABLE';
            plugin.leadActiveStatus = plugin.leadActive == 'ENABLE';
            plugin.leadRepeatFlagStatus = plugin.leadRepeatFlag == 'ENABLE';
            this.CurrentPlugin = plugin;
        };
        if (event.heading == 'Custom Forms') {
            isfound = true;
            let plugin = this.Plugindata['Forms'][1];
            plugin.status = plugin.pluginStatus == 'ENABLE';
            plugin.leadActiveStatus = plugin.leadActive == 'ENABLE';
            plugin.leadRepeatFlagStatus = plugin.leadRepeatFlag == 'ENABLE';
            this.CurrentPlugin = plugin;

            if(this.inactiveStore.length > 0){
                let checkAvail = this.inactiveStore.find(x=>x.heading == event.heading);                
                if(checkAvail){
                    this.updateListByStatus = checkAvail.showInactive;
                } else if(checkAvail == undefined){
                    this.updateListByStatus = false;
                } 
                this.customFormList = this.customFormListCache.filter(p=>{
                    if(this.updateListByStatus == false){
                        return p.status == 'ACTIVE';
                    } else{
                        return p.status == 'INACTIVE';
                    }
                    
                })
            }
            
        }
        if (event.heading == 'Google Forms') {
            isfound = true;
            let plugin = this.Plugindata['Forms'][0];
            plugin.status = plugin.pluginStatus == 'ENABLE';
            plugin.leadActiveStatus = plugin.leadActive == 'ENABLE';
            plugin.leadRepeatFlagStatus = plugin.leadRepeatFlag == 'ENABLE';
            this.CurrentPlugin = plugin;


            if(this.inactiveStore.length > 0){
                let checkAvail = this.inactiveStore.find(x=>x.heading == event.heading);                
                if(checkAvail){
                    this.updateListByStatus = checkAvail.showInactive;
                } else if(checkAvail == undefined){
                    this.updateListByStatus = false;
                } 
                this.googleFormList = this.googleFormListCache.filter(p=>{
                    if(this.updateListByStatus == false){
                        return p.status == 'ACTIVE';
                    } else{
                        return p.status == 'INACTIVE';
                    }
                })
            }
        }
        if (event.heading == "IVR" || event.id == "IVR") {
            isfound = true;
            let plugindata = this.Plugindata['IVR']
            let plugin = null;
            if (event.heading == "IVR") {
                this.ivrTabs.tabs[0].active = true;
                plugin = plugindata[0];
            } else {
                plugindata.forEach((element, i) => {
                    if (element.pluginName == event.heading) {
                        plugin = plugindata[i];
                    }
                });
            }
            plugin.status = plugin.pluginStatus == 'ENABLE';
            plugin.leadActiveStatus = plugin.leadActive == 'ENABLE';
            plugin.leadRepeatFlagStatus = plugin.leadRepeatFlag == 'ENABLE';
            this.CurrentPlugin = plugin;
        }
        if (event.heading == "marketplace" || event.id == 'Marketplace') {
            isfound = true;
            let plugindata = this.Plugindata['Marketplace']
            let plugin = null;
            if (event.heading == "Marketplace") {
                this.marketplaceTabs.tabs[0].active = true;
                plugin = plugindata[0];
            } else {
                plugindata.forEach((element, i) => {
                    if (element.pluginName == event.heading) {
                        plugin = plugindata[i];
                    }
                });
            }
            plugin.status = plugin.pluginStatus == 'ENABLE';
            plugin.leadActiveStatus = plugin.leadActive == 'ENABLE';
            plugin.leadRepeatFlagStatus = plugin.leadRepeatFlag == 'ENABLE';
            this.CurrentPlugin = plugin;
        }
        if (!isfound) {
            let plugin = this.Plugindata[event.heading][0];
            plugin.status = plugin.pluginStatus == 'ENABLE';
            plugin.leadActiveStatus = plugin.leadActive == 'ENABLE';
            plugin.leadRepeatFlagStatus = plugin.leadRepeatFlag == 'ENABLE';
            this.CurrentPlugin = plugin;
        }

        
        
    }

    updatePluginAction(event){ 
        let plugin = this.CurrentPlugin.title;   
        if(this.inactiveStore.length > 0){
            let checkAvail = this.inactiveStore.find(x=>x.heading == plugin);
            if(checkAvail == undefined){
                this.inactiveStore.push({heading:plugin,showInactive:this.updateListByStatus})
            } else{
                for(let i=0; i<this.inactiveStore.length; i++){
                    if(this.inactiveStore[i].heading == plugin){
                       delete this.inactiveStore[i]
                    }
                }
                this.inactiveStore.push({heading:plugin,showInactive:this.updateListByStatus});
                this.inactiveStore = this.inactiveStore.filter(p=>{
                    return p != null;
                })
            }
            } else{
                this.inactiveStore.push({heading:plugin,showInactive:this.updateListByStatus})
            }

            localStorage.setItem('activeInactive',JSON.stringify(this.inactiveStore));
        
        if(this.updateListByStatus == true)
        {
           if(plugin == "Custom Forms"){
            this.customFormList = this.customFormListCache.filter(p=>{
                return p.status == 'INACTIVE'
            })
           } else if(plugin == "Google Forms"){
            this.googleFormList = this.googleFormListCache.filter(p=>{
                return p.status == 'INACTIVE'
            })
           } else if(plugin == "Facebook"){
            this.facebookPageList = this.facebookPageListCache.filter(p=>{
                return p.status == 'INACTIVE'
            })
           }
        }
        if(this.updateListByStatus == false)
        {
           if(plugin == "Custom Forms"){
            this.customFormList = this.customFormListCache.filter(p=>{
                return p.status == 'ACTIVE'
            })
           } else if(plugin == "Google Forms"){            
            this.googleFormList = this.googleFormListCache.filter(p=>{
                return p.status == 'ACTIVE'
            })
           } else if(plugin == "Facebook"){
            this.facebookPageList = this.facebookPageListCache.filter(p=>{
                return p.status == 'ACTIVE'
            })
           }
        }
        
    }

    deleteGoogleForm() {
        this.projectService.deleteGoogleForm({ id: this.googleFormId }).subscribe(data => {
            this.modalRef.hide();
            if (data.status) {
                this.googleFormList.forEach((element, i) => {
                    if (element.id == this.googleFormId) {
                        this.googleFormList.splice(i, 1);
                    }
                });
                this.getGoogleFormList('reload');
            } else {
                this.toastr.error(data.error_msgs, 'Error');
            }
        });
    }

    goToCustomTab() {
        this.modalRef.hide();
        // this.pageTabs.tabs[1].active = true;
        this.pluginTabs.tabs[this.getPluginData().length].active = true;
    }

    getDynamicCategory() {        
        this.projectService.fetchCategory({}).then(data => {
            // let categoryes = data;
            if (data) {
                const dataList = data
                let id = this.authenticationService.getUserId();
                for(let i=0; i<dataList.length; i++){
                    if(dataList[i]){
                    if(this.authenticationService.getUserRole() != 1){
                    let getUserList = dataList[i].userList.find(userid=>userid == id);
                    if(getUserList){
                        this.dynamicCategory.push({ linkRewrite: dataList[i].linkRewrite, name: dataList[i].name })
                    }
                } else{
                    this.dynamicCategory.push({ linkRewrite: dataList[i].linkRewrite, name: dataList[i].name })
                }
                }
                }
                this.dynamicCategory = this.dynamicCategory.filter(p=>{
                    return p!= null
                });
            
            }
        })
    }

    fetchCategory() {        
        this.dataList = [];
        this.projectService.fetchCategory({}).then(data => {
            if (data) {
                const dataList = data
                let id = this.authenticationService.getUserId();
                if(this.authenticationService.getUserRole() != 1){
                for(let i=0; i<dataList.length; i++){
                    if(dataList[i]){
                    let getUserList = dataList[i].userList.find(userid=>userid == id);
                    if(getUserList){
                        this.dataList.push(dataList[i]);
                    }
                }
                }
                this.dataList = this.dataList.filter(p=>{
                    return p!= null
                });
                let checkForAudCat = this.dataList.find(x=>x.linkRewrite == 'audience');
                    if(checkForAudCat){
                        this.categorySelector='audience';
                    } else{
                        this.categorySelector=this.dataList[0].linkRewrite;
                    }
            } else{
                this.dataList = data;
                let checkForAudCat = this.dataList.find(x=>x.linkRewrite == 'audience');
                if(checkForAudCat){
                    this.categorySelector='audience';
                }
            }

            
            }
        })
    }

    showCustomFormModal(data: CustomFormObj) {
        this.activeInactiveStatus = data.status == 'INACTIVE' ? false : true;
        this.customFormData = data;
        this.customFormData.url = ApiConstants.CUSTOM_FORM_LINK + data.linkRewrite;
        this.customFormData.iframe = '<iframe src="' + ApiConstants.CUSTOM_FORM_LINK + data.linkRewrite + '" height="' + data.height + '" width="100%" title="' + data.name + '" style="border:none;"></iframe>'
        this.modalRef = this.modalService.show(this.customForm, {
            backdrop: 'static',
        });
    
    }

    updateFormCat(form){
    this.pluginId = form.pluginFormId;

    }

    resetUpdateField(){
        if(this.updateProp == 'active'){
            this.activeInactiveStatus = this.googleFormItem.status == 'ACTIVE' ? true : false;
        }

        if(this.updateProp == 'updateStatus'){
            this.leadUpdateStatus = this.formDetailData.leadActive == 'ACTIVE' ? true : false;
        }
        if(this.updateProp == 'repeatLead'){
            this.repeatLeadStatus = this.formDetailData.leadRepeatFlag == 'ACTIVE' ? true : false;
        }
        if(this.updateProp == 'categoryUpdate'){
            this.categorySelector = this.formDetailData.category;
        }
    }

    confirmUpdateGoogleForm(){

        

        if(this.updateProp == 'active'){
            this.updateGoogleFormStatus(this.googleFormItem.status)
            return;
        }

        if(this.updateProp == 'updateStatus'){
            this.updateLeadStatus(this.googleFormItem.pluginId);
            return;
        }
        if(this.updateProp == 'repeatLead'){
            this.updateRepeatLeadAssignemnt(this.googleFormItem.pluginId,this.formDetailData.leadRepeatFlag);
            return
        }

        if(this.updateProp == 'categoryUpdate'){
            let dataToSend = {pluginId:this.googleFormItem.pluginId,category:this.categorySelector}
            this.projectService.updateFormDetail(dataToSend).subscribe(data=>{
                if(data.status){
                    this.toastr.success('Success');
                    this.confirmUpdateCategory = false;
                } else{
                    this.toastr.error(data.error_msgs);
                    this.confirmUpdateCategory = false;
                }
            })
            return
        }

    }

    resetUpdatePlugin(){

        if(this.updateProp == 'updateStatus'){
            this.leadUpdateStatus = this.formDetailData.leadActive == 'ACTIVE' ? true : false;
        }
        if(this.updateProp == 'repeatLead'){
            this.repeatLeadStatus = this.formDetailData.leadRepeatFlag == 'ACTIVE' ? true : false;
        }
        if(this.updateProp == 'categoryUpdate'){
            this.categorySelector = this.formDetailData.category;
        }
    }


    confirmUpdatePlugin(){
        if(this.updateProp == 'updateStatus'){
            this.updateLeadStatus(this.getPluginId);
            return;
        }
        if(this.updateProp == 'repeatLead'){
            this.updateRepeatLeadAssignemnt(this.getPluginId,this.formDetailData.leadRepeatFlag);
            return
        }

        if(this.updateProp == 'active'){
            this.updateCustomFormStatus(this.customFormData.name,this.customFormData.status);
            return
        }

        if(this.updateProp == 'categoryUpdate'){
            let dataToSend = {pluginId:this.getPluginId,category:this.categorySelector}
            this.projectService.updateFormDetail(dataToSend).subscribe(data=>{
                if(data.status){
                this.dataStoreSubmit.formName = this.formDetailData.formName;
                this.dataStoreSubmit.id = this.formDetailData.id;
                this.dataStoreSubmit.isAssignAttribute = false;
                this.dataStoreSubmit.paramId = 0;
                this.dataStoreSubmit.paramMappingDTOs = [];
                this.dataStoreSubmit.userList = [];
                this.dataStoreSubmit.operator = "in";
                var type = this.CurrentPlugin.title.replace(' ', '').toLowerCase();
                this.userService.saveUserListForPlugin(this.dataStoreSubmit,type).subscribe ( data => {
                    if (data.status) {
                        this.toastr.success(data.data, 'Rules reset success');
                    } else {
                        this.toastr.error('Rules not reset', 'Error');
                    }
                })
                    this.toastr.success('Success');
                    this.confirmUpdateCategory = false;
                } else{
                    this.toastr.error(data.error_msgs);
                    this.confirmUpdateCategory = false;
                }
            })
            return
        }

    }


    confirmUpdateCat(){
        if(this.updateProp == 'active'){
            this.updateCustomFormStatus(this.customFormData.name,this.customFormData.status);
            return
        }
        if(this.updateProp == 'updateStatus'){
            this.updateLeadStatus(this.customFormData.pluginFormId);
            return;
        }
        if(this.updateProp == 'repeatLead'){
            this.updateRepeatLeadAssignemnt(this.customFormData.pluginFormId,this.formDetailData.leadRepeatFlag);
            return
        }

        if(this.updateProp == 'categoryUpdate'){            
            this.updateFormCat(this.customFormData);
            let dataToSend = {pluginId:this.pluginId,category:this.categorySelector}
            this.projectService.updateFormDetail(dataToSend).subscribe(data=>{
                if(data.status){
                    this.toastr.success('Success');
                    this.confirmUpdateCategory = false;
                } else{
                    this.toastr.error(data.error_msgs);
                    this.confirmUpdateCategory = false;
                }
            })
            return
        }
    }

    

    deleteCustomForm() {
        this.modalRef.hide();
        this.customFormService.deleteCustomForm({ id: this.customFormData.id }).subscribe(data => {
            if (data.status) {
                this.toastr.success(data.data, 'Success');
               // this.customFormService.customFormList = undefined;
                this.getCustomFormList('reload');
            } else {
                this.toastr.error(data.error_msgs, 'Error');
            }
        });
    }

    deletePluginFormData() {
        this.modalRef.hide();
        this.pageType = this.CurrentPlugin.pluginName;
          let header={};
        if(this.CurrentPlugin.categoryName == 'IVR')
        header =  {pluginName:this.CurrentPlugin.pluginName};
        this.projectService.deletepluginsFormData({pluginId:this.CurrentPlugin.id,formName:this.marketplaceFormName},header,this.CurrentPlugin.cloudPluginUrl).subscribe( data => {
            if (data.status) {
                this.fetchuserPlugins('reload')
                this.toastr.success(data.data, 'Success');
                var pluginIndex = this.projectService.pluginData[this.CurrentPlugin.categoryName].indexOf(this.CurrentPlugin);
                var formIndex = this.projectService.pluginData[this.CurrentPlugin.categoryName][pluginIndex].formDataMap.indexOf(this.marketplaceFormName);
                this.projectService.pluginData[this.CurrentPlugin.categoryName][pluginIndex].formDataMap.splice(formIndex, 1);
                // this.Plugindata = this.projectService.getPluginData();        
            } else {
                this.toastr.error(data.error_msgs, 'Error');
            }
        });
    }

    deleteConfirm()
    {
        this.delete_action = true;
    }

    deleteXlForm(formData)
    {
        this.projectService.deleteExcelFormData({formName:formData.formName}).subscribe(data=>{
            if(data.status)
            {
                this.toastr.success('Form removed successfully');
                this.fetchPluginFormXl();
                this.delete_action = false;
                this.modalRef.hide();
            }         
            else
            {
                this.toastr.error(data.error_msgs);
            }
        })
    }

    getSubUserList() {
        this.dataList.forEach(element => {
            if(element.linkRewrite == this.formDetailData.category){
                this.userService.getUsersData().then(data => {
                    if (data != undefined) {
                        this.subUserList = data;
                        if(element.userList && element.userList.length > 0){
                            this.subUserList = this.subUserList.filter(p=>{
                                return element.userList.find(x=>x == p.id);
                            })
                        }
                        if(this.subUserList.length > 0){
                            let checkSelfUser = this.subUserList.find(x=>x.id == this.authenticationService.getUserId());
                            if(checkSelfUser == undefined){
                            this.subUserList.push({
                                id:this.authenticationService.getUserId(),
                                userName:this.authenticationService.getUserData().userName,
                                userProfileName:this.authenticationService.getUserData().userName,
                                userRoleName:this.authenticationService.getUserData().userRoleName
                                })
                            }
                            this.subUserList.forEach(elems=>{
                                elems.userProfileName = elems.userProfileName+' ('+ elems.userRoleName +')'
                            })
                        }
                    }
                });
            }
        });
    }


    showConfigTools(pluginId,event){        
        this.projectService.fetchFormDetail({pluginId:pluginId}).subscribe(detailData=>{
            if(detailData.status){
            this.formDetailData = detailData.data;
            this.getSubUserList()
            this.getPluginId = pluginId;
            this.categorySelector = this.formDetailData.category;
            this.leadUpdateStatus = this.formDetailData.leadActive == 'ACTIVE' ? true : false;
            this.repeatLeadStatus = this.formDetailData.leadRepeatFlag == 'ACTIVE' ? true : false;
            if(event == 'leadAssign'){
                return
            } else{
                this.modalRef = this.modalService.show(this.configurationType, { class: '', backdrop: 'static' });
            }
            } else{
                this.toastr.error(detailData.error_msgs);
                return
            }
        })
    }


    showLeadStatusModal(item) {  
        this.uploadableSelected = false;      
        var type = this.CurrentPlugin.title.replace(' ', '').toLowerCase();
        this.userService.fetchUserListForPlugin({ id: this.CurrentPlugin.id, formName: item }, type).subscribe(data => {
            if (data.status) {
                this.leadUpdateValue = {
                    userList : [],
                    id : this.CurrentPlugin.id,
                    formName : item,
                    isAssignAttribute : data.data.paramId != undefined,
                    paramId : data.data.paramId != undefined ? data.data.paramId : 0,
                    paramMappingDTOs : [],
                    operator:data.data.operator ? data.data.operator : 'in'
                }
                var allUserList = []
                data.data.userList.forEach(element => {
                    if (this.subUserList.find(x => x.id === element)) {
                        allUserList.push(element);
                    }
                });
                allUserList.forEach(id => {
                    if (this.subUserList.find(x => x.id === id)) {
                        this.leadUpdateValue.userList.push(id);
                    } else {
                        this.leadUpdateValue.otherUserList.push(id);
                    }
                });
                
               // this.leadUpdateValue.userList = allUserList;

                if(this.leadUpdateValue.paramId != 0 ){
                    this.getAttributeValue(this.leadUpdateValue.paramId,data.data.paramMappingDTOs,this.leadUpdateValue.operator)
                }
                this.modalRef = this.modalService.show(this.leadUpdationByUser, { class: '', backdrop: 'static' });
            }
        })
    }


    confirmUpdate()
    {
        if(this.leadUpdateValue.isAssignAttribute == true){
            if(this.leadUpdateValue.paramMappingDTOs[0].paramValue == "" || this.leadUpdateValue.paramMappingDTOs[0].userList.length == 0){
                return
            }
        }
        this.updateConfirmModal = true;        
    }

    cancelUpdateLeadAssign()
    {
        this.updateConfirmModal = false;        
    }

    updateLeadStatusByUser(){
     
        var leadUpdateValue : UserAssignLeadbyPluginObj  = JSON.parse(JSON.stringify(this.leadUpdateValue));

        var type = this.CurrentPlugin.title.replace(' ', '').toLowerCase();
        var isValid = true;
        if( leadUpdateValue.isAssignAttribute ){
       
        leadUpdateValue.paramMappingDTOs.forEach( element => {

            if(element.otherUserList != undefined && element.otherUserList.length > 0){
                element.userList = element.userList.concat(element.otherUserList);
            }
            if(element.userList.length > 0){
                element.userList.forEach(user => {
                    leadUpdateValue.userList.push(user);  
                });
            }
            if(element.paramValue == '' || element.userList.length == 0){
              //  isValid = false;
            }
        });
        }else{
            leadUpdateValue.paramId=0;
            leadUpdateValue.paramMappingDTOs = [];
        }
        if (!isValid) {
            this.toastr.error(this.global.MessageRequired);
            return;
        }


        if(this.leadUpdateValue.otherUserList != undefined &&  this.leadUpdateValue.otherUserList.length > 0){
            leadUpdateValue.userList = leadUpdateValue.userList.concat(this.leadUpdateValue.otherUserList);
        }
        leadUpdateValue.userList = leadUpdateValue.userList.filter((el, i, a) => i === a.indexOf(el));
       
        this.dataStoreSubmit.formName = this.leadUpdateValue.formName;
        this.dataStoreSubmit.id = this.leadUpdateValue.id;
        this.dataStoreSubmit.isAssignAttribute = this.leadUpdateValue.isAssignAttribute;
        this.dataStoreSubmit.paramId = this.leadUpdateValue.paramId;
        this.dataStoreSubmit.paramMappingDTOs = this.leadUpdateValue.paramMappingDTOs;
        this.dataStoreSubmit.userList = this.leadUpdateValue.userList;
        this.dataStoreSubmit.operator = this.leadUpdateValue.operator;
        
        
        let jointer = [];
        let userListStore = [];
        this.dataStoreSubmit.paramMappingDTOs.forEach((elem,index,object)=>{
            
                if(elem.paramValue == "" && elem.userList.length == 0)
                {
                    object.splice(index, 1);
                }
                if(elem.paramValue)
                {                    
                    if(Array.isArray(elem.paramValue) == true)
                    {
                        elem.paramValue.forEach((element,index,object) => {

                        jointer.push(element.paramValue ? element.paramValue : element);
                        
                        elem.paramValue = jointer.join('|');
                        
                        if(index === object.length -1)
                        {                    
                            jointer = [];
                        }  
                        });
                    }

                    if(Array.isArray(elem.paramValue) == false)
                    {
                        if(elem.paramValue.includes("','"))
                        {
                            elem.paramValue = elem.paramValue.split("','").join('|');
                        }
                    }

                elem.userList.forEach(elems=>{
                    userListStore.push(elems)
                })
                }
            })

            leadUpdateValue.userList.forEach(elems=>{
                userListStore.push(elems)
            })
           
           let userList = this.uniqBy(userListStore,JSON.stringify);
           this.dataStoreSubmit.userList = userList;
           if( leadUpdateValue.isAssignAttribute == false)
           {
            this.dataStoreSubmit.paramId=0;
            this.dataStoreSubmit.paramMappingDTOs = [];
            this.dataStoreSubmit.userList = leadUpdateValue.userList;
           }
           if(this.dataStoreSubmit.isAssignAttribute == true)
           {
            
            if(this.dataStoreSubmit.paramMappingDTOs.length == 0)
            {                
                isValid = false;
            }
           }

           if(!isValid){
            this.toastr.error(this.global.MessageRequired);
            this.updateConfirmModal = false;
            this.addAttributeParam();
            return;
        }

           
        this.userService.saveUserListForPlugin(this.dataStoreSubmit,type).subscribe ( data => {
            this.modalRef.hide();
            if (data.status) {
                this.userService.subUserList = undefined;
                this.toastr.success(data.data, 'Success');
                this.paramMappingDTOsStores = [];
                this.operatorStore = undefined;
                this.updateConfirmModal = false;
            } else {
                this.toastr.error(data.error_msgs, 'Error');
                this.paramMappingDTOsStores = [];
                this.operatorStore = undefined;
                this.updateConfirmModal = false;
            }
        })
    }


    containValUpdate(event,param?)
    {
        
        let event_val = event.target.value;
        let comma_devide = event_val.split(',');
        if(param == 'betweenParam')
        {
            if(comma_devide.length == 2)
        {            
            if(event.code == 'Comma')
            {
                this.toastr.error('Only one comma allowed');
                event.preventDefault();
                return false;
            }
        }
        }
        if(comma_devide.length == 4)
        {            
            if(event.code == 'Comma')
            {
                this.toastr.error('Only four parameters allowed');
                event.preventDefault();
                return false;
            }
        }
    }

    uniqBy(a, key) {
        var seen = {};
        return a.filter(function(item) {
            var k = key(item);
            return seen.hasOwnProperty(k) ? false : (seen[k] = true);
        })
    }

    getFaceBookPageURL(page){
         return "/admin/manage-plugin-campaigns/facebook/"+encodeURIComponent(page);
    }

    uploadDefaultValues(event){
        this.selectfile = event.target.files[0];
        this.inputParamForm.get('file').setValue(this.selectfile);
    }

    fileFieldStatus(){
            this.inputParamForm.get('file').setValue("");
            this.inputParamForm.get('csv_file_name').setValue("");
    }

    onFileSelect(event) {
        if (event.target.files.length > 0) {
        //   if(event.target.files[0].size > 2000000){
        //     this.fileInput.nativeElement.value = '';
        //     this.toastr.error('Maximum file size should be 2 MB.')
        //     return;
        //   }
          this.file = event.target.files[0];
          this.attributeCsvUpload.get('file').setValue(this.file);
        }
      }

      checkLeadAssignByAttr(event){
        event.userList = [];
        event.paramMappingDTOs = [];
        event.paramId = 0;
        event.operator = 'IN'
        this.uploadableSelected = false;
        // if(event == false){
        //     if(this.uploadableSelected == true){
        //         this.uploadableSelected = false;
        //     }
        // }
      }

      downloadDataCsv(){
        let formObj = 'id='+this.leadUpdateValue.id+'&formName='+this.leadUpdateValue.formName+'&paramId='+this.leadUpdateValue.paramId;
        this.projectService.downloadCsvData(formObj).subscribe(data=>{
            if(data.headers){           
            let fileNameByTime = new Date().getTime();
            let attachmentName = fileNameByTime+'.csv';
            var link = document.createElement('a');
            link.href = URL.createObjectURL(data.body);
            //link.download = attachmentName;
            //attachmentName = attachmentName.replaceAll('"','')
            link.setAttribute('download',attachmentName)
            link.click();
            // if(contentDisposition){
                this.toastr.success(attachmentName + ' Download Success');

            //}
           // const href = URL.createObjectURL(data);
           // window.open(href, "_top");
            }
        })
      }

      saveAttributeCsv(form: any) {
        if (!this.attributeCsvUpload.controls.file.value) {
          this.toastr.error('Please Select CSV');
          return;
        }
        this.spinner.show();
        let formData: FormData = new FormData(); 
        let formId = this.leadUpdateValue.id
        let formIdAsString = formId.toString()
        formData.append("file", this.file);
        formData.append('formName',this.leadUpdateValue.formName);
        formData.append('id',formIdAsString);
        formData.append('isAssignAttribute',this.leadUpdateValue.isAssignAttribute);
        formData.append('paramId',this.leadUpdateValue.paramId);
        formData.append('operator',this.leadUpdateValue.operator);
        
        this.projectService.uploadAttributeDataCsv(formData).subscribe(data => {
          this.spinner.hide();
          if (data.status) {
           this.toastr.success(data.data);
           this.modalRef.hide();
           this.attributeCsvUpload.controls.file.setValue('');
           this.file = '';
           this.attributeCsvUpload.get('file').setValue('');
           //this.fileInput.nativeElement.value = '';
          }
          else {            
            this.toastr.error(data.error_msgs);
            this.attributeCsvUpload.get('file').setValue('');
            this.attributeCsvUpload.get('file_name').setValue('');          
          }
        })
    
      }

    getAttributeValue(event, paramMapping?,operator?){
        this.paramMappingDTOsStores = []
        this.catDataLoad = false;
        let checkUploadable;
        if(event.target){
            checkUploadable = event.target.value;
        } else {
            checkUploadable = event
        }
        let checkParamVal = this.inputParams.find(x=>x.paramKey == checkUploadable);
        if(checkParamVal){            
        if(checkParamVal.isUploadable && checkParamVal.isUploadable == 1){
            this.uploadableSelected = true
        } else
        {
            this.uploadableSelected = false;
        }
        }
        let eventPass = false;
        let paramId
        if(event.target)
        {
            eventPass = true;
            paramId = event.target.value;
        }
        else
        {
            paramId = event;
            this.leadUpdateValue.paramId = paramId;
        }        
        this.attributeParamValues = [];
        this.postDataFilter.values = []
        var paramObj = JSON.parse(JSON.stringify(this.inputParams.find(x => x.paramKey == paramId)));        
        if(paramObj != undefined){
            paramId = paramObj.pluginName == 'dynamic' ? 'params.'+paramId  : paramId;
        }
        if(!this.uploadableSelected){
        if(paramId == 'params.telecaller_name'){
            this.catDataLoad = true;
            let existingUsers = []
      this.projectService.getUserFromMaster().then(data => {
        if (data != undefined) {
            let userData = Object.keys(data);
            userData.forEach(elem=>{
                if(data[elem].status == 'ACTIVE'){
                    existingUsers.push({'paramKey':'params.telecaller_name','paramValue':data[elem].userName});
            }
            });

            if(existingUsers){
                this.postDataFilter.values = [];
                this.postDataFilter.values = existingUsers
                this.attributeParamValues = existingUsers
              
                this.inputParams.forEach((item, index, object)=>{
                    if(item.paramDatatype == 'Date' || item.paramDatatype == 'date' || item.paramDatatype == 'boolean' ||  item.paramDatatype == 'Boolean')
                    {
                        object.splice(index,1);
                    }
                });
                
                
                this.paramObjCheckOperator = paramObj            
                if(paramObj.paramDefaultValueDTO != undefined){
                    this.attributeParamValues = paramObj.paramDefaultValueDTO;
                } else{
                    if(paramObj.paramDatatype == 'Numeric' || paramObj.paramDatatype == 'numeric'){
                        if(eventPass == true){
                        this.attributeParamValues.push({paramKey:paramId,paramValue:""});
                        this.leadUpdateValue.paramMappingDTOs.push({paramValue:"",userList:[]});
                        this.leadUpdateValue.operator = 'GREATER_THEN_EQUAL';
                        }                                                            
                    }
                }
                this.loadAutoComplete(this.postDataFilter);
    
                
                if(paramMapping != undefined){                
                    this.paramMappingDTOsStores = paramMapping;
                    this.leadUpdateValue.paramMappingDTOs = paramMapping;
                    paramMapping.forEach(element => {
                        this.attributeParamValues.push({paramKey:paramId,paramValue:element.paramValue});
                        if(operator != 'contain'){
                        this.operatorStore = operator;
                        element.paramValue = element.paramValue.split('|');
                        }
    
                        if(this.attributeParamValues.find(x => x.paramValue == element.paramValue) == undefined)
                        if (this.authenticationService.getUserRole() > 1 && (this.authenticationService.getUserRoleType() == 1)) {
                            element.otherUserList = [];
                            element.userList.forEach((id, i) => {
                                if (this.subUserList.find(x => x.id === id) == undefined)
                                element.otherUserList.push(id);
                            });
                        }
                    });
                } else {
                    this.leadUpdateValue.paramMappingDTOs = []
                }
                
                if(this.attributeParamValues.length > 0){
                  
                    this.attributeParamValues = this.attributeParamValues.filter((thing, index, self) =>
                        index === self.findIndex((t) => (
                            t.paramValue.trim().toLowerCase() === thing.paramValue.trim().toLowerCase()
                        ))
                    )
                    this.addAttributeParam();
                }    

                
            }

        }
    });
      return

        }

            if(paramId == 'params.lead_status'){
                this.catDataLoad = true;
                if(this.dataList.length > 0 && this.formDetailData){
                    this.dataList.forEach(element => {
                        if(element.linkRewrite == this.formDetailData.category){
                            element.leadStatusList.forEach(status => {
                                this.attributeParamValues.push({paramKey:"params.lead_status",paramValue:status.stageName});   
                                this.postDataFilter.values.push({paramKey:"params.lead_status",paramValue:status.stageName});
                            });
                        }
                    });
                    this.inputParams.forEach((item, index, object)=>{
                        if(item.paramDatatype == 'Date' || item.paramDatatype == 'date' || item.paramDatatype == 'boolean' ||  item.paramDatatype == 'Boolean')
                        {
                            object.splice(index,1);
                        }
                    });
                    
                    
                    this.paramObjCheckOperator = paramObj            
                    if(paramObj.paramDefaultValueDTO != undefined){
                        this.attributeParamValues = paramObj.paramDefaultValueDTO;
                    } else{
                        if(paramObj.paramDatatype == 'Numeric' || paramObj.paramDatatype == 'numeric'){
                            if(eventPass == true){
                            this.attributeParamValues.push({paramKey:paramId,paramValue:""});
                            this.leadUpdateValue.paramMappingDTOs.push({paramValue:"",userList:[]});
                            this.leadUpdateValue.operator = 'GREATER_THEN_EQUAL';
                            }                                                            
                        }
                    }
                    this.loadAutoComplete(this.postDataFilter);
        
                    
                    if(paramMapping != undefined){                
                        this.paramMappingDTOsStores = paramMapping;
                        this.leadUpdateValue.paramMappingDTOs = paramMapping;
                        paramMapping.forEach(element => {
                            this.attributeParamValues.push({paramKey:paramId,paramValue:element.paramValue});
                            if(operator != 'contain'){
                            this.operatorStore = operator;
                            element.paramValue = element.paramValue.split('|');
                            }
        
                            if(this.attributeParamValues.find(x => x.paramValue == element.paramValue) == undefined)
                            if (this.authenticationService.getUserRole() > 1 && (this.authenticationService.getUserRoleType() == 1)) {
                                element.otherUserList = [];
                                element.userList.forEach((id, i) => {
                                    if (this.subUserList.find(x => x.id === id) == undefined)
                                    element.otherUserList.push(id);
                                });
                            }
                        });
                    } else {
                        this.leadUpdateValue.paramMappingDTOs = []
                    }
                    
                    if(this.attributeParamValues.length > 0){
                      
                        this.attributeParamValues = this.attributeParamValues.filter((thing, index, self) =>
                            index === self.findIndex((t) => (
                                t.paramValue.trim().toLowerCase() === thing.paramValue.trim().toLowerCase()
                            ))
                        )
                        this.addAttributeParam();
                    }

                    
                }
                return
            }
         this.campaignService.searchParamData({ paramKey: paramId ,paramValue:'' }).subscribe(data => {
            if (data.status) {
                data.data.forEach(element => {
                    element.paramValue = element.paramValue.length > 50 ? element.paramValue.slice(0, 50) + '...' : element.paramValue;
                    this.attributeParamValues.push(element);
                    this.postDataFilter.values.push(element);                     
                });                
            } else {
                this.attributeParamValues = [];
            }
            this.inputParams.forEach((item, index, object)=>{
                if(item.paramDatatype == 'Date' || item.paramDatatype == 'date' || item.paramDatatype == 'boolean' ||  item.paramDatatype == 'Boolean')
                {
                    object.splice(index,1);
                }
            });
            
            
            this.paramObjCheckOperator = paramObj            
            if(paramObj.paramDefaultValueDTO != undefined){
                this.attributeParamValues = paramObj.paramDefaultValueDTO;
            } else{
                if(paramObj.paramDatatype == 'Numeric' || paramObj.paramDatatype == 'numeric'){
                    if(eventPass == true){

                        for(let i=0; i<this.attributeParamValues.length;i++){
                            if(this.attributeParamValues[i].paramValue == ""){
                                delete this.attributeParamValues[i];
                            }
                        }
                        this.attributeParamValues = this.attributeParamValues.filter(p=>{
                            return p!= null
                        });
        
                        for(let i=0; i<this.leadUpdateValue.paramMappingDTOs.length;i++){
                            if(this.leadUpdateValue.paramMappingDTOs[i].userList.length == 0 || this.leadUpdateValue.paramMappingDTOs[i].paramValue == ""){
                                delete this.leadUpdateValue.paramMappingDTOs[i];
                            }
                        }
    
                        this.leadUpdateValue.paramMappingDTOs = this.leadUpdateValue.paramMappingDTOs.filter(p=>{
                            return p != null;
                        })

                    this.attributeParamValues.push({paramKey:paramId,paramValue:""});
                    this.leadUpdateValue.paramMappingDTOs.push({paramValue:"",userList:[]});
                    this.leadUpdateValue.operator = 'GREATER_THEN_EQUAL';
                    }                                                            
                }
            }
            this.loadAutoComplete(this.postDataFilter);

            
            if(paramMapping != undefined){                
                this.paramMappingDTOsStores = paramMapping;
                this.leadUpdateValue.paramMappingDTOs = paramMapping;
                paramMapping.forEach(element => {
                    this.attributeParamValues.push({paramKey:paramId,paramValue:element.paramValue});
                    if(operator != 'contain'){
                    this.operatorStore = operator;
                    element.paramValue = element.paramValue.split('|');
                    }

                    if(this.attributeParamValues.find(x => x.paramValue == element.paramValue) == undefined)
                    if (this.authenticationService.getUserRole() > 1 && (this.authenticationService.getUserRoleType() == 1)) {
                        element.otherUserList = [];
                        element.userList.forEach((id, i) => {
                            if (this.subUserList.find(x => x.id === id) == undefined)
                            element.otherUserList.push(id);
                        });
                    }
                });
            } else {
                this.leadUpdateValue.paramMappingDTOs = []
            }
            
            if(this.attributeParamValues.length > 0){
              
                this.attributeParamValues = this.attributeParamValues.filter((thing, index, self) =>
                    index === self.findIndex((t) => (
                        t.paramValue.trim().toLowerCase() === thing.paramValue.trim().toLowerCase()
                    ))
                )
                this.addAttributeParam();
            }
        });        
        } else{
                this.paramObjCheckOperator.paramDatatype = checkParamVal.paramDatatype
        }
    }

    operatorChange(event)
    {
        if(this.leadUpdateValue.operator == this.operatorStore){            
            if(this.paramMappingDTOsStores.length > 0){
            this.leadUpdateValue.paramMappingDTOs = this.paramMappingDTOsStores;
            }
            if(this.paramMappingDTOsStores.length == 0){
                this.leadUpdateValue.paramMappingDTOs = [];
                this.addAttributeParam();
            }
        }
        else{
            if(this.leadUpdateValue.paramMappingDTOs.length > 0){
            this.leadUpdateValue.paramMappingDTOs = [];
            }
            this.addAttributeParam();
        }
    }

    addAttributeParam(add?){
        if(add == 'add'){
            for(let i=0; i<this.leadUpdateValue.paramMappingDTOs.length;i++){
                if(this.leadUpdateValue.paramMappingDTOs[i].paramValue == "" || this.leadUpdateValue.paramMappingDTOs[i].userList.length == 0){
                    return
                }
            }
            this.leadUpdateValue.paramMappingDTOs.push({paramValue:'',userList:[]})
        } else if(add == undefined){
            this.leadUpdateValue.paramMappingDTOs.push({paramValue:'',userList:[]})
        }
        
    }

    getShortUrl(url) {
        this.urlShortnerService.getUrlShortner(url).then(data => {
            if (data != undefined) {
                this.global.copyText(data);
                this.toastr.success('Copied to clipboard');
            }
        })
    }

    isOptionDisable(value) {
        return this.leadUpdateValue.paramMappingDTOs.find(x => x.paramValue == value);
    }

    saveRepeatLeadPreference() {

        if (!this.lead_email_atrribute && !this.lead_mobile_atrribute) {
            return this.toastr.error("Please select columns for identify repeat lead");
        }
        let columns = [];
        if (this.lead_email_atrribute) {
            columns.push('EMAIL');
        }
        if (this.lead_mobile_atrribute) {
            columns.push('MOBILE');
        }
        let postData = { 'repeatColumn': columns.join() };
        this.authenticationService.projectDetails.repeatColumn = columns.join();
        this.projectService.updateRepeatLeadPreferences(postData).subscribe(data => {
           if(data.status){
            this.toastr.success("Preferences stored successfully");
           }
        }, (error) => {
            console.log(error);
        })

    }


    updateRepeatLeadAssignemnt(plugin,leadRepeatFlag){
            var postData = {
                "pluginId": plugin,
                "leadRepeatFlag": leadRepeatFlag == "ACTIVE" ? "INACTIVE" : "ACTIVE"
            };
            // var type = this.CurrentPlugin.title.replace(' ', '').toLowerCase();
            // this.CurrentPlugin.leadRepeatFlag = this.CurrentPlugin.leadRepeatFlagStatus ? "ENABLE" : "DISABLE";
            this.projectService.updateLeadRepeatStatus(postData).subscribe(data => {
                if(data.status){
                    this.toastr.success(data.data);
                    this.modalRef.hide();
                    this.confirmUpdateCategory = false;
                } else{
                    this.toastr.error(data.error_msgs);
                    this.modalRef.hide();
                    this.confirmUpdateCategory = false;
                }
            });
        
    }


updateCustomFormStatus(formName,status) { 
    
    let fromStatus = status == 'INACTIVE' ? 'ACTIVE' : 'INACTIVE';
    this.projectService.updateCustomFormStatus({ name: decodeURIComponent(formName), status: fromStatus }).subscribe(data => {
        if (data.status) {
            this.toastr.success(data.data);
            this.modalRef.hide();
            this.projectService.getUserSourceData('reload');
            this.getCustomFormList('reload');
            this.confirmUpdateCategory = false;
           // this.fetchPluginFormXl();
        } else {
            this.toastr.error(data.error_msgs, 'Error');
            this.modalRef.hide();
            this.getCustomFormList('reload');
            this.confirmUpdateCategory = false;
        }
    });
}

updateGoogleFormStatus(status){

    let fromStatus = status == 'INACTIVE' ? 'ACTIVE' : 'INACTIVE';
    this.projectService.updateGoogleFormStatus({ googleFormId:this.googleFormId, status: fromStatus }).subscribe(data => {
        if (data.status) {
            this.toastr.success(data.data);
            this.modalRef.hide();
            this.projectService.getUserSourceData('reload');
            this.getGoogleFormList('reload');
            this.confirmUpdateCategory = false;
           // this.fetchPluginFormXl();
        } else {
            this.toastr.error(data.error_msgs, 'Error');
            this.modalRef.hide();
            this.getGoogleFormList('reload');
            this.confirmUpdateCategory = false;
        }
    });

}

}



