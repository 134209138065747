export class field{
  _id?:any;
  name?:any;
  sort?:any;
  type?:any;
  icon?:any;
  toggle?:any;
  required?:any;
  regex?:any;
  errorText?:any;
  label?:any;
  description?:any;
  placeholder?:any;
  className?:any;
  subtype?:any;
  handle?:any;
  ngName?:any;
  min?:number;
  max?:number;
  inline?:any;
  value?:any;
  values?:Array<value>;
  bgColor?:any;
  textColor?:any;
  fileType?:any;
  bgHoverColor?:any;
  btnSize?:any;
  btnWidth?:any;
  hint?:any;
  defaultHint?:string;
  parent?:boolean;
  valueIndex?:number;
  parentAttributeName?:any;
  parentAttributeValue?: Array<ParentValue>;
  logic?:LogicCondition;
  link?:string;
  linkText?:string;
  fieldType?:any
  
}

export class value{
  label?:any="";
  value?:any="";
}

export class ParentValue{
  label?:string="";
  values?:Array<value>;
}

export interface CustomFormObj {
  id:number;
  name:string;	
  json:string;
  html:string;
  isPublic:number;
  height:number;
  status?: string;
  linkRewrite?: string;
  iframe?: string;
  expireTime?:number;
  url?:string;
  startDate?:string;
  endDate?:string;
  pluginFormId?:any;
}

export class Theme{
  bgColor?:string="#ffffff";
  textColor:string="#333333";
  bannerImage:string="";
  backgroundImage:string="";
  fontFamily:string="Roboto";
  borderColor:string="#CED4DA";
  borderRadius:string="3";
  fieldBgColor:string="#FFFFFF";
  fieldTextColor:string="#333333";
  fontSize:string="14px";
  btnHoverColor:string='#1862b0';
}

export class FormModel{
 
  stepName?:string = "";
  description?:string = "";
  theme: Theme=new Theme();
  attributes:Array<field>=[];
  logic?:LogicCondition=new LogicCondition();
}

export class DataModel{
  name?:string="";
  multiStep:Array<FormModel> =[];
  successMessage?:string="Thank you very much for your time, we have noted your request and our concerned team will call you soon !!";
  expireTime=0;
  startDate:Date=new Date();
  startDateTime:Date=new Date();
  endDate:Date;
  endDateTime:Date=new Date();
  stepUI?:string="wizard";
  userStyle?:string="";
  userJavascript?:string="";
  redirectUrl?:string="";
  bannerHeight?:string="";
  bannerWidth?:string="";
  bannerAlign?:string="";
  formHook=0;
  isPublic=1;
  captcha=0;
  button:field= {
    type: "button",
    icon: "form-paper-plane.svg",
    label: "Submit",
    bgColor: "#007BFD",
    textColor: "#FFFFFF",
    className: 'right',
    btnSize: 'btn-md',
    btnWidth: 'btn',
    bgHoverColor:'#1862b0',
};
location:field= {
  type: "button",
  label: "Location",
  icon:"location.svg"
};
linkTextColor?:any = '#000';
stepsActiveColor:any="#cd0141";
stepsDefaultColor:any="#f4f7f9";
bodyBackground?:string="";
bodybackgroundImage?:any="";
allInOneBannerImage?:any="";
sendData:any='no';
}

export class Condition{
  field:string;
  operator:any;
  value:any;
}

export  class LogicCondition{
  conditions:Array<Condition|String>=[];
  outcome:string="";
}
