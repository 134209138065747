import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { SourceObj } from '../core/modal/project';
import { ProjectService } from '../core/services/project.service';
import { ReportService } from '../core/services/report.service';
import { Global } from '../shared/models/global.model';
import { noWhitespaceValidator } from '../shared/models/no-whitespace-validator';

@Component({
  selector: 'app-create-report-graph',
  templateUrl: './create-report-graph.component.html',
  styleUrls: ['./create-report-graph.component.scss']
})
export class CreateReportGraphComponent implements OnInit {
  public siteForm: FormGroup;
  public formBuilder:FormBuilder;
  isSubmited:boolean = false;
  @Input() modalRef: BsModalRef;
  @Input() viewAudiencePage:any;
  @Input() callNext:any;
  @Input() paramKey:any;
  @Input() refilEditVal:any;
  @Output() recordUpdateAdded : any = new EventEmitter();
  @Input() addAction:boolean;
  @Input() categoryName:any;
  inputParams:any = new Array();
  entryDateId:any;
  sourceShow:boolean = false;
  fetchUser:any;
  checkedList:any;
  getSource:any;
  yaxisType:any;
  filterJson:any;
  pluginFormList:any = new Array();
  public sourseList: SourceObj[] = [];
  queryParamsFromMenu:any;

  constructor(
    private toastr:ToastrService, 
    private reportService:ReportService,
    private projectService:ProjectService,
    private global:Global,
    private route:ActivatedRoute,
    private router:Router) { 

      this.getAttributeData(); 

      this.siteForm = new FormGroup ({
        reportName: new FormControl('', Validators.compose([Validators.required, noWhitespaceValidator, Validators.pattern(this.global.AlphaNumPatternWithChar)])),
        source: new FormControl('',Validators.compose([])),
        reportColumnId: new FormControl('',Validators.compose([Validators.required])),
        yaxisColumnId:new FormControl('',Validators.compose([])),
        eventTimeId:new FormControl('',Validators.compose([Validators.required])),
        reportType:new FormControl('',Validators.compose([Validators.required])),
        ycolumnSum:new FormControl('',Validators.compose([])),
        showToDashboard:new FormControl(false,Validators.compose([])),
        defaultSort:new FormControl('default',Validators.compose([])),
        showDataGreater:new FormControl('0',Validators.compose([])),
        id:new FormControl(null,Validators.compose([null])),
        pluginForms:new FormControl(null,Validators.compose([null]))
    });
    
  }


  ngOnInit() {
    
    this.route.paramMap.subscribe(data=>{
            if(data){
                this.queryParamsFromMenu = data;                
            }
        })
  
    this.getInputParams();         
    this.siteForm.controls.reportType.setValue("0");
    if(this.addAction){
      if(this.addAction == true){
        //this.siteForm.reset();
        this.global.Channel.sourceFormIds = [];
        this.global.Channel.Source = []
        this.global.Channel.Attributes = []
      }
    }

   

    }

    attributeSelect(event){
      let value = event.target.value;
      if(value == ""){
        let yAxisId = this.siteForm.controls.yaxisColumnId.value;
        let reportColId = this.siteForm.controls.reportColumnId.value;
        if(yAxisId){
          let checkAttr = this.inputParams.find(x=>x.paramKey == yAxisId);
          if(checkAttr){
            this.inputParams.forEach(element => {
            // element.disable = false;
              element.attrDisable = false;
              if(element.paramKey == yAxisId){
                element.attrDisable = true;
              }
            });
          }
        }
        if(reportColId){
          let checkAttr = this.inputParams.find(x=>x.paramKey == reportColId);
          if(checkAttr){
            this.inputParams.forEach(element => {
            // element.disable = false;
              element.attrDisable = false;
              if(element.paramKey == reportColId){
                element.attrDisable = true;
              }
            });
          }
        }
        if(reportColId == "" && yAxisId == ""){
          this.inputParams.forEach(element => {
              element.attrDisable = false;
            });
        }
        
      }
      let checkAttr = this.inputParams.find(x=>x.paramKey == value);
      if(checkAttr){
        this.inputParams.forEach(element => {
         // element.disable = false;
          element.attrDisable = false;
          if(element.paramKey == value){
            element.attrDisable = true;
          }
        });
      }
    }

    setyAxisVal(event)
    {
      if(event == ""){
        this.siteForm.controls.ycolumnSum.setValue(0);
      }
      let findAttr = this.inputParams.find(x=>x.paramKey == event);
      if(findAttr != undefined){
      if(findAttr.paramDatatype == 'Numeric' || findAttr.paramDatatype == 'numeric'){
        this.yaxisType = 'numeric';
        this.siteForm.controls.ycolumnSum.setValue(1);
      } else{
        this.yaxisType = 'hide';
        this.siteForm.controls.ycolumnSum.setValue(0);
      }
    }

      let value = event;
      let checkAttr = this.inputParams.find(x=>x.paramKey == value);
      if(checkAttr){
        this.inputParams.forEach(element => {
          element.attrDisable = false;
          if(element.paramKey == this.siteForm.controls.yaxisColumnId.value || element.paramKey == this.siteForm.controls.reportColumnId.value){
            element.attrDisable = true;
          }
        });
      }

      if(value == ""){
        let yAxisId = this.siteForm.controls.yaxisColumnId.value;
        let reportColId = this.siteForm.controls.reportColumnId.value;
        if(yAxisId){
          let checkAttr = this.inputParams.find(x=>x.paramKey == yAxisId);
          if(checkAttr){
            this.inputParams.forEach(element => {
            // element.disable = false;
              element.attrDisable = false;
              if(element.paramKey == yAxisId){
                element.attrDisable = true;
              }
            });
          }
        }
        if(reportColId){
          let checkAttr = this.inputParams.find(x=>x.paramKey == reportColId);
          if(checkAttr){
            this.inputParams.forEach(element => {
            // element.disable = false;
              element.attrDisable = false;
              if(element.paramKey == reportColId){
                element.attrDisable = true;
              }
            });
          }
        }
        if(reportColId == "" && yAxisId == ""){
          this.inputParams.forEach(element => {
              element.attrDisable = false;
            });
        }
        
      }

    }

  ngOnChanges()
  {
    this.getInputParams();    
  if(this.refilEditVal){
    this.setGraphData(this.refilEditVal)
  } else{
    this.getSource = []
    this.pluginFormList = [];
    this.global.Channel.sourceFormIds = []
    this.global.Channel.Source.forEach(element => {
      if(element.assignMappingDTOs){
      element.assignMappingDTOs.forEach(elems => {
        if(elems.checked == true){
          this.pluginFormList.push(elems);
          this.global.Channel.sourceFormIds.push(elems.id)
        }
      });
      }
      this.getSource.push(element.pluginId);
    });
     
    this.siteForm.controls.source.setValue(this.getSource);
   //this.siteForm.setValue({ source:this.getSource });

  }

  }

  onSelectAllSource(){
    this.refilEditVal = undefined;
    const selected = this.sourseList.map(item => item.pluginId);
    //this.currentSegments.jsonData.source = selected;
    //this.updateSource(this.currentSegments.jsonData.source);
    this.siteForm.controls.source.setValue(selected);
    this.sourseList.forEach(element => {
      let checkDefined = selected.find(x=>x == element.pluginId);
      if(checkDefined){
        element.checked = true;
        element.assignMappingDTOs.forEach(elem=>{
          if(elem.status == 'ACTIVE'){
          this.pluginFormList.push(elem);
          }
        })
      }
    });
    this.global.Channel.Source = this.sourseList.filter(function (item: any) {
      return item.checked == true;
    });
  }

  onClearAllSource(){
    this.siteForm.controls.source.setValue([]);
    this.pluginFormList = []
  }

  onClearAllPlugin(){
    this.siteForm.controls.pluginForms.setValue([]);
  }

  onSelectAllPlugin(){
    const selected = this.pluginFormList.map(item => item.id);
    this.siteForm.controls.pluginForms.setValue(selected);
  }

  updateSource(event){
    let sourceList = event;
    let sourceSet = [];
    this.pluginFormList = [];
    if(sourceList.length > 0){
    this.sourseList.forEach(element => {
      let checkDefined = sourceList.find(x=>x == element.pluginId);
      if(checkDefined){
        element.checked = true;
        element.assignMappingDTOs.forEach(elem=>{
          if(elem.status == 'ACTIVE'){
          this.pluginFormList.push(elem);
          }
        })
        sourceSet.push(element)
      }
    });
    for(let i=0; i<this.global.Channel.sourceFormIds.length; i++){
      let checkAvail = this.pluginFormList.find(x=>x.id == this.global.Channel.sourceFormIds[i]);
      if(checkAvail == undefined){
        delete this.global.Channel.sourceFormIds[i]
      }
    }
    this.global.Channel.sourceFormIds = this.global.Channel.sourceFormIds.filter(p=>{
      return p != null;
    })
    }
    this.global.Channel.Source = this.sourseList.filter(function (item: any) {
      return item.checked == true;
  });
  }

  setGraphData(data){
    data = JSON.parse(data)
    this.categoryName = data.category;
    let filterJson = data.filterJson ? JSON.parse(data.filterJson) : [];
    this.filterJson = filterJson;
    let setPluginForm = filterJson.pluginFormIds;
    this.global.Channel.sourceFormIds = filterJson.pluginFormIds;
   // this.global.Channel.Source = filterJson.source.split(',');
    let veriticalParamIdSet = data.veriticalParamId.includes('params.') == true ? data.veriticalParamId.split('.')[1] : data.veriticalParamId;
    let eventParamIdSet = data.eventParamId.includes('params.') == true ? data.eventParamId.split('.')[1] : data.eventParamId;
    let horizontalParamIdSet = data.horizontalParamId.includes('params.') == true ? data.horizontalParamId.split('.')[1] : data.horizontalParamId;
    let sourceSet = data.source.split(',');

    let findAttr = this.inputParams.find(x=>x.paramKey == veriticalParamIdSet);
      if(findAttr != undefined){
      if(findAttr.paramDatatype == 'Numeric' || findAttr.paramDatatype == 'numeric'){
        this.yaxisType = 'numeric';
      } else{
        this.yaxisType = 'hide';
      }
    }

    for(let i=0; i<sourceSet.length; i++){
      if(sourceSet[i] == ''){
        delete sourceSet[i];
      }
    }
    sourceSet = sourceSet.filter(p=>{
      return p != null;
    })
    this.sourseList.forEach(elems=>{
      let checkSource = sourceSet.find(x=>x == elems.pluginId);
      if(checkSource){
        this.global.Channel.Source.push(elems);
        if(elems.assignMappingDTOs.length > 0){
          elems.assignMappingDTOs.forEach(assigndto=>{
            if(assigndto.status == 'ACTIVE'){
            this.pluginFormList.push(assigndto);
            }
          })
        }
      }
    });
    if(this.filterJson.paramList){
      let paramList = this.filterJson.paramList;
      if(paramList.length > 0){
        paramList.forEach(element => {          
          element.paramKey = element.paramKey.includes('params.') == true ? element.paramKey.replace('params.',"") : element.paramKey;
          let findKeyInList = this.inputParams.find(x=>x.paramKey == element.paramKey);
          if(findKeyInList){            
            this.inputParams.forEach(element => {
              element.disable = false;
              if(element.paramKey == findKeyInList.paramKey){
                setTimeout(() => {
                  element.disable = true;            
                }, 1000);
              }
            });
          }
        });
      }
    }
    setTimeout(()=>{
        this.inputParams.forEach(element => {
          element.attrDisable = false;
          if(element.paramKey == veriticalParamIdSet || element.paramKey == horizontalParamIdSet){
            element.attrDisable = true;            
          }
        });
    },1000)
    
    this.siteForm.setValue({
            id:data.id,
            reportName:data.reportName,
            source:sourceSet,
            reportColumnId:horizontalParamIdSet,
            yaxisColumnId:veriticalParamIdSet,
            eventTimeId:eventParamIdSet,
            reportType:data.reportType,
            ycolumnSum:data.columnOutput,
            defaultSort: data.defaultSort,
            showDataGreater: data.showDataGreater,
            showToDashboard:data.showToDashboard,
            pluginForms:setPluginForm ? setPluginForm : []
        });

  }

  

  saveLeadReport(){
    let categoryParam;
  if(this.categoryName){
    categoryParam =this.categoryName;
  } else if(this.queryParamsFromMenu){
        categoryParam = this.queryParamsFromMenu['params'].dynamicparam;
    }

    this.isSubmited = true;
    if (this.siteForm.invalid ) {
        this.toastr.error('Please fill all mandatory fields');
        return;
    };
    
    if(this.siteForm.controls.showToDashboard.value == true){
      this.siteForm.controls.showToDashboard.setValue('1')
    } else{
      this.siteForm.controls.showToDashboard.setValue('0')
    }
    
    var formData = this.siteForm.value;
    let horizontalTypeCol = this.inputParams.find(x=>x.paramKey == formData.reportColumnId)
    let verticalTypeCol = this.inputParams.find(x=>x.paramKey == formData.yaxisColumnId)
    let eventParamType = this.inputParams.find(x=>x.paramKey == formData.eventTimeId);
    let eventParamCheck = eventParamType != undefined ? eventParamType.pluginName : null    
    let filterJson;    
    if(this.paramKey == undefined){
      filterJson = {"pluginFormIds":this.global.Channel.sourceFormIds,"source":this.global.Channel.Source.map(function (val) {return val.pluginName; }).join(",")};
      if(this.filterJson){
        if(this.filterJson.paramList){
          this.filterJson.paramList.forEach(element => {
            let checkDynamic = this.inputParams.find(x=>x.paramKey == element.paramKey);
            if(checkDynamic && checkDynamic.pluginName){
              element.paramKey = 'params.'+element.paramKey;              
            }
          });
          filterJson.paramList = this.filterJson.paramList;
        }
      }
    }
    if(this.paramKey != undefined){
      let checkAttribute = JSON.parse(localStorage.getItem('paramList'));
      let paramKey = []
      this.global.Channel.Attributes.forEach(element => {
        if(element.pluginName == 'dynamic'){
          paramKey.push('params.'+element.paramKey)
        } else{
          paramKey.push(element.paramKey)
        }
      });
      if(paramKey.length > 0){
        this.paramKey = paramKey;
      } else{
        this.paramKey = "";
      }
      filterJson = {"paramList":checkAttribute,"pluginFormIds":this.global.Channel.sourceFormIds,"source":this.global.Channel.Source.map(function (val) {return val.pluginName; }).join(",") }

    }    
    let formObj = {
      "horizontalParamId":formData.reportColumnId,
      "horizontalParamType":horizontalTypeCol != undefined ? horizontalTypeCol.paramDatatype : null,
      "veriticalParamId":formData.yaxisColumnId,
      "verticalParamType":verticalTypeCol != undefined ? verticalTypeCol.paramDatatype : null,
      "reportType":parseInt(formData.reportType),
      "reportName":formData.reportName,
      "defaultSort":formData.defaultSort,
      "eventParamId": eventParamCheck == 'dynamic' ? 'params.'+formData.eventTimeId : formData.eventTimeId,
      "eventParamType":eventParamType != undefined ? eventParamType.paramDatatype : null,
      "columnOutput":formData.ycolumnSum,
      "showToDashboard":formData.showToDashboard,
      "showDataGreater":formData.showDataGreater,
      "filterJson":JSON.stringify(filterJson),
      "id":formData.id ? formData.id : null,
      "source":formData.source.join()
    }
    if(verticalTypeCol != undefined){
      if(verticalTypeCol.pluginName == 'dynamic'){
        formObj.veriticalParamId = 'params.'+formData.yaxisColumnId
      }
      if(verticalTypeCol.paramDatatype == 'String' || verticalTypeCol.paramDatatype == 'string'){
        formObj.columnOutput = 0;
      }
    }
    if(horizontalTypeCol != undefined){
      if(horizontalTypeCol.pluginName == 'dynamic'){
        formObj.horizontalParamId = 'params.'+formData.reportColumnId
      }
    }
    // formData.range = this.datePipe.transform(this.siteForm.value.range.startDate, "yyyy-MM-dd") + '-' + this.datePipe.transform(this.siteForm.value.range.endDate, "yyyy-MM-dd")
    this.reportService.createReport(formObj, categoryParam).subscribe(data => {
        this.sourseList.forEach(elem=>{
          elem.checked = false;
        })
        if(data.status){
          
          if(this.viewAudiencePage == true){
            if(formData.showToDashboard == '0'){
            this.toastr.success('Your report successfully placed on analysis <u>click here</u>', null,{enableHtml:true,timeOut:600000,closeButton:true,extendedTimeOut:600000}).onTap.pipe()
          .subscribe(() => {
                this.router.navigate(['/admin/data/analysis/'+categoryParam]);
            });
            }
            if(formData.showToDashboard == '1'){
              this.toastr.success('Your report successfully placed on dashboard <u>click here</u>', null,{enableHtml:true,timeOut:600000,closeButton:true,extendedTimeOut:600000}).onTap.pipe()
          .subscribe(() => {
                this.router.navigate(['/admin/dashboard'],{queryParams:{'category':categoryParam}});
            });
            }
          } else if(this.refilEditVal){
            this.toastr.success('Your report updated successfully');
          } else{
            if(formData.showToDashboard == '1'){
             // this.toastr.success('Your report successfully placed on dashboard');
             this.toastr.success('Your report successfully placed on dashboard <u>click here</u>', null,{enableHtml:true,timeOut:600000,closeButton:true,extendedTimeOut:600000}).onTap.pipe()
          .subscribe(() => {
                this.router.navigate(['/admin/dashboard']);
            });
            }
            if(formData.showToDashboard == '0'){
            this.toastr.success('Your report created successfully');
            }
          }

          this.siteForm.reset();
            
            this.reportService.leadReportsData = undefined;
                // this.getInputParams();
                this.recordUpdateAdded.next('recordSuccess');
                if(this.paramKey == undefined){
                  this.global.Channel.Source = []
                }
                this.modalRef.hide();
                
                // this.fetchLeadReports();
        } else {
           // this.modalRef.hide();
            this.toastr.error(data.error_msgs,'Error');
        }
    })
}


getAttributeData(){
  this.projectService.fetchAttributeList().then( data => { 
      if(data){
          this.sourseList = this.projectService.getSourceList();
          this.sourseList.sort((a,b)=>{
              if(a.pluginName < b.pluginName){
                  return -1;
              }
              if(a.pluginName > b.pluginName){
                  return 1;
              }
          })
          this.getInputParams();
      }
  });
};


getInputParams() {
  var source = [];
  this.inputParams = [];
  this.sourseList.forEach(element => {
      if(element.pluginId == this.siteForm.controls.source.value){
          source.push(element.pluginName);
      }
  });
  var list = this.projectService.filterAttributeList(source);
  let filterStoreByCat = []
  let systemDefineAttr = []
  list.forEach(element => {
    if(element.paramOwner == 0 && element.pluginName != 'static'){
      if(element.category){        
        let catToArr = element.category.split(',');
        catToArr = catToArr.filter(p=>{
            return p != null && p != "";
        });        
        let checkByCat = catToArr.find(x=>x == this.categoryName);
        if(checkByCat){
            filterStoreByCat.push(element);
        }
      }
    }
    if(element.paramOwner == 1 && element.pluginName != 'static'){
      systemDefineAttr.push(element);
    }

    if(element.pluginName == 'static'){
      systemDefineAttr.push(element);
  }
      // this.inputParams.push(element);
  });


  if(filterStoreByCat.length > 0){
    this.inputParams = filterStoreByCat;
    systemDefineAttr.forEach(elem=>{
      this.inputParams.push(elem)
    })
  } 
  if(filterStoreByCat.length == 0){
  this.inputParams = []
    systemDefineAttr.forEach(elem=>{
      this.inputParams.push(elem)
    })
  }

  this.inputParams.forEach(element => {
    element.disable = false;
    element.attrDisable = false;
    if(this.viewAudiencePage == true){
    let checkDisable = this.global.Channel.Attributes.find(x=>x.paramKey == element.paramKey);
    if(checkDisable){
      element.disable = true;
    }
  }
  });

  this.inputParams.sort((a,b)=>{
      if(a.paramName < b.paramName){
          return -1;
      }
      if(a.paramName > b.paramName){
          return 1;
      }
  });
}

}
