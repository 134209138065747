import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvoiceEstimateRoutingModule } from './invoice-estimate-routing.module';
import { InvoiceEstimateComponent } from './invoice-estimate.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { ToastrModule } from 'ngx-toastr';
import { InvoiceSettingComponent } from './invoice-setting/invoice-setting.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ColorPickerModule } from "ngx-color-picker";
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ShareModule } from '../../shared/models/share.module';
import { InvoiceProductListComponent } from './invoice-product-list/invoice-product-list.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { QuotationComponent } from './quotation/quotation.component';

@NgModule({
  declarations: [
    InvoiceEstimateComponent,
    InvoiceSettingComponent,
    InvoiceProductListComponent,
    InvoiceComponent,
    QuotationComponent
  ],
  imports: [
    InvoiceEstimateRoutingModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    CommonModule,
    NgxPaginationModule,
    FormsModule,
    TabsModule,
    ColorPickerModule,
    Ng2SearchPipeModule,
    ShareModule,
    ToastrModule.forRoot({
      preventDuplicates: true
    }),
  ],
  exports:[
  ],
  providers: [
  ]
})
export class InvoiceEstimateModule { }
