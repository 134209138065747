import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { PathConstants } from "../../constants/path.constants";
import { AuthenticationService } from "../../../app/core/services/authentication.service";
import { ProjectService } from "../../../app/core/services/project.service";

@Component({
    selector: "splash-screen",
    templateUrl: "./splash-screen.component.html",
    styleUrls: ["./splash-screen.component.scss"]
})
export class SplashScreenComponent implements OnInit {
    windowWidth: string;
    showSplash = true;
    @Output() siteLoad = new EventEmitter();
    apiCoutn: number = 0;
    constructor(
        private authenticationService: AuthenticationService,
        private projectService: ProjectService,
        private router: Router
    ) {
        
    }

    ngOnInit(): void {
        if(this.authenticationService.getUserId() != undefined && this.authenticationService.getProjectId() == undefined){
            var userData = {
                userId : this.authenticationService.getUserId(),
                userRoleId: this.authenticationService.getUserRole(),
                accountCreated: false
            }
            this.authenticationService.setUserdata(userData);
            setTimeout(() => {
                this.siteLoad.emit('true');
                this.showSplash = false;    
            }, 500);
            this.router.navigate([PathConstants.CREATE_ACCOUNT]);
            return;
        };
        this.getProjectDetail();
    }

    getProjectDetail() {
        this.projectService.getUserProjectDetails().then( data => {
            if (data != undefined) {
                var userData = {
                    userId : data.userId,
                    projectId : data.id,
                    email : data.email,
                    phone : data.phone,
                    accountCreated : data.status == "ACTIVE",
                    userRoleId : data.userRoleId,
                    userRoleName : data.roleName,
                    emailVerified : data.emailVerified == "VERIFIED",
                    userName : data.firstName+' ' +data.lastName,
                    roleTypeId : data.roleTypeId
                }
                this.authenticationService.setUserdata(userData);
             //   this.projectService.setFollowUpTimer();
                this.getSiteMenuList();
            } 
        });
    }

    getSiteMenuList(){
        this.authenticationService.getSiteMenuData().then(data => {
            if(data != undefined){
                this.getAttributeList();
            }
        });
    }

    getAttributeList(){
        this.projectService.fetchAttributeList().then(data => {
            if(data != undefined){
                setTimeout(() => {
                    this.siteLoad.emit('true');
                    this.showSplash = false;
                }, 500);
            }
        });
    }

}